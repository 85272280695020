import React from 'react';

function CustomZoomIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_7711_321814)">
					<path d="M0 31.2323V75.9125C0.0405078 86.0156 8.29219 94.1457 18.3548 94.1052H83.4795C85.3309 94.1052 86.8202 92.616 86.8202 90.805V46.1273C86.7796 36.0242 78.5303 27.8916 68.4653 27.9321H3.3407C1.48926 27.9321 0 29.4214 0 31.2323ZM90.9663 48.6626L117.854 29.0187C120.189 27.0886 122 27.57 122 31.0727V90.9647C122 94.9511 119.786 94.4674 117.854 93.0187L90.9663 73.4153V48.6626Z" fill="#4A8CFF" />
				</g>
				<defs>
					<clipPath id="clip0_7711_321814">
						<rect width="122" height="122" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</React.Fragment>
	)
}

export default CustomZoomIcon
