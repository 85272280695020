import React, { useState } from 'react'
import LogoGreenLight from '../../assets/icons/LogoGreenLight';
import LogoGreenDark from '../../assets/icons/LogoGreenDark';
import customerServices from '../../services/customer-services';
import { showError } from '../../hooks/utilities';
import CheckIcon from '../../assets/icons/CheckIcon';

const EmailTemplate = () => {
    let template_theme = localStorage.getItem("template_theme")
    const [selectedTemplate, setSelectedTemplate] = useState(Number(template_theme) == 1 ? "blank" : Number(template_theme) == 3 ? "light"  : "default")
    const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);

    const handleChangeTemplate = (template) => {
      if(selectedTemplate != template){
        try {
            customerServices.updateEmailTemplate(template == "blank" ?  1 : template == "default"  ? 2 : 3).then((res)=>{
                if (res && res.status) {
                    localStorage.setItem("template_theme" , template == "blank" ?  1 : template == "default"  ? 2 : 3)
                    setSelectedTemplate(template)
                } else {
                    showError("l_something_went_wrong")
                }
            })
        } catch (error) {
            console.log(error)
        }
      }
    }
    
  return (
    <div className='ps-3 row'>
            <div key={1} className="space-select-box select-input-cox mx-3 bg-transparent col-lg-6 p-0">
            <input
              className="bottom-0 end-0 form-check-input h_230px w-100 position-absolute start-0 top-0 bg-transparent"
              name="provider-type"
              type="checkbox"
              checked={selectedTemplate == "default"}
              value={"default"}
              onClick={(e) => {
                handleChangeTemplate("default");
              }}
            />
            <a href="#/" className="check-image">
              <div className="check-image-box">
                <CheckIcon className="HW14 color-white-03-solid" />
              </div>
            </a>
            <div className="">
                <div className="d-flex bg-white-03 flex-column h_230px radius_3 justify-content-between">
                    <div className='border-bottom p-10 sampleheader'>
                        {LightTheme ? < LogoGreenLight className="header-logo-img h30wauto"/> :  <LogoGreenDark  className="header-logo-img h30wauto"/>}
                    </div>
                    <div className='d-flex flex-column flex-grow-1 justify-content-between p-10 samplebody'>
                        <div className='c-font f-14 fw-semibold'>Hi [name],</div>
                        <div className='c-font f-12'>Kind regards,[Name], [position] at [company]</div>
                    </div>
                    <div className='samplefooter border-top p-10 c-font f-12 text-center color'>© 2022 MYR.AI</div>
                </div>
              <div className="text-center p-10 ps-0">
                <div className="text-start c-font f-16 p-10 px-0 fw-semibold">Dark Theme </div>
              </div>
            </div>
          </div>
          <div key={2} className="space-select-box select-input-cox mx-3 bg-transparent col-lg-6 p-0">
            <input
              className="bottom-0 end-0 form-check-input h_230px w-100 position-absolute start-0 top-0 bg-white"
              name="provider-type"
              type="checkbox"
              checked={selectedTemplate == "light"}
              value={"light"}
              onClick={(e) => {
                handleChangeTemplate("light");
              }}
            />
            <a href="#/" className="check-image">
              <div className="check-image-box">
                <CheckIcon className="HW14 color-white-03-solid" />
              </div>
            </a>
            <div className="">
                <a className="d-flex z-index-3 bg-white-03 flex-column h_230px radius_3 justify-content-between" onClick={()=>{
                    handleChangeTemplate("light");
                }}>
                    <div className='blackborderbottom p-10 sampleheader'>
                        {LightTheme ? <LogoGreenDark  className="header-logo-img h30wauto"/> : <LogoGreenLight className="header-logo-img h30wauto"/> }
                    </div>
                    <div className='d-flex flex-column flex-grow-1 justify-content-between p-10 samplebody'>
                        <div className='c-font f-14 fw-semibold text-black'>Hi [name],</div>
                        <div className='c-font f-12 text-black'>Kind regards,[Name], [position] at [company]</div>
                    </div>
                    <div className='samplefooter blackbordertop p-10 c-font f-12 text-center text-black'>© 2022 MYR.AI</div>
                </a>
              <div className="text-center p-10 ps-0">
                <div className="text-start c-font f-16 p-10 px-0 fw-semibold">White Theme </div>
              </div>
            </div>
          </div>
         <div key={3} className="space-select-box select-input-cox mx-3 bg-transparent col-lg-6 p-0">
            <input
              className="bottom-0 end-0 form-check-input h_230px w-100 position-absolute start-0 top-0 bg-white"
              name="provider-type"
              type="checkbox"
              checked={selectedTemplate == "blank"}
              value={"blank"}
              onClick={(e) => {
                handleChangeTemplate("blank");
              }}
            />
            <a href="#/" className="check-image">
              <div className="check-image-box">
                <CheckIcon className="HW14 color-white-03-solid" />
              </div>
            </a>
            <div className="">
                <a className="d-flex z-index-3 bg-white-03 flex-column h_230px radius_3 justify-content-between"
                onClick={()=>{
                    handleChangeTemplate("blank");
                }}>
                   
                    <div className='d-flex flex-column flex-grow-1 justify-content-between p-10 samplebody'>
                        <div className='c-font f-14 fw-semibold text-black'>Hi [name],</div>
                        <div className='c-font f-12 text-black'>Kind regards,[Name], [position] at [company]</div>
                    </div>
                </a>
                <div className="text-center p-10 ps-0">
                <div className="text-start c-font f-16 p-10 px-0 fw-semibold">Blank </div>
              </div>
              
            </div>
          </div>
         
    

         

         
    </div>
  )
}

export default EmailTemplate