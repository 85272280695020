import React, { useEffect, useState } from "react";
import { _l, isCamFound } from "../../hooks/utilities";
import FileUploadComponent from "./FileUploadComponent";
import LinkComponent from "./LinkComponent";
import NotesComponent from "./NotesComponent";
import DocumentsDetail from "../Documents/DocumentsDetail";
import DocumentsActivity from "../Documents/DocumentsActivity";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { useSelector } from "react-redux";
import ChecklistComponent from "./ChecklistComponent"
import Documentdata from "../Documents/tabs/Document"
import FeedchatInTaskModalButton from "./FeedchatInTaskModalButton";
import FeedchatInTaskModal from "./FeedchatInTaskModal";
import MagnifyingGlassIcon from "../../assets/icons/MagnifyingGlassIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import FunnelIcon from "../../assets/icons/FunnelIcon";
function TaskBodyCommonModal({
  data,
  handleBodyAction,
  isExternal = false,
  checklistAssigneesList,
  showFullscreen=false,
  pageName,
  iscallModal,
  floorFullScreendView = () => {}
}) {
  const [activeTab, setActiveTab] = useState(
    (data.provider === "google_drive" || data.provider === "one_drive") && typeof data.is_synced !== "undefined"
      ? "document"
      : "checklist"
  );
  const { isCallFullscreen } = useSelector((state) => state.customer);
  const [selectedTask, setSelectedTask] = useState("checklist");
  const projectId = localStorage.getItem("selectedOffice");
  const [searchLinkAttchment, setSearchLinkAttchment] = useState("");
  const [taskId, setTaskId] = useState(0);
  const [toastClose, setToastClose] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checklistSearchString, setChecklistSearchString] = useState("")
  const [checklistFilter, setChecklistFilter] = useState(["all"]);
    const [actionMenuItems, setActionMenuItems] = useState([]);
  const [ShowCommentModal, setShowCommentModal] = useState(false);
  const {floorDetails} = useSelector((state) => state.benchmarkReducer);
  const { isCallRecived } = useSelector((state) => state.chat);
  const [isMute, setIsMute] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [isCameraFound, setIsCameraFound] = useState(true);
  useEffect(() => {
    if (Object.keys(data).length) {
      setTaskId(data.id ? data.id : 0);
      setSelectedTask(data);
    }
  }, [JSON.stringify(data)]);
  useEffect(() => {
    setSearchLinkAttchment("")
    setChecklistSearchString("")
  }, [taskId]);
  useEffect(() => {
     let actionMenu = [
      {
        actionName: _l("l_todo"),
        actionIcon: "CircleFillTodoIcons",
        actionHandler: () => {checkListFilter("todo")},
        active : checklistFilter.includes("todo")
      },
      {
        actionName: _l("l_inprogress"),
        actionIcon: "CircleFillInprogressIcons",
        actionHandler: () => {checkListFilter("inprogress")},
        active : checklistFilter.includes("inprogress")
      },
      {
        actionName: _l("l_done"),
        actionIcon: "CircleFillDoneIcons",
        actionHandler: () => {checkListFilter("done")},
        active : checklistFilter.includes("done")
      },
      {
        actionName: _l("l_priority"),
        actionIcon: "HighPriorityActiveIcon",
        actionHandler: () => {checkListFilter("priority")},
        active : checklistFilter.includes("priority")
      }
    ];
    if(floorDetails.floor.length > 1)
    {
      actionMenu = [{
        actionName: _l("l_floor_filter"),
        actionIcon: "StepIcon",
        actionHandler: () => {checkListFilter("floor")},
        active : checklistFilter.includes("floor")
      }].concat(actionMenu);
    }
    actionMenu = [{
      actionName: _l("l_all"),
      actionIcon: "SelectionAll",
      actionHandler: () => {checkListFilter("all")},
      active : checklistFilter.includes("all")
    },].concat(actionMenu);
    setActionMenuItems(actionMenu);
  }, [checklistFilter]);
  const checkIsCamFound = async () => {
    const camFound = await isCamFound();
    if(!camFound){
        setIsCameraOff(true);
        setIsCameraFound(false);
    }
  };
  useEffect(() => {
    checkIsCamFound();
  }, []);
  const handleSearchCheckList = (value) =>{
    setChecklistSearchString(value)
  }
  const checkListFilter = (value) => {
    let filterArr = checklistFilter;
    let index = filterArr.findIndex((f) => f == value);
    if (index > -1) {
      filterArr = filterArr.filter((c) => c != value);
    } else {
      filterArr = [value];
    }
    if(!filterArr.length)
    {
      filterArr.push(parseInt(data.floor_id) > 0 ? 'floor' : "all");
    }
    setChecklistFilter(filterArr.slice());
  }
  return (
    <React.Fragment>
      <div className={`tab-wrapper_main res-overflow-auto z-index-12imp d-none ${activeTab === "files" ? "pb-3":""}`}>
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <div className="comman-tab-wrapper pb-0">
            <div className="d-flex align-items-center">
              {(data.provider === "google_drive" || data.provider === "one_drive") &&
              typeof data.is_synced !== "undefined" ? (
                <>
                  <div
                    id="task_detail_head_4"
                    className={`tab-wrapper  for-accessible ${
                      activeTab === "document" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("document");
                      }}
                    >
                      {_l("l_document")}
                    </a>
                    <a href="#/" className="info-icon">
                    {selectedTask.provider === 'one_drive' || selectedTask.provider === 'google_drive'?"":<InfoIcon className="HW18" />}
                    </a>
                  </div>
                  <div
                    id="task_detail_head_5"
                    className={`tab-wrapper  for-accessible ${
                      activeTab === "basic_details" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("basic_details");
                      }}
                    >
                      {_l("l_basic_details")}
                    </a>
                    <a href="#/" className="info-icon">
                      {selectedTask.provider === 'one_drive' || selectedTask.provider === 'google_drive'?"":<InfoIcon className="HW18" />}
                    </a>
                  </div>
                  <div
                    id="task_detail_head_6"
                    className={`tab-wrapper  for-accessible ${
                      activeTab === "activities" ? "active" : ""
                    }`}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("activities");
                      }}
                    >
                      {_l("l_activities")}
                    </a>
                    <a href="#/" className="info-icon">
                    {selectedTask.provider === 'one_drive' || selectedTask.provider === 'google_drive'?"":<InfoIcon className="HW18" />}
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div
                    id="task_detail_head_1"
                    className={`tab-wrapper  for-accessible d-none ${
                      activeTab === "checklist" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("checklist");
                      }}
                    >
                      {_l("l_checklist")}
                    </a>
                  </div>
                  <div
                    id="task_detail_head_2"
                    className={`tab-wrapper  for-accessible d-none ${
                      activeTab === "files" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("files");
                      }}
                    >
                      {_l("l_files")}
                    </a>
                  </div>
                  <div
                    id="task_detail_head_3"
                    className={`tab-wrapper  for-accessible d-none ${
                      activeTab === "links" ? "active" : ""
                    } `}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setActiveTab("links");
                      }}
                    >
                      {_l("l_links")}
                    </a>
                  </div>
                </>
              )}
              {selectedTask && selectedTask.is_one_time_benchmark == 1 ? (
                <div
                  id="task_detail_head_5"
                  className={`tab-wrapper task-detail-tab for-accessible ${
                    activeTab === "benchmark" ? "active" : ""
                  } d-flex align-items-center`}
                >
                  <a href="#/"
                    className="tab-name"
                    onClick={() => {
                      setActiveTab("benchmark");
                    }}
                  >
                    {_l("l_benchmark")}
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {activeTab === "checklist" ? (
            <>
            <div
              className={` d-none switch ${isExternal ? "d-none" : " " } `}
            >
              <div className="d-flex align-items-center">
                <div className="c-font f-10 color-white-60 pe-2">
                </div>
                <div className="label-text">
                  <div className="d-flex align-items-center">
                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={isChecked}
                        onChange={() => {
                          setIsChecked(!isChecked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex commn-search radius_3 justify-content-between">
              <div className="comment-input-box">
                <input
                  type="text"
                  className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font shadow-none "
                  placeholder={`${_l("l_search")}`}
                  value={checklistSearchString}
                  onChange={(e)=>{handleSearchCheckList(e.target.value)}}
                />
              </div>
              <a href="#/" className="comman_action_icon">
                <div className="d-flex align-items-center ">
                  <div className="action_icon h32w32 with_bg">
                    <MagnifyingGlassIcon
                      className="HW18"
                    />
                  </div>
                </div>
              </a>
              <div className="dropstart ml-10">
              <a href="#/" className={`comman_action_icon  ${checklistFilter.length > 0 ? "iconactive" : ""}`}
              type="button"
              id="commentOptionDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >
                <div className="d-flex align-items-center  ">
                  <div className="action_icon h32w32 with_bg on-hover-active-toggle-img">
                    <FunnelIcon
                      className="HW18"
                    />
                  </div>
                </div>
              </a>
              <ActionDropdown
                actions={actionMenuItems}
              />
              </div>
            </div>
            </>
          ) : (
            <></>
          )}
          {activeTab === "files" || activeTab === "links" ? (
            <div className="d-flex commn-search radius_3 justify-content-between">
              <div className="comment-input-box">
                <input
                  type="text"
                  className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                  placeholder={`${_l("l_search")}`}
                  value={searchLinkAttchment}
                  onChange={(e) => {
                    setSearchLinkAttchment(e.target.value);
                  }}
                />
              </div>
              <a href="#/" className="comman_action_icon">
                <div className="d-flex align-items-center ">
                  <div className="action_icon h25w25">
                    <MagnifyingGlassIcon
                      className="HW14"
                    />
                  </div>
                </div>
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {(data.provider === "google_drive" || data.provider === "one_drive") &&
      typeof data.is_synced !== "undefined" ? (
        <>
          <div
            id="task_detail_11"
            className={`${activeTab === "document" ? "" : "d-none"} h-100`}
          >
            <Documentdata data={data} />
          </div>
          <div
            id="task_detail_12"
            className={`${activeTab === "basic_details" ? "" : "d-none"} h-100`}
          >
            <div className="d-flex commn-search radius_3 justify-content-between">
              <div className="comment-input-box">
                <DocumentsDetail data={data} />
              </div>
            </div>
          </div>
          <div
            id="task_detail_13"
            className={`${activeTab === "activities" ? "" : "d-none"} h-100`}
          >
            <DocumentsActivity data={data} />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className={`tast-detail-tab-wrapper res-height-auto ${ShowCommentModal ? "" : "h-135"}`}>
        <div
          id="task_detail_contain_1"
          className={`${
            activeTab === "checklist" ? "" : "d-none"
          } overflow-hiiden-auto d-flex flex-column h-100`}
        >
          {activeTab === "checklist" && (
            <>
            {iscallModal || isCallRecived ? <>
            <div className="row h-100">
                  {/* Chat feed start */}
              {isCallFullscreen ? 
              <FeedchatInTaskModal isMute={isMute} isCameraOff={isCameraOff} isCameraFound={isCameraFound} />
              : <></>
            }
            <div className="col-lg-7 d-flex flex-column h-90per">
                <ChecklistComponent
                projectId={isExternal ? data.project_id  : projectId}
                task_id={taskId}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                isSystemTask={
                  (selectedTask &&
                    selectedTask.addedfrom == 0 &&
                    selectedTask.schedule_id &&
                    parseInt(selectedTask.schedule_id) > 0) ||
                  selectedTask.is_allowed_to_edit != 1
                    ? true
                    : null
                }
                toastClose={() => {}}
                closeAllTost={toastClose}
                setRequestChecklist={() => {}}
                isExternal={isExternal}
                checklistAssigneesList={checklistAssigneesList}
                checklistSearchString={checklistSearchString}
                checklistFilter={checklistFilter}
                showFullscreen={showFullscreen}
                setChecklistFilter={setChecklistFilter}
                actionMenuItems={actionMenuItems}
                data={data}
                isAllowToEdit={data.is_allowed_to_edit == 1}
                iscallModal={iscallModal}
                floorFullScreendView = {floorFullScreendView}
              />
                <FileUploadComponent
                projectId={projectId}
                task_id={taskId}
                attachmentCountUpdate={(task_id, flag) => {
                  handleBodyAction("attachmentCountUpdate", {
                    task_id: task_id,
                    flag: flag,
                  });
                }}
                toastClose={() => {}}
                closeAllTost={toastClose}
                searchAttchment={searchLinkAttchment}
                isExternal={isExternal}
                readOnly={false}
                taskDetails={selectedTask}
                showFullscreen={showFullscreen}
              />
            </div>
        {/* Chat feed over */}
                  {/* feed chat button start */}
          {isCallFullscreen ?
          <FeedchatInTaskModalButton isMute={isMute} setIsMute={setIsMute} isCameraOff={isCameraOff} setIsCameraOff={setIsCameraOff} isCameraFound={isCameraFound} />
          :<></>
        }
      {/* feed chat button over */}
            </div>
            </>
              : <>
          <ChecklistComponent
            projectId={isExternal ? data.project_id  : projectId}
            task_id={taskId}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            isSystemTask={
              (selectedTask &&
                selectedTask.addedfrom == 0 &&
                selectedTask.schedule_id &&
                parseInt(selectedTask.schedule_id) > 0) ||
              selectedTask.is_allowed_to_edit != 1
                ? true
                : null
            }
            toastClose={() => {}}
            closeAllTost={toastClose}
            setRequestChecklist={() => {}}
            isExternal={isExternal}
            checklistAssigneesList={checklistAssigneesList}
            checklistSearchString={checklistSearchString}
            checklistFilter={checklistFilter}
            showFullscreen={showFullscreen}
            setChecklistFilter={setChecklistFilter}
            actionMenuItems={actionMenuItems}
            data={data}
            isAllowToEdit={data.is_allowed_to_edit == 1}
            iscallModal={iscallModal}
            floorFullScreendView = {floorFullScreendView}
          />
          <FileUploadComponent
          projectId={projectId}
          task_id={taskId}
          attachmentCountUpdate={(task_id, flag) => {
            handleBodyAction("attachmentCountUpdate", {
              task_id: task_id,
              flag: flag,
            });
          }}
          toastClose={() => {}}
          closeAllTost={toastClose}
          searchAttchment={searchLinkAttchment}
          isExternal={isExternal}
          readOnly={false}
          taskDetails={selectedTask}
          showFullscreen={showFullscreen}
        />
              </> }
        </>
          )}
        </div>
        <div
          id="task_detail_contain_2"
          className={`${activeTab === "files" ? "" : "d-none"} h-100`}
        >
          {activeTab === "files" && (<FileUploadComponent
            projectId={projectId}
            task_id={taskId}
            attachmentCountUpdate={(task_id, flag) => {
              handleBodyAction("attachmentCountUpdate", {
                task_id: task_id,
                flag: flag,
              });
            }}
            toastClose={() => {}}
            closeAllTost={toastClose}
            searchAttchment={searchLinkAttchment}
            isExternal={isExternal}
            readOnly={false}
            taskDetails={selectedTask}
            showFullscreen={showFullscreen}
          />)}
        </div>
        <div
          id="task_detail_contain_3"
          className={`${activeTab === "links" ? "" : "d-none"} h-100`}
        >
          {
            activeTab === "links" ? <>
              <LinkComponent
                projectId={projectId}
                task_id={taskId}
                toastClose={() => {}}
                closeAllTost={() => {}}
                searchLink={searchLinkAttchment}
                isExternal={isExternal}
                readOnly={
                  (selectedTask &&
                    selectedTask.addedfrom == 0 &&
                    selectedTask.schedule_id &&
                    parseInt(selectedTask.schedule_id) > 0) ||
                  selectedTask.is_allowed_to_edit != 1
                    ? true
                    : false
                }
              />
            </> : <></>
          }
        </div>
        <div
          id="task_detail_contain_4"
          className={`for-disabled ${
            activeTab === "notes" ? "" : "d-none"
          } h-100`}
        >
          {
            activeTab === "notes" ? <>
            <NotesComponent
              projectId={projectId}
              task_id={taskId}
              toastClose={() => {}}
              closeAllTost={() => {}}
              isExternal={isExternal}
            />
          </> : <></>
          }
        </div>
      </div>
    </React.Fragment>
  );
}
export default React.memo(TaskBodyCommonModal);
