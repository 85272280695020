export const ADD_DOCUMENT_COMMENT = "ADD_DOCUMENT_COMMENT";
export const REMOVE_DOCUMENT_COMMENT = "REMOVE_DOCUMENT_COMMENT";
export const GET_MENTION_LIST = "GET_MENTION_LIST";
export const GET_COMMENTS = "GET_COMMENTS";
export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const GET_ESTIMATE_DETAILS = "GET_ESTIMATE_DETAILS";
export const GET_DOCUMENT_TYPE = "GET_DOCUMENT_TYPE";
export const GET_ONBOARD_DETAILS = "GET_ONBOARD_DETAILS";
export const GET_SPACE_DOCUMENT = "GET_SPACE_DOCUMENT";
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_CREDIT_NOTE_DETAILS = "GET_CREDIT_NOTE_DETAILS";
export const GET_ONBOARD_PROVIDER_LIST = "GET_ONBOARD_PROVIDER_LIST";
export const GET_ONBOARD_DOCUMENT_LIST = "GET_ONBOARD_DOCUMENT_LIST";
export const GET_DOCUMENT_DETAILS = "GET_DOCUMENT_DETAILS";