import React from 'react';
 

function CheckCircleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
		<path fillRule="evenodd" clipRule="evenodd" d="M2.89062 8C2.89062 4.96243 5.35306 2.5 8.39062 2.5C11.4282 2.5 13.8906 4.96243 13.8906 8C13.8906 11.0376 11.4282 13.5 8.39062 13.5C5.35306 13.5 2.89062 11.0376 2.89062 8ZM8.39062 1.5C4.80077 1.5 1.89062 4.41015 1.89062 8C1.89062 11.5899 4.80077 14.5 8.39062 14.5C11.9805 14.5 14.8906 11.5899 14.8906 8C14.8906 4.41015 11.9805 1.5 8.39062 1.5ZM11.4858 6.86178C11.6856 6.67116 11.693 6.35467 11.5024 6.15486C11.3118 5.95506 10.9953 5.94761 10.7955 6.13822L7.47217 9.30868L5.98607 7.88852C5.78643 7.69774 5.46993 7.70492 5.27914 7.90456C5.08836 8.1042 5.09554 8.4207 5.29518 8.61148L7.12643 10.3615C7.31958 10.5461 7.62371 10.5462 7.81701 10.3618L11.4858 6.86178Z"/>
		</svg>


		</React.Fragment>
	)
}

export default CheckCircleIcon