import React,{useEffect, useState} from 'react'
import moment from "moment";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { _l } from "../../hooks/utilities";
import LockTaskCard from './LockTaskCard';
import { useSelector } from 'react-redux';
import { DEFAULT_PLACEHOLDER } from "../../actions/action-type";
import HashIcon from '../../assets/icons/HashIcon';
import CaretDoubleDownIcon from '../../assets/icons/CaretDoubleDownIcon';

const LockTasks = ({provider}) => {
    const [categoryName, setCategoryName] = useState('No Topic');

    const { defaultTopics, globalSearchString } = useSelector((state) => state.customer);
    const [taskSearchString, setTaskSearchString] = useState("");

    const [taskList, setTaskList] = useState(provider.tasks);
    const [taskListPage, setTaskListPage] = useState(1);
    const [section, setSection] = useState(provider ? provider : []);

    useEffect(() => {
        if(defaultTopics)
        {
            let filtered_topic = defaultTopics.filter((topic) => (topic.main_key).toLowerCase().includes(provider.assginee_type && provider.assginee_type !== null ? provider.assginee_type: ""));
            if(filtered_topic.length != 0) {
                let category = filtered_topic[0].categories.filter((category) => category.filter_key.includes(provider.assginee_id && provider.assginee_id !== null ? provider.assginee_id : ""));
                if(category.lengh != 0) {
                    setCategoryName(category && category[0] &&  category[0].title ? category[0].title : "");
                }
            }
        }
    }, [defaultTopics, taskList, provider]);

    useEffect(() => {
        setTaskList(provider ? provider.tasks : []);
        setTaskListPage(1);
      }, [provider]);

    useEffect(() => {
        if(typeof globalSearchString !== 'undefined') {
        setTaskSearchString(globalSearchString !== undefined ? globalSearchString : "");
        }
    }, [globalSearchString]);

    return (
        <div className='p-10 px-0'>
            <div className='d-flex h-100'>
                <div className='w_80px h-100 after-separator position-relative'>
                    <div className='c-font color-white-80 f-12 pb-2 text-center title-fonts'>
                        {provider && provider['time'] !== false ? moment(provider['time']).format('h:mm a') : ''}
                    </div>
                </div>
                <div className="bg-white-03 p-15 radius_3 flex-grow-1 w_80calc">
                    <div className='align-items-end d-flex justify-content-between border-bottom p-15 px-0 pt-0 ps-1'>
                        <a href="#/" className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 mb-1"
                         
                         
                         
                        >
                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${ provider.boss_operator ? ((provider.boss_operator['profile_image'])) : DEFAULT_PLACEHOLDER}')` }}></div>
                        </a>
                        <div className='ps-2  w100minus20'>
                            <div className=''>
                                <HashIcon className="HW18" />
                                <span className='f-13 c-font'>{_l(categoryName)}</span>
                            </div>
                            <div className='bottom-options'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='align-items-center d-flex w100minus40'>
                                        <div class="badge rounded-pill text-nowrap list-toggle-view-none"><span class=" p-0 ">{_l("l_task")}</span></div>
                                        <div className='before-dnone card-view-none comman_action_icon with_separator_10'>
                                            <div className='multi_inner_wrapper with-min-width d-flex with_minus '>
                                                <a href="#/" className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                                >
                                                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${DEFAULT_PLACEHOLDER}')` }} >

                                                    </div>
                                                </a>
                                                <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle tag="a" className=" dropdown team-list-dropdown" >
                                                            <a href="#/" className="comman-image-box h20w20 with_overlay rounded-circle d-flex radius_3">
                                                                <div className="unread_count m-auto" >
                                                                    {provider.total_providers.length > 0 ?provider.total_providers.length: 0}
                                                                </div>
                                                            </a>
                                                            <UncontrolledGeneralListing list={provider.total_providers.map((res,index) => {
                                                                return {
                                                                    name: res.name,
                                                                    image: res.profile_image,
                                                                    id: index,
                                                                };
                                                            })} />
                                                        </DropdownToggle>
                                                    </UncontrolledDropdown>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='before-dnone comman_action_icon with_separator_10 card-view-none flex-grow-1 '>
                                            <div className="progress-slider flex-grow-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="progress me-3">
                                                        <div className="progress-bar" style={{ width: `${provider.task_progress}%` }}></div>
                                                    </div>
                                                    <div className="c-font f-12 ms-2 title-fonts">{provider.task_progress}%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href={window.location.origin+'/chat'} target='_blank' ><Button variant="primary" size="sm">{_l("l_chat_modal")}</Button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='pb-2 pt-3 ps-30'>
                        <ul className=''>
                        {
                            taskList && taskList.length ? (
                            taskList
                            .filter((task) => (task.name || "").toLowerCase().includes(taskSearchString.toLowerCase()))
                            .slice(0,
                                taskListPage > 1
                                    ? (taskListPage - 1) * 5 + 3
                                    : taskListPage * 3
                                )
                            .map((task, index) => {
                                return ( <LockTaskCard key={index} task={task} section={section}/>)
                            })) : (<></>)
                        }
                        </ul>
                        {
                            taskList.length >= (taskListPage - 1) * 5 + 3 &&
                            taskList.length != (taskListPage - 1) * 5 + 3 ? (
                                <a href="#/" className="btn btn-white-03 w-100 view-more-btn" onClick={() => {  setTaskListPage(taskListPage + 1); }}>
                                    <div className="d-flex align-item-center justify-content-center">
                                        <span className=" title-fonts c-font f-12 ms-2">
                                            {_l("l_view_more_btn")} (
                                                {taskListPage > 1
                                                ? taskList.length -
                                                    ((taskListPage - 1) * 5 + 3)
                                                : taskList.length - 3}
                                                )
                                        </span>
                                        <CaretDoubleDownIcon className="HW12 mx-2" />
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            className="d-none"
                                        />
                                    </div>
                                </a>
                            ) : (<></>) 
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LockTasks