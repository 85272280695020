import React from 'react';

function CustomYahooIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_6651_285602)">
					<path d="M25.2632 0H4.73684C2.12076 0 0 2.12076 0 4.73684V25.2632C0 27.8792 2.12076 30 4.73684 30H25.2632C27.8792 30 30 27.8792 30 25.2632V4.73684C30 2.12076 27.8792 0 25.2632 0Z" fill="url(#paint0_radial_6651_285602)" />
					<path opacity="0.1" d="M28.2237 1.04014C26.1454 6.25461 22.3421 11.9507 17.1474 17.1454C11.9526 22.3401 6.25461 26.1434 1.04014 28.2237C0.36503 27.3849 -0.00208946 26.3399 8.94596e-06 25.2632V4.73685C8.94596e-06 3.48056 0.499068 2.27573 1.3874 1.3874C2.27573 0.499068 3.48056 8.94596e-06 4.73685 8.94596e-06H25.2632C26.3399 -0.00208946 27.3849 0.36503 28.2237 1.04014Z" fill="white" />
					<path d="M13.8298 10.9796L11.8423 15.9118L9.91795 10.9776C9.89026 10.9069 9.84196 10.8461 9.77932 10.8032C9.71667 10.7603 9.64258 10.7371 9.56663 10.7368H6.89229C6.83048 10.7368 6.76961 10.752 6.71504 10.781C6.66047 10.81 6.61388 10.8521 6.57937 10.9033C6.54486 10.9546 6.52348 11.0136 6.51713 11.0751C6.51078 11.1366 6.51965 11.1987 6.54295 11.2559L10.1429 20.0921L9.22321 22.3697C9.19864 22.4273 9.18878 22.49 9.19451 22.5523C9.20025 22.6146 9.2214 22.6745 9.25606 22.7266C9.29072 22.7787 9.3378 22.8213 9.39305 22.8507C9.4483 22.88 9.50999 22.8952 9.57255 22.8947H12.1561C12.2311 22.895 12.3046 22.8728 12.3668 22.8309C12.4291 22.7891 12.4774 22.7295 12.5054 22.6599L17.114 11.2638C17.137 11.2075 17.1459 11.1464 17.1402 11.0859C17.1344 11.0253 17.1141 10.967 17.0809 10.916C17.0478 10.865 17.0028 10.8228 16.9498 10.793C16.8967 10.7632 16.8373 10.7466 16.7765 10.7447H14.1772C14.1024 10.7448 14.0295 10.7672 13.9676 10.8091C13.9057 10.8509 13.8577 10.9103 13.8298 10.9796ZM20.1712 7.34012C20.2001 7.26988 20.2494 7.20994 20.3127 7.16806C20.3761 7.12618 20.4506 7.1043 20.5265 7.10525H23.114C23.1757 7.10487 23.2366 7.11974 23.2912 7.14854C23.3458 7.17733 23.3925 7.21917 23.427 7.27032C23.4616 7.32148 23.483 7.38038 23.4893 7.44179C23.4957 7.5032 23.4867 7.56523 23.4633 7.62236L20.7239 14.3566C20.6959 14.4264 20.6477 14.4863 20.5854 14.5285C20.5232 14.5707 20.4497 14.5933 20.3745 14.5934H17.791C17.7292 14.5934 17.6683 14.5783 17.6137 14.5492C17.5592 14.5202 17.5126 14.4782 17.478 14.4269C17.4435 14.3756 17.4222 14.3167 17.4158 14.2552C17.4095 14.1937 17.4183 14.1316 17.4416 14.0743L20.1712 7.34012Z" fill="white" />
					<path d="M17.9959 19.9225C19.1502 19.9225 20.086 18.9867 20.086 17.8323C20.086 16.678 19.1502 15.7422 17.9959 15.7422C16.8415 15.7422 15.9058 16.678 15.9058 17.8323C15.9058 18.9867 16.8415 19.9225 17.9959 19.9225Z" fill="white" />
				</g>
				<defs>
					<radialGradient id="paint0_radial_6651_285602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15 15) scale(15)">
						<stop stopColor="#6401D9" />
						<stop offset="0.99" stopColor="#5201B3" />
					</radialGradient>
					<clipPath id="clip0_6651_285602">
						<rect width="30" height="30" fill="white" />
					</clipPath>
				</defs>
			</svg>

		</React.Fragment>
	)
}

export default CustomYahooIcon
