import React from 'react';
 

function EqualsIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_4443_245288)">
<path d="M3.125 12.5H16.875"  stroke-opacity="0.8" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.125 7.5H16.875"  stroke-opacity="0.8" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_4443_245288">
<rect width="20" height="20" />
</clipPath>
</defs>
</svg>

		</React.Fragment>
	)
}

export default EqualsIcon