import React from 'react';
 

function PhysicalIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g opacity="0.8">
					<path d="M52.0108 8.89122H25.4883V55.2148H52.0108V8.89122Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M25.489 55.2148H7.53516V26.725H25.489V55.2148Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M3.59961 55.2148H56.3996" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M14.9302 26.7246H7.53516V22.2824H14.9302V26.7246Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M48.2995 8.8916H29.4746V4.80025H48.2995V8.8916Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M35.8459 19.4306H31.2969V14.8816H35.8459V19.4306ZM45.9034 19.4306H41.3543V14.8816H45.9034V19.4306ZM35.8459 28.8145H31.2969V24.2655H35.8459V28.8145ZM45.9034 28.8145H41.3543V24.2655H45.9034V28.8145ZM35.8459 38.1982H31.2969V33.6492H35.8459V38.1982ZM45.9034 38.1982H41.3543V33.6492H45.9034V38.1982Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M42.1639 55.2139H35.166V45.2178H42.1639V55.2139ZM18.8284 55.2139H14.0703V49.2356H18.8284V55.2139Z" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M12.041 31.3441V44.7891" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M16.4297 31.3441V44.7891" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M20.8281 31.3441V44.7891" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				</g>
			</svg>


		</React.Fragment>
	)
}

export default PhysicalIcon