import React from 'react';
 

function CardholderIcon (props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_14363_217874)">
<path d="M14.6667 0.666992H1.33334C1.15653 0.666992 0.986958 0.73723 0.861934 0.862254C0.73691 0.987279 0.666672 1.15685 0.666672 1.33366V14.667C0.666672 14.8438 0.73691 15.0134 0.861934 15.1384C0.986958 15.2634 1.15653 15.3337 1.33334 15.3337H14.6667C14.8435 15.3337 15.0131 15.2634 15.1381 15.1384C15.2631 15.0134 15.3333 14.8438 15.3333 14.667V1.33366C15.3333 1.15685 15.2631 0.987279 15.1381 0.862254C15.0131 0.73723 14.8435 0.666992 14.6667 0.666992Z"/>
<path d="M0.666672 10.6667H3.72001C3.81176 10.6424 3.90825 10.6424 4.00001 10.6667C4.07992 10.6952 4.15271 10.7407 4.21334 10.8L5.84001 12.4267C5.90064 12.486 5.97342 12.5315 6.05334 12.56C6.13662 12.5798 6.22339 12.5798 6.30667 12.56H9.72001C9.80328 12.5798 9.89006 12.5798 9.97334 12.56C10.0533 12.5315 10.126 12.486 10.1867 12.4267L11.8133 10.8C11.8662 10.7437 11.9296 10.6984 12 10.6667C12.0833 10.6468 12.1701 10.6468 12.2533 10.6667H15.3333" />
</g>
<defs>
<clipPath id="clip0_14363_217874">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>






		</React.Fragment>
	)
}

export default CardholderIcon 