import React from 'react'
const DashboardSkeleton = () => {
  return (
    <>
      <div className='Cardbox bg-white-03 radius_3 p-15 mb-15px SkeletonMain'>
        <div className='d-flex'>
          <div className='radius_3 skeleton-animation' style={{ height: '144px', width: '220px', marginRight: '15px' }}></div>
          <div className='flex-grow-1 d-flex flex-column justify-content-between'>
            <div className="d-flex align-items-center">
              <div className='d-flex  flex-grow-1 w-50 w100minus100per'>
                <div className='h52w52 rounded-5 skeleton-animation'></div>
                <div className='ms-10px flex-grow-1 d-flex flex-column justify-content-center'>
                  <div className='mb-1 skeleton-animation radius_3' style={{ width: '40%', height: '15px' }}></div>
                  <div className='skeleton-animation radius_3' style={{ width: '25%', height: '10px' }}></div>
                </div>
              </div>
              <div className='d-flex kpi-main-wrapper p-0 kpi-main-box-shadow'>
                <div className='kpi-box w_25per skeleton-animation w60px h52px'></div>
                <div className='kpi-box w_25per skeleton-animation w60px h52px'></div>
                <div className='kpi-box w_25per skeleton-animation w60px h52px'></div>
                <div className='kpi-box w_25per skeleton-animation w60px h52px'></div>
              </div>
            </div>
            <div className='border-bottom pb-0 pt-15'></div>
            <div className='align-items-center justify-content-between pb15px pt-15 pb-0 d-flex'>
              <div className='d-flex flex-column'>
                <div className='radius_3 skeleton-animation mb-15px' style={{ height: '8px', width: '90px' }}></div>
              <div className='d-flex align-items-center'>
              <div className='h32w32 rounded-5 skeleton-animation me-2'></div>
              <div className='h32w32 rounded-5 skeleton-animation me-2'></div>
              <div className='h32w32 rounded-5 skeleton-animation me-2'></div>
              </div>
              </div>
              <div className='d-flex flex-column'>
              <div className='radius_3 skeleton-animation mb-15px' style={{ height: '8px', width: '90px' }}></div>
              <div className='d-flex my-auto radius_3 skeleton-animation p-15 py-0' style={{ height: '6px', width: '258px' }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className='align-items-center d-flex mt-3'>
        <div className='radius_3 skeleton-animation w25per' style={{ height: '67px', marginRight: '15px' }}></div>
        <div className='radius_3 skeleton-animation w25per' style={{ height: '67px', marginRight: '15px' }}></div>
        </div>
      </div>
    </>
  )
}
export default DashboardSkeleton