import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";

const PdfSignatureTittle = ({ show, handleClose,handleSignatureChange }) => {


  return (
    <>
      <div className="">
        <Modal
          show={show}
          
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_signature")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative d-flex">
                  <Form.Check
                  className="me-20px"
                  type="radio"
                    rows={5}
                    name=""
                    value="company"
                    onChange={(e)=>{handleSignatureChange(e)}}
                    label={"Company Signature"}
                  ></Form.Check>
                  <Form.Check
                  type="radio"
                    rows={5}
                    name=""
                    value="agent"
                    onChange={(e)=>{handleSignatureChange(e)}}
                    label={"Agent Signature"}
                  ></Form.Check>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PdfSignatureTittle