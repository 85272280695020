import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { _l, showError } from "../../hooks/utilities";

const RemoveProviderConfirmation = ({ show, handleClose, handleConfirm, header, message }) => {

  const [inputValue, setInputValue] = useState("");

  const verifyText = () => {
    if(inputValue === _l("l_delete"))
    {
        handleConfirm();
    }
    else {
        showError("l_not_matched");
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{_l(header)}?</Modal.Title>
      </Modal.Header>
      <Modal.Body className=" pt-0">
        <div className="form-wrapper-main">
          <div className="row">
            <Form.Group className="col-12 c-input-box position-relative">
                <span className="mb-2">{_l(message)}</span>
              <div className="d-flex align-items-center justify-content-between w-100 ">
                <div className="d-flex align-items-center justify-content-between flex-grow-1">
                  <input
                    className="form-control"
                    placeholder={_l("l_delete")}
                    type="text"
                    name="confirmation"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
        </Button>
        <Button variant="primary" size="sm" onClick={verifyText}>
          {_l("l_delete")}{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveProviderConfirmation;
