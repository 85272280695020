import { LOGIN_AS_STAFF_CONTACT } from "./action-type";

/**
 *
 * @param {*} loginStatus : boolean
 * @returns
 * if admin login as staff or customer, pass the status true, false for other case
 */
export const adminLoginAsStaff = (loginStatus) => (dispatch) => {
  dispatch({
    type: LOGIN_AS_STAFF_CONTACT,
    payload: { loginStatus },
  });
};
