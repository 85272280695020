import React from 'react';
 

function NoImages(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="81" height="71" viewBox="0 0 81 71" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M74.516 30.4017C74.5635 39.5866 74.5525 55.339 74.5415 64.5239C74.5415 68.5396 73.0594 69.9998 69.0072 69.9998H37.8055C27.3404 69.9998 16.8753 69.9998 6.41023 69.9998C2.51868 69.9998 1 68.4921 1 64.5969C1 48.6 1 32.603 1 16.606C1 13.1051 2.58806 11.5317 6.15836 11.5317C18.8698 11.5317 35.2355 11.5098 47.9469 11.5317" strokeMiterlimit="10" />
				<path d="M73.0246 68.9744C66.9622 61.33 60.8973 53.6881 54.83 46.0486C53.9721 44.9534 53.399 45.4645 52.7054 46.1947C49.7654 49.2977 46.8133 52.3873 43.849 55.4635C41.958 57.4275 40.7459 57.3436 39.0593 55.146C35.1386 50.0351 31.2239 44.9242 27.3154 39.8134C26.9028 39.2731 26.4392 38.7766 25.8551 38.083L2.42188 68.9343" strokeMiterlimit="10" />
				<path d="M63.352 34.622C66.6853 34.6371 69.9478 33.661 72.725 31.8176C75.5022 29.9743 77.6688 27.3469 78.9493 24.2694C80.2299 21.1919 80.5666 17.8032 79.9168 14.5339C79.267 11.2646 77.6599 8.26217 75.2999 5.90828C72.9398 3.55438 69.9332 1.95523 66.6622 1.314C63.3912 0.672774 60.0034 1.01843 56.9293 2.30705C53.8551 3.59566 51.2335 5.76911 49.3975 8.55114C47.5614 11.3332 46.5938 14.5982 46.6176 17.9314C46.6445 22.3573 48.4176 26.5936 51.5514 29.7191C54.6851 32.8447 58.9261 34.6067 63.352 34.622Z" strokeMiterlimit="10" />
				<path d="M21.5712 26.6925C21.5673 25.9486 21.4167 25.2126 21.1278 24.527C20.8389 23.8414 20.4174 23.2196 19.8876 22.6973C19.3578 22.1749 18.7301 21.7624 18.0404 21.4832C17.3508 21.204 16.6128 21.0638 15.8689 21.0705C15.122 21.0799 14.3842 21.2362 13.6978 21.5307C13.0113 21.8252 12.3895 22.252 11.868 22.7868C11.3465 23.3215 10.9353 23.9538 10.6581 24.6474C10.3809 25.3411 10.2431 26.0825 10.2524 26.8294C10.2617 27.5763 10.4182 28.3141 10.7126 29.0006C11.0071 29.687 11.4339 30.3088 11.9686 30.8303C12.5034 31.3518 13.1357 31.7629 13.8293 32.0401C14.5229 32.3173 15.2644 32.4553 16.0113 32.4459C16.7551 32.4364 17.4896 32.2796 18.1725 31.9848C18.8554 31.6899 19.4732 31.2627 19.9902 30.7278C20.5071 30.1929 20.9129 29.5609 21.1843 28.8683C21.4556 28.1757 21.5871 27.4362 21.5712 26.6925Z" strokeMiterlimit="10" />
				<path d="M55.1504 9.58203L71.7096 26.0353" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M71.7096 9.58203L55.1504 26.0353" strokeLinecap="round" strokeLinejoin="round" />
			</svg>


		</React.Fragment>
	)
}

export default NoImages