import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopicSettingModal from "../components/Modals/TopicSettingModal";
import { _l } from "../hooks/utilities";

const EditProfile = () => {
  const navigate = useNavigate();

  const [settingModalData, setSettingModalData] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      setSettingModalData({
        main_key: "edit_profile",
        id: -1,
        main_key_title: _l("l_edit_profile"),
        title: "l_all",
      });
    }
  }, []);

  useEffect(() => {
    if(!settingModalData)
    {
      navigate(-1);
    }
  }, [settingModalData])
  

  return (
    <div>
      {settingModalData && Math.abs(settingModalData.id) > 0 ? (
        <TopicSettingModal
          data={{
            main_key: settingModalData.main_key,
            id: settingModalData.id,
            title: settingModalData.title,
            main_key_title: settingModalData.main_key_title,
          }}
          handleClose={(navigateToZero) => {
            if(window.opener)
            {
              window.close();
            }
            else {
              if (navigateToZero) {
                navigate(0);
              } else {
                navigate(-2);
              }
            }
            
          }}
          hideSearchArea={false}
          hideFilterArea={false}
          show={Math.abs(settingModalData.id) > 0}
          title={settingModalData.main_key_title}
          titlePrefix={""}
          project_id={0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default EditProfile;
