import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";

const CreateChecklistTemplate = ({ show, handleClose, handleSave, scheduleDetail , loder = false, riskTitle }) => {

  const dispatch = useDispatch();  
  const titleWrapper = riskTitle ? riskTitle : `#${scheduleDetail.benchmarkId}-${scheduleDetail.categoryTitle}`
  const [title, setTitle] = useState(titleWrapper);
  const validateInputFields = () => {
    let validationSuccess = true;
    let errMessage; 
    if (!title.trim()) {
      errMessage = _l("l_please_enter_template_title");
      validationSuccess = false 
    }

    if (!validationSuccess) {
      dispatch(showMessage("unsucess", _l("l_error"), `${errMessage}`));
    }
    return validationSuccess;
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_create_template")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_title")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_title")}`}
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  name=""
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" onClick={() => {
            if (validateInputFields()) {
              handleSave(title)
            }
          }}
          className={`${loder ? "for-disabled" : ""}`}>
          {loder ? _l("l_please_wait") : _l("l_save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateChecklistTemplate;
