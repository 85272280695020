import React, { useEffect, useState } from "react";
import OnboardingHeader from "../components/SpaceOnBoarding/OnboardingHeader";
import OnboardingFooter from "../components/SpaceOnBoarding/OnboardingFooter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { _l, showError, showSuccessMessage } from "../hooks/utilities";
import BenchmarkServices from "../services/benchmark-services";
import OperatorForm from "../components/RegistrationFlow/OperatorForm";
import moment from "moment";
import RectangleCheckIcon from "../assets/icons/RectangleCheckIcon";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmation, toastCloseAction } from "../actions/messages";
import { getBenchmarkDetail, getBenchmarkList } from "../actions/customer";
import AcceptProposalSignatureModal from "../components/Modals/AcceptProposalSignatureModal";
import benchmarkServices from "../services/benchmark-services";
import BuildingsIcon from "../assets/icons/BuildingsIcon";
const AgnentJobProposalOnboarding = () => {
    const {benchmarkList, benchmarkDetail } = useSelector(
        (state) => state.customer
      );
      let proposals =
      benchmarkDetail && benchmarkDetail.proposal_list && benchmarkDetail.proposal_list.length
        ? benchmarkDetail.proposal_list[0].proposals
        : [];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = searchParams.get("space");
  const jobId = searchParams.get("job");
  const proposalId = searchParams.get("proposal");
  const benchmarkId = searchParams.get("id");
  const client_id = localStorage.getItem("client_id");
  const [jobDetails, setJobDetails] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [benchmarkDetails, setBenchmarkDetails] = useState({});
  const [showSignaturePad, setShowSignaturePad] = useState(false);
const [contract, setContract] = useState()
  const jobProposalAction = (action) => {
    let messageText =
      action === "accept"
        ? "l_so_your_interest"
        : "l_confirm_reject_proposal";
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l(messageText),
        _l("l_yes"),
        _l("l_cancel"),
        () => {},
        async () => {
          try {
            setShowLoader(true);
            const response =
              await BenchmarkServices.agentAcceptRejectJobProposal(
                projectId,
                jobId,
                action === "accept" ? 3 : 2,
                proposalId
              );
            if (response.status) {
              navigate("/onboarding-proposal?tab=pending");
            } else {
              showError(response.message);
            }
          } catch (e) {
            console.error(e);
          } finally {
            dispatch(toastCloseAction());
            setShowLoader(false);
          }
        }
      )
    );
  };
  const getCurrentProposalDetail = () => {
    if (
      !jobDetails ||
      !jobDetails.proposal_detail ||
      !jobDetails.proposal_detail.find(
        (proposal) => proposal.proposal_id == proposalId
      )
    ) {
      return {};
    } else {
      return {
        ...jobDetails.proposal_detail.find(
          (proposal) => proposal.proposal_id == proposalId
        ),
      };
    }
  };
  useEffect(() => {
    if (
      !projectId ||
      !jobId ||
      isNaN(Number(projectId)) ||
      isNaN(Number(jobId))
    ) {
      navigate(-1);
    }
    const getJobInfo = async () => {
      try {
        setShowLoader(true);
        const response = await BenchmarkServices.getAgentJobDetail(
          projectId,
          jobId
        );
        if (response.status && response.data && response.data.length) {
          setJobDetails(response.data[0]);
        } else {
          showError(response.message);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setShowLoader(false);
      }
    };
    getJobInfo();
    dispatch(getBenchmarkList(0, 0, "", 0, 0, 0, 1, 10, 1, [], -1));
    dispatch(getBenchmarkDetail(benchmarkId, client_id));
  }, []);
  const handleAccept = (signature) =>{
    try {
    setShowLoader(true);
    benchmarkServices.acceptAgentContract(jobId,proposalId,signature).then((res)=>{
        if (res && res.status) {
            setShowSignaturePad(false);
            navigate("/onboarding-proposal?tab=accepted");
            showSuccessMessage(res.message);
            setShowLoader(false);
        }else{
            showError(res.message);
            setShowLoader(false);
        }
    })
    } catch (error) {
        console.error(error);
  }
  }
  useEffect(() => {
      if (benchmarkList && benchmarkList.length > 0) {
          let templist = benchmarkList.filter((data) => data.proposal_id == proposalId)
        if (templist.length > 0) {
            setBenchmarkDetails(templist[0])
        }
    }
  }, [benchmarkList])
  useEffect(() => {
    if (proposals && proposals.length > 0) {
      let list = proposals.filter((data) => data.id == proposalId)
      if (list && list.length > 0) {
        setContract(list[0].company_contract_url)
      }
    }
  }, [proposals])
  return (
    <div
      className={`mt-3 d-flex m-auto h-100 flex-column onboarding-with-bg`}
    >
        <React.Fragment>
          {jobDetails && Object.keys(jobDetails).length ? (
            <div className="p-20">
              <OnboardingHeader
                title={`# ${_l("l_job_proposal")} - ${jobId}`}
                backButtonText={"l_back"}
                nextButtonText={
                  benchmarkDetails.agent_status == 4  && benchmarkDetails.status == 4 ? "l_interested" : 
                  benchmarkDetails.agent_status == 3  && benchmarkDetails.status == 4 && benchmarkDetails.is_company_contract_upload == 1 && benchmarkDetails.is_agent_signature_upload == 0 ? `${_l("l_view_proposal")}` : ""
                }
                skipButtonText={
                  benchmarkDetails.agent_status == 4  && benchmarkDetails.status == 4 ? "l_not_interested" : getCurrentProposalDetail().agent_status == 4 ? "l_reject" : ""
                }
                backBtnHandler={() => navigate("/onboarding-proposal?tab=newProposal")}
                nextBtnHandler={() => 
                  benchmarkDetails.agent_status == 3  && benchmarkDetails.status == 4 && benchmarkDetails.is_company_contract_upload == 1 ?
                     setShowSignaturePad(true) 
                     : jobProposalAction("accept")
            }
                skipButtonHandler={() => jobProposalAction("reject")}
              />
              <div className="bg-white-03-solid top-0 mb-4 z-index-5">
                <div className="select-input-cox p-10-15 w-100 align-items-center d-flex mt-10 border">
                  <div className="check-image top-0 end-0 position-absolute h-auto w-auto bg-transparent">
                    <RectangleCheckIcon
                      size={20}
                      weight="fill"
                      className="c-icons color-green"
                    />
                  </div>
                  <div class="h30w30 d-flex main-image">
                    <BuildingsIcon
                      className="c-icons HW30 color-green"
                    />
                  </div>
                  <div class="w100minus100per text-truncate ps-3">
                    <div class="c-font fw-semibold text-truncate title-fonts">
                      {jobDetails.company_name}
                    </div>
                    <div class="c-font color-white-60 f-12 text-truncate">
                      {jobDetails.address}
                    </div>
                  </div>
                  <div className="align-items-center d-flex justify-content-between">
                    <div className="with_separator_10 text-center flex-grow-1 px-3 before-dnone">
                      <div className="c-font f-12 fw-semibold title-fonts">
                        {_l("l_start_date")}
                      </div>
                      <div className=" c-font color-white-60 f-12 mt-1">
                        {moment(jobDetails.start_date).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div className="with_separator_10 text-center flex-grow-1 px-3 before-dnone">
                      <div className="c-font f-12 fw-semibold title-fonts">
                        {_l("l_end_date")}
                      </div>
                      <div className="c-font color-white-60 f-12 mt-1">
                        {moment(jobDetails.end_date).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <OperatorForm
                agentView={true}
                contract={{}}
                contractAttachments={jobDetails.attachments.map((contract) => {
                  return {
                    name: contract.file_name,
                    type: "",
                    url: contract.file_url,
                  };
                })}
                formData={{
                  ...jobDetails,
                  mission_address: jobDetails.postal_address,
                  service_start_date: moment(jobDetails.start_date).format(
                    "YYYY-MM-DD"
                  ),
                  service_end_date: moment(jobDetails.end_date).format(
                    "YYYY-MM-DD"
                  ),
                  unknown_end_date: Number(jobDetails.is_empty_end_date),
                  slots: jobDetails.details.map((slotInfo) => {
                    return {
                      ...slotInfo,
                      start_time: new Date(
                        `${moment().format("YYYY-MM-DD")} ${
                          slotInfo.start_time
                        }`
                      ),
                      end_time: new Date(
                        `${moment().format("YYYY-MM-DD")} ${slotInfo.end_time}`
                      ),
                      rate:
                        !isNaN(Number(slotInfo.hourly_rate)) &&
                        Number(slotInfo.hourly_rate)
                          ? Number(slotInfo.hourly_rate)
                          : 0,
                      slots: {
                        mon: slotInfo.mon > 0,
                        tue: slotInfo.tue > 0,
                        wed: slotInfo.wed > 0,
                        thu: slotInfo.thu > 0,
                        fri: slotInfo.fri > 0,
                        sat: slotInfo.sat > 0,
                        sun: slotInfo.sun > 0,
                      },
                    };
                  }),
                  contract_description: jobDetails.description,
                }}
                setContract={() => {}}
                setContractAttachments={() => {}}
                setFormData={setJobDetails}
              />
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      <OnboardingFooter />
      {showSignaturePad ? (
        <AcceptProposalSignatureModal
          show={showSignaturePad}
          external={true}
          projectId={0}
          hash={""}
          handleClose={() => {
            setShowSignaturePad(false);
          }}
          callBack={(signature) => {
            handleAccept(signature)
          }}
          loder={showLoader}
          contract={contract}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default AgnentJobProposalOnboarding;
