import React from 'react';
 

function NoFutureSubscription(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="98" height="94" viewBox="0 0 98 94" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M60.1014 78.9634H52.195C36.2521 78.9634 20.3142 78.9334 4.37135 78.9884C2.02092 78.9884 1.0057 78.2583 1.0157 75.7628C1.08571 57.7596 1.48081 37.4709 1.4158 19.4826V18.5574C28.8408 18.6124 62.3569 18.2324 89.7818 18.1724L89.8169 18.3224C89.7319 28.7393 89.8169 49.5331 89.7419 59.955" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M59.6857 74.3875C59.6711 78.0489 60.741 81.6325 62.7605 84.6866C64.78 87.7407 67.6586 90.1284 71.0334 91.5485C74.4081 92.9687 78.1278 93.3577 81.7233 92.6666C85.3189 91.9754 88.6293 90.235 91.237 87.6649C93.8447 85.0948 95.633 81.81 96.3763 78.2249C97.1196 74.6397 96.7846 70.9148 95.4136 67.5198C94.0426 64.1248 91.6969 61.2118 88.6725 59.1481C85.6481 57.0845 82.0803 55.9627 78.4191 55.9241C75.9691 55.8781 73.5346 56.3223 71.2587 57.2305C68.9828 58.1387 66.9113 59.4926 65.1661 61.2127C63.4208 62.9328 62.037 64.9843 61.0958 67.2468C60.1547 69.5093 59.6753 71.9371 59.6857 74.3875Z" strokeMiterlimit="10" />
				<path d="M4.78516 31.8003H85.9199V25.144H4.78516V31.8003Z" strokeMiterlimit="10" />
				<path d="M4.74609 40.9217H14.9879V36.0908H4.74609V40.9217Z" strokeMiterlimit="10" />
				<path d="M1 15.1121C1 12.7567 1 10.6663 1 8.57589C1.05501 3.54498 3.00041 1.49961 7.9213 1.48961C22.624 1.46961 37.325 1.46961 52.0243 1.48961C62.3062 1.48961 72.5881 1.48961 82.8699 1.48961C87.5458 1.48961 89.6461 3.59998 89.7312 8.33584C89.7762 10.5162 89.7312 12.6966 89.7312 15.0921L1 15.1121Z" strokeMiterlimit="10" />
				<path d="M70.332 75.273C71.9423 76.6682 73.3026 78.3635 74.8929 80.0788C78.8486 76.1081 82.4593 72.4575 86.1099 68.8418" strokeLinecap="round" strokeLinejoin="round" />
			</svg>

		</React.Fragment>
	)
}

export default NoFutureSubscription