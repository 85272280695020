import React, { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import BulkActionsModal from "../Modals/BulkActionsModal";
import LeadCard from "./LeadCard";
import { useDispatch, useSelector } from "react-redux";
import {
  leadModalToggle,
  leftPanelFilters,
  projectTaskCounts,
} from "../../actions/customer";
import {
  checkedBulkLead,
  getLeadDetail,
  getLeadList,
  getLeadNotes,
  setMyrLeadSources,
  setMyrStaffList,
} from "../../actions/leads-actions/leads-actions";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { _l, showLeftPanelNew } from "../../hooks/utilities";
import LeadIcon from "../../assets/icons/LeadIcon";
import { Form } from "react-bootstrap";
import LeadCustomCalendar from "./LeadCustomCalendar";
import XIcon from "../../assets/icons/XIcon";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";

const MyLeadsList = ({ projectId }) => {
  const [show, setShow] = useState(false);
  const [leads, setLeads] = useState([]);
  const [pageNumber, setPageNumber] = useState({
    0: 1,
    1: 1,
    2: 1,
    3: 1,
    4: 1,
  });
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(0);
  const [leadDetails, setLeadDetails] = useState({
    tabChangeHandler: handleLeadCanvasTabChange,
    lead_id: 0,
    lead_profile: {},
    lead_contact: {},
    lead_attachments: {},
    lead_call_logs: {},
    lead_tracked_emails: {},
    lead_views_tracking: {},
  });
  const [latestSelectedTab, setLatestSelectedTab] = useState("lead_profile");
  const [leadCounts, setLeadCounts] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const [creatorDetails, setcreatorDetails] = useState({});
  const [myrStaff, setMyrStaff] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [selectedEventDate, setselectedEventDate] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const checkBox = {
    client: false,
    notInterested: false,
    lead: false,
    ongoing: false,
    interested: false,
  }
  const [bulkActionAllLeads, setBulkActionAllLeads] = useState(checkBox)

  const selectStartDate  = localStorage.getItem("fromDate");

  const dispatch = useDispatch();
  const {
    taskFilters,
    activeTaskStatusFilters,
    globalSearchString,
    globalView,
    isLeadOpen
  } = useSelector((state) => state.customer);
  const {
    leads: leadsData,
    leadDetails: leadDetailsData,
    totalCounts,
    myrStaffList,
    myrLeadSources,
    leadsFilters,
    leadsBulkActionToggle
  } = useSelector((state) => state.leadsReducer);

  const leadStatuses = [
    {
      id: "1",
      statusText: _l("l_client_kpi"),
      eventKey: "1",
      statusClass: "to-do",
      statusdots: "in-progress-white",
      title : "client"
    },
    {
      id: "3",
      statusText: _l("l_not_interested_kpi"),
      eventKey: "2",
      statusClass: "not-started",
      statusdots: "color-orange",
      title : "notInterested"
    },
    {
      id: "16",
      statusText: _l("l_lead_kpi"),
      eventKey: "3",
      statusClass: "done",
      statusdots: "color-green",
      title : "lead"
    },
    {
      id: "28",
      statusText: _l("l_ongoing_kpi"),
      eventKey: "4",
      statusClass: "ongoing",
      statusdots: "color-yellow",
      title : "ongoing"
    },
    {
      id: "30",
      statusText: _l("l_interested_kpi"),
      eventKey: "5",
      statusClass: "in-progress",
      statusdots: "in-progress-blue",
      title : "interested"
    },
  ];

  let checkedLeads = useRef([]);
  let selectedCheckbox = useRef([])
  const [defaultAccordianOpened, setDefaultAccordianOpened] = useState([]);

  useEffect(() => {
    /**Inital leads loading */
    dispatch(getLeadList(0, 1, taskFilters));
    setPageLoadFlag(true);
    dispatch(setMyrLeadSources());
    dispatch(setMyrStaffList());
    checkedLeads.current = [];
    dispatch(checkedBulkLead(checkedLeads.current));
  }, []);

  useEffect(() => {
    leadListFilter();
    checkedLeads.current = [];
    dispatch(checkedBulkLead(checkedLeads.current));
    selectedCheckbox.current = [];
    setBulkActionAllLeads(checkBox)
  }, [JSON.stringify(taskFilters), JSON.stringify(activeTaskStatusFilters), JSON.stringify(leadsFilters)]);

  useEffect(() => {
    leadListFilter();
    checkedLeads.current = [];
    dispatch(checkedBulkLead(checkedLeads.current));
    selectedCheckbox.current =[];
    setBulkActionAllLeads(checkBox)
  }, [localStorage.getItem("selectedDate")]);

  useEffect(() => {
    setLeads([]);
    setLeadCounts({
      4 :0,
      3 :0,
      2: 0,
      1: 0,
      0: 0,
    });
    if (leadsData && leadsData.length) {
      setLeads(
        leadsData.filter(
          (lbs) =>
            lbs.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
            lbs.company
              .toLowerCase()
              .includes(globalSearchString.toLowerCase()) ||
            (lbs.company_id != null ? lbs.company_id : "")
              .toLowerCase()
              .includes(globalSearchString.toLowerCase()) ||
            (lbs.appointment_date != null ? lbs.appointment_date : "")
              .toLowerCase()
              .includes(globalSearchString.toLowerCase())
        )
      );
      setLeadCounts({
        4: totalCounts.status30 || 0,
        3: totalCounts.status28 || 0,
        2: totalCounts.status16 || 0,
        1: totalCounts.status3 || 0,
        0: totalCounts.status1 || 0,
      });

      if(totalCounts.status1 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "1"]);
      }

      if(totalCounts.status3 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "2"]);
      }

      if(totalCounts.status16 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "3"]);
      }

      if(totalCounts.status28 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "4"]);
      }

      if(totalCounts.status30 > 0) {
        setDefaultAccordianOpened(defaultAccordianOpened => [...defaultAccordianOpened, "5"]);
      }
    }
  }, [JSON.stringify(leadsData), globalSearchString]);

  useEffect(() => {
    if (leadDetailsData && leadDetailsData.lead_id > 0) {
      setLeadDetails({ ...leadDetails, ...leadDetailsData });
      setSelectedLeadId(leadDetailsData.lead_id);
      if (!showDocumentCanvas) {
        setShowDocumentCanvas(true);
      }
    }
  }, [JSON.stringify(leadDetailsData)]);

  useEffect(() => {
    let creator = leads.filter((l) => l.id == leadDetails.lead_id);
    setcreatorDetails(
      creator.length && creator[0].creator_details
        ? creator[0].creator_details
        : {}
    );
  }, [leadDetails]);

  useEffect(() => {
    if (myrStaffList.length) {
      const formattedResponse = myrStaffList.map((staff) => {
        return {
          value: staff.staffid,
          label: staff.fullname,
          role: 1,
          profile_image: staff.profile_image_url,
        };
      });
      setMyrStaff(formattedResponse);
    }
    if (myrLeadSources.length) {
      const formattedResponse = myrLeadSources.map((source) => {
        return {
          value: source.id,
          label: source.name,
        };
      });
      setLeadSources(formattedResponse);
    }
  }, [myrLeadSources, myrStaffList])


  const handleLeadCanvasToggle = (lead) => {
    setShowDocumentCanvas(true);
    dispatch(getLeadDetail(lead.id, latestSelectedTab));
  };

  function handleLeadCanvasTabChange(leadId, tab) {
    if (tab) {
      if (tab == "notesandreminders") {
        dispatch(getLeadNotes(leadId));
      } else {
        dispatch(getLeadDetail(leadId, tab));
      }
      setLatestSelectedTab(tab);
    }
  }

  const leadListFilter = () => {
    if (taskFilters && taskFilters.length && pageLoadFlag) {
      setLeftActivePanelFilters(taskFilters);
      if (projectId > 0) {
        setPageNumber({
          0: 1,
          1: 1,
          2: 1,
          3: 1,
          4: 1,
        });
        dispatch(
          getLeadList(
            activeTaskStatusFilters ? activeTaskStatusFilters : [],
            1,
            taskFilters,
            leadsFilters,
            globalSearchString
          )
        );
      }
    } else if (taskFilters && !taskFilters.length && pageLoadFlag) {
      setPageNumber({
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
      });
      setLeftActivePanelFilters([]);
      dispatch(
        getLeadList(
          activeTaskStatusFilters ? activeTaskStatusFilters : [],
          1,
          taskFilters
        )
      );
    }
  };

  const handleViewMore = (status) => {
    setPageNumber({ ...pageNumber, [status]: pageNumber[status] + 1 });
    //lead status convert to task status
    dispatch(
      getLeadList(
        [
          leadStatuses[status].id == 1
            ? 1
            : leadStatuses[status].id == 3
              ? 3
              : leadStatuses[status].id == 16
                ? 16
                : leadStatuses[status].id == 28
                  ? 28
                  : 30,
        ],
        pageNumber[status] + 1,
        taskFilters,
        leadsFilters,
        globalSearchString
      )
    );
  };

  const leadCheckUncheck = (lead, all = "") => { 
    if (all == "all") {
      lead.forEach((item) => {
        const leadPosition = checkedLeads.current.findIndex(x => x.id == item.id)
        if (leadPosition === -1) {
          checkedLeads.current.push(item);
          selectedCheckbox.current.push(item.id)
        }
        else {
          checkedLeads.current.splice(leadPosition, 1);
          selectedCheckbox.current.splice(leadPosition, 1)
        }
      })
    }else{
      const leadPosition = checkedLeads.current.findIndex(x => x.id == lead.id)
      if (leadPosition === -1) {
        checkedLeads.current.push(lead);
        selectedCheckbox.current.push(lead.id)
      }
      else {
        checkedLeads.current.splice(leadPosition, 1);
        selectedCheckbox.current.splice(leadPosition, 1)
      }
    }
    dispatch(checkedBulkLead(checkedLeads.current));
  }

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleAccordianExpansion = (key) => {
    let activeKeysArray = defaultAccordianOpened.slice();

    if(activeKeysArray.includes(key))
    {
      activeKeysArray = activeKeysArray.filter((x) => x!= key)
    }
    else {
      activeKeysArray = activeKeysArray.concat(key);
    }
    setDefaultAccordianOpened(activeKeysArray.slice());
  }

  useEffect(() => {
    setShowDocumentCanvas(false);
    showLeftPanelNew();
  }, []);

  useEffect(() => {
    if (!isLeadOpen) {
      setLatestSelectedTab("lead_profile");
    }
  }, [isLeadOpen])
  

  useEffect(() => {
    dispatch(
      getLeadList(
        activeTaskStatusFilters ? activeTaskStatusFilters : [],
        1,
        taskFilters,
        leadsFilters,
        globalSearchString
      )
    );
  }, [globalSearchString, leadsBulkActionToggle])
  

  return (
    <>
     
        <React.Fragment>
          <div className="pt-10px  "></div>
           <div className={`${["liteVersionView", "listView"].includes(globalView) ? 'daily-schedule-main w-800px' : ''} h-100 my-0 overflow-scroll  with_checkbox_absolute`}>
          {["liteVersionView", "listView"].includes(globalView) ? 
          <div
            className={`comman-main-box custom-accordian-main res-padding-right0 mt-0 h-100 task-list-accordian ${showDocumentCanvas ? "list-view-toggle" : ""
              } `}
          >
            <Accordion
              activeKey={defaultAccordianOpened}
              onSelect={(eventKey) => {
                handleAccordianExpansion(eventKey);
              }}
              className="task-list-accordian-main mt-0"
            >
              <div className="pill-wrapper top_8px">
              <div className="pill-wrappe-modal-overlay"></div>
                <div id="" className="">
                  <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end d-none">
                    {leftPanelActiveFilters.length ? (
                      leftPanelActiveFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                          let displayFilterKey = value.title;
                          return (
                            <div
                              key={index}
                              className="badge  c-font f-12 w-auto color-white-80 bg-white-05  radius_3 me-2 comman-round-box active d-none"
                            >
                              <div className="d-flex align-items-center">
                                <span className="color-white">
                                  {displayFilterKey}
                                </span>
                                <a href="#/"
                                  className="ps-1"
                                  onClick={() => {
                                    dispatch(
                                      leftPanelFilters({
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                        main_sub_key: value.main_sub_key,
                                      })
                                    );
                                    dispatch(
                                      projectTaskCounts(
                                        projectId,
                                        taskFilters,
                                        {
                                          main_key: value.main_key,
                                          filter_key: value.filter_key,
                                          title: value.title,
                                        },
                                        1
                                      )
                                    );
                                  }}
                                >
                                  <XIcon className="HW14" />
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                    <a href="#/"
                      className="btn btn-white-10 btn-sm me-2 border-0"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      {_l("l_bulk_action")}
                    </a>
                  </div>
                </div>
              </div>
              {leadStatuses.map((status, index) => {
                const leadByStatus = leads.filter((x) => x.status == status.id);
                return (
                  <Accordion.Item
                    eventKey={status.eventKey}
                    key={index}
                    className="with-pb-15"
                  >
                     <div
                      className={`${status.statusClass}    accordian-header-box d-flex align-items-center justify-content-between `}
                    >
                      <div className="modal-overlay3"></div>
                        <div className="checkbox_accordian">
                          {leadCounts[index] > 0 ? 
                          <span className=" checkbox_absolute" >
                            <Form.Check type="checkbox" 
                            checked={bulkActionAllLeads[status.title] ? true : false} 
                            onChange={(e) => {
                              leadCheckUncheck(leadByStatus, "all")
                              setBulkActionAllLeads({ ...bulkActionAllLeads, [status.title]: e.target.checked })
                            }} />
                          </span> 
                          : <></>}
                        <Accordion.Header className={`${status.statusClass} active  commanTaskCount w-auto accordion-header`}>
                        <div className="task-title left-task-header fw-semibold title-fonts c-font f-13">
                        <span className={`${status.statusdots}  c-dots  c-7 rounded-circle me-3`}></span>
                        <span className="me-2">{status.statusText}</span>
                        <span>
                          {" "}
                          {leadCounts ? "[" + leadCounts[index] + "]" : "[0]"}
                        </span>
                      </div>
                        </Accordion.Header>
                      </div>
                      
                    </div>
                    <Accordion.Body>
                      {leadByStatus.length > 0 ? (
                        <React.Fragment>
                          {leadByStatus.map((lead, index2) => {
                            return (
                              <LeadCard
                                key={lead.id}
                                data={lead}
                                hideShow={() => {
                                  handleLeadCanvasToggle(lead);
                                  dispatch(leadModalToggle(true));
                                }}
                                selectedLeadId={selectedLeadId}
                                leadCheckUncheck={leadCheckUncheck}
                                selectedCheckbox ={selectedCheckbox.current}
                              />
                            );
                          })}
                          {leadCounts[index] > leadByStatus.length ? (
                            <a href="#/"
                              className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn mb-2"
                              onClick={() => {
                                handleViewMore(index);
                              }}
                            >
                              <span className="title-fonts d-flex align-item-center justify-content-center">
                                <div className="d-flex align-item-center justify-content-center">
                                  {_l("l_view_more")} (
                                  {leadCounts[index] - leadByStatus.length})
                                  <CaretDoubleDownIcon className="HW14 ms-2" />
                                  <span
                                    role="status"
                                    aria-hidden="true"
                                    className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                  ></span>
                                </div>
                              </span>
                            </a>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ) : (
                        <div className="comman-placeholder-wrapper mt-4 w-100">
                          <div className="d-flex flex-column align-items-center m-auto">
                            <LeadIcon className="HW50 opacity-50" />
                            <span className="color-white-80 py-2 title-fonts">
                              {_l("l_no_leads")}
                            </span>
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
          : globalView == "calendarView" ?
          <LeadCustomCalendar
            pageName="calendar"
            setselectedEventDate={setselectedEventDate}
            selectStartDate={selectStartDate}
            setDashboardStartDate={setstartDate}
            setDashboardEndDate={setEndDate}
          />
          : globalView == "spaceView" ? <></> :
          <>
          <div
          id="kanbanView"
          className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian px-0 ${globalView == "kanbanView" ? "pb-0 kanban-view overflow-hidden horizontal-scroll":""}`} >
            <Accordion
            className="task-list-accordian-main with_checkbox_absolute mt-0"
            onSelect={(eventKey) => {
              handleAccordianExpansion(eventKey);
            }}
            activeKey={defaultAccordianOpened}
          > {leadStatuses.map((status, index) => {
            const leadByStatus = leads.filter((x) => x.status == status.id);
            return (
            <Accordion.Item eventKey={status.eventKey} key={index} className="with-pb-15">
              <div className="accordian-header-box d-flex align-items-center justify-content-between">
              <div className="modal-overlay3"></div>
                <div className="checkbox_accordian">
                  {leadCounts[index] > 0 ? 
                  <span className=" checkbox_absolute" >
                    <Form.Check type="checkbox" 
                    checked={bulkActionAllLeads[status.title] ? true : false} 
                    onChange={(e) => {
                      leadCheckUncheck(leadByStatus, "all")
                      setBulkActionAllLeads({ ...bulkActionAllLeads, [status.title]: e.target.checked })
                    }} />
                  </span> 
                  : <></>}
                <Accordion.Header
                  className={`active ${status.statusClass} commanTaskCount w-auto`}
                  id={_l(status.statusText)}
                >
                  <div className="accordion-header-wrapper w-auto ">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="left-task-header">
                        <div className="d-flex align-items-center  fw-semibold title-fonts c-font f-13">
                          <span className={`${status.statusdots} c-dots  c-7 rounded-circle me-3`}></span>
                          <span className="task-title me-2">{_l(status.statusText)}</span>
                          <span className="" id={_l(status.statusText)}>
                            {leadCounts ? "[" + leadCounts[index] + "]" : "[0]"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                </div>

              </div>
              <Accordion.Body className="taskCountToggle" id={_l(status.statusText)}> 
              {leadByStatus.length > 0 ? (
                <React.Fragment>
                  {leadByStatus.map((lead, index2) => {
                    return (
                      <LeadCard
                        key={lead.id}
                        data={lead}
                        hideShow={() => {
                          handleLeadCanvasToggle(lead);
                          dispatch(leadModalToggle(true));
                        }}
                        selectedLeadId={selectedLeadId}
                        leadCheckUncheck={leadCheckUncheck}
                        iskanbanView = {true}
                        selectedCheckbox ={selectedCheckbox.current}
                      />
                    );
                  })}
                  {leadCounts[index] > leadByStatus.length ? (
                    <a href="#/"
                      className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn mb-2"
                      onClick={() => {
                        handleViewMore(index);
                      }}
                    >
                      <span className="title-fonts d-flex align-item-center justify-content-center">
                        <div className="d-flex align-item-center justify-content-center">
                          {_l("l_view_more")} (
                          {leadCounts[index] - leadByStatus.length})
                          <CaretDoubleDownIcon className="HW14 ms-2" />
                          <span
                            role="status"
                            aria-hidden="true"
                            className="ms-2 opacity-0 spinner-border spinner-border-sm"
                          ></span>
                        </div>
                      </span>
                    </a>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ) : (
                <div className="comman-placeholder-wrapper mt-4 w-100">
                  <div className="d-flex flex-column align-items-center m-auto">
                    <LeadIcon className="HW50 opacity-50" />
                    <span className="color-white-80 py-2 title-fonts">
                      {_l("l_no_leads")}
                    </span>
                  </div>
                </div>
              )}
              </Accordion.Body>
              
            </Accordion.Item>
            );
          })}
          </Accordion >
          </div>
          </> 
        }
          </div>
          {show ? 
          <BulkActionsModal
            show={show}
            leads={checkedLeads}
            staffList={myrStaff}
            leadSources={leadSources}
            handleClose={handleClose}
            handleShow={handleShow}
          />
          :<></>}
          {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                dispatch(getLeadDetail("", "", 1));
                dispatch(leadModalToggle(false));
              }}
              data={leadDetails}
              docType={"lead"}
              setSelectedId={() => { }}
              creatorDetails={creatorDetails}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
     
    </>
  );
};
export default MyLeadsList;
