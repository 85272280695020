import React from 'react';
 

function CheckSquareIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M3.39062 2C2.83834 2 2.39062 2.44772 2.39062 3V13C2.39062 13.5523 2.83834 14 3.39062 14H13.3906C13.9429 14 14.3906 13.5523 14.3906 13V3C14.3906 2.44772 13.9429 2 13.3906 2H3.39062ZM3.39062 3H13.3906V13H3.39062V3ZM11.4858 6.86178C11.6856 6.67116 11.693 6.35467 11.5024 6.15486C11.3118 5.95506 10.9953 5.94761 10.7955 6.13822L7.47217 9.30868L5.98607 7.88852C5.78643 7.69774 5.46993 7.70492 5.27914 7.90456C5.08836 8.1042 5.09554 8.4207 5.29518 8.61148L7.12643 10.3615C7.31958 10.5461 7.62371 10.5462 7.81701 10.3618L11.4858 6.86178Z" />
</svg>

		</React.Fragment>
	)
}

export default CheckSquareIcon