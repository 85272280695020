import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";
import GoogleCalendar from "../../services/google-calendar";
import { useDispatch } from "react-redux";
import Toasts from "../Toasts/Toasts";
import { showMessage } from "../../actions/messages";
import { is_googlecalendar_connected } from "../../actions/googlecalendar_auth";
import outlookCalendar from "../../services/outlook-calendar";
import { is_calendar_connected } from "../../actions/outlook_auth";
import $ from "jquery";
import CustomOutlookCalendarIcon from "../../assets/icons/CustomOutlookCalendarIcon";
import CustomGoogleCalendarIcon from "../../assets/icons/CustomGoogleCalendarIcon";

const CalendarModal = ({ show, handleClose }) => {

  const dispatch = useDispatch();
  const [toastClass, setToastClass] = useState("unsucess");
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [validationFalse, setValidationFalse] = useState(false);
  const [calendar_type, setRadioButton] = useState(0);
  const projectId = localStorage.getItem("selectedOffice");

  const calendar_type_check = localStorage.getItem('calendar_type');
  const [isAllowAllSpaces,setIsAllowAllSpaces] = useState(false);
  const [allow,setAllow] = useState(0);

  window.addEventListener("handleClose", () => {
    localStorage.setItem('calendar_type', 2);
    handleClose();
  });

  window.addEventListener("outlook_calendar", () => {
    localStorage.setItem('calendar_type', 1);
    handleClose();
    dispatch(showMessage('sucess',_l('l_success'), _l('l_authorization_sucessfully')));
  });

  useEffect(() => {
    dispatch(is_googlecalendar_connected(projectId));
  }, [projectId]);
  useEffect(() => {
    if(isAllowAllSpaces == true)
    {
      setAllow(1);
    } else {
      setAllow(0)
    }
  }, [isAllowAllSpaces])

   useEffect(() => {
    outlookCalendar.checkStatus(projectId).then((res) => {
      if (res.status == 1 && res.data.is_active == 1) {
        localStorage.setItem("calendar_type", 1);
        setRadioButton(1);
      } else {
        localStorage.removeItem("calendar_type");
        setRadioButton(0);
      }
    });
  }, [projectId]);
  window.addEventListener('revoke', () => {
  })

  
    useEffect(() => {
   
      if(calendar_type_check==2){
        setRadioButton(2);
        $('#outlook_calendar').attr("disabled", "disabled");
      }

    }, [calendar_type]);
    useEffect(() => {
      if(calendar_type_check==1){
        setRadioButton(1);
        $('#google_calendar').attr("disabled", "disabled");
      } else {
        dispatch(is_calendar_connected(projectId));
        setRadioButton(0);
      }
    }, [calendar_type_check == 1]);

  return (
    <>
      <div>
          <Modal
            show={show}
            onHide={handleClose}
            className="custom-modal-style"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
          >
          <Modal.Header closeButton>
            <div className="d-flex align-items-center justify-content-between flex-grow-1">
              <Modal.Title>{_l('l_calendar_modal')}</Modal.Title>
              <div className="d-flex">
                <div class="form-check with_separator_10">
                <input id="isAllowAllSpaces" className="form-check-input" type="checkbox" checked={isAllowAllSpaces == true} value={isAllowAllSpaces == true ?1:0} onChange={() => { setIsAllowAllSpaces(!isAllowAllSpaces); }} />
                <label class="form-check-label" for="flexCheckDefault">{_l("l_is_allow_for_all_spaces")}</label>
              </div>
              <div className="with_separator_10 p-0"></div>
              </div>
          </div>
          </Modal.Header>
          <Modal.Body className="pt-3">
            <a href="#/" className={`platform-card task-list-card list-view p-4`}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                    <CustomOutlookCalendarIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l('l_outlook_calendar')}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input  id="outlook_calendar" className="absolute-input" type="radio" name="calendartype" value={calendar_type} checked={calendar_type_check == 1 || calendar_type_check != undefined || calendar_type == 1 ? true :false} onChange={() => { setRadioButton(1); }} />
                      {_l('l_outlook_calendar')}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </a>
            <a href="#/" className={`platform-card task-list-card list-view p-4`}>
                <div className="d-flex justify-content-between">
                  <div className="h30w30">
                    <CustomGoogleCalendarIcon />
                  </div>
                  <div className="w100minus30 ps-4">
                    <div className="title-fonts fw-semibold">{_l('l_google_calendar')}</div>
                    <div className="c-font f-12 color-white-60">{_l("l_gmail_subtext")}</div>
                    <div className="card-btn-wrapper mt-4">
                      <div className="d-flex align-items-center">
                      <Button size="sm" variant="primary" className="position-relative">
                      <input id="google_calendar" className="absolute-input" type="radio" name="calendartype" checked={calendar_type === 2 } value={calendar_type} onChange={() => { setRadioButton(2); }} />
                      {_l('l_google_calendar')}
                      </Button>
                      </div>
                    </div>  
                  </div>
                </div>
            </a>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end pt-0">
          
            <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
            </Button>
          
            { calendar_type_check != 2 && (calendar_type === 2 &&(
                <Button size="sm" onClick={() => { 
                  if(calendar_type === 2)
                  {

                    GoogleCalendar.createAuthUrl().then((res) => {
                          if (res.status == 1) {
                       
                            var left = (window.screen.width - 600) /2;
                            var top = (window.screen.height - 600) / 4;
                            window.open(res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);

                          }
                        });
                      }
                  
                 }} variant={`${calendar_type_check == 2?'danger':'primary'} ${calendar_type ? '' : 'disabled'}`}>{calendar_type_check == 2 ?"Revoke Google Calendar":"Get Google Calendar"}</Button>
              ) )

              } 

            {calendar_type === 1 && (<Button size="sm" onClick={() => { 
              if(calendar_type === 1)
              {
                  if(calendar_type_check == 1)
                  {
                    outlookCalendar.revoke(projectId, allow).then((res) => {
                      if(res.status ==1)
                      {
                        dispatch(is_calendar_connected(projectId));
                        localStorage.removeItem('revoke');
                        localStorage.removeItem('calendar_type');
                        localStorage.setItem('assign_contact', JSON.stringify([]));
                        window.dispatchEvent(new Event('assign_contact'));
                      } else if(res.status == 0 && res.message == "user not found.....!")
                      {
                        dispatch(is_calendar_connected(projectId));
                        localStorage.removeItem('revoke');
                        localStorage.removeItem('calendar_type');
                        localStorage.setItem('assign_contact', JSON.stringify([]));
                        window.dispatchEvent(new Event('assign_contact'));
                      }
                    })
                  } else {
                    outlookCalendar.oauthUrl('outlook_calendar',"", projectId,allow).then((res) => {
                      if (res.message == "oauth_url") {
                        localStorage.removeItem('calendar_type');
                        var left = (window.screen.width - 600) /2;
                        var top = (window.screen.height - 600) / 4;
                        window.open( res.data, "center window", 'resizable = yes, width=600, height=600, top='+ top + ', left=' + left);
                      }
                    });
                  }
                  
              }
              
             }} variant={`${calendar_type_check == 1?'danger':'primary'} ${calendar_type ? '' : 'disabled'}`}>{calendar_type_check == 1 ?"Revoke Outlook Calendar":"Get Outlook Calendar"}</Button>)}
          </Modal.Footer>
        </Modal>
      </div>
      {validationFalse ? (
        <Toasts
          message={message}
          header={header}
          toastClass={toastClass}
          delay={10000}
          handler={() => {
            setValidationFalse(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CalendarModal;
