import React from 'react'
import { _l } from '../../hooks/utilities';
const DocumentsDetail = ({ data }) => {
    return (
        <>
            <div className="comman-data-table">
                <table className="dataTable">
                    <thead className="bg-transparent p-0">
                        <tr className="p-0">
                            <th style={{width:'15%'}} className="p-0" ></th>
                            <th style={{width:'10px'}} className="p-0" ></th>
                            <th className="p-0"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className=" pt-0">{_l("l_document_type")}</td>
                            <td className=' pt-0'>:</td>
                            <td className=" pt-0">{data.fileExtension}</td>
                        </tr>
                        <tr>
                            <td>{_l("l_document_size")}</td>
                            <td>:</td>
                            <td>{Math.floor(parseInt(data.size) / 1000)} KB </td>
                        </tr>
                        <tr>
                            <td >{_l("l_document_storage")}</td>
                            <td >:</td>
                            <td>{Math.floor(parseInt(data.size) / 1000)} KB </td>
                        </tr>
                        <tr>
                            <td>{_l("l_document_owner")}</td>
                            <td>:</td>
                            <td>{data.provider == 'one_drive' ? data.task_creator_name : (data.owners && data.owners.length ) ? data.owners[0].displayName : ""}</td>
                        </tr>
                        <tr>
                            <td>{_l("l_document_modified")}</td>
                            <td>:</td>
                            <td>{(new Date(data.modifiedTime)).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}  { data.lastModifyingUser ? `By ${data.lastModifyingUser.displayName}` : ``}
                            </td>
                        </tr>
                        <tr>
                            <td>{_l("l_document_opened")}</td>
                            <td >:</td>
                            <td>{(new Date(data.viewedByMeTime)).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            })} {data.lastViewedOrModifiedBy ? data.lastViewedOrModifiedBy : 'By Me'}</td>
                        </tr>
                        <tr>
                            <td>{_l("l_document_created")}</td>
                            <td>:</td>
                            <td>{(new Date(data.createdTime)).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            })} By {data.provider == 'one_drive' ? data.task_creator_name : data&& data.owners && data.owners.length ? data.owners[0].displayName : "" }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
export default DocumentsDetail