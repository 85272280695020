import { FETCH_AUTH_TOKEN } from "./chat-action-type";
import ChatAuthService from "../services/chat-auth-services";

export const fetchAuthToken = (email, myrUserId, myrUserType) => (dispatch) => {
  const staffOrContactId =
    localStorage.getItem("user_type") == "contact"
      ? localStorage.getItem("contact_id")
      : localStorage.getItem("staff_id");
  let user_type = localStorage.getItem("user_type");
  user_type = user_type === "contact" ? "client" : "staff";

  return ChatAuthService.fetchAuthToken(
    email,
    staffOrContactId,
    user_type
  ).then((data) => {
    let id = data && data.result && data.result._id
    dispatch({
      type: FETCH_AUTH_TOKEN,
      payload: { data },
    });
    localStorage.setItem("chatUserId",id);
  });
};
