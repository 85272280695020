import React from 'react';
 

function CalendarDateIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M12.2103 3.78906H3.78937V12.21H12.2103V3.78906Z" fill="white"/>
<path d="M12.2103 16.0004L15.9997 12.2109L14.105 11.8877L12.2103 12.2109L11.8645 13.944L12.2103 16.0004Z" fill="#EA4335"/>
<path d="M0 12.2109V14.7372C0 15.4351 0.565242 16.0004 1.26312 16.0004H3.78944L4.1785 14.1057L3.78944 12.2109L1.72493 11.8877L0 12.2109Z" fill="#188038"/>
<path d="M15.9997 3.78944V1.26312C15.9997 0.565242 15.4345 0 14.7367 0H12.2103C11.9798 0.939745 11.8645 1.6313 11.8645 2.07473C11.8645 2.51811 11.9798 3.08972 12.2103 3.78944C13.0484 4.0295 13.68 4.14943 14.105 4.14943C14.5301 4.14943 15.1617 4.0295 15.9997 3.78944Z" fill="#1967D2"/>
<path d="M16 3.78906H12.2105V12.21H16V3.78906Z" fill="#FBBC04"/>
<path d="M12.2103 12.2109H3.78937V16.0004H12.2103V12.2109Z" fill="#34A853"/>
<path d="M12.2104 0H1.26318C0.565249 0 0 0.565242 0 1.26312V12.2104H3.78944V3.78944H12.2104V0Z" fill="#4285F4"/>
<path d="M5.51681 10.3219C5.20208 10.1093 4.98415 9.79879 4.86522 9.38819L5.59575 9.0872C5.66201 9.33979 5.77781 9.53559 5.94314 9.67452C6.10728 9.81346 6.30727 9.88192 6.541 9.88192C6.77994 9.88192 6.9852 9.80932 7.15673 9.66399C7.32833 9.51873 7.41466 9.33346 7.41466 9.10933C7.41466 8.8798 7.32413 8.6924 7.14313 8.5472C6.96207 8.40193 6.73467 8.32933 6.46314 8.32933H6.04101V7.60621H6.41994C6.6536 7.60621 6.8504 7.54301 7.0104 7.41661C7.1704 7.29041 7.2504 7.11768 7.2504 6.89768C7.2504 6.70195 7.17887 6.54615 7.03573 6.42935C6.8926 6.31242 6.71147 6.25349 6.49147 6.25349C6.27674 6.25349 6.10621 6.31042 5.97994 6.42508C5.85368 6.54015 5.75875 6.68535 5.70415 6.84721L4.98102 6.54615C5.07675 6.27455 5.25255 6.03455 5.51041 5.82722C5.76835 5.61982 6.09781 5.51562 6.49781 5.51562C6.7936 5.51562 7.05993 5.57249 7.29573 5.68722C7.53146 5.80196 7.71673 5.96089 7.85039 6.16302C7.98419 6.36615 8.05039 6.59348 8.05039 6.84615C8.05039 7.10401 7.98833 7.32194 7.86419 7.50088C7.73993 7.67981 7.58726 7.81661 7.4062 7.91241V7.95554C7.64 8.05194 7.84306 8.21027 7.99359 8.41347C8.14619 8.61873 8.22312 8.864 8.22312 9.15033C8.22312 9.43659 8.15039 9.69239 8.00519 9.91659C7.85993 10.1409 7.65893 10.3177 7.40413 10.4461C7.14833 10.5745 6.861 10.6398 6.54207 10.6398C6.17254 10.6408 5.83148 10.5345 5.51675 10.3219H5.51681ZM10.0041 6.69661L9.20205 7.27661L8.80099 6.66828L10.2399 5.63036H10.7914V10.5261H10.0041V6.69668V6.69661Z" fill="#4285F4"/>
</svg>



		</React.Fragment>
	)
}

export default CalendarDateIcon