import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CUSTOM_CLIENT_IDS, CUSTOM_HOMEPAGE_URLS } from '../../constants/constants';
import { leftPanelFilters } from '../../actions/customer';
import { useDispatch } from 'react-redux';
const Redirect = ({ handler }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { param } = useParams();
    const client_id = localStorage.getItem("client_id");
    let homepage = state ?  state.url : "/";
    useEffect(() => {
        if (param) {
            handler(param);
        }
        else if(state && state.url){
            if(CUSTOM_CLIENT_IDS.includes(client_id) && CUSTOM_HOMEPAGE_URLS[client_id])
            {
              homepage = "/"
            }
                navigate(homepage);
        }else{
                navigate("/");
        }
        dispatch(leftPanelFilters("", "", "topicFilterClear"));
    }, []);
    return (
        <div>
        </div>
    )
}
export default Redirect