import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import Toasts from "../Toasts/Toasts";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";


const CategoryProviderList = ({ projectId = 0, show, handleClose, handleAddCategory = null, editTopicDetails }) => {
  const dispatch = useDispatch();
  const [addCategory, setAddCategory] = useState(editTopicDetails ? editTopicDetails.title : "");
  const [frenchTitle, setFrenchTitle] = useState(editTopicDetails ? editTopicDetails.title : "");
  const [addCategoryEn, setAddCategoryEn] = useState(editTopicDetails && editTopicDetails.languages ? editTopicDetails.languages.english : "");
  const [addCategoryFr, setAddCategoryFr] = useState(editTopicDetails && editTopicDetails.languages ? editTopicDetails.languages.french : "");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState('');
  const [toastHeader, setToastHeader] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState("english");
  const [cost, setCost] = useState(editTopicDetails && editTopicDetails.cost ? editTopicDetails.cost : "");
  const [people, setPeople] = useState(editTopicDetails && editTopicDetails.people ? editTopicDetails.people : "");
  const [toolsArray, setToolsArray] = useState(editTopicDetails && editTopicDetails.tools ? editTopicDetails.tools : [""]);

  const [value, setValue] = React.useState(editTopicDetails && editTopicDetails.title ? editTopicDetails.title : null);
  const [allAvailableCategoryListing, setAllAvailableCategoryListing] = useState([]);

  

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    id: '',
  });


  const handleAddNewCategory = () => {
    if (addCategory && addCategory != '') {
      const languages = {
        english: addCategoryEn,
        french: addCategoryFr
      };

      CustomerServices.addCategory(addCategory, projectId, editTopicDetails && editTopicDetails.id ? editTopicDetails.id : 0, languages, people, cost, toolsArray).then((res) => {
        if (res.status == '1' && res.data) {
          const newCategory = {
            provider_id: parseFloat(res.data.category_id),
            name: addCategory
          }
          handleAddCategory(newCategory);
          setAddCategory('');
          handleClose();
          dispatch(showMessage("sucess", _l('l_success'), _l(res.message)));
        } else {
          dispatch(showMessage("unsucess", _l('l_error'), _l(res.message)));
        }
      });
    } else {
      dispatch(showMessage("unsucess", _l('l_error'), _l('l_category_title_is_required')));
    }
  }



  const getAllAvailableProviders = async() => {
    try{
      const response = await CustomerServices.getDefaultTopicList(1);
      if(response.status && response.data)
      {
        setAllAvailableCategoryListing(response.data.map((w) => {return {title: w.name, id: w.id}}));
      }
    }
    catch(e)
    {
      console.error(e);
    }
  }

  useEffect(() => {
    getAllAvailableProviders();
  }, [])
  

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        {showToast ?
          <Toasts cancelButtonHandler={() => { }} cancelButtonText={() => { }} confirmButtonHandler={() => { }} confirmButtonText={""} delay={2500} handler={() => setShowToast(false)} header={toastHeader} message={toastMessage} toastClass={toastClass} />

          :
          <></>
        }
        <Modal.Header closeButton>
          <Modal.Title>{editTopicDetails && editTopicDetails.id && editTopicDetails.id > 0 ? _l('l_edit') + " " +_l('l_category') : _l('l_add_new_category')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="tab-wrapper_main pb-2 res-overflow-auto d-none">
            <div className="d-flex align-items-center justify-content-between border-bottom">
              <div className="comman-tab-wrapper pb-0">
                <div className="d-flex align-items-center">
                  <div
                    className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "english"
                      ? "active"
                      : ""
                      }`}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setSelectedTab("english");
                      }}
                    >
                      {_l("l_english")}
                    </a>
                  </div>
                  <div
                    className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "french"
                      ? "active"
                      : ""
                      }`}
                  >
                    <a href="#/"
                      className="tab-name"
                      onClick={() => {
                        setSelectedTab("french");
                      }}
                    >
                      {_l("l_french")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-wrapper-main">
            <div className="row">
              {
                selectedTab == "english"
                  ?
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l('l_category_title')}
                    </Form.Label>
                    <React.Fragment>
                      <Autocomplete
                        className="custom-autocomplete"
                        value={value}
                        onKeyDown={(e) => { 
                          setValue(e.target.value);
                          setAddCategory(e.target.value);
                        }}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            // timeout to avoid instant validation of the dialog's form.
                            setTimeout(() => {
                              setDialogValue({
                                title: newValue,
                                id: '',
                              });
                            });
                          } else if (newValue && newValue.inputValue) {
                            setDialogValue({
                              title: newValue.inputValue,
                              id: '',
                            });
                          } else {
                            setValue(newValue);
                            setAddCategory(newValue && newValue.title ? newValue.title : "");
                          }
                        }}
                        filterOptions={(options, params) => {
                          let filtered = [];
                          if(params.inputValue !== ''){
                            filtered = options.filter((w) => w.title && w.title.toLowerCase().trim().includes(params.inputValue.toLowerCase().trim()))
                          }
                         
                          return filtered;
                        }}
                        id="free-solo-dialog-demo"
                        options={allAvailableCategoryListing}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.title;
                        }}
                        
                        handleHomeEndKeys
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                        sx={{ width: 300 }}
                        freeSolo
                        renderInput={(params) => <TextField {...params} />}
                      />
                      
                    </React.Fragment>
                  </Form.Group>
                  :
                  <Form.Group className="col-12 c-input-box position-relative mb-3">
                    <Form.Label className="input-label no-asterisk ">
                      {_l('l_category_title')} [French]
                    </Form.Label>
                    <Form.Control placeholder={_l('l_category_title')} type="text" name="" value={frenchTitle}
                      onChange={(e) => {
                        setFrenchTitle(e.target.value);
                        setAddCategory(e.target.value);
                      }} />
                  </Form.Group>
              }
             
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={handleAddNewCategory}>{_l('l_save')} </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default CategoryProviderList;
