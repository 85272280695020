import React, { useState } from "react";
import Invoice from "../../tabs/Invoice";
import Paytm from "../../tabs/PaytmTabs/Paytm";
import { _l, showError } from "../../../../hooks/utilities";
import PermissionTab from "../../tabs/PermissionTab";
import ClientInvoiceTab from "../../tabs/ClientInvoiceTab";
import ClientSubscriptionTab from "../../tabs/ClientSubscriptionTab";
import ClientContractsTab from "../../tabs/ClientContractsTab";
import ClientCreditnoteTab from "../../tabs/ClientCreditnoteTab";
import ClientContactsTab from "../../tabs/ClientContactsTab";
import ClientSpaceTab from "../../tabs/ClientSpaceTab";
import documentsServices from "../../../../services/documents-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../../../actions/messages";

const Invoices = ({ data, setData }) => {
  const [selectedTab, setSelectedTab] = useState("basic_detail");
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const dispatch = useDispatch();

  /**Hide the Paynow button if invoice status value is from below array */
  const hidePayButtonStatuses = ["paid"];

  const payInvoice = async() => {
    if(!hidePayButtonStatuses.includes(data.status) && data.is_app_invoice > 0 && data.is_one_time_benchmark > 0 && data.proposal_wallet_id > 0)
    {
      try {
        setPaymentInProgress(true);
        const response = await documentsServices.payAppInvoice(data.hash, data.project_id, data.is_app_invoice, data.is_one_time_benchmark, data.proposal_wallet_id, data.proposal_id);
        if(response.status)
        {
          setData({...data, status: "paid"});
          dispatch(showMessage("success", _l("l_success"), _l(response.message)));
        }
        else {
          showError(response.message);
        }
      }
      catch(e)
      {
        console.error(e);
        showError("l_something_went_wrong");
      }
      finally {
        setPaymentInProgress(false);
      }
    }
    else {
      return;
    }
  };

  return (
    <>
      <>
        <div className="tab-wrapper_main res-overflow-auto pe-2">
          <div className="d-flex align-items-center justify-content-between border-bottom">
            <div className="comman-tab-wrapper pb-0">
              <div className="d-flex align-items-center">
              <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'basic_detail' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("basic_detail")}}>{_l("l_basic_details")}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'staff') ? "" : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'permissions' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("permissions")}}>{'Permissions'}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'customer') ? '' : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'Contacts' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("Contacts")}}>{'Contacts'}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'customer') ? '' : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'Invoices' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("Invoices")}}>{'Invoices'}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'customer') ? '' : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'Credit Notes' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("Credit Notes")}}>{'Credit Notes'}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'customer') ? '' : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'Contracts' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("Contracts")}}>{'Contracts'}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'customer') ? '' : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'Subscriptions' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("Subscriptions")}}>{'Subscriptions'}</a>
                </div>
              </div>
            </div>
            <div className={`comman-tab-wrapper pb-0 ${(data.type == 'customer') ? '' : 'd-none'}`}>
              <div className="d-flex align-items-center">
                <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === 'Spaces' ? 'active' : ""}`}>
                  <a href="#/" className="tab-name m-0" onClick={() => { setSelectedTab("Spaces")}}>{'Spaces'}</a>
                </div>
              </div>
            </div>
            <div className="task-header-right">
              <div
                className={`d-flex align-items-center justify-content-end ${
                  selectedTab === "paytm" ? "active" : ""
                }`}
              >
                <a href="#/"
                  className="tab-name"
                >
                  <button
                    type="button"
                    className={`btn btn-primary btn-sm ${
                      data && (data.proposal_wallet_id == 0 || hidePayButtonStatuses.includes(data.status) || data.type == 'staff' || data.type == 'customer')
                        ? "d-none"
                        : ""
                    }`}
                    onClick={payInvoice}
                    disabled={paymentInProgress}
                  >
                    {paymentInProgress ? _l("l_please_wait") : _l('l_pay_now')}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hiiden-auto comman_vertical_btn_h pb-2 position-relative px-2">
          {selectedTab === "basic_detail" ? <Invoice data={data} /> : 
          selectedTab === "permissions" ? <PermissionTab data={data}/> : 
          selectedTab === "Invoices" ? <ClientInvoiceTab data={data}/> : 
          selectedTab === "Subscriptions" ? <ClientSubscriptionTab data={data}/> : 
          selectedTab === "Subscriptions" ? <ClientContractsTab data={data}/> : 
          selectedTab === "Contracts" ? <ClientContractsTab data={data}/> : 
          selectedTab === "Credit Notes" ? <ClientCreditnoteTab data={data}/> : 
          selectedTab === "Contacts" ? <ClientContactsTab data={data}/> : 
          selectedTab === "Spaces" ? <ClientSpaceTab data={data}/> : <Paytm />}
        </div>
      </>
    </>
  );
};
export default Invoices;
