import {
  CLEAR_PARTIALLY_CONVERSIONS,
  CLEAR_TRANSCRIPT,
  GET_ASSISTANT_CALL_TASK_ID,
  GET_PARTIALLY_CONVERSIONS,
  GET_TRANSCRIPT,
  IS_ASSISTANT_CALL,
  SET_CALL_ID,
} from "../actions/vapi-action-type";

const initialState = {
  aiConverstion: [],
  partiallyConversation: [],
  isAssistantCall : false,
  callID : "",
  isGetTaskId : false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case GET_TRANSCRIPT:
      let tempScript =
        state.aiConverstion.length > 0 ? state.aiConverstion : [];
      let returnArr = tempScript.concat([payload]);
      returnArr = returnArr.filter((t)=> t.transcriptType == "final")
      // console.log("tempScript", returnArr);
      return {
        ...state,
        aiConverstion: returnArr,
      };

    case CLEAR_TRANSCRIPT:
      return {
        ...state,
        aiConverstion: [],
      };

    case GET_PARTIALLY_CONVERSIONS:
      let tempConversion =
        state.partiallyConversation.length > 0 ? state.partiallyConversation : [];
      let returnConArr = tempConversion.concat([payload]);
      returnConArr = returnConArr.filter((t) => t.transcriptType == "final");
      return {
        ...state,
        partiallyConversation: returnConArr,
      };

    case CLEAR_PARTIALLY_CONVERSIONS:
      return {
        ...state,
        partiallyConversation: [],
      };

    case IS_ASSISTANT_CALL:
      return {
        ...state,
        isAssistantCall: payload,
      };

    case SET_CALL_ID:
      return {
        ...state,
        callID: payload,
      };

    case GET_ASSISTANT_CALL_TASK_ID:
      return {
        ...state,
        isGetTaskId: payload,
      };

    default:
      return state;
  }
}
