import React from 'react';
import { _l } from '../../hooks/utilities';
import Button from "react-bootstrap/Button";

const ErrorFallback = () => {
    return (
            <>

        <div className={`main-loader-wrapper full-screen with-opacity up-left-panel z-index-22`}>
        <div className="w-100 h-100 d-flex">
            <div className="loader-video w-25">
                <div className="sk-circle">
                    <div className="sk-circle1 sk-child"></div>
                    <div className="sk-circle2 sk-child"></div>
                    <div className="sk-circle3 sk-child"></div>
                    <div className="sk-circle4 sk-child"></div>
                    <div className="sk-circle5 sk-child"></div>
                    <div className="sk-circle6 sk-child"></div>
                    <div className="sk-circle7 sk-child"></div>
                    <div className="sk-circle8 sk-child"></div>
                    <div className="sk-circle9 sk-child"></div>
                    <div className="sk-circle10 sk-child"></div>
                    <div className="sk-circle11 sk-child"></div>
                    <div className="sk-circle12 sk-child"></div>
                </div>
                <div className="c-font f-18 loader-text mt-4">{_l("l_something_went_wrong")}</div>
                <div className="color-white-60 fst-italic loader-subtitle mt-4">
                    <span className="d-block mb-2">{_l("l_could_not_connect_to_internet")}</span>
                    <span className="d-block mb-2">{_l("l_please_check_your_network_connection")}</span>
                </div>
                
                <Button variant="primary" className="mt-5" onClick={() => { window.location.reload() }} >{_l("l_reload")} </Button>
            </div>
        </div>
    </div>

        </>
      



        
    )
}

export default ErrorFallback