import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { _l, showError, updateSpaceDetailInLocalStorage } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import Scan3dPreview from "./Scan3dPreview";
import { showConfirmation, showMessage, toastCloseAction } from "../../../actions/messages";
import CustomerServices from "../../../services/customer-services";
import { getFloorDetails, setSelectedSubFloorIdsForLease, updateFloorSetting } from "../../../actions/benchmark-actions/benchmark-actions";
import VirtualIcon from "../../../assets/icons/VirtualIcon";
import BenchmarkServices from "../../../services/benchmark-services";
import Autocomplete from "../../Dropdowns/Autocomplete";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import AIContextModal from "../../Modals/AIContextModal";
import LeaseModal from "../../Modals/LeaseModal";
import leaseServices from "../../../services/lease-services";
import CaretDownIcon from "../../../assets/icons/CaretDownIcon";
import CaretUpIcon from "../../../assets/icons/CaretUpIcon";
import CheckIcon from "../../../assets/icons/CheckIcon";
import CpuIcon from "../../../assets/icons/CpuIcon";
import FilesIcon from "../../../assets/icons/FilesIcon";
import MinusIcon from "../../../assets/icons/MinusIcon";
import XIcon from "../../../assets/icons/XIcon";
import PencilIcon from "../../../assets/icons/PencilIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import RobotIcon from "../../../assets/icons/RobotIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
let currentFloorTypeOfSpaces = [];
let initialTypeOfSpaceSelected = false;
export function FloorSetup({
  spaceDetail,
  setSpaceDetail,
  floorDetail,
  setFloorDetail,
  setCurrentStep,
  saveFloorSettings,
  deleteFloor,
  currentStep
}) {
  const spaceFloorPlan = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );
  const [newTypeOfSpaceData, setNewTypeOfSpaceData] = useState({
    title: "",
    quantity: 1,
    details: [],
  });
  const [editingTypeOfSpace, setEditingTypeOfSpace] = useState(0); // For Space Item
  const [targetSpaceIdForEdit, setTargetSpaceIdForEdit] = useState(0); // FOr Main Space Title
  const [preview3DScan, setPreview3DScan] = useState(false);
  const [bulkTypeOfSpaceList, setBulkTypeOfSpaceList] = useState();
  const [selectedTypeOfSpace, setSelectedTypeOfSpace] = useState({
    title: "",
    details: [],
    id: 0,
    quantity: 0,
    skill_require_for_benchmark: 0
  });
  const [viewAIContext, setViewAIContext] = useState(false);
  const [aiContextData, setAiContextData] = useState({image_url: floorDetail.scan3d});
  const [aiContextResponse, setAiContextResponse] = useState([])
  const [aiContextDataResponse, setAiContextDataResponse] = useState([])
  const [aiContextDataExtra, setAiContextDataExtra] = useState({})
  const [bulkTOSLoader, setBulkTOSLoader] = useState(false);
  const [selectesSubFlooeIds, setSelectesSubFlooeIds] = useState([]);
  const [showLeaseModal, setshowLeaseModal] = useState(false);
  const [selectedFloorId, setselectedFloorId] = useState(-1);
  const [leaseJson, setLeaseJson] = useState([])
  const [leaseDetails, setleaseDetails] = useState({});
  const [documents, setDocuments] = useState([]);
  const [subLeaseId, setSubLeaseId] = useState(0);
const [isCreate, setisCreate] = useState(false)
  const dispatch = useDispatch();
  let currentFloorList = spaceFloorPlan.floor.slice();
  const contact_role = localStorage.getItem("contact_role");
  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");
  const staff_id = localStorage.getItem("staff_id");
  const isLoginFromUser = localStorage.getItem("adminArchieve");
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;
  const checkForBulkTypeOfSpaceInput = () => {
    if (bulkTypeOfSpaceList && bulkTypeOfSpaceList.split("\n").length) {
      addNewTypeOfSpace(
        bulkTypeOfSpaceList.split("\n"),
        newTypeOfSpaceData.quantity
      );
      setBulkTypeOfSpaceList("");
    } else if (newTypeOfSpaceData.title.trim().length) {
      addNewTypeOfSpace(
        [newTypeOfSpaceData.title],
        newTypeOfSpaceData.quantity
      );
    }
  };
  const addNewTypeOfSpace = async (title, quantity) => {
    try {
      const response = await CustomerServices.addFloorItem(
        spaceDetail.project_id,
        quantity,
        title,
        "type_of_space",
        floorDetail.id,
        0
      );
      if (response.status) {
        dispatch(updateFloorSetting(floorDetail, response.data.type_of_space));
        setNewTypeOfSpaceData({ details: [], quantity: 1, title: "" });
      }
    } catch (e) {
      console.error(e);
    }
  };
  const updateFloorSuperficy = (value) => {
    if (value < 0) {
      return false;
    }
    let totalFloorwiseSuperficy = 0;
    currentFloorList.forEach((w) => {
      if (w.id != floorDetail.id && w.superficy) {
        totalFloorwiseSuperficy += Number(w.superficy);
      }
    });
    if (totalFloorwiseSuperficy + Number(value) > spaceDetail.superfice) {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_confirm_upgrade_space_superficy"),
          _l("l_yes"),
          _l("l_no"),
          () => {
            setFloorDetail({
              ...floorDetail,
              superficy: spaceDetail.superfice - totalFloorwiseSuperficy,
            });
          },
          () => {
            setSpaceDetail({
              ...spaceDetail,
              superfice: totalFloorwiseSuperficy + Number(value),
            });
            updateSpaceDetails(
              spaceDetail.address,
              spaceDetail.title,
              spaceDetail.region_id,
              totalFloorwiseSuperficy + Number(value),
              spaceDetail.number_of_desk,
              "",
              "",
              spaceDetail.space_type,
              [],
              spaceDetail.id
            );
            setFloorDetail({ ...floorDetail, superficy: value });
            dispatch(toastCloseAction());
          }
        )
      );
    } else {
      setFloorDetail({ ...floorDetail, superficy: value });
    }
  };
  const updateNumberOfDesks = (value) => {
    if (value < 0) {
      return false;
    }
    let totalFloorwiseDesks = 0;
    currentFloorList.forEach((w) => {
      if (w.id != floorDetail.id && w.number_of_desk) {
        totalFloorwiseDesks += Number(w.number_of_desk);
      }
    });
    if (totalFloorwiseDesks + Number(value) > spaceDetail.number_of_desk) {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_confirm_increase_space_desks"),
          _l("l_yes"),
          _l("l_no"),
          () => {
            setFloorDetail({
              ...floorDetail,
              number_of_desk: spaceDetail.number_of_desk - totalFloorwiseDesks,
            });
          },
          () => {
            setSpaceDetail({
              ...spaceDetail,
              number_of_desk: totalFloorwiseDesks + Number(value),
            });
            updateSpaceDetails(
              spaceDetail.address,
              spaceDetail.title,
              spaceDetail.region_id,
              spaceDetail.superfice,
              totalFloorwiseDesks + Number(value),
              "",
              "",
              spaceDetail.space_type,
              [],
              spaceDetail.id
            );
            setFloorDetail({ ...floorDetail, number_of_desk: value });
            dispatch(toastCloseAction());
          }
        )
      );
    } else {
      setFloorDetail({ ...floorDetail, number_of_desk: value });
    }
  };
  const updateSpaceDetails = async(
    address,
    title,
    region_id,
    superfice,
    number_of_desk,
    office_id,
    country_code,
    space_type,
    typeOfSpace,
    spaceId
  ) => {
    try {
      const response = await CustomerServices.addEditSpaces(
        address,
        title,
        region_id,
        superfice,
        number_of_desk,
        office_id,
        country_code,
        { value: space_type },
        typeOfSpace,
        spaceId
      );
      if(response.status > 0)
      {
        updateSpaceDetailInLocalStorage(response.data.project_id, {office_title: spaceDetail.title, postal_address: spaceDetail.address, number_of_desk: spaceDetail.number_of_desk, superficies: spaceDetail.superfice, floor_count: spaceDetail.floor_count});
      }
    } catch (e) {
      console.error(e);
    }
  };
  const removeSpaceItems = async (slug, records, key, fromAiSpace) => {
    try {
      const response = await CustomerServices.deleteFloorItems(
        spaceDetail.project_id,
        slug,
        records,
        key
      );
      if (response.status) {
        dispatch(updateFloorSetting(floorDetail, response.data.type_of_space));
        let updatedSpaceDetail = response.data.type_of_space.find(
          (w) => w.id == selectedTypeOfSpace.id
        );
        if (updatedSpaceDetail && updatedSpaceDetail.quantity < 1) {
          initialTypeOfSpaceSelected = false;
        }
        if(fromAiSpace){
          aiGetDataFromScan3d();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const deleteTypeOfSpaceItems = (itemIds, spaceItem, fromAiSpace) => {
  if (itemIds && itemIds.length > 0) {
    if (spaceItem) {
      removeSpaceItems("space_item", itemIds, "item_id");
    } else {
      if(!fromAiSpace){
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            _l("l_delete_type_of_space_confirmation"),
            _l("l_delete"),
            _l("l_cancel"),
            () => {},
            () => {
              removeSpaceItems("type_of_space", itemIds, "space_id", fromAiSpace);
              initialTypeOfSpaceSelected = false;
              dispatch(toastCloseAction());
            }
          )
        );
      } else {
        removeSpaceItems("type_of_space", itemIds, "space_id", fromAiSpace);
        initialTypeOfSpaceSelected = false;
      }
    }
  } else {
    aiGetDataFromScan3d() 
  }
  };
  const uploadScan3d = async (file) => {
    try {
      const response = await CustomerServices.addSpaceDocument(
        floorDetail.id,
        "",
        "",
        file,
        0,
        0,
        1,
        spaceDetail.project_id
      );
      if(response.status)
      {
        setAiContextData({...aiContextData, image_url: response.data.file_url});
        dispatch(
          showMessage("sucess", _l("l_success"), _l("l_the_type_of_space_generation_process_started_Wew_ill_notify_you_ass_oon_as_the_results_are_ready"))
        )
        if (adminAsStaff && spaceDetail.project_id && floorDetail.id) {
          setTimeout(() => {
            CustomerServices.addTypeofSpaceUsingAi(spaceDetail.project_id, floorDetail.id).then((res) => {
              if (res.status && currentStep == 'floor-setup') {
                dispatch(
                  showMessage("sucess", _l("l_success"), _l("l_floor_data_is_ready"))
                )
                dispatch(getFloorDetails(spaceDetail.project_id, 1));
              }
            }) 
        }, 5000);
        }
      }
      setFloorDetail({ ...floorDetail, scan3d: file });
    } catch (e) {
      console.error(e);
    }
  };
  const updateTypeOfSpaceTitle = (title) => {
    let validTitle = title.replace(/[^a-zA-Z0-9\s]/g, "");
    if (validTitle && validTitle.trim().length) {
      setSelectedTypeOfSpace({ ...selectedTypeOfSpace, title: validTitle });
    }
  };
  const saveTypeOfSpaceTitle = async (title, space_id, item_id, slug, skillRequireForBenchmark) => {
    try {
      if (title && title.trim().length) {
        const response = await BenchmarkServices.updateTypeOfSpaceTitle(
          spaceDetail.project_id,
          slug,
          space_id,
          item_id,
          title,
          undefined,
          undefined,
          skillRequireForBenchmark
        );
        if (response.status) {
          setTargetSpaceIdForEdit(0);
          dispatch(
            updateFloorSetting(floorDetail, response.data.type_of_space)
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const validItemTitle = (title, index) => {
    let flag = true;
    if (title && title.trim().length) {
      let titleIndex = selectedTypeOfSpace.details.findIndex(
        (w, i) =>
          i != index &&
          w.title &&
          title.trim().toLowerCase() == w.title.trim().toLowerCase()
      );
      if (titleIndex !== -1) {
        flag = false;
      }
    }
    return flag;
  };
  const getSpaceOptionList = () => {
    let spaceOptions = spaceFloorPlan.type_of_space
      .concat(spaceFloorPlan.all_type_of_space)
      .filter((w) => w.is_default == 0)
      .map((space) => {
        return {
          label: space.title,
          value: space.id,
        };
      });
    spaceOptions = spaceOptions.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => obj.label == o.label)
    );
    return spaceOptions;
  };
  const saveFloorAIContext = async(content) => {
    let aiContextDatas = aiContextDataResponse;
    try {
      const response = await CustomerServices.saveTaskAIContext(0, spaceDetail.project_id, content, "", "", "", "", "", aiContextData.image_url, "", aiContextResponse);
      if(response.status && response.message && response.extra)
      {
        aiContextDatas.push({ question :content, answer : response.message});
        setAiContextDataResponse(aiContextDataResponse);
        setAiContextResponse(response.data);
        setAiContextDataExtra(response.extra.room_json_object)
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong , 1213");
    }
  };
  const aiGetDataFromScan3d = async() => {
    try {
      setBulkTOSLoader(true);
      const response = await CustomerServices.aiGetDataFromScan3d(contact_id, client_id, staff_id, spaceDetail.project_id, floorDetail.id, aiContextData.image_url);
      if(response.status && response.data)
      {
        addNewTypeOfSpaceBulk(response.data);
      }
      else {
        showError(response.message);
      }
    }
    catch(e)
    {
      console.error(e);
      showError("l_something_went_wrong , 1213");
    }
  };
  const addNewTypeOfSpaceBulk = async (data) => {
    let aiTypeOfSpace = []
    for (const key in data) {
      let prevQuntity
      spaceFloorPlan.type_of_space.map(function(item) {
          if (item.title == key) {
            return prevQuntity = item.quantity;
          }
      })
      aiTypeOfSpace.push({
          name : key,
          quantity: data[key],
          prevQuntity: prevQuntity == undefined || null ? 0 : parseInt(prevQuntity)
      })
    }
    try {
      for (let item = 0; item < aiTypeOfSpace.length; item++) {
        const response = await CustomerServices.addFloorItem(
          spaceDetail.project_id,
          aiTypeOfSpace[item].quantity,
          [aiTypeOfSpace[item].name],
          "type_of_space",
          floorDetail.id,
          aiTypeOfSpace[item].prevQuntity,
        );
        if (response.status) {
          // For final call
          if(item + 1 == aiTypeOfSpace.length){
            setBulkTOSLoader(false);
            dispatch(showMessage("sucess", _l("l_success"), _l(response.message)));
          }
          dispatch(updateFloorSetting(floorDetail, response.data.type_of_space));
          setNewTypeOfSpaceData({ details: [], quantity: 1, title: "" });
        }else{
          dispatch(showMessage("unsucess", _l("l_error"), _l(response.message)));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const updateSpaceSkillRequirement = (e) => {
    const flagValue = e.target.checked ? 1 : 0;
    saveTypeOfSpaceTitle(selectedTypeOfSpace.title, selectedTypeOfSpace.id, 0, "type_of_space", flagValue);
    setSelectedTypeOfSpace({...selectedTypeOfSpace, skill_require_for_benchmark: flagValue});
  };
  const handleFloorSelection = (floorId, main_floor_id) =>{
    let index = selectesSubFlooeIds.findIndex((id) => id == floorId)
    if (index > -1) {
      dispatch(setSelectedSubFloorIdsForLease(selectesSubFlooeIds.filter((id) => id != floorId)))
      setSelectesSubFlooeIds(selectesSubFlooeIds.filter((id) => id != floorId));
      setLeaseJson(leaseJson.filter((floor) => floor.type_of_space_item_id != floorId))
    } else {
      dispatch(setSelectedSubFloorIdsForLease(selectesSubFlooeIds.concat([floorId])))
      setSelectesSubFlooeIds(selectesSubFlooeIds.concat([floorId]))
      let json = {
        "floor_id" : main_floor_id,
        "type_of_space_id" : selectedTypeOfSpace.id,
        "type_of_space_item_id" : floorId
      }
      setLeaseJson(leaseJson.concat([json]))
    }
  }
  const getLeaseDeatils = (floorlease_id, floor_sub_lease_id) => {
    leaseServices.getProjectSubLease(spaceDetail.project_id ,floorlease_id,floor_sub_lease_id).then((res)=>{
      if (res && res.status) {
        if (res.data && res.data.length  > 0) {
          let floorids = res.data[0].lease_area.map((floor) => {
            return floor.type_of_space_item_id
          })
          setselectedFloorId(res.data[0].lease_area[0].floor_id)
          setSubLeaseId(floor_sub_lease_id)
          setDocuments(res.data[0].documents && res.data[0].documents.length > 0 ? res.data[0].documents : []);
          setLeaseJson(res.data[0].lease_area);
          setSelectesSubFlooeIds(floorids)
          setleaseDetails(res.data[0]);
          setshowLeaseModal(true);
        }
      }
    })
  }
  useEffect(() => {
    //Setting up the first active space
    let fisrtSpaceId = 0;
    spaceFloorPlan.type_of_space.slice().forEach((x) => {
      if (x.is_default != 1 && fisrtSpaceId === 0) {
        let quantity = 0;
        x.details.forEach((w) => {
          if (w.floor_id == floorDetail.id) {
            quantity++;
          }
        });
        if (quantity > 0) {
          fisrtSpaceId = x.id; //Setting up this ID will prevent the loop and will update the state only once
          setSelectedTypeOfSpace(x);
        }
      }
    });
    return () => {
      currentFloorTypeOfSpaces = [];
      initialTypeOfSpaceSelected = false;
    };
  }, []);
  useEffect(() => {
    if (spaceFloorPlan.type_of_space && selectedTypeOfSpace) {
      let targetSpace = spaceFloorPlan.type_of_space.find(
        (w) => w.id == selectedTypeOfSpace.id
      );
      setSelectedTypeOfSpace(targetSpace);
    }
  }, [spaceFloorPlan]);
  return (
    <div className="row m-0 mt-3 gy-2 gx-0 create-of-floor-main overflow-hidden h-100">
      <div className=" col-xl-12 flex-grow-1 h-100 mt-0 position-relative">
        <div className="radius_5 w-100 d-flex flex-column flex-grow-1 h-100 overflow-hiiden-auto onboarding-floor-bg">
          <div className="position-sticky top-0 bg-white-03-solid border-bottom z-index-2">
            <div class="align-items-center d-flex justify-content-between p-10 light-theme-white-bg bg-white-03 mb-32px light-theme-border">
              <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60">
                {floorDetail.title}
              </div>
              <div class="align-items-center d-flex justify-content-between comman_action_icon">
                {spaceFloorPlan.floor.length > 1 && contact_role == 1 ? (
                  <a href="#/"
                    className="action_icon h32w32 with_bg ms-10px"
                    onClick={() => {
                      deleteFloor();
                    }}
                  >
                    <TrashIcon className="HW18" />
                  </a>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                <a href="#/"
                  className="action_icon h32w32 with_bg ms-10px"
                  onClick={() => {
                    setCurrentStep("floor-listing");
                    saveFloorSettings(floorDetail);
                  }}
                >
                  <XIcon className="HW18" />
                </a>
              </div>
            </div>
            <div className="row m-0">
              <Form.Group className="col-xl-4 c-input-box pb-4 position-relative ps-0">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_floor_title")}
                </Form.Label>
                <Form.Control
                  className=""
                  placeholder={`${_l("l_floor_title")}`}
                  type="text"
                  value={floorDetail.title}
                  onChange={(e) => {
                    setFloorDetail({ ...floorDetail, title: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-2 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_superficy")}
                </Form.Label>
                <div className="d-flex align-items-center justify-content-between form-control py-0">
                  <input
                    type="number"
                    className="bg-transparent border-0 form-control px-0 shadow-none"
                    placeholder={`${_l("l_superficy")} (m²)`}
                    value={floorDetail.superficy}
                    onChange={(e) => {
                      if (e.target.value > 0) {
                        setFloorDetail({
                          ...floorDetail,
                          superficy: e.target.value,
                        });
                      }
                    }}
                    onBlur={(e) => updateFloorSuperficy(e.target.value)}
                  />
                  <div className="d-flex flex-column">
                    <a href="#/"
                      className="c-font f-22 line-height-0"
                      onClick={() =>
                        updateFloorSuperficy(
                          Number(floorDetail.superficy) + 100
                        )
                      }
                    >
                      <CaretUpIcon className="HW12" />
                    </a>
                    <a href="#/"
                      className={`c-font f-22 line-height-0 ${
                        floorDetail.superficy > 0 ? "" : "for-disabled"
                      }`}
                      onClick={() =>
                        updateFloorSuperficy(
                          Number(floorDetail.superficy) - 100
                        )
                      }
                    >
                      <CaretDownIcon className="HW12" />
                    </a>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-2 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_number_of_desk")}
                </Form.Label>
                <div className="d-flex align-items-center justify-content-between form-control comman_action_icon h_53px">
                  <a href="#/"
                    className={`c-font f-22 line-height-0 ${
                      floorDetail.number_of_desk > 1 ? "" : "for-disabled"
                    }`}
                    onClick={() => {
                      updateNumberOfDesks(
                        Number(floorDetail.number_of_desk) - 1
                      );
                    }}
                  >
                    <div className="action_icon h32w32 with_bg">
                    <MinusIcon className="HW16" />
                    </div>
                  </a>
                  <input
                    type="number"
                    className="bg-transparent border-0 text-center "
                    placeholder={_l("l_number_of_desk")}
                    name=""
                    value={floorDetail.number_of_desk}
                    onChange={(e) => {
                      setFloorDetail({
                        ...floorDetail,
                        number_of_desk: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      updateNumberOfDesks(e.target.value);
                    }}
                  />
                  <a href="#/"
                    className="c-font f-22 line-height-0"
                    onClick={() =>
                      updateNumberOfDesks(
                        Number(floorDetail.number_of_desk) + 1
                      )
                    }
                  >
                    <div className="action_icon h32w32 with_bg">
                    <PlusIcon className="HW16" />
                    </div>
                  </a>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-4 position-relative d-flex align-items-center">
                {adminAsStaff ? <>
                <div className="me-10px">
                  {
                    floorDetail.scan3d
                    ?
                    <React.Fragment>
                      <Form.Label className="input-label no-asterisk">
                        {_l("l_ai")}
                      </Form.Label>
                      <div className="comman_action_icon ">
                        <a href="#/"
                          className="h50w50 bg-white-03 d-flex lightthemebg"
                          onClick={() => { 
                            setViewAIContext(true) }}
                        >
                          <CpuIcon
                            className="HW24"
                          />
                        </a>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                  }
                </div>
                <div className="me-10px">
                  {
                    floorDetail.scan3d
                    ?
                    <React.Fragment>
                      <Form.Label className="input-label no-asterisk opacity-0">
                      {_l("l_")}
                      </Form.Label>
                      <div className="comman_action_icon h50w50 ">
                        <a href="#/"
                          className="h50w50 bg-white-03 d-flex justify-content-center align-items-center lightthemebg"
                          onClick={() => {
                            deleteTypeOfSpaceItems(currentFloorTypeOfSpaces, false , true)
                          }}
                          disabled={bulkTOSLoader ? true : false}
                        >
                          <RobotIcon
                            className={`HW24 ${bulkTOSLoader ? "d-none" : "" }`}
                          />
                          {bulkTOSLoader ?
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2 `}
                              aria-hidden="true"
                            />
                            : <></>
                            }
                        </a>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment></React.Fragment>
                  }
                </div>
                </> : <></>}
                <div className="flex-grow-1">
                  <Form.Label className="input-label no-asterisk">
                    {_l("l_upload_scan_3d")}
                  </Form.Label>
                  <div className="d-flex align-items-center w-100">
                    <div className="form-control file-upload with-bg position-relative p-2 flex-grow-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <input
                          className="z-index-3 hidden-input"
                          type="file"
                          onInput={(e) => {
                            uploadScan3d(e.target.files[0]);
                          }}
                        />
                        <span className="color-white-60 ps-2">
                          {_l("l_click_to_upload")}
                        </span>
                        <div className="c-font f-12 btn btn-white-05 btn-sm border-0">
                          {_l("l_browse_file")}
                        </div>
                      </div>
                    </div>
                    <div className="upload-image-preview p-0 d-flex flex-wrap ms-10px">
                      <div className="d-flex">
                        {floorDetail && floorDetail.scan3d ? (
                          <div>
                            <div
                              className="comman-image-box h50w50 radius_3 upload-image with-margin d-flex me-0"
                              onClick={() => {
                                setPreview3DScan(true);
                              }}
                            >
                              <VirtualIcon className="HW30" />
                              <a href="#/"
                                className="upload-close"
                                onClick={() => {
                                  setFloorDetail({ ...floorDetail, scan3d: "" });
                                }}
                              >
                                <XIcon className="HW10" />
                              </a>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="create-of-floor pb-0 d-flex flex-column flex-grow-1 overflow-hidden light-theme-white-bg">
            <div className="sub-floor flex-grow-1 h_100">
              <div className="row h-100 m-0">
                <div className="col-xl-4 ps-0 border-right h-100 pt-3 d-flex flex-column">
                    <React.Fragment>
                      <div className="d-flex align-items-center justify-content-between comman_action_icon">
                        <span className="input-label no-asterisk">
                          {_l("l_type_of_space_label")}
                        </span>
                        {(!currentFloorTypeOfSpaces.length) || (floorDetail && floorDetail.scan3d && isLoginFromUser) ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <a href="#/" className="action_icon h32w32 with_bg"
                          onClick={() =>
                            deleteTypeOfSpaceItems(currentFloorTypeOfSpaces)
                          }
                        >
                          <TrashIcon className="HW18" />
                          </a>
                        )}
                      </div>
                      { floorDetail && floorDetail.scan3d && isLoginFromUser ? 
                      ( <React.Fragment></React.Fragment> ) :  
                      <div className="pt-15">
                      <Form.Group className="c-input-box pb-3 position-relative ">
                        <div className="d-flex align-items-center justify-content-between w-100 ">
                          <div className="d-flex align-items-center justify-content-between form-control flex-grow-1 h_53px comman_action_icon">
                            <div className="with_separator_10 flex-grow-1 ps-0">
                              <Autocomplete
                                inputClass="bg-transparent border-0"
                                dropdownClass="max-height-20vh"
                                value={newTypeOfSpaceData.title}
                                options={getSpaceOptionList()}
                                onChange={(e) => {
                                  setNewTypeOfSpaceData({
                                    ...newTypeOfSpaceData,
                                    title: e.target.value,
                                  });
                                }}
                                onSelect={(value) => {
                                  setNewTypeOfSpaceData({
                                    ...newTypeOfSpaceData,
                                    title: value.label,
                                  });
                                }}
                              />
                            </div>
                            <div className="d-flex align-items-center justify-content-between with_separator_10 pe-0 w_170px min-width-60">
                              <a href="#/"
                                className={`c-font f-22 line-height-0 ${
                                  newTypeOfSpaceData.quantity > 1
                                    ? ""
                                    : "for-disabled"
                                }`}
                                onClick={() => {
                                  if (newTypeOfSpaceData.quantity > 1) {
                                    setNewTypeOfSpaceData({
                                      ...newTypeOfSpaceData,
                                      quantity:
                                        newTypeOfSpaceData.quantity - 1,
                                    });
                                  }
                                }}
                              >
                                <div className="action_icon h32w32 with_bg">
                                <MinusIcon className="HW16" />
                                </div>
                              </a>
                              <input
                                type="number"
                                className="bg-transparent border-0 text-center "
                                placeholder={1}
                                name=""
                                value={newTypeOfSpaceData.quantity}
                                onChange={(e) => {
                                  setNewTypeOfSpaceData({
                                    ...newTypeOfSpaceData,
                                    quantity: e.target.value,
                                  });
                                }}
                              />
                              <a href="#/"
                                className="c-font f-22 line-height-0"
                                onClick={() => {
                                  setNewTypeOfSpaceData({
                                    ...newTypeOfSpaceData,
                                    quantity: newTypeOfSpaceData.quantity + 1,
                                  });
                                }}
                              >
                                <div className="action_icon h32w32 with_bg">
                                <PlusIcon
                                  className="HW16"
                                />
                                </div>
                              </a>
                            </div>
                          </div>
                          <a href="#/"
                            className="align-items-center d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05"
                            onClick={() => checkForBulkTypeOfSpaceInput()}
                          >
                            <div className="h-100">
                              <CheckIcon
                                className="HW18"
                              />
                            </div>
                          </a>
                        </div>
                      </Form.Group>
                      </div> }
                      <ul className="overflow-auto comman-verticle-tab mt-2 row gy-2 gx-0 floor-step-wrapper pb-15px">
                        {currentFloorTypeOfSpaces.length ||
                        !initialTypeOfSpaceSelected ? (
                          <React.Fragment>
                            {spaceFloorPlan.type_of_space &&
                              spaceFloorPlan.type_of_space
                                .filter((x) => x.is_default != 1)
                                .map((space, index) => {
                                  let quantity = 0;
                                  space.details.map((item) => {
                                    if (item.floor_id == floorDetail.id) {
                                      quantity++;
                                    }
                                  });
                                  if (quantity === 0) {
                                    return <React.Fragment></React.Fragment>;
                                  }
                                  if (
                                    !currentFloorTypeOfSpaces.includes(space.id)
                                  ) {
                                    currentFloorTypeOfSpaces.push(space.id);
                                  }
                                  if (!initialTypeOfSpaceSelected) {
                                    setSelectedTypeOfSpace(space);
                                    initialTypeOfSpaceSelected = true;
                                  }
                                  return (
                                    <li
                                      className="d-flex align-items-center justify-content-between w-100 floor-step-box-main"
                                      key={index}
                                    >
                                      <div
                                        className={`d-flex align-items-center justify-content-between form-control flex-grow-1 floor-step-box h_53px comman_action_icon bgspacelisting lightthemebgwhite ${
                                          selectedTypeOfSpace &&
                                          selectedTypeOfSpace.id == space.id
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>{
                                          setSelectedTypeOfSpace(space)
                                        }}
                                      >
                                        {targetSpaceIdForEdit == space.id ? (
                                          <input
                                            type="text"
                                            className="bg-transparent border-0 "
                                            value={
                                              selectedTypeOfSpace
                                                ? selectedTypeOfSpace.title
                                                : ""
                                            }
                                            onChange={(e) =>
                                              updateTypeOfSpaceTitle(
                                                e.target.value
                                              )
                                            }
                                            onBlur={() =>
                                              saveTypeOfSpaceTitle(
                                                selectedTypeOfSpace.title,
                                                selectedTypeOfSpace.id,
                                                0,
                                                "type_of_space",
                                                selectedTypeOfSpace.skill_require_for_benchmark
                                              )
                                            }
                                          />
                                        ) : (
                                          <div className="with_separator_10 flex-grow-1 ps-0 text-truncate w100minus100per ">
                                            <span>{space.title}</span>
                                          </div>
                                        )}
                                        <div className={`d-flex align-items-center justify-content-between with_separator_10 pe-0 w_170px`}>
                                        {floorDetail && floorDetail.scan3d && isLoginFromUser ? (
                                         <React.Fragment></React.Fragment> ) :    <a href="#/"
                                         className={`c-font f-22 line-height-0`}
                                         onClick={() =>
                                           deleteTypeOfSpaceItems(
                                             [
                                               space.details
                                                 .filter(
                                                   (x) =>
                                                     x.floor_id ==
                                                     floorDetail.id
                                                 )
                                                 .map((x) => x.id)
                                                 .pop(),
                                             ],
                                             true
                                           )
                                         }
                                       >
                                         <div className="action_icon h32w32 with_bg">
                                         <MinusIcon className="HW16" />
                                         </div>
                                       </a> }
                                          <input
                                            type="number"
                                            className="bg-transparent border-0 text-center "
                                            placeholder={1}
                                            value={quantity}
                                            disabled
                                          />
                                        {floorDetail && floorDetail.scan3d && isLoginFromUser ? (
                                        <React.Fragment></React.Fragment>  ) : <a href="#/"
                                          className="c-font f-22 line-height-0"
                                          onClick={() =>
                                            addNewTypeOfSpace(
                                              [space.title],
                                              1
                                            )
                                          }
                                        >
                                          <div className="action_icon h32w32 with_bg">
                                          <PlusIcon
                                            className="HW16"
                                          />
                                          </div>
                                        </a> }
                                        </div>
                                      </div>
                                      {space.sub_lease_id > 0 ?
                                      <a href="#/"
                                        className="align-items-center border-0 d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05"
                                        onClick={() => {
                                          getLeaseDeatils(space.lease_id, space.sub_lease_id)
                                        }}
                                      >
                                        <FilesIcon
                                          className="HW18"
                                        />
                                      </a> : <></>}
                                      {targetSpaceIdForEdit == space.id ? (
                                        <React.Fragment>
                                          <a href="#/"
                                            className="align-items-center border-0 d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05"
                                            onClick={() => {
                                              saveTypeOfSpaceTitle(
                                                selectedTypeOfSpace.title,
                                                selectedTypeOfSpace.id,
                                                0,
                                                "type_of_space",
                                                selectedTypeOfSpace.skill_require_for_benchmark
                                              );
                                            }}
                                          >
                                            <CheckIcon
                                              className="HW18"
                                            />
                                          </a>
                                          <a href="#/"
                                            className="align-items-center border-0 d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05"
                                            onClick={() => {
                                              setTargetSpaceIdForEdit(0);
                                            }}
                                          >
                                            <XIcon
                                              className="HW18"
                                            />
                                          </a>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <a href="#/"
                                            className="align-items-center border-0 d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05"
                                            onClick={() => {
                                              setTargetSpaceIdForEdit(space.id);
                                              setSelectedTypeOfSpace(space);
                                            }}
                                          >
                                            <PencilIcon
                                              className="HW18"
                                            />
                                          </a>
                                        {floorDetail && floorDetail.scan3d && isLoginFromUser ? (
                                        <React.Fragment></React.Fragment>  ) :   <a href="#/"
                                          className="align-items-center border-0 d-flex form-control h-100 h45w45 justify-content-center ms-2 px-2 on-hover-bg-white-05"
                                          onClick={() => {
                                            deleteTypeOfSpaceItems([
                                              space.id,
                                            ]);
                                          }}
                                        >
                                          <TrashIcon className="HW18" />
                                        </a>}
                                        </React.Fragment>
                                      )}
                                    </li>
                                  );
                                })}
                          </React.Fragment>
                        ) : (
                          <CommanPlaceholder
                            imgType="no-office"
                            placeholderText={"l_add_type_of_space_label"}
                          />
                        )}
                      </ul>
                    </React.Fragment>
                </div>
                <div className="col-xl-8 h-100 pe-0 pb-2">
                  {preview3DScan ? (
                    <Scan3dPreview
                      url={
                        floorDetail && floorDetail.scan3d
                          ? typeof floorDetail.scan3d == "string"
                            ? floorDetail.scan3d
                            : URL.createObjectURL(floorDetail.scan3d)
                          : ""
                      }
                      setShow={() => setPreview3DScan(false)}
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {preview3DScan ? (
                    <></>
                  ) : (
                    <>
                      <div className="h-100">
                          <div className="onboarding-type-of-space p-15 px-0 pb-0 d-flex flex-column h-100 ">
                            <div className="d-flex align-items-center justify-content-between comman_action_icon">
                              <Form.Label className="input-label no-asterisk">
                                {_l("l_type_of_space_items")}
                              </Form.Label>
                              <div className="d-flex align-items-center">
                                {(!currentFloorTypeOfSpaces.length) || (floorDetail && floorDetail.scan3d && isLoginFromUser) ? (
                                  <React.Fragment></React.Fragment>
                                ) : (
                                  <>
                                    <div className="form-check form-check-inline m-0  d-flex align-items-center">
                                      <label className="input-label no-asterisk form-label me-10px mb-0">{_l("l_skill_require_for_benchmark")}</label>
                                      <Form.Check
                                        className=" with-no-label-space"
                                        type="switch"
                                        role="switch"
                                        checked={selectedTypeOfSpace && selectedTypeOfSpace.skill_require_for_benchmark > 0}
                                        onChange={updateSpaceSkillRequirement}
                                      />
                                    </div>
                                    <a href="#/" className="action_icon h32w32 with_bg ms-10px" onClick={() => deleteTypeOfSpaceItems([selectedTypeOfSpace.id,])}>
                                      <TrashIcon className="HW18" />
                                    </a>
                                      <Button size="sm" variant={"primary"} className={`ms-10px me-10px d-flex align-items-center gap10 ${selectesSubFlooeIds.length > 0 && selectedFloorId != -1? "" : "for-disabled"}`}
                                        onClick={() => {
                                          if (selectesSubFlooeIds.length > 0) {
                                            setshowLeaseModal(true);
                                            setisCreate(true);
                                          }
                                        }}>
                                        <PlusIcon className="HW16" />
                                        <div> {_l("l_lease")}</div>
                                      </Button>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="row overflow-auto pt-15">
                              {currentFloorTypeOfSpaces.length ? (
                                <></>
                              ) : (
                                <CommanPlaceholder
                                  imgType="no-office"
                                  placeholderText={"l_add_type_of_space_label"}
                                />
                              )}
                              {selectedTypeOfSpace &&
                              selectedTypeOfSpace.details ? (
                                selectedTypeOfSpace.details.map(
                                  (space, index) => {
                                    if (space.floor_id != floorDetail.id) {
                                      return <React.Fragment></React.Fragment>;
                                    }
                                    return (
                                      <div key={index} className="col-xl-12 mb-2px">
                                        <div className="task-list-card w-100 p-2 m-0 form-control ">
                                          <div class="align-items-center d-flex justify-content-between">
                                            {Number(editingTypeOfSpace) &&
                                            editingTypeOfSpace == space.id ? (
                                              <div className="flex-grow-1 ps-0">
                                                <input
                                                  type="text"
                                                  className="bg-transparent border-0 p-0"
                                                  value={space.title}
                                                  onChange={(e) => {
                                                    setSelectedTypeOfSpace({
                                                      ...selectedTypeOfSpace,
                                                      details: Object.values({
                                                        ...selectedTypeOfSpace.details,
                                                        [index]: {
                                                          ...space,
                                                          title: e.target.value,
                                                        },
                                                      }),
                                                    });
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div class="c-font f-14 text-truncate w100minus100per d-flex align-items-center gap10">
                                                <div className="form-check">
                                                  <input 
                                                  className="form-check-input mt-1" 
                                                  type="checkbox" 
                                                  checked={selectesSubFlooeIds.some((id) => id == space.id)}
                                                  id={`floorcheckbox`}
                                                  onChange={(e)=>{
                                                    setselectedFloorId(space.floor_id)
                                                    handleFloorSelection(space.id, space.floor_id)
                                                  }}
                                                  />
                                                </div>
                                                <div className="mt-1">{space.title}</div>
                                              </div>
                                            )}
                                            <div className="space-right align-items-center d-flex">
                                              <div className="align-items-center d-flex space-right-action-icon comman_action_icon">
                                                {Number(editingTypeOfSpace) &&
                                                editingTypeOfSpace ==
                                                  space.id ? (
                                                  <React.Fragment>
                                                    <a href="#/"
                                                      className="action_icon with_bg h32w32"
                                                      onClick={() => {
                                                        setEditingTypeOfSpace(
                                                          0
                                                        );
                                                      }}
                                                    >
                                                      <XIcon
                                                        className="HW18"
                                                      />
                                                    </a>
                                                    <a href="#/"
                                                      className="action_icon with_bg h32w32 ms-10px"
                                                      onClick={() => {
                                                        if (
                                                          validItemTitle(
                                                            space.title,
                                                            index
                                                          )
                                                        ) {
                                                          saveTypeOfSpaceTitle(
                                                            space.title,
                                                            selectedTypeOfSpace.id,
                                                            space.id,
                                                            "type_of_space_item",
                                                            selectedTypeOfSpace.skill_require_for_benchmark
                                                          );
                                                          setEditingTypeOfSpace(
                                                            0
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <CheckIcon
                                                        className="HW18"
                                                      />
                                                    </a>
                                                  </React.Fragment>
                                                ) : (
                                                  <React.Fragment>
                                                    {space.sub_lease_id > 0 ?
                                                    <a href="#/"
                                                      className="action_icon h32w32 with_bg ms-10px"
                                                      onClick={() => {
                                                        getLeaseDeatils(space.lease_id, space.sub_lease_id)
                                                      }}
                                                    >
                                                      <FilesIcon
                                                        className="HW18"
                                                      />
                                                    </a> : <></>}
                                                    <a href="#/"
                                                      className="action_icon h32w32 with_bg ms-10px"
                                                      onClick={() => {
                                                        setEditingTypeOfSpace(
                                                          space.id
                                                        );
                                                      }}
                                                    >
                                                      <PencilIcon
                                                        className="HW18"
                                                      />
                                                    </a>
                                                    {floorDetail && floorDetail.scan3d && isLoginFromUser ? (
                                                    <React.Fragment> </React.Fragment>) : 
                                                    <a href="#/"
                                                    className="action_icon h32w32 with_bg ms-10px"
                                                    onClick={() => {
                                                      deleteTypeOfSpaceItems(
                                                        [space.id],
                                                        true
                                                      );
                                                    }}
                                                  >
                                                    <TrashIcon className="HW18" />
                                                  </a>}
                                                  </React.Fragment>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </div>
                          </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        viewAIContext ?
          <AIContextModal 
          relType={"floorPlan"} show={viewAIContext} handleClose={()=> {setViewAIContext(false)}} loadDescription={() => {return {contextDescription :'', taskContext: ''}}} handelSave={saveFloorAIContext} data={aiContextData} aiContextDataResponse={aiContextDataResponse}
           aiContextResponse={aiContextDataExtra} floorDetail={floorDetail} spaceDetail={spaceDetail} spaceFloorPlan={spaceFloorPlan} setNewTypeOfSpaceData={deleteTypeOfSpaceItems} currentFloorTypeOfSpaces={currentFloorTypeOfSpaces}/>
        :
          <></>
      }
       {
    selectesSubFlooeIds.length > 0 && selectedFloorId != 1 &&  showLeaseModal ?
      <LeaseModal
      show={showLeaseModal} 
      handleClose={()=>{
        setshowLeaseModal(false);
        setselectedFloorId(-1);
        setSelectesSubFlooeIds([]);
        setSubLeaseId(0);
      }}
      spaceDetail={spaceDetail}
      selectedFloorIds={selectesSubFlooeIds}
      selectedFloorId={selectedFloorId}
      selectedTypeOfSpaceID={selectedTypeOfSpace.id}
      isFrom={"floor-setup"}
      leaseJson={leaseJson}
      leaseDetails={leaseDetails}
      detailsdocuments={documents}
      lease_sub_id={subLeaseId}
      setLeaseJson={setLeaseJson}
      currentFloorId={floorDetail.id}
      setSpaceDetail={setSpaceDetail}
      isCreate={isCreate}
       />
       : <></>
    }
    </div>
  );
}
