import React from 'react';
 

function SentIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M13.7299 7.57094L2.77063 2.05839C2.67914 2.01228 2.57401 1.99273 2.46967 2.00242C2.36491 2.01032 2.26618 2.04976 2.18946 2.11435C2.14283 2.14697 2.10406 2.18777 2.07552 2.23427C2.04697 2.28077 2.02924 2.332 2.02341 2.38485C1.9922 2.47601 1.9922 2.57351 2.02341 2.66467L4.09903 7.86009C4.14025 7.96255 4.14025 8.07476 4.09903 8.17722L2.02341 13.3726C1.9922 13.4638 1.9922 13.5613 2.02341 13.6525C2.04534 13.7458 2.10438 13.8287 2.18946 13.8856C2.26618 13.9502 2.36491 13.9897 2.46967 13.9976C2.57401 14.0073 2.67914 13.9877 2.77063 13.9416L13.7299 8.39175C13.8102 8.34737 13.8777 8.28666 13.9271 8.21453C13.9748 8.1443 14 8.06362 14 7.98135C14 7.89907 13.9748 7.81839 13.9271 7.74816C13.8777 7.67603 13.8102 7.61532 13.7299 7.57094V7.57094Z" />
<path d="M8.31257 7.98145H5.63234H4.59453"/>
</svg>


		</React.Fragment>
	)
}

export default SentIcon