import React from 'react';
 

function ListChecksIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M6.47959 3.36757C6.68259 3.18036 6.69539 2.86404 6.50819 2.66104C6.32098 2.45804 6.00466 2.44523 5.80166 2.63244L3.97236 4.3194L3.23012 3.63293C3.02739 3.44543 2.71104 3.45778 2.52355 3.66051C2.33605 3.86324 2.3484 4.17958 2.55113 4.36708L3.63238 5.36708C3.82381 5.54412 4.11915 5.54434 4.31084 5.36757L6.47959 3.36757ZM8.39062 3.5C8.11448 3.5 7.89062 3.72386 7.89062 4C7.89062 4.27614 8.11448 4.5 8.39062 4.5H13.8906C14.1668 4.5 14.3906 4.27614 14.3906 4C14.3906 3.72386 14.1668 3.5 13.8906 3.5H8.39062ZM8.39062 7.5C8.11448 7.5 7.89062 7.72386 7.89062 8C7.89062 8.27614 8.11448 8.5 8.39062 8.5H13.8906C14.1668 8.5 14.3906 8.27614 14.3906 8C14.3906 7.72386 14.1668 7.5 13.8906 7.5H8.39062ZM7.89062 12C7.89062 11.7239 8.11448 11.5 8.39062 11.5H13.8906C14.1668 11.5 14.3906 11.7239 14.3906 12C14.3906 12.2761 14.1668 12.5 13.8906 12.5H8.39062C8.11448 12.5 7.89062 12.2761 7.89062 12ZM6.50819 6.66104C6.69539 6.86404 6.68259 7.18036 6.47959 7.36757L4.31084 9.36757C4.11915 9.54434 3.82381 9.54412 3.63238 9.36708L2.55113 8.36708C2.3484 8.17958 2.33605 7.86324 2.52355 7.66051C2.71104 7.45778 3.02739 7.44543 3.23012 7.63293L3.97236 8.3194L5.80166 6.63244C6.00466 6.44523 6.32098 6.45804 6.50819 6.66104ZM6.47959 11.3676C6.68259 11.1804 6.69539 10.864 6.50819 10.661C6.32098 10.458 6.00466 10.4452 5.80166 10.6324L3.97236 12.3194L3.23012 11.6329C3.02739 11.4454 2.71104 11.4578 2.52355 11.6605C2.33605 11.8632 2.3484 12.1796 2.55113 12.3671L3.63238 13.3671C3.82381 13.5441 4.11915 13.5443 4.31084 13.3676L6.47959 11.3676Z"/>
</svg>


		</React.Fragment>
	)
}

export default ListChecksIcon