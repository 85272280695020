import React, { useEffect, useState } from "react";
import { CardListFullHeight } from "../hooks/utilities";
import OnboardingEntryPage from "../components/SpaceOnBoarding/OnboardingEntryPage";

const OnboardingNew = () => {

  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId, setProjectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );
  useEffect(() => {
    // DefaultLeftPanel();
    // CardListScroll();
    CardListFullHeight();
    // document.addEventListener('mouseup', leftPanelMouseUpEvent);
    return () => {
      // document.removeEventListener('mouseup', leftPanelMouseUpEvent);
    }
  }, [])

  return (
    <>
      <div className="d-flex flex-column h-100">
        <OnboardingEntryPage pageName="SpaceOnBoardingNew" />
      </div>
    </>
  );
};

export default OnboardingNew;
