import React from 'react';
 

function FlagIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256"className={`comman-icons with-fill ${CommanHW}`}><path d="M36.08,43.37A6,6,0,0,0,34,47.9V216a6,6,0,0,0,12,0V170.78c28.08-22.79,51.88-11,79.34,2.57,16.12,8,33.49,16.58,52,16.58,13.57,0,27.76-4.6,42.56-17.42A6,6,0,0,0,222,168V47.9a6,6,0,0,0-9.93-4.54c-29,25.12-53.28,13.09-81.41-.84C102.77,28.71,71,13.16,36.08,43.37ZM210,165.17c-28.08,22.8-51.88,11-79.34-2.58C105.4,150.08,77.09,136.07,46,156V50.72c28.08-22.8,51.88-11,79.34,2.56C150.6,65.79,178.91,79.8,210,59.91Z"></path></svg>




		</React.Fragment>
	)
}

export default FlagIcon