import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, AddDriveDocument, EditDriveDocuments, getFileType } from "../../hooks/utilities";
import Select from "react-select";
 
import { useDispatch, useSelector } from "react-redux";
import documentsServices from "../../services/documents-services";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { showMessage } from "../../actions/messages";
import DocumentPreview from "../Documents/DocumentPreview";
import benchmarkServices from "../../services/benchmark-services";
import { getIDBDocData } from "../../actions/customer";
import XIcon from "../../assets/icons/XIcon";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";
import FilePdfIcon from "../../assets/icons/FilePdfIcon";
import UserCircleIcon from "../../assets/icons/UserCircleIcon";

const UploadDocument = ({ show, handleClose = () => { }, data = {}, selectedDocument = {} }) => {
     
  const { defaultTopics } = useSelector((state) => state.customer);

  const staffId = localStorage.getItem("staff_id") ? localStorage.getItem("staff_id") : 0;
  const contactId = localStorage.getItem("contact_id") ? localStorage.getItem("contact_id") : 0;
  const clientId = localStorage.getItem("client_id") ? localStorage.getItem("client_id") : 0;
  const projectId = localStorage.getItem("selectedOffice");
  const isDisbaled = selectedDocument.id ? true : false
  const dispatch = useDispatch();


  const [openGallery, setOpenGallery] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [file, setFile] = useState(selectedDocument.id ? {... selectedDocument, name: selectedDocument.file_name} : null);
  const [fileBolbUrl, setFileBolbUrl] = useState(selectedDocument.id ? selectedDocument.image_url : "");
  const [selectedPeople, setSelectedPeople] = useState(selectedDocument.id ? [] : [staffId]);
  const [category, setCategory] = useState("");
  const [loader, setLoader] = useState(false);
  const [ProviderOpearator, setProviderOpearator] = useState([]);
  const [selectedOpearator, setSelectedOpearator] = useState([]);
  const providers = defaultTopics && defaultTopics.filter((topic) => topic.main_key === "providers")[0]
  const servicesList = providers && providers.categories.map((item) => {
    return { value : item.filter_key, label: item.title }
  })
  const RemoveMYRCategory = servicesList && servicesList.filter((item) => item.value != "myrai_support");

  const myPeople = defaultTopics && defaultTopics.length > 0 && defaultTopics.find((topic) => topic.main_key === "my_contact");
  const people = myPeople && myPeople.categories.filter((people) => people.main_sub_key === "people").filter((person) => person.filter_key !== staffId);

  useEffect(() => {
    if (selectedDocument.id && selectedDocument.user_visibility.length > 0) {
      let editPeopleId = [];
      let editOperatorId = [];
      const editModalPeople = selectedDocument.user_visibility.map((doc) => doc.staff_id)
      editModalPeople.map((item) => {
        people && people.map((person) => {
          if (item == person.filter_key) {
            editPeopleId.push(item)
          }
        })
      })
      editOperatorId = editModalPeople.filter((operator) => !editPeopleId.includes(operator));
      setSelectedPeople([...selectedPeople, ...editPeopleId]);
      setSelectedOpearator(editOperatorId);
    }
  }, [selectedDocument])
  
  
  const validateInputFields = () =>{
    let validationSuccess = true;
    let errMessage; 
    if (!category.value) {
      errMessage = _l("l_select_topic");
      validationSuccess = false 
    }
    if (file == null) {
      errMessage = _l("l_file_required");
      validationSuccess = false 
    }
    if (!validationSuccess) {
      dispatch(
        showMessage("unsucess", _l("l_error"), `${errMessage}`)
      );
    }
    return validationSuccess;
  } 

  useEffect(() => {
    if (category.value > 0) {
      setProviderOpearator([]);
      benchmarkServices.getOperatorsByCategory(projectId, category.value ,1, 1, 1).then(
        (res) => {
          if (res.status && res.data) {
            setProviderOpearator(res.data);
          }
        }
      );
    }
  }, [category])

  useEffect(() => {
    let getData = providers && providers.categories.find(items => items.filter_key == selectedDocument.rel_id)
    if(getData) {
      setCategory({ value: getData.filter_key, label: getData.title })
    }
  }, [])

  const handleUploadDocs = async() => {
    if (validateInputFields()) {
      try {
        setLoader(true)
        let selectedPeoples = selectedOpearator.concat(selectedPeople);
        const response = await documentsServices.topicDocumentUpload(staffId, contactId, clientId, projectId, category.value, selectedPeoples, file);
        if (response && response.data) {
          setLoader(false)
          handleClose(1)
          AddDriveDocument(response.data.topic_document[0], Object.keys(response.data)[0]);
          dispatch(getIDBDocData(true));
        }
      } catch (e) { 
        console.error(e);
      } finally {
        setLoader(false)
      }
    }
  }


  const editDocument = async () => {
    if (validateInputFields()) {
      try {
        setLoader(true)
        let selectedPeoples = selectedOpearator.concat(selectedPeople)
        const response = await documentsServices.editTopicDocument(staffId, contactId, clientId, projectId, category.value, "providers", selectedPeoples, selectedDocument.id);
        if (response && response.data) {
          setLoader(false)
          handleClose(1)
          EditDriveDocuments(response.data.topic_document[0], Object.keys(response.data)[0]);
          dispatch(getIDBDocData(true));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false)
      }
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal-style add-topic-search w1300px"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {
        file !== null && openGallery
        ? <DocumentPreview
          open={openGallery}
          setOpen={setOpenGallery}
          slides={[{ src : fileBolbUrl , type: getFileType(file.name) }]}
        />
        : <></>
      }
      <Modal.Header closeButton>
        <Modal.Title>{`${_l("l_upload_documents")}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="with-custom-height80vh pt-0">
        <div className="form-wrapper-main">
          <div className="row custom-modal-section-sticky pb-1">
            <Form.Group className="col-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {`${_l("l_upload_documents")}`}
              </Form.Label>
              <div className="upload-inputbox position-relative ">
                <div className="d-flex  align-items-center">
                  <div className="position-relative flex-grow-1">
                    <input 
                      className="hidden-input" 
                      type="file"
                      accept=".png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.jpeg,.mp4,.m4r,.m4a,.mp3,.glb"
                      onChange={(event) => {
                        if (event.target.files && event.target.files.length !== 0 && event.target.files[0] !== null) {
                          setFile(event.target.files[0])
                          setFileBolbUrl(URL.createObjectURL(event.target.files[0]))
                        }
                      }}
                      disabled={isDisbaled}
                    />
                    <input className="form-control" type="text" disabled />
                    <PaperclipIcon className="HW18 clip-img"/>
                    <div className="uploadbox-text c-font f-12"> {_l("l_upload_file")}</div>
                  </div>
                  <div className="upload-image-preview ms-10px">
                    <div className="d-flex align-items-center justify-content-center">
                        { file === null
                          ? <></>
                          : getFileType(file.name) === "pdf"
                            ? <a onClick={() => setOpenGallery(true)} className="h-100 w-100"> 
                                  <FilePdfIcon className="h-100 w-100 "/>
                                  {
                                    isDisbaled ? <></>
                                    : <a onClick={() => setFile(null)} className="upload-close">
                                        <XIcon className="HW14" />
                                      </a>
                                  }
                              </a>
                            : <div className="comman-image-box h50w50 radius_3 upload-image">
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                                  style={{ backgroundImage: `url('${fileBolbUrl}')` }}
                                  onClick={()=> setOpenGallery(true)}>
                                </div>
                                  {
                                    isDisbaled ? <></>
                                    : <a onClick={() => setFile(null)} className="upload-close">
                                      <XIcon className="HW14" />
                                      </a>
                                  }
                              </div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="col-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {`${_l("l_select_category")}`}
              </Form.Label>
              <Select
                className="custom-select-menu"
                classNamePrefix="react-select"
                placeholder={`${_l("l_select_category")}`}
                options={RemoveMYRCategory}
                value={category}
                onChange={(e) => {
                  setCategory(e)
                }}
              />
            </Form.Group>
            <Form.Group className="col-12 comment-input-box d-flex flex-grow-1">
              <Form.Control
                className="border-0"
                type="text"
                placeholder={`${_l("l_search")}`}
                aria-label="default input example"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
              />
            </Form.Group>
            </div>
            <div className="row">
              <div className="col-lg-6">
              <Accordion
              defaultActiveKey={"0"}
              key={0}
              className={`bg-base-header-color position-sticky top-0`}
            >
              <Card.Header className={`mainTopicTaskFilter new_accordion_toggle bg-transparent custom-accordian-main `}>
                <Accordion.Button 
                  eventKey={0} 
                  className="new_accordion_toggle_padding">
                    <div className="task-list-card p-10-15 list-view  taskcardliteversion overflow-visible onhoverbgtransparent">
                      <div className="task-list-wrapper">
                        <div className="d-flex w-100 align-items-start">
                          <div className="d-flex task-list-detail">
                            <div className="task-left flex-grow-1 width100minus160">
                              <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                                {_l('l_operator')}
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Collapse 
                eventKey={0} 
                in ={true}
                >
                  <Card.Body className="mt-0">
                  <div className="py-1 pt-0  pb-1">
                        <div className="position-relative  w-100 p-0">
                  <div className="comman_vertical_btn_h scroll_110px">
                    <div className="comman-content-scroll-wrapper">
                      <ul className="comman-verticle-tab m-0 max-h40vh overflow-hiiden-auto row">
                        { 
                          ProviderOpearator && ProviderOpearator.length > 0 ?
                          ProviderOpearator.filter((person) => person.fullname.toLowerCase().includes(searchVal.toLowerCase()))
                          .map((person) => {
                            return (
                              <li className="comman-list second-last-child-none with-after-50 p-0 col-xl-6">
                                <a href="#/" className="w-100 position-relative tab_link  left-chat ">
                                  <div className="d-flex align-items-center">
                                    <div className="c-list-icon">
                                      <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                          style={{ backgroundImage: `url('${person.profile_image_url}')` }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                      <div className="top-part pb-1">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="chat-u-name text-truncate">{ person.fullname }</div>
                                          <div className="d-flex align-items-cente">
                                            <div 
                                              className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1"
                                               
                                               
                                               
                                            >
                                            </div>
                                            <div className="topic-switch d-flex align-items-center">
                                              <div className="form-check form-check-inline m-0 ps-2">
                                                <Form.Check
                                                  className="with-no-label-space"
                                                  type="switch"
                                                  role="switch"
                                                  checked={selectedOpearator.find((id) => {
                                                    if (id === person.staffid) {
                                                      return true 
                                                    } else {
                                                      return false
                                                    }
                                                  })}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectedOpearator(prevState => [...prevState, person.staffid])
                                                    } else {
                                                      const removedItems = selectedOpearator.filter((item) => item !== person.staffid)
                                                      setSelectedOpearator(prevState =>  removedItems)
                                                    }
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label p-0"
                                                ></label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                 
                              </li>
                            )
                          })
                          : 
                        <div className="comman-placeholder-wrapper w-100 py-0 d-flex h-100">
                          <div className="d-flex flex-column align-items-center m-auto">
                            <UserCircleIcon className="HW16 small-image opacity-50" />
                            <span className="color-white-80 py-2 title-fonts">
                              {_l("l_no_operator")}
                            </span>
                          </div>
                        </div>
                        }
                      </ul>
                    </div>
                  </div>
                  </div>
                  </div>
                  </Card.Body>
                </Accordion.Collapse>
            </Accordion>
              </div>
              <div className="col-lg-6">
                               <Accordion
              defaultActiveKey={"0"}
              key={0}
              className={`bg-base-header-color position-sticky top-0`}
            >
              <Card.Header className={`mainTopicTaskFilter new_accordion_toggle bg-transparent custom-accordian-main`}>
                <Accordion.Button 
                  eventKey={0} 
                  className="new_accordion_toggle_padding">
                    <div className="task-list-card p-10-15 list-view  taskcardliteversion overflow-visible onhoverbgtransparent">
                      <div className="task-list-wrapper">
                        <div className="d-flex w-100 align-items-start">
                          <div className="d-flex task-list-detail">
                            <div className="task-left flex-grow-1 width100minus160">
                              <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                              {_l('l_people')}
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </Accordion.Button>
              </Card.Header>
              <Accordion.Collapse 
                eventKey={0} 
                in={true}
                >
                  <Card.Body className="mt-0">
                  <div className="py-1 pt-0  pb-1">
                    <div className="position-relative  w-100 p-0">
                      <div className="comman_vertical_btn_h scroll_110px">
                        <div className="comman-content-scroll-wrapper">
                          <ul className="comman-verticle-tab m-0 max-h40vh overflow-hiiden-auto row">
                            { 
                              people && people.length > 0 ?
                              people.filter((person) => person.title.toLowerCase().includes(searchVal.toLowerCase())) 
                              .map((person) => {
                                return (
                                  <li className="comman-list second-last-child-none with-after-50 p-0 col-xl-6">
                                    <a href="#/" className="w-100 position-relative tab_link  left-chat ">
                                      <div className="d-flex align-items-center">
                                        <div className="c-list-icon">
                                          <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                                            <div
                                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                              style={{ backgroundImage: `url('${person.image}')` }}
                                            ></div>
                                          </div>
                                        </div>
                                        <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                                          <div className="top-part pb-1">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <div className="chat-u-name text-truncate">{ person.title }</div>
                                              <div className="d-flex align-items-cente">
                                                <div 
                                                  className="badge badge-white rounded-pill badge  badge-white rounded-pill fw-light me-1"
                                                   
                                                   
                                                   
                                                >
                                                </div>
                                                <div className="topic-switch d-flex align-items-center">
                                                  <div className="form-check form-check-inline m-0 ps-2">
                                                    <Form.Check
                                                      className="with-no-label-space"
                                                      type="switch"
                                                      role="switch"
                                                      checked={selectedPeople.find((id) => {
                                                        if (id === person.filter_key) {
                                                          return true 
                                                        } else {
                                                          return false
                                                        }
                                                      })}
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          setSelectedPeople(prevState => [...prevState, person.filter_key])
                                                        } else {
                                                          const removedItems = selectedPeople.filter((item) => item !== person.filter_key)
                                                          setSelectedPeople(prevState =>  removedItems)
                                                        }
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label p-0"
                                                    ></label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                     
                                  </li>
                                )
                              })
                              : 
                            <div className="comman-placeholder-wrapper w-100 py-0 d-flex h-100">
                              <div className="d-flex flex-column align-items-center m-auto">
                                <UserCircleIcon className="HW16 small-image opacity-50" />
                                <span className="color-white-80 py-2 title-fonts">
                                  {_l("l_no_people")}
                                </span>
                              </div>
                            </div>
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Card.Body>
                </Accordion.Collapse>
            </Accordion>
              </div>
            </div>
           
         
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end">
        <Button variant="secondary" onClick={() => handleClose(0)}>
          {_l("l_close")}
        </Button>
        <Button className={`${loader ? "disabled" : ""}`} variant="primary" onClick={selectedDocument.id ? editDocument : handleUploadDocs}>
          { 
            loader ? (
              <>
                {_l("l_please_wait")}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className="ms-2"
                />
              </>
            ) : (
              _l("l_save")
            )
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDocument;
