import React from 'react';
 

function TopCalendarArrowIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="6" height="8" viewBox="0 0 6 8" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M0.2 4.4C0.1379 4.35343 0.0875002 4.29303 0.0527802 4.22361C0.0180702 4.15418 0 4.07762 0 4C0 3.92238 0.0180702 3.84582 0.0527802 3.77639C0.0875002 3.70697 0.1379 3.64657 0.2 3.6L4.867 0.100001C4.94128 0.0442909 5.02961 0.0103609 5.12209 0.00202088C5.21457 -0.00631912 5.30755 0.0112609 5.3906 0.0527909C5.47366 0.0943109 5.5435 0.158151 5.59232 0.237131C5.64114 0.316121 5.667 0.407141 5.667 0.500001V7.5C5.667 7.5929 5.64114 7.6839 5.59232 7.7629C5.5435 7.8419 5.47366 7.9057 5.3906 7.9472C5.30755 7.9887 5.21457 8.0063 5.12209 7.998C5.02961 7.9896 4.94128 7.9557 4.867 7.9L0.2 4.4Z"/>
</svg>


		</React.Fragment>
	)
}

export default TopCalendarArrowIcon