import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RegistrationFlow from "./RegistrationFlow";
import ForgotPassword from "./Forgot";
import RegistrationNew from "./RegistrationNew";
import ResetPassword from "./ResetPassword";
import SolarExternalBenchmark from "./SolarExternalBenchmark";
import RegistrationFlowNew from "./RegistrationFlowNew";
import ConnectNew from "./ConnectNew";
import WalletSetupForm from "../components/WalletSetup/WalletSetupForm";

const ExternalPageConnector = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const navigate = useNavigate();

  switch(page)
  {
    case "connect":
        return <RegistrationFlow />;
    case "forgot-password":
        return <ForgotPassword />;
    case "contact":
        return <ConnectNew />
    case "registration":
        return <RegistrationNew />;
    case "reset-password":
        return <ResetPassword />;
    case "create-solar-benchmark":
        return <SolarExternalBenchmark />;
    case "register-new-user":
        return <RegistrationFlowNew />;
    case "user-payment-setup":
        return <WalletSetupForm />;
    default:
        navigate("/login");
  }
};

export default ExternalPageConnector;
