import { CHECK_DISCORD_CONNECTIVITY,OUTLOOK_CALENDAR_CONNECTIVITY, DISCORD_TOPICS ,OUTLOOK_CALENDAR_TOPICS,
  GET_REFRESH_REQUEST_DATA,SET_CONTACT_DATA,GET_CONTACT_DATA, IS_ASSIGNED_USER, GET_MEETING_LINK,OUTLOOK_CALENDAR_CONTACTS
, SET_DATE_CLICK, GET_3RD_PARTY_CONTACTS} from "../actions/action-type";

export default function (state = [], action) {
  switch (action.type) {
    case CHECK_DISCORD_CONNECTIVITY: 
      return {...state, discord_connectivity: action.payload};

      case OUTLOOK_CALENDAR_CONNECTIVITY: 
      return {...state, outlook_calendar_connectivity: action.payload};
      
    case DISCORD_TOPICS: 
      return {...state, topics: action.payload};

      case OUTLOOK_CALENDAR_TOPICS: 
      return {...state, outlook_calendar_topics: action.payload};

      case GET_REFRESH_REQUEST_DATA:
        return {
          ...state,
          requestRefresh: action.payload,
        };
      
      case SET_CONTACT_DATA:
        return {...state,   set_contact_data: action.payload }

      case GET_CONTACT_DATA:
          return {...state,   get_contact_data: action.payload }

      case IS_ASSIGNED_USER:
          return {...state,   isAssignedUsers: action.payload }

      case GET_MEETING_LINK:
          return {...state,   getMeetUrl: action.payload }

      case OUTLOOK_CALENDAR_CONTACTS:
            return {...state,   outlookContacts: action.payload }
      case SET_DATE_CLICK:
              return {...state,   newDayClick: action.payload }
      case GET_3RD_PARTY_CONTACTS:
          return {...state,   getTPContacts: action.payload }
    default:
      return state;
  }
}
