import React, { useEffect } from "react";
import { showLeftPanelNew } from "../hooks/utilities";
import ProviderList from "../components/Providertables/ProviderList";
import { useSelector } from "react-redux";

const ProviderTable = () => {
  
  const {commanoffcanvasIsOpen} = useSelector((state) => state.customer)
  useEffect(() => {
    if (commanoffcanvasIsOpen) {
      
    } else {
      showLeftPanelNew(); 
    }
  })
  
  return (
    <>
        <div className="d-flex flex-column h-100">
          <ProviderList  />
        </div>
    </>
  );
};

export default ProviderTable;
