import React, { useMemo } from "react";
import FileTextIcon from "../../../assets/icons/FileTextIcon";

const Document = ({ projectId, clientId, location, zoomLevel, data }) => {
  const memoizedata = useMemo(() => data, [data]);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      {memoizedata.provider === 'one_drive' ?
        (
            <iframe
          src={ memoizedata.webViewLink }
          height="590px"
          width="100%"
        ></iframe>
        )
      :
      memoizedata.exportLinks ? (
        <iframe
          src={
            memoizedata.exportLinks
              ? memoizedata.webViewLink
              : memoizedata.exportLinks
          }
          height="590px"
          width="100%"
        ></iframe>
      ) : memoizedata.fileExtension == "pdf" ? (
        <embed
          src={
            memoizedata.exportLinks
              ? memoizedata.embedable_data[2]
              : memoizedata.embedable_data
          }
          height="590px"
          width="100%"
        ></embed>
      ) : ["png", "jpg", "jpeg", "gif"].includes(memoizedata.fileExtension) ? (
        <img
          src={data.exportLinks ? data.embedable_data[2] : data.embedable_data}
          alt=""
          height="590px"
          width="100%"
        />
      ) : ["wmv", "avi", "mp4", "webm", "flv", "swf", "mov"].includes(
          data.fileExtension
        ) ? (
        <video
          style={{
            width: "100%",
            height: "calc(100vh - 214px)",
          }}
          controls
        >
          <source
            src={
              memoizedata.exportLinks
                ? memoizedata.embedable_data[2]
                : memoizedata.embedable_data
            }
          />
        </video>
      ) : memoizedata.hasThumbnail && memoizedata.thumbnailLink ? (
        <img
          src={memoizedata.thumbnailLink}
          alt=""
          style={{
            width: "100%",
            height: "calc(100vh - 460px)",
            objectFit: "contain",
          }}
        />
      ) : (
        <FileTextIcon className="HW400" />
      )}
    </div>
  );
};
export default Document;
