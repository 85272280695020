import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
const CommanLoader = (props) => {
  const LoaderClass 	= (props.className) ? props.className : "";
  const textArray = ["l_please_wait", "l_hold_on_a_sec", "l_almost_there", "l_give_us_a_moment", "l_hang_tight_getting_data", "l_your_data_is_on_its_way", "l_thank_you_for_your_patience"]
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (currentIndex < textArray.length - 1) {
      const delay = Math.floor(Math.random() * (10000 - 5000 + 1) + 5000);
      timeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % textArray.length);
      }, delay);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <>


<div className={`main-loader-wrapper full-screen with-opacity up-left-panel ${LoaderClass}`}>
                <div className="w-100 h-100 d-flex">
                    <div className="loader-video w-25">
                        <div className="sk-circle">
                            <div className="sk-circle1 sk-child"></div>
                            <div className="sk-circle2 sk-child"></div>
                            <div className="sk-circle3 sk-child"></div>
                            <div className="sk-circle4 sk-child"></div>
                            <div className="sk-circle5 sk-child"></div>
                            <div className="sk-circle6 sk-child"></div>
                            <div className="sk-circle7 sk-child"></div>
                            <div className="sk-circle8 sk-child"></div>
                            <div className="sk-circle9 sk-child"></div>
                            <div className="sk-circle10 sk-child"></div>
                            <div className="sk-circle11 sk-child"></div>
                            <div className="sk-circle12 sk-child"></div>
                        </div>
                        <div className="loader-text c-font f-18">{_l(textArray[currentIndex])}
                            </div>
                        <div className='progress-slider-with-action-icon w-100 mt-15px'>
                            <div className='progress-slider w-100'>
                                <div className='progress-slider w-100'>
                                    <div className='progress count-loading-bar'>
                                        <div className='progress-bar'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
};

export default CommanLoader;