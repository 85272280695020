import React from 'react'
const LeftPanelCategorySkeleton = () => {
  return (
    <div className='d-flex align-items-center flex-grow-1 p-10 SkeletonMain'>
      <div className='h40w40 rounded-5 skeleton-animation'></div>
      <div className='ms-10px flex-grow-1'>
        <div className='mb-1 skeleton-animation radius_3' style={{ width: '70%', height: '15px' }}></div>
        <div className='skeleton-animation radius_3' style={{ width: '25%', height: '10px' }}></div>
      </div>
    </div>
  )
}
export default LeftPanelCategorySkeleton