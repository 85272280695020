import { GOOGLE_OAUTH } from "../actions/action-type";
const initialState = {
  google_oauth_status: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GOOGLE_OAUTH:
      return { ...state, google_oauth_status: payload.google_oauth_status };
    default:
      return state;
  }
}

