import React, { useEffect, useRef, useState } from "react";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import DatePicker from "react-datepicker";
import {
  _l,
  averageTaskChecklistRatings,
  getDatePickerLocale,
  hideLeftPanelNew,
  selectedAssigneeForTaskAndRequest,
  showError,
  showSuccess,
} from "../../hooks/utilities";
import TaskListStatusDropdown from "../Dropdowns/TaskListStatusDropdown";
import AddAssigneeMainDropdown from "./AddAssigneeMainDropdown";
import GeneralListing from "./GeneralListing";
import Form from "react-bootstrap/Form";
import jquery from "jquery";
import moment from "moment";
import CustomerServices from "../../services/customer-services";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import LeadReminderModal from "../Modals/LeadReminderModal";
import SignatureConfirmationIdentity from "../Modals/SignatureConfirmationIdentity";
import { DEFAULT_PLACEHOLDER } from "../../actions/action-type";
import FollowersDropdown from "../Dropdowns/FollowersDropdown";
import Spinner from "react-bootstrap/esm/Spinner";
import { useNavigate } from "react-router-dom";
import EditTaskModal from "../Modals/EditTaskModal";
import RobotPlanDetailModal from "../Modals/RobotPlanDetailModal";
import { getComments } from "../../actions/documents-actions/documents-actions";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import AddAssigneeMainDropdownNew from "./AddAssigneeMainDropdownNew";
import ImageUploadDropdown from "../Dropdowns/ImageUploadDropdown";
import CommentMinified from "../Comments/CommentMinified";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import DocumentsServices from "../../services/documents-services";
import { Dropdown } from "react-bootstrap";
import AddEmailTemplatesModalNew from "../Modals/AddEmailTemplatesModalNew";
import StatusDropdown from "../Dropdowns/StatusDropdown";
import userpaymnetServices from "../../services/userpaymnet-services";
import IbanAddWithSIgnatureModal from "../Modals/IbanAddWithSIgnatureModal";
import customerServices from "../../services/customer-services";
import { fetchMails, getArLocalAnchors, getSelectedTask } from "../../actions/customer";
import ChatCircleIcons from "../../assets/icons/ChatCircleIcons";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import HashIcon from "../../assets/icons/HashIcon";
import ProhibitIcon from "../../assets/icons/ProhibitIcon";
import PushPinIcon from "../../assets/icons/PushPinIcon";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import RepeatIcon from "../../assets/icons/RepeatIcon";
import DownloadSimpleIcon from "../../assets/icons/DownloadSimpleIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
import XIcon from "../../assets/icons/XIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import EnvelopeSimpleIcon from "../../assets/icons/EnvelopeSimpleIcon";
import UserListIcon from "../../assets/icons/UserListIcon";
import StarIcon from "../../assets/icons/StarIcon";
import ImageIcon from "../../assets/icons/ImageIcon";
import DotsNineIcon from "../../assets/icons/DotsNineIcon";
import MinusIcon from "../../assets/icons/MinusIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import CalendarCheckIcon from "../../assets/icons/CalendarCheckIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
const convertMenuFilters = {
  status: 0,
  categories: [],
  date: "",
};
function CommonHeaderBody({ data, type, handleAction, checkListCounts = 0, allData, isExternal, readOnlyMode,contacts = [] , handleSubscriptionBody ,setSelectedContact ,selectedContact, visibleToCustomer, setVisibleToCustomer, visibleToProvider, setVisibleToProvider, loder = false,withAbsPosition,setModalHeaderIconToggle,modalHeaderIconToggle, showDefaultIcons = [], setShowDefaultIcons,
   handleChange,
   equipmentData, 
   previewArray, 
   handleRemoveQuantityFile, 
   handleFileUpload, 
   handleAddComment, 
   commentsList, 
   addEquipment, 
   handleChangeQuantity,
   addNewTypeOfSpace,
   newTypeOfSpaceData,
   removeFloorItem,
   handleDeleteEquipmentComment,
   lastItemId,
   setdata,
   handleClose,
   spaceBasicDetails = {} ,
   leaseDocumentDeatails = {},
   topics = [],
   setIsEditable,
   isEditable,
   setSaveAndDraftEstimate,
   seteditEstimateSubmit,
   editEstimateSubmit
  }) {
  const client_id = localStorage.getItem("client_id");
  const [date, setDate] = useState();
  const [dateTootip, setDateTootip] = useState("");
  const [datePermission, setDatePermission] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [statusClass, setStatusClass] = useState("");
  const [statusPermission, setStatusPermission] = useState("");
  const [taskPriority, setTaskPriority] = useState("");
  const [taskPriorityPermission, setTaskPriorityPermission] = useState(false);
  const [commonPermission, setCommonPermission] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [documentId, seDocumentId] = useState(0);
  const [selectedTagId, setTagId] = useState(0);
  const [assigneesEquipment, setAssigneesEquipment] = useState([]);
  const [assigneesProviders, setAssigneesProviders] = useState([]);
  const userType = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const virtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const virtualspace = localStorage.getItem("selectedVirtualSpace") ? 1 : 0;
  const [checkListCount, setCheckListCount] = useState(0);
  const [convertedTaskId, setConvertedTaskId] = useState(0);
  const [requestAssignee, setRequestAssignee] = useState([]);
  const [documentAssignees, setDocumentAssignees] = useState([]);
  const [documentForMe, setDocumentForMe] = useState({});
  const [downloadUrl, setDownLoadUrl] = useState("");
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [estimateId, setEstimateId] = useState(0);
  const taskStatusShow = ["task", "request-task", "leads", 'gmail'];
  const [buttonShow, setButtonShow] = useState([]);
  const [showLead, setShowLead] = useState(false);
  const handleCloseLead = () => setShowLead(false);
  const handleShowLead = () => setShowLead(true);
  const downLoadUrl = ["estimate", "invoice", "credit_note" ,"subscription"];
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [selectedAssigneeNew, setSelectedAssigneeNew] = useState([]);
  const [myr_total, setMyr_total] = useState(0);
  const [openFollowersDropdown, setOpenFollowersDropdown] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showMapInfoModal, setShowMapInfoModal] = useState(false);
  const [isAcceptEstimateProcessOngoing, setIsAcceptEstimateProcessOngoing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMyrTotal, setShowMyrTotal] = useState(true);
  const [myr_total_permission, setMyr_total_permission] = useState(false);
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  let selectedTheme = localStorage.getItem("selectedTheme");
  const [showEmailTemp, setShowEmailTemp] = useState(false);
  const [showAddIBANModal, setShowAddIBANModal] = useState(false);
  const [IBanDetails, setIBanDetails] = useState({
    account_iban:"",
    company_name:"",
    city: "",
    postal_code:"",
    company_address:"",
    estimate_id: data && data.estimate_id ? data.estimate_id : 0
  });
  const getTPContacts = useSelector(
    (state) => state.discord_auth.getTPContacts
  );
  const checklistStateData = useSelector(
    (state) => state.customer.taskChecklist
  );
  const setContacts = useSelector(
    (state) => state.discord_auth.set_contact_data
  );
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const DefaultTaskAssignees = useSelector(
    (state) => state.customer.defaultTaskAssignees
  );
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const isAssignedUsers = useSelector((state) => state.discord_auth.isAssignedUsers);
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const { getSelectedFloorId, globalView, taskFilters } = useSelector((state) => state.customer);
  const [IBANListing, setIBANListing] = useState([]);
  const adminAsStaff = localStorage.getItem('adminArchieve') && localStorage.getItem('adminArchieve') ? true : false;
  const isExternalYes = localStorage.getItem("accessToken") ? true : false;
  const modalhandleleadClose = () => setShowEmailTemp(false);
  const [signatureImage, setSignatureImage] = useState("");
  const signaturepadRef = useRef();
  const projectId = localStorage.getItem("selectedOffice"); 
  const leadStatusArray = [
    {
      id: "1",
      name: "l_client_kpi",
      eventKey: "1",
      className: "to-do",
      statusdots: "in-progress-white",
    },
    {
      id: "3",
      name: "l_not_interested_kpi",
      eventKey: "2",
      className: "not-started",
      statusdots: "color-orange",
    },
    {
      id: "16",
      name: "l_lead_kpi",
      eventKey: "3",
      className: "done",
      statusdots: "color-green",
    },
    {
      id: "28",
      name: "l_ongoing_kpi",
      eventKey: "4",
      className: "ongoing",
      statusdots: "color-yellow",
    },
    {
      id: "30",
      name: "l_interested_kpi",
      eventKey: "5",
      className: "in-progress",
      statusdots: "in-progress-blue",
    },
  ];
  useEffect(() => {
  }, [isAssignedUsers, getTPContacts, DefaultTaskAssignees, setContacts, JSON.stringify(data), type])
  const loginUserRole = localStorage.getItem("contact_role")
  ? localStorage.getItem("contact_role")
  : 0;
  useEffect(() => {
    if (data && allData) {
      setDateTootip(allData.provider === 'one_drive' && jquery.isNumeric(allData.id) === false ? 'Last Modify Date' :data.date_tooltip);
      setDate(allData.provider === 'one_drive' && jquery.isNumeric(allData.id) === false ? new Date(allData.modifiedTime) : data.date);
      setDatePermission(data.date_readonly);
      setIsPin(data.is_pinned);
      setStatusClass(data.status_class); 
      setStatusPermission(data.status_permission);
      setTaskPriority(data.priority);
      setTaskPriorityPermission(data.priority_permission);
      setCommonPermission(data.common_permission);
      seDocumentId(data.document_id ? data.document_id : 0);
      setTagId(data.tag_id ? data.tag_id : 0);
      setAssigneesEquipment(
        data.assignee_equipments ? data.assignee_equipments : []
      );
      setVisibleToCustomer(data.visible_to_client);
      setVisibleToProvider(data.visible_to_provider);
      setConvertedTaskId(data.convertedTaskId ? data.convertedTaskId : 0);
      setRequestAssignee(data.requestAssignee ? data.requestAssignee : []);
      setDownLoadUrl(data.url ? data.url : "");
      setSubscriptionId(data.document_id ? data.document_id : 0);
      setEstimateId(data.estimate_id ? data.estimate_id : 0);
      setCheckListCount(data.checklist ? data.checklist : checklistStateData ?  checklistStateData.length: " ")
      setDocumentAssignees(localStorage.getItem("user_type") == "staff" ? data.documentAssignees ? data.documentAssignees.myr_staff_task : [] : data.documentAssignees ? data.documentAssignees.my_people : [])
      setDocumentForMe(data.documentAssignees && data.documentAssignees.my_task && data.documentAssignees.my_task.length ? data.documentAssignees.my_task[0] : {})
      if (data && data.buttonShow && data.buttonShow.length) {
        setButtonShow(data.buttonShow)
      } else if (buttonShow.length) {
        setButtonShow(data.buttonShow)
      }
      if (
        type == "request-task" &&
        data.requestAssignee &&
        data.requestAssignee.length
      ) {
        let mainKey =
          data.requestAssignee[0] && data.requestAssignee[0].main_key;
        let id = data.requestAssignee[0] && data.requestAssignee[0].id;
        setTimeout(() => {
          jquery(`.custom-class-add[data-id=${mainKey}_${id}]`).addClass(
            "active with-tick"
          );
        }, 1000);
      }
      if (type == 'task' || type == "request-task") {
        setMyr_total(data.myr_total);
      } else if (taskAssigneePills.length) {
      }
    }
    if (window.location.pathname == '/calendar') {
      if (setContacts != undefined && setContacts.data.status == 1) {
        setContacts.data.data.map((staff, index) => {
          setAssigneesProviders((assigneesProviders) => [...assigneesProviders, staff]);
        })
      } else
        if (getTPContacts) {
          getTPContacts.data.map((staff, index) => {
            setAssigneesProviders((assigneesProviders) => [...assigneesProviders, staff]);
          })
        }
      if (isAssignedUsers && isAssignedUsers.data.data.length > 0) {
        let array = [];
        isAssignedUsers.data.data.map((contact, index) => {
          array.push({
            name: contact.assginee_id
          });
        })
        setTaskAssigneePills(array);
      }
      if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ){
        if(selectedTask && selectedTask.task_assignee.providers != "" && selectedTask.task_assignee.providers != undefined){
          selectedTask.task_assignee.providers.map((contact, index) => {
            setAssigneesProviders((providersArray) => [...providersArray, contact]);
          })
        }
      } 
      if (virtualspace == 1) {
        setAssigneesProviders([]);
        let AssigneeChat = {};
        if (DefaultTaskAssignees) {
          if (DefaultTaskAssignees.task_topic != undefined && DefaultTaskAssignees.task_topic.tasks.categories != undefined) {
            AssigneeChat = DefaultTaskAssignees.task_topic.tasks.categories.filter((s) => s.is_assigned === 1);
            AssigneeChat.map((task, index) => {
              setAssigneesProviders((providersArray) => [...providersArray, {
                name: task.title || task.filter_key,
                email: task.filter_key,
                staffid: localStorage.getItem('staff_id'),
                profile_image: DEFAULT_PLACEHOLDER,
              }]);
            })
          }
        }
        if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ){
          if(selectedTask && selectedTask.task_assignee.providers != "" && selectedTask.task_assignee.providers != undefined){
            selectedTask.task_assignee.providers.map((contact, index) => {
              setAssigneesProviders((providersArray) => [...providersArray, contact]);
            })
          }
        } 
        if (setContacts != undefined && setContacts.data.status == 1) {
          setContacts.data.data.map((staff, index) => {
            setAssigneesProviders((assigneesProviders) => [...assigneesProviders, staff]);
          })
        } else
          if (getTPContacts) {
            getTPContacts.data.map((staff, index) => {
              setAssigneesProviders((assigneesProviders) => [...assigneesProviders, staff]);
            })
          }
      }
    }
    if (type == 'lead') {
      setAssigneesProviders((assigneesProviders) => [...assigneesProviders, data.assignee_providers]);
    }
    setShowMyrTotal(data.show_task_myr_total);
    setMyr_total_permission(data.myr_total_permission);
  }, [isAssignedUsers, getTPContacts, DefaultTaskAssignees, setContacts, JSON.stringify(data), type, selectedTask , checklistStateData]);
  useEffect(() => {
    if(Object.keys(data).length > 0){
       let tempDefaultIcons = []
    if(data.is_pinned == 1 ){
      tempDefaultIcons.push("pin")
    }
    if(data.priority == 1){
      tempDefaultIcons.push("priority")
    }
    if(myr_total >= 0){
      tempDefaultIcons.push("amount")
    }
      setShowDefaultIcons(tempDefaultIcons)
    }
  }, [JSON.stringify(data)])
  useEffect(() => {
    if (data.selectedAssigneesNew) {
      setSelectedAssigneeNew(data.selectedAssigneesNew);
    }
  }, [JSON.stringify(data.selectedAssigneesNew), JSON.stringify(data)])
  useEffect(() => {
    if (selectedAssigneeNew) {
      selectedAssigneeForTaskAndRequest(selectedAssigneeNew, setTaskAssigneePills, setAssigneesProviders,type == "leaseDocument"  ? topics : defaultTopics)
    }
  }, [JSON.stringify(selectedAssigneeNew),defaultTopics])
  useEffect(() => {
  }, [isExternal])
  useEffect(() => {
    if (requestAssignee.length && requestAssignee[0].id) {
      let assign = []
      if (requestAssignee[0].image == "") {
        const ass = data.assigneeList.filter(m => m.id == requestAssignee[0].id)[0]
        assign.push({
          main_key:ass.main_key,
          categories: [{ filter_key: ass.id }]
        })
      } else {
        if (requestAssignee[0].contact_id) {
          const ass2 = data.assigneeList.filter(m => m.id == requestAssignee[0].contact_id)[0]
          assign.push({
            main_key:ass2.main_key,
            categories: [{ filter_key: ass2.id }]
          })
        } else {
          const ass3 = data.assigneeList.filter(m => m.id == requestAssignee[0].id)[0]
          assign.push({
            main_key:ass3.main_key,
            categories: [{ filter_key: ass3.id }]
          })
        }
      }
      let filter = { type: "categories", value: assign }
      convertMenuFilters[filter.type] = filter.value;
      convertMenuFilters['status'] = 1;
      CustomerServices.getTodoTaskList(0, convertMenuFilters,0).then((res) => {
      });
    }
  }, [JSON.stringify(requestAssignee)]);
  useEffect(() => {
    if (!isExternal && allData && !['leaseDocument', "request-task", 'task'].includes(type)) {
      dispatch(getComments(type == "provider_page" ? localStorage.getItem("currentCompanyID") : allData.id, type == "provider_page" ? "provider_page" : "task", isExternal, "", 1, 0)); 
    }
  }, [assigneesProviders.length])
  const isValidSubscribeDay = (date) => {
    const day = date.getDate();
    return day !== 29 && day !== 30 && day !== 31;
  };
  const cancelSubscription = (subscription, type) => {
    dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_subscription"), _l("l_yes"), _l("l_no"), () => {}, async() => {
      try {
        dispatch(toastCloseAction());
        const response = await DocumentsServices.cancelCustomerSubscription(subscription.document_id, type);
        if(response.status)
        {
          if(type !== "at_period_end")
          {
            setdata({...data, status_name: ""});
            showMessage("l_subscription_cancelled");
          }
          else {
            showMessage("l_subscription_cancellation_scheduled");
          }
        }
      }
      catch(e)
      {
        console.error(e);
      }
    }));
  };
  const showEstimateActionButtons = () => {
    let showButtons = true;
    if(!["estimate", "premiumEstimate"].includes(type))
    {
      return false;
    }
    if(!data.type)
    {
      return false  
    }
    if(data.type && ["premium_membership", "operator_premium"].includes(data.type))
    {
      if(!["Sent", "Draft"].includes(data.status_name))
      {
        return false;
      }
    }
    return showButtons;
  };
  useEffect(() => {
    userpaymnetServices.getIBANlisting().then((response)=>{
      if (response && response.status) {
        setIBANListing(response.data)
      }
    })
  }, [])
  const deleteTask = (taskId) => {
    dispatch(
      showConfirmation(
      _l("l_delete"),
      _l("l_you_want_tot_delete_this_task") + "?",
      _l("l_delete"),
      _l("l_cancel"),
      () => {},
      async () => {
        dispatch(toastCloseAction());
          try {
          if (taskId > 0) {
            customerServices.deleteTask(
              taskId,
              projectId
            ).then((res) => {
              if (res.status === 1) {
                dispatch(getArLocalAnchors(getSelectedFloorId));
                dispatch(getSelectedTask(0, 0, null, null, 1));
                handleClose()
              }
            });
          }
          } catch (e) {
          console.error(e);
          }
      }
      )
    );
  }
  const addToSpam = (id) =>{
    const functionWrapper = () => {
      try {
        customerServices.addToSpam(id).then((res) =>{
          if (res && res.status) {
            showSuccess(res.message);
            dispatch(fetchMails(taskFilters))
          }else{
            showError("l_something_went_wrong")
          }
        })      
        } catch (error) {
          console.log(error)
        } finally {
        }
    }
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_do_you_want_to_mark_this_email_as_spam"),
        _l("l_yes"),
        _l("l_no"),
        undefined,
        () => {
          functionWrapper()
        }
      )
    );
  }
  return (
    <>
      <div className={`task-setting-section withbox-shadow comman_action_icon p-0 d-flex flex-wrap  ${type == "type_of_space" ? "d-none" : ""} ${data.show_task_myr_total ? "d-none":""}  ${withAbsPosition ? "with-abs-position":""} ${readOnlyMode ? 'for-disabled' : ''}`}>
        <div className=" d-flex align-items-center flex-wrap dropdown-center">
        <div className="task-setting-right d-flex align-items-center comman_action_icon">
          <React.Fragment>
            {
              userType === "contact" && data.status_name == "active" && [1, 2].includes(Number(loginUserRole))
              ?
              <React.Fragment>
                {
                  data.payment_mode === "stripe"
                  ?
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" className="btn-sm-2" id="dropdown-basic">
                      {_l("l_cancel")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(() => {cancelSubscription(data, "immediately")})}>{_l("l_cancel_immediately")}</Dropdown.Item>
                      <Dropdown.Item onClick={() => {cancelSubscription(data, "at_period_end")}}>{_l("l_cancel__at_period_end")}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  :
                  <Button variant="primary" className="btn-sm-2" onClick={(() => {cancelSubscription(data)})}>
                    {_l("l_cancel")}
                  </Button>
                }
              </React.Fragment>
              :
              <React.Fragment></React.Fragment>
            }
          </React.Fragment>
          {
            data && data.status_name == "not_subscribed" ?
              <div className="task-header-right comman-tab-wrapper p-0 ms-auto">
                <div
                  className={`d-flex align-items-center justify-content-end `}
                >
                  <a href="#/"
                    className="tab-name p-0"
                    onClick={() => {
                      if (selectedContact) {
                        handleSubscriptionBody("ContactEmail",selectedContact)
                      }
                        handleAction("subscribe", date ? date : '');
                    }}
                  >
                    <button type="button" class={`btn btn-primary for-accessible btn-sm-2  line-height-1  ${allData.wallet_id == 0 && allData.proposal_id > 0 && allData.payment_mode === "stripe" ? "d-none" : ""} ${loder ? "for-disabled" : "" }`}>
                    {loder
                    ? _l('l_please_wait')
                    :  _l("l_subscribe")}
                    {
                    loder ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 `}
                        aria-hidden="true"
                      />
                    ) :
                      <></>
                  }
                    </button>
                  </a>
                </div>
              </div>
              : <></>
          }
          {
            showEstimateActionButtons() && ["1", "2"].includes(data.estimate_status) && !["spaceDocument","subscription","invoice","credit_note","lead"].includes(type) ?
              <div
                className={`right-tabing-part for-accessible`}
              >
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    if (data.user_payment_id == 0 && IBANListing.length == 0 ) {
                      setShowAddIBANModal(true);
                      setIBanDetails({...IBanDetails,estimate_id :data.estimate_id})
                    } else {
                      setShowSignaturePad(true);
                    }
                  }}
                  disabled={isAcceptEstimateProcessOngoing}
                >
                  <div>
                    {
                      isAcceptEstimateProcessOngoing
                      ?
                      <div>
                        <span>{_l("l_please_wait")}</span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`ms-2`}
                          aria-hidden="true"
                        />
                      </div>
                      :
                      <div>
                        {_l("l_accept")}
                      </div>
                    }
                  </div>
                </Button>
                <Button
                  className={`ms-2 me-2 ${data.type === "premium_membership" ? "d-none" : ""}`}
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    handleAction("documentEstimateReject");
                  }}
                >
                  {_l("l_reject")}
                </Button>
              </div>
              : <></>
          }
          {type == "request-task" && (data.schedule_id > 0 || selectedTask.schedule_id > 0)?
           <a href="#/" className={`btn btn-primary btn-sm-2 me-10px line-height-1`}
           data-bs-auto-close="outside"
           data-bs-toggle="dropdown"
           aria-expanded="false"
           onClick={() => navigate(`/connect-provider?space=${localStorage.getItem("selectedOffice")}&provider-type=${userType === "operator" ? "proposal" : "benchmark"}&id=${data.schedule_id || selectedTask.schedule_id}&recursive=1`)}
         >
           {_l(userType === "operator" ? "l_send_my_estimate" : "l_view_my_proposals")}
         </a>
            : <></>
          }
          {checkListCount > 0 && type == "request-task" && convertedTaskId == 0 && commonPermission ?
            <>
              < a
                className={`btn btn-primary btn-sm-2 line-height-1 ${convertedTaskId == 0 ? " d-none" : " " }
              `}
                data-bs-auto-close="outside"
                aria-expanded="false"
                onClick={() => {
                  handleAction("requestTaskConvert", {
                    type: "new_task",
                    id: ''
                  });
                }}
              >
                {convertedTaskId == 0
                  ? " "
                  : _l("l_only_one_action_perform_at_a_time")
                }
              </a>
            </>
            : <></>
          }
             {["equipment_task","type_of_space"].includes(type) ? 
          <>
            <div className="d-flex align-items-center justify-content-between h32w100px ms-10px form-control">
              <a href="#/" className="c-font f-22 line-height-0">
                <div
                  className="img-width-18 "
                  onClick={() => {
                    if (type == "type_of_space") {
                      if (newTypeOfSpaceData.quantity > 1) {
                        removeFloorItem("space_item", lastItemId, "item_id")
                    } 
                    } else {
                      if (equipmentData.quantity > 1 && equipmentData.title != "") {
                        handleChange("quantity", Number(equipmentData.quantity) - 1)
                        handleChangeQuantity(Number(equipmentData.quantity) - 1, "minus")
                    }
                    }
                  }}
                >
                  <MinusIcon className="HW16" />
                </div>
              </a>
              <input
                type="text"
                className="bg-transparent border-0 text-center line-height-1"
                placeholder="0"
                name="budget"
                value={type == "type_of_space" ? newTypeOfSpaceData.quantity : equipmentData.quantity} 
                disabled={true}
              />
              <a href="#/" className="c-font f-22 line-height-0">
                <div
                  className="img-width-18 "
                  onClick={() => {
                    if (type == "type_of_space") {
                      addNewTypeOfSpace([newTypeOfSpaceData.title], 1);
                    } else {
                      if ( equipmentData.title != "") {
                        handleChange("quantity", Number(equipmentData.quantity) + 1)
                        handleChangeQuantity(Number(equipmentData.quantity) + 1, "add")
                    }
                    }
                  }}
                >
                  <PlusIcon className="HW16" />
                </div>
              </a>
            </div>
            </>
            : <></>
            }
        {type == "equipment_task" ?
              <Button variant="primary" size="sm" className="ms-10px" onClick={() => addEquipment()}>
                {_l('l_save')}
              </Button>
              :
              <></>}
        </div>
          <div className="w-auto">
            {selectedContact && type === "subscription" &&
                  selectedContact.full_name ? (
                  <div className="assignee-img-with-remove pe-2 ps-1">
                    <div
                      className="h32w32 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle"
                    >
                      <div
                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                        style={{
                          backgroundImage: `url('${selectedContact && selectedContact.profile_image_url ?selectedContact.profile_image_url: "" }')`,
                        }}
                      ></div>
                      {
                       commonPermission ?
                      <a href="#/"
                        className={`remove-img ${data && data.status_name  === "active" ? "d-none" : ""}`}
                        onClick={() => {
                          handleSubscriptionBody("ContactEmail")
                          setSelectedContact()
                        }}
                      >                      
                        <XIcon className="HW10" />
                      </a>
                      : <></>
                      }
                    </div>
                  </div>
                ) : (<></>)
            }
          </div>
          { (showDefaultIcons.includes('status') || modalHeaderIconToggle) && type != "equipment_task" && !["leaseDocument","gmail"].includes(type) ? <div className="dropdown ">
            <div
              className={`white-border-box color-white-60 action_icon h32wauto calendarpadding with_bg dropdown-center ms-10px ${statusPermission === false ? " for-disabled" : " "}`}
              id="TaskListStatusDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <a href="#/" className="d-flex align-items-center dropdown dropdown-center">
                <span
                  className={`c-dots c-10 ${statusClass} rounded-circle`}
                ></span>
                {type == "lead" ? 
                    <StatusDropdown
                    currentStatus={leadStatusArray.filter((item) => item.statusdots == statusClass)[0].id}
                    statuses={leadStatusArray}
                    handler={(status, type) => {
                      handleAction("status", status);
                    }}
                    page='lead'
                    />
                    : 
                    <TaskListStatusDropdown
                      key={2}
                      setTaskStatus={(status) => {
                        if (taskStatusShow.indexOf(type) > -1) {
                          handleAction("status", status);
                        }
                      }}
                      is_task={`${type == 'task' || type == "request-task" ? 1 : 0}`}
                    />
                }
              </a>
            </div>
            </div> : <></>}
            {/* edit lease button start */}
          {
            type == "leaseDocument" && !isExternal ?
              <>
                <a href="#/"
                  id="editlease"
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    localStorage.setItem("leaseDetailType", "lease-contract");
                    navigate(`/space-onboarding?space=${spaceBasicDetails.project_id}&edit=1`);
                    hideLeftPanelNew();
                  }}
                >
                  <div className="btn btn-primary btn-sm-2 text-uppercase line-height-1">
                    {_l("l_edit_lease_contract")}
                  </div>
                </a>
              </> : <></>
          }
            {/* edit lease button end */}
            {/* task rating start */}
          <>
            {(showDefaultIcons.includes("star") || modalHeaderIconToggle || averageTaskChecklistRatings() > 0 )  && !["lead","equipment_task","gmail","leaseDocument"].includes(type) ? <div className="  with_separator_10 before-dnone text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding">
              <a href="#/" className="comman_action_icon w-100 d-flex align-items-center close-toast border-0 on-hover-active-toggle-img text-on-hover-active-green hovertextgreen">
                <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                  {averageTaskChecklistRatings() > 0 ?
                    <StarIcon className="HW18 color-green" color={selectedTheme == "light" ? "#FCA120" : "#00ff6a"} />
                    :
                    <StarIcon className="HW18" />}
                </div>
                {averageTaskChecklistRatings() > 0 ? <span className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle">{(client_id == 1080 || (selectedTask && selectedTask.user_client_id == 1080)) ? `${averageTaskChecklistRatings()} / 20` : averageTaskChecklistRatings()}</span> : <></>}
                  </a>
                </div> : <></>}
              </>
             {/* task rating end */}
            {/* cube icon start */}
            {
            data.rel_type == "equipment" ?
              <>
                {data.surface > 0 ? <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1"
                  >
                  <span className={` p-0 `}>
                    {data.surface}m<sup>2</sup>
                  </span>
                </div> : <></>}
               {data.duration > 0 ? <div className="badge rounded-pill text-nowrap list-toggle-view-none me-1"
                 >
                  <span className={` p-0`}>
                    {data.duration}hrs
                  </span>
                </div> : <></>}
              </>
              :
              <></>
          }
            {/* cube icon end */}
            {/* 3d space modal icon start  */}
            {type == "equipment_task" && modalHeaderIconToggle ?
          <>
              <div className=" dropdown-center dropdown d-flex align-items-center ms-10px">
                <UncontrolledDropdown className="w-100">
                  <DropdownToggle data-toggle="dropdown" tag="span" className=" comman_action_icon w-100 d-flex close-toast border-0 p-0">
                    <a href="#/"
                      onClick={(e) => {
                        setOpenAttachmentsDropdown(true);
                      }}
                    >
                      <div className="d-flex align-items-center w-100">
                        <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img h32w32">
                          <ImageIcon
                            className="HW18"
                          />
                        </div>
                        {previewArray.length > 0 ?
                          <span className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                            {previewArray.length}
                          </span>
                          :
                          <></>
                        }
                      </div>
                    </a>
                  </DropdownToggle>
                  <DropdownItem toggle={false} className="p-0">
                  <ImageUploadDropdown
                    setOpenAttachmentsDropdown={
                      setOpenAttachmentsDropdown
                    }
                    openAttachmentsDropdown={
                      openAttachmentsDropdown
                    }
                    handleFileUpload={handleFileUpload}
                    handleRemoveQuantityFile={(fileIndex,file_id) => {
                      handleRemoveQuantityFile("equipment",equipmentData.id ,file_id, fileIndex);
                    }}
                    previewArray={previewArray}
                    task_id={""}
                    project_id={""}
                    updateImageCount={""}
                    pageName="equipment"
                    taskDetails={""}
                    unContolList={true}
                    from="addequipments"
                  />
                  </DropdownItem>
                </UncontrolledDropdown>
              </div>
              <div className={`dropdown-center dropdown d-flex align-items-center ms-10px`}>
                <UncontrolledDropdown className="w-100">
                  <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon d-flex border-0 p-0">
                    <a href="#/"
                      data-bs-auto-close="false"
                    >
                      <div className="d-flex align-items-center">
                        <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img h32w32">
                          <ChatCircleIcons
                            className="HW16"
                          />
                        </div>
                        {commentsList.length > 0 ? commentsList.length : ''}
                      </div>
                    </a>
                  </DropdownToggle>
                  <DropdownItem toggle={false} className="p-0">
                    {
                      <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddComment}
                        deleteComment={handleDeleteEquipmentComment}
                        id={""}
                        task={""}
                        loading={""}
                        from={"addequipments"}
                        handleImagePreview={""}
                        unContolList={true}
                        showComments={true}
                      />
                    }
                  </DropdownItem>
                </UncontrolledDropdown>
              </div>
            </>
            : <></>
          }
            {/* 3d space modal icon end  */}
          {/* disabled start */}
          { modalHeaderIconToggle && !["leaseDocument"].includes(type)
            ?
            <div className="before-dnone comman_action_icon with_separator_10 me-10px px-0">
              <a href="#/"
                className="action_icon with_bg h32w32 light-theme-img close-toast"
                onClick={() => {
                  addToSpam(allData["thread"]["id"]) 
                }}
              >
                <div className="m-auto ">
                  <ProhibitIcon className="HW18" />
                </div>
              </a>
            </div> : <></>}
          {/* disabled over */}
          {/* Hash start */}
          { modalHeaderIconToggle && !["leaseDocument"].includes(type) ?
            <div className="before-dnone comman_action_icon with_separator_10 me-10px px-0">
              <a href="#/"
                className="action_icon with_bg h32w32 light-theme-img close-toast"
              >
                <div className="m-auto ">
                  <HashIcon className="HW18 fill-white" />
                </div>
              </a>
            </div> : <></>}
          {/* Hash over */}
            {/* priority icon start */}
            {(showDefaultIcons.includes("priority") || modalHeaderIconToggle) && type != "leaseDocument" ? <div className={`white-border-box d-flex align-items-center action_icon h32w32 with_bg  ${type == "lead" ? "me-10px" : ""} ${type == "gmail" ? "me-10px ms-0"  : ""} ${type == "leaseDocument" ? "ms-10px" : ""}`}
              data-className="tooltip-main">
            <a href="#/" className={`priority-checkbox text-truncate ${isExternal ? "for-disabled" : ""}`}>
              <input
                onChange={(e) => {
                  if (type == "equipment_task") {
                    handleChange("priority", e.target.checked ? 1 : 0)
                  }else{
                    handleAction("priority", documentId);
                  }
                }}
                checked={type == "equipment_task" ? equipmentData.priority == 1 : taskPriority == 1 ? true : false}
                type="checkbox"
                className="absolute-input"
                disabled={type == "equipment_task" ? false : taskPriorityPermission}
              />
              <div className="check_box">
                {taskPriority == 1 || equipmentData.priority == 1 ? (
                  <HighPriorityActiveIcon className="HW18 check_span_img active" />
                ) : (
                  <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                )}
              </div>
            </a>
          </div> : <></>}
            {/* priority icon end */}
            {/* pin icon start */}
            {(showDefaultIcons.includes('pin') || modalHeaderIconToggle) && !["equipment_task","leaseDocument"].includes(type) || type == "gmail" ?  <div className={`white-border-box d-flex align-items-center action_icon h32w32 with_bg ${type == "lead " ||  type == "gmail" ? "me-10px" : "ms-10px" }`}
           data-className="tooltip-main">
            <a href="#/" className={` ${isExternal ? "for-disabled":""}`}>
              <div
                className={`  m-auto `}
                onClick={() => {
                  if (convertedTaskId == 0 && type == "request-task") {
                    handleAction("pin");
                  } else if (type != "request-task") {
                    handleAction("pin", documentId);
                  }
                }}
              >
                {isPin || isPin == 1 ? (
                  <PushPinFillIcon className="stroke-transparent Push-Pin-fill HW18" />
                ) : (
                  <PushPinIcon className="HW18" />
                )}
              </div>
            </a>
          </div> : <></>}
            {/* pin icon end */}
          {/* delete icon start */}
            {
              globalView == "spaceView" && (showDefaultIcons.includes('delete') || modalHeaderIconToggle) ?
              // 1 ?
              <div className="comman_action_icon card-view-less-padding p-0 ms-10px">
                <a href="#/"
                  className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32`}
                  onClick={() => {
                    deleteTask(data.document_id);
                  }}
                >
                  <div className="">
                    <TrashIcon className="HW18" />
                  </div>
                </a>
              </div>
              : <></>
            }
          {/* delte icon end */}
            {type == "lead" && <div className={`position-relative me-10px assignee-list-wrapper card-view-less-padding`}>
              <div className="multi_inner_wrapper with-min-width d-flex with_minus">
                <a href="#/"
                  className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                >
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                    style={{
                      backgroundImage: `url('${data && data.assignee_providers &&
                        data.assignee_providers[0].profile_image
                        ? data.assignee_providers[0].profile_image
                        : ""
                        }')`,
                    }}
                  ></div>
                </a>
              </div>
            </div>}
          {/* envelope icon start  */}
          {type == "lead" && <div className={` white-border-box d-flex align-items-center action_icon h32w32 me-10px with_bg test ${type == "gmail" ? "me-0" : ""}`}
          data-className="tooltip-main">
            <a href="#/" className={` priority-checkbox text-truncate ${isExternal ? "for-disabled":""}`}
              onClick={() => {
                setShowEmailTemp(true)
              }}
            >
              <EnvelopeSimpleIcon className="HW18" />
            </a>
          </div>}
           {/* envelope icon end */}
           {/* phone call icon start */}
          {type == "lead" && <div className={` white-border-box d-flex align-items-center action_icon h32w32  with_bg  ${type == "lead" ? "" :"d-none"}`}
           data-className="tooltip-main">
            <a href="#/" className={` priority-checkbox text-truncate ${isExternal ? "for-disabled":""}`}>
              <PhoneIcon className="HW18 " />
            </a>
          </div>}
          {/* phone call icon end */}
           {/* LeadPage Icons Start */}
           <div className="task-setting-right d-flex align-items-center comman_action_icon">
           {
             !modalHeaderIconToggle  ? <></> 
             : buttonShow && buttonShow.indexOf(_l("l_lead_reminder")) > -1 || type == "lead" ?
              <Button variant="secondary" size="sm"  className="d-flex px-1 action_icon h32w32 with_bg mr-10 ml-10"
                   onClick={() => {
                if (buttonShow && buttonShow.indexOf(_l("l_lead_reminder")) > -1 || type == "lead") {
                  handleShowLead();
                }
              }
              }>
                <CalendarCheckIcon className="HW18" />
              </Button>
              : <></>
          }
          {
            type == "lead" ?  
              allData.lead_profile.status != 1 ?
                <>
                {modalHeaderIconToggle && <div className="comman_action_icon me-10px">
                  <a className="d-flex with_bg action_icon h32w32" 
                    onClick={() => {
                    if (type == "lead") {
                      handleAction("convert_as_client", 'contact');
                    }
                  }
                  }>
                    <UserListIcon className="HW18" />
                  </a>
                  </div>}
                  {modalHeaderIconToggle && <div className="comman_action_icon">
                  <a className="d-flex with_bg action_icon h32w32"  
                    onClick={() => {
                    if (type == "lead") {
                      handleAction("convert_as_client", 'operator');
                    }
                  }
                  }>
                    <RepeatIcon className="HW18" />
                  </a>
                  </div>}
                </>
                : <Button variant="primary" size="smaller" className="d-none" onClick={() => {
                  if (type == "lead") {
                    handleAction("access_space", data.spaceProjectId);
                  }
                }
                }>
                  {_l("l_login_as_user")}
                </Button>
              : <></>
          }
           </div>
           {/* LeadPage Icons end */}
      {/* Mail Icon start   */}
        { ["estimate"].includes(type) && data.estimate_status == "1"
          ? <div
            className={`white-border-box dropdown d-flex  action_icon h32w32 with_bg ms-10px`}
          >
            <a href="#/"
              className={`d-flex align-items-center ${isExternal ? "for-disabled":""}`}
              onClick={() => {
                setShowEmailTemp(true)
              }}
            >
              <EnvelopeSimpleIcon className="HW18" />
            </a>
            </div> 
          : <></>
        }
      {/* Mail Icon end */}
            {/* download icon start */}
          {["estimate",].includes(type) && (adminAsStaff || userType == "staff") && isExternalYes  ? 
           <>
            {isEditable ? 
            <>
              <a href="#/"
                className="pe-10px "
                id={_l("l_cancel")}
                data-bs-toggle="dropdown"
                onClick={() => {
                  setIsEditable(!isEditable)
                }}
              >
                <div className="btn btn-secondary btn-sm">
                  {_l("l_cancel")}
                </div>
              </a>
              <a href="#/"
                className="pe-10px"
                id={_l("l_save_and_send")}
                data-bs-toggle="dropdown"
                onClick={() => {
                  setSaveAndDraftEstimate(true)
                }}
              >
                <div className="btn btn-primary btn-sm-2 text-uppercase line-height-1">
                  {_l("l_save_and_send")}
                </div>
              </a>
            </> 
            : <React.Fragment></React.Fragment>}
            <Button
              variant="primary" size="sm"
              onClick={() => {
                if (isEditable) {
                  seteditEstimateSubmit("editEstimateSubmit");
                }else{
                  setIsEditable(!isEditable)
                }
              }}
              disabled={!editEstimateSubmit == "editEstimateSubmit" ? 1 : 0}
            >
              { editEstimateSubmit == "editEstimateSubmit" ? _l('l_please_wait') : isEditable ? _l("l_submit") : _l("l_edit") }
              { editEstimateSubmit == "editEstimateSubmit"  ?
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                : <></>
              }
            </Button>
           </>
          : <></>}
          { !["type_of_space","task","request-task","lead","equipment_task","gmail","provider_page","leaseDocument"].includes(type) ? 
          <div
            className={`white-border-box dropdown d-flex  action_icon h32w32 with_bg ms-10px on-hover-active-toggle-img ${type == "task" || type == "request-task" || type == "lead" || type == "equipment_task"? "d-none" : ""
              }`}
          >
            <a href="#/"
              className={`d-flex align-items-center ${isExternal ? "for-disabled":""}`}
              onClick={() => {
                if (downLoadUrl.indexOf(type) > -1) {
                  handleAction("downloadUrl", downloadUrl);
                }
              }}
            >
              <DownloadSimpleIcon className="HW18" />
            </a>
          </div> 
          : <></>}
            {/* download icon end */}
          {
            Object.keys(documentForMe).length > 0 ?
              <div className={`badge ${type == "request-task" ? "badge-warning" : ""} rounded-pill `}>
                <span className={``}>#{documentForMe.name}</span>
              </div>
              :
              ""
          }
         { ["subscription","estimate","invoice"].includes(type) || documentAssignees.length == 0 ? <></> :
          <div
            className={`multi_inner_wrapper d-flex position-relative mx-2 ${documentAssignees.length == 0 ? "d-none" : "" } ${type == "subscription" || type == "estimate" || type == "invoice" ? "" : "d-none"
              } `}
          >
            <>
              {documentAssignees &&
                documentAssignees.length &&
                documentAssignees[0] && documentAssignees[0].image == "" ? (
                <span className="c-font f-12 fw-light color-white-80 p-0">
                  <span className="ps-0">
                    <HashIcon className="HW12" />
                  </span>{" "}
                  <span className="ps-0">{documentAssignees[0].name}</span>
                </span>
              ) : (
                documentAssignees &&
                documentAssignees.map((assignee, index) => {
                  if (index < 2) {
                    return (
                      <a href="#/"
                        key={index}
                        type="button"
                        id="TaskAssigneeDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="comman-image-box h20w20 rounded-circle"
                      >
                        {assignee.id > 0 ?
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                            style={{
                              backgroundImage: `url('${assignee.profile_image ? assignee.profile_image : ""}')`,
                            }}
                          ></div>
                          :
                          <UsersThreeIcon className="HW18" />
                        }
                      </a>
                    );
                  }
                })
              )}
            </>
            {documentAssignees.length > 2 ? (
              <>
                <a href="#/"
                  className="comman-image-box h20w20 with_overlay d-flex rounded-circle"
                  type="button"
                  id="TaskAssigneeDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="unread_count m-auto">{`+${documentAssignees.length - 2
                    }`}</div>
                </a>
              </>
            ) : (
              <></>
            )}
            {documentAssignees.length > 0 ? (
              <GeneralListing
                list={documentAssignees.map((item, index) => {
                  return {
                    name: item.name || item.full_name,
                    image: item.profile_image ? item.profile_image : "",
                    id: index,
                  };
                })}
                handler={() => { }}
              />
            ) : (
              <></>
            )}
          </div>}
          {/* assignees start */}
         {["task","request-task","leaseDocument"].includes(type) &&
         <div
            className={`multi_image_wrapper d-flex align-items-center dropdown w-auto ${ ["task","request-task","leaseDocument"].includes(type) || type == "lead" ? "" : "d-none"
              } `}
            aria-expanded="false"
            onClick={() => {
              handleAction("handleTaskAssigneeData");
            }}
          >
            {assigneesProviders.length > 0 && <div className={`multi_inner_wrapper d-flex afternone position-relative ms-10px ${assigneesProviders.length === 0 ? "d-none":"" }`}>
              {assigneesProviders && assigneesProviders.length ? (
                <>
                  {assigneesProviders.map((assignee, index) => {
                    if (index < 2) {
                      return (
                        <a href="#/"
                          type="button"
                          aria-expanded="false"
                          className="comman-image-box h32w32 rounded-circle"
                          key={index}
                        >
                          <div
                            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                            style={{
                              backgroundImage: `url('${assignee.profile_image}')`,
                            }}
                          ></div>
                        </a>
                      );
                    }
                  })}
                </>
              ) : (
                <></>
              )}
              {assigneesProviders && assigneesProviders.length > 2 ? (
                <>
                  <a href="#/"
                    className="comman-image-box h32w32 with_overlay d-flex rounded-circle"
                    type="button"
                    id="TaskAssigneeDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="unread_count m-auto">{`+${assigneesProviders.length - 2
                      }`}</div>
                  </a>
                </>
              ) : (
                <></>
              )}
              {assigneesProviders && assigneesProviders.length ? (
                <GeneralListing
                  list={assigneesProviders.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                  }).map((item, index) => {
                    return {
                      name: item.full_name || item.name,
                      image: item.user_image || item.profile_image,
                      id: item.id,
                    };
                  })}
                  handler={() => { }}
                  customSort={true}
                />
              ) : (
                <></>
              )}
            </div>}
            <div
            className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus ${taskAssigneePills && taskAssigneePills.length >= 1
              ? ""
              : "d-none"
              }`}
          >
            {taskAssigneePills && taskAssigneePills.length >= 1 ? (
              taskAssigneePills.map((assignee, index) => {
                if (index < 1)
                  return (
                    <div
                      className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px"
                      key={index}
                    >
                      <span className={` p-0 `}>
                        {assignee.main_key == "my_task" ? "#" : ""}{_l(assignee.name)}
                      </span>
                    </div>
                  );
              })
            ) : (
              <></>
            )}
            {taskAssigneePills && taskAssigneePills.length > 1 ? (
              <a href="#/"
                className="badge rounded-pill text-nowrap list-toggle-view-none me-1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="unread_count m-auto c-font f-12">
                  +{taskAssigneePills.length - 1}
                </div>
              </a>
            ) : (
              <></>
            )}
            {taskAssigneePills && taskAssigneePills.length ? (
              <GeneralListing
                handler={() => { }}
                list={
                  taskAssigneePills &&
                  taskAssigneePills.length &&
                  taskAssigneePills.map((item, index) => {
                    return {
                      name: item.name,
                      id: index,
                    };
                  })
                }
                isHash={true}
              />
            ) : (
              <></>
            )}
          </div>
          </div>}
          {/* assignees end */}
          {/* amount start */}
          {(type == "task" || type == "request-task") && type != "equipment_task" ? (
              <input
                className={`badge-white color-green c-font f-14 ms-10px fw-semibold p-1 radius_3 text-center title-fonts calednarwithborder max-width75 h32wauto ${
                  showMyrTotal ? "" : "d-none"
                }`}
                placeholder="$300"
                type="text"
                value={"€ " + myr_total}
                id="myr_total"
                onChange={(e) => {
                  if (parseFloat(e.target.value) !== NaN) {
                    let TempTotal = e.target.value;
                    setMyr_total(TempTotal.replaceAll("€", "").trim());
                    handleAction(
                      "setMyr_total",
                      TempTotal.replaceAll("€", "").trim()
                    );
                  }
                }}
                onBlur={(e) => {
                  if (myr_total !== NaN && myr_total > 0) {
                    let TempTotal = e.target.value;
                    handleAction(
                      "myrTotalUpdate",
                      TempTotal.replaceAll("€", "").trim()
                    );
                  }
                }}
                ref={(reference) => {
                  handleAction("totalRef", reference);
                }}
                disabled={myr_total_permission}
              />
            ) : (
              <></>
            )}
          {/* amount end */}
          {/* gmail pill start */}
          { type == "gmail" &&
           <div className={`position-relative d-flex assignee-list-wrapper card-view-less-padding ps-2`}>
          <div
            className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus `}
          >
            <div className="pill-min-width pill-bottom-show  text-end">
              <div className="d-flex align-items-center">
                <a href="#/" className="badge  badge-white rounded-pill fw-light">
                  <span className="color-white p-0">{data.pills}
                  </span>
                </a>
              </div>
            </div>
          </div>
          </div>}
          {/* gmail pill end */}
          {/* lease Pills start */}
          {type == "leaseDocument" 
          &&  <div className="ms-10px">
            <div className="badge   rounded-pill fw-light h32wauto c-font f-12 d-flex align-items-center "
              data-bs-toggle="dropdown"
              aria-expanded="false"
               >
              <span className={` p-0 `}>{leaseDocumentDeatails.status == '1' ? _l("l_active") : leaseDocumentDeatails.status == '2' ? _l("l_future") : _l("l_inactive")}</span>
            </div>
          </div>}
          {/* lease Pills end */}
          {/*separator line start  */}
         {window.location.pathname == "/providerlist" ?  <></> : showDefaultIcons.length > 0 || modalHeaderIconToggle ?  <div className="px-0 with_separator2_10 ms-10px 123"></div> : <></>}
          {/*separator line end  */}
          {/* calendar start */}
          {!["lead", "type_of_space", "documents","provider_page", "gmail"].includes(type)  ? 
          <a href="#/"
            className={`comman_action_icon custom-datepicker w-330px withinputabsolute ms-10px ${type == "subscription" ? '' : 'w-330px'} ${!datePermission && (!isExternal || (type == "subscription" && buttonShow && buttonShow.indexOf(_l("l_subscribe")) > -1 && data && data.status_name == "not_subscribed")) ? "for-accessible" : ""} ${date ?"" :"withinputabsolute"}`}
            data-bs-toggle="tooltip"
            currentitem="false"
          >
            <div className={`action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative overflow-hidden  ${isExternal ? 'for-disabled':""}`}>
              <CalendarBlankIcon className="HW18" />
              {
                  type == "equipment_task" ?
                    <DatePicker
                      selected={date}
                      locale={getDatePickerLocale()}
                      disabledKeyboardNavigation
                      onChange={(date) => handleChange("defalutLifeTime", date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={_l('l_select_start_date')}
                      calendarClassName={`custom-datepicker`}
                      selectsStart
                      calendarStartDay={1}
                      defaultMenuIsOpen
                      portalId="custom_datepicker"
                    ><div className="datepicker-label ps-2">{_l("l_select_date")}</div></DatePicker>
                    :
              <DatePicker
              portalId="custom_datepicker"
                selected={date}
                locale={getDatePickerLocale()}
                onChange={(currentDate) => {
                  setDate(currentDate);
                  handleAction("dateUpdate", currentDate);
                }}
                showTimeSelect={type == "subscription" ? false : true}
                dateFormat="dd/MM/yyyy"
                placeholderText={dateTootip}
                calendarClassName="custom-datepicker"
                minDate={type == "subscription" ? new Date(moment().add("days", 10).format("YYYY-MM-DD")) : ""}
                readOnly={!isExternal || (type == "subscription" && buttonShow && buttonShow.indexOf(_l("l_subscribe")) > -1 && data && data.status_name == "not_subscribed") ? datePermission : true}
                filterDate={type == "subscription" ? isValidSubscribeDay : false}
                calendarStartDay={1}
              ><div className="datepicker-label ps-2">{_l("l_select_date_and_time")}</div></DatePicker>
              }
            </div>
          </a>
          :
          <></>}
          {/* calendar end */}
           {/* assignee start */}
           {!["type_of_space","equipment_task","gmail","leaseDocument"].includes(type) && modalHeaderIconToggle ? 
          <div
            className={`d-flex white-border-box  d-flex  action_icon ${taskAssigneePills.length == 0 && assigneesProviders.length == 0 ? "h32wauto" : "h32w32"} with_bg ms-10px ${commonPermission || contact_role != 3 ? "" : "for-disabled"
              } ${!isExternal || data.status_name == "not_subscribed" ? "" : "d-none"}`}
              data-bs-auto-close="outside"
          >
              <UncontrolledDropdown setActiveFromChild={true}  direction="end" isOpen={openAssigneeDropdown} className="w-200 bg-transparent h-100" id="Loginuserdropdown">
                  <DropdownToggle className={`border-0 h-100 py-0  ${taskAssigneePills.length == 0 && assigneesProviders.length == 0 ? "" : "p-0"}`}
                  onClick={() => {
                    if(type == "request-task" || type == "task"){
                      setOpenAssigneeDropdown(!openAssigneeDropdown)
                    }
                  }}
                  >
                    <UserCircleGearIcon className="HW18" />
                    {assigneesProviders.length == 0 && taskAssigneePills.length == 0 ? <span className="c-font f-12 ps-2 text-uppercase no-display-in-responsive text-on-hover-active-green">{_l('l_assign_to')}</span> :<></>}
                  </DropdownToggle>
              {type == "subscription" ? <>
              <FollowersDropdown
                openFollowersDropdown={
                  openFollowersDropdown
                }
                setOpenFollowersDropdown={
                  setOpenFollowersDropdown
                }
                task_id={data.id}
                checklistId={" "}
                handler={()=>{ }}
                checklistAssigneesList={contacts}
                is_task_checklist={1}
                selectedId={" "}
                type ={"subscription"}
                handleSubscriptionBody={handleSubscriptionBody}
                setSelectedContact={setSelectedContact}
                 /></>
              :<>
              {( type == "estimate" || type == "invoice" || type == "gmail") ? (
                <AddAssigneeMainDropdown
                  projectId={localStorage.getItem("selectedOffice")}
                  taskId={documentId}
                  openAssigneeDropdown={openAssigneeDropdown}
                  setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                  handleProvider={(type, value, details) => {
                    handleAction("assignees", {
                      type: type,
                      value: value,
                      details: details,
                    });
                  }}
                  providerDefaultType={"providerDefaultType"}
                  providerDefaultValue={"providerDefaultValue"}
                  providerDefaultDetails={"providerDefaultDetails"}
                  selectedTagId={selectedTagId}
                  isCreateTask={null}
                  hangleTag={(tab, is_remove = 0) => {
                    handleAction("tag", { tab: tab, is_remove: is_remove });
                  }}
                  moduleType={type}
                  documentAssignees={data.documentAssignees ? data.documentAssignees : []}
                />
              ) 
              : (commonPermission || contact_role != 3) && (type == "request-task" || type == "task") ?
              (
                <>
                  <AddAssigneeMainDropdownNew
                    taskId={documentId}
                    openAssigneeDropdown={
                      openAssigneeDropdown
                    }
                    setOpenAssigneeDropdown={
                      setOpenAssigneeDropdown
                    }
                    fromTaskcard="0"
                    pageName='task'
                    selectedId={data && data.task_assignee_ids}
                    handleProvider={(data, sub_categories,sub_category) => {
                      handleAction("assignees", data,sub_categories,sub_category);
                    }}
                    myr_total={myr_total}
                    isRequest = {type == "request-task"}
                    selectedAssignee={selectedAssigneeNew}
                  setSelectedAssignee={setSelectedAssigneeNew}
                  />
                </>
              )
               : (window.location.pathname == '/documents') && (type == "request-task" || type == "task") ? (
                <>
                  <AddAssigneeMainDropdown
                    projectId={localStorage.getItem("selectedOffice")}
                    taskId={documentId}
                    openAssigneeDropdown={openAssigneeDropdown}
                    setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                    oneDriveAssignees = {allData.assignee_list}
                    oneDriveContacts = {allData.contacts}
                    handleProvider={(type, value, details) => {
                      handleAction("assignees", {
                        type: type,
                        value: value,
                        details: details,
                      });
                    }}
                    keyTrigger="calendar"
                  />
                </>
              ) 
              : (
                  <></>
                )}
              </>}
          </UncontrolledDropdown>
            {/* </div> */}
            {(type == "subscription" || type == "estimate" || type == "invoice") ? (
              <AddAssigneeMainDropdown
                projectId={localStorage.getItem("selectedOffice")}
                taskId={documentId}
                openAssigneeDropdown={openAssigneeDropdown}
                setOpenAssigneeDropdown={setOpenAssigneeDropdown}
                handleProvider={(type, value, details) => {
                  handleAction("assignees", {
                    type: type,
                    value: value,
                    details: details,
                  });
                }}
                providerDefaultType={"providerDefaultType"}
                providerDefaultValue={"providerDefaultValue"}
                providerDefaultDetails={"providerDefaultDetails"}
                selectedTagId={selectedTagId}
                isCreateTask={null}
                hangleTag={(tab, is_remove = 0) => {
                  handleAction("tag", { tab: tab, is_remove: is_remove });
                }}
                moduleType={type}
                documentAssignees={data.documentAssignees ? data.documentAssignees : []}
              />
            ) : (commonPermission || contact_role != 3) && (type == "request-task" || type == "task") && data.schedule_id <= 0 ? (
              <>
                <AddAssigneeMainDropdownNew
                  taskId={documentId}
                  openAssigneeDropdown={
                    openAssigneeDropdown
                  }
                  setOpenAssigneeDropdown={
                    setOpenAssigneeDropdown
                  }
                  fromTaskcard="0"
                  pageName='task'
                  selectedId={data && data.task_assignee_ids}
                  handleProvider={(data) => {
                    handleAction("assignees", data);
                  }}
                  myr_total={myr_total}
                  isRequest = {type == "request-task"}
                  selectedAssignee={selectedAssigneeNew}
                  setSelectedAssignee={setSelectedAssigneeNew}
                />
              </>
            ) : (
              <></>
            )}
          </div> 
          :<></>}
          {/* assignee end */}
          <div
            className={`multi_image_wrapper  d-flex ${type == "task" ? "d-none" : "d-none"
              }`}
          >
            {assigneesEquipment ? (
              <>
                {assigneesEquipment.map((assignee, index) => {
                  if (index < 2) {
                    return (
                      <a href="#/"
                        key={index}
                        data-bs-toggle="tooltip"
                        className="comman-image-box h25w25 radius_3"
                      >
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                          style={{
                            backgroundImage: `url('${assignee.profile_image}')`,
                          }}
                        ></div>
                      </a>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
            {assigneesEquipment && assigneesEquipment.length > 2 ? (
              <a href="#/"
                data-bs-toggle="tooltip"
                className="comman-image-box h25w25 with_overlay d-flex rounded-circle"
              >
                <div className="unread_count m-auto">{`+${assigneesEquipment.length - 2
                  }`}</div>
              </a>
            ) : (
              <></>
            )}
          </div>
          <div className="d-flex align-items-center flex-wrap w-auto d-none">
           <div className={`align-items-center before-dnone color-white-60 d-flex px-0 with_separator_10 ${userType == "staff" &&
              !virtualSpaces &&
              (type == "task" || type == "request-task")
              ? ""
              : "d-none"
              }
                }`}>
               <span>{_l("l_visible_for")}</span> <span className="ps-2">:</span>
           </div>
          <Form.Check
            className={`d-flex justify-content-end c-font f-11 mx-2 ${userType == "staff" &&
              !virtualSpaces &&
              (type == "task" || type == "request-task")
              ? ""
              : "d-none"
              }`}
            type="checkbox"
            id=""
            label={"customer"}
            checked={visibleToCustomer}
            onChange={(e) => {
              setVisibleToCustomer(e.target.checked);
              handleAction("visible_to_client", e.target.checked);
            }}
          />
          <Form.Check
            className={`d-flex justify-content-end c-font f-11 mx-2 ${userType == "staff" &&
              !virtualSpaces &&
              (type == "task" || type == "request-task")
              ? ""
              : "d-none"
              }`}
            type="checkbox"
            id=""
            label={_l('l_provider')}
            checked={visibleToProvider}
            onChange={(e) => {
              setVisibleToProvider(e.target.checked);
              handleAction("visible_to_provider", e.target.checked);
            }}
          />
          </div>
          { !["estimate","spaceDocument","subscription","invoice","credit_note","lead"].includes(type) ?
           <div className="color-white-80 w-auto c-font f-12 d-flex text-nowrap">
            {type == "subscription" ?
              <div className=" pe-2 text-nowrap with_separator_10 ">
                {_l('l_sid')}   : #{subscriptionId}
              </div>
              : type == "estimate" ?
                <div className=" ps-2 text-nowrap ">
                  {_l('l_eid')}  : #{estimateId}
                </div>
                :
                ""
            }
          </div> : <></>}
        </div>
        {isExternal ? <></> :
        <div className="task-setting-right d-flex align-items-center comman_action_icon 1">
            {
              !["type_of_space", "documents","provider_page", "estimate","spaceDocument","subscription","invoice","credit_note","lead"].includes(type) ? 
            <a href="#/"
              className={`h32w32 d-flex action_icon with_bg with-border-active ms-10px on-hover-active-toggle-img ${modalHeaderIconToggle ?"active":""}`}
              onClick={() => {
                setModalHeaderIconToggle(!modalHeaderIconToggle);
              }}
            >
              <DotsNineIcon className="HW18" />
            </a> 
            :
            <></>
          }
        </div>
        }
      </div>
      {
        showLead ? (
          <LeadReminderModal
            show={showLead}
            reminderData={allData}
            handleClose={handleCloseLead}
            handleShow={handleShowLead}
          />
        ) : (
          <></>
        )
      }
      {
        showSignaturePad ? (
          <SignatureConfirmationIdentity
            show={showSignaturePad}
            external={true}
            projectId={0}
            estimate={data}
            hash={allData.hash}
            handleClose={() => {
              setShowSignaturePad(false);
            }}
            callBack={() => {
              handleAction("documentEstimateAccept", 'Accepted');
            }}
            isExternal={isExternal}
            showIbanSelection={true}
            ibanID={data.user_payment_id == 0 && IBANListing.length == 0 ? data.user_payment_id : data.user_payment_id == 0 && IBANListing.length > 0 ? IBANListing[0].id : data.user_payment_id }
            signatureImg = {signatureImage}
            setSignatureImg = {setSignatureImage}
            signaturepadRef = {signaturepadRef}
          />
        ) : (
          <></>
        )
      }
      {
        showMapModal ?
          <EditTaskModal
            show={showMapModal}
            handleClose={() => {
              setShowMapModal(false);
            }}
            task_id={data.document_id}
            surface={data.surface}
            taskDuration={data.duration}
            attachment={data.attachment}
          />
          :
          <></>
      }
      {
        showMapInfoModal ?
          <RobotPlanDetailModal
            show={showMapInfoModal}
            handleClose={() => {
              setShowMapInfoModal(false);
            }}
            handleOpenEdit={() => {
              setShowMapModal(true);
            }}
            surface={data.surface}
            duration={data.duration}
            attachment={data.attachment}
            isExternal={isExternal}
          />
          :
          <></>
      }
      {showEmailTemp ?
            <AddEmailTemplatesModalNew 
                show={showEmailTemp}
                handleClose={modalhandleleadClose}
                lead = {data}
                type={data.estimate_id ? "estimate" : ""}
                from={data.estimate_id ? "existingEstimate" : ""}
            />
            :<></>
        }
        {showAddIBANModal ? <>
          <IbanAddWithSIgnatureModal
            show={showAddIBANModal}
            external={true}
            projectId={0}
            estimate={data}
            hash={allData.hash}
            handleClose={() => {
              setShowAddIBANModal(false);
            }}
            callBack={() => {
            }}
            isExternal={isExternal}
            showIbanSelection={true}
            ibanID={data.user_payment_id == 0 && IBANListing.length == 0 ? data.user_payment_id : data.user_payment_id == 0 && IBANListing.length > 0 ? IBANListing[0].id : data.user_payment_id }
          />
        </> :<></>}
    </>
  );
}
export default CommonHeaderBody;
