import React, { useEffect, useState } from "react";
import { _l } from "../../../../hooks/utilities";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
import LeadCommanCard from "./LeadCommanCard";
const LeadEmailTracking = ({ projectId, clientId, location, zoomLevel, data }) => {


    const [leadEmails, setLeadEmails] = useState(data);
    const [openAccordion, setOpenAccordion] = useState('0');

    useEffect(() => {
        setLeadEmails(data);
    }, [JSON.stringify(data)]);

    const handleAccordionToggle = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
      };
    return (
        <div className="d-flex flex-column flex-grow-1 p-10 py-0">
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex flex-column flex-grow-1">
                    <div className="comman-content-scroll-wrapper d-flex flex-column flex-grow-1">
                        <div className="comman-content-scroll d-flex flex-column flex-grow-1 ">
                            <div className=" custom-accordian-main comman-data-table small-data-table d-flex flex-column flex-grow-1">
                                {leadEmails && leadEmails.length > 0 ?
                                    <>
                                        {leadEmails.map((leadEmail, index) => {
                                            return (<>
                                            <LeadCommanCard data={leadEmail} type={'email'} handleAccordionToggle={handleAccordionToggle} index={index} openAccordion={openAccordion}/>
                                            </>)
                                        })

                                        }
                                    </> :
                                    <>
                                        <CommanPlaceholder imgType="email" mainWidth="m-auto" placeholderText={_l('l_no_tracked_emails_sent')} />
                                    </>}
                            </div>
                        </div>
                    </div >
                </div >
            </div >
             
        </div >
    );
};
export default LeadEmailTracking;