import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { _l } from "../../../hooks/utilities";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";

const ViewTenants = ({tenants}) => {
  return (
    <Row className="flex-grow-1 justify-content-center h-100">
      <Col className="d-flex flex-column flex-grow-1 h-100" xs={12}>
        <Form.Label className="c-font f-14 title-fonts mb-3">
          {_l("l_tenants")}
        </Form.Label>
        <div className="border-dashed-2px radius_3 flex-grow-1 overflow-auto  padding-bottom-60px">
          <div className="px-2 dealing-list">
            {tenants.map((tenant, index) => {
              return (
                <a href="#/"
                  key={index}
                  className={`d-flex align-items-center title-fonts comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active color-white-60`}
                >
                  <div
                    className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url("${tenant.profile_image}")`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="ps-2 text-truncate  d-flex align-items-center flex-grow-1 w100minus100per">
                      {tenant.full_name}
                    </div>
                    <div className="text-truncate  d-flex align-items-center flex-grow-1 w100minus100per">
                      {tenant.email}
                    </div>
                    <div className="text-truncate  d-flex align-items-center flex-grow-1 w100minus100per">
                      {tenant.phone_number}
                    </div>
                    <div className={`badge ${tenant.is_psw_set == 1 ? "badge-warning" : "badge-red"}  rounded-pill fw-light `}>
                      <span className={` p-0`}>
                        {tenant.is_psw_set == 1 ? _l("l_registered") : _l("l_not_registered")}
                      </span>
                    </div>
                  </div>
                </a>
              );
            })}
            {
              !tenants.length
              ?
              <CommanPlaceholder imgType="no-userfound" placeholderText = {_l("l_no_tenants")}/>
              :
              <></>
            }
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ViewTenants;
