import React, { useEffect, useState } from "react";
import UserPlaceholder from "../../assets/images/default-image-placeholder.png";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useDispatch, useSelector } from "react-redux";
import { assignSlotToOperator } from "../../actions/benchmark-actions/benchmark-actions";
import { _l } from "../../hooks/utilities";
 
import Modal from "react-bootstrap/Modal";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import InfoIcon from "../../assets/icons/InfoIcon";
import HashIcon from "../../assets/icons/HashIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import ProhibitIcon from "../../assets/icons/ProhibitIcon";
const ScheduleDropDown = ({ staff, show, modal1handleClose }) => {
  const scheduleDataStore = useSelector((state) => state.benchmarkReducer);
  const scheduleData = scheduleDataStore.benchmarks;

  const dispatch = useDispatch();

  const [operatorSchedule, setOperatorSchedule] = useState(
    scheduleData ? scheduleData : []
  );
  const [checklistItemsForSlot, setChecklistItemsForSlot] = useState([]);
  const [slotWeekSchedule, setSlotWeekSchedule] = useState([]);

  const { filter_key: staffId } = staff;

  useEffect(() => {
    if (staffId > 0 && scheduleData.length) {
      setOperatorSchedule(scheduleData);
    }
  }, [staffId, JSON.stringify(scheduleData)]);

  const assignSingleSlot = (scheduleId, slotId) => {
    dispatch(assignSlotToOperator(scheduleId, [slotId], staff));
  };

  const allSlotsAssigned = (schedule) => {
    const totalSlots = schedule.slots.length;
    const assignedSlots = schedule.slots.filter((x) => {
      if (
        x.assignees &&
        x.assignees.findIndex((w) => w.staffid == staffId) !== -1
      ) {
        return x;
      }
    });
    return totalSlots === assignedSlots.length;
  };

  const assignAllSlots = (schedule, assign) => {
    let scheduleSlots = [];
    if (assign) {
      schedule.slots.forEach((slot) => {
        if (!slot.assignees || !slot.assignees.length) {
          scheduleSlots.push(slot.slot_id);
        } else if (
          slot.assignees.length &&
          slot.assignees.findIndex((w) => w.staffid == staffId) === -1
        ) {
          scheduleSlots.push(slot.slot_id);
        }
      });
    } else {
      schedule.slots.forEach((slot) => {
        if (
          slot.assignees.length &&
          slot.assignees.findIndex((w) => w.staffid == staffId) !== -1
        ) {
          scheduleSlots.push(slot.slot_id);
        }
      });
    }
    dispatch(
      assignSlotToOperator(schedule.benchmark_id, scheduleSlots, staff, true)
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={modal1handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton className="mb-1">
          <Modal.Title>
            {_l("l_provider_list")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 mt-3">
          <ul
            aria-labelledby="TimeSlotDropdownWithCheckbox"
            id="TimeSlotDropdownWithCheckboxDropdown"
          >
            {operatorSchedule.length ? (
              operatorSchedule.map((schedule, scheduleIndex) => {
                return (
                  <div key={scheduleIndex} className="dropdown-checklist-wrapper">
                    <div className="timeslot-dropdown-header p-2 custom-accordian-Header-sticky">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="left-timeslot-header flex-grow-1 w100minus100per">
                          <div className="d-flex align-items-center">
                            <div className="color-white-80 f13 text-truncate title-font  ">
                              {schedule.title}
                            </div>
                            <div class="badge  badge-white rounded-pill fw-light ms-1 min-width55 text-truncate">
                              <span class="color-white-60 p-0">
                                #{schedule.benchmark_id}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="right-timeslot-header">
                          <div className="d-flex align-items-center">
                            <div className="count-timeslot c-font f-12 color-white-60 pe-1">
                              {schedule.slots.length}
                            </div>
                            <Form.Check
                              type="switch"
                              className="d-flex align-items-center" 
                              checked={allSlotsAssigned(schedule)}
                              onChange={(e) => {
                                assignAllSlots(schedule, e.target.checked);
                              }}
                            />
                            {["right-start"].map((placement) => (
                              <OverlayTrigger
                                rootClose
                                key={placement}
                                placement={placement}
                                overlay={
                                  <Popover
                                    id={`popover-positioned-${placement}`}
                                    className="overflowscroll"
                                  >
                                    <Popover.Body className="p-0 d-none">
                                      <div className="title-font f13 opacity-50 p-2  border-bottom">
                                        Daily
                                      </div>
                                      <div className="popover-detail-wrapper padding_10 pt-0">
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                        <div className="time-text py-1 border-bottom c-font f-12 color-white-60 ">
                                          # Lorem Ipsum is simply dummy text of the
                                          printing and typesetting industry
                                        </div>
                                      </div>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <div className="info-icon ms-2 light-theme-img">
                                  <HashIcon className="HW16" />
                                </div>
                              </OverlayTrigger>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {schedule.slots.length ? (
                      schedule.slots.map((slot, index) => {
                        return (
                          <li key={index} className="hr_sepretr">
                            <a href="#/" className="dropdown-item w-100 py-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-grow-1 w100minus100per">
                                  <div className="c-list-icon">
                                    <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${UserPlaceholder}')`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="ps-2 w100minus20 d-flex text_wrap">
                                    <div className={`c-list-detail c-font f-14 text-truncate`} 
                                       
                                       
                                       >
                                      <span className="c-font f-14 title-fonts ps-0 text-truncate">
                                        {slot.slot_title ? slot.slot_title + " - " : ""} {slot.slot}
                                      </span>

                                    </div>
                                    <div className=" text-truncate color-white-60 pt-1 c-font f-12 fw-light">
                                      {slot.title}
                                    </div>
                                    <span className="c-font f-12 fst-italic color-white-60 pe-1 text-truncate d-flex align-items-center">
                                      ({slot.type})
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <Form.Check
                                    type="switch"
                                    checked={
                                      slot.assignees &&
                                      slot.assignees.findIndex(
                                        (x) => x.staffid == staffId
                                      ) !== -1
                                    }
                                    onChange={() => {
                                      assignSingleSlot(
                                        schedule.benchmark_id,
                                        slot.slot_id
                                      );
                                    }}
                                  />
                                  {["right-start"].map((placement) => (
                                    <OverlayTrigger
                                      trigger="click"
                                      rootClose
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                        <Popover
                                          id={`popover-positioned-${placement}`}
                                          className="overflowscroll max-height-50vh"
                                        >
                                          <Popover.Body className="timeslot-popover-body w-280">
                                            <div className="timeslot-popover-main">
                                              <div className="bg-white-03 ">
                                                <div className="week-schedule-main">
                                                  <div className="week-schedule-box bg-transparent p-1 with-bg-hover">
                                                    <div className="d-flex justify-content-between">
                                                      {slotWeekSchedule &&
                                                        Object.keys(slotWeekSchedule)
                                                          .length ? (
                                                        Object.keys(
                                                          slotWeekSchedule
                                                        ).map((day, dayIndex) => {
                                                          return (
                                                            <div
                                                              key={dayIndex}
                                                              className="week-box align-items-center"
                                                            >
                                                              <div className="text-center">
                                                                <div className="day-name color-white-80">
                                                                  {day[0].toUpperCase()}
                                                                </div>
                                                                <div
                                                                  className={`available-status ${slotWeekSchedule[
                                                                    day
                                                                  ] > 0
                                                                    ? "available"
                                                                    : "not-available"
                                                                    }`}
                                                                >
                                                                  <CheckIcon className="HW16 available-slot" />
                                                                  <ProhibitIcon className="HW16 available-slot" />
                                                                </div>
                                                                <div className="time-text c-font f-12 color-white-80"></div>
                                                              </div>
                                                            </div>
                                                          );
                                                        })
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <ul>
                                                {checklistItemsForSlot &&
                                                  checklistItemsForSlot.length ? (
                                                  checklistItemsForSlot.map(
                                                    (
                                                      checklistItem,
                                                      checklistIndex
                                                    ) => {
                                                      return (
                                                        <li
                                                          key={checklistIndex}
                                                          className="list-group-item hr_sepretr left-25 px-0 py-3"
                                                        >
                                                          <div className="form-check d-flex align-items-start justify-content-between  p-0 pb-1 w-100">
                                                            <input
                                                              className="form-check-input ms-0 mt-1"
                                                              type="switch"
                                                              checked={true}
                                                              readOnly={true}
                                                            />
                                                            <div className="margin-top-minu w100minus20 bg-transparent border-0 ps-2 text-break c-font f-12 color-white-80">
                                                              {
                                                                checklistItem.checklist_name
                                                              }
                                                            </div>
                                                          </div>
                                                          <div className="label-text d-flex justify-content-between align-items-center">
                                                            <div className="d-flex">
                                                              <div className="img-width-24"></div>
                                                              <div className="fst-italic c-font f-12  ps-1  opacity-50">
                                                                {_l('l_photo_required')}
                                                              </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                              <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                                                <input
                                                                  className="form-check-input"
                                                                  type="switch"
                                                                  readOnly={true}
                                                                  checked={
                                                                    checklistItem.req_photo ==
                                                                    "1"
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </ul>
                                            </div>
                                          </Popover.Body>
                                        </Popover>
                                      }
                                    >
                                      <div
                                        className="info-icon ms-2 on-hover-active-toggle-img light-theme-img"
                                        onClick={() => {
                                          setChecklistItemsForSlot(slot.checklist);
                                          setSlotWeekSchedule(slot.week_schedule);
                                        }}
                                      >
                                        <InfoIcon className="HW16 opacity-50" />
                                      </div>
                                    </OverlayTrigger>
                                  ))}
                                   
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <CommanPlaceholder imgType="no-timeslots" placeholderText = {_l('l_no_slot')} />
                    )}
                  </div>
                );
              })
            ) : (
              <CommanPlaceholder imgType="no-timeslots" placeholderText = {_l('l_no_slot')} />
            )}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScheduleDropDown;
