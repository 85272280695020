
import slackServices from "../services/slack-services";
import { CHECK_SLACK_CONNECTIVITY, SLACK_TOPICS } from "./action-type";
export const is_slack_connected = (project_id) => (dispatch) => {
    slackServices
        .is_slack_connected(project_id)
        .then((data) => {
            dispatch({
                type: CHECK_SLACK_CONNECTIVITY,
                payload: data
            });
        });
}

export const slack_topics = (data) => (dispatch) => {

    dispatch({
        type: SLACK_TOPICS,
        payload: data
    });
}


