import { CHECK_SLACK_CONNECTIVITY, SLACK_TOPICS } from "../actions/action-type";

export default function (state = [], action) {
  switch (action.type) {
    case CHECK_SLACK_CONNECTIVITY: 
      return {...state, slack_connectivity: action.payload};

    case SLACK_TOPICS: 
      return {...state, slacktopics: action.payload};

    default:
      return state;
  }
}
