import React from 'react';
 

function AiTask(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M13.7056 6.39656V14.6317C13.7056 14.8351 13.5227 15 13.2971 15H3.22103C2.99541 15 2.8125 14.8351 2.8125 14.6317V13.0822M2.8125 7.5V1.36825C2.8125 1.16487 2.99541 1 3.22103 1H11.2774M11.2774 1L13.7056 3.30994M11.2774 1L11.2773 3.08713C11.2773 3.14622 11.3033 3.2029 11.3497 3.24468C11.396 3.28647 11.4589 3.30994 11.5245 3.30994H13.7056M13.7056 3.30994V6.5M4.63656 4H7.02909M4.63656 5.5H5.38172M10.0732 13H2.65138C2.45344 13 2.29297 12.8554 2.29297 12.6769V7.82307C2.29297 7.64465 2.45344 7.5 2.65138 7.5H10.0732C10.2711 7.5 10.4316 7.64465 10.4316 7.82307V12.6769C10.4316 12.8554 10.2711 13 10.0732 13Z" stroke="white" stroke-opacity="0.7" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.08984 11.7383L5.40692 8.78054C5.43385 8.72426 5.52697 8.72418 5.55401 8.78041L6.85911 11.7383M4.50056 11.0016H6.45495M8.58984 8.7383V11.7383" stroke="white" stroke-opacity="0.7" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


		</React.Fragment>
	)
}

export default AiTask