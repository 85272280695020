import React from 'react';
 

function AreaIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M11.5912 9.73452H10.7208V7.39052C10.7208 7.00122 10.6574 6.71587 10.5308 6.53452C10.4042 6.35322 10.2196 6.26252 9.97694 6.26252C9.73429 6.26252 9.50744 6.35587 9.29639 6.54252C9.09069 6.72387 8.94559 6.96122 8.86119 7.25452V9.73452H7.99074V7.39052C7.99074 7.00122 7.92744 6.71587 7.80084 6.53452C7.67424 6.35322 7.49224 6.26252 7.25484 6.26252C7.01214 6.26252 6.78534 6.35322 6.57429 6.53452C6.36859 6.71587 6.22084 6.95322 6.13119 7.24652V9.73452H5.26074V5.55052H6.05204V6.39052C6.21559 6.09722 6.42924 5.87322 6.69299 5.71852C6.96204 5.55852 7.26804 5.47852 7.61094 5.47852C7.95384 5.47852 8.22289 5.56922 8.41804 5.75052C8.61854 5.93187 8.74249 6.15852 8.78999 6.43052C8.96934 6.12122 9.18829 5.88652 9.44674 5.72652C9.71054 5.56122 10.0112 5.47852 10.3488 5.47852C10.5915 5.47852 10.7946 5.52387 10.9581 5.61452C11.1217 5.70522 11.2483 5.83052 11.338 5.99052C11.4277 6.14522 11.491 6.32652 11.5279 6.53452C11.5701 6.73722 11.5912 6.95322 11.5912 7.18252V9.73452Z" fill="white"/>
<path d="M12.3252 6.57494C12.3252 6.29229 12.3595 6.05764 12.4281 5.87094C12.4966 5.68429 12.6048 5.52694 12.7525 5.39894C12.9002 5.26564 13.098 5.14829 13.346 5.04694C13.5042 4.98296 13.6651 4.91896 13.8287 4.85496C13.9975 4.79096 14.1373 4.71096 14.2481 4.61496C14.3589 4.51896 14.4143 4.39629 14.4143 4.24696C14.4143 4.09762 14.3509 3.97496 14.2243 3.87896C14.0977 3.78296 13.9184 3.73496 13.6862 3.73496C13.5227 3.73496 13.3776 3.75896 13.251 3.80696C13.1244 3.84962 13.0136 3.90562 12.9187 3.97496C12.829 4.03896 12.7551 4.10029 12.6971 4.15896L12.3568 3.77496C12.4043 3.71629 12.4861 3.64429 12.6022 3.55896C12.7235 3.46829 12.8817 3.39096 13.0769 3.32696C13.2721 3.26296 13.499 3.23096 13.7575 3.23096C14.1689 3.23096 14.4776 3.32429 14.6833 3.51096C14.8943 3.69762 14.9998 3.94029 14.9998 4.23896C14.9998 4.40962 14.9655 4.55629 14.897 4.67896C14.8284 4.80162 14.7387 4.90296 14.6279 4.98296C14.5171 5.05764 14.3984 5.12164 14.2718 5.17494C14.1505 5.22829 14.0371 5.26829 13.9316 5.29494C13.7364 5.35894 13.5834 5.43364 13.4726 5.51894C13.3671 5.60429 13.2906 5.69229 13.2431 5.78294C13.2009 5.87364 13.1798 5.96429 13.1798 6.05494H14.9603V6.57494H12.3252Z" fill="white"/>
<path d="M12.8694 12.4615H3.13018C2.96874 12.4615 2.81392 12.3967 2.69977 12.2813C2.58561 12.1659 2.52148 12.0094 2.52148 11.8462V2"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.8696 12.4613L13.0473 12.6371V12.2855L12.8696 12.4613ZM11.5256 10.7471C11.4285 10.6489 11.2702 10.648 11.172 10.7451C11.0739 10.8422 11.073 11.0005 11.1701 11.0987L11.5256 10.7471ZM11.1701 13.824C11.073 13.9221 11.0739 14.0804 11.172 14.1775C11.2702 14.2746 11.4285 14.2738 11.5256 14.1756L11.1701 13.824ZM13.0473 12.2855L11.5256 10.7471L11.1701 11.0987L12.6919 12.6371L13.0473 12.2855ZM12.6919 12.2855L11.1701 13.824L11.5256 14.1756L13.0473 12.6371L12.6919 12.2855Z" fill="white"/>
<path d="M2.52175 2.00003L2.69949 1.82422H2.34401L2.52175 2.00003ZM0.822259 3.36268C0.725164 3.46084 0.726029 3.61913 0.824189 3.71623C0.922354 3.81332 1.08064 3.81246 1.17774 3.7143L0.822259 3.36268ZM3.86576 3.7143C3.96285 3.81246 4.12114 3.81332 4.2193 3.71623C4.31747 3.61913 4.31833 3.46084 4.22123 3.36268L3.86576 3.7143ZM2.34401 1.82422L0.822259 3.36268L1.17774 3.7143L2.69949 2.17584L2.34401 1.82422ZM2.34401 2.17584L3.86576 3.7143L4.22123 3.36268L2.69949 1.82422L2.34401 2.17584Z" fill="white"/>
</svg>



		</React.Fragment>
	)
}

export default AreaIcon