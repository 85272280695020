import React from 'react';


function PhoneSlashIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";

	return (
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M52.44,36A6,6,0,0,0,43.56,44L73.08,76.51a139.32,139.32,0,0,0-47.27,31.08c-19.25,19.26-21.11,47.65-4.52,69A14,14,0,0,0,37.39,181l49-17.37.22-.09a13.93,13.93,0,0,0,8.53-10.25l5.9-29.51a2,2,0,0,1,1.21-1.47,80.07,80.07,0,0,1,10-2.75L203.56,220a6,6,0,0,0,8.88-8.08ZM98.29,111a14,14,0,0,0-9,10.45L83.37,151a2,2,0,0,1-1.15,1.43L33.28,169.77l-.22.08a2,2,0,0,1-2.29-.58c-13-16.73-11.56-38.11,3.53-53.19A127.91,127.91,0,0,1,81.75,86l21.34,23.48C101.47,110,99.87,110.47,98.29,111Zm136.42,65.61a14,14,0,0,1-16.1,4.41l-9.28-3.29a6,6,0,1,1,4-11.31l9.38,3.33.22.08a2,2,0,0,0,2.29-.58c13-16.73,11.56-38.11-3.53-53.19C195.64,90,158.86,76.2,120.83,78.19a6,6,0,1,1-.63-12c41.44-2.15,81.52,12.93,110,41.39C249.44,126.85,251.3,155.24,234.71,176.63Z"></path></svg>
		</React.Fragment>
	)
}

export default PhoneSlashIcon