import { LOGIN_AS_STAFF_CONTACT } from "./../actions/action-type";

const initialState = {
  loginState: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_AS_STAFF_CONTACT:
      return { ...state, loginState: payload.loginStatus };
    default:
      return state;
  }
}
