import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l} from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { updatedFloorList } from "../../actions/customer";
import { Spinner } from "reactstrap";

const AddEditFloor = ({ show, handleClose, floorData, getSapceFloorList }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [floorId, setFloorId] = useState("");
  const [floorHash, setFloorHash] = useState("");
  const [loder, setLoder] = useState(false);
  const addFloor = () => {
    if(name == ""){
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_floor_name')));
    }else{
      setLoder(true);
    customerServices.addEditFloor(name,floorId).then((res) => {
      setLoder(false);
      if (res.status == 1) {
        handleClose();
        clearForm();
        if(floorData){
          getSapceFloorList.find(p => p.id == floorId && (p.name = name, true))
          dispatch(updatedFloorList(getSapceFloorList));
        }else{
          dispatch(updatedFloorList([...getSapceFloorList, { name: name, id: res.data.id, hash: floorHash }]));
        }
        dispatch(showMessage('sucess', _l('l_success'), res.message));
      }else{
        dispatch(showMessage('unsucess', _l('l_error'), res.message));
      }
    });
    }
  }
  useEffect(() => {
    if (floorData) { 
      setName(floorData.label);
      setFloorId(floorData.value);
      setFloorHash(floorData.hash);
    }else{
      setName("")
    }
  },[floorData])
  const clearForm = () => {
    setName('');
    setFloorId('');  
    setFloorHash('');
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose()
          clearForm()
        }}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{floorData ? _l("l_edit_floor") : _l("l_add_floor")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_name")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_name")}`}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}

                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={() => {
            handleClose()
            clearForm()
          }}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={() => addFloor()}
           disabled={loder ? true : false}>
            { loder ? _l('l_please_wait') : floorData ? _l('l_save') : _l('l_add') }
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2`}
              aria-hidden="true"
            />
            : <></>
            }</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEditFloor;
