import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, generateDefaultSignature, getDatePickerLocale, showError, validateIBAN } from "../../hooks/utilities";
import SignatureCanvas from "react-signature-canvas";
import Toasts from "../Toasts/Toasts";
import { validateEmail } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import Spinner from "react-bootstrap/Spinner";
 
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import BenchmarkServices from "../../services/benchmark-services";
import { GET_BENCHMARK_DETAIL } from "../../actions/action-type";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getBenchmarkDetail } from "../../actions/customer";
import DatePicker from "react-datepicker";
import { createScheduleDetails } from "../../actions/benchmark-actions/benchmark-actions";
import addDays from "date-fns/addDays";
import moment from "moment";
import DocumentsServices from "../../services/documents-services";
import userpaymnetServices from "../../services/userpaymnet-services";
import Select from "react-select";
import IBanEditModal from "./IBanEditModal";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import EraserIcon from "../../assets/icons/EraserIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import ArrowBendDoubleLeftIcon from "../../assets/icons/ArrowBendDoubleLeftIcon";

const SignatureConfirmationIdentity = ({
    projectId,
    show,
    handleClose,
    callBack,
    estimate,
    external,
    hash,
    type = "",
    benchmark,
    setReadOnlyMode,
    showIBANField,
    isExternal,
    showIbanSelection = false,
    ibanID = 0,
    estimateId = 0,
    signatureImg,
    setSignatureImg,
    signaturepadRef
}) => {
    const { benchmarkDetail, IBANAccounts } = useSelector((state) => state.customer);
    const { createScheduleDetail } = useSelector((state) => state.benchmarkReducer);

    let spaces = localStorage.getItem("spaces");
    spaces = spaces ? JSON.parse(spaces) : [];
    let currentSpace = benchmarkDetail && benchmarkDetail.project_id ? spaces.find((w) => w.project_id == benchmarkDetail.project_id) : {};
    const fullName = localStorage.getItem("full_name");
    const email = localStorage.getItem("email");
    const showEstimateTermsAccept = type === "Proposal" && [0, 2].includes(Number(currentSpace.status)) && estimate.estimate_id > 0
    const extfirstName = estimate && estimate.contacts && estimate.contacts.firstname ?  estimate.contacts.firstname : '';
    const extlastName = estimate && estimate.contacts && estimate.contacts.lastname?  estimate.contacts.lastname : '';
    const extEmail = estimate && estimate.contacts && estimate.contacts.email ? estimate.contacts.email : '';

    const [loder, setLoder] = useState(false);
    const [firstName, setFirstName] = useState(
        isExternal 
            ? extfirstName 
            : fullName 
                ? fullName.split(" ")[0].toString() 
                : ""
    );
    const [lastName, setLastName] = useState(
        isExternal 
            ? extlastName 
            : fullName 
                ? fullName.split(" ").slice(1).toString() 
                : ""
    );
    const [emailAddress, setEmailAddress] = useState(isExternal ? extEmail : email);
    const [serviceStartDate, setServiceStartDate] = useState(
        benchmarkDetail && benchmarkDetail.startdate
            ? new Date(benchmarkDetail.startdate)
            : ""
    );
    const [basicDetails, setBasicDetails] = useState({
        amount: 0,
        isUnKnownBudget: false,
    });
    const [showToast, setShowToast] = useState(false);
    const [toastClass, setToastClass] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [toastHeader, setToastHeader] = useState("");
    const userType = localStorage.getItem("user_type");
    const [ibanData, setIbanData] = useState({
        ibanNumber: "",
        spaceTitle: estimate.company_name,
        billingAddress: estimate.address,
        key: "l_single",
    });
    const [useSameAddress, setUseSameAddress] = useState(true);
    const [accpetEstimateTerms, setAccpetEstimateTerms] = useState(true);
    const [IBANListing, setIBANListing] = useState([]);
    const [showAddIBANModal, setShowAddIBANModal] = useState(false);
    const [IBanDetails, setIBanDetails] = useState({
        account_iban:"",
        company_name:localStorage.getItem("company_name"),
        city: "",
        postal_code:"",
        company_address: localStorage.getItem("company_address"),
        estimate_id: estimate.estimate_id ? estimate.estimate_id : 0
    });
    const [selectedPaymentID, setselectedPaymentID] = useState(estimate.user_payment_id)
    const [selectedPayment, setselectedPayment] = useState({})

    const [savedSignature, setSavedSignature] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [IBNWalletId, setIBNWalletId] = useState("");
    const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;
    const signatureContent = `${firstName} ${lastName}`;
    const [selectedTab, setSelectedTab] = useState("l_Signature_Confirmation");
    const [disableTab, setDisableTab] = useState(true);
    const TABS = [
        {
            title: "l_Signature_Confirmation",
            color: "black",
            name: "Signature & Confirmation",
        },
        {
            title: "l_Wallets",
            color: "black",
            name: "Wallets",
        },
    ];

    const generateSignature = () => {
        if (signaturepadRef.current) {
        generateDefaultSignature(signaturepadRef, signatureContent);
        }
    };

    useEffect(() => {
        generateSignature();
        if (savedSignature && signaturepadRef.current) {
            signaturepadRef.current.fromDataURL(savedSignature);
        }
    }, [selectedTab]);

    const handleSaveSignature = () => {
        if (signaturepadRef.current) {
            setSavedSignature(signaturepadRef.current.toDataURL());
        }
    };
    useEffect(() => {
        // Handle window resize event
        const handleResize = () => {
            if (savedSignature && signaturepadRef.current) {
                signaturepadRef.current.fromDataURL(savedSignature);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [savedSignature]);

    useEffect(() => {
        setselectedPayment(IBANAccounts && IBANAccounts.filter((w) => w.id == IBNWalletId).map((w) => {
            return {
                label: w.company_name,
                value: w.id,
                account_iban: w.account_iban,
                customer_id: w.gocardless_customer_id
            } 
        }));    
    }, [IBNWalletId])
  
    const clearSignaturePad = () => {
        if (signaturepadRef.current && signaturepadRef.current.clear) {
            signaturepadRef.current.clear();
            setSavedSignature(null);
        }
    };

    const undoSignature = () => {
        if (signaturepadRef.current && signaturepadRef.current.toData()) {
            let signaturePadData = signaturepadRef.current.toData();
            signaturePadData.pop();
            signaturepadRef.current.fromData(signaturePadData);
            setSavedSignature(signaturepadRef.current.toDataURL());
        }
    };

    const validPaymentInfo = () => {
        let flag = true;
        if (!ibanData.ibanNumber || !validateIBAN(ibanData.ibanNumber)) {
            showError("l_please_enter_valid_iban_number");
            flag = false;
        } else if (!useSameAddress && !ibanData.billingAddress.trim().length) {
            showError("l_please_add_space_title");
            flag = false;
        } else if (!useSameAddress && !ibanData.spaceTitle.trim().length) {
            showError("l_please_enter_valid_billing_address");
            flag = false;
        }
        return flag;
    };

    const validationNextBtn = () => {
        if (!firstName || !firstName.trim().length) {
            dispatch(
                showMessage("unsucess", _l("l_error"), _l("l_please_enter_first_name"))
            );
            setDisableTab(true);
            return;
        } else if (!lastName || !lastName.trim().length) {
            dispatch(
                showMessage("unsucess", _l("l_error"), _l("l_please_enter_last_name"))
            );
            setDisableTab(true);
            return;
        } else {
            setSelectedTab("l_Wallets");
        }
    }

    const handleSave = async () => {
        let cardless_customer_id = selectedPayment && Object.keys(selectedPayment).length ? selectedPayment && selectedPayment.customer_id : IBANListing && IBANListing.filter((w) => w.value == ibanID)[0] && IBANListing.filter((w) => w.value == ibanID)[0].customer_id;

        const packageId = userType == "operator" ? 5 : 2;

        if (!firstName || !firstName.trim().length) {
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_first_name")));
            return;
        } else if (!lastName || !lastName.trim().length) {
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_last_name")));
            return;
        } else if (!emailAddress || !emailAddress.trim().length || !validateEmail(emailAddress)) {
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email_address")));
            return;
        } else if (signaturepadRef && signaturepadRef.current && signaturepadRef.current.isEmpty()) {
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_add_the_signature")));
            return;
        } else if (type == "Proposal" && benchmarkDetail.start_date_is_empty == 1 && !benchmarkDetail.startdate && !serviceStartDate) {
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_the_start_date")));
        } else if(moment().isAfter(serviceStartDate)){
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_invalid_start_date")));
        } else if(showEstimateTermsAccept && !accpetEstimateTerms) {
            showError("l_please_accept_terms_and_conditions");
        } else {
            setLoder(true);

            if (type == "Proposal") {
                if (showIBANField) {
                    if (validPaymentInfo()) {
                        try {
                            let formattedIBANData = ibanData.key === "l_single"
                            ? [
                                ibanData.ibanNumber,
                                ibanData.spaceTitle,
                                ibanData.billingAddress,
                            ]
                            : [
                                {
                                    account_iban: ibanData.ibanNumber,
                                    billing_address: ibanData.billingAddress,
                                    title: ibanData.spaceTitle,
                                    project_id: projectId,
                                },
                            ];
                            let key = ibanData.key.replace("l_", "");

                            const response = CustomerServices.setIBANSetting(key, formattedIBANData);
                            if (!response &&!response.status) {
                                showError(response.message);
                                return false;
                            }
                        } catch (e) {
                            setLoder(false);
                            console.error(e);
                        }
                    } else {
                        setLoder(false);
                        return false;
                    }
                }

                if(currentSpace) {
                    if([0, 2].includes(Number(currentSpace.status)) && estimate.estimate_id > 0) {

                        userpaymnetServices.updatePaymentMethod(estimateId, benchmarkDetail.id, selectedPaymentID, cardless_customer_id).then((response)=>{
                            if (response && response.status) {
                                setLoder(true);

                                CustomerServices.estimateCreate(
                                    estimate.estimate_id,
                                    firstName,
                                    lastName,
                                    emailAddress,
                                    signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"),
                                    packageId,
                                    external,
                                    hash,
                                    moment(serviceStartDate).format("YYYY-MM-DD"),
                                    ibanData.ibanNumber,
                                    estimate.proposal_id
                                ).then(async(res) => {
                                    if (res.status) {
                                        if(res.data && res.data.site){
                                            window.location.href = res.data.site;
                                        }
                                        if (benchmarkDetail) {
                                            let proposalBenchmark = benchmarkDetail;
                                            for (let i = 0; i < proposalBenchmark.proposal_list.length; i++) {
                                                for (let j = 0; j < proposalBenchmark.proposal_list[i].proposals.length; j++) {
                                                    if (estimate.proposal_id ==proposalBenchmark.proposal_list[i].proposals[j].proposal_id) {
                                                        proposalBenchmark.proposal_list[i].proposals[j].status = "3";
                                                    } else {
                                                        proposalBenchmark.proposal_list[i].proposals[j].status = "2";
                                                    }
                                                }
                                            }
                                            dispatch({type: GET_BENCHMARK_DETAIL, payload: { data: proposalBenchmark }, });
                                        }
                                        if (res.data.subscription_id && !estimate.is_custom_invoice) {
                                            if (estimate.is_iban_set > 0) {
                                                await subscribe(res.data.subscription_id, res.data.hash);
                                                callBack();
                                                dispatch(showMessage("success", _l("l_success"), _l("l_proposal_accepted")));
                                            }
                                        }
                                        if (res.data.schedule_id > 0) {
                                            dispatch(getBenchmarkDetail(res.data.schedule_id));
                                            if(dashboardBenchmarkId){
                                                navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
                                            } else {
                                                navigate(`/connect-provider?space=${benchmark.project_id}&provider-type=schedule&id=${res.data.schedule_id}`);
                                            }
                                        } else {
                                            dispatch(getBenchmarkDetail(benchmarkDetail.id));
                                        }
                                        if(dashboardBenchmarkId){
                                            navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
                                        } else {
                                            navigate("/");
                                        }
                                        handleClose();
                                        setLoder(false);
                                        setReadOnlyMode(true);
                                        window.open(response.data.site);
                                    } else {
                                        dispatch(showMessage("unsucess", _l("l_error"), res.message));
                                    }
                                }).finally(() => {
                                    setLoder(false);
                                });
                            } else {
                                showError(response.message);
                            }
                        })
                    } else {

                        userpaymnetServices.updatePaymentMethod(0,benchmarkDetail.id,selectedPaymentID, cardless_customer_id).then((response)=>{
                            if (response && response.status) {

                                BenchmarkServices.acceptProposal(
                                    estimate.proposal_id,
                                    estimate.id,
                                    signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"),
                                    firstName,
                                    lastName,
                                    emailAddress,
                                    serviceStartDate ? moment(serviceStartDate).format("YYYY-MM-DD") : "",
                                    benchmarkDetail.is_added_by_myr,
                                    ibanData.ibanNumber
                                ).then(async(res) => {

                                    if (res.status) {
                                        if (benchmarkDetail) {
                                            let proposalBenchmark = benchmarkDetail;
                                            for (let i = 0; i < proposalBenchmark.proposal_list.length; i++) {
                                                for (let j = 0; j < proposalBenchmark.proposal_list[i].proposals.length; j++) {
                                                    if ( estimate.proposal_id == proposalBenchmark.proposal_list[i].proposals[j].proposal_id) {
                                                        proposalBenchmark.proposal_list[i].proposals[j].status = "3";
                                                    } else {
                                                        proposalBenchmark.proposal_list[i].proposals[j].status = "2";
                                                    }
                                                }
                                            }
                                            dispatch({type: GET_BENCHMARK_DETAIL, payload: { data: proposalBenchmark },});
                                        }
                                        if (res.data.subscription_id  && !estimate.is_custom_invoice) {
                                            if (estimate.is_iban_set > 0) {
                                                await subscribe(res.data.subscription_id, res.data.hash);
                                                callBack();
                                                dispatch(showMessage("success", _l("l_success"), _l("l_proposal_accepted")));
                                            }
                                        }
                                        if (res.data.schedule_id > 0) {
                                            dispatch(getBenchmarkDetail(res.data.schedule_id));
                                            if(dashboardBenchmarkId){
                                                navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
                                            } else {
                                                navigate(`/connect-provider?space=${benchmark.project_id}&provider-type=schedule&id=${res.data.schedule_id}`);
                                            }
                                        } else {
                                            dispatch(getBenchmarkDetail(benchmarkDetail.id));
                                        }
                                        handleClose();
                                        setLoder(false);
                                        setReadOnlyMode(true);
                                        
                                        if(dashboardBenchmarkId){
                                            navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
                                        } else {
                                            navigate("/");
                                        }
                                        window.open(response.data.site);
                                    } else {
                                        dispatch(showMessage("unsucess", _l("l_error"), res.message));
                                    }
                                });
                            } else {
                                showError(response.message);
                            }
                        })
                    }
                }
            } else if(type == "operator-estimate") {
              if (signatureImg == "" || !signatureImg ) {
                showError("l_please_add_the_signature");
                setLoder(false);
                return
              }
                BenchmarkServices.upgradeOperatorPlan(
                    firstName,
                    lastName,
                    emailAddress,
                    signatureImg,
                    estimate.package_id
                ).then((response) => {
                    if(response.status){ 
                        callBack(response.data);
                    } else {
                        showError(response.message);
                        handleClose();
                    }
                }).catch((e) => {
                    console.error(e); 
                    showError("l_something_went_wrong"); 
                    handleClose()
                });
            } else {
              if (signatureImg == "" || !signatureImg ) {
                showError("l_please_add_the_signature");
                setLoder(false);
                return
              }else{

                userpaymnetServices.updatePaymentMethod(estimate.estimate_id,0,selectedPaymentID, cardless_customer_id).then((response)=>{
                    if (response && response.status) {
                        setLoder(true);

                        CustomerServices.estimateCreate(
                            estimate.estimate_id,
                            firstName,
                            lastName,
                            emailAddress,
                            signatureImg,
                            packageId,
                            external,
                            hash,
                            moment(serviceStartDate).format("YYYY-MM-DD")
                        ).then((res) => {
                            if (res.status == 1) {
                                if(res.data && res.data.site){
                                    window.location.href = res.data.site;
                                }
                                handleClose();
                                localStorage.setItem(
                                    "membership_package_id",
                                    res.data && res.data.package_id && res.data.package_id != ""
                                        ? res.data.package_id
                                        : 0
                                );
                                callBack();
                            } else {
                                dispatch(showMessage(("unsuess", _l("l_error"), res.message)));
                                return;
                            }
                        }).finally(() => {
                            setLoder(false);
                        });
                        window.open(response.data.site);
                    } else {
                        showError(response.message);
                    }
                })
              }
            }
        }
        setLoder(false);
    };

    const getSubscripitonDetails = async (id, hash) => {
        let details = {};
        try {
            const response = await DocumentsServices.getSubscriptionDetail(benchmarkDetail.project_id, id, hash, 1);
            if (response && response.status) {
                details = response.data;
            } else {
                showError("l_something_went_wrong");
            }
        } catch (e) {
            console.error(e);
        } finally {
            return details;
        }
    };

    const subscribeForProvider = async (operatorData) => {
        try {
            const subscriptionDetail = await DocumentsServices.getSubscriptionDetail(0, 0, operatorData.hash);
            const paymentMethod = subscriptionDetail.data.payment_mode;

            const response = await DocumentsServices.subscribe(
                operatorData.hash,
                "",
                operatorData.email,
                1,
                0,
                1,
                operatorData.client_id,
                undefined,
                undefined, 
                paymentMethod
            );
        } catch (e) {
            console.error(e);
        }
    };

    const subscribe = async (id, hash) => {
        try {
            setLoder(true);
            let subscriptionDetails = await getSubscripitonDetails(id, hash);
            let subscriptionEmail = "";

            if (Object.keys(subscriptionDetails).length) {
                if(userType === "staff"){
                    subscriptionEmail = subscriptionDetails.staff_email;
                }else {
                    let primaryContact = subscriptionDetails.contacts.find((w) => w.is_primary == 1);

                    if (!primaryContact) {
                        showError("l_no_primary_contact_found_for_subscription");
                        return false;
                    } else {
                        subscriptionEmail = primaryContact.email;
                    }
                }

                const response = await DocumentsServices.subscribe(
                    subscriptionDetails.hash,
                    benchmarkDetail.startdate,
                    subscriptionEmail,
                    0,
                    benchmarkDetail.project_id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    subscriptionDetails.payment_mode
                );
                if (response && response.status == 1) {
                    if (response.extra.client_id) {
                        let operatorData = response.extra;
                        subscribeForProvider(operatorData);
                    }
                }
            }
        } catch(e) {
            console.error(e);
        } finally {
            setLoder(false);
        }
    };

    const saveButtonText = () => {
        if (showIBANField) {
            return _l("l_accept_and_subscribe");
        } else {
            return _l("l_save");
        }
    };

    const getSpaceIBANData = async() => {
        try {
            const response = await CustomerServices.getIbanList();
            if(response && response.data) {
                if(response.data.single_iban_for_all_spaces && response.data.account_iban) {
                    const {billing_address, account_iban, title} = response.data;
                    setIbanData({billingAddress: billing_address, ibanNumber: account_iban, spaceTitle: title, key: "l_single"});
                    setUseSameAddress(false);
                }
            }
        } catch(e) {
            console.log(e);
        }
    };

    const isValidIBAN = (ibanString) => {
        if(!validateIBAN(ibanString)){
            showError("l_please_enter_valid_iban_number");
        }
    }

    useEffect(() => {
        if (createScheduleDetail && Object.keys(createScheduleDetail).length) {
            setBasicDetails(createScheduleDetail);
        } else {
            setBasicDetails({});
        }
    }, [JSON.stringify(createScheduleDetail)]);

    useEffect(() => {
        if(type === "Proposal") {
            getSpaceIBANData();
        }
        if (IBANAccounts && IBANAccounts.length) {
            setIBANListing(IBANAccounts.map((w) => {
                {
                    return {
                        label: w.company_name,
                        value: w.id,
                        account_iban: w.account_iban,
                        customer_id: w.gocardless_customer_id
                    };
                }
            }))
            setselectedPaymentID(IBANAccounts && IBANAccounts.length && IBANAccounts[0].id)
        }
    }, [IBANAccounts])

  useEffect(() => {
    if (signaturepadRef && signaturepadRef.current) {
        setSignatureImg(signaturepadRef.current.getTrimmedCanvas().toDataURL("image/png"))
    }
  })
  
    return (
        <>
            {showToast ? (
                <Toasts
                    delay={2500}
                    handler={() => { setShowToast(false); }}
                    header={toastHeader}
                    message={toastMessage}
                    toastClass={toastClass}
                    cancelButtonHandler={() => { setShowToast(false); }}
                    cancelButtonText={"cancel"}
                    confirmButtonText={"Delete"}
                />
            ) : (
                <></>
            )}
            <div className="">
                <Modal show={show} onHide={handleClose} className="custom-modal-style" aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{_l("l_signature_conformation_of_identity")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" py-0">
                        <div className="form-wrapper-main">
                            <div>
                                <div className="comman-tab-wrapper  pb-20px">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center w-100">
                                            {TABS.map((t) => {
                                                return (
                                                    <>
                                                        <div className={`w-50 tab-wrapper d-flex align-items-center lightthemebg ${selectedTab === t.title ? "active" : ""}`}>
                                                            <a
                                                                href="#/"
                                                                className="tab-name d-flex align-items-center bg-white-03 w-100 justify-content-center"
                                                                onClick={() => {
                                                                    if(selectedTab == "l_Signature_Confirmation") {
                                                                        validationNextBtn();
                                                                    } else {
                                                                        setSelectedTab(t.title);
                                                                        setDisableTab(false);
                                                                    }
                                                                }}
                                                            >
                                                                <span>{_l(t.name)}</span>
                                                            </a>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {selectedTab == "l_Signature_Confirmation" &&
                                <>
                                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                                        <Form.Label className="input-label no-asterisk ">{_l("l_first_name")}</Form.Label>
                                        <Form.Control
                                            placeholder={`${_l("l_first_name_placeholder")}`}
                                            type="text"
                                            name=""
                                            value={firstName}
                                            onChange={(e) => {setFirstName(e.target.value);}}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
                                        <Form.Label className="input-label no-asterisk ">{_l("l_last_name")}</Form.Label>
                                        <Form.Control
                                            placeholder={`${_l("l_last_name_placeholder")}`}
                                            type="text"
                                            name=""
                                            value={lastName}
                                            onChange={(e) => {setLastName(e.target.value);}}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                                        <Form.Label className="input-label no-asterisk ">{_l("l_email")}</Form.Label>
                                        <Form.Control
                                            placeholder={`${_l("l_email_placeholder")}`}
                                            type="email"
                                            name=""
                                            value={emailAddress}
                                            onChange={(e) => {setEmailAddress(e.target.value);}}
                                        />
                                    </Form.Group>
                            
                                    {["Proposal"].includes(type) ? (
                                    <React.Fragment>
                                        <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                                            <Form.Label className="input-label no-asterisk">{_l("l_service_start_date")}</Form.Label>
                                            <div className="custom-datepicker" data-bs-toggle="tooltip">
                                                <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                                                    <CalendarBlankIcon className="HW18 m-0 me-2" />
                                                    <DatePicker
                                                        onKeyDown={(e) => { e.preventDefault(); }}
                                                        onChange={(date) => {
                                                            dispatch(
                                                                createScheduleDetails({
                                                                    ...basicDetails,
                                                                    startDate: date,
                                                                    isUnKnownStartDate: false,
                                                                })
                                                            );
                                                            setServiceStartDate(date);
                                                        }}
                                                        disabledKeyboardNavigation
                                                        selected={serviceStartDate}
                                                        placeholderText={_l("l_select_start_date")}
                                                        locale={getDatePickerLocale()}
                                                        dateFormat="dd/MM/yyyy"
                                                        selectsStart
                                                        minDate={addDays(new Date(), 1)}
                                                        defaultMenuIsOpen
                                                        calendarStartDay={1}
                                                    >
                                                    <div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                                                </div>
                                            </div>
                                        </Form.Group>
                                        
                                        {!estimate.is_custom_invoice && type === "proposal" ?
                                        <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                                            <Form.Label className="input-label no-asterisk ">{_l("l_total_payable_amount")}</Form.Label>
                                            <Form.Control type="email" disabled value={estimate.proposal_total} />
                                        </Form.Group>
                                        : <React.Fragment></React.Fragment>
                                        }
                                    </React.Fragment>
                                    ) : (
                                    <React.Fragment></React.Fragment>
                                    )}

                                    {showIBANField && !estimate.is_custom_invoice ? (
                                    <React.Fragment>
                                        <Form.Group className="d-flex py-1 justify-content-start">
                                            <Form.Check
                                                name="package-type"
                                                type="radio"
                                                label={_l("l_single")}
                                                value={"l_single"}
                                                id="disabled-default"
                                                className="me-3 form-check-radio-input-border"
                                                checked={ibanData.key === "l_single"}
                                                onChange={(e) => { setIbanData({ ...ibanData, key: e.target.value }); }}
                                            />
                                            <Form.Check
                                                name="package-type"
                                                type="radio"
                                                label={_l("l_individual")}
                                                value={"l_individual"}
                                                id="disabled-default"
                                                className="me-3 form-check-radio-input-border"
                                                checked={ibanData.key === "l_individual"}
                                                onChange={(e) => { setIbanData({ ...ibanData, key: e.target.value }); }}
                                            />
                                            <Form.Check
                                                name="package-type"
                                                type="radio"
                                                label={_l("l_individual_with_software")}
                                                value={"l_individual_with_software"}
                                                id="disabled-default"
                                                className="me-3 form-check-radio-input-border"
                                                checked={ibanData.key === "l_individual_with_software"}
                                                onChange={(e) => { setIbanData({ ...ibanData, key: e.target.value }); }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                                            <Form.Label className="input-label no-asterisk ">{_l("l_account_iban")}</Form.Label>
                                            <Form.Control
                                                placeholder={_l("l_account_iban_placeholder")}
                                                type="text"
                                                name=""
                                                value={ibanData.ibanNumber}
                                                onBlur={(e) => isValidIBAN(e.target.value)}
                                                onChange={(e) => {
                                                    setIbanData({
                                                        ...ibanData,
                                                        ibanNumber: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Form.Group>

                                        {!useSameAddress ? (
                                        <React.Fragment>
                                            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                                                <Form.Label className="input-label no-asterisk ">{_l("l_space_title")}</Form.Label>
                                                <Form.Control
                                                    placeholder={_l("Microsoft")}
                                                    type="text"
                                                    name=""
                                                    value={ibanData.spaceTitle}
                                                    onChange={(e) => {
                                                        setIbanData({
                                                            ...ibanData,
                                                            spaceTitle: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                                                <Form.Label className="input-label no-asterisk ">{_l("l_billing_address")}</Form.Label>
                                                <Form.Control
                                                    placeholder={_l("l_billing_address_placeholder")}
                                                    type="text"
                                                    name=""
                                                    value={ibanData.billingAddress}
                                                    onChange={(e) => {
                                                        setIbanData({
                                                            ...ibanData,
                                                            billingAddress: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </React.Fragment>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}

                                        <Form.Group className="col-xl-12 c-input-box py-2 position-relative">
                                            <Form.Check
                                                type={"checkbox"}
                                                label={_l("l_use_postal_address_as_billing_address")}
                                                checked={useSameAddress}
                                                onChange={(e) => setUseSameAddress(e.target.checked)}
                                            />
                                        </Form.Group>
                                    </React.Fragment>
                                    ) : (
                                        <></>
                                    )}
                                </>
                                }

                                {showIbanSelection && selectedTab == "l_Wallets" ?      
                                <div className="col-xl-12 c-input-box pb-3 position-relative">
                                    <Form.Label className="input-label no-asterisk ">{_l("l_wallets")}</Form.Label>
                                    <div className="row comman_action_icon">
                                        <div className="col-auto flex-grow-1 " title={`**** **** **** **** **** **** ${selectedPayment && Object.keys(selectedPayment).length ? selectedPayment && selectedPayment.account_iban && selectedPayment.account_iban.trim().slice(-3) : IBANListing && IBANListing.filter((w) => w.value == ibanID)[0] && IBANListing.filter((w) => w.value == ibanID)[0].account_iban.trim().slice(-3)}`}>
                                            <Select
                                                placeholder={_l("l_select_space_selection")}
                                                className="custom-select-menu"
                                                classNamePrefix="react-select"
                                                options={IBANListing}
                                                defaultValue={IBANListing.filter((w) => w.value == ibanID)}
                                                onChange={(e) => {
                                                    setselectedPaymentID(e.value);
                                                    setselectedPayment(e);
                                                }}
                                                value={selectedPayment && Object.keys(selectedPayment).length ? selectedPayment :  IBANListing.filter((w) => w.value == ibanID)}
                                            />
                                        </div>
                                        <a href="#/" className="col-12 action_icon with_bg h53w53 me-3" onClick={()=>{ setShowAddIBANModal(true)}}>
                                            <PlusIcon className="HW30" />
                                        </a>
                                    </div>
                                </div> 
                                : <></>}

                                {selectedTab == "l_Signature_Confirmation" &&
                                <>
                                    <Form.Group className="col-xl-12 c-input-box  position-relative">
                                        <Form.Label className="input-label no-asterisk ">{_l("l_signature")}</Form.Label>
                                    </Form.Group>
                                    <div className="col-xl-12  pb-3">
                                        <SignatureCanvas
                                            penColor="white"
                                            ref={signaturepadRef}
                                            canvasProps={{
                                                className: "custom-canvas-style form-control p-0 w-100",
                                            }}
                                            onEnd={handleSaveSignature}
                                        />
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="d-flex">
                                            <a href="#/" className="h40w40 d-flex  form-control p-0  me-3" onClick={undoSignature}>
                                                <ArrowBendDoubleLeftIcon className="c-icons" />
                                            </a>
                                            <a href="#/" className="h40w40 d-flex  form-control p-0" onClick={clearSignaturePad}>
                                                <EraserIcon className="HW18" />
                                            </a>
                                        </div>
                                        <div className="input-label no-asterisk title-fonts c-font f-12 color-white-60 mt-2">
                                            {_l("l_singature_subtext")}
                                        </div>
                                    </div>
                                    {showEstimateTermsAccept ? (
                                    <Form.Group className="col-xl-12 c-input-box py-2 position-relative">
                                        <div className="d-flex align-items-center">
                                            <label className="form-check-label  me-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`default`}
                                                    label="I Accept this Estimate"
                                                    onChange={(e) => {setAccpetEstimateTerms(e.target.checked)}}
                                                    checked={accpetEstimateTerms}
                                                />
                                            </label>
                                            <a href="#/" className="color-white-80" onClick={(e) => {setAccpetEstimateTerms(!accpetEstimateTerms)}}>
                                                {_l("l_i_accept_this_estimate")}
                                            </a>
                                            <a className="color-green ms-1" href={"https://myr.ai/cgsc.html"} target="_blank">
                                                CGSC
                                            </a>
                                            <a className="color-green ms-1" href={"https://myr.ai/cgsp.html"} target="_blank">
                                                CGSP
                                            </a>
                                            <span className="m-1">&</span>
                                            <a className="color-green ms-1" href={"https://myr.ai/privacy-policy.html"} target="_blank">
                                                RGPD
                                            </a>
                                        </div>
                                    </Form.Group>
                                    ) : (
                                        <></>
                                    )}
                                </>
                                }
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="border-0 justify-content-end">
                        <Button variant="secondary" size="sm" onClick={handleClose}>{_l("l_close")}</Button>
                        {selectedTab == "l_Signature_Confirmation" ?
                        <Button variant="primary" size="sm" onClick={() => { validationNextBtn() }} disabled={loder}>
                            {loder ? _l("l_please_wait") : "Next"}
                            {loder ? (
                                <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />
                            ) : (
                                <></>
                            )}
                        </Button>
                        : <Button variant="primary" size="sm" onClick={loder ? "" : handleSave} disabled={loder}>
                            {loder ? _l("l_please_wait") : saveButtonText()}
                            {loder ? (
                                <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />
                            ) : (
                                <></>
                            )}
                        </Button>
                        }
                    </Modal.Footer>
                </Modal>
                
                {showAddIBANModal ? 
                <>
                    <IBanEditModal
                        show={showAddIBANModal}
                        handleClose={() => {
                            setShowAddIBANModal(false);
                            setIBanDetails({
                                account_iban:"",
                                company_name:localStorage.getItem("company_name") ,
                                city: "",
                                postal_code:"",
                                company_address: localStorage.getItem("company_address"),
                                estimate_id: estimate.estimate_id ? estimate.estimate_id : 0 
                            })
                        }}
                        isForAdd={true}
                        ibanDetails={IBanDetails}
                        setIBanDetails={setIBanDetails}
                        handleSave={()=>{}}
                        redirectIsActive={false}
                        setIBNWalletId = {setIBNWalletId}
                    />
                </> 
                : <></>
                }
            </div>
        </>
    );
};

export default SignatureConfirmationIdentity;
