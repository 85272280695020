import React from "react";
import XIcon from "../../assets/icons/XIcon";
import MagnifyingGlassIcon from "../../assets/icons/MagnifyingGlassIcon";
const CommanSearchbox = ({searchString, onChangeHandler,mainClass=""}) => {
  return (
<div className={`pill-wrapper  radius_3 commn-search ${mainClass}`}>
    <a href="#/" className="comman_action_icon filter-list-view d-none">
        <div className="action_icon with_bg_03 h35w35">
            <XIcon className="HW18" />
        </div>
    </a>
    <div className="filter-list-toggle">
        <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
            <div className="d-flex  radius_3 w-100 justify-content-between">
                <div className="comment-input-box">
                  <input type="text" className="form-control bg-transparent with-base-bg border-0  f-11 c-font pe-0 shadow-none" placeholder="Search" data-bs-toggle="tooltip" value={searchString} onChange={(e) => {onChangeHandler(e.target.value)}} />
                </div>
                <a href="#/" className="comman_action_icon d-flex align-items-center">
                    <div className="d-flex align-items-center ">
                        <div className="action_icon h25w25">
                            <MagnifyingGlassIcon className="HW14" />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
 );
};
export default CommanSearchbox;