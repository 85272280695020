import React from "react";
import { _l } from "../../hooks/utilities";
import { DropdownMenu } from 'reactstrap';
import HashIcon from "../../assets/icons/HashIcon";
const HeaderFilterDropdown = ({ widthClass, unControlList = false ,list = [], projectId, pageName, isLeadPage, removeStatusFilter}) => {
  const HeaderFilterDropdownContain = (<>
  {list && list.map((value)=>{return(<>
    <li className="hr_sepretr">
      <div className="d-flex align-items-center text-truncate  dropdown-item">
        <div className="align-items-center d-flex w100minus20 flex-grow-1">
            <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
            {value.image && value.main_key != "my_task" ?
            <div
            className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
            style={{
              backgroundImage: `url('${value.image}')`,
            }}>
          </div>  :
              <HashIcon className="HW18" /> }
            </div>
          <div className="ps-2 w100minus30">
            <div className="c-list-detail c-font f-14 text-truncate">{value.title}</div>
          </div>
        </div>
      </div>
    </li>
  </>)})}
  </>)
  return (
    <>
      {
        !unControlList ? (
          <DropdownMenu container="body" direction="down" className={`dropdown-menu w-170 overflowscroll ${widthClass}`} aria-labelledby="HeaderFilterDropdown">{HeaderFilterDropdownContain}</DropdownMenu>
        ) : (
          <ul className={`dropdown-menu w-170 overflowscroll ${widthClass}`} aria-labelledby="HeaderFilterDropdown">{HeaderFilterDropdownContain}</ul>
        )
      }
    </>
  );
};
export default HeaderFilterDropdown;
