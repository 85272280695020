import React from 'react';
 

function NutIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M8.39062 1.07227C8.21815 1.07227 8.0487 1.11744 7.89914 1.20326L2.65076 4.17109L2.64997 4.17154C2.49611 4.25892 2.36801 4.38531 2.27859 4.53799C2.18917 4.69066 2.14158 4.86421 2.14063 5.04114H2.14062V5.04383V10.9563L2.14063 10.959C2.14158 11.1359 2.18917 11.3095 2.27859 11.4622C2.36801 11.6148 2.49611 11.7412 2.64997 11.8286L2.65076 11.8291L7.8991 14.7969C8.04868 14.8827 8.21814 14.9279 8.39062 14.9279C8.56311 14.9279 8.73257 14.8827 8.88215 14.7969L14.1305 11.8291L14.1313 11.8286C14.2851 11.7412 14.4132 11.6148 14.5027 11.4622C14.5921 11.3095 14.6397 11.1359 14.6406 10.959L14.1406 10.9563H14.6406L14.6406 5.04383L14.6406 5.04114C14.6397 4.86421 14.5921 4.69066 14.5027 4.53799C14.4132 4.38531 14.2851 4.25892 14.1313 4.17154L14.1305 4.17109L8.88211 1.20326C8.73255 1.11744 8.5631 1.07227 8.39062 1.07227ZM13.6406 5.04624V10.9539C13.6406 10.9546 13.6405 10.9552 13.6402 10.9558C13.6401 10.9561 13.64 10.9565 13.6398 10.9568C13.6394 10.9575 13.6388 10.9581 13.6382 10.9586C13.638 10.9587 13.6378 10.9589 13.6376 10.959L13.6375 10.959L8.39062 13.926L3.14378 10.959L3.1437 10.959C3.14278 10.9585 3.14201 10.9577 3.14147 10.9568C3.14096 10.9559 3.14067 10.9549 3.14062 10.9539V5.04624C3.14067 5.04524 3.14096 5.04426 3.14147 5.04339C3.14202 5.04246 3.14279 5.0417 3.14371 5.04115L3.14378 5.04111L8.39062 2.07415L13.6375 5.04111L13.6375 5.04115L13.6386 5.0419L13.6398 5.04339C13.6403 5.04426 13.6406 5.04524 13.6406 5.04624ZM6.64062 8C6.64062 7.0335 7.42413 6.25 8.39062 6.25C9.35712 6.25 10.1406 7.0335 10.1406 8C10.1406 8.9665 9.35712 9.75 8.39062 9.75C7.42413 9.75 6.64062 8.9665 6.64062 8ZM8.39062 5.25C6.87184 5.25 5.64062 6.48122 5.64062 8C5.64062 9.51878 6.87184 10.75 8.39062 10.75C9.90941 10.75 11.1406 9.51878 11.1406 8C11.1406 6.48122 9.90941 5.25 8.39062 5.25Z"/>
</svg>

		</React.Fragment>
	)
}

export default NutIcon