import React from 'react';
import { _l } from '../../../hooks/utilities';
import { useSearchParams } from 'react-router-dom';
import CheckIcon from '../../../assets/icons/CheckIcon';
import MagnifyingGlassIcon from '../../../assets/icons/MagnifyingGlassIcon';
import UsersThreeIcon from '../../../assets/icons/UsersThreeIcon';
import UserPlusIcon from '../../../assets/icons/UserPlusIcon';

const userType = localStorage.getItem("user_type");

const TYPE_OF_TENANTS = [
  {
    key: userType === "operator" ? "add-employee" : "add-contact",
    value:  userType === "operator" ? "l_add_employee" : "l_add_tenant",
    icon: UserPlusIcon,
    description: "Description"
  },
  {
    key: "create-team",
    value: "l_create_team",
    icon: UsersThreeIcon,
    description: "Description"
  },
  {
    key: "find-contact",
    value: userType === "operator" ? "l_find_employee" : "l_find_contact",
    icon: MagnifyingGlassIcon,
    description: "Description"
  }
];

const TenantType = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const tenantType = searchParams.get("actionType")

  const selectTenantType = (value) => {
    searchParams.set("actionType", value);
    setSearchParams(searchParams);
  };

  return (
    <React.Fragment>
    {TYPE_OF_TENANTS.map((tenant, key) => {
      return (
        <div key={key} className="space-select-box select-input-cox mx-3 bg-transparent">
          <input
            className="bottom-0 end-0 form-check-input h_230px position-absolute start-0 top-0 w-100 bg-transparent"
            name="tenant-type"
            type="radio"
            value={tenant.key}
            checked={tenantType === tenant.key}
            onClick={(e) => {selectTenantType(e.target.value)}}
            disabled = {tenant.key == 'find-contact'}
          />
          <a href="#/" className="check-image">
            <div className="check-image-box">
              <CheckIcon className="HW14 color-white-03-solid" />
            </div>
          </a>
          <div className=''>
          <div className="d-flex bg-white-03 m-auto h_230px radius_3">
            <tenant.icon className="h60w60 stroke-width-3px" />
          </div>
          <div className="text-center p-10">
            <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
              {_l(tenant.value)}
            </div>
            <div className="text-center c-font f-14 color-white-60 fw-light">
              {_l(tenant.description)}
            </div>
          </div>
          </div>
        </div>
      );
    })}
  </React.Fragment>
  )
}

export default TenantType