import React, { useEffect, useState, useRef } from "react";
import Select, {components} from "react-select";
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import jquery from "jquery";
import Geocode from "react-geocode";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import { _l } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "react-bootstrap";
import { DEFAULT_ADDRESS, DEFAULT_LAT, DEFAULT_LNG } from "../../constants/constants";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CommentMinified from "../Comments/CommentMinified";
import documentsServices from "../../services/documents-services";
import { getMentionList } from "../../actions/customer";
import ChatCircleIcons from "../../assets/icons/ChatCircleIcons";
import InfoIcon from "../../assets/icons/InfoIcon";
import PencilIcon from "../../assets/icons/PencilIcon";

let showComments = false;

const Companyprofilestep = ({ setShowInfoModal, setPushNotificationPreference, setEmailNotificationPreference}) => {
  
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const [companyProfile, setCompanyProfile] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState(DEFAULT_ADDRESS);
  const [companyType, setCompanyType] = useState("");
  const [companyLable, setCompanyLable] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [locationLetAndLng, setLocationLetAndLng] = useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG
  });
  const [validResetDetails, setValidResetDetails] = useState(true);
  const [getDetails, setGetDetails] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState(DEFAULT_ADDRESS);
  const [taxNumber, setTaxNumber] = useState("");
  const [aditionalData, setAditionalData] = useState("");
  const [companyIdNumber, setCompanyIdNumber] = useState("");
  const userType = localStorage.getItem("user_type");
  const [logo , setLogo] = useState();
  const [regionList, setRegionList] = useState([]);
  const [regionvalue, setRegionValue] = useState([]);
  const [categoryListing, setCategoriesList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedKeys, setselectedKeys] = useState('');
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [replyCommentData, setReplyCommentData] = useState({});

  const dispatch = useDispatch();
  let providerID = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");


  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const companytype = [
    {
      value: "1",
      label: "Office",
    },
    {
      value: "2",
      label: "Coworking & Flex-office ",
    },
    {
      value: "3",
      label: "Building ",
    },
    {
      value: "4",
      label: " Multi-Properties",
    },
    {
      value: "5",
      label: "Hotel & Retail ",
    },
    {
      value: "6",
      label: "Property Manager & Facility Manager ",
    },
  ];

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["address"],
  };

  useEffect(() => {
    if (companyAddress && companyAddress.trim().length && inputRef.current) {
      try {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        ).addListener("place_changed", () => {
          setSuggestedAddress(inputRef.current.value);
          setCompanyAddress(inputRef.current.value);
        });
      } catch (e) {
        console.log(e);
      }
      getAddressForMapLocation(companyAddress);
    }
  }, [companyAddress]);

  const getAddressForMapLocation = (address) => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLocationLetAndLng({
          lat: lat,
          lng: lng,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    let regions = [];
    let categoryLists = [];
    CustomerServices.getRegionList().then((response) => {
      if(response.status)
      {
         regions = response.data.map((region) => {
          return {
            label: region.name,
            value: region.id,
          };
        })
        setRegionList(regions.sort((a, b) => a.label.localeCompare(b.label)));
      }
    })  
    CustomerServices.getProviderServices().then((response) => {
      if (response.status) {
         categoryLists = response.data.map((w) => {
            return { 
              label: w.name, 
              value: w.id, 
            };
          })
        setCategoriesList(categoryLists)
      }
    })
    CustomerServices.getCompanyProfile(
      companyName,
      companyAddress,
      companyType
    ).then((res) => {
      if (res.status && res.data) {
        localStorage.setItem("template_theme",res.data.template_theme)
        setCompanyProfile(res.data);
        setCompanyName(res.data.company ? res.data.company : "");
        setCompanyAddress(res.data.address ? res.data.address : "");
        setSuggestedAddress(res.data.address ? res.data.address : "");
        setTaxNumber(res.data.v_t_number ? res.data.v_t_number : "");
        setLogo(res.data.logo ?res.data.logo :"");
        setPushNotificationPreference(Number(res.data.push_notification));
        setEmailNotificationPreference(Number(res.data.email_notification_preference_type));
        setCompanyIdNumber(
          res.data.company_id_number ? res.data.company_id_number : ""
        );
        setAditionalData(
          res.data.additional_data ? res.data.additional_data : ""
        );

        let CompanyIndustryTypeData = res.data.industry_type;
        setCompanyLable(
          CompanyIndustryTypeData ? res.data.types[CompanyIndustryTypeData] : ""
        );
        setRegionValue(res.data.regions ? regions.filter((item) => res.data.regions.includes(item.value)).map((w) => {
          return{
            label : w.label,
            value : w.value,
            isFixed : true,
          }
        }) : []);
        setSelectedCategories(res.data.categories ? categoryLists.filter((item) => res.data.categories.includes(item.value)).map((w) => {
          return{
            label : w.label,
            value : w.value,
            isFixed : true,
          }
        })  : []);
        let industryTypeResponse = res.data.types
          ? res.data.types[CompanyIndustryTypeData]
          : "";
        let getCompanyTypeValue =
          industryTypeResponse == "Office"
            ? "1"
            : industryTypeResponse == "Coworking & Flex-office"
            ? "2"
            : industryTypeResponse == "Building"
            ? "3"
            : industryTypeResponse == "Multi-Properties"
            ? "4"
            : industryTypeResponse == "Hotel & Retail"
            ? "5"
            : industryTypeResponse == "Property Manager & Facitlity Manager"
            ? "6"
            : "";
        setValidResetDetails(true);
        setCompanyType(getCompanyTypeValue);
        jquery("#companyName").text(
          companyName != "" ? res.data.company : companyProfile.company
        );
      }
    });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_SECRET);
    Geocode.setLanguage("fr");
    Geocode.setRegion("fr");
  }, []);

  const validCompanyDetails = () => {
    if (companyName == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_the") + " " + _l("l_company_name"))
      );
      return;
    } else if (companyIdNumber == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_the") + " " + _l("l_company_id_number"))
      );
      return;
    } else if (
      !suggestedAddress.length ||
      !companyAddress.length ||
      suggestedAddress.toLowerCase() !== companyAddress.toLowerCase()
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_valid_address")
        )
      );
      return;
    }
    setShowLoader(true);
    CustomerServices.getCompanyUpdate(
      companyName,
      companyAddress,
      companyType,
      taxNumber,
      aditionalData,
      companyIdNumber,
      regionvalue,
      selectedCategories
    ).then((res) => {
      if (res.status) {
        setShowLoader(false);
        setValidResetDetails(false);
        setCompanyType(companyType);
        jquery("#companyName").text(companyName);
        localStorage.setItem("company_name", companyName);
        localStorage.setItem("company_address", companyAddress);
        localStorage.setItem("company_type", companyLable);
        dispatch(showMessage("sucess", _l("l_success"), res.message));
      }else{
        dispatch(showMessage("unsucess", _l("l_error"),  res.message));
        setShowLoader(false);
      }
    });
  };

  const resetDetails = () => {
    setCompanyName(
      getDetails != true
        ? companyProfile.company
        : localStorage.getItem("company_name")
    );
    setCompanyAddress(
      getDetails != true
        ? companyProfile.address
        : localStorage.getItem("company_address")
    );
    let companyTypeIndex = companyProfile.industry_type;
    setCompanyLable(companyProfile.types[companyTypeIndex]);
  };
 

  const handleUploadLogo =(files)=>{
    if (files.length === 1) {                            
      CustomerServices.updateCompanyLogo(
       files[0]                             
      ).then((res) => {
        if (res && res.status) {
          localStorage.setItem("company_logo_header", res.data.url);
        }
      })
      let thumbnails = [];
      Array.from(files).map((img) => {
        thumbnails.push({ name: img.name, url: URL.createObjectURL(img) });
      });
      setLogo(thumbnails[0].url)
    }
  }
  const MultiValueRemove = (props) => {
    if (props.data.isFixed) {
      return null;
    }
    return  <components.MultiValueRemove {...props} /> ;
  };
  const getTaskComments = async(type_key) => {
    showComments = true;
    setCommentsLoading(true);
    try {
      const comments = await documentsServices.getComments(providerID, type_key, undefined, undefined, 0);
      if(comments.data)
      {
        setCommentsList(comments.data.map((comment) => {
          return {
            id: comment.id,
            content: comment.content,
            contact_id: comment.contact_id,
            staffid: comment.staffid,
            full_name: comment.contact_full_name || comment.staff_full_name ,
            profile_url: comment.addedfrom_details.profile_url,
            attachments: comment.attachments,
            date: comment.time,
            dateadded: comment.dateadded,
            is_allowed_to_edit:comment.is_allowed_to_edit,
            parent_comment_details : comment.parent_comment_details ? comment.parent_comment_details : '',
            parent_comment_id : comment.parent_comment_id? comment.parent_comment_id : 0
          }
        }));
      }
    }
    catch(e)
    {
      console.error(e);
    }
    finally{
      setCommentsLoading(false);
    }
  }

  const getMentionlist = (type_key) => {
    dispatch(getMentionList(providerID, type_key));
  };
  
  const handleAddEditComment = async(content, files, id) => {
    setFileUploadLoader(true)
    try {   
      const response = await documentsServices.addDocumentComment(selectedKeys, providerID, content, 0, 0, files, 0, 0, 0 );
      if(id > 0)
      {
        const commentIndex = commentsList.findIndex(w => w.id == id);
        setCommentsList(Object.values({...commentsList, [commentIndex]: {...commentsList[commentIndex], content}}));
      }
      else {
        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];

        if(files)
        {
          Array.from(files).map((file) => {
            attachments.push({
              file_url: URL.createObjectURL(file),
              file_name: file.name
            })
          });
        }

        var addComments = {
          id: response.data.comment_id,
          content: content,
          contact_id: contact_id,
          staffid: staffid,
          full_name: full_name,
          profile_url: profile_url,
          is_allowed_to_edit:"1",
          attachments: attachments,
          date: response.data.time,
          dateadded:new Date(),
        }
        if (replyCommentData && replyCommentData.content != '' && replyCommentData.id != '') {
          addComments.parent_comment_details = {id : replyCommentData.id, content : replyCommentData.content , file_id : "0"}
          addComments.parent_comment_id = replyCommentData.id
        }else if(replyCommentData.attachments && replyCommentData.attachments[0].file_url != ''){
          let replyCommentDatas = replyCommentData.attachments[0]
          addComments.parent_comment_details = {content : "[task_attachment]", file_id : replyCommentDatas.file_id, file_name : replyCommentDatas.file_name, file_url: replyCommentDatas.file_url}
          addComments.parent_comment_id = replyCommentData.id
        }
        setCommentsList(commentsList.concat([addComments]));
        setFileUploadLoader(false);
      }
    }
    catch(e)
    {
      setFileUploadLoader(false);
      console.error(e);
    }
  };
  const handleDeleteComment = (comment) => {
    try {
      const response = documentsServices.removeComment(providerID, selectedKeys, comment.id);
      setCommentsList(commentsList.filter(x => x.id != comment.id));
      }
    catch(e)
    {
      console.error(e);
    }
  }

  return (
    <>
      <div className="tab-contain-wrapper comman_vertical_btn_h ">
        <div className="comman_top_header  p-0 d-none">
          <div className="comman-tab-wrapper pb-0">
            <div className="tab-wrapper d-inline-flex align-items-center">
              <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
              {_l('l_company_profile')}
              </div>
              <a href="#/" className="info-icon d-inline-block" 
              onClick={() => {
                setShowInfoModal(true);
              }}
              >
                <InfoIcon className="HW18 m-auto" />
              </a>
            </div>
          </div>
        </div>
        <div className="comman-content-scroll-wrapper p-15 bgOfficelisting lightthemebgwhite">
          <div className="comman-content-scroll">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <Form.Group className=" c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_company_logo')}
                </Form.Label>
                <div className="h70w70 comman-round-box rounded-circle bg-white-05">
                  <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" 
                   style={{backgroundImage: `url('${logo !="" ? logo: 'https://myrpp.myr.ai/orb/assets/images/default-comapny-placeholder.svg'}')`}}>
                  </div>
                  <a href="#/" className="remove-img large" >
                      <input
                          type="file"
                          accept="image/*"
                          className="absolute-input"
                          onChange={(event) => { handleUploadLogo(event.target.files) }} 
                      />
                      <PencilIcon className="HW14 m-auto" />
                  </a>
                </div>
                </Form.Group>
              </div>
              <div className="col-xl-6 mb-3">
                <Form.Group className=" c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_company_name')}
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      value={companyName}
                      type="text"
                      placeholder={`${_l("l_company_name_placeholder")}`}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setValidResetDetails(true);
                        setGetDetails(true);
                      }}
                    />
                { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                      <a href="#/"
                         
                         
                         
                         
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_company");
                          setselectedKeys("provider_company");
                        }}
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                            <ChatCircleIcons
                              className="HW20"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                        comments={commentsList}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={providerID}
                        loading={commentsLoading}
                        mentionList={mentionsList.map((name) => {
                          let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        unContolList={true}
                        showComments={showComments}
                        setReplyCommentData={setReplyCommentData}
                        fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>

                  </UncontrolledDropdown>
                  {companyProfile.provider_company > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_company}</span>}
                </div>
                }
                  </div>
                </Form.Group>
                
              </div>
                <div className="col-xl-6 mb-3 z-index-3">
                  <Form.Group className="c-input-box  position-relative ">
                    <Form.Label className="input-label no-asterisk ">
                    {_l('l_company_type')}
                    </Form.Label>
                    <div className="d-flex">
                      <div className="d-flex flex-grow-1 align-items-center">
                        <Select
                          className="custom-select-menu w-100"
                          classNamePrefix="react-select"
                          // menuIsOpen={true}
                          placeholder={`${_l("l_select_company_type")}`}
                          options={companytype}
                          closeMenuOnSelect={true}
                          value={
                            companyType != "" || companyLable != ""
                              ? { value: companyType, label: companyLable }
                              : ""
                          }
                          onChange={(e) => {
                            setCompanyType(e.value);
                            setCompanyLable(e.label);
                            setValidResetDetails(true);
                          }}
                        />
                      </div>
                      { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                          <a href="#/"
                             
                             
                             
                             
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_industry_type");
                              setselectedKeys("provider_industry_type");
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW20"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>

                      </UncontrolledDropdown>
                      {companyProfile.provider_industry_type > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_industry_type}</span>}
                    </div>}

                    </div>
                  </Form.Group>
                </div>
                <div className="col-xl-6 mb-3">
                  <Form.Group className=" c-input-box  position-relative">
                    <Form.Label className="input-label no-asterisk ">
                    {_l('l_company_id_number')} 
                    </Form.Label>
                    <div className="d-flex">
                    <Form.Control
                      type="text"
                      placeholder={_l('l_company_id_number')}
                      value={companyIdNumber}
                      onChange={(e) => {
                        setCompanyIdNumber(e.target.value);
                      }}
                    />
                      { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                          <a href="#/"
                             
                             
                             
                             
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_company_id_number");
                              setselectedKeys("provider_company_id_number");
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW20"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>

                      </UncontrolledDropdown>
                      {companyProfile.provider_company_id_number > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_company_id_number}</span>}
                    </div>}

                    </div>
                  </Form.Group>
                </div>
              <div className="col-xl-6 mb-3">
                <Form.Group className=" c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {userType == "contact"
                      ? _l('l_vat_number')
                      : _l('l_tax_id_number')}
                  </Form.Label>
                  <div className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder= {userType == "contact"? _l('l_vat_number'): _l('l_tax_id_number')}
                    value={taxNumber}
                    onChange={(e) => {
                      setTaxNumber(e.target.value);
                    }}
                  />
                  { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                    <UncontrolledDropdown className="w-100">
                      <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                        <a href="#/"
                           
                           
                           
                           
                          onClick={(e) => {
                            getMentionlist("profile_page");
                            getTaskComments("provider_v_t_number");
                            setselectedKeys("provider_v_t_number");
                          }}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                              <ChatCircleIcons
                                className="HW20"
                              />
                            </div>
                          </div>
                        </a>
                      </DropdownToggle>
                      <DropdownItem toggle={false} className="p-0">
                        {
                          <CommentMinified
                          comments={commentsList}
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={providerID}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          unContolList={true}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                          />
                        }
                      </DropdownItem>

                    </UncontrolledDropdown>
                    {companyProfile.provider_v_t_number > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_v_t_number}</span>}
                  </div>}

                  </div>
                </Form.Group>
              </div>
              {userType == "operator" ? <>
              <div className="col-xl-6 mb-3">
                <Form.Group className="c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk">
                    {_l("l_select_region")}
                  </Form.Label>
                  <div className="d-flex">
                  <div className="d-flex flex-grow-1 align-items-center">
                    <Select
                      placeholder={_l("l_select_region")}
                      className="custom-select-menu z-index-12 w-100"
                      classNamePrefix="react-select"
                      isMulti
                      value={regionvalue}
                      isClearable={false}
                      options={regionList}
                      components={{MultiValueRemove}}
                      onChange={(value) => { setRegionValue(value)}}
                    />
                  </div>
                    { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                          <a href="#/"
                             
                             
                             
                             
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_regions");
                              setselectedKeys("provider_regions");
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW20"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>

                      </UncontrolledDropdown>
                      {companyProfile.provider_regions > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_regions}</span>}
                    </div>}

                  </div>
                </Form.Group>
              </div>
              <div className="col-xl-6 mb-3">
                <Form.Group className="c-input-box  position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {" "}
                    {_l("l_select_category")}
                  </Form.Label>
                  <div className="d-flex">
                    <Select
                      placeholder={_l("l_select_category")}
                      className="custom-select-menu w-100"
                      classNamePrefix="react-select"
                      styl es={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      options={categoryListing}
                      isClearable={false}
                      value={selectedCategories}
                      onChange={(option) => { setSelectedCategories(option) }}
                      components={{MultiValueRemove}}
                      isMulti
                    />
                    { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                          <a href="#/"
                             
                             
                             
                             
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_categories");
                              setselectedKeys("provider_categories");
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW20"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>

                      </UncontrolledDropdown>
                      {companyProfile.provider_categories > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_categories}</span>}
                    </div>}

                  </div>
                </Form.Group>
              </div>
              </> :<></>}
              <div className="col-xl-12 mb-3">
                <Form.Group className=" c-input-box ">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_company_address')} 
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      ref={inputRef}
                      value={companyAddress}
                      onChange={(e) => {
                        setCompanyAddress(e.target.value);
                        setValidResetDetails(true);
                        setGetDetails(true);
                      }}
                      rows={3}
                      placeholder={`${_l("l_address")}`}
                    ></Form.Control>
                    { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                          <a href="#/"
                             
                             
                             
                             
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_address");
                              setselectedKeys("provider_address");
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW20"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>

                      </UncontrolledDropdown>
                      {companyProfile.provider_address > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_address}</span>}
                    </div>}

                  </div>
                </Form.Group>
              </div>
              <div className="col-xl-12 mb-3">
                <Form.Group className=" c-input-box ">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_additional_data')}  
                  </Form.Label>
                  <div className="d-flex">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder={_l('l_additional_data_placeholder')}
                      value={aditionalData}
                      onChange={(e) => {
                        setAditionalData(e.target.value);
                      }}
                    ></Form.Control>
                    { userType == "operator" && <div className={`pe-0 dropdown-center dropdown with_separator_10 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                          <a href="#/"
                             
                             
                             
                             
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_additional_data");
                              setselectedKeys("provider_additional_data");
                            }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h53w53 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW20"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>

                      </UncontrolledDropdown>
                      {companyProfile.provider_additional_data > 0 && <span className="rounded-pill badge-custom d-flex ">{companyProfile.provider_additional_data}</span>}
                    </div>}

                  </div>
                </Form.Group>
              </div>  
              <div className="col-xl-12">
                <Form.Group className=" map-box-parent">
                  {Object.keys(locationLetAndLng).length ? (
                    <GoogleMap
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                      options={{
                        streetViewControl: false,
                        scrollwheel: false,
                      }}
                      mapContainerStyle={containerStyle}
                      center={locationLetAndLng}
                      zoom={8}
                    >
                      <Marker position={locationLetAndLng} />
                    </GoogleMap>
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="comman-content-bottom-btn d-flex justify-content-end align-items-center">
            <a href="#/"
              className="btn btn-secondary me-3"
              onClick={validResetDetails == true ? resetDetails : ""}
            >
              {_l('l_reset')}
            </a>
            <Button className={`btn btn-primary`} disabled={showLoader} onClick={validCompanyDetails}>
            {showLoader ? _l('l_please_wait') : _l('l_submit')}
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
          </div>
        </div>
      </div>
       
    </>
  );
};

export default Companyprofilestep;
