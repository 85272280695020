import { CALENDAR_TOPICS, CHECK_CALENDAR_CONNECTIVITY, GET_GOOGLE_CONTACTS, REFRESH_EVENTS_OF_CALENDAR } from "../actions/action-type";

export default function (state = [], action) {
    switch (action.type) {
      case CHECK_CALENDAR_CONNECTIVITY: 
        return {...state, googlecalendar_connectivity: action.payload};
  
      case CALENDAR_TOPICS: 
        return {...state, topics: action.payload};

      case GET_GOOGLE_CONTACTS: 
        return {...state, gcalendar_contacts: action.payload};

      case REFRESH_EVENTS_OF_CALENDAR: 
        return {...state, refresh_events: action.payload};
        
      default:
        return state;
    }
  }