import React, { useEffect, useState } from "react";
import AddNewCategory from "./AddNewCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  getDefaultTopics,
  getSelectedTask,
  updateProjectTopics,
  getNewCustomer,
} from "../../actions/customer";
import CustomerServices from "../../services/customer-services";
import { _l, accessPremiumFeature, showLeftPanelNew } from "../../hooks/utilities";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Toasts from "../Toasts/Toasts";
import CategoryProviderList from "./CategoryProviderList";
import CreateTeam from "./CreateTeam";
import AddEmployee from "./AddEmployee";
import AddOperator from "./AddOperator";
import { is_calendar_connected } from "../../actions/outlook_auth";
import outlookCalendar from "../../services/outlook-calendar";
import discordServices from "../../services/discord-services";
import { is_discord_connected } from "../../actions/discord_auth";
import slackServices from "../../services/slack-services";
import { is_slack_connected } from "../../actions/slack_auth";
import googleCalendar from "../../services/google-calendar";
import { is_googlecalendar_connected } from "../../actions/googlecalendar_auth";
import googleDriveServices from "../../services/google-drive-services";
import { is_google_drive_connected, is_onedrive_connected } from "../../actions/google-drive";
import AddEditEquipment from "./AddEditEquipment";
import onedriveServices from "../../services/onedrive-services";
import documentsServices from "../../services/documents-services";
import { EQUIPMENTS_CATEGORY_TOPICS, ESTIMATE_TOPICS, STAFF_TOPICS } from "../../actions/action-type";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import PlusIcon from "../../assets/icons/PlusIcon";
import UserGearCircleIcon from "../../assets/icons/UserGearCircleIcon";
import AddProvider from "./AddProvider";
import ProviderDropdownWithprogressBar from "../Dropdowns/ProviderDropdownWithprogressBar";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import UserIcon from "../../assets/icons/UserIcon";
import HashIcon from "../../assets/icons/HashIcon";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import PencilIcon from "../../assets/icons/PencilIcon";

const AddEditCategory = ({
  projectId,
  show,
  handleClose,
  activeTopic,
  pageName,
  editCustomTopic,
  deleteCustomTopic,
  outlookFlag,
  deleteEquipment,
  setActiveTopic
}) => {

  const [defaultTopics, setDefaultTopics] = useState([]);
  const [activeTopicKey, setActiveTopicKey] = useState(activeTopic);
  const [topicCategory, setTopicCategory] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [myPeopleCategory, setMyPeopleCategory] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showAddOperatorModal, setShowAddOperatorModal] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [tagId, setTagId] = useState(false);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const stateTopicData = useSelector((state) => state.customer.topicData);
  const [showAddCategoryForMe, setshowAddCategoryForMe] = useState(false);
  const [equipmentDetail, setEquipmentDetail] = useState("");
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [isEditTopic, setIsEditTopic] = useState({})
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [categoryListing, setCategoryListing] = useState();
  const [Modal1show, setModal1show] = useState(false);
  const [selectOperators, setSelectOperators] = useState({});
  const defaultTopicState = useSelector(
    (state) => state.customer.defaultTopics
  );
  const discord_topics = useSelector(
    (state) => state.discord_auth.topics
  );
  const google_drive_topics = useSelector(
    (state) => state.googleDrive.topics
  );
  const outlook_topics = useSelector(
    (state) => state.discord_auth.outlook_calendar_topics
  );

  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const google_topics = useSelector(
    (state) => state.google_calendar.topics
  );
  const selectedProject = useSelector(
    (state) => state.customer.selectedProject
  );
  const [selectedTab, setSelectedTab] = useState("contacts");
  const dispatch = useDispatch();
  const userType = localStorage.getItem("user_type");
  const [isOutlookCalendarActive, setIsOutlookCalendarActive] = useState(outlookFlag == true ? true : false);
  const outlook_calendar = useSelector((state) => state.discord_auth.outlook_calendar_connectivity);
  const estimateTopics = useSelector((state) => state.customer.estimateTopics);
  const staffTopics = useSelector((state) => state.customer.staffTopics);
  const equipmentTopics = useSelector((state) => state.customer.equipmentTopics);  
  const newCusData = useSelector((state) => state.customer.newCusData);
  const contact_role = localStorage.getItem("contact_role");

  useEffect(() => {
    if (outlook_calendar != undefined) {
      if (outlook_calendar.status == 1) {
        setIsOutlookCalendarActive(true)
      }
    }
  }, [outlook_calendar])
  window.addEventListener("outlook_calendar", () => {
    setIsOutlookCalendarActive(true);
  });
  
  useEffect(() => {
    if (defaultTopicState && activeTopic) {
      if (discord_topics) {
        defaultTopicState.push(discord_topics);
      }
      if (google_drive_topics) {
        defaultTopicState.push(google_drive_topics);
      }

      if (estimateTopics) {
        defaultTopicState.push(estimateTopics.data ? estimateTopics.data  : []);
      }
      if (staffTopics) {
        defaultTopicState.push(staffTopics.data ? staffTopics.data  : []);
      }
      if (equipmentTopics) {
        defaultTopicState.push(equipmentTopics.data ? equipmentTopics.data  : []);
      }
      if (outlook_topics) {
        defaultTopicState.push(outlook_topics);
      }
      if (google_topics) {
        defaultTopicState.push(google_topics);
      }
      if(newCusData)
      {
        if(defaultTopicState.indexOf(newCusData.invoice_topic) == -1)
        {
          defaultTopicState.push(newCusData.invoice_topic ? newCusData.invoice_topic : []);
        }  if(defaultTopicState.indexOf(newCusData.proposal_topic) == -1)
        {
          defaultTopicState.push(newCusData.proposal_topic ? newCusData.proposal_topic : []);
        }  if(defaultTopicState.indexOf(newCusData.space_topic) == -1)
        {
          defaultTopicState.push(newCusData.space_topic ? newCusData.space_topic : []);
        }  if(defaultTopicState.indexOf(newCusData.customer_partner) == -1)
        {
          defaultTopicState.push(newCusData.customer_partner ? newCusData.customer_partner : []);
        } if(defaultTopicState.indexOf(newCusData.group_topic) == -1)
        {
          defaultTopicState.push(newCusData.group_topic ? newCusData.group_topic : []);
        } if(defaultTopicState.indexOf(newCusData.country_topic) == -1)
        {
          defaultTopicState.push(newCusData.country_topic ? newCusData.country_topic : []);
        } if(defaultTopicState.indexOf(newCusData.estimate_topic) == -1)
        {
          defaultTopicState.push(newCusData.estimate_topic ? newCusData.estimate_topic : []);
        }
      }
      
      let selectedTopic = defaultTopicState.findIndex(
        (topic) => topic.main_key === activeTopic
      );
      if (selectedTopic === -1) {
        selectedTopic = 0;
      }
      var filteredTopics = [];
      if (window.location.pathname == "/MyEmail") {
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "my_customs",
            "my_customers",
            "my_people",
            "myr_customers",
            "myr_providers",
            "providers",
            "my_staff",
            "myr_leads",
          ].includes(cat.main_key)
        );
      } else if (window.location.pathname == "/leads") {
        filteredTopics = defaultTopicState.filter((cat) =>
          ["my_task", "my_staff"].includes(cat.main_key)
        );
      } else if (window.location.pathname == "/calendar") {
        if (contact_role == 3) {
          filteredTopics = defaultTopicState.filter((cat) =>
            [
              "my_task",
              "my_calendars",
              "slack_channel",
              "google_drive_documents",
              "onedrive_documents"
            ].includes(cat.main_key)
          );
        } else if (contact_role == "undefined" && userType == "staff") {
          filteredTopics = defaultTopicState.filter((cat) =>
            [
              "my_task",
              "my_staff",
              "task_topic"
            ].includes(cat.main_key)
          );
        } else{
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "my_task",
            "proposals",
            "my_people",
            "equipments",
            "user_journey",
            "my_staff",
            "request_people",
            "my_calendars",
            "slack_channel",
            "task_topic",
            "google_drive_documents",
            "onedrive_documents",
            "providers"
          ].includes(cat.main_key)
        );}

      } else if (window.location.pathname == "/documents") {
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "my_task",
            "providers",
            "proposals",
            "my_people",
            "equipments",
            "user_journey",
            "my_staff",
            "task_topic",
            "google_drive_documents",
            "onedrive_documents"
          ].includes(cat.main_key)
        );

      } else if (window.location.pathname == "/estimate") {
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "my_task",
            "my_estimates",
            "my_staff",
            "task_topic",
            "proposals"
          ].includes(cat.main_key)
        );

      } else if (window.location.pathname == "/staff") {
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "my_staff_categories",
          ].includes(cat.main_key)
        );
      } else if (window.location.pathname == "/equipmentListing") {
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "equipment_categories",
          ].includes(cat.main_key)
        );
      } else if (contact_role == 3 ){
        filteredTopics = defaultTopicState.filter(
          (cat) =>
          ["my_task"].includes(cat.main_key)
            );
      }  else if (contact_role == "undefined" && userType == "staff") {
        filteredTopics = defaultTopicState.filter((data) => 
        ["my_task", 
        "my_staff"].includes(data.main_key))
      }else if(userType == "contact"){
        filteredTopics = defaultTopicState.filter((data) => 
        ["my_task", 
        "providers","my_contact","equipments"].includes(data.main_key))
      }else if(userType == "operator"){
        filteredTopics = defaultTopicState.filter((data) => 
        ["my_task", 
        "providers","my_operator","equipments"].includes(data.main_key))
      } else if (window.location.pathname == "/clients") {
        filteredTopics = defaultTopicState.filter((cat) =>
          [
            "my_task",
            "my_staff",
            "invoice_topic",
              "estimate_topic",
              "proposal_topic",
              "space_topic",
              "customer_partner",
              "customer_groups",
              "country_topic"
          ].includes(cat.main_key)
        );
        } else {
        filteredTopics = defaultTopicState.filter(
          (cat) =>
          ![
              "my_customs",
              "my_customers",
              "myr_customers",
              "myr_providers",
              "myr_leads",
              "my_calendars",
              "my_estimates",
              "my_staff_categories",
              "invoice_topic",
              "estimate_topic",
              "proposal_topic",
              "space_topic",
              "customer_partner",
              "customer_groups",
              "country_topic",
            ].includes(cat.main_key)
            );
      }

      setDefaultTopics(filteredTopics)
      setTopicCategory(defaultTopicState[selectedTopic].categories);
      setActiveTopicKey(defaultTopicState[selectedTopic].main_key);
      let inputs = [];
      if (defaultTopicState) {
        defaultTopicState.forEach((topic) => {
          if (topic && topic.categories != undefined) {
            topic.categories.forEach((category) => {
              inputs[`${category.filter_key}`] = parseInt(category.is_active) === 1;
            });
          }
        });
      }
      if (activeTopic == "my_people" || activeTopic == "task_topic") {
        if (activeTopic == "task_topic") {
          setMyPeopleCategory(
            defaultTopicState[selectedTopic].categories.filter(
              (category) => category.filter_key < 0
            )
          );
        } else {
          setMyPeopleCategory(
            defaultTopicState[selectedTopic].categories.filter(
              (category) => category.filter_key > 0
            )
          );
        }
      } else {
        setMyPeopleCategory([1]);
      }
    }
  }, [defaultTopicState, activeTopic]);

  const handleChange = (key) => {
    if (key != undefined) {
      setActiveTopic(key);      
    }
    setSearchString("");
    setTopicCategory([]);
    const activeTopic = defaultTopics.filter((topic) => topic.main_key === key);
    if (activeTopic.length) {
      setTopicCategory(activeTopic[0].categories);
      setActiveTopicKey(key);
    }
    if (key == "my_people" || key == "task_topic") {
      setSelectedTab("contacts");
      if (key == "task_topic") {
        setMyPeopleCategory(
          activeTopic &&
          activeTopic[0] &&
          activeTopic[0].categories.filter(
            (category) => category.filter_key < 0
          )
        );
      } else {
        setMyPeopleCategory(
          activeTopic &&
          activeTopic[0] &&
          activeTopic[0].categories.filter(
            (category) => category.filter_key > 0
          )
        );
      }
      
    } else {
      setMyPeopleCategory([1]);
    }
  };

  const categoryToggle = (filter_key, activeOrInactive,main_sub_key) => {
    if (activeTopicKey == "my_calendars" && isOutlookCalendarActive == true) {
      outlookCalendar.change_topic_status(
        filter_key,
        activeOrInactive,
        selectedProject,
        activeTopicKey
      ).then((res) => {
        let tempTopics = defaultTopics.map((topic) => {
          if (topic.main_key == activeTopicKey) {
            topic.categories.forEach((category) => {
              if (category.filter_key == filter_key) {
                category["is_active"] = activeOrInactive;
                return category;
              }
              return category;
            });
            return topic;
          }
          return topic;
        });

        dispatch(is_calendar_connected(selectedProject));
        setDefaultTopics(tempTopics);
      })
    } else
      if (activeTopicKey == "my_calendars" && googlecalendar_connectivity.status == 1) {
        googleCalendar.change_topic_status(
          filter_key,
          activeOrInactive,
          selectedProject,
          activeTopicKey
        ).then((res) => {
          let tempTopics = defaultTopics.map((topic) => {
            if (topic.main_key == activeTopicKey) {
              topic.categories.forEach((category) => {
                if (category.filter_key == filter_key) {
                  category["is_active"] = activeOrInactive;
                  return category;
                }
                return category;
              });
              return topic;
            }
            return topic;
          });

          dispatch(is_googlecalendar_connected(selectedProject));
          setDefaultTopics(tempTopics);
        })
      } else if (activeTopicKey == "google_drive_documents") {
        googleDriveServices
          .change_topic_status(selectedProject, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(is_google_drive_connected(selectedProject))
              setDefaultTopics(tempTopics);
            }

          });
      } else if (activeTopicKey == "my_estimates") {
        documentsServices
          .changeEstimateTopics(filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              let data = res.data;
              dispatch({
                type: ESTIMATE_TOPICS,
                payload: {data}
              })
              setDefaultTopics(tempTopics);
            }

          });
      } else if (activeTopicKey == "my_staff_categories") {
        documentsServices
          .changeEstimateTopics(filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              let data = res.data;
              dispatch({
                type: STAFF_TOPICS,
                payload: {data}
              })
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "onedrive_documents") {
        onedriveServices
          .change_topic_status(filter_key, activeOrInactive, selectedProject)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(is_onedrive_connected(selectedProject));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "discord_channels") {
        discordServices
          .change_topic_status(selectedProject, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(is_discord_connected(selectedProject))
              setDefaultTopics(tempTopics);
            }

          });
      } else if (activeTopicKey == "slack_channel") {
        slackServices
          .change_topic_status_slack(selectedProject, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(is_slack_connected(selectedProject))
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "equipment_categories") {
        documentsServices
          .changeEquipmentStatus(selectedProject, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              let data = tempTopics[0];
              dispatch({
                type: EQUIPMENTS_CATEGORY_TOPICS,
                payload: { data }
              })
              setDefaultTopics(tempTopics);
            }

          });
      } else if (activeTopicKey == "customer_groups") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "invoice_topic") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "estimate_topic") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "proposal_topic") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "country_topic") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "customer_partner") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              let data = tempTopics[0];
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }else if (activeTopicKey == "space_topic") {
        documentsServices
          .changeEquipmentStatus(activeTopicKey, filter_key, activeOrInactive)
          .then((res) => {
            if (res.status) {
              let tempTopics = defaultTopics.map((topic) => {
                if (topic.main_key == activeTopicKey) {
                  topic.categories.forEach((category) => {
                    if (category.filter_key == filter_key) {
                      category["is_active"] = activeOrInactive;
                      return category;
                    }
                    return category;
                  });
                  return topic;
                }
                return topic;
              });
              dispatch(getNewCustomer(1,1));
              setDefaultTopics(tempTopics);
            }

          });
      }
      else {
        CustomerServices.changeMainTopicStatus(
          selectedProject,
          activeTopicKey,
          filter_key,
          activeOrInactive,
          main_sub_key
        ).then((res) => {
          if (res.status) {
            let tempTopics = defaultTopics.map((topic) => {
              if (topic.main_key == activeTopicKey) {
                topic.categories.forEach((category) => {
                  if (category.filter_key == filter_key) {
                    category["is_active"] = activeOrInactive;
                    return category;
                  }
                  return category;
                });
                return topic;
              }
              return topic;
            });
            setDefaultTopics(tempTopics);
            dispatch(getDefaultTopics(projectId,"","",pageName,[]));  
          }
        });
      }
  };

  const handleSearch = (e, flagCategory = "searchInit") => {
    let searchString;
    if (flagCategory == "") {
      searchString = "";
    } else {
      searchString = e.target.value;
    }
    setSearchString(searchString);

    let activeTopic = defaultTopics.filter(
      (topic) => topic.main_key === activeTopicKey
    );

    if (searchString && activeTopic.length) {
      if (activeTopicKey == "my_people") {
        if (selectedTab == "teams") {
          setTopicCategory(
            activeTopic[0].categories.filter(
              (category) =>
                category.filter_key < 0 &&
                category.title &&
                category.title
                  .toLowerCase()
                  .includes(searchString.toLowerCase())
            )
          );
        } else {
          setTopicCategory(
            activeTopic[0].categories.filter(
              (category) =>
                category.filter_key > 0 &&
                category.title &&
                category.title
                  .toLowerCase()
                  .includes(searchString.toLowerCase())
            )
          );
        }
      } else if (activeTopicKey == "task_topic") {
        if (selectedTab == "teams") {
          setTopicCategory(
            activeTopic[0].categories.filter(
              (category) =>
                category.filter_key > 0 &&
                category.title &&
                category.title
                  .toLowerCase()
                  .includes(searchString.toLowerCase())
            )
          );
        } else {
          setTopicCategory(
            activeTopic[0].categories.filter(
              (category) =>
                category.filter_key < 0 &&
                category.title &&
                category.title
                  .toLowerCase()
                  .includes(searchString.toLowerCase())
            )
          );
        }
      } else {
        setTopicCategory(
          activeTopic[0].categories.filter(
            (category) =>
              category.title &&
              category.title.toLowerCase().includes(searchString.toLowerCase())
          )
        );
      }
    } else {
      setTopicCategory(activeTopic[0].categories);
    }
  };
  const SetLeftPanel = () => {
    showLeftPanelNew();
  };
  const prepareToast = (classType, header, message) => {
    setShowToast(true);
    setToastClass(classType);
    setToastHeader(header);
    setToastMessage(message);
  };
  const handleAddNewCategory = () => {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
    if (searchString && searchString != "" && !format.test(searchString)) {
      CustomerServices.addCategory(searchString).then((res) => {
        if (res.status == "1" && res.data) {
          const temp = {
            filter_key: res.data.category_id,
            title: searchString,
            is_active: 1,
          };
          handleSearch(undefined, "");
          setTopicCategory((topicCategory) => [...topicCategory, temp]);
          setDefaultTopics((defaultTopics) =>
            defaultTopics.map((obj) => {
              if (obj && obj.main_key == "providers") {
                return {
                  ...obj,
                  categories: [...obj.categories, temp],
                };
              }
              return obj;
            })
          );
          prepareToast(
            "sucess",
            _l("l_success"),
            _l("l_benchmark_category_added_sucessfully")
          );
        } else {
          prepareToast(
            "unsucess",
            _l("l_error"),
            _l("l_category_cannot_added")
          );
        }
      });
    } else {
      prepareToast("unsucess", _l("l_error"), _l("l_category_is_invalid"));
    }
  };

  const handleAddCategory = (addedCategory) => {
    setTopicCategory(
      topicCategory.concat({
        filter_key: addedCategory.provider_id,
        title: addedCategory.name,
        is_active: 0,
        custom_keys: [],
      })
    );
  };

  const handleAddedTeam = (addedTeam) => {
    setTopicCategory(
      topicCategory.concat({
        filter_key: addedTeam.id * -1,
        title: addedTeam.teamTitle,
        is_active: 0,
        custom_keys: [],
      })
    );
  };

  const handleAddedContact = (addedContact) => {
    setTopicCategory(
      topicCategory.concat({
        filter_key: addedContact.id,
        title: addedContact.full_name,
        is_active: 0,
        custom_keys: [],
      })
    );
  };
  const handleAddedOperator = (addedOperator) => {
    setTopicCategory(
      topicCategory.concat({
        filter_key: addedOperator.id,
        title: addedOperator.full_name,
        is_active: 0,
        custom_keys: [],
        image: addedOperator.image,
      })
    );
  };

  const removeTaskTopic = (id) => {
    CustomerServices.removeTopicTag(id).then((res) => {
      if (res.status == 1) {
        dispatch(getDefaultTopics(projectId, "delete", id));
        dispatch(toastCloseAction());
        if (selectedTask && Object.keys(selectedTask).length > 0) {
          dispatch(
            getSelectedTask(
              localStorage.getItem("selectedOffice"),
              selectedTask.id
            )
          );
        }
      }else{
        dispatch(showMessage("unsucess", _l("l_error"),res.message));
      }
    });
  };
  const handleAddTopic = (category) => {
    dispatch(
      updateProjectTopics(
        { filter_key: category.id, title: category.name, custom_keys: [] },
        "my_task",
        Object.keys(isEditTopic).length > 0 ? isEditTopic.id : 0
      )
    );
    let categoryArr = topicCategory
    let Index = categoryArr.findIndex((c) => c.filter_key == category.id)
    if (Index > -1) {
      categoryArr[Index].title = category.name;
      setTopicCategory(categoryArr);
    } else {
      setTopicCategory(
        topicCategory.concat({
          filter_key: category.id,
          title: category.name,
          is_active: 1,
          custom_keys: [],
        })
      );
    }
  };
  useEffect(() => {
    if (defaultTopics && defaultTopics.length > 0) {
      let Arr = []
      var main_key = userType == "contact" ? "providers" : "proposals";
      defaultTopics.map((item) => {
        if (item.main_key == main_key) {
          item.categories.map((item1) => {
            return Arr.push({
              value: parseInt(item1.filter_key),
              label: item1.title,
              is_default: item1.is_default,
            });
          })
        }
      });
      setCategoryListing(Arr)
    }
  }, [defaultTopics]);
  return (
    <>
      {showToast ? (
        <Toasts
          cancelButtonHandler={() => { }}
          cancelButtonText={() => { }}
          confirmButtonHandler={() => { }}
          confirmButtonText={""}
          delay={2500}
          handler={() => setShowToast(false)}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
        />
      ) : (
        <></>
      )}
      <Modal
        show={show}
        onHide={()=> {
          handleClose()
          dispatch(toastCloseAction());
        }}
        className="custom-modal-style add-topic-search overflow-hidden with-min-height"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header
          className="modal-header border-0"
          closeButton
          onClick={() => {
            window.dispatchEvent(new Event("addEditCategoryModalClose"));
            dispatch(getDefaultTopics(projectId, pageName));
            SetLeftPanel();
          }}
        >
          {showToast ? (
            <Toasts
              delay={2000}
              handler={() => {
                setShowToast(false);
                setTagId(0);
              }}
              header={toastHeader}
              message={toastMessage}
              toastClass={toastClass}
              cancelButtonText={cancelButtonText}
              cancelButtonHandler={() => {
                setShowToast(false);
                setTagId(0);
              }}
              confirmButtonText={confirmButtonText}
              confirmButtonHandler={() => {
                removeTaskTopic(tagId);
                setShowToast(false);
                setTagId(0);
              }}
            />
          ) : (
            <></>
          )}
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="modal-title title-fonts fw-semibold d-flex align-items-center">
              {_l("l_set_topic")}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 d-flex flex-column light-full-bg-modal">
          <div className="custom-modal-section-sticky">
            <div className="comman-tab-wrapper">
              <div className="d-flex align-items-center border-bottom">
                { window.location.pathname == "/estimate" || window.location.pathname == "/staff" || window.location.pathname == "/equipmentListing" ? 
                defaultTopics.map((topic, index) => {
                  return (
                    <div
                      key={index}
                      className={`tab-wrapper ${topic.main_key === activeTopicKey ||
                        (!activeTopicKey && !index)
                        ? "active"
                        : ""
                        } d-flex align-items-center ${topic.main_key == "myr_staff_task" && localStorage.getItem('selectedVirtualSpace') ? "d-none" : ""
                        }`}
                      onClick={() => {
                        handleChange(topic.main_key);
                        SetLeftPanel();
                      }}
                    >
                      <a href="#/" className="tab-name">{_l(topic.title)}</a>
                    </div>
                  );
                })
                :
                defaultTopics && stateTopicData &&
                stateTopicData.map((td) => {
                  return(
                  defaultTopics.map((topic, index) => {
                    if ((td.main_key == topic.main_key) && (td.main_key != undefined && topic.main_key != undefined)){
                    return (
                      <div
                        key={index}
                        className={`tab-wrapper ${topic.main_key === activeTopicKey ||
                          (!activeTopicKey && !index)
                          ? "active"
                          : ""
                          } d-flex align-items-center ${topic.main_key == "myr_staff_task" && localStorage.getItem('selectedVirtualSpace') ? "d-none" : ""
                          }`}
                        onClick={() => {
                          handleChange(topic.main_key);
                          SetLeftPanel();
                        }}
                      >
                        <a href="#/" className="tab-name">{_l(topic.title)}</a>
                      </div>
                    );
                  } 
                  }))
                })
                }
              </div>
            </div>
            <div className="d-flex align-items-center flex-grow-1">
              <Form.Group className="comment-input-box d-flex flex-grow-1">
                <Form.Control
                  className=" border-0 pe-2"
                  type="text"
                  placeholder={`${_l("l_search")}`}
                  aria-label="default input example"
                  value={searchString}
                  onChange={(e) => handleSearch(e)}
                />
                <a href="#/"
                  className={`send-arrow-btn ${activeTopicKey == "providers" ? "" : "d-none"
                    }`}
                  onClick={() => {
                    handleAddNewCategory();
                  }}
                >
                  <ArrowRightIcon className="HW18" />
                </a>
              </Form.Group>

              {activeTopicKey === "my_people" ? (
                <>
                  <Button
                    className="action_icon with_bg h40w40 ms-2"
                    variant="secondary"
                     
                     
                     
                    onClick={() => {
                      setShowAddTeamModal(true);
                    }}
                  >
                    <UsersThreeIcon className="HW14" />
                  </Button>

                  <Button
                    variant="secondary"
                    className="action_icon with_bg h40w40 ms-2"
                    
                    onClick={() => {
                        setShowAddContactModal(true);
                    }}
                  >
                    <UserIcon className="HW14" weight="light" />{" "}
                  </Button>
                </>
              ) : activeTopicKey === "providers" ||
                activeTopicKey === "my_task" ? (
                <Button
                  variant="secondary"
                  className="action_icon with_bg h40w40 ms-2"
                   
                   
                   
                   
                  onClick={() => {
                    if (activeTopicKey === "providers") {
                      setShowAddCategoryModal(true);
                    } else if (activeTopicKey === "my_task") {
                      setshowAddCategoryForMe(true);
                    }
                  }}
                >
                  <PlusIcon className="HW14" />{" "}
                </Button>
              ) : (
                <></>
              )}
              {
                activeTopicKey === "equipments"? (
                <Button
                  variant="secondary"
                  className="action_icon with_bg h40w40 ms-2"
                   
                   
                   
                  onClick={() => {
                    setEquipmentDetail(undefined)
                    setEquipmentModal(true)
                  }}
                >
                  <PlusIcon className="HW14" />{" "}
                </Button>
              ) : (
                <></>
              )
              }
            </div>
          </div>
           
          <div
            className={`topic-list-wrapper flex-grow-1 ${activeTopicKey == "my_people" || activeTopicKey == "task_topic" ? "row" : "row"
              }`}
          >
            <div
              className={`col-xl-3 py-3 ${activeTopicKey == "my_people" || activeTopicKey == "task_topic" ? "" : "d-none"
                }`}
            >
              <div className="left-content-part p-0 res-width-100 d-flex flex-column">
                <div className="comman-content-scroll-wrapper overflow-hiiden-web">
                  <ul className="comman-content-scroll comman-verticle-tab modal-tabs-fixed">
                    <li className="comman-list p-0">
                      <a href="#/"
                        className={`w-100 position-relative tab_link company-profile ${selectedTab === "contacts" ? "active" : ""
                          }`}
                        onClick={() => {
                          let newPropleArr = []
                          if (localStorage.getItem('selectedVirtualSpace')) {
                            newPropleArr = topicCategory.filter((category) => category.filter_key < 0)
                          } else {
                            newPropleArr = topicCategory.filter((category) => category.filter_key > 0)
                          }
                          setMyPeopleCategory(newPropleArr);
                          setSearchString("");
                          setSelectedTab("contacts");
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="comman-list-left w100minus20">
                            <div className="d-flex align-items-center">
                              <div className="c-list-icon">
                                <div className="h25w25  d-flex align-items-center p-1">
                                  <UserIcon
                                    className="HW14"
                                  />
                                </div>
                              </div>
                              <div className="c-list-detail c-font f-14 w100minus20 text-truncate color-white-80">
                                {activeTopicKey == "task_topic" ? _l("l_my_people") : _l("l_my_contacts")}
                              </div>
                            </div>
                          </div>
                          <div className="comman-list-right">
                            <CaretRightIcon
                              className="HW14 color-green"
                            />
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="comman-list p-0">
                      <a href="#/"
                        className={`w-100 position-relative tab_link company-profile  ${selectedTab === "teams" ? "active" : ""
                          }`}
                        onClick={() => {
                          let newTeamArr = []
                          if (localStorage.getItem('selectedVirtualSpace')) {
                            newTeamArr = topicCategory.filter((category) => category.filter_key > 0)
                          } else {
                            newTeamArr = topicCategory.filter((category) => category.filter_key < 0)
                          }
                          setMyPeopleCategory(newTeamArr);
                          setSearchString("");
                          setSelectedTab("teams");
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="comman-list-left w100minus20">
                            <div className="d-flex align-items-center">
                              <div className="c-list-icon">
                                <div className="h25w25  d-flex align-items-center p-1">
                                  <UsersThreeIcon
                                    className="HW14"
                                  />
                                </div>
                              </div>
                              <div className="c-list-detail c-font f-14 w100minus20 text-truncate color-white-80">
                                {_l("l_my_teams")}
                              </div>
                            </div>
                          </div>
                          <div className="comman-list-right">
                          <CaretRightIcon
                              className="HW14 color-green"
                            />
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col flex-grow-1 set-topic-list  overflow-hiiden-auto flex-grow-1 d-flex flex-column">
              <div className="row">
                {topicCategory &&
                  topicCategory.length &&
                  myPeopleCategory.length ? (
                  topicCategory.map((category, index) => {
                    if (
                      category.filter_key >= 0 &&
                      (activeTopicKey == "my_people") &&
                      selectedTab == "teams"
                    ) {
                      return;
                    } else if (
                      category.filter_key < 0 &&
                      (activeTopicKey == "my_people") &&
                      selectedTab == "contacts"
                    ) {
                      return;
                    } else if (category.filter_key < 0 &&
                      (activeTopicKey == "task_topic") &&
                      selectedTab == "teams") {
                      return;
                    } else if ( category.filter_key >= 0 &&
                      (activeTopicKey == "task_topic") &&
                      selectedTab == "contacts") {
                      return;
                    }
                    const isChecked = category.is_active == 1 ? true : false;
                    var hideRow = false;
                    if((activeTopicKey == "my_people" || activeTopicKey == "task_topic") && (
                      (userType == "contact" && category.filter_key == localStorage.getItem("contact_id")) ||
                      (userType == "operator" && category.filter_key == localStorage.getItem("staff_id")) ||
                      (userType == "staff" && Math.abs(category.filter_key) == localStorage.getItem("staff_id"))
                    )){
                      hideRow = true;
                    }
                    return (
                      <div
                        key={index}
                        className={` 
                          ${activeTopicKey == "my_people" || activeTopicKey == "task_topic" ? "col-xl-6" : "col-xl-6"}
                          ${hideRow ? "d-none" : ""}
                        `}
                      >
                        <div className={`topic-main-box hr_sepretr left-25`}>
                          <div className="">
                            <div className=" py-3  d-flex align-items-center justify-content-between">
                              {(userType == "staff" && activeTopicKey == "my_staff") || (userType == "contact" && activeTopicKey == "my_contact") || (userType == "operator" && activeTopicKey == "my_operator") || activeTopicKey == "task_topic"? (
                                <div className="topic-left-title text-truncate d-flex align-item-center flex-grow-1 w100minus120">
                                  <div className="comman-image-box h25w25 rounded-circle d-flex">
                                    {category.main_sub_key == "team" || (category.filter_key > 0 && activeTopicKey == "task_topic") ? (
                                      <UsersThreeIcon
                                        className="HW16"
                                      />
                                    ) : (
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('${category.image}')`,
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                  <div class="w100minus40 text-truncate ps-2">
                                  {_l(category.title)}
                                  </div>
                                </div>
                              ) : (
                                <div className="topic-left-title text-truncate d-flex align-item-center flex-grow-1 w100minus120">
                                  <div
                                    className={`h25w25 d-flex ${(activeTopicKey == "request_people" &&
                                      category.filter_key > 0) ||
                                      category.filter_key
                                        .split("")
                                        .some((c) => c == "_")
                                        && category.filter_key != "myrai_support"
                                      ? "comman-image-box rounded-circle"
                                      : ""
                                      }`}
                                  >
                                    {activeTopicKey == "my_task" || category.filter_key == "myrai_support" ? (
                                      <HashIcon
                                        className="HW20"
                                      />
                                    ) : (activeTopicKey == "request_people" &&
                                      category.filter_key > 0) ||
                                      activeTopicKey == "my_staff" || ((activeTopicKey == "task_topic" || activeTopicKey == "requested_task") && category.image) || activeTopicKey == "equipments" ? (
                                      <>
                                        <div
                                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                          style={{
                                            backgroundImage: `url('${category.image || category.equipment_url}')`,
                                          }}
                                        ></div>
                                      </>
                                    ) : category.filter_key
                                      .split("")
                                      .some((c) => c == "_") ? (
                                      <UsersThreeIcon
                                        className="HW18"
                                      />
                                    ) : activeTopicKey == "task_topic" ? (
                                      <UsersThreeIcon
                                        className="HW18"
                                      />
                                    ) : (
                                      !isNaN(parseInt(category.filter_key)) && activeTopicKey == 'user_journey' ?
                                      <UsersThreeIcon
                                        className="HW18"
                                      />
                                        :
                                        <HashIcon
                                          className="HW18"
                                        />
                                    )}
                                  </div>
                                  <div class="w100minus40 text-truncate ps-2">
                                    {_l(category.title)}
                                  </div>
                                </div>
                              )}
                              <div className="topic-switch d-flex align-items-center comman_action_icon">
                                {activeTopicKey == "my_customs" ? (
                                  <div
                                    className={`right-accordian-header transparent-bg`}
                                  >
                                    <div className="d-flex align-items-center right-pill-addtask">
                                      <div className="d-flex align-items-center text-truncate">
                                        <div className="c-list-icon  mr-30">
                                          <div
                                            className="h25w25 comman-round-box  d-flex align-items-center"
                                            onClick={() => {
                                              editCustomTopic(
                                                category.filter_key,
                                                category.email,
                                                category.title
                                              );
                                            }}
                                          >
                                            <PencilIcon
                                              className="HW16"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {activeTopicKey == "my_customs" ? (
                                  <a href="#/"
                                    className="with_bg action_icon d-flex mtminus5 radius_3"
                                    onClick={() => {
                                      deleteCustomTopic(category.filter_key);
                                    }}
                                  >
                                    <TrashIcon
                                      className="HW16"
                                    />
                                  </a>
                                ) : (
                                  <></>
                                )}
                                {activeTopicKey == "my_task" &&
                                  category.filter_key > 0 ? (
                                    <>
                                    <a href="#/" className="with_bg action_icon d-flex mtminus5 radius_3 me-1">
                                    <PencilIcon
                                      className="HW16"
                                      onClick={() => {
                                        setshowAddCategoryForMe(true);
                                        setIsEditTopic({ ...category, id: category.filter_key });
                                      }}
                                    />
                                  </a>
                                  <a href="#/" className="with_bg action_icon d-flex mtminus5 radius_3">
                                    <TrashIcon
                                      className="HW16"
                                      onClick={() => {
                                        dispatch(
                                          showConfirmation(
                                            _l("l_are_you_sure"),
                                            _l("l_you_want_to_delete_this_topic_with_assigh_all_task_this_tag_also_remove"),
                                            _l("l_yes"),
                                            _l("l_no"),
                                            undefined,
                                            () => { removeTaskTopic(category.filter_key); }
                                          )
                                        );
                                      }}
                                    />
                                  </a>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {activeTopicKey == "equipments" ? (
                                  <>
                                    <a href="#/" className="with_bg action_icon d-flex mtminus5 radius_3 me-2">
                                      <PencilIcon
                                        className="HW16"
                                        onClick={() => {
                                          setEquipmentDetail(category)
                                          setEquipmentModal(true)
                                          dispatch(toastCloseAction());
                                        }}
                                      />
                                    </a>
                                    <a href="#/" className="with_bg action_icon d-flex mtminus5 radius_3">
                                      <TrashIcon
                                        className="HW16"
                                        onClick={() => deleteEquipment(category.filter_key,true)}
                                      />
                                    </a>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {activeTopicKey == "providers" && category.filter_key != "myrai_support"?  (
                                  <>
                                    <a href="#/" className="with_bg action_icon d-flex mtminus5 radius_3 me-2"
                                     
                                       
                                       
                                      onClick={(event) => {
                                        accessPremiumFeature(projectId,() => {
                                          setModal1show(true)
                                          setSelectOperators(
                                            category);
                                        })
                                      }}
                                    >
                                      <UserGearCircleIcon
                                        size={16}
                                        weight="light"
                                        className="c-icons"
                                        
                                      />
                                    </a>
                                    <a href="#/" className="with_bg action_icon d-flex mtminus5 radius_3"
                                      
                                       
                                      onClick={() => {
                                        accessPremiumFeature(projectId, () => {
                                          setShowAddProvider(true)
                                          setSelectedCategory(parseInt(category.filter_key))
                                        })
                                      }}
                                    >
                                      <PlusIcon
                                        size={16}
                                        weight="light"
                                        className="c-icons"                                    
                                      />
                                    </a>
                                  </>
                                ) : (
                                  <></>
                                )}
                                 
                                <div className="form-check form-check-inline m-0 ps-2">
                                  <Form.Check
                                    className=" with-no-label-space"
                                    type="switch"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    disabled={
                                     (activeTopicKey == "my_task" || activeTopicKey == "providers" || activeTopicKey == "proposals") &&
                                        [
                                          "created_by_me",
                                          "assigned_to_me",
                                          "pinned",
                                          "myrai_support",
                                          "overdue"
                                        ].indexOf(category.filter_key) > -1
                                        ? true
                                        : false
                                    }
                                    checked={category.filter_key == "myrai_support" ? true : isChecked}
                                    onChange={() => {
                                      categoryToggle(
                                        category.filter_key,
                                        isChecked ? 0 : 1,
                                        category.main_sub_key
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label p-0"
                                    htmlFor="flexSwitchCheckChecked"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <CommanPlaceholder imgType="no-user" placeholderText = {`${_l("l_no")} ${activeTopicKey == "my_people" || activeTopicKey == "task_topic" ? selectedTab == "teams" ? "Teams" : "Contacts"  : activeTopicKey}`} />
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end d-none">
        </Modal.Footer>
        <div className="modal-footer border-0 justify-content-end d-none">
          <button
            type="button"
            className="btn btn-secondary "
            data-bs-toggle="modal"
            data-bs-target="#addNewCategoryModal"
          >
            {_l("l_add_new_category")}
          </button>
          <div>
            <button
              type="button"
              id="closeButton"
              className="btn btn-secondary me-2"
              data-bs-dismiss="modal"
            >
              {_l("l_close")}
            </button>
          </div>
        </div>
        <AddNewCategory
          categories={topicCategory.map((category) => {
            return { value: category.filter_key, label: category.title };
          })}
          handler={(filter_key, custom_category_title) => { }}
        />
      </Modal>
      {showAddCategoryModal ? (
        <CategoryProviderList
          handleClose={() => {
            setShowAddCategoryModal(false);
          }}
          handleShow={() => {
            setShowAddCategoryModal(true);
          }}
          projectId={projectId}
          show={showAddCategoryModal}
          handleAddCategory={handleAddCategory}
        />
      ) : (
        <></>
      )}

      {showAddTeamModal ? (
        <CreateTeam
          dataSource={[]}
          setDataSource={() => { }}
          handleClose={() => {
            setShowAddTeamModal(false);
          }}
          setTeamsData={() => { }}
          show={showAddTeamModal}
          team={""}
          teamsData={[]}
          handleAddedTeam={handleAddedTeam}
        />
      ) : (
        <></>
      )}

      {showAddContactModal ? (
        <AddEmployee
          handleClose={() => {
            setShowAddContactModal(false);
          }}
          projectId={projectId}
          selectedRecord={""}
          show={showAddContactModal}
          updateDataSource={handleAddedContact}
        />
      ) : (
        <></>
      )}
      {showAddOperatorModal ? (
        <AddOperator
          handleClose={() => {
            setShowAddOperatorModal(false);
          }}
          projectId={projectId}
          selectedRecord={""}
          show={showAddOperatorModal}
          updateDataSource={handleAddedOperator}
        />
      ) : (
        <></>
      )}
      {showAddCategoryForMe ? (
        <AddNewCategory
          projectId={projectId}
          show={showAddCategoryForMe}
          handleClose={() => {
            setshowAddCategoryForMe(false);
            setIsEditTopic({});
          }}
          activeTopicKey={activeTopicKey}
          handler={(topic) => {
            handleAddTopic(topic);
          }}
          editTopicDetails={isEditTopic}
        />
      ) : (
        <></>
      )}
      {
        equipmentModal ? 
        <AddEditEquipment
            handleClose={() => {
              setEquipmentModal(false);
            }}
            show={equipmentModal}
            data={equipmentDetail}
            deleteEquipment={deleteEquipment}
          />
        :
        <></>
      }
      {
        showAddProvider ? 
            <AddProvider
              show={showAddProvider}
              handleClose={() => {
                setShowAddProvider(false);
              }}
              categoryList={categoryListing}
              selectedCategory={selectedCategory}
            />
           : (
          <></>
        )
      }
      {Modal1show ? (
          <>
            <ProviderDropdownWithprogressBar 
            show={Modal1show}
            modal1handleClose={() => {
              setModal1show(false)
            }}
              staff={selectOperators} />
          </>
        ) : (
          <></>
        )}
    </>
  );
};

export default AddEditCategory;
