import React from 'react';

function CustomGoogleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.04545 26.2533H6.8182V14.6624L3.69293 9.20508L0 9.54873V24.2078C0 25.3379 0.915352 26.2533 2.04545 26.2533Z" fill="#0085F7"/>
                <path d="M23.1816 26.2533H27.9544C29.0845 26.2533 29.9998 25.3379 29.9998 24.2078V9.54873L26.3122 9.20508L23.1817 14.6624V26.2533H23.1816Z" fill="#00A94B"/>
                <path d="M23.1824 5.799L20.3789 11.1488L23.1824 14.6626L30.0006 9.54899V6.82175C30.0006 4.2939 27.1148 2.85015 25.0915 4.3672L23.1824 5.799Z" fill="#FFBC00"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.81795 14.6625L4.14648 9.01932L6.81795 5.79883L14.9997 11.9352L23.1815 5.79883V14.6625L14.9997 20.7988L6.81795 14.6625Z" fill="#FF4131"/>
                <path d="M0 6.82175V9.54899L6.8182 14.6626V5.799L4.9091 4.3672C2.8858 2.85015 0 4.2939 0 6.82175Z" fill="#E51C19"/>
            </svg>
		</React.Fragment>
	)
}

export default CustomGoogleIcon
