import React from 'react';
 

function LinkIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

			<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8.03428 4.46254L9.27178 3.22504C9.79572 2.74749 10.4835 2.49019 11.1923 2.5066C11.901 2.52301 12.5761 2.81186 13.0774 3.31315C13.5787 3.81443 13.8676 4.48959 13.884 5.19832C13.9004 5.90705 13.6431 6.59485 13.1655 7.11879L11.3968 8.88129C11.1419 9.13716 10.8389 9.34018 10.5054 9.47871C10.1718 9.61724 9.81421 9.68855 9.45303 9.68855C9.09185 9.68855 8.73423 9.61724 8.40067 9.47871C8.06711 9.34018 7.76419 9.13716 7.50928 8.88129M8.74692 11.5375L7.50942 12.775C6.98547 13.2526 6.29767 13.5099 5.58894 13.4935C4.88021 13.4771 4.20505 13.1882 3.70377 12.6869C3.20249 12.1856 2.91363 11.5105 2.89722 10.8018C2.88082 10.093 3.13812 9.40523 3.61567 8.88128L5.38442 7.11878C5.63933 6.86291 5.94225 6.65989 6.27581 6.52136C6.60936 6.38283 6.96699 6.31152 7.32817 6.31152C7.68935 6.31152 8.04697 6.38283 8.38053 6.52136C8.71408 6.65989 9.017 6.86291 9.27192 7.11878"/>
</svg>

		</React.Fragment>
	)
}

export default LinkIcon