import React from 'react';
 

function NoOffice(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M95.6952 48.5962C95.7026 54.8418 94.4743 61.0271 92.0811 66.7959C89.6878 72.5647 86.1768 77.8029 81.7504 82.209C77.324 86.6152 72.0696 90.102 66.2899 92.4687C60.5101 94.8355 54.3193 96.0354 48.0738 95.9993C41.8284 95.9631 35.6519 94.6918 29.8999 92.2584C24.1479 89.825 18.9342 86.2775 14.559 81.8205C10.1839 77.3635 6.73371 72.085 4.40732 66.2889C2.08092 60.4928 0.924207 54.2937 1.00385 48.0486C1.18248 21.6974 22.1982 0.949611 48.6621 1.00009C74.9706 1.04669 95.8505 22.179 95.6952 48.5962Z" strokeMiterlimit="10"/>
				<path d="M37.5137 28.5901C37.5137 28.3388 37.6136 28.0978 37.7913 27.9201C37.9689 27.7424 38.2099 27.6426 38.4612 27.6426H58.2382C58.4895 27.6426 58.7305 27.7424 58.9082 27.9201C59.0859 28.0978 59.1857 28.3388 59.1857 28.5901V31.448H37.5137V28.5901Z" strokeMiterlimit="10"/>
				<path d="M15.6172 45.785V32.1939C15.6182 31.9958 15.6976 31.8061 15.8381 31.6664C15.9785 31.5267 16.1685 31.4482 16.3666 31.4482H80.3342C80.5323 31.4482 80.7224 31.5267 80.8628 31.6664C81.0033 31.8061 81.0827 31.9958 81.0837 32.1939V45.7539" strokeMiterlimit="10"/>
				<path d="M80.3342 69.1455H16.3666C16.1678 69.1455 15.9772 69.0666 15.8367 68.9261C15.6961 68.7855 15.6172 68.5949 15.6172 68.3961V45.8348C22.9447 48.2501 32.5828 49.7607 43.0829 50.1413C43.0829 50.1724 43.0829 50.1995 43.0829 50.2306V51.38C43.0829 52.4099 43.492 53.3976 44.2203 54.1258C44.9485 54.854 45.9362 55.2632 46.9661 55.2632H49.7619C50.7918 55.2632 51.7795 54.854 52.5078 54.1258C53.236 53.3976 53.6451 52.4099 53.6451 51.38V50.215C53.6451 50.184 53.6451 50.1529 53.6451 50.1257C64.153 49.7374 73.7872 48.2268 81.1108 45.8037V68.3806C81.113 68.4826 81.0943 68.5841 81.0559 68.6786C81.0175 68.7732 80.9602 68.8589 80.8875 68.9305C80.8147 69.0021 80.7282 69.0581 80.633 69.0951C80.5379 69.1321 80.4362 69.1493 80.3342 69.1455V69.1455Z" strokeMiterlimit="10"/>
				<path d="M17.3047 12.3623L79.3928 84.4302" strokeMiterlimit="10"/>
			</svg>

		</React.Fragment>
	)
}

export default NoOffice