import React from 'react'

const ReadUnreadChecks = (props) => {
    const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	const strok1 	= (props.strok1) ? props.strok1 : "";
	const strok2 	= (props.strok2) ? props.strok2 : "";
  return (
    <div><svg width="14" height="14" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth={"3px"}>
    <g clip-path="url(#clip0_3816_92221)">
    <path d="M18.5 10.5L7.5 21.5L2 16" stroke={strok1} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30 10.5L19 21.5L16.075 18.575" stroke={strok2} stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_3816_92221">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg></div>
  )
}

export default ReadUnreadChecks