import React, { useEffect, useState } from "react";
import { _l } from "../hooks/utilities";
import { getTableInfo } from "../hooks/indexed_db_helpers";

const LocalDataSummaryPage = () => {
  const [tableData, setTableData] = useState([]);

  const fetchTableData = async () => {
    try {
      const data = await getTableInfo("myr_ai");
      setTableData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <>
    <div className="bgpatternCommanImg h40w40 position-sticky top-0 w-100 z-1"></div>
    <div className="h40w40 position-sticky w-100 top40px bgpatternCommanImg z-1">{_l("l_local_data_summary")} {`(${tableData.length})`}</div>
      <div className="comman-data-table employee-table small-data-table  comman-content-scroll-wrapper flex-grow-1">
        <table className="dataTable text-center tablewithborder">
          <thead className="comman-white-box-shadow top80px">
            <tr>
              <th className="text-start" style={{ width: "25%" }}>
                {_l("l_table_name")}
              </th>
              <th className="text-start" style={{ width: "5%" }}>
                {_l("l_total_counts")}
              </th>
            </tr>
          </thead>
          <tbody style={{ verticalAlign: "middle" }}>
            {tableData.map((col, index) => {
              return (
                <>
                  <tr key={index}>
                    <td className="c-font color-white-60 f-13  fw-semibold text-start">
                      {col.tableName}
                    </td>
                    <td className="text-start c-font f-13  color-white text-truncate p-10 ">
                     {col.totalKeys}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LocalDataSummaryPage;
