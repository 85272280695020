import React from 'react'
const CalendarDayboxSkeleton = () => {
  return (
    <>
      <div className="WeekDetailbox bg-white-03 p-15 pb-0 radius_3 SkeletonMain">
        <a href="#/" className="WeekDetailHeader c-font f-12 d-block">
          <div className='flex-grow-1 d-flex ms-2' style={{ height: '18px' }}>
            <div className='radius_3 skeleton-animation' style={{ height: '8px', width: '20%' }}></div>
          </div>
        </a>
        <div className="align-items-cente d-flex justify-content-between p-15 pb-2">
          <div className="d-flex align-items-cente">
            <div className="rounded-5 skeleton-animation h35w35 d-flex me-10px"></div>
            <div className='d-flex flex-grow-1 me-10px mt-15px mx-auto radius_3 skeleton-animation' style={{ height: '8px', width: '130px' }}></div>
          </div>
          <div className="d-flex align-items-cente">
            <div className='d-flex my-auto radius_3 skeleton-animation me-20px p-15 py-0' style={{ height: '6px', width: '200px' }}></div>
            <div className="d-flex align-items-center justify-content-center comman_action_icon">
              <div className='me-10px radius_3 skeleton-animation' style={{ height: '33px', width: '50px' }}></div>
              <div className='radius_3 skeleton-animation' style={{ height: '33px', width: '50px' }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CalendarDayboxSkeleton