import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
 
import AddOperatorStepOne from "./AddOperatorStepOne";
import AddOperatorStepThree from "./AddOperatorStepThree";
import { _l } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import Spinner from "react-bootstrap/Spinner";
import AddOperatorStepTwo from "./AddOperatorStepTwo";

function AddOperator({ show, handleClose, updateDataSource, selectedRecord }) {
    const [selectedTab, setSelectedTab] = useState("step-1");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [countryCode, setCountryCode] = useState('')
    const [imagePreviews, setImagePreviews] = useState({});
    const [idCardPreviews, setIdCardPreviews] = useState({});
    const [kbisPreviews, setKbisPreviews] = useState({});
    const [joinText, setJoinText] = useState('');
    const [source, setSource] = useState('')
    const [allregionData, setAllregionData] = useState([]);
    const [region, setRegion] = useState([]);
    const { defaultTopics } = useSelector((state) => state.customer);
    const [categoryListing, setCategoryListing] = useState([]);
    const [typeJob, setTypeJob] = useState([]);
    const [loder, setLoder] = useState(false);
    const [lastName, setLastName] = useState("");
    const [employeeRole, setEmployeeRole] = useState('');
    const [employeePosition, setEmployeePosition] = useState('');

    const dispatch = useDispatch();
    const userType = localStorage.getItem("user_type");
    const contact_role = localStorage.getItem("contact_role");
    const admin = userType == "staff" ? localStorage.getItem("admin") : contact_role == 1;

    const sourceList = [
        {
            value: "0",
            label: _l('l_source_from_google'),
        },
        {
            value: "1",
            label: _l('l_source_from_facebook'),
        },
        {
            value: "2",
            label: _l('l_source_from_website'),
        },
        {
            value: "3",
            label: _l('l_source_from_newspaper'),
        },
        {
            value: "4",
            label: _l('l_source_from_operator'),
        },

    ];

    const employeeRoleListing = admin ? [
        {
            value: 1,
            label: _l('l_admin'),
        },
        {
            value: 2,
            label: _l('l_manager'),
        },
        {
            value: 3,
            label: _l('l_employee'),
        },
    ]
    :
    [
        {
            value: 2,
            label: _l('l_manager'),
        },
        {
            value: 3,
            label: _l('l_employee'),
        },
    ];
    const countryCodes = [
        {
            value: "+32",
            label: "+32",
        },
        {
            value: "+33",
            label: "+33",
        },
        {
            value: "+49",
            label: "+49",
        },
        {
            value: "+325",
            label: "+325",
        },
        {
            value: "+34",
            label: "+34",
        },
        {
            value: "+41",
            label: "+41",
        },
        {
            value: "+44",
            label: "+44",
        },
    ];
    const previousStep = (id) => {
        let decrement = id - 1
        setSelectedTab("step-" + decrement)
    }
    const nextStep = (id) => {
        let increment = id + 1
        if (id == 1 && stepOneValidate()) {
            setSelectedTab("step-" + increment)
        } else if (id == 2 && stepOneValidate() && stepTwoValidate()) {
            setSelectedTab("step-" + increment)
        }

    }
    useEffect(() => {
        CustomerServices.getRegionList().then((res) => {
            if (res.status) {
                const formattedRegionList = res.data.map((x) => {
                    return {
                        label: x.name,
                        value: x.id
                    };
                })
                setAllregionData(formattedRegionList);
                if (selectedRecord) {
                    let selectedRegions = selectedRecord.region ? selectedRecord.region.split(',') : []
                    let selectedRegionPush = []
                    formattedRegionList.forEach((region) => {
                        if (selectedRegions.includes(region.value)) {
                            selectedRegionPush.push(region)
                        }
                    })
                    setRegion(selectedRegionPush);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (defaultTopics && defaultTopics.length > 0) {
            var main_key = "proposals"
            defaultTopics.map((item) => {
                if (item.main_key == main_key) {
                    const formattedCategoryListing = item.categories.map((item1) => {
                        return {
                            value: parseInt(item1.filter_key),
                            label: item1.title,
                            is_default: item1.is_default
                        };
                    })
                    setCategoryListing(
                        formattedCategoryListing
                    );
                }
            })
        }
    }, [defaultTopics]);

    useEffect(() => {
        if (show) {
            clearData()
        }
    }, [show])

    const handleSave = () => {
        if (stepOneValidate()) {
            let mobileTemp = mobileNumber != '' ? countryCode.value + ' ' + mobileNumber : ''
            setLoder(true);
            CustomerServices.addCompanyEmployee(firstName, lastName, email, password, mobileTemp, employeeRole.value ? employeeRole.value : '', "", "", employeePosition
            ).then((res) => {
                setLoder(false);
                if (res.status == '1') {
                    let newOperator = {
                        id: res.data.operator_id,
                        index: 0,
                        full_name: `${firstName} ${lastName}`,
                        team_name: "",
                        role: employeeRole.label,
                        role_id: employeeRole.value
                    }
                    if (selectedRecord != "") {
                        updateDataSource(newOperator, true);
                    } else {
                        updateDataSource(newOperator);
                    }

                    dispatch(showMessage('sucess', _l('l_success'), res.message ? res.message : selectedRecord != "" ? _l('l_edit') : _l('l_add') + _l('l_successfully')));
                    handleClose()
                } else {
                    dispatch(showMessage('unsucess', _l('l_error'), res.message ? res.message : _l('l_something_went_wrong')));
                }
            });
        }
    }
    const stepOneValidate = () => {
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (firstName == '') {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_first_name')));
            return false;
        } else if (lastName == '') {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_last_name')));
            return false;
        } else if (email == '') {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_email')));
            return false;
        } else if (!pattern.test(email)) {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_valid_email')));
            return false;
        } else if (!selectedRecord && password == '') {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_password')));
            return false;
        } else if (!selectedRecord && confirmPassword == '') {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_confirm_password')));
            return false;
        } else if (confirmPassword != password) {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_password_and_confirm_password_doesnot_match')));
            return false;
        }
        else {
            return true;
        }

    }

    const stepTwoValidate = () => {
        return true;
    }


    const clearData = () => {
        setFirstName('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
        setCountryCode('')
        setImagePreviews('')
        setIdCardPreviews('')
        setKbisPreviews('')
        setJoinText('')
        setSource('')
        setAllregionData('')
        setRegion([])
    }

    useEffect(() => {

        if (selectedRecord && selectedRecord.staffid) {
            setFirstName(selectedRecord.first_name);
            setLastName(selectedRecord.last_name);
            setEmail(selectedRecord.email);
            let empolyees = employeeRoleListing.filter((employee) => employee.value == selectedRecord.role)
            if (empolyees.length) {
                setEmployeeRole(empolyees[0]);
            } else {
                setEmployeeRole('');
            }
            if (selectedRecord.phonenumber) {
                let extractedCountryCode = countryCodes.filter((x) =>
                    selectedRecord.phonenumber.split(' ')[0] == x.value
                );

                if (extractedCountryCode.length) {
                    setCountryCode(extractedCountryCode[0]);
                } else {
                    setCountryCode({ value: "+33", label: '+33' });
                }
                const phoneNumber = selectedRecord.phonenumber.split(' ')[1] ? selectedRecord.phonenumber.split(' ')[1] : selectedRecord.phonenumber;
                setMobileNumber(phoneNumber.trim());
            }
        }
    }, [JSON.stringify(selectedRecord)]);

    return (
        <Modal
            show={show}
            onHide={() => {
                handleClose();
                clearData()
            }}
            className="custom-modal-style add-topic-search overflow-hidden with-min-height"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            size="lg"
            centered
        >
            <Modal.Header
                className="modal-header border-0"
                closeButton
                onClick={() => {
                }}
            >

                <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="modal-title title-fonts fw-semibold d-flex align-items-center">
                        {selectedRecord != "" ? _l('l_edit') : _l('l_add')} {_l('l_user')}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="custom-modal-section-sticky ">
                    <div className="comman-tab-wrapper ">
                        <div className="d-flex align-items-center border-bottom">
                            <div className={`tab-wrapper d-none ${selectedTab == 'step-1' ? "active" : ""} d-flex align-items-center }`} onClick={() => {
                                setSelectedTab("step-1");
                            }} >
                                <a href="#/" className="tab-name">{_l('l_detail')}</a>
                            </div>
                            <div className={`tab-wrapper ${selectedTab == 'step-3' ? "active" : ""} d-flex align-items-center d-none }`} onClick={() => {
                                if (stepOneValidate() && stepTwoValidate()) {
                                    setSelectedTab("step-3");
                                }

                            }} >
                                <a href="#/" className="tab-name">{_l('l_documents')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                 

                <div className="form-wrapper-main pt-0">
                    <div className="row ">
                        {
                            selectedTab == 'step-1' ? (
                                <React.Fragment>
                                    <AddOperatorStepOne firstName={firstName} setFirstName={setFirstName} setLastName={setLastName} lastName={lastName} email={email} setEmail={setEmail} password={password} setPassword={setPassword} countryCodes={countryCodes} countryCode={countryCode} setCountryCode={setCountryCode} mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} allregionData={allregionData} region={region} setRegion={setRegion} categoryListing={categoryListing} typeJob={typeJob} setTypeJob={setTypeJob} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} employeeRole={employeeRole} setEmployeeRole={setEmployeeRole} employeeRoleListing={employeeRoleListing} employeePosition={employeePosition} setEmployeePosition={setEmployeePosition}/>
                                    <AddOperatorStepTwo imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} joinText={joinText} setJoinText={setJoinText} sourceList={sourceList} source={source} setSource={setSource} />
                                </React.Fragment>
                            ) : selectedTab == 'step-3' ? (
                                <AddOperatorStepThree idCardPreviews={idCardPreviews} setIdCardPreviews={setIdCardPreviews} kbisPreviews={kbisPreviews} setKbisPreviews={setKbisPreviews} imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} />
                            ) : <></>
                        }

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-end">
                <Button variant="secondary" className={`${selectedTab == 'step-1' ? 'd-none' : ''}`} onClick={() => {
                    if (selectedTab == 'step-3') {
                        let tempStep = 2;
                        previousStep(tempStep)
                    }
                }}>
                    {_l('l_previous')}
                </Button>
                <Button variant="primary" size="sm" onClick={() => {
                    handleSave()
                }}>
                    {loder
                        ? _l('l_please_wait')
                        : selectedRecord != ""
                            ? _l('l_edit_user')
                            : _l('l_add_user')

                    }
                    {loder ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 `}
                        aria-hidden="true"
                    />
                        : <></>
                    }
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default AddOperator