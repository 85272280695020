import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import DocumentsServices from "../../services/documents-services";
import CustomerServices from "../../services/customer-services";
import LeftSideComponent from "./LeftSideComponent";
import {
  _l,
  createGuestProfile,
  loadDataToIDB,
  setPageMetaDataDescription,
  showError,
} from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import {
  GET_DEFAULT_TOPICS,
  GET_FLOOR_DOCUMENT,
  LOGIN_SUCCESS,
} from "../../actions/action-type";
import {
  GET_ESTIMATE_DETAILS,
  GET_INVOICE_DETAILS,
  GET_SUBSCRIPTION_DETAILS,
} from "../../actions/documents-actions/documents-action-types";
import { setDocumentType } from "../../actions/documents-actions/documents-actions";
import { SetSelectedTask, getCustomerSpaces, getTeams, setLeaseDocumentsList, setLeftPanelTab } from "../../actions/customer";
import DocumentModal from "../Modals/DocumentModal";
import TopicSettingModal from "../Modals/TopicSettingModal";
import BenchmarkServices from "../../services/benchmark-services";
import { fetchAuthToken } from "../../actions/chat-auth";
import { connectToSocket, fetchUnreadMsgCount } from "../../actions/chat";
import leaseServices from "../../services/lease-services";
import jquery from "jquery";
import { login } from "../../actions/auth";
import { showMessage } from "../../actions/messages";
const Main = () => {
  const { id, page } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [dataState, setDataState] = useState();
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [docModalToggle, setDocModalToggle] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [modalDocType, setModalDocType] = useState("");
  const [dashBoardDocRes, setDashBoardDocRes] = useState({});
  const [isExternal, setIsExternal] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [Documents, setDocuments] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  let userHashID = searchParams.get("hash");
  const getBenchmarkDetailAndLogin = async() => {
    try {
      const response = await BenchmarkServices.getBechmarkDetailsAndLogin(id);
      const client_id = response.data.client_id;
      const contact_id = response.data.contact_id;
      const staff_id = response.data.staff_id;
      localStorage.setItem("client_id", client_id);
      localStorage.setItem("contact_id", contact_id);
      localStorage.setItem("staff_id", staff_id);
      await dispatch(getCustomerSpaces(client_id, contact_id));
      if(response.status)
      {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { data:response },
        });
        setTimeout(() => {
          // Get chat auth token
          const accessToken = localStorage.getItem("accessToken");
          if (accessToken != "" && accessToken != null) {
            const email = localStorage.getItem("email");
            const contact_id = localStorage.getItem("contact_id");
            dispatch(fetchAuthToken(email, contact_id, "client"));
            dispatch(getTeams());
          }
          // Connet to socket and fetch chat unread message count
          setTimeout(() => {
            const chatAccessToken = localStorage.getItem("chatAccessToken");
            if (chatAccessToken != "" && chatAccessToken != null) {
              dispatch(connectToSocket());
              var chatUserId = localStorage.getItem("chatUserId");
              var projectId = localStorage.getItem("selectedOffice");
              dispatch(fetchUnreadMsgCount(chatUserId, projectId));
            }
          }, 1000);
        }, 1500);
        navigate(
          `/connect-provider?space=${response.extra.benchmark_project_id}&provider-type=benchmark&id=${response.extra.external_link_benchmark_id}`
        );
      }
      else {
        showError(response.message);
        window.location.href = process.env.REACT_APP_MYR_WEB_URL;
      }
    }
    catch(e)
    {
      showError("l_something_went_wrong");
      window.location.href = process.env.REACT_APP_MYR_WEB_URL;
    }
  };
  useEffect(() => {
    if(page === "benchmark")
    {
      getBenchmarkDetailAndLogin();
    }
    else if (!localStorage.getItem("accessToken")) {
      createGuestDetails();
    } else if (localStorage.getItem("accessToken") && data && page) {
      setIsExternal(false);
      switch (page) {
        case "task":
        case "request-task":
          localStorage.setItem("TaskHashLink", id);
          localStorage.setItem("TaskTypeLink", page);
          navigate(`/`);
          localStorage.setItem("currentLeftPanelTab", "my-request");
          dispatch(setLeftPanelTab("my-request"));
          break;
        case "estimate":
          navigate(`/documents`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_ESTIMATE_DETAILS,
            payload: data.data,
          });
        case "subscription":
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_SUBSCRIPTION_DETAILS,
            payload: data.data,
          });
          break;
        case "invoice":
          navigate(`/documents`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_INVOICE_DETAILS,
            payload: data.data,
          });
        case "spaceDocument":
          navigate(`/documents`);
          dispatch(setDocumentType(page));
          dispatch({
            type: GET_FLOOR_DOCUMENT,
            payload: data.data,
          });
          break;
        case "dashboardDocuments":
          localStorage.setItem("searchParamUserId", id)
          if (id === localStorage.getItem("user_hash_id")) {
            navigate(`/dashboard`)
          }
          break;
        case "benchmark":
          getBenchmarkDetailAndLogin();
          break;
        default:
          break;
      }
    }
  }, [id, page, data]);
  useEffect(() => {
    if (!userHashID) {
      fetchDocumentDetails();
    }
  }, [id, page]);
  useEffect(() => {
    if (dataState && !localStorage.getItem("accessToken")) {
      CustomerServices.getDefaultTopic(
        dataState.project_id,
        "",
        dataState.user_client_id,
        dataState.user_id,
        dataState.user_type,
        1
      ).then((data) => {
        if (data.status == 1) {
          dispatch({
            type: GET_DEFAULT_TOPICS,
            payload: data.data.topics,
          });
        }
      });
    }
  }, [JSON.stringify(dataState)]);
  const fetchDocumentDetails = async () => {
    try {
      let response;
      switch (page) {
        case "subscription":
          response = await DocumentsServices.getSubscriptionDetail(0, 0, id);
          break;
        case "invoice":
          response = await DocumentsServices.getInvoiceDetail(0, 0, id);
          break;
        case "estimate":
          response = await DocumentsServices.getEstimateDetail(0, 0, id);
          break;
        case "task":
          response = await CustomerServices.getTaskData(0, 0, null, id);
          if (userHashID) {
            localStorage.setItem("externalTaskData", JSON.stringify(response.data));
          }
          dispatch(SetSelectedTask(response.data));
          break;
        case "request-task":
          response = await CustomerServices.getRequestedTaskData(0, 0, id);
          dispatch(SetSelectedTask(response.data));
          break;
        case "credit_note":
          response = await DocumentsServices.getCreditNoteDetail(0, 0, id);
          break;
        case "spaceDocument":
          response = await CustomerServices.getSpaceDocument(id, 1);
          break;
        case "dashboardDocuments":
          response = {
            "status": 1,
            "message": "l_data_found",
            "data": {
              "subscription": [],
              "space": [],
              "estimate": [],
              "invoice": [],
              "credit_notes": [],
              "topic_document": [],
              "lease_document": []
            },
            "extra": []
          }
          break;
        case "leaseDocument" :
          jquery("body").addClass("pb-0");
          response = await leaseServices.getProjectLease(0 , 0, id, 1);
          break;
        default:
          break;
      }
      if (response && response.status == 1 && response.data) {
        if (page == "leaseDocument") {
          let details = response.data.detail
          let documents = response.data.documents && response.data.documents.length > 0 ? response.data.documents : []
          setDataState({
            leaseStartDate : new Date(details.start_date),
            leaseEndDate : new Date(details.end_date),
            noticeDates : details.notice_date_detail,
            isRentingorOwning : response.data.lease_type == 1 ? "renting" : "owning",
            amount : details.amount,
            isMonthlyOrAnnualli : details.payment_type == 1 ? "monthly" : "annually",
            termandCondition : details.termination_condition,
            status : response.data.status,
            sub_lease_count : response.data.sub_lease_count,
            lease_type : response.data.lease_type,
            lease_schedule_id : response.data.detail.lease_schedule_id,
            id : response.data.detail.lease_schedule_id,
            hash : response.data.hash
          })
          setData({
            leaseStartDate : new Date(details.start_date),
            leaseEndDate : new Date(details.end_date),
            noticeDates : details.notice_date_detail,
            isRentingorOwning : response.data.lease_type == 1 ? "renting" : "owning",
            amount : details.amount,
            isMonthlyOrAnnualli : details.payment_type == 1 ? "monthly" : "annually",
            termandCondition : details.termination_condition,
            status : response.data.status,
            sub_lease_count : response.data.sub_lease_count,
            lease_type : response.data.lease_type,
            lease_schedule_id : response.data.detail.lease_schedule_id,
            id : response.data.detail.lease_schedule_id,
            hash : response.data.hash
          });           
          dispatch(setLeaseDocumentsList(documents))
          let docs = documents.map((doc, i)=>{
            if (doc && doc.sub_document && doc.sub_document.length > 0 ) {
              return {...doc , image_url : doc.sub_document[doc.sub_document.length - 1].image_url, previous_document_id : doc.sub_document[doc.sub_document.length - 1].previous_document_id }
            }else{
              return doc
            }
          })
          setDocuments(docs);
        }else{
          setDataState(response.data);
          setData(response);
        }
        setPageMetaDataDescription(
          `${response.data.id} - #${response.data.name}`
        );
        setShowOffCanvas(true);
        setContacts(response.data.contacts ? response.data.contacts : []);
        if (page === "dashboardDocuments") {
          setDashBoardDocRes(response)
        }
      } else if (response && response.status === 0 && response.data.status === 2) {
        setShowAlertModal(true)
      } else {
        showError(_l(response.message))
      }
    } catch (e) {
      console.error(e);
    }
  };
  const createGuestDetails = async (guestId) => {
    try {
      const guestProfile = await createGuestProfile();
      localStorage.setItem("full_name", guestProfile.name);
      localStorage.setItem("contact_image", guestProfile.avtar_url);
      localStorage.setItem("guest_id", guestProfile.id);
      if (id) {
        localStorage.setItem("searchParamUserId", id)        
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const docToggle = async (documentData) => {
    try {
      let response;
      switch (documentData.type) {
        case "subscription":
          response = await DocumentsServices.getSubscriptionDetail(0, 0, documentData.hash);
          break;
        case "invoice":
          response = await DocumentsServices.getInvoiceDetail(0, 0, documentData.hash, documentData.is_app_invoice);
          break;
        case "estimate":
          response = await DocumentsServices.getEstimateDetail(0, 0, documentData.hash);
          break;
        case "credit_note":
        response = await DocumentsServices.getCreditNoteDetail(0, 0, documentData.hash, documentData.is_app_credit_note);
        break;
        case "spaceDocument":
          response = await CustomerServices.getSpaceDocument(documentData.hash, 1);
          break;
        case "dashboardDocuments":
          response = dashBoardDocRes
          break;
        default:
          break;
      }
      if (response && response.status == 1 && response.data) {     
        setModalDocType(documentData.type)
        setDataState(response.data);
        setPageMetaDataDescription(
          `${response.data.id} - #${response.data.name}`
        );
        if (documentData.type != "dashboardDocuments") {
          setDocModalToggle(true);
        }
        setContacts(response.data.contacts ? response.data.contacts : []);
      }
    } catch (e) {
      console.error(e);
    }
  }
  const handleLogin = (userHashID) => {
      localStorage.removeItem("guest_id");
      dispatch(
        login("","",userHashID)
      ).then(async(res) => {
        if(res && res.status == 0) {
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        } else {
          const staff_id = localStorage.getItem("staff_id");
          await loadDataToIDB(staff_id);
          if (!localStorage.getItem("accessToken") || (res && res.status && res.status != 1)) {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l(res.message))
            );
          } else {
            localStorage.setItem("is_auto_login", true);
            localStorage.setItem("externalTaskHash", id);
            fetchDocumentDetails();
            sessionStorage.removeItem("schedule_id");
            sessionStorage.removeItem("project_id");
          }
        }
      });
  };
  useEffect(() => {
    if (userHashID) {
      handleLogin(userHashID)
    }
  }, [userHashID])
  return (
    <div className={` ${page == 'leaseDocument' ? 'h-100' : '' }`}>
        <>
        {userHashID ? (<>
        </>) 
        :showOffCanvas ? (
          <React.Fragment>
            {!isExternal && page === "subscription" ? (
              <>
                <DocumentModal
                  documentData={dataState}
                  handleClose={() => {
                    setDataState({});
                    navigate("/")
                  }}
                  show={dataState.id > 0}
                  type={page}
                />
              </>
            ) : page == "dashboardDocuments" ? (
              <>
                <LeftSideComponent
                  data={dataState}
                  type={page}
                  docModalIsOpen={!docModalToggle}
                />
                <TopicSettingModal 
                  data={{main_key:"documents", id: 1}} 
                  handleClose={() => { 
                    navigate("/") 
                    localStorage.removeItem("searchParamUserId")
                  }}
                  docModalViewToggle={(documentData) => { docToggle(documentData) }}
                  show={!docModalToggle} 
                  title={"documents"}
                  hideFilterArea={true} 
                  hideSearchArea={false}
                  guestUserModal={true}
                />
                { docModalToggle ? 
                  <CommanOffCanvas
                      data={dataState}
                      setData={setData}
                      docType={modalDocType}
                      handleClose={() => {
                        setDocModalToggle(false);
                        docToggle({    
                          type: "dashboardDocuments"
                        })
                      }}
                      pinHandler={() => {}}
                      setSelectedId={() => {}}
                      show={docModalToggle}
                      isExternal={true}
                      contacts={contacts}
                      backdrop={false}
                      isCommentsection={true}
                    />
                  : <></>
                }
              </>
            ) : (
              <>
                <LeftSideComponent
                  data={dataState}
                  type={page}
                />
                <CommanOffCanvas
                  data={dataState}
                  setData={setData}
                  docType={page}
                  handleClose={() => {
                    setShowOffCanvas(false);
                  }}
                  pinHandler={() => {}}
                  setSelectedId={() => {}}
                  show={showOffCanvas}
                  isExternal={isExternal}
                  contacts={contacts}
                  backdrop={false}
                  documents = {Documents}
                />
              </>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {
          showAlertModal
          ? <></>
          : <></>
        }
        </>
    </div>
  );
};
export default Main;
