import React, { useEffect, useState } from "react";
import SubscriptionBasicdetails from "../../tabs/SubscriptionBasicdetails";
import InformationPaytabs from "../../tabs/PaytmTabs/InformationPaytabs";
import { _l } from "../../../../hooks/utilities";

const Subscriptions = ({ data, handleSubscription, handleCommonButtonToggle, handleSubscriptionBody }) => {
  const [selectedTab, setSelectedTab] = useState("basicdetails");
  const [selectedContactEmail, setSelectedContactEmail] = useState("");
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (data && data.contacts && data.contacts.length) {
      setContacts(data.contacts.map(item => ({ value: item.email, label: item.firstname + ' ' + item.lastname })));
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (selectedTab == 'information') {
      handleCommonButtonToggle('commonHeaderBodyData', _l("l_subscribe"))
    } else {
      handleCommonButtonToggle('commonHeaderBodyData', '')
    }
  }, [selectedTab])
  return (
    <>
 <> <div className="tab-wrapper_main pb-3 res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom for-accessible">
          <div className="comman-tab-wrapper p-15 pt-0 pb-0 ps-0">
            <div className="d-flex align-items-center">
              <div
                className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "basicdetails" ? "active" : ""
                  }`}
              >
                <a href="#/"
                  className="tab-name"
                  onClick={() => {
                    setSelectedTab("basicdetails");
                  }}
                >
                  {_l("l_basic_details")}
                </a>
              </div>
            </div>
          </div>
          <div className="task-header-right comman-tab-wrapper p-0">
          </div>
        </div>
      </div>
      <div className="overflow-hiiden-auto comman_vertical_btn_h p-15">
        {selectedTab === "basicdetails" ? (
          <SubscriptionBasicdetails data={data} />
        ) : 
          selectedTab === "information" ? (
            <InformationPaytabs data={data} contacts={contacts} selectedContactEmail={selectedContactEmail} setSelectedContactEmail={setSelectedContactEmail} handleSubscriptionBody={handleSubscriptionBody} />
          ) : (
            <React.Fragment></React.Fragment>
          )}
      </div>
      </>
    </>
  );
};
export default Subscriptions;
