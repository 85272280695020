import React from 'react';
 

function BackArrowIcon(props){
	const height 	= (props.height) ? props.height : "18";
	const width 	= (props.width) ? props.width : "23.216";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 18 23.216";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M25 11.7904L19.7216 5.99988H15.3505M25 11.7904L12.9588 24.9999M25 11.7904L1 11.8808M15.3505 5.99988L17.1649 11.7904L12.9588 24.9999M15.3505 5.99988H10.4845M12.9588 24.9999L1 11.8808M12.9588 24.9999L8.75257 11.7904L10.4845 5.99988M1 11.8808L6.27835 5.99988H10.4845M12.9999 4V1M19.9999 4L21.4999 1.49999M5.99988 4L4.49988 1.49999" stroke="url(#paint0_linear_14056_219939)" strokeLinecap="round"/>
<defs>
<linearGradient id="paint0_linear_14056_219939" x1="4.49988" y1="1" x2="5.50317" y2="6.82921" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFC700"/>
<stop offset="1" stopColor="#FDD500"/>
</linearGradient>
</defs>
</svg>




		</React.Fragment>
	)
}

export default BackArrowIcon