import React from 'react';

function CustomDiscordIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_7645_317449)">
					<path d="M25.4128 1.94496C23.4454 1.02442 21.364 0.370336 19.2237 0C18.9571 0.481992 18.6456 1.13027 18.4308 1.64602C16.1231 1.29902 13.8367 1.29902 11.5714 1.64602C11.3567 1.13039 11.0382 0.481992 10.7692 0C8.62674 0.370506 6.54358 1.02626 4.57523 1.94977C0.658351 7.86879 -0.403485 13.6406 0.127375 19.3308C2.7253 21.2708 5.24296 22.4494 7.7182 23.2206C8.33335 22.3746 8.87714 21.479 9.34394 20.543C8.45522 20.2046 7.59846 19.7877 6.78386 19.297C6.99823 19.1381 7.20755 18.9726 7.41152 18.8005C12.3477 21.1093 17.7111 21.1093 22.5885 18.8005C22.7933 18.9714 23.0026 19.137 23.216 19.297C22.4001 19.7889 21.5417 20.2068 20.6512 20.5454C21.1207 21.4853 21.6635 22.3818 22.277 23.2229C24.7546 22.4518 27.2746 21.2733 29.8725 19.3308C30.4955 12.7345 28.8084 7.01566 25.4128 1.94496ZM10.0165 15.8314C8.53464 15.8314 7.31941 14.448 7.31941 12.7635C7.31941 11.0789 8.50874 9.69316 10.0165 9.69316C11.5243 9.69316 12.7394 11.0764 12.7135 12.7635C12.7159 14.448 11.5243 15.8314 10.0165 15.8314ZM19.9834 15.8314C18.5016 15.8314 17.2864 14.448 17.2864 12.7635C17.2864 11.0789 18.4757 9.69316 19.9834 9.69316C21.4912 9.69316 22.7064 11.0764 22.6805 12.7635C22.6805 14.448 21.4912 15.8314 19.9834 15.8314Z" fill="#5865F2" />
				</g>
				<defs>
					<clipPath id="clip0_7645_317449">
						<rect width="30" height="23.3203" fill="white" />
					</clipPath>
				</defs>
			</svg>

		</React.Fragment>
	)
}

export default CustomDiscordIcon
