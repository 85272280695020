import React, {  } from "react";

import LockscreenMain from "../components/LockscreenMain";

const LiteVersion = () => {

  return (
    <>
      <LockscreenMain pageName="LiteVersion" />
    </>
  );
};

export default LiteVersion;
