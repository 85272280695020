import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";

const AddAssigneeList = ({ projectId, show, handleClose, handleShow, callBack }) => {
  const [managerList, setManagerList] = useState();

  useEffect(() => {
    CustomerServices.getSpaceManagerList().then((res) => {
      if (res.data) {
        setManagerList(res.data.map((member) => {
          return {
            id: member.id,
            fullname: member.fullname,
            checked: false,
            profile_image: member.profile_image,
          }
        }));
      }
    });
  }, []);

  const handleAssignSpaceManager = () => {
    let assignedManagers = managerList.filter(x => x.checked);
    assignedManagers = assignedManagers.map(x => x.id);
    if (assignedManagers && assignedManagers.length) {
      CustomerServices.assignSpaceManager(projectId, assignedManagers).then((res) => {
        if (res.status) {
          handleClose();
        }
      });
    }
    callBack();
  }



  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_add_assignee_manage_company")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" py-0">
          <div className="form-wrapper-main pt-2">
            <div className="assignee-list-wrapper">
              {managerList &&
                managerList.map((member, index) => {
                  return (
                    <div className="assignee-box hr_sepretr py-2">
                      <div className=" row justify-content-between align-items-center">
                        <div className="col-lg-10">
                          <ul className="notification-list-wrapper d-flex flex-column h-100">
                            <li className="notification-box w-100">
                              <div class="d-flex align-items-center">
                                <div class="user-imge">
                                  <div
                                    data-bs-toggle="tooltip"
                                    class="comman-image-box h30w30 rounded-circle p-1"
                                  >
                                    <div
                                      class="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url(${member.profile_image})`,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div
                                  class="notification-detail text-truncate title-fonts ps-2"
                                >
                                  {member.fullname}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-2">
                          <div class="form-check  text-end pb-0">
                            <label class="form-check-label  me-2">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={member.checked}
                                onChange={(e) => {
                                  setManagerList(Object.values({ ...managerList, [index]: { ...member, checked: e.target.checked } }));
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" onClick={handleAssignSpaceManager}>{_l("l_save")} </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAssigneeList;
