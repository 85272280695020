import React from "react";
import { Form } from "react-bootstrap";
 
import {
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { _l } from "../../../../hooks/utilities";
import { useSelector } from "react-redux";
import XIcon from "../../../../assets/icons/XIcon";

const DropdownWithCheckbox = ({ dropdownTitle, options, onClickHandler, onClose }) => {

  const { defaultTopics } = useSelector((state) => state.customer);

  const userType = localStorage.getItem("user_type");

  const getEmployeeInfo = (main_key, filter_key, titlePrefix) => {
    const usersData = defaultTopics.find((w) => w.main_key == main_key);
    const user = usersData ? usersData.categories.find((w) => w.filter_key == filter_key) : "";

    if(user)
    {
      return {
        full_name: `${user.title} ${titlePrefix ? "#" + titlePrefix : ""}`,
        profile_image: user.image,
      }
    }
    else {
      return {}
    }

  };

  return (
    <DropdownMenu container="body" className={"w-500"} toggle={false}>
      <DropdownItem
        aria-readonly
        header
        tag="div"
        className="dropdown-header-sticky hr_1"
        toggle={false}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="c-list-detail c-font f-14 title-fonts text-truncate color-white"
            
          >
            {dropdownTitle}
          </div>

          <a href="#/" className="color-white" onClick={onClose}>
            <XIcon className="HW16 m-auto" />
          </a>
        </div>
      </DropdownItem>
      <DropdownItem
        aria-readonly
        tag="div"
        toggle={false}
        className={`no-hover z-index-8 max-h40vh max-h20vh overflow-hiiden-auto p-0 `}
      >
        {options.map((option, key) => {
          const assigneeData = getEmployeeInfo(userType === "operator" ? "my_operator" : "my_contact", userType === "operator" ? option.provider_assignee : option.contact_assignee);

          return (
            <Form.Group
              key={key}
              className={`align-items-center dropdown-item hr_seprator d-flex position-relative c-input-box py-2 borderbottomtransparent`}
            >
              <input className="form-check-input mx-2" type="checkbox" defaultChecked={option.checked} onChange={(e) => {onClickHandler({...option, checked: e.target.checked})}} disabled={option.disabled} />
              <React.Fragment>
                <div className="comman-image-box multi-wrapper-border h30w30 rounded-circle one assignee-list-wrapper card-view-less-padding">
                  <div
                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                    style={{
                      backgroundImage: `url('${
                        option.attachments.length
                          ? option.attachments[0].image_url
                          : ""
                      }')`,
                    }}
                  ></div>
                </div>
                <div className="align-item-center d-flex py-1 w100minus40">
                  <span
                    className="w-auto m-auto text-truncate flex-grow-1 ps-2"
                    
                     
                     
                  >
                    {option.title}
                  </span>
                  {
                    option.device_id
                    ?
                    <div className="d-flex align-items-center justify-content-end w-120 ">
                      <div className="position-relative d-flex assignee-list-wrapper card-view-less-padding ">
                        <div className="dropdown-center multi_inner_wrapper with-min-width pe-2 d-flex with_separator_10 with_minus  ">
                          <div className="badge badge-done rounded-pill text-nowrap list-toggle-view-none">
                            <span class={`p-0`}>#{option.device_id}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <></>
                  }
                  {
                    assigneeData.full_name
                    ?
                    <div
                      className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator_10 with_minus before-dnone`}
                    >
                      <a href="#/"
                        data-bs-toggle="tooltip"
                        className="comman-image-box multi-wrapper-border h20w20 rounded-circle one assignee-list-wrapper card-view-less-padding"
                         
                         
                         
                         
                        
                      >
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                          style={{
                            backgroundImage: `url('${assigneeData.profile_image}')`,
                          }}
                        ></div>
                         
                      </a>
                    </div>
                    :
                    <React.Fragment></React.Fragment>
                  }
                </div>
              </React.Fragment>
            </Form.Group>
          );
        })}
     
      </DropdownItem>
       
    </DropdownMenu>
  );
};

export default DropdownWithCheckbox;
