
import React, { useState } from "react";
import EditcardDetailsModal from "../../../Modals/EditcardDetailsModal";
import NotePencilIcon from "../../../../assets/icons/NotePencilIcon";

 const Paytm = ({ projectId, clientId, location, zoomLevel }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

 return (
    <div>
        <div className="bg-white-05 radius_5 border p-2 w-50">
            <div className="d-flex align-items-center justify-content-between w-100 pb-2">
                <a href="#/" className="comman-image-box multi-wrapper-border  one assignee-list-wrapper card-view-less-padding" style={{ width: `30px`, height: `20px` }}>
                    <div className="comman-image-box-bg bg-style-cover h-100 w-100 " style={{ backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')` }}></div>
                </a>
                <a href="#/" className="" 
                onClick={() => {
                    setShow(true);
                  }}>
                    <NotePencilIcon className="HW18" />
                </a>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
                <div className="color-white-80 text-truncate  c-font f-14">
                    SEPA Debit
                </div>
                <div className="color-green text-truncate  c-font f-10">
                    Default Method
                </div>
            </div>
        </div>
        <EditcardDetailsModal
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
    </div>
  );
 };
 export default Paytm;