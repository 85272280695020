import React, { useEffect, useState } from "react";
import Customcalendar from "../components/Customcalendar";
import { showLeftPanel ,leftPanelCategoryListToggle } from "../hooks/utilities";
import NewThemeDashboard from "./NewThemeDashboard";

const CustomFullCalendar = ({pageName}) => {

  const [selectedEventDate, setselectedEventDate] = useState("");
  const [selectStartDate] = useState("")
  const getPage = localStorage.getItem("currentLeftPanelTab");
  
  useEffect(() => {
    showLeftPanel(true , true);
    document.addEventListener('mouseup', leftPanelCategoryListToggle);
    return () => {
      document.removeEventListener('mouseup', leftPanelCategoryListToggle);
    }
  }, []);
  return (
    <>
      {getPage != "homepage" ?
     <Customcalendar pageName="calendar"  setselectedEventDate={setselectedEventDate} selectStartDate={selectStartDate}/>
     :
     <NewThemeDashboard />
      }
    </>
  );
  };

export default CustomFullCalendar;
