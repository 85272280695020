import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Toasts from '../Toasts/Toasts';
import { hideFullScreenLoader } from '../../actions/messages';
import FeatureRestrictionModal from '../Modals/FeatureRestrictionModal';
const Alerts = () => {
    const messagesStates = useSelector((state) => state.messages);
    const {showFeatureRestrictonModal} = useSelector((state) => state.generalReducer);
    const [messageProps, setMessageProps] = useState(messagesStates);
    const dispatch = useDispatch();
    useEffect(() => {
        if (Object.keys(messagesStates).length) {
            setMessageProps(messagesStates);
        }
        if (messagesStates.fullScreenLoader) {
            setTimeout(() => {
                dispatch(hideFullScreenLoader());
            }, 4000);
        }
    }, [JSON.stringify(messagesStates)]);
    return (
        <React.Fragment>
            {
                showFeatureRestrictonModal
                ?
                <FeatureRestrictionModal />
                :
                <React.Fragment></React.Fragment>
            }
            {messageProps && messageProps.showToast ? (
                <Toasts
                    cancelButtonHandler={messageProps.cancelButtonHandler}
                    cancelButtonText={messageProps.cancelButtonText}
                    confirmButtonHandler={messageProps.confirmButtonHandler}
                    confirmButtonText={messageProps.confirmButtonText}
                    delay={5000}
                    handler={messageProps.toastCloseHandler}
                    header={messageProps.header}
                    message={messageProps.message}
                    toastClass={messageProps.className}
                    closeOnlyToast={messageProps.closeOnlyToast}
                />
            ) : (messageProps && messageProps.fullScreenLoader) ? (
                <></>
            ) : (
                <></>
            )}
        </React.Fragment>
    )
}
export default Alerts