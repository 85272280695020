import React from 'react';
 

function PhoneIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M6.17187 7.79953C6.68639 8.86204 7.54555 9.719 8.60937 10.2308C8.68785 10.2679 8.77464 10.284 8.86122 10.2774C8.94779 10.2709 9.03116 10.2419 9.10312 10.1933L10.6656 9.14953C10.7347 9.10271 10.8144 9.07414 10.8975 9.06649C10.9806 9.05884 11.0642 9.07236 11.1406 9.10578L14.0656 10.362C14.1656 10.4036 14.2491 10.477 14.3033 10.5707C14.3575 10.6644 14.3794 10.7734 14.3656 10.8808C14.2729 11.6044 13.9197 12.2694 13.3722 12.7514C12.8246 13.2334 12.1201 13.4994 11.3906 13.4995C9.13628 13.4995 6.97428 12.604 5.38022 11.0099C3.78616 9.41588 2.89063 7.25387 2.89062 4.99953C2.89079 4.27003 3.15676 3.56558 3.63877 3.018C4.12077 2.47043 4.78579 2.11724 5.50937 2.02453C5.61677 2.01074 5.72573 2.03263 5.81947 2.08684C5.9132 2.14105 5.98652 2.22456 6.02812 2.32453L7.28437 5.25578C7.31704 5.331 7.33071 5.4131 7.32417 5.49484C7.31763 5.57659 7.29109 5.65547 7.24687 5.72453L6.20312 7.31203C6.15666 7.38383 6.12938 7.46636 6.12391 7.55171C6.11844 7.63706 6.13495 7.72239 6.17187 7.79953V7.79953Z"/>
</svg>



		</React.Fragment>
	)
}

export default PhoneIcon