import React, { useEffect, useState } from "react";
import { _l, fetchProviderListFromIDB, insertProviderPageDataToIDB, showError } from "../../hooks/utilities";
import RiskTable from "../Providertables/RiskTable";
import SpaceTable from "../Providertables/SpaceTable";
import DocumentTable from "../Providertables/DocumentTable";
import ContactTable from "../Providertables/ContactTable";
import TodoTable from "../Providertables/TodoTable";
import { Button } from "react-bootstrap";
import RiskListModal from "../Modals/RiskListModal";
import ProviderProfileTable from "../Providertables/ProviderProfileTable";
import CreateTodoModal from "../Modals/CreateTodoModal";
import customerServices from "../../services/customer-services";
import { getListOfRiskData, getProviderListTableData, updateProviderListLoader } from "../../actions/customer";
import { useDispatch, useSelector } from "react-redux";
import UploadDocument from "../Modals/UploadDocument";
import ScheduleTable from "../Providertables/ScheduleTable";
import EquipmentTable from "../Providertables/EquipmentTable";
import SpaceSelectionModal from "../Modals/SpaceSelectionModal";
import { Spinner } from "react-bootstrap";
import { showMessage } from "../../actions/messages";
import AddEditContactProvider from "../Modals/AddEditContactProvider";

const TABS = [
  {
    title: "l_provider_profile",
    color: "black",
    name: "Provider-Profile",
  },
  {
    title: "l_contact",
    color: "black",
    name: "Contact",
  },
  {
    title: "l_space",
    color: "black",
    name: "Space",
  },
  {
    title: "l_document",
    color: "black",
    name: "Document",
  },
  {
    title: "l_risk",
    color: "black",
    name: "Risk",
  },
  {
    title: "l_todo_kpi_name",
    color: "black",
    name: "Todo",
  },
  {
    title: "l_schedule",
    color: "black",
    name: "Schedule",
  },
  {
    title: "l_equipment",
    color: "black",
    name: "Equipment",
  },
];
const ProviderListOffcanvas = ({ data, existingdTab = '' }) => {

  const { providerlistTableData } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState("Provider-Profile");
  const [tabCounts, setTabCounts] = useState({
    Risk: 0,
    Space: 0,
    Document: 0,
    Contact: 0,
    Todo: 0,
  });
  const [showRiskModal, setShowRiskModal] = useState(false);
  const [showTodoModal, setShowTodoModal] = useState(false);
  const [showDocumnentsModal, setShowDocumnentsModal] = useState(false);
  const [showSpaceModal, setShowSpaceModal] = useState({ show: false, actionType: "" });
  const [editCompanyProfile, setEditCompanyProfile] = useState(true);
  const [loader, setloader] = useState(false);
  const [editCompanyProfileData, setEditCompanyProfileData] = useState({
    company: "",
    industry_type:[],
    company_id_number: "",
    v_t_number: "",
    regions: [],
    categories: [],
    address: "",
    additional_data: "",
  });
  const [addContactData, setAddContactData] = useState(false);

  let providerID = localStorage.getItem("currentCompanyID")
  let client_id = localStorage.getItem("client_id") 

  const fetchData = (tab_name) => {
    dispatch(updateProviderListLoader(true));
    try {
      fetchProviderListFromIDB(providerID, "company_profile",client_id, dispatch)
      customerServices.getSelectedProviderDetails(providerID, tab_name).then((res) => {
        if (res && res.status) {
          switch (tab_name) {
            case "company_profile":
              dispatch(getProviderListTableData({ ...providerlistTableData, "company_profile": res.data.company_profile }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "company_profile": res.data.company_profile } , providerID, client_id )

              break;
            case "contact":
              dispatch(getProviderListTableData({ ...providerlistTableData, "contact": res.data.contact }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "contact": res.data.contact } , providerID, client_id )

              break;
            case "spaces":
              dispatch(getProviderListTableData({ ...providerlistTableData, "spaces": res.data.spaces }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "spaces": res.data.spaces } , providerID, client_id )

              break;
            case "document":
              dispatch(getProviderListTableData({ ...providerlistTableData, "document": res.data.document }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "document": res.data.document }, providerID, client_id )

              break;
            case "risk":
              dispatch(getProviderListTableData({ ...providerlistTableData, "risk": res.data.risk }));
              dispatch(getListOfRiskData(res.data.risk))
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "risk": res.data.risk } , providerID, client_id )

              break;
            case "task":
              dispatch(getProviderListTableData({ ...providerlistTableData, "task": res.data.task }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "task": res.data.task }, providerID, client_id )

              break;
            case "schedule":
              dispatch(getProviderListTableData({ ...providerlistTableData, "schedule": res.data.schedule }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "schedule": res.data.schedule } , providerID, client_id )

              break;
            case "equipment":
              dispatch(getProviderListTableData({ ...providerlistTableData, "equipment": res.data.equipment }));
              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "equipment": res.data.equipment }, providerID, client_id )

            break;
            default:
              break;
          }
          dispatch(updateProviderListLoader(false));

        } else {
          showError(res.message);
          dispatch(updateProviderListLoader(false));

        }
      })
    } catch (error) {
      console.log(error);
    }

  }

  const addEditProfileCompanytHandler = async() => {
    try {

      setloader(true);
      customerServices.addEditProfileCompany(
        editCompanyProfileData.company, 
        editCompanyProfileData.company_id_number, 
        editCompanyProfileData.v_t_number, 
        editCompanyProfileData.address, 
        editCompanyProfileData.industry_type && editCompanyProfileData.industry_type[0] ? editCompanyProfileData.industry_type[0].id : '', 
        localStorage.getItem('currentCompanyID'), 
        editCompanyProfileData.additional_data, 
        editCompanyProfileData.categories, 
        editCompanyProfileData.regions
      ).then((res) => {
       if (res.status) {
        dispatch(getProviderListTableData({ ...providerlistTableData, 
          "company_profile": {
            ...providerlistTableData.company_profile, 
            company : editCompanyProfileData.company,
            company_id_number : editCompanyProfileData.company_id_number,
            v_t_number : editCompanyProfileData.v_t_number,
            address : editCompanyProfileData.address,
            additional_data : editCompanyProfileData.additional_data,
            industry_type : editCompanyProfileData.industry_type && editCompanyProfileData.industry_type[0] ? editCompanyProfileData.industry_type[0].id : '',
            categories : editCompanyProfileData.categories && editCompanyProfileData.categories[0] ? [editCompanyProfileData.categories[0].value] : [],
            regions : editCompanyProfileData.regions && editCompanyProfileData.regions[0] ? [editCompanyProfileData.regions[0].value] : [],
          } 
        }));
        insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData,
           "company_profile": {
              ...providerlistTableData.company_profile, 
              company : editCompanyProfileData.company,
              company_id_number : editCompanyProfileData.company_id_number,
              v_t_number : editCompanyProfileData.v_t_number,
              address : editCompanyProfileData.address,
              additional_data : editCompanyProfileData.additional_data,
              industry_type : editCompanyProfileData.industry_type && editCompanyProfileData.industry_type[0] ? editCompanyProfileData.industry_type[0].id : '',
              categories : editCompanyProfileData.categories && editCompanyProfileData.categories[0] ? [editCompanyProfileData.categories[0].value] : [],
              regions : editCompanyProfileData.regions && editCompanyProfileData.regions[0] ? [editCompanyProfileData.regions[0].value] : [],
            }  
          } , providerID, client_id )
        setEditCompanyProfile(true);
        setloader(false);
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
       }else{
        setloader(false);
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
       }
      })
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    try {
      customerServices.getProviderCompanyTabsCount(providerID).then((res) => {
        setTabCounts({
          Risk: res.data && res.data.risk ? res.data.risk : 0,
          Space: res.data && res.data.spaces ? res.data.spaces : 0,
          Document: res.data && res.data.document ? res.data.document : 0,
          Contact: res.data && res.data.contact ? res.data.contact : 0,
          Todo:  res.data &&res.data.task ? res.data.task : 0,
          Schedule: res.data && res.data.schedule? res.data.schedule : 0,
          Equipment: res.data && res.data.equipment? res.data.equipment : 0,
        });
      })
    } catch (error) {
    }
  }, []);

  useEffect(() => {
    switch (selectedTab) {
      case "Provider-Profile":
        fetchData("company_profile")
        break;
      case "Contact":
        fetchData("contact")
        break;
      case "Space":
        fetchData("spaces")
        break;
      case "Document":
        fetchData("document")
        break;
      case "Risk":
        fetchData("risk")
        break;
      case "Todo":
        fetchData("task")
        break;
      case "Schedule":
        fetchData("schedule")
        break;
      case "Equipment":
        fetchData("equipment")
        break;
      default:
        fetchData("company_profile")
        break;
    }

  }, [selectedTab])


  return (
    <>
   
      <div className="comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian position-relative px20py20 bgpatternCommanImg">
        <div className="comman-tab-wrapper  pb-20px">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {TABS.map((t) => {
                return (
                  <>
                    <div
                      className={`tab-wrapper d-flex align-items-center lightthemebg ${selectedTab === t.name ? "active" : ""
                        }`}
                    >
                      <a
                        href="#/"
                        className="tab-name d-flex align-items-center bg-white-03"
                        onClick={() => {
                          setSelectedTab(t.name);
                        }}
                      >
                        <span>{_l(t.title)}</span>
                        {!["l_provider_profile"].includes(t.title) && <span className="ms-1">{`[ ${t.name == "Risk"
                            ? tabCounts.Risk
                            : t.name == "Space"
                              ? tabCounts.Space
                              : t.name == "Document"
                                ? tabCounts.Document
                                : t.name == "Contact"
                                  ? tabCounts.Contact
                                  : t.name == "Todo"
                                    ? tabCounts.Todo
                                    : t.name == "Schedule"
                                     ? tabCounts.Schedule
                                      : t.name == "Equipment"
                                        ? tabCounts.Equipment
                                        : 0
                          } ]`}</span>}
                      </a>
                    </div>
                  </>
                );
              })}
            </div>
            <>
            <div className="d-flex align-items-center gap10">
            {(selectedTab == "Contact" && existingdTab == "Invited")  ? <Button variant={"primary"} className="" size="sm"
              onClick={() => {
                switch (selectedTab) {
                  case "Contact":
                    setAddContactData(true);
                    break;
                  default:
                    break;
                }
              }}>
              { selectedTab == "Contact" 
                    ?  _l("l_add")
                    : ""
              }
            </Button> : <></>}
            {["Risk", "Todo", "Document", "Contact", "Equipment", "Schedule", "Space"].includes(selectedTab) || (selectedTab == "Provider-Profile" && existingdTab == "Invited")  ? <Button variant={"primary"} className="" size="sm"
              onClick={() => {
                switch (selectedTab) {
                  case "Risk":
                    setShowRiskModal(true);

                    break;
                  case "Todo":
                    setShowTodoModal(true);

                    break;
                  case "Document":
                    setShowDocumnentsModal(true);
                    break;
                  case "Contact":
                    window.open(`/connect-provider?space=${localStorage.getItem("selectedOffice")}&provider-type=invite_provider`, "_blank");
                    break;
                  case "Equipment" :
                    setShowSpaceModal({ show : true, actionType : "equipment"})
                    break
                  case "Schedule" :
                    setShowSpaceModal({ show : true, actionType : "schedule"})
                    break
                  case "Space" :
                    setShowSpaceModal({ show : true, actionType : "Addspace"})
                    break
                  case "Provider-Profile" :
                    if (editCompanyProfile) {
                      setEditCompanyProfile(false);
                    }else {
                      addEditProfileCompanytHandler();
                    }
                    break
                  default:
                    break;
                }
              }}>
              {selectedTab == "Risk" ? _l("l_add_risk") :
                selectedTab == "Todo" ? _l("l_create_todo") :
                  selectedTab == "Document" ? _l("l_upload_file") :
                    selectedTab == "Contact" ? _l("l_invite_provider") :
                      selectedTab == "Equipment" ? _l("l_add_equipment") :
                        selectedTab == "Schedule" ? _l("l_intro_add_schedule") :
                          selectedTab == "Provider-Profile" ? editCompanyProfile ? _l("l_edit") : loader ? _l("l_please_wait") : _l("l_submit") :
                            selectedTab == "Space" ? _l("l_space") + " " + _l("l_assign")
                            : ""
              }
               {loader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
            </Button> : <></>}
            </div>
            </>
          </div>
        </div>
         <div className="comman_vertical_btn_h  comman-white-box-shadow light-theme-white-bg">
          <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
            {selectedTab == "Risk" ? (
              <RiskTable data={data.risk} />
            ) : selectedTab == "Space" ? (
              <SpaceTable data={data.spaces} />
            ) : selectedTab == "Document" ? (
              <DocumentTable data={data.document} />
            ) : selectedTab == "Contact" ? (
              <ContactTable data={data.contact} existingdTab={existingdTab} />
            ) : selectedTab == "Todo" ? (
              <TodoTable data={data.task} />
            ) : selectedTab == "Schedule" ? (
              <ScheduleTable data={data.schedule}/>
            ) : selectedTab == "Equipment" ? (
              <EquipmentTable data={data.equipment}/>
            ) :
             selectedTab == "Provider-Profile" ? (
              <ProviderProfileTable data={data.company_profile} editCompanyProfile={editCompanyProfile} setEditCompanyProfileData={setEditCompanyProfileData} editCompanyProfileData={editCompanyProfileData}/>
            ) : (
              <></>
            )}
          </div>
        </div> 
      </div> 
      {
        showRiskModal ?
          <RiskListModal
            show={showRiskModal}
            handleClose={() => {
              setShowRiskModal(false);
            }}
          />
          : <></>
      }
      {
        showTodoModal ?
          <CreateTodoModal
            show={showTodoModal}
            handleClose={() => {
              setShowTodoModal(false);
            }}
          />
          : <></>
      }
      {
        showDocumnentsModal ?
          <>
            <UploadDocument
              show={showDocumnentsModal}
              handleClose={() => {
                setShowDocumnentsModal(false);
              }}
              data={{}}
              selectedDocument={{}}
            />
          </> :
          <></>
      }
      {
        showSpaceModal.show 
        ? <SpaceSelectionModal
          showSpaceModal={showSpaceModal}
          handleClose={()=> setShowSpaceModal({ show: false, actionType : ""})}
          />
        : <></>
      }
      {addContactData ? 
        <AddEditContactProvider show = {addContactData} handleClose={() => setAddContactData(false)} setTabCounts={setTabCounts} />
        : <></>
      }
    </>
  );
};

export default ProviderListOffcanvas;
