import React, { useEffect, useState } from "react";
import moment from "moment";
import { _l } from "../../../hooks/utilities";
import $ from "jquery";
import { showMessage } from "../../../actions/messages";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { updateCustomerData, updateStaffDate } from "../../../actions/documents-actions/documents-actions";
import ReactHtmlParser from "react-html-parser";

const Invoice = ({ data }) => {
  const [amount, setAmount] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("");
  const [nextBillingDate, setNextBillingDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [billFrom, setBillFrom] = useState("");
  const [billTo, setBillTo] = useState("");
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceSubTotal, setInvoiceSubTotal] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [invoiceTerms, setInvoiceTerms] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [total, setTotal] = useState("");
  const [facebook, setFacebook] = useState("");
  const [skype, setSkype] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState("");
  const [vat, setVat] = useState("");
  const [website, setWebsite] = useState("");
  const [d_lang, setD_lang] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [type, setType] = useState("");
  const [sex, setSex] = useState("");
  const [country_id, setCountry_id] = useState("");
   const dispatch = useDispatch();

  const userType = localStorage.getItem("user_type");

  $('._status option[value="'+data.active+'"]').attr('selected', 'selected');
  $('._type option[value="'+data.role+'"]').attr('selected', 'selected');
  $('._country option[value="'+data.country_id+'"]').attr('selected', 'selected');
  $('._sex option[value="'+data.sex+'"]').attr('selected', 'selected');

  useEffect(() => {
    if (data) {
      if(data.type == 'customer')
      {
        setCompany(data.full_name);
        setVat(data.vat);
        setPhone(data.phonenumber);
        setEmail(data.email);
        setWebsite(data.website);
        setD_lang(data.language);
        setAddress(data.address);
        setCity(data.city);
        setState(data.state);
        setZipCode(data.zip);
        setStatus(data.active)
        setCountry_id(data.country_id);
      }else {
        setAmount(data.total);
        setOrderNumber("0000-000000");
        setInvoiceDate(moment(data.date).format("D MMM, YY"));
        setBillingPeriod("unknown");
        setNextBillingDate("unknown");
        setInvoiceNumber(data.number);
        setBillFrom(data.bill_from);
        setBillTo(data.bill_to);
        setInvoiceItems(data.items);
        setInvoiceTerms(data.terms);
        setInvoiceSubTotal(data.subtotal);
        setInvoiceTotal(data.total);
        setTotalTax(data.total_tax);
        setFname(data.firstname);
        setLname(data.lastname);
        setEmail(data.email);
        setPhone(data.phonenumber ? data.phonenumber : "");
        setTotal(data.total ? data.total : 0.00)
        setFacebook(data.facebook ? data.facebook : "");
        setSkype(data.skype ? data.skype : "");
        setLinkedin(data.linkedin ? data.linkedin : "");
        setType(data.role);
        setSex(data.sex);
        setStatus(data.active)
  
  
        let arr = [];
        for (const key in data.permissions)
        {
          arr.push(key);
        }
      }
    }
  }, [JSON.stringify(data)]);
  const updateStaff = (value, key) => {
    let _type = type;
    let _status = status;
    let _sex = sex;
      if(key == 'type')
      {
        _type = value;
      } else if(key == 'status')
      {
        _status = value;
      } else if(key == 'sex')
      {
        _sex = value;
      }
      dispatch(
        updateStaffDate(data.id, fname, lname, email, phone, total, skype, facebook, linkedin, _type, _sex, _status)
      );
  }
  const updateCustomer = (value, key) => {
    let countryId = country_id;
    let _status = status;
      if(key == 'country')
      {
        countryId = value;
      } else if(key == 'status')
      {
        _status = value;
      }
    dispatch(updateCustomerData(data.id,company,vat,phone,website,d_lang, address, city, state, zipCode, countryId, _status))
  }

  return (
    <div>
      <p className="mb-0"><br></br></p>
      <table className="w-100">
        <thead></thead>
        <tbody className="d-none">
          <tr className="w-100 border-bottom ">
            <td className="w-50 p-2">
              <div className="d-flex ">
                <span className="">{_l("l_amount")} : ${amount}</span>
                <span className="color-white-80  ps-3"></span>
              </div>
            </td>
            <td className="w-50 p-2 border-left">
              <div className="d-flex ">
                <span className="">{_l("l_billing_period")} : </span>
                <span className="color-white-80  ps-3">{billingPeriod}</span>
              </div>
            </td>
          </tr>
          <tr className="w-100 border-bottom ">
            <td className="w-50 p-2">
              <div className="d-flex ">
                <span className="">{_l("l_order_number")} : </span>
                <span className="color-white-80  ps-3">{orderNumber}</span>
              </div>
            </td>
            <td className="w-50 p-2 border-left">
              <div className="d-flex ">
                <span className="">{_l("l_next_billing_date")} : </span>
                <span className="color-white-80  ps-3">{nextBillingDate}</span>
              </div>
            </td>
          </tr>
          <tr className="w-100 ">
            <td className="w-50 p-2">
              <div className="d-flex ">
                <span className="">{_l("l_invoice_date")} : </span>
                <span className="color-white-80  ps-3">{invoiceDate}</span>
              </div>
            </td>
            <td className="w-50 p-2 border-left">
              <div className="d-flex ">
                <span className="">{_l("l_invoice_number")} : </span>
                <span className="color-white-80  ps-3">{invoiceNumber}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bill-from w-100 d-flex  ">
        <div className="w-50 flex-grow-1">
          <div className="color-white-60  pb-2">{data.type == 'staff'? "Staff Detail" : data.type == 'customer' ? 'Customer Detail' : data.type == "equipment" ? 'General Details' :_l("l_bill_from")}</div>
          {data.type == "staff" ? 
          <>
          <b class="company-name-formatted">
          General Info</b><br /><br />
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label   form-label'>{'First Name'}</label>
              <Input placeholder='First name' type='text'  class='form-control form-control _fname' value={fname} 
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                onBlur={() => {
                    if(fname == '')
                    {
                        dispatch(
                            showMessage('unsucess', _l('l_error'),  _l("l_please_enter_first_name"))
                          );
                    } else {
                        updateStaff(fname, 'fname');
                    }
                }}
            />
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label   form-label'>Last Name</label>
              <Input placeholder='last name' type='text'  class='form-control form-control _lname' value={lname} 
              onChange={(e) => {
                setLname(e.target.value);
              }}
              onBlur={() => {
                  if(lname == '')
                  {
                      dispatch(
                          showMessage('unsucess', _l('l_error'),  _l("l_please_enter_first_name"))
                        );
                  } else {
                      updateStaff(lname, 'lname');
                  }
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label   form-label '>Email</label>
              <Input placeholder='123@email.com' type='email' name='email' class='form-control form-control _email' value={email} 
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onBlur={() => {
                  if(email == '')
                  {
                      dispatch(
                          showMessage('unsucess', _l('l_error'),  _l("l_please_enter_first_name"))
                        );
                  } else {
                      updateStaff(email, 'email');
                  }
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'>Phone</label>
              <Input placeholder='Phone number' type='number' name='phonenumber' class='form-control form-control _phone' value={phone} 
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              onBlur={() => {
                      updateStaff(phone, 'phone');
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'onChange={(e) => {
                setStatus(e.target.value);
                updateStaff(e.target.value, 'status');
              }}
              onBlur={() => {
              }}>Status</label>
              <select class='form-control form-control _status'>
                  <option value='1' >Active</option>
                  <option value='0' >Deactive</option>
              </select>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'>Sex</label>
              <select class='form-control form-control _sex' onChange={(e) => {
                setSex(e.target.value);
                updateStaff(e.target.value, 'sex');
              }}
              onBlur={() => {
              }}>
                  <option value='' selected>Select Gender</option>
                  <option value='male' >Male</option>
                  <option value='female' >Female</option>
                  <option value='others' >Others</option>
              </select>
          </div> 
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'>Type</label>
              <select class='form-control form-control _type' onChange={(e) => {
                setType(e.target.value);
                updateStaff(e.target.value, 'type');
              }}
              onBlur={() => {
              }}>
                  <option value='1' >Employee</option>
                  <option value='2' >Opérateur</option>
                  <option value='3' >Account Executive</option>
                  <option value='8' >Sales</option>
                  <option value='11' >Technical Manager</option>
                  <option value='12' >Account Manager</option>
                  <option value='13' >Partner Success</option>
              </select>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Hourly Rate</label>
              <Input placeholder='Hourly Rate'  type='number' class='form-control form-control _rate' value={total} 
              onChange={(e) => {
                setTotal(e.target.value);
              }}
              onBlur={() => {
                      updateStaff(total, 'total');
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Facebook</label>
              <Input placeholder='Facebook ID'  type='text' class='form-control form-control _facebook' value={facebook} 
              onChange={(e) => {
                setFacebook(e.target.value);
              }}
              onBlur={() => {
                      updateStaff(facebook, 'facebook');
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Skype</label>
              <Input placeholder='Skype ID'  type='text' class='form-control form-control _skype' value={skype} 
              onChange={(e) => {
                setSkype(e.target.value);
              }}
              onBlur={() => {
                      updateStaff(skype, 'skype');
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Linkedin</label>
              <Input placeholder='Linkedin ID'  type='text' class='form-control form-control _linkedin' value={linkedin} 
              onChange={(e) => {
                setLinkedin(e.target.value);
              }}
              onBlur={() => {
                      updateStaff(linkedin, 'linkedin');
              }}/>
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Staff ID</label>
              <Input placeholder='Staff ID #0000123' type='number' disabled class='form-control form-control _staffid' value={data.staffid} />
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Contact ID</label>
              <Input placeholder='Contact ID' type='email' disabled class='form-control form-control ' value={data.contactid} />
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Date Created</label>
              <Input placeholder='Created Datetime' type='email' disabled class='form-control form-control ' value={data.datecreated} />
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Date Updated</label>
              <Input placeholder='Updated Datetime' type='email' disabled class='form-control form-control ' value={data.update_date} />
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Last Login</label>
              <Input placeholder='Last Login Date' type='email' disabled class='form-control form-control ' value={data.last_login} />
          </div>
          <div class='col-xl-10 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Last Activity</label>
              <Input placeholder='Last Activity Date' type='email' disabled class='form-control form-control ' value={data.last_activity} />
          </div> 
          </>:
          data.type == 'customer' ? 
          <>
          <div className="m-0 row w-100">
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label   form-label'>{'Company Name'}</label>
              <Input placeholder='First name' type='text'  class='form-control form-control _fname' value={company} 
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                onBlur={() => {
                    if(company == '')
                    {
                        dispatch(
                            showMessage('unsucess', _l('l_error'), 'Company name cannot be empaty')
                          );
                    } else {
                        updateCustomer(company, 'comapny');
                    }
                }}
            />
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Vat Number</label>
              <Input placeholder='Vat Number' type='email' class='form-control form-control ' value={vat} 
              onChange={(e) => {
                setVat(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(vat, 'vat');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Email</label>
              <Input placeholder='123@email.com' disabled type='email' name='email' class='form-control form-control _email' value={email} 
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onBlur={() => {
                      updateStaff(email, 'email');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'>Phone</label>
              <Input placeholder='Phone number' type='number' name='phonenumber' class='form-control form-control _phone' value={phone} 
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              onBlur={() => {
                      updateCustomer(phone, 'phone');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Website</label>
              <Input placeholder='Website' type='text' class='form-control form-control ' value={website} 
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(website, 'website');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'onChange={(e) => {
                setStatus(e.target.value);
                updateCustomer(e.target.value, 'status');
              }}
              onBlur={() => {
              }}>Status</label>
              <select class='form-control form-control _status'>
                  <option value='1' >Active</option>
                  <option value='0' >Deactive</option>
              </select>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Default Language</label>
              <Input placeholder='Default Language' type='text' class='form-control form-control ' value={d_lang} 
              onChange={(e) => {
                setD_lang(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(d_lang, 'language');
              }}/>
          </div>
          </div>
          </> :
        <span
        dangerouslySetInnerHTML={{
          __html: billFrom,
        }}
      ></span>}
          
          <div className="d-none">
            <span className="">Unknown</span>
          </div>
          <div className="d-none">
            <span className="">Unknown</span>
            <span className="color-white-80  ps-3"> Unknown</span>
          </div>
          <div className="d-none">
            <span className="">Unknown</span>
            <span className="color-white-80  ps-3"> Unknown</span>
          </div>
        </div>
        <div className="w-50 flex-grow-1">
          <div className="color-white-60  pb-2">{data.type == 'staff'? "" : data.type == 'customer' ? "Address Info" : data.type == "equipment" ? 'Task Details' : _l("l_bill_to")}</div>
          { data.type == 'staff' ?
          <span>
          </span>
          : data.type == 'customer' ?
          <>
          <div className="m-0 row w-100">
            <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Address</label>
              <Input placeholder='Address' type='text' class='form-control form-control ' value={address} 
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(address, 'address');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>City</label>
              <Input placeholder='City' type='text' class='form-control form-control ' value={city} 
              onChange={(e) => {
                setCity(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(city, 'city');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>State</label>
              <Input placeholder='State' type='text' class='form-control form-control ' value={state} 
              onChange={(e) => {
                setState(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(state, 'state');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label '>Zip Code</label>
              <Input placeholder='Zip Code' type='text' class='form-control form-control ' value={zipCode} 
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
              onBlur={() => {
                updateCustomer(zipCode, 'zipCode');
              }}/>
          </div>
          <div class='col-xl-12 c-input-box pb-3 position-relative'>
              <label class='input-label no-asterisk  form-label'>Country</label>
              <select class='form-control form-control _country' onChange={(e) => {
                setCountry_id(e.target.value);
                updateCustomer(e.target.value, 'country');
              }}
              onBlur={() => {
              }}>
                  <option value='' >Choose Country</option>
                  { data.countries && data.countries.length > 0 ?
                  data.countries.map((res, index) =>{
                    return (
                      <>
                      <option value={res.country_id} >{res.short_name}</option>
                      </>
                    )
                  })
                : ""}
              </select>
          </div>
          </div>
          </> :
          <span
              dangerouslySetInnerHTML={{
                __html: billTo,
              }}
            ></span>
          }
        </div>
      </div>
      <div className={`${data.type == 'staff' || data.type == 'customer' || data.type == "equipment" ? 'd-none' : ""}`}>
        <div className=" d-flex flex-column pt-3">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table GeneralItemTable">
                <table className="dataTable comman-table smaller-table">
                  <thead className="bg-transparent">
                    <tr className="bg-white-03">
                      <th style={{ width: "3%" }}>#</th>
                      <th>{_l("l_item")}</th>
                      <th style={{ width: "7%" }}>{_l("l_quantity")}</th>
                      <th style={{ width: "7%" }}>{_l("l_rate")}</th>
                      <th style={{ width: "18%" }}>{_l("l_tax")} (%)</th>
                      <th style={{ width: "8%" }}>{_l("l_amount")}</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      invoiceItems && invoiceItems.length ?
                        invoiceItems.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr className="">
                                <td className="border-bottom align-top">
                                  {item.item_order}
                                </td>
                                <td className="max-width-100 text-truncate border-bottom">
                                  <div className="color-white-60 ">
                                    {ReactHtmlParser(item.description)}  {!index && data.proposal_item_code && userType === "operator" ? `(${ReactHtmlParser(data.proposal_item_code)})` : ""}
                                  </div>
                                  <div className="color-white-60 ">
                                    {ReactHtmlParser(item.long_description)}
                                  </div>
                                </td>
                                <td className="border-bottom align-top ">
                                  {item.qty}
                                </td>
                                <td className="border-bottom align-top ">
                                  {item.rate}
                                </td>
                                <td className="border-bottom align-top ">
                                  {item.tax_rate}
                                </td>
                                <td className="border-bottom align-top ">
                                  {item.amount}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        }) : <></>
                    }
                    <tr className="">
                      <td colSpan={5} className="text-end border-bottom ">
                        {_l("l_sub_total")}
                      </td>
                      <td className="border-bottom  color-white-60 ">
                        {invoiceSubTotal}
                      </td>
                    </tr>
                    <tr className="">
                      <td colSpan={5} className="text-end border-bottom  ">
                      {invoiceItems && invoiceItems.length ? invoiceItems[0].tax_rate : `0% ${_l("l_tva")}`}
                      </td>
                      <td className="border-bottom  color-white-60">
                        {totalTax}
                      </td>
                    </tr>
                    <tr className="">
                      <td colSpan={5} className="text-end color-green ">
                        {_l("l_total")}
                      </td>
                      <td className="fw-semibold  color-green">
                        {invoiceTotal}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="terms-condition">
                  <div className=" fw-semibold pb-2">{_l("l_terms_conditions")}</div>
                  <div className="color-white-60 pb-3 URLsemibold">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: invoiceTerms,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
export default Invoice;
