import React from 'react'
import ErrorFallback from './ErrorFallback';
import authServices from '../../services/auth-services';
import moment from 'moment';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    async logError(error, errorInfo)
    {       
        const logs = {
            timeStamp: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            userData: Object.keys(localStorage).filter(key => ["client_id", "contact_id", "staff_id", "email", "full_name", "selectedOffice", "version"].includes(key)).map(key => {return {[key]: localStorage.getItem(key)}}),
            error,
            errorInfo,
            page: window.location.pathname
        }

        try{
            const response = await authServices.sendLogsToServer(JSON.stringify(logs));
        }
        catch(e)
        {
            console.error(e);
        }
    }
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.logError(error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorFallback></ErrorFallback>;
        }
        return this.props.children;
    }
}