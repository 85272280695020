import React from 'react';
 

function UsersIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
				<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5.89062 10C7.68555 10 9.14062 8.54493 9.14062 6.75C9.14062 4.95507 7.68555 3.5 5.89062 3.5C4.0957 3.5 2.64062 4.95507 2.64062 6.75C2.64062 8.54493 4.0957 10 5.89062 10ZM5.89062 10C5.00801 10 4.13836 10.2122 3.35513 10.6191C2.5719 11.026 1.89809 11.6154 1.39062 12.3375M5.89062 10C6.77324 10 7.64289 10.2122 8.42612 10.6191C9.20935 11.026 9.88316 11.6154 10.3906 12.3375M10.1031 3.61875C10.3904 3.54083 10.6867 3.50091 10.9844 3.5C11.8463 3.5 12.673 3.84241 13.2825 4.4519C13.892 5.0614 14.2344 5.88805 14.2344 6.75C14.2344 7.61195 13.892 8.4386 13.2825 9.0481C12.673 9.65759 11.8463 10 10.9844 10C11.8671 9.99945 12.7369 10.2116 13.5202 10.6185C14.3035 11.0254 14.9773 11.615 15.4844 12.3375"/>
</svg>



		</React.Fragment>
	)
}

export default UsersIcon