export const mentionInputStyle = {
//     control: {
//     //   backgroundColor: '#fff',
//     //   fontSize: 16,
//       // fontWeight: 'normal',
//     },
//     multiLine: {
//       control: {
//         fontFamily: 'monospace',
//         minHeight: 63,
//       },
//       highlighter: {
//         padding: 9,
//         border: '1px solid green',
//       },
//       input: {
//         padding: 9,
//         border: '1px solid yellow',
//       },
//     },
//     singleLine: {
//       display: 'inline-block',
//       width: 180,
//       highlighter: {
//         padding: 1,
//         border: '2px inset purple',
//       },
//       input: {
//         padding: 1,
//         border: '2px inset',
//       },
//     },
    suggestions: {
//       list: {
//         backgroundColor: 'red',
//         border: '1px solid red',
//         fontSize: 16,
//       },
      item: {
//         padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#182f3d',
        },
      },
    },
  };
