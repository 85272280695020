import React, { useEffect, useRef, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { tabindexFix, _l, updateFloorFilters, getFloorsListForFilter } from "../../../hooks/utilities";
import SelectScheduleCategory from "./SelectScheduleCategory";
import CreateScheduleNew from "./CreateScheduleNew";
import AssignmentSchedule from "./AssignmentSchedule";
import CreteScheduleType from "./CreteScheduleType";
import { useDispatch, useSelector } from "react-redux";
 
import AccessoriesSchedule from "./AccessoriesSchedule";
import Form from 'react-bootstrap/Form';
import addDays from "date-fns/addDays";
import { createScheduleDetails } from "../../../actions/benchmark-actions/benchmark-actions";
import GeneralListing from "../../TaskModalComponents/GeneralListing";
import MinusIcon from "../../../assets/icons/MinusIcon";
import InfoIcon from "../../../assets/icons/InfoIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import FunnelIcon from "../../../assets/icons/FunnelIcon";
const NewSchedule = ({ show, handleClose, isCreateBenchmark, isEdit = false }) => {
    const [selectedTab, setselectedTab] = useState("SelectCategory")
    const [maxBudget, setMaxBudget] = useState(0);
    
    const {  benchmarkSchedule, benchmarkDetail } =
        useSelector((state) => state.customer);
    const { floorDetails, createScheduleDetail, filteredFloorIds } = useSelector((state) => state.benchmarkReducer);
    const dispatch = useDispatch();
    const [showFullscreen, setShowFullscreen] = useState(false);
    const [isUnKnownBudget, setIsUnKnownBudget] = useState(false);

    const floorList = getFloorsListForFilter();
    const accessoriesRef = useRef();

    useEffect(() => {
        if (isEdit) {
            setShowFullscreen(false);
        } else {
            setShowFullscreen(true);
        }
    }, [isEdit])

    useEffect(() => {

        if (createScheduleDetail) {
            let tempAmount = createScheduleDetail.amount ? createScheduleDetail.amount : 0
            setMaxBudget(tempAmount)
            setIsUnKnownBudget(createScheduleDetail.isUnKnownBudget ? createScheduleDetail.isUnKnownBudget : false)
        }
    }, [JSON.stringify(createScheduleDetail)]);

    useEffect(() => {
        if (benchmarkSchedule && benchmarkDetail && isEdit) {
            let tempStartDate =
                benchmarkDetail.startdate && benchmarkDetail.startdate != "0000-00-00"
                    ? new Date(benchmarkDetail.startdate)
                    : addDays(new Date(), 10);
            let editDetails = {
                providerCategory: benchmarkDetail.provider_id,
                clientId: getEditDetail('client_id'),
                isSchedule: getEditDetail('is_schedule'),
                categoryTitle: getEditDetail('provider_name'),
                regionId: getEditDetail('region_id'),
                benchmarkId: getEditDetail('id'),
                startDate: tempStartDate,
                endDate: benchmarkDetail.contract_end_date &&
                    benchmarkDetail.contract_end_date != "0000-00-00"
                    ? new Date(benchmarkDetail.contract_end_date)
                    : benchmarkDetail.id && benchmarkDetail.id > 0
                        ? ""
                        : addDays(tempStartDate, 30),
                amount: getEditDetail('max_budget'),
                basicInfo: getEditDetail('basic_information'),
                needToHire: getEditDetail('need_to_hire_people') > 0,
                isUnKnownStartDate: getEditDetail('start_date_is_empty') == 1,
                isUnKnownBudget: getEditDetail('budget_is_empty') == 1,
                needToHire: getEditDetail('need_to_hire_people') > 0,
                selectedLinks: getEditDetail('links'),
                benchmarkStatus: (benchmarkDetail && benchmarkDetail.is_schedule == "1" && benchmarkDetail.status != "2" && benchmarkDetail.status != "5") || benchmarkDetail.status == 1 ? false : true,
                scheduleStatus: benchmarkDetail && benchmarkDetail.status ? benchmarkDetail.status : 1,
                statusText: getEditDetail('status_text'),
                selectedSchedule: getEditDetail('is_schedule') == 1 && getEditDetail('operator_details') != '' ? getEditDetail('operator_details')[0] : {},
                selectedBenchmarkOperator: getEditDetail('is_schedule') == 0 && getEditDetail('operator_details') != '' ? getEditDetail('operator_details') : [],
                operators: getEditDetail('operator_details') != '' ? getEditDetail('operator_details') : []
            }

            const provider_name = benchmarkDetail.provider_name;
            if (
                provider_name &&
                (provider_name.toLowerCase().trim().includes("cleaning") ||
                    provider_name.toLowerCase().trim().includes("nettoyage")) &&
                benchmarkDetail.is_schedule == "0"
            ) {
                editDetails['includeAccessoriesTab'] = true
            }
            if (
                getEditDetail('creator_details') &&
                Object.keys(getEditDetail('creator_details')).length > 0
            ) {
                editDetails['creatorFullName'] = getEditDetail('creator_details').full_name
                editDetails['creatorProfileUrl'] = getEditDetail('creator_details').profile_image_url
            }
            if (getEditDetail('proposal_list') && getEditDetail('proposal_list').length
            ) {
                editDetails['benchmarkProposal'] = getEditDetail('proposal_list')
            }
            //Check if the benchmark is already active but budget and start date are unknown, user can update it in active status (But only once)
            if (
                getEditDetail('status') == 3 || getEditDetail('status') == 4) {
                if (getEditDetail('start_date_is_empty') == "1") {
                    editDetails['canUpdateStartDate'] = true
                }

                if (getEditDetail('budget_is_empty') == "1") {
                    editDetails['canUpdateBudget'] = true
                }
            }

            dispatch(createScheduleDetails(editDetails));
        }
    }, [benchmarkSchedule, benchmarkDetail]);
    

    const handleStep = (setTabName = '') => {
        dispatch(createScheduleDetails(setTabName, '', 1, 'selectedTab'));
        setselectedTab(setTabName)
    }

    const getEditDetail = (key) => {
        return benchmarkDetail && benchmarkDetail[`${key}`] ? benchmarkDetail[`${key}`] : ''
    }

    const stepTitle = () => {
        switch (selectedTab) {
            case "SelectCategory":
                return _l('l_select_provider');
            case "create-schedule-type":
                return isCreateBenchmark ? _l('l_benchmark_details') : _l('l_schedule_details');
            case "create-schedule":
                return _l('l_checklist');
            case "assignment-schedule":
                return _l('l_assignment');
            case "accessories":
                return _l('l_accessories');
            default:
                return ""
        }
    }

    const getFloorSelectedIds = () => {
        if(floorList.filter((x) => x.id !== -1).length === filteredFloorIds.length)
        {
            return floorList.map((x) => Number(x.id));
        }
        else {
            return filteredFloorIds.map((x) => Number(x));
        }
    }


    return (
        <>
            <Offcanvas
                show={show}
                onHide={handleClose}
                placement={!isEdit ? "bottom" : "end"}
                backdropClassName="offcanvas-backdrop test-class-04"
                className={!isEdit ? `bottom-offcanvas  w-600 offcanvas-center ${selectedTab == "accessories" ? "w-1200" : ""} ${selectedTab == "assignment-schedule" ? "w-1200" : ""} ${selectedTab == "create-schedule-type" ? "w-950" : ""} ${selectedTab == "create-schedule" ? "w-1200" : ""}` : `new_schedule taskDetailModal custom-offcanvas-modal radius_3 overflow-hidden custom-modal-style modal-full ${showFullscreen ? "" : ""}`}
                backdrop={!isEdit ? "false" : "static"}
                onShow={() => {
                    tabindexFix();
                }}
            >
                <div className="d-flex align-items-center w-100 justify-content-between">
                    <Offcanvas.Header closeButton closeVariant="white" className="w-100">
                        <div className="d-flex align-items-center justify-content-between w100minus30">
                            <Offcanvas.Title className={`text-truncate pe-1  ${selectedTab == "create-schedule" ? "" :"flex-grow-1"}`}>
                                {isEdit ? _l('l_edit') : _l('l_add')} {isCreateBenchmark ? _l('l_benchmark') : _l('l_schedule')}{` - ${stepTitle()}`}
                            </Offcanvas.Title>
                                {
                                    selectedTab == "create-schedule" && floorDetails.floor && floorDetails.floor.length > 1
                                        ?
                                        <div className="dropdown comman_action_icon ms-2">
                                            <a href="#/"
                                                className={`action_icon with_bg h35w35 on-hover-active-toggle-img`}
                                                type="button"
                                                id="OfficeListDropdown"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="outside"
                                                aria-expanded="false"
                                                 
                                                 
                                                 
                                            >
                                                <FunnelIcon className="HW18" />
                                            </a>
                                            <GeneralListing selectedIds={getFloorSelectedIds()} handler={(data) => { updateFloorFilters(data.id) }} list={floorList} />
                                        </div>
                                        :
                                        <></>
                                }
                                <div className="d-flex align-items-center w100minus100per">
                                <div className="schedule-count-box-main d-flex align-items-center w-100 overflow-hidden justify-content-end">
                                    {selectedTab === "create-schedule" ? (
                                        <div className="d-flex align-items-center overflow-auto w100minus150 flex-grow-1 mx-2">
                                            <div className="d-flex ms-auto px-2">
                                                {floorDetails.type_of_space.length ? floorDetails.type_of_space.map((space) => {
                                                    if (space.is_default == 1 || space.checked)
                                                        return (
                                                            <>
                                                                <div className="schedule-count-box with_separator_10 px-2 c-font f-10"
                                                                     
                                                                     
                                                                    data-class="tooltip-main " >
                                                                    <div className=" fw-semibold schedule-number title-fonts">{space.count || 0}</div>
                                                                    <div className=" color-white-80 schedule-title text-truncate">{space.title}</div>
                                                                </div>
                                                                 
                                                            </>
                                                        )
                                                }) : <></>}
                                            </div>
                                        </div>
                                    ) :
                                        <></>
                                    }
                                    
                                    {selectedTab != "SelectCategory" ?
                                        <div className="task-header-right pe-2 d-flex align-items-center">
                                            <Form.Check
                                                type="checkbox"
                                                id="budget"
                                                className="h-100 d-flex"
                                                onChange={(e) => {
                                                    dispatch(createScheduleDetails({ ...createScheduleDetail, [`isUnKnownBudget`]: e.target.checked }));
                                                }}
                                                checked={isUnKnownBudget}
                                                disabled={selectedTab == "create-schedule-type" ? false : true}

                                            />
                                            <a href="#/" className="with_separator_10"
                                                 
                                                 
                                                 >
                                                <InfoIcon className="HW16" />
                                            </a>
                                            <div className="c-font color-white-80 f-12 mx-2"
                                                 
                                                 
                                                 >
                                                {_l("l_budget")}
                                            </div>
                                            <div className={`d-flex align-items-center radius_3 badge-white px-2 ${isUnKnownBudget ? "schedule-checbox-disbled" : ""
                                                }`}
                                                 
                                                 
                                                 >
                                                <span className=" color-green c-font f-14 ps-1 fw-semibold text-center title-fonts">€</span>
                                                <input
                                                    className="bg-transparent m_w50 color-green c-font f-14 fw-semibold p-1  text-center title-fonts border-0"
                                                    value={isUnKnownBudget ? 0 : maxBudget}
                                                    type="number"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (!isUnKnownBudget && selectedTab == "create-schedule-type") {

                                                            dispatch(createScheduleDetails({ ...createScheduleDetail, [`amount`]: parseInt(value), ['isUnKnownBudget']: false }));
                                                        }
                                                        if (!isNaN(parseFloat(value))) {
                                                            setMaxBudget(value)
                                                        }
                                                        else {
                                                            setMaxBudget("");
                                                        }
                                                    }}
                                                    disabled={selectedTab == "create-schedule-type" ? false : true}
                                                />
                                                 
                                            </div>
                                            <div className="task-header-right pe-2 d-flex align-items-center d-none">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="budget"
                                                    className="h-100 d-flex"
                                                />
                                                <a href="#/" className="px-2"
                                                     
                                                     
                                                     >
                                                    <InfoIcon className="HW16" />
                                                </a>
                                                <div className="d-flex align-items-center radius_3 badge-white px-2">
                                                    <a href="#/">
                                                        <MinusIcon className="HW14" />
                                                    </a>
                                                    <input
                                                        className="bg-transparent m_w50 color-green c-font f-14 fw-semibold p-1  text-center title-fonts border-0"
                                                        value="$300"
                                                        type="text"
                                                         
                                                         
                                                         
                                                         
                                                        disabled
                                                    />
                                                    <a href="#/">
                                                        <PlusIcon className="HW14" />
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        : <></>
                                    }
                                </div>
                            </div>

                        </div >
                    </Offcanvas.Header >
                </div >
                <Offcanvas.Body id="new-schedule-body" className={` d-flex flex-column py-0 mt-3 ${showFullscreen ? "" : ""} ${!isEdit && selectedTab === "SelectCategory" ? '' : ''}`}>
                    <div className="h-100">
                        <div className={selectedTab === "SelectCategory" ? "h-100 w-100 d-flex flex-column" : 'd-none'}>
                            <SelectScheduleCategory handleStep={handleStep} isCreateBenchmark={isCreateBenchmark} isEdit={isEdit} />
                        </div>
                        <div className={selectedTab == "create-schedule-type" ? "h-100 w-100 d-flex flex-column" : 'd-none'}>
                            <CreteScheduleType handleStep={handleStep} isCreateBenchmark={isCreateBenchmark} isEdit={isEdit} />
                        </div>
                        <div className={selectedTab === "create-schedule" ? "h-100 w-100 d-flex flex-column" : 'd-none'}>
                            <AssignmentSchedule handleClose={(isSendBenchmark = 0) => {
                                handleClose(createScheduleDetail && createScheduleDetail['benchmarkId'], isSendBenchmark)
                            }} handleStep={handleStep} isCreateBenchmark={isCreateBenchmark} isEdit={isEdit} />
                        </div>
                        <div className={selectedTab === "assignment-schedule" ? "h-100 w-100 d-flex flex-column" : 'd-none'}>
                            <CreateScheduleNew handleClose={handleClose} />
                        </div>
                        <div className={selectedTab === "accessories" ? "h-100 w-100 d-flex flex-column" : 'd-none'}>
                            <AccessoriesSchedule ref={accessoriesRef} handleClose={(isSendBenchmark = 0) => handleClose(createScheduleDetail && createScheduleDetail['benchmarkId'], isSendBenchmark)} handleStep={handleStep} isCreateBenchmark={isCreateBenchmark} isEdit={false} />
                        </div>
                    </div>
                </Offcanvas.Body>

            </Offcanvas >
             
        </>
    );
};

export default NewSchedule;