import React, { useEffect, useState } from "react";
import Select from "react-select";
import { _l, showError } from "../../hooks/utilities";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { showMessage } from "./../../actions/messages";
import BenchmarkServices from "../../services/benchmark-services";
import { Spinner } from "react-bootstrap";
import XIcon from "../../assets/icons/XIcon";

const AddChecklistTemplate = ({
  show,
  handleClose,
  categories,
  checklists,
  template,
  isTask
}) => {
  const [templateTitle, setTemplateTitle] = useState("");
  const [checklistItems, setChecklistItems] = useState([]);
  const [categoryValue, setCategoryValue] = useState(
    categories.find((w) => w.value == template.provider_id)
  );
  const [savingInProgress, setSavingInProgress] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setChecklistItems(checklists.map((w) => w.description));
    setTemplateTitle(template.title);
  }, []);

  const addChecklistItem = () => {
    let flag = false;
    checklistItems.forEach((item) => {
      if (!item || !item.trim().length) {
        flag = true;
      }
    });
    if (!flag) {
      setChecklistItems(checklistItems.concat(""));
      return;
    }
  };

  const saveTemplate = async () => {
    try {
      setSavingInProgress(true);
      const checklistArray = checklistItems.map((w) => {
        return {
          checklist: [{ name: w }],
          slot: "",
          room_id: 0,
          days: {},
          frequency: {},
          priority: 0,
          type_of_space_items: 0,
          type_of_space_item_id: 0,
          photo_required: 0,
        };
      });
      const response = await BenchmarkServices.updateTaskChecklistTemplate(
        checklistArray,
        categoryValue ? categoryValue.value : 0,
        templateTitle,
        template.id,
        isTask
      );
      if (response.status) {
        handleClose();
      }
      else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      setSavingInProgress(false);
    }
  };

  const handleSaveTemplate = () => {
    if (!templateTitle || !templateTitle.trim().length) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_template_title")
        )
      );
      return;
    } else if (
      checklistItems.length < 1 ||
      checklistItems.findIndex((x) => x.trim() === "") !== -1
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_add_checklist_item_and_description")
        )
      );
      return;
    } else {
      saveTemplate();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style with-overflow"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {template ? _l("l_edit") : _l("l_create")}{" "}
            {_l("l_checklist_tempate")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 with-overflow">
          <div className="form-wrapper-main pb-2 custom-modal-section-sticky">
            <div className="row">
              <Form.Group className="col-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_title")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_title_placeholder")}`}
                  type="text"
                  autoFocus
                  name=""
                  value={templateTitle}
                  onChange={(e) => {
                    setTemplateTitle(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-6 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_category")}
                </Form.Label>
                <Select
                  className="custom-select-menu"
                  classNamePrefix="react-select"
                  placeholder={`${_l("l_category_placeholder")}`}
                  options={categories}
                  value={categoryValue}
                  closeMenuOnSelect={true}
                  onChange={(value) => {
                    setCategoryValue(value);
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className="form-wrapper-main pt-0">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={addChecklistItem}
            >
              {_l("l_add_item")}
            </button>
            {checklistItems && checklistItems.length ? (
              checklistItems.map((item, index) => {
                return (
                  <div key={index} className="row pt-3">
                    <div className="col-12 ">
                      <Form.Group className="comment-input-box">
                        <Form.Control
                          className="height-45"
                          type="text"
                          autoFocus={index + 1 === checklistItems.length}
                          placeholder={`${_l("l_cleaning_placeholder")}`}
                          aria-label="default input example"
                          value={item}
                          onChange={(e) =>
                            setChecklistItems(
                              Object.values({
                                ...checklistItems,
                                [index]: e.target.value,
                              })
                            )
                          }
                        />
                        <a
                          href="#/"
                          className="send-arrow-btn on-hover-active-toggle-img"
                          onClick={() => {
                            if (checklistItems.length > 1) {
                              setChecklistItems(
                                checklistItems.filter(
                                  (item, position) => position !== index
                                )
                              );
                            }
                          }}
                        >
                          <XIcon className="HW18" />
                        </a>
                      </Form.Group>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={handleSaveTemplate}
            disabled={savingInProgress}
          >
            {savingInProgress ? (
              <>
                {_l("l_please_wait")}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className="ms-2"
                />
              </>
            ) : (
              _l("l_save")
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddChecklistTemplate;
