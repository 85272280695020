import React from 'react'
import Button from 'react-bootstrap/Button';
import { _l } from "../../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
function ScheduleFooter({ selectedTab, handleStep, currentStep, isCreateBenchmark, providerCategory, isEdit, loader, setLoader = () => { } }) {
    
    return (
        <div className={`p-15 px-0 bg-base-header-color  new_schedule_footer flex-grow-1   ${currentStep == 3 ? '' : 'border-top'}`}>
            <div className="d-flex align-items-center justify-content-between">
                {
                    selectedTab != "SelectCategory" ? (
                        <Button variant="secondary" size="sm" className="" onClick={() => {
                            handleStep('prev')
                        }}> {_l("l_previous")}

                        </Button>

                    ) : (<></>)
                }
                {
                    isEdit ? <></> : <div className="daily-schedule-main w-auto m-0 mx-3 d-flex flex-grow-1">
                        <div className="schedule-progress-wrapper flex-grow-1 d-flex w-100 p-0">
                            <div className="d-flex align-items-center flex-grow-1">
                                <div className={`progress-box flex-grow-1 ${currentStep == 2 || currentStep == 3 || currentStep == 4 ? 'active' : ''}`}></div>
                                <div className={`progress-box  flex-grow-1 ${currentStep == 3 || currentStep == 4 ? 'active' : ''}`}></div>
                                <div className={`progress-box  flex-grow-1 ${currentStep == 4 ? 'active' : ''} `}></div>
                                {isCreateBenchmark ? <div className={`progress-box  flex-grow-1`}></div> : ""}
                            </div>
                            <div className="schedule-info w-auto p-0 ps-2">
                                <span className="pe-2">{_l(`l_step`)}</span>{currentStep + ' / '}<span className="ps-2">{isCreateBenchmark ? 4 : 3}</span>
                            </div>
                        </div>
                    </div>
                }

                {
                    selectedTab != "assignment-schedule" && (currentStep == 1 || currentStep == 2) ? (
                        <div className='ms-auto'>
                            <Button variant="primary" className="ms-3" size="sm" disabled={loader} onClick={() => {
                                setLoader(true)
                                handleStep('next')
                            }}>
                                {loader ? _l("l_please_wait") : _l("l_next")}
                                {
                                    loader
                                    ?
                                   <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        className={`ms-2 `}
                                        aria-hidden="true"
                                    />
                                    :
                                    <React.Fragment></React.Fragment>
                                }

                            </Button>
                        </div>

                    ) : (<></>)
                }

            </div>
        </div>
    )
}

export default ScheduleFooter