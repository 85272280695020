import chatApiService from "./api-service/chat-api-service";
import apiService from "./api-service/api-service";
import moment from "moment";
const REACT_APP_CHAT_BASE_URL = process.env.REACT_APP_CHAT_BASE_URL;
const REACT_APP_MYR_WEB_URL = process.env.REACT_APP_CHAT_WEB_BASE_URL;

const fetchGroups = (userId, projectId, isTopic) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/group/get-group-list";
  var reqData = {
    userId: userId,
    projectId: projectId,
    isTopic : isTopic ? isTopic : 0
  };
  return chatApiService(endPoint, reqData);
};

const fetchMessages = (userId, groupId, otherUserId , firstMsgDate = 0, id = "",startDate = "", endDate= "", chatCount = 20) => {
  // console.trace()
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/message/get-messages";
  var reqData = {
    userId: userId,
    groupId: groupId,
    otherUserId: otherUserId,
    // firstMsgDate: 1,
    startDate: startDate  && startDate != "" ? moment(startDate).format("YYYY-MM-DD") : "",
    endDate: endDate && endDate !="" ? moment(endDate).format("YYYY-MM-DD") : "",
    allowPage: 1,
    limit: parseInt(chatCount),
    fromDateTime: id == "" ? moment(new Date).add(1,"days").format("YYYY-MM-DD") + " 00:00:00" : id,
    pageBy: "date-time"

  };
  return chatApiService(endPoint, reqData);
};

const uploadChatMedia = (file) => {
  const endPoint =
    REACT_APP_MYR_WEB_URL + "/orb/chat_api/upload_chat_attachment";
  let form = new FormData();
  form.append("document_file", file);
  return apiService(endPoint, "POST", false, form);
};

const fetchUnreadMsgCount = (userId, projectId) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/message/get-unread-msg-count";
  var reqData = {
    userId: userId,
    projectId: projectId == null ? 0 : projectId,
  };
  return chatApiService(endPoint, reqData);
};

const fetchUserOnlineStatus = (userArr) => {
  var reqData = {
    userArr: userArr,
  };
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/user/get-myr-user-online-status";
  return chatApiService(endPoint, reqData);
};

const getAssignedAM = (userId, projectId) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/user/get-assigned-am";
  var reqData = {
    userId: userId,
    projectId: projectId,
  };
  return chatApiService(endPoint, reqData);
};

const fetchViewGroups = (userId, projectId) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/group/get-view-group-list";
  var reqData = {
    userId: userId,
    projectId: projectId,
  };
  return chatApiService(endPoint, reqData);
};

const fetchAllProjUnreadMsgCnt = (userId) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/group/get-project-wise-unread-count";
  var reqData = {
    userId: userId
  };
  return chatApiService(endPoint, reqData);
};

const fetchAllProjAMUnreadMsgCnt = (userId) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/message/get-project-list-by-last-message";
  var reqData = {
    userId: userId
  };
  return chatApiService(endPoint, reqData);
};

const createChatGroup = (projectId, userId, groupName, members, displayPicture = "") => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/group/create-chat-group";
  var reqData = {
    projectId: projectId,
    userId: userId,
    groupName: groupName,
    displayPicture: displayPicture,
    members: members
  };
  return chatApiService(endPoint, reqData);
};

const editChatGroup = (userId, groupId, groupName, members, displayPicture = "",type = 2) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/group/edit-chat-group";
  var reqData = {
    groupId: groupId,
    userId: userId,
    groupName: groupName,
    displayPicture: displayPicture,
    members: members,
    type : type
  };
  return chatApiService(endPoint, reqData);
};

const getGroupDetails = (userId, groupId) => {
  // console.trace()
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/group/get-group-details";
  var reqData = {
    userId: userId,
    groupId: groupId
  };
  return chatApiService(endPoint, reqData);
};

const editMessage = (userId, groupId, messageId, message, extraData = {}) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/message/edit-message";
  var reqData = {
    userId: userId,
    groupId: groupId,
    messageId: messageId,
    message: message,
    extraData: extraData
  };
  return chatApiService(endPoint, reqData);
};

const deleteMessage = (userId, groupId, messageId) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/message/delete-message";
  var reqData = {
    userId: userId,
    groupId: groupId,
    messageId: messageId
  };
  return chatApiService(endPoint, reqData);
};

const setMeetLink = (userId, groupId, meetLink) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/group/set-meet-link";
  var reqData = {
    userId: userId,
    groupId: groupId,
    meetLink: meetLink
  };
  return chatApiService(endPoint, reqData);
};

const createUserGroup = (userId1, userId2, projectId) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/group/create-user-group-1";
  var reqData = {
    userId1: userId1,
    userId2: userId2,
    projectId: projectId
  };
  return chatApiService(endPoint, reqData);
};

const createMyrUserGroup = (userId1, email1, UserType1, userId2, email2, UserType2, projectId) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/group/create-myr-group-1";
  var reqData = {
    projectId: projectId,
    myrUserId1: userId1,
    email1: email1,
    myrUserType1: UserType1,
    myrUserId2: userId2,
    email2: email2,
    myrUserType2: UserType2
  };
  return chatApiService(endPoint, reqData);
};

const setCustomUnreadMessage = (messageId)=>{
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/message/set-group-unread-message";
  var reqData = {
    userId: localStorage.getItem("chatUserId"),
    groupId : localStorage.getItem("activeGroupId"),
    messageId : messageId
  };
  return chatApiService(endPoint, reqData);
};

const getInitialChatJSON = (myrUserId, email, myrUserType, projectIds) => {
  const endPoint = REACT_APP_CHAT_BASE_URL + "/api/group/get-all-project-group-data";
  var reqData = {
    myrUserId,
    email,
    myrUserType,
    projectIds
  };
  return chatApiService(endPoint, reqData);
};

export default {
  fetchGroups,
  fetchMessages,
  uploadChatMedia,
  fetchUnreadMsgCount,
  fetchUserOnlineStatus,
  getAssignedAM,
  fetchViewGroups,
  fetchAllProjUnreadMsgCnt,
  fetchAllProjAMUnreadMsgCnt,
  createChatGroup,
  getGroupDetails,
  editChatGroup,
  editMessage,
  deleteMessage,
  createUserGroup,
  createMyrUserGroup,
  setMeetLink,
  setCustomUnreadMessage,
  getInitialChatJSON
};
