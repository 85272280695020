import React from 'react';
 

function ImportantIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M12.2363 12.7886L14.919 8.34428C14.9712 8.25068 14.9991 8.14306 14.9996 8.03318C15.0036 7.91569 14.9755 7.7996 14.919 7.69986L12.2363 3.25555C12.1889 3.17893 12.1269 3.1145 12.0548 3.06666C11.98 3.02372 11.8971 3.00088 11.8128 3H2L4.8339 7.69986C4.88486 7.79001 4.91273 7.89366 4.91458 7.99985C4.91405 8.10973 4.88615 8.21734 4.8339 8.31095L2 12.9997H11.8128C11.8963 13.0027 11.9793 12.9836 12.0548 12.9441C12.1269 12.8963 12.1889 12.8319 12.2363 12.7553V12.7886Z" />
</svg>


		</React.Fragment>
	)
}

export default ImportantIcon