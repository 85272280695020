import React,{ createContext, useState } from "react";
import { updateActionCounts, updateScheduleSlotKPICounts } from "../hooks/utilities";

const ScheduleContext = createContext({});

export const ScheduleProvider = ({ children }) => {

  const operator_availability = {
    action_count: 0,
    assignee_id: 0,
    assignee_name: "",
    assignee_image: "",
    assigned_items: [],
    available_from: "",
    available_items: [],
    comment: "",
    task_title: "",
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
      monthly: false,
      quarterly: false,
      yearly: false,
      half_yearly: false,
    },
    assigned_days: {
      mon: -1,
      tue: -1,
      wed: -1,
      thu: -1,
      fri: -1,
      sat: -1,
      sun: -1,
      monthly: -1,
      quarterly: -1,
      yearly: -1,
      half_yearly: -1,
    },
    time_slot: {
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
      sun: "",
      monthly: "",
      quarterly: "",
      yearly: "",
      half_yearly: "",
    },
    frequency: [],
    selected_count: 0,
    id: 0,
  };
  
  const counts = {
    headerCounts: {
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
      monthly: 0,
      quarterly: 0,
      yearly: 0,
      half_yearly: 0,
    }
  };

  const [currentSlot, setCurrentSlot] = useState(0);
  const [operatorAvailability, setOperatorAvailability] = useState([
    operator_availability,
  ]);
  const [calculationsCounts, setCalculationsCounts] = useState(counts);
  const [assignedChecklistItems, setAssignedChecklistItems] = useState([]);

  const updateAssigneeInMasterList = (checklistId, checked, availability) => {
    const pos = assignedChecklistItems.findIndex((x) => x.id == checklistId);
    if (pos > -1) {
      let targetItem = assignedChecklistItems[pos];
      if (
        targetItem.assigned_days &&
        Object.keys(targetItem.assigned_days).length
      ) {
        Object.keys(availability[currentSlot].days).forEach((day) => {
          if (
            availability[currentSlot].days[day] &&
            (targetItem["assigned_days"][day] == currentSlot ||
              targetItem["assigned_days"][day] < 0 || targetItem["assigned_days"][day] == undefined)
          ) {
            targetItem["assigned_days"][day] = checked ? currentSlot : -1;
          }
        });
      } else {
        targetItem["assigned_days"] = {};
        Object.keys(availability[currentSlot].days).forEach((day) => {
          if (availability[currentSlot].days[day] && targetItem.days[day]) {
            targetItem["assigned_days"][day] = checked ? currentSlot : -1;
          }
        });
      }
      const updatedMasterList = Object.values({
        ...assignedChecklistItems,
        [pos]: targetItem,
      });
      setAssignedChecklistItems(Object.values(updatedMasterList));
      setCalculationsCounts(updateScheduleSlotKPICounts(updatedMasterList));
      let allOperatorSlots = availability;
      updateActionCounts(allOperatorSlots, assignedChecklistItems);
      setOperatorAvailability(Object.values(allOperatorSlots));
    }
  };

  const handleSelectItem = (id, checked) => {
    let currentItems = operatorAvailability[currentSlot].assigned_items;
    let index = assignedChecklistItems.findIndex((x) => x.id == id);
    if (checked && currentItems.findIndex(w => w.id == id) == -1) {
      currentItems.push(assignedChecklistItems[index]);
    } 
    else if(!checked) {
      currentItems = currentItems.filter((x) => x.id != id);
    }
    const availability = Object.values({
      ...operatorAvailability,
      [currentSlot]: {
        ...operatorAvailability[currentSlot],
        assigned_items: currentItems,
      },
    });
    updateAssigneeInMasterList(id, checked, availability);
  };

  return (
    <ScheduleContext.Provider value={{operatorAvailability, setOperatorAvailability, currentSlot, setCurrentSlot, calculationsCounts, setCalculationsCounts, assignedChecklistItems, setAssignedChecklistItems, handleSelectItem }}>
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleContext;
