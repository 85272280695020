
import React from 'react';

function ChooseFromPastIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(	
        <React.Fragment>
        <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg"className={`comman-icons ${CommanHW}`}>
			<path d="M7.125 13.8125C7.125 10.5802 8.40904 7.48024 10.6946 5.19464C12.9802 2.90904 16.0802 1.625 19.3125 1.625C22.5448 1.625 25.6448 2.90904 27.9304 5.19464C30.216 7.48024 31.5 10.5802 31.5 13.8125M9.00009 48.5L2.12821 36.7812C1.51642 35.7052 1.35507 34.4308 1.67939 33.2363C2.00371 32.0417 2.78736 31.0239 3.85933 30.405C4.93131 29.786 6.20457 29.6162 7.40127 29.9326C8.59797 30.249 9.62095 31.0259 10.247 32.0938L14.6251 39.125V13.8125C14.6251 12.5693 15.119 11.377 15.998 10.4979C16.8771 9.61886 18.0694 9.125 19.3126 9.125C20.5558 9.125 21.7481 9.61886 22.6272 10.4979C23.5062 11.377 24.0001 12.5693 24.0001 13.8125V26.9375C24.0001 25.6943 24.494 24.502 25.373 23.6229C26.2521 22.7439 27.4444 22.25 28.6876 22.25C29.9308 22.25 31.1231 22.7439 32.0022 23.6229C32.8812 24.502 33.3751 25.6943 33.3751 26.9375V30.6875C33.3751 29.4443 33.869 28.252 34.748 27.3729C35.6271 26.4939 36.8194 26 38.0626 26C39.3058 26 40.4981 26.4939 41.3772 27.3729C42.2562 28.252 42.7501 29.4443 42.7501 30.6875V39.125C42.7501 44.75 40.8751 48.5 40.8751 48.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
		</React.Fragment>
	)
}

export default ChooseFromPastIcon