import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { _l } from '../../hooks/utilities';
import moment from 'moment';
import { commanoffcanvasisOpenfromCalendar, setIndexForCalendarSelectedTask } from '../../actions/customer';
import { getLeadDetail } from '../../actions/leads-actions/leads-actions';
import CommanOffCanvas from '../Offcanvas/CommanOffCanvas';
import jquery from "jquery";
import CommanCustomFullcalendar from '../CommanCustomFullcalendar';


const LeadCustomCalendar = ({ pageName,
  setselectedEventDate = () => { },
  selectStartDate,
}) => {
  const {
    calendarData,
    isDayGridDay,
    isDayGridMonth,
    isDayGridWeek,
    commanoffcanvasIsOpen,
    daterangeFilterApplied,
  } = useSelector((state) => state.customer);

  const {
    leads: leadsData,
    leadDetails: leadDetailsData,
  } = useSelector((state) => state.leadsReducer);

  const [calendarMonthData, setCalendarMonthData] = useState([]);
  const [calendarWeekData, setCalendarWeekData] = useState([]);
  const [events, setEvents] = useState([]);
  const [activeTaskID, setActiveTaskID] = useState(0);
  const [dayofWek, setdayofWek] = useState(0);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskId, setTaskId] = useState(0);
  const [task_type, setTask_type] = useState('lead');
  const [leadDetails, setLeadDetails] = useState({
    lead_id: 0,
    lead_profile: {},
    lead_contact: {},
    lead_attachments: {},
    lead_call_logs: {},
    lead_tracked_emails: {},
    lead_views_tracking: {},
  });
  const [selectedDateIndex, setSelectedDateIndex] = useState(0)
  const [dropdownToggle, setDropdownToggle] = useState(false);


  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const savedDate = localStorage.getItem("selectedDate");

  const dispatch = useDispatch();

  useEffect(() => {
    if (calendarData && isDayGridMonth == false) {
        setCalendarWeekData(calendarData);
    } else if(daterangeFilterApplied == false){
        const todayDate = new Date();
    }
}, [JSON.stringify(calendarData), JSON.stringify(savedDate)])

  useEffect(() => {
    if (isDayGridMonth) {
        const todayDate = new Date();
        const firstDayOfMonth = moment(selectStartDate ? selectStartDate : todayDate).startOf('month');
        const lastDayOfMonth = moment(selectStartDate ?selectStartDate : todayDate ).endOf('month');
        const monthDatesArray = [];

        let currentDate = firstDayOfMonth;
        while (currentDate.isSameOrBefore(lastDayOfMonth)) {
            monthDatesArray.push({ date: currentDate.format('YYYY-MM-DD') });
            currentDate.add(1, 'day');
        }
        setCalendarMonthData(monthDatesArray)
        const date = moment(monthDatesArray[0].date); // Thursday Feb 2015
        const dow = date.day();
        setdayofWek(dow == 0 ? dow + 6 : dow - 1)
    }

    }, [isDayGridMonth, selectStartDate])

    useEffect(() => {
    },[JSON.stringify(leadDetailsData)])
    useEffect(() => {
        if (leadDetailsData && leadDetailsData.lead_id > 0) {
        setLeadDetails({ ...leadDetails, ...leadDetailsData });
        if (!showTaskModal) {
            setShowTaskModal(true);
        }
        }
    }, [JSON.stringify(leadDetailsData)]);

  useEffect(() => {
  if (leadsData.length > 0) {
      var templeadsData = [];
      leadsData.map((eItem) => {
        eItem.lead_appointments.map((appo) => {
          if (appo.date == null) {
            templeadsData.push({...eItem, startdate : savedDate});
          }else{
            templeadsData.push({...eItem, startdate : appo.date});
          }
        })
    });

      let validDateData = templeadsData.sort((a, b) => {
        let event_dateA = "";
        if (a.startdate != null) {
            event_dateA = moment(a.startdate).format("hh:mm A");
        }
        let event_dateB = "";
        if (b.startdate != null) {
            event_dateB = moment(b.startdate).format("hh:mm A");
        }
        return event_dateA.localeCompare(event_dateB) ;

    })
    setEvents(validDateData);
    }   

  }, [JSON.stringify(leadsData), isDayGridWeek, isDayGridMonth,isDayGridDay])

  const findIndexOfselectedTask = (date) => {
    let index = calendarWeekData.findIndex((week)=>week.date === date)
    
    switch (index) {
        case 0:
            setSelectedDateIndex(1);
            dispatch(setIndexForCalendarSelectedTask(1));
            break;
        case 6:
            setSelectedDateIndex(5);
            dispatch(setIndexForCalendarSelectedTask(5))                
            break;
        default:
            setSelectedDateIndex(index);
            dispatch(setIndexForCalendarSelectedTask(index));
            break;
    }
  }



  return (
    <>
    <React.Fragment>
            <CommanCustomFullcalendar 
                type_calendar = {"lead"}
                daysOfWeek = {daysOfWeek}
                calendarWeekData = {calendarWeekData}
                calendarMonthData = {calendarMonthData}
                dayofWek = {dayofWek}
                events = {events}
                activeTaskID = {activeTaskID}
                dropdownToggle = {dropdownToggle}
                selectedDateIndex = {selectedDateIndex}
                commonModalData = {leadDetails}
                OpenCommanOffCanvasModal = {(event, dates, index) => {
                    if (isDayGridMonth) {
                            dispatch(commanoffcanvasisOpenfromCalendar(true));
                            jquery("body").removeClass("with-left-penel");
                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                            if (!commanoffcanvasIsOpen) {
                            setTaskId(event.id);
                            setActiveTaskID(index);
                            dispatch(getLeadDetail(event.id, "lead_profile"));                                                      
                            setShowTaskModal(true);
                            setselectedEventDate(dates.date)
                        }
                    }else if (isDayGridWeek) {
                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                            jquery("body").removeClass("with-left-penel");
                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                            if (!commanoffcanvasIsOpen) {
                            setTaskId(event.id);
                            setActiveTaskID(index);
                            findIndexOfselectedTask(dates.date)
                            setselectedEventDate(dates.date)
                            setShowTaskModal(true);
                            dispatch(getLeadDetail(event.id, "lead_profile"));
                        }
                    }else if (isDayGridDay) {
                            dispatch(commanoffcanvasisOpenfromCalendar(true))
                            jquery("body").removeClass("with-left-penel");
                            jquery("body").addClass("pb-0 right-penel-no-padding modal-open with-right-penel task-modal-offcanvas-open")
                            if (!commanoffcanvasIsOpen) {
                            setActiveTaskID(index)
                            setselectedEventDate(dates.date)
                            setShowTaskModal(true);
                            dispatch(getLeadDetail(event.id, "lead_profile"));
                        }
                    }
                }}
            />
        {
                showTaskModal ? <CommanOffCanvas
                    show={showTaskModal}
                    handleClose={() => {
                        setShowTaskModal(false);
                        dispatch(getLeadDetail("", "", 1));
                        dispatch(commanoffcanvasisOpenfromCalendar(false));
                        setTaskId(0);
                        setTask_type('')
                    }}
                    data={leadDetails}
                    pageName={"calendar"}
                    docType={task_type}
                    setSelectedId={setTaskId}
                    selectedTaskId={taskId}
                    IsKanbanView={false}
                    canvasfullHeightwithHeaderFooter={true}
                    setActiveTaskID={setActiveTaskID}
                    isFromCalendar = {true}
                /> : <></>
            }
        </React.Fragment>    
    
    </>
  )
}

export default LeadCustomCalendar