import React, { useEffect, useState } from "react";
import { changeTaskStatusFromChecklistItems, _l , getDatePickerLocale, selectedAssigneeForTaskAndRequest } from "../../hooks/utilities";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TaskListStatusDropdown from "./TaskListStatusDropdown";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import TaskShareLinkModal from "../Modals/TaskShareLinkModal";
import CustomerServices from "../../services/customer-services";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import ImageUploadDropdown from "../Dropdowns/ImageUploadDropdown";
import CheckedDropdown from "../Dropdowns/CheckedDropdown";
import outlookCalendar from "../../services/outlook-calendar";
import GoogleCalendar from "../../services/google-calendar";
import { showMessage } from "../../actions/messages";
import MeetingProviderModal from "../Modals/MeetingProviderModal";
import Toasts from "../Toasts/Toasts";
import {
  updateTaskStatus,
  changePriority,
  setTaskData,
  getTaskCount,
  setTaskAssignee,
  getTaskList,
  getSelectedTask,
  getMentionList,
  setTaskAssigneeNew,
  setTaskUnreadCmtCntAll,
  setSpaceNotificationCount,
  setVirtualSpaceNotificationCount,
} from "../../actions/customer";
import $, { isNumeric } from "jquery";
import moment from "moment";
import { get_refresh_request_data} from "../../actions/outlook_auth";
import { DEFAULT_PLACEHOLDER } from "../../actions/action-type";
import googleCalendar from "../../services/google-calendar";
import CommentMinified from "../Comments/CommentMinified";
import ImageViewer from "../Offcanvas/ImageViewer";
import DocumentsServices from "../../services/documents-services";
import customerServices from "../../services/customer-services";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { CUSTOM_ASSIGNEE_CHANGE } from "../../constants/constants";
import AddAssigneeMainDropdownNew from "../TaskModalComponents/AddAssigneeMainDropdownNew";
import PushPinIcon from "../../assets/icons/PushPinIcon";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import VideoCameraIcon from "../../assets/icons/VideoCameraIcon";
import ImageIcon from "../../assets/icons/ImageIcon";
import ChatCircleIcons from "../../assets/icons/ChatCircleIcons";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import ListChecksIcon from "../../assets/icons/ListChecksIcon";
import XIcon from "../../assets/icons/XIcon";
let showComments = false;
const CalendarDropdown = ({
  projectId,
  handler,
  setShowTaskInfo,
  taskNameOrStatusUpdate,
  dayClick,
  task_id,
  meetingUrl,
  selectCalIds,
  isOutlookCalActive,
  docType = "",
  data,
  getSelectedTaskCalendar,
  providers,
  fromChat = false,
  pageName = "",
  setCheckListClear = () => { },
  setActiveTaskID = () => { },
  setSelectedTaskId = () => { },
  startdate,
  endDate,
  setDropdownToggle = () => { },
  isFromPopover=false,
  style,
  setTaskId3dspase
}) => {
  const projects = JSON.parse(localStorage.getItem("spaces"));
  const { taskUnreadCmtCntAll,globalView } = useSelector((state) => state.customer);
  const currentDate = new Date();
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const [taskStatus, setTaskStatus] = useState(0);
  const [myr_total, setMyr_total] = useState(0);
  const [taskName, setTaskName] = useState("");
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [assigneesProviders, setAssigneesProviders] = useState([]);
  const [taskAssigneeTeam, setTaskAssigneeTeam] = useState([]);
  const [taskAllAssignee, setTaskAllAssignee] = useState([]);
  const [taskAssigneeIds, setTaskAssigneeIds] = useState([]);
  const [showMyrTotal, setShowMyrTotal] = useState(true);
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  const client_id = localStorage.getItem("client_id");
  const [taskCreatorImage, setTaskCreatorImage] = useState('');
  const contact_id = localStorage.getItem("contact_id");
  const contact_role = localStorage.getItem("contact_role");
  const [startDate, setStartDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [taskId, setTaskId] = useState();
  const [meetingcreate, setMeetingCreate] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [attachments, setAttachments] = useState({
    file_array: [],
    date: "",
    currentIndex: 0
  })
  const userType = localStorage.getItem('user_type');
  const [taskPriority, setTaskPriority] = useState(
    selectedTask ? (selectedTask.priority == "3" ? 3 : 2) : 0
  );
  const [start_date, setStart_date] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [end_date, setEnd_date] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  localStorage.setItem('fromDate', moment(startdate).format("YYYY-MM-DD"))
  localStorage.setItem('toDate', moment(endDate).format("YYYY-MM-DD"))
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const [isOutlookCalendarActive, setIsOutlookCalendarActive] = useState(isOutlookCalActive == true ? true : false);
  const [openCheckList, setOpenCheckList] = useState(false);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [providerType, setProviderType] = useState("");
  const [providerValue, setProviderValue] = useState("");
  const [providerDetail, setProviderDetail] = useState("");
  const [taskPinShow, setTaskPinShow] = useState(false);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [commentUnreadCount, setCommentUnreadCount] = useState(0);
  const [attchamentCount, setAttchamentCount] = useState(0);
  const [checklisProgressCount, setCheckProgressCount] = useState(0);
  const [linkCount, setLinkCount] = useState(0);
  const [meetingProvider,setMeetingProvider] = useState(false);
  const [openLinksDropdown, setOpenLinksDropdown] = useState(false);
  const [thirdPartyMeetingLink, setThirdPartyMeetingLink] = useState('');
  const dispatch = useDispatch();
  const [providersArray, setProvidersArray] = useState([]);
  const [MeetingUrl,setMeetingUrl] = useState("");
  const [showToast,setShowToast] = useState(false);
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [showLink, setShowLink] = useState(false);
  const handleCloseShareLink = () => setShowLink(false);
  const handleShowShareLink = () => setShowLink(true);
  const isAssignedUsers = useSelector((state) => state.discord_auth.isAssignedUsers);
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const DefaultTaskAssignees = useSelector(
    (state) => state.customer.defaultTaskAssignees
  );
  const selectedDate =
    localStorage.getItem("calendarData") &&
      localStorage.getItem("calendarData") != "undefined"
      ? JSON.parse(localStorage.getItem("calendarData"))[3].date
      : '';
  let totalRef = null;
  const virtualspace = localStorage.getItem("selectedVirtualSpace") ? 1 : 0 ;
useEffect(() => {
  if(meetingUrl != null && meetingUrl != "")
  {
    if(meetingUrl.includes('zoom') == true)
  {
    setThirdPartyMeetingLink("Join With Zoom Meet");
  } else if(meetingUrl.includes('teams') == true)
  {
    setThirdPartyMeetingLink("Join With MS Team Meet");
  } else if(meetingUrl.includes('google') == true)
  {
    setThirdPartyMeetingLink("Join With Google Meet");
  } else if(meetingUrl.includes('skype') == true)
  {
    setThirdPartyMeetingLink("Join With Skype Meet");
  }
  }
},[meetingUrl != ""])
window.addEventListener("outlook_calendar", () => {
  setIsOutlookCalendarActive(true);
});
useEffect(() => {
  setDueDate(dayClick);
  setMeetingCreate(false);
  setMeetingUrl("");
  setTaskStatus(1);
}, dayClick != "")
  useEffect(() => {
    if(taskName != "" && dayClick != "")
      {
        dispatch(
          setTaskData(
            projectId,
            task_id,
            taskName,
            startDate,
            dayClick||dueDate,
            myr_total
          )
        );
      }
  }, [dayClick != "", selectedTask == ""]);
  useEffect(() => {
    if (taskAssigneeTeam.length > 0 || assigneesProviders.length > 0) {
      setTaskAllAssignee(assigneesProviders.concat(taskAssigneeTeam))
    }
  },[taskAssigneeTeam,assigneesProviders])
  const [selectedAssigneeNew, setSelectedAssigneeNew] = useState([])
  useEffect(() => {
    if (selectedTask) {
      if (selectedTask.assignee_list_DEV) { 
        setSelectedAssigneeNew(selectedTask.assignee_list_DEV);
      }
      let link = selectedTask.task_share_link;
      if(link != null)
      {
        if(link.includes('meet.google.com'))
        {
          setMeetingCreate(true);
          setMeetingUrl(link);
        } else {
          setMeetingCreate(false);
        }
      } else {
        setMeetingCreate(false);
      }
  }
  }, [JSON.stringify(selectedTask)]);
  useEffect(() => {
    if (selectedAssigneeNew && selectedTask) {
      selectedAssigneeForTaskAndRequest(selectedAssigneeNew, setTaskAssigneePills, setAssigneesProviders, defaultTopics);
    }
  }, [JSON.stringify(selectedAssigneeNew), JSON.stringify(selectedTask)])
  useEffect(() => {
    if (selectedTask) {
      let task_assignee = [];
      let task_assignee_ids = [];
      let task_assignee_pills = [];
      let task_assignee_team = [];
      if (
        !!selectedTask.assignee_list &&
        selectedTask.assignee_list.constructor === Array
      ) {
        selectedTask.assignee_list.map((ts) => {
          ts.categories && ts.categories.map((taskAssign) => {
            defaultTopics &&
            defaultTopics.forEach((topic) => {
              if (topic.main_key ==  ts.main_key) {
                topic.categories.forEach((provider) => {
                  if (taskAssign.filter_key == provider.filter_key) {
                    if (ts.main_key == "equipments" || (selectedTask.is_requested == 1 && ts.main_key == "provider_task")) {
                      task_assignee_ids.push({
                        name: ts.main_key,
                      });
                    }else{
                      task_assignee_ids.push({
                        id: taskAssign.filter_key,
                        name: ts.main_key,
                      });
                    }
                  }
                })
              }})
            defaultTopics && defaultTopics.forEach((topic) => {
              if (ts.main_key == topic.main_key) {
                topic.categories.forEach((provider) => {
                  if (taskAssign.filter_key == provider.filter_key) {
                    if (
                      ts.main_key == "myr_staff_task" ||
                      ts.main_key == "my_people" ||
                      ts.main_key == "task_topic"
                    ) {
                      if (provider.filter_key < 0 && !virtualspace) {
                        task_assignee_team.push({
                          name: provider.title,
                          profile_image: provider.image,
                          id: provider.filter_key,
                        });
                      }
                      if (provider.filter_key > 0 && virtualspace) {
                        task_assignee_team.push({
                          name: provider.title,
                          profile_image: provider.image,
                          id: provider.filter_key,
                        });
                      }
                    } else {
                      let subCategory = []
                      if (selectedTask.is_requested == 1 && topic.main_key == "provider_task") {
                        taskAssign.sub_category  && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                            subCategory.push({...dd,id:dd.staffid});
                        })
                      }else{
                        provider.sub_category && provider.sub_category.forEach((sc) => {
                          taskAssign.sub_category && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                            if (sc.id == dd) {
                              subCategory.push(dd);
                              task_assignee_pills.push({
                                name: sc.name,
                                id: sc.id,
                                main_key:topic.main_key
                              });
                            }
                          })
                        });
                    }
                          task_assignee_ids.push({
                            id: provider.filter_key,
                            name: provider.title,
                            sub_category: subCategory
                          })
                          if (topic.main_key != "equipments" || !(selectedTask.is_requested == 1 && topic.main_key == "provider_task" && userType == "contact" && !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) { 
                      task_assignee_pills.push({
                        name: provider.title,
                        id: provider.filter_key,
                        main_key: topic.main_key
                      });
                    }
                    }
                  }
                });
              }
            });
            if ((ts.main_key == "provider_task" || ts.main_key == "proposals" )&& userType == "staff") {
              ts.categories.map((data) => {
                task_assignee_pills.push({
                  name: "Myr.ai Support",
                  id: data.filter_key,
                  main_key: data.filter_key
                });
              })
            }
          });
        })
      }
      if (selectedTask && selectedTask.schedule_assignees && selectedTask.schedule_assignees.length > 0) {
        selectedTask.schedule_assignees.forEach((ts) => {
          task_assignee.push({
            name: ts.name,
            profile_image: ts.profile_image,
            id: ts.staffid,
          })
        })
      }
      setTaskAssigneeTeam(task_assignee_team.slice());
      setTaskAssigneeIds(task_assignee_ids);
      const taskProject = projects.filter(
        (project) => project.project_id === projectId
      );
      setTaskName(selectedTask.name ? selectedTask.name : "");
      setTaskStatus(parseInt(selectedTask.status));
      setTaskId(selectedTask.task_id || selectedTask.id)
      if (selectedTask.startdate != null) {
        setStartDate(new Date(selectedTask.startdate));
      }
      if (
        selectedTask.duedate != null &&
        selectedTask.duedate != "0000-00-00 00:00:00"
      ) {
        setDueDate(new Date(selectedTask.is_requested == 1?new Date(selectedTask.dateadded):new Date(selectedTask.duedate)));
      }
      setTaskPinShow(parseInt(selectedTask.is_pinned) > 0);
      setTaskPriority(selectedTask.priority == "3" ? 3 : 2);
      setShowMyrTotal(selectedTask.myr_total > 0);
      if (selectedTask.task_creator_image) {
        setTaskCreatorImage(selectedTask.task_creator_image)
      }
      setMyr_total(selectedTask.myr_total);
      if (selectedTask.checklist) {
        setCheckList(selectedTask.checklist)
      }
      setCommentCount(selectedTask.comment_count)
      setCommentUnreadCount(selectedTask.unread_comment_count);
      setAttchamentCount(selectedTask.attachment_count)
      setLinkCount(selectedTask.link_count)
      setCheckProgressCount(selectedTask.checklist_progress )
    }
  }, [JSON.stringify(selectedTask)]);
  useEffect(() => {
    if (selectedTask) {
      setCheckProgressCount(selectedTask && selectedTask.checklist ? Math.round((selectedTask.checklist.filter((c) => c.finished == 1).length / selectedTask.checklist.length) * 100) : selectedTask.checklist_progress)
    }
  }, [checkList])
  const createEventByTaskId = (new_task_id, projectId) => {
    if(isOutlookCalendarActive == true)
            {
              outlookCalendar.createEvent(new_task_id, projectId).then((result) => {
                if(result.status == 1)
                {
                  dispatch(getSelectedTask(projectId, new_task_id, null, 1));
                }
              })
            } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2)
            {
              googleCalendar.createEvent(new_task_id, projectId).then((result) => {
                if(result.status == 1)
                {
                  dispatch(getSelectedTask(projectId, new_task_id, null, 1, 0, "google_calendar"));
                  dispatch(showMessage("success", _l("l_success"), _l("l_task_created_into_myr_and_event_created_into_google_calendar_sucessfully")));
                }
              })
            }
  } 
  const createTaskFromEvent = (selectedEvent, status, priority, pin, dueDate, title) => {
    if(selectedEvent && $.isNumeric(selectedEvent.id) == false)
    {
      if(isOutlookCalendarActive)
      {
                  outlookCalendar
                        .eventAddedIntoTask(
                          localStorage.getItem("selectedOffice"),
                          title != undefined ? title : selectedEvent.name,
                          selectedEvent.id,
                          selectedEvent.calendar_id,
                          selectedEvent.dateadded,
                          selectedEvent.startdate,
                          dueDate != undefined ? moment(dueDate).format("YYYY-MM-DD hh:mm:ss") : selectedEvent.duedate,
                          selectedEvent.TP_meeting_link,
                          status != undefined ? status : 1,
                          priority != undefined ? priority : 2,
                          pin == true ? 1 : 0
                        )
                        .then((res) => {
                          if (res.status == 1) {
                            dispatch(getSelectedTask(projectId, res.data, null, 1))
                            dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"), "outlook_calendar", res.data));
                          }
                        });
      } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ){
        GoogleCalendar.google_event_insert_into_task(
          localStorage.getItem("selectedOffice"),
          "project",
          selectedEvent.id,
          selectedEvent.calendar_id,
          status != undefined ? status : 1,
          priority != undefined ? priority : 2,
          pin == true ? 1 : 0,
          selectedEvent.TP_meeting_link
        ).then((res) => {
          if (res.status == 1) {
            dispatch(getSelectedTask(projectId, res.data, null, 1, 0, "google_calendar"));
            dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"), "google_calendar", res.data));
        }
        });
      }
    }
  }
  const updateTaskData = (startDateTemp = "", dueDateTemp = "", total = 0) => {
    if (taskName && taskName.trim() && dayClick == "") {
      dispatch(
        setTaskData(
          projectId,
          taskId,
          taskName,
          startDateTemp || startDate,
          dueDateTemp || dueDate,
          total || myr_total
        )
      );
      taskNameOrStatusUpdate(taskId, taskName);
    } else if(dayClick != "" && taskName && taskName.trim())
    {
      if(dayClick != "" && taskName && setShowTaskInfo)
      {
        outlookCalendar.createTask(
          projectId,
          task_id,
          taskName,
          dayClick
        ).then((res) => {
          if (res.status == 1) {
            setShowTaskInfo(false);
            localStorage.setItem('task_updated', 2);
            window.dispatchEvent(new Event('task_updated'));
            if(isOutlookCalendarActive == true)
            {
              outlookCalendar.createEvent(res.data.id, projectId).then((result) => {
                if(result.status == 1)
                {
                }
              })
            } else if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2)
            {
              googleCalendar.createEvent(res.data.id, projectId).then((result) => {
                if(result.status == 1)
                {
                  dispatch(showMessage("success", _l("l_success"), _l("l_task_created_into_myr_and_event_created_into_google_calendar_sucessfully")));
                }
              })
            }
          }
        })
      }
    }
    if(selectedTask && $.isNumeric(selectedTask.id) == true && (selectedTask.calendar_id == undefined || "" || null) && (isOutlookCalendarActive == true || googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2))
      {
        createEventByTaskId(selectedTask.id, projectId)
      }
      createTaskFromEvent(selectedTask, undefined, undefined, undefined, dueDateTemp, taskName);
    if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ){
      googlecalendar_events(taskId,taskName,startDate, dueDateTemp);
    }
    if(isOutlookCalendarActive === true)
    { 
     if(selectedTask && taskId != undefined)
     {
      if(selectedTask.calendar_id)
      {
        outlookCalendar.updateEvent(taskId, taskName, startDate, dueDateTemp, selectedTask.calendar_id, projectId).then((res) => {
          if(res.status == 1)
          {
            dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"), "outlook_calendar", 0));
          }
        })
      }
     }
    }
    if (startDate || dueDate)
      dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
  };
  useEffect(() => {
    let meeting_Url = selectedTask && selectedTask.TP_meeting_link ? selectedTask.TP_meeting_link : "";
    if(meeting_Url != null && meeting_Url != "")
    {
      setMeetingUrl(selectedTask && selectedTask.TP_meeting_link ? selectedTask.TP_meeting_link : "");
      setMeetingCreate(true);
      if(meeting_Url.includes('zoom.us') == true)
        {
          setThirdPartyMeetingLink("Join With Zoom Meet");
        } else if(meeting_Url.includes('teams') == true)
        {
          setThirdPartyMeetingLink("Join With MS Team Meet");
        } else if(meeting_Url.includes('google') == true)
        {
          setThirdPartyMeetingLink("Join With Google Meet");
        } else if(meeting_Url.includes('skype') == true)
        {
          setThirdPartyMeetingLink("Join With Skype Meet");
        }
    }
  },[selectedTask])
  useEffect(() => {
    window.addEventListener('selectedTaskId' , () => {
      let selectedTaskId = JSON.parse(localStorage.getItem('selectedTaskId'));
      if(selectedTaskId != null && $.isNumeric(selectedTaskId) == true)
      {
        $('#selectedTask_'+selectedTaskId).addClass('d-none');
        localStorage.removeItem('selectedTaskId');
      }
    })
  }, [JSON.parse(localStorage.getItem('selectedTaskId')) != null])
  useEffect(() => {
    window.addEventListener('join_meeting', () => {
      if(JSON.parse(localStorage.getItem('join_meeting')) != null && JSON.parse(localStorage.getItem('join_meeting')) != "")
        {
          if(JSON.parse(localStorage.getItem('join_meeting')).includes('zoom') == true)
        {
          setThirdPartyMeetingLink("Join With Zoom Meet");
        } else if(JSON.parse(localStorage.getItem('join_meeting')).includes('teams') == true)
        {
          setThirdPartyMeetingLink("Join With MS Team Meet");
        } else if(JSON.parse(localStorage.getItem('join_meeting')).includes('google') == true)
        {
          setThirdPartyMeetingLink("Join With Google Meet");
        } else if(JSON.parse(localStorage.getItem('join_meeting')).includes('skype') == true)
        {
          setThirdPartyMeetingLink("Join With Skype Meet");
        }
          setMeetingCreate(true);
          setMeetingUrl(JSON.parse(localStorage.getItem('join_meeting')));
      }
    })
  }, [JSON.parse(localStorage.getItem('join_meeting'))])
  const googlecalendar_events = (taskId,taskName,startDate,dueDate) => {
    if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 && taskId)
    {
        GoogleCalendar.update_google_events(taskId,taskName,startDate,dueDate,selectedTask.calendar_id).then((res)=>{
          if(res.status==1)
          { 
            dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"), "google_calendar", 0));
          }
        })
    }
  };
  const changeTaskStatus = (statusId) => {
    dispatch(updateTaskStatus(taskId, projectId, statusId));
    setTaskStatus(statusId);
    taskNameOrStatusUpdate(taskId, statusId, 'status')
    dispatch(getTaskCount(client_id, contact_id, projectId, selectedDate));
      if(selectedTask && $.isNumeric(selectedTask.id) == true && (selectedTask.calendar_id == undefined || "" || null) && (isOutlookCalendarActive == true || googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2) )
      {
        createEventByTaskId(selectedTask.id, projectId)
      }
      createTaskFromEvent(selectedTask, statusId, undefined, undefined, undefined, taskName);
  };
  const changeTaskPriority = () => {
    dispatch(changePriority(taskId, projectId, taskPriority, selectedTask.provider)).then(() => {
      setTaskPriority(taskPriority == 3 ? 2 : 3);
    });
      if(selectedTask && $.isNumeric(selectedTask.id) == true && (selectedTask.calendar_id == undefined || "" || null) && (isOutlookCalendarActive == true || googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2))
      {
        createEventByTaskId(selectedTask.id, projectId)
      }
      createTaskFromEvent(selectedTask, undefined, taskPriority == 3 ? 2 : 3, undefined, undefined, taskName);
  };
  const handlePinTask = () => {
    CustomerServices.pinTask(taskId, projectId, selectedTask.provider).then((res) => {
      setTaskPinShow(!taskPinShow);
    });
    if(selectedTask && $.isNumeric(selectedTask.id) == true && (selectedTask.calendar_id == undefined || "" || null) && (isOutlookCalendarActive == true || googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ))
      {
        createEventByTaskId(selectedTask.id, projectId)
      }
      createTaskFromEvent(selectedTask, undefined, undefined, !taskPinShow, undefined, taskName);
  };
  const assignToProvider = (type, value, details) => {
    updateTaskData(undefined, undefined, myr_total);
    createTaskFromEvent(selectedTask);
    CustomerServices.setTaskAssignee(projectId, taskId, type, value).then(
      (res) => {
        if (res.status && res.data) {
          dispatch(setTaskAssignee(details, type));
        }
      }
    );
      if(selectedTask && $.isNumeric(selectedTask.id) == true && (selectedTask.calendar_id == undefined || "" || null) && (isOutlookCalendarActive == true || googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2))
      {
        createEventByTaskId(selectedTask.id, projectId)
      }
  };
  const handleRequestAssignee = (assigneeDetail) => {
    let selectedAssignees = {
      main_key: assigneeDetail.main_key,
      filter_key: assigneeDetail.id || assigneeDetail.filter_key,
      image: assigneeDetail.image || "",
      name: assigneeDetail.name || "",
      is_assigned: 1,
    };
    customerServices.setRequestTaskAssignee(taskId, [selectedAssignees])
    .then((res) => {
        if (res.status == 1 && res.data && res.data.schedule_assignees) {
          if (docType == "task" || docType == "request-task") {
            dispatch(
              setTaskAssigneeNew(
                {
                  filter_key: assigneeDetail.id || assigneeDetail.filter_key,
                  title: assigneeDetail.name,
                  image: assigneeDetail.image || "",
                },
                assigneeDetail.main_key,
                assigneeDetail.id || assigneeDetail.filter_key,
                taskId,
                [],
                0,
                0,
                res.data.schedule_assignees,
                "calendar"
              )
            );
          }
        } 
      });
  };
  useEffect(() => {
    setProvidersArray([]);
      if(selectedTask)
      {
        if(selectedTask.task_assignee && selectedTask.task_assignee.providers != undefined)
        {
          setProvidersArray(selectedTask.task_assignee.providers);
        }
      }
      if(selectedTask)
      {
        selectedTask.task_assignee && selectedTask.task_assignee.myr_staff.map((staff, index) => {
          if(staff.is_assigned == 1)
          {
            setProvidersArray((providersArray) => [...providersArray, staff]);
          }
        })
      }
      if(DefaultTaskAssignees && typeof DefaultTaskAssignees.myr_staff === 'object')
      {  
        DefaultTaskAssignees.myr_staff.staff.map((staff, index) => {
          if(staff.is_assigned == 1)
          {
            setProvidersArray((providersArray) => [...providersArray, staff]);
          }
        })
      } 
      if(isAssignedUsers != undefined)
      {
        isAssignedUsers && isAssignedUsers.data && isAssignedUsers.data.data && isAssignedUsers.data.data.length > 0 && isAssignedUsers.data.data.map((contact) => {
          setProvidersArray((providersArray) => [...providersArray, {
            name:contact.assginee_id,
            email:contact.assginee_id,
            staffid:localStorage.getItem('staff_id')
          }]);
        })
      }
      if(providers != undefined && providers != [] && providers.length > 0)
      {
        providers.map((contact, index) => {
          setProvidersArray((providersArray) => [...providersArray, contact]);
        })
      }
      if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ){
        if(selectedTask){
          if(selectedTask.task_assignee.providers != "" && selectedTask.task_assignee.providers != undefined){
            setProvidersArray(selectedTask.task_assignee.providers);
          }
        }
      } 
      let AssigneeChat ={};
      if(selectedTask && DefaultTaskAssignees )
      {
        if( DefaultTaskAssignees.slack_channel != undefined && DefaultTaskAssignees.slack_channel.slack != undefined ){
          AssigneeChat = DefaultTaskAssignees.slack_channel.slack.filter((s) => s.is_active === 1);
          AssigneeChat.map((slack_assignee, index) => {
                setProvidersArray((providersArray) => [...providersArray, {
                  name:slack_assignee.title || slack_assignee.filter_key,
                  email:slack_assignee.filter_key,
                  staffid:localStorage.getItem('staff_id'),
                  profile_image: DEFAULT_PLACEHOLDER,
                }]);
              })
        }
      }
      if(virtualspace == 1){
        setProvidersArray([]);
        if(selectedTask && DefaultTaskAssignees )
        {
          if( DefaultTaskAssignees.task_topic != undefined && DefaultTaskAssignees.task_topic.tasks.categories != undefined ){
            AssigneeChat = DefaultTaskAssignees.task_topic.tasks.categories.filter((s) => s.is_assigned === 1);
            AssigneeChat.map((task, index) => {
                  setProvidersArray((providersArray) => [...providersArray, {
                    name:task.title || task.filter_key,
                    email:task.filter_key,
                    staffid:localStorage.getItem('staff_id'),
                    profile_image: DEFAULT_PLACEHOLDER,
                  }]);
                })
          }
        }
        if(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 ){
          if(selectedTask && selectedTask.task_assignee.providers != "" && selectedTask.task_assignee.providers != undefined){
            selectedTask.task_assignee.providers.map((contact, index) => {
              setProvidersArray((providersArray) => [...providersArray, contact]);
            })
          }
        } 
        if(providers != undefined && providers != [] && providers.length > 0)
        {
          providers.map((contact, index) => {
            setProvidersArray((providersArray) => [...providersArray, contact]);
          })
        }
      }
}, [selectedTask, providers,isAssignedUsers, DefaultTaskAssignees,virtualspace]);
  const handleProvider = (type, value, details) => {
    if (myr_total < 0 && type.main_key == "provider_task") {
      assignToProvider(type, value, details);
      handleRequestAssignee(type);
    } else {
      handleRequestAssignee(type);
      setShowMyrTotal(true);
      totalRef.focus();
      setProviderType(type);
      setProviderValue(value);
      setProviderDetail(details);
    }
  };
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };
  const copyLink = () => {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($('#copy_link').val()).select();
    document.execCommand("copy");
    $temp.remove();
  }
  const isString =(id) => {
    var type = _l('l_task');
    if(selectedTask && selectedTask.type != undefined || selectedTask && selectedTask.calendar_id){
      type = _l('l_google_task')
    } else if(selectedTask && selectedTask.provider ==='one_drive')
    {
      type = 'document task';
    }
    if($.isNumeric(id))
    {
      return type;
    } else {
      return _l('l_event');
    }
  }
  const getTaskComments = async() => {
    showComments = true;
    setCommentsLoading(true);
    try {
      const comments = await DocumentsServices.getComments(taskId, 'task',undefined, undefined, commentUnreadCount > 0 ? 1 : 0);
      if(comments.data)
      {
        setCommentsList(comments.data.map((comment) => {
          return {
            id: comment.id,
            content: comment.content,
            contact_id: comment.contact_id,
            staffid: comment.staffid,
            full_name: comment.contact_full_name,
            profile_url: comment.addedfrom_details.profile_url,
            attachments: comment.attachments,
            date: comment.time,
            is_allowed_to_edit:comment.is_allowed_to_edit
          }
        }));
      }
      if(commentUnreadCount > 0){
          dispatch(setTaskUnreadCmtCntAll(Number(taskUnreadCmtCntAll) - Number(commentUnreadCount)))
          if (localStorage.getItem('selectedVirtualSpace') && JSON.parse(localStorage.getItem('selectedVirtualSpace'))) {
            dispatch(setVirtualSpaceNotificationCount(projectId, commentUnreadCount, -1));
          }
          else {
            dispatch(setSpaceNotificationCount(projectId, commentUnreadCount, -1));
          }
          setCommentUnreadCount(0);
        }
    }
    catch(e)
    {
      console.error(e);
    }
    finally{
      setCommentsLoading(false);
    }
  }
  const handleImagePreview = (comment) => {
    setShowImagePreview(true);
    setAttachments({...attachments, file_array: comment.attachments, currentIndex: 0, date: comment.dateadded});
  };
  const closePreview = () => {
    setAttachments({...attachments, file_array: [], currentIndex: 0});
    setShowImagePreview(false);
  };
  const changeAttachment = (direction) => {
    if(direction === "prev" && attachments.currentIndex > 0)
    {
      setAttachments({...attachments, currentIndex: attachments.currentIndex--});
    }
    else if(direction === "next" && attachments.currentIndex <= attachments.current.file_array.length)
    {
      setAttachments({...attachments, currentIndex: attachments.currentIndex++});
    }
  };
  const handleAddEditComment = async(content, files, id) => {
    try {
      const response = await DocumentsServices.addDocumentComment('task', taskId, content, 0, id, files);
      if(id > 0)
      {
        const commentIndex = commentsList.findIndex(w => w.id == id);
        setCommentsList(Object.values({...commentsList, [commentIndex]: {...commentsList[commentIndex], content}}));
      }
      else {
        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];
        if(files)
        {
          Array.from(files).map((file) => {
            attachments.push({
              file_url: URL.createObjectURL(file),
              file_name: file.name
            })
          });
        }
        setCommentsList(commentsList.concat([
          {
            id: response.data.comment_id,
            content: content,
            contact_id: contact_id,
            staffid: staffid,
            full_name: full_name,
            profile_url: profile_url,
            attachments: attachments,
            is_allowed_to_edit: 1,
            date: response.data.time,
            dateadded:new Date()
          }
        ]));
        setCommentCount(commentsList.length + 1);
      }
    }
    catch(e)
    {
      console.error(e);
    }
  };
  const handleDeleteComment = (comment) => {
    try {
      const response = DocumentsServices.removeComment(taskId, 'task', comment.id);
      setCommentsList(commentsList.filter(x => x.id != comment.id));
      setCommentCount(commentsList.length - 1);
    }
    catch(e)
    {
      console.error(e);
    }
  }
  return (
    <>
      <ul className={`dropdown-menu bgleftpanelnewImp lightthemebgwhite radius_5 w-420 calednar-dropdown-modal d-block ${isFromPopover ? "position-relative" : ""}`} 
      aria-labelledby="CalendarDropdown" id="CalendarDropdownClose" 
      style={globalView == "spaceView" ? style : fromChat == true ? { width: "450px" } : {top : "0px"}}
        onMouseLeave={() => {
          if (globalView == "spaceView") { 
            setTaskId3dspase(0);
            setShowTaskInfo(false);
          }
        }}
      >
        <div className="small_modal_wrapper ">
          <div className="top_detail p-15 ">
            <div className="d-flex align-items-center">
              <div className="h30w30 comman-round-box with-bg rounded-circle bg-white-05" 
                 >
                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${taskCreatorImage}')` }}></div>
              </div>
              <div className="w100minus30">
                <div className="d-flex align-items-center">
                  <div className="fw-semibold  text-truncate title-fonts flex-grow-1  " 
                    ><input
                    type=""
                    id="task_title"
                    className="form-control text-truncate transparent py-0 c-font f-16 fw-semibold pe-0 ps-2 m-w100px"
                    placeholder={`${_l("l_task_name_placeholder")}`}
                    data-className="tooltip-main"
                    data-bs-placement="left"
                    value={taskName}
                    onChange={(e) => {
                      setTaskName(e.target.value);
                      let array = {
                       title: e.target.value,
                       color: '#b3b3b3',
                       date: moment(dayClick != "" ? dayClick : "").format("YYYY-MM-DD")
                     };
                     if(dayClick != "")
                     {
                      localStorage.setItem('realTimeTask', JSON.stringify(array));
                      window.dispatchEvent(new Event('realTimeTask'));
                     }
                    }}
                    onBlur={() => {
                      updateTaskData(undefined, undefined);
                    }}
                    disabled={
                      selectedTask
                        ? parseInt(selectedTask.is_allowed_to_edit) !=
                        1
                        : false
                    } /></div>
                  <input
                    className={`w-25 badge-white color-green c-font f-14 ms-2 fw-semibold p-1 radius_3 calednarwithborder text-center title-fonts ${showMyrTotal ? "" : "d-none"}`}
                    placeholder="$300"
                    type="text"
                    data-className="tooltip-main"
                    value={'€ ' + myr_total}
                    id="myr_total"
                    onChange={(e) => {
                      if (parseFloat(e.target.value) !== NaN) {
                        let TempTotal = e.target.value
                        setMyr_total(TempTotal.replaceAll('€', '').trim());
                      }
                    }}
                    onBlur={() => {
                      if (myr_total !== NaN && myr_total > 0) {
                        if (
                          selectedTask.task_assignee &&
                          providersArray &&
                          providersArray.length
                        ) {
                          updateTaskData(
                            undefined,
                            undefined,
                            myr_total
                          );
                        } else {
                          assignToProvider(
                            providerType,
                            providerValue,
                            providerDetail
                          );
                        }
                        handleProvider(providerType);
                      }
                    }} ref={(reference) => {
                      totalRef = reference;
                    }} />
                <div className="comman_action_icon with_separator_10">
                  <div className={`badge ${selectedTask && selectedTask.is_requested == 1 ? "badge-red" : ""} rounded-pill fw-light`}><span className={`p-0`}>{selectedTask && selectedTask.is_requested == '1' ? _l('l_request') :isString(selectedTask && selectedTask.id ? selectedTask.id:0)}</span>
                  </div>
                </div>
                <div className="comman_action_icon with_separator_10">
                <a href="#/"
                  id="commentOptionDropdown"
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    copyLink()
                  }}>
                    <div className="btn btn-white-10 btn-sm">
                    {_l("l_share")}
                    </div>
                </a>
                <ActionDropdown
                   actions={[
                    {
                      actionName: _l("l_copy_link"),
                      actionHandler: () => {
                        navigator.clipboard.writeText(`${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${selectedTask.hash}`);
                        dispatch(showMessage("sucess",_l("success"),_l("l_link_copied")));
                      },
                      actionIcon: "CopyIcon",
                    },
                    {
                      actionName: _l("l_send_to_email"),
                      actionHandler: () => {
                        setShowLink(true)
                      },
                      actionIcon: "EnvelopeSimpleIcon",
                    },
                    {
                      actionName: _l("l_open_link"),
                      actionHandler: () => {
                        window.open(`${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${selectedTask.hash}`);
                      },
                      actionIcon: "LinkSimpleIcon",
                    }
                  ]}          
                />         
              </div>
                <a href="#/" className="pe-0 with_separator_10" 
                onClick={()=>{ setShowTaskInfo(false)                   
                                setSelectedTaskId({})
                                if (setCheckListClear) {
                                  setCheckListClear();
                                }}}>
                  <XIcon className="HW16" /></a>
                </div>
              </div>
            </div>
            <div className="mid-detail d-flex align-items-center justify-content-between pt-3">
              <div className="d-flex align-items-center flex-wrap grid-row-gap-10">
                <div className=" c-font f-12  ps-0 ">
                  <a href="#/"
                    className="white-border-box custom-datepicker datepicker-input w-90"
                  >
                    <div className="d-flex align-items-center">
                    <CalendarBlankIcon className="HW16" />
                      <DatePicker
                        selected={dayClick != "" ? dayClick : dueDate}
                        onChange={(date) => {
                          setDueDate(date);
                          updateTaskData(undefined, date);
                        }}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy hh:mm"
                        locale={getDatePickerLocale()}
                        placeholderText={`${_l("l_duedate")}`}
                        className="c-font f-12"
                        readOnly={
                          selectedTask
                            ? parseInt(selectedTask.is_allowed_to_edit) < 1
                            : false
                        }
                        calendarStartDay={1}
                      ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                    </div>
                  </a>
                </div>
                <div className={`comman_action_icon with_separator_10 card-view-less-padding 
                            ${selectedTask && !(userType == "operator" && selectedTask.schedule_id > 0) &&
                    ((parseInt(selectedTask.is_allowed_to_edit) > 0 && selectedTask.is_requested == 0) ||
                      (parseInt(selectedTask.request_task_id) == 0 && selectedTask.is_requested == 1)) ? "" : "for-disabled"}
                          `}>
                  <div
                    className={`white-border-box  dropend d-flex align-items-center h32w32 action_icon with_bg calednarwithborder action_icon with_bg ${selectedTask &&
                      parseInt(selectedTask.is_allowed_to_edit) > 0 || dayClick != ""
                      ? ""
                      : "for-disabled"
                      } `}
                    id="TaskListStatusDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-className="tooltip-main"
                  >
                    <div className="d-flex">
                      <span
                        className={`c-dots c-10 ${selectedTask && selectedTask.is_requested == 1? "bg-warning" :taskStatus == 1
                          ? "in-progress-white"
                          : taskStatus == 4
                            ? "in-progress-blue"
                            : "done"
                          } rounded-circle `}
                      ></span>
                      {selectedTask &&
                        parseInt(selectedTask.is_allowed_to_edit) > 0  || dayClick != ""? (
                        <TaskListStatusDropdown
                          key={2}
                          setTaskStatus={changeTaskStatus}
                          is_task={selectedTask.is_requested == 1? 1:0}
                        />
                      ) : (
                        <>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="comman_action_icon with_separator_10 card-view-less-padding">
                  <div className="white-border-box d-flex align-items-center h32w32 action_icon with_bg calednarwithborder ">
                    <div
                      className="priority-checkbox text_wrap position-relative"
                    >
                      <input
                        onChange={(e) => {
                          changeTaskPriority(e);
                        }}
                        checked={taskPriority == 3 ? true : false}
                        type="checkbox"
                        className="absolute-input"
                        disabled={
                          selectedTask
                            ? parseInt(selectedTask.is_allowed_to_edit) < 1
                            : false
                        }
                      />
                      <div className="check_box">
                        <HighPriorityActiveIcon className="HW18 check_span_img active" />
                        <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comman_action_icon with_separator_10 me-2">
                  <div className="white-border-box d-flex align-items-center h32w32 action_icon with_bg calednarwithborder">
                    <a href="#/"
                      className=""
                      onClick={handlePinTask}
                    >
                      <PushPinIcon className={`HW16 ${!taskPinShow ? "" : "d-none"
                          }`} />
                      <PushPinFillIcon className={`stroke-transparent Push-Pin-fill HW16 ${taskPinShow ? "" : "d-none"
                          }`} />
                    </a>
                  </div>
                </div>
            <div className="comman_action_icon with_separator_10 ps-0">
              <div className="user-listing">
              <div className="d-flex two-part-div">
                <div className="left-part">
                  <div
                    className={`white-border-box dropdown d-flex h32w32 action_icon with_bg calednarwithborder ${(selectedTask &&
                      selectedTask.is_allowed_to_edit == 1) || dayClick != "" ? "" : "for-disabled"
                      }`}
                  >
                      <UncontrolledDropdown setActiveFromChild={true} direction="end" isOpen={openAssigneeDropdown} className="w-200">
                        <DropdownToggle className="border-0" onClick={() => setOpenAssigneeDropdown(!openAssigneeDropdown)}><UserCircleGearIcon className="HW20" weight="light" /></DropdownToggle>
                      {(selectedTask && taskId && 
                        parseInt(selectedTask.is_allowed_to_edit)) || dayClick != "" > 0 ? (
                                <AddAssigneeMainDropdownNew
                                  taskId={taskId}
                                  openAssigneeDropdown={
                                    openAssigneeDropdown
                                  }
                                  setOpenAssigneeDropdown={
                                    setOpenAssigneeDropdown
                                  }
                                  fromTaskcard="0"
                                  pageName='task'
                                  selectedId={taskAssigneeIds}
                                  handleProvider={handleProvider}
                                  myr_total={myr_total}
                                  isRequest = {selectedTask.is_requested == 1}
                                  selectedAssignee = {selectedAssigneeNew}
                                  setSelectedAssignee ={setSelectedAssigneeNew}
                                />
                      ) : (
                        <></>
                      )}
                      </UncontrolledDropdown>
                  </div>
                </div>
                <div className="right-part ps-2 w100minus20 d-flex align-items-center">
                        <div
                          className={`multi_image_wrapper dropdown`}
                          aria-expanded="false"
                        >
                          <div className={`multi_inner_wrapper d-flex position-relative px-2 ${taskAllAssignee.length === 0 ? "d-none" : ""}`}>
                            {taskAllAssignee && taskAllAssignee.length ? (
                              <>
                                {taskAllAssignee.map((assignee, index) => {
                                  if (index < 2) {
                                    return (
                                      <a href="#/"
                                        type="button"
                                        aria-expanded="false"
                                        className="comman-image-box h20w20 rounded-circle"
                                        key={index}
                                      >
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${assignee.profile_image}')`,
                                          }}
                                        ></div>
                                      </a>
                                    );
                                  }
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {taskAllAssignee && taskAllAssignee.length > 2 ? (
                              <>
                                <a href="#/"
                                  className="comman-image-box h20w20 with_overlay d-flex rounded-circle"
                                  type="button"
                                  id="TaskAssigneeDropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="unread_count m-auto">{`+${taskAllAssignee.length - 2
                                    }`}</div>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}
                            {taskAllAssignee && taskAllAssignee.length ? (
                              <GeneralListing
                                list={taskAllAssignee.map((item, index) => {
                                  return {
                                    name: item.full_name || item.name,
                                    image: item.user_image || item.profile_image,
                                    id: item.id,
                                  };
                                })}
                                handler={() => { }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div
                          className={`dropdown-center multi_inner_wrapper with-min-width px-2 d-flex with_separator_10 with_minus ${taskAssigneePills && taskAssigneePills.length >= 1
                              ? ""
                              : "d-none"
                            }`}
                        >
                          {taskAssigneePills && taskAssigneePills.length >= 1 ? (
                            taskAssigneePills.map((assignee, index) => {
                              if (index < 1)
                                return (
                                  <div
                                    className="badge rounded-pill text-nowrap list-toggle-view-none me-1"
                                    key={index}
                                  >
                                    <span className={` p-0 `}>
                                      {assignee.main_key == "my_task" ? "#" : ""}{_l(assignee.name)}
                                    </span>
                                  </div>
                                );
                            })
                          ) : (
                            <></>
                          )}
                          {taskAssigneePills && taskAssigneePills.length > 1 ? (
                            <a href="#/"
                              className="badge rounded-pill text-nowrap list-toggle-view-none me-1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={handleTaskAssigneeData}
                            >
                              <div className="unread_count m-auto c-font f-10">
                                +{taskAssigneePills.length - 1}
                              </div>
                            </a>
                          ) : (
                            <></>
                          )}
                          {taskAssigneePills && taskAssigneePills.length ? (
                            <GeneralListing
                              handler={() => { }}
                              list={
                                taskAssigneePills &&
                                taskAssigneePills.length &&
                                taskAssigneePills.map((item, index) => {
                                  return {
                                    name: item.name,
                                    id: index,
                                  };
                                })
                              }
                              isHash={true}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                </div>
              </div>
            </div>
            </div>
              </div>
            </div>
          </div>
          <div className="p-15 py-2">
            <div className="meeting-btn d-flex align-items-center" >
              <div className="video-img me-3">
              <VideoCameraIcon className="HW20" />
              </div>
               {(meetingcreate == true && MeetingUrl != "" && MeetingUrl != null) || (meetingUrl != null && meetingUrl != "") && dayClick == "" ?  <a href={meetingUrl != ""?meetingUrl:MeetingUrl} className="btn btn-white-10 btn-sm"
                target="_blank"
                  data-toggle="tooltip" onClick={() => {
                }}>
                {(googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 && dayClick == "") || (isOutlookCalendarActive == true && dayClick == "")  ? 
                thirdPartyMeetingLink  : 
                thirdPartyMeetingLink
                }
              </a> :  
              <a href="#/" 
                type="button"
                onClick={() => {
                localStorage.setItem('meetingProviderModalClose', JSON.stringify(true));
                window.dispatchEvent(new Event('meetingProviderModalClose'));
                setMeetingProvider(true);
                }} className="btn btn-white-10 btn-sm">{_l("l_create_meeting")}
                </a>
                }
              {dayClick == ""? <MeetingProviderModal meetingProvider={meetingProvider} HandleClose={()=>{setMeetingProvider(false);}}   selectedTask={selectedTask} getSelectedTaskCalendar={getSelectedTaskCalendar}/> : ""}
            </div>
            <div className="slider-part mb-2 pt-15">
              <div className="d-flex dropdown  align-items-center table-list-progress ps-0 " >
                <a href="#/" className=" comman_action_icon">
                  <div className="action_icon  rounded-circle on-hover-active-toggle-img">
                    <ListChecksIcon className="HW20" />
                  </div>
                </a>
                <div className="progress-slider ps-2 dropdown d-flex align-items-center w-100">
                  <div className="progress w_100"><div className={`progress-bar `} style={{ width: checklisProgressCount + "%" }} ></div></div>
                  <div className="progress-text c-font f-12 title-fonts">{checklisProgressCount ? checklisProgressCount : 0}%</div>
                </div>
              </div>
              <CheckedDropdown
                setOpenCheckList={setOpenCheckList}
                project_id={projectId}
                openCheckList={openCheckList}
                task_id={taskId}
                isSystemTask={
                  selectedTask && parseInt(selectedTask.is_allowed_to_edit) != 1
                    ? true
                    : null
                }
                isChecklistPermission={selectedTask && selectedTask.is_requested == 1 ? 1 : 0}
                isFromCalendarDropdown='1'
                updateChecklistFromCalendarDropdown={(id, finished) => {
                  let check_list = checkList.map((check) => {
                    if (id == check.id) {
                      check['finished'] = finished
                      return check;
                    }
                    return check;
                  })
                  setCheckList(check_list)
                }}
              />
              <div className="w100minus30 mt-2 ms-auto">
                <div className="form-check with-span  overflowscroll max-height-20vh w-100">
                  {
                    checkList && checkList.length ? (
                      checkList.map((checklist, index) => {
                        return (
                          <label className="form-check-label w-auto form-label d-block" key={index}>
                            <input className="form-check-input" type="checkbox" checked={checklist.finished == 1 ? true : false} disabled={selectedTask && parseInt(selectedTask.is_allowed_to_edit) == 1 ? false : true}
                              onChange={(e) => {
                                CustomerServices.updateChecklistItem(
                                  projectId,
                                  taskId,
                                  checklist.id,
                                  checklist.finished == 1 ? 0 : 1,
                                  checklist.description
                                ).then((res) => {
                                  if (res.status) {
                                    let tempChecklist = checkList.map((check) => {
                                      if (check.id == checklist.id) {
                                        check['finished'] = check.finished == 1 ? 0 : 1
                                        return check
                                      }
                                      return check;
                                    })
                                    const isScheduled = selectedTask.schedule_id != null && selectedTask.schedule_id != 0;
                                    changeTaskStatusFromChecklistItems(
                                      tempChecklist.map((x) => x.finished != 0),
                                      taskId,
                                      selectedTask.status,
                                      projectId,
                                      isScheduled
                                    );
                                    dispatch(
                                      getTaskList(
                                        localStorage.getItem("client_id"),
                                        localStorage.getItem("contact_id"),
                                        localStorage.getItem("selectedOffice"),
                                        moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                                        false,
                                        [],
                                        [{
                                          main_key: "date_range_filter",
                                          filter_key: "date_range_filter",
                                          title: moment(startdate).format("DD/MM/YYYY") +
                                          " - " +
                                          moment(endDate).format("DD/MM/YYYY"),
                                        },]
                                      )
                                    );
                                    setCheckList(tempChecklist);
                                    setDropdownToggle(true)
                                  }
                                });
                              }} />
                            <span className="d-block">{checklist.description}</span>
                          </label>
                        )
                      })
                    ) : (
                      <span className="opacity-50">{_l("l_no_checklist_found")}</span>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="right-part">
                <div className=" d-flex align-items-center">
                  <div className="dropup  d-flex align-items-center pe-2">
                    <a href="#/" className="before-dnone calednarwithborder bg-white-05 comman_action_icon d-flex py-1 radius_3 w-100 with_separator_10"
                      type="button"
                      id="imageLstAndUploadDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={(e) => {
                        setOpenAttachmentsDropdown(true);
                      }}>
                      <div className="d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green">
                        <div className="action_icon  position-relative">
                          <ImageIcon className="HW18" />
                        </div>
                        <span className="c-font f-12  opacity-50 d-flex justify-content-center flex-grow-1">{attchamentCount}</span>
                      </div>
                    </a>
                    {
                      <ImageUploadDropdown
                        setOpenAttachmentsDropdown={
                          setOpenAttachmentsDropdown
                        }
                        openAttachmentsDropdown={openAttachmentsDropdown}
                        task_id={taskId}
                        project_id={projectId}
                        updateImageCount={setAttchamentCount}
                        Request_task_converted={
                          selectedTask &&
                            parseInt(selectedTask.request_task_id) > 0 &&
                            selectedTask.is_requested == 1
                            ? true
                            : false
                        }
                        taskDetails={selectedTask}
                        pageName={pageName}
                      />
                    }
                  </div>
                  <div className="dropup  d-flex align-items-center me-2">
                    {
                      commentUnreadCount > 0 ?
                      <span className="rounded-pill badge-custom badge-small calednarwithborder">{commentUnreadCount}</span>
                      :
                      <></>
                    }
                    <a href="#/" className="before-dnone calednarwithborder bg-white-05 comman_action_icon d-flex py-1 radius_3 w-100 with_separator_10"
                      type="button"
                      id="writeComMentDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={(e) => {
                        getTaskComments();
                        dispatch(getMentionList(taskId, 'task'));
                      }}
                    >
                      <div className="d-flex align-items-center w-100 on-hover-active-toggle-img text-on-hover-active-green">
                        <div className="action_icon  position-relative">
                        <ChatCircleIcons className="HW18" />
                        </div>
                        <span className="c-font f-12  opacity-50 d-flex justify-content-center flex-grow-1">{commentCount}</span>
                      </div>
                    </a>
                    {
                      <CommentMinified
                        comments={commentsList}
                        disabled={ 
                          selectedTask &&
                          parseInt(selectedTask.request_task_id) > 0 &&
                          selectedTask.is_requested == 1}
                        handleAddComment={handleAddEditComment}
                        deleteComment={handleDeleteComment}
                        id={taskId}
                        loading={commentsLoading}
                        mentionList={mentionsList}
                        handleImagePreview={handleImagePreview}
                        pageName={pageName}
                        task={selectedTask}
                        showComments={showComments}
                        />
                    }
                  </div>
                </div>
              </div>
              <a href="#/" className="btn btn-primary btn-sm w-100 px-3 d-flex align-items-center justify-content-center" onClick={() => {
              if(selectedTask && dayClick == "")
              {
                if((selectedTask.id && $.isNumeric(taskId) != true) && selectedTask.provider != 'google_drive')
                {
                  if(isOutlookCalendarActive == true)
                  {
                    setShowTaskInfo(false);
                    outlookCalendar
                      .eventAddedIntoTask(
                        localStorage.getItem("selectedOffice"),
                        selectedTask.name,
                        selectedTask.id,
                        selectedTask.calendar_id,
                        selectedTask.dateadded,
                        selectedTask.startdate,
                        selectedTask.duedate,
                        selectedTask.TP_meeting_link
                      )
                      .then((res) => {
                        if (res.status == 1) {
                          dispatch(getSelectedTask(projectId, res.data, null, 1))
                          handler(
                            res.data,
                            true,
                            null,
                            selectedTask && selectedTask.request_task_id
                          );
                          dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"), "outlook_calendar", res.data));
                      }
                      });
                  } else {
                    if (googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 && dayClick == "") {
                      setShowTaskInfo(false);
                      GoogleCalendar.google_event_insert_into_task(
                        localStorage.getItem("selectedOffice"),
                        "project",
                        selectedTask.id,
                        selectedTask.calendar_id,
                      ).then((res) => {
                        if (res.status == 1) {
                          dispatch(getSelectedTask(projectId, res.data, null, 1, 0, "google_calendar"))
                          handler(
                            res.data,
                            true,
                            null,
                            selectedTask && selectedTask.request_task_id
                          );
                          dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"),  'google_calendar', res.data));
                        }
                      });
                    }
                  }
                } else 
                {
                  if (pageName == "calendar" ) {
                    setActiveTaskID(selectedTask && selectedTask.task_id ? selectedTask.task_id : taskId)
                  }
                  handler(
                    selectedTask.rel_id,
                    selectedTask && selectedTask.task_id ? selectedTask.task_id : taskId,
                    true,
                    null,
                    selectedTask && selectedTask.request_task_id
                  );
                  setShowTaskInfo(false);
                }
              } else {
                setShowTaskInfo(false);
              }
            }}>{dayClick != "" ? "Create New Task" : selectedTask && (($.isNumeric(selectedTask.id) == true || $.isNumeric(selectedTask.task_id) == true) || selectedTask.provider == 'google_drive') ?  _l("l_see_details") : "Convert Into Task To See The Details"}</a>
            </div>
          </div>
        </div>
      </ul>
      {
      showToast ? 
      <Toasts
          delay={15000}
          handler={() => {
            setShowToast(false);
            localStorage.setItem('meetingProviderModalClose', JSON.stringify(false));
            window.dispatchEvent(new Event('meetingProviderModalClose'));
          }}
          header={header}
          message={message}
          toastClass={"toast-with-btn"}
          cancelButtonText={_l("NO")}
          cancelButtonHandler={() => {
            setShowToast(false);
            localStorage.setItem('meetingProviderModalClose', JSON.stringify(false));
            window.dispatchEvent(new Event('meetingProviderModalClose'));
            if(dayClick != "")
            {
              localStorage.setItem('newTaskFlag', JSON.stringify(false));
              window.dispatchEvent(new Event('newTaskFlag'));
            } else {
            }
          }}
          confirmButtonText={_l("YES")}
          confirmButtonHandler={() => {
            if (isOutlookCalendarActive === true && dayClick == "") {
              outlookCalendar
                .eventAddedIntoTask(
                  localStorage.getItem("selectedOffice"),
                  selectedTask.id,
                  selectedTask.calendar_id
                )
                .then((res) => {
                  if (res.status == 1) {
                    dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"), "outlook_calendar", res.data));
                  }
                });
            } 
             if (googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type === 2 && dayClick == "") {
              GoogleCalendar.google_event_insert_into_task(
                localStorage.getItem("selectedOffice"),
                "project",
                selectedTask.id,
                selectedTask.calendar_id,
              ).then((res) => {
                if (res.status == 1) {
                  dispatch(get_refresh_request_data(moment(start_date).format("YYYY-MM-DD"), moment(end_date).format("YYYY-MM-DD"), selectCalIds, localStorage.getItem("selectedOffice"),  'google_calendar', 1));
                }
              });
            }
            localStorage.setItem('meetingProviderModalClose', JSON.stringify(false));
            window.dispatchEvent(new Event('meetingProviderModalClose'));
            setShowToast(false);
          }}
        /> 
      : <></>
      }
      {showImagePreview ? 
        <ImageViewer
          CloseCommentModal={closePreview}
          attachmentDate={attachments.date}
          attachmentURL={attachments.file_array[attachments.currentIndex].file_url}
          changeAttach={changeAttachment}
          handleAction={() => {}}
          isChat={false}
          showNextBtn={attachments.file_array.length > 0}
          showPrevBtn={attachments.file_array.length > 0}
        />
      : <React.Fragment></React.Fragment>
      }
      {showLink ? 
        <TaskShareLinkModal
          show={showLink}
          handleClose={handleCloseShareLink}
          handleShow={handleShowShareLink}
          link={`${process.env.REACT_APP_MYR_WEB_URL}/${docType}/${selectedTask.hash}`}
          data={{id: selectedTask.id, contacts: data.contacts}}
          docType={docType}
        />
      : <></>
      }
    </>
  );
};
export default CalendarDropdown;
