import React from 'react';
 

function TrashIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M13.8906 3.5H2.89062M6.89062 6.5V10.5M9.89062 6.5V10.5M12.8906 3.5V13C12.8906 13.1326 12.8379 13.2598 12.7442 13.3536C12.6504 13.4473 12.5232 13.5 12.3906 13.5H4.39062C4.25802 13.5 4.13084 13.4473 4.03707 13.3536C3.9433 13.2598 3.89062 13.1326 3.89062 13V3.5M10.8906 3.5V2.5C10.8906 2.23478 10.7853 1.98043 10.5977 1.79289C10.4102 1.60536 10.1558 1.5 9.89062 1.5H6.89062C6.62541 1.5 6.37105 1.60536 6.18352 1.79289C5.99598 1.98043 5.89062 2.23478 5.89062 2.5V3.5"/>
</svg>




		</React.Fragment>
	)
}

export default TrashIcon