import React from 'react';

function CustomSlackIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={` ${CommanHW}`}>
				<g clip-path="url(#clip0_7645_317452)">
					<path d="M6.30963 18.9045C6.30963 20.6426 4.9049 22.0474 3.16678 22.0474C1.42865 22.0474 0.0239258 20.6426 0.0239258 18.9045C0.0239258 17.1664 1.42865 15.7617 3.16689 15.7617H6.30951V18.9046L6.30963 18.9045ZM7.88111 18.9045C7.88111 17.1664 9.28584 15.7617 11.024 15.7617C12.7621 15.7617 14.1668 17.1664 14.1668 18.9046V26.7616C14.1668 28.4998 12.7621 29.9046 11.0238 29.9046C9.28596 29.9046 7.88111 28.4998 7.88111 26.7616V18.9045Z" fill="#E01E5A" />
					<path d="M11.0238 6.2857C9.2857 6.2857 7.88086 4.88098 7.88086 3.14285C7.88086 1.40473 9.28582 0 11.0238 0C12.7618 0 14.1667 1.40473 14.1667 3.14285V6.28582H11.0237L11.0238 6.2857ZM11.0238 7.88098C12.762 7.88098 14.1667 9.2857 14.1667 11.0238C14.1667 12.762 12.762 14.1667 11.0237 14.1667H3.14297C1.40473 14.1667 0 12.762 0 11.0237C0 9.28582 1.40473 7.88098 3.14285 7.88098H11.0238Z" fill="#36C5F0" />
					<path d="M23.6189 11.0238C23.6189 9.2857 25.0236 7.88086 26.7616 7.88086C28.4998 7.88086 29.9046 9.2857 29.9046 11.0238C29.9046 12.762 28.4998 14.1667 26.7616 14.1667H23.6189V11.0238ZM22.0474 11.0238C22.0474 12.762 20.6426 14.1667 18.9045 14.1667C17.1664 14.1667 15.7617 12.762 15.7617 11.0237V3.14297C15.7617 1.40473 17.1664 0 18.9045 0C20.6425 0 22.0473 1.40473 22.0473 3.14285V11.0237L22.0474 11.0238Z" fill="#2EB67D" />
					<path d="M18.9045 23.6189C20.6426 23.6189 22.0474 25.0236 22.0474 26.7616C22.0474 28.4998 20.6426 29.9046 18.9045 29.9046C17.1664 29.9046 15.7617 28.4998 15.7617 26.7616V23.6189H18.9045ZM18.9045 22.0474C17.1664 22.0474 15.7617 20.6426 15.7617 18.9045C15.7617 17.1664 17.1664 15.7617 18.9046 15.7617H26.7854C28.5236 15.7617 29.9284 17.1664 29.9284 18.9046C29.9284 20.6426 28.5236 22.0474 26.7854 22.0474H18.9045Z" fill="#ECB22E" />
				</g>
				<defs>
					<clipPath id="clip0_7645_317452">
						<rect width="30" height="30" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</React.Fragment>
	)
}

export default CustomSlackIcon
