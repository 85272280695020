import React from 'react';
 

function CustomOutlookIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_7645_317418)">
					<path d="M29.0625 6.56445H15.9375C15.42 6.56445 15 6.98445 15 7.50195C15 8.01945 15.42 8.43945 15.9375 8.43945H26.3306L20.5706 12.9188L16.44 10.292L15.435 11.8745L20.1225 14.8557C20.2726 14.9514 20.447 15.0022 20.625 15.002C20.8275 15.002 21.0319 14.9363 21.2006 14.8051L28.125 9.4182V21.5645H15.9375C15.42 21.5645 15 21.9845 15 22.502C15 23.0195 15.42 23.4395 15.9375 23.4395H29.0625C29.58 23.4395 30 23.0195 30 22.502V7.50195C30 6.98445 29.58 6.56445 29.0625 6.56445Z" fill="#1976D2" />
					<path d="M16.5356 1.15439C16.3219 0.976263 16.035 0.899388 15.765 0.955638L0.765001 3.76814C0.549939 3.80788 0.355616 3.92176 0.215842 4.08996C0.0760681 4.25817 -0.00030857 4.47006 9.3699e-07 4.68876V25.3138C9.3699e-07 25.7638 0.320626 26.1519 0.765001 26.2344L15.765 29.0469C15.8213 29.0581 15.8794 29.0638 15.9375 29.0638C16.155 29.0638 16.3669 28.9888 16.5356 28.8481C16.6418 28.7602 16.7272 28.6499 16.7859 28.5251C16.8445 28.4003 16.875 28.2641 16.875 28.1263V1.87626C16.875 1.59689 16.7513 1.33251 16.5356 1.15439Z" fill="#2196F3" />
					<path d="M8.4375 21.5645C5.85375 21.5645 3.75 19.0407 3.75 15.9395C3.75 12.8382 5.85375 10.3145 8.4375 10.3145C11.0213 10.3145 13.125 12.8382 13.125 15.9395C13.125 19.0407 11.0213 21.5645 8.4375 21.5645ZM8.4375 12.1895C6.88687 12.1895 5.625 13.8713 5.625 15.9395C5.625 18.0076 6.88687 19.6895 8.4375 19.6895C9.98813 19.6895 11.25 18.0076 11.25 15.9395C11.25 13.8713 9.98813 12.1895 8.4375 12.1895Z" fill="#FAFAFA" />
				</g>
				<defs>
					<clipPath id="clip0_7645_317418">
						<rect width="30" height="30" fill="white" />
					</clipPath>
				</defs>
			</svg>


		</React.Fragment>
	)
}

export default CustomOutlookIcon