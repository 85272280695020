import React from 'react';


function HourglassSimpleLowIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M209.8,198l-73.12-70L209.8,58l.09-.09A14,14,0,0,0,200,34H56a14,14,0,0,0-9.9,23.9l.09.09,73.12,70L46.2,198l-.09.09A14,14,0,0,0,56,222H200a14,14,0,0,0,9.9-23.9ZM54.56,49.38A2,2,0,0,1,56,46H200a2,2,0,0,1,1.45,3.38L128,119.69ZM128,136.31,163.19,170H92.81Zm73.84,72.46A1.91,1.91,0,0,1,200,210H56a2,2,0,0,1-1.45-3.38L80.28,182h95.44l25.72,24.62A1.91,1.91,0,0,1,201.84,208.77Z"></path></svg>
		</React.Fragment>
	)
}

export default HourglassSimpleLowIcon