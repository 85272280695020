import React from "react";
import Form from "react-bootstrap/Form";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { _l } from "../../hooks/utilities";

const UncontrolledDropdownWithCheckbox = ({ list, onCheckHandler }) => {
  return (
    <>
      <DropdownMenu
        container="body"
        className="dropdown-menu w-240px p-2 overflowscroll"
      >
        {list.map((x, index) => {
          return (
            <DropdownItem tag="a" className="p-2 radius_3" toggle={false} key={index}>
              <li className="d-flex align-items-center">
                <div className="form-check with-span d-flex align-items-center flex-grow-1">
                  <div className="d-flex align-items-center w-100">
                    <Form.Group className="form-check ps-0 w-100 d-flex align-items-center">
                      <Form.Label className="form-check-label mb-0 text-truncate w-100 d-flex align-items-center">
                        <Form.Check type="checkbox" onChange={(e) => {onCheckHandler(x, e.target.checked)}} />
                        <div className="c-font color-white f-14 ps-2 text-truncate title-fonts">
                          {x.title} 
                        </div>
                      </Form.Label>
                    </Form.Group>
                  </div>
                </div>
              </li>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </>
  );
};

export default UncontrolledDropdownWithCheckbox;
