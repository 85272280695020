import React from 'react';
 

function WalletIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M3.32494 3.43431C3.47497 3.28429 3.67845 3.2 3.89062 3.2H12.3906C12.5011 3.2 12.5906 3.11046 12.5906 3C12.5906 2.88954 12.5011 2.8 12.3906 2.8H3.89062C3.57237 2.8 3.26714 2.92643 3.0421 3.15147C2.81705 3.37652 2.69063 3.68174 2.69063 4V12C2.69063 12.3183 2.81705 12.6235 3.0421 12.8485C3.26714 13.0736 3.57237 13.2 3.89062 13.2H13.8906C14.0763 13.2 14.2543 13.1263 14.3856 12.995C14.5169 12.8637 14.5906 12.6857 14.5906 12.5V5.5C14.5906 5.31435 14.5169 5.1363 14.3856 5.00503C14.2543 4.87375 14.0763 4.8 13.8906 4.8H3.89062C3.67845 4.8 3.47497 4.71571 3.32494 4.56569C3.17491 4.41566 3.09062 4.21217 3.09062 4C3.09062 3.78783 3.17491 3.58434 3.32494 3.43431ZM3.09062 12V4.89443C3.31003 5.09069 3.59469 5.2 3.89062 5.2H13.8906C13.9702 5.2 14.0465 5.23161 14.1028 5.28787C14.159 5.34413 14.1906 5.42043 14.1906 5.5V12.5C14.1906 12.5796 14.159 12.6559 14.1028 12.7121C14.0465 12.7684 13.9702 12.8 13.8906 12.8H3.89062C3.67845 12.8 3.47497 12.7157 3.32494 12.5657C3.17491 12.4157 3.09062 12.2122 3.09062 12ZM12.2656 9C12.2656 9.34518 11.9858 9.625 11.6406 9.625C11.2954 9.625 11.0156 9.34518 11.0156 9C11.0156 8.65482 11.2954 8.375 11.6406 8.375C11.9858 8.375 12.2656 8.65482 12.2656 9Z"/>
</svg>

		</React.Fragment>
	)
}

export default WalletIcon