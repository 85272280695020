import React from "react";
import { _l } from "../../hooks/utilities";



export const contactintrostep = [
    {
      element: '.company-logo-box',
      title: _l("l_intro_space_name"),
      intro: <div> {_l("l_intro_space_description")}</div>,
      position: 'right',
    },
    {
      element: '.office-list-main',
      title: _l("l_intro_space_list"),
      intro: <div>{_l("l_intro_space_list_view_description")}</div>,
      position: 'right',
    },
    {
      element: '.provider-list ',
      title: _l("l_intro_filter_list"),
      intro: <div>{_l("l_intro_filter_list_description")}</div>,
      highlightClass: 'intro-js-left-60'
    },
    {
      element: '.c-top-calendar',
      title: _l("l_intro_calendar_filter"),
      intro: <div>{_l("l_intro_calendar_filter_description")}</div>,
    },
    {
      element: '.task-list-accordian',
      title: _l("l_intro_detailed_task_list_view"),
      intro: <div>{_l("l_intro_custom_accordian_main_description")}</div>,
    },
    {
      element: '.left-panel-crown',
      title: _l("l_intro_premium_estimate"),
      intro: <div>{_l("l_intro_remium_estimate_description")}</div>,
    },
    {
      element: '.left-panel-email',
      title: _l("l_intro_my_email"),
      intro: <div>{_l("l_intro_my_email_description")}</div>,
    },
    {
      element: '.left-panel-wallet',
      title: _l("l_intro_my_wallet"),
      intro: <div>{_l("l_intro_my_wallet_description")}</div>,
    },
    {
      element: '.left-panel-company-setting',
      title: _l("l_intro_company_setting"),
      intro: <div>{_l("l_intro_company_setting_description")}</div>,
    },
    {
      element: '.header-calendar',
      title: _l("l_intro_header_calendar"),
      intro: <div>{_l("l_intro_header_calendar_description")}</div>,
    },
    {
      element: '#HeaderDocumentLinkDropdown',
      title: _l("l_intro_header_document_link_dropdown"),
      intro: <div>{_l("l_intro_header_document_link_dropdown_description")}</div>,
    },
    {
      element: '.header-support',
      title: _l("l_intro_header_support"),
      intro: <div>{_l("l_intro_header_support_description")}</div>,
    },
    {
      element: '#headerUserDropdown',
      title: _l("l_intro_my_profile"),
      intro: <div>{_l("l_intro_my_profile_description")}</div>,
    },
    {
      element: '#BottomMenu',
      title: _l("l_intro_create_bottom_menu"),
      intro: <div>{_l("l_intro_create_bottom_menu_description")}</div>,
    },
    {
      element: '#Add_task',
      title: _l("l_intro_add_task"),
      intro: <div>{_l("l_intro_add_task_description")}</div>,
    },
    {
      element: '#Add_request',
      title: _l("l_intro_add_request"),
      intro: <div>{_l("l_intro_add_request_description")}</div>,
    },
    {
      element: '#Add_schedule',
      title: _l("l_intro_add_schedule"),
      intro: <div>{_l("l_intro_add_schedule_description")}</div>,
    },
    {
      element: '#Add_Benchmark',
      title: _l("l_intro_add_benchmark"),
      intro: <div>{_l("l_intro_add_benchmark_description")}</div>,
    },
    {
      element: '.task-list-card',
      title: _l("l_intro_task_list_card"),
      intro: <div>{_l("l_intro_task_list_card_description")}</div>,
    },
    {
      element: '.offcanvas-body',
      title: _l("l_intro_task_detail_modal"),
      intro: <div>{_l("l_intro_task_detail_modal_description")}</div>,
      highlightClass: 'intro-js-task-modal',
      position: 'left',
    },
    {
      element: '.intro-footer-my-space',
      title: _l("l_intro_footer_my_space"),
      intro: <div>{_l("l_intro_footer_my_space_description")}</div>,
    },
]

export const Staffintrosteps = [
  {
    element: '.company-logo-box',
    title: _l("l_intro_space_name"),
    intro: <div> {_l("l_intro_space_description")}</div>,
    position: 'right',
  },
  {
    element: '.office-list-main',
    title: _l("l_intro_space_list"),
    intro: <div>{_l("l_intro_space_list_view_description")}</div>,
    position: 'right',
  },
  {
    element: '.provider-list ',
    title: _l("l_intro_filter_list"),
    intro: <div>{_l("l_intro_filter_list_description")}</div>,
    highlightClass: 'intro-js-left-60'
  },
  {
    element: '.c-top-calendar',
    title: _l("l_intro_calendar_filter"),
    intro: <div>{_l("l_intro_calendar_filter_description")}</div>,
  },
  {
    element: '.task-list-accordian',
    title: _l("l_intro_detailed_task_list_view"),
    intro: <div>{_l("l_intro_custom_accordian_main_description")}</div>,
  },
  {
    element: '.left-panel-team',
    title: _l("l_intro_team"),
    intro: <div>{_l("l_intro_team_description")}</div>,
  },
  {
    element: '.left-panel-lead',
    title: _l("l_intro_leads"),
    intro: <div>{_l("l_intro_leads_description")}</div>,
  },
  {
    element: '.left-panel-email',
    title: _l("l_intro_my_email"),
    intro: <div>{_l("l_intro_my_email_description")}</div>,
  },
  {
    element: '.left-panel-company-setting',
    title: _l("l_intro_company_setting"),
    intro: <div>{_l("l_intro_company_setting_description")}</div>,
  },
  {
    element: '.header-calendar',
    title: _l("l_intro_header_calendar"),
    intro: <div>{_l("l_intro_header_calendar_description")}</div>,
  },
  {
    element: '#HeaderDocumentLinkDropdown',
    title: _l("l_intro_header_document_link_dropdown"),
    intro: <div>{_l("l_intro_header_document_link_dropdown_description")}</div>,
  },
  {
    element: '.header-support',
    title: _l("l_intro_header_support"),
    intro: <div>{_l("l_intro_header_support_description")}</div>,
  },
  {
    element: '#headerUserDropdown',
    title: _l("l_intro_my_profile"),
    intro: <div>{_l("l_intro_my_profile_description")}</div>,
  },
  {
    element: '#BottomMenu',
    title: _l("l_intro_create_bottom_menu"),
    intro: <div>{_l("l_intro_create_bottom_menu_description")}</div>,
  },
  {
    element: '#Add_task',
    title: _l("l_intro_add_task"),
    intro: <div>{_l("l_intro_add_task_description")}</div>,
  },
  {
    element: '#Add_request',
    title: _l("l_intro_add_request"),
    intro: <div>{_l("l_intro_add_request_description")}</div>,
  },
  {
    element: '#Add_schedule',
    title: _l("l_intro_add_schedule"),
    intro: <div>{_l("l_intro_add_schedule_description")}</div>,
  },
  {
    element: '#Add_Benchmark',
    title: _l("l_intro_add_benchmark"),
    intro: <div>{_l("l_intro_add_benchmark_description")}</div>,
  },
  {
    element: '.task-list-card',
    title: _l("l_intro_task_list_card"),
    intro: <div>{_l("l_intro_task_list_card_description")}</div>,
  },
  {
    element: '.offcanvas-body',
    title: _l("l_intro_task_detail_modal"),
    intro: <div>{_l("l_intro_task_detail_modal_description")}</div>,
    highlightClass: 'intro-js-task-modal',
    position: 'left',
  },
  {
    element: '.intro-footer-my-space',
    title: _l("l_intro_footer_my_space"),
    intro: <div>{_l("l_intro_footer_my_space_description")}</div>,
  },
]


export const steps = [
{
  element: '.ai-welcome-chat',
  title: _l("l_ai_chat_title"),
  intro: <div> {_l("l_ai_chat_description")}</div>,
  tooltipClass: 'myTooltipClass',
  hideNext: true   
},
{
  element: '.ai-welcome-category',
  title: _l("l_ai_chat_title"),
  intro: <div> {_l("l_ai_chat_description")}</div>,
  position: 'top',
  tooltipClass: 'myTooltipClass',
  hideNext: true,   
  autoPosition:true,
}
]
export const stepsForStaff = [
{
  element: '.ai-welcome-chat',
  title: _l("l_ai_chat_title"),
  intro: <div> {_l("l_ai_chat_description")}</div>,
  tooltipClass: 'myTooltipClass',
  hideNext: true   
}
]
