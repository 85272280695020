import React, { useEffect, useState } from "react";
import {
  CardListFullHeight,
  DefaultListView,
  leftPanelCategoryListToggle,
  showLeftPanel,
} from "../hooks/utilities";
import documentsServices from "../services/documents-services";
import { EQUIPMENTS_CATEGORY_TOPICS } from "../actions/action-type";
import { useDispatch } from "react-redux";
import EquipmentListing from "../components/EquipmentListing";
const EquipmentView = () => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );
  const dispatch = useDispatch();
  
  useEffect(() => {
    showLeftPanel(true, true);
    CardListFullHeight();
    DefaultListView();
    document.addEventListener('mouseup', leftPanelCategoryListToggle);
    return () => {
      document.removeEventListener('mouseup', leftPanelCategoryListToggle);
    }
  }, []);

  useEffect(() => {
    documentsServices.getEquipmentCategoryTopics().then((res) => {
        if(res.status == 1)
        {
            let data = res.data;
          dispatch({
            type: EQUIPMENTS_CATEGORY_TOPICS,
            payload: { data }
          })
        } else 
        {
            let data = [];
          dispatch({
            type: EQUIPMENTS_CATEGORY_TOPICS,
            payload: { data }
          })
        }
    })
  }, []);

  return (
    <>
      <div className="d-flex flex-column h-100">
        <EquipmentListing projectId={projectId} />
      </div>
      
    </>
  );
};

export default EquipmentView;
