import React from 'react';
 

function FileSearchIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15372_259128)">
<g clipPath="url(#clip1_15372_259128)">
<path d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.5 2V5.5H13"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.98749 10.4873L9.98749 11.4873"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.75 11C8.7165 11 9.5 10.2165 9.5 9.25C9.5 8.2835 8.7165 7.5 7.75 7.5C6.7835 7.5 6 8.2835 6 9.25C6 10.2165 6.7835 11 7.75 11Z"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15372_259128">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15372_259128">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default FileSearchIcon