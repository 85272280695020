import React from 'react';

function CustomOneDriveIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9714_404149)">
<path d="M11.2095 2.1808C10.8291 2.10595 10.4423 2.06828 10.0547 2.0683C7.03395 2.0683 4.52947 4.32082 4.13285 7.23447C1.79051 7.62816 0 9.67044 0 12.123C0 14.7655 2.07855 16.9314 4.6865 17.0724C4.57025 16.5953 4.51158 16.1061 4.51172 15.6152C4.51172 14.2076 5.00109 12.8306 5.88961 11.738C6.4735 11.02 7.21277 10.4441 8.04029 10.0519C8.565 6.52296 11.4294 3.7476 15.0001 3.35783L16.7579 1.74996L15.0001 0.14209C13.5579 0.403535 12.2344 1.11147 11.2095 2.1808Z" fill="#007CAA"/>
<path d="M9.71191 11.2207C9.71191 11.2552 9.71221 11.2898 9.71279 11.3244C7.74539 11.7602 6.26953 13.5186 6.26953 15.6152C6.26953 18.0384 8.24092 20.0097 10.6641 20.0097H15L18.5156 12.5696L15 5.12939C12.0154 5.5508 9.71191 8.1216 9.71191 11.2207Z" fill="#007CAA"/>
<path d="M26.4234 11.297C26.0357 8.76873 23.846 6.82617 21.2109 6.82617C20.8904 6.82617 20.5713 6.85529 20.257 6.91307C19.103 5.73439 17.5311 5.06836 15.8643 5.06836C15.5709 5.06836 15.2825 5.08945 15 5.12936V20.0098H25.6055C28.0286 20.0098 30 18.0384 30 15.6152C30 13.4715 28.4572 11.6813 26.4234 11.297ZM20.8502 5.07768C21.588 5.03977 22.327 5.11863 23.0402 5.31135C22.2738 2.27965 19.5233 0.0292969 16.2568 0.0292969C15.831 0.0292969 15.4107 0.0676758 15 0.142148V3.35783C15.2839 3.32684 15.5722 3.31049 15.8643 3.31049C17.7065 3.31055 19.4467 3.93246 20.8502 5.07768Z" fill="#015E82"/>
</g>
<defs>
<clipPath id="clip0_9714_404149">
<rect width="30" height="20.0391" fill="white"/>
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default CustomOneDriveIcon
