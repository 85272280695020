import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LeftPanel from "../components/LeftPanel";
import MyEmailList from "../components/MyEmail/MyEmailList";
import { showLeftPanelNew } from "../hooks/utilities";
import { useSelector } from "react-redux";
import ConnectEmails from "../components/MyEmail/ConnectEmails";

const MyEmail = () => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const {leftPanelTab, emailTokenVaild} = useSelector((state) => state.customer);
  const [projectId, setProjectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );
  useEffect(() => {
    showLeftPanelNew();
  }, []);
  const [allMAilMessages,setAllMAilMessages] = useState([]);
  const [allImportantMessages,setAllImportantMessages] = useState([]);
  const staff_id = localStorage.getItem("staff_id");
  const [showMailModal, setShowMailModal] = useState(false);
  const [synchronizedMail, setSynchronizedMail] = useState(false);

  return (
    <>
      <Header projectId={projectId} setShowMailModal={setShowMailModal} showMailModal={showMailModal} setSynchronizedMail={setSynchronizedMail} pageName="MyEmail" />
        <LeftPanel setSelectedProject={setProjectId} pageName="MyEmail" projectId={projectId} allMAilMessages={allMAilMessages} allImportantMessages={allImportantMessages} hideMailSyncBtn={staff_id == 2 || staff_id == 1557 ? false : false} setShowMailModal={setShowMailModal} synchronizedMail={synchronizedMail} setSynchronizedMail={setSynchronizedMail}  />
        {leftPanelTab == "my-email" && emailTokenVaild ? 
          <>
          <ConnectEmails />
          </>
          :
        <MyEmailList setAllMAilMessages={setAllMAilMessages} setAllImportantMessages={setAllImportantMessages} synchronizedMail={synchronizedMail} />}
        <Footer pageName={"MyEmail"}  />
    </>
  );
};

export default MyEmail;
