import React from "react";
import { _l } from "../../../../hooks/utilities";
import { useDispatch } from "react-redux";
import {
  showConfirmation,
  toastCloseAction,
} from "../../../../actions/messages";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import XIcon from "../../../../assets/icons/XIcon";
 

const SetupScreen = ({
  headerText,
  leftListingData,
  rightPortionJSX,
  handleSelectListingElement,
  handleClose,
  handleDelete,
  activeRecord,
  allocation,
  tag
}) => {
  const dispatch = useDispatch();

  const deleteConfirmation = () => {
    dispatch(
      showConfirmation(
        _l("l_delete"),
        _l("l_confirm_delete_quantity"),
        _l("l_delete"),
        _l("l_cancel"),
        () => {},
        () => {
          dispatch(toastCloseAction());
          handleDelete();
        }
      )
    );
  };

  return (
    <div className="create-of-floor-main h-100 row g-0 overflow-hidden ">
      <div className=" col-xl-12 flex-grow-1 mt-0 position-relative d-flex  flex-column h-100">
        <div className="radius_5 w-100 d-flex flex-column flex-grow-1 h-100 ">
          <div class="form-control d-flex align-items-center mb-3 justify-content-between">
            <div class="c-font f-14 fw-semibold text-truncate title-fonts color-white-60">
              {headerText}
            </div>

            <div class="align-items-center d-flex justify-content-between comman_action_icon">
              {handleDelete && (
                <div className="with_separator_10 pe-0">
                  <a
                    href="#/"
                    className="action_icon with_bg h32w32"
                    onClick={deleteConfirmation}
                  >
                    <TrashIcon className="HW18" />
                  </a>
                </div>
              )}
              {allocation ? (
                <div class="c-font f-14 text-end fw-semibold text-truncate title-fonts color-white-60 d-flex">
                  {allocation}
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {tag ? (
                <div className="dropdown-center multi_inner_wrapper with-min-width d-flex me-10px with_minus mx-2">
                  <div
                    className="badge badge-done rounded-pill text-nowrap list-toggle-view-none h32wauto c-font f-12 d-flex align-items-cente"
                     
                     
                     
                  >
                    <span
                      class={`text-truncate max-width75  d-block p-0 my-auto`}
                    >
                      {tag}
                    </span>
                  </div>
                   
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <div className="with_separator_10 pe-0">
                <a
                  href="#/"
                  className="action_icon with_bg h32w32"
                  onClick={handleClose}
                >
                  <XIcon className="HW18" />
                </a>
              </div>
            </div>
          </div>
          <div className="sub-floor flex-grow-1 h_100">
            <div className="row m-0 h-100 g-0">
              {leftListingData.length ? (
                <div className="col-xl-4 border-right h-100 overflow-hiiden-auto pt-10px pe-10px">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="input-label no-asterisk">
                      {_l("l_equipments")}
                    </span>
                  </div>
                  <ul className="overflow-auto comman-verticle-tab mt-2 row gy-2 gx-0 floor-step-wrapper pb-15px">
                    {leftListingData.map((item, key) => {
                      return (
                        <li
                          key={key}
                          className="d-flex align-items-center justify-content-between w-100 floor-step-box-main"
                          onClick={(e) => handleSelectListingElement(item)}
                        >
                          <div
                            className={`d-flex align-items-center justify-content-between form-control flex-grow-1 floor-step-box on-hover-bg-white-05 cursor-pointer ${
                              activeRecord && activeRecord.id == item.id
                                ? "active"
                                : ""
                            }`}
                          >
                            <div className="flex-grow-1 ps-0 text-truncate">
                              <span>{item.label}</span>
                            </div>
                            {item.count ? (
                              <div>
                                <span class="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                  <span class="m-auto p-0">{item.count}</span>
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <div
                className={`${
                  leftListingData.length
                    ? "col-xl-8 h-100 overflow-hiiden-auto pt-10px ps-10px"
                    : "col-xl-12"
                }`}
              >
                {rightPortionJSX}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupScreen;
