import React from "react";
import { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import DeleteDropdown from "../Dropdowns/DeleteDropdown";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import Toasts from "../Toasts/Toasts";
import { useParams } from "react-router-dom";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
const LinkComponent = ({ task_id, projectId, toastClose, closeAllTost, searchLink = '', isExternal, readOnly = false }) => {
  const [linkList, setLinkList] = useState([]);
  const [linkDefaultList, setLinkDefaultList] = useState([]);
  const [newLink, setNewLink] = useState("");
  const [linkInputs, setlinkInputs] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [linkId, setLinkId] = useState(0);
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const contact_image = localStorage.getItem("contact_image");
  const { id: hash_id } = useParams();
  const dispatch = useDispatch();
  const toastdata = useSelector((state) => state.customer.toastClose);
  const addNewLink = (e) => {
    if (e.detail === 1 || e.keyCode === 13 ) {
      let pattern = newLink.match(
        /^((http|https)\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      let data = linkList.map((list)=>{return list.content})
      if(data.includes(`${pattern}`))
      {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l('l_please_enter_valid_link'))
        );
        setNewLink("");
      } else {
        if (newLink.trim() && pattern) {
          CustomerServices.addLinkComment(
            task_id,
            projectId,
            newLink.trim(),
            1
          ).then((res) => {
            if (res.status) {
              setLinkList((linkList) => [
                ...linkList,
                {
                  content: newLink.trim(),
                  id: res.data.comment_id,
                  profile_image: contact_image,
                  is_allowed_to_edit: 1
                },
              ]);
              setlinkInputs({
                ...linkInputs,
                [`${res.data.comment_id}`]: newLink.trim(),
              });
              setNewLink("");
            }
          });
        } else {
          setNewLink("");
          setToastHeader(_l('l_error'));
          setToastMessage(_l('l_please_enter_valid_link'));
          setConfirmButtonText("");
          setCancelButtonText("");
          setToastClass("unsucess");
          setShowToast(true);
        }
      }
    }else
    {
      setNewLink("");     
    }
  };
  const handleEditLink = (id, content) => {
    let pattern = content.match(
      /^((http|https)\:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (pattern) {
      CustomerServices.editLinkComment(
        task_id,
        projectId,
        content.trim(),
        1,
        id
      ).then((res) => {
        if (res.status) {
          setlinkInputs({
            ...linkInputs,
            [`${id}`]: content.trim(),
          });
          setNewLink("");
          jquery(`#link_item_${id}`).attr("disabled", true);
        }
      });
    } else {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l('l_please_enter_valid_link'))
      );
      jquery(`#link_item_${id}`).focus();
    }
  };
  const handleDeleteLink = (id) => {
    CustomerServices.removeLink(projectId, id).then((res) => {
      if (res.status) {
        setLinkList(linkList.filter((link) => link.id !== id));
        dispatch(toastCloseAction());
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_you_want_to_delete_this_link"),
        _l("l_delete"),
        _l("l_cancel"),
        undefined,
        () => { handleDeleteLink(id)}
      )
    );
  }
  useEffect(() => {
    setLinkDefaultList([])
    setNewLink("");
    if (task_id) {
      CustomerServices.getLinks(task_id, projectId, '', (isExternal || hash_id) ? hash_id : false).then((res) => {
        const links = res.data;
        let inputs = [];
        setLinkList([]);
        links.forEach((item) => {
          setLinkList((linkList) => [...linkList, item]);
          setLinkDefaultList((linkList) => [...linkList, item]);
          inputs[item.id] = item.content;
        });
        setlinkInputs(inputs);
      });
    }
  }, [task_id]);
  useEffect(() => {
    if (searchLink != "") {
      let linkTemp = linkDefaultList.filter(
        (link) =>
          link.content &&
          link.content.toLowerCase().includes(searchLink.toLowerCase())
      )
      setLinkList(linkTemp);
    } else {
      setLinkList(linkDefaultList);
    }
  }, [searchLink]);
  return (
    <>
      <div className={`comment-input-box ${readOnly ? 'd-none' : ''}`}>
        <div className="d-block align-items-end">
          <input
            className="form-control height-45 border-0"
            type="text"
            value={newLink}
            onChange={(e) => {
              setNewLink(e.target.value);
            }}
            placeholder={`${_l('l_add_links')}`}
            aria-label="default input example"
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                addNewLink(e);
              }
            }}
          />
          <a href="#/" className="send-arrow-btn" onClick={(e)=>addNewLink(e)} >
            <ArrowRightIcon className="HW18 lt-black" />
          </a>
        </div>
      </div>
      <div className="task-checklist-wrapper">
        {linkList.map((link, index) => {
          return (
            <div key={index} className="note-list border-bottom">
              <div className="d-flex align-items-center justify-content-between">
                <div className="left-link">
                  <div className="d-flex align-items-center position-relative">
                    <div
                      data-bs-toggle="tooltip"
                      className="comman-image-box h25w25 rounded-circle"
                    >
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url('${link.profile_image}')`,
                        }}
                      ></div>
                    </div>
                    <a href={linkInputs[link.id]} target="_blank" className="flex-grow-1">
                      <input
                        id={`link_item_${link.id}`}
                        value={linkInputs[link.id]}
                        className=" w100minus40 text_wrap color-green link-text bg-transparent border-0"
                        disabled={true}
                        onChange={(e) => {
                          setlinkInputs({
                            ...linkInputs,
                            [`${link.id}`]: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          handleEditLink(link.id, e.target.value);
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className={`right-checklist-action dropend ${link.is_allowed_to_edit == 1 ? '' : 'for-disabled'}`}>
                  <a href="#/"
                    className="line-height-0 "
                    type="button"
                    id="commentOptionDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <DotsThreeVerticalIcon className="HW18" />
                  </a>
                  {link.is_allowed_to_edit == 1 ?
                    <DeleteDropdown
                      handleEdit={() => {
                        jquery(`#link_item_${link.id}`).attr("disabled", false);
                        jquery(`#link_item_${link.id}`).focus();
                      }}
                      edit={true}
                      handleDelete={(id) => {
                        handleDelete(link.id)
                      }}
                    /> : <></>}
                </div>
              </div>
            </div>
          );
        })}
        {
          linkList && linkList.length == 0 ?
            (
              <CommanPlaceholder imgType="no-link" placeholderText = {_l('l_no_links')} />
            )
            :
            (
              <></>
            )
        }
      </div>
      {showToast && !toastdata ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
            setLinkId(0);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
            setLinkId(0);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            handleDeleteLink(linkId);
            setShowToast(false);
            setLinkId(0);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default LinkComponent;
