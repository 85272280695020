import React from 'react';
 

function NoInprogressTask(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			
			<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M30.2598 30.1392L48.163 47.8607"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M48.163 30.1392L30.2598 47.8607"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M10.4295 38.2639L5.58337 42.9858L1 38.2305"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M67.8477 40.2425L72.6269 35.3008L77.4349 39.994"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M36.6484 67.833L41.332 72.4498L36.7965 76.9997"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M38.6892 10.8883L33.6328 5.91784L38.5077 1"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M33.8477 5.89415L34.2252 5.84159C42.9137 4.57326 51.7542 6.74375 58.8674 11.8916C65.9805 17.0395 70.8054 24.7588 72.3161 33.4084"  strokeMiterlimit="10" />
				<path d="M5.59737 42.766C5.47098 41.5753 5.40721 40.3788 5.40625 39.1815C5.40539 31.4879 8.03646 24.0254 12.8624 18.0336C17.6884 12.0418 24.4189 7.88111 31.936 6.24268"  strokeMiterlimit="10" />
				<path d="M40.8779 72.8278C40.29 72.8613 39.6974 72.8757 39.1 72.8757C31.2078 72.8745 23.5662 70.1042 17.5069 65.0475C11.4475 59.9908 7.35468 52.9682 5.94141 45.2036"  strokeMiterlimit="10" />
				<path d="M72.609 35.6113C73.5229 44.2646 71.0575 52.9364 65.728 59.8147C60.3984 66.693 52.6171 71.2458 44.0098 72.5218"  strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default NoInprogressTask