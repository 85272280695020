import React, { useState } from 'react'
import Form from "react-bootstrap/Form";
import { _l } from '../../hooks/utilities';
import XIcon from '../../assets/icons/XIcon';
import UploadSimpleIcon from '../../assets/icons/UploadSimpleIcon';
 

const AddEmployeeDocuments = ({ imagePreviews, setImagePreviews }) => {
    const [imagename, setimagename] = useState("");
    return (
        <React.Fragment>
            {
                imagePreviews && Object.keys(imagePreviews).map((inputName, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Form.Group className="col-xl-6 c-input-box pb-3 file-upload position-relative">
                                <Form.Label className="input-label no-asterisk ">
                                    {_l(inputName)}
                                </Form.Label>
                                <div className='d-flex align-items-center'>
                                    <div className='flex-grow-1'>
                                        <div className="form-control file-upload position-relative">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <input
                                                    className="  hidden-input"
                                                    type="file"
                                                    accept="image/*"
                                                    value=""
                                                    onChange={(e) => {
                                                        let previewArray = [];
                                                        Array.from(e.target.files).map((img) => {
                                                            setimagename(img.name)
                                                            previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
                                                        });
                                                        setImagePreviews({...imagePreviews, [inputName]: { previewArray: previewArray, file: e.target.files[0]} })
                                                    }}
                                                />
                                                <span>
                                                    <UploadSimpleIcon className="HW18" />
                                                    <span className="color-white-60 ps-2">
                                                        {_l("l_drag_and_drop_or_browse")}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className='file-upload-bg-abs'></div>
                                        </div>
                                    </div>
                                    <div className="mt-auto">
                                        {imagePreviews[inputName] && imagePreviews[inputName].previewArray ? (
                                            imagePreviews[inputName].previewArray.map((preview, index) => {
                                                if (preview['url']) {
                                                    return (
                                                        <div className="ps-3 upload-image-preview" key={index}>
                                                            <div className="border comman-image-box h75w75 mx-auto p-2 radius_3 upload-image"
                                                            
                                                             >
                                                                <div
                                                                    className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                                                                    style={{ backgroundImage: `url(${preview.url})` }}
                                                                ></div>
                                                                <a href="#/" onClick={() =>{setimagename('')
                                                                 setImagePreviews({ ...imagePreviews, [inputName]: {} })}}
                                                                    className="upload-close"
                                                                >
                                                                    <XIcon className="HW10" />
                                                                </a>
                                                            </div>
                                                            <div className="image-text d-flex flex-column justify-content-center d-none">
                                                                <div className="text-truncate c-font f-13">
                                                                    {preview['name']}{" "}
                                                                </div>
                                                            </div>
                                                             
                                                        </div>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </Form.Group>
                        </React.Fragment>
                    )
                })
            }
             
        </React.Fragment>
    )
}

export default AddEmployeeDocuments