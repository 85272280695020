import React from 'react';

function EmailRevokeIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
			<path d="M3 6H27M3 6V23C3 23.2652 3.10536 23.5196 3.29289 23.7071C3.48043 23.8946 3.73478 24 4 24H15M3 6L15 15L27 6M27 6V13.5M27.1562 18.8438L19.8438 26.1562M27.1562 26.1562L19.8438 18.8438" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</React.Fragment>
	)
}

export default EmailRevokeIcon