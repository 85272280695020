

import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;
const outlook_calendar_events = async() => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=get_calendar_events";
    let form = new FormData();
    return apiService(endPoint, "POST", true, form);
  };
  const oauthUrl = async(state, task_id, project_id, is_allow_all_spaces, date) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=oauth_url";
    let form = new FormData();
    form.append('state', state);
    form.append('task_id', task_id);
    form.append('project_id', project_id);
    form.append('is_allow_all_spaces', is_allow_all_spaces);
    form.append('date', date);
    return apiService(endPoint, "POST", true, form);
  };
  const editEvent = async(event_id, calendar_id, project_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=edit_event";
    let form = new FormData();
    form.append('calendar_id', calendar_id);
    form.append('event_id', event_id);
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const eventAddedIntoTask = async(project_id, name, event_id, calendar_id, dateadded, startdate, duedate, meetingLink, status, priority, pin) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=event_added_into_task";
    let form = new FormData();
    form.append('project_id', project_id);
    form.append('calendar_id', calendar_id);
    form.append('event_id', event_id);
    form.append('name', name);
    form.append('dateadded', dateadded);
    form.append('startdate', startdate);
    form.append('duedate', duedate);
    form.append('meetingLink', meetingLink);
    form.append('status', status);
    form.append('priority', priority);
    form.append('pin', pin);
    return apiService(endPoint, "POST", true, form);
  };
  const user_authentication = async(code, state, project_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=user_authentication";
    let form = new FormData();
    form.append('code', code);
    form.append('state', state);
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const ms_teem_authentication = async(code, state, project_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=ms_teem_authentication";
    let form = new FormData();
    form.append('code', code);
    form.append('state', state);
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const change_topic_status = async(key, status, project_id, main_key) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=change_topic_status";
    let form = new FormData();
    form.append('key', key);
    form.append('status', status);
    form.append('project_id', project_id);
    form.append('main_key', main_key);
    return apiService(endPoint, "POST", true, form);
  };
  const get_single_event = async(calendar_id, event_id, project_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=get_single_event";
    let form = new FormData();
    form.append('calendar_id', calendar_id);
    form.append('event_id', event_id);
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const get_calendars = async() => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=get_calendars";
    let form = new FormData();
    return apiService(endPoint, "POST", true, form);
  };
  const revoke = async(project_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=revoke";
    let form = new FormData();
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const get_events = async(startDate, endDate, calendar_id, project_id, taskID) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=refresh_request";
    let form = new FormData();
    form.append('startDate', startDate);
    form.append('endDate', endDate);
    form.append('calendar_id', calendar_id);
    form.append('project_id', project_id);
    form.append('taskID', taskID);
    return apiService(endPoint, "POST", true, form);
  };
  const is_calendar_connected = async(project_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=is_calendar_connected";
    let form = new FormData();
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const createTask = async(
    project_id,
    task_id,
    task_name,
    dueDate,
    total,
    description) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=create_task";
    let form = new FormData();
    form.append('project_id', project_id);
    form.append('task_id', task_id);
    form.append('task_name', task_name);
    form.append('dueDate', dueDate);
    form.append('total', total);
    form.append('description', description);
    return apiService(endPoint, "POST", true, form);
  };
  const updateEvent = async(
    task_id,
    task_name,
    start_date,
    dueDate,
    calendar_id,
    project_id)=> {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=update_event";
    let form = new FormData();
    form.append('task_id', task_id);
    form.append('task_name', task_name);
    form.append('dueDate', dueDate);
    form.append('start_date', start_date);
    form.append('calendar_id', calendar_id);
    form.append('project_id', project_id);
    return apiService(endPoint, "POST", true, form);
  };
  const getMeetingLink = async(
    task_id, project_id, calendar_id) => {
    const endPoint = API_URL + "mod=outlook_calendar_integration&act=get_meeting_link";
    let form = new FormData();
    form.append('task_id', task_id);
    form.append('project_id', project_id);
    form.append('calendar_id', calendar_id);
    return apiService(endPoint, "POST", true, form);
    }
    const getAssigneeCal = async(
      task_id, calendar_id, project_id) => {
      const endPoint = API_URL + "mod=outlook_calendar_integration&act=contacts";
      let form = new FormData();
      form.append('task_id', task_id);
      form.append('calendar_id', calendar_id);
      form.append('project_id', project_id);
      return apiService(endPoint, "POST", true, form);
      }
      const setAssignee = async(
        task_id,
        name,
        email,
        calendar_id,
        project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=set_assignee";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('name', name);
        form.append('email', email);
        form.append('calendar_id', calendar_id);
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };

      // #####################################################################################################
                        // ZOOM
      const oauthUrlZoom = async(task_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=connect";
        let form = new FormData();
        form.append('task_id', task_id);
        return apiService(endPoint, "POST", true, form);
      };
      const zoomAthentication = async(task_id,code) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=zoom_authentication";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('code', code);
        return apiService(endPoint, "POST", true, form);
      };
      const zoomMeetingLinkAddedIntoTask = async(task_id, meeting_link, calendar_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=create_event_with_zoom_meeting";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('meeting_link', meeting_link);
        form.append('calendar_id', calendar_id);
        return apiService(endPoint, "POST", true, form);
      };

      const zoomRevoke = async() => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=zoom_revoke";
        let form = new FormData();
        return apiService(endPoint, "POST", true, form);
      };
      //##############################################################################################################
                                  // MS_Temas
      // const msTeamAuthentication = async(task_id) => {
      //   const endPoint = API_URL + "mod=outlook_calendar_integration&act=user_authentication";
      //   let form = new FormData();
      //   form.append('task_id', task_id);
      //   return apiService(endPoint, "POST", true, form);
      // };
      const MsTeamMeetingLinkAddedIntoTask = async(task_id, meeting_link, calendar_id, project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=create_event_with_ms_meeting";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('meeting_link', meeting_link);
        form.append('calendar_id', calendar_id);
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      const msTeamRevoke = async(project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=ms_team_revoke";
        let form = new FormData();
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      //###################################################################################################################
                                // Google Meet
      const oauthUrlGoogleMeet = async(task_id, project_id, date) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=createAuthUrl";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('project_id', project_id);
        form.append('date', date);
        return apiService(endPoint, "POST", true, form);
      };
      const googleMeetAuthentication = async(state, code, project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=google_meet_authentication";
        let form = new FormData();
        form.append('state', state);
        form.append('code', code);
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      const googleMeetingLinkAddedIntoTask = async(task_id, meeting_link, calendar_id, project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=create_event_with_google_meet";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('meeting_link', meeting_link);
        form.append('calendar_id', calendar_id);
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      const googleMeetRevoke = async(project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=google_meet_revoke";
        let form = new FormData();
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      const meetingToolStatus = async(project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=meeting_tool_status";
        let form = new FormData();
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      const checkStatus = async(project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=check_status";
        let form = new FormData();
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
      const checkIsAssigned = async(
        task_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=get_user_journey";
        let form = new FormData();
        form.append('task_id', task_id);
        return apiService(endPoint, "POST", true, form);
      };
      const createEvent = async(
        task_id, project_id) => {
        const endPoint = API_URL + "mod=outlook_calendar_integration&act=create_event";
        let form = new FormData();
        form.append('task_id', task_id);
        form.append('project_id', project_id);
        return apiService(endPoint, "POST", true, form);
      };
  export default {
    createEvent,
    outlook_calendar_events, 
    oauthUrl, 
    user_authentication,
    get_calendars,
    revoke,
    get_events,
    get_single_event,
    editEvent,
    eventAddedIntoTask,
    createTask,
    updateEvent,
    getMeetingLink,
    getAssigneeCal,
    setAssignee,
    oauthUrlZoom,
    zoomAthentication,
    zoomMeetingLinkAddedIntoTask,
    // msTeamAuthentication,
    MsTeamMeetingLinkAddedIntoTask,
    oauthUrlGoogleMeet,
    googleMeetAuthentication,
    googleMeetingLinkAddedIntoTask,
    googleMeetRevoke,
    msTeamRevoke,
    zoomRevoke,
    meetingToolStatus,
    checkStatus,
    is_calendar_connected,
    change_topic_status,
    ms_teem_authentication,
    checkIsAssigned
  };

