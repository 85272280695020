import React from 'react';

function CalendarRevokeIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_9329_398257)">
					<path d="M23 25H26C26.5523 25 27 24.593 27 24.0909V5.90909C27 5.40701 26.5523 5 26 5H6C5.44772 5 5 5.40701 5 5.90909V24.0909C5 24.593 5.44772 25 6 25H7.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M22 3V7" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M10 3V7" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M5 11H27" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M20 21L12 29" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M20 29L12 21" strokeLinecap="round" strokeLinejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_9329_398257">
						<rect width="32" height="32" fill="white" />
					</clipPath>
				</defs>
			</svg>

		</React.Fragment>
	)
}

export default CalendarRevokeIcon