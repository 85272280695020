import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, showError, showSuccess } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import LeaseContractSetup from "../SpaceOnBoarding/SpaceDetails/LeaseContractSetup";
import leaseServices from "../../services/lease-services";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";

const LeaseModal = ({ 
    show, 
    handleClose, 
    spaceDetail,
    selectedFloorIds = [],
    selectedFloorId = -1,
    selectedTypeOfSpaceID = -1,
    isFrom = "",
    leaseJson = [],
    leaseDetails = {},
    setSelectedFloorIds = ()=>{},
    detailsdocuments = [],
    lease_sub_id = 0,
    setLeaseJson = ()=>{},
    currentFloorId= -1,
    setSpaceDetail = ()=>{},
    isCreate = false
  }) => {
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();

const [leaseData, setLeaseData] = useState({
  leaseStartDate : "",
  leaseEndDate : "",
  noticeDates : [{ notice_date: ""}],
  isRentingorOwning : "renting",
  amount : "",
  isMonthlyOrAnnualli : "monthly",
  termandCondition : "",
  selectedServices : [],
  selectedEquipments : [],
  selectedResidents : [],
  space_type : spaceDetail.space_type
})
const [leaseDocuments, setLeaseDocuments] = useState([]);
const [otherDocuments, setOtherDocuments] = useState([]);
const [topics, setTopics] = useState();
const [chartData, setChartData] = useState([]);
const [documents, setDocuments] = useState(detailsdocuments ? detailsdocuments : []);
const [leaseFloorJson, setleaseFloorJson] = useState([{}]);

const validateStep = () => {
    if (leaseData.leaseStartDate == '') {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_select_the_lease_start_date")
          )
        );
        return false;
      } else if (leaseData.leaseEndDate == '') {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_select_the_lease_end_date")
          )
        );
        return false;
      } else if (leaseData.noticeDates && leaseData.noticeDates.filter((date) => date.notice_date != '').length == 0) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_select_the_notice_lease_end_date")
          )
        );
        return false;
      } else if (leaseData.amount == '') {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_amount")
          )
        );
        return false;
      } else if (leaseDocuments && leaseDocuments.length == 0 && detailsdocuments.length == 0) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_upload_lease_document")
          )
        );
        return false;
      }  else if(leaseData.selectedResidents.length == 0) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_select_residents")
          )
        );
        return false;
      }else{
        return true;
      }
      return true
  }

  const handleSave = () =>{
    if(!validateStep())
      {
        return false;
      }
    setLoader(true);

        if (isFrom == "floor-setup") {
            try {
                leaseServices.addeditProjectSubLease(
                    spaceDetail.project_id,
                    leaseData,
                    leaseDocuments,
                    otherDocuments,
                    spaceDetail.lease_id,
                    leaseJson,
                    leaseData.selectedResidents[0],
                    lease_sub_id
                ).then((res)=>{
                    if (res && res.status) {
                      dispatch(getFloorDetails(spaceDetail.project_id, 1));
                      showSuccess(res.message);
                      setLoader(false);
                      handleClose();
                    }else{
                      showError(res.message);
                      setLoader(false);
                    }
                })
            } catch (error) {
                console.log(error);
                setLoader(false);
            }
            
        } else if(isFrom == "floor-list"){
          try {
            leaseServices.addeditProjectSubLease(
                spaceDetail.project_id,
                leaseData,
                leaseDocuments,
                otherDocuments,
                spaceDetail.lease_id,
                leaseFloorJson,
                leaseData.selectedResidents[0],
                lease_sub_id
            ).then((res)=>{
                if (res && res.status) {
                  dispatch(getFloorDetails(spaceDetail.project_id, 1));
                  showSuccess(res.message);
                  setLoader(false);
                  handleClose();
                }else{
                  showError(res.message);
                  setLoader(false);
                }                
            })
        } catch (error) {
            console.log(error)
        }
            
        }
    
   
  }

  
  useEffect(() => {
    if (spaceDetail && spaceDetail.project_id ) {

      leaseServices.getLeaseTopics(spaceDetail.project_id,isCreate ? 0 : spaceDetail.lease_id,isCreate ? 0 : lease_sub_id).then((res)=>{
        if (res && res.status) {
          setTopics(res.data.topics)
        }
      })

      leaseServices.getLeaseChartData(spaceDetail.project_id, isCreate ? 0 : spaceDetail.lease_id, isCreate ? 0 : lease_sub_id).then((res)=>{
        if (res && res.status) {
          setChartData(res.data)
        }
      })
    }
    
  }, [spaceDetail])

  useEffect(() => {
    let tempData = [];
    if (selectedFloorIds && selectedFloorIds.length && selectedFloorId != -1) {
        let json = {}
        selectedFloorIds.map((id)=>{
            json =
                {
                    "floor_id": selectedFloorId,
                    "type_of_space_id": selectedTypeOfSpaceID,
                    "type_of_space_item_id": id
                }
                tempData.push(json)
        })
        setleaseFloorJson(tempData)
    }

    if (selectedFloorIds && selectedFloorIds.length && selectedFloorId == -1) {
        let json = {}
        selectedFloorIds.map((id)=>{
            json =
                {
                    "floor_id": id,
                    "type_of_space_id": 0,
                    "type_of_space_item_id": 0
                }
                tempData.push(json)
        })
        setleaseFloorJson(tempData)
        
    }
    
  }, [selectedFloorIds])

  useEffect(() => {
    if (Object.keys(leaseDetails).length > 0) {
      let details = leaseDetails.detail
      setLeaseData({
        ...leaseData,
        amount : details.amount,
        leaseEndDate : new Date(details.end_date),
        leaseStartDate : new Date(details.start_date),
        noticeDates : details.notice_date_detail,
        isMonthlyOrAnnualli : details.payment_type == 1 ? "monthly" :"",
        termandCondition : details.termination_condition,
        selectedResidents : [leaseDetails.resident_staff_id]
      })
      
    }
  }, [JSON.stringify(leaseDetails)])
  
  

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="xl"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_lease_modal_header")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="with-custom-height80vh overflow-hiiden-auto">
          <LeaseContractSetup
          leaseData={leaseData}
          setLeaseData={setLeaseData}
          leaseDocuments={leaseDocuments}
          setLeaseDocuments={setLeaseDocuments}
          otherDocuments={otherDocuments}
          setOtherDocuments={setOtherDocuments}
          spaceDetail={spaceDetail}
          topics={topics}
          chartData={chartData}
          documents={documents}
          setDocuments={setDocuments}
          isFrom={isFrom}
          selectedFloorId={selectedFloorIds}
          leaseJson={leaseJson}
          setSelectedFloorIds={setSelectedFloorIds}
          setLeaseJson={setLeaseJson}
          currentFloorId={currentFloorId}
          lease_sub_id={lease_sub_id}
          setTopics={setTopics}
          setSpaceDetail={setSpaceDetail}
          isCreate={isCreate}
           />
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" size="sm" onClick={() => {
              if (!loader) {
                handleSave()
              }
            }}
              disabled={loader}
            >
              {loader
                ? _l('l_please_wait')
                : _l('l_save')}
              {loader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default LeaseModal