import React, { useState } from "react";
import OperatorSheetMain from "../components/Operator/OperatorSheetMain";

const ProviderReport = () => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );

  return (
    <>
      <div className="d-flex flex-column h-100">
        <OperatorSheetMain projectId={projectId} />
      </div>
    </>
  );
};

export default ProviderReport;
