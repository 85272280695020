import React from 'react';
 

function VirtualIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g opacity="0.8" clip-path="url(#clip0_10372_408072)">
					<path d="M30.525 15.1407L42.5555 22.0899C42.7302 22.1946 42.8747 22.343 42.9745 22.5206C43.0744 22.6982 43.1263 22.8987 43.125 23.1024V36.8977C43.1263 37.1014 43.0744 37.3019 42.9745 37.4795C42.8747 37.657 42.7302 37.8055 42.5555 37.9102L30.525 44.8594C30.3654 44.9516 30.1843 45.0001 30 45.0001C29.8157 45.0001 29.6346 44.9516 29.475 44.8594L17.4446 37.9102C17.2698 37.8055 17.1254 37.657 17.0255 37.4795C16.9256 37.3019 16.8738 37.1014 16.875 36.8977V23.1024C16.8738 22.8987 16.9256 22.6982 17.0255 22.5206C17.1254 22.343 17.2698 22.1946 17.4446 22.0899L29.475 15.1407C29.6346 15.0485 29.8157 15 30 15C30.1843 15 30.3654 15.0485 30.525 15.1407Z" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M16.9707 22.6338L29.9996 30.1572M29.9996 30.1572L43.0285 22.6338M29.9996 30.1572L29.9996 45.0002" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M43.125 11.25H52.5V20.625" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M16.875 48.75H7.5V39.375" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M52.5 39.375V48.75H43.125" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M7.5 20.625V11.25H16.875" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_10372_408072">
						<rect width="60" height="60" fill="white" />
					</clipPath>
				</defs>
			</svg>

		</React.Fragment>
	)
}

export default VirtualIcon