import React from 'react';

function DocumentSyncIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_9329_398255)">
					<path d="M25 18V10L18 3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V26C5 26.2652 5.10536 26.5196 5.29289 26.7071C5.48043 26.8946 5.73478 27 6 27H15" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M18 3V10H25" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M19.2272 21.2273C19.2272 21.2273 20.4545 20 22.5 20C25.3636 20 27 22.8636 27 22.8636" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M25.7727 27.7727C25.7727 27.7727 24.5455 29 22.5 29C19.6364 29 18 26.1364 18 26.1364" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M24.5454 22.8637H27V20.4091" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M20.4545 26.1364H18V28.5909" strokeLinecap="round" strokeLinejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_9329_398255">
						<rect width="32" height="32" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</React.Fragment>
	)
}

export default DocumentSyncIcon