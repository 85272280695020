import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import CustomerServices from "../../services/customer-services";
import { _l, showError, showSuccess } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import customerServices from "../../services/customer-services";
import XIcon from "../../assets/icons/XIcon";
const AddNewCategory = ({ categories, handler, projectId, show, handleClose, activeTopicKey, editTopicDetails}) => {
  const dispatch = useDispatch();
  const { leftPanelTab } = useSelector((state) => state.customer);

  const [categoryTitle, setCategoryTitle] = useState(editTopicDetails ? editTopicDetails.title : "");
  const [selectedCategory, setSelectedCategory] = useState(categories && categories.length ? categories[0] : { value: "", label: "" });
  const [tagEmail, setTagEmail] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [emails, setEmails] = useState(editTopicDetails && editTopicDetails.email ? editTopicDetails.email  : []);
  const [spaceEmail, setSpaceEmail] = useState("");

  const page_name = window.location.pathname;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const saveCategory = () => {
    if(activeTopicKey == "addEmail") {
      if (!spaceEmail || spaceEmail == "") {
        showError("Please enter email");
        return
      }
      setShowLoader(true);
      CustomerServices.addUserByEmail(`${spaceEmail}@mg.myr.ai`).then((res) => {
        setShowLoader(false);
        if(res && res.status) {
          showSuccess(res.message);
          handleClose();
        } else {
          showError(res ? res.message : "l_something_went_wrong");
        }
      })
    } else if (categoryTitle && categoryTitle != '') {
      setShowLoader(true);
      CustomerServices.addCustomTopicFilter(categoryTitle, activeTopicKey, editTopicDetails && editTopicDetails.id ? editTopicDetails.id : 0,page_name == "/leads" ? "leadPage" :"", emails, leftPanelTab == "my-request" ? 1 : 0).then((res) => {
        setShowLoader(false);
        if (res.status == 1) {
          if (editTopicDetails && editTopicDetails.id && editTopicDetails.id > 0) {
            handler({ id: editTopicDetails.id, name: categoryTitle,  main_key :activeTopicKey, email : activeTopicKey === "my_emails" ?  emails :[]});
          }else{
            handler({ id: res.data.tag_id ? res.data.tag_id : 0, name: categoryTitle, main_key :activeTopicKey, email : activeTopicKey === "my_emails" ?  emails : []});
          }
          showSuccess(res.message);
          handleClose();
        }else{
          showError(res.message);
        }
      });
    } else {
      dispatch(showMessage('unsucess', _l("l_error"), _l("l_please_enter_valid_topic")));
      return false;
    }
  };

  const handleAddEmail = () => {
    if (tagEmail.trim() !== '') {
      if (!emailRegex.test(tagEmail.trim())) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
        );
      }else{
        setEmails([...emails, tagEmail.trim()]);
        setTagEmail(''); 
      }
    }
  };

  const removeMailID = (mail) => {
    setEmails(emails.filter((w)=> w !== mail))
  }

  const getUserEmailFromApi = () => {
    customerServices.getUserEmail()
    .then((res) => {
      if(res && res.data) {
        let email = res.data.email.replace(/@mg\.myr\.ai$/, "");
        setSpaceEmail(email)          
      }
    })
  }

  useEffect(() => {
    if(activeTopicKey == "addEmail"){
      getUserEmailFromApi();
    }
  }, [])

  return (
    <div className="custom-modal-style add-topic-search">
      <Modal
        className="modal fade custom-modal-style"
        tabIndex="-1"
        aria-labelledby="addNewCategoryModal"
        aria-hidden="true"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton className="">
          <Modal.Title>{activeTopicKey == "addEmail" ? _l('l_set_email') : activeTopicKey == "addEmail" ? _l("l_add_user") :editTopicDetails && editTopicDetails.id && editTopicDetails.id > 0 ? _l('l_edit_new_topic') : _l('l_add_new_topic')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="add-new-category">
            <div className="d-flex align-items-center row">
              <a href="#/"
                className="bg-white-03 dropdown-toggle d-flex align-items-center p-2 d-none"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id="EmojiDropdown"
              >
                <div className="h30w30  d-flex comman-round-box with-bg radius_3 me-2">
                  <span className="m-auto">😊</span>
                </div>
              </a>
              {activeTopicKey != "addEmail" ?
              <Form.Group className="comment-input-box flex-grow-1">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_topic_name')}
                </Form.Label>
                <Form.Control
                  className="height-45 pe-2"
                  type="text"
                  placeholder={`${_l('l_topic_name')}`}
                  value={categoryTitle}
                  onChange={(e) => { setCategoryTitle(e.target.value) }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      saveCategory();
                    }
                  }}
                />

              </Form.Group>
              : <></>}
              {activeTopicKey === "my_emails" ? 
                <Form.Group className="comment-input-box flex-grow-1">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_emails')}
                </Form.Label>
                <Form.Control
                  className="height-45 pe-2"
                  type="email"
                  multiple
                  placeholder={`${_l('l_emails')}`}
                  value={tagEmail}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleAddEmail();
                    }
                  }}
                  onChange={(e) => { setTagEmail(e.target.value)}}
                />

              </Form.Group> : <></>}
              {activeTopicKey === "my_emails" && emails && emails.length > 0 && <div className="pb-4">
                  {emails && emails.length > 0 && 
                  emails.map((mail,index)=>{
                    return(<>
                    <div
                      key={index}
                      className='badge c-font f-12 p-2 rounded-pill me-1 mt-2'
                    >
                      {mail}
                      <a href="#/" className='ps-1' onClick={() => removeMailID(mail)}>
                        <XIcon className="HW10" />
                      </a>
                    </div>
                    </>)
                  })}
                  </div>}
            </div>
            <Form.Group className="c-input-box  position-relative py-3 d-none">
              <div className="row align-items-center">
                <div className="col-2">
                  <Form.Label className="input-label no-asterisk pe-0 mb-0">
                    {_l('l_category')}
                  </Form.Label>
                </div>
                <div className="col-10">
                  <Select
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    options={categories}
                    value={selectedCategory}
                    onChange={(option) => { setSelectedCategory(option) }}
                  />
                </div>
              </div>
            </Form.Group>

            { activeTopicKey == "addEmail" ?
            // <Form.Group className="comment-input-box flex-grow-1">
            //     <Form.Label className="input-label no-asterisk ">
            //       {_l('l_emails')}
            //     </Form.Label>
            //     <Form.Control
            //       className="height-45 pe-2"
            //       type="email"
            //       multiple
            //       placeholder={`${_l('l_emails')}`}
            //       value={tagEmail}
            //       onKeyUp={(e) => {
            //         if (e.key === "Enter") {
            //           if (tagEmail.trim() !== '') {
            //             if (!emailRegex.test(tagEmail.trim())) {
            //               dispatch(
            //                 showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
            //               );
            //             }else{
            //               saveCategory()
            //             }
            //           }
            //           // handleAddEmail();
            //         }
            //       }}
            //       onChange={(e) => { setTagEmail(e.target.value)}}
            //     />

            //   </Form.Group> : <></>}
            <Form.Group className="c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                  {_l('l_email')} 
                  </Form.Label>
                  <div className="row g-0">
                    <div className="col-7">
                      <Form.Control
                        placeholder={_l('l_employee_name_placeholder')} 
                        type="text"
                        value={spaceEmail}
                        onChange={(e) => setSpaceEmail(e.target.value)}
                      
                      />
                    </div>
                    <div className="col-5">
                      <span className="form-control for-disabled color-white-60">@mg.myr.ai</span>
                    </div>
                  </div>
                </Form.Group> : <></>}
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={saveCategory} disabled={showLoader}>
            {showLoader ? _l('l_please_wait') : _l('l_save')}
            {showLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewCategory;
