import { useSelector } from "react-redux";
import store from "../store";

export const isLoadingData = (componentName) => {
  const { hideLoader} = useSelector((state) => state.customer);
  if (hideLoader === false) {
    const allPendingAPIs = store.getState().generalReducer.loaderArray;
    let componentsPendingAPI = 0;
    if (componentName && componentAPIs[componentName])
      componentAPIs[componentName].forEach((api) => {
        allPendingAPIs.forEach((pendingAPI) => {
          if (pendingAPI.url.includes(api)) {
            componentsPendingAPI++;
          }
        });
      });
    return false;
    // return componentsPendingAPI > 0;
  }
};

const componentAPIs = {
  week_calendar:["get_task_count_week_calendar"],
  task_list: ["get_task_list"], //Array in case component depends on more than 1 API,
  //Task Modal
  benchmark_list: ["mod=benchmark&act=get", "benchmarks_with_slots_and_assignees"],
  lead_list: ["get_leads_count_week_calendar", "get_leads_list", "get_leads_count"],
  document_list: ["get_invoices", "get_credit_note_list", "get_estimate_list", "get_subscription_list", "get_space_document_list"],
  folder_data: ["get_folder_data"],
  // get_invoices: ["get_invoices"],
  get_comments: ["get_comments"],
  equipment_list: ["equipment_list"],
  get_space_document: ["get_space_document"],
  client_listing: ["client_listing"],
  get_all_estimates:["get_all_estimates"],
  full_calendar_tasks: ["full_calendar_tasks"],
  get_all_subscriptions: ["get_all_subscriptions"],
  staff_listing: ["staff_listing"],
  get_all_invoices: ["get_all_invoices"],
  left_panel: [
    "get_default_topics", 
    "get_staff_team_operator", 
    "get_project_task_count", 
    "get_spaces", 
    // "is_discord_connected", 
    "is_slack_connected",
    // "is_calendar_connected", 
    // "is_googlecalendar_connected", 
    // "check_status", 
    "benchmarks_with_slots_and_assignees"
  ],
  get_attendance_list: ["get_attendance_list"],
  get_robot_schedule:["get_robot_schedule"],
  get_existing_and_invitee_operator_by_customer:["get_existing_and_invitee_operator_by_customer"],
  get_user_list:["get_user_list"],
  get_myr_staff_list : ["get_myr_staff_list"]
};
