import React from 'react';
 

function NoSummary(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="48" height="74" viewBox="0 0 48 74" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons w-100 h-100 ${CommanHW}`}>
				<path d="M10.7729 8.59896H8.88621C6.75219 8.59896 4.61713 8.59896 2.48101 8.59896C1.48423 8.59896 1 9.08319 1 10.08C1 30.5565 1 51.0351 1 71.5158C1 72.5063 1.49996 73 2.48731 73H46.1194C47.1005 73 47.6098 72.4938 47.6098 71.5158V10.0894C47.6098 9.10521 47.1099 8.61469 46.1194 8.61155C43.9843 8.61155 39.9783 8.61155 37.8433 8.61155M44.129 69.2864V12.3723C44.0797 12.3566 44.0292 12.345 43.9781 12.3376C42.4058 12.3376 40.8336 12.3376 39.2362 12.3376C39.1553 12.3534 39.0784 12.3856 39.0103 12.4321C38.9422 12.4786 38.8842 12.5385 38.84 12.6081C38.5488 13.0841 38.1382 13.4758 37.649 13.7444C37.1598 14.013 36.6089 14.1492 36.0509 14.1395C28.2297 14.1395 20.4095 14.1395 12.5904 14.1395C12.0248 14.1523 11.4658 14.0164 10.9692 13.7454C10.4726 13.4745 10.0557 13.078 9.76037 12.5955C9.70778 12.5247 9.64136 12.4654 9.56515 12.4211C9.48894 12.3768 9.40451 12.3484 9.317 12.3376C7.79195 12.3188 6.26692 12.3376 4.74188 12.3376C4.64486 12.3435 4.54825 12.3551 4.45258 12.3723V69.2864H44.129ZM24.3222 12.6993H35.8843C37.142 12.6993 37.8527 11.9792 37.8558 10.7435C37.8558 10.0643 37.8558 9.38509 37.8558 8.70589C37.8558 7.47642 37.12 6.74377 35.8874 6.74377H30.3564C29.7715 6.74377 29.5231 6.49217 29.5137 5.91046C29.5137 5.38534 29.5137 4.85707 29.498 4.33824C29.4628 3.45585 29.0914 2.62033 28.46 2.00295C27.8286 1.38557 26.9849 1.03306 26.102 1.01771C24.9102 0.992552 23.7185 0.995696 22.5267 1.01771C21.6923 1.02467 20.889 1.33529 20.267 1.89141C19.6449 2.44753 19.2466 3.21113 19.1465 4.03954C19.0966 4.61878 19.0798 5.20041 19.0962 5.78156C19.0962 6.55195 18.8887 6.75629 18.1025 6.75629C16.3008 6.75629 14.499 6.75629 12.6973 6.75629C11.5307 6.75629 10.8106 7.4858 10.7855 8.64294C10.7855 9.30956 10.7855 9.97307 10.7855 10.6397C10.7855 12.039 11.4521 12.7087 12.8419 12.7087L24.3222 12.6993Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M27.2245 30.783H39.7015C39.8545 30.7751 40.0077 30.7751 40.1606 30.783C40.343 30.7951 40.5139 30.876 40.6388 31.0094C40.7637 31.1428 40.8331 31.3187 40.8331 31.5015C40.8331 31.6842 40.7637 31.8602 40.6388 31.9936C40.5139 32.127 40.343 32.2079 40.1606 32.22C40.0663 32.22 39.9688 32.22 39.8713 32.22H14.499C14.3609 32.2254 14.2227 32.2149 14.087 32.1886C13.9311 32.1519 13.7921 32.0636 13.6926 31.9381C13.5932 31.8125 13.5391 31.657 13.5391 31.4968C13.5391 31.3366 13.5932 31.1811 13.6926 31.0555C13.7921 30.93 13.9311 30.8416 14.087 30.805C14.2373 30.7771 14.3903 30.7666 14.543 30.7736L27.2245 30.783Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M9.6508 30.783C10.0124 30.783 10.3709 30.783 10.7325 30.783C10.8312 30.7765 10.9303 30.7903 11.0235 30.8236C11.1167 30.8569 11.2021 30.909 11.2744 30.9766C11.3467 31.0443 11.4043 31.1261 11.4436 31.2169C11.483 31.3077 11.5034 31.4056 11.5034 31.5046C11.5034 31.6036 11.483 31.7015 11.4436 31.7924C11.4043 31.8832 11.3467 31.965 11.2744 32.0326C11.2021 32.1003 11.1167 32.1523 11.0235 32.1856C10.9303 32.2189 10.8312 32.2328 10.7325 32.2262C9.98409 32.243 9.23575 32.243 8.48738 32.2262C8.38982 32.2302 8.29247 32.214 8.20145 32.1786C8.11044 32.1433 8.0277 32.0896 7.95837 32.0208C7.88903 31.9521 7.83458 31.8698 7.79846 31.7791C7.76234 31.6884 7.7453 31.5912 7.74841 31.4936C7.74994 31.3962 7.77119 31.3 7.81082 31.211C7.85045 31.1219 7.90765 31.0418 7.97902 30.9755C8.05039 30.9091 8.13442 30.8578 8.2261 30.8248C8.31778 30.7917 8.41521 30.7775 8.51252 30.783C8.90243 30.7736 9.27661 30.783 9.6508 30.783Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M27.1781 22.0698H14.5753C14.4621 22.0698 14.352 22.0698 14.242 22.0698C14.0588 22.0577 13.8871 21.9764 13.7617 21.8424C13.6362 21.7084 13.5664 21.5317 13.5664 21.3482C13.5664 21.1646 13.6362 20.9879 13.7617 20.8539C13.8871 20.7199 14.0588 20.6386 14.242 20.6265C14.3394 20.6265 14.4369 20.6265 14.5344 20.6265H39.6553C39.8062 20.6265 39.9697 20.6265 40.1112 20.6265C40.3008 20.6273 40.4823 20.7032 40.6161 20.8376C40.7499 20.9719 40.825 21.1538 40.825 21.3434C40.8242 21.5333 40.749 21.7154 40.6156 21.8506C40.4822 21.9858 40.3011 22.0634 40.1112 22.0666C40.0168 22.0666 39.9194 22.0666 39.8219 22.0666L27.1781 22.0698Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M9.61096 22.0695C9.24935 22.0695 8.89087 22.0695 8.52926 22.0695C8.43196 22.0755 8.33449 22.0617 8.24265 22.029C8.1508 21.9963 8.0665 21.9455 7.99484 21.8794C7.92317 21.8133 7.86561 21.7334 7.8256 21.6445C7.7856 21.5556 7.76398 21.4595 7.76204 21.362C7.75902 21.2611 7.77737 21.1606 7.81589 21.0672C7.85442 20.9739 7.91226 20.8897 7.98559 20.8202C8.05892 20.7507 8.14609 20.6976 8.24142 20.6641C8.33674 20.6307 8.43804 20.6178 8.5387 20.6262C9.25878 20.6262 9.982 20.6262 10.7021 20.6262C10.8026 20.616 10.9042 20.6272 11.0002 20.6591C11.0962 20.6909 11.1843 20.7426 11.2588 20.8109C11.3334 20.8792 11.3926 20.9625 11.4327 21.0553C11.4727 21.1482 11.4927 21.2484 11.4913 21.3494C11.4896 21.4502 11.4671 21.5494 11.4253 21.641C11.3834 21.7326 11.3232 21.8147 11.2482 21.882C11.1732 21.9493 11.0852 22.0003 10.9896 22.0321C10.8941 22.0638 10.7929 22.0755 10.6926 22.0664C10.331 22.0758 9.97257 22.0695 9.61096 22.0695Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M27.1763 52.5569H14.5735C14.4353 52.5664 14.2966 52.5664 14.1584 52.5569C13.983 52.5388 13.8209 52.4553 13.7044 52.323C13.5878 52.1907 13.5254 52.0195 13.5295 51.8432C13.5312 51.6751 13.5925 51.5129 13.7024 51.3857C13.8124 51.2584 13.9639 51.1743 14.1301 51.1482C14.2683 51.1387 14.4069 51.1387 14.5451 51.1482C22.9449 51.1482 31.3458 51.1482 39.7477 51.1482C39.8861 51.1482 40.0245 51.1482 40.1628 51.1482C40.3513 51.1507 40.531 51.2281 40.6625 51.3631C40.794 51.4982 40.8665 51.6799 40.864 51.8683C40.8615 52.0568 40.7842 52.2366 40.6492 52.3681C40.5141 52.4996 40.3324 52.572 40.1439 52.5695C40.0213 52.5695 39.8955 52.5695 39.7698 52.5695L27.1763 52.5569Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M9.66708 51.1267C10.0287 51.1267 10.3871 51.1267 10.7487 51.1267C10.8459 51.1219 10.9429 51.1372 11.0339 51.1716C11.1248 51.206 11.2077 51.2587 11.2773 51.3266C11.347 51.3944 11.4019 51.476 11.4386 51.566C11.4753 51.656 11.4931 51.7526 11.4908 51.8498C11.4921 51.9472 11.4729 52.0437 11.4345 52.1332C11.3961 52.2226 11.3394 52.303 11.2679 52.3691C11.1965 52.4352 11.112 52.4855 11.0198 52.5169C10.9277 52.5482 10.8299 52.5599 10.733 52.5511C9.99721 52.5511 9.26351 52.5511 8.53191 52.5511C8.43468 52.5599 8.33669 52.5482 8.24422 52.5169C8.15175 52.4856 8.06684 52.4353 7.99492 52.3693C7.92299 52.3033 7.86563 52.223 7.82652 52.1336C7.78742 52.0441 7.76744 51.9474 7.76784 51.8498C7.76512 51.7491 7.78387 51.6489 7.82284 51.556C7.86181 51.463 7.92009 51.3795 7.99384 51.3108C8.06759 51.2421 8.15511 51.1898 8.25059 51.1575C8.34607 51.1252 8.44734 51.1137 8.54765 51.1235C8.91869 51.1235 9.2929 51.1267 9.66708 51.1267Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M27.1774 42.3915H14.5336C14.3951 42.3974 14.2563 42.3922 14.1185 42.3759C13.9525 42.3502 13.8013 42.2654 13.6927 42.1371C13.5841 42.0089 13.5254 41.8458 13.5274 41.6778C13.5264 41.5098 13.5848 41.3469 13.6925 41.218C13.8001 41.0891 13.95 41.0024 14.1154 40.9734C14.2399 40.9639 14.3651 40.9639 14.4896 40.9734H39.9029C40.4972 40.9734 40.8242 41.2281 40.8242 41.6872C40.8242 42.1463 40.5097 42.4041 39.9029 42.4041L27.1774 42.3915Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M9.64569 40.9607C10.0199 40.9607 10.394 40.9607 10.7682 40.9607C10.8619 40.9582 10.9551 40.9745 11.0424 41.0086C11.1296 41.0427 11.2092 41.0939 11.2763 41.1593C11.3434 41.2246 11.3968 41.3029 11.4332 41.3892C11.4696 41.4755 11.4884 41.5682 11.4883 41.6619C11.4926 41.7558 11.4777 41.8495 11.4444 41.9373C11.4111 42.0252 11.3602 42.1053 11.2949 42.1728C11.2295 42.2402 11.1509 42.2935 11.0642 42.3295C10.9774 42.3655 10.8842 42.3834 10.7903 42.382C10.0167 42.3967 9.2411 42.3967 8.46338 42.382C8.36959 42.3834 8.2765 42.3656 8.18978 42.3298C8.10306 42.2941 8.02452 42.241 7.95894 42.174C7.89335 42.1069 7.8421 42.0273 7.80828 41.9398C7.77445 41.8523 7.75876 41.7588 7.76218 41.6651C7.76216 41.5712 7.78088 41.4784 7.81726 41.3919C7.85364 41.3054 7.90694 41.2269 7.97402 41.1613C8.0411 41.0957 8.12058 41.0442 8.20786 41.0097C8.29514 40.9753 8.38845 40.9586 8.48226 40.9607C8.86903 40.9513 9.25263 40.9607 9.64569 40.9607Z" stroke-width="0.5" strokeMiterlimit="10" />
				<path d="M24.2682 4.50806C24.8248 4.50806 25.3782 4.50806 25.9348 4.50806C26.2492 4.50806 26.4945 4.41374 26.4882 4.04899C26.4819 3.68423 26.246 3.61816 25.9442 3.61816C24.8499 3.61816 23.7431 3.61816 22.6582 3.61816C22.3438 3.61816 22.0954 3.73458 22.1017 4.08046C22.108 4.42635 22.3406 4.51435 22.6457 4.51121C23.1865 4.50178 23.7179 4.50806 24.2682 4.50806Z" stroke-width="0.5" strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default NoSummary