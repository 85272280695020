import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, getImagePreviewIcon } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { showMessage } from "../../actions/messages";
import customerServices from "../../services/customer-services";
import { setTaskMapData } from "../../actions/customer";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";
import XIcon from "../../assets/icons/XIcon";
const EditTaskModal = ({
  show = false,
  handleClose,
  task_id,
  surface = 0,
  taskDuration = 0,
  attachment = ""
}) => {
  const dispatch = useDispatch();
  const regex = /^[0-9]+$/;
  const projectId = localStorage.getItem("selectedOffice");
  const { taskList } = useSelector((state) => state.customer)
  const [loader, setLoader] = useState(false);
  const [superficy, setSuperficy] = useState(surface);
  const [duration, setDuration] = useState(taskDuration);
  const [file, setFile] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  useEffect(() => {
    if (taskDuration > 0) {
      setDuration(taskDuration)
    }
    if (surface > 0) {
      setSuperficy(surface)
    }
    if (attachment != "") {
      setImagePreviews([{ name: attachment, url: attachment }])
    }
  }, [surface, taskDuration,attachment, task_id])
  const preparePreview = (files) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url });
    });
    setFile(files)
    setImagePreviews(previrewArray);
  };
  const removeImageSelection = (name) => {
    imagePreviews.forEach((thumbnail) => {
      URL.revokeObjectURL(thumbnail.url);
    });
    const updatedFileList = Array.from(file).filter(
      (img) => img.name != name
    );
    preparePreview(updatedFileList);
  };
  const handleSave = () => {
    if (superficy == 0) {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_superficy')));
    } else if (duration == 0) {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_duration')));
      } else if (file && file.length == 0 && attachment == "") {
        dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select")+ " " + _l("l_files")));
    } else {
      setLoader(true)
      customerServices.addEditRobotTask(projectId, task_id, 1, superficy, duration, file).then((res) => {
        setLoader(false)
        if (res.status == 1) {
          dispatch(showMessage("sucess", _l("l_success"), res.message));
          let taskListsNew = taskList
          let Index = taskListsNew.findIndex((d) => d.id == task_id);
          if (Index > -1) {
            taskListsNew[Index].surface = superficy
            taskListsNew[Index].duration = duration
            taskListsNew[Index].map_attachment_url = imagePreviews[0].url
          }
          dispatch(setTaskMapData(taskListsNew,taskListsNew[Index]));
          handleClose()
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), res.message));
        }
      })
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {attachment != "" ? _l("l_edit") : _l("l_add")} {_l("l_map")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_total")} {_l("l_superficy")}
                </Form.Label>
                <Form.Control
                  placeholder={`${_l("l_total")} ${_l("l_superficy")}`}
                  type="number"
                  onChange={(e) => setSuperficy(regex.test(e.target.value) ? e.target.value : 0)}
                  value={superficy}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative mb-3">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_duration")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_duration")}
                  type="number"
                  onChange={(e) => setDuration(regex.test(e.target.value) ? e.target.value : 0)}
                  value={duration}
                />
              </Form.Group>
              <Form.Group className="col-12 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk ">
                {_l("l_upload_file")}
                </Form.Label>
                {imagePreviews && imagePreviews.length ? (
                  <div className="upload-image-preview p-2">
                    <div className="d-flex align-items-center justify-content-start">
                      {imagePreviews.map((preview, index) => {
                        if (typeof preview.url === "string") {
                          return (
                            <div
                              key={index}
                              className="comman-image-box h60w60 radius_3 upload-image with-margin mt-2"
                            >
                              <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                style={{
                                  backgroundImage: `url(
                                                '${preview.url}'
                                              )`,
                                }}
                              ></div>
                              <a href="#/"
                                className="upload-close"
                                onClick={() => {
                                  removeImageSelection(preview.name);
                                }}
                              >
                                <XIcon className="HW10" />
                              </a>
                            </div>
                          );
                        }
                        else {
                          return (
                            <div
                              key={index}
                              className="comman-image-box h60w60 radius_3 upload-image with-margin d-flex"
                            >
                              <preview.url size={40} weight="light" className="c-icons" />
                              <a href="#/"
                                className="upload-close"
                                onClick={() => {
                                  removeImageSelection(preview.name);
                                }}
                              >
                                <XIcon className="HW10" />
                              </a>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="input_box_main with_file_upload bg-white-03">
                  <label className="upload_inputbox w-100 upload_relative">
                    <input
                      type="file"
                      className="absolute-input"
                      accept=".png,.jpg,.jpeg"
                      onChange={(event) => {
                        preparePreview(event.target.files);
                      }}
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                    />
                    <div className="custom_file">
                      <div className="comman_action_icon upload_icon">
                        <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img">
                          <PaperclipIcon className="HW16" />
                        </div>
                      </div>

                      <div className="btn btn-sm btn-white-10 c-font f-13">
                        {_l("l_upload_file")}
                      </div>
                    </div>
                  </label>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" disabled={loader}
            onClick={() => { handleSave() }}
          >{loader ? _l("l_please_wait") : _l("l_save")}
            {loader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTaskModal;
