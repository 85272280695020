import React from 'react'
const BillingCardSkeleton = () => {
  return (
    <>
    { [1,2,3,4].map((item, i) => {
      return(
        <div className="col-lg-6 mb-3">
      <div key={i} className="border no-hover p-15 task-list-card w-100 SkeletonMain">
        <div className=' d-flex align-items-center gap10 justify-content-between'>
          <div className=' d-flex align-items-center gap10' style={{ height: '58px' }}>
            <div className='radius_3 skeleton-animation m-auto' style={{ height: '40px', width: '40px' }}></div>
            <div className='radius_3 skeleton-animation m-auto' style={{ height: '15px', width: '40px' }}></div>
          </div>
          <div className=' d-flex align-items-center gap10'>
            <div className='h32w32 skeleton-animation radius_3'></div>
            <div className='h32w32 skeleton-animation radius_3'></div>
          </div>
        </div>
        <div className='pt-20px d-flex flex-column gap10'>
          <div className='mb-2 skeleton-animation radius_3' style={{ width: '70px', height: '12px' }}></div>
          <div className='mb-2 skeleton-animation radius_3' style={{ width: '60%', height: '12px' }}></div>
          <div className='skeleton-animation radius_3' style={{ width: '100%', height: '12px' }}></div>
          <div className='skeleton-animation radius_3' style={{ width: '30%', height: '12px' }}></div>
          <div className='border-bottom-10 mt-10'></div>
          <div className='mb-1 skeleton-animation radius_3' style={{ width: '50%', height: '12px' }}></div>
        </div>
      </div>
      </div>
      )
    }) }
    </>
  )
}
export default BillingCardSkeleton