import React from 'react';

function CustomGoogleDriveIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
<svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.25956 23.5426L3.5785 25.8303C3.85254 26.3118 4.24706 26.6905 4.71047 26.9663L9.44157 23.6119L9.45018 18.7961L4.72887 16.6689L4.79743e-06 18.7791C-0.000932703 19.3118 0.135532 19.8447 0.409634 20.3262L2.25956 23.5426Z" fill="#0066DD"/>
<path d="M15.0172 9.1842L15.264 2.91092L10.3067 0.99707C9.84229 1.27117 9.44643 1.64846 9.17062 2.12904L0.415137 17.2337C0.139395 17.7143 0.0009375 18.2467 0 18.7793L9.45018 18.7962L15.0172 9.1842Z" fill="#00AD3C"/>
<path d="M15.0172 9.18416L19.5584 6.74116L19.7568 1.01391C19.2934 0.738169 18.761 0.59977 18.2112 0.598774L11.8538 0.587407C11.304 0.586411 10.7711 0.740103 10.3066 0.997036L15.0172 9.18416Z" fill="#00831E"/>
<path d="M20.5497 18.8162L9.4501 18.7964L4.71045 26.9666C5.17387 27.2423 5.70631 27.3807 6.25609 27.3817L23.7131 27.4129C24.2629 27.4139 24.7959 27.2602 25.2603 27.0033L25.2787 21.5154L20.5497 18.8162Z" fill="#0084FF"/>
<path d="M25.2604 27.0033C25.7248 26.7292 26.1207 26.3519 26.3964 25.8713L26.9479 24.9273L29.585 20.3787C29.8607 19.8981 29.9992 19.3657 30.0001 18.8331L24.5379 15.959L20.5671 18.8163L25.2604 27.0033Z" fill="#FF4131"/>
<path d="M25.2396 9.71772L20.8888 2.14975C20.6148 1.66811 20.2203 1.28941 19.7568 1.01367L15.0171 9.18393L20.5497 18.8159L29.9827 18.8327C29.9836 18.3001 29.8471 17.7672 29.5731 17.2856L25.2396 9.71772Z" fill="#FFBA00"/>
</svg>



		</React.Fragment>
	)
}

export default CustomGoogleDriveIcon
