import React, { useEffect, useState } from "react";
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { _l } from "../../../hooks/utilities";
import CustomWhatsappIcon from "../../../assets/icons/CustomWhatsappIcon";
import CustomGmailIcons from "../../../assets/icons/CustomGmailIcons";
import CustomSlackIcon from "../../../assets/icons/CustomSlackIcon";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { showConfirmation, showMessage, toastCloseAction } from "../../../actions/messages";
import XCircleIcon from "../../../assets/icons/XCircleIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import NotePencilIcon from "../../../assets/icons/NotePencilIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import UserIcon from "../../../assets/icons/UserIcon";
const PilotOnboardingFacility = ({ data, projectId, setSelectedTab, toolsList, handleSave, RoleList, loder }) => {
  const dispatch = useDispatch();
  const regex = /^[0-9\b]+$/;
  const [cost, setCost] = useState(0);
  const [documentId, setDocumentId] = useState(0);
  const [meeting, setMeeting] = useState(0);
  const [tools, setTools] = useState([]);
  const [saving, setSaving] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [peopleRole, setPeopleRole] = useState("");
  const [peopleName, setPeopleName] = useState("");
  const [people, setPeople] = useState([]);
  const addPeople = () => {
    if (peopleName == "") {
      dispatch(showMessage("unsucess", _l("l_error"),_l("l_plaese_enter_name")));
    }else if (peopleRole == ""){     
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_select_role")));
    } else {
      setPeople([...people, {full_name : peopleName, role : peopleRole.label}])
      setPeopleRole('');
      setPeopleName('');
    }
  }
  useEffect(() => {
    if (data && data.facility_manager) {
      setCompanyName(data.facility_manager.company_name);
      setCost(data.facility_manager.cost);
      setMeeting(data.facility_manager.meeting);
      setPeople(data.facility_manager.people ? data.facility_manager.people : []);
      if (data.facility_manager.tools) {
        setTools(toolsList.map(t => data.facility_manager.tools.includes(t.label) ? t : ''));
      }
    }
    if (data) {
      setDocumentId(data.document_id);
      setSaving(data.saving);
    }
  }, [data,data.facility_manager])
  const validateData = () => {
    if (companyName == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_enter_comapny_name")));
      return false
    } else if (cost == "" || cost <= 0) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_plaese_enter_cost")));
      return false
    } else {
      return true
    }
  }
  const deleteTools = (id) => {
    setTools(tools.filter(t => t.value != id))
  }
  const handleNext = () => {
    if (validateData()) {
      let toolsItem = []
      for (let i = 0; i < tools.length; i++) {
        if (tools[i] != "") {
          toolsItem.push(tools[i].label)       
        }
      }
      let facility = {
        company_name: companyName,
        tools: toolsItem,
        people: people,
        meeting: meeting,
        cost: cost
      }
      handleSave(projectId, documentId, data.document_type, data.company_name, data.total_area, data.tenants, data.cost, data.visibility, data.meeting, data.tools, data.people, saving, facility)
    }
  }
  return (
<>
    <div className="flex-grow-1 overflow-hiiden-auto">
      <Form className="row">
        <Form.Group className="mb-3 col-xl-12 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_company_name")}</Form.Label>
          <Form.Control type="test" placeholder="Jaguar pvt. ltd" onChange={(e) => setCompanyName(e.target.value)} value={companyName} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_cost")}</Form.Label>
          <Form.Control type="number" placeholder="20" pattern="[0-9]*" onChange={(e) => setCost(regex.test(e.target.value) ? e.target.value : 0)} value={cost} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_meeting")}</Form.Label>
          <Form.Control type="number" placeholder="20" pattern="[0-9]*" onChange={(e) => setMeeting(regex.test(e.target.value) ? e.target.value : 0)} value={meeting} />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box"  controlId="formGroupPassword">
          <Form.Label className="input-label no-asterisk ">{_l("l_tools")}</Form.Label>
          <Select
            value={tools}
            className="custom-select-menu max-h95 multi-select-with-scroll"
            classNamePrefix="react-select"
            options={toolsList}
            isMulti
            closeMenuOnSelect={false}
            placeholder={`${_l('l_select_tool')}`}
            onChange={(value) => {
              setTools(value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <Form.Label className="input-label no-asterisk ">{_l("l_saving")}</Form.Label>
          <Form.Control type="number" placeholder={_l("l_saving")} pattern="[0-9]*" onChange={(e) => setSaving(regex.test(e.target.value) ? e.target.value : 0 )} value={saving} />
        </Form.Group>
      </Form>
      <div className="d-flex align-items-center">
        {
          tools.map((t, k) => {
            return (
              t != "" ?
                <div className="badge rounded-pill text-nowrap d-flex align-items-center me-2" key={k}>
                  { t == "WhatsApp" || t.label == "WhatsApp"?
                    <CustomWhatsappIcon className="h25w25" />
                    : t == "Gmail" || t.label == "Gmail" ?
                      <CustomGmailIcons className="h25w25" />
                      : t == "Slack" || t.label == "Slack" ?
                        <CustomSlackIcon className="h20w20" />
                        :
                        <></>
                  }
                  <span className="c-font color-white-80 f-12 flex-grow-1 px-2 text-truncate w100minus100per">{t.label}</span> <a href="#/" onClick={() => deleteTools(t.value)}><XCircleIcon className="HW18 m-0 opacity-50" weight="light" /></a>
                </div>
                :
                <></>
            );
          })
        }
      </div>
      <Form className="row mt-3 border-bottom">
        <Form.Label className="input-label no-asterisk ">{_l("l_people")}</Form.Label>
        <Form.Group className="mb-3 col-xl-6" controlId="formGroupEmail">
          <div className="d-flex align-items-center">
            <Form.Control type="text" placeholder="people name" value={peopleName} onChange={(e) => setPeopleName(e.target.value)}/>
          </div>
        </Form.Group>
        <Form.Group className="mb-3 col-xl-6 c-input-box" controlId="formGroupEmail">
          <div className="d-flex align-items-center comman_action_icon">
          <Select
            value={peopleRole}
            className="custom-select-menu flex-grow-1 multi-select-with-scroll"
            classNamePrefix="react-select"
            options={RoleList}
            placeholder={`${_l('l_select_role')}`}
            onChange={(value) => {
              setPeopleRole(value)
            }}
          />
            <a href="#/" className="bg-white-05 d-flex h38w38 ms-2 with_bg" onClick={() => addPeople()}><PlusIcon className="HW18 " weight="light" /></a>
          </div>
        </Form.Group>
      </Form>
      <ul className="w-100 mt-3">
        {
          people.map((p, k) => {
            return (
              <li className="w-100" key={k}>
                <a href="#/" className="task-list-card list-view w-100 p-2 d-flex align-items-center">
                  <div className="d-flex flex-grow-1 w100minus100per">
                    <div className="comman-image-box h25w25 rounded-circle me-2">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                        <UserIcon className="HW14 " weight="light" />
                      </div>
                    </div>
                    <div className="flex-grow-1 fw-semibold text-truncate title-fonts w100minus100per">{p.full_name}</div>
                  </div>
                  <div className="f flex-grow-1 maxw100minus180 text-truncate fw-light">{p.role}</div>
                  <div className="d-flex  justify-content-start z-index-3 comman_action_icon">
                    <a href="#/" className="action_icon with_bg h30w30 d-flex me-2 d-none">
                      <NotePencilIcon className="HW " />
                    </a>
                    <a href="#/" className="action_icon with_bg h30w30 d-flex"
                     onClick={() => dispatch(
                      showConfirmation(
                        _l("l_are_you_sure"),
                        _l("l_you_want_to_delete_people"),
                        _l("l_yes"),
                        _l("l_no"),
                        undefined,
                        () => {
                          setPeople(people.filter((p,key) => key != k));
                          dispatch(toastCloseAction());
                        }
                      )
                    )}
                     >
                      <TrashIcon className="HW18 " />
                    </a>
                  </div>
                  
                </a>
              </li>
            )
          })
        }
      </ul>
      </div>
      <div className="d-flex justify-content-between">
      <Button variant="secondary" size="sm"  onClick={() => setSelectedTab("tenant")}>{_l("l_previous")}</Button> 
        <Button variant="primary" size="sm" onClick={() => {
          if (!loder) {
            handleNext()
          }
        }}>
          {loder
            ? _l('l_please_wait')
            : _l('l_next')}
          {loder ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            className={`ms-2 `}
            aria-hidden="true"
          />
            : <></>
          }</Button></div>
      </>

  );
};


export default PilotOnboardingFacility;

