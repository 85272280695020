import React from 'react';
 

function VideoCameraIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M11.8906 7L15.3906 5V11L11.8906 9M1.89062 3.75H9.89062C10.4211 3.75 10.9298 3.96071 11.3048 4.33579C11.6799 4.71086 11.8906 5.21957 11.8906 5.75V11.75C11.8906 11.8826 11.8379 12.0098 11.7442 12.1036C11.6504 12.1973 11.5232 12.25 11.3906 12.25H3.39062C2.86019 12.25 2.35148 12.0393 1.97641 11.6642C1.60134 11.2891 1.39063 10.7804 1.39062 10.25V4.25C1.39062 4.11739 1.4433 3.99021 1.53707 3.89645C1.63084 3.80268 1.75802 3.75 1.89062 3.75Z" />
</svg>


		</React.Fragment>
	)
}

export default VideoCameraIcon