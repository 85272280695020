import googleDriveServices from "../services/google-drive-services";
import onedriveServices from "../services/onedrive-services";
import { CHANGE_DOCUMENT_PRIORITY, CHECK_GOOGLE_DRIVE_CONNECTIVITY, DRIVE_TOPICS, FETCH_DOCUMENT_LIST, UPDATE_DOCUMENT_PIN, UPDATE_DOCUMENT_STATUS,  CHECK_ONEDRIVE_CONNECTIVITY} from "./action-type";

export const is_google_drive_connected = (project_id) => (dispatch) => {
  googleDriveServices
    .is_connected(project_id)
    .then((data) => {
      dispatch({
        type: CHECK_GOOGLE_DRIVE_CONNECTIVITY,
        payload: data
      });
    });
}

export const is_onedrive_connected = (project_id) => (dispatch) => {
  onedriveServices
    .is_connected(project_id)
    .then((data) => {
      dispatch({
        type: CHECK_ONEDRIVE_CONNECTIVITY,
        payload: data
      });
    });
}

export const drive_topics = (data) => (dispatch) => {
  dispatch({
    type: DRIVE_TOPICS,
    payload: data
  });
}

export const getDocumentList = (project_id, startDate = "", page = 1, status = 0, filters = [], searchString) => (dispatch) => {
  return googleDriveServices.getDocumentList(
    project_id,
    startDate,
    page,
    status,
    filters,
    searchString
  ).then((data) => {
    dispatch({
      type: FETCH_DOCUMENT_LIST,
      payload: { data },
    });
  });
};

export const updateDocumentStatus = (document_id, project_id, status) => (dispatch) => {
  return googleDriveServices.updateDocumentStatus(document_id, project_id, status).then(
    (data) => {
      if (data.status) {
        let payload = { document_id, status };
        dispatch({
          type: UPDATE_DOCUMENT_STATUS,
          payload: { payload },
        });
      }
    }
  );
};

export const updateDocumentPin = (document_id, project_id, documentPinShow) => (dispatch) => {
    return googleDriveServices.pinDocument(document_id, project_id).then((data) => {
      let payload = { document_id, documentPinShow };
      if (data.status) {
        dispatch({
          type: UPDATE_DOCUMENT_PIN,
          payload: { payload },
        });
      }
    });
  };


export const changePriority = (document_id, project_id, documentPriority) => (dispatch) => {
  return googleDriveServices.changePriority(document_id, project_id).then((data) => {
    if (data.status) {
      let payload = { document_id, documentPriority };
      dispatch({
        type: CHANGE_DOCUMENT_PRIORITY,
        payload: { payload },
      });
    }
  });
};
