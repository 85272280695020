import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { _l } from "../../hooks/utilities";
import Button from "react-bootstrap/Button";
import moment from "moment";
import EyeIcon from "../../assets/icons/EyeIcon";

const CommanCreditNotModal = ({
  projectId,
  show,
  handleClose,
  handleShow,
  statusUpdate,
  handleSubmit,
  raiseToggle,
  data = {}
}) => {
  const [raiseAmoutToggle, setRaiseAmountToggle] = useState(true);
  useEffect(()=>{
    setRaiseAmountToggle(raiseToggle);
  },[raiseToggle]);

  const addDays = (d, days) => {
    var d = new Date(d);
    d.setDate(d.getDate()+days);
    d = moment(d).format("DD/MM/YYYY");
    return d.toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric'});
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Credit Note Creation </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 pt-2">
          <div class="comman-data-table">
            <table class="dataTable">
              <thead class="bg-transparent p-0">
                <tr class="p-0">
                  <th style={{ width: '35%' }} class="p-0" ></th>
                  <th style={{ width: '10px' }} class="p-0" ></th>
                  <th class="p-0"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="">{_l("l_no_of_missing_task")}</td>
                  <td class="">:</td>
                  <td class="title-fonts">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>{data.total_missing_tasks}</span>
                      <a href={window.location.origin+'?invoiceDate='+data.date} className="h32w32 d-flex bg-white-05" target="_blank"><EyeIcon className="HW16" /></a>
                    </div>
                  </td>

                </tr>
                <tr>
                  <td class="">{_l("l_date_duration_of_missing_task")}</td>
                  <td class="">:</td>
                  <td class="title-fonts">{addDays(data.i_start_date,0)}  -  {addDays(data.i_end_date,0)}</td>
                </tr>
                <tr>
                  <td class="">{_l("l_per_task_amount")}</td>
                  <td class="">:</td>
                  <td class="title-fonts"><span>&euro;</span><span className="ps-1 fw-semibold">{data.total_credit/data.total_missing_tasks}</span></td>
                </tr>
                <tr>
                  <td class="">{_l("l_total")}</td>
                  <td class="">:</td>
                  <td class="title-fonts"><span>&euro;</span><span  className="ps-1 fw-semibold">{data.total_credit}</span></td>
                </tr>
              </tbody>
            </table>
          </div>

        </Modal.Body>
        <Modal.Footer className={`border-0 justify-content-end  ${raiseAmoutToggle ? "" : "p-0"}`}>
          {
            raiseAmoutToggle ? (
              <Button variant="primary" size="sm" onClick={() =>{
                handleSubmit({id:data.id,amount:data.total_credit});
              }}>
                {_l('l_raise_credit_note')}
              </Button>
            ) : (
              <div className="p-3 c-font f-15 border-top bg-white-03 m-0">
                <span>We have send your request to the provider and once provider approves/accepts we will credit your balance in next invoice.</span>
              </div>
              )
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommanCreditNotModal;
