import React from 'react';

function CalendarSyncIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_9329_398258)">
					<path d="M23 25H26C26.5523 25 27 24.593 27 24.0909V5.90909C27 5.40701 26.5523 5 26 5H6C5.44772 5 5 5.40701 5 5.90909V24.0909C5 24.593 5.44772 25 6 25H7.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M22 3V7" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M10 3V7" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M5 11H27" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M11.6364 18.6364C11.6364 18.6364 13.2727 17 16 17C19.8182 17 22 20.8182 22 20.8182" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M20.3636 27.3636C20.3636 27.3636 18.7273 29 16 29C12.1818 29 10 25.1818 10 25.1818" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M18.7273 20.8182H22V17.5455" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M13.2727 25.1818H10V28.4546" strokeLinecap="round" strokeLinejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_9329_398258">
						<rect width="32" height="32" fill="white" />
					</clipPath>
				</defs>
			</svg>


		</React.Fragment>
	)
}

export default CalendarSyncIcon