import React from 'react';


function CrownIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";

	return (
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M246,80a26,26,0,1,0-46.6,15.84l-29.83,36.72-26-59.76a26,26,0,1,0-31.16,0l-26,59.76L56.6,95.84a26,26,0,1,0-22.36,10.09L49,194.3A14,14,0,0,0,62.78,206H193.22A14,14,0,0,0,207,194.3l14.73-88.37A26,26,0,0,0,246,80ZM128,38a14,14,0,1,1-14,14A14,14,0,0,1,128,38ZM22,80A14,14,0,1,1,36,94,14,14,0,0,1,22,80ZM195.2,192.33a2,2,0,0,1-2,1.67H62.78a2,2,0,0,1-2-1.67L46.08,104l1.22-.55,36,44.36A6,6,0,0,0,88,150a5.52,5.52,0,0,0,.81-.06,6,6,0,0,0,4.69-3.55l29.92-68.8a25.79,25.79,0,0,0,9.16,0l29.92,68.8a6,6,0,0,0,4.69,3.55,5.52,5.52,0,0,0,.81.06,6,6,0,0,0,4.66-2.22l36-44.36,1.22.55ZM220,94a14,14,0,1,1,14-14A14,14,0,0,1,220,94Z"></path></svg>
		</React.Fragment>
	)
}

export default CrownIcon