import React from 'react';
 

function ShieldCheckIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M11.1406 6.5L7.47188 10L5.64062 8.25M2.89062 7.16875V3.5C2.89062 3.36739 2.9433 3.24021 3.03707 3.14645C3.13084 3.05268 3.25802 3 3.39062 3H13.3906C13.5232 3 13.6504 3.05268 13.7442 3.14645C13.8379 3.24021 13.8906 3.36739 13.8906 3.5V7.16875C13.8906 12.4187 9.43437 14.1562 8.54688 14.45C8.44605 14.4873 8.3352 14.4873 8.23438 14.45C7.34688 14.1562 2.89062 12.4187 2.89062 7.16875Z"/>
</svg>



		</React.Fragment>
	)
}

export default ShieldCheckIcon