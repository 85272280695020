import { combineReducers } from "redux";
import auth from "./auth";
import customer from "./customer";
import chatAuth from "./chat-auth";
import chat from "./chat";
import messages from "./messages";
import adminReducer from "./admin-reducer";
import benchmarkReducer from "./benchmark/benchmark-reducer";
import google_oauth_reducer from "./google_oauth_reducer";
import leadsReducer from "./leads-reducer";
import documentsReducer from "./documents-reducer";
import googleDrive from "./google-drive";
import discord_auth from "./discord_auth";
import slack_auth from "./slack_auth";
import google_calendar from "./google_calendar";
import generalReducer from "./general-reducer";
import onboardingReducer from "./onboarding-reducer";
import vapi from "./vapi";

export default combineReducers({
  auth,
  customer,
  chatAuth,
  chat,
  messages,
  adminReducer,
  benchmarkReducer,
  google_oauth_reducer,
  leadsReducer,
  documentsReducer,
  googleDrive,
  discord_auth,
  slack_auth,
  google_calendar,
  generalReducer,
  onboardingReducer,
  vapi
});
