import React, { useEffect, useState } from "react";
import UserProfile from "../components/SpaceOnBoarding/UserProfile";
import { useNavigate, useParams } from "react-router-dom";
import AuthServices from "../services/auth-services";
import { Button, Spinner } from "react-bootstrap";
import { _l, showError } from "../hooks/utilities";
import ExternalHeader from "../components/ExternalHeader";

const SpaceInvitation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [existingUser, setExistingUser] = useState(false);
  const [inviteLoader, setInviteLoader] = useState(false);

  const acceptInvite = async () => {
    try {
      setLoader(true);
      const response = await AuthServices.acceptProviderInvite(userDetails);
      if (response.status && response.data.auto_login_key) {
        sessionStorage.setItem("skip_onboarding", 1);
        navigate(`/autologin/${response.data.auto_login_key}`);
      }
    } catch (e) {
      console.error(e);
      showError("l_something_went_wrong");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const getCustomerUserDetails = async (hash) => {
      try {
        setInviteLoader(true);
        const response = await AuthServices.getCustomerInviteeDetails(hash);
        if (response.status) {
          if (Number(response.data.exist_client_id > 0)) {
            setExistingUser(true);
          } 
          let fullName = response.data.contact_name.split(" ");
          setUserDetails({
            contact_name: response.data.contact_name,
            phonenumber: response.data.phonenumber,
            lastname: fullName.pop(),
            firstname: fullName.join(" "),
            email: response.data.email,
            project_id: response.data.project_id,
            company: response.data.company,
            staff_id: response.data.staff_id,
            input_client_id: response.data.exist_client_id,
            provider_name: response.data.provider_name,
            provider_image_url: response.data.provider_image_url,
            space_title: response.data.space_title,
            space_address: response.data.space_address,
          });
        } else {
          navigate("/login");
        }
      } catch (e) {
        console.error(e);
        navigate("/login");
      } finally {
        setInviteLoader(false);
      }
    };

    if (id) {
      getCustomerUserDetails(id);
    } else {
      navigate("/login");
    }
    
    document.body.classList.remove("with-left-penel");
   
    return(() => {
      document.body.classList.add("with-left-penel");
    });

  }, []);

  return (
    <React.Fragment>
      <ExternalHeader hideLeftPanel={true} pageName={"space-invite"} profileImageURL={userDetails.provider_image_url} languageSelect={[]} spaceAddress={userDetails.space_address} spaceTitle={userDetails.space_title} userDescription={userDetails.space_address} userName={userDetails.provider_name} />
      <div className="row h-100 ">
        <div className="col-xl-9 m-auto">
         
            <>
              {existingUser ? (
                <div className="d-flex flex-column justify-content-center text-center h-100">
                  <div class="bg-white-03 onboarding-header p-20pximp text-center">
                    <div class="c-font f-20 fw-semibold title-fonts py-2">
                      {_l("l_space_invite_header")}
                    </div>
                    <div class="c-font f-16 color-white-60 py-1 text-center">
                      <span>{_l("l_space_invitation_message_body")}</span>
                    </div>
                    <div className="align-items-center d-flex justify-content-center mt-3">
                      <Button
                        variant="primary"
                        className="me-3"
                        onClick={acceptInvite}
                        disabled={inviteLoader}
                      >
                        {inviteLoader ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 `}
                            aria-hidden="true"
                          />
                        ) : (
                          _l("l_accept")
                        )}
                      </Button>
                      <Button variant="secondary">{_l("l_later")}</Button>
                    </div>
                  </div>
                </div>
              ) : userDetails ? (
                <UserProfile
                  loader={loader}
                  profileData={userDetails}
                  pageName={"space-invite"}
                  isMyprofileStep={true}
                />
              ) : (
                <></>
              )}
            </>
          
        </div>
      </div>
    </React.Fragment>
  );
};

export default SpaceInvitation;
