import React, { useState } from "react";
import EmplyeeTimeSheet from "../components/EmployeeTimeSheetComponents/EmployeeSheetMain";

const EmployeeTimeSheet = () => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );


  return (
    <>
      <div className="d-flex flex-column h-100">
        <EmplyeeTimeSheet projectId={projectId} />
      </div>
    </>
  );
};

export default EmployeeTimeSheet;
