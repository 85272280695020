import React from 'react';
 

function ArrowElbowUpRightIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15371_259119)">
<g clipPath="url(#clip1_15371_259119)">
<path d="M10 8L13 5L10 2"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4 14V5H13"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15371_259119">
<rect width="16" height="16" />
</clipPath>
<clipPath id="clip1_15371_259119">
<rect width="16" height="16" />
</clipPath>
</defs>
</svg>


		</React.Fragment>
	)
}

export default ArrowElbowUpRightIcon