import React from 'react';

function CustomGoogleMicrosoftMeetIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_1709_14738)">
					<path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="#827DDC" />
					<path d="M353.579 190.934L324.05 161.405C318.955 156.311 312.037 153.039 304.359 152.653L266.611 114.905L93.125 145.343V362.656L242.089 511.62C246.695 511.867 251.331 512 255.999 512C386.025 512 493.4 415.059 509.815 289.511L401.889 181.584L401.885 181.58C396.513 176.209 389.092 172.887 380.895 172.887C368.631 172.887 358.106 180.325 353.579 190.934Z" fill="#6E64C3" />
					<path d="M93.125 362.657L266.611 393.094V114.906L93.125 145.343V362.657ZM279.105 347C285.815 352.435 294.192 355.396 302.827 355.385C323.691 355.385 340.605 338.471 340.605 317.607V226.775H279.104L279.105 347ZM302.827 212.646C319.404 212.646 332.843 199.208 332.843 182.63C332.843 166.053 319.405 152.614 302.827 152.614C293.176 152.614 284.595 157.174 279.105 164.251V201.009C284.596 208.087 293.176 212.646 302.827 212.646ZM418.261 246.237H353.029V326.471C359.886 334.541 370.105 339.659 381.511 339.659C386.452 339.659 391.343 338.679 395.903 336.776C400.462 334.873 404.599 332.085 408.073 328.572C411.548 325.06 414.291 320.893 416.144 316.313C417.997 311.733 418.924 306.831 418.87 301.891L418.261 246.237Z" fill="#302C89" />
					<path d="M380.899 232.258C397.295 232.258 410.587 218.966 410.587 202.57C410.587 186.174 397.295 172.882 380.899 172.882C364.503 172.882 351.211 186.174 351.211 202.57C351.211 218.966 364.503 232.258 380.899 232.258Z" fill="#302C89" />
					<path d="M214.082 222.896H188.202V298.509H172.583V222.896H146.703V207.363H214.081L214.082 222.896Z" fill="white" />
				</g>
				<defs>
					<clipPath id="clip0_1709_14738">
						<rect width="512" height="512" fill="white" />
					</clipPath>
				</defs>
			</svg>

		</React.Fragment>
	)
}

export default CustomGoogleMicrosoftMeetIcon
