import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDispatch, } from "react-redux";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import { showMessage } from "../../actions/messages";
import benchmarkServices from "../../services/benchmark-services";
import Spinner from "react-bootstrap/Spinner";
import { _l } from "../../hooks/utilities";
import AddEmailTemplatesModalNew from "./AddEmailTemplatesModalNew";
import documentsServices from "../../services/documents-services";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import GearSixIcon from "../../assets/icons/GearSixIcon";
import PencilLineIcon from "../../assets/icons/PencilLineIcon";

  const AddressModal = ({ show , modalKey, handleClose , handleInput  = (address) => {}, data }) => {
    const [billingAddress, setBillingAddress] = useState({
      billing_street: "",
      billing_city: "",
      billing_state : "",
      billing_zip: "",
      billing_country: null,
    });
    const mainModalKey = modalKey == "billing_address" ? "billing"  : "shipping"
    const dispatch = useDispatch();
    const[checked, setChecked] = useState(false)
    const validateInputFields = () => {
      let validationSuccess = true;
  
      const { billing_street, billing_city , billing_state , billing_zip , billing_country } = billingAddress;
      let errMessage; 
      
      if (billing_country == null) {
        errMessage = 'Country is required';
        validationSuccess = false 
      }
      if (!billing_zip.trim()) {
        errMessage = 'Zip Code is required';
        validationSuccess = false 
      }
      if (!billing_state.trim()) {
        errMessage = 'State Name is required';
        validationSuccess = false 
      }
      if (!billing_city.trim()) {
        errMessage = 'City Name is required';
        validationSuccess = false 
      }
      if (!billing_street.trim()) {
        errMessage = 'Street Name is required';
        validationSuccess = false 
      }
      if (!validationSuccess) {
        dispatch(
          showMessage("unsucess", _l("l_error"), `${errMessage}`)
        );
      }
      return validationSuccess;
    };

    const hideModal = () => {
      if (validateInputFields()) {
        handleInput(
          {
            [`${mainModalKey}_street`]: billingAddress.billing_street,
            [`${mainModalKey}_city`]: billingAddress.billing_city,
            [`${mainModalKey}_state`] : billingAddress.billing_state,
            [`${mainModalKey}_zip`]: billingAddress.billing_zip,
            [`${mainModalKey}_country`]: billingAddress.billing_country,
          },
          {
            [`shipping_street`]: billingAddress.billing_street,
            [`shipping_city`]: billingAddress.billing_city,
            [`shipping_state`] : billingAddress.billing_state,
            [`shipping_zip`]: billingAddress.billing_zip,
            [`shipping_country`]: billingAddress.billing_country,
          },
          checked)
      }
    }

    const [countryList, setCountryList] = useState([]);
    useEffect(() => {
      CustomerServices.getCountryCodes(true).then((res) => {
        if (res.status == "1") {
          setCountryList(
            res.data.map((code) => {
              return {
                value: code.id,
                label: code.long_name,
                short_code: code.iso2,
              };
            })
          );
        }
      });
      if (data !== null) {
        const addresData = {
          billing_street: data.billing_street || data.shipping_street,
          billing_city: data.billing_city || data.shipping_city,
          billing_state : data.billing_state || data.shipping_state,
          billing_zip: data.billing_zip || data.shipping_zip,
          billing_country: data.billing_country || data.shipping_country,
        }
        setBillingAddress(addresData)
      }  
  }, []);

    return (
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_create_estimate")}</Modal.Title> 
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 mb-3 border-bottom">
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
              <div className="d-flex flex-wrap flex-column">
              <Form.Label className="input-label no-asterisk  d-flex align-items-center">
                {_l("l_street")}
              </Form.Label>
              <Form.Control
                  as="textarea"
                  placeholder={_l("l_street")}
                  rows={2}
                  value={billingAddress.billing_street}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_street: e.target.value });
                  }}
                />
              </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_city")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("France")}
                  aria-label="france"
                  aria-describedby="basic-addon1"
                  value={billingAddress.billing_city}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_city: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_state")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("")}
                  aria-label=""
                  aria-describedby="basic-addon1"
                  value={billingAddress.billing_state}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_state: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_zip_code")}
                </Form.Label>
                <Form.Control
                  placeholder={("93160")}
                  aria-label=""
                  aria-describedby="basic-addon1"
                  value={billingAddress.billing_zip}
                  onChange={(e) => {
                    setBillingAddress({ ...billingAddress, billing_zip: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative mb-3 ">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_country")}
                </Form.Label>
                  <div className="d-flex">
                    <Select
                      className="custom-select-menu flex-grow-1"
                      placeholder={_l("l_please_select_country")}
                      options={countryList}
                      classNamePrefix="react-select"
                      value = { countryList && countryList.find(
                              (w) => w.label == billingAddress.billing_country
                            )
                      }
                      onChange={(e) => {
                        setBillingAddress({
                          ...billingAddress,
                          billing_country: e.label,
                        });
                      }}
                    />
                  </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={()=>{handleClose()}} >
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              hideModal()
            }}
          >
            {_l("l_confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const ModalBodyEstamate = ({
    customers, 
    estimateinput, 
    setEstimateInput, 
    selectedSpace, 
    excludedDate, 
    estimateTypes, 
    paymentModes, 
    setAddressModal, 
    setAddressModalKey, 
    estimateItems, 
    setEstimateItems, 
    taxFeild, 
    addNewItemValidation,
    spacesOption
   }) => {
    return (
      <div className="form-wrapper-main p-10 overflow-hiiden-auto">
        <div className="row m-0 mb-3 border-bottom">
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3  z-3">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_customer")}
            </Form.Label>
            <div className="d-flex">
              <Select
                className="custom-select-menu flex-grow-1"
                placeholder={_l("l_select")}
                options={customers}
                value={estimateinput.customer_client_id}
                onChange={(e) => {
                  setEstimateInput({ ...estimateinput, customer_client_id: e });
                }}
                classNamePrefix="react-select"
              />
            </div>
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3 z-3 ">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_project")}
            </Form.Label>
            <div className="d-flex">
              <Select
                className="custom-select-menu flex-grow-1"
                placeholder={_l("l_select")}
                options={spacesOption}
                value={spacesOption.find((spaces) => spaces.value == estimateinput.project_id)}
                onChange={(e) => {
                  // console.log("eproject_id",e);
                  setEstimateInput({ ...estimateinput, project_id: e.value});
                }}
                classNamePrefix="react-select"
              />
            </div>
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3  ">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_custom_company_name")}
            </Form.Label>
            <Form.Control
              placeholder={_l("l_name")}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={estimateinput.customer_company_name}
              onChange={(e) => {
                setEstimateInput({
                  ...estimateinput,
                  customer_company_name: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_estimate_number")}
            </Form.Label>
            <Form.Control
              placeholder={_l(estimateinput.estimate_number)}
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={estimateinput.estimate_number}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
            <Form.Label className="input-label no-asterisk">
              {_l("l_start_date")}
            </Form.Label>
            <div
              className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100"
              data-bs-toggle="tooltip"
            >
              <div className="d-flex align-items-center form-control  color-white-60 dropdown-center dropdown">
                <CalendarBlankIcon
                  className="HW18 opacity-60 m-0 me-2"
                />
                <DatePicker
                  portalId="custom_datepicker"
                  calendarClassName="custom-datepicker"
                  placeholderText={`${_l("l_start_date_placeholder")}`}
                  dateFormat="dd/MM/yyyy"
                  selectsStart
                  calendarStartDay={1}
                  minDate={new Date()}
                  selected={estimateinput.start_date}
                  onChange={(date) => {
                    setEstimateInput({
                      ...estimateinput,
                      start_date: date,
                    });
                  }}
                  filterDate={excludedDate}
                >
                  <div className="datepicker-label">
                    {_l("l_select_date_and_time")}
                  </div>
                </DatePicker>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box pb-4 position-relative">
            <Form.Label className="input-label no-asterisk">
              {_l("l_end_date")}
            </Form.Label>
            <div
              className="custom-datepicker WithFixWidthAndSelectYear react-datepicker-wrapper-w-100"
              data-bs-toggle="tooltip"
            >
              <div className="d-flex align-items-center form-control  color-white-60 dropdown-center dropdown">
                <CalendarBlankIcon
                  className="HW18 opacity-60 m-0 me-2"
                />
                <DatePicker
                  portalId="custom_datepicker"
                  calendarClassName="custom-datepicker"
                  placeholderText={`${_l("l_end_date_placeholder")}`}
                  dateFormat="dd/MM/yyyy"
                  selectsStart
                  calendarStartDay={1}
                  selected={estimateinput.end_date}
                  onChange={(date) => {
                    setEstimateInput({
                      ...estimateinput,
                      end_date: date,
                    });
                  }}
                >
                  <div className="datepicker-label">
                    {_l("l_select_date_and_time")}
                  </div>
                </DatePicker>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3  ">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_estimate_type")}
            </Form.Label>
            <div className="d-flex">
              <Select
                className="custom-select-menu flex-grow-1"
                placeholder={_l("l_select")}
                options={estimateTypes}
                value={estimateinput.estimate_type}
                onChange={(e) => {
                  setEstimateInput({ ...estimateinput, estimate_type: e });
                }}
                classNamePrefix="react-select"
              />
            </div>
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3 ">
            <Form.Label className="input-label no-asterisk ">
              {_l("l_payment_mode")}
            </Form.Label>
            <div className="d-flex">
              <Select
                className="custom-select-menu flex-grow-1"
                placeholder={_l("l_select")}
                options={paymentModes}
                value={estimateinput.payment_mode}
                onChange={(e) => {
                  setEstimateInput({ ...estimateinput, payment_mode: e });
                }}
                classNamePrefix="react-select"
              />
            </div>
          </Form.Group>
          <Form.Group className="col-xl-4 c-input-box position-relative mb-3  ">
            <Form.Label className="input-label no-asterisk ">
              <div className="input-label no-asterisk  form-label mb-0 me-10px">
                {_l("l_recurring")}
              </div>
            </Form.Label>
            <div className="d-flex mt-3">
              <Form.Label className="form-check-label text_wrap  mb-0 ps-0">
                <Form.Check
                  inline
                  name="recurring"
                  label="Yes"
                  type="radio"
                  value={1}
                  checked={Number(estimateinput.recurring) == 1}
                  onChange={(e) => {
                    setEstimateInput({
                      ...estimateinput,
                      recurring: e.target.value,
                    });
                  }}
                />
              </Form.Label>
              <Form.Label className="form-check-label text_wrap mb-0">
                <Form.Check
                  inline
                  name="recurring"
                  label="No"
                  type="radio"
                  value={0}
                  checked={Number(estimateinput.recurring) == 0}
                  onChange={(e) => {
                    setEstimateInput({
                      ...estimateinput,
                      recurring: e.target.value,
                    });
                  }}
                />
              </Form.Label>
            </div>
          </Form.Group>
        </div>
  
        <div className="border-bottom pe-10px ps-10px row">
          <Form.Group className="col-12 c-input-box position-relative mb-3 ">
            <div className="d-flex align-items-center mb-2 comman_action_icon">
              <Form.Label className="input-label no-asterisk mb-0">
                {_l("l_bill_to")}
              </Form.Label>
              <a
                href="#/"
                className="action_icon h20w20 with_bg"
                onClick={() => {
                  setAddressModal(true);
                  setAddressModalKey("billing_address");
                }}
              >
                <PencilLineIcon className="HW14" />
              </a>
            </div>
            <div className="d-flex align-items-center gap10 title-fonts">
              <div className="align-items-center d-flex gap-1">
               <><span className="color-white-60"> {_l("l_street")} </span> : <span className="color-white"> {estimateinput.billing_address && estimateinput.billing_address.billing_street || "-"} </span></>
              </div>
              <div className="align-items-center d-flex gap-1">
              <><span className="color-white-60"> {_l("l_city")} </span> : <span className="color-white"> {estimateinput.billing_address && estimateinput.billing_address.billing_city || "-"} </span></> 
              </div>
              <div className="align-items-center d-flex gap-1">
              <><span className="color-white-60"> {_l("l_state")} </span> : <span className="color-white"> {estimateinput.billing_address && estimateinput.billing_address.billing_state || "-"} </span></> 
              </div>
              <div className="align-items-center d-flex gap-1">
              <><span className="color-white-60"> {_l("l_zip_code")} </span> : <span className="color-white"> {estimateinput.billing_address && estimateinput.billing_address.billing_zip || "-"} </span></> 
              </div>
              <div className="align-items-center d-flex gap-1">
              <><span className="color-white-60"> {_l("l_country")} </span> : <span className="color-white"> {estimateinput.billing_address && estimateinput.billing_address.billing_country || "-"} </span></> 
              </div>
            </div>
          </Form.Group>
        </div>
  
        <div className="row m-0 border-bottom">
          <div className=" d-flex flex-column pt-3 p-0">
            <div className="comman-content-scroll-wrapper">
              <div className="comman-content-scroll">
                <div className="GeneralItemTable comman-data-table pe-2 ps-2">
                  <table className="dataTable comman-table smaller-table">
                    <thead className="bg-transparent">
                      <tr className="bg-white-03">
                        <th>{_l("l_item")}</th>
                        <th>{_l("l_description")}</th>
                        <th>{_l("l_qty")}</th>
                        <th>{_l("l_rate")}</th>
                        <th>{_l("l_tax")} (%)</th>
                        <th>{_l("l_amount")}</th>
                        <th className="text-center">
                          <GearSixIcon className="HW18" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {estimateItems.length &&
                        estimateItems.map((item, index) => {
                          return (
                            <tr className="">
                              <td className="max-width-100 text-truncate border-bottom">
                                <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control
                                    as="textarea"
                                    placeholder={_l("l_description")}
                                    rows={1}
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    value={estimateItems[index].description}
                                    onChange={(e) => {
                                      const items = [...estimateItems];
                                      items[index] = {
                                        ...items[index],
                                        description: e.target.value,
                                      };
                                      setEstimateItems(items);
                                    }}
                                    onBlur={() =>
                                      setEstimateInput({
                                        ...estimateinput,
                                        items: estimateItems,
                                      })
                                    }
                                  />
                                </div>
                              </td>
                              <td className="max-width-100 text-truncate border-bottom">
                                <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control
                                    as="textarea"
                                    placeholder={_l("l_description")}
                                    rows={1}
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    value={estimateItems[index].long_description}
                                    onChange={(e) => {
                                      const items = [...estimateItems];
                                      items[index] = {
                                        ...items[index],
                                        long_description: e.target.value,
                                      };
                                      setEstimateItems(items);
                                    }}
                                    onBlur={() =>
                                      setEstimateInput({
                                        ...estimateinput,
                                        items: estimateItems,
                                      })
                                    }
                                  />
                                </div>
                              </td>
                              <td className="max-width-100 text-truncate border-bottom">
                                <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control
                                    type="number"
                                    placeholder={_l("l_quantity")}
                                    aria-label="Username"
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    min="0"
                                    value={estimateItems[index].qty}
                                    onChange={(e) => {
                                      const items = [...estimateItems];
                                      let amount = 0;
                                      if (
                                        items[index].taxtnamewrapper &&
                                        items[index].rate > 0
                                      ) {
                                        amount =
                                          items[index].rate * e.target.value;
                                      }
                                      items[index] = {
                                        ...items[index],
                                        qty: e.target.value,
                                        item_total: amount,
                                      };
                                      setEstimateItems(items);
                                    }}
                                    onBlur={() =>
                                      setEstimateInput({
                                        ...estimateinput,
                                        items: estimateItems,
                                      })
                                    }
                                  />
                                </div>
                              </td>
                              <td className="max-width-100 text-truncate border-bottom">
                                <div className="color-white-60 c-input-box position-relative">
                                  <Form.Control
                                    type="number"
                                    placeholder={_l("l_rate")}
                                    aria-label="Username"
                                    className="inputh30 pb-1 ps-2 pt-1"
                                    min="0"
                                    value={estimateItems[index].rate}
                                    onChange={(e) => {
                                      const items = [...estimateItems];
                                      let amount = 0;
                                      let taxAmount = 0;
                                      if (items[index].taxtnamewrapper) {
                                        amount =
                                          items[index].qty * e.target.value;
                                        let percentage =
                                          items[index].taxtnamewrapper.taxrate;
                                        taxAmount =
                                          ((e.target.value * percentage) / 100) *
                                          items[index].qty;
                                      }
                                      items[index] = {
                                        ...items[index],
                                        rate: e.target.value,
                                        item_total: amount,
                                        tax_amount: taxAmount,
                                      };
                                      setEstimateItems(items);
                                    }}
                                    onBlur={() =>
                                      setEstimateInput({
                                        ...estimateinput,
                                        items: estimateItems,
                                      })
                                    }
                                  />
                                </div>
                              </td>
                              <td className="max-width-100 z-3 border-bottom">
                                <div className="color-white-60 c-input-box position-relative">
                                  <div className="d-flex">
                                    <Select
                                      placeholder={_l("l_select")}
                                      className="custom-select-menu flex-grow-1 h_30px"
                                      options={taxFeild}
                                      classNamePrefix="react-select"
                                      value={estimateItems[index].taxname}
                                      onChange={(e) => {
                                        const items = [...estimateItems];
                                        let amount = 0;
                                        let taxAmount = 0;
                                        if (items[index].rate > 0) {
                                          let total =
                                            items[index].qty * items[index].rate;
                                          let percentage = e.taxrate;
                                          taxAmount =
                                            ((items[index].rate * e.taxrate) /
                                              100) *
                                            items[index].qty;
                                          amount =
                                            total + (percentage / 100) * total;
                                        }
                                        items[index] = {
                                          ...items[index],
                                          taxname: e.value,
                                          taxtnamewrapper: e,
                                          item_total: amount,
                                          tax_amount: taxAmount,
                                        };
                                        setEstimateItems(items);
                                        setEstimateInput({
                                          ...estimateinput,
                                          items: items,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td className="max-width-100 text-truncate border-bottom text-center title-fonts">
                                {estimateItems[index].item_total}
                              </td>
                              <td className="comman_action_icon max-width-100 text-truncate border-bottom text-center ">
                                <Button
                                  size="sm"
                                  onClick={() => {
                                    if (addNewItemValidation(index)) {
                                      const newItem = estimateItems.concat([
                                        {
                                          description: "",
                                          long_description: "",
                                          order: 0,
                                          qty: 0,
                                          rate: 0,
                                          taxtnamewrapper: taxFeild[0],
                                          taxname: taxFeild[0].value,
                                          item_total: 0,
                                          tax_amount: 0,
                                        },
                                      ]);
                                      setEstimateItems(newItem);
                                    }
                                  }}
                                >
                                  {_l("l_add_item")}
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
  
                      <tr className="">
                        <td colSpan={6} className="text-end border-bottom pe-5  ">
                          {_l("l_sub_total")}
                        </td>
                        <td className="border-bottom  color-white fw-semibold text-end">
                          {estimateinput.estimate_subtotal}
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={6} className="text-end border-bottom  pe-0">
                          <Form.Group className=" c-input-box position-relative d-flex align-items-center justify-content-end">
                            <Form.Label className="input-label no-asterisk mb-0">
                              {taxFeild.length > 0 && taxFeild[0].label}
                            </Form.Label>
                            <div className="d-flex  ">
                              <Form.Control
                                type="number"
                                placeholder={estimateinput.tax_amount_total}
                                className="inputh30 pb-1 ps-2 pt-1"
                                value={estimateinput.tax_amount_total}
                                disabled
                              />
                            </div>
                          </Form.Group>
                        </td>
                        <td className="border-bottom  color-white fw-semibold text-end">
                          {estimateinput.tax_amount_total}
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={6} className="text-end border-bottom  pe-0">
                          <Form.Group className=" c-input-box position-relative d-flex align-items-center justify-content-end">
                            <Form.Label className="input-label no-asterisk mb-0">
                              {`${_l("l_discount")}`}
                              <span className="c-font f-10 ps-10px color-white">
                                {_l("l_fixed_amount")}
                              </span>
                            </Form.Label>
                            <div className="d-flex gap-2 ">
                              <Form.Control
                                type="number"
                                placeholder={_l("l_discount")}
                                aria-describedby="basic-addon1"
                                className="inputh30 pb-1 ps-2 pt-1"
                                value={estimateinput.discount_total}
                                onChange={(e) => {
                                  setEstimateInput({
                                    ...estimateinput,
                                    discount_total: e.target.value,
                                    estimate_total: (
                                      parseFloat(
                                        estimateinput.estimate_subtotal
                                      ) +
                                      parseFloat(estimateinput.tax_amount_total) -
                                      e.target.value
                                    ).toFixed(2),
                                  });
                                }}
                              />
                            </div>
                          </Form.Group>
                        </td>
                        <td className="border-bottom  color-white fw-semibold text-end">
                          {estimateinput.discount_total}
                        </td>
                      </tr>
                      <tr className="">
                        <td colSpan={6} className="text-end color-green pe-5">
                          {_l("l_total")}
                        </td>
                        <td className="fw-semibold  color-green text-end title-fonts">
                          {`€${estimateinput.estimate_total}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="border-top c-input-box pb-10px pt-10px terms-condition">
                    <div className=" fw-semibold pb-2">{_l("l_client_note")}</div>
                    <Form.Control
                      as="textarea"
                      placeholder={_l("l_description")}
                      rows={2}
                      value={estimateinput.client_note}
                      onChange={(e) => {
                        setEstimateInput({
                          ...estimateinput,
                          client_note: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="terms-condition">
                    <div className=" fw-semibold pb-2">
                      {_l("l_terms_conditions")}
                    </div>
                    <div className=" pb-3 d-flex ">
                      <Form.Control
                        as="textarea"
                        placeholder={_l("l_terms_conditions")}
                        rows={2}
                        value={estimateinput.terms}
                        onChange={(e) => {
                          setEstimateInput({
                            ...estimateinput,
                            terms: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

const AddEstimateModal = ({ show, handleClose, isModal = true, setSaveAndDraftEstimate, saveAndDraftEstimate, seteditEstimateSubmit, editEstimateSubmit, setIsEditable, setData }) => {

  const estimateTypes = [
    {
      value: "custom",
      label: _l("l_custom"),
    },
    {
      value: "benchmark",
      label: _l("l_benchmark"),
    },
    {
      value: "premium_membership",
      label: _l("l_premium_membership"),
    },
    {
      value: "operator_premium",
      label: _l("l_operator_premium_membership"),
    }
  ]

  const paymentModes = [
    {
      value: "gocardless",
      label: _l("GoCardless"),
    },
    {
      value: "stripe",
      label: _l("Stripe"),
    },
  ]

  const estimateData = JSON.parse(localStorage.getItem("estimateData"));

  const staffId = localStorage.getItem("staff_id") ? localStorage.getItem("staff_id") : null
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const projectId = isModal ? localStorage.getItem("isLiteVersion") == "true" ? 0 : localStorage.getItem("selectedOffice") : estimateData && estimateData.project_id;
  const selectedSpace = spaces && spaces.filter((item) => item.project_id == projectId);
  const spaceClientId = selectedSpace && selectedSpace.client_id

  const spacesOption = spaces && spaces.map((sp) => {
    return { value: sp.project_id, label: sp.office_title };
  })

  const initialInput = {
    customer_client_id: null,
    project_id: projectId,
    staff_id: staffId,
    customer_company_name: "",
    estimateno: 0,
    start_date: null,
    end_date: null,
    estimate_type: estimateTypes[0],
    payment_mode: paymentModes[0],
    recurring: 0,
    name: "",
    billing_address: null,
    shipping_address: null,
    show_shipping_on_estimate : 0,
    items: [],
    address: "",
    estimate_number: null,
    estimate_subtotal: 0,
    discount_total: 0,
    estimate_total: 0,
    tax_amount_total: 0,
    adjustment: 0,
    client_note: "",
    terms: "",
  };
 
  const dispatch = useDispatch();

  const [customers, setCustomers] = useState([]);
  const [taxFeild, setTaxFeild] = useState([]);
  const [estimateinput, setEstimateInput] = useState(initialInput);
  const [estimateItems, setEstimateItems] = useState([{
    description: "",
    long_description: "",
    order: 0,
    qty: 0,
    rate: 0,
    taxname: null,
    taxtnamewrapper: null,
    item_total: 0,
    tax_amount: 0
  },]);
  const [addressModal, setAddressModal] = useState(false);
  const [addressModalKey, setAddressModalKey] = useState("");
  const [emailModal, setEmailModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editEstimateTata, setEditEstimateTata] = useState({});

  
  
  const excludedDate = (date) => {
    const day = Number(moment(date).format("D"));
      return ![29,30,31].includes(day);
  }

  useEffect(() => {
    let defaultSpace = estimateinput.customer_client_id;
    let estimateNumber = estimateinput.estimate_number;
    let termsText = estimateinput.terms

    CustomerServices.getCustomerEstimate(projectId).then((res) => {
      if (res.status == "1") {
        const response = res.data
        setCustomers(
          response.map((customer) => {
            let defaultSpace= null
            if (customer.userid == spaceClientId) {
              defaultSpace = {
               value : customer.userid,
               label : customer.company
              }
            }
            return {
              value: customer.userid,
              label: customer.company,
            };
          })
        )
        if (isModal) {
          setEstimateInput({...estimateinput, estimate_number : estimateNumber, terms: termsText, customer_client_id : defaultSpace})
        }
      }
    })

    CustomerServices.getAllTaxName().then((res) => {
      if (res.status == "1") {
        const response = res.data
        const taxFieldWrapper = response.map((tax) => {
          return {
            value: tax.id,
            label: `${tax.name} ${tax.taxrate}%`,
            taxrate: parseFloat(tax.taxrate)
          };
        })
        setTaxFeild(taxFieldWrapper)
        if (isModal) {
        const items = [...estimateItems]
        items[0] = {...items[0], taxname : taxFieldWrapper[0].value, taxtnamewrapper: taxFieldWrapper[0] }
        setEstimateItems(items)
        }
      }
    })

    if (isModal) {   
    CustomerServices.getEstimateTerms(staffId).then((res) => {
      if (res.status == "1") {
        const response = res.data
        estimateNumber = `${response.prefix} ${response.estimate}`;
        termsText = response.terms
        setEstimateInput({...estimateinput, estimate_number : estimateNumber, terms: termsText, customer_client_id : defaultSpace})
      }
    })
    }

  }, []);


  const validateInputFields = () => {
    let validationSuccess = true;

    const  {
      customer_client_id,
      start_date,
      recurring,
      items,
      terms
    } = estimateinput
    let errMessage; 
    
    if (!terms.trim()) {
      errMessage = 'Please Aggree our terms and conditons';
      validationSuccess = false 
    }
    if (items.length == 0) {
      errMessage = 'Please enter at least one item';
      validationSuccess = false 
    }
    if (recurring == null) {
      errMessage = 'Please select if Estimate is recurring or not.';
      validationSuccess = false 
    }
    if (start_date == null) {
      errMessage = 'Please select if Estimate is recurring or not.';
      validationSuccess = false 
    }
    if (start_date == null) {
      errMessage = 'Start Date is required';
      validationSuccess = false 
    }
    if (customer_client_id == null) {
      errMessage = 'Please Select Customer';
      validationSuccess = false 
    }

    if (!validationSuccess) {
      dispatch(
        showMessage("unsucess", _l("l_error"), `${errMessage}`)
      );
    }
    return validationSuccess;
  };

  const createNewEstimate = (saveAndSend, sendEmailData = {}) => {
    if (validateInputFields()) {
      setLoader(saveAndSend ? "" : "create")
      benchmarkServices.createEstimate(estimateinput, saveAndSend, sendEmailData, isModal).then((res) => {
        if (res.status) {
          dispatch(showMessage("sucess", _l("l_success"), !isModal ? _l("l_estimate") + '' +  _l("l_update"): _l("l_estimate_created")));
          if (!isModal) {
            documentsServices.getEstimateDetail('', editEstimateTata.estimate_id, editEstimateTata.hash).then((response) => {
              if (response.status) {
                setData(response.data)
                seteditEstimateSubmit('');
                setIsEditable(false);
              }else{
                seteditEstimateSubmit('');
                setIsEditable(false);
              }
            });
          }else{
            setTimeout(() => {
              handleClose({
                hash: res.data.estimate_hash,
                type:"estimate"
              })
            }, 1000);
          }
          setLoader(false)
        }
        else {
          if (!isModal) {
            seteditEstimateSubmit('');
          }
          setLoader(false)
          dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
        }
      });
    }
  };

  const addNewItemValidation = (index) => {
    let validationSuccess = true;
    let errMessage;
    const {qty, description} = estimateItems[index]

    if (qty == 0) {
      errMessage = 'Quantity of item should be greater than 0';
      validationSuccess = false 
    }

    if (!description.trim()) {
      errMessage = 'Description is required';
      validationSuccess = false 
    }

    if (!validationSuccess) {
      dispatch(
        showMessage("unsucess", _l("l_error"), `${errMessage}`)
      );
    }
    return validationSuccess;
  }

  useEffect(() => {
    let estimateTotal = 0;
    let taxSum = 0;
    let estimateSubtotal = 0;
    estimateItems.length && estimateItems.map((item, index) => {
      taxSum = taxSum + item.tax_amount
      estimateSubtotal = estimateSubtotal + item.item_total
      estimateTotal = estimateSubtotal + taxSum
    })
    
    setEstimateInput({...estimateinput, estimate_subtotal: estimateSubtotal > 0 ?  estimateSubtotal.toFixed(2) :  0, estimate_total: (estimateTotal - estimateinput.discount_total).toFixed(2), tax_amount_total: taxSum.toFixed(2)})
  }, [estimateItems])
  
  useEffect(() => {
    if (!isModal && editEstimateTata && customers && customers.length > 0 && taxFeild && taxFeild.length > 0) {
      setEstimateInput({...estimateinput,
        customer_client_id : customers.find((client) => Number(client.value) == Number(editEstimateTata.clientid)),
        customer_company_name : editEstimateTata.custom_company_name,
        estimate_number : editEstimateTata.name,
        estimate_type : estimateTypes.find((item) => item.value == editEstimateTata.type),
        payment_mode : paymentModes.find(pay => pay.value == editEstimateTata.payment_mode),
        recurring : null,
        items : editEstimateTata.items,
        terms :editEstimateTata.terms,
        client_note : '',
        end_date :editEstimateTata.expirydate ? new Date(editEstimateTata.expirydate) : null,
        start_date : editEstimateTata.date ? new Date(editEstimateTata.date) : null,
        shipping_address : {
          shipping_street: editEstimateTata.shipping_street,
          shipping_city: editEstimateTata.shipping_city,
          shipping_state: editEstimateTata.shipping_state, 
          shipping_zip: editEstimateTata.shipping_zip,
          shipping_country: editEstimateTata.shipping_country},
        billing_address : {
          billing_street: editEstimateTata.billing_street,
          billing_city: editEstimateTata.billing_city,
          billing_state: editEstimateTata.billing_state,
          billing_zip: editEstimateTata.billing_zip,
          billing_country: editEstimateTata.billing_country,
        },
        recurring : editEstimateTata.recurring,
        address : editEstimateTata.addresses,
        client_note : editEstimateTata.clientnote,
        estimate_id : editEstimateTata.estimate_id,
        project_id : editEstimateTata.project_id
      })
      const setTitems = editEstimateTata.items && editEstimateTata.items.map((item) => {
        item.amount = Number(item.amount.slice(1))
        item.tax_amount = Number(item.amount * 0.20)
        item.item_total = Number(item.amount)
        item.rate = Number(item.rate.slice(1))
        item.taxtnamewrapper = taxFeild.find((tax) => tax.label == item.tax_rate)
        item.taxname = taxFeild.find((tax) => tax.label == item.tax_rate) ? taxFeild.find((tax) => tax.label == item.tax_rate).value : 0
          return item
      })
      setEstimateItems(setTitems.slice())
    }   
  }, [customers, taxFeild])

  useEffect(() => {
    setEditEstimateTata(estimateData);
  }, [JSON.stringify(estimateData)])

  useEffect(() => {
    if (saveAndDraftEstimate) {
      if (validateInputFields()) {
        setEmailModal(true);
      }
    }
  }, [saveAndDraftEstimate])
  
  useEffect(() => {
    if (editEstimateSubmit == "editEstimateSubmit") {
      createNewEstimate(0);
    }
  },[editEstimateSubmit])
  // console.log("editEstimateTata", editEstimateTata, "estimateinput", estimateinput, "estimateItems", estimateItems);
  return (
    <>
      {isModal ? 
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style w1300px"
        aria-labelledby="contained-modal-title-vcenter"
        size="xl"
        centered
        scrollable
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_create_estimate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <ModalBodyEstamate 
            customers = {customers}
            estimateinput ={estimateinput}
            setEstimateInput ={setEstimateInput}
            selectedSpace ={selectedSpace}
            excludedDate ={excludedDate}
            estimateTypes ={estimateTypes}
            paymentModes ={paymentModes}
            setAddressModal={setAddressModal}
            projectId={projectId}
            staffId={staffId}
            setAddressModalKey={setAddressModalKey}
            estimateItems={estimateItems}
            taxFeild={taxFeild}
            setEstimateItems={setEstimateItems}
            addNewItemValidation={addNewItemValidation}
            spacesOption={spacesOption}

          />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_cancel")}
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              if (validateInputFields()) {
                setEmailModal(true)
              }
            }}
            disabled={!loader == "" ? 1 : 0}
          >
            { loader == "saveAndSend" ? _l('l_please_wait') : _l("l_save_and_send") }
            { loader == "saveAndSend" ?
              <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              : <></>
            }
          </Button>
          <Button
            variant="primary" size="sm"
            onClick={() => {
              createNewEstimate(0)
            }}
            disabled={!loader == "" ? 1 : 0}
          >
            { loader == "create" ? _l('l_please_wait') : _l("l_submit") }
            { loader == "create"  ?
              <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              : <></>
            }
          </Button>
        </Modal.Footer>
      </Modal>
      :
      <ModalBodyEstamate 
            customers = {customers}
            estimateinput ={estimateinput}
            setEstimateInput ={setEstimateInput}
            selectedSpace ={selectedSpace}
            excludedDate ={excludedDate}
            estimateTypes ={estimateTypes}
            paymentModes ={paymentModes}
            setAddressModal={setAddressModal}
            projectId={projectId}
            staffId={staffId}
            setAddressModalKey={setAddressModalKey}
            estimateItems={estimateItems}
            taxFeild={taxFeild}
            setEstimateItems={setEstimateItems}
            addNewItemValidation={addNewItemValidation}
            spacesOption={spacesOption}

          />
      }

      { addressModal 
        ? <AddressModal 
            show={addressModal}
            modalKey={addressModalKey}
            data = {estimateinput[addressModalKey]}
            handleClose={() => {setAddressModal(false)}}
            handleInput={(address, address2, checked) => {
              if (checked) {
                setEstimateInput({ ...estimateinput, [addressModalKey] : address, shipping_address : address2 });
              }else{
                setEstimateInput({ ...estimateinput, [addressModalKey] : address });
              } 
              setAddressModal(false)
              setAddressModalKey("")
            }}
          />
        : <></>
      }

      { emailModal
        ? <AddEmailTemplatesModalNew
            show={emailModal}
            handleClose={() => {
              if (!isModal) {
                setSaveAndDraftEstimate(false);
              }
              setEmailModal(false);
            }}
            type={"estimate"}
            from={"newEstimate"}
            createEstimateHandler={(sendEmailData) => {
              createNewEstimate(1, sendEmailData)
              setEmailModal(false)
              if (!isModal) {
                setSaveAndDraftEstimate(false);
              }
            }}
          />
        : <></>
      }
    </>
  );
};


export default AddEstimateModal;