import React from 'react';
 

function HashIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M3.11562 6H14.3906M11.3906 2.5L9.39062 13.5M7.39062 2.5L5.39062 13.5M2.39062 10H13.6656" />
			</svg>


		</React.Fragment>
	)
}

export default HashIcon