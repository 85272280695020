import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, getDatePickerLocale } from "../../hooks/utilities";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";

const BookThreedPlan = ({
  projectId,
  show,
  handleClose
}) => {
  const { accessOfficeData } = useSelector((state) => state.customer);
  const [firstName, setFirstName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [scanDate, setScanDate] = useState("");
  const [floorSurface, setFloorSurface] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [wifiCode, setWifiCode] = useState("");
  const [accessCode, setAccessCode] = useState(accessOfficeData && accessOfficeData.accese_code);
  const [accessDescription, setAccessDescription] = useState("");
  const [indoorScan, setIndoorScan] = useState(false);
  const [outdoorScan, setOutdoorScan] = useState(false);
  const [loder, setLoder] = useState(false);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState({
    value: "+33",
    label: "+33",
  });
  const optionsStrict = {
    componentRestrictions: {
      country: ["nl", "fr", "uk", "de", "esp", "ita", "bel", "che"],
    },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
    types: ["address"],
  };
  const numberlisting = [
    {
      value: "1",
      label: "+31 ",
    },
    {
      value: "2",
      label: "+32 ",
    },
    {
      value: "3",
      label: "+33 ",
    },
    {
      value: "4",
      label: "+35 ",
    },
    {
      value: "5",
      label: "+34 ",
    },
  ];

var spaces = JSON.parse(localStorage.getItem("spaces"));
var selectedSpace = spaces.filter((item) => item.project_id == projectId);
useEffect(() => {
  const phoneNumber = accessOfficeData.phonenumber &&  accessOfficeData.phonenumber.split(' ')[1] ? accessOfficeData.phonenumber.split(' ')[1] : accessOfficeData.phonenumber;
  setFirstName(accessOfficeData && accessOfficeData.name);
  setMobileNumber(phoneNumber && phoneNumber.trim());
  setWifiCode(accessOfficeData && accessOfficeData.wifi_code);
  setAccessCode(accessOfficeData && accessOfficeData.accese_code);
  setAccessDescription(accessOfficeData && accessOfficeData.description.trim());
  setPostalAddress(selectedSpace[0] && selectedSpace[0].postal_address);
  setFloorSurface(selectedSpace[0] && selectedSpace[0].superficies);
  
}, [accessOfficeData])

  const handleSave = () => {
    if (validateInputFields()) {
      setLoder(true);
      let formattedDate = moment(scanDate).format("YYYY-MM-DD")
      let mobileTemp = countryCode.value + " " + mobileNumber.trim();

      CustomerServices.scan3dPlan(
        projectId,
        firstName,
        mobileTemp,
        formattedDate,
        floorSurface,
        postalAddress,
        wifiCode,
        accessCode,
        accessDescription,
        indoorScan,
        outdoorScan).then((res)=> {
          if (res.status == 1 ) {
            setLoder(false);
            handleClose();
            dispatch(showMessage('sucess', _l('l_success'), res.message ? res.message : _l('l_something_went_wrong')));            
          } else {
            setLoder(false);
            dispatch(showMessage('unsucess', _l('l_error'), res.message ));   
          }

        })
    }
  }

 
  const validateInputFields = () => {

    if (!firstName || !firstName.trim().length) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_name')));
      return false;
    }  else if (mobileNumber == "" || !mobileNumber.trim().length ) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_valid_mobile_number')));
      return false;
    } else if (!scanDate || scanDate == "") {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_select_scandate')));
      return false;
    } else if (!floorSurface || !floorSurface.trim().length) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_floor_surface')));
      return false;
    } else if (!postalAddress || !postalAddress.trim().length) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_address')));
      return false;
    } 
    
    return true;
  };

  const handleSelect = async (value) => {
    setPostalAddress(value);
    geocodeByAddress(value)
      .then(() => {
      })
      .catch((error) => console.error("Error", error));
  };

  const autosearchPlaces = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }) => {
    return (
      <div>
        <Form.Control
          placeholder={`${_l("l_address_placeholder")}`}
          type="text"
          name="address_3dplan"
          {...getInputProps({
            placeholder: "Avenue de France, Paris, France",
          })}
        />
        <div className="custom-map-suggestion border-0">
          {loading && <div>{_l("l_loading")}</div>}
          {suggestions.map((suggestion, index) => {
            return (
              <div {...getSuggestionItemProps(suggestion)} key={index}>
                <a>{suggestion.description}</a>
              </div>
            );
          })}
        </div>
      </div>
    );
  };


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l('l_schedule_a_3d_scan')}</Modal.Title>
          <div className="d-flex justify-content-end flex-grow-1">
            <div className={`with_separator_10 align-items-center d-flex
                  }`}>
              <Form.Check
                className="align-items-center d-flex "
                type="checkbox"
                label={_l('l_indoor_scan')}
                onChange={(e)=>{setIndoorScan(e.target.checked)}}
              />
            </div>
            <div className={`with_separator_10 align-items-center d-flex
                  }`}>
              <Form.Check
                className="align-items-center d-flex "
                type="checkbox"
                label={_l('l_outdoor_scan')}
                onChange={(e)=>{setOutdoorScan(e.target.checked)}}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row">
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative pe-3">
                <Form.Label className="input-label">
                  {_l("l_name")}
                </Form.Label>
                <Form.Control
                  placeholder={_l("l_full_name")}
                  type="text"
                  value={firstName}
                  onChange={(e)=>{setFirstName(e.target.value)}}
                />
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box position-relative">
                <Form.Label className="input-label">
                  {_l('l_mobile_number')}
                </Form.Label>
                <div className="row">
                  <div
                    className="c_input_border drop_image col-3 col-md-3  col-xl-3 position-relative"
                  >
                    <Select
                      className="custom-select-menu scroll-height-30vh"
                      classNamePrefix="react-select"
                      options={numberlisting}
                      placeholder={`${_l("l_region")}`}
                      value={countryCode}
                      onChange={(value) => {
                        setCountryCode(value);
                      }}
                    />
                  </div>
                  <div className="col-9 col-md-9 col-xl-9 ps-md-0">
                    <Form.Control
                      placeholder={_l('l_mobile_number')}
                      type="number"
                      value={mobileNumber}
                      onChange={(e)=>{setMobileNumber(e.target.value)}}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label">
                  {_l('l_3d_scan_date')}
                </Form.Label>
                <div
                  className="custom-datepicker"
                  data-bs-toggle="tooltip"
                  title="Aug, 8 2022"
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown react-datepicker-wrapper-w-100">
                    <CalendarBlankIcon className="HW18" />
                    <DatePicker
                      selected={scanDate}
                      placeholderText={_l('l_select_scan_date')}
                      onChange={(date) => setScanDate(date)}
                      disabledKeyboardNavigation
                      locale={getDatePickerLocale()}
                      dateFormat="dd/MM/yyyy"
                      selectsStart
                      defaultMenuIsOpen
                      minDate={ new Date(moment().add("days", 15).format("YYYY-MM-DD"))}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }} 
                      calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label">
                  {`${_l('l_floor_system_generated_title')} ${_l('l_surface')}`}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Form.Control
                    placeholder={`${_l('l_floor_system_generated_title')} ${_l('l_surface')}`}
                    type="number"
                    value={floorSurface}
                    onChange={(e)=>{setFloorSurface(e.target.value)}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                <Form.Label className="input-label">
                  {_l('l_address')}
                </Form.Label>
                 <PlacesAutocomplete
                  value={postalAddress}
                  onChange={setPostalAddress}
                  onSelect={handleSelect}
                  searchOptions={optionsStrict}
                >
                  {autosearchPlaces}
                </PlacesAutocomplete>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l('l_wifi_code')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Form.Control
                    placeholder={_l('l_wifi_code')}
                    type="text"
                    value={wifiCode}
                    onChange={(e)=>{setWifiCode(e.target.value)}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l('l_access_code')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Form.Control
                    placeholder={_l('l_access_code')}
                    type="text"
                    value={accessCode}
                    onChange={(e)=>{setAccessCode(e.target.value)}}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-12 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l('l_access_description')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Form.Control as="textarea" rows={3}
                  value={accessDescription}
                  onChange={(e)=>{setAccessDescription(e.target.value)}}
                   />
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" 
          className={`${loder ? "for-disabled" : ""}`}
          onClick={
            () => {
              if (!loder) {

                handleSave()
              }
            }
          }>
            {loder
              ? _l('l_please_wait')
              : _l('l_save')}
            {loder ? <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              className={`ms-2 `}
              aria-hidden="true"
            />
              : <></>
            }

          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BookThreedPlan;
