import {
  CHANGE_DOCUMENT_PRIORITY,
  CHECK_GOOGLE_DRIVE_CONNECTIVITY,
  DRIVE_TOPICS,
  FETCH_DOCUMENT_LIST,
  UPDATE_DOCUMENT_PIN,
  UPDATE_DOCUMENT_STATUS,
  CHECK_ONEDRIVE_CONNECTIVITY,
} from "../actions/action-type";

export default function (state = [], action) {
  const { payload } = action;
  switch (action.type) {
    case CHECK_GOOGLE_DRIVE_CONNECTIVITY:
      return { ...state, drive_connectivity: action.payload };

    case CHECK_ONEDRIVE_CONNECTIVITY:
        return { ...state, onedriveStatus: action.payload };
        
    case DRIVE_TOPICS:
      return { ...state, topics: action.payload };

    case FETCH_DOCUMENT_LIST:
      let provider = payload.data && payload.data.data && payload.data.data.provider ? payload.data.data.provider : "";
      if(provider === 'one_drive')
      {
        return {
          ...state,
          documentList : payload.data.data.files ? payload.data.data.files : [],
          foldersList : payload.data.data.folders ? payload.data.data.folders : [],
        }
      } else {
        let sortedDocumentList =
          payload.data && payload.data.data ? payload.data.data : [];
        sortedDocumentList = sortedDocumentList.sort((a, b) => {
          if (b.priority === a.priority) {
            return parseInt(b.pinned) - parseInt(a.pinned);
          } else {
            return parseInt(b.priority) - parseInt(a.priority);
          }
        });
        return {
          ...state,
          documentList: sortedDocumentList,
          extraCountDocument: (payload.data ? payload.data.extra : []),
          UnreadCommentDocumentList: (payload.data ? payload.data.data : []),
        };
      }

    case UPDATE_DOCUMENT_STATUS:
      if (state.documentList) {
        const documentList = state.documentList;
        let targetDocument = state.selectedDocument
          ? state.selectedDocument
          : {};
        documentList.forEach((document) => {
          if (document.id === payload.payload.task_id) {
            document.id = payload.payload.original_task_id;
            document.status = payload.payload.status.toString();
          }
        });

        if (Object.keys(targetDocument).length > 0) {
          targetDocument["status"] = payload.payload.status.toString();
        } else {
          targetDocument =
            documentList[
              documentList.findIndex(
                (document) => document.id === payload.payload.document_id
              )
            ];
        }

        return {
          ...state,
          documentList: documentList,
          selectedDocument: targetDocument,
        };
      }
      return state;

    case CHANGE_DOCUMENT_PRIORITY:
      let documentList = state.documentList;

      if (documentList && documentList.length) {
        let highPriorityDocuments = [];
        let lowPriorityDocuments = [];
        let newDocumentList = [];
        documentList.forEach((document) => {
          if (document.id == payload.payload.document_id) {
            document.priority = payload.payload.documentPriority == 1 ? 0 : 1;
          }

          if (document.priority == 1) {
            highPriorityDocuments.push(document);
          } else if (document.priority == 0) {
            lowPriorityDocuments.push(document);
          }
        });
        newDocumentList = highPriorityDocuments.concat(lowPriorityDocuments);
        // if (payload.payload.documentPriority == 1) {
        //   const documentIndex = documentList.findIndex(
        //     (document) => document.id === payload.payload.document_id
        //   );
        //   if (documentIndex != -1) {
        //     highPriorityDocuments = [documentList[documentIndex]];
        //     lowPriorityDocuments = documentList;
        //     lowPriorityDocuments.splice(documentIndex, 1);
        //     newDocumentList =
        //       highPriorityDocuments.concat(lowPriorityDocuments);
        //   } else {
        //     newDocumentList = documentList;
        //   }
        // }
        let currentDocument = state.selectedDocument;
        if (currentDocument) {
          currentDocument.priority =
            payload.payload.documentPriority == 0 ? 1 : 0;
        }

        return {
          ...state,
          documentList: newDocumentList,
          selectedDocument: currentDocument,
        };
      } else {
        return state;
      }

    case UPDATE_DOCUMENT_PIN:
      let documentLists = state.documentList;
      if (documentLists && documentLists.length) {
        documentLists.forEach((document) => {
          if (document.id === payload.payload.document_id) {
            document.pinned = payload.payload.documentPinShow == 0 ? "1" : "0";
          }
        });
        let currentPinDocument = state.selectedDocument;
        if (currentPinDocument) {
          if (currentPinDocument.is_requested == 1) {
            currentPinDocument.is_pinned =
              payload.payload.documentPinShow == 0 ? "1" : "0";
          } else {
            currentPinDocument.pinned =
              payload.payload.documentPinShow == 0 ? "1" : "0";
          }
        }
        return {
          ...state,
          selectedDocument: currentPinDocument,
          documentList: documentLists,
        };
      } else {
        return state;
      }
      
    default:
      return state;
  }
}
