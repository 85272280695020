import React, { useEffect, useState } from "react";
import {
  _l,
  showError,
  showSuccess,
  validateEmail,
  validateIBAN,
} from "../../hooks/utilities";
import { Button, Form, Spinner } from "react-bootstrap";
import Sepalogo from "../../assets/icons/Sepa";
import Select from "react-select";
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete";
import { setSelectedLanguage } from "../../actions/customer";
import { useDispatch } from "react-redux";
import CustomerServices from "../../services/customer-services";
import moment from "moment";
import CelebrationIcon from "../../assets/icons/CelebrationIcon";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
const COUNTRY_LIST = [
  {
    label: "FR",
    value: "FR",
  },
];
const WalletSetupForm = () => {
  const [paymentInformation, setPaymentInformation] = useState({
    country: "FR",
    company_name: "",
    email: "",
    billing_address: "",
    city: "",
    postal_code: "",
    account_holder_name: "",
    iban: "",
    first_name: "",
    last_name: "",
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState("input");
  const dispatch = useDispatch();
  const setAdressComponents = ({ city, postal_code, address }) => {
    setPaymentInformation({
      ...paymentInformation,
      city,
      postal_code,
      billing_address: address,
    });
  };
  const validatePaymentInformation = () => {
    let flag = true;
    if (!paymentInformation.country || !paymentInformation.country.length) {
      showError("l_please_select_country_code");
      flag = false;
    } else if (
      !paymentInformation.company_name ||
      !paymentInformation.company_name.length
    ) {
      showError("l_please_enter_company_name");
      flag = false;
    } else if (
      !paymentInformation.email ||
      !paymentInformation.email.length ||
      !validateEmail(paymentInformation.email.trim())
    ) {
      showError("l_please_enter_valid_email");
      flag = false;
    } else if (
      !paymentInformation.billing_address ||
      !paymentInformation.billing_address.length
    ) {
      showError("l_please_enter_valid_billing_address");
      flag = false;
    } else if (!paymentInformation.city || !paymentInformation.city.length) {
      showError("l_please_enter_valid_city");
      flag = false;
    } else if (
      !paymentInformation.postal_code ||
      !paymentInformation.postal_code.length
    ) {
      showError("l_please_enter_valid_postal_code");
      flag = false;
    } else if (
      !paymentInformation.account_holder_name ||
      !paymentInformation.account_holder_name.length
    ) {
      showError("l_please_enter_valid_account_holder_name");
      flag = false;
    } else if (
      !paymentInformation.iban ||
      !paymentInformation.iban.length ||
      !validateIBAN(paymentInformation.iban)
    ) {
      showError("l_please_enter_valid_iban_number");
      flag = false;
    }
    if (flag) {
      setSelectedTab("summary");
    } else {
      return flag;
    }
  };
  const handleNextStep = () => {
    switch (selectedTab) {
      case "input":
        validatePaymentInformation();
        break;
      case "summary":
        savePaymentInformation();
        break;
      default:
        break;
    }
  };
  const handleBackStep = () => {
    switch (selectedTab) {
      case "summary":
        setSelectedTab("input");
        break;
      default:
        break;
    }
  };
  const savePaymentInformation = async () => {
    try {
      setIsFormSubmitting(true);
      const response = await CustomerServices.saveUserGoCardless({
        ...paymentInformation,
      });
      if (response.status) {
        showSuccess("l_details_submitted");
        setSelectedTab("confirmation");
        setTimeout(() => {
          window.location.href = "https://myr.ai/en";
        }, 3000);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFormSubmitting(false);
    }
  };
  const nextButtonText = () => {
    let text = "";
    switch (selectedTab) {
      case "input":
        text = _l("l_next");
        break;
      case "summary":
        text = _l("l_add");
        break;
    }
    return text;
  };
  useEffect(() => {
    dispatch(setSelectedLanguage("english"));
    localStorage.setItem("language", "english");
  }, []);
  return (
    <div className="onboarding-wrapper-main d-flex onboarding-with-bg with-modified-width m-auto h-100 flex-column onboarding-with-bg  mt-0imp">
      {
        selectedTab !== "confirmation"
          ?
          <div className="onboarding-header text-center p-20 position-relative ">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                {selectedTab === "summary" ? (
                  <a
                    href="#/"
                    className="border-0 btn c-font color-white f-15 title-fonts btn-sm text-nowrap ps-0"
                    onClick={handleBackStep}
                  >
                    <ArrowLeftIcon className="HW18" />
                    <span className="ms-2">{_l("l_back")}</span>
                  </a>
                ) : (
                  <></>
                )}
              </div>
              <div className=" px-3 d-flex align-items-center">
                <div className="me-2 bg-white">
                <Sepalogo
                      size={50}
                      className="c-icons"
                    />
                </div>
                <div className="d-flex flex-column">
                  <div class="c-font fw-semibold title-fonts py-2 f-20">
                  {_l("l_Set_up_Direct")} MyrAi - Gocardless
                  </div>
                </div>
              </div>
              <div className={`d-flex`}>
                <div className="d-flex right-side-button ms-auto">
                  <Button
                    variant={"primary btn-sm"}
                    className={`text-nowrap`}
                    onClick={handleNextStep}
                    disabled={isFormSubmitting}
                  >
                    {isFormSubmitting ? (
                      <>
                        <span>{_l("l_please_wait")}</span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`ms-2`}
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      <span>{nextButtonText()}</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          :
          <></>
      }
      <div className="onboarding-content-scroll-wrapper overflow-hiiden-auto d-flex flex-column scrollheight mt-15px ">
        <div className="w-50 bg-white-03 p-15 radius_3 m-auto mb-3 d-none">
          <div className="mb-15px">
            <div className="c-font f-16 fw-semibold mb-10px">
              MyrAi - Gocardless
            </div>
            <div className="mb-15px">
            {_l("l_Your_Direct_Debit")}
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div>
                <img src={Sepalogo} className=" h50px" />
              </div>
            </div>
            <div className="col-lg-6">
              <Form.Group className=" c-input-box position-relative pb-3 z-index-12 d-flex align-items-center">
                <Form.Label className="input-label no-asterisk me-2">
                  {_l("l_pay_with")}
                </Form.Label>
                <div className="c_input_border w-190 position-relative ">
                  <Select
                    className="custom-select-menu bg-white-03-solid onboarding-map-input scroll-height-30vh"
                    classNamePrefix="react-select"
                    placeholder={_l("l_select")}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className={`FormMain ${selectedTab === "input" ? "" : "d-none"}`}>
          <div className="c-font f-18 fw-semibold mb-10px">
          {_l("l_company_details")}
          </div>
          <div className="row m-1">* {_l("l_fields_are_required")}</div>
          <div className=" row">
            <Form.Group className="col-xl-6 c-input-box position-relative pb-3 z-index-12 mx-auto">
              <Form.Label className="input-label no-asterisk">
                {_l("l_country_of_residence")}
              </Form.Label>
              <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                <Select
                  className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                  classNamePrefix="react-select"
                  placeholder={_l("l_select")}
                  options={COUNTRY_LIST}
                  value={COUNTRY_LIST.find(
                    (w) => w.value == paymentInformation.country
                  )}
                  isDisabled
                />
              </div>
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {" "}
                {_l("l_company_name")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_company_name")}
                type="text"
                value={paymentInformation.company_name}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    company_name: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {" "}
                {_l("l_first_name")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_first_name")}
                type="text"
                value={paymentInformation.first_name}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    first_name: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {" "}
                {_l("l_last_name")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_last_name")}
                type="text"
                value={paymentInformation.last_name}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    last_name: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {" "}
                {_l("l_email_address_label")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_email")}
                type="text"
                value={paymentInformation.email}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    email: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <AddressAutocomplete
              className={"col-xl-6 c-input-box pb-4 position-relative"}
              value={paymentInformation.billing_address}
              placeholder={"l_billing_address"}
              label={"l_billing_address"}
              name={"billing_address"}
              setAdressComponents={setAdressComponents}
              data={paymentInformation}
            />
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
              {_l("l_town_city")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_city")}
                type="text"
                value={paymentInformation.city}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    city: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                Postcode
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_postal_address_placeholder")}
                type="text"
                value={paymentInformation.postal_code}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    postal_code: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_account_holder")}
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_full_name_placeholder")}
                type="text"
                value={paymentInformation.account_holder_name}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    account_holder_name: e.target.value,
                  });
                }}
              />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative">
              <Form.Label className="input-label no-asterisk ">
                {" "}
                IBAN
              </Form.Label>
              <input
                className="form-control p-15"
                placeholder={_l("l_account_iban_placeholder")}
                type="text"
                value={paymentInformation.iban}
                onChange={(e) => {
                  setPaymentInformation({
                    ...paymentInformation,
                    iban: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  if (!validateIBAN(e.target.value)) {
                    showError("l_please_enter_valid_iban_number");
                  }
                }}
              />
            </Form.Group>
          </div>
        </div>
        <div className={`FormMain pb-15px ${selectedTab === "summary" ? "" : "d-none"}`}>
          <div className="c-font f-18 fw-semibold mb-10px">{_l("l_summary")}</div>
          <ul className="p-10 px-0">
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_creditor_name")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">8 Orbit</div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_creditor")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                GB27ZZZSDDBARC0000007495895
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_address")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                Sutton Yard, 65 Goswell Road, London, ECIV 7EN, GB
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_type")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
              {_l("l_recurrent")}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_reference")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
              {_l("l_available_confirmation")}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_date")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                {moment().format("DD/MM/YYYY")}
              </div>
            </li>
            <li className="border-top w-100 my-2"></li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_company_name")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                {paymentInformation.company_name}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_email")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                {paymentInformation.email}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_first_name")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                {paymentInformation.first_name}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_last_name")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                {paymentInformation.last_name}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
                {_l("l_billing_address")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
                {paymentInformation.billing_address}
              </div>
            </li>
            <li className="row align-items-center p-1">
              <div className="c-font col-lg-2 color-white-60 f-13 text-truncate">
              {_l("l_account_number")}
              </div>
              <div className="col-1 text-center">:</div>
              <div className="col-lg-9 c-font f-13 fw-semibold">
              {_l("l_ending_******")}
                {paymentInformation.iban.slice(
                  paymentInformation.iban.length - 4
                )}
              </div>
            </li>
            <li className="mt-3 c-font f-12 color-orange fw-semibold">
            {_l("l_advance_of_any_changes_your_payment_date")}
            </li>
          </ul>
        </div>
        <div className={`h-100 ${selectedTab === "confirmation" ? "" : "d-none"}`}>
          <div className="h-100 d-flex align-items-center justify-content-center flex-column">
            <div>
              <CelebrationIcon />
            </div>
            <div className="c-font f-30 fw-semibold title-fonts"> {_l("l_congratulation")}</div>
            <div className="c-font f-16 pt-15">{_l("l_gocardless_account_sucessfully")}</div>
          </div>
        </div>
        {
          selectedTab !== "confirmation"
            ?
            <div className="onboarding-footer c-font f-12 p-15 bgOfficelisting ">
              {
                selectedTab === "input"
                  ?
                  <div>
                    {" "}
                   {_l("l_direct_debit_payments")}
                    {" "}
                    <a href="#/" className="fw-semibold color-green">
                      {" "}
                      gocardless.com/legal/privacy/
                    </a>
                  </div>
                  :
                  selectedTab === "summary"
                    ?
                    <div>
                      {" "}
                      {_l("l_gocardless_to_send_instructions")}
                    </div>
                    :
                    <></>
              }
            </div>
            :
            <></>
        }
      </div>
    </div>
  );
};
export default WalletSetupForm;
