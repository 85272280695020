import React from 'react';
 

function FileDocIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}  >
<path d="M3.625 13.5C4.02282 13.5 4.40436 13.342 4.68566 13.0607C4.96696 12.7794 5.125 12.3978 5.125 12C5.125 11.6022 4.96696 11.2206 4.68566 10.9393C4.40436 10.658 4.02282 10.5 3.625 10.5H2.75V13.5H3.625Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.125 13.5C8.88439 13.5 9.5 12.8284 9.5 12C9.5 11.1716 8.88439 10.5 8.125 10.5C7.36561 10.5 6.75 11.1716 6.75 12C6.75 12.8284 7.36561 13.5 8.125 13.5Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.25 13.1562C13.0108 13.3749 12.6991 13.4974 12.375 13.5C11.6125 13.5 11 12.8313 11 12C11 11.1687 11.6125 10.5 12.375 10.5C12.6991 10.5026 13.0108 10.6251 13.25 10.8438"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 8V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V8"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.5 2V5.5H13"  strokeLinecap="round" strokeLinejoin="round"/>
</svg>


		</React.Fragment>
	)
}

export default FileDocIcon