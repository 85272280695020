import React, { useEffect, useState } from "react";
import MainScreen from "../components/ExploreFeatures/MainScreen";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConnectProvider from "../components/ExploreFeatures/ConnectProvider/ConnectProvider";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import { useDispatch } from "react-redux";
import { TaskModalClose } from "../hooks/utilities";

const ExploreFeatures = () => {
  const contact_role = localStorage.getItem("contact_role");
  const userType = localStorage.getItem("user_type");

  

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("main-screen");

  useEffect(() => {
    let key = searchParams.get("feature");
    if(key)
    {
      setSelectedTab(key);
    }
    else {
      setSelectedTab("main-screen");
    }
  }, [searchParams]);
  

  useEffect(() => {

    if(userType !== "staff" && contact_role == 3)
    {
      navigate("/myprofile");
    }

    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );

    TaskModalClose(true);

    return () => {
     
    };
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100">
        {
          selectedTab == "main-screen"
          ?
          <MainScreen />
          :
          selectedTab == "connect-provider"
          ?
          <ConnectProvider />
          :
          <></>
        }
      </div>
    </React.Fragment>
  );
};

export default ExploreFeatures;
