import React from 'react';
 

function UsersThreeIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
			<path d="M15.6406 8.75C15.292 8.28354 14.8392 7.905 14.3183 7.64457C13.7975 7.38414 13.223 7.24902 12.6406 7.25C13.0202 7.25038 13.3921 7.14273 13.7128 6.93962C14.0335 6.73651 14.2897 6.44633 14.4516 6.10299C14.6135 5.75965 14.6744 5.37732 14.6271 5.00068C14.5797 4.62404 14.4262 4.26865 14.1844 3.97603C13.9426 3.6834 13.6225 3.46564 13.2616 3.34817C12.9006 3.2307 12.5136 3.21839 12.1459 3.31266C11.7782 3.40694 11.445 3.60391 11.185 3.88057C10.9251 4.15722 10.7493 4.50213 10.6781 4.875M1.14062 8.75C1.48925 8.28354 1.94205 7.905 2.46291 7.64457C2.98377 7.38414 3.55828 7.24902 4.14062 7.25C3.76103 7.25038 3.38916 7.14273 3.06847 6.93962C2.74778 6.73651 2.49151 6.44633 2.32962 6.10299C2.16772 5.75965 2.10688 5.37732 2.1542 5.00068C2.20152 4.62404 2.35505 4.26865 2.59685 3.97603C2.83864 3.6834 3.15873 3.46564 3.51969 3.34817C3.88066 3.2307 4.2676 3.21839 4.63531 3.31266C5.00301 3.40694 5.3363 3.60391 5.59621 3.88057C5.85613 4.15722 6.03195 4.50213 6.10313 4.875M4.79063 13.5C5.11989 12.8257 5.63193 12.2574 6.2684 11.8599C6.90488 11.4624 7.64021 11.2516 8.39062 11.2516C9.14104 11.2516 9.87637 11.4624 10.5128 11.8599C11.1493 12.2574 11.6614 12.8257 11.9906 13.5M10.8906 8.75C10.8906 10.1307 9.77134 11.25 8.39062 11.25C7.00991 11.25 5.89062 10.1307 5.89062 8.75C5.89062 7.36929 7.00991 6.25 8.39062 6.25C9.77134 6.25 10.8906 7.36929 10.8906 8.75Z" />
		</svg>

		</React.Fragment>
	)
}

export default UsersThreeIcon