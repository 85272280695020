import React from 'react'
const LeftPanelCategoryHeaderSkeleton = () => {
  return (
    <div className=' d-flex justify-content-between align-items-center w-100 p-10 SkeletonMain'>
      <div className='d-flex align-items-center w-100'>
        <div className='h15w15 skeleton-animation'></div>
        <div className='ms-10px skeleton-animation radius_3' style={{ width: '100px', height: '15px' }}></div>
      </div>
      <div className='align-items-center d-flex justify-content-end'>
        <div className='with_separator_10'>
          <div className='skeleton-animation radius_3 h18w18'></div>
        </div>
        <div className='with_separator_10'>
          <div className='skeleton-animation radius_3 h18w18'></div>
        </div>
        <div className='with_separator_10'>
          <div className='skeleton-animation radius_3 h18w18'></div>
        </div>
      </div>
    </div>
  )
}
export default LeftPanelCategoryHeaderSkeleton