import React from 'react';
 

function UserListIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M9.5 5H15.5M9.5 8H15.5M11 11H15.5M1.125 12C1.3453 11.1396 1.8457 10.377 2.54731 9.83236C3.24892 9.28776 4.11183 8.99216 5 8.99216C5.88817 8.99216 6.75108 9.28776 7.45269 9.83236C8.1543 10.377 8.6547 11.1396 8.875 12M7.5 6.5C7.5 7.88071 6.38071 9 5 9C3.61929 9 2.5 7.88071 2.5 6.5C2.5 5.11929 3.61929 4 5 4C6.38071 4 7.5 5.11929 7.5 6.5Z" />
</svg>



		</React.Fragment>
	)
}

export default UserListIcon