import React from 'react';
 

function JoinCompanyTrainingIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons`}>
<g clip-path="url(#clip0_16205_1909)">
<path d="M15.1944 37.8411C15.1944 40.4518 13.0779 42.5684 10.4672 42.5684C7.85639 42.5684 5.73999 40.4518 5.73999 37.8411C5.73999 35.2305 7.85639 33.1139 10.4672 33.1139C13.0779 33.1139 15.1944 35.2305 15.1944 37.8411Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.597 42.5685H6.33788C4.06269 42.5685 2.20117 44.4301 2.20117 46.7053V50.7057C2.20117 51.3112 2.69207 51.8022 3.29757 51.8022H17.6373C18.2428 51.8022 18.7337 51.3112 18.7337 50.7057V46.7053C18.7337 44.4301 16.8722 42.5685 14.597 42.5685Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.7272 37.8411C31.7272 40.4518 29.6107 42.5684 27 42.5684C24.3892 42.5684 22.2727 40.4518 22.2727 37.8411C22.2727 35.2305 24.3892 33.1139 27 33.1139C29.6107 33.1139 31.7272 35.2305 31.7272 37.8411Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.1295 42.5685H22.8703C20.5952 42.5685 18.7336 44.4301 18.7336 46.7053V50.7057C18.7336 51.3112 19.2245 51.8022 19.83 51.8022H34.1698C34.4606 51.8022 34.7395 51.6867 34.9451 51.4811C35.1508 51.2754 35.2663 50.9965 35.2663 50.7057V46.7053C35.2663 44.4301 33.4048 42.5685 31.1295 42.5685Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.2598 37.8411C48.2598 40.4518 46.1433 42.5684 43.5326 42.5684C40.9218 42.5684 38.8054 40.4518 38.8054 37.8411C38.8054 35.2305 40.9218 33.1139 43.5326 33.1139C46.1433 33.1139 48.2598 35.2305 48.2598 37.8411Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.6622 42.5685H39.4031C37.1279 42.5685 35.2664 44.4301 35.2664 46.7053V50.7057C35.2664 51.3112 35.7573 51.8022 36.3628 51.8022H50.7025C51.308 51.8022 51.7989 51.3112 51.7989 50.7057V46.7053C51.7989 44.4301 49.9374 42.5685 47.6622 42.5685Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M52.6052 2.71325C52.6052 1.60868 51.7098 0.713257 50.6052 0.713257H3.39477C2.2902 0.713257 1.39478 1.60868 1.39478 2.71325V28.1138C1.39478 29.2183 2.29021 30.1138 3.39478 30.1138H50.6052C51.7098 30.1138 52.6052 29.2183 52.6052 28.1138V2.71325Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.0002 24.0005C31.9708 24.0005 36.0002 19.9711 36.0002 15.0005C36.0002 10.0299 31.9708 6.00049 27.0002 6.00049C22.0297 6.00049 18.0002 10.0299 18.0002 15.0005C18.0002 19.9711 22.0297 24.0005 27.0002 24.0005Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.0005 15L25.5005 12V18L30.0005 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_16205_1909">
<rect width="54" height="53" fill="currentColor"/>
</clipPath>
</defs>
</svg>

			{/* <svg width="54" height="53" viewBox="0 0 54 53" fill="none" xmlns="http://www.w3.org/2000/svg" >
				<path d="M15.1945 37.8411C15.1945 40.4518 13.078 42.5684 10.4673 42.5684C7.85651 42.5684 5.74011 40.4518 5.74011 37.8411C5.74011 35.2305 7.85651 33.1139 10.4673 33.1139C13.078 33.1139 15.1945 35.2305 15.1945 37.8411Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M14.5969 42.5685H6.33776C4.06257 42.5685 2.20105 44.4301 2.20105 46.7053V50.7057C2.20105 51.3112 2.69195 51.8022 3.29745 51.8022H17.6372C18.2427 51.8022 18.7336 51.3112 18.7336 50.7057V46.7053C18.7336 44.4301 16.8721 42.5685 14.5969 42.5685Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M31.7272 37.8411C31.7272 40.4518 29.6107 42.5684 27 42.5684C24.3892 42.5684 22.2727 40.4518 22.2727 37.8411C22.2727 35.2305 24.3892 33.1139 27 33.1139C29.6107 33.1139 31.7272 35.2305 31.7272 37.8411Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M31.1295 42.5685H22.8703C20.5952 42.5685 18.7336 44.4301 18.7336 46.7053V50.7057C18.7336 51.3112 19.2245 51.8022 19.83 51.8022H34.1698C34.4606 51.8022 34.7395 51.6867 34.9451 51.4811C35.1508 51.2754 35.2663 50.9965 35.2663 50.7057V46.7053C35.2663 44.4301 33.4048 42.5685 31.1295 42.5685Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M48.2598 37.8411C48.2598 40.4518 46.1433 42.5684 43.5326 42.5684C40.9218 42.5684 38.8054 40.4518 38.8054 37.8411C38.8054 35.2305 40.9218 33.1139 43.5326 33.1139C46.1433 33.1139 48.2598 35.2305 48.2598 37.8411Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M47.6622 42.5685H39.4031C37.1279 42.5685 35.2664 44.4301 35.2664 46.7053V50.7057C35.2664 51.3112 35.7573 51.8022 36.3628 51.8022H50.7025C51.308 51.8022 51.7989 51.3112 51.7989 50.7057V46.7053C51.7989 44.4301 49.9374 42.5685 47.6622 42.5685Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M52.6052 2.71327C52.6052 1.6087 51.7098 0.713272 50.6052 0.713272H3.39477C2.2902 0.713272 1.39478 1.6087 1.39478 2.71327V28.1138C1.39478 29.2183 2.29021 30.1138 3.39478 30.1138H50.6052C51.7098 30.1138 52.6052 29.2183 52.6052 28.1138V2.71327Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M27.0002 24.0005C31.9708 24.0005 36.0002 19.9711 36.0002 15.0005C36.0002 10.0299 31.9708 6.00049 27.0002 6.00049C22.0297 6.00049 18.0002 10.0299 18.0002 15.0005C18.0002 19.9711 22.0297 24.0005 27.0002 24.0005Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M30.0004 15L25.5004 12V18L30.0004 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
			</svg> */}


		</React.Fragment>
	)
}

export default JoinCompanyTrainingIcon