import React from 'react';

function FileRevokeIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<g clip-path="url(#clip0_9329_398256)">
					<path d="M25 18V10L18 3H6C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V26C5 26.2652 5.10536 26.5196 5.29289 26.7071C5.48043 26.8946 5.73478 27 6 27H15" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M18 3V10H25" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M27 21L19 29" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M27 29L19 21" strokeLinecap="round" strokeLinejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_9329_398256">
						<rect width="32" height="32" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</React.Fragment>
	)
}

export default FileRevokeIcon