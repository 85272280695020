import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import store from "../../../src/store";
import {
  getDefaultTopics,
  getTopicTasks,
  setEquipmentCategoryId,
} from "../../actions/customer";
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import RobotScheduleModal from "./RobotScheduleModal";
import Spinner from "react-bootstrap/esm/Spinner";
import EquipmentsServices from "../../services/equipments-services";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import HashIcon from "../../assets/icons/HashIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import MinusIcon from "../../assets/icons/MinusIcon";
import NotepadIcon from "../../assets/icons/NotepadIcon";
import PlayIcon from "../../assets/icons/PlayIcon";
import PauseIcon from "../../assets/icons/PauseIcon";
import PencilIcon from "../../assets/icons/PencilIcon";

const AddEditEquipment = ({
  show,
  handleClose,
  data,
  deleteEquipment
}) => {
  const dispatch = useDispatch();
  const project_id = store.getState().customer.selectedProject;
  const { get_detail_for_login_as_user } = useSelector((state) => state.customer)
  const [selectedTab, setSelectedTab] = useState(data ? "equipment_quantity" : "add_equipment");
  const [equipmentQuantityList, setEquipmentQuantityList] = useState([]);
  const [contactAssigneeList, setContactAssigneeList] = useState([]);
  const [providerAssigneeList, setProviderAssigneeList] = useState([]);
  const [contactAssignee, setContactAssignee] = useState();
  const [providerAssignee, setProviderAssignee] = useState();
  const [newQuantity, setNewQuantity] = useState("");
  const [name, setName] = useState("");
  const [equipmentId, setEquipmentId] = useState(0);
  const [robotScheduleModalShow, setRobotScheduleModalShow] = useState(false);
  const [catrgoryDetails, setCatrgoryDetails] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [allowScheduling, setAllowScheduling] = useState(false)
  const [deviceId, setDeviceId] = useState("")
  const [quantityId, setQuantityId] = useState(0)
  const [loder, setLoder] = useState(false);
  const handleChange = (key) => {
    setSelectedTab(key)
    setQuantityId(0);
    setNewQuantity("");
    setDeviceId("");
  };
  const isValidDate = () => {
    if (name.trim() == "") {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_name_is_empty')));
      return false
    } else if (quantity < 1) {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_quantity_is_not_less_then_zero')));
      return false
    }
    return true
  }
  const AddUpdateEquipment = () => {
    if (isValidDate()) {
      setLoder(true);
      CustomerServices.addEditEquipment(equipmentId, name, quantity, contactAssignee , providerAssignee,allowScheduling).then((res) => {
      setLoder(false);
        if (res.status == '1') {
          setSelectedTab("equipment_quantity")
          if (!data && equipmentId == 0) {
            setEquipmentId(res.data.id)
          }
          dispatch(getTopicTasks(project_id));
          dispatch(getDefaultTopics(project_id));
          CustomerServices.getEquipmentQuantity(res.data.id || equipmentId).then((data) => {
            if (data.status == '1') {
              setEquipmentQuantityList(data.data)
            }
          })
        } else {
          dispatch(showMessage('unsucess', _l('l_error'), res.message ? res.message : ''));
        }
      });
    }
  }

  useEffect(() => {
  if (data) {
      setName(data.title)
      setEquipmentId(data.filter_key)
      setContactAssignee(data.contact_assignee)
      setProviderAssignee(data.provider_assignee)
      CustomerServices.getEquipmentQuantity(data.filter_key).then((res) => {
        if (res.status == '1') {
          setEquipmentQuantityList(res.data)
        }
      })
      setAllowScheduling(data.is_schedule_allowed == 1 ? true : false)
    }
  }, [JSON.stringify(data)])

  useEffect(() => {
        if (get_detail_for_login_as_user) {
          let customers =
            get_detail_for_login_as_user.customers && get_detail_for_login_as_user.customers.members
              ? get_detail_for_login_as_user.customers.members
              : [];
          let providers =
            get_detail_for_login_as_user.providers && get_detail_for_login_as_user.providers.members
              ? get_detail_for_login_as_user.providers.members
              : [];
          if (customers.length) {
            customers.sort(((a, b) => (b.is_primary - a.is_primary) ||  a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())));
            setContactAssigneeList(customers.map((c) => {
              return { label: c.fullname, value: c.contact_id }
            }))
          }
          if (providers.length) {
            providers.sort(((a, b) => (b.is_boss_operator - a.is_boss_operator) ||  a.fullname.toLowerCase().localeCompare(b.fullname.toLowerCase())));
            setProviderAssigneeList(providers.map((p) => {
              return { label: p.fullname, value: p.staff_id }
            }))
          }
        }
  }, [get_detail_for_login_as_user])
  const handleChangeQuantity = (name, val, k) => {
    const arr = equipmentQuantityList.filter((file) => {
      return file !== val
    });
    arr[k] = {
      ...arr[k],
      [name]: val
    }
    setEquipmentQuantityList([...arr])
  };
  const addEditSingleQuantity = (quantity_name, quantity_id = undefined,device_id) => {
    if (quantity_name.trim() != "") {
      CustomerServices.EditEquipmentQuantity(equipmentId, quantity_id || quantityId , quantity_name, project_id,device_id).then((res) => {
        if (res.status == 1) {
          dispatch(getDefaultTopics(project_id));
          if (quantityId) {
            let quantityList = equipmentQuantityList
            let index = quantityList.findIndex(item => item.id == quantityId);
            quantityList[index].quantity_name = quantity_name
            quantityList[index].device_id = device_id
            setEquipmentQuantityList(quantityList);
          }
          setNewQuantity("")
          setDeviceId("")
          setQuantityId(0)
          if (res.data && res.data.id) {
            setEquipmentQuantityList([...equipmentQuantityList, { id: res.data.id, quantity_name: quantity_name , device_id : device_id}])
          }
        } else {
          dispatch(showMessage('unsucess', _l('l_error'), res.message ? res.message : ''));
        }
      })
    }else{
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_pleace_enter_quantity_name')));
    }
  }
  const deleteSingleQuantity = (id) => {
    EquipmentsServices.deleteEquipmentQuantity(equipmentId, id, project_id).then((res) => {
      if (res.status == 1) {
        setEquipmentQuantityList(equipmentQuantityList.filter(e => e.id != id))
        dispatch(showMessage('sucess', _l('l_success'), res.message ? res.message : ''));
      }
    })
  }
  const deleteQuantity = (id) => {
    dispatch(showConfirmation(_l('l_delete_quantity'), _l('l_delete_quantity_confirmation'), _l('l_delete'), _l('l_cancel'), ()=>{}, 
    () => { deleteSingleQuantity(id)
      dispatch(toastCloseAction()) }))
  }

  const actionStartStop = (action, equipment_id, task_id = 0, device_id, index) => {
    CustomerServices.robotCommandAction(action, equipment_id, device_id, task_id, project_id).then((res) => {
      if (res.status == 1) {
        let newEquipmentArr = equipmentQuantityList
        if (action == "start") { 
          newEquipmentArr[index].on_going_task= res.data.task_id
        }else{
          newEquipmentArr[index].on_going_task = 0
        }
        setEquipmentQuantityList(newEquipmentArr.slice());
        dispatch(showMessage('sucess', _l('l_success'), res.message ? res.message : ''));
      }else{
        dispatch(showMessage('unsucess', _l('l_error'), res.message ? res.message : ''));
      }
    })
  }
  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style add-topic-search overflow-hidden with-min-height"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header
          className="modal-header border-0"
          closeButton
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="modal-title title-fonts fw-semibold d-flex align-items-center">
              {equipmentId > 0 ? name : _l("l_add_equipment")}
            </div>
            <div className={`${selectedTab == "add_equipment" ? "" : "d-none"}`}>
              <Form.Check
                className="align-items-center d-flex me-2"
                type="checkbox"
                id=""
                checked={allowScheduling}
                onChange={(e) => { setAllowScheduling(e.target.checked) }}
                label={`${_l("l_allow_scheduling")}`}
              />
            </div>  
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 d-flex flex-column">
          <div className="custom-modal-section-sticky">
            <div className="comman-tab-wrapper">
              <div className="d-flex align-items-center border-bottom">
                <div
                  className={`tab-wrapper ${selectedTab == "add_equipment"
                    ? "active"
                    : ""
                    } d-flex align-items-center`}
                  onClick={() => {
                    handleChange("add_equipment");
                    
                  }}
                >
                  <a href="#/" className="tab-name">{_l("l_details")}</a>
                </div>
                {
                  equipmentQuantityList.length ?
                    <div
                      className={`tab-wrapper ${selectedTab == "equipment_quantity"
                        ? "active"
                        : ""
                        } d-flex align-items-center`}
                      onClick={() => {
                        handleChange("equipment_quantity");
                      }}
                    >
                      <a href="#/" className="tab-name">{_l("l_list")}<span className="title-fonts ps-1 fw-semibold">[{equipmentQuantityList.length}]</span></a>
                    </div>
                    : ""
                }
              </div>
            </div>
            <div className="">
              {
                selectedTab == "add_equipment" ?
                  ""
                  :
                  <>
                  <div className="row align-items-center">
                    <Form.Group className="col-lg-6 c-input-box  position-relative">
                      <Form.Label className="input-label ">
                      {_l("l_add_equipment")}
                    </Form.Label>
                      <Form.Control
           
                        type="text"
                        placeholder={`${_l("l_title")}`}
                        aria-label="default input example"
                        value={newQuantity}
                        onChange={(e) => setNewQuantity(e.target.value)}
                      />
                     
                      </Form.Group>
                      <Form.Group className="col-lg-5 c-input-box  position-relative" >
                        <Form.Label className="input-label no-asterisk ">
                          {_l("l_add_device_id")}
                        </Form.Label>
                      <Form.Control
                  
                        type="text"
                        placeholder={`${_l("l_device_id")+" ("+_l("l_optional")+")"}`}
                        aria-label="default input example"
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                      />
                      <a href="#/"
                        className={`send-arrow-btn d-none`}
                        onClick={() => addEditSingleQuantity(newQuantity,"",deviceId)}
                      >
                        <ArrowRightIcon className="HW18" />
                      </a>
                    </Form.Group>
                    <Form.Group className="col-lg-1 pt-4 mt-1 ">
                      <Button variant="primary" size="sm" className="d-block" onClick={() => addEditSingleQuantity(newQuantity,"",deviceId)}>{ quantityId > 0 ? _l('l_edit') : _l('l_add')}</Button>
                      </Form.Group>
                  </div>
                  </>
              }
            </div>
          </div>
           
          <div className={`topic-list-wrapper flex-grow-1 row`}>
            <div className={`col flex-grow-1 set-topic-list flex-grow-1 d-flex flex-column ${selectedTab == "add_equipment"? "" : "overflow-hiiden-auto"}`}>
              <div className="row">
                {
                  selectedTab == "add_equipment" ?
                    <>
                      <Form.Group className="col-xl-6 comment-input-box pb-4">
                        <Form.Label className="input-label no-asterisk no-asterisk">
                          {_l('l_name')}
                        </Form.Label>
                        <Form.Control
                          className=""
                          type="text"
                          placeholder={`${_l("l_name")}`}
                          aria-label="default input example"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Group>
                      {
                        equipmentId > 0 ?
                          ""
                          :
                          <Form.Group className="col-xl-6 c-input-box pb-4">
                            <Form.Label className="input-label no-asterisk title-fonts color-white-60">
                              {_l('l_quantity')}
                            </Form.Label>
                            <div className="d-flex align-items-center  form-control pe-3">
                              <a href="#/" className="c-font f-22 line-height-0" onClick={() => {
                                if (quantity > 1) {
                                  setQuantity(prevCount => prevCount - 1);
                                }
                              }}>
                                <MinusIcon className="HW18" />
                              </a>
                              <Form.Control
                                className="border-0 bg-transparent p-0 text-center"
                                placeholder={_l('l_quantity')}
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                              />
                              <a href="#/" className="c-font f-22 line-height-0" onClick={() => { setQuantity(prevCount => prevCount + 1); }}>
                                <PlusIcon className="HW18" />
                              </a>
                            </div>
                          </Form.Group>
                      }
                      <Form.Group className="col-xl-6 c-input-box position-relative pb-4">
                        <Form.Label className="input-label no-asterisk no-asterisk">
                          {_l('l_contact_assignee')}
                        </Form.Label>
                        <div className="d-flex flex-grow-1 align-items-center">
                          <Select
                            className="custom-select-menu w-100"
                            classNamePrefix="react-select"
                            placeholder={_l('l_contact_assignee')}
                            options={contactAssigneeList}
                            closeMenuOnSelect={true}
                            value={contactAssigneeList.filter(c => c.value == contactAssignee)[0]}
                            onChange={(e) => setContactAssignee(e.value)}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="col-xl-6 c-input-box position-relative pb-4">
                        <Form.Label className="input-label no-asterisk no-asterisk">
                          {_l('l_provider_assignee')}
                        </Form.Label>
                        <div className="d-flex flex-grow-1 align-items-center">
                          <Select
                            className="custom-select-menu w-100"
                            classNamePrefix="react-select"
                            placeholder={_l('l_provider_assignee')}
                            options={providerAssigneeList}
                            closeMenuOnSelect={true}
                            value={providerAssigneeList.filter(c => c.value == providerAssignee)[0]}
                            onChange={(e) => setProviderAssignee(e.value)}
                          />
                        </div>
                      </Form.Group>
                    </>
                    :
                    ""
                }
                {equipmentQuantityList && equipmentQuantityList.length ? (
                  equipmentQuantityList.map((qty, index) => {
                    return (
                      selectedTab == "equipment_quantity" ? (
                        <div
                          key={index}
                          className={`col-xl-6`}
                        >
                          <div className="topic-main-box hr_sepretr left-25 r_0">
                            <div className="">
                              <div className="comman_action_icon py-3  d-flex align-items-center justify-content-between">
                                <div className="topic-left-title text-truncate d-flex align-item-center flex-grow-1  w-100 justify-content-between">
                                  <div className=" d-flex  rounded-circle align-items-center  ">
                                    <HashIcon
                                      className="HW16 me-2"
                                    />{index + 1}
                                  </div>
                                  <Form.Control
                                    className=" border-0 p-0 bg-transparent px-2 w-auto"
                                    type="text"
                                    placeholder={`${_l("l_name")}`}
                                    aria-label="default input example"
                                    value={qty.quantity_name}
                                    onChange={(e) => { handleChangeQuantity("quantity_name", e.target.value, index) }}
                                    onBlur={() => addEditSingleQuantity(qty.quantity_name, qty.id , qty.device_id)}
                                    disabled
                                  />
                                    <div className="d-flex align-items-center">
                                      <div className={`d-flex align-items-center badge ${qty.on_going_task > 0 ? 'badge-red' : 'bange-warning'} rounded-pill fw-light me-1 pill-task-active p-1`}>
                                        <span className={`c-dots c-7  rounded-circle me-1`}></span>
                                        <span>{qty.on_going_task > 0 ? _l("l_online") : _l("l_offline")}</span>
                                      </div>
                                    </div>
                                  <div className={`${qty.device_id > 0 && allowScheduling ? "":""}`}>
                                      <a href="#/" tag="a" className="bg-white-05 d-flex h25w25  radius_3 me-2" onClick={() => {
                                        if (qty.device_id == "") {
                                          dispatch(showMessage("unsucess" ,_l("l_error"), _l("l_device_id_required_for_create_schedule")));
                                          setNewQuantity(qty.quantity_name);
                                          setDeviceId(qty.device_id && qty.device_id.toLowerCase() != "null" ? qty.device_id :"");
                                          setQuantityId(qty.id);
                                          
                                        }else if(!allowScheduling){
                                          dispatch(showMessage("unsucess" ,_l("l_error"), _l("l_please_allow_to_schedule")));
                                        } else{ 
                                          dispatch(setEquipmentCategoryId(qty.id))
                                          setRobotScheduleModalShow(true);
                                          setCatrgoryDetails(qty);
                                        }
                                        }}>
                                    <NotepadIcon
                                      className="HW16"
                                    />
                                  </a>
                                      
                                      </div>
                                  { qty.device_id != "" &&  qty.device_id !== undefined && qty.device_id != null ?
                                    qty.on_going_task > 0 ?
                                    <a href="#/" className={`bg-white-05 d-flex h25w25  radius_3 me-2 ${qty.device_id == "" || qty.device_id == 0 || qty.quantity_name.length < 1 ? "for-disabled" : ""}`} onClick={() => {
                                      if (qty.device_id != "" || qty.device_id != 0 || qty.quantity_name.length != 0) {
                                        actionStartStop("stop", qty.id, qty.on_going_task, qty.device_id, index)
                                      }
                                    }}
                                    >
                                      <PauseIcon
                                        className="HW16"
                                      />
                                    </a>
                                      :
                                      <a href="#/" className={`bg-white-05 d-flex h25w25  radius_3 me-2 ${qty.device_id == "" || qty.device_id == 0 || qty.quantity_name.length < 1 ? "for-disabled" : ""}`} onClick={() => {
                                        if (qty.device_id != "" || qty.device_id != 0 || qty.quantity_name.length != 0) { 
                                          actionStartStop('start', qty.id, 0, qty.device_id, index)
                                        }
                                        }}>
                                        <PlayIcon
                                          className="HW16" />
                                      </a>
                                      :
                                      <></>
                                  }
                                  
                                  {
                                    equipmentQuantityList.length > 1 ?
                                    <a href="#/"
                                    className="bg-white-05 d-flex h25w25  radius_3 me-2"
                                    onClick={() => {
                                      setNewQuantity(qty.quantity_name);
                                      setDeviceId(qty.device_id && qty.device_id.toLowerCase() != "null" ? qty.device_id :"");
                                      setQuantityId(qty.id);
                                    }}
                                  >
                                    <PencilIcon
                                      className="HW16"
                                    />
                                  </a>
                                  :
                                  <></>}
                                  {
                                    equipmentQuantityList.length > 1 ?
                                    <a href="#/"
                                    className="bg-white-05 d-flex h25w25  radius_3"
                                    onClick={() => {
                                      deleteQuantity(qty.id);
                                    }}
                                  >
                                    <TrashIcon
                                      className="HW16"
                                    />
                                  </a>
                                  :
                                  <></>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    );
                  })
                ) : (
                  <>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          {equipmentId > 0 ? 
          <Button
          variant="secondary" size="sm"
          onClick={() => deleteEquipment(equipmentId,true,handleClose)}
          >
            {_l('l_delete')}
          </Button>
          :<></>
          }
          <Button
            variant="secondary" size="sm"
            onClick={() => handleClose()}
          >
            {_l('l_close')}
          </Button>
          {
            selectedTab == "equipment_quantity" ?
              ""
              :
            
              <Button
              variant="primary" className="ms-2" size="sm"
                    onClick={() => {
                      if (!loder) {
                        AddUpdateEquipment()
                      }
                    }}
                  >
                    {loder
                      ? _l('l_please_wait')
                      : equipmentId > 0 ? _l('l_save') : _l('l_add')
                    }
                    {
                      loder ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`ms-2 `}
                          aria-hidden="true"
                        />
                      ) :
                        <></>
                    }
                  </Button>
          }
        </Modal.Footer>
      </Modal>
      {
        robotScheduleModalShow ?
          <RobotScheduleModal
            show={robotScheduleModalShow}
            handleClose={() => setRobotScheduleModalShow(false)}
            equipmentId={equipmentId}
            category={catrgoryDetails}
            project_id={project_id}
          />
          :
          <></>
      }
    </>
  );
};

export default AddEditEquipment;
