import React from 'react';
 

function DefaultImgPlaceholder(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons fill-white ${CommanHW}`}>
				<g clipPath="url(#clip0_2776_208947)">
					<path fillRule="evenodd" clipRule="evenodd" d="M55.0568 63.8346C55.0568 52.7577 64.0364 43.7782 75.1132 43.7782C86.1901 43.7782 95.1696 52.7577 95.1696 63.8346C95.1696 74.8126 86.3495 83.7306 75.4091 83.8888C75.3105 83.8881 75.2119 83.8878 75.1132 83.8878C75.0146 83.8878 74.916 83.8881 74.8174 83.8888C63.8769 83.7306 55.0568 74.8126 55.0568 63.8346ZM62.8837 85.7089C55.2299 81.4207 50.0568 73.2317 50.0568 63.8346C50.0568 49.9963 61.275 38.7782 75.1132 38.7782C88.9515 38.7782 100.17 49.9963 100.17 63.8346C100.17 73.2317 94.9965 81.4207 87.3428 85.7089C90.3849 86.6355 93.3245 87.9086 96.1017 89.5122C102.483 93.1968 107.782 98.4963 111.465 104.878C112.156 106.074 111.746 107.603 110.55 108.293C109.354 108.983 107.825 108.573 107.135 107.378C103.89 101.756 99.2226 97.0879 93.6015 93.8422C88.0701 90.6482 81.8071 88.9426 75.4234 88.8891C75.3202 88.8904 75.2168 88.891 75.1132 88.891C75.0097 88.891 74.9063 88.8904 74.803 88.8891C68.4194 88.9426 62.1564 90.6482 56.6249 93.8422C51.0039 97.0879 46.3363 101.756 43.0914 107.378C42.4011 108.573 40.8722 108.983 39.6764 108.293C38.4806 107.603 38.0708 106.074 38.761 104.878C42.4447 98.4963 47.7435 93.1968 54.1247 89.5122C56.9019 87.9086 59.8416 86.6355 62.8837 85.7089Z" />
				</g>
				<defs>
					<clipPath id="clip0_2776_208947">
						<rect width="150" height="150" />
					</clipPath>
				</defs>
			</svg>


		</React.Fragment>
	)
}

export default DefaultImgPlaceholder