import React, { useEffect, useState } from "react";
import {
  _l,
  hideLeftPanelNew,
  TaskCommentModalOpen,
} from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { createScheduleDetails } from "../../actions/benchmark-actions/benchmark-actions";
import { useDispatch, useSelector } from "react-redux";
import { commanoffcanvasisOpen, getBenchmarkDetail, setActionForLeaseDocument } from "../../actions/customer";
import Autocomplete from "../Dropdowns/Autocomplete";
import Spinner from "react-bootstrap/Spinner";
import customerServices from "../../services/customer-services";
import GeneralListing from "./GeneralListing";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import UnControlMainFiltersDropDown from "./LeadsMainFiltersDropDown";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/icons/InfoIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
function CommonHeaderModal({
  data,
  type,
  handleAction,
  isExternal,
  allData,
  readOnlyMode,
  setModalHeaderIconToggle=true,
  newTypeOfSpaceData,
  setNewTypeOfSpaceData,
  getSpaceOptionList,
  equipmentData,
  handleChange,
  selectedEquipmentsQuantity,
  setRequestData,
  requestData,
  loaderForPOI = false,
  spaceBasicDetails={}
}) {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const [name, setName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [namePermission, setNamePermission] = useState(false);
  const [taskRedirectBtnPermission, setTaskRedirectBtnPermission] =
    useState(false);
  const [taskRedirectBtnText, setTaskRedirectBtnText] = useState(false);
  const [myr_total, setMyr_total] = useState(0);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const { createScheduleDetail } = useSelector(
    (state) => state.benchmarkReducer
  );
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const {calendarCanvasIsOpen ,commanoffcanvasIsOpen, providerlistTableData, companyEmployeeData } = useSelector((state) => state.customer);
  const [isUnKnownBudget, setIsUnKnownBudget] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [categoryListing, setCategoriesList] = useState([]);
  const [taskAssignee, setTaskAssignee] = useState([])
  const getStatusText = () => {
    if(data.document_type == "benchmark")
    {
      if(userType == "operator")
      {
        switch(data.proposal_status)
        {
          case "2":
            return _l("l_declined")
          case "3":
            return _l("l_accepted");;
          case "4":
            if(data.proposal_amount == 0 || data.proposal_amount == null)
            {
              return _l("l_received");
            }
            else {
              return _l("l_pending");
            }
          default:
            break;
        }
      }
      else {
        if(data.status == 1)
        {
          return _l('l_draft');
        }
        else if(data.is_opened == 1)
        {
          return _l('l_open');
        }
        else {
          return _l('l_close');
        }
      }
    }
    else {
      if((data.status == 3 || data.status == 4))
      {
        if(!data.start_date)
        {
          return _l('l_waiting_for_start_date');
        }
        else if(data.is_assigneed == 0) {
          return _l('l_waiting_for_assignee');
        }
        else {
          return data.status_text;
        }
      }
      else {
        return data.status_text;
      }
    }
  };
  useEffect(() => {
    if (data && allData) {
      setName(allData.type == "staff" ? allData.addedfrom_details.full_name : allData.type == 'customer' ? allData.full_name : data.document_name ? data.document_name :allData.type == 'equipment' ? allData.name : allData.title ? allData.title : "");
      setNamePermission(allData.type == 'staff' || allData.type == 'customer' ? false : data.document_name_permission);
      setTaskRedirectBtnPermission(data.task_redirect_btn_permission);
      setDocumentType(data.is_synced ? "Google Document" : data.document_type);
      setTaskRedirectBtnText(data.task_redirect_btn_text);
      setMyr_total(data.myr_total && data.myr_total);
      if (type == "schedule" || type == "benchmark") {
        setIsUnKnownBudget(data.isUnKnownBudget ? data.isUnKnownBudget : "");
        setStatus(data.proposal_status ? data.proposal_status : data.status);
      }
      else if(type == "invoice")
      {
        setStatus(data.is_app_invoice != 1 ? data.status : "");
      }
      else {
        setStatus(data.status ? data.status : "");
      }
    }
  }, [JSON.stringify(data), type, JSON.stringify(allData)]);
  useEffect(() => {
    if (createScheduleDetail) {
      if (type == "schedule" || type == "benchmark") {
        setSelectedTab(
          createScheduleDetail.selectedTab
            ? createScheduleDetail.selectedTab
            : ""
        );
      }
    }
  }, [createScheduleDetail]);
  useEffect(() => {
    if(selectedTask){
      setDocumentType((selectedTask.type === "event & task" || selectedTask && selectedTask.calendar_id ) ? _l('l_google_task') : data.document_type);
    }
  }, [selectedTask]);
  useEffect(() => {
    localStorage.setItem("click_detail", JSON.stringify(false));
  }, [JSON.parse(localStorage.getItem("click_detail")) == true]);
  const scheduleBenchmarkAmount = (value) => {
    dispatch(
      createScheduleDetails({
        ...createScheduleDetail,
        [`amount`]: value,
        ["isUnKnownBudget"]: false,
      })
    );
    dispatch(getBenchmarkDetail(0, 0, "edit", "max_budget", value));
  };
  /*Close detail modal and comment on office change and week calendar date change*/
  useEffect(() => {
    if (!calendarCanvasIsOpen && !commanoffcanvasIsOpen && !isExternal && type !== "provider_page") { 
      handleAction("modalClose");
    }else if (type === "provider_page") {
      dispatch(commanoffcanvasisOpen(true))
      hideLeftPanelNew()
        TaskCommentModalOpen()
    }
  }, [commanoffcanvasIsOpen,calendarCanvasIsOpen])
  useEffect(() => {
    let categoryLists = [];
if (providerlistTableData && providerlistTableData.company_profile) {
  let data  = providerlistTableData.company_profile
  customerServices.getProviderServices().then((response) => {
    if (response.status) {
       categoryLists = response.data.map((w) => {
          return { 
            label: w.name, 
            value: w.id, 
          };
        })
      setCategoriesList(categoryLists ? categoryLists.filter((item) => data.categories && data.categories.includes(item.value)).map((w) => {
          return{
            label : w.label,
            value : w.value,
            isFixed : true,
          }
        })  : []);
    }
  })
}
  }, [])
  useEffect(() => {
    if (type === "provider_page") {
      let  setAssigneeProviders = companyEmployeeData;
      let proviserAssineeId = providerlistTableData && providerlistTableData.provider_assignee && providerlistTableData.provider_assignee.map((item) => item.assignee_id);
      setAssigneeProviders = setAssigneeProviders && setAssigneeProviders.filter((item) => proviserAssineeId.includes(item.staff_id));
      setTaskAssignee(setAssigneeProviders);
    }
  }, [JSON.stringify(companyEmployeeData), JSON.stringify(providerlistTableData)])
  return (
    <div className="task-detail-header h-100"
    onClick={()=>{
      dispatch(setActionForLeaseDocument());
    }}>
      <div className="d-flex align-items-center h-100">
        <div className="task-header-left flex-grow-1">
          <div className="d-flex align-items-center">
            {
              ["type_of_space","equipment_task"].includes(type) ?
              <>
                  <Form.Group className="c-input-box pb-0 position-relative position-sticky top-0 bg-base-header-color z-index-4 w-33">
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                          <div className="align-items-center bg-transparent border-0 d-flex flex-grow-1 form-control h32wauto justify-content-between ps-0">
                              <div className="with_separator_10 flex-grow-1 ps-0">
                                  <Autocomplete
                                      inputClass="bg-transparent border-0"
                                      dropdownClass="max-height-20vh"
                                      value={type == "type_of_space" ? newTypeOfSpaceData.title : equipmentData.title}
                                      options={getSpaceOptionList()}
                                      onChange={(e) => {
                                        if (type == "type_of_space") {
                                          setNewTypeOfSpaceData({
                                            ...newTypeOfSpaceData,
                                            title: e.target.value,
                                        });
                                        } else {
                                          handleChange("title", e.target.value)
                                        }
                                      }}
                                      onSelect={(value) => {
                                        if (type == "type_of_space") {
                                          setNewTypeOfSpaceData({
                                            ...newTypeOfSpaceData,
                                            title: value.label,
                                        });
                                        } else {
                                          selectedEquipmentsQuantity(value.value);
                                        }
                                      }}
                                  />
                              </div>
                          </div>
                      </div>
                  </Form.Group>
              </>
              : type == "poi" ? 
                  <div className="task-name-input w100minus40 position-relative">
                    <div className={`absoluteTaskName ${namePermission ? "" : "d-none"}`}
                      data-bs-placement="left">
                    </div>
                    <input
                      type=""
                      className="form-control transparent py-0 ps-0 title-fonts shadow-none"
                      placeholder={`${_l("l_poi_name")}`}
                      data-bs-placement="left"
                      value={data.name}
                      onChange={(e) => {handleAction("poi_title",e.target.value)}}
                    />
                  </div>
              :
            <div className={`task-name-input  position-relative ${type == "provider_page"? "" : "w100minus40"}`}>
              <div className={ `absoluteTaskName  ${isExternal || namePermission ? "":"d-none"}` }
                    data-bs-placement="left">
                  </div>
                    <div className="d-flex">
                      <input
                        type=""
                        className="form-control transparent py-0 ps-0 title-fonts shadow-none c-font f-14 fw-semibold"
                        placeholder={`${_l("l_task_name")}`}
                        data-bs-placement="left"
                        value={
                          type === "gmail"
                            ? (requestData.conversation.messages[0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value']
                            : requestData && requestData.id > 0 && type != "provider_page" 
                              ? type == "pilotonboarding"
                                ? _l("l_pilot_onboarding") 
                              : type == "task" || type == "request-task" 
                                ? name : `#${data.id || data.taskId || data.document_id} - ${name}` 
                              : requestData.name
                        }
                        currentitem="false"
                        onClick={() => { setModalHeaderIconToggle(false) }}
                        onChange={(e) => {
                          if (requestData && requestData.id > 0) {
                            setName(e.target.value);
                            handleAction("nameChangeValue", e.target.value);
                          } else {
                            setRequestData({ ...requestData, name: e.target.value })
                          }
                        }}
                        onBlur={(e) => {
                          handleAction("nameUpdate", e.target.value);
                        }}
                        disabled={isExternal}
                      />
                      {type == "provider_page" ? <>
                        {categoryListing.length > 0 ? (
                          <div
                            className={`position-relative d-flex assignee-list-wrapper card-view-less-padding`}
                          >
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus ${categoryListing && categoryListing.length > 0
                                  ? ""
                                  : "d-none"
                                }`}
                            >
                              {categoryListing && categoryListing.length >= 1 ? (
                                categoryListing.map((assignee, index) => {
                                  if (index < 3)
                                    return (
                                      <div className="pe-10px">
                                        <div
                                          className="badge rounded-pill text-nowrap list-toggle-view-none"
                                          key={index}
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span className={` p-0 `}>
                                            {assignee.main_key == "my_task"
                                              ? "#"
                                              : ""}
                                            {_l(assignee.label)}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                })
                              ) : (
                                <></>
                              )}
                              {categoryListing && categoryListing.length > 3 ? (
                                <div className="pe-10px">
                                  <a
                                    href="#/"
                                    className="badge rounded-pill text-nowrap list-toggle-view-none"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <div className="unread_count m-auto c-font f-10">
                                      +{categoryListing.length - 3}
                                    </div>
                                  </a>
                                  <GeneralListing
                                    handler={() => { }}
                                    list={
                                      categoryListing &&
                                      categoryListing.length &&
                                      categoryListing.map((item, index) => {
                                        return {
                                          name: item.label,
                                          id: index,
                                        };
                                      })
                                    }
                                    isHash={true}
                                    direction={true}
                                    unControlList={true}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </> : <></>}
                    </div>
            </div>
          }
          </div>
          </div>
          {type === "provider_page" && 
          <div
            className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_separator2_10 with_minus ${
              taskAssignee && taskAssignee.length >= 1
                ? ""
                : "d-none"
            }`}
          >
            {taskAssignee && taskAssignee.length >= 1 ? (
              taskAssignee.map((assignee, index) => {
                if (index < 1)
                  return (
                    <a
                      href="#/"
                      data-bs-toggle="tooltip"
                      className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                      key={index}
                    >
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url('${
                            assignee.profile_image
                          }')`,
                        }}
                      ></div>
                    </a>
                  );
              })
            ) : (
              <></>
            )}
            {taskAssignee && taskAssignee.length > 1 ? (
              <a
                href="#/"
                className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="unread_count m-auto c-font f-12">
                  +{taskAssignee.length - 1}
                </div>
              </a>
            ) : (
              <></>
            )}
            {taskAssignee && taskAssignee.length ? (
              <GeneralListing
                handler={() => {}}
                list={
                  taskAssignee &&
                  taskAssignee.length &&
                  taskAssignee.map((item, index) => {
                    return {
                      name: item.full_name,
                      image:
                        item.profile_image,
                      id: index,
                    };
                  })
                }
              />
            ) : (
              <></>
            )}
          </div>
          }
        {type == "provider_page" && 
          <UncontrolledDropdown className="comman_action_icon ms-10px"
              ><DropdownToggle
                  tag={'a'}
                  className="d-flex on-hover-active-toggle-img action_icon with_bg h32w32"
                  type="button"
                  data-bs-toggle="dropdown" 
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                  onClick={(e) => {
                      e.stopPropagation();
                  }}
              > 
              <div className="d-flex align-items-center w-100">
                  <UserCircleGearIcon
                    className={`HW24`}
                  />
              </div>
              </DropdownToggle>
              <DropdownItem toggle={false} className="p-0"> 
                  <UnControlMainFiltersDropDown
                      type = {"provider_page"}
                  />
              </DropdownItem>
          </UncontrolledDropdown>
        }
        <div className="task-header-right pe-2">
          <div className="d-flex align-items-center justify-content-end comman_action_icon">
            <a href="#/"
              className={`btn btn-primary btn-sm line-height-1 action_icon mx-2 ${
                taskRedirectBtnPermission ? "" : "d-none"
              }`}
              onClick={() => {
                if (taskRedirectBtnPermission) {
                  handleAction("taskMoveBtn", "");
                }
              }}
            >
              {taskRedirectBtnText}
            </a>
            {type != "task" &&
              type != "request-task" &&
              type != "lead" &&
              type != "gmail" &&
              type != "document" &&
              userType == "operator" ? (
              <></>
            ) : (
              <></>
            )}
            {status != "" && type != "task" &&
            type != "request-task" &&
            type != "lead" &&
            type != "gmail" &&
            type != "document" &&
            type != "pilotonboarding"  ? (
              <>
              </>
            ) : (
              <></>
            )}
            <div className={`badge ${
                  type == "request-task" ? "badge-warning" : ""
                } rounded-pill d-none`}>
              <span
                className={``}
              >
                {userType == "operator" && documentType == "benchmark" ? _l('l_proposal') : documentType}
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              {type != "type_of_space" && type != "poi" && type != "task" &&
              type != "request-task" &&
              type != "lead" &&
              type != "gmail" &&
              type != "document" &&
              type != "pilotonboarding" ? (
                <>
                  {type == "schedule" || type == "benchmark" ? (
                    <>
                      <div className="task-header-right ps-2 d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          id="budget"
                          className="h-100 d-flex"
                          onChange={(e) => {
                            dispatch(
                              createScheduleDetails({
                                ...createScheduleDetail,
                                [`isUnKnownBudget`]: e.target.checked,
                              })
                            );
                            dispatch(
                              getBenchmarkDetail(
                                0,
                                0,
                                "edit",
                                "budget_is_empty",
                                e.target.checked ? "1" : "0"
                              )
                            );
                          }}
                          checked={isUnKnownBudget}
                          disabled={
                            (selectedTab == "create-schedule-type" && !readOnlyMode) ? false : true
                          }
                        />
                        <a href="#/"
                          className="with_separator_10"
                        >
                          <InfoIcon className="HW16" />
                        </a>
                        <div className="c-font color-white-80 f-12 mx-2"
                           >
                          {_l("l_budget")}
                        </div>
                        <div
                          className={`d-flex align-items-center radius_3 badge-white ps-2 ${
                            isUnKnownBudget ? "schedule-checbox-disbled" : ""
                          }`}
                        >
                          <span className=" color-green c-font f-10 fw-semibold text-center title-fonts">
                            €
                          </span>
                          <input
                            className="bg-transparent m_w50 color-green c-font f-10 fw-semibold p-1 px-0  text-center title-fonts border-0"
                            value={isUnKnownBudget || !myr_total ? 0 : myr_total.replace(/[^0-9.]|_/g, "")}
                            type="number"
                            onChange={(e) => {
                              const value = e.target.value;
                              if (!isNaN(parseFloat(value))) {
                                setMyr_total(value);
                                if (type == "benchmark" || type == "schedule") {
                                  scheduleBenchmarkAmount(value);
                                }
                              } else {
                                setMyr_total("");
                              }
                            }}
                            disabled={
                              selectedTab == "create-schedule-type"
                                ? false
                                : true
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={`comman_action_icon ${type == "provider_page" || type == "leaseDocument" ? "d-none" : ""} `}>
                      <div className="f-12 fw-semibold badge radius_3 H32 d-flex">
                        <span className="color-green m-auto">{myr_total}</span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              {
                type == "leaseDocument"?
                <>
                   <a href="#/"
                          id="editlease"
                          data-bs-toggle="dropdown"
                          onClick={() => {
                             localStorage.setItem("leaseDetailType", "lease-contract");
                                            navigate(`/space-onboarding?space=${spaceBasicDetails.project_id}&edit=1`);
                                            hideLeftPanelNew();
                          }}
                        >
                          <div className="btn btn-primary btn-sm-2 text-uppercase line-height-1">
                            {_l("l_edit_lease_contract")} 
                          </div>
                        </a>
                </> : <></>
              }             
            </div>
            {type == "poi" ? 
            <a href="#/"  className={`btn btn-primary btn-sm-2 text-uppercase line-height-1 mt-1`} disabled={loaderForPOI} onClick={() => {
                  handleAction("poi_create");
                }}>
            {loaderForPOI ? _l('l_please_wait') : _l('l_save')}
            {loaderForPOI ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </a>
            :<></>}
          </div>
        </div>
      </div>
  </div>
  );
}
export default React.memo(CommonHeaderModal);
