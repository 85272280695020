import React from 'react';
 

function UserMagnifyingIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M26.25 45C36.6053 45 45 36.6053 45 26.25C45 15.8947 36.6053 7.5 26.25 7.5C15.8947 7.5 7.5 15.8947 7.5 26.25C7.5 36.6053 15.8947 45 26.25 45Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.5085 39.5085L52.4999 52.4999" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.2502 29.844C30.3924 29.844 33.7502 26.4861 33.7502 22.344C33.7502 18.2019 30.3924 14.844 26.2502 14.844C22.1081 14.844 18.7502 18.2019 18.7502 22.344C18.7502 26.4861 22.1081 29.844 26.2502 29.844Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 36.406C17.2699 32.4837 21.3926 29.8435 26.25 29.8435C31.1074 29.8435 35.2301 32.4837 37.5 36.406" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

			{/* <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" >
				<g clip-path="url(#clip0_2188_48928)">
					<path d="M26.25 45C36.6053 45 45 36.6053 45 26.25C45 15.8947 36.6053 7.5 26.25 7.5C15.8947 7.5 7.5 15.8947 7.5 26.25C7.5 36.6053 15.8947 45 26.25 45Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M39.5085 39.5085L52.4999 52.4999" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M26.2502 29.844C30.3924 29.844 33.7502 26.4861 33.7502 22.344C33.7502 18.2019 30.3924 14.844 26.2502 14.844C22.1081 14.844 18.7502 18.2019 18.7502 22.344C18.7502 26.4861 22.1081 29.844 26.2502 29.844Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M15 36.406C17.2699 32.4837 21.3926 29.8435 26.25 29.8435C31.1074 29.8435 35.2301 32.4837 37.5 36.406" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_2188_48928">
						<rect width="60" height="60" fill="currentColor" />
					</clipPath>
				</defs>
			</svg> */}
		</React.Fragment>
	)
}

export default UserMagnifyingIcon