import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogoGreenDark from "../assets/icons/LogoGreenDark";
import LogoGreenLight from "../assets/icons/LogoGreenLight";
import myMsgAudio from "../assets/audio/my-msg-audio.mp3";
import HeaderUserDropdown from "./Dropdowns/HeaderUserDropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { _l, addActiveBorderforWeekCalendar, formateDate, getImagePreviewIcon, hideLeftPanelNew, SetLightAndDarkTheme, showError, showLeftPanelNew, updateBurgerMenuIDB } from "../hooks/utilities";
import jquery from "jquery";
import moment from "moment";
import Button from "react-bootstrap/Button";
import {
  leftPanelFilters,
  getTaskCount,
  setSpaceNotificationCount,
  projectTaskCounts,
  setVirtualSpaceNotificationCount,
  getTopicTasks,
  getDefaultTopics,
  userLogout,
  selectedChatUser,
  setAllChatUserList,
  setTaskTypeFilter,
  setSelectedGloablView,
  taskBulkAction,
  setAllCheckedTask,
  setSelectedDateView,
  setMetricsDetails,
  getDashboardFilterList,
  setFetchedTaskandMessages,
  updateTaskList,
  chatUnreadMessageCount,
  unreadTaskComment,
  lastMessageSpace,
  setCallRecording,
  setCallDisconnect,
  lastMessageContactDir,
  getAttendanceList,
  fetchMails,
  dayGridWeek,
  dayGridMonth,
  dayGridDay,
  showTasklistInCalendar,
  setselectedDaterangeFilter,
  setDaterangedayDifference,
  setShowWeekcalendardays,
  setDaterangeFilterApplied,
  setDaterangeFilter,
  setCalendarView,
  setActiveTaskStatusFilters,
  setSelectedDateForChatai,
  getAttendanceCountWeekCalendar,
  setFilterSpaceProjectid,
  setMyDriveTopic,
  setTenatsRoleId,
  setTabForSetupAccountAi,
  setTentantsEmployeeData
} from "../actions/customer";
import {
  updateUnreadMsgCount,
  updateNewMessage,
  updateNewTaskNoti,
  playMsgAudio,
  updateGeneralMsgCount,
  setOpenGeneralChatGroup,
  userOffline,
  fetchGroups,
  initCall,
  getGroupDetails,
  groupIn,
  handleChatScrolltoStop,
  groupOut,
  fetchMessages,
  fetchGroupsExternal,
} from "../actions/chat";
import MailSetting from "./Modals/MailSetting";
import { useLocation } from "react-router-dom";
import customerServices from "../services/customer-services";
import benchmarkServices from "../services/benchmark-services";
import { showConfirmation, showMessage, toastCloseAction } from "../actions/messages";
import { SET_GLOBAL_SEARCH_STRING, USER_LOGOUT } from "../actions/action-type";
import SetUserPassword from "./Modals/SetUserPassword";
import HeaderFilterDropdown from "./TaskModalComponents/HeaderFilterDropdown";
import StepIcon from "../assets/icons/StepIcon";
import FloorFilterDropdown from "./Dropdowns/FloorFilterDropdown";
import Autocomplete from "./Dropdowns/Autocomplete";
import adminServices from "../services/admin-services";
import ActionDropdown from "./Dropdowns/ActionDropdown";
import { ATTACH_BASE_URL, USR_IMG_PLACEHOLDER } from "../actions/chat-action-type";
import { DropdownToggle, UncontrolledDropdown, Dropdown, DropdownItem } from "reactstrap";
import UncontrolledGeneralListing from "./TaskModalComponents/UncontrolledGeneralListing";
import TopicSettingModal from "./Modals/TopicSettingModal";
import ChatServices from "../services/chat-services";
import ChatGroupDetailModal from "./Modals/ChatGroupDetailModal";
import EquipmentModalHTML from "./Modals/EquipmentModalHTML";
import chatServices from "../services/chat-services";
import FeedChatCallModal from "./Modals/FeedChatCallModal";
import FeedChatAudioCall from "./ChatAi/FeedChatAudioCall";
import BulkActionsModal from "./Modals/BulkActionsModal";
import CalendarWithFilterIcon from "../assets/icons/CalendarWithFilterIcon";
import CalendarHeader from "./CalendarHeader";
import DateRangeDropdown from "./Dropdowns/DateRangeDropdown";
import UnControlMainFiltersDropDown from "./TaskModalComponents/LeadsMainFiltersDropDown";
import ListBulletsIcon from "../assets/icons/ListBulletsIcon";
import AddEmailTemplatesModalNew from "./Modals/AddEmailTemplatesModalNew";
import { leadFiltersApplied } from "../actions/leads-actions/leads-actions";
import SpaceListDropdown from "./Dropdowns/SpaceListDropdown";
import { startCall } from "../actions/vapi";
import { readCollectionFromDB } from "../hooks/indexed_db_helpers";
import differenceInDays from "date-fns/differenceInDays";
import getMonth from "date-fns/getMonth";
import CalendarBlankIcon from "../assets/icons/CalendarBlankIcon";
import ChatIcon from "../assets/icons/ChatIcon";
import XIcon from "../assets/icons/XIcon";
import MagnifyingGlassIcon from "../assets/icons/MagnifyingGlassIcon";
import CaretLeftIcon from "../assets/icons/CaretLeftIcon";
import HardDrivesIcon from "../assets/icons/HardDrivesIcon";
import SignOutIcon from "../assets/icons/SignOutIcon";
import DotsThreeIcon from "../assets/icons/DotsThreeIcon";
import GearSixIcon from "../assets/icons/GearSixIcon";
import ListIcon from "../assets/icons/LinkIcon";
import DeskIcon from "../assets/icons/DeskIcon";
import UsersThreeIcon from "../assets/icons/UsersThreeIcon";
import FunnelIcon from "../assets/icons/FunnelIcon";
import UserIcon from "../assets/icons/UserIcon";
import ClipboardIcon from "../assets/icons/ClipboardIcon";
import PhoneCallIcon from "../assets/icons/PhoneCallIcon";
import FadersHorizontalIcon from "../assets/icons/FadersHorizontalIcon";
import EnvelopeSimpleIcon from "../assets/icons/EnvelopeSimpleIcon";
import MapPinIcon from "../assets/icons/MapPinIcon";
import FileTextIcon from "../assets/icons/FileTextIcon";
import ShareNetworkIcon from "../assets/icons/ShareNetworkIcon";
const BACK_BUTTON_RESTRICTED = ["proposal"];
const Header = ({
  projectId,
  removeStatusFilter,
  hideDriveSyncBtn = true,
  setShowMailModal,
  showMailModal,
  setSynchronizedMail,
  pageName = "",
  setProjectId = () =>{}
}) => {
  const {
    taskFilters,
    activeTaskStatusFilters,
    globalSearchString,
    isBackdrop,
    commanoffcanvasIsOpen,
    generalUnreadCount,
    selectedUserForChat,
    globalView,
    pinDelayfilter,
    getCheckedTask,
    dateView,
    groupList,
    lastChatSession,
    defaultTopics,
    dashBoradFilter,
    createTaskModalIsopen,
    virtualSpaceList,
    getSelectedFloorId,
    isContactDirChat,
    leftPanelTab,
    filterProjectID,
    setupAccTab,
    tenantsEmployeeData,
    taskList,
    daterangeAppliedFilters,
    myDriveTopic
  } = useSelector((state) => state.customer);
  const isPremiumEstimateEdit = useSelector(
    (state) => state.customer.isPremiumEstimateAccess
  );
  const stepData = useSelector((state) => state.onboardingReducer.stepData);
  const [preEstiPageDisable, setPreEstiPageDisable] = useState(false);
  const {
    unreadMsgCount,
    newMessage,
    newTaskNoti,
    openGeneralChatGroup,
    callStatus,
    groupDetails
  } = useSelector((state) => state.chat);
  const {
    myrStaffList,
    myrLeadSources,
    checkedBulkLeads,
    leadsFilters,
  } = useSelector((state) => state.leadsReducer);
  const chatPlanTittle = useSelector((state) => state.customer.chatPlanTittle);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let key = searchParams.get("feature")
  let providerType = searchParams.get("provider-type")
  const displayUnreadMsgCount = ((globalView == "liteVersionView" && localStorage.getItem("isLiteVersion") == "true") && generalUnreadCount > 0 ) || (localStorage.getItem("isLiteVersion") != "true" && unreadMsgCount > 0) ? "" : "d-none";  
  const pathname = window.location.pathname;
  const isLeadPage = pageName == "leadPage" ? 1 : 0;
  const [currentUrl, setCurrentUrl] = React.useState(pathname);
  const [taskType, setTaskType] = useState('2');
  const [selectedOffice, setSelectedOffice] = useState({});
  const navigate = useNavigate();
  const [CalendarPageActiveClass, setCalendarPageActiveClass] = useState(false);
  const [DocumentPageActiveClass, setDocumentPageActiveClass] = useState(false);
  const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
  const [ChatPageActiveClass, setChatPageActiveClass] = useState(false);
  const [showFilterArea, setShowFilterArea] = useState(true);
  const ref = useRef(null);
  const userType = localStorage.getItem("user_type");
  const packagePlan = localStorage.getItem("packagePlan") ? JSON.parse(localStorage.getItem("packagePlan")) : {}
  const [showSupportNavigator, setShowSupportNavigator] = useState(true);
  const [showDocumentsNavigator, setShowDocumentsNavigator] = useState(true);
  let searchMentionUsers = [];
  const spaceState = useSelector((state) => state.customer.spaces);
  const [searchString, setSearchString] = useState('');
  const [mentionStaffArray, setMentionStaffArray] = useState([]);
  const [selectedStaffMembersForSpaceFilter, setSelectedStaffMembersForSpaceFilter] = useState([]);
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const outlook_calendar = useSelector(
    (state) => state.discord_auth.outlook_calendar_connectivity
  );
  const [showGlobalSerachModal, setShowGlobalSerachModal] = useState(false);
  const mailModalHandleClose = () => setShowMailModal(false);
  const mailModalHandleShow = () => setShowMailModal(true);
  const location = useLocation();
  const [loginUserImage, setLoginUserImage] = useState(
    localStorage.getItem("contact_image")
  );
  const [topicLogo, setTopicLogo] = useState({});
  const [selectedTeam, setSelectedTeam] = useState();
  const {themeColor} = useSelector((state) => state.customer)
  const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);
  const [show, setShow] = useState(false);
  const [viewGroupDetails, setViewGroupDetails] = useState(false);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [groupMemberIds, setGroupMemberIds] = useState([]);
  const [providerOpearator, setProviderOpearator] = useState([]);
  let chatUserId = localStorage.getItem("chatUserId");
  let project_id = localStorage.getItem('selectedOffice')
  const [filterArr, setFilterArr] = useState([]);
  let virtualSpaceClientId = localStorage.getItem("virtualSpaceClientId")
  const [totalNotificationCount, settotalNotificationCount] = useState(0);
  const [missedcallCounts, setmissedcallCounts] = useState(0);
  const [selectedTopic, setselectedTopic] = useState();
  let selected_topic = JSON.parse(localStorage.getItem("selectedTopic"))
  let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []
  const [showBulkActionForLeads, setShowBulkActionForLeads] = useState(false);
  const [myrStaff, setMyrStaff] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [showPasswordSetModal, setShowPasswordSetModal] = useState(localStorage.getItem("is_registration_confirmed") == 0);
  const [isOnline, setIsOnline] = useState(0)
  const [tagesLeads, settagesLeads] = useState([]);
  let is_registered = localStorage.getItem("is_registered");
  let isContactDir = groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey == "contact_directory";
  const dashboardBenchmarkId = !isNaN(Number(searchParams.get("dashboard-benchmark-id"))) && Number(searchParams.get("dashboard-benchmark-id")) ? Number(searchParams.get("dashboard-benchmark-id")) : 0;
  const [showDateRangeDropDown, setShowDateRangeDropDown] = useState(false);
  const [datesArr,setDatesArr] = useState([]);
  const [isDateRangeFilterApplied, setIsDateRangeFilterApplied] = useState(false);
  const [dayDifference, setdayDifference] = useState(0);
  const [appliedFilters, setappliedFilters] = useState("today");
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const [showSearchbar, setShowSearchbar] = useState(false)
  let ONBOARDING_PAGES = ["/hire-new-agent","/onboarding-proposal","/onboarding-employees","/view-onboarding-proposal","/job-proposal-onboarding" ,is_registered == 1 ? "/connect-provider" : ""];
  const [selectedleadfilers, setselectedleadfilers] = useState([]);
  const [bulkEmailSendShow, setBulkEmailSendShow] = useState(false);
  const [calendarFilterDate, setcalendarFilterDate] = useState(null);
  const modalhandleleadClose = () => setBulkEmailSendShow(false);
  const modalhandleleadShow = () => setBulkEmailSendShow(true);
  const [selectedSpace, setSelectedSpace] = useState(0);
  const [openSpaceListDropdown, setOpenSpaceListDropdown] = useState(false);
  const [startDateNew, setStartDateNew] = useState('');
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const is_agent = localStorage.getItem('is_agent') == 1 ? 1 : 0;
  // Access individual query parameters
  const searchSpaceId  = queryParams.get("space");
  const userHashId = localStorage.getItem("user_hash_id") ? localStorage.getItem("user_hash_id") : ""
  const curPage = localStorage.getItem("currentLeftPanelTab");
  const status_filter = [
    "estimate",
    "staff",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "_equipments",
    "invoice",
    "credit_notes",
    "subscription",
    "schedule",
    "benchmark",
  ];
  useEffect(() => {
    if(groupDetails && groupDetails.members){
      var chatUserId = localStorage.getItem("chatUserId");
      setGroupMemberIds(groupDetails.members.map((val) => val._id));
      if(taskFilters.some((filter)=>filter.main_key == "my_task")){
        setIsGroupAdmin(true)
      }else{
        setIsGroupAdmin(true);
      }
      if (selected_topic && selected_topic.title) {
        const online = groupDetails.members.filter((user) => user.lname && user.lname != '' ? `${user.fname} ${user.lname}` == selected_topic.title : user.fname == selected_topic.title)[0]
        if (online && online.isOnline && online.isOnline == 1) {
          setIsOnline(1);
        }else{
          setIsOnline(0);
        }
      }
    }
  }, [groupDetails]);
  function getImageDimensions(image) {
    return new Promise((resolve, reject) => {
      image.onload = function (e) {
        const width = this.width;
        const height = this.height;
        resolve({ height, width });
      };
    });
  }
  function compressImage(image, scale, initalWidth, initalHeight) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      canvas.width = scale * initalWidth;
      canvas.height = scale * initalHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpg");
    });
  }
  async function readAndPreview(file) {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    var newName = timestamp + ".png";
    file.name = newName;
    var file = new File([file], file.name, { type: file.type });
    return file
  }
  async function previewImage(inputId, previewId = "", previewAttr = "src") {
    const imageInput = document.getElementById(inputId);
    const uploadedImage = imageInput.files[0];
    if (!uploadedImage) {
      return;
    }
    var name = uploadedImage.name;
    if (!/\.(jpe?g|png|jpg)$/.test(name)) {
      return dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_select_valid_file")));
    }
    const inputPreview = document.getElementById("input-preview");
    inputPreview.src = URL.createObjectURL(uploadedImage);
    const { height, width } = await getImageDimensions(inputPreview);
    const MAX_WIDTH = 500;
    const MAX_HEIGHT = 500;
    const widthRatioBlob = await compressImage(
      inputPreview,
      MAX_WIDTH / width,
      width,
      height
    );
    const heightRatioBlob = await compressImage(
      inputPreview,
      MAX_HEIGHT / height,
      width,
      height
    );
    const compressedBlob =
      widthRatioBlob.size > heightRatioBlob.size
        ? heightRatioBlob
        : widthRatioBlob;
    const outputPreview = document.getElementById("output-preview");
    outputPreview.src = URL.createObjectURL(compressedBlob);
    if(previewId != ""){
      const previewImage = document.getElementById(previewId);
      if(previewAttr == "style"){
        previewImage.style.backgroundImage = "url('"+URL.createObjectURL(compressedBlob)+"')";
      }else{
        previewImage.src = URL.createObjectURL(compressedBlob);
      }
    }
    compressedBlob.name = name;
    URL.revokeObjectURL(inputPreview);
    URL.revokeObjectURL(outputPreview);
    return readAndPreview(compressedBlob);
  }
  const [equipementModalHTML, setEquipementModalHTML] = useState(false)
  const handleDropDownOpen = () => {
    jquery("#HeaderDocumentLinkDropdown").addClass("active");
  };
  const handleDropDownClose = () => {
    jquery("#HeaderDocumentLinkDropdown").removeClass("active");
  };
  const [showPills, setshowPills] = useState()
  const [showSettingModal, setshowSettingModal] = useState(false)
  useEffect(() => {
  setshowPills(window.innerWidth <= 1500 ? 1 : 2)
})
useEffect(() => {
  SetLightAndDarkTheme();
  if (themeColor == "light") {
    setLightTheme(true);
  }else{
    setLightTheme(false);
  }
}, [themeColor])
useEffect(() => {
  dispatch(getDashboardFilterList());
  if (Number(is_registered) == 1) {
    localStorage.removeItem("selectedOffice")
  }
}, [])
const handleCreateChatGroup =(projectId,main_key,main_sub_key,filter_key)=>{
  localStorage.removeItem("firstDate");
  localStorage.removeItem("lastDate");
  dispatch(setFetchedTaskandMessages([]));
  var activeGroupIdPrev = localStorage.getItem("activeGroupId");
  if (chatUserId != "" && activeGroupIdPrev != "" && activeGroupIdPrev != undefined) {
    var socketDataOut = {
      groupId: activeGroupIdPrev,
      userId: chatUserId,
    };
    dispatch(groupOut(socketDataOut));
  }
    customerServices.createChatGroup(projectId,main_key,main_sub_key,filter_key).then((res)=>{
      if(res && res.status && res.data){
        let groupID = res.data._id
        localStorage.setItem("activeGroupId", groupID);
        chatServices.getGroupDetails( localStorage.getItem("chatUserId"), groupID).then((res) => {
          if (res.success) {
            var activeGroupId = localStorage.getItem("activeGroupId");
            if (chatUserId != "" && activeGroupId != "" && activeGroupId != undefined) {
              var socketDataIn = {
                groupId: activeGroupId,
                userId: chatUserId,
              };
              if (globalView  == "AichatView") {
                dispatch(groupIn(socketDataIn));
                dispatch(fetchMessages( 
                  localStorage.getItem("chatUserId"), 
                  groupID,
                  0,
                  ()=>{},
                  1,
              ));          
              }
            }
            localStorage.setItem("getGroupDetails", JSON.stringify(res.result))
            dispatch(getGroupDetails(res.result))       
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        });
      }else{
        localStorage.setItem("activeGroupId",0);
        dispatch(showMessage("unsucess", _l("l_error"),_l(res && res.message ? res.message : "l_something_went_wrong")));
      }
    })
  }
useEffect(() => {
  if (dashBoradFilter) {
    let filter = []
    let tempFilterData = []
    dashBoradFilter.forEach((dd) => {
      if (dd.is_active == 1) {
        filter.push(dd.id);
        tempFilterData.push(dd);;
      }
    })
    setFilterArr(filter.slice());
  }
}, [JSON.stringify(dashBoradFilter)])
  useEffect(() => {
    if (localStorage.getItem("selectedVirtualSpace")) {
      try {
        let virtualSPace = JSON.parse(
          localStorage.getItem("selectedVirtualSpace")
        );
        setSelectedTeam(
          virtualSPace && Object.keys(virtualSPace).length ? virtualSPace : {}
        );
        dispatch(
          getDefaultTopics(
            localStorage.getItem("selectedOffice"),
            "",
            "",
            pageName
          )
        );
      } catch (error) {
        console.log(error);
      }
      jquery(".link-center-box-toggle").addClass("d-none");
    } else {
      setSelectedTeam();
      jquery(".link-center-box-toggle").removeClass("d-none");
    }
  }, [localStorage.getItem("selectedVirtualSpace")]);
  const token = localStorage.getItem("accessToken");
  const adminData = localStorage.getItem("adminArchieve");
  const contact_role = localStorage.getItem("contact_role");
  if (!token && !adminData) {
    navigate("/login");
  }
  const GlobalModalSearchShow = () => {
    jquery(".after-login-header").addClass("comman-backdrop-search");
    jquery(".c-top-calendar").addClass("comman-backdrop-search");
    jquery(".create-task-box-main").addClass("comman-backdrop-search");
    jquery(".fixed-left-panel ").addClass("comman-backdrop-search");
    jquery("footer").addClass("comman-backdrop-search");
    jquery(".custom-class").addClass("comman-search-on-focus");
    jquery("#modal-overlay-toggle").removeClass("modal-overlay");
    setShowGlobalSerachModal(false);
  };
  const GlobalModalSearchHide = () => {
    if(createTaskModalIsopen == false){
    jquery(".after-login-header").removeClass("comman-backdrop-search");
    jquery(".c-top-calendar").removeClass("comman-backdrop-search");
    jquery(".create-task-box-main").removeClass("comman-backdrop-search");
    jquery(".fixed-left-panel ").removeClass("comman-backdrop-search");
    jquery("footer").removeClass("comman-backdrop-search");
    jquery("body").removeClass("searchbackdrop");
    jquery("#modal-overlay-toggle").addClass("modal-overlay");
    }
  };
  useEffect(() => {
    if (searchString == "" && globalSearchString == "") { 
      GlobalModalSearchHide()
    }
  },[searchString ,globalSearchString])
  const NoHeaderIconPage = [
    "estimate",
    "staff",
    "client",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "_equipments",
    "invoice",
    "credit_notes",
    "subscription",
    "schedule",
    "benchmark",
    "MyProfile",
    "PremiumSubscription",    
    "SpaceOnBoardingNew"
  ];
  const globalSearchPageArray = [
    "Dashboard",
    "documents",
    "leadPage",
    "MyEmail",
    "Wallet",
    "Proposals",
    "benchmarkList",
    "estimate",
    "staff",
    "client",
    "MyPeople",
    "calendar",
    "fullcalendar",
    "AdminTaskPage",
    "subscription",
    "credit_notes",
    "equipmentListReporting",
    "_equipments",
    "invoice",
    "schedule",
    "benchmark",
    "operatorListReporting",
    "MySpace",
    "LiteVersion",
    "ChatAi",
    "dashboardnew",
    "ProviderList"
  ];
  const pageNames = {
    CompanyProfile: _l("l_company_profile"),
    Wallet: _l("l_wallet"),
    PremiumSubscription: _l("l_premium_subscription"),
    MyProfile: _l("l_my_profile"),
  };
  const logout = () => {
    var chatUserId = localStorage.getItem("chatUserId");
    if (chatUserId != "" && chatUserId != undefined) {
      dispatch(userOffline({ userId: chatUserId }));
    }
    dispatch(userLogout());
    dispatch(setAllChatUserList(false));
    localStorage.clear();
    localStorage.setItem("version", process.env.REACT_APP_VERSION);
    dispatch({
      type: USER_LOGOUT
    });
    navigate("/login");
  };
  const handleFecthOperatorByCategory = () =>{
    if(groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey && groupDetails.extraData.mainKey == "providers"){
      benchmarkServices.getOperatorsByCategory(projectId, groupDetails.extraData.filterKey ,0).then(
        (res) => {
          if (res.status && res.data) {
            setProviderOpearator(res.data)   
          }
        }
      );
    }
  }
    jquery(document).mouseup(function (e) {
      var container = jquery(".global-search-main, .dropdown-menu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        GlobalModalSearchHide();
      }
    });
  useEffect(() => {
    if (["leadPage","dashboardnew"].includes(pageName)) {
      setShowSupportNavigator(false);
      setShowDocumentsNavigator(false);
    }
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state") != null ? urlParams.get("state") : "";
    if (state.includes("outlook_mail")) {
      customerServices
        .exchangeGoogleCode(code, "outlook_mail")
        .then((response) => {
          if (response.status) {
            window.opener.dispatchEvent(
              new CustomEvent("outlook_mail", { detail: response })
            );
            window.close();
          }
        });
    }
    window.addEventListener("outlook_mail", (event) => {
      let data = event.detail;
      setSynchronizedMail("outlook_mail");
      setShowMailModal(false);
      dispatch(getTopicTasks(selectedOffice.id, "MyEmail"));
      dispatch(showMessage("success", _l("l_success"), data.message));
    });
  }, []);
  useEffect(() => {
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    if (spaces && spaces.length) {
      const spaceId = projectId > 0 ? projectId : searchSpaceId
      const project = spaces.filter((s) => s.project_id == spaceId);
      if (project.length && !dashboardBenchmarkId) {
        setSelectedOffice(project[0]);
      } else {
        setSelectedOffice({})
      }
    }
    if (window.location.pathname === "/calendar") {
      setCalendarPageActiveClass(true);
    }
    if (window.location.pathname === "/documents") {
      setDocumentPageActiveClass(true);
    }
    if (window.location.pathname === "/chat") {
      setChatPageActiveClass(true);
    }
    const element = ref.current;
    if (element != null) {
      element.addEventListener("shown.bs.dropdown", handleDropDownOpen);
      element.addEventListener("hide.bs.dropdown", handleDropDownClose);
    } else {
    }
    return () => {
      if (element != null) {
        element.addEventListener("shown.bs.dropdown", handleDropDownOpen);
        element.addEventListener("hide.bs.dropdown", handleDropDownClose);
      }
    };
  }, [projectId, searchSpaceId]);
  useEffect(() => {
    if (
      googlecalendar_connectivity &&
      googlecalendar_connectivity.data &&
      googlecalendar_connectivity.data.calendar_type != 0 && leftPanelTab != "my-request"
    ) {
      setLeftActivePanelFilters([]);
    }
    if (taskFilters && pageName == "dashboard") {
      setLeftActivePanelFilters(taskFilters);
    }
  }, [googlecalendar_connectivity]);
  useEffect(() => {
    if (outlook_calendar != undefined && parseInt (outlook_calendar.status) != 0) {
      setLeftActivePanelFilters([]);
    }
  }, [outlook_calendar]);
  React.useEffect(() => {
    setCurrentUrl(pathname);
  }, [pathname]);
  useEffect(() => {
    if (currentUrl && currentUrl.includes("benchmarklisting")) {
      setShowFilterArea(false);
    }
  }, [currentUrl]);
  if (!jquery.isEmptyObject(newMessage)) {
    localStorage.setItem("newMessageId", newMessage && newMessage._id)
    dispatch(handleChatScrolltoStop(true));
    let activeGroupId = localStorage.getItem("activeGroupId");
    let tempProjectId = localStorage.getItem('isLiteVersion') == "true" ? 0 : projectId
      if (newMessage.projectId == 0) {
        dispatch(lastMessageContactDir(newMessage.projectId, newMessage, "chat")) 
      } else {
        dispatch(lastMessageSpace(newMessage.projectId, newMessage, "chat")) 
      }
      if (parseInt(newMessage.projectId) == 0 && newMessage.group != activeGroupId) { 
      }else{
        if (newMessage.group != activeGroupId || globalView  != "AichatView" || newMessage.projectId != tempProjectId) {
          dispatch(playMsgAudio());
          dispatch(setSpaceNotificationCount(newMessage.projectId, 1, 1, "chat"));
          dispatch(setVirtualSpaceNotificationCount(newMessage.projectId, 1, 1, "chat"));
          dispatch(chatUnreadMessageCount(newMessage.group, 'add', "chatMSG")) /// burger menu 
        } 
      }
      if (newMessage.projectId == projectId) {
        dispatch(updateUnreadMsgCount(1));
        if (newMessage.groupType == 4) {
          dispatch(updateGeneralMsgCount(1));
        }
      }
    // }
    if ( localStorage.getItem("activeGroupId") != newMessage.group || newMessage.projectId != tempProjectId ) {
    }else if (globalView == "AichatView" &&  localStorage.getItem("activeGroupId") == newMessage.group) {
      var msgListDiv = document.getElementById("scrollableDiv");
      if(msgListDiv){
        msgListDiv.scrollTop = msgListDiv.scrollHeight;
      }
    } 
    // for tantants employees
    if (newMessage.projectId == 0) {
      const updateTenantData = tenantsEmployeeData && tenantsEmployeeData.map((tenant) => {
        if (tenant && tenant.chat_data && tenant.chat_data.groupId == newMessage.group) {
          tenant.chat_data.lastMessageDate = new Date();
          tenant.chat_data.lastMessage = newMessage.message;
          tenant.chat_data.lastMsgUserName = newMessage.senderName;
          tenant.chat_data.unreadCount = activeGroupId == newMessage.group ? 0 : tenant.chat_data.unreadCount + 1;
        }
        return tenant
      })
      dispatch(setTentantsEmployeeData(updateTenantData));
      if (newMessage.group != activeGroupId) {
        dispatch(playMsgAudio());
      }
      if (newMessage.projectId == 0 && leftPanelTab == "my-tenants") {
        updateBurgerMenuIDB('0', "my_contact", updateTenantData)
      }
    }
      dispatch(updateNewMessage());
      dispatch(handleChatScrolltoStop(false));
  }
  if (!jquery.isEmptyObject(newTaskNoti)) {
    dispatch(playMsgAudio());
    if (newTaskNoti.taskId) {
      var taskUnreadCmtCntEl = jquery(
        "span[data-unread-cmt-id='" + newTaskNoti.taskId + "']"
      );
      var taskCmtCntEl = jquery(
        "span[data-cmt-cnt-id='" + newTaskNoti.taskId + "']"
      );
      taskCmtCntEl.text(parseInt(taskCmtCntEl.text()) + 1);
      taskUnreadCmtCntEl.text(parseInt(taskUnreadCmtCntEl.text()) + 1);
      taskUnreadCmtCntEl.removeClass("d-none");
      let indexT = taskList.findIndex((t) => t.id == newTaskNoti.taskId);
      let tempdataT = taskList;
      if (indexT > -1) {
        tempdataT[indexT].unread_comment_count = Number(tempdataT[indexT].unread_comment_count) + 1;
      }
      dispatch(updateTaskList(tempdataT));
    }
    if (newTaskNoti.projectId) {
      dispatch(setSpaceNotificationCount(newTaskNoti.projectId, 1, 1, "task"));
      dispatch(
        setVirtualSpaceNotificationCount(newTaskNoti.projectId, 1, 1, "task")
      );
      if (newTaskNoti.projectId == projectId && newTaskNoti.main_key != "") {
        dispatch(chatUnreadMessageCount(newTaskNoti, 'add', 'task'))
        dispatch(unreadTaskComment(newTaskNoti.main_key))
      }
    }
    dispatch(updateNewTaskNoti());
  }
  var iKey = 0;
  const onPagechangeToggle = () => {
    showLeftPanelNew();
  };
  useEffect(() => {
    setPreEstiPageDisable(false);
    if (isPremiumEstimateEdit == true) {
      setPreEstiPageDisable(true);
    }
  }, [JSON.stringify(isPremiumEstimateEdit)]);
  useEffect(() => {
    setLoginUserImage(localStorage.getItem("contact_image"));
  }, [localStorage.getItem("contact_image")]);
  useEffect(() => {
    if (taskFilters || activeTaskStatusFilters) {
      setLeftActivePanelFilters(taskFilters.concat(activeTaskStatusFilters, pinDelayfilter));
    }
  }, [taskFilters, activeTaskStatusFilters, pinDelayfilter]);
  useEffect(() => {
    if(groupDetails && groupDetails._id && groupDetails.displayPicture != ""){
      setTopicLogo({url : ATTACH_BASE_URL.concat(groupDetails.displayPicture)})
    }else{
      setTopicLogo("")
    }
    handleFecthOperatorByCategory()
  }, [groupDetails])
useEffect(() => {      
  if ((taskFilters.length == 0 || taskFilters.filter((fil)=> fil.filter_key != "unread_comments").length == 0 )&& projectId > 0 && (spaceState.length > 0 || virtualSpaceList.length > 0 )&& (virtualSpaceClientId != "undefined" || virtualSpaceClientId == '')) {
    if (!["my-providers", "my-clients"].includes(leftPanelTab)) {
      applyDefaultfilter()
    }
  }else if(((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (is_registered == 1) && (taskFilters.length == 0 || taskFilters.filter((fil)=> fil.filter_key != "unread_comments").length == 0 && isContactDirChat === false))){
    dispatch(leftPanelFilters({
      main_key: "providers",
      filter_key: "myrai_support",
      title: "Myr.ai Support",
      image: "",
      main_sub_key: "providers",
      sub_category: [],
    }))
  }else if(leftPanelTab == "my-request" && (taskFilters.length == 0 || taskFilters.filter((fil)=> fil.filter_key != "unread_comments").length == 0 )&& projectId == 0 ){
    dispatch(leftPanelFilters("","","applyForMewithAll"));
  }
}, [JSON.stringify(taskFilters), JSON.stringify(lastChatSession), JSON.stringify(defaultTopics),projectId])
  useEffect(() => {
    if(taskFilters.length > 0 && taskFilters.some((filter) => filter.main_key == "providers") && dashBoradFilter.length > 0 && projectId > 0){
      let startdate = localStorage.getItem("selectedDate")
      let provider_id = taskFilters.filter((filter) => filter.main_key == "providers")[0].filter_key
      customerServices.getMetricsForProvider(projectId,provider_id,startdate,dashBoradFilter.filter((fi) => fi.is_active == 1)).then((res)=>{
        if(res && res.status && res.data){
          dispatch(setMetricsDetails(res.data))
        }
      })
    }
  }, [taskFilters, lastChatSession, JSON.stringify(dashBoradFilter), JSON.stringify(filterArr)])
useEffect(() => {
if(taskFilters.length == 1 && dateRangeFilters.length == 1 && !["operatorListReporting","equipmentListReporting"].includes(pageName)){
  if ((userType == "contact" || userType == "operator") && contact_role == 3) {
    applyDefaultfilter()
  }else{
    let cleaningArr = defaultTopics && defaultTopics.filter((topic) => topic.main_key == "providers")
    if (cleaningArr.length > 0) {
     let isCleaning = cleaningArr[0].categories && cleaningArr[0].categories.filter((category) => category.filter_key == 21  && category.is_active == 1)
     if (isCleaning && isCleaning.length > 0 && pageName != "dashboardnew" && projectId && projectId > 0) {
      dispatch(leftPanelFilters("","","applyCleaningFilter"))
      handleCreateChatGroup(projectId,"providers", "providers", 21)      
     } else {
      applyDefaultfilter()
     }
    }else{
      applyDefaultfilter()
    }
  }
}
if (pageName !== "leadPage" ) {
  setselectedleadfilers([]);
  dispatch(leadFiltersApplied([]));
}
}, [pageName])
useEffect(() => {
  if((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (taskFilters.length > 0 && taskFilters.filter((w)=> w.main_key != "contact_directory").length > 0 )&& ( is_registered !== 1) && pageName !== "MyEmail" && !localStorage.getItem("activeGroupId")) {
    if (localStorage.getItem("activeGroupId")) {
    } else {
      if (leftPanelTab == "my-agents" && userType == "operator") {
      }else{
        handleCreateChatGroup(0,"myr_support",userType, localStorage.getItem("staff_id"));
      }
    }
  }
}, [JSON.stringify(taskFilters)])
useEffect(() => {
  if ((spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) && (userType != "staff") ) {
    localStorage.setItem("is_registered",1)
  }else{
    localStorage.setItem("is_registered",0)
  }
}, [spaceState,virtualSpaceList])
  const applyDefaultfilter = () =>{
    if ((userType == "contact" || userType == "operator") && contact_role == 3) {
      hideLeftPanelNew();
      dispatch(
        leftPanelFilters({
          main_key: userType == "contact" ? "my_contact" : "my_operator",
          filter_key: localStorage.getItem("staff_id"),
          title: "l_to_me",
          image: "",
          main_sub_key: "people",
          sub_category: undefined,
        })
      );
      handleCreateChatGroup(projectId,userType == "contact" ? "my_contact" : "my_operator", "people", localStorage.getItem("staff_id"))
      localStorage.setItem("selectedTopic",JSON.stringify({
        id:userType == "contact" ? "my_contact" : "my_operator", 
        main_key:userType == "contact" ? "my_contact" : "my_operator", 
        is_active: 1, 
        main_key_title: "",
        main_sub_key:  "people",
        contact_role : "", 
        total_notification_count: {}, 
        thread_id : "",
        filter_key: localStorage.getItem("staff_id"),
      }))
    } else if(pageName != "dashboardnew"){
      if(!["SpaceOnBoarding", "SpaceOnBoardingNew", "MyProfile", "ConnectProvider", "MyEmail"].includes(pageName))
      {
        showLeftPanelNew();
      }
      let tempTopics = []
      let filter = []
      if (defaultTopics && lastChatSession && lastChatSession.length > 0) { 
        if (lastChatSession[0].main_key == "myr_support" && userType != "staff") {
          tempTopics = defaultTopics.filter((topic) => topic.main_key == "providers")              
        } else {
          tempTopics = defaultTopics.filter((topic) => topic.main_key == lastChatSession[0].main_key)
        }
      }
      if (tempTopics.length > 0) {
        if (lastChatSession[0].filter_key ==  localStorage.getItem("staff_id") && userType != "staff") {
          filter = tempTopics[0].categories.filter((category) => category.filter_key == "myrai_support")              
        } else {
          filter = tempTopics[0].categories.filter((category) => category.filter_key == lastChatSession[0].filter_key && category.is_active == 1)
        }
      }
      if (leftPanelTab == "my-request") {
        dispatch(leftPanelFilters("","","applyForMewithAll"));
        dispatch(setDaterangeFilter("all"))
        dispatch(setDaterangeFilterApplied(true));
      }else  if (filter.length > 0 && !["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName)) {
        filter.map((category) => {
          let mainKey = category.filter_key == "myrai_support" ? "myr_support" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key
          let filterKey = category.filter_key == "myrai_support" ? localStorage.getItem("staff_id") : category.filter_key
          let mainSubKey =  category.filter_key == "myrai_support" ? userType : category.main_sub_key
          dispatch(
            leftPanelFilters({
              main_key: category.filter_key == "myrai_support" ? "providers" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key ,
              filter_key: category.filter_key,
              title: category.title,
              image: category.image,
              main_sub_key: category.main_sub_key,
              sub_category: category && category.sub_category  ? category.sub_category : [],
            })
          );
          localStorage.setItem("myrUserId",category.contact_id)
          localStorage.setItem("selectedTopic",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, contact_role : category.contact_role, total_notification_count: category.total_notification_count, thread_id : category.thread_id,filter_key: category.filter_key ,image: category.image}))
          localStorage.setItem("callerInfo",JSON.stringify({id:category.filter_key, title: category.title, main_key:tempTopics[0].main_key, is_active: category.is_active, main_key_title: tempTopics[0].title,main_sub_key: category.main_sub_key, contact_role : category.contact_role, total_notification_count: category.total_notification_count, thread_id : category.thread_id,filter_key: category.filter_key ,image: category.image}))
          setselectedTopic({
            id:category.filter_key, 
            title: category.title, 
            main_key:tempTopics[0].main_key, 
            is_active: category.is_active, 
            main_key_title: tempTopics[0].title,main_sub_key: 
            category.main_sub_key, 
            contact_role : category.contact_role , 
            total_notification_count : category.total_notification_count
          })
          dispatch(unreadTaskComment(category.filter_key == "myrai_support" ? "providers" : userType == "satff" ? tempTopics[0].main_key : tempTopics[0].main_key == " myr_support" ? "my_contact" : tempTopics[0].main_key))
          settotalNotificationCount(category.total_notification_count && category.total_notification_count.service_count ?  category.total_notification_count.service_count : 0 )
          setmissedcallCounts(category.total_notification_count && category.total_notification_count.missed_call_count ? category.total_notification_count.missed_call_count : 0)
          handleCreateChatGroup(projectId,mainKey, mainSubKey, filterKey)
        })
      }else if(!["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) && lastChatSession.length > 0 && defaultTopics && lastChatSession && lastChatSession[0].filter_key && (lastChatSession[0].filter_key == undefined || lastChatSession[0].filter_key == '')){
        dispatch(leftPanelFilters("","","applyCleaningFilter"))
        setTimeout(() => {
          dispatch(unreadTaskComment("my_task"))
        }, 800);
      }
    }
  }
  const makeCall = (receiverId = "" , full_name = "", groupId = "") => {
    dispatch(setCallDisconnect(false));
    dispatch(setCallRecording(false));
    dispatch(initCall({
      userId: localStorage.getItem("orgChatUserId"),
      receiverId: receiverId,
      extraData: {
        full_name: full_name,
        groupId: groupId
      }
    }));
  };
  const getMentionUsersForFilter = async() => {
    if(userType === "staff" && !mentionStaffArray.length)
    {
      try {
        const virtualResponse = await adminServices.getVirtualSpaceList();
        const response = await adminServices.getAllProjectMembers();
        let members = [];
        if(response.status && response.data)
        {
          response.data.forEach((project) => {
            project.members.forEach((member) => {
              let memberIndex = members.findIndex((w) => w.label && member.full_name && member.full_name.trim() == w.label.trim() && member.staffid == w.value);
              if(memberIndex === -1)
              {
                let showCrown = false;
                if(member.userType === "operator" && member.role == 2 && member.contact_role == 1)
                {
                  showCrown = true;
                }
                else if(member.userType === "contact" && member.contact_role == 1)
                {
                  showCrown = true;
                }
                members.push({label: member.full_name, value: member.staffid, profile_image_url: member.profile_image_url, pillContent: `#${member.userType}`, projects: [{project_id : project.project_id.toString(),title:project.office_title,space_name:project.postal_address}], showCrown,
                userType:member.userType,
                staffid:member.staffid,
                contactid:member.contactid,
                email:member.email,
                full_name: member.full_name
              });
              }
              else {
                members[memberIndex] = {...members[memberIndex], projects: members[memberIndex].projects.concat([{project_id : project.project_id.toString(),title:project.office_title,space_name:project.postal_address}])};
              }
            });
          });
        }
        let virtualMembers= [];
        if (virtualResponse.status && virtualResponse.data) {
          virtualResponse.data.forEach((projectV) => {
            projectV.members.forEach((member) => {
              let memberIndexV = virtualMembers.findIndex((w) => w.full_name && member.full_name && member.full_name.trim() == w.full_name.trim() && member.staffid == w.staffid);
              if (memberIndexV === -1) {
                let showCrown = false;
                virtualMembers.push({
                  label: member.full_name, 
                  value: member.staffid, 
                  profile_image_url: member.profile_image_url, 
                  pillContent: `#staff`, 
                  projects: [{ project_id: projectV.id.toString(), title: projectV.name, space_name: projectV.name }], 
                  showCrown,
                  userType: "staff",
                  staffid: member.staffid,
                  contactid: member.contactid || "",
                  email: member.email || "",
                  full_name: member.full_name
                });
              } else {
                virtualMembers[memberIndexV] = { ...virtualMembers[memberIndexV], projects: virtualMembers[memberIndexV].projects.concat([{ project_id: projectV.id.toString(), title: projectV.name, space_name: projectV.name }]) };
              }
            });
          });
        }
        let tempMember = members.concat(virtualMembers)
        tempMember.forEach((member) => {member.label = `@${member.label}`});
        tempMember.sort((a, b) => a.label.localeCompare(b.label));
        setMentionStaffArray(tempMember);
      }
      catch(e)
      {
        console.error(e);
        showError("l_something_went_wrong");
      }
    }
  }
  const applyStaffUserFilter = (member, isRemove) => {
    let projectIdArray = [];
    if(isRemove)
    {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.filter((w) => w.value != member.value);
      if(searchMentionUsers.length)
      {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      let updatedMemberList = mentionStaffArray.concat(member);
      updatedMemberList.sort((a, b) => a.label.localeCompare(b.label));
      setMentionStaffArray(updatedMemberList);
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
    }
    else {
      searchMentionUsers = selectedStaffMembersForSpaceFilter.concat(member);
      if(searchMentionUsers.length == 1)
      {
        projectIdArray = searchMentionUsers.map((w) => w.projects);
        projectIdArray = projectIdArray[0];
      }
      else {
        projectIdArray = searchMentionUsers.reduce((commonProjects, obj) => 
        {
          const projects = obj.projects;
          return commonProjects.filter(project => projects.includes(project));
        }, searchMentionUsers[0].projects);
      }
      setSearchString("");
      setSelectedStaffMembersForSpaceFilter(searchMentionUsers);
      setMentionStaffArray(mentionStaffArray.filter((w) => w.value != member.value));
    }
  }
  const handleTaskBulkAction = (is_delete = 0, status, actionType, files) => {
    if (getCheckedTask && getCheckedTask.length == 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_select_task"))
      );
      return false
    }else if(actionType == "set_assignee" || actionType == "comment") {
      customerServices.taskBulkAction(getCheckedTask, actionType, status, files, leftPanelTab == "my-request" ? 1 : 0).then((res) => {
        if (res.status) {
          dispatch(setAllCheckedTask([]));
          dispatch(
            projectTaskCounts(
              projectId,
              taskFilters
                ? taskFilters
                : [],
              "",
            )
          );
          dispatch(
            getTaskCount(
              0,
              0,
              localStorage.getItem("selectedOffice"),
              moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
              taskFilters
                ? taskFilters
                : [],
              "",
              activeTaskStatusFilters ? activeTaskStatusFilters : []
            )
          );
        }
        dispatch(showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : ''))
      })
    }else if(actionType == "set_due_date"){
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          _l("l_you_want_to_change_due_date_of_selected_tasks"),
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices.taskBulkAction(getCheckedTask, actionType, status, [], leftPanelTab == "my-request" ? 1 : 0).then((res) => {
              if (res.status) {
                dispatch(
                  projectTaskCounts(
                    projectId,
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                  )
                );
                dispatch(
                  getTaskCount(
                    0,
                    0,
                    localStorage.getItem("selectedOffice"),
                    moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                    activeTaskStatusFilters ? activeTaskStatusFilters : []
                  )
                );
                dispatch(setAllCheckedTask([]));
                dispatch(taskBulkAction(getCheckedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? 'task_convert_to_request' : status))
              }
              dispatch(
                showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : '')
              );
            })
          }
        )
      );
    } else {
      dispatch(
        showConfirmation(
          _l("l_are_you_sure"),
          `${is_delete ? _l("l_you_want_tot_delete_this_task") : _l("l_you_want_to_change_task_status")}`,
          _l("l_yes"),
          _l("l_no"),
          undefined,
          () => {
            customerServices.taskBulkAction(getCheckedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? '' : status, [], leftPanelTab == "my-request" ? 1 : 0).then((res) => {
              if (res.status) {
                dispatch(
                  projectTaskCounts(
                    projectId,
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                  )
                );
                dispatch(
                  getTaskCount(
                    0,
                    0,
                    localStorage.getItem("selectedOffice"),
                    moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD"),
                    taskFilters
                      ? taskFilters
                      : [],
                    "",
                    activeTaskStatusFilters ? activeTaskStatusFilters : []
                  )
                );
                dispatch(setAllCheckedTask([]));
                dispatch(taskBulkAction(getCheckedTask, is_delete ? 'delete_task' : status == 'task_convert_to_request' ? 'task_convert_to_request' : 'status_change', is_delete ? '' : status == 'task_convert_to_request' ? 'task_convert_to_request' : status))
              }
              dispatch(
                showMessage(res.status ? "sucess" : "unsucess", res.status ? _l("l_success") : _l("l_error"), res.message ? res.message : '')
              );
            })
          }
        )
      );
    }
  }
  const backNavigationText = () => {
    if(dashboardBenchmarkId > 0)
    {
      return _l("l_back");
    }
    else if(["ConnectProvider", "SpaceOnBoarding", "SpaceOnBoardingNew"].includes(pageName) || key == "connect-provider")
    {
      if (leftPanelTab == "my-drive") {
        return _l("l_back_to_drive");
      }else {
        return _l("l_back_to_space");
      }
    }
    else if(["hireNewAgent"].includes(pageName)){
      return _l("l_back");
    }
    else {
      return _l("l_back_to_space_settings");
    }
  };
  const backButtonHandler = () => {
    if(dashboardBenchmarkId)
    {
      navigate(`/create-dpgf-benchmark?dashboard-benchmark-id=${dashboardBenchmarkId}`);
    }
    else if(["ConnectProvider"].includes(pageName) || key == "connect-provider")
    {
      navigate(`/explore-features?space=${projectId}`);
    }
    else if(leftPanelTab == "my-drive"){
      navigate("/my-drive");
      dispatch(setMyDriveTopic({
        key: "invoice",
        name: "l_invoices",
        icon: FileTextIcon,
      }))
      showLeftPanelNew();
    }else if(leftPanelTab == "equipments"){
      navigate("/equipment-table");
      showLeftPanelNew();
    }else if(leftPanelTab == "my-providers"){
      navigate("/providerlist");
      showLeftPanelNew();
    }
    else if(localStorage.getItem("is_registered") == 1){
      navigate("/aichat");
      dispatch(setSelectedGloablView("AichatView"));
    }
    else {
      showLeftPanelNew();
      let FavView = localStorage.getItem("fav_dashboard")
      FavView == "AichatView" ?
      dispatch(setSelectedGloablView("AichatView")) :
        navigate("/") 
          FavView == "calendar" ?
            dispatch(setSelectedGloablView("calendarView"))
            : FavView == "my_spaces" ?
              dispatch(setSelectedGloablView("spaceView"))
              : FavView == "kanban_view" ?
                dispatch(setSelectedGloablView("kanbanView"))
                : FavView == "my_tasks" ? dispatch(setSelectedGloablView("listView"))
                  : dispatch(setSelectedGloablView("AichatView"))
    }
  }
  useEffect(() => {
  if (chatUserId && project_id) {
    dispatch(fetchGroups(chatUserId, localStorage.getItem('selectedOffice'),0, 1));
  }
  }, [chatUserId, project_id])
  useEffect(() => {
    dispatch(fetchGroupsExternal(chatUserId, 0 , 1));
}, [])
  useEffect(() => {
    setselectedTopic(selected_topic);
    if(selected_topic && selected_topic.total_notification_count){
      setmissedcallCounts(selected_topic.total_notification_count.missed_call_count ? selected_topic.total_notification_count.missed_call_count : 0)
      settotalNotificationCount(selected_topic.total_notification_count.service_count ? selected_topic.total_notification_count.service_count : 0)
    }
  }, [JSON.stringify(selected_topic)])
  useEffect(() => {
    if (myrStaffList.length) {
      const formattedResponse = myrStaffList.map((staff) => {
        return {
          value: staff.staffid,
          label: staff.fullname,
          role: 1,
          profile_image: staff.profile_image_url,
        };
      });
      setMyrStaff(formattedResponse);
    }
      if (myrLeadSources.length || Object.keys(myrLeadSources).length > 0 ) {
      const formattedResponse =myrLeadSources && myrLeadSources.sources.map((source) => {
        return {
          value: source.id,
          label: source.name,
        };
      });
      const tagesLead =myrLeadSources && myrLeadSources.tags.map((source) => {
        return {
          value: source.id,
          label: source.name,
        };
      });
      setLeadSources(formattedResponse);
      settagesLeads(tagesLead);
    }
  }, [myrLeadSources, myrStaffList])
  const preparePreview = (files) => {
    var chatUserId = localStorage.getItem('chatUserId');
    var chatGroupId = localStorage.getItem('activeGroupId');
    var groupMembers = [];
    groupDetails.members.map((item) => {
      if(item != chatUserId){
        groupMembers.push({ userId: item._id });
      }
    });
    ChatServices.uploadChatMedia(files[0]).then((res) => {
      if (res.status && res.data.filename) {
        ChatServices.editChatGroup(chatUserId, chatGroupId, groupDetails.groupName, groupMembers, res.data.filename ,5).then((res) => {
          if (res.success) {
            dispatch(showMessage("sucess", _l("l_success"),res.message));
          }else{
            dispatch(showMessage("unsucess", _l("l_error"),res.message));
          }
        })
      }
    })
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray = ({ name: img.name, url: preview_url });
    });
    setTopicLogo(previrewArray);
  };
  const scrollElement = (date)=>{
    let id = moment(date).format("YYYY-MM-DD");
    let el = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: "auto", block: "start", inline: "start" })  
      }, 500);
    }
  }
  const handleChangeDate = (date,changeView = true) => {
    dispatch(setDaterangeFilterApplied(false));
    if (formateDate(date) == formateDate(new Date())) {
      dispatch(setDaterangeFilter("today"));
    }else{
      dispatch(setDaterangeFilter("date"));
    }
    dispatch(setShowWeekcalendardays(true));
    if (globalView != "calendarView" && globalView != "spaceView" && changeView) { 
      dispatch(setCalendarView("day_view"));
      localStorage.setItem("tasklistView", "liteVersionView")
      dispatch(setSelectedDateView("dayView"));
    }
    const formattedDate = date.toISOString().split("T")[0];
    localStorage.setItem("selectedDate",date)
    dispatch(dayGridDay(true));
    dispatch(dayGridMonth(false));
    dispatch(dayGridWeek(false));
    localStorage.setItem("selectedDate", date);
    if (pageName == "ChatAi" || globalView == "AichatView") {
      dispatch(setSelectedDateForChatai(formateDate(date)));
      setTimeout(() => {
        scrollElement(formateDate(date));
      }, 1000);
    }
    dispatch(setActiveTaskStatusFilters([], 1));
    if (isLeadPage) {
      dispatch(
        getTaskCount(client_id, contact_id, projectId, formattedDate, taskFilters, '', '', '', '', 1, pageName,  '', '', leadsFilters)
      );
    } 
    if(pageName == "MyPeople") {
        dispatch(getAttendanceCountWeekCalendar(projectId , formattedDate))
    } else if (globalView == "spaceView") {
      dispatch(
        getTaskCount(client_id, contact_id, projectId, formattedDate, taskFilters, '', '', '', '', 0,pageName,globalView,getSelectedFloorId)
      );
    } else {
      if (!isLeadPage) {
        dispatch(getTaskCount(client_id, contact_id, projectId, formateDate(date), taskFilters, '', '', '', '', 0, pageName));  // <<-- this line commented bcz on change date two times API call  -->>
      }
      dispatch(projectTaskCounts(projectId, taskFilters, '', isLeadPage, pageName, '', leadsFilters));
    }
  };
  const filterDatedRange = (dateArr = []) => { 
    if (showSearchbar && !["my-providers", "my-clients", "homepage"].includes(leftPanelTab)) {
      filterDatedRanges(dateArr);
    }else{
      setcalendarFilterDate(dateArr);
    }
  }
  const filterDatedRanges = (dateArr = []) => {
    setDatesArr(dateArr);
    if(taskFilters && taskFilters.length > 0)
      {
        if(status_filter.indexOf(pageName) > -1)
        {
          let vr = taskFilters.length;
          for (let i = 0; i < vr; i++) {
            taskFilters.splice(taskFilters[i], 1);
          } 
        }
      }
    if (dateArr && dateArr.length > 0) {
      let date1 = dateArr[0]
      if (formateDate(dateArr[0]) == formateDate(dateArr[1]) && (dateArr[2] == undefined)) {
        dispatch(dayGridDay(true));
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(false));
        dispatch(setDaterangeFilter("today"));
      }else{
        if(dateArr[2] == "today"){
          addActiveBorderforWeekCalendar();
          localStorage.setItem("selectedDate",new Date())
        }
      }
      setStartDateNew(date1);
      dispatch(setselectedDaterangeFilter(dateArr[2]))
      var m1 = moment(dateArr[0],'YYYY-MM-DD');
      var m2 = moment(dateArr[1],'YYYY-MM-DD');
      let tempdayDifference = m2.diff(m1,'days')
      const startMoment =  moment(dateArr[0]).format('MM');
      const endMoment =  moment(dateArr[1]).format('MM');
      dispatch(setDaterangedayDifference(tempdayDifference));
      if (dateArr[2]) {
        dispatch(setDaterangeFilter(dateArr[2]))
      }
      setdayDifference(tempdayDifference)
      if (m2.diff(m1,'days') == 6) {
        dispatch(setShowWeekcalendardays(false));
        dispatch(setDaterangeFilterApplied(false));
        setIsDateRangeFilterApplied(false);
      } else if(m2.diff(m1,'days') == 0 && daterangeAppliedFilters != "all"){
        dispatch(setShowWeekcalendardays(true));
        dispatch(setDaterangeFilterApplied(false));
        setIsDateRangeFilterApplied(false);
      } else {
        dispatch(setShowWeekcalendardays(false));
        dispatch(setDaterangeFilterApplied(true));
        setIsDateRangeFilterApplied(true);
      }
      if (dateArr[2] == "today" && globalView != "calendarView") {
        dispatch(setCalendarView("day_view"));
        localStorage.setItem("tasklistView", globalView == "listView" ? "liteVersionView" : globalView)
        dispatch(setSelectedDateView("dayView"));
        dispatch(showTasklistInCalendar(false));
      }else if((dateArr[2] == "this_week" || dateArr[2] == "last_7_days") && globalView != "calendarView"){
        localStorage.setItem("tasklistView", "liteVersionView" )
        dispatch(setSelectedDateView("weekView"));  
      }else if (((tempdayDifference < 7 && tempdayDifference > 0) || tempdayDifference >= 7 )  && globalView != "calendarView"){
        localStorage.setItem("tasklistView", "liteVersionView")
      }
      if (dateArr[2] == "today") {
        dispatch(dayGridDay(true));
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(false));
        dispatch(showTasklistInCalendar(false));
        setStartDateNew(new Date())
      } else if(dateArr[2] == "this_week" || dateArr[2] == "last_7_days"){
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(true));
        dispatch(dayGridDay(false));
        dispatch(showTasklistInCalendar(false));
      }else if (dateArr[2] == "last_30_days") {
        dispatch(dayGridMonth(true));
        dispatch(dayGridWeek(false));
        dispatch(dayGridDay(false));
        dispatch(showTasklistInCalendar(false));
      } else if (dateArr[2] == "all") {
        dispatch(dayGridMonth(true));
        dispatch(dayGridWeek(false));
        dispatch(dayGridDay(false));
        dispatch(showTasklistInCalendar(true));
      }else if(tempdayDifference >= 7){
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(true));
        dispatch(dayGridDay(false));
      } else if (tempdayDifference < 3 && tempdayDifference > 0) {
        dispatch(dayGridWeek(false));
        dispatch(dayGridMonth(false));
        dispatch(dayGridDay(true));
        dispatch(showTasklistInCalendar(false));
        setappliedFilters("3_days");
      } else if (tempdayDifference >= 3 && tempdayDifference < 7) {
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(true));
        dispatch(dayGridDay(false));   
      } else if (tempdayDifference == 0) {
        dispatch(dayGridDay(true));
        dispatch(dayGridMonth(false));
        dispatch(dayGridWeek(false));
      }
      var fromDate = formateDate(dateArr[0]);
      var toDate = formateDate(dateArr[1]);
      if (pageName == 'MyEmail') {
        dispatch(fetchMails(fromDate, toDate, dateArr[2] == "all" ? 1 : 0)).then((res) => {
        });
      }
      if (fromDate == toDate && dateArr[2] != "all" && (appliedFilters != "date" && appliedFilters != "all")) {
        handleChangeDate(dateArr[0]);
      } else {
          if(pageName=='dashboard'){
            navigate("/dashboardfordaterange");
          }
          if(window.location.pathname != '/dashboardfordaterange')
        dispatch(setActiveTaskStatusFilters([], 1));
        localStorage.setItem("fromDate", fromDate);
        localStorage.setItem("toDate", toDate);
        jquery(`#kpi_task_status_1`).removeClass("active");
        jquery(`#kpi_task_status_4`).removeClass("active");
        jquery(`#kpi_task_status_5`).removeClass("active");
        var filter_key = "date_range_filter";
        var title =
          moment(dateArr[0]).format("DD/MM/YYYY") +
          " - " +
          moment(dateArr[1]).format("DD/MM/YYYY");
        if (dateArr[2] == "all") {
          filter_key = "all";
          title = "All";
        }
        localStorage.setItem('taskFilters', JSON.stringify({
          main_key: "date_range_filter",
          filter_key: filter_key,
          title: title,
        }));
        dispatch(
          leftPanelFilters(
            {
              main_key: "date_range_filter",
              filter_key: filter_key,
              title: title,
            },
            "add"
          )
        );
        if (pageName == 'MyPeople') {
          dispatch(getAttendanceList(fromDate,toDate));
        }
        if (pageName != 'operatorListReporting' && window.location.pathname != '/dashboardfordaterange') {
          if (isLeadPage) {
            dispatch(
              projectTaskCounts(projectId, taskFilters, {
                main_key: "date_range_filter",
                filter_key: filter_key,
                title: title,
              }, 1)
            );
          }else {
            dispatch(
              projectTaskCounts(projectId, taskFilters, {
                main_key: "date_range_filter",
                filter_key: filter_key,
                title: title,
              }, 0, pageName)
            );
          }
        }
      }
    }
  };
  const handlespaceFilter = (space) =>{
    if (leftPanelTab != "equipments") {
      setProjectId(filterProjectID == space.project_id ? 0 : space.project_id);
    }
    setSelectedSpace(filterProjectID == space.project_id ? 0 : space.project_id);
    dispatch(setFilterSpaceProjectid(filterProjectID == space.project_id ? 0 : space.project_id));
  }
  useEffect(() => {
    jquery(document).mouseup(function (e) {
        var container = jquery('#dateRangePickerDropdown');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          setShowDateRangeDropDown(false)
        }
    });
})
  useEffect(() => {
    if (showSearchbar && !["my-providers", "my-clients", "homepage"].includes(leftPanelTab)) {
        let weekDaysArray = [];
        let today = ""
        if(startDateNew != "" && startDateNew != undefined && startDateNew != null){
        today = new Date(startDateNew);
          for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            weekDaysArray.push(date);}        
      }
          localStorage.setItem("initialStartDate", weekDaysArray.slice(0, 1))
          localStorage.setItem("initialEndDate", weekDaysArray.slice(-1))
    }
  }, [isDateRangeFilterApplied,dayDifference,startDateNew])
  useEffect(() => {
    if (showSearchbar && !["my-providers", "my-clients", "homepage"].includes(leftPanelTab)) {
      let weekDaysArray = [];
      let today = ""
      if(startDateNew != "" && startDateNew != undefined && startDateNew != null){
      today = new Date(startDateNew);
        for (let i = 0; i < 7; i++) {
          const date = new Date(today);
          date.setDate(today.getDate() + i);
          weekDaysArray.push(date);}        
      }
      if (window.location.pathname == "/leads") {
        localStorage.setItem('selectedDate',dayDifference > 2 ? formateDate(weekDaysArray[3]) : formateDate(weekDaysArray[1]) );
        dispatch(getTaskCount(client_id, contact_id, projectId, (["ChatDate","today","date"].includes(appliedFilters) || dayDifference == 0) ? formateDate(localStorage.getItem('selectedDate')) :  dayDifference > 2 ? formateDate(weekDaysArray[3]) : formateDate(weekDaysArray[1])  , taskFilters, '', '', '', '', true, pageName,  '', '', leadsFilters));
      }
    }
  }, [pageName])

  useEffect(() => {
    if (taskFilters && taskFilters.length == 0) {
      setDatesArr([]);
      setcalendarFilterDate([]);
    }
  }, [taskFilters])
  
  useEffect(() => {
    if (showSearchbar && !["my-providers", "my-clients", "homepage"].includes(leftPanelTab)) {
      let weekDaysArray = [];
      let today = ""
      if(startDateNew != "" && startDateNew != undefined && startDateNew != null){
      today = new Date(startDateNew);
        for (let i = 0; i < 7; i++) {
          const date = new Date(today);
          date.setDate(today.getDate() + i);
          weekDaysArray.push(date);}        
    }
    if(globalView == "calendarView"){
      if ((differenceInDays(datesArr[1], datesArr[0]) > 31) ||
          (getMonth(datesArr[1]) != getMonth(datesArr[0]) && differenceInDays(datesArr[1], datesArr[0]) > 6) || 
          (appliedFilters == "all")) {
        filterDatedRange([new Date(),new Date()])
        if (appliedFilters != "all") {
          dispatch(
            showConfirmation(
              _l("l_are_you_sure"),
              differenceInDays(datesArr[1], datesArr[0]) > 31 ? 
              _l("l_sorry_calendar_view_cant_be_shown_for_large_date_range_selections_do_you want_to_switch_to_list_view")
              : _l("l_sorry_calendar_view_cant_be_shown_for_two_diffrent_months_do_you_want_to_switch_to_list_view"),
              _l("l_yes"),
              _l("l_no"),
              undefined,
              (e) => {
                dispatch(setSelectedGloablView("listView"));
                navigate("/")
                filterDatedRange([datesArr[0],datesArr[1]])
                dispatch(toastCloseAction());
              }
            )
          );
        }
      }
    }
    }
  }, [dayDifference,appliedFilters, JSON.stringify(taskFilters),globalView])
  useEffect(() => {
    if ((projectId > 0 && ["my-space"].includes(leftPanelTab)) || "reporting-page" == leftPanelTab) {
      setShowSearchbar(false);
    } else  if (["my-providers", "my-clients","equipments", "my-tenants", "my-request"].includes(leftPanelTab) || pageName == "SpaceOnBoardingNew" || (["my-space", "chat-agents"].includes(leftPanelTab) && contact_role == 3) || projectId == 0) {
      setShowSearchbar(true);
    }else{
      setShowSearchbar(false);
    }
  }, [leftPanelTab, pageName , projectId])

  useEffect(() => {
    if ("my-request" == leftPanelTab) {
      if (taskFilters && taskFilters.find(fil => fil.main_key == "date_range_filter") && taskFilters.find(fil => fil.main_key == "date_range_filter").title == "all") {
        setShowSearchbar(true);
      }else{
        setShowSearchbar(false);
      }
    }
  }, [taskFilters])
  
  useEffect(() => {
    if (leftPanelTab != "equipments") {
      setProjectId(filterProjectID);
    }
    setSelectedSpace(filterProjectID);
  }, [filterProjectID])
  return (
    <>
      {[
        "SpaceOnBoarding",
        "SpaceOnBoardingEquipments",
        "ConnectProvider",
        "SpaceOnBoardingNew",
        "Providers",
        "hireNewAgent"
      ].includes(pageName) && !BACK_BUTTON_RESTRICTED.find((w) => window.location.href.includes(w))? (
        <div className="after-login-header onboarding-header d-flex flex-column">
          <div className="row my-auto">
            <div className="col-lg-4">
              <div className="w-100 pe-3 py-2 text-truncate header-back-btn">
                <a href="#/" className="d-flex align-items-center"  onClick={backButtonHandler}>
                  {["ConnectProvider", "Providers","SpaceOnBoarding", "SpaceOnBoardingNew","hireNewAgent"].includes(pageName) ? (
                    <div
                      className="h40w40 d-flex"
                    >
                      <CaretLeftIcon className="HW16" />
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  <div className="w100minus40">
                  <div className="title-fonts text-truncate">{backNavigationText()}</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex h-100 justify-content-center text-truncate align-items-center">
                <div className="title-fonts text-center text-truncate">
                  <div className="fw-semibold title-fonts text-truncate">
                      {["Providers"].includes(pageName) ? (
                        <span id="header-page-title"></span>
                      ) : (
                        <span>
                          {selectedOffice.office_title
                            ? selectedOffice.office_title
                            : selectedOffice.postal_address}
                        </span>
                      )}
                    </div>
                    <div className="c-font f-12 opacity-50 text-truncate">
                      {["Providers"].includes(pageName) ? (
                        <span id="header-page-address"></span>
                      ) : (
                        <span>{selectedOffice.postal_address}</span>
                      )}
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex h-100 justify-content-center">
                <div className="d-flex align-items-center ms-auto">
                  {stepData.step == "space-onboarding" ? (
                    <React.Fragment>
                      {stepData.showChat ? (
                        <a href="#/"
                         className={`h-right-part-link on-hover-active-toggle-img res header-support d-none ${
                            ChatPageActiveClass ? "active" : ""
                          } ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          } ${showSupportNavigator ? "" : "d-none"} ${
                            [
                              "dashboard",
                              "PremiumSubscription"
                            ].includes(pageName)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(selectedChatUser(undefined));
                            if (globalView == "liteVersionView" ) {
                              dispatch(setAllChatUserList(false)); 
                              localStorage.setItem("activeGroupId","");
                              localStorage.setItem("generalChatForLiteVersion",true);
                              localStorage.removeItem("selectedOffice")
                            }
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            if (localStorage.getItem("selectedOffice") > 0) {
                              localStorage.setItem("isLiteVersion", false)
                            }else{
                              localStorage.setItem("isLiteVersion", true)
                            }
                            navigate("/chat");
                            dispatch(setOpenGeneralChatGroup(false));
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative">
                            <div
                              className={`rounded-pill badge-custom ${displayUnreadMsgCount}`}
                            >
                              {globalView == "liteVersionView" && localStorage.getItem("isLiteVersion") == "true" ? generalUnreadCount : unreadMsgCount}
                            </div>
                            <ChatIcon
                              className="HW24"
                            />
                            <span className="d-block c-font f-10 hover-span color-white-60 line-height-1 pt-1 res-d-none-imp">
                              {_l("l_support")}
                            </span>
                          </div>
                        </a>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <Button
                      variant="secondary"
                      className="ms-auto title-fonts d-flex align-items-center"
                      onClick={logout}
                    >
                      <SignOutIcon className="HW16" />
                      <div className="ms-2">{_l("l_logout")}</div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            showGlobalSerachModal ? "z-index-15 position-relative" : ""
          }`}
        >
          <a href="#/" className="left-panel-close" id="left-panel-close">
            <XIcon className="HW18" />
          </a>
          <div
            className={`${setupAccTab ? "ps-20pximp" : ""} after-login-header ${globalView == "AichatView" ? "py-0" : ""} ${isBackdrop ? "comman-backdrop-search" : ""} ${
              globalView == "liteVersionView" ? "" : ""
            }`}
          >
            <div className="modal-overlay2 "></div>
            <div className={`header-wrapper position-relative h-100 header-back-btn ${["MyProfile", "PremiumSubscription","Proposals","dashboardnew","ConnectProvider" ,"staff", "SpaceOnBoardingNew" ].includes(pageName) || jquery
            ('body').hasClass("with-right-penel") || contact_role == 3 || localStorage.getItem("space_setup_pending") == 1 || localStorage.getItem("is_registered") == 1 || (spaceState && spaceState.length == 0) && (virtualSpaceList && virtualSpaceList.length == 0) || setupAccTab ? "" : "pl70px"}`}> 
              <div className="d-flex align-items-center justify-content-between h-100">
                <div
                  className={`left-part d-flex align-items-center  ${
                    globalView == "liteVersionView" ? "left-part" : "left-part"
                  }`}
                >
                  {setupAccTab ? <>
                  <div className="d-flex align-items-center gap10">
                    <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-03 position-relative comman-round-box-border-color  comman-image-box" >
                    <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                  backgroundImage: `url('${localStorage.getItem("contact_image")}')`,
                                }}></div>
                    </div>
                    <div class="fw-semibold title-fonts">Myr.ai</div>
                    </div>
                  </> : <></>}
                  {showMailModal ? (
                    <MailSetting
                      show={showMailModal}
                      handleClose={mailModalHandleClose}
                      handleShow={mailModalHandleShow}
                      setSynchronizedMail={setSynchronizedMail}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="hamburger-menu-link" id="hamburger-menu-link">
                    <ListIcon className="HW18" />
                  </div>
                  <div
                    className={`d-flex flex-column flex-grow-1 w-100  ${contact_role == 3 && projectId <= 0 && taskFilters.length == 0 ?  "" : "d-none"}`}
                    >
                    {  globalView == "liteVersionView" && pageName == "Dashboard"  ? (
                      <div className="title-fonts fw-semibold text-truncate f-12 c-font">
                        { (projectId == null || projectId == 0 || projectId == undefined || projectId == "null" || contact_role == 3)
                          ? _l("l_dashboard")
                          : selectedTeam
                          ? selectedTeam.name
                          : selectedOffice.office_title}
                      </div>
                    ) : (
                     (pageName == "Dashboard" || pageName == "dashboardnew") && !["my-calendar"].includes(leftPanelTab) ? <div className="title-fonts fw-semibold text-truncate f-12 c-font ">
                        {_l("l_dashboard")}
                      </div> :<></>
                    )}
                    {  globalView == "liteVersionView"  ? (
                      <div className="text-truncate f-12 c-font color-white-60 max-w-lg">
                        {(projectId == null || projectId == 0 || projectId == undefined || projectId == "null")
                          ? ""
                          : selectedTeam
                          ? ""
                          : selectedOffice.postal_address}
                      </div>
                    ) : (
                      <div className="text-truncate f-12 c-font d-flex align-items-center d-none">
                        <a href="#/"
                          className="pe-1"
                          type="button"
                          id="StatusScheduleDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <DeskIcon
                            className="HW18"
                          />
                          <span className="ps-2">{_l("l_on_desk")}</span>
                        </a>
                        <div className="text-truncate f-12 c-font"></div>
                      </div>
                    )}
                  </div>
                  { isContactDir
                    ? <div className={`d-flex flex-column flex-grow-1 w-100 `}>
                           <div className="title-fonts fw-semibold text-truncate f-12 c-font">{groupDetails.groupName}</div>
                      </div>
                    : <></>
                  }
                  {!["MyProfile", "PremiumSubscription","Proposals","dashboardnew","invoice","ConnectProvider","SpaceOnBoardingNew","SpaceOnBoarding"].includes(pageName) && (taskFilters && taskFilters.filter((key)=> key.main_key !="date_range_filter").length == 1) ? (  
                    <>
                      {taskFilters && taskFilters.length > 0 && !isContactDir  ?
                      <div className={`d-flex align-items-center w-100 ${["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) ? 'd-none' : ''}`}>
                        <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-03 position-relative comman-round-box-border-color  comman-image-box" onClick={()=>{
                          if(groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey){
                            var mainKey = groupDetails.extraData.mainKey;
                            var filterKey = groupDetails.extraData.filterKey;
                            var mainSubKey = groupDetails.extraData.mainSubKey;
                            if((["my_contact","my_operator","my_staff"].includes(mainKey) && (mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(mainKey)){
                              setShow(true);
                              if(userType == "staff" || contact_role == 1 || contact_role == 2){
                                setViewGroupDetails(true);
                              }else{
                                setViewGroupDetails(false); 
                              }
                            }else{
                              setShow(false);
                              setViewGroupDetails(false); 
                            }
                          }
                        }}
                          >
                          {taskFilters.some((taskFilter)=> taskFilter.filter_key == "myrai_support") || (userType != "staff" && contact_role == 3) ? 
                          <UsersThreeIcon className={`HW16`}/>
                          :topicLogo.url && topicLogo.url != "" && topicLogo.url != undefined ?
                           <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                            backgroundImage: `url('${topicLogo.url && topicLogo.url != "" && topicLogo.url != undefined ? topicLogo.url : ""}')`,
                          }}></div> 
                              : taskFilters && taskFilters.some((key) => key.main_key == "my_task")  ? 
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                  backgroundImage: `url('${localStorage.getItem("contact_image")}')`,
                                }}>
                          </div>
                           :
                           <>
                          {selected_topic && selected_topic.image ? <>
                            <span class="d-flex  light-pill mt-1 position-absolute rounded-pill headmemberstatus right2px" >
                              <span className={`c-dots c-7 ${isOnline == 1 ? "done" : "bg-warning"} rounded-circle`}></span>
                            </span>
                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{
                                  backgroundImage: `url('${selected_topic.image}')`,
                                }}></div>
                          </> :taskFilters && taskFilters.some((key) => key.main_sub_key == "people") ?
                          <UserIcon 
                            className={`HW16`} /> 
                            : 
                            <UsersThreeIcon
                            className={`HW16`} /> 
                            }</>}
                        </div> 
                        <div className="c-font f-12 fw-semibold p-10 py-0 title-fonts w100minus25 text-truncate w100minus100per">
                          <div className="mb-1 text-truncate c-font f-14" onClick={() => {
                            if(groupDetails && groupDetails.extraData && groupDetails.extraData.mainKey){
                              var mainKey = groupDetails.extraData.mainKey;
                              var filterKey = groupDetails.extraData.filterKey;
                              var mainSubKey = groupDetails.extraData.mainSubKey;
                              if((["my_contact","my_operator","my_staff"].includes(mainKey) && (mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(mainKey)){
                                setShow(true);
                                if(userType == "staff" || contact_role == 1 || contact_role == 2){
                                  setViewGroupDetails(true);
                                }else{
                                  setViewGroupDetails(false); 
                                }
                              }else{
                                setShow(false);
                                setViewGroupDetails(false); 
                              }
                            }
                          }}>
                            {
                            userType != "staff" && contact_role == 3 && !is_agent
                              ? localStorage.getItem("company_name") ? localStorage.getItem("company_name") : "Myr.ai Support" 
                              :  _l(taskFilters.filter((filter) => filter.main_key == "my_task")[0] 
                                  ? localStorage.getItem('full_name') 
                                  : taskFilters.filter((filter) => filter.filter_key != "date_range_filter" && filter.filter_key != "unread_comments")[0] 
                                    ? taskFilters.filter((filter) => filter.filter_key != "date_range_filter" && filter.filter_key != "unread_comments")[0].title
                                    : groupDetails && groupDetails.groupName 
                                      ? groupDetails.extraData && groupDetails.extraData.mainKey == "myr_support" ? "Myr.ai Support" 
                                      : groupDetails.groupName :'')
                          }</div>
                          <div className="multi_image_wrapper dropdown dropdown-center me-auto">
                            <div className=" d-flex position-relative">
                          { groupDetails && groupDetails.members && groupDetails.extraData
                            && ((["my_contact","my_operator","my_staff"].includes(groupDetails.extraData.mainKey) && (groupDetails.extraData.mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(groupDetails.extraData.mainKey))
                          && groupDetails.members.filter((m , index) => contact_role ==  3 ? index > -1  && index < 3 : index < 3).map((member) =>{
                            return(<>                            
                              <a href="#/" className={`h20w20 comman-image-box with-bg d-flex align-items-center rounded-circle bg-white-03 with-close me-2 ${member.myrUserType == "client" ? "contact_user" : member.myrUserType == "staff" && member.myrRole == 2 ? "operator_user" : "staff_user" }`}
                              >
                                <span class="d-flex light-pill headmemberstatus position-absolute rounded-pill " >
                                  <span className={`c-dots c-7 ${member.isOnline == 1 ? "done" : "bg-warning"} rounded-circle`}></span>
                                </span>
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url('${member.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + member.displayPicture}')`,
                                  }}>
                                  </div>
                              </a>
                            </>)
                          })}
                         {groupDetails && groupDetails.extraData && ((["my_contact","my_operator","my_staff"].includes(groupDetails.extraData.mainKey) && (groupDetails.extraData.mainSubKey == "team" || groupDetails.members.length > 2)) || ["providers","myr_support","equipments"].includes(groupDetails.extraData.mainKey)) ? 
                              <a href="#/" className="d-flex align-items-center rounded-circle with-close me-1">
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex"
                                  >   
                                  </div>
                                  <UncontrolledDropdown className="d-flex rounded-circle dropdown dropdown-center team-list-dropdown ">
                                    <DropdownToggle tag="div" className="" >
                                      <div
                                        className="comman-image-box h20w20 with_overlay d-flex rounded-circle comman-round-box-border-color border-width3"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                        aria-expanded="false"
                                      >
                                        <DotsThreeIcon className="HW16" />
                                      </div>
                                      <UncontrolledGeneralListing
                                        list={groupDetails && groupDetails.members && groupDetails.members.length > 0 && groupDetails.members.sort((a,b)=>{
                                          {
                                            if (a.myrUserType2 == "Myr Support") {
                                              a = -1; b = 1;
                                            } else {
                                              a = 1; b = -1;
                                            }
                                            return a - b
                                          }
                                        }).map((item, index) => {
                                          let filter_key = ""
                                          let main_key = ""
                                          let main_sub_key = ""
                                          if (defaultTopics && defaultTopics.length > 0) {
                                            defaultTopics.forEach((topic) => {
                                              if (topic.categories) {
                                                topic.categories.forEach((category) => {
                                                  if (topic.main_key !== "myr_support" && category.contact_id && category.contact_id == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  } else if (topic.main_key !== "myr_support" && category.filter_key && category.filter_key == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  }
                                                })
                                              }
                                            })
                                          }
                                          return {
                                            name: item.fname + " " + item.lname,
                                            image: item.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + item.displayPicture,
                                            id: index,
                                            isOnline: item.isOnline,
                                            userId: item._id,
                                            userType : item.myrUserType,
                                            myrRole: item.myrRole,
                                            filter_key : filter_key,
                                            main_key : main_key,
                                            main_sub_key :main_sub_key
                                          };
                                        })}
                                        fromChat={false}
                                        forCall={false}
                                        makeCall={makeCall}
                                        fromAichat={true}
                                        sortList={false}
                                        callFromGroup={groupDetails && groupDetails._id ? groupDetails._id : ""}
                                      />
                                    </DropdownToggle>
                                  </UncontrolledDropdown>
                              </a> 
                              :<></>}
                           </div>
                          </div>
                        </div>
                      </div> 
                      : <></>}  
                    </>
                  ): ["my-providers", "my-clients", "equipments"].includes(leftPanelTab) ?
                    <>
                    <div class="c-font f-12 fw-semibold p-10 py-0 title-fonts w100minus25 text-truncate w100minus100per">
                      <div className="mb-1 text-truncate c-font f-14">
                        {leftPanelTab == "my-providers"
                            ? _l("l_providers")
                            : leftPanelTab == "equipments"
                              ? _l("l_equipments")
                              : _l("l_my_clients")
                        }
                      </div>
                    </div>
                    </> : <></>}
                  {pageName == "MyPeople" ? (
                    <>
                      <a href="#/"
                        style={{
                          marginRight: "15px",
                        }}
                        onClick={() => {
                          navigate("/");
                          onPagechangeToggle();
                          dispatch(setSelectedGloablView(globalView));
                        }}
                      >
                        <CaretLeftIcon
                          className="HW18"
                        />
                      </a>
                      <div className="d-flex flex-column">
                        <div className="fw-semibold text-truncate">
                          <span>{_l("l_present_reporting")}</span>
                        </div>
                      </div>
                    </>
                  ) : ["Chat"].includes(
                      pageName
                    ) ? (
                    localStorage.getItem("selectedVirtualSpace") &&
                    Object.keys(localStorage.getItem("selectedVirtualSpace"))
                      .length > 0 ? (
                      <>
                        <a href="#/"
                        className="no-space"
                          style={{
                            marginRight: "15px",
                          }}
                          onClick={() => {
                            navigate("/");
                            onPagechangeToggle();
                            dispatch(setOpenGeneralChatGroup(false));
                          dispatch(setSelectedGloablView(globalView));
                          }}
                        >
                          <CaretLeftIcon
                            className="HW18"
                          />
                        </a>
                        <div className="d-flex flex-column w-50">
                          <div className="fw-semibold title-fonts text-truncate">
                            <span>
                              {
                                JSON.parse(
                                  localStorage.getItem("selectedVirtualSpace")
                                ).name
                              }
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <a href="#/"
                         className="with-space"
                          style={{
                            marginRight: "15px",
                          }}
                          onClick={() => {
                            navigate(-1);
                            dispatch(setOpenGeneralChatGroup(false));
                            dispatch(setSelectedGloablView(globalView));
                          }}
                        >
                          <CaretLeftIcon
                            className="HW18"
                          />
                        </a>
                        {projectId > 0 ? 
                        <>
                        <div className="d-flex flex-column w-100 text-truncate">
                          {openGeneralChatGroup || localStorage.getItem("generalChatForLiteVersion") == "true" || selectedUserForChat && Object.keys(selectedUserForChat).length > 0 ? (
                            <div className="fw-semibold text-truncate">
                              <span></span>
                            </div>
                          ) : (
                            <>
                              <div className="w-100 text-truncate">
                                <div className="fw-semibold title-fonts text-truncate">
                                  <span>
                                    {selectedOffice.office_title
                                      ? selectedOffice.office_title
                                      : selectedOffice.postal_address}
                                  </span> 
                                </div>
                                <div className="c-font f-12 opacity-50 text-truncate">
                                  <span>{selectedOffice.postal_address}</span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        </> : <></>}
                      </>
                    )
                  ) : (
                    <></> 
                  )}
                  {[
                    "Wallet",
                    "CompanyProfile",
                    "PremiumSubscription",
                    "MyProfile",
                  ].includes(pageName) ? (
                    <div className="f-14 title-fonts fw-semibold">
                      {pageNames[pageName]}
                    </div>
                  ) : (
                    <></>
                  )}
                  {["operatorListReporting", "operatorListReporting", "equipmentListReporting"].includes(pageName) || (taskFilters && taskFilters.filter((key)=> key.main_key !="date_range_filter").length > 1) ? <div
                    className={`office-detail text-truncate w-100`} 
                  >
                    <div className="d-flex align-items-start">
                      {projectId > 0 || leftPanelTab == "my-request" || (leftPanelTab == "my-space" && projectId == 0)? 
                      <div className="office-detail-name w100minus40 flex-grow-1">
                        {showFilterArea &&
                        (  pathname == "/" ||
                          pathname == "/tasks" ||
                          pathname == "/calendar" ||
                          pathname == "/MyEmail" ||
                          pageName == "operatorListReporting" ||
                          pageName == "equipmentListReporting" ||
                          pageName == "Proposals" ||
                          isLeadPage ||
                          pageName == "documents" ||
                          pageName == "invoice" ||
                          pageName == "credit_notes" ||
                          pageName == "subscription" ||
                          pageName == "client" ||
                          pageName == "estimate" ||
                          pageName == "schedule" ||
                          pageName == "benchmark" ||
                          pageName == "staff" ||
                          pageName == "dashboard" ||
                          pageName == "fullcalendar" ||
                          pageName == "_equipments") ? (
                          <React.Fragment>
                            <div className="fw-semibold c-font f-12 title-fonts text-truncate max-w-lg">
                              {(leftPanelActiveFilters.length == 1 &&
                                (leftPanelActiveFilters[0]["filter_key"] == "all" || leftPanelActiveFilters[0]["filter_key"] == "date_range_filter") ) ? (
                                <>
                                  {window.location.pathname === "/provider-reporting" ?
                                    <span>{ _l("l_services")}</span>
                                    : window.location.pathname === "/employee-reporting" ?
                                      <span>{ _l("l_people")}</span> 
                                      : window.location.pathname == "/equipment-reporting" ?
                                        <span>{ _l("l_equipment")}</span>
                                        : pageName == "dashboard" ?
                                          <span> {_l("l_dashboard")}</span>
                                          : <span>{_l("l_all_section")}</span>
                                  }
                                </>
                              ) : (
                                <></>
                              )}
                              {leftPanelActiveFilters.length !== 0 ? (
                                leftPanelActiveFilters.map((value, index) => {
                                  let displayMainKey = ""
                                  if(value == "overdue" || value == "pinned"){
                                    displayMainKey = _l("l_my_task");
                                  }
                                  if (
                                    value.main_key != "date_range_filter" &&
                                    value.main_key != ""
                                  ) {
                                    iKey++;
                                    if (iKey == 1) {
                                      let displayTitleKey = value.title;
                                      let displayFilterKey = value.filter_key;
                                      let displayMainKey = (value == "overdue" || value == "pinned") ?  _l("l_my_task") : value.main_key;
                                      if (displayMainKey == "my_task") {
                                        displayMainKey = _l("l_my_task");
                                      } else if (displayMainKey == "providers") {
                                        displayMainKey = _l("l_services");
                                      } else if (displayMainKey == "my_contact") {
                                        displayMainKey = _l("l_my_people");
                                      } else if (displayMainKey == "equipments") {
                                        displayMainKey = _l("l_equipments");
                                      } else if (displayMainKey == "requested_task") {
                                        displayMainKey = _l("l_request");
                                        localStorage.setItem(
                                          "value.main_key",
                                          displayMainKey
                                        );
                                      } else if (displayMainKey == "my_emails") {
                                        displayMainKey = _l("l_email");
                                      } else if (displayMainKey == "my_customs") {
                                        displayMainKey = _l("l_customs");
                                      } else if (displayMainKey == "my_customers") {
                                        displayMainKey = _l("l_my_customers");
                                      } else if (displayMainKey == "myr_staff_task") {
                                        displayMainKey = _l("l_myr_staff_task");
                                      } else if (displayMainKey == "user_journey") {
                                        displayMainKey = _l("l_user_journey");
                                      } else if (displayMainKey == "request_people") {
                                        displayMainKey = _l("l_request_people");
                                      }
                                      localStorage.setItem(
                                        "value.filter_key",
                                        displayFilterKey
                                      );
                                      window.dispatchEvent(
                                        new Event("value.filter_key")
                                      );
                                      return <span>{displayMainKey}</span>;
                                    }
                                  }
                                })
                              ) : (
                                <>
                                  {window.location.pathname === "/provider-reporting" ?
                                    <span>{ _l("l_services")}</span>
                                    : window.location.pathname === "/employee-reporting" ?
                                      <span>{ _l("l_people")}</span> 
                                      : window.location.pathname == "/equipment-reporting" ?
                                        <span>{ _l("l_equipment")}</span>
                                        : pageName == "dashboard" ?
                                          <span> {_l("l_dashboard")}</span>
                                          : <></>
                                  }
                                </>
                              )}
                            </div>
                            <div className="pill-wrapper mt-1 max-w-lg">
                              <div className="d-flex align-items-center">
                                {leftPanelActiveFilters.length == 1 &&
                                activeTaskStatusFilters.length == 0 &&
                                (leftPanelActiveFilters[0]["filter_key"] =="all" || leftPanelActiveFilters[0]["filter_key"] == "date_range_filter") ? (
                                  <span className={`badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 ${["operatorListReporting", "equipmentListReporting"].includes(pageName)? 'd-none' : ''}`}>
                                    {_l("l_all_topics")}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {leftPanelActiveFilters.length !== 0 ||
                                activeTaskStatusFilters.length !== 0 ? (
                                  leftPanelActiveFilters.findIndex(
                                    (x) =>
                                      (x.main_key === "my_operator" ||
                                        x.main_key === "my_staff" ||
                                        x.main_key === "my_contact" ||
                                        x.main_key === "myr_staff_task" ||
                                        x.main_key === "request_people" ||
                                        x.main_key === "equipments" ||
                                        (pageName == "operatorListReporting" || pageName == "equipmentListReporting" &&
                                          x.main_key != "date_range_filter")) &&
                                      x.filter_key > 0
                                  ) !== -1 ? (
                                    <div className="multi-images-with-close d-flex">
                                      {commanoffcanvasIsOpen == false &&
                                        leftPanelActiveFilters.map(
                                          (value, index) => {
                                            if (
                                              value.main_key !=
                                                "date_range_filter" &&
                                              value.main_key != ""
                                            ) {
                                              let displayFilterKey =
                                                value.title;
                                              localStorage.setItem(
                                                "value.filter_key",
                                                displayFilterKey
                                              );
                                              return (
                                                <div
                                                  className={` d-flex align-items-center ms-1 ${
                                                    index > 2 ? "d-none" : ""
                                                  }`}
                                                >
                                                  <div
                                                    data-bs-toggle="tooltip"
                                                    className="comman-image-box h20w20 rounded-circle with-close me-2"
                                                    currentitem="false"
                                                    aria-describedby="ta80d5ced-c0a1-48da-8c8a-fea25169cae6"
                                                  >
                                                    <div
                                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                      style={{
                                                        backgroundImage: `url('${value.image}')`,
                                                      }}
                                                    >{value.image == "" ? <UsersThreeIcon class="HW18 active-svg" />:<></>}</div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  ) : (
                                    leftPanelActiveFilters.map(
                                      (value, index) => {
                                        if (
                                          value.main_key !=
                                            "date_range_filter" &&
                                          value.main_key != ""
                                        ) {
                                          let displayFilterKey = value.title ? value.title : value == "pinned" ? "l_pinned" : value == "overdue" ? "l_delay": value;
                                          localStorage.setItem(
                                            "value.filter_key",
                                            displayFilterKey
                                          );
                                          let statusName = "";
                                          if (value == 1) {
                                            statusName = _l("l_todo_kpi");
                                          } else if (value == 4) {
                                            statusName = _l("l_inprogress_kpi");
                                          } else if (value == 5) {
                                            statusName = _l("l_done_kpi");
                                          } else if (value == 0) {
                                            statusName = _l("l_request");
                                          }
                                          return (
                                            <div
                                              className={`badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 max-width75 text-truncate ${
                                                commanoffcanvasIsOpen
                                                  ? index > 0
                                                    ? "d-none"
                                                    : ""
                                                  : index > showPills
                                                  ? "d-none"
                                                  : ""
                                              }`}
                                              key={index}
                                            >
                                              <div className="d-flex align-items-center text-truncate">
                                                <span className="text-truncate">
                                                  {statusName != ""
                                                    ? statusName
                                                    : _l(displayFilterKey)}
                                                </span>
                                                <a href="#/" className="ps-1">
                                                </a>
                                              </div>
                                            </div>
                                          );
                                        } else if ( pageName == "operatorListReporting" &&  value.main_key == "date_range_filter") {
                                          return (
                                            <span
                                              className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 d-none"
                                            >
                                              {pageName ==
                                              "operatorListReporting"
                                                ? _l("l_all_operators")
                                                : _l("l_all_topics")}
                                              {pageName ==
                                              "operatorListReporting" ? (
                                                <a href="#/" 
                                                   className="ms-1"
                                                   onClick={() => {
                                                    if (
                                                      pageName ==
                                                      "operatorListReporting"
                                                    ) {
                                                      navigate("/");
                                                    }
                                                   }}
                                                >
                                                <XIcon
                                                  className="HW10 lt-black"
                                                /> 2
                                                </a>
                                              ) : (
                                                <></>
                                              )}
                                            </span>
                                          );
                                        }
                                      }
                                    )
                                  )
                                ) : (
                                  <>
                                    <span
                                      className="badge badge-white rounded-pill fw-light me-1 pill-task-active p-1 d-none"
                                    >
                                      {pageName == "operatorListReporting"
                                        ? _l("l_all_operators")
                                        : pageName == "dashboard"
                                        ? ""
                                        : _l("l_all_topics")}
                                      {pageName == "operatorListReporting" ? (
                                        <a href="#/" 
                                           className="ms-1"
                                           onClick={() => {
                                            if (
                                              pageName == "operatorListReporting"
                                            ) {
                                              navigate("/");
                                            }
                                          }}
                                        >
                                        <XIcon
                                          className="HW10 lt-black"
                                        /> 1
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  </>
                                )}
                                {leftPanelActiveFilters.length < (showPills + 2) &&
                                !commanoffcanvasIsOpen ? (
                                  <></>
                                ) : (
                                  <div
                                    className={`${
                                      leftPanelActiveFilters.length == 0
                                        ? "d-none"
                                        : ""
                                    }`}
                                  >
                                    <a href="#/"
                                      className={`badge badge-white rounded-pill fw-light me-1 pill-task-active p-0 h20w20 d-flex`}
                                      type="button"
                                      id="HeaderFilterDropdown"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="outside"
                                      aria-expanded="false"
                                    >
                                      <DotsThreeIcon
                                        className="HW16"
                                      />
                                    </a>
                                    <HeaderFilterDropdown
                                      list={leftPanelActiveFilters.map(
                                        (data, index) => {
                                          let statusName = "";
                                          if (data == 1) {
                                            statusName = _l("l_todo_kpi");
                                          } else if (data == 4) {
                                            statusName = _l("l_inprogress_kpi");
                                          } else if (data == 5) {
                                            statusName = _l("l_done_kpi");
                                          } else if (data == 0) {
                                            statusName = _l("l_request");
                                          } else if (data == "pinned") {
                                            statusName = _l('l_pinned');
                                          } else if (data == "overdue") {
                                            statusName = _l("l_delay");
                                          }
                                          return {
                                            filter_key: data.filter_key
                                              ? data.filter_key
                                              : "",
                                            main_key: data.main_key
                                              ? data.main_key
                                              : "",
                                            title: data.title
                                              ? _l(`${data.title}`)
                                              : statusName,
                                            image: data.image ? data.image : "",
                                            status: data,
                                          };
                                        }
                                      )}
                                      projectId={projectId}
                                      pageName={pageName}
                                      isLeadPage={isLeadPage}
                                      removeStatusFilter={removeStatusFilter}
                                    />
                                  </div>
                                )}
                                {
                      taskType == 0 || taskType == 1 ?
                        <div className="badge w-auto color-white-80 bg-white-05  radius_3 me-2">
                          <div className="d-flex align-items-center">
                            <span className="color-white">
                              {taskType ? _l('l_request_task') : _l('l_task')}
                            </span>
                            <a href="#/"
                              className="ps-1"
                              onClick={() => {
                                dispatch(setTaskTypeFilter(_l('l_all')))
                              }}
                            >
                              <XIcon className="HW14" />
                            </a>
                          </div>
                        </div>
                        : <></>
                    }
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        <div className="d-none c-font f-10 fw-light color-white-80">
                          <span className="res-d-none-imp">
                            {selectedOffice.postal_address
                              ? selectedOffice.postal_address
                              : ""}
                          </span>
                        </div>
                      </div> : 
                      <>
                      <div className={`office-detail-name w100minus40 flex-grow-1 ${pageName == "Chat" || pageName == "Dashboard" || pageName == "dashboardnew" ? "" : "d-none" }`}>
                        <div className="title-fonts fw-semibold text-truncate f-12 c-font">
                        {curPage == "my-drive" ? myDriveTopic && _l(myDriveTopic.name) : curPage == "my-calendar" ? "Calendar" : ""}
                        </div>
                        </div>
                        </>}
                    </div>
                  </div> : <></>}
                </div>
               {!["homepage"].includes(leftPanelTab) && providerType != "proposal" ? 
               <div className="center-part flex-grow-1 z-index-25">
                  {/* Global Search HTML start */}
                    <div className={`${globalView ==="liteVersionView" ? "daily-schedule-main  m-0 daily-schedule-transform " : "daily-schedule-main m-0 daily-schedule-transform res-width"} ${showSearchbar ? "" : globalView =="calendarView" ? "" : ""}`}>
                      {!showSearchbar && !["my-providers", "my-clients"].includes(leftPanelTab) ?
                       <CalendarHeader
                      projectId={projectId}
                       clearStatusFilter = {()=>{} }
                       setTaskModalClose= {()=>{}}
                        seteMaildate = ''
                         pageName = ''
                         setRefetch={()=>{}}
                          setShowLoader={()=>{}}
                           setSelectStartDate ={() => {}}
                           setSelectEndDate = {()=>{}}
                           calendarFilterDate ={calendarFilterDate}
                       /> : showSearchbar && globalSearchPageArray.indexOf(pageName) > -1 ? (
                        <div
                          className={` commn-search d-flex justify-content-between radius_3 position-relative global-search-main align-items-center ps-10px radius_3 bg-base-header-color ${
                            showGlobalSerachModal ? "opacity-0" : ""
                          } ${isBackdrop ? "comman-search-on-focus" : ""}`}
                        >
                          <div className="m-auto ps-0">
                            <MagnifyingGlassIcon
                              className="HW18"
                            />
                          </div>
                          <div className={`comment-input-box flex-grow-1 ${globalView == "AichatView" ? "" : "before-dnone with_separator_10 "}`}
                          onClick={() => {
                            jquery("body").addClass("searchbackdrop")
                            GlobalModalSearchShow();
                          }}
                          >
                            <Autocomplete
                              mainWidth="w-100"
                              inputClass="form-control border-0 bg-transparent office-list-icon-search-input d-flex align-items-center h-100 w-100 shadow-none px-0"
                              dropdownClass="w-100 max-height-60vh"
                              options={searchString.length && searchString.trim().charAt(0) === "@" ? mentionStaffArray : []}
                              value={searchString || globalSearchString}
                              onChange={(e) => {
                                let search_string = e.target.value;
                                if (search_string.split("").includes("@")) {
                                  setSearchString(search_string);
                                }else{
                                if (search_string == "") {
                                  GlobalModalSearchHide();
                                }
                                GlobalModalSearchShow();
                                dispatch({
                                  type: SET_GLOBAL_SEARCH_STRING,
                                  payload: { search_string },
                                });
                                setSearchString("");
                              }
                              }}
                              onSelect={applyStaffUserFilter}
                              onFocus={() => {
                                getMentionUsersForFilter();
                              }}
                              filterFunctionForOptionString={(str) => str.replace("@", "")}
                              actualWidth={true}
                              isCallChatEnable={true}
                            />
                          </div>
                        <>
                        <div className={`with_separator_10 before-block p-0 ${["my-providers", "my-clients"].includes(leftPanelTab) ? 'd-none' : ''}`}></div>
                        {
                          ["equipments", "my-providers"].includes(leftPanelTab)
                          ? <></>
                          : <a href="#/"
                            className={`comman_action_icon px-1 pe-0 d-flex with_separator_10 before-dnone on-hover-active-toggle-img ${["my-providers", "my-clients"].includes(leftPanelTab) ? 'd-none' : ''}`}
                            onClick={() => {
                              if (["my-space", "chat-agents"].includes(leftPanelTab) && contact_role == 3) {
                              }else{
                                if(globalSearchString == "") {                                 
                                  GlobalModalSearchHide();
                                  setSearchString("");
                                  setShowSearchbar(false);
                                }
                              }
                            }}
                          >
                            <div
                              className="d-flex align-items-center action_icon h32w32 shadow-none light-theme-img m-auto"
                              onClick={() => {
                                let search_string = "";
                                dispatch({
                                  type: SET_GLOBAL_SEARCH_STRING,
                                  payload: { search_string },
                                });
                              }}
                            >
                              <XIcon className="HW18" />
                            </div>
                            </a>
                        }
                        </>
                           {/* new modal for call */}
                           <a href="#/" className={`d-none comman_action_icon  px-0`}>
                            <div className="d-flex align-items-center  action_icon m-auto h32w32 light-theme-img shadow-none"
                              data-bs-toggle="dropdown" 
                              data-bs-auto-close="outside"
                              aria-expanded="false">
                              <GearSixIcon
                                className="HW18"
                              />
                            </div>
                          </a>
                           {/* new modal for call */}
                          {["leadPage"].includes(pageName) && 
                          <UncontrolledDropdown className={`  res px-1 comman_action_icon with_separator_10 `}>
                          <div className="d-flex align-items-center  action_icon m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                              <a href="#/"
                                className={`d-flex on-hover-active-toggle-img h32w32  ${selectedleadfilers.length > 0 ? 'activeicon' : ''}`}
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                                >
                                <FadersHorizontalIcon
                                className="HW18"
                                /> 
                                {selectedleadfilers.length > 0 ?
                                  <span className="badge-custom email-orange d-flex rounded-pill p-0">{selectedleadfilers.length}</span>
                                : <></>}                                  
                              </a>
                            </DropdownToggle>
                            <DropdownItem toggle={false} className="px-0"> <UnControlMainFiltersDropDown 
                               myrLeadSources ={myrLeadSources}
                               setselectedleadfilers = {setselectedleadfilers}
                               selectedleadfilers ={selectedleadfilers}
                              /></DropdownItem>
                          </div>
                        </UncontrolledDropdown>}
                        {["leadPage"].includes(pageName) && <div className="dropdown-center dropdown  d-flex align-items-center card-view-less-padding close-toast">
                         <a href="#/" className="comman_action_icon w-100"
                           onClick={() => {
                               setBulkEmailSendShow(true);
                            }
                          }
                         >
                           <div className="d-flex align-items-center w-100">
                             <div className="action_icon h32w32 on-hover-active-toggle-img light-theme-img">
                               <EnvelopeSimpleIcon className="HW18" />
                             </div>
                           </div>
                         </a>
                       </div>
                        }
                          <a href="#/" className={`comman_action_icon with_separator_10 px-0  d-none ${pageName == "Dashboard" ? globalView == "calendarView" ? "d-none" : "" : "d-none"}`}>
                            <div className="d-flex align-items-center action_icon m-auto h32w32 light-theme-img shadow-none"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false">
                              <FunnelIcon
                                className="HW18"
                              />
                            </div>
                            <ActionDropdown widthClass="w-170"
                              actions={[
                                {
                                  actionName: _l("l_day_view"),
                                  actionIcon: "DayViewIcon",
                                  active:dateView == "dayView",
                                  actionHandler: () => {
                                    dispatch(setSelectedDateView("dayView"));
                                    jquery(".dropdown-menu").removeClass("show");
                                  },
                                },
                                {
                                  actionName: _l("l_week_view"),
                                  actionIcon: "WeekViewIcon",
                                  active:dateView == "weekView",
                                  actionHandler: () => {
                                    dispatch(setSelectedDateView("weekView"));
                                    jquery(".dropdown-menu").removeClass("show");
                                  },
                                },
                                {
                                  actionName: _l("l_month_view"),
                                  actionIcon: "CalendarBlankIcon",
                                  active:dateView == "monthView",
                                  actionHandler: () => {
                                    dispatch(setSelectedDateView("monthView"));
                                    jquery(".dropdown-menu").removeClass("show");
                                  },
                                }
                              ]}
                            />
                          </a>
                        </div>
                      ) : pageName === "Chat" || pageName === "MyProfile" ? (
                        <></>
                      ) : localStorage.getItem("is_registered") == 1 ? (
                        <>
                        <span className="c-font fw-semibold title-fonts py-2 f-20">{chatPlanTittle.package_name ?chatPlanTittle.package_name : packagePlan.package_name }</span>
                        </>
                      ) : (
                        <a href="#/"
                          className={`header-logo position-center-element  ${
                            preEstiPageDisable == true ? "with_overlay" : ""
                          }`}
                          onClick={() => {
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            navigate("/");
                            onPagechangeToggle();
                          }}
                        >
                         {LightTheme ? < LogoGreenLight className="header-logo-img "/> :  <LogoGreenDark  className="header-logo-img"/>}
                        </a>
                      )}
                    </div>
                  {/* Global Search HTML over */}
                </div>
                : <React.Fragment></React.Fragment>}
                <div className="right-part">
                  {!setupAccTab ? 
                  <div className="d-flex align-items-center justify-content-end comman_action_icon">  
                {
                    (pageName == "Dashboard" || globalView == "spaceView") && projectId > 0 && (leftPanelTab == "my-space" && contact_role != 3) ?
                        <>
                          <div className={` action_icon with_bg h38w38 on-hover-active-toggle-img d-flex justify-content-center me-10px`}>
                            <a href="#/"
                              className="d-flex align-items-center action_icon h-100 w-100 shadow-none light-theme-img m-auto "
                              type="button"
                              id="FloorFilterDropdown"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                            >
                              <StepIcon className="HW14 opacity-75 " />
                            </a>
                            <FloorFilterDropdown />
                          </div>
                        </>
                      : <></>
                }
                {/* -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ Bulk Actions Start -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */}
                {["Dashboard", "leadPage"].includes(pageName) && ["my-request"].includes(leftPanelTab) && (contact_role != 3 && leftPanelTab == "my-space") &&
                  <div className={` action_icon with_bg h38w38 on-hover-active-toggle-img d-flex justify-content-center me-10px ${["Dashboard", "leadPage"].includes(pageName) ? userType == 'staff' && pageName != "leadPage" ? 'd-none' : '' : "d-none"}`}>
                          <a
                            className="d-flex align-items-center action_icon h-100 w-100 shadow-none light-theme-img m-auto "
                            type="button"
                            id="FloorFilterDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            onClick={() => {
                              if (pageName == "leadPage") {
                                setShowBulkActionForLeads(true);
                              }
                            }}
                          >
                            <ListBulletsIcon
                              size={18}
                              className="c-icons"
                              weight="light"
                            />
                          </a>
                    { pageName != "leadPage" && 
                      <ActionDropdown widthClass="w-170"
                        actions={[
                          {
                            actionName: _l("l_status"),
                            actionIcon: "TagIcon",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_assign"),
                            actionIcon: "UserGearCircleIcon",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_due_date"),
                            actionIcon: "CalendarBlankIcon",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_comment"),
                            actionIcon: "ChatCircleIcons",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_delete"),
                            actionIcon: "TrashIcon",
                            actionHandler: () => {
                              handleTaskBulkAction(1)
                            },
                          },
                        ]}
                        actionStausChange={handleTaskBulkAction}
                      />
                    }
                  </div>
                }
                {/* -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ Bulk Actions End -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */}
                {/* -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ Map Pin Start -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */}
                {[
                  "my-request",
                  "my-providers",
                  "my-contacts",
                  "my-calendar",
                  "my-clients",
                  "my-agents",
                  "homepage",
                  "equipments"
                ].includes(leftPanelTab) ? 
                   <UncontrolledDropdown className="dropdown">
                    <DropdownToggle
                      tag="a"
                      className="justify-content-center align-items-center me-10px"
                      type="button"
                      id="SapceListMainDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div
                        className={`on-hover-active-toggle-img text-on-hover-active-green action_icon with_bg h38w38 ${
                          selectedSpace > 0 ? "active" : ""
                        }`}
                      >
                        <MapPinIcon className="HW18" />
                      </div>
                    </DropdownToggle>
                    <DropdownItem toggle={false}  className="p-0 no-hover" tag="div">
                      <SpaceListDropdown
                        openSpaceListDropdown={openSpaceListDropdown}
                        SpaceList={spaceState}
                        setOpenSpaceListDropdown={setOpenSpaceListDropdown}
                        handler={handlespaceFilter}
                        selectedSpace={selectedSpace}
                        isFromTaskCard={true}
                        setSelectedSpace={()=>{}}
                        unContolList={true}
                      />
                    </DropdownItem>
                  </UncontrolledDropdown> : <></>}
                {/* -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ Map Pin End -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */}
                {["Dashboard", "leadPage"].includes(pageName) && ["my-space", "my-request"].includes(leftPanelTab) &&  
                  <a href="#/" className={` action_icon with_bg h38w38 on-hover-active-toggle-img d-flex justify-content-center me-10px  ${["Dashboard", "leadPage"].includes(pageName) ?  userType == 'staff' && pageName != "leadPage" ? 'd-none' : '' : "d-none"}`}>
                      <div className="d-flex align-items-center m-auto light-theme-img shadow-none"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        onClick={()=>{
                          if (pageName == "leadPage") {
                            setShowBulkActionForLeads(true);                                  
                          }
                        }}
                        >
                        <ListBulletsIcon 
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </div>
                    {pageName !="leadPage"  && <ActionDropdown widthClass="w-170"
                        actions={[
                          {
                            actionName: _l("l_status"),
                            actionIcon: "TagIcon",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_assign"),
                            actionIcon: "UserGearCircleIcon",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_due_date"),
                            actionIcon: "CalendarBlankIcon",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_comment"),
                            actionIcon: "ChatCircleIcons",
                            actionHandler: () => {
                            },
                          },
                          {
                            actionName: _l("l_delete"),
                            actionIcon: "TrashIcon",
                            actionHandler: () => {
                              handleTaskBulkAction(1)
                            },
                          },
                        ]}
                      actionStausChange = {handleTaskBulkAction}
                      />}
                  </a>
                }
                {/* {---------------------- ai asssistant for tanants filters starts ----------------------------------} */}
                {localStorage.getItem("tenantesRole") == 4 && leftPanelTab == "my-tenants" && ["liteVersionView", "listView"].includes(globalView) ?
                  <a href="#/" className={` action_icon with_bg h38w38 on-hover-active-toggle-img d-flex justify-content-center me-10px d-none`}>
                      <div className="d-flex align-items-center  action_icon m-auto h32w32 light-theme-img shadow-none"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        >
                        <ListBulletsIcon 
                          size={18}
                          className="c-icons"
                          weight="light"
                        />
                      </div>
                    {<ActionDropdown widthClass="w-170"
                        actions={[
                          {
                            actionName: _l("l_call"),
                            actionIcon: "PhoneIcon",
                            actionHandler: () => {
                              dispatch(setTenatsRoleId(1));;
                            },
                          },
                          {
                            actionName: _l("l_email"),
                            actionIcon: "EnvelopeSimpleIcon",
                            actionHandler: () => {
                              dispatch(setTenatsRoleId(2));;
                            },
                          }
                        ]}
                      />}
                  </a>
                  : <React.Fragment></React.Fragment>
                }
                {/* {---------------------- ai asssistant for tanants filters ends ----------------------------------} */}
                {/* -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ Start Dashbord Share Link -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */}
                {leftPanelTab == "my-drive" ?
                  <a href="#/" className={` action_icon with_bg h38w38 d-flex justify-content-center me-10px on-hover-active-toggle-img`}>
                      <div className="d-flex align-items-center m-auto light-theme-img shadow-none "
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        >
                        <ShareNetworkIcon 
                          className="HW18"
                        />
                      </div>
                      <ActionDropdown
                        actions={[
                          {
                            actionName: _l("l_copy_link"),
                            actionHandler: () => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_MYR_WEB_URL}/${"dashboardDocuments"}/${userHashId}`
                              );
                              dispatch(
                                showMessage(
                                  "sucess",
                                  _l("success"),
                                  _l("l_link_copied")
                                )
                              );
                            },
                            actionIcon: "CopyIcon",
                          }
                        ]}
                      />
                  </a>
                  : <React.Fragment></React.Fragment>
                }
                {/* -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ End Dashbord Share Link -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */}
              {["my-space", "chat-agents"].includes(leftPanelTab) && contact_role == 3 ? <></>  : 
              showDateRangeDropDown == false  && !["my-providers", "equipments"].includes(leftPanelTab) && !["SpaceOnBoarding", "SpaceOnBoardingNew"].includes(pageName)  ?
              <a href="#/" className=" action_icon with_bg h38w38 on-hover-active-toggle-img d-flex justify-content-center me-10px" onClick={()=>{setShowDateRangeDropDown(!showDateRangeDropDown)}}>
                <div className={`w-100 active-dropdown align-item-center custom-datepicker d-flex datepicker-hidden-input flex-column on-hover-active-toggle-img position-relative text-center ${(isDateRangeFilterApplied == true || taskFilters.some((filter) => filter.filter_key == "date_range_filter"))  ? "active bgwhite10" : ""} `}>
                  <CalendarWithFilterIcon className="fill-transparent HW18 c-icons rangecalendar " />
                </div>
              </a> 
              :!["my-providers", "equipments"].includes(leftPanelTab) && !["SpaceOnBoarding", "SpaceOnBoardingNew"].includes(pageName) ?
              <Dropdown isOpen={true} className="dropdown dropdown-center action_icon with_bg h38w38 on-hover-active-toggle-img  me-10px">
              <DropdownToggle
                tag={'a'}
                className={`active-dropdown align-item-center custom-datepicker d-flex justify-content-center w-100 datepicker-hidden-input flex-column on-hover-active-toggle-img position-relative text-center ${isDateRangeFilterApplied == true || taskFilters.some((filter) => filter.filter_key == "date_range_filter") ? "active bgwhite10" : ""} `}
                 >
                <DropdownItem toggle={true} className="p-0">
                      <DateRangeDropdown
                        show={showDateRangeDropDown}
                        setShow={setShowDateRangeDropDown}
                        pageName={pageName}
                        setDatesFrom={filterDatedRange}
                        initialdates={datesArr}
                        handleclose={() =>{
                          setShowDateRangeDropDown(false)
                        }}
                        setIsDateRangeFilterApplied={setIsDateRangeFilterApplied}
                        setappliedFilters={setappliedFilters}
                      /> 
                  </DropdownItem>
                <CalendarWithFilterIcon className="fill-transparent HW18 c-icons rangecalendar" />
              </DropdownToggle>
              </Dropdown> :
            <React.Fragment></React.Fragment>}
                  {
                    ["SpaceOnBoarding", "SpaceOnBoardingNew"].includes(pageName)
                      ? <></>
                      : <a className={`action_icon with_bg h38w38 on-hover-active-toggle-img ${showSearchbar ? "active" : ""}`}
                          onClick={()=>{
                            setShowSearchbar(true)
                          }}>
                            <MagnifyingGlassIcon className="HW18"/>
                        </a>
                  }
                    <div
                      className={`link-part ${
                        NoHeaderIconPage.indexOf(pageName) > -1 ? "d-none" : ""
                      }`}
                    >
                      <div className="d-flex align-items-center">
                        <a href="#/"
                          id="HeaderCalendar"
                          className={`h-right-part-link on-hover-active-toggle-img res-d-none-imp header-calendar d-none ${
                            CalendarPageActiveClass ? "active" : ""
                          }  ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          }  ${
                            pageName === "dashboard" ||
                            pageName === "PremiumSubscription" ||
                            pageName == "ConnectProvider" ||
                            globalView == "liteVersionView"
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            navigate("/calendar");
                            onPagechangeToggle();
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <CalendarBlankIcon
                              className="HW24"
                            />
                            <span className="d-block c-font f-10 fw-light hover-span color-white-80 line-height-1 pt-1">
                              {_l("l_calendar")}
                            </span>
                          </div>
                        </a>
                        <a href="#/"
                          id="HeaderDocumentLinkDropdown"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                          ref={ref}
                          className={`h-right-part-link on-hover-active-toggle-img res-d-none-imp active-dropdown border-0 d-none ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          }   ${DocumentPageActiveClass ? "active" : ""} ${
                            showDocumentsNavigator && contact_role != 3
                              ? ""
                              : "d-none"
                          } ${
                            ["dashboard",  "PremiumSubscription", "ConnectProvider", "Providers"].includes(pageName)
                              ? "d-none"
                              : ""
                          } ${globalView == "liteVersionView" ? "d-none" : ""}`}
                          onClick={() => {
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            navigate("/documents");
                            onPagechangeToggle();
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <HardDrivesIcon
                              className="HW24"
                            />
                            <span className="d-block c-font f-10 fw-light hover-span color-white-80 line-height-1 pt-1">
                              {_l("l_drive")}
                            </span>
                          </div>
                        </a>
                        <a href="#/" 
                          className={`h-right-part-link on-hover-active-toggle-img res header-support comman_action_icon ms-10px d-none`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 with_bg">
                            <PhoneCallIcon
                              className="HW18"
                            />
                          </div>
                        </a>
                        <a href="#/"
                          className={`h-right-part-link on-hover-active-toggle-img res header-support comman_action_icon ms-10px d-none`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon h32w32 with_bg">
                            <PhoneCallIcon
                              className="HW18"
                            />
                          </div>
                        </a>
                        <a href="#/"
                          className={`p-10 on-hover-active-toggle-img res pe-0 py-0 d-none ${
                            ChatPageActiveClass ? "active" : ""
                          } ${
                            preEstiPageDisable == true ? "for-disabled" : ""
                          } ${showSupportNavigator ? "" : "d-none"} ${
                            [
                              "dashboard",
                              "PremiumSubscription",
                              "ChatAi"
                            ].includes(pageName)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            dispatch(selectedChatUser(undefined));
                            if (globalView == "liteVersionView" ) { 
                              dispatch(setAllChatUserList(false));
                              localStorage.setItem("activeGroupId","")
                            }
                            if (preEstiPageDisable == true) {
                              return;
                            }
                            if (localStorage.getItem("selectedOffice") > 0) {
                              localStorage.setItem("isLiteVersion", false)
                              localStorage.setItem("generalChatForLiteVersion",false);
                            }else{
                              localStorage.setItem("isLiteVersion", true)
                              localStorage.setItem("generalChatForLiteVersion",true);
                            }
                            navigate("/chat");
                            dispatch(setOpenGeneralChatGroup(false));
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                          <div
                              className={`rounded-pill badge-custom ${displayUnreadMsgCount}`}
                            >
                              {globalView == "liteVersionView" && localStorage.getItem("isLiteVersion") == "true" ? generalUnreadCount : unreadMsgCount}
                            </div>
                            <ChatIcon
                              className="HW18"
                            />
                          </div>
                        </a>
                        <>
                        <>
                        {/* ------------------------------------------- providerManager dropdown end  ----------------------------------------------------------------------------------- */}
                        {/* ------------------------------------------- providerBoss dropdown start  ----------------------------------------------------------------------------------- */}
                        {!ONBOARDING_PAGES.includes(location.pathname) && userType == "operators" ?
                         <UncontrolledDropdown className={`p-10  res pe-0 py-0`}>
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h30w30">
                            <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                              <div
                                className="d-flex on-hover-active-toggle-img"
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false"
                              >
                                <ClipboardIcon className="HW18" />                                    
                              </div>
                              <UncontrolledGeneralListing
                                list={[]}
                                fromChat={false}
                                forCall={false}
                                fromAichat={false}
                                ismatrix={false}
                                providerManager={false}
                                providerBoss={true}
                                row1={"l_satisfaction_level"}
                                row2={"l_average_response_time"}
                                row3={"l_number_of_quote_requests"}
                                row4={"l_task_completion_rate"}
                                row5={null}
                              /> 
                            </DropdownToggle>
                          </div>
                        </UncontrolledDropdown> : <></> }
                        </>
                         {contact_role == 3 && leftPanelTab == "my-space" ? <div className='align-items-center comman_action_icon d-flex gap-2 ms-10px'
                            onClick={async () => {
                              let spaces = JSON.parse(localStorage.getItem("spaces"));
                              let assistantID = spaces.find(item => item.vapi_assistant_id != null)
                              let projectID = assistantID.project_id
                              assistantID = assistantID ? assistantID.vapi_assistant_id : ""
                              const data = await readCollectionFromDB("topics", "project_id");
                              if (data.length) {
                                const currentProjectTopics = data.find((projectTopics) => projectTopics.project_id == projectID);
                                if (currentProjectTopics && currentProjectTopics.topics) {
                                  let Assistant = currentProjectTopics.topics.find((topics) => topics.main_key == "my_contact").categories.filter((category) => category.contact_role == 4)[0]
                                  localStorage.setItem("selectedTopicForCall", JSON.stringify(Assistant))
                                  localStorage.setItem("assistantCallerID", assistantID);
                                  localStorage.setItem('selectedOffice',projectID);
                                  startCall();
                                }
                              }
                            }}>
                            <Button variant="primary" className='w-100 lh-1'
                              >
                              <PhoneCallIcon className="HW18" />
                              <span className="">{_l("l_assistant_call")}</span>
                            </Button> 
                          </div> 
                          :<React.Fragment></React.Fragment>}
                        {/* ------------------------------------------- providerBoss dropdown end  ----------------------------------------------------------------------------------- */}
                              {!["my-providers"].includes(leftPanelTab) && contact_role != 3 && !ONBOARDING_PAGES.includes(location.pathname) && pageName != "dashboardnew" && taskFilters && taskFilters.some((key) => key.main_key != "my_task") && taskFilters.filter((key) => key.main_key != "date_range_filter").length == 1  ? <UncontrolledDropdown
                                className={` on-hover-active-toggle-img res ps-10px `}>
                                  {missedcallCounts > 0 ? <div class="badge-custom bg-danger d-flex  rounded-pill"><span class="color-white ps-0">{missedcallCounts}</span></div> : <></>}
                                <DropdownToggle tag="div" className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg  h38w38">
                                  <div className="d-flex rounded-circle dropdown dropdown-center team-list-dropdown ">
                                    <div
                                      className="d-flex"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="outside"
                                      aria-expanded="false"
                                      onClick={() => {
                                        if(contact_role != 3 && taskFilters.some((key) =>key.main_key == "my_contact" || key.main_sub_key == "contact")){
                                          let tempArr = groupDetails.members
                                          let templist = tempArr.filter((member) => member._id != localStorage.getItem("orgChatUserId"))
                                          if (templist[0].myrRole == 4) {
                                            let spaces = JSON.parse(localStorage.getItem("spaces"));
                                            let index = spaces.findIndex((s)=> s.project_id == localStorage.getItem("selectedOffice"))
                                            localStorage.setItem("assistantCallerID", spaces[index].vapi_assistant_id);
                                            startCall(); 
                                          } else {
                                            makeCall(templist[0]._id, templist[0].fname + " " + templist[0].lname, groupDetails._id)
                                          }
                                        }else if (contact_role != 3 && taskFilters.some((key) => key.main_sub_key == "people" || key.main_key == "my_contact" || key.main_sub_key == "contact") || (userType != "contact" && userType != "operator") && contact_role != 3) {
                                          let tempArr = groupDetails.members
                                          let templist = tempArr.filter((member) => member._id != localStorage.getItem("orgChatUserId"))
                                          if (templist[0].myrRole == 4) {
                                            let spaces = JSON.parse(localStorage.getItem("spaces"));
                                            let index = spaces.findIndex((s)=> s.project_id == localStorage.getItem("selectedOffice"))
                                            localStorage.setItem("assistantCallerID", spaces[index].vapi_assistant_id);
                                            startCall(); 
                                          } else {
                                          makeCall(templist[0]._id, templist[0].fname + " " + templist[0].lname, groupDetails._id)
                                          }
                                        }
                                      }}
                                    >
                                      { 
                                        <>
                                          <PhoneCallIcon
                                            className="HW18 "
                                          />
                                        </>}
                                    </div>
                                    {taskFilters.some((key) => key.main_sub_key != "people" ) || ((userType == "contact" || userType == "operator") && contact_role == 3) ?
                                      <UncontrolledGeneralListing
                                        list={groupDetails && groupDetails.members && groupDetails.members.length > 0 && groupDetails.members.map((item, index) => {
                                          let filter_key = ""
                                          let main_key = ""
                                          let main_sub_key = ""
                                          if (defaultTopics && defaultTopics.length > 0) {
                                            defaultTopics.forEach((topic) => {
                                              if (topic.categories) {
                                                topic.categories.forEach((category) => {
                                                  if (topic.main_key !== "myr_support" && category.contact_id && category.contact_id == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  } else if (topic.main_key !== "myr_support" && category.filter_key && category.filter_key == item.myrUserId) {
                                                    filter_key = category.filter_key;
                                                    main_key = topic.main_key;
                                                    main_sub_key = category.main_sub_key;
                                                  }
                                                })
                                              }
                                            })
                                          }
                                          return {
                                            name: item.fname + " " + item.lname,
                                            image: item.displayPicture == "" ? USR_IMG_PLACEHOLDER : ATTACH_BASE_URL + item.displayPicture,
                                            id: index,
                                            isOnline: item.isOnline,
                                            userId: item._id,
                                            userType : item.myrUserType,
                                            myrRole: item.myrRole,
                                            filter_key : filter_key,
                                            main_key : main_key,
                                            main_sub_key :main_sub_key
                                          };
                                        })}
                                        fromChat={false}
                                        forCall={false}
                                        makeCall={makeCall}
                                        fromAichat={true}
                                        callFromGroup={groupDetails && groupDetails._id ? groupDetails._id : ""}
                                      /> : <></>}
                                  </div>
                                </DropdownToggle>
                                </UncontrolledDropdown> : <></>}
                        {(!ONBOARDING_PAGES.includes(location.pathname) && contact_role != 3  && pageName != "dashboardnew" && (taskFilters && taskFilters.length  > 0 && taskFilters.filter((topic)=> ["created_by_me" , "assigned_to_me" , "pinned",  "overdue" , "myrai_support"].includes(topic.filter_key)).length == 0 ||selectedTopic && selectedTopic.main_key != "my_task" && selectedTopic.id != "myrai_support")) && taskFilters.filter((key) => key.main_key != "date_range_filter").length == 1 ? <a href="#/" 
                          className={`d-none p-10 on-hover-active-toggle-img res pe-0 py-0 position-relative`}
                          onClick={()=>{setshowSettingModal(true);}}
                          >
                            { totalNotificationCount > 0 ? <div class="badge-custom  d-flex rounded-pill z-index-2"><span class="ps-0">{totalNotificationCount}</span></div> : <></>}
                          <div className="d-flex flex-column justify-content-center align-items-center position-relative action_icon with_bg h38w38">
                            <GearSixIcon
                              className="HW18"
                            />
                          </div>
                        </a> : <></>}
                        </> 
                      </div>
                    </div>
                    {ONBOARDING_PAGES.includes(location.pathname) ? <></> :
                    <div className={`h-user-part position-relative dropdown dropdown-center d-flex d-none ${pageName == "MyProfile" ?"no-after":""} ${ globalView == "AichatView" ? "ps-2" : ""}`}>
                      <a href="#/"
                        className={`h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle border-dashed p-5px m-auto ${
                          pageName == "MyProfile" ? "border-width2" : ""
                        } ${
                          userType == "staff"
                            ? "staff_user"
                            : userType == "operator"
                            ? "operator_user"
                            : "contact_user"
                        }`}
                        type="button"
                        id="headerUserDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false" 
                        data-bs-auto-close="outside"
                      >
                        <div
                          id="userHeaderProfileImage"
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${loginUserImage}')`,
                          }}
                        ></div>
                      </a>
                      <HeaderUserDropdown ProductTour pageName={pageName} />
                    </div>}
                  </div> :
                  <>
                  <div className="closeIcon comman_action_icon d-flex justify-content-end">
                    <a className="action_icon h32w32 with_bg" href="#/"
                    onClick={() => {
                      dispatch(setTabForSetupAccountAi())
                    }}
                    >
                      <XIcon className="HW18" />
                    </a>
                  </div>
                  </>}
                </div>
              </div>
            </div>
          </div>
          {showBulkActionForLeads ? 
          <BulkActionsModal
            show={showBulkActionForLeads}
            leads={checkedBulkLeads}
            staffList={myrStaff}
            leadSources={leadSources}
            handleClose={() => {setShowBulkActionForLeads(false)}}
            handleShow={() => {setShowBulkActionForLeads(true)}}
            tagesLeads={tagesLeads}
          />
          :<></>}
          {bulkEmailSendShow ? 
          <AddEmailTemplatesModalNew
             show={bulkEmailSendShow}
             handleClose={modalhandleleadClose}
             handleShow={modalhandleleadShow}
             checkedBulkLeads = {checkedBulkLeads}
             bulkEmail = {true}
          />
          : <></>}
          { !jquery.isEmptyObject(callStatus) 
              ? callStatus.status == "initCall"
                ? <FeedChatCallModal show={true} callStatus={callStatus} handleClose={()=>{}} />
                : <FeedChatAudioCall callStatus={callStatus} />
              : <></>
          }
          {equipementModalHTML ? (
            <EquipmentModalHTML
              show={equipementModalHTML}
              handleClose={() => {
                setEquipementModalHTML(false);
              }}
            />
          ) : (
            <></>
          )}
          {showPasswordSetModal ? (
            <SetUserPassword handleClose={() => {setShowPasswordSetModal(false)}} />
          ) : (
            <></>
          )}
        </div>
      )}
{
        showSettingModal && Math.abs(selectedTopic.id) > 0
        ?
        <TopicSettingModal 
        data={selectedTopic} 
        show={Math.abs(selectedTopic.id) > 0} 
        handleClose={() => {
          setshowSettingModal(false);
        }} 
          title={selectedTopic.main_key_title} 
          titlePrefix={"l_settings"} 
          />
        :
        <React.Fragment></React.Fragment>
      }
      <ChatGroupDetailModal     
        show={show}
        handleClose={()=>{
          setShow(false)
        }}
        handleShow={()=>{
          setShow(true)
        }}
        groupList={groupList}
        groupDetails={groupDetails}
        viewGroupDetails={viewGroupDetails}
        isGroupAdmin={isGroupAdmin}
        groupMemberIds={groupMemberIds}
        setGroupMemberIds={setGroupMemberIds}
        previewImage={previewImage}
        fromaichat={ globalView == "AichatView" ? true : false}
        providerOpearator={providerOpearator}
        preparePreview={preparePreview}
        topicLogo={topicLogo}
      />
      <audio id="my-msg-audio" className="d-none">
        <source src={myMsgAudio} type="audio/mpeg" />
      </audio>
    </>
  );
};
export default Header;
