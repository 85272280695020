import React, { useEffect } from "react";
import CreditNotesList from "../components/CreditNotesList";
import {
  CardListFullHeight,
  DefaultListView,
  leftPanelCategoryListToggle,
  showLeftPanel,
} from "../hooks/utilities";
const CreditNotesView = () => {
 
  const pageName = window.location.pathname == '/credit_notes' ? 'credit_notes' : "";
  useEffect(() => {
    showLeftPanel(true, true);
    CardListFullHeight();
    DefaultListView();
    document.addEventListener('mouseup', leftPanelCategoryListToggle);
    return () => {
      document.removeEventListener('mouseup', leftPanelCategoryListToggle);
    }
  }, []);

  return (
    <>
     <CreditNotesList pageName={pageName} />
    </>
  );
};

export default CreditNotesView;
