import React from "react";
import { DropdownMenu } from "reactstrap";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { _l } from "../../hooks/utilities";
import moment from "moment";

const EmailAccordionDropDown = ({data}) => {
  return (
    <DropdownMenu className="dropdown-menu w-200 h-200px overflow-auto" direction="down">
      <ul>
        {data && data.track_details && data.track_details.length > 0 ? (
          data.track_details.map((filter, i) => {
            return (
              <React.Fragment key={i}>
                <li className="">
                  <a href="#/" className="custom-assignees custom-class-active dropdown-item with-tick">
                    <div className="d-flex align-items-center text-truncate">
                      <div className="d-flex align-items-center w100minus10 text_wrap">
                        <div className="ps-2 text_wrap">
                          <div className="c-list-detail c-font f-14 text-truncate">
                            {moment(filter.date).format(
                        "DD/MM/yy hh:mm a"
                      )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="dropdown-divider my-0"></li>
              </React.Fragment>
            );
          })
        ) : 
        <CommanPlaceholder
            imgWidth="h60w60"
            mainSpacing="py-3"
            placeholderText={_l("l_please_wait")}
      />}
      </ul>
    </DropdownMenu>
  );
};

export default EmailAccordionDropDown;