
import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;

const discord_auth = async(rel_id, rel_type, code, project_id) => {
    
    const endPoint = API_URL + "mod=discord_integration&act=discord_authorization";
    let form = new FormData();
    form.append("rel_id", rel_id);
    form.append("rel_type", rel_type);
    form.append("code", code);
    form.append("project_id", project_id);
    
    return apiService(endPoint, "POST", true, form);
  };

const is_discord_connected = async(project_id) => {
    
    const endPoint = API_URL + "mod=discord_integration&act=is_discord_connected";
    let form = new FormData();
    form.append("project_id", project_id);
    
    return apiService(endPoint, "POST", true, form);
  };

const change_topic_status = async(project_id, filter_key, active_or_inactive) => {
    
    const endPoint = API_URL + "mod=discord_integration&act=change_topic_status";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("filter_key", filter_key);
    form.append("active", active_or_inactive);
    
    return apiService(endPoint, "POST", true, form);
  };

const discord_tasks = async(project_id, channel_id) => {
    
    const endPoint = API_URL + "mod=discord_integration&act=get_channel_tasks";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("channel_id", channel_id);
    
    return apiService(endPoint, "POST", true, form);
  };

const discord_revoke = async(project_id) => {
    
    const endPoint = API_URL + "mod=discord_integration&act=revoke";
    let form = new FormData();
    form.append("project_id", project_id);
    
    return apiService(endPoint, "POST", true, form);
  };

  export default {discord_auth, is_discord_connected, change_topic_status, discord_tasks, discord_revoke};