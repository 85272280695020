import store from "../store";
import {
  SHOW_MESSAGE,
  SHOW_CONFIRMATION,
  DISMISS_MESSAGE,
  SHOW_FULL_SCREEN_LOADER,
  HIDE_FULL_SCREEN_LOADER,
  SHOW_IDB_DATA_FETCHING_LOADER,
} from "./action-type";

export const toastCloseAction = () => (dispatch) => {
  dispatch({
    type: DISMISS_MESSAGE,
    payload: {},
  });
};

const toastCloseHandler = () => {
  store.dispatch(toastCloseAction());
};

export const showMessage = (className, header, message) => (dispatch) => {
  dispatch({
    type: SHOW_MESSAGE,
    payload: { className, header, message, toastCloseHandler },
  });
};

export const showConfirmation =
  (
    header,
    message,
    confirmButtonText,
    cancelButtonText,
    cancelButtonHandler = toastCloseHandler,
    confirmButtonHandler,
    closeOnlyToast
  ) =>
  (dispatch) => {
    dispatch({
      type: SHOW_CONFIRMATION,
      payload: {
        className: "toast-with-btn",
        header,
        message,
        cancelButtonText,
        confirmButtonText,
        cancelButtonHandler,
        toastCloseHandler,
        confirmButtonHandler,
        closeOnlyToast
      },
    });
  };

export const showFullScreenLoader = () => (dispatch) => {
  dispatch({
    type: SHOW_FULL_SCREEN_LOADER,
  });
};

export const hideFullScreenLoader = () => (dispatch) => {
  dispatch({
    type: HIDE_FULL_SCREEN_LOADER,
  });
};

export const showIDBDataFetchingLoader = (show) => (dispatch) => {
  dispatch({
    type: SHOW_IDB_DATA_FETCHING_LOADER,
    payload: show
  });
};