import React from 'react';

function CustomGmailIWhitecons(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" aria-label="Gmail" role="img" viewBox="0 0 512 512" className={`comman-icons stroke-transparent ${CommanHW}`}><rect width="512" height="512" rx="15%" fill="none"></rect><path fill="#f2f2f2" d="M120 392V151.075h272V392"></path><path fill-opacity=".05" d="M256 285L120 392l-4-212"></path><path fill="#d54c3f" d="M120 392H97c-12 0-22-10-22-23V143h45z"></path><path fill-opacity=".08" d="M317 392h77V159H82"></path><path fill="#f2f2f2" d="M97 121h318L256 234"></path><path fill="#b63524" d="M392 392h23c12 0 22-10 22-23V143h-45z"></path><path fill="none" stroke="#de5145" stroke-linecap="round" stroke-width="44" d="M97 143l159 115 159-115"></path></svg>

		</React.Fragment>
	)
}

export default CustomGmailIWhitecons
