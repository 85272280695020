import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import CustomerServices from "../../services/customer-services";
import { _l } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import { useDispatch } from "react-redux";
const AddNewMailCustomCategory = ({ categories, handler, projectId, show, handleClose, activeTopicKey,customTopicTitle, setCustomTopicTitle, customTopicMail, setCustomTopicMail,topicId,setTopicId }) => {
  const dispatch = useDispatch();
  const [categoryTitle, setCategoryTitle] = useState(customTopicTitle);
  const [categoryMail, setCategoryMail] = useState(customTopicMail);

  const saveCategory = () => {

    if (categoryTitle && categoryTitle != '') {
      CustomerServices.addCustomTopic(projectId, 'my_customs',categoryMail,categoryTitle,topicId).then((res) => {
        if (res.status == 1) {
          setCustomTopicTitle('');
          setCustomTopicMail('');
          setTopicId(0);
          window.dispatchEvent(new Event("addEditCategoryModalClose"));
          window.dispatchEvent(new Event("filter_key_close"));
          handler({ id: res.data.tag_id ? res.data.tag_id : 0, name: categoryTitle, email: categoryMail });
          handleClose();
        }
      });
    } else {
      dispatch(showMessage('unsucess', _l("l_error"), _l("l_please_enter_valid_topic")));
      return false;
    }
  };
  const closeModal = () => {
    handleClose();
    setCustomTopicTitle('');
    setCustomTopicMail('');
    setTopicId(0);
  }

  return (
    <div className="custom-modal-style add-topic-search">
      <Modal
        className="modal fade custom-modal-style"
        tabIndex="-1"
        aria-labelledby="addNewCategoryModal"
        aria-hidden="true"
        show={show}
        onHide={closeModal}
        centered
      >
        <Modal.Header closeButton className="">
          <Modal.Title>{customTopicTitle ? _l('l_edit_new_topic') : _l('l_add_new_topic')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="add-new-category">
            <div className="d-flex align-items-center">
              <Form.Group className="comment-input-box flex-grow-1">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_topic_name')}
                </Form.Label>
                <Form.Control
                  className="height-45 border-0 pe-2"
                  type="text"
                  placeholder={`${_l('l_topic_name')}`}
                  value={categoryTitle}
                  onChange={(e) => { setCategoryTitle(e.target.value) }}
                />
              </Form.Group>
            </div>
            <div className="d-flex align-items-center mt-4">
              <Form.Group className="comment-input-box flex-grow-1">
                <Form.Label className="input-label no-asterisk ">
                  {_l('l_topic_mail')}
                </Form.Label>
                <Form.Control
                  className="height-45 border-0 pe-2"
                  type="text"
                  placeholder={`${_l('l_topic_mail')}`}
                  value={categoryMail}
                  onChange={(e) => { setCategoryMail(e.target.value) }}
                />
              </Form.Group>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_close')}
          </Button>
          <Button variant="primary" size="sm" onClick={saveCategory}>{_l('l_save')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewMailCustomCategory;
