import React, { useState, useEffect } from "react";
import { _l } from "../../hooks/utilities";
import CheckedDropdown from "./CheckedDropdown";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Toasts from "../Toasts/Toasts";
import CustomerServices from "../../services/customer-services";
import {
  getSelectedTask,
} from "../../actions/customer";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import CheckSquareIcon from "../../assets/icons/CheckSquareIcon";
import RepeatIcon from "../../assets/icons/RepeatIcon";
import ChatCircleIcons from "../../assets/icons/ChatCircleIcons";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import PushPinIcon from "../../assets/icons/PushPinIcon";
const CalendarRequestTaskDropdown = ({ projectId, handler, setShowRequestTaskInfo }) => {
  const [openCheckList, setOpenCheckList] = useState(false);
  const selectedTask = useSelector((state) => state.customer.selectedTask);
  const [description, setDescription] = useState("");
  const [taskPriority, setTaskPriority] = useState(
    selectedTask ? (selectedTask.priority == "3" ? 3 : 2) : 2
  );
  const [todoTasks, setTodoTasks] = useState([]);
  const [convertTask, setConvertTaskId] = useState({});
  var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/;
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [addOrEditTaskId, setAddOrEditTaskId] = useState(
    selectedTask && taskId ? taskId : ""
  );
  const [taskId, setTaskId] = useState();
  const [taskStatus, setTaskStatus] = useState(0);
  const [taskName, setTaskName] = useState("");
  const [taskCreaterProfile, setTaskCreaterProfile] = useState('');
  const [taskPinShow, setTaskPinShow] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [attachmentList, setAttachmentList] = useState([]);
  const [convertedTaskId, setConvertedTaskId] = useState(0);
  const [ProviderId, setProviderId] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      selectedTask &&
      Object.keys(selectedTask).length) {
      setTaskId(selectedTask.task_id);
      if (selectedTask.startdate != null) {
        setStartDate(moment(selectedTask.startdate).format(
          "DD/MM/YYYY hh:mm a"
        ));
      }
      setTaskPinShow(parseInt(selectedTask.is_pinned) > 0);
      if (selectedTask.task_creator_image) {
        setTaskCreaterProfile(selectedTask.task_creator_image);
      }
      if (selectedTask.status) {
        setTaskStatus(selectedTask.status);
      }
      if (selectedTask.priority) {
        setTaskPriority(selectedTask.priority == 3 ? 3 : 2);
      }
      if (selectedTask.name) {
        setTaskName(selectedTask.name);
      }
      setDescription("");
      setAttachmentList([]);
      if (
        selectedTask.description != "" &&
        selectedTask.description != "null"
      ) {
        setDescription(selectedTask.description);
      }
      setProviderId(selectedTask.provider_id ? selectedTask.provider_id : 0)
      if (selectedTask.atttachments && selectedTask.atttachments.length > 0) {
        setAttachmentList(
          selectedTask.atttachments.map((img) => {
            return img;
          })
        );
      }
      setConvertedTaskId(selectedTask && selectedTask.request_task_id ? selectedTask.request_task_id : 0)
    }
  }, [JSON.stringify(selectedTask)]);
  const handleConvertTask = (task_Id = "", flag = "") => {
    if (
      description != null &&
      description != undefined &&
      description != "" &&
      !format.test(description)
    ) {
      let task_id = task_Id;
      CustomerServices.convertRequestTask(
        flag,
        description,
        taskId,
        task_id
      ).then((res) => {
        if (res.status && res.data) {
          if (flag == "new_task") {
            task_id = res.data.id;
          }
          setConvertedTaskId(task_id)
          setDescription("");
        }
      });
    } else {
      setShowToast(false);
      setToastClass("unsucess");
      setToastHeader(_l("l_error"));
      setToastMessage(_l("l_please_enter_valid_description"));
      setShowToast(true);
    }
  }
  const getTodoTasks = (provider_id = 0) => {
    setTodoTasks([]);
    CustomerServices.getTodoTaskList(provider_id).then((res) => {
      if (res.status && res.data) {
        setTodoTasks(res.data);
      }
    });
  };
  return (
    <>
      <div
        className="dropdown-menu w-350 radius_5 d-block"
        aria-labelledby="CalendarRequestTaskDropdown" id="CalendarRequestTaskDropdownClose"
      >
        {showToast ? (
          <Toasts
            delay={2000}
            handler={() => {
              setShowToast(false);
              setConvertTaskId({})
            }}
            header={toastHeader}
            message={toastMessage}
            toastClass={toastClass}
            cancelButtonText={cancelButtonText}
            cancelButtonHandler={() => {
              setShowToast(false);
              setTaskName("");
              setConvertTaskId({})
            }}
            confirmButtonText={confirmButtonText}
            confirmButtonHandler={() => {
              if (Object.keys(convertTask).length) {
                handleConvertTask(convertTask.id, convertTask.type)
                setShowToast(false);
              }
            }}
          />
        ) : (
          <></>
        )}
        <div>
          <div className="dropdown-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center w-100 text-truncate">
                <div className="c-list-icon">
                  <div className="comman-image-box h25w25 rounded-circle"
                     >
                    <div
                      className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                      style={{ backgroundImage: `url('${taskCreaterProfile}')` }}
                    ></div>
                  </div>
                </div>
                <div className="c-list-detail c-font f-14 title-fonts fw-semibold text-truncate px-2">
                  <div className="text-truncate">
                    {taskName}
                  </div>
                  <div className="date-detail d-flex align-items-center color-white-60 c-font f-10 py-1">
                    <CalendarBlankIcon className="HW14 m-0" />
                    <span className="ps-1">{startDate}</span>
                  </div>
                </div>
              </div>
              <div className="right-option">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="white-border-box d-flex align-items-center h25w25">
                      <a href="#/"
                        onClick={() => {
                          if (convertedTaskId == 0) {
                            if (description == '' || format.test(description)) {
                              setShowToast(false);
                              setToastClass("unsucess");
                              setToastHeader(_l("l_error"));
                              setToastMessage(_l("l_please_enter_valid_description"));
                              setShowToast(true);
                            } else {
                              setToastHeader(_l("l_are_you_sure"));
                              setToastClass("toast-with-btn");
                              setToastMessage(_l("l_only_one_action_perform_at_a_time"));
                              setConfirmButtonText(_l("l_convert"));
                              setCancelButtonText(_l("l_cancel"));
                              setShowToast(true);
                              setConvertTaskId({
                                type: 'new_task', id: ''
                              })
                            }
                          }
                        }}
                      >
                        <RepeatIcon
                          className="HW16"
                        />
                      </a>
                    </div>
                    <div className="white-border-box d-flex align-items-center h25w25 dropdown ms-2">
                      <a href="#/"
                        onClick={() => {
                          if (convertedTaskId == 0) {
                            setOpenCheckList(true);
                            getTodoTasks(ProviderId)
                          }
                        }}
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <CheckSquareIcon
                          className="HW16"
                        />
                      </a>
                      {convertedTaskId == 0 ? (
                        <CheckedDropdown
                          setOpenCheckList={setOpenCheckList}
                          project_id={projectId}
                          openCheckList={openCheckList}
                          task_id={addOrEditTaskId}
                          isRequestTask="convert_to_checklist"
                          tasks={todoTasks}
                          handleTask={(taskId) => {
                            if (description == '' || format.test(description)) {
                              setShowToast(false);
                              setToastClass("unsucess");
                              setToastHeader(_l("l_error"));
                              setToastMessage(_l("l_please_enter_valid_comment"));
                              setShowToast(true);
                            } else {
                              setToastHeader(_l("l_are_you_sure"));
                              setToastClass("toast-with-btn");
                              setToastMessage(_l("l_only_one_action_perform_at_a_time"));
                              setConfirmButtonText(_l("l_convert"));
                              setCancelButtonText(_l("l_cancel"));
                              setShowToast(true);
                              setConvertTaskId({
                                'type': 'convert_to_checklist', id: taskId
                              })
                            }
                          }}
                          isSystemTask={
                            selectedTask && parseInt(selectedTask.is_allowed_to_edit) != 1
                              ? true
                              : null
                          }
                          isFromCalendarDropdown="fromCalendar"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="white-border-box d-flex align-items-center h25w25 dropdown ms-2">
                      <a href="#/"
                        onClick={() => {
                          if (convertedTaskId == 0) {
                            setOpenCheckList(true);
                            getTodoTasks(ProviderId)
                          }
                        }}
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <ChatCircleIcons
                          className="HW16"
                        />
                      </a>
                      {convertedTaskId == 0 ? (
                        <CheckedDropdown
                          setOpenCheckList={setOpenCheckList}
                          project_id={projectId}
                          openCheckList={openCheckList}
                          task_id={addOrEditTaskId}
                          isRequestTask="convert_to_comment"
                          tasks={todoTasks}
                          handleTask={(taskId) => {
                            if (description == '' || format.test(description)) {
                              setShowToast(false);
                              setToastClass("unsucess");
                              setToastHeader(_l("l_error"));
                              setToastMessage(_l("l_please_enter_valid_description"));
                              setShowToast(true);
                            } else {
                              setToastHeader(_l("l_are_you_sure"));
                              setToastClass("toast-with-btn");
                              setToastMessage(_l("l_only_one_action_perform_at_a_time"));
                              setConfirmButtonText(_l("l_convert"));
                              setCancelButtonText(_l("l_cancel"));
                              setShowToast(true);
                              setConvertTaskId({
                                'type': 'convert_to_comment', id: taskId
                              })
                            }
                          }}
                          isFromCalendarDropdown="fromCalendar"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <li>
          <hr className="dropdown-divider mt-0" />
        </li>
        <div className="access-box-main pt-0">
          <div className="d-flex justify-content-between align-items-center pb-2 border-bottom">
            <div className="d-flex align-items-center flex-wrap">
              <div className="white-border-box color-white-60 dropend d-flex align-items-center h25w25 me-2"
                id="TaskListStatusDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className={`c-dots c-10  ${taskStatus == 1
                  ? "in-progress-white"
                  : taskStatus == 4
                    ? "in-progress-blue"
                    : "done"
                  } rounded-circle `}></span>
              </div>
              <div className="white-border-box d-flex align-items-center h25w25 me-2">
                <div
                  className="priority-checkbox text_wrap position-relative"
                >
                  <input
                    checked={taskPriority == 3 ? true : false}
                    type="checkbox"
                    className="absolute-input"
                    disabled />
                  <div className="check_box">
                    <HighPriorityActiveIcon className="HW18 check_span_img active" />
                    <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                  </div>
                </div>
              </div>
              <div className="white-border-box d-flex align-items-center h25w25 ">
                <a href="#/"
                  className=""
                >
                  <PushPinIcon
                    className={`HW16 ${!taskPinShow ? "" : "d-none"
                      }`}
                  />
                  <PushPinFillIcon
                    className={`stroke-transparent Push-Pin-fill HW16 ${taskPinShow ? "" : "d-none"
                      }`}
                  />
                </a>
              </div>
            </div>
            <div className="d-flex">
              <div className="badge badge-red rounded-pill badge  badge-white rounded-pill fw-light mx-2">
                <span className="">{_l('l_request')}</span>
              </div>
              <a href="#/" className={`${convertedTaskId == 0 ? 'd-none' : ''}`} onClick={() => {
                if (convertedTaskId > 0) {
                  dispatch(
                    getSelectedTask(
                      localStorage.getItem("selectedOffice"),
                      convertedTaskId
                    )
                  );
                  handler(
                    convertedTaskId,
                    "show",
                    0,
                    taskId
                  );
                  setShowRequestTaskInfo(false)
                }
              }}>
                <span className=" btn btn-primary btn-sm line-height-1">
                {_l('l_view_task')}
                </span>
              </a>
            </div>
          </div>
          <div className="detail-part pb-2 color-white-60 c-font f-12 title-fonts">
            {description}
          </div>
          <div className="multt-image-square">
            <div className="d-flex align-items-center">
              {attachmentList.length ? (
                attachmentList.slice(0, 4).map((attachment, index) => {
                  return (
                    <div className="h50w50 comman-image-box with-bg d-flex align-items-center bg-white-05 p-5px me-1" key={index}>
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                        style={{
                          backgroundImage: `url('${attachment.image_url}')`,
                        }}
                      ></div>
                    </div>
                  )
                })
              ) : <></>
              }
              {
                attachmentList.length > 5 ? (
                  <div className="h50w50 comman-image-box with_overlay with-bg d-flex align-items-center bg-white-05 p-5px me-1">
                    <div className="unread_count m-auto title-fonts c-font f-12 fw-semibold">+{attachmentList.length - 5}</div>
                  </div>
                ) : <></>
              }
              {
                attachmentList.length == 0
                  ? (
                    <CommanPlaceholder imgType="no-images" placeholderText = {_l("l_no_images")} />
                  )
                  : <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CalendarRequestTaskDropdown;
