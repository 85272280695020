import React, { useEffect, useState } from 'react'
import moment from "moment";
import Form from "react-bootstrap/Form";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import UncontrolledGeneralListing from "../TaskModalComponents/UncontrolledGeneralListing";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TaskListStatusDropdown from '../Dropdowns/TaskListStatusDropdown';
import Spinner from "react-bootstrap/Spinner";
import { _l, getDaysBetweenDates, getHoursBetweenDates, getMintuesBetweenDates } from "../../hooks/utilities";
import CommentDropdown from '../Dropdowns/CommentDropdown';
import FollowersDropdown from '../Dropdowns/FollowersDropdown';
import ImageUploadDropdown from '../Dropdowns/ImageUploadDropdown';

import { useDispatch, useSelector } from 'react-redux';
import { changePriority, getSelectedTask, updateTaskStatus } from '../../actions/customer';
import customerServices from '../../services/customer-services';
import CommanOffCanvas from '../Offcanvas/CommanOffCanvas';
import { COUNT_LOCK_TASKS } from '../../actions/action-type';
import { DEFAULT_PLACEHOLDER } from "../../actions/action-type";
import UserCircleGearIcon from '../../assets/icons/UserCircleGearIcon';
import ChatCircleIcons from '../../assets/icons/ChatCircleIcons';
import PushPinFillIcon from '../../assets/icons/PushPinFillIcon';
import PushPinIcon from '../../assets/icons/PushPinIcon';
import CaretDoubleDownIcon from '../../assets/icons/CaretDoubleDownIcon';
import ImageIcon from '../../assets/icons/ImageIcon';


const RequestCard = ({provider, hendleAction}) => {
    const [request, setRequest] = useState(provider.request ? provider.request : []);
    const [openImageItemTab,setOpenImageItemTab] = useState(false);
    const [items, setItems] = useState(provider.checklists ? provider.checklists : []);
    const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
    const [taskStatus, setTaskStatus] = useState(request.status);
    const dispatch = useDispatch();
    const project_id = useSelector((state) => state.customer.selectedProject);
    const [updatedCommentCount, setUpdatedCommentCount] = useState(request.comments_count ? request.comments_count : 0);
    const [task_type, setTask_type] = useState('');
    const RequestTaskdays =
      request.startdate && request.startdate != null
        ? getDaysBetweenDates(request.startdate, request.converted_date)
        : "";
    const RequestTaskHours =
      request.startdate && request.startdate != null
        ? getHoursBetweenDates(request.startdate, request.converted_date)
        : "";
    const RequestTaskMintues =
      request.startdate && request.startdate != null
        ? getMintuesBetweenDates(request.startdate, request.converted_date)
        : "";
    const [openCommentTab, setOpenCommentTab] = useState(false);
    const [isTaskPinned, setIsTaskPinned] = useState(request.pinned == "1" ? true : false);
    const [openCommentTabReq, setOpenCommentTabReq] = useState(false);
    const spaces = localStorage.getItem("spaces") != null && localStorage.getItem("spaces") != undefined ? JSON.parse(localStorage.getItem("spaces")) : '';
    const savedProject = localStorage.getItem("selectedOffice");
  const [taskId, setTaskId] = useState(request.id);
  const [categoryName, setCategoryName] = useState('No Topic');
  const contact_id = localStorage.getItem("contact_id");
  const [projectId, setProjectId] = useState( savedProject && ( savedProject != null ? savedProject : ( spaces && spaces.length ? spaces[0].project_id : 0 )));
  const [updatedAttachmentCount, setUpdatedAttachmentCount] = useState(Number(request.attachments_count));
  const [taskPriority, setTaskPriority] = useState(request && request.priority == "2"? 2:3);
  const [openAttachmentsDropdown, setOpenAttachmentsDropdown] = useState(false);
  const [checked, setChecked] = useState(false);
  const [itemAttachmentCount, setItemAttachmentCount] = useState(0);
  const {selectedTask, defaultTopics } = useSelector((state) => state.customer);
  const [page, setPage] = useState(1);

  const changeTaskPriority = (e) => {
    dispatch(
      changePriority(
        request.id,
        project_id,
        taskPriority == 3 ? 3 : 2,
        request.provider
      )
    ).then(() => {
      setTaskPriority(taskPriority == 3 ? 2 : 3);
    });
  };
  const changeTaskStatus = (statusId) => {
    if (statusId != "task_convert_to_request") {
      setTaskStatus(statusId);
    }
    dispatch(
      updateTaskStatus(
        request.id,
        project_id,
        statusId,
        0
      )
    ).then(() => {
      customerServices.getCountLockTask(project_id, moment(localStorage.getItem('__selectedDate')).format("YYYY-MM-DD"), localStorage.getItem('is_dashboard') ? localStorage.getItem('is_dashboard'): 0).then((res) => {
        if(res.status)
        {
            let data = res.data;
            dispatch({
                type: COUNT_LOCK_TASKS,
                payload: {data}
            });
        }
      });
    });
  };
  const itemPriority = (checklistId, isFinished, item, priority) => {
    customerServices.updateChecklistItem(
      projectId,
      request.id,
      checklistId,
      isFinished,
      item,
      priority
    ).then((res) => {
      if (res.status) {
        const index = items.findIndex(
          (item) => item.id == checklistId
        );
        let updatedChecklist = items[index];
        updatedChecklist.priority = priority;
        setItems(
          Object.values({ ...items, [index]: updatedChecklist })
        );
      }
    });
  }
  const pinTask = () => {
    let ProjectId = project_id
      ? project_id
      : localStorage.getItem("selectedOffice");
    customerServices.pinTask(request.id, ProjectId).then((res) => {
      setIsTaskPinned(!isTaskPinned);
    });
  };
  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
  };
 const handleCheckListUpdate = (
  id,
  value,
  isFinished,
  checkUnCheck = false,
  index = -1
) => {
  const prev =
    items && items.length
      ? items.filter((previousValue) => previousValue.id == id)
      : {};

  if (
    !checkUnCheck &&
    prev[0].addedfrom != contact_id &&
    localStorage.getItem("user_type") == "contact"
  ) {
    return;
  }

  if (value) {

    customerServices.updateChecklistItem(
      projectId,
      request.id,
      id,
      isFinished,
      value.trim()
    ).then((res) => {
      if (res.status) {
      }
    });
  }
};
 const itemFinished = (checklistData, checked, index) => {
  checklistData.finished = checked ? "1" : "0";
  handleCheckListUpdate(
    checklistData.id,
    checklistData.description,
    checklistData.finished,
    true
  );
  setItems(
    Object.values({ ...items, [index]: checklistData })
  );
  const element = document.getElementById(
    `taskcard_checkbox_${checklistData.id}`
  );
  if (element) {
    const currentValue = element.checked;
    element.checked = !currentValue;
  }
};

useEffect(() => {
  if(defaultTopics)
  {
      let filtered_topic = defaultTopics.filter((topic) => (topic.main_key).toLowerCase().includes(provider.assginee_type && provider.assginee_type !== null ? provider.assginee_type: ""));
      if(filtered_topic.length != 0) {
          let category = filtered_topic[0].categories.filter((category) => category.filter_key.includes(provider.assginee_id && provider.assginee_id !== null ? provider.assginee_id: ""));
          if(typeof category == 'object' && category.lengh != 0 && category[0]) {
              setCategoryName(category[0].title);
          }
      }
  }
}, [defaultTopics, request]);
    return (
        <>
            <div className='p-10 px-0'>
                <div className='d-flex h-100'>
                    <div className='w_80px h-100 after-separator position-relative'>
                        <div className='c-font color-white-80 f-12 pb-2 text-center title-fonts'>
                        {provider && provider['time'] !== false && provider['time'] !== null ? moment(provider['time']).format('h:mm a') : ''}
                        </div>
                    </div>
                    <div className="bg-white-03 p-15 radius_3 flex-grow-1 w_80calc">
                        <div className='align-items-center d-flex justify-content-between border-bottom p-15 px-0 pt-0 ps-1'>
                            <div className='d-flex flex-grow-1 w100minus100per align-items-end'>
                            <a href="#/" className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 mb-1"
                               
                               
                               
                              >
                                <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle" style={{ backgroundImage: `url('${ request && request.creator_image ? ((request.creator_image)) : DEFAULT_PLACEHOLDER}')` }}></div>
                            </a>
                                <div className='flex-grow-1 ms-2 w100minus100per'>
                                    <div className='c-font f-13'>#{_l(categoryName)}</div>
                                    <div class="align-item-center color-white-60 d-flex justify-content-between pill-bottom-show title-fonts">
                                        <div className=' d-flex align-items-center'>
                                            <div className='me-3'>
                                                <div class="badge badge-red rounded-pill"><span class="ps-0"> {_l("l_request")}</span></div>
                                            </div>
                                            <div className="multi_image_wrapper dropdown me-2">
                                                <div className="multi_inner_wrapper d-flex position-relative">
                                                { request && request.assignee_task && 
                                                  request.assignee_task.length > 1 ? 
                                                  <a href="#/" className="comman-image-box h20w20 with_overlay d-flex rounded-circle">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a" className=" dropdown team-list-dropdown dropdown-center" >
                                                                <a href="#/" className="comman-image-box h20w20 with_overlay rounded-circle d-flex radius_3">
                                                                    <div className="unread_count m-auto" >
                                                                        +{request.assignee_task.length > 0 ?request.assignee_task.length - 1: 0}
                                                                    </div>
                                                                </a>
                                                                <UncontrolledGeneralListing list={request.assignee_task && request.assignee_task.map((res,index) => {
                                                                    return {
                                                                        name: res.name,
                                                                        image: res.profile_image,
                                                                        id: index,
                                                                    };
                                                                })} />
                                                            </DropdownToggle>
                                                        </UncontrolledDropdown>
                                                    </a>
                                                    :
                                                    <a href="#/" className="comman-image-box h20w20 rounded-circle multi-wrapper-border">
                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url('${request.assignee_task && request.assignee_task.lengh > 0 ? request.assignee_task[0].profile_image : DEFAULT_PLACEHOLDER}')` }} >
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="a" className=" dropdown team-list-dropdown dropdown-center" >
                                                                <UncontrolledGeneralListing list={request.assignee_task && request.assignee_task.map((res,index) => {
                                                                    return {
                                                                        name: res.name,
                                                                        image: res.profile_image,
                                                                        id: index,
                                                                    };
                                                                })} />
                                                            </DropdownToggle>
                                                        </UncontrolledDropdown>
                                                        </div>
                                                    </a>
                                                }
                                                    
                                                </div>
                                            </div>
                                            <div
                                              className={`title-fonts text-truncate pill-bottom-show color-white-60 pe-2 ${
                                                RequestTaskdays == "" &&
                                                RequestTaskHours == "" &&
                                                RequestTaskMintues == ""
                                                  ? "d-none"
                                                  : ""
                                              }`}
                                            >
                                              <span className="me-1">
                                                <span
                                                  className={` c-font f-13  ${
                                                    RequestTaskdays >= 0
                                                      ? "color-green"
                                                      : "color-red"
                                                  }`}
                                                >
                                                  {Math.abs(RequestTaskdays)}
                                                </span>
                                                <span className="color-white-60 c-font f-10">
                                                  {_l("l_day")}
                                                </span>
                                              </span>
                                              <span className="me-1">
                                                <span
                                                  className={` c-font f-13  ${
                                                    RequestTaskHours >= 0
                                                      ? "color-green"
                                                      : "color-red"
                                                  }`}
                                                >
                                                  {Math.abs(RequestTaskHours)}
                                                </span>
                                                <span className="color-white-60 c-font f-10">
                                                  {_l("l_hrs")}
                                                </span>
                                              </span>
                                              <span className="me-1">
                                                <span
                                                  className={` c-font f-13  ${
                                                    RequestTaskHours >= 0
                                                      ? "color-green"
                                                      : "color-red"
                                                  }`}
                                                >
                                                  {Math.abs(RequestTaskMintues)}
                                                </span>
                                                <span className="color-white-60 c-font f-10">
                                                  {_l("l_min")}
                                                </span>
                                              </span>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex pe-2'>
                                                <div className="multi_image_wrapper dropdown">
                                                  {
                                                    updatedAttachmentCount > 0 ?
                                                    <div className="multi_inner_wrapper d-flex position-relative">
                                                        <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')` }} >

                                                            </div>
                                                        </a>
                                                        <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                            <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')` }} >

                                                            </div>
                                                        </a>
                                                        <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropdown dropdown-center'>
                                                            <a href="#/" className="m-auto" type="button"
                                                                id="imageLstAndUploadDropdown"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                data-bs-auto-close="outside"
                                                                 
                                                                 
                                                                 
                                                                onClick={()=>{setOpenAttachmentsDropdown(true)}}
                                                                 >
                                                                    {Number(updatedAttachmentCount)}
                                                            </a>
                                                            <ImageUploadDropdown
                                                        setOpenAttachmentsDropdown={
                                                            setOpenAttachmentsDropdown
                                                          }
                                                          openAttachmentsDropdown={
                                                            openAttachmentsDropdown
                                                          }
                                                          task_id={request.id}
                                                          project_id={project_id}
                                                          updateImageCount={setUpdatedAttachmentCount}
                                                          Request_task_converted={
                                                            request &&
                                                            parseInt(request.request_task_id) > 0 &&
                                                            request.is_requested == 1
                                                              ? true
                                                              : false
                                                          }
                                                          taskDetails={request}
                                                        />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-flex align-items-center'>
                                                      <div className='d-flex pe-2'>
                                                          <div className="multi_image_wrapper dropdown dropdown-center">
                                                              <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                                                                <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropdown dropdown-center'>
                                                                  <a href="#/"  className="m-auto" type="button"
                                                                            id="imageLstAndUploadDropdown"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                            data-bs-auto-close="outside"
                                                                             
                                                                             
                                                                             
                                                                            onClick={()=>{setOpenAttachmentsDropdown(true)}}
                                                                             >
                                                                              <ImageIcon
                                                                                className="HW20"
                                                                              />
                                                                            </a>
                                                                              <ImageUploadDropdown
                                                                              setOpenAttachmentsDropdown={
                                                                                  setOpenAttachmentsDropdown
                                                                                }
                                                                                openAttachmentsDropdown={
                                                                                  openAttachmentsDropdown
                                                                                }
                                                                                task_id={request.id}
                                                                                project_id={project_id}
                                                                                updateImageCount={setUpdatedAttachmentCount}
                                                                                Request_task_converted={
                                                                                  request &&
                                                                                  parseInt(request.request_task_id) > 0 &&
                                                                                  request.is_requested == 1
                                                                                    ? true
                                                                                    : false
                                                                                }
                                                                                taskDetails={request}
                                                                              />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                  }
                                                </div>
                                            </div>
                                            <div className={`dropdown-center comman_action_icon with_separator_10 card-view-less-padding
                                                    `}>
                                            
                                                <div
                                                    className={`white-border-box color-white-60 dropend d-flex align-items-center h25w25 action_icon with_bg border-0 action_icon with_bg`}
                                                    id="TaskListStatusDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                     
                                                     
                                                     
                                                    data-className="tooltip-main"
                                                >
                                                    <div className="d-flex">
                                                   
                                                    <span
                                                        className={`c-dots c-10 ${request && request.is_requested == 1? "bg-warning" :taskStatus == 1
                                                        ? "in-progress-white"
                                                        : taskStatus == 4
                                                            ? "in-progress-blue"
                                                            : "done"
                                                        } rounded-circle `}
                                                    ></span>
                                                        <TaskListStatusDropdown
                                                        key={2}
                                                        setTaskStatus={changeTaskStatus}
                                                        is_task={request.is_requested == 1? 1:0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="comman_action_icon with_separator_10 card-view-less-padding">
                                                <div
                                                    className={`priority-checkbox span-13 white-border-box action_icon with_bg close-toast`}
                                                     
                                                >
                                                    <input
                                                    type="checkbox"
                                                    id="priority"
                                                    className="absolute-input"
                                                    checked={taskPriority == 3 ? true : false}
                                                    onChange={(e) => {
                                                        changeTaskPriority(e);
                                                    }}
                                                   
                                                    disabled={
                                                        request && parseInt(request.request_task_id) > 0
                                                    }
                                                    />
                                                    <div className="check_box">
                                                    <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                    <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="before-dnone comman_action_icon with_separator_10 px-1">
                                            <a href="#/"
                                                className="action_icon with_bg light-theme-img close-toast"
                                                
                                                 
                                                 
                                            >
                                                {isTaskPinned && isTaskPinned == true ? (
                                                <div
                                                    className="m-auto "
                                                    onClick={() => {
                                                    pinTask();
                                                    }}
                                                >
                                                    <PushPinFillIcon className='HW16 stroke-transparent Push-Pin-fill' />
                                                </div>
                                                ) : (
                                                <div
                                                    className="m-auto"
                                                    onClick={() => {
                                                    pinTask();
                                                    }}
                                                >
                                                    <PushPinIcon
                                                    className="HW16"
                                                    />
                                                </div>
                                                )}
                                            </a>
                                            </div>
                                            <div className="dropdown comman_action_icon px-1 align-items-center d-flex">
                                            <UncontrolledDropdown className="w-100 d-flex">
                                              <DropdownToggle  data-toggle="dropdown" tag="span">
                                            <a href="#/" className="comman_action_icon w-100 d-flex"
                                                type="button"
                                                id="writeComMentDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                data-bs-auto-close="outside"
                                                 
                                                onClick={()=>{setOpenCommentTab(true)}}
                                                 
                                                 
                                                 >
                                                <div className="d-flex align-items-center w-100">
                                                    <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                                                        <ChatCircleIcons
                                                            className="HW16"
                                                        />
                                                        <span className="rounded-pill badge-custom p-0 d-none">5</span>
                                                    </div>
                                                    <span
                                                        className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle">
                                                            {updatedCommentCount}
                                                            
                                                        </span>
                                                </div>
                                            </a>
                                            </DropdownToggle>
                                            <DropdownItem toggle={false} className="p-0">
                                            {
                                                <CommentDropdown
                                                openCommentTab={openCommentTab}
                                                setOpenCommentTab={setOpenCommentTab}
                                                task_id={request.id}
                                                projectId={project_id}
                                                updatedCommentCount={setUpdatedCommentCount}
                                                Request_task_converted={
                                                  request &&
                                                  parseInt(request.request_task_id) > 0 &&
                                                  request.is_requested == 1
                                                    ? true
                                                    : false
                                                }
                                                unContolList={true}
                                              />
                                            }
                                            </DropdownItem>
                                          </UncontrolledDropdown>
                                        </div>
                                        <a href="#/"  onClick={() => {
                                               setShowDocumentCanvas(true);
                                               dispatch(getSelectedTask(project_id, request.id, request.is_requested));
                                                }}
                                                className='btn btn-primary ms-2'
                                                variant="primary"
                                        >
                                            {_l("l_convert")}
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pb-2 pt-3 ps-30'>
                            <ul className=' '>
                                {
                                    items && items.length ? items.slice(
                                        0,
                                        page > 1
                                          ? (page - 1) * 5 + 3
                                          : page * 3
                                      ).map((item, key) => {
                                        return (
                                            <>
                                            <li className='align-items-center d-flex justify-content-between p-10 px-0 pt-0'>
                                    <div className='d-flex flex-grow-1 w100minus100per align-items-center'>
                                        <Form.Check
                                            type="checkbox"
                                            className=""
                                            checked={item.finished != 0}
                                            onChange={(e) => {
                                              itemFinished(item, e.target.checked, key);
                                              setChecked(e.target.checked);
                                            }}
                                        />
                                        <div className='c-font color-white-80 f-12 flex-grow-1 ms-2 text-truncate w100minus100per pe-2'>{item.description}</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='d-flex pe-2'>
                                            <div className="multi_image_wrapper dropdown">
                                              {
                                                itemAttachmentCount > 0 || item.attachments_count.attachments_count > 0 ?
                                                <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                                                    <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')` }} >

                                                        </div>
                                                    </a>
                                                    <a href="#/" className="comman-image-box h20w20 radius_3 multi-wrapper-border">
                                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3" style={{ backgroundImage: `url('https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg')` }} >

                                                        </div>
                                                    </a>
                                                    <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropdown-center'>
                                                        <a href="#/" className="m-auto" type="button"
                                                            id="imageLstAndUploadDropdown"
                                                            data-bs-toggle="dropdown"
                                                            data-bs-offset="5,5"
                                                            aria-expanded="false"
                                                            data-bs-auto-close="outside"
                                                             
                                                             
                                                             
                                                            onClick={() => {setOpenImageItemTab(true)}}
                                                             >
                                                                {items[key].attachments_count.attachments_count}
                                                        </a>
                                                        <ImageUploadDropdown
                                                        setOpenAttachmentsDropdown={
                                                            setOpenImageItemTab
                                                          }
                                                          openAttachmentsDropdown={
                                                            openImageItemTab
                                                          }
                                                          task_id={request.id}
                                                          checklistId={item.id}
                                                          project_id={project_id}
                                                          updateImageCount={(count) => {
                                                            item.attachments_count.attachments_count = count;
                                                            setItems((items) => [...items, item]);
                                                          }}
                                                          Request_task_converted={
                                                            request &&
                                                            parseInt(request.request_task_id) > 0 &&
                                                            request.is_requested == 1
                                                              ? true
                                                              : false
                                                          }
                                                          taskDetails={request}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className='d-flex'>
                                                  <div className='d-flex pe-2'>
                                                    <div className="multi_image_wrapper dropdown">
                                                      <div className="d-flex multi_inner_wrapper pe-2 position-relative">
                                                        <div className='comman-image-box h20w20 with_overlay d-flex radius_3 me-1 dropdown-center'>
                                                          <a href="#/" className="m-auto" type="button"
                                                              id="imageLstAndUploadDropdown"
                                                              data-bs-toggle="dropdown"
                                                              data-bs-offset="5,5"
                                                              aria-expanded="false"
                                                              data-bs-auto-close="outside"
                                                               
                                                               
                                                               
                                                              onClick={() => {setOpenImageItemTab(true)}}
                                                               >
                                                                  <ImageIcon
                                                                    className="HW20"
                                                                  />
                                                          </a>

                                                          <ImageUploadDropdown
                                                          setOpenAttachmentsDropdown={
                                                              setOpenImageItemTab
                                                            }
                                                            openAttachmentsDropdown={
                                                              openImageItemTab
                                                            }
                                                            task_id={request.id}
                                                            checklistId={item.id}
                                                            project_id={project_id}
                                                            updateImageCount={(count) => {
                                                              item.attachments_count.attachments_count = count;
                                                              setItems((items) => [...items, item]);
                                                            }}
                                                            Request_task_converted={
                                                              request &&
                                                              parseInt(request.request_task_id) > 0 &&
                                                              request.is_requested == 1
                                                                ? true
                                                                : false
                                                            }
                                                            taskDetails={request}
                                                          />
                                                      </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                        </div>
                                        <div className="dropdown comman_action_icon px-1 align-items-center d-flex">
                                            <a href="#/" className="action_icon with_bg justify-content-center align-items-center"
                                                type="button"
                                                 
                                                 
                                                 
                                                 
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <div className="on-hover-active-toggle-img text-on-hover-active-green">
                                                    <UserCircleGearIcon className="HW18 active d-none" />
                                                    <UserCircleGearIcon className="HW18" />
                                                </div>
                                            </a>
                                            <FollowersDropdown />
                                        </div>
                                        <div
                                            className={`comman_action_icon me-2`}
                                        >
                                            <div className="white-border-box d-flex align-items-center h25w25 action_icon with_bg border-0">
                                            <div
                                                className="priority-checkbox text_wrap position-relative"
                                                 
                                                 
                                                 
                                                 
                                            >
                                                <input
                                                type="checkbox"
                                                className="absolute-input"
                                                id="priority"
                                                onClick={(e) => {
                                                    itemPriority(item.id, item.finished, item.description, item.priority == 1 ? 0 : 1)
                                                }}
                                                checked={item.priority == 1}
                                                />
                                                <div className="check_box" onChange={() => {
                                                }}>
                                                <HighPriorityActiveIcon className="HW18 check_span_img active" />
                                                <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`dropend d-flex align-items-center me-2`}
                                        >
                                          <UncontrolledDropdown className="w-100 d-flex">
                                              <DropdownToggle  data-toggle="dropdown" tag="span">
                                            <a href="#/"
                                            className="comman_action_icon"
                                            type="button"
                                            id="writeComMentDropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-bs-auto-close="outside"
                                             
                                             
                                             
                                             
                                            onClick={(e) => setOpenCommentTabReq(true)}
                                            >
                                            <div className="d-flex align-items-center">
                                                <div className="action_icon with_bg position-relative on-hover-active-toggle-img">
                                                <ChatCircleIcons className="HW18" />
                                                </div>
                                                <span
                                                className={`rounded-pill badge-custom p-0 ${item.unread_comment_count > 0 ? ""  : "d-none"}`}
                                                data-unread-cmt-id={item.id}
                                                >
                                                {item.unread_comment_count}
                                                </span>
                                                <span className="c-font f-12 opacity-50 text-on-hover-active-green number-counter-toggle">
                                                {items[key].comments_count.comments_count}
                                                </span>
                                            </div>
                                            </a>
                                            </DropdownToggle>
                                            <DropdownItem toggle={false} className="p-0">
                                            <CommentDropdown
                                            docType={"imageview"}
                                            data={request}
                                            key={key}
                                            openCommentTab={openCommentTabReq}
                                            setOpenCommentTab={setOpenCommentTabReq}
                                            projectId={projectId}
                                            task_id={request.id}
                                            updatedCommentCount={(count) => {
                                                item.comments_count.comments_count = count;
                                                setItems((items) => [...items, item]);
                                            }}
                                            checklistId={item.id}
                                            allComment={1}
                                            unContolList={true}
                                            />
                                             </DropdownItem>
                                          </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </li>
                                </>
                                        )
                                    })
                                    :
                                    <></>
                                }
                            </ul>
                            {
                                items.length >= (page - 1) * 5 + 3 &&
                                items.length != (page - 1) * 5 + 3 ? (
                                    <a href="#/" className="btn btn-white-03 w-100 view-more-btn" onClick={() => {  setPage(page + 1); }}>
                                        <div className="d-flex align-item-center justify-content-center">
                                            <span className=" title-fonts c-font f-12 ms-2">
                                                {_l("l_view_more_btn")} (
                                                    {page > 1
                                                    ? items.length -
                                                        ((page - 1) * 5 + 3)
                                                    : items.length - 3}
                                                    )
                                            </span>
                                            <CaretDoubleDownIcon className="HW12 mx-2" />
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                className="d-none"
                                            />
                                        </div>
                                    </a>
                                ) : (<></>) 
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showDocumentCanvas ? (
            <CommanOffCanvas
                data={selectedTask ? selectedTask : []}
                setSelectedId={setTaskId}
                selectedTaskId={taskId}
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                setRequest([]);
              }}
              docType={"request-task"}
              canvasfullHeightwithHeaderFooter={true}
              onCloseShowLeftPanelDefault={true}
              handleParentComponentAction={(type, taskData) => {
                if (task_type == 'task') {
                  if (type == 'attachmentCountUpdate') {
                    return;
                  } else if (type == 'handleSelectedTask') {
                    handleSelectedTask(taskData.task_id, taskData.flag)
                    return;
                  } else if (type == 'convertedTaskAction') {
                    handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                    return;
                  }
                } else if (task_type == 'request-task') {

                  if (type == 'convertedTaskAction') {
                    handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                    return;
                  }
                }

              }}
            />
          ) : (
            <></>
          )}
        </>
    )
}

export default RequestCard