
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { _l } from "../../../../hooks/utilities";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
import CalendarBlankIcon from "../../../../assets/icons/CalendarBlankIcon";
const LeadView = ({ projectId, clientId, location, zoomLevel, data }) => {


    const [leadTrackView, setleadTrackView] = useState(data);
    useEffect(() => {
        setleadTrackView(data);
    }, [JSON.stringify(data)]);
    return (
        <div className="d-flex flex-column flex-grow-1 p-10 py-0">
            <div className="d-flex flex-column flex-grow-1">
                <div className=" d-flex flex-column flex-grow-1">
                    <div className="comman-content-scroll-wrapper d-flex flex-column flex-grow-1">
                        <div className="comman-content-scroll d-flex flex-column flex-grow-1">
                            <div className="comman-data-table small-data-table d-flex flex-column flex-grow-1">
                                <table className="dataTable d-flex flex-column flex-grow-1">
                                {
                                            leadTrackView && leadTrackView.length ? (
                                    <thead className="bg-transparent">
                                        <tr className="bg-white-03">
                                            <th style={{ width: "3%" }}>{_l('l_id')}</th>
                                            <th style={{ width: "15%" }}>{_l('l_date')}</th>
                                            <th style={{ width: "30%" }}>{_l('l_ip_address')}</th>


                                        </tr>
                                    </thead>
                                    ) : (
                                        <></>
                                        )
                                        }
                                            
                                    <tbody className="d-flex flex-column flex-grow-1">
                                        {
                                            leadTrackView && leadTrackView.length ? (
                                                leadTrackView.map((trackView, index) => {
                                                    return (
                                                        <tr className="c-font f-12 ps-3">
                                                            <td className="">
                                                                <a href="#/" className="c-font color-white-80 f-14">{index + 1}</a>
                                                            </td>
                                                            <td className="">
                                                                <div className="white-border-box color-white-60 d-flex d-none ">
                                                                    <a href="#/" className="custom-datepicker datepicker-input w-90 m-auto ms-2 c-font ">
                                                                        <div className="d-flex align-items-center ">
                                                                        <CalendarBlankIcon className="HW16" />
                                                                            <DatePicker
                                                                                showTimeSelect
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText={`${_l('l_select_date_placeholder')}`}
                                                                                calendarStartDay={1}
                                                                            />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <a href="#/" className="c-font color-white-80 f-14 ">
                                                                    {trackView.date ? moment(trackView.date).format('MM/DD/YYYY') : ''}
                                                                </a>
                                                            </td>
                                                            <td className="">
                                                                <a href="#/" className="c-font color-white-80 f-14 text-truncate m-w150 d-block"
                                                                     
                                                                     
                                                                     
                                                                > {trackView.view_ip ? trackView.view_ip : ''}</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                            ) : (
                                                <>
                                                    <CommanPlaceholder imgType="no-tracked" mainWidth="m-auto" placeholderText = {_l("l_no_views")} />
                                                </>
                                            )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
        </div >
    );
};
export default LeadView;