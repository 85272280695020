import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l, showError, showSuccessMessage } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import Form from "react-bootstrap/Form";
import customerServices from "../../services/customer-services";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { getListOfRiskData } from "../../actions/customer";
import moment from "moment";
import TrashIcon from "../../assets/icons/TrashIcon";


const RiskListModal = ({
  show,
  handleClose,
  hideActionMenu = false,
  editData,
  isFromEdit = false,
  actionType,
  TemplateTitle = "",
  id = 0,
}) => {
  const dispatch = useDispatch();
  const { riskList } = useSelector((state) => state.customer);

  // Initial columns configuration
  const TABLE_COLUMN = [
    { title: "l_risk_zone", width: "20%", intialVal: "" , maxWidth: "min-width200"},
    { title: "l_risk_indicator", width: "10%" , intialVal: "", maxWidth: "min-width200"},
    { title: "l_rating", width: "3%" , intialVal: 0},
    { title: "l_note", width: "10%" , intialVal: ""},
    { title: "l_coefficient", width: "3%", intialVal: 0 }
  ];
  const keyMapping = {
    l_coefficient: "coefficient",
    l_note: "notes",
    l_risk_indicator: "indicator",
    l_risk_zone: "title",
    l_rating: "rating",
  };
  const keyMappingReverse = Object.fromEntries(
    Object.entries(keyMapping).map(([key, value]) => [value, key])
  );

  const staff_id = localStorage.getItem("staff_id");
  const contact_id = localStorage.getItem("contact_id");
  const client_id = localStorage.getItem("client_id");
  const provider_company_id = localStorage.getItem("currentCompanyID");

  // Initial data structure based on TABLE_COLUMN titles
  const defaultRow = TABLE_COLUMN.reduce((acc, col) => {
    acc[col.title] = col.intialVal;
    return acc;
  }, { id: "" });

  // Initialize state
  const [columns, setColumns] = useState(TABLE_COLUMN);
  const [riskData, setRiskData] = useState([{ ...defaultRow, id: 1 }]);
  const [title, setTitle] = useState("");
  const [riskScore, setRiskScore] = useState(0);
  const [loader, setLoader] = useState("");
  const [temlateOptions, setTemlateOptions] = useState([]);



  // Add new column
  const handleAddColumn = () => {
      const newColumn = { title: `Column ${columns.length - 4}`, width: "10%", intialVal: ""};
      setColumns([...columns, newColumn]);
      setRiskData(riskData.map(row => ({ ...row, [`Column ${columns.length - 4}`]: ''})));
  };

  // Update cell data
  const handleCellChange = (rowIndex, columnName, value) => {
    const newRiskData = [...riskData];
    newRiskData[rowIndex][columnName] = value;
    setRiskData(newRiskData);
  };

  // Add new row
  const handleAddRow = () => {
    setRiskData([...riskData, { ...defaultRow, id: riskData.length + 1 }]);
  };

  const handleDelete = (index) => {
    let emptyData = riskData.filter((d, i) => d.title == "");
    if (emptyData.length == riskData.length) {
    } else {
      setRiskData(riskData.filter((d, i) => i != index));
    }
  };
;
  const handleSaveData = () => {
    const data = riskData.filter((w)=> w.l_risk_zone !== "").map(row => {
      const newRow = {};
      for (const key in row) {
        if (key in keyMapping) {
          newRow[keyMapping[key]] = row[key];
        } else {
          newRow[key] = row[key];
        }
      }
      return newRow;
    });
    // Here, you can perform the actual save operation, e.g., sending the data to a server
    if (data.length > 0) {
      if (!title || title == "") {
        showError("l_title_required");
      } else {
        setLoader("save");
        try {
          let risk_score = 0;
          let totalIndicator = 0;
          let totalCoefficient = data[0].coefficient;
          data.map((d) => {
            totalIndicator = totalIndicator + Number(d.indicator);
          });
          risk_score = totalIndicator / data.length;
          risk_score = (risk_score * totalCoefficient) / 100;
          customerServices
            .addRiskData(provider_company_id, data, 0, title, risk_score.toFixed(2))
            .then((res) => {
              if (res && res.status) {
                showSuccessMessage("l_risk_data_added");
                let tempRIsk = riskList;
                let tempData = [
                  {
                    id: res.data[0],
                    title: title,
                    created_at: moment(new Date()).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    risk_detail: JSON.stringify(data),
                    score: risk_score.toFixed(2),
                  },
                ];
                let finalArr = tempRIsk.concat(tempData);
                dispatch(getListOfRiskData(finalArr));
                handleClose();
              }
            });
        } catch (error) {
          console.log(error);
        } finally {
          setLoader("");
        }
      }
    } else {
      showError("l_one_or_more_row_has_all_emply_slots");
    }
  };
  const handleSaveTemplate = () => {
    let data = riskData.filter((w)=> w.l_risk_zone !== "").map(row => {
      const newRow = {};
      for (const key in row) {
        if (key in keyMapping) {
          newRow[keyMapping[key]] = row[key];
        } else {
          newRow[key] = row[key];
        }
      }
      return newRow;
    });
    if (data.length > 0 && title != "") {
      setLoader("saveAsTemplate");
      try {
        customerServices
          .addRiskDataAsTemplate(
            data,
            staff_id,
            contact_id,
            client_id,
            provider_company_id,
            title,
            undefined
          )
          .then((res) => {
            if (res && res.status) {
              showSuccessMessage("l_risk_data_added");
              let tempRIsk = riskList;
              let finalArr = tempRIsk.concat(data);
              dispatch(getListOfRiskData(finalArr));
              handleClose();
            } else {
              showError(res.message);
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        setLoader("");
      }
    } else {
      showError("l_one_or_more_row_has_all_emply_slots");
    }
  };

  const changeTitleName = (changTitle, oldTitle, index) => {
    setColumns(columns.filter(item => item.title != "l_description").map((item, i) => {
      if (index == i) {
        item.title = changTitle;
      }
      return item;
    }));
    setRiskData(riskData.map((row) => {
        const newItem = { ...row, [changTitle] : row[oldTitle] };
        delete newItem[oldTitle];
        return newItem;
    }));
  }

  useEffect(() => {
    if (riskData && riskData.length) {
      let data = riskData.filter((w)=> w.l_risk_zone !== "").map(row => {
        const newRow = {};
        for (const key in row) {
          if (key in keyMapping) {
            newRow[keyMapping[key]] = row[key];
          } else {
            newRow[key] = row[key];
          }
        }
        return newRow;
      });
      if (data && data.length) {
        let risk_score = 0;
        let totalIndicator = 0;
        let totalCoefficient = data[0].coefficient;
        data.map((d) => {
          totalIndicator = totalIndicator + Number(d.indicator);
        });
        risk_score = totalIndicator / data.length;
        risk_score = (risk_score * totalCoefficient) / 100;
        setRiskScore(risk_score.toFixed(2));
      }
    }
  }, [JSON.stringify(riskData)]);

  useEffect(() => {
    if (editData) {
    let newCols = []
    let initialData = editData.map((item, index) => {
        let newItem = { ...defaultRow };
        Object.keys(item).forEach(key => {
          if (keyMappingReverse[key]) {
            newItem[keyMappingReverse[key]] = item[key];
          } else {
            if(index === 0 && key !== "id") {
              newCols.push({ title: key, width: "5%", intialVal: "" })
            }
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
      setColumns([...columns, ...newCols]);
      setRiskData(initialData)
    }
  }, [editData])
  
  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="xl"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <div className="d-flex justify-content-between align-items-center w-100 pe-20pximp">
              <Modal.Title>
                <Form.Group className="col-xl-12 c-input-box position-relative">
                  <Form.Control
                    className="p-1"
                    placeholder={`${_l("l_template_title")}`}
                    type="text"
                    name="risk_zone"
                    value={title || TemplateTitle}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Modal.Title>
              <div className="d-flex">
                <span className="me-2 fw-semibold">{_l("l_risk_score")} :</span>
                <span>{riskScore > 0 ? riskScore : 0}</span>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="with-overflow pt-0 minh60vh">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="comman_vertical_btn_h vertical_scroll120 comman-white-box-shadow light-theme-white-bg">
                <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
             
                    <table className="dataTable text-center overflow-x-hidden">
                    <thead className="comman-white-box-shadow bgspacelisting">
                      <tr>
                        {columns &&
                          columns.filter(item => item.title != "l_description").map((col, i) => {
                            return (
                              <>
                                <th
                                  style={{ width: `${col.width}` }}
                                  className="text-start"
                                  key={i}
                                >
                                  {["l_risk_zone", "l_risk_indicator", "l_rating","l_note", "l_coefficient"].includes(col.title) ? 
                                    _l(col.title)
                                    :
                                    <Form.Group className="col-xl-12 c-input-box position-relative">
                                      <Form.Control
                                        className="p-1 c-font f-12 fw-semibold"
                                        placeholder={_l("l_column_name")}
                                        type="text"
                                        name="risk_zone"
                                        value={col.title}
                                        onChange={(e) => {
                                          changeTitleName(e.target.value, col.title, i)
                                        }}
                                      ></Form.Control> 
                                    </Form.Group>}
                                </th>
                              </>
                            );
                          })}
                          
                        
                          <th style={{ width: "2%" }} className="text-center">
                            {_l("l_action")}
                          </th>
                        
                      </tr>
                    </thead>
                      <tbody>
                        {riskData.map((row, rowIndex) => (
                          <>
                          <tr className="border-bottom-0" key={row.id}>
                            {columns.filter(item => item.title != "l_description").map((col, colIndex) => (
                              <td className={`title-fonts text-truncate text-start  p-2 1 ${col.maxWidth ? col.maxWidth : "max-width75  min-width100" }`}>
                                <div className="name-with-progress"
                                    onBlur={() => {
                                      if (
                                        riskData.filter((d) => d.l_risk_zone === "")
                                          .length == 0
                                      ) {
                                        handleAddRow();
                                      }
                                    }}>
                              <Form.Group className="col-xl-12 c-input-box position-relative">
                                <Form.Control
                                  className="p-1"
                                  placeholder={`${_l(col.title)}`}
                                  type={col.intialVal !== "" && col.intialVal >= 0 ? "number" : "text"}
                                  name="risk_indicator"
                                  value={row[col.title]}
                                  onChange={(e) => { 
                                    if (col.intialVal !== "" && (e.target.value < 0 || e.target.value > 100)) {
                                      showError(`${_l("l_range")} 0-100`);
                                    } else {
                                      handleCellChange(rowIndex, col.title, e.target.value) 
                                    }
                                  }}
                                  max={100}
                                  min={0}
                                ></Form.Control>
                              </Form.Group>
                              </div>
                            </td>
                            ))}
                             <td className="p-2">
                                      <div className="align-items-center comman_action_icon d-flex justify-content-center">
                                        <a
                                          href="#/"
                                          className="dropdown-item custom-assignees custom-class-active  w-100"
                                          onClick={() => handleDelete(rowIndex)}
                                        >
                                          <div className="c-list-icon">
                                            <TrashIcon
                                              className="HW20"
                                            />
                                          </div>
                                        </a>
                                      </div>
                                    </td>
                          </tr>
                          </>

                        ))}
                      </tbody>
                    </table>
                                 
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end ">
           
            
              <div className="d-flex align-items-end justify-content-end gap10">
              <Button
                  variant=""
                  className="  btn-white-05"
                  size="sm"
                  onClick={() => {
                    handleAddColumn();
                  }}
                  disabled={!loader == "" ? 1 : 0}
                >
                  {_l("l_add_new_column")}
              </Button>
                <UncontrolledDropdown setActiveFromChild={true}>
                  <DropdownToggle className="border-0 p-0">
                    <Button variant="" className="btn-white-05" size="sm">
                      {_l("l_import_template")}
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu className="w-200 ">
                    {temlateOptions.length > 0 ? (
                      temlateOptions.map((item) => {
                        return (
                          <a
                            href="#/"
                            className="dropdown-item custom-assignees custom-class-active hr_sepretr w-100"
                            onClick={() => {
                              if (item.details.length > 0) {
                                const secondLastIndex = riskData.length - 1;
                                const dataArr = [...riskData];
                                dataArr.splice(
                                  secondLastIndex,
                                  0,
                                  ...item.details
                                );
                                setRiskData(dataArr);
                              }
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div className="ps-2 w100minus40 text_wrap">
                                {item.title}
                              </div>
                            </div>
                          </a>
                        );
                      })
                    ) : (
                      <CommanPlaceholder placeholderText={_l("l_no_data")} />
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => {
                  let data = riskData.filter((d) => d.title !== "");
                  if (data.length > 0) {
                    handleSaveTemplate();
                  } else {
                    showError("l_one_or_more_row_has_all_emply_slots");
                  }
                }}
                disabled={!loader == "" ? 1 : 0}
              >
                {loader === "saveAsTemplate"
                  ? _l("l_please_wait")
                  : _l("l_save_as_template")}
                {loader === "saveAsTemplate" ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    className={`ms-2 `}
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
              </Button>
        
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                switch (actionType) {
                  case "edit":
                    break;
                  default:
                    handleSaveData();
                    break;
                }
              }}
              disabled={!loader == "" ? 1 : 0}
            >
              {loader === "save" ? _l("l_please_wait") : _l("l_save")}
              {loader === "save" ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};

export default RiskListModal;
