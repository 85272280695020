import React from 'react';
 

function NoPresentSubscription(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="98" height="94" viewBox="0 0 98 94" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M60.0808 78.9634H52.1744C36.2315 78.9634 20.2936 78.9334 4.35572 78.9884C2.0003 78.9884 0.990072 78.2583 1.00007 75.7628C1.06509 57.7596 1.46518 37.4709 1.40017 19.4826V18.5574C28.8302 18.6124 62.3412 18.2324 89.7712 18.1724L89.8063 18.3224C89.7162 28.7393 89.8062 49.5331 89.7262 59.955"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M59.6896 74.3875C59.675 78.0489 60.745 81.6325 62.7645 84.6866C64.784 87.7407 67.6626 90.1284 71.0373 91.5485C74.4121 92.9687 78.1318 93.3577 81.7273 92.6666C85.3229 91.9754 88.6332 90.235 91.2409 87.6649C93.8487 85.0948 95.637 81.81 96.3802 78.2249C97.1235 74.6397 96.7885 70.9148 95.4175 67.5198C94.0465 64.1248 91.7009 61.2118 88.6765 59.1481C85.652 57.0845 82.0842 55.9627 78.4231 55.9241C75.9731 55.8781 73.5386 56.3223 71.2626 57.2305C68.9867 58.1387 66.9153 59.4926 65.17 61.2127C63.4248 62.9328 62.0409 64.9843 61.0998 67.2468C60.1586 69.5093 59.6792 71.9371 59.6896 74.3875V74.3875Z"  strokeMiterlimit="10" />
				<path d="M4.79102 31.8003H85.9308V25.144H4.79102V31.8003Z"  strokeMiterlimit="10" />
				<path d="M4.75 40.9217H15.0169V36.0908H4.77503L4.75 40.9217Z"  strokeMiterlimit="10" />
				<path d="M74.9883 64.0557V77.5431L81.4695 84.8694"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M1.01562 15.1121C1.01562 12.7567 1.01562 10.6663 1.01562 8.57589C1.06563 3.54498 3.01597 1.49961 7.93186 1.48961C22.6345 1.46961 37.3372 1.46961 52.0399 1.48961C62.3218 1.48961 72.6019 1.48961 82.8805 1.48961C87.5563 1.48961 89.6568 3.59998 89.7468 8.33584C89.7868 10.5162 89.7468 12.6966 89.7468 15.0921L1.01562 15.1121Z"  strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default NoPresentSubscription