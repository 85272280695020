import React from "react";
const ProgressBarSmallLoader = ({height, width}) => {
  return (
    <>
          <div className={`w-${width} h-${height} d-flex`}>
            <div className="loader-video">
              <img height={height} width={width} src="https://i0.wp.com/www.inspirefm.org/wp-content/themes/onair2-child/images/loader.gif?w=1170&ssl=1" alt="https://i0.wp.com/www.inspirefm.org/wp-content/themes/onair2-child/images/loader.gif?w=1170&ssl=1" />
            </div>
          </div>
    </>
  );
};

export default ProgressBarSmallLoader;