import React, { useState } from "react";
import MyLeadsList from "../components/MyLead/MyLeadsList";

const MyLeads = () => {
  const [projectId] = useState(localStorage.getItem("selectedOffice"));

  return (
    <>
      <MyLeadsList projectId={projectId} />
    </>
  );
};

export default MyLeads;
