import apiService from "./api-service/api-service";
import moment from "moment";
const API_URL = process.env.REACT_APP_API_URL;

  const getPrimary_Googlecalendar = (
    data
  ) => {
  
      const endPoint = API_URL + "mod=google_calendar&act=calendarid";
      let form = new FormData();
        form.append("provider", data.provider);
        form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

const setCalendarModals = (
    calendar_type = 0
  ) => {
    const endPoint = API_URL + "mod=google_calendar&act=createAuthUrl";
    let form = new FormData();
    form.append("calendar_type", calendar_type);
    form.append("project_id", localStorage.getItem("selectedOffice"));
    return apiService(endPoint, "POST", true, form);
  };
  
  const ExchangeCode = (
    data
  ) => {
    const endPoint = API_URL + "mod=google_calendar&act=exchangecode";
    let form = new FormData();
    form.append("code", data.code);
    form.append("provider", data.provider);
    form.append("project_id", data.project_id);
    form.append("is_calendar_sync_for_all", data.is_calendar_sync_for_all);
    return apiService(endPoint, "POST", true, form);
  };  

  const CalendarLists = (
    provider,calendar_id=''
  ) => {

    const endPoint = API_URL + "mod=google_calendar&act=calendarlist";
    let form = new FormData();
    form.append("provider", provider);
    form.append("calendar_id", calendar_id);
    form.append("project_id", localStorage.getItem("selectedOffice"));
    return apiService(endPoint, "POST", true, form);
  }; 

  const revoke_Googlecalendar = (
    data
    ) => {
      
        const endPoint = API_URL + "mod=google_calendar&act=revokeToken";
        let form = new FormData();
        form.append("provider", data.provider);
        form.append("project_id", data.project_id);
        return apiService(endPoint, "POST", true, form);
  };

  const get_Googlecalendar_events = (
    calendarId,startDate,endDate
  ) => {
      const endPoint = API_URL + "mod=google_calendar&act=get_events";
      let form = new FormData();
          form.append("provider", 'google_calendar');
          form.append("google_calendar_ids", calendarId );
          form.append("from_date", startDate);
          form.append("to_date", endDate);
          form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

    const get_single_event = async(calendar_id, event_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=get_event_detail_by_eventid";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('event_id', event_id);
      form.append("provider", 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };
  
    const assignee_task_of_google = async(event_id, calendar_id, assignee_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=assignee_task_of_google";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('task_id', event_id);
      form.append('assignee_id', assignee_id);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

    const create_meeting = async(event_id, calendar_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=create_meeting";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('event_id', event_id);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };
    
    const update_google_events = async(taskId,taskName,startDate,dueDate,calendar_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=update_events";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('event_id', taskId);
      form.append('taskName', taskName);
      form.append('startDate', moment(startDate).format("YYYY-MM-DD HH:MM:SS"));
      form.append('dueDate', moment(dueDate).format("YYYY-MM-DD HH:MM:SS"));
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };
    
    const google_event_insert_into_task = async(selected_office,type,taskId,calendar_id,status=1, priority=0,pin=0, meeting_link= "") => {
      const endPoint = API_URL + "mod=google_calendar&act=google_event_insert_into_task";
      let form = new FormData();
      form.append('selected_office', selected_office);
      form.append('type', type);
      form.append('calendar_id', calendar_id);
      form.append('event_id', taskId);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      form.append('status', status);
      form.append('priority', priority);
      form.append('pin', pin);
      form.append('meeting_link', meeting_link);
      
      return apiService(endPoint, "POST", true, form);
    };

    const update_tasks_of_google_calendar = async(taskId,calendar_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=update_tasks_of_google_calendar";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('event_id', taskId);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

    const getMeetingLink = async(taskId, calendar_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=get_meeting_link";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('event_id', taskId);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

    const syncronize_task_event = async(event_id,calendar_id,task_id,project_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=get_assignee_of_sync_task";
      let form = new FormData();
      form.append('calendar_id', calendar_id);
      form.append('task_id', event_id);
      form.append('provider', 'google_calendar');
      form.append('task_id', task_id);
      form.append('project_id', project_id);
      return apiService(endPoint, "POST", true, form);
    };

    const zoomMeetingLinkAddedIntoTask = async(task_id, meeting_link, calendar_id = "") => {
      const endPoint = API_URL + "mod=google_calendar&act=create_event_with_zoom_meeting";
      let form = new FormData();
      form.append('task_id', task_id);
      form.append('meeting_link', meeting_link);
      form.append('calendar_id', calendar_id);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

    const MsTeamMeetingLinkAddedIntoTask = async(task_id, meeting_link, calendar_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=create_event_with_ms_meeting";
      let form = new FormData();
      form.append('task_id', task_id);
      form.append('meeting_link', meeting_link);
      form.append('calendar_id', calendar_id);
      form.append('provider', 'google_calendar');
      form.append("project_id", localStorage.getItem("selectedOffice"));
      return apiService(endPoint, "POST", true, form);
    };

    const is_googlecalendar_connected = async(project_id) => {
    
      const endPoint = API_URL + "mod=google_calendar&act=is_googlecalendar_connected";
      let form = new FormData();
      form.append("project_id", project_id);
      return apiService(endPoint, "POST", true, form);
    };

    const change_topic_status = async(key, status, project_id, main_key) => {
      const endPoint = API_URL + "mod=google_calendar&act=change_topic_status";
      let form = new FormData();
      form.append('key', key);
      form.append('status', status);
      form.append('project_id', project_id);
      form.append('main_key', main_key);
      return apiService(endPoint, "POST", true, form);
    };
    const refresh_request = async(startDate, endDate, calendar_id, project_id, taskID) => {
      const endPoint = API_URL + "mod=google_calendar&act=refresh_request";
      let form = new FormData();
      form.append('startDate', startDate);
      form.append('endDate', endDate);
      form.append('calendar_id', calendar_id);
      form.append('project_id', project_id);
      form.append('taskID', taskID);
      return apiService(endPoint, "POST", true, form);
    };
    const setAssignee = async(
      task_id,
      name,
      email,
      calendar_id,
      project_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=set_assignee";
      let form = new FormData();
      form.append('task_id', task_id);
      form.append('name', name);
      form.append('email', email);
      form.append('calendar_id', calendar_id);
      form.append('project_id', project_id);
      return apiService(endPoint, "POST", true, form);
    };

    const createAuthUrl = async() => {
      const endPoint = API_URL + "mod=google_calendar&act=createAuthUrl";
      let form = new FormData();
      return apiService(endPoint, "POST", true, form);
    };

    const createEvent = async(
      task_id, project_id) => {
      const endPoint = API_URL + "mod=google_calendar&act=create_event";
      let form = new FormData();
      form.append('task_id', task_id);
      form.append('project_id', project_id);
      return apiService(endPoint, "POST", true, form);
    };

export default { createEvent, createAuthUrl,setAssignee,get_Googlecalendar_events, setCalendarModals, ExchangeCode, CalendarLists, getPrimary_Googlecalendar, revoke_Googlecalendar,get_single_event,create_meeting, update_google_events, google_event_insert_into_task,update_tasks_of_google_calendar, assignee_task_of_google, getMeetingLink,syncronize_task_event,zoomMeetingLinkAddedIntoTask ,MsTeamMeetingLinkAddedIntoTask, is_googlecalendar_connected, change_topic_status,refresh_request };
