import React from "react";
import { _l } from "../../../hooks/utilities";
import InvoiceList from "../../InvoiceList";
const ClientInvoiceTab = ({data}) => {

    return (
        <div>
            <InvoiceList pageName="customer" clientid={data.id}/>
        </div>
    )
}
export default ClientInvoiceTab;