import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { _l } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import EyeIcon from "../../assets/icons/EyeIcon";

const CreditNoteModal = ({ show, handleClose, project_id, pageName, spacecontent, data  }) => {

  const [loader, setLoader] = useState(false)
   const {daterangeFilterApplied} =  useSelector(state => state.customer);


  const handleSave = () =>{
    setLoader(true);

  }
  


  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_credit_note_creation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0 ">
            <div className="row mb-3">
                <div className="d-flex align-items-center justify-content-between col-5">
                    <div className="c-font f-14 color-white-60 title-fonts">{_l("l_no_of_not_completed_task")}</div>
                    <span>:</span>
                </div>
                <div className="d-flex align-items-center justify-content-between col-7">
                <div className="c-font f-14 color-white title-fonts fw-semibold">{data.total_missing_tasks}</div>
                <div className="comman_action_icon">
                  <a href="#/" className="action_icon h32w32 with_bg">
                    <EyeIcon className="HW18" />
                    </a>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="d-flex align-items-center justify-content-between col-5">
                    <div className="c-font f-14 color-white-60 title-fonts">{_l("l_date_duration_of_not_completed_task")}</div>
                    <span>:</span>
                </div>
                <div className="d-flex align-items-center justify-content-between col-7">
                <div className="c-font f-14 color-white title-fonts fw-semibold">{daterangeFilterApplied ? `${moment(localStorage.getItem("fromDate")).format("DD/MM/YYYY")} - ${moment(localStorage.getItem("toDate")).format("DD/MM/YYYY")}` : moment(localStorage.getItem("selectedDate")).format("DD/MM/YYYY") }</div>
               
                </div>
            </div>
            <div className="row mb-3">
                <div className="d-flex align-items-center justify-content-between col-5">
                    <div className="c-font f-14 color-white-60 title-fonts">{_l("l_per_task_amount")}</div>
                    <span>:</span>
                </div>
                <div className="d-flex align-items-center justify-content-between col-7">
                <div className="c-font f-14 color-white title-fonts fw-semibold">€ {data.total_missing_tasks > 0 ? (Number(data.total_credit.replace(",", ""))/ Number(data.total_missing_tasks)).toFixed(2) : 0}</div>
                
                </div>
            </div>
            <div className="row mb-3">
                <div className="d-flex align-items-center justify-content-between col-5">
                    <div className="c-font f-14 color-white-60 title-fonts">{_l("l_total")}</div>
                    <span>:</span>
                </div>
                <div className="d-flex align-items-center justify-content-between col-7">
                <div className="c-font f-14 color-white title-fonts fw-semibold">€ {data.total_credit}</div>
               
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="primary" size="sm" onClick={() => {
              if (!loader) {
                handleSave()
              }
            }}
              disabled={loader}
            >
              {loader
                ? _l('l_please_wait')
                : _l("l_raise_credit_note")
              }
              {loader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CreditNoteModal;

