import React from 'react';
 

function NoSearch(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>


			<svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M61.2072 58.4505C74.8365 42.9503 71.6391 20.3668 57.1499 8.66527C43.319 -2.51565 23.0664 -1.34684 10.7402 11.3992C4.71755 17.607 1.24582 25.8502 1.01256 34.4963C0.779305 43.1424 3.80153 51.5608 9.48069 58.0844C12.4873 61.5083 16.1399 64.3053 20.2293 66.3152C24.3188 68.325 28.7646 69.5081 33.3121 69.7967C37.8596 70.0853 42.4193 69.4737 46.73 67.997C51.0407 66.5203 55.0176 64.2075 58.4329 61.1911L63.0309 65.9973C62.5607 66.4104 61.8184 67.0553 61.093 67.7169C60.0249 68.691 59.9544 69.6985 61.0056 70.7397C65.8018 75.5224 70.5375 80.3622 75.4042 85.0744C76.0365 85.6957 76.7855 86.1856 77.6081 86.516C78.4307 86.8464 79.3106 87.0107 80.1969 86.9995C81.0833 86.9882 81.9587 86.8017 82.7726 86.4505C83.5866 86.0994 84.3229 85.5906 84.9393 84.9535C85.5906 84.3326 86.1111 83.5878 86.4702 82.7628C86.8293 81.9377 87.0198 81.0492 87.0305 80.1495C87.0411 79.2498 86.8717 78.357 86.5322 77.5237C86.1927 76.6905 85.6899 75.9335 85.0535 75.2974C80.3716 70.4811 75.5721 65.779 70.833 61.0198C69.8053 59.9853 68.8178 59.9249 67.8103 60.9963C67.1385 61.7218 66.4668 62.464 65.8488 63.1324L61.2072 58.4505Z" strokeMiterlimit="10" />
				<path d="M61.4555 35.3397C61.4824 21.173 49.9555 9.52854 35.7653 9.38412C21.575 9.2397 9.67197 21.0957 9.7425 35.3095C9.7945 42.1447 12.5365 48.6844 17.3749 53.5127C22.2133 58.341 28.7587 61.0695 35.594 61.1072C49.6667 61.171 61.4253 49.4595 61.4555 35.3397Z" strokeMiterlimit="10" />
				<path d="M26.5137 26.2178L44.6806 44.2705" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M44.6806 26.2178L26.5137 44.2705" strokeLinecap="round" strokeLinejoin="round" />
			</svg>

		</React.Fragment>
	)
}

export default NoSearch