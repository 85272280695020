import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleFeatureRestrictionModal } from "../../actions/general-actions/general-actions";
import { _l } from "../../hooks/utilities";
import { useLocation, useNavigate } from "react-router-dom";

const NAVIGATION_REQURED_PAGES = ["explore-features"];
const NAVIGATION_DESTINATIONS = {"explore-features": "/"};

const FeatureRestrictionModal = () => {
  const { showFeatureRestrictonModal, featureRestrictionMessage } = useSelector(
    (state) => state.generalReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pathName = location.pathname.replace("/", "");

  const userType = localStorage.getItem("user_type");
  const isExternalUser = localStorage.getItem("is_external_user");

  const handleUpgradePlan = () => {
    if(userType === "operator")
    {
      const element = document.getElementById("operator-upgrade-plan");
      if(element)
      {
        element.click();
        dispatch(toggleFeatureRestrictionModal(false));
      }
    }
    else {
      navigate("/premiumsubscription");
      dispatch(toggleFeatureRestrictionModal(false));
    }
  };

  const handleCloseModal = () => {
    dispatch(toggleFeatureRestrictionModal(false));
    if(NAVIGATION_REQURED_PAGES.includes(pathName))
    {
        navigate({NAVIGATION_DESTINATIONS: pathName});
    }
  };

  useEffect(() => {
    if(isExternalUser > 0)
    {
      handleCloseModal();
    }
  }, []);
  

  return (
    <Modal
      show={showFeatureRestrictonModal}
      onHide={handleCloseModal}
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-transparent">
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="c-font f-24 fw-bold">{_l("l_upgrade_plan")}</div>
        <div className="c-font f-18 mt-4">
        <div className="mb-2">{_l("l_upgrade_plan_feature_1")}</div>
        <div className="mb-2">{_l("l_upgrade_plan_feature_2")}</div>
        <div className="mb-2">{featureRestrictionMessage}</div>
        </div>
        <div className="d-flex justify-content-center my-4">
          <Button
            className="me-2"
            onClick={handleUpgradePlan}
          >
            {_l("l_upgrade_now")}
          </Button>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
          >
            {_l("l_upgrade_later")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeatureRestrictionModal;
