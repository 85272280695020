import React, { useEffect, useState } from "react";
import Estimate from "../../tabs/Estimate";
import { _l } from "../../../../hooks/utilities";
import SignatureConfirmationIdentity from "../../../Modals/SignatureConfirmationIdentity";
import AddEstimateModal from "../../../Modals/AddEstimateModal";

const Estimates = ({ data, handleEstimateStatus, isEditable, setIsEditable, setSaveAndDraftEstimate, saveAndDraftEstimate, seteditEstimateSubmit, editEstimateSubmit, setData }) => {

  const [showSignaturePad, setShowSignaturePad] = useState(false);

  useEffect(() => {
    if (isEditable) {
      localStorage.setItem("estimateData", JSON.stringify(data));
    }
  }, [JSON.stringify(data), isEditable])
  

  return (
    <>
    {isEditable ? 
      <>
      <AddEstimateModal isModal ={false} setIsEditable = {setIsEditable} setSaveAndDraftEstimate = {setSaveAndDraftEstimate} saveAndDraftEstimate={saveAndDraftEstimate} seteditEstimateSubmit={seteditEstimateSubmit} editEstimateSubmit={editEstimateSubmit} setData ={setData}/>
      </> 
      :
     <>
      <div className="tab-wrapper_main pb-3 res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <div className="comman-tab-wrapper p-15 pt-0 pb-0 ps-0">
            <div className="d-flex align-items-center">
              <div className="tab-wrapper active">
                <a href="#/" className="tab-name m-0">{_l("l_basic_details")}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hiiden-auto comman_vertical_btn_h p-15">
        <Estimate data={data} />
      </div>
     </>}
      {showSignaturePad ? (
        <SignatureConfirmationIdentity
          show={showSignaturePad}
          external={true}
          projectId={0}
          estimate={data}
          hash={data.hash}
          handleClose={() => {
            setShowSignaturePad(false);
          }}
          callBack={() => {
            handleEstimateStatus("Accepted");
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default Estimates;
