
import React from 'react';
 

function UploadSimpleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5.76562 5.125L8.39062 2.5M8.39062 2.5L11.0156 5.125M8.39062 2.5V9.5M13.8906 9.5V13C13.8906 13.1326 13.8379 13.2598 13.7442 13.3536C13.6504 13.4473 13.5232 13.5 13.3906 13.5H3.39062C3.25802 13.5 3.13084 13.4473 3.03707 13.3536C2.9433 13.2598 2.89062 13.1326 2.89062 13V9.5"/>
</svg>

		</React.Fragment>
	)
}

export default UploadSimpleIcon