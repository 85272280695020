import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { showMessage } from "../../actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { updateSpaceContent } from "../../actions/customer";
import Spinner from "react-bootstrap/Spinner";

const OpenAi = ({ show, handleClose, project_id, pageName, spacecontent  }) => {

  const [description, setDescription] = useState(spacecontent ? spacecontent : "")
  const [loader, setLoader] = useState(false)
  const staff_id = localStorage.getItem("staff_id");
  const dispatch = useDispatch();

  const spaceState = useSelector((state) => state.customer.spaces);

  const handleSave = () =>{
    setLoader(true);
    customerServices.openAiSpace(project_id, staff_id, description ).then((res) => {
      if (res.status) {
        setLoader(false);
        let index = spaceState.findIndex((spaces)=> spaces.project_id == project_id) 
        if (index > -1) {
          dispatch(updateSpaceContent([...spaceState, spaceState[index].content = description]))  
        }
        dispatch(showMessage("sucess", _l("l_sucess"), _l(res.message)));
        handleClose();
      }else {
        setLoader(false);
        dispatch(
          showMessage("unsucess", _l("l_error"), res.message)
        );
      }
    })
  }
  


  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_open_ai")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l('l_space_description')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder={`${_l("l_type_description_here")}`}
                    type="text"
                    name=""
                    value={description}
                    onChange={(e)=>{setDescription(e.target.value)}}
                  ></Form.Control>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" size="sm" onClick={() => {
              if (!loader) {
                handleSave()
              }
            }}
              disabled={loader}
            >
              {loader
                ? _l('l_please_wait')
                : _l('l_save')}
              {loader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default OpenAi;
