// src/vapiService.j
import apiService from './api-service/api-service';


const API_URL = process.env.REACT_APP_API_URL;
const MYR_BASE_URL = process.env.REACT_APP_MYR_WEB_URL;
const CHAT_WEB_BASE_URL = process.env.REACT_APP_CHAT_WEB_BASE_URL;
const user_type = localStorage.getItem("user_type");

const createAssistant = (profileData) => {
  const endPoint = API_URL + "mod=task&act=create_vapi_aasistant";
  let form = new FormData();
  let topics = profileData.selectedProviders.concat(profileData.selectedTenets, profileData.selectedEquipments)
  form.append("first_name", profileData.firstName);
  form.append("last_name", profileData.lastName);
  form.append("email",profileData.email );
  form.append("mobile_key", profileData.mobile_key);
  form.append("mobile_number", profileData.phoneNumber);
  form.append("language", profileData.language);
  // form.append("voice_id", profileData.voice);

  form.append("gender", profileData.gender);
  // form.append("project_ids", JSON.stringify(profileData.spaces));
  
  // form.append("topic", JSON.stringify(topics));

  if (profileData.firstMessage && profileData.firstMessage != "") {
    form.append("first_message", profileData.firstMessage.trim());
  }

  if (profileData.generalContext && profileData.generalContext != "") {
    form.append("system_prompt", profileData.generalContext.trim());
  }
  

  if (profileData.profilePicture) {
    Array.from(profileData.profilePicture).map((file, index) => {
      form.append(`file[${index}]`, file);
    });
  }
  // if (profileData.topics.length > 0) {
  //   Array.from(profileData.topics).map((t, index) => {
  //     form.append(`topic[${index}]`, t);
  //   });
  // }

  return apiService(endPoint, "POST", true, form);
}

const getAssistantLanguage = () =>{
  const endPoint = API_URL + " mod=general&act=ai_assistant_language_list";
  let form = new FormData()
  return apiService(endPoint, "POST", true, form);
 
}

const getAssistantVoices = () =>{
  const endPoint = API_URL + "mod=general&act=ai_voice_list";
  let form = new FormData()
  return apiService(endPoint, "POST", true, form);
 
}

const getAssistantMobileNumbers = () =>{
  const endPoint = API_URL + "mod=vapi_ai&act=get_vapi_mobile_number";
  let form = new FormData()
  return apiService(endPoint, "POST", true, form);
 

}
const getAssistantProfileData = () =>{
  const endPoint = API_URL + "mod=task&act=get_client_assistant";
  let form = new FormData()
  return apiService(endPoint, "POST", true, form);
 
}

const setAssistantPrompt = (englishPrompt , frenchPrompt) =>{
  const endPoint = API_URL + "mod=user&act=update_open_ai_prompts";
  let form = new FormData()
  form.append("system_prompt_english", englishPrompt.trim());
  form.append("system_prompt_french", frenchPrompt.trim());

  return apiService(endPoint, "POST", true, form);

  
}

const getAssistantPrompt = () =>{
  const endPoint = API_URL + "mod=user&act=get_open_ai_prompt";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}



export default {
  createAssistant,
  getAssistantLanguage,
  getAssistantVoices,
  getAssistantMobileNumbers,
  getAssistantProfileData,
  setAssistantPrompt,
  getAssistantPrompt
}
