import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";

const ExternalRoutes = () => {
  const authToken = localStorage.getItem("accessToken");
  const location = useLocation();

  return (
    <React.Fragment>
      {!authToken ? (
        <Outlet />
      ) : (
        // For now redirect to "/" but later on redirect conditonally based on counter part of external page
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </React.Fragment>
  );
};

export default ExternalRoutes;
