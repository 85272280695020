import chatApiService from "./api-service/chat-api-service";
const REACT_APP_CHAT_BASE_URL = process.env.REACT_APP_CHAT_BASE_URL;

const fetchAuthToken = async (email, myrUserId, myrUserType) => {
  const endPoint =
    REACT_APP_CHAT_BASE_URL + "/api/user/get-authorization-token";
  const staffOrContactId =
    localStorage.getItem("user_type") == "contact"
      ? localStorage.getItem("contact_id")
      : localStorage.getItem("staff_id");
  let user_type = localStorage.getItem("user_type");
  user_type = user_type === "contact" ? "client" : "staff";
  var reqData = {
    email: email,
    myrUserId: staffOrContactId,
    myrUserType: user_type,
    myrUserType: user_type,
  };
  return chatApiService(endPoint, reqData);
};

export default {
  fetchAuthToken,
};
