import React from 'react';
 

function FindJobIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons`}>
<path d="M26.25 45C36.6053 45 45 36.6053 45 26.25C45 15.8947 36.6053 7.5 26.25 7.5C15.8947 7.5 7.5 15.8947 7.5 26.25C7.5 36.6053 15.8947 45 26.25 45Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.5085 39.5088L52.4999 52.5002" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38.484 24.1497C38.484 25.2804 37.5674 26.197 36.4368 26.197C35.876 26.197 33.8523 26.2061 33.8523 26.2061V22.1025H36.4368C37.5674 22.1025 38.484 23.0191 38.484 24.1497Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.2828 28.594C39.2828 29.9167 38.2106 30.9889 36.888 30.9889C36.2321 30.9889 33.8523 30.9994 33.8523 30.9994V26.1992H36.888C38.2106 26.1992 39.2828 27.2714 39.2828 28.594Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.5483 30.9998C28.0052 30.9998 29.9969 29.0081 29.9969 26.5512C29.9969 24.0943 28.0052 22.1025 25.5483 22.1025C23.0914 22.1025 21.0996 24.0943 21.0996 26.5512C21.0996 29.0081 23.0914 30.9998 25.5483 30.9998Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3887 29.0719C13.3887 30.1366 14.2517 30.9997 15.3164 30.9997C16.3811 30.9997 17.2441 30.1366 17.2441 29.0719V22.1025" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

			{/* <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" >
				<g clip-path="url(#clip0_2321_6421)">
					<path d="M26.25 45C36.6053 45 45 36.6053 45 26.25C45 15.8947 36.6053 7.5 26.25 7.5C15.8947 7.5 7.5 15.8947 7.5 26.25C7.5 36.6053 15.8947 45 26.25 45Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M39.5085 39.5088L52.4999 52.5002" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M38.484 24.1497C38.484 25.2804 37.5674 26.197 36.4368 26.197C35.876 26.197 33.8523 26.2061 33.8523 26.2061V22.1025H36.4368C37.5674 22.1025 38.484 23.0191 38.484 24.1497Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M39.2828 28.594C39.2828 29.9167 38.2106 30.9889 36.888 30.9889C36.2321 30.9889 33.8523 30.9994 33.8523 30.9994V26.1992H36.888C38.2106 26.1992 39.2828 27.2714 39.2828 28.594Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M25.5484 30.9998C28.0053 30.9998 29.997 29.0081 29.997 26.5512C29.997 24.0943 28.0053 22.1025 25.5484 22.1025C23.0915 22.1025 21.0997 24.0943 21.0997 26.5512C21.0997 29.0081 23.0915 30.9998 25.5484 30.9998Z" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M13.3887 29.0719C13.3887 30.1366 14.2517 30.9997 15.3164 30.9997C16.3811 30.9997 17.2441 30.1366 17.2441 29.0719V22.1025" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<clipPath id="clip0_2321_6421">
						<rect width="60" height="60" fill="currentColor" />
					</clipPath>
				</defs>
			</svg> */}

		</React.Fragment>
	)
}

export default FindJobIcon