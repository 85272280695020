
import React from 'react';

function NetworkIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(	
        <React.Fragment>
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M27 30V21M24 35L15 38M39 38L30 35M11.625 44H7.375C3.85419 44 1 47.0221 1 50.75V53H18V50.75C18 47.0221 15.1458 44 11.625 44ZM29.25 12H24.75C21.0221 12 18 15.0221 18 18.75V21H36V18.75C36 15.0221 32.9779 12 29.25 12ZM46.625 44H42.375C38.8542 44 36 47.0221 36 50.75V53H53V50.75C53 47.0221 50.1458 44 46.625 44ZM15 38.5C15 41.5375 12.5375 44 9.5 44C6.46246 44 4 41.5375 4 38.5C4 35.4625 6.46246 33 9.5 33C12.5375 33 15 35.4625 15 38.5ZM32.5 6.5C32.5 9.53754 30.0375 12 27 12C23.9625 12 21.5 9.53754 21.5 6.5C21.5 3.46246 23.9625 1 27 1C30.0375 1 32.5 3.46246 32.5 6.5ZM50 38.5C50 41.5375 47.5375 44 44.5 44C41.4625 44 39 41.5375 39 38.5C39 35.4625 41.4625 33 44.5 33C47.5375 33 50 35.4625 50 38.5ZM30.6992 33.5C30.6992 35.4329 29.1322 37 27.1992 37C25.2663 37 23.6992 35.4329 23.6992 33.5C23.6992 31.5671 25.2663 30 27.1992 30C29.1322 30 30.6992 31.5671 30.6992 33.5Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

		</React.Fragment>
	)
}

export default NetworkIcon