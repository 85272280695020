import React from 'react';
 

function WifiHighIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M8.39072 4.00098C6.11526 4.00098 3.93116 4.8963 2.31043 6.49347C2.11374 6.6873 1.79717 6.68498 1.60334 6.4883C1.40951 6.29161 1.41183 5.97504 1.60852 5.78121C3.41635 3.99966 5.85258 3.00098 8.39072 3.00098C10.9289 3.00098 13.3651 3.99966 15.1729 5.78121C15.3696 5.97504 15.3719 6.29161 15.1781 6.4883C14.9843 6.68498 14.6677 6.6873 14.471 6.49347C12.8503 4.8963 10.6662 4.00098 8.39072 4.00098ZM4.42732 8.61423C5.4873 7.57993 6.90963 7.00098 8.39062 7.00098C9.87162 7.00098 11.2939 7.57993 12.3539 8.61423C12.5516 8.80708 12.8681 8.8032 13.061 8.60556C13.2538 8.40791 13.25 8.09135 13.0523 7.8985C11.8055 6.68195 10.1326 6.00098 8.39062 6.00098C6.64866 6.00098 4.97571 6.68195 3.72893 7.8985C3.53129 8.09135 3.52741 8.40791 3.72026 8.60556C3.91311 8.8032 4.22967 8.80708 4.42732 8.61423ZM6.54293 10.742C7.03987 10.2652 7.70187 9.99902 8.39053 9.99902C9.07919 9.99902 9.74119 10.2652 10.2381 10.742C10.4374 10.9332 10.7539 10.9266 10.9451 10.7274C11.1363 10.5281 11.1297 10.2116 10.9304 10.0204C10.2473 9.36498 9.33723 8.99902 8.39053 8.99902C7.44382 8.99902 6.53377 9.36498 5.85062 10.0204C5.65136 10.2116 5.6448 10.5281 5.83598 10.7274C6.02715 10.9266 6.34367 10.9332 6.54293 10.742ZM9.01562 12.5C9.01562 12.8452 8.7358 13.125 8.39062 13.125C8.04545 13.125 7.76562 12.8452 7.76562 12.5C7.76562 12.1548 8.04545 11.875 8.39062 11.875C8.7358 11.875 9.01562 12.1548 9.01562 12.5Z" />
</svg>


		</React.Fragment>
	)
}

export default WifiHighIcon