import axios from "axios";
import chatAuthHeader from "../chat-auth-header";

const chatApiService = async(url, data) => {
    var method = "POST";
    var headers = { "Content-Type": "application/json", "x-auth-token" : chatAuthHeader()};
    const response = await axios({
        url,
        method,
        headers,
        data,
    });
    if(response.status == 200 && response.data && response.data.success === true){
        return response.data;
    }else if(response.data && response.data.message){
        return response.data;
    }else{ 
        console.error(response);
        return [];
    }
}

export default chatApiService;