import React from 'react';
 

function BenchmarkSend(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M12.022 24.9995C9.24825 25.0009 6.4737 24.9994 3.69829 24.9947C2.00429 24.9947 1.00195 23.776 1 21.7439C1.00065 18.8158 1.00228 15.8869 1.00489 12.9572V4.28535C1.00489 2.21582 2.00429 1.00996 3.72858 1.0041C7.91181 0.998633 12.095 0.998633 16.2783 1.0041C18.0035 1.0041 18.999 2.21113 19 4.28535V14.439M4.96196 6H14.8669M4.96196 10H12.8859M4.96196 14H9.91441M14.0748 24.9371C14.8464 24.1154 16.8719 22.3721 19.75 22.3721V24.6221L23.5 20.8721L19.75 17.1221V19.3721C17.275 19.3721 14.102 21.737 13.75 24.7881C13.7451 24.8276 13.753 24.8676 13.7724 24.9024C13.7917 24.9371 13.8217 24.9648 13.8579 24.9814C13.8941 24.998 13.9346 25.0027 13.9736 24.9947C14.0126 24.9868 14.0481 24.9666 14.0748 24.9371Z" stroke="white" stroke-miterlimit="1" stroke-linecap="round"/>
</svg>

		</React.Fragment>
	)
}

export default BenchmarkSend