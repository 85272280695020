import React, { useEffect, useState } from "react";
import ExternalHeader from "../components/ExternalHeader";
import { useDispatch } from "react-redux";
import { setSelectedLanguage } from "../actions/customer";
import { _l } from "../hooks/utilities";
import { DEFAULT_PLACEHOLDER } from "../actions/action-type";
import RegistrationMain from "../components/RegistrationFlow/RegistrationMain";

const RegistrationFlow = () => {
  const dispatch = useDispatch();
  const languageSelect = [
    { value: "english", label: _l("l_english") },
    { value: "french", label: _l("l_french") },
  ];
  const [language, setlanguage] = useState(localStorage.getItem("language") || "french");

  const setLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    localStorage.setItem("language", language);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    // Access individual query parameters
    const appLanguage = queryParams.get("language");

    if(appLanguage)
    {
      let languageValue = "";

      switch (appLanguage) {
        case "en":
          languageValue = "english";
          break;
        case "fr":
          languageValue = "french";
          break;
      }
      setLanguage(languageValue); //Function call
      setlanguage(languageValue); //Local state update
    }
    else {
      localStorage.setItem("language", language);
    }

    document.body.classList.add("px-0");

    return () => {
      document.body.classList.remove("px-0");
    }
  }, [])

  return (
    <>
      <ExternalHeader
        profileImageURL={DEFAULT_PLACEHOLDER}
        hideLeftPanel={true}
        setlanguage={setlanguage}
        language={language}
        setLanguage={setLanguage}
        languageSelect={languageSelect}
      />
      <div className="d-flex flex-column h-100">
        <RegistrationMain language={language} />
      </div>
    </>
  );
};

export default RegistrationFlow;
