import React from 'react';

function FileSyncIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M19 4V11H26M19 4L26 11M19 4H7C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V16M26 11V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28H20M13.5049 22.1022H15.9997M15.9997 22.1022V19.6074M15.9997 22.1022L14.2328 20.3405C13.8085 19.9155 13.3045 19.5784 12.7498 19.3484C12.1951 19.1184 11.6005 19 10.9999 19C10.3994 19 9.80479 19.1184 9.25007 19.3484C8.69535 19.5784 8.19141 19.9155 7.76709 20.3405M8.4948 25.0444H6M6 25.0444V27.5392M6 25.0444L7.76715 26.8064C8.19147 27.2313 8.69541 27.5684 9.25013 27.7985C9.80485 28.0285 10.3995 28.1469 11 28.1469C11.6005 28.1469 12.1951 28.0285 12.7499 27.7985C13.3046 27.5684 13.8085 27.2313 14.2328 26.8064"  strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</React.Fragment>
	)
}

export default FileSyncIcon