import React from 'react';
 

function DotsNineIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M4.14062 4.375C4.4858 4.375 4.76562 4.09518 4.76562 3.75C4.76562 3.40482 4.4858 3.125 4.14062 3.125C3.79545 3.125 3.51562 3.40482 3.51562 3.75C3.51562 4.09518 3.79545 4.375 4.14062 4.375ZM8.39062 4.375C8.7358 4.375 9.01562 4.09518 9.01562 3.75C9.01562 3.40482 8.7358 3.125 8.39062 3.125C8.04545 3.125 7.76562 3.40482 7.76562 3.75C7.76562 4.09518 8.04545 4.375 8.39062 4.375ZM13.2656 3.75C13.2656 4.09518 12.9858 4.375 12.6406 4.375C12.2954 4.375 12.0156 4.09518 12.0156 3.75C12.0156 3.40482 12.2954 3.125 12.6406 3.125C12.9858 3.125 13.2656 3.40482 13.2656 3.75ZM4.14062 8.625C4.4858 8.625 4.76562 8.34518 4.76562 8C4.76562 7.65482 4.4858 7.375 4.14062 7.375C3.79545 7.375 3.51562 7.65482 3.51562 8C3.51562 8.34518 3.79545 8.625 4.14062 8.625ZM9.01562 8C9.01562 8.34518 8.7358 8.625 8.39062 8.625C8.04545 8.625 7.76562 8.34518 7.76562 8C7.76562 7.65482 8.04545 7.375 8.39062 7.375C8.7358 7.375 9.01562 7.65482 9.01562 8ZM12.6406 8.625C12.9858 8.625 13.2656 8.34518 13.2656 8C13.2656 7.65482 12.9858 7.375 12.6406 7.375C12.2954 7.375 12.0156 7.65482 12.0156 8C12.0156 8.34518 12.2954 8.625 12.6406 8.625ZM4.76562 12.25C4.76562 12.5952 4.4858 12.875 4.14062 12.875C3.79545 12.875 3.51562 12.5952 3.51562 12.25C3.51562 11.9048 3.79545 11.625 4.14062 11.625C4.4858 11.625 4.76562 11.9048 4.76562 12.25ZM8.39062 12.875C8.7358 12.875 9.01562 12.5952 9.01562 12.25C9.01562 11.9048 8.7358 11.625 8.39062 11.625C8.04545 11.625 7.76562 11.9048 7.76562 12.25C7.76562 12.5952 8.04545 12.875 8.39062 12.875ZM13.2656 12.25C13.2656 12.5952 12.9858 12.875 12.6406 12.875C12.2954 12.875 12.0156 12.5952 12.0156 12.25C12.0156 11.9048 12.2954 11.625 12.6406 11.625C12.9858 11.625 13.2656 11.9048 13.2656 12.25Z" fill="#DEDEDE"/>
</svg>


		</React.Fragment>
	)
}

export default DotsNineIcon