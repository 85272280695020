import React from 'react';
 

function UserPlusIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M12.5 8.5H15.5M14 7V10M6.75 10C8.82106 10 10.5 8.32107 10.5 6.25C10.5 4.17893 8.82106 2.5 6.75 2.5C4.67893 2.5 3 4.17893 3 6.25C3 8.32107 4.67893 10 6.75 10ZM6.75 10C5.72778 10 4.71797 10.2232 3.79154 10.6552C2.8651 11.0872 2.04453 11.7169 1.3875 12.5M6.75 10C7.77221 10 8.78203 10.2232 9.70846 10.6552C10.6349 11.0872 11.4555 11.7169 12.1125 12.5" />
</svg>

		</React.Fragment>
	)
}

export default UserPlusIcon