import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import jquery from "jquery";
const EquipmentListing = ({ list, setTaskList, projectId, showLoader, getTaskList }
) => {
  const FullLeftPanelWidth = jquery('.employeeListingDropDownminHeight').width();
  const FullLeftPanelHeight = jquery('.employeeListingDropDownminHeight').height();
  const [heightWidth, setHeightWidth] = useState({});
  let IdName = 'writeComMentDropdown';
  useEffect(() => {
    if (Object.keys(heightWidth).length) {
      IdName = 'writeComMentDropdown';
      let tempWidth = heightWidth.width;
      let tempHeight = heightWidth.height;
      if (heightWidth.width + jquery("." + IdName).width() > FullLeftPanelWidth) {
        tempWidth = heightWidth.width - jquery("." + IdName).width()
      }
      if (heightWidth.height + jquery("." + IdName).height() > FullLeftPanelHeight) {
        tempHeight = heightWidth.height
      }
      jquery("#commentDropDownOpen").css({
        position: 'absolute',
        left: 255,
        top: Math.abs(tempHeight),
      }
      );
    }
  }, [heightWidth])
  return (
    <></>
  );
};
export default EquipmentListing;
