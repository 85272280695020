import React, { useEffect } from "react";
import StaffList from "../components/StaffList";
import {
  CardListFullHeight,
  DefaultListView,
  leftPanelCategoryListToggle,
  showLeftPanel,
} from "../hooks/utilities";
const staffmain = () => {

  const pageName = window.location.pathname == '/staff' ? 'staff' : "";

  useEffect(() => {
    showLeftPanel(true, true);
    CardListFullHeight();
    DefaultListView();
    document.addEventListener('mouseup', leftPanelCategoryListToggle);
    return () => {
      document.removeEventListener('mouseup', leftPanelCategoryListToggle);
    }
  }, []);

  return (
    <>
     <StaffList pageName={pageName} />
    </>
  );
};

export default staffmain;
