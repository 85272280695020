import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
 
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { LeftPanelResponsiveToggle, _l , getDatePickerLocale } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import HighPriorityActiveIcon from "../../assets/icons/HighPriorityActiveIcon";
import HighPriorityIcon from "../../assets/icons/HighPriorityIcon";
import AddAssigneeMainDropdown from "../TaskModalComponents/AddAssigneeMainDropdown";
import { useDispatch, useSelector } from "react-redux";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import CommentDropdown from "../Dropdowns/CommentDropdown";
import TaskListStatusDropdown from "../Dropdowns/TaskListStatusDropdown";
import { useNavigate } from "react-router-dom";
import {
  changePriority,
  updateDocumentPin,
  updateDocumentStatus,
} from "../../actions/google-drive";
import moment from "moment";
import { clientTabData } from "../../actions/customer";
import documentsServices from "../../services/documents-services";
import { showMessage } from "../../actions/messages";
import PayoutsDetailsModal from "../Modals/PayoutsDetailsModal";
import $ from "jquery";
import PushPinFillIcon from "../../assets/icons/PushPinFillIcon";
import PushPinIcon from "../../assets/icons/PushPinIcon";
import DownloadSimpleIcon from "../../assets/icons/DownloadSimpleIcon";
import DotsThreeVerticalIcon from "../../assets/icons/DotsThreeVerticalIcon";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import CaretDownIcon from "../../assets/icons/CaretDownIcon";
import UserCircleGearIcon from "../../assets/icons/UserCircleGearIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import ChatCircleIcons from "../../assets/icons/ChatCircleIcons";
export function CardView({
  hideShow = (data) => {},
  docType,
  setDocType,
  data,
  selectedId,
  setSelectedId = () =>{},
  pinHandler,
  setShowLink ,
  actionItems,
  aiChatAssistURL,
  listData,
  setListData
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [downloadURL, setDownloadURL] = useState("#");
  const [pinDocument, setPinDocument] = useState(0);
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [openCommentTab, setOpenCommentTab] = useState(false);
  const selectedDocument = useSelector(
    (state) => state.googleDrive.selectedDocument
  );
  const [updatedCommentCount, setUpdatedCommentCount] = useState(0);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const { selectedProject: projectId } = useSelector(
    (state) => state.customer
  );

  const [documentPriority, setDocumentPriority] = useState(
    data.priority && data.priority == 1 ? 1 : 0
  );
  const [isDocumentPinned, setIsDocumentPinned] = useState(
    data.pinned && data.pinned == 1 ? true : false
  );
  const userType = localStorage.getItem("user_type");
  const [showSmallModal, setShowSmallModal] = useState(false);
  const [dataModal, setDataModal] = useState([]);

  const cardOnClickHandler = (id) => {
    hideShow(id);
    setDocType = () => {};
    LeftPanelResponsiveToggle();
    setShowSmallModal(false)

  };
  const staffId = localStorage.getItem("staff_id") ? localStorage.getItem("staff_id") : ""

  const statusClass = { 
    Unpaid: "badge-red",
    Paid: "badge-done",
    Active: "badge-done",
    active: "badge-done",
    Partially: "badge-warning",
    Accepted: "badge-done",
    Overdue: "badge-red",
    Cancelled: "badge-warning",
    Draft: "",
    null: "badge-in-progress",
    Future: "badge-done",
    Incomplete: "badge-warning",
    Sent: "badge-in-progress",
    Expire: "badge-red",
    Expired: "badge-red",
    Declined: "badge-red",
    canceled: "badge-red",
    deactive: "badge-red",
    l_accepted: "badge-done",
    l_expired: "badge-red",
    l_declined: "badge-warning",
    l_active: "badge-done",
    l_not_subscribed: "badge-warning",
    unpaid: "badge-warning",
    paid: "badge-done"
  };
  useEffect(() => {
    if (data) {
      if (data.date) {
        setStartDate(new Date(data.date));
        setDownloadURL(data.downloadUrl || data.invoice_link || data.download_link || data.estimate_link || data.subscription_link);
      }
      setPinDocument(data.pinned == 1 ? 1 : 0);
    }
  }, [data]);

  useEffect(() => {
    if (selectedDocument && selectedDocument.id === data.id) {
      setDocumentPriority(selectedDocument.priority == 0 ? 0 : 1);
      setIsDocumentPinned(selectedDocument.pinned == 1 ? true : false);
    } else {
      setDocumentPriority(data.priority == 0 ? 0 : 1);
      setIsDocumentPinned(data.pinned == 1 ? true : false);
    }
  }, [JSON.stringify(selectedDocument), data.id]);


  

  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };

  const pinDocuments = (id) => {
    if (docType === "document") {
      dispatch(
        updateDocumentPin(id, projectId, isDocumentPinned == 0 ? 0 : 1)
      ).then(() => {
        pinHandler(id);
      });
    } else {
      CustomerServices.pinDocuments(id, docType).then((res) => {
        if (res.status == 1) {
          pinHandler(id);
        }
      });
    }
  };

  const changeDocumentPriority = (e) => {
    dispatch(
      changePriority(data.id, projectId, documentPriority == 0 ? 0 : 1)
    ).then(() => {
      setDocumentPriority(documentPriority == 0 ? 1 : 0);
    });
  };

  const changeDocumentStatus = (statusId) => {
    dispatch(updateDocumentStatus(data.id, projectId, statusId));
  };
  
  const estimateStatusActionHanler = async (estimateId, status, statusName, status_text) => {
    documentsServices.handleEstimateStatus(staffId, estimateId, status).then((res) => {
      if (res.status == 1) {
        let estimates = listData.l_estimates
        estimates = estimates.map((estimates) => {
          if (estimates.id == estimateId) {
            estimates.status = statusName;
            estimates.status_text = status_text;
          }
          return estimates;
        });
  
        setListData({ l_estimates: estimates });
        dispatch(showMessage("sucess", _l("l_success"), _l("l_estimate_status_updated")));
      } else {
        dispatch(showMessage("error", _l("l_error"), _l(res.message)));
      }
    });
  }

  const copyEstimate = (estimateId) => {
    documentsServices.copyEstimateByAdmin(staffId, estimateId.id).then((res) => {
      if (res.status == 1) {
          hideShow({
            id: res.data.id,
            hash: res.data.hash,
          });
          setDocType("estimate");
          LeftPanelResponsiveToggle();
          dispatch(showMessage("sucess", _l("l_success"), _l("l_estimate_duplicate_is_made_successfully")));          
      } else {
        dispatch(showMessage("error", _l("l_error"), _l(res.message)));
      }
    });
  }

  const deleteEstimate = (id) => {
    documentsServices.deleteEstimateAdmin(id).then((res) => {
      if (res.status) {
        setListData({
          l_estimates : listData.l_estimates.filter(
            (x) => x.id != id
          ),
        });
        dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
      }else{
        dispatch(showMessage("error", _l("l_error"), _l(res.message)));
      }
    }) 
  }
  
  return (
    <div
      className={`task-list-card list-view document-list-card cursor-pointer ${
        data && data.id && selectedId == data.id ? "active" : ""
      }`}
      onClick={(e) => {
        if($('.payout-modal').hasClass('show')){
          setShowSmallModal(false)
          return 
        }
        if(data.client_type == 'space')
        {
          dispatch(clientTabData(data));
        } else if(data.client_type == 'client_invoice')
        {
          dispatch(clientTabData(data));
          localStorage.setItem('clientTab', 1);
          navigate('/invoice');
        } else if(data.client_type == 'client_creditnote')
        {
          dispatch(clientTabData(data));
          localStorage.setItem('clientTab', 1);
          navigate('/credit_notes');
        } else if(data.client_type == 'client_subscription')
        {
          dispatch(clientTabData(data));
          localStorage.setItem('clientTab', 1);
          navigate('/subscription');
        } else if(data.client_type == 'client_estimate')
        {
          dispatch(clientTabData(data));
          localStorage.setItem('clientTab', 1);
          navigate('/estimate');
        } else if(docType == "payouts") {
          setShowSmallModal(true);
          setDataModal(data);
        } else {
          cardOnClickHandler()
          if (docType != "subscription") {
            setSelectedId(data.id)
          }
        }
      }}
    >
      <div className="task-list-wrapper">
        <div className="d-flex w-100 align-items-start">
          <div className="d-flex task-list-detail">
            <div className="task-left flex-grow-1 width100minus160">
              <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                <div
                  className="task-top-part flex-grow-1 main-calc-width d-flex text-truncate"
                >
                  <div className="d-flex align-items-center w-100">
                    {docType == 'staff' ?
                    <div className="task-creator-img">
                    <div
                      
                       
                       
                      className="comman-image-box h32w32 rounded-circle"
                    >
                      <div
                        className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                        style={{
                          backgroundImage: `url('${data.profile_image}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                  : docType == "payouts" ? <></> :
                    <div className="task-creator-img">
                      <div className="comman-image-box h32w32 rounded-circle">
                        <div
                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                         
                          style={{
                            backgroundImage: `url('${
                              data &&
                              data.addedfrom_details &&
                              docType == "invoice"
                                ? data.addedfrom_details.profile_image
                                : data &&
                                  data.addedfrom_details &&
                                  data.addedfrom_details.profile_image
                            }')`,
                          }}
                        ></div>
                      </div>
                    </div>
                    }
                    <a href="#/" className="title-fonts text-truncate task-list-name" 
                      onClick={() => {}}>
                      <span className="me-2">
                        {docType === "payouts" ? (
                          <>
                            #{data.id} - {data && data.payout && data.payout.description ? data.payout.description : ""}
                          </>
                        ) : docType === "document" ? (
                          <>
                          [{data.fileExtension.toUpperCase()}]
                          </>
                        ) : docType === "spaceDocument" ? (
                          <>
                            {data && data.title ? data.title : data.name ? data.name : ""}
                          </>
                        ) : docType === "pilotonboarding" ? (
                          <>
                            {_l("l_pilot_onboarding")}
                          </>
                        ) : docType === "credit_note" ? (
                          <>
                           #{data.id} - {data && data.credit_note_number ? data.credit_note_number : ""}
                          </>
                        ) : (
                          <>
                          #{data.id} - {data && data.name ? data.name : ""}
                          </>
                        )}
                      </span>
                    </a>
                  </div>
                   
                </div>
                <div className="task-mid-part w-460 d-flex res-flex-wrap-nowrap align-items-center  for-content-between comman_action_icon">
                  <div className="d-flex align-items-center justify-content-end res-space-start res-width-100">
                  
                    <div className="pill-min-width pill-bottom-show  text-end">
                      <div className="d-flex align-items-center">
                      {
                            docType == 'staff' && data.employee_batch ?
                            <>
                                <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap ">
                                <span
                                  className={` p-0 `}
                                >
                                  {data
                                    ? data.employee_batch
                                    : ""}
                                </span>
                              </a>
                              </> :<></>
                      }
                      {
                            docType == 'invoice' && data.customer_name ?
                            <>
                                <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12  badge-white rounded-pill me-2  text-nowrap ">
                                <span
                                  className={` p-0 `}
                                >
                                  {data
                                    ? data.customer_name
                                    : ""}
                                </span>
                              </a>
                              </> :<></>
                      }
                      {                             
                        docType == 'equipment' && data.category_name ?
                              <>
                                  <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap ">
                                  <span
                                    className={` p-0 `}
                                  >
                                    {data
                                      ? data.category_name
                                      : ""}
                                  </span>
                                </a>
                                </>:
                                <></>
                      }
                      {
                            docType == 'staff' && data.status_text ?
                            <>
                                <a href="#/" className={`badge ${data.active == 1 ? 'badge-done' :'badge-red'} ${statusClass ? statusClass[data && data.status_text] : ""} h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap `}>
                                <span
                                  className={`  p-0 `}
                                >
                                  {data
                                    ? data.status_text
                                    : ""}
                                </span>
                              </a>
                              </> :
                              <></>}
                        {docType == "document" ? (
                          <></>
                        ) : (
                          <>
                            {
                              data && (data.status || data.status_text) && docType != 'staff' ? 
                              <a href="#/" className={`badge ${
                                statusClass
                                  ? statusClass[data && data.status_text ? data.status_text : data.status]
                                  : ""
                              } h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap `}>
                                <span
                                  className={`p-0 text-capitalize`}
                                >
                                  {data
                                    ? _l(data.status_text)
                                      ? _l(data.status_text)
                                      : _l(data.status)
                                    : ""}
                                </span>
                              </a>
                                :
                                <></>
                            }
                            
                          </>
                        )}
                      </div>
                    </div>
                    {startDate &&  docType != "payouts" ? <div className="pill-min-width pill-bottom-show  text-start">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap ">
                          <span
                            className={`p-0`}
                          >
                            { moment(startDate).format('MMMM') + " " + moment(startDate).format('YYYY')}
                          </span>
                        </a>
                      </div>
                    </div> : <></>}
                    { docType == "payouts" && data && data.payout && data.payout.created ? <div className="pill-min-width pill-bottom-show  text-start">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill me-2  text-nowrap ">
                          <span
                            className={`p-0`}
                          >
                            {moment(data.payout.created).format("DD/MM/YYYY")}
                          </span>
                        </a>
                      </div>
                    </div> : <></>}
                  </div>
                </div>
              </div>
             
              <div className="d-flex align-items-center task-bottom-part-full">
                <div className="task-right-static-options">
                  <div className="d-flex align-items-center">
                  {docType == "payouts" ? <></> :
                          docType != "spaceDocument" ?

                    <div className="comman_action_icon custom-datepicker w-330px withinputabsolute  list-toggle-view-box ">
                      <a href="#/"
                        className="action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative "
                         
                         
                         
                      >
                          <CalendarBlankIcon
                            className="HW16 opacity-60"
                          />
                          <DatePicker
                            dateFormat="dd/MM/yyyy hh:mm:ss"
                            locale={getDatePickerLocale()}
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            placeholderText={`${_l(
                              "l_select_date_placeholder"
                            )}`}
                            readOnly={true}
                            calendarStartDay={1}
                          >
                            </DatePicker>
                      </a>
                    </div>
                    :
                    <a href="#/"
                        className={`btn btn-white-10 h32wauto btn-sm line-height-1 d-flex align-items-center  action_icon ml-10 d-none`} // space document follow button hiden as hardik radadiya 
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowLink(data.hash,docType);
                        }}
                      >
                        {_l("l_follow")}
                      </a>
                            }
                    {docType == "document" ? (
                      <>
                        <div className="comman_action_icon with_separator_10 card-view-less-padding" onClick={(e) => {e.stopPropagation()}}>
                          <div
                            className={`white-border-box me-0 color-white-60 action_icon with_bg dropdown d-flex close-toast`}
                            type="button"
                            id="TaskListStatusDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                             
                             
                             
                          >
                            <div className="white-border-box color-white-60 action_icon with_bg border-0 dropdown d-flex">
                              <div className="d-flex align-items-center m-auto">
                                <span
                                  className={`c-dots c-10 ${
                                    data.status == 1
                                      ? "in-progress-white"
                                      : data.status == 4
                                      ? "in-progress-blue"
                                      : "done"
                                  } rounded-circle`}
                                ></span>
                                <span className="d-none">{_l("l_status")}</span>
                                <CaretDownIcon
                                  className="HW14 opacity-60 d-none "
                                />
                                {data ? (
                                  <TaskListStatusDropdown
                                    setTaskStatus={changeDocumentStatus}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="comman_action_icon with_separator_10 card-view-less-padding">
                          <div
                            className={`priority-checkbox span-13 white-border-box action_icon with_bg close-toast`}
                           
                          >
                            <input
                              type="checkbox"
                              id="priority"
                              className="absolute-input"
                              checked={documentPriority == 1 ? true : false}
                              onChange={(e) => {
                                changeDocumentPriority(e);
                              }}
                            />
                            <div className="check_box">
                              <HighPriorityActiveIcon className="HW18 check_span_img active" />
                              <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                            </div>
                          </div>
                        </div>

                        <div className="comman_action_icon card-view-none">
                          <div className="right-part ps-2">
                            <div
                              className="multi_image_wrapper dropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                               
                               
                               
                               
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTaskAssigneeData();
                              }}
                            >
                              <div className="d-flex position-relative">
                                {data &&
                                data.assignees &&
                                data.assignees.shared ? (
                                  <>
                                    {data.assignees.shared.map(
                                      (assignee, index) => {
                                        if (index < 2) {
                                          return (
                                            <a href="#/"
                                              type="button"
                                              id="TaskAssigneeDropdown"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                              className="comman-image-box h20w20 rounded-circle multi-wrapper-border"
                                              key={index}
                                            >
                                              <div
                                                className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                style={{
                                                  backgroundImage: `url('${assignee.profile_image}')`,
                                                }}
                                              ></div>
                                            </a>
                                          );
                                        }
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {data &&
                                data.assignees &&
                                data.assignees.shared &&
                                data.assignees.shared.length > 2 ? (
                                  <>
                                    <a href="#/"
                                      className="comman-image-box h20w20 with_overlay d-flex rounded-circle"
                                      type="button"
                                      id="TaskAssigneeDropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <div className="unread_count m-auto">{`+${
                                        data.assignees.shared.length - 2
                                      }`}</div>
                                    </a>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {data &&
                                data.assignees &&
                                data.assignees.shared &&
                                data.assignees.shared.length ? (
                                  <GeneralListing
                                    list={data.assignees.shared.map(
                                      (item, index) => {
                                        return {
                                          name: item.full_name || item.name,
                                          image:
                                            item.user_image ||
                                            item.profile_image,
                                          id: index,
                                        };
                                      }
                                    )}
                                    isFromCalDropDown="1"
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="comman_action_icon with_separator_10 ">
                          <div className="right-part">
                            <div className="dropup">
                              <div
                                className="close-toast"
                                id="AddAssigneeMainDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="outside"
                                type="button"
                                 
                                 
                                 
                                 
                                 
                                currentitem="false"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenAssigneeDropdown(true);
                                }}
                              >
                                <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img">
                                  <UserCircleGearIcon
                                    className="HW18"
                                  />
                                  {
                                    <AddAssigneeMainDropdown
                                      key={data.id}
                                      projectId={JSON.parse(
                                        localStorage.getItem("selectedOffice")
                                      )}
                                      taskId={data.id}
                                      openAssigneeDropdown={
                                        openAssigneeDropdown
                                      }
                                      setOpenAssigneeDropdown={
                                        setOpenAssigneeDropdown
                                      }
                                      fromTaskcard="1"
                                      selectedTagId={1}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="comman_action_icon with_separator_10 card-view-less-padding">
                          <div className="c-font f-12 fw-semibold text-nowrap ">
                            {Math.floor(parseInt(data.size) / 1000)} KB
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    { docType == "payouts" && data && data.payout ? <div className="pill-min-width pill-bottom-show  text-start">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="badge h32wauto d-flex align-items-center c-font f-12 badge-white rounded-pill text-nowrap ">
                          <span
                            className={`p-0`}
                          >
                            {_l("l_no_of_Payment")} : {data && data.payout && data.payout.source_payments ? data && data.payout && data.payout.source_payments.length : 0 }
                          </span>
                        </a>
                      </div>
                    </div> : <></>}

                    <div
                      className={`comman_action_icon with_separator_10 card-view-less-padding pe-0 before-dnone ${
                        (data && data.total) || (docType == "payouts" && data && data.payout && data.payout.db_amount) ? "" : "d-none"
                      }`}
                    >
                      <div className="c-font f-12 h32wauto d-flex align-items-center  fw-semibold badge radius_3">
                        <span className="color-green">
                          {" "}
                          {data && data.total ? data.total : data && data.payout && data.payout.db_amount ? '€' + data.payout.db_amount : ""}
                        </span>
                      </div>
                    </div>
                    <div className={`d-flex align-items-center justify-content-end table-list-attechment w_172 w-auto ${['equipment', 'payouts'].includes(docType) ? 'd-none' : ""} ${['staff'].includes(docType) ? 'd-none' : ""}`}>
                      <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding  min-width-auto before-dnone pe-0">
                        <a
                          className="comman_action_icon w-100 d-flex"
                          href={downloadURL}
                           
                           
                           
                           
                          onClick={(e) => {e.stopPropagation(); e.preventDefault(); window.open(downloadURL)}}
                        >
                          {data.fileExtension !== "form" && (
                            <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                              <DownloadSimpleIcon
                                className="HW18"
                              />
                            </div>
                          )}
                        </a>
                      </div>
                    </div>

                    {docType == "document" ? (
                      <>
                        <div className="comman_action_icon with_separator_10 card-view-none">
                          <div className="d-flex align-items-center">
                            <div className="action_icon with_bg position-relative on-hover-active-toggle-img light-theme-img">
                              <a href="#/"
                                className="comman_action_icon w-100 d-flex"
                                type="button"
                                id="writeComMentDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                data-bs-auto-close="outside"
                                 
                                 
                                 
                                 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenCommentTab(true);
                                }}
                              >
                                <div className="d-flex align-items-center w-100">
                                  <div className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img light-theme-img">
                                    <ChatCircleIcons
                                      className="HW18"
                                    />
                                    {data.unread_comment_count > 0 && (
                                      <span
                                        className={`rounded-pill badge-custom p-0 ${
                                          data.unread_comment_count == 0
                                            ? "d-none"
                                            : ""
                                        }`}
                                        data-unread-cmt-id={data.id}
                                      >
                                        {data.unread_comment_count}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </a>
                              <CommentDropdown
                                openCommentTab={openCommentTab}
                                setOpenCommentTab={setOpenCommentTab}
                                task_id={data.id}
                                projectId={localStorage.getItem(
                                  "selectedOffice"
                                )}
                                updatedCommentCount={setUpdatedCommentCount}
                                callbackFunction={() => {
                                  return 0;
                                }}
                              />
                            </div>
                            <span
                              className="c-font f-12 d-flex justify-content-center flex-grow-1 text-on-hover-active-green number-counter-toggle"
                              data-cmt-cnt-id={data.id}
                            >
                              {updatedCommentCount || data.comments_count}
                            </span>
                          </div>
                        </div>

                        <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                          <div className="comman_action_icon w-100 d-flex">
                            <div className="action_icon with_bg on-hover-active-toggle-img position-relative">
                              <a>
                                <div
                                  className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(data.webViewLink, "_blank");
                                  }}
                                >
                                  <EyeIcon
                                    className="HW18"
                                  />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="task-assign-section">
              <div className="d-flex align-items-center justify-content-end comman_action_icon">
                <div className="pill-min-width  pill-bottom-top text-end d-none">
                  <div className="d-flex align-items-center">
                    <a href="#/"
                      className="badge h32wauto d-flex  align-items-center  c-font f-12 badge-white rounded-pill fw-light"
                    >
                      <span className=" p-0">{_l("l_invoice")}</span>
                    </a>
                    <a href="#/"
                      className="badge badge-done h32wauto d-flex  align-items-center  c-font f-12  rounded-pill fw-light d-none"
                    >
                      <span className="p-0">{_l("l_paid")}</span>
                    </a>
                  </div>
                </div>
                <div className={`before-dnone comman_action_icon with_separator_10 pe-0  ${['equipment', 'payouts', 'staff'].includes(docType) ? 'd-none': ""}`} 
                  onClick={(e) => {e.stopPropagation()}}>
                  <a href="#/"
                    className="action_icon with_bg h32w32 on-hover-active-toggle-img"
                    onClick={() => pinDocuments(data.id ? data.id : 0)}
                     
                     
                     
                     
                  >
                    {pinDocument == 1 ? (
                      <PushPinFillIcon
                        className="stroke-transparent Push-Pin-fill HW18"
                      />
                    ) : (
                      <PushPinIcon className="HW18" />
                    )}
                  </a>
                </div>
                {
                  userType == "staff"
                  ?
                    <div className="dropstart action_icon with_bg h32w32 d-flex align-items-center justify-content-center ms-10px"
                      onClick={(e) => {e.stopPropagation()}}>
                      <a href="#/"
                        type="button"
                        className={`line-height-0 ${(docType == 'staff' || docType == 'equipment') ? 'for-disabled opacity-50' : ""}`}
                        id="ActiveOfficeOptionsDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                         
                         
                         
                      >
                        <DotsThreeVerticalIcon
                          className="HW18"
                        />
                      </a>
                      {docType != "staff" ?
                      <ActionDropdown
                      actions={[ 
                        ...[
                          {
                          actionName: _l("l_edit"),
                          actionHandler: () => {},
                          actionIcon: "PencilSimpleLineIcon",
                          },
                          {
                            actionName: _l("l_inactive"),
                            actionHandler: () => {},
                            actionIcon: "ProhibitIcon",
                          }
                        ],
                        ...docType == "estimates" ? [
                          {
                            actionName: _l("l_mark_as_sent"),
                            actionHandler: () => { estimateStatusActionHanler(data.id, 2, "Sent", "l_sent")},
                            actionIcon: "ArrowRightIcon",
                          },
                          {
                            actionName: _l("l_mark_as_expired"),
                            actionHandler: () => { estimateStatusActionHanler(data.id, 5, "Expired", "l_expired")},
                            actionIcon: "ProhibitIcon",
                          },
                          {
                            actionName: _l("l_mark_as_declined"),
                            actionHandler: () => { estimateStatusActionHanler(data.id, 3,"Declined", "l_declined")},
                            actionIcon: "XCircleIcon",
                          },
                          {
                            actionName: _l("l_mark_as_accepted"),
                            actionHandler: () => { estimateStatusActionHanler(data.id, 4, "Accepted", "l_accepted")},
                            actionIcon: "ProhibitIcon",
                          },
                          {
                            actionName: _l("l_copy_estimate"),
                            actionHandler: () => { copyEstimate(data)},
                            actionIcon: "ClipboardIcon",
                          },
                          {
                            actionName: _l("l_delete_estimate"),
                            actionHandler: () => {deleteEstimate(data.id)},
                            actionIcon: "TrashIcon",
                          },
                        ] : []
                      ]} 
                    />
                  :
                  <></>}
                    </div>
                  :
                    <React.Fragment></React.Fragment>
                }
                  {actionItems && actionItems.length ? (
                    <div className="dropstart  comman_action_icon ms-10px"
                      onClick={(e) => {e.stopPropagation()}}>
                      <a href="#/"
                        type="button"
                        className="line-height-0 action_icon with_bg h32w32"
                         
                         
                         
                         
                        id="deLeteDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <DotsThreeVerticalIcon
                          className="HW18"
                        />
                      </a>
                      <ActionDropdown actions={actionItems} />
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
       
    {
      showSmallModal 
      ? <PayoutsDetailsModal 
        showSmallModal = {showSmallModal}
        handleClose = {false}
        data = {dataModal}
        cardOnClickHandler = {cardOnClickHandler}
        />  
      : <></>    
    }

    </div>
  );
}
