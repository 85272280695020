import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { _l, insertProviderPageDataToIDB, showError } from "../../hooks/utilities";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import benchmarkServices from "../../services/benchmark-services";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import customerServices from "../../services/customer-services";
import { getProviderListTableData } from "../../actions/customer";

const SpaceSelectionModal = ({showSpaceModal, handleClose}) => {
    const {  providerlistTableData, providerList } = useSelector((state) => state.customer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  let spaceList = JSON.parse(localStorage.getItem("spaces")).map((space) => {
    return { value: space.project_id, label: space.office_title != "" ? space.office_title : space.postal_address };
  })

  const { show, actionType } = showSpaceModal
  const [selectedSpace, setSelectedSpace] = useState([]);
  const [SpaceSelctionOption, setSpaceSelctionOption] = useState(spaceList)
  const [Loader, setLoader] = useState(false);
  let client_id = localStorage.getItem("client_id")

  const handleNavigate = () => {
    let selectedSpaces = selectedSpace.filter(space => space.value != "all")
    if (selectedSpaces != null) {
        if (actionType == "provider") {
            localStorage.setItem("providerSelectedSpaces", JSON.stringify(selectedSpaces));
        }
        const { value } = actionType == "provider" ?  selectedSpaces[0] : selectedSpaces[0]
        switch (actionType) {
            case "schedule":
                navigate(`/connect-provider?space=${value}&provider-type=schedule`);
                break;
            case "benchmark":
                navigate(`/connect-provider?space=${value}&provider-type=benchmark`);
                break;
            case "provider":
                navigate(`/connect-provider?space=${value}&provider-type=invite_provider`);
                break;
            case "equipment":
                navigate(`/equipments?space=${value}&action=equipment-list`);
                break;
            default:
                handleClose()
                break;
        }
        handleClose()
    }
  }
  
  useEffect(() => {
    if (["provider" , "Addspace"].includes(actionType) && spaceList && spaceList.length > 0) {
        if (actionType == "provider") {
            setSpaceSelctionOption([{value: "all", label: "All Spaces" }].concat(spaceList));
        }else{
            setSpaceSelctionOption([{value: "all", label: "All Spaces" }].concat(spaceList.filter(fi => !(providerlistTableData.spaces.map(t => t.project_id)).includes(fi.value))));
        }
    }
  }, [actionType, JSON.stringify(providerlistTableData)]);

  const handleAddspace = () => {
    try {
        setLoader(true);
        let selectedSpaces = selectedSpace.filter(space => space.value != "all")
        localStorage.setItem("providerSelectedSpaces", JSON.stringify(selectedSpaces));
        let newProviderDetails = providerList.filter(item => item.userid == localStorage.getItem('currentCompanyID'))[0]

        if (newProviderDetails) {
            benchmarkServices.addBulkProvidersToCategory(
                '',
                newProviderDetails.category[0],
                [
                  {
                    email: newProviderDetails.email,
                    name: newProviderDetails.fullname,
                    company_name: newProviderDetails.company,
                  },
                ]
            ).then((res) => {
                if (res.status == 1) {
                    localStorage.removeItem('providerSelectedSpaces');
                    customerServices.getSelectedProviderDetails(localStorage.getItem("currentCompanyID"), "spaces").then((res) => {
                        if (res && res.status) {
                              dispatch(getProviderListTableData({ ...providerlistTableData, "spaces": res.data.spaces }));
                              insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "spaces": res.data.spaces } , localStorage.getItem("currentCompanyID"), client_id )
                              setLoader(false);
                              handleClose();
                        } else {
                          showError(res.message);
                          setLoader(false);
                          handleClose();
                        }
                      })
                }else{
                    localStorage.removeItem('providerSelectedSpaces');
                    setLoader(false);
                    handleClose();
                }
            })
        }
    } catch (error) {
        console.log(error);
    }
  }

  return (
    <>
          <Modal
              show={show}
              onHide={handleClose}
              className="custom-modal-style w1300px"
              aria-labelledby="contained-modal-title-vcenter"
              size="md"
              centered
              scrollable
          >
              <Modal.Header closeButton className="">
                  <Modal.Title>{`${_l('l_select')} ${_l('l_space')}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="py-0 min-h-230px">
                  <div className="form-wrapper-main">
                      <div className="row m-0 mb-3">
                          <Form.Group className="col-xl-12 c-input-box position-relative mb-3 g-0 z-3">
                              <div className="d-flex"> 
                                  <Select
                                      className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-20vh position-sticky top-0 flex-grow-1"
                                      placeholder={_l("l_select")}
                                      options={selectedSpace.some(v => v.value == "all") ? [] : SpaceSelctionOption}
                                      value={selectedSpace.some(v => v.value == "all") ? SpaceSelctionOption.filter(t => t.value == "all") : selectedSpace}
                                      isMulti = {["provider" , "Addspace"].includes(actionType)}
                                      onChange={(e) => {
                                        if (["provider" , "Addspace"].includes(actionType)) {
                                            if (e.some(item => item.value == "all")) {
                                                setSelectedSpace(SpaceSelctionOption)
                                            }else{
                                                setSelectedSpace(e);
                                            }
                                        }else{
                                            setSelectedSpace([e]);
                                        }
                                      }}
                                      classNamePrefix="react-select"
                                  />
                              </div>
                          </Form.Group>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer className="border-0 justify-content-end">
                  <Button variant="secondary" size="sm" disabled = {Loader} onClick={handleClose}>
                      {_l('l_close')}
                  </Button>
                  <Button variant="primary" size="sm" disabled = {Loader || selectedSpace && selectedSpace.length == 0} onClick={() => actionType == "Addspace" ? handleAddspace() :  handleNavigate()}>
                        {Loader ? _l('l_please_wait') : actionType == "Addspace" ? _l("l_space") + " " + _l("l_assign") :  _l('l_select')}
                        {Loader ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 `}
                            aria-hidden="true"
                        />
                        ) : (
                        <></>
                        )}
                  </Button>
              </Modal.Footer>
          </Modal>
    </>
  );
};

export default SpaceSelectionModal;
