import React from 'react';


function CaretCircleDownIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm44.24-110.24a6,6,0,0,1,0,8.48l-40,40a6,6,0,0,1-8.48,0l-40-40a6,6,0,0,1,8.48-8.48L128,143.51l35.76-35.75A6,6,0,0,1,172.24,107.76Z"></path></svg>
		</React.Fragment>
	)
}

export default CaretCircleDownIcon