import React, { useEffect, useState } from "react";
import AssigneeSubMenu from "./AssigneeSubMenu";
import { useDispatch, useSelector } from "react-redux";
import outlookCalendar from "../../services/outlook-calendar";
import $ from "jquery";
import {
  getDefaultTaskAssignee,
  setTaskAssignee,
  setCalendarsAssignee, setTaskAssigneeNew} from "../../actions/customer";
import CustomerServices from "../../services/customer-services";
import { getAssigneeTypesForRequest, _l } from "../../hooks/utilities";
import jQuery from "jquery";
import { checkIsAssigned, set_contact_data } from "../../actions/outlook_auth";
import {OUTLOOK_CALENDAR_CONTACTS} from "../../actions/action-type";
import Button from 'react-bootstrap/Button';
import Spinner from "react-bootstrap/Spinner";
import store from "../../store";
import { CUSTOM_ASSIGNEE_CHANGE } from "../../constants/constants";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import HashIcon from "../../assets/icons/HashIcon";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";
const AddAssigneeMainDropdown = ({
  projectId,
  taskId,
  calendar_id,
  openAssigneeDropdown,
  setOpenAssigneeDropdown,
  handleProvider,
  providerDefaultType = null,
  providerDefaultValue = null,
  providerDefaultDetails = null,
  fromTaskcard = null,
  selectedTagId = 0,
  isCreateTask = null,
  hangleTag,
  isRequest = false,
  handleRequestAssignee = () => { },
  moduleType,
  documentAssignees,
  pageName = '',
  selectedId = [],
  myr_total = 0,
  actionStausChange,
  action = false,
  direction= null, /*Dropdown direction Left or right */
  bulkAction = false,
  saveBtnToggle = false,
  setSelectedId,
  actionType = "",
  actionBtnLoader = false,
  actionTypeId = "",
  setDropDownToggle,
  dropDownToggle
}) => {
  const defaultTopicState = useSelector(
    (state) => state.customer.defaultTopics
  );
  let { subscription_details, invoice_details, estimate_details } = useSelector((state) => state.documentsReducer);
  const [searchAssignees, setSearchAssignees] = useState("");
  const [assigneeData, setAssigneeData] = useState([]);
  const [docsAssigneeData, setDocsAssigneeData] = useState([]);
  const [defaultTags, setDefaultTags] = useState([]);
  const rel_type = localStorage.getItem("user_type");
  const user_id = rel_type == "contact" ? localStorage.getItem("contact_id") : localStorage.getItem("staff_id");
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [assignee_list, setassignee_list] = useState([]);
  const assigneeStateData = useSelector(
    (state) => state.customer.defaultTaskAssignees
  );
  const isDefaultTopicsDataAccess = ['task','LiteVersion']
  const { selectedTask } = useSelector(
    (state) => state.customer
  );
  const outlookContacts = useSelector(
    (state) => state.discord_auth.outlookContacts
  );
  // slack_connectivity
  const outlook_calendar = useSelector((state) => state.discord_auth.outlook_calendar_connectivity);
  const isAssignedUsers = useSelector((state) => state.discord_auth.isAssignedUsers);
  const setContacts = useSelector(
    (state) => state.discord_auth.set_contact_data
  );
  const [isOutlookCalendarActive, setIsOutlookCalendarActive] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]); 
  const googlecalendar_connectivity = useSelector(
    (state) => state.google_calendar.googlecalendar_connectivity
  );
  const gcalendar_contacts = useSelector(
    (state) => state.google_calendar.gcalendar_contacts
  );
  const discord_topics = useSelector(
    (state) => state.discord_auth.topics
  );
  const slack_topics = useSelector(
    (state) => state.slack_auth.slacktopics
  );
  const mail_assignees = useSelector(
    (state) => state.customer.mail_assignees
  );
  let chat_connection = (slack_topics != undefined ) ? "slack_channel" : discord_topics ? "discord_channels" : "";
  let provider = googlecalendar_connectivity && googlecalendar_connectivity.data && googlecalendar_connectivity.data.calendar_type && googlecalendar_connectivity.data.calendar_type === 2 ? "google_calendars" : isOutlookCalendarActive === true ? "outlook_calendars" : "";
  let assigneeTypes = [];
  const virtualspace = localStorage.getItem("selectedVirtualSpace") ? "task_topic" : "myr_staff" ;
 if(window.location.pathname == '/calendar')
 {
  assigneeTypes = [
    "my_self",
    "myr_network",
    "user_journey",
    virtualspace,
    "equipments",
    'my_task',
    provider,
    chat_connection ? chat_connection : "",
  ];
 } else if(window.location.pathname == '/MyEmail'){
  if(moduleType == 'cc'){
    if(rel_type == 'operator'){
      assigneeTypes = [
        "my_peoples",
        "my_customers",
      ];
    }else if(rel_type == 'contact'){
      assigneeTypes = [
        "my_peoples",
        "provider_task"
      ];
    }else{
      assigneeTypes = [
        "myr_staff_task",
        "myr_customers",
        "myr_providers",
      ];
    }
  }else{
    assigneeTypes = [
      "my_mail_peoples",
    ];
  }
 } else if(window.location.pathname === '/documents'){
  assigneeTypes = [
    "my_self",
    "myr_staff",
    'my_task',
    'one_drive',
    'google_drive',
  ];
 } else {
  assigneeTypes = [
    "my_self",
    "current_providers",
    "my_peoples",
    "myr_network",
    "myr_staff",
    "equipments",
    'my_task',
    provider ? provider : "",
    chat_connection ? chat_connection : "",
  ];
 }
  if (pageName != '' && isDefaultTopicsDataAccess.indexOf(pageName) > -1) {
    assigneeTypes = ['my_task']
    assigneeTypes = assigneeTypes.concat(getAssigneeTypesForRequest());
    if(provider) {
      assigneeTypes.push(provider);
    }
    if(chat_connection) {
      assigneeTypes.push(chat_connection);
    }
    if (localStorage.getItem('selectedVirtualSpace')) {
      assigneeTypes = assigneeTypes.filter((type) => type != 'myr_staff_task')
    }
  }
  var docsAsigneeTypes = [];
  if(moduleType == 'cc'){
    if(rel_type == 'operator'){
       docsAsigneeTypes = [
        "my_customers",
        "my_people",
      ];
    }else if(rel_type == 'contact'){
      docsAsigneeTypes = [
        "my_people",
        "provider_task",
      ];
    }else{ 
       docsAsigneeTypes = [
        "myr_staff_task",
        "myr_customers",
        "myr_providers",
      ];
    }
  }else if(moduleType == 'gmail'){
    docsAsigneeTypes = [
      "my_mail_peoples"
    ];
  }else{
     docsAsigneeTypes = [
      "my_task",
      "my_people",
      provider,
    ];
  }
  window.addEventListener("outlook_calendar", (detail) => {
    setIsOutlookCalendarActive(true);
  });
  useEffect(() => {
    if (outlook_calendar != undefined) {
      if (outlook_calendar.status == 1) {
        setIsOutlookCalendarActive(true)
      }
    }
  }, [outlook_calendar])
  useEffect(() => {
    let AssigneeCalendars = {};
    const ObjectParse = {};
    const CalendarProviderslist = {};
    if (setContacts != undefined && setContacts.data.status == 1 && window.location.pathname == '/calendar') {
      outlookAssignee(taskId, calendar_id, AssigneeCalendars, ObjectParse, CalendarProviderslist);
    }
  }, [setContacts])
  useEffect(() => {
    if (providerDefaultType && providerDefaultValue && providerDefaultDetails) {
    }
    return () => {
      if (setOpenAssigneeDropdown) {
        setOpenAssigneeDropdown(false);
      }
    };
  }, []);
useEffect(() => {
  setAssigneeData([])
  setSelectedIds([])
  setTaskAssignee([])
}, [projectId])
  const outlookAssignee = (id, calendar_id, AssigneeCalendars, ObjectParse, CalendarProviderslist) => {
    outlookCalendar.getAssigneeCal(id, calendar_id, localStorage.getItem('selectedOffice')).then((res) => {
      if (res.status == 1) {
        let arr = [];
        if (res.data.contacts.length > 0) {
          ObjectParse['contacts'] = res.data.contacts;
          res.data.contacts.map((cnt, i) => {
            arr.push(cnt);
          })
        }
        else { ObjectParse['contacts'] = []; }
        if (res.data.assignee.length > 0) {
          ObjectParse['attendees'] = res.data.assignee;
          res.data.assignee.map((asn, i) => {
            arr.push(asn);
          })
        }
        else { ObjectParse['attendees'] = []; }
        ObjectParse['title'] = 'outlook_calendars';
        CalendarProviderslist[provider] = ObjectParse;
        AssigneeCalendars = { ...assigneeStateData, ...CalendarProviderslist };
        localStorage.setItem('outlook_contacts', JSON.stringify(arr));
        dispatch({
          type: OUTLOOK_CALENDAR_CONTACTS,
          payload: { AssigneeCalendars },
        });
      } else if (res.status == 0) {
        AssigneeCalendars = assigneeStateData;
        dispatch({
          type: OUTLOOK_CALENDAR_CONTACTS,
          payload: { AssigneeCalendars },
        });
      }
    })
  }
  const handleSelectAssignee = (type, value, details, is_tag = "") => {  
    if ((moduleType == "subscription" || moduleType == "estimate" || moduleType == "invoice" || moduleType == "cc") && handleProvider) {
      handleProvider(type, value, details);
    } else {
      if (isRequest) {
        handleRequestAssignee(type, value, details);
        return;
      }
      if (type === "google_calendars") {
        let remove_calendar_id = '';
        remove_calendar_id = selectedTask.calendar_id;
        if (value != 0) {
          dispatch(setCalendarsAssignee(taskId, remove_calendar_id, JSON.stringify(details)));
        }
      }
      if (type == "outlook_calendars") {
        let object = "";
        var data = JSON.parse(localStorage.getItem('outlook_contacts'));
        data.forEach((res, index) => {
          if (res.id == value && value != 0) {
            object = res.id;
            dispatch(set_contact_data(taskId, res.name, res.email, localStorage.getItem('selectedOffice'), selectedTask.calendar_id, 'outlook_calendar'));
          }
        })
        if (object == "" && value != 0) {
          dispatch(set_contact_data(taskId, value, value, localStorage.getItem('selectedOffice'), selectedTask.calendar_id, 'outlook_calendar'));
        }
      }
      if (type === "current_providers" && handleProvider) {
        handleProvider(type, value, details);
        return;
      }
      if(type == 'user_journey')
      {
        let selectedAssignees = {
          main_key: type,
          filter_key: details.id,
          name:details.name || "",
        };
        CustomerServices.setRequestTaskAssignee(taskId, [selectedAssignees]).then(
          (res) => {
            if (res.status && res.data && res.data.schedule_assignees) {
              dispatch(checkIsAssigned(taskId));
              dispatch(setTaskAssigneeNew({
                filter_key: details.id,
                title: details.name,
                image: details.profile_image || "",
              }, type, details.id, taskId))
            } 
          }
        );
      }
      if (is_tag != "" && type != 'user_journey') {
        let apiStatus = 0;
        if (selectedTagId == value) {
          CustomerServices.removeTag(taskId, projectId, value).then((res) => {
            if (res.status) {
              if (isCreateTask) {
                hangleTag([], 1);
              }
              dispatch(
                setTaskAssignee(
                  details,
                  type,
                  fromTaskcard ? taskId : null,
                  "remove"
                )
              );
            }
          });
        } else {
          CustomerServices.setTag(taskId, projectId, "", value).then((res) => {
            if (res.status) {
              if (isCreateTask) {
                hangleTag(details);
              }
              dispatch(
                setTaskAssignee(
                  details,
                  type,
                  fromTaskcard ? taskId : null,
                  "set"
                )
              );
            }
          });
        }
      } else {
        CustomerServices.setTaskAssignee(projectId, taskId, type, value).then(
          (res) => {
            if (res.status && res.data) {
              if(moduleType == 'gmail'){
                handleProvider(moduleType, value, details);
              }else{
                dispatch(getDefaultTaskAssignee(projectId, taskId));
                dispatch(
                  setTaskAssignee(details, type, fromTaskcard ? taskId : null)
                );
              }
              setSearchAssignees('');
            }
          }
        );
      }
    }
  };
  useEffect(() => {
    if (selectedTask && !isRequest) {
      let AssigneeCalendars = {};
      const ObjectParse = {};
      const CalendarProviderslist = {};
      if (googlecalendar_connectivity != undefined && googlecalendar_connectivity.data.calendar_type == 2) {
        ObjectParse['contacts'] = selectedTask.contactsGoogleCalendar;
        ObjectParse['attendees'] = selectedTask.attendees;
        ObjectParse['title'] = provider;
        CalendarProviderslist[provider] = ObjectParse;
        AssigneeCalendars = { ...assigneeStateData, ...CalendarProviderslist };
      } 
    }
  }, [selectedTask, gcalendar_contacts, googlecalendar_connectivity && googlecalendar_connectivity.data && googlecalendar_connectivity.data.calendar_type &&  googlecalendar_connectivity.data.calendar_type == 2, isAssignedUsers]);
  useEffect(() => {
    if (assigneeStateData && !isRequest) {
      let AssigneeCalendars = {};
      const ObjectParse = {};
      const CalendarProviderslist = {};
      if (googlecalendar_connectivity && googlecalendar_connectivity.data && googlecalendar_connectivity.data.calendar_type &&  googlecalendar_connectivity.data.calendar_type == 2) {
        if (selectedTask) {
          ObjectParse['contacts'] = selectedTask.contactsGoogleCalendar ? selectedTask.contactsGoogleCalendar : [];
          ObjectParse['attendees'] = selectedTask.attendees ? selectedTask.attendees : [];
          ObjectParse['title'] = provider;
          CalendarProviderslist[provider] = ObjectParse;
          AssigneeCalendars = { ...assigneeStateData, ...CalendarProviderslist };
        }
      } else if (isOutlookCalendarActive == true) {
        if (selectedTask && window.location.pathname == '/calendar') {
          outlookAssignee(selectedTask.id, selectedTask.calendar_id, AssigneeCalendars, ObjectParse, CalendarProviderslist);
        }
      } else if(selectedTask && selectedTask.provider === 'one_drive')
      {
        ObjectParse['contacts'] = selectedTask.contacts ? selectedTask.contacts : [];
          ObjectParse['attendees'] = selectedTask.assignee_list ? selectedTask.assignee_list : [];
          ObjectParse['title'] = selectedTask.provider;
          CalendarProviderslist[selectedTask.provider] = ObjectParse;
          AssigneeCalendars = { ...assigneeStateData, ...CalendarProviderslist };
      } else {
      }
    }
  }, [assigneeStateData, selectedTask]);
  useEffect(() => {
    if (taskId && projectId && openAssigneeDropdown) {
      if (pageName == '' || isDefaultTopicsDataAccess.indexOf(pageName) == -1) {
      }
    }
  }, [taskId, openAssigneeDropdown, projectId]);
  useEffect(() => {
    setDefaultTags([]);
    if (defaultTopicState && defaultTopicState.length) {
      const index = defaultTopicState.findIndex(
        (assignee) => assignee.main_key == "my_task"
      );
      if(index > -1)
      {
        setDefaultTags(
          defaultTopicState[index].categories
            .filter((tag) => tag.filter_key > 0)
            .sort((a, b) => {
              if (a.is_active > b.is_active) {
                return -1;
              }
              if (a.is_actve < b.is_active) {
                return 1;
              }
              return 0;
            })
        );
      }
    }
    if (moduleType == "subscription" || moduleType == "estimate" || moduleType == "invoice" || moduleType == "cc" || moduleType == 'gmail') {
      if(moduleType == 'cc'){
        let Arr = {};
        if(rel_type == 'operator'){
          Arr = {
            "my_people": defaultTopicState.filter(da => da.main_key == "my_people")[0],
            "my_customers": defaultTopicState.filter(da => da.main_key == "my_customers")[0]
          }
        }else if(rel_type == 'contact'){
          Arr = {
            "my_people": defaultTopicState.filter(da => da.main_key == "my_people")[0],
            "provider_task": defaultTopicState.filter(da => da.main_key == "provider_task")[0]
          }
        }else{
          Arr = {
            "myr_staff_task": defaultTopicState.filter(da => da.main_key == "myr_staff_task")[0],
            "myr_customers": defaultTopicState.filter(da => da.main_key == "myr_customers")[0],
            "myr_providers": defaultTopicState.filter(da => da.main_key == "myr_providers")[0]
          }
        }
        setDocsAssigneeData(Arr)
      }else if (defaultTopicState) {
        let Arr = {};
        if(moduleType == 'gmail'){
           Arr = {
            "my_mail_peoples": defaultTopicState.filter(da => da.main_key == "my_people" || da.main_key == "myr_staff_task")[0]
          }
        }else{
           Arr = {
            "my_task": defaultTopicState.filter(da => da.main_key == "my_task")[0],
            "my_people": defaultTopicState.filter(da => da.main_key == "my_people" || da.main_key == "myr_staff_task")[0]
          }
        }
        setDocsAssigneeData(Arr)
      }
    }
    if (pageName != '' && isDefaultTopicsDataAccess.indexOf(pageName) > -1) {
      let topicList = defaultTopicState ? defaultTopicState : [];
      const {taskList} = store.getState().customer;
      if(taskList && taskList.length)
      {
        const taskIndex = taskList.findIndex(w => w.id == taskId);
        if(taskIndex > -1 && taskList[taskIndex].schedule_id > 0)
        {
          topicList = topicList.filter((x) => x.main_key == "my_people")
        }
      }
      topicList.filter((d) => d.main_key == "equipments")[0] && topicList.filter((d) => d.main_key == "equipments")[0].categories.forEach((equipment) => {
        let subCategory = equipment.sub_category
        subCategory && subCategory.forEach((df) => {
          df.is_assigned = 0
        })
      })
      setAssigneeData(topicList);
    }
  }, [defaultTopicState]);
  //add assingee code
  const handleSelectAssigneeNew = (type, itemId, item, sub_category) => {
    let selectedAssignees = {
      main_key: type,
      filter_key: itemId,
      image: item.profile_image || "",
      name: item.name || "",
      sub_category: sub_category ? [sub_category] : []
    };
    let sub_categories = []
    if (sub_category) {
      sub_categories = [
        {
            main_key: type,
            categories: [
                {
                    filter_key: itemId,
                    categories: [sub_category].map((sc) => sc.id)
                }
            ]
        }
    ]
    }
    const userType =localStorage.getItem("user_type");
    const contact_role =localStorage.getItem("contact_role");
    if (handleProvider) {
      if (type == "provider_task" && userType == "contact" && CUSTOM_ASSIGNEE_CHANGE.includes(contact_role) && isRequest) {
        handleProvider(selectedAssignees,sub_categories,[sub_category]);
      }else{
        handleProvider(selectedAssignees);
      }
      return;
    } else if (type == "provider_task" && fromTaskcard == 0 && myr_total == 0) {
      handleProvider(selectedAssignees);
      return;
    } else if (type == "my_task" && handleProvider) {
      handleProvider(selectedAssignees);
      return;
    } else if (type == "my_people" && handleProvider) {
      handleProvider(selectedAssignees);
      return;
    } else if (type == "equipments" && handleProvider) {
      handleProvider(selectedAssignees,sub_categories,[sub_category]);
      return;
    } else if(type == 'outlook_calendars')
    {
      let object = "";
        var data = JSON.parse(localStorage.getItem('outlook_contacts'));
        data.forEach((res, index) => {
          if (res.id == itemId && itemId != 0) {
            object = res.id;
            dispatch(set_contact_data(taskId, res.name, res.email, localStorage.getItem('selectedOffice'), selectedTask.calendar_id, 'outlook_calendar'));
          }
        })
        if (object == "" && itemId != 0) {
          dispatch(set_contact_data(taskId, itemId, itemId, localStorage.getItem('selectedOffice'), selectedTask.calendar_id, 'outlook_calendar'));
        }
    } else if(type === 'google_calendars'){
        let remove_calendar_id = '';
        remove_calendar_id = selectedTask.calendar_id;
    } else {
      CustomerServices.setRequestTaskAssignee(taskId, [selectedAssignees], sub_categories,isRequest).then(
        (res) => {
          if (res && res.status && res.data && res.data.schedule_assignees) {
            dispatch(setTaskAssigneeNew({
              filter_key: itemId,
              title: item.name,
              image: item.profile_image || "",
            }, type, itemId, taskId, [sub_category],0,0,res.data.schedule_assignees,isRequest))
          } 
        }
      );
    }
  };
  useEffect(() => {
    if (selectedId) {
      setSelectedIds(selectedId)
    }
  }, [JSON.stringify(selectedId)])
  const handleAssigneSubmenu = (type)=>{
    setSearchAssignees("")
    if($('.w-200').hasClass('show'))
    {
      $('.overflowscroll').scrollTop(0)
    }
  }
  const actionHandle = (item) => {
    let assigneeList = assignee_list
    if (assigneeList && assigneeList.length > 0) {
      const index = assigneeList.findIndex(a => a.main_key == item.type);
      if (index > -1 ) {
        let isExist = assigneeList && assigneeList[index].categories.findIndex((taskassign) => taskassign.filter_key == item.id)
        if (isExist > -1) {
          assigneeList[index]['categories'] = assigneeList[index]['categories'].filter((taskassign) => taskassign.filter_key != item.id)
        } else {
          if (item.type == "provider_task") {
            assigneeList[index]['categories'] = [{ filter_key: item.id }]
          }else{
            assigneeList[index]['categories'].push({ filter_key: item.id })
          }
        }
      } else {
        assigneeList.push({
          main_key: item.type,
          categories: [{ filter_key: item.id }]
        })
      }
    } else {
      assigneeList = [{
        main_key: item.type,
        categories: [{ filter_key: item.id }]
      }]
    }
    setassignee_list(assigneeList.slice());
  }
  // set selected ids passed in props
  useEffect(() => {
    if (assignee_list.length > 0) {
      let task_assignee_ids = []
      assignee_list.map((ta) => {
        ta.categories && ta.categories.map((taskAssign) => {
          defaultTopicState &&
            defaultTopicState.forEach((topic) => {
              if (topic.main_key == ta.main_key) {
                topic.categories.forEach((provider) => {
                  if (taskAssign.filter_key == provider.filter_key) {
                    task_assignee_ids.push({
                      id: provider.filter_key,
                      name: topic.main_key,
                    });
                  }
                });
              }
            });
        });
      })
      setSelectedId(task_assignee_ids);
    }
  }, [JSON.stringify(assignee_list)])
  return (
    <DropdownMenu
      className="w-200"
      aria-labelledby="AddAssigneeMainDropdown"
    >
      {Object.keys(docsAssigneeData).length > 0 ?
        docsAsigneeTypes.map((type, index) => {
          if (docsAssigneeData[type]) {
            let docsAssigneeOptions = [];
            switch (type) {
              case "my_task":
                docsAssigneeData[type].categories.filter(c => c.filter_key > 0).forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.filter_key,
                    is_tag: 1,
                    is_assigned: moduleType == "subscription" ?
                      subscription_details && subscription_details.assignees && subscription_details.assignees["my_task"].filter(x => x.id == provider.filter_key).length ? 1 : 0
                      : moduleType == "estimate" ?
                        estimate_details && estimate_details.assignees && estimate_details.assignees["my_task"].filter(x => x.id == provider.filter_key).length ? 1 : 0
                        : moduleType == "invoice" ?
                          invoice_details && invoice_details.assignees && invoice_details.assignees["my_task"].filter(x => x.id == provider.filter_key).length ? 1 : 0
                          : 0,
                  });
                });
                break;
              case "my_people":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: moduleType == "cc" ? provider.email : provider.filter_key,
                    is_assigned: moduleType == "cc" ? provider.is_assigned : moduleType == "subscription" ?
                      subscription_details && subscription_details.assignees && subscription_details.assignees["my_people"].filter(x => x.id == provider.filter_key).length ? 1 : 0
                      : moduleType == "estimate" ?
                        estimate_details && estimate_details.assignees && estimate_details.assignees["my_people"].filter(x => x.id == provider.filter_key).length ? 1 : 0
                        : moduleType == "invoice" ?
                          invoice_details && invoice_details.assignees && invoice_details.assignees["my_people"].filter(x => x.id == provider.filter_key).length ? 1 : 0
                          : 0
                    ,
                    profile_image: provider.image || "",
                  });
                });
                break;
              case "my_mail_peoples":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.filter_key,
                    is_assigned: mail_assignees && mail_assignees.filter(x => x.shared_to == provider.filter_key).length ? 1 : 0,
                    profile_image: provider.image || "",
                  });
                });
                break;
              case "my_customers":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.email,
                    is_assigned: provider.is_assigned,
                    profile_image: provider.image || "",
                  });
                });
                break;
              case "provider_task":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.email,
                    is_assigned: provider.is_assigned,
                    profile_image: provider.image || "",
                  });
                });
                break;
              case "myr_customers":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.email,
                    is_assigned: provider.is_assigned,
                    profile_image: provider.image || "",
                  });
                });
                break;
              case "myr_providers":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.email,
                    is_assigned: provider.is_assigned,
                    profile_image: provider.image || "",
                  });
                });
                break;
              case "myr_staff_task":
                docsAssigneeData[type].categories.forEach((provider) => {
                  docsAssigneeOptions.push({
                    name: provider.title,
                    id: provider.email,
                    is_assigned: provider.is_assigned,
                    profile_image: provider.image || "",
                  });
                });
                break;
              default:
                break;
            }
            return (
              <React.Fragment key={index}>
                <li className={`hr_1`}>
                  <a href="#/" className="dropend w-100">
                    <div
                      className="d-flex align-items-center text-truncate dropdown-item custom-assigneesMain "
                      type="button"
                      id="CurrentProviderDropdown"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                      aria-expanded="false"
                      data-popper-placement="left-start"
                      data-assigneeMain={type}
                    >
                      <div className="w100minus10 text_wrap">
                        <div className="c-list-detail c-font f-14 text-truncate ">
                          {_l(docsAssigneeData[type].title)}
                        </div>
                      </div>
                      {
                        docsAssigneeOptions.filter(
                          (assignee) => parseInt(assignee.is_assigned) > 0
                        ).length ? (
                          <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                            <span className="m-auto p-0">
                              {
                                docsAssigneeOptions.filter(
                                  (assignee) => parseInt(assignee.is_assigned) > 0
                                ).length
                              }
                            </span>
                          </span>
                        ) : (
                          <></>
                        )
                      }
                      <div className="c-list-icon">
                        <CaretRightIcon className="HW14" />
                      </div>
                    </div>
                    <AssigneeSubMenu
                      data={docsAssigneeOptions}
                      type={type}
                      provider={provider}
                      handler={handleSelectAssignee}
                      searchAssignees={searchAssignees}
                      setSearchAssignees={setSearchAssignees}
                      isRequest={isRequest}
                    />
                  </a>
                </li>
              </React.Fragment>
            );
          }
        })
        : 
        assigneeData ? (
          <>
          {assigneeTypes.map((type, index) => {
            let filterData = assigneeData && assigneeData.length ? assigneeData.filter((assign) => assign.main_key == type) : [];
            let assignDataKey = []
            if (assigneeData[type] || (filterData && filterData.length && filterData[0]['main_key'] == type)) {
              let assigneeOptions = [];
              if (isRequest || isDefaultTopicsDataAccess.indexOf(pageName) > -1) {
                assignDataKey = assigneeData[type]
                if (isDefaultTopicsDataAccess.indexOf(pageName) > -1 && filterData && filterData.length) {
                  assignDataKey = filterData[0];
                }
                switch (type) {
                  case "provider_task":
                    let providerId = ""; 
                    assignDataKey.categories.forEach((provider) => {
                      let subCategory = []
                      selectedIds.forEach((se)=> {
                        if (provider.filter_key == se.id) {
                          providerId = provider.filter_key
                          se.sub_category && se.sub_category.map((sc) => {
                            subCategory.push({...sc,is_assigned : 1})
                          })
                        }
                      })
                      assigneeOptions.push({
                        name: provider.title,
                        id: provider.filter_key,
                        is_assigned: provider.is_assigned || 0,
                        profile_image: provider.image || "",
                        is_tag: 1,
                        subCategory:subCategory,
                        type:type
                      });
                    });
                    break;
                  case "my_people":
                    assignDataKey.categories.forEach((people) => {
                      if(rel_type == people.user_type && user_id == people.filter_key)
                      {
                        if(!isNaN(Number(taskId)))
                        {
                          assigneeOptions.push({
                            name: people.title,
                            id: people.filter_key,
                            is_assigned: people.is_assigned || 0,
                            profile_image: people.filter_key < 0 ? <UsersThreeIcon
                              className="HW18"
                            /> : people.image || '',
                            type:type,
                            disabled: true
                          });
                        }
                        else {
                          return false;
                        }
                      }
                      else {
                        assigneeOptions.push({
                          name: people.title,
                          id: people.filter_key,
                          is_assigned: people.is_assigned || 0,
                          profile_image: people.filter_key < 0 ? <UsersThreeIcon
                            className="HW18"
                          /> : people.image || '',
                          type:type,
                        });
                      }
                    });
                    break;
                  case "proposals":
                    assignDataKey.categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        is_tag: 1,
                      });
                    });
                    break;
                  case "user_journey":
                    assignDataKey.categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        is_tag: 1
                      });
                    });
                    break;
                  case "myr_staff_task":
                    assignDataKey.categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        type:type
                      });
                    });
                    break;
                  case "my_task":
                    assignDataKey.categories.forEach((my_task) => {
                      if (!isNaN(parseInt(my_task.filter_key)) && assigneeOptions.filter((assign) => assign.id == my_task.filter_key).length == 0 && parseInt(my_task.filter_key) > 0) {
                        assigneeOptions.push({
                          name: my_task.title,
                          id: my_task.filter_key,
                          is_assigned: my_task.is_assigned,
                          profile_image: my_task.image || "",
                          is_tag: 1,
                          type:type
                        });
                      }
                    });
                  case "task_topic":
                    if(assignDataKey.categories != undefined){
                      assignDataKey.categories.forEach((my_task) => {
                        assigneeOptions.push({
                          name: my_task.title,
                          id: my_task.filter_key,
                          is_assigned: my_task.is_assigned,
                          profile_image: my_task.image || "",
                          type:type
                        });
                      });
                    }
                    break;
                  case chat_connection:
                    if(assignDataKey != undefined){
                      let slack_assignees = [];
                      if(assignDataKey.slack != undefined) {
                        slack_assignees = assignDataKey.slack;
                      } else if(assignDataKey.categories != undefined) {
                        slack_assignees = assignDataKey.categories;
                      }
                      slack_assignees.forEach((provider) => {
                        assigneeOptions.push({
                          name: provider.title,
                          id: provider.filter_key,
                          is_assigned: provider.is_active || 0,
                          profile_image: "",
                        });
                      });
                    }
                  break;
                  case "equipments":
                    let equipmentId = ""; 
                    assignDataKey.categories.forEach((equipment) => {
                      let subCategory = equipment.sub_category
                      selectedIds.forEach((se)=> {
                        if (equipment.filter_key == se.id) {
                          equipmentId = equipment.filter_key
                          let selectedSubCate =  se.sub_category ? se.sub_category : []
                            subCategory.forEach((df) => {
                              if (selectedSubCate.includes(df.id)) {
                                df.is_assigned = 1
                              }else{
                                df.is_assigned = 0
                              }
                            })
                        }
                        if (equipmentId != equipment.filter_key) {
                          subCategory && subCategory.forEach((df) => {
                            df.is_assigned = 0
                        })
                        }
                      })
                      if (!isNaN(parseInt(equipment.filter_key)) && assigneeOptions.filter((assign) => assign.id == equipment.filter_key).length == 0 && parseInt(equipment.filter_key) > 0) {
                        assigneeOptions.push({
                          name: equipment.title,
                          id: equipment.filter_key,
                          is_assigned: equipment.is_assigned,
                          profile_image: equipment.image || "",
                          is_tag: 1,
                          subCategory: subCategory,
                          type:type
                        });
                      }
                    });
                  default:
                    break;
                }
              } else {
                switch (type) {
                  case "my_self":
                    assigneeOptions = (moduleType == "subscription" || moduleType == "estimate" || moduleType == "invoice") ? [] : [
                      {
                        name: "To Me",
                        email: localStorage.getItem("email"),
                        id: 1,
                        is_assigned: assigneeData[type].is_assigned,
                        profile_image: assigneeData[type].profile_image || "",
                        type:type
                      },
                    ];
                    defaultTags.forEach((tag) => {
                      assigneeOptions.push({
                        name: tag.title,
                        id: tag.filter_key,
                        is_assigned: tag.filter_key == selectedTagId ? 1 : 0,
                        is_tag: 1,
                        type:type
                      });
                    });
                    break;
                  case "current_providers":
                    assigneeData[type].providers.forEach((provider) => {
                      assigneeOptions.push({
                        name: provider.name,
                        id: provider.email,
                        is_assigned: provider.is_assigned || 0,
                        profile_image: provider.profile_image || "",
                        type:type
                      });
                    });
                    break;
                  case "my_peoples":
                    assigneeData[type].employees.forEach((employee) => {
                      assigneeOptions.push({
                        name: employee.name,
                        id: employee.contact_id,
                        is_assigned: employee.is_assigned || 0,
                        profile_image: employee.profile_image || "",
                        type:type
                      });
                    });
                    break;
                  case "user_journey":
                    assigneeData[type].categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        is_tag: 1,
                        type:type
                      });
                    });
                    break;
                  case "myr_staff":
                    assigneeData[type].staff.forEach((employee) => {
                      assigneeOptions.push({
                        name: employee.name,
                        id: employee.staffid,
                        is_assigned: employee.is_assigned || 0,
                        profile_image: employee.profile_image || "",
                        type:type
                      });
                    });
                    break;
                  case "equipments":
                    assigneeData[type].equipments.forEach((equipment) => {
                      assigneeOptions.push({
                        name: equipment.equipment_name,
                        id: equipment.equipment_id,
                        is_assigned: equipment.is_assigned || 0,
                        profile_image: equipment.profile_image || "",
                        subCategory: equipment.sub_category,
                        type:type
                      });
                    });
                    break;
                  case "task_topic":
                    assigneeData[type].tasks.categories.forEach((proposal) => {
                      assigneeOptions.push({
                        name: proposal.title,
                        id: proposal.filter_key,
                        is_assigned: proposal.is_assigned || 0,
                        profile_image: proposal.image || "",
                        type:type
                      });
                    });
                    break;
                  case "myr_network":
                    assigneeData[type].providers.forEach((provider) => {
                      assigneeOptions.push({
                        name: provider.name,
                        id: provider.provider_id,
                        is_assigned: provider.is_assigned || 0,
                        profile_image: provider.profile_image || "",
                        type:type
                      });
                    });
                    break; 
                  case provider:
                    if (assigneeData[type].contacts || assigneeData[type].attendees) {
                      assigneeOptions.push({
                        name: 'Selected Contacts',
                        id: 0
                      });
                      assigneeData[type].attendees.forEach((attendees) => {
                        assigneeOptions.push({
                          name: attendees.name || attendees.email,
                          email: attendees.email,
                          id: attendees.id || 0,
                          is_assigned: attendees.is_assigned || 0,
                          profile_image: attendees.profileImage || "",
                        });
                      });
                      assigneeOptions.push({
                        name: 'Suggested Contacts',
                        id: 0
                      });
                      assigneeData[type].contacts.forEach((contacts) => {
                        assigneeOptions.push({
                          name: contacts.name || contacts.email,
                          id: contacts.id || 0,
                          email: contacts.email,
                          is_assigned: contacts.is_assigned || 0,
                          profile_image: contacts.profileImage || "",
                        });
                      });
                    }
                      break;
                      case 'one_drive':
                    if (jQuery.isArray(assigneeData[type].contacts) && jQuery.isArray(assigneeData[type].attendees)) {
                      assigneeOptions.push({
                        name: 'Selected Contacts',
                        id: 0
                      });
                      assigneeData[type].attendees.forEach((attendees) => {
                        assigneeOptions.push({
                          name: attendees.name || attendees.email,
                          email: attendees.email,
                          id: attendees.id || 0,
                          is_assigned: attendees.is_assigned || 0,
                          profile_image: attendees.profileImage || "",
                        });
                      });
                      assigneeOptions.push({
                        name: 'Suggested Contacts',
                        id: 0
                      });
                      assigneeData[type].contacts.forEach((contacts) => {
                        assigneeOptions.push({
                          name: contacts.name || contacts.email,
                          id: contacts.id || 0,
                          email: contacts.email,
                          is_assigned: contacts.is_assigned || 0,
                          profile_image: contacts.profileImage || "",
                        });
                      });
                    }
                      break;
                  case chat_connection:
                    if(assigneeData[type] != undefined){
                      let slack_assignees = [];
                      if(assigneeData[type].slack != undefined) {
                        slack_assignees = assigneeData[type].slack;
                      } else if(assigneeData[type].categories != undefined) {
                        slack_assignees = assigneeData[type].categories;
                      }
                      slack_assignees.forEach((provider) => {
                        assigneeOptions.push({
                          name: provider.title,
                          id: provider.filter_key,
                          is_assigned: provider.is_active || 0,
                          profile_image: "",
                        });
                      });
                    }
                  break;
                  default:
                    break;
                }
              }
              return (
                <>
                                    <UncontrolledDropdown setActiveFromChild={true} direction="end" className="w-200">
                                        <DropdownToggle toggle="false" className="w-100 hr_sepretr">
                                        <a href="#/" className={`${direction == "left" ? "dropstart" :"dropend"} w-100`}>
                      <div
                        className="d-flex align-items-center text-truncate dropdown-item custom-assigneesMain "
                        type="button"
                        id="CurrentProviderDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                        data-popper-placement="left-start"
                        data-assigneeMain={type}
                        onClick={() => {
                        handleAssigneSubmenu(type)}}
                      >
                        <div className="c-list-icon">
                          <div className="h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle">
                            <div className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle d-flex">
                              <HashIcon className="HW16" />
                            </div>
                          </div>
                        </div>
                        <div className="ps-2 w100minus40 text_wrap">
                          <div className="c-list-detail c-font f-14 text-truncate ">
                            {(assigneeData[type] && _l(assigneeData[type].title)) || (assignDataKey && _l(assignDataKey.title))}
                          </div>
                        </div>
                   {(pageName == '' || isDefaultTopicsDataAccess.indexOf(pageName) == -1) && assigneeOptions.filter(
                          (assignee) => parseInt(assignee.is_assigned) > 0
                        ).length
                          ? (
                            <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                              <span className="m-auto p-0">
                                {
                                  assigneeOptions.filter(
                                    (assignee) => parseInt(assignee.is_assigned) > 0
                                  ).length
                                }
                              </span>
                            </span>
                          ) : (
                              isDefaultTopicsDataAccess.indexOf(pageName) > -1 && selectedIds.length && selectedIds.filter((select) => select.name == type).length > 0 ?
                                assignDataKey.main_key == "provider_task" && isRequest ?
                                  assigneeOptions.map((ss) =>
                                    ss.subCategory.length > 0 ?
                                      <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                        <span className="m-auto p-0">
                                          {1}
                                        </span></span>
                                      :
                                      <></>
                                  )
                                  :
                                  <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                    <span className="m-auto p-0">
                                      {selectedIds.filter((select) => select.name == type).length}
                                    </span>
                                  </span>
                                : <>{assigneeData[type] ? selected.filter((ss) => ss.type == assigneeData[type].title).length : ""}</>
                            )}
                        <div className="c-list-icon">
                            <CaretRightIcon className="HW14" />
                          </div>
                      </div>
                      </a>
                                        </DropdownToggle>
                                               <AssigneeSubMenu
                        data={assigneeOptions}
                        type={type}
                        provider={provider}
                        handler={(type, itemId, item, isTag = '',sub) => {
                          if (pageName != '' && isDefaultTopicsDataAccess.indexOf(pageName) > -1) {
                            handleSelectAssigneeNew(type, itemId, item, sub)
                          } else {
                            handleSelectAssignee(type, itemId, item, isTag)
                          }
                        }}
                        searchAssignees={searchAssignees}
                        setSearchAssignees={setSearchAssignees}
                        selectedId={selectedIds}
                        actionHandle={actionHandle}
                        action={action}
                        bulkAction={bulkAction}
                        dropDownToggle={dropDownToggle}
                        isRequest={isRequest}
                      />
                                    </UncontrolledDropdown>
                                    </>
              );
            }
          })}
          { bulkAction ? 
            <div className="d-flex justify-content-end p-2">
              <Button variant="secondary" className="border w-100 p-1 text-center" onClick={() => actionStausChange(0, assignee_list, "set_assignee")} size="sm">{_l("l_save")}</Button>
            </div>
           : <></>
          } 
          { actionType == "task_from_chat" || actionType == "request_from_chat" ?
            <li  className="hr_sepretr" >
              <div className="dropdown-item d-flex justify-content-end no-hover">
                <Button variant="primary" size="sm" className={`${actionBtnLoader ? "for-disabled" : ""}`} onClick={() => { actionStausChange(actionTypeId, assignee_list, actionType == "request_from_chat" ? 1 : 0); }}>
                  {actionBtnLoader ? _l("l_please_wait") : actionType == "request_from_chat" ? _l("l_create_request") : _l("l_create_task")}
                  {actionBtnLoader ? <Spinner as="span" animation="border" size="sm" role="status" className="ms-2" aria-hidden="true" /> : <></> }
                </Button>
              </div>
            </li>
            : <></>
          }
      </>
        ) : (
          <></>
        )}
    </DropdownMenu>
  );
};
export default AddAssigneeMainDropdown;
