import React from 'react';
 

function FigmaLogoIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={`comman-icons ${CommanHW}`}>
<path fillRule="evenodd" clipRule="evenodd" d="M3.53707 3.64645C3.63084 3.55268 3.75802 3.5 3.89062 3.5H12.3906C12.6668 3.5 12.8906 3.27614 12.8906 3C12.8906 2.72386 12.6668 2.5 12.3906 2.5H3.89062C3.4928 2.5 3.11127 2.65804 2.82996 2.93934C2.54866 3.22064 2.39062 3.60218 2.39062 4V12C2.39062 12.3978 2.54866 12.7794 2.82996 13.0607C3.11127 13.342 3.4928 13.5 3.89062 13.5H13.8906C14.1558 13.5 14.4102 13.3946 14.5977 13.2071C14.7853 13.0196 14.8906 12.7652 14.8906 12.5V5.5C14.8906 5.23478 14.7853 4.98043 14.5977 4.79289C14.4102 4.60536 14.1558 4.5 13.8906 4.5H3.89062C3.75802 4.5 3.63084 4.44732 3.53707 4.35355C3.4433 4.25978 3.39062 4.13261 3.39062 4C3.39062 3.86739 3.4433 3.74021 3.53707 3.64645ZM3.39062 12V5.41421C3.54973 5.47047 3.71865 5.5 3.89062 5.5H13.8906V12.5H3.89062C3.75802 12.5 3.63084 12.4473 3.53707 12.3536C3.4433 12.2598 3.39062 12.1326 3.39062 12ZM12.2656 9C12.2656 9.34518 11.9858 9.625 11.6406 9.625C11.2954 9.625 11.0156 9.34518 11.0156 9C11.0156 8.65482 11.2954 8.375 11.6406 8.375C11.9858 8.375 12.2656 8.65482 12.2656 9Z" />
</svg>


		</React.Fragment>
	)
}

export default FigmaLogoIcon