import React from 'react'

const EditProfileDataSkeleton = () => {
    return (
        <div className='task-list-card list-view taskcardliteversion SkeletonMain'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <div className='ms-10px flex-grow-1'>
                        <div className='skeleton-animation radius_3' style={{ width: '15%', height: '12px' }}></div>
                    </div>
                </div>
                <div className='d-flex align-items-cente gap10'>
                    <div className='h32w32 skeleton-animation radius_3'></div>
                    <div className='h32w32 skeleton-animation radius_3'></div>
                </div>
            </div>
        </div>
    )

}

export default EditProfileDataSkeleton