import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {_l } from "../../hooks/utilities";
import benchmarkServices from "../../services/benchmark-services";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { Form } from "react-bootstrap";

const ProposalCustomeFiledModal = ({ show, handleClose, data }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l('l_additional_fields')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
              <div className="form-wrapper-main">
              {
                data && data.custom_fields.length > 0 ?
                  data.custom_fields.map((item) => {
                    return (
                      <div className="border-bottom mb-10px pb-10px pt-10px">
                        <div className="color-white-60 f-14 mb-1">{item.title}</div>
                        <div className="d-flex align-items-center fw-semibold">{item.value}</div>
                      </div>
                    );
                  })
                  :
                  <CommanPlaceholder imgType="todo-task" placeholderText = {_l("l_no_items_found")} />
              }
              </div>
            
        </Modal.Body>
        <Modal.Footer className="d-none">
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProposalCustomeFiledModal;
