export class ChatModel {
  selectedProject: number | string =
    localStorage.getItem("selectedOffice") || 0;
  groupList: any[] = [];
  groupListExternal: any[] = [];
  contactDirMsgCount: number = 0;
  messageList: any[] = [];
  otherUserStatus: number = 0;
  newMessage: any[] = [];
  uploadFileName: string = "";
  userOnline: any[] = [];
  userOffline: any[] = [];
  unreadMsgCount: number = 0;
  readAllGrpMsgBy: any[] = [];
  viewGroupList: any[] = [];
  newTaskNoti: any[] = [];
  allProjUnreadMsgCnt: any[] = [];
  newMsgForAM: any[] = [];
  generalMsgCount: number = 0;
  openGeneralChatGroup: boolean = false;
  callStatus: any[] = [];
  myStream: any[] = [];
  remoteStream: any[] = [];
  groupDetails: object = {};
  callTaskAPI: object = {};
  stopScrolling:boolean = false;
  typingMembers: any[] = [];
  isCallRecived: boolean = false;
  chatSocket : object = {};
}