import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { _l, showError, showSuccessMessage } from "../hooks/utilities";
import AuthServices from "../services/auth-services";
import EyeSlashIcon from "../assets/icons/EyeSlashIcon";
import EyeIcon from "../assets/icons/EyeIcon";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {isLoggedIn} = useSelector((state) => state.auth);

  const [searchParams] = useSearchParams();
  const key = searchParams.get("hash");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLinkValid = async () => {
    try {
      const response = await AuthServices.validatePasswordResetLink(key);
      if (response.status != 1) {
        showError("l_invalid_link");
        navigate("/login");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetPassword = async() => {
    if(!password.length)
    {
        showError("l_password_can_not_be_empty");
    }
    else if(!confirmPassword.length)
    {
        showError("l_confirm_password_can_not_be_empty");
    }
    else if(password.trim() !== confirmPassword.trim())
    {
        showError("l_password_and_confirm_passwords_mismatch");
    }
    else {
        try {
            setIsLoading(true);
            const response = await AuthServices.validatePasswordResetLink(key);
            if(response.status != 1)
            {
                showError(response.message);
                navigate("/login");
            }
            else {
                const responseOfReset = await AuthServices.resetUserPassword(key, password);
                if(responseOfReset.status == 1)
                {
                    showSuccessMessage("l_reset_password_successful");
                    if(responseOfReset.data && responseOfReset.data.email)
                    {
                      dispatch(login(responseOfReset.data.email, password));
                    }
                }
                else {
                    showError(responseOfReset.message);
                }
            }
        }
        catch(e)
        {
            showError("l_something_wrong");
        }
        finally {
            setIsLoading(false);
        }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/");
    }

    isLinkValid();
  }, []);

  useEffect(() => {
    if(isLoggedIn)
    {
      navigate(`/`);
    }
  }, [isLoggedIn]);
  

  return (
    <div>
      <div className="login_page">
        <div className="login_box">
          <div className="comman_title_white">{_l("l_reset_password")}</div>
          <div className="custom_input_box_main">
            <div className="custom_input_box  with_before">
              <label className="input_box_label">
                <div className="title_text color-white-60">
                  {_l("l_password")}
                  <span className="star_img">*</span>
                </div>
              </label>
              <div className="d-flex align-items-center justify-content-between">
              <input
                type={passwordShown ? "text" : "password"}
                className="input_box  autofill_off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={`********`}
                autoComplete="off"
              />
               <a href="#/" className="">
                  <div
                    className={`img-width-18 ${passwordShown ? "" : "d-none"}`}
                    data-bs-toggle="tooltip"
                    data-bs-title="Hide password"
                    onClick={() => {
                      setPasswordShown((pre) => !passwordShown);
                    }}
                  >
                    <EyeSlashIcon className="HW18" />
                  </div>
                  <div
                    className={`img-width-18 ${passwordShown ? "d-none" : ""}`}
                    data-bs-toggle="tooltip"
                    data-bs-title="Show password"
                    onClick={() => {
                      setPasswordShown((pre) => !passwordShown);
                    }}
                  >
                    <EyeIcon className="HW18" />
                  </div>
                </a>
              </div>
            </div>
            <div className="custom_input_box  with_before">
              <label className="input_box_label">
                <div className="title_text color-white-60">
                  {_l("l_confirm_password")}
                  <span className="star_img">*</span>
                </div>
              </label>
              <div className="d-flex align-items-center justify-content-between">
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  className="input_box  autofill_off"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder={`********`}
                  autoComplete="off"
                />
                <a href="#/" className="">
                  <div
                    className={`img-width-18 ${confirmPasswordShown ? "" : "d-none"}`}
                    data-bs-toggle="tooltip"
                    data-bs-title="Hide password"
                    onClick={() => {
                      setConfirmPasswordShown((pre) => !confirmPasswordShown);
                    }}
                  >
                    <EyeSlashIcon className="HW18" />
                  </div>
                  <div
                    className={`img-width-18 ${confirmPasswordShown ? "d-none" : ""}`}
                    data-bs-toggle="tooltip"
                    data-bs-title="Show password"
                    onClick={() => {
                      setConfirmPasswordShown((pre) => !confirmPasswordShown);
                    }}
                  >
                    <EyeIcon className="HW18" />
                  </div>
                </a>
              </div>
            </div>
            <div className="mtop20"></div>
            <div className="login_btn d-flex align-items-center justify-content-between">
              <div>
                <button
                  className="btn btn-primary me-4"
                  onClick={handleResetPassword}
                  disabled={isLoading}
                >
                  {isLoading ? _l("l_please_wait") : _l("l_reset")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
