import React, { useState } from "react";

 const Paytm = ({ projectId, clientId, location, zoomLevel }) => {

  const [selectedTab, setSelectedTab] = useState("cardtype");
 return (
    <div className="d-flex flex-column h-100">
            <div className="tab-wrapper_main custom-modal-section-sticky">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                    <div className="comman-tab-wrapper pb-0">
                        <div className="d-flex align-items-center">
                            
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "cardtype" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("cardtype");
                                    
                                }}
                                >
                                Card Type
                                </a>
                            </div>
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "carddetails" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("carddetails");
                                    
                                }}
                                >
                                Card Details
                                </a>
                            </div>
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center ${selectedTab === "address" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("address");
                                    
                                }}
                                >
                                Address
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="task-header-right comman-tab-wrapper p-0"
                    >
                    </div>
                </div>
            </div>
        <div className="bg-white-03 mt-2 px-2 radius_3 res-overflow-auto h-100">
            {selectedTab === "cardtype" ? (
                <></>
                ) : selectedTab === "carddetails" ? (
                <></>
                ) : (
                <></>
            )}
        </div>
        <div className="comman-content-bottom-btn d-flex justify-content-between mt-auto custom-modal-section-sticky py-2 align-items-center" style={{bottom: "-1px"}}>
            <a href="#/" className="btn btn-secondary me-3">Back</a>
            <a href="#/" className="btn btn-primary">Next</a>
        </div>
    </div>
  );
 };
 export default Paytm;