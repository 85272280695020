import React, { useEffect, useState } from 'react'
import { _l, fetchMydriveData, getDocumentsFromIDB, getFileType, getPreviewFromURL, saveDocumentDataToIDB, searchObjects, showError } from '../../hooks/utilities'
import CommanPlaceholder from '../Placeholder/CommanPlaceholder';
import { useDispatch, useSelector } from 'react-redux';
import documentsServices from '../../services/documents-services';
import EstimateIcon from '../../assets/icons/EstimateIcon';
import ArrowClockwise from '../../assets/icons/ArrowClockwise';
import LeaseCard from '../Documents/tabs/LeaseCard';
import GeneralCardView from '../Documents/GeneralCard';
import moment from 'moment';
import TaskCard from '../TaskCard';
import { Form } from 'react-bootstrap';
import { showConfirmation, showMessage, toastCloseAction } from '../../actions/messages';
import { CardView } from '../Documents/CardView';
import leaseServices from '../../services/lease-services';
import UploadDocument from '../Modals/UploadDocument';
import DocumentPreview from '../Documents/DocumentPreview';
import customerServices from '../../services/customer-services';
import CommanOffCanvas from '../Offcanvas/CommanOffCanvas';
import DocumentModal from '../Modals/DocumentModal';
import { setLeaseDocumentsList, getIDBDocData } from '../../actions/customer';
import DownloadSimpleIcon from '../../assets/icons/DownloadSimpleIcon';
import FileTextIcon from '../../assets/icons/FileTextIcon';
import FileIcon from '../../assets/icons/FileIcon';
import FileCloudIcon from '../../assets/icons/FileCloudIcon';
import CaretDoubleDownIcon from '../../assets/icons/CaretDoubleDownIcon';
import FilesIcon from '../../assets/icons/FilesIcon';
import MydriveDocumentPage from './MydriveDocumentPage';
import EditProfileManageTaskListSkeleton from '../Skeleton/EditProfileManageTaskListSkeleton';
let newRelTypeId ;
const MyDrivePage = ({
  guestUserModal = false,
  project_id = 0,
  docModalViewToggle = (documentData) => {},
  }) => {
    const topicsSelected = () => {
      let topicList = [];
        if (window.location.pathname == "/dashboard" || guestUserModal) {
            topicList = [
                {
                key: "invoice",
                name: "l_invoices",
                icon: FileTextIcon,
                },
                {
                key: "estimate",
                name: "l_estimates",
                icon: EstimateIcon,
                },
                {
                key: "credit_notes",
                name: "l_credit_note",
                icon: FileIcon,
                },
                {
                key: "subscription",
                name: "l_subscriptions",
                icon: ArrowClockwise,
                },
            ];
            if (window.location.pathname == "/dashboard") {
                const spaceTab = [{
                key: "space",
                name: "l_space_document",
                icon: FileCloudIcon,
                }]
                topicList = topicList.concat(spaceTab)  
            }
            } else {
            topicList = [
            {
                key: "invoice",
                name: "l_invoices",
                icon: FileTextIcon,
            },
            {
                key: "estimate",
                name: "l_estimates",
                icon: EstimateIcon,
            },
            {
                key: "credit_notes",
                name: "l_credit_note",
                icon: FileIcon,
            },
            {
                key: "subscription",
                name: "l_subscriptions",
                icon: ArrowClockwise,
            },
            {
                key: "google_drive",
                name: "l_documents",
                icon: FileIcon,
            },
            {
                key: "space",
                name: "l_space_document",
                icon: FileCloudIcon,
            },
            {
                key: "lease_document",
                name: "l_lease_document",
                icon: FilesIcon,
            },
            ];
            }
            if (userType == "operator") {
              const payoutsTab = [{
                key: "payouts",
                name: "l_payouts",
                icon: FileCloudIcon,
              }]
              topicList = topicList.concat(payoutsTab)  
            }
            return topicList;
    }
    const { myDriveTopic, leftPanelTab, globalSearchString, daterangeAppliedFilters, docBoolean } = useSelector((state) => state.customer);
    const spaceState = useSelector((state) => state.customer.spaces);

    const [listData, setListData] = useState({"l_invoices" : []});
    const [paginationState, setPaginationState] = useState({});
    const [selectedTopic, setSelectedTopic] = useState({});
    const [sectionList, setSectionList] = useState(topicsSelected());
    const [selectedCategory, setSelectedCategory] = useState({
        id: 1,
        title: "",
        is_active: "",
        main_sub_key: "",
      });
    const TOPICS_WITH_ALL_FILTERS = ["documents"];
    const projectId =
    project_id > 0 ? project_id : localStorage.getItem("selectedOffice");
    const userType = localStorage.getItem("user_type");
    const contact_id = localStorage.getItem("contact_id");
    const client_id = localStorage.getItem("client_id");
    const staff_id = localStorage.getItem("staff_id");
    const loginUserRole = localStorage.getItem("contact_role")
        ? localStorage.getItem("contact_role")
        : 0;
    const isExternal = localStorage.getItem("guest_id") ? true : false
    const [dashBoardDocData, setDashBoardDocData] = useState([]);
    const [overWriteMainModal, setOverWriteMainModal] = useState(false);
    const [showDocumentUpload, setShowDocumentUpload] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});
    const [openLightBox, setOpenLightBox] = useState(false);
    const [lightBoxData, setlightBoxData] = useState([]);
    const [attachmentDetail, setAttachmentDetail] = useState({});
    const [documentMiniModalData, setDocumentMiniModalData] = useState({});
    const [leaseDocumentDeatails, setLeaseDocumentDeatails] = useState({
      leaseStartDate : "",
      leaseEndDate : "",
      noticeDates : [{ notice_date: ""}],
      isRentingorOwning : "renting",
      amount : "",
      isMonthlyOrAnnualli : "monthly",
      termandCondition : "",
      selectedServices : [],
      selectedEquipments : [],
      selectedResidents : [],
      space_type : 0
    });
    const [documents, setDocuments] = useState([]);
    const [topics, setTopics] = useState();
    const [spaceBasicDetails, setSpaceBasicDetails] = useState({})
    const [isSkeltonShow, setIsSkeltonShow] = useState(false);


    let selectedDate = localStorage.getItem('selectedDate');
    let fromDate = localStorage.getItem('fromDate');
    let toDate = localStorage.getItem('toDate');

    const data = {
      id: 1,
      title: "",
      is_active: "",
      main_sub_key: "",
    }
    const dispatch = useDispatch()

    const changeTopic = (topic) => {
        setSelectedTopic(topic);
        switch (topic.key) {
         case "credit_notes":
            getDriveData("credit_notes");
            break;
          case "estimate":
            getDriveData("estimate");
            break;
          case "invoice":
            getDriveData("invoice");
            break;
          case "space":
            getDriveData("space");
            break;
          case "subscription":
            getDriveData("subscription");
            break;
          case "google_drive":
            getDriveData("documents", -1 ,true);
            break;
          case "lease_document":
            setListData({ l_lease_document: [] });
            setPaginationState({ l_lease_document: 1 });
            getDriveData("lease_document");
            break;
          case "payouts":
            getDriveData("payouts");
          default:
            setListData({});
            break;
        }
      };

    const getDriveData = async (rel_type = "", document_rel_id = "",topicDoc) => {
      setIsSkeltonShow(true);
        try {    
          let filters = [];
    
          if (TOPICS_WITH_ALL_FILTERS.includes("documents")) {
            filters = [];
          } else if (selectedCategory.id !== -1) {
            filters = [
              {
                main_key: "documents",
                filter_keys: [
                  {
                    filter_key: selectedCategory.id,
                    main_sub_key: '',
                  },
                ],
              },
            ];
          }
          let response;
          if (window.location.pathname == "/dashboard" || guestUserModal || !localStorage.getItem("selectedOffice") || leftPanelTab != "my-space") {
            response = await getDashboardDriveData(rel_type)
          } else {
            response = await getDocumentsFromIDB(
              projectId,
              filters,
              rel_type,
              document_rel_id
            );
          }
          if (response.status) {
            if (rel_type && rel_type !== "documents") {
              const documentData = response.data[rel_type];
              if (rel_type === "credit_notes") {
                documentData.forEach((credit_note) => {
                  credit_note.name = credit_note.credit_note_number;
                });
              }
              const listKey = sectionList.find((w) => w.key === rel_type);
              
              setListData({
                [listKey["name"]]: documentData && documentData.length ? documentData : [],
              });
              setPaginationState({
                [listKey["name"]]: 1,
              });    
            } else {
              const {
                credit_notes,
                estimate,
                invoice,
                space,
                subscription,
                google_drive,
                documents,
                payouts
              } = response.data;
              if (topicDoc) {
                setListData({
                  l_documents:  documents ? documents : [] ,
                });
                
              } else {                     
              
              setListData({
                l_documents: google_drive ? google_drive : [] ,
                l_estimates: estimate ? estimate : [] ,
                l_space_document: space ? space : [] ,
                l_subscriptions: subscription ? subscription : [] ,
                l_invoice: invoice ? invoice : [] ,
                l_payouts : payouts ? payouts : [] ,
                l_credit_note: credit_notes ? credit_notes.map((credit_note) => {
                  return {
                    ...credit_note,
                    name: credit_note.credit_note_number,
                  };
                }) : [] ,
              });
            }
              setPaginationState({
                l_documents: 1,
                l_estimates: 1,
                l_space_document: 1,
                l_subscriptions: 1,
                l_invoice: 1,
                l_credit_note: 1,
                l_payouts : 1,
              });
            }
          }
          else {
            setListData({
              l_documents: [],
              l_estimates: [],
              l_space_document: [],
              l_subscriptions: [],
              l_invoice: [],
              l_credit_note: [],
              l_payouts :[],
            });
            setPaginationState({
              l_documents: 1,
              l_estimates: 1,
              l_space_document: 1,
              l_subscriptions: 1,
              l_invoice: 1,
              l_credit_note: 1,
              l_payouts:1,
            });
          }
        } catch (e) {
          console.error(e);
        }
        setIsSkeltonShow(false);
      };

    const getDashboardDriveData = async (rel_type) => {
        const userHashId = isExternal || guestUserModal ? localStorage.getItem("searchParamUserId") : localStorage.getItem("user_hash_id")
        let currentRelTypeId = crypto.randomUUID();
        newRelTypeId = currentRelTypeId;
        try {
          const documentKeys = [
            "subscription",
            "space",
            "estimate",
            "invoice",
            "credit_notes",
            "documents",
            "lease_document",
            "payouts"
          ];
          let formattedResponse = {};
      
          documentKeys.forEach(key => {
            formattedResponse[key] = [];
          });
          if(dashBoardDocData.length == 0 || !dashBoardDocData.length̉ )
          {
              const newDocumentsResponse = await documentsServices.getDocumentsForDashboard(
                1,
                userHashId,
                1,
                rel_type == "documents" ? "other_documents" :rel_type,
              );
              if(newDocumentsResponse.status)
              {
                saveDocumentDataToIDB(newDocumentsResponse.data,()=>{}, false, rel_type == "documents" ? "other_documents" :rel_type);
                if (newRelTypeId == currentRelTypeId) { 
                setDashBoardDocData(newDocumentsResponse.data);
                // setListData({"l_invoices" : newDocumentsResponse.data[rel_type]})
                // setPaginationState({
                //     "l_invoices": 1,
                //   });
                formattedResponse[rel_type] = newDocumentsResponse.data[rel_type === "documents" ? "topic_document" : rel_type];
                return {status: 1, data: formattedResponse};
                }
            }
          }
          else {
            formattedResponse[rel_type] = dashBoardDocData[rel_type === "documents" ? "topic_document" : rel_type];
            return {status: 1, data: formattedResponse};
          }
        }
        catch(e)
        {
          console.error(e);
          return {status: 0, data: []};
        }
    }
    const getCountPerPage = (pageCount, keyName) => {
    switch (pageCount) {
        case 1:
        return keyName * 10;
        default:
        return keyName === 1 ? keyName * 3 : keyName * 10;
    }
    };

    const cancelLeaseContracts = (lease, type) => {
      dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_lease_contract"), _l("l_yes"), _l("l_no"), () => {}, async() => {
        setIsSkeltonShow(true);
          try {
          dispatch(toastCloseAction());
          const response = await leaseServices.cancelLeaseContract(lease.project_id, lease.id);
          if(response.status) {
              setListData({
                  ...listData,
                  ["l_lease_document"]: listData["l_lease_document"].map((w) => {
                  if (w.project_id == lease.project_id) {
                      w.status = "0";
                  }
                  return w;
                  })
              });
              dispatch(showMessage("sucess", _l("l_success"), _l("l_subscription_cancelled")))
          }else{
              showMessage(response.message);
          }
          }
          catch(e)
          {
          console.error(e);
          }
        setIsSkeltonShow(false);
      }))
    }

    useEffect(() => {
      if(docBoolean && docBoolean == true) {
        fetchDocumentsFromIDB();
        dispatch(getIDBDocData(false));        
      }
    }, [docBoolean])

    const openDocumentDetailView = async (documentData, key,space) => {
      try {
          switch (key) {
          case "l_current":
          case "l_past":
          case "l_future":
          case "l_subscriptions":
              if (guestUserModal) {
              docModalViewToggle({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "subscription",
              })
              } else {
              setOverWriteMainModal(true);
              setDocumentMiniModalData({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "subscription",
              });
              }
              break;
          case "l_estimates":
              if (guestUserModal) {
              docModalViewToggle({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "estimate",
              })
              } else {
              setOverWriteMainModal(true);
              setDocumentMiniModalData({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "estimate",
              });
              }
              break;
          case "premiumEstimate":
              setOverWriteMainModal(true);
              setDocumentMiniModalData({
              id: documentData.project_id,
              hash: documentData.hash,
              type: "premiumEstimate",
              space :space
              });
              break;
          case "l_space_document":
              if (guestUserModal) {
              docModalViewToggle({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "spaceDocument",
              })
              } else {
              setOverWriteMainModal(true);
              setDocumentMiniModalData({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "spaceDocument",
                  floor_id: documentData.floor_id,
              });
              }
              break;
          case "l_payouts" :
            let tempData = documentData && documentData.payout && documentData.payout.source_payments && documentData.payout.source_payments.length && documentData.payout.source_payments.filter((item) => item.id == space)
            if (tempData && tempData.length) {
              setOverWriteMainModal(true);
                setDocumentMiniModalData({
                    id: tempData[0].invoice_details.id,
                    hash: tempData[0].invoice_details.hash,
                    type: "invoice",
                    is_app_invoice:
                    key === "l_expenses" || tempData[0].invoice_details.is_app_invoice === 1 ? 1 : 0,
                });
            }
            break;
          case "l_expenses":
          case "l_invoices":
          case "l_invoice":
              if (guestUserModal) {
              docModalViewToggle({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "invoice",
                  is_app_invoice : documentData.is_app_invoice ? documentData.is_app_invoice : 0
              })
              } else {
              setOverWriteMainModal(true);
              setDocumentMiniModalData({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "invoice",
                  is_app_invoice:
                  key === "l_expenses" || documentData.is_app_invoice === 1 ? 1 : 0,
              });
              }
              break;
          case "l_income":
          case "l_credit_note":
              if (guestUserModal) {
              docModalViewToggle({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "credit_note", 
                  is_app_credit_note : documentData.is_app_credit_note === 1 ? 1 : 0
              })
              } else {
              setOverWriteMainModal(true);
              setDocumentMiniModalData({
                  id: documentData.id,
                  hash: documentData.hash,
                  type: "credit_note",
                  is_app_credit_note:  documentData.is_app_credit_note === 1 ? 1 : 0,
              });
              }
              break;
          case "l_documents":
              setOverWriteMainModal(true);
              if (documentData.image_url) {
              setDocumentMiniModalData({ 
                  ...documentData, 
                  type: "documents",
              });
              } else {
              setDocumentMiniModalData({
                  id: documentData.id,
                  type: "task",
                  ...documentData,
              });
              }
              break;
          default:
              break;
          }
      } catch (e) {
          console.error(e);
      }
    };

    const editTopicDocument = async (item = {}) => {
      setShowDocumentUpload(true);
      setSelectedDocument(item)
    }

    const deleteTopciDocument = (item, category) => {

        const deleteDoc = async () => {
          setIsSkeltonShow(true);
          try {
            const { rel_id, rel_type, id } = item;
            const response = await documentsServices.deleteTopicDocument(staff_id, contact_id, client_id, project_id, rel_id, rel_type, id);
            if (response.status) {
              setListData({
                ...listData,
                [category]: listData[category].filter((doc) => doc.id !== id),
              });
              toastCloseAction();
            }
          } catch (e) {
            console.error(e);
          }
          setIsSkeltonShow(false);
        }
    
        dispatch(
          showConfirmation(
            _l("l_are_you_sure"),
            _l("l_are_you_sure_you_want_to_delete_this_document"),
            _l("l_yes"),
            _l("l_no"),
            undefined,
            () => {
              deleteDoc()
            }
          )
        );
        
    }

    const hideViewMoreBtn = (totalLength, pageCount, keyName) => {
        switch (totalLength) {
          case 1:
            return (
              pageCount >= (keyName - 1) * 10 + 10 &&
              pageCount != (keyName - 1) * 10 + 10
            );
          default:
            return (
              pageCount >= (keyName - 1) * 10 + 3 &&
              pageCount != (keyName - 1) * 10 + 3
            );
        }
    };

    const getTotalCountPerPage = (totalLength, pageCount, keyName) => {
        switch (totalLength) {
            case 1:
            return pageCount - ((keyName - 1) * 10 + 10);
            default:
            return pageCount - ((keyName - 1) * 10 + 3);
        }
    };

    const showViewAllButton = (key) => {
        const totalResults = listData[key].length;
        const remainingResults = getTotalCountPerPage(
          Object.keys(listData).length,
          globalSearchString.trim().length
            ? listData[key].filter((item) => searchObjects(item, globalSearchString))
                .length
            : listData[key].length,
          paginationState[key]
        );
        return totalResults !== remainingResults;
    };

    const viewAll = (key) => {
        const totalPages = Math.ceil((listData[key].length - 3) / 10) + 1;
        setPaginationState({ ...paginationState, [key]: totalPages });
      };

    const prepareLightboxData = (attachmentsData, attachmentType, position) => {
      let openLightBox = true;
      let lightBoxData = attachmentsData.map((file) => ({
          src: file.image_url,
          type: getFileType(file.file_name),
      }));

      if (["l_gallery", "l_files"].includes(attachmentType)) {
          let selectedAttachment = attachmentsData[position];
          if (selectedAttachment.task_id > 0) {
            getTaskDetailsForAttachments(selectedAttachment);
          return;
          }
      }

      setOpenLightBox(openLightBox);
      setlightBoxData(lightBoxData);
    };

    const getTaskDetailsForAttachments = async (selectedAttachment) => {
      try {
        const taskDetail = await customerServices.getTaskData(
          projectId,
          selectedAttachment.task_id
        );
        const taskAttachments = await customerServices.getAttachments(
          selectedAttachment.task_id,
          projectId
        );
        const taskComments = await documentsServices.getComments(
          selectedAttachment.id,
          "task"
        );
  
        const currentAttachment = {
          id: selectedAttachment.id,
          task_id: selectedAttachment.task_id,
          task_comment_id: selectedAttachment.task_comment_id,
          image_url: selectedAttachment.image_url,
          dateadded: selectedAttachment.dateadded,
        };
  
        const attachmentAdditionalDetails = taskAttachments.data.find(
          (w) => w.id == selectedAttachment.id
        );
  
        setAttachmentDetail({
          id: selectedAttachment.id,
          taskId: selectedAttachment.task_id,
          projectId,
          attachmentDetails: {
            ...currentAttachment,
            ...attachmentAdditionalDetails,
          },
          taskDetails: taskDetail.data,
          allAttachments: taskAttachments.data,
          comments: taskComments.data,
          download_link: ""
        }); 
        setOverWriteMainModal(true);
      } catch (e) {
        console.error(e);
      }
    };

    const changeDocumentActivityStatus = async (
        user_id,
        document_type,
        document_id,
        category,
        position,
        checked
      ) => {
        setIsSkeltonShow(true);
        try {
          const response = await documentsServices.updateDocumentActivityStatus(
            projectId,
            user_id,
            document_type,
            document_id,
            checked ? 1 : 0
          );
          if (response.status) {
            setListData({
              ...listData,
              [category]: listData[category].map((w, i) => {
                if (i === position) {
                  w = { ...w, is_active: checked ? 1 : 0 };
                } else {
                  w = { ...w, is_active: 0 };
                }
                return w;
              }),
            });
          }
        } catch (e) {
          console.error(e);
        }
        setIsSkeltonShow(false);
      };

      const cancelSubscription = (subscription, type) => {
        dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_subscription"), _l("l_yes"), _l("l_no"), () => {}, async() => {
          setIsSkeltonShow(true);
          try {
            dispatch(toastCloseAction());
            const response = await documentsServices.cancelCustomerSubscription(subscription.id, type);
            if(response.status)
            {
              if(type !== "at_period_end")
              {
                setListData({
                  ...listData,
                  ["l_subscriptions"]: listData["l_subscriptions"].map((w) => {
                    if (w.id == subscription.id) {
                      w.status = "canceled";
                    }
                    return w;
                  }),
                });
                showMessage("l_subscription_cancelled");
              }
              else {
                showMessage("l_subscription_cancellation_scheduled");
              }
            }
          }
          catch(e)
          {
            console.error(e);
          }
          setIsSkeltonShow(false);
        }))
      }

const getLeaseDocumentDetails = async(space)=>{
let projectId = space.project_id
let spaces = localStorage.getItem("spaces") ? JSON.parse(localStorage.getItem("spaces")) : [];
let spaceDetails = spaces.find(space => space.project_id == projectId)
let leaseID = spaceDetails ? spaceDetails.lease_id : -1
if (leaseID > -1) {
  try {
    let assignee = []
    await leaseServices.getLeaseTopics(projectId ,leaseID).then((res)=>{
      if (res && res.status) {
        setTopics(res.data.topics)
        const filteredArray = res.data.topics.map((item) => {
          // Filter categories based on is_active === 1
          const filteredCategories = item.categories.filter((category) => Number(category.is_active) === 1);
        
          // Return a new object with the filtered categories
          return {
            ...item,
            categories: filteredCategories,
          };
        }).filter(item => item.categories.length > 0); // Remove items with no active categories
        
        assignee = filteredArray;
      }
    })

    let response = await leaseServices.getProjectLease(projectId ,leaseID )
    if (response && response.status && response.data && Object.keys(response.data).length > 0) {

      let details = response.data.detail ? response.data.detail : {}
      let documents = response.data.documents && response.data.documents.length > 0 ? response.data.documents : []

      setLeaseDocumentDeatails({
        ...leaseDocumentDeatails,
          leaseStartDate : new Date(details.start_date),
          leaseEndDate : new Date(details.end_date),
          noticeDates : details.notice_date_detail,
          isRentingorOwning : response.data.lease_type == 1 ? "renting" : "owning",
          amount : details.amount,
          isMonthlyOrAnnualli : details.payment_type == 1 ? "monthly" : "annually",
          termandCondition : details.termination_condition,
          name : spaceDetails.office_title,
          status : response.data.status,
          sub_lease_count : response.data.sub_lease_count,
          lease_type : response.data.lease_type,
          assignee_list_DEV : assignee,
          lease_schedule_id : response.data.detail.lease_schedule_id,
          id : response.data.detail.lease_schedule_id,
          projectId : projectId,
          hash : response.data.hash,
          lease_id : response.data.id
      })
      dispatch(setLeaseDocumentsList(documents))
      let docs = documents.map((doc, i)=>{
        if (doc && doc.sub_document && doc.sub_document.length > 0 ) {
          return {...doc , image_url : doc.sub_document[doc.sub_document.length - 1].image_url, previous_document_id : doc.sub_document[doc.sub_document.length - 1].previous_document_id }
        }else{
          return doc
        }
      })
      setDocuments(docs);
      setSpaceBasicDetails(spaceDetails)
    }else{
      showError("l_something_went_wrong")
    }
    
  } catch (error) {
    console.log(error)
  }
}

}

const fetchDocumentsFromIDB = async()=>{
  setIsSkeltonShow(true);
  let rel_type  = myDriveTopic.key === "google_drive" ? "topic_document" : myDriveTopic.key
  // rel_type === "documents" ? "topic_document" : rel_type
  let documentsList = await fetchMydriveData(rel_type);
  setDashBoardDocData({...listData ,[myDriveTopic.name] : documentsList } )
  setListData({[myDriveTopic.name] : documentsList })
  setPaginationState({...paginationState, [myDriveTopic.name] : 1})
  if (documentsList) {
    setIsSkeltonShow(false);
  }
}

// useEffect(() => {
//     let topicList = [];
//     if (window.location.pathname == "/dashboard" || guestUserModal) {
//         topicList = [
//             {
//             key: "invoice",
//             name: "l_invoices",
//             icon: FileTextIcon,
//             },
//             {
//             key: "estimate",
//             name: "l_estimates",
//             icon: EstimateIcon,
//             },
//             {
//             key: "credit_notes",
//             name: "l_credit_note",
//             icon: FileIcon,
//             },
//             {
//             key: "subscription",
//             name: "l_subscriptions",
//             icon: ArrowClockwise,
//             },
//         ];
//         if (window.location.pathname == "/dashboard") {
//             const spaceTab = [{
//             key: "space",
//             name: "l_space_document",
//             icon: FileCloudIcon,
//             }]
//             topicList = topicList.concat(spaceTab)  
//         }
//         } else {
//         topicList = [
//         {
//             key: "invoice",
//             name: "l_invoices",
//             icon: FileTextIcon,
//         },
//         {
//             key: "estimate",
//             name: "l_estimates",
//             icon: EstimateIcon,
//         },
//         {
//             key: "credit_notes",
//             name: "l_credit_note",
//             icon: FileIcon,
//         },
//         {
//             key: "subscription",
//             name: "l_subscriptions",
//             icon: ArrowClockwise,
//         },
//         {
//             key: "google_drive",
//             name: "l_documents",
//             icon: FileIcon,
//         },
//         {
//             key: "space",
//             name: "l_space_document",
//             icon: FileCloudIcon,
//         },
//         {
//             key: "lease_document",
//             name: "l_lease_document",
//             icon: FilesIcon,
//         },
//         ];
//         }
//         if (userType == "operator") {
//           const payoutsTab = [{
//             key: "payouts",
//             name: "l_payouts",
//             icon: FileCloudIcon,
//           }]
//           topicList = topicList.concat(payoutsTab)  
//         }
//         setSectionList(topicList);
//     }, [])

    useEffect(() => {
        if (myDriveTopic && myDriveTopic.key !== "") {
          
          fetchDocumentsFromIDB();
            changeTopic(myDriveTopic)
            setSelectedTopic(myDriveTopic)
        }
    }, [myDriveTopic])

    const dateRangeFilters = (item) => {
      let date = new Date();
      switch (myDriveTopic.key) {
        case "payouts":
          date = item && item.payout && item.payout.created && item.payout.created
          break;
        case "invoice":
          date = item.date
          break;
        case "estimate":
          date = item.date;
          break;
        case "credit_notes":
          date = item.date;
          break;
        case "subscription":
          date = item.date;
          break;
        case "google_drive":
          date = item.created_at;
          break;
        case "space":
          date = '';
          break;
        case "lease_document":
          date = item.detail && item.detail.start_date;
          break;
        default:
          date = '';
          break;
      }

      if (date == '') {
        return item
      }else{
        const itemDate = moment(date).format('YYYY-MM');
        const setSelectedDate = moment(selectedDate).format('YYYY-MM');
        const setFromDate = moment(fromDate).format('YYYY-MM');
        const setToDate = moment(toDate).format('YYYY-MM');
  
        if (["today", "date"].includes(daterangeAppliedFilters)) {
           if (moment(itemDate).isSame(moment(setSelectedDate), 'day')) return item;
        }else if (daterangeAppliedFilters == "this_week") {
          if (moment(itemDate).isBetween(setFromDate, setToDate, 'day', '[]')) return item;
        }else if (daterangeAppliedFilters == "month") {
          if (moment(itemDate).isSameOrAfter(setFromDate, 'month') && moment(itemDate).isSameOrBefore(setToDate, 'month')) return item;
        }else if (daterangeAppliedFilters == "CustomRange") {
          if (moment(itemDate).isBetween(setFromDate, setToDate, 'day', '[]')) return item;
        }else if (daterangeAppliedFilters == "all") {
          return item
        }
      }
    }

  return (
    <React.Fragment>
       
          <div className='schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column    '>
            <div className=' d-flex flex-column h-100 flex-grow-1 backdroplighttheme '>
                {
                  selectedTopic.key == "google_drive" ? 
                  <MydriveDocumentPage
                  editTopicDocument={editTopicDocument}
                  deleteTopciDocument={deleteTopciDocument}
                  openDocumentDetailView={openDocumentDetailView}
                  getLeaseDocumentDetails={getLeaseDocumentDetails}
                  cancelLeaseContracts={cancelLeaseContracts}
                  listData={listData}
                  setListData={setListData}
                  dashBoardDocData={dashBoardDocData}
                  /> 
                  :
                  <>
                  <div className='comman-main-box custom-accordian-main res-padding-right0 overflow-auto flex-grow-1 h-100 task-list-accordian   '>
                  <div className='task-list-accordian-main with_checkbox_absolute mt-0 w-800px my-0 padding-bottom-60px daily-schedule-main'>
                    {
                       Object.keys(listData).map((key, index) => {
                        return (
                          isSkeltonShow ?
                            ["0", "1", "2"].map(() => {
                              return <EditProfileManageTaskListSkeleton />
                            }) :
                            <div className="mb-3" key={index}>
                                <div className={`settingmodalHeader d-flex  align-items-center justify-content-between position-sticky top-0 z-index-7 bgleftpanelnew list-view p-10 rounded-0 shadow-none ${key == "l_manage_companies_profile" || key == "l_manage_checklist_label" ? "lightthemetransparent" : ""}`}>
                                <div className="c-font color-white-80 f-12 title-fonts">
                                    {_l(key)}
                                    {
                                    listData[key] && listData[key].length && listData[key].length > 0
                                    ? `(${globalSearchString.trim().length
                                        ? listData[key].filter(
                                        (item) =>
                                            searchObjects(
                                            item,
                                            globalSearchString
                                            ) && dateRangeFilters(item)
                                        ).length
                                        : listData[key].filter(
                                          (item) => dateRangeFilters(item)
                                          ).length
                                    })`
                                    : ""}
                                </div>
                                </div>
                                <div className="h-100 res-padding-right0 pe-0">
                                {
                                listData[key]
                                    .filter(
                                    (i) =>
                                        searchObjects(i, globalSearchString) && dateRangeFilters(i)
                                    )
                                    .sort((a, b) => {
                                      if(a && a.payout && b && b.payout) {
                                        return new Date(b.payout.created) - new Date(a.payout.created);
                                      } else if (a.date && b.date) {
                                        return new Date(b.date) - new Date(a.date)
                                    } else if (a.created_at && b.created_at) {
                                        return new Date(b.created_at) - new Date(a.created_at)
                                    }
                                    })
                                    .slice(
                                    0,
                                    getCountPerPage(
                                        Object.keys(listData).length,
                                        paginationState[key]
                                    )
                                    )
                                    .map((item, index2) => {
                                    
                                    switch (selectedTopic.key) {
                                        case "lease_document" :
                                        let spaceLease = spaceState.find((sp) => sp.project_id == item.project_id);
                                            return(
                                            <LeaseCard
                                                key={index2}
                                                leaseItem = {item}
                                                space = {spaceLease}
                                                openDetailLease = {() => {
                                                  getLeaseDocumentDetails(item)
                                                }}
                                                actionItems={
                                                item.status == 0 
                                                    ? [] 
                                                    : [
                                                        {
                                                        actionName:
                                                            _l("l_delete"),
                                                        actionHandler:
                                                            () => {
                                                            cancelLeaseContracts(item);
                                                            },
                                                        actionIcon:
                                                            "TrashIcon",
                                                        },
                                                    ]
                                                }
                                                isFrom = "driveLease"
                                                />
                                                )
                                        case "google_drive":
                                            return (
                                                <GeneralCardView
                                                    key={index2}
                                                    cardTitle={`${item.file_name}`}
                                                    ownerDetails={{
                                                    name:
                                                        item.task_name ||
                                                        item.file_name,
                                                    profile_image:
                                                        getPreviewFromURL(
                                                        item.image_url ? item.image_url : ""
                                                        ),
                                                    }}
                                                    pills={[
                                                    {
                                                        color: "color-green",
                                                        content: `${moment(item.created_at).format("ll")}`,
                                                    },
                                                    ]}
                                                    onClick={() => {
                                                    
                                                    }}
                                                    actionItems={
                                                    staff_id == item.added_from ? [
                                                        {
                                                            actionName: _l("l_edit"),
                                                            actionHandler: () => {
                                                                 editTopicDocument(item, key) 
                                                                },
                                                            actionIcon: "PencilSimpleLineIcon",
                                                        },
                                                        { 
                                                            actionName: _l("l_delete"),
                                                            actionHandler: () => { 
                                                                deleteTopciDocument(item, key)
                                                             },
                                                            actionIcon: "TrashIcon",
                                                        },
                                                        ]
                                                    : []
                                                    } 
                                                    hideChatAndCall={true}
                                                    ActionButton={
                                                    <a
                                                        className="comman_action_icon w-100 d-flex"
                                                        href={`${item.image_url}`}
                                                        target="_blank"
                                                         
                                                         
                                                    >
                                                        <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                                        <DownloadSimpleIcon
                                                            className="HW18"
                                                        />
                                                        </div>
                                                    </a>
                                                    }
                                                />
                                            );
                                        case  "payouts":
                                          return (
                                            <CardView
                                              hideShow={(id) => {
                                                openDocumentDetailView(
                                                  item,
                                                  key,
                                                  id,
                                                );
                                              }}
                                              docType={key.replace("l_", "")}
                                              User_2={""}
                                              data={item}
                                              selectedId={0}
                                              key={index2}
                                              listData={listData}
                                              setListData={setListData}
                                            />
                                          );
                                        case "space":
                                        case "estimate":
                                        case "subscription":
                                        case "invoice":
                                        case "credit_notes":
                                            let options = [];
                                                if ( key.replace("l_", "") === "documents" && !item.file_name) {
                                                  return (
                                                    <TaskCard
                                                      key={index2}
                                                      task={item}
                                                      project_id={projectId}
                                                      handler={() => {
                                                        setOverWriteMainModal(true);
                                                      }}
                                                      pageName="task"
                                                    />
                                                  );
                                                } else if (
                                                  [
                                                    "l_id_card",
                                                    "l_company_id",
                                                    "l_payment_documents",
                                                    "l_insurance_documents",
                                                  ].includes(key)
                                                ) {
                                                  return (
                                                    <GeneralCardView
                                                      key={index2}
                                                      cardTitle={`${
                                                        item.task_name ||
                                                        item.file_name
                                                      }`}
                                                      ownerDetails={{
                                                        name:
                                                          item.task_name ||
                                                          item.file_name,
                                                        profile_image:
                                                          getPreviewFromURL(
                                                            item.image_url
                                                          ),
                                                      }}
                                                      pills={[
                                                        {
                                                          color: "color-green",
                                                          content: `${moment(
                                                            item.dateadded
                                                          ).format("ll")}`,
                                                        },
                                                      ]}
                                                      onClick={() => {
                                                        prepareLightboxData(
                                                          listData[key],
                                                          key,
                                                          index2
                                                        );
                                                      }}
                                                      actionItems={[]}
                                                      hideChatAndCall={true}
                                                      ActionButton={
                                                        [
                                                          "l_id_card",
                                                          "l_company_id",
                                                          "l_payment_documents",
                                                          "l_insurance_documents",
                                                        ].includes(key) ? (
                                                          <div className="form-check form-check-inline m-0 ps-2">
                                                            <Form.Check
                                                              className=" with-no-label-space"
                                                              type="switch"
                                                              role="switch"
                                                              id="flexSwitchCheckChecked"
                                                              checked={
                                                                item.is_active > 0
                                                              }
                                                              onChange={(e) => {
                                                                changeDocumentActivityStatus(
                                                                  item.staffid,
                                                                  item.rel_type,
                                                                  item.id,
                                                                  key,
                                                                  index2,
                                                                  e.target.checked
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <React.Fragment></React.Fragment>
                                                        )
                                                      }
                                                    />
                                                  );
                                                } else if (item.file_name) {
                                                  return (
                                                    <GeneralCardView 
                                                      key={index2}
                                                      cardTitle={`${item.file_name}`}
                                                      ownerDetails={{
                                                        name:
                                                          item.task_name ||
                                                          item.file_name,
                                                        profile_image:
                                                          getPreviewFromURL(
                                                            item.image_url
                                                          ),
                                                      }}
                                                      pills={[
                                                        {
                                                          color: "color-green",
                                                          content: `${moment(item.dateadded).format("ll")}`,
                                                        },
                                                      ]}
                                                      onClick={() => {
                                                        openDocumentDetailView(
                                                          item,
                                                          key
                                                        );
                                                      }}
                                                      actionItems={
                                                        staff_id == item.added_from ? [
                                                            {
                                                              actionName: _l("l_edit"),
                                                              actionHandler: () => { 
                                                                editTopicDocument(item, key) 
                                                            },
                                                              actionIcon: "PencilSimpleLineIcon",
                                                            },
                                                            { 
                                                              actionName: _l("l_delete"),
                                                              actionHandler: () => {
                                                                 deleteTopciDocument(item, key)
                                                                 },
                                                              actionIcon: "TrashIcon",
                                                            },
                                                          ]
                                                        : []
                                                      } 
                                                      hideChatAndCall={true}
                                                      ActionButton={
                                                        <a
                                                          className="comman_action_icon w-100 d-flex"
                                                          href={`${item.image_url}`}
                                                          target="_blank"
                                                           
                                                           
                                                        >
                                                          <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                                            <DownloadSimpleIcon
                                                              className="HW18"
                                                            />
                                                          </div>
                                                        </a>
                                                      }
                                                      data = {item}
                                                    />
                                                  );
                                                } else if (
                                                  ["l_gallery", "l_files"].includes(
                                                    key
                                                  )
                                                ) {
                                                  return <></>;
                                                }
                                                if(userType === "contact" && (selectedTopic.key === "subscription" || key === "l_subscriptions") && item.status == "active" && [1, 2].includes(Number(loginUserRole)))
                                                {
                                                  if(item.payment_mode == "stripe")
                                                  {
                                                    options = [
                                                      {
                                                        actionName: _l("l_cancel_immediately"),
                                                        actionHandler: () => {
                                                          cancelSubscription(item, "immediately");
                                                        },
                                                        actionIcon: "ProhibitIcon",
                                                      },
                                                      {
                                                        actionName: _l("l_cancel__at_period_end"),
                                                        actionHandler: () => {
                                                          cancelSubscription(item, "at_period_end");
                                                        },
                                                        actionIcon: "FIleXicon",
                                                      },
                                                    ]
                                                  }
                                                  else {
                                                    options = [
                                                      {
                                                        actionName: _l("l_cancel"),
                                                        actionHandler: () => {
                                                          cancelSubscription(item);
                                                        },
                                                        actionIcon: "ProhibitIcon",
                                                      },
                                                    ]
                                                  }
                                                }
    
                                                return (
                                                  <CardView
                                                    hideShow={(estiamteDuplicateData = false) => {
                                                      if (estiamteDuplicateData) {
                                                        openDocumentDetailView(
                                                          estiamteDuplicateData,
                                                          "l_estimates"
                                                        );
                                                      } else {
                                                        openDocumentDetailView(
                                                          item,
                                                          key
                                                        );
                                                      }
                                                    }}
                                                    docType={
                                                      key === "l_space_document"
                                                        ? "spaceDocument"
                                                        : key.replace("l_", "")
                                                    }
                                                    setDocType={() => {}}
                                                    pinHandler={() => {}}
                                                    User_2={""}
                                                    data={item}
                                                    selectedId={0}
                                                    key={index2}
                                                    setSelectedId={() => {}}
                                                    aiChatAssistURL={`/aichat?rel_type=${selectedTopic.key}&rel_id=${item.id}&project_id=${projectId}`}
                                                    actionItems={options}
                                                    listData={listData}
                                                    setListData={setListData}
                                                  />
                                                );
                                       }
                                    })}
                                {
                                !listData[key].filter((item) => searchObjects(item, globalSearchString) && dateRangeFilters(item)).length &&
                                (![
                                    "company_profile",
                                    "manage_checklist",
                                ].includes(selectedTopic.key) ||
                                    !["l_gallery", "l_files"].includes(
                                    key
                                    )) ? (
                                    <CommanPlaceholder
                                    mainSpacing="bgOfficelisting p-0"
                                    imgWidth="big-image h95 d-none"
                                    isImageOpacity="opacity-50"
                                    placeholderTextClass="c-font f-16 pt-0 opacity30"
                                    imgType="no-benchmark"
                                    placeholderText={_l(
                                        `l_no_${selectedTopic.key}`
                                    )}
                                    />
                                ) : (
                                    <></>
                                )}
    
                                {!["l_gallery", "l_files"].includes(key) &&
                                hideViewMoreBtn(
                                    Object.keys(listData).length,
                                    globalSearchString.trim().length
                                    ? listData[key].filter((item) =>
                                        searchObjects(item, globalSearchString) && dateRangeFilters(item)
                                        ).length
                                    : listData[key] && listData[key].length && listData[key].filter((item) => dateRangeFilters(item)).length,
                                    paginationState[key]
                                ) ? (
                                    <div className="d-flex justify-content-center">
                                    <a href="#/"
                                        className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                                        onClick={() => {
                                        setPaginationState({
                                            ...paginationState,
                                            [key]: paginationState[key] + 1,
                                        });
                                        }}
                                    >
                                        <span className="title-fonts d-flex justify-content-center align-item-center ">
                                        <div className="d-flex align-item-center justify-content-center">
                                            {_l("l_view_more_btn")} (
                                            {getTotalCountPerPage(
                                            Object.keys(listData).length,
                                            globalSearchString.trim().length
                                                ? listData[key].filter(
                                                    (item) =>
                                                    searchObjects(
                                                        item,
                                                        globalSearchString
                                                    )
                                                ).length
                                                : listData[key].length,
                                            paginationState[key]
                                            )}
                                            )
                                            <CaretDoubleDownIcon
                                            className="HW14 mx-2"
                                            />
                                            <span
                                            role="status"
                                            aria-hidden="true"
                                            className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                            ></span>
                                        </div>
                                        </span>
                                    </a>
                                    {showViewAllButton(key) ? (
                                        <a href="#/"
                                        className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                                        onClick={() => {
                                            viewAll(key);
                                        }}
                                        >
                                        <span className="title-fonts d-flex justify-content-center align-item-center ">
                                            <div className="d-flex align-item-center justify-content-center">
                                            {_l("l_view_all")}
                                            </div>
                                        </span>
                                        </a>
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                </div>
                            </div>
                            );
                        })
                    }
                  </div>
                  </div>

                  </>
                  }
            </div>
          </div>
          {openLightBox && lightBoxData ? (
            <DocumentPreview
              open={openLightBox}
              setOpen={setOpenLightBox}
              slides={lightBoxData}
            />
          ) : (
            <></>
          )}
          {
            showDocumentUpload
              ? <UploadDocument
                show={showDocumentUpload}
                handleClose={(onSave = false)=>{
                  setShowDocumentUpload(false);
                  setSelectedDocument({});
                  if (onSave) {
                    if (selectedTopic.key == "google_drive") {
                      getDriveData("documents", -1 ,true);
                    }else{
                      getDriveData("documents", selectedCategory.id);
                    }
                  }
                }}
                data={
                  selectedDocument.id ? {
                    id: data.id,
                    title: data.title,
                    is_active: data.is_active,
                    main_sub_key: data.main_sub_key
                  } : selectedCategory
                }
                selectedDocument={selectedDocument}
                />
              : <></>
          }
          {attachmentDetail.id > 0 ? (
            <CommanOffCanvas
              show={attachmentDetail.id > 0}
              handleClose={() => {
                setAttachmentDetail({});
                setOverWriteMainModal(false);
              }}
              data={attachmentDetail}
              docType={"imageview"}
              setSelectedId={() => {}}
              commentList={attachmentDetail.comments}
              selectedTaskId={attachmentDetail.taskId}
              handleParentComponentAction={() => {}}
              CloseCommentModal={() => {}}
              isChat={true}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {overWriteMainModal ? (
            <React.Fragment>
              {documentMiniModalData.id > 0 ||
                (documentMiniModalData.id && documentMiniModalData.id.length) ? (
                  <DocumentModal
                    handleClose={() => {
                      setDocumentMiniModalData({});
                      setOverWriteMainModal(false);
                    }}
                    fromTopicSetting={true}
                    documentData={documentMiniModalData}
                    show={
                      documentMiniModalData.id > 0 ||
                      (documentMiniModalData.id && documentMiniModalData.id.length)
                    }
                    type={documentMiniModalData.type}
                  />
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          
          {
            leaseDocumentDeatails && Object.keys(leaseDocumentDeatails).length > 0 && leaseDocumentDeatails.leaseStartDate && leaseDocumentDeatails.leaseStartDate != "" ?
            <>
            <CommanOffCanvas
            show={Object.keys(leaseDocumentDeatails).length > 0 }
            data={leaseDocumentDeatails}
            docType={"leaseDocument"}
            isFullScreen={true}
            handleClose={ ()=>{
              setLeaseDocumentDeatails({
                leaseStartDate : "",
                leaseEndDate : "",
                noticeDates : [{ notice_date: ""}],
                isRentingorOwning : "renting",
                amount : "",
                isMonthlyOrAnnualli : "monthly",
                termandCondition : "",
                selectedServices : [],
                selectedEquipments : [],
                selectedResidents : [],
                space_type : 0
              })
            }}
            documents={documents}
            spaceBasicDetails={spaceBasicDetails}
            leaseDocumentDeatails={leaseDocumentDeatails}
            topics={topics}
            setDocuments={setDocuments}
            />
            
            </> :
            <React.Fragment></React.Fragment>
          }
      </React.Fragment>
  )
}

export default MyDrivePage