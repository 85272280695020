import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showSuccessMessage } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { getListOfRiskData } from "../../actions/customer";
import Spinner from "react-bootstrap/Spinner";

const AddRiskModal = ({ show, handleClose,  }) => {

  const {riskList} =  useSelector((state)=> state.customer)
  const dispatch = useDispatch();



    const [loader, setLoader] = useState(false);
    const [riskZone, setRskZone] = useState("");
    const [indicator, setIndicator] = useState("");
    const [rating, setRating] = useState("");
    const [note, setNote] = useState("");
    const [coefficient, setCoefficient] = useState("");

    let providerID = localStorage.getItem("currentCompanyID")

    const handleSave = () => {
      setLoader(true);
      try {
        customerServices.addRiskData(
          providerID,
          rating,
          coefficient,
          riskZone,
          note,
          indicator
        ).then((res) => {
          if (res && res.status) {
            setLoader(false);
            showSuccessMessage("l_risk_data_added")
            let obj = {
              "coefficient" : coefficient,
              "indicator" : indicator,
              "notes": note,
              "rating" : rating,
              "title" : riskZone,
              "id" :  res.data.id
            }
            dispatch(getListOfRiskData([...riskList,obj]))
          }
        });
      } catch (error) {
       console.log(error) 
      } finally {
        setLoader(false);
        handleClose();
      }

    }
   
  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_add_risk")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main border-bottom pb-2">
              <div className="row">
              <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l('l_risk_zone')}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_risk_zone")}`}
                    type="text"
                    name="isk_zone"
                    value={riskZone}
                    onChange={(e)=>{
                      setRskZone(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l('l_risk_indicator')}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_risk_indicator")}`}
                    type="text"
                    name="risk_indicator"
                    value={indicator}
                    onChange={(e)=>{
                      setIndicator(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l('l_rating')}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_rating")}`}
                    type="number"
                    name="rating"
                    value={rating}
                    onChange={(e)=>{
                      setRating(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l('l_note')}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_note")}`}
                    type="text"
                    name="note"
                    value={note}
                    onChange={(e)=>{
                      setNote(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l('l_coefficient')}
                  </Form.Label>
                  <Form.Control
                    placeholder={`${_l("l_coefficient")}`}
                    type="number"
                    name="coefficient"
                    value={coefficient}
                    onChange={(e)=>{
                      setCoefficient(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" size="sm" onClick={() => {
              if (!loader) {
                handleSave()
              }
            }}
              disabled={loader}
            >
              {loader
                ? _l('l_please_wait')
                : _l('l_save')}
              {loader ?
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
                : <></>
              }
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddRiskModal