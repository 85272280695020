import jquery from "jquery";
import { addexpandedClass } from "../../hooks/utilities";

/* Iframe Loader Js start */
jquery(document).ready(function () {
  jquery('#example').on("click", function () {
    var ajaxurl = document.getElementById("MyIframe").contentWindow.myDataTable.ajax.url();
    alert(ajaxurl);
  });
  jquery('#MyIframe').contents().find("body").addClass('custom-no-header-no-leftbar');
});
/* Iframe Loader Js end */


jquery(document).ready(function () {
  jquery(document).on("click", ".sub-custom ", function () {
    jquery('.sub-custom-dropdown').addClass("show");
  });
  
});
jquery(document).ready(function () {
});


jquery(document).ready(function () {
  jquery(document).on("click", "#TimeSlotDropdownWithCheckboxHandle", function () {
    jquery('#TimeSlotDropdownWithCheckboxDropdown').addClass('show');
    jquery(".time-slot-dropdown").scrollTop(0);
  });
  jquery(document).mouseup(function (e) {
    var container = jquery("#TimeSlotDropdownWithCheckboxDropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jquery('#TimeSlotDropdownWithCheckboxDropdown').removeClass('show');
      jquery('.right-accordian-header').removeClass('onactive');
      jquery('.right-accordian-header .on-hover-active-toggle-img').removeClass('active');
    }
  });
});

jquery(document).ready(function () {
  jquery(document).on("click", "#Loginuserdropdown", function () {
    jquery(".dropdown-menu").scrollTop(0);
  });
});

jquery(document).ready(function () {
  jquery(document).on("click", "#ProviderDropdownWithprogressBarHandle", function () {
    jquery('#ProviderDropdownWithprogressBarDropDown').addClass('show');
  });
  jquery(document).mouseup(function (e) {
    var container = jquery("#ProviderDropdownWithprogressBarDropDown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jquery('#ProviderDropdownWithprogressBarDropDown').removeClass('show');
    }
  });
});


jquery(document).ready(function () {
  jquery(document).on("click", ".right-accordian-header ", function () {
    jquery(this).addClass('onactive');
  });
  jquery(document).on("click", ".right-accordian-header .on-hover-active-toggle-img ", function () {
    jquery(this).addClass('active');
  });
  jquery(document).on("click", ".position-relative.task-sub-list ", function () {
  });
});
jquery(document).ready(function () {
  jquery(document).on("click", ".office-list-static", function () {
    addexpandedClass();
  });
  jquery(document).on("click", ".office-list-desc-right #Loginuserdropdown ", function () {
    addexpandedClass();
  });
});
jquery(document).ready(function () {
  jquery(document).mouseup(function (e) {
    var container = jquery("#employeeListingDropDown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jquery('#employeeListingDropDown').removeClass('show');
    }
  });
  jquery(document).on("click", ".employeeListingDropDownOpen", function (e) {

    jquery('#employeeListingDropDown').toggleClass('show');
  });

});
jquery(document).ready(function () {
  jquery(document).mouseup(function (e) {
    var container = jquery("#commentDropDownOpen");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jquery('#commentDropDownOpen').removeClass('show');
     
    }
  });
  jquery(document).on("click", ".writeComMentDropdown", function () {
    jquery('#commentDropDownOpen').addClass('show');
    jquery('#commentDropDownOpen').css({ "position": "absolute", "top": "65px" })
  });
});
