import { SET_ONBOARDING_FLOW_SUB_STEP } from "../actions/onboarding-actions/onboarding-action-type";
  
  const initialState = {
    stepData: {
      headerTitle: "",
      headerDescription: "",
      footerNextButtonTitle: "",
      footerPreviousButtonTitle: "",
      skipButtonTitle: "",
      skipButtonHandler: () => {},
      footerNextButtonHandler: () => {},
      footerPreviousButtonHandler: () => {},
    },
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case SET_ONBOARDING_FLOW_SUB_STEP:
        return { ...state, stepData: {...state.stepData, ...payload.data}};
    
      default:
        return state;
    }
  }
  