import React from 'react';
 

function CalendarWithFilterIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>


<svg width="24" height="26" viewBox="0 0 24 26"xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M18 1V5M6 1V5M1 9H23M2 3H22C22.5523 3 23 3.44772 23 4V24C23 24.5523 22.5523 25 22 25H2C1.44772 25 1 24.5523 1 24V4C1 3.44772 1.44772 3 2 3ZM7.42586 12H16.5741C16.6568 12 16.7376 12.0255 16.8068 12.0735C16.876 12.1214 16.9306 12.1896 16.9639 12.2698C16.9972 12.35 17.0078 12.4388 16.9943 12.5253C16.9809 12.6118 16.9441 12.6923 16.8883 12.757L13.3898 16.8413C13.317 16.924 13.277 17.0332 13.278 17.1463V20.3437C13.2786 20.4188 13.2614 20.4929 13.2279 20.5591C13.1944 20.6252 13.1457 20.6814 13.0863 20.7222L11.3823 21.9255C11.3184 21.9702 11.2443 21.9957 11.1679 21.9995C11.0914 22.0033 11.0154 21.9852 10.9478 21.947C10.8803 21.9089 10.8236 21.8521 10.7839 21.7827C10.7442 21.7133 10.7228 21.6338 10.722 21.5526V17.1463C10.723 17.0332 10.683 16.924 10.6102 16.8413L7.11169 12.757C7.05591 12.6923 7.01907 12.6118 7.00565 12.5253C6.99224 12.4388 7.00282 12.35 7.03612 12.2698C7.06942 12.1896 7.12399 12.1214 7.19319 12.0735C7.26239 12.0255 7.34323 12 7.42586 12Z"  strokeLinecap="round" strokeLinejoin="round"/>
</svg>
		</React.Fragment>
	)
}

export default CalendarWithFilterIcon