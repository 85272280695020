import React from 'react';
 

function NoProviderDetail(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="92" height="85" viewBox="0 0 92 85" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M26.3888 41.4088C23.7967 39.5242 21.6294 37.1161 20.0273 34.3405C12.0928 20.449 20.9548 2.94982 36.8483 1.11941C39.8425 0.808321 42.8688 1.1066 45.7447 1.99626C48.6206 2.88592 51.2867 4.34856 53.5822 6.29606C55.8778 8.24355 57.7554 10.6356 59.1018 13.328C60.4483 16.0205 61.2358 18.9577 61.4168 21.9626C61.5978 24.9676 61.1687 27.9781 60.1553 30.8127C59.1418 33.6474 57.565 36.2475 55.5199 38.4565C53.4747 40.6656 51.0036 42.4377 48.2553 43.6662C45.507 44.8948 42.5385 45.5542 39.5285 45.6049C24.0722 45.801 11.5249 55.8928 8.13782 70.965C7.73865 73.0588 7.50097 75.1801 7.4269 77.3102C7.29207 79.4144 6.02958 80.8566 4.1338 80.8239C2.23802 80.7912 0.991868 79.3204 1.00004 77.2816C1.06277 69.6438 3.40146 62.1982 7.71722 55.8963C12.033 49.5944 18.1298 44.7225 25.2284 41.9032C25.5471 41.7724 25.874 41.6254 26.3888 41.4088ZM23.5288 23.2763C23.5328 26.4192 24.4686 29.4904 26.2178 32.1016C27.967 34.7128 30.4511 36.7467 33.3561 37.9462C36.2611 39.1458 39.4565 39.4572 42.5384 38.841C45.6204 38.2248 48.4504 36.7086 50.6708 34.4842C52.8911 32.2598 54.4022 29.4271 55.0129 26.3441C55.6235 23.2611 55.3064 20.0662 54.1016 17.1633C52.8969 14.2605 50.8585 11.7801 48.2442 10.0356C45.6299 8.29104 42.557 7.36081 39.4141 7.36243C35.1972 7.37107 31.1558 9.05087 28.1751 12.0338C25.1945 15.0167 23.5178 19.0595 23.5124 23.2763H23.5288Z" strokeMiterlimit="10" />
				<path d="M68.3249 64.8077C74.6279 64.8077 80.9308 64.8077 87.2337 64.8077C89.6361 64.8077 91.2173 66.9159 90.4369 69.0568C90.2424 69.648 89.8755 70.1674 89.3832 70.5483C88.891 70.9291 88.296 71.1538 87.675 71.1936C87.3756 71.21 87.0755 71.21 86.7761 71.1936H49.6613C49.196 71.2146 48.7299 71.1817 48.2722 71.0956C47.5085 70.9071 46.8413 70.4432 46.3987 69.7929C45.9561 69.1427 45.7693 68.3518 45.8742 67.5722C45.979 66.7926 46.3682 66.0793 46.9668 65.5691C47.5655 65.0588 48.3315 64.7877 49.1179 64.8077C53.8206 64.8077 58.5151 64.8077 63.2218 64.8077H68.3249Z" strokeMiterlimit="10" />
				<path d="M68.3251 77.6003C74.6253 77.6003 80.9268 77.6003 87.2298 77.6003C89.6322 77.6003 91.2133 79.7126 90.4371 81.8494C90.2435 82.4412 89.8768 82.9613 89.3844 83.3423C88.892 83.7232 88.2965 83.9475 87.6751 83.9863C87.3758 84.0047 87.0756 84.0047 86.7762 83.9863H49.6614C49.1962 84.0072 48.73 83.9743 48.2723 83.8882C47.4996 83.7078 46.8218 83.2461 46.3712 82.5931C45.9206 81.94 45.7294 81.1424 45.8351 80.356C45.9407 79.5696 46.3355 78.8508 46.9425 78.3397C47.5495 77.8287 48.3251 77.5622 49.118 77.5921C53.8166 77.5921 58.5152 77.5921 63.222 77.5921L68.3251 77.6003Z" strokeMiterlimit="10" />
				<path d="M77.7261 58.389C74.6944 58.389 71.6601 58.389 68.623 58.389C66.4944 58.389 65.0358 57.0939 65.0235 55.2103C65.0113 53.3268 66.4576 51.9907 68.574 51.9907C74.7407 51.9907 80.9088 51.9907 87.0783 51.9907C89.1865 51.9907 90.6451 53.3227 90.6329 55.2144C90.6206 57.1061 89.162 58.385 87.0293 58.389C83.9296 58.3945 80.8285 58.3945 77.7261 58.389Z" strokeMiterlimit="10" />
				<path d="M52.2463 58.3892C51.1799 58.3892 50.1135 58.4097 49.0472 58.3892C48.6276 58.3908 48.2118 58.3098 47.8235 58.1507C47.4353 57.9916 47.0822 57.7576 46.7843 57.4621C46.4865 57.1665 46.2498 56.8152 46.0878 56.4282C45.9257 56.0411 45.8415 55.626 45.8399 55.2064C45.8383 54.7868 45.9193 54.3711 46.0784 53.9828C46.2375 53.5945 46.4715 53.2414 46.767 52.9436C47.0626 52.6458 47.4139 52.4091 47.8009 52.2471C48.1879 52.085 48.6031 52.0007 49.0227 51.9991C51.1881 51.9705 53.3535 51.9664 55.5231 51.9991C56.3694 51.9958 57.1823 52.3289 57.783 52.9251C58.3837 53.5212 58.723 54.3315 58.7263 55.1778C58.7295 56.0241 58.3965 56.837 57.8003 57.4377C57.2042 58.0384 56.3939 58.3778 55.5476 58.381C54.4485 58.4137 53.3454 58.3892 52.2463 58.3892Z" strokeMiterlimit="10" />
			</svg>


		</React.Fragment>
	)
}

export default NoProviderDetail