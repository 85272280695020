import { CHECK_CALENDAR_CONNECTIVITY, CALENDAR_TOPICS, GET_GOOGLE_CONTACTS, REFRESH_EVENTS_OF_CALENDAR } from "./action-type";
import GoogleCalendar from "../services/google-calendar";

export const is_googlecalendar_connected = (project_id) => (dispatch) => {
    GoogleCalendar
        .is_googlecalendar_connected(project_id)
        .then((data) => {
            dispatch({
                type: CHECK_CALENDAR_CONNECTIVITY,
                payload: data
            });
        });
}

export const googlecalendar_topics = (data) => (dispatch) => {
    dispatch({
        type: CALENDAR_TOPICS,
        payload: data
    });
}

export const googlecalendar_assignees = (TaskId) => (dispatch) => {
    GoogleCalendar.getMeetingLink(TaskId).then((data) => {
        dispatch({
            type: GET_GOOGLE_CONTACTS,
            payload: data
        });
    });
}

export const googlecalendar_refresh_events = (selectCalIds, start_date, end_date) => (dispatch) => {

    GoogleCalendar.get_Googlecalendar_events(selectCalIds , start_date , end_date ).then((res)=>{
        dispatch({
            type: REFRESH_EVENTS_OF_CALENDAR,
            payload: res.data
        });
    });

}




