
import React from 'react';

function InviteProviderIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(	
        <React.Fragment>
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
        <path d="M2 21L26.0001 38L50 21M26 12V28M18 20H34M26 2L1 20.4679V46.8031C1 49.121 2.89714 51 5.23729 51H46.7627C49.1029 51 51 49.121 51 46.8031V20.4679L26 2Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
		</React.Fragment>
	)
}

export default InviteProviderIcon