import React, { useState } from "react";
import EquipmentSheetMain from "../components/Equipment/EquipmentSheetMain";

const EquipmentTimeSheet = () => {
  const spaces = JSON.parse(localStorage.getItem("spaces"));
  const [projectId] = useState(
    spaces && spaces.length ? spaces[0].project_id : 0
  );

  return (
    <>
      <div className="d-flex flex-column h-100">
        <EquipmentSheetMain projectId={projectId} />
      </div>
    </>
  );
};

export default EquipmentTimeSheet;
