import React from 'react';

function EmailSyncIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";
	return (
		<React.Fragment>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M26.5049 20.1022H28.9997M28.9997 20.1022V17.6074M28.9997 20.1022L27.2328 18.3405C26.8085 17.9155 26.3045 17.5784 25.7498 17.3484C25.1951 17.1184 24.6005 17 23.9999 17C23.3994 17 22.8048 17.1184 22.2501 17.3484C21.6953 17.5784 21.1914 17.9155 20.7671 18.3405M21.4948 23.0444H19M19 23.0444V25.5392M19 23.0444L20.7672 24.8064C21.1915 25.2313 21.6954 25.5684 22.2501 25.7985C22.8049 26.0285 23.3995 26.1469 24 26.1469C24.6005 26.1469 25.1951 26.0285 25.7499 25.7985C26.3046 25.5684 26.8085 25.2313 27.2328 24.8064M3 6H27M3 6V23C3 23.2652 3.10536 23.5196 3.29289 23.7071C3.48043 23.8946 3.73478 24 4 24H15M3 6L15 15L27 6M27 6V13.5" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</React.Fragment>
	)
}

export default EmailSyncIcon