import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import 'intro.js/introjs.css';
import Accordion from "react-bootstrap/Accordion";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import CustomerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { TaskCommentModalOpen, _l, hideLeftPanelNew, isCustomerHavePaidSpace, showLeftPanelNew } from "../../hooks/utilities";
import {
   
    getDefaultTopics,
    getTopicTasks,
    fetchMails,
    updateMailCalendarCounter,
    getMailAssigees,
    commanoffcanvasisOpen,
    leftPanelFilters,
    updateSchedualEmail,
    setLeftPanelTab,
    clearDefaultTopicsList
} from "../../actions/customer";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import { toggleFeatureRestrictionModal } from "../../actions/general-actions/general-actions";
import EmailCard from "../EmailCard";
import {  MAIL_TOPIC } from "../../constants/constants";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { getGroupDetails } from "../../actions/chat";
import GeneralCardView from "../Documents/GeneralCard";
import AddEmailTemplatesModalNew from "../Modals/AddEmailTemplatesModalNew";


const MyEmailList = ({ setAllMAilMessages, setAllImportantMessages,synchronizedMail }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showDocumentCanvas, setShowDocumentCanvas] = useState(false);
    const spaces = JSON.parse(localStorage.getItem("spaces"));
    const savedProject = localStorage.getItem("selectedOffice");
    const [projectId, setProjectId] = useState(
        savedProject && savedProject != null
            ? savedProject
            : spaces && spaces.length
                ? spaces[0].project_id
                : 0
    );
    
    const [inbox_messages, setInbox_messages] = useState([]);
    const [important_messages, setImportant_messages] = useState([]);
    const [mailToView, setMailToView] = useState(false);
    const [mailDate, seteMaildate] = useState(false);
    const [mailTypeFilter, setMailTypeFilter] = useState('all');
    const { isAppliedUnreadComment, commanoffcanvasIsOpen, defaultTopics } = useSelector((state) => state.customer);
    const { mailFilters } = useSelector((state) => state.customer);
    const [oldInbx, setOldInbx] = useState([]);
    const [oldImportant, setOldImportant] = useState([]);
    const [mailSearchString, setMailSearchString] = useState("");
    const [refetch, setRefetch] = useState(true);
    const allMails = useRef([]);
    const count = useRef(0);
    const { importantMessages, inboxMessages, statusClose,mailNextPageToken, taskFilters, spamMessage, priority_messages, mailResultSizeEstimate, schedule_messages } = useSelector((state) => state.customer);
    const [selectedMailId, setSelectedMailId] = useState(0);
    const [mailType,setMailType] = useState('gmail');
  const [showDefaultIcons, setShowDefaultIcons] = useState([]); 
  const [selectedTaskIdforIconToggle, setSelectedTaskIdforIconToggle] = useState(0);
  const [prirotyMails, setPrirotyMails] = useState([])
  const [messagesPage, setMessagesPage] = useState(1);
  const [bulkEmailSendShow, setBulkEmailSendShow] = useState(false);
  const [schedualEmailData, setschedualEmailData] = useState({})

  const email_filter_key = localStorage.getItem("email_filter_key");

    useEffect(() => {
        setStatesOfMails(inboxMessages, importantMessages, spamMessage);
    }, [importantMessages, inboxMessages, spamMessage, statusClose])
    useEffect(() => {
        if(!isCustomerHavePaidSpace())
        {
            navigate(location.state && location.state.from ? location.state.from : "/");
            dispatch(toggleFeatureRestrictionModal(true));
        }
        count.mailReqCount = 0;

        dispatch(leftPanelFilters({ 
            filter_key : "inbox",
            image : "",
            main_key : "my_emails",
            main_sub_key : "",
            sub_category : [],
            title : "l_inbox"
        }));
        dispatch(getGroupDetails({}));
        dispatch(setLeftPanelTab("my-email"));
    }, [])

    const extractEmails = (text) => {
        return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

    useEffect(() => {
        if (mailFilters) {
            if (refetch == true) {
                dispatch(fetchMails(taskFilters)).then((res)=>{
                });
                setRefetch(true);
            } else {
                setStatesOfMails();
                setRefetch(true);
            }
        }
    }, [mailDate, isAppliedUnreadComment, mailFilters, taskFilters]);


    useEffect(() => {
        setStatesOfMails();
    }, [mailTypeFilter, mailSearchString])

    const setStatesOfMails = (inm = oldInbx, imm = oldImportant, spamMessage) => {
        setOldInbx(inm);
        setOldImportant(imm);
        setAllMAilMessages([...inm, ...imm]);
        allMails.current = [...inm, ...imm];
        allMails.mailFilters = mailFilters;
        var _imm = imm;
        var _inm = inm
        let inbox_tag = []

        
        if ((mailFilters || []).length > 0 && !localStorage.getItem("email_filter_email")) {
            
            if (mailTypeFilter == "spam" ) {
                inm = spamMessage;
            }else if (mailTypeFilter != 'important') {
                imm = imm.filter((message) => !mailFilters.includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
            }
            inm = inm && inm.length &&  inm.filter(
                (conversation) =>
                    conversation["conversation"][
                        "messages"
                    ].filter(
                        (message) =>
                        ((message["labelIds"] && message["labelIds"].includes(
                            localStorage.getItem("email_filter_key").toUpperCase()
                        ) == true || (localStorage.getItem("email_filter_key") == 'all' && message["labelIds"].includes(
                            'INBOX'
                        ) == true)) && ((['INBOX', 'IMPORTANT', 'SENT', 'DRAFT'].filter((type) => type != localStorage.getItem("email_filter_key").toUpperCase())).includes(
                            localStorage.getItem("email_filter_key").toUpperCase()
                        ) == false))
                    ).length > 0
            )

        } else if ((mailFilters || []).length > 0 && localStorage.getItem("email_filter_email")) {

            inm = inm.filter((message) => JSON.parse(localStorage.getItem("email_filter_email")).includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
            if (mailTypeFilter != 'important') {
                imm = imm.filter((message) => JSON.parse(localStorage.getItem("email_filter_email")).includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
            }
        }
        
        setAllImportantMessages(_imm);

        if (mailSearchString) {
            inm = inm.filter(item => (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (extractEmails((item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase())[0] == mailSearchString.toLowerCase()));

            imm = imm.filter(item => (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase().includes(mailSearchString.toLowerCase()) == true || (extractEmails((item['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'].toLowerCase())[0] == mailSearchString.toLowerCase()));

        }
        const tagTaskFilters = taskFilters.filter((tag) => tag.main_key == 'my_emails');
        if (tagTaskFilters && tagTaskFilters[0] && tagTaskFilters[0].main_key == "my_emails"  && tagTaskFilters[0].filter_key > 0 ) {
            _inm && _inm.forEach((topic) => {
                topic.thread.custom_topic.map((tag) => {
                    if (tag.tag_id == tagTaskFilters[0].filter_key) {
                        inbox_tag.push(topic)
                    }
                })  
            })
            if (inm.length) {
                inm = inm.concat(inbox_tag);
            }
        }
        setInbox_messages(inm);
        setImportant_messages(imm);
        let nameTopic = taskFilters.filter((n) => n.main_key != 'date_range_filter')
        if (count.mailReqCount == 0) {
            window.addEventListener('email_filter', () => {
                setMailTypeFilter(localStorage.getItem("email_filter_key"));
            });
            window.addEventListener('filter_key_close', () => {
                localStorage.setItem("email_filter_key", "all");
                localStorage.setItem("email_filter_email", "");
                setMailTypeFilter(localStorage.getItem("email_filter_key"));
            });

            window.addEventListener('bulkAddCustomTopic', () => {
                bulkAddCustomTopic();
            });
            count.mailReqCount = count.mailReqCount + 1;
        }
        if (nameTopic[0] && nameTopic[0].filter_key > 0) {
            setMailTypeFilter(nameTopic[0].title);
        }
        dispatch(updateMailCalendarCounter(inm,imm,mailTypeFilter));
        
    }
    const showMailDetailsMessage = (show_documentCanvas, mailToView ,is_inbox) => {
        mailToView['thread']['is_inbox'] = is_inbox;
        setShowDocumentCanvas(show_documentCanvas);
        setMailToView(mailToView);
        hideLeftPanelNew();
        TaskCommentModalOpen();
        dispatch(commanoffcanvasisOpen(true))
        setSelectedTaskIdforIconToggle(mailToView['conversation']['id'])
        if(show_documentCanvas){
        }
        dispatch(getMailAssigees(mailType,mailToView['thread']['id']));
        CustomerServices.markAsRead(mailToView['thread']['id']).then((res) => {
            if (res.status == 1) {
              var inm = oldInbx.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });
              setOldInbx(inm);
              var imm = oldImportant.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });
              setOldImportant(imm);
              setStatesOfMails(inm, imm);

              var ain = inboxMessages.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });
              var alim = importantMessages.map((mail) => {
                if (mail['thread']['id'] == mailToView['thread']['id']) {
                  mail['conversation']['messages'].map((mes) => {
                    var index = mes['labelIds'].indexOf('UNREAD');
                    if (index != -1) {
                      delete mes['labelIds'][index];
                    }
                    return mes;
                  });
                  mail['thread']['mentions'] = [];
                }
                return mail;
              });

              setAllMAilMessages(ain, alim);
            }
          });
    }


    const markImportantEmail = (message_id, message_date) => {
        CustomerServices.markImportantEmail(message_id, message_date).then((res) => {
            if (res.status == 1) {
                swapMailToImportant(message_id);
            }
        });
    }
    const htmlDecode = (content) => {
        let e = document.createElement('div');
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    const swapMailToImportant = (message_id, saverFromInbox = false) => {
        const ibm = oldInbx.filter(item => item['thread']['id'].trim() != message_id.trim());
        const res = oldInbx.filter(item => item['thread']['id'].trim() == message_id.trim());
        if (res && res.length > 0) {
            
        
        res[0]['thread']['is_saver'] = saverFromInbox ? 1 : 0;
        
        res[0]['thread']['is_inbox'] = 0;
        res[0]['thread']['is_important'] = 1;

        setOldInbx(ibm);
        setOldImportant([...res, ...oldImportant]);
        setStatesOfMails(ibm, [...res, ...oldImportant]);
        }
    }
    const markImportantEmailToClose = (message_id) => {
        const res = important_messages.map(item => {
            if (item['thread']['id'].trim() == message_id.trim()) {
                item['thread']['is_closed'] = 1;
            }
            return item;
        });
        setImportant_messages(res);
    }
    const UpdateMessage = (message_id, status, column) => {
        CustomerServices.UpdateMessage(message_id, status, column).then((res) => {
            if (res.status == 1) {
                if (column == 'un_important') {
                    swapMailToIbox(message_id);
                } else {
                    UpdateMessageIsState(message_id, status, column);
                }
            }
        });
    }
    const UpdateMessageIsState = (message_id, status, column) => {
        const res = important_messages.map(item => {
            if (item['thread']['id'].trim() == message_id.trim()) {
                item['thread'][column] = status;
            }
            return item;
        });

        setImportant_messages(res);
    }

    useEffect(() => {
        if (mailFilters) {
            window.addEventListener('gmail_exchange', () => {
                dispatch(fetchMails(taskFilters));
            });
        }
    }, [mailFilters])

    const swapMailToIbox = (message_id) => {
        const imm = oldImportant.filter(item => item['conversation']['id'] != message_id);
        const res = oldImportant.filter(item => item['conversation']['id'] == message_id);
        if (res && res.length > 0) {        
        res[0]['thread']['is_important'] = 0;
        res[0]['thread']['is_inbox'] = 1;
        setOldInbx([...res, ...oldInbx]);
        setOldImportant(imm);
        setStatesOfMails([...res, ...oldInbx], imm);
        }
    }
    const saverFromInbox = (message_id, message_date) => {
        CustomerServices.saverFromInbox(message_id, message_date).then((res) => {
            if (res.status == 1) {
                swapMailToImportant(message_id, true);
            }
        });
    }

    useEffect(() => {
        window.addEventListener('mailSyncRevoked', () => {
            setStatesOfMails([], []);
        });
        window.addEventListener('addEditCategoryModalClose', () => {
            setRefetch(false);
        });
        dispatch(getDefaultTopics(projectId, "", "", 'MyEmail'));
        dispatch(clearDefaultTopicsList(MAIL_TOPIC.concat(defaultTopics && defaultTopics.length > 0 ? defaultTopics : [])));
  
    }, []);

    const handleAddCMTopic = (category) => {
        setRefetch(false);
    };
    const bulkAddCustomTopic = () => {
        var imm = allMails.current.filter((message) => !allMails.mailFilters.includes(extractEmails(message['conversation']['messages'][0]['payload']['headers'].filter((filter) => filter['name'] == 'From')[0]['value'])[0]));
        var topics = [];
        var topicMails = [];
        imm.map((mail) => {
            var from = mail['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From')[0]['value'];
            var title = htmlDecode(from);
            var mail = extractEmails(from)[0];
            if (!topicMails.includes(mail)) {
                topicMails.push(mail);
                topics.push({ 'title': title, 'mail': mail });
            }
        });
        topics = JSON.stringify(topics);
        CustomerServices.bulkAddCustomTopic(topics, projectId).then((res) => {
            if (res.status == 1) {
                setRefetch(false);
                dispatch(getDefaultTopics(projectId, "", "", 'MyEmail'));
                dispatch(getTopicTasks(projectId, "MyEmail"));
            }
        });
    }


    const handleComponentAction = (type,data) =>{
        if(type == 'UpdateMessage'){
            UpdateMessage(data.id,data.value,data.column);
        }else if(type == 'markImportantEmail'){
            markImportantEmail(data.id,data.value);
        }else if(type == 'saverFromInbox'){
            saverFromInbox(data.id,data.value);
        }else if(type == 'shareEMail'){
            dispatch(getMailAssigees(mailType,mailToView['thread']['id']));
        }
    }
    
    const fetchMoreMails = (token) => {
        dispatch(fetchMails(taskFilters, 0,token)).then((res)=>{
        });
    }

    const handleAddTag = (data, item) => {
        try {
            let custom_topic = [];
            const index = inbox_messages.findIndex(item => item['conversation']['historyId'] == data['conversation']['historyId']);
            const threadId = data && data.thread && data.thread.id
            custom_topic = data && data.thread && data.thread.custom_topic
            const isTag = custom_topic.findIndex((topic) => topic.tag_id == item.filter_key);
             if (isTag > -1) {
                custom_topic = custom_topic.filter((topic) => topic.tag_id != item.filter_key);
             }else{
                custom_topic = [{tag_id : item.filter_key}].concat(custom_topic);
             }

            CustomerServices.addCustomTopicTags(threadId, custom_topic).then((res) => {
                if (res.status) {
                    let tagsId = []
                    res.data.tag_ids.map((tag) => {
                        tagsId.push({tag_id : tag})
                    })
                    let updatedInboxMessages = inbox_messages[index];
                    updatedInboxMessages['thread'].custom_topic = tagsId.slice();
                    setInbox_messages(
                      Object.values({ ...inbox_messages, [index]: updatedInboxMessages })
                    );
                }
            })
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleToggleIcons = () =>{
        setShowDefaultIcons(["calendar","assigneTo","pin","priority","comment", "disabled", "hash", "mail",])    
      }

      useEffect(() => {
        if (priority_messages && priority_messages.length > 0) {
            let ids = [];
            priority_messages.map((mail)=>{
                ids.push(mail.thread.id)
            })
            setPrirotyMails(ids)
        }else {
            setPrirotyMails([])
        }
        
      }, [JSON.stringify(priority_messages)])

      useEffect(() => {
        if (!mailNextPageToken) {
            setMessagesPage(1);
        }
      }, [JSON.stringify(inbox_messages)])

      const deleteShedualEmail = (id) => {
        CustomerServices.deleteShedualeEmail(id).then((res) => {
            if (res.status) {
                let updatedschedual = schedule_messages.filter((msg) => msg.id != id);
                dispatch(updateSchedualEmail(updatedschedual));
            }
        })
      }

    return (
        <>
            <div className="d-flex flex-column h-100">
                <div className="schedule-content-wrapper h-100 d-flex flex-column px-0 pb-0 radius_3 pt-10px  d-flex flex-column" id="list_view">
                    <div id="list_view" className={`d-flex flex-column h-100 flex-grow-1 backdroplighttheme ${commanoffcanvasIsOpen ? "" : "daily-schedule-main w-800px"}`}>
                   
                <div className={`comman-main-box padding-bottom-100px custom-accordian-main res-padding-right0 h-100 task-list-accordian ${showDocumentCanvas ? "list-view-toggle" : ""
                    }`}>
                    <Accordion defaultActiveKey={['1']} alwaysOpen className="task-list-accordian-main mt-0">
                        <div className="pill-wrapper with-top-30px">
                            <div id="" className="">
                                <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                             
                                </div>
                            </div>
                        </div>
                        {(mailTypeFilter == 'all' || mailTypeFilter == 'important' || localStorage.getItem("email_filter_email") || !mailTypeFilter || mailTypeFilter) ?

                            <Accordion.Item eventKey="0" className="with-pb-15 ">
                                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border bgpatternCommanImg">
                                <div className="checkbox_accordian">
                                    <Accordion.Header className="active in-progress commanTaskCount accordion-header">
                                        <div className="left-task-header color-blue  fw-semibold title-fonts c-font f-13">
                                            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span>
                                            {_l('l_important')}  ({(important_messages || []).length})
                                        </div>
                                    </Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body className="taskCountToggle">
                                    {important_messages && important_messages.length > 0 ?
                                     important_messages.map((email) => {
                                        return (

                                            <div>
                                                <EmailCard
                                                     email={email}
                                                     active={selectedTaskIdforIconToggle}
                                                     pageName={"myEmail"}
                                                     fromReply={false}
                                                     showOptions={false}
                                                     setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                                     selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                                     showDefaultIcons={showDefaultIcons}
                                                     setShowDefaultIcons={setShowDefaultIcons}
                                                     handleToggleIcons={handleToggleIcons}
                                                     setAllMAilMessages={setAllMAilMessages}
                                                     setAllImportantMessages={setAllImportantMessages}
                                                     showMailDetailsMessage={showMailDetailsMessage}
                                                     inbox={0}
                                                     showDocumentCanvas={showDocumentCanvas}
                                                     UpdateMessage={UpdateMessage}
                                                     markImportantEmail={markImportantEmail}
                                                     is_important={true}
                                                     prirotyMails={prirotyMails}
                                                     />
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <CommanPlaceholder imgType="email" placeholderText = {_l("l_no_data")} />
                                    </>
                                    }
                                    
                                </Accordion.Body>
                            </Accordion.Item> : <></>

                        }
                        {(mailTypeFilter != 'important' && email_filter_key !== "scheduled") ?
                            <Accordion.Item eventKey="1" className="with-pb-15 ">
                                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border bgpatternCommanImg">
                                <div className="checkbox_accordian">
                                    <Accordion.Header className="active  to-do commanTaskCount accordion-header">
                                        <div className="left-task-header  fw-semibold title-fonts c-font f-13">
                                            <span className="c-dots c-7 rounded-circle in-progress-white me-2"></span>
                                            {mailTypeFilter == 'all' ? _l('l_inbox') : mailTypeFilter} ({taskFilters.filter((c) => c.filter_key == "all")[0] && taskFilters.filter((c) => c.filter_key == "all")[0].filter_key == "all" ? mailResultSizeEstimate : (inbox_messages || []).length })
                                        </div>
                                    </Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body className="taskCountToggle">
                                    {inbox_messages && inbox_messages.length > 0 ?
                                     inbox_messages.sort((a,b)=>{
                                      return  b["thread"].is_priority - a["thread"].is_priority
                                        
                                     }).slice(0, (messagesPage * 10)).map((email, index) => {
                                        defaultTopics && defaultTopics.length > 0 && defaultTopics.filter((topic) => topic.main_key === "my_emails")[0] && 
                                        defaultTopics.filter((topic) => topic.main_key === "my_emails")[0].categories.forEach((category) => {
                                            email['thread'] && email['thread'].custom_topic && email['thread'].custom_topic.forEach((tag) => {
                                            if (category.filter_key == tag.tag_id) {
                                                tag.name = category.title
                                              }
                                            })
                                          })
                                        return (
                                            <div>
                                                      <EmailCard
                                                     email={email}
                                                     active={selectedTaskIdforIconToggle}
                                                     pageName={"myEmail"}
                                                     fromReply={false}
                                                     showOptions={false}
                                                     setSelectedTaskIdforIconToggle={setSelectedTaskIdforIconToggle}
                                                     selectedTaskIdforIconToggle={selectedTaskIdforIconToggle}
                                                     showDefaultIcons={showDefaultIcons}
                                                     setShowDefaultIcons={setShowDefaultIcons}
                                                     handleToggleIcons={handleToggleIcons}
                                                     setAllMAilMessages={setAllMAilMessages}
                                                     setAllImportantMessages={setAllImportantMessages}
                                                     showMailDetailsMessage={showMailDetailsMessage}
                                                     inbox={1}
                                                     showDocumentCanvas={showDocumentCanvas}
                                                     UpdateMessage={UpdateMessage}
                                                     markImportantEmail={markImportantEmail}
                                                     is_important={false}
                                                     prirotyMails={prirotyMails}
                                                     index = {index}
                                                     handleAddTag = {handleAddTag}
                                                     />
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <CommanPlaceholder imgType="email" placeholderText = {_l("l_no_data")} />
                                    </>
                                    }
                                    { (inbox_messages && inbox_messages.length > (messagesPage * 10)) || mailNextPageToken ? 
                                        <a href="#/" className="btn c-font f-12 py-2 w-100 view-more-btn transparent bg-white-03-solid-imp"
                                        
                                        onClick={()=>{
                                            if (mailNextPageToken) {
                                                fetchMoreMails(mailNextPageToken)
                                            }
                                            setMessagesPage(messagesPage + 1);
                                        }}
                                        
                                        >
                                            <div className=" d-flex align-item-center justify-content-center">
                                                <div className="">
                                                    <span className="title-fonts">{`${_l('l_view_more')} ${!mailNextPageToken ? `(${inbox_messages.length - (messagesPage * 10)})` : ''}`}</span>
                                                    <CaretDoubleDownIcon
                                                        className="HW14 ms-2"
                                                    />
                                                    <span role="status" aria-hidden="true" className="ms-2 opacity-0 spinner-border spinner-border-sm"></span>
                                                </div>
                                            </div>
                                        </a> 
                                    : <></>}
                                </Accordion.Body>
                            </Accordion.Item> : <></>
                        }
                         {(mailTypeFilter != 'important' && email_filter_key === "scheduled") ?
                            <Accordion.Item eventKey="1" className="with-pb-15 ">
                                <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border bgpatternCommanImg">
                                <div className="checkbox_accordian">
                                    <Accordion.Header className="active  to-do commanTaskCount accordion-header">
                                        <div className="left-task-header  fw-semibold title-fonts c-font f-13">
                                            <span className="c-dots c-7 rounded-circle in-progress-white me-2"></span>
                                            {mailTypeFilter} ({schedule_messages && schedule_messages.length})
                                        </div>
                                    </Accordion.Header>
                                    </div>
                                </div>
                                <Accordion.Body className="taskCountToggle">
                                    {schedule_messages && schedule_messages.length > 0 ?
                                     schedule_messages.map((email, key) => {
                                        return (
                                            <div>
                                                  <GeneralCardView 
                                                    key={key}
                                                    cardTitle={email.subject}
                                                    details = {{
                                                        schedule_date : email.schedule_date, 
                                                        msg_body : email.msg_body, 
                                                    }}
                                                    hideChatAndCall
                                                    isFromEmailSchedul = {true}
                                                    actionItems={[
                                                    {
                                                        actionName: _l("l_delete"),
                                                        actionHandler: () => { deleteShedualEmail(email.id)},
                                                        actionIcon: "TrashIcon",
                                                    },
                                                    ]}
                                                />
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <CommanPlaceholder imgType="email" placeholderText = {_l("l_no_data")} />
                                    </>
                                    }
                                { (schedule_messages && schedule_messages.length > (messagesPage * 10)) || mailNextPageToken ? 
                                    <a href="#/" className="btn c-font f-12 py-2 w-100 view-more-btn transparent bg-white-03-solid-imp"
                                    
                                    onClick={()=>{
                                        if (mailNextPageToken) {
                                            fetchMoreMails(mailNextPageToken)
                                        }
                                        setMessagesPage(messagesPage + 1);
                                    }}
                                    
                                    >
                                        <div className=" d-flex align-item-center justify-content-center">
                                            <div className="">
                                                <span className="title-fonts">{`${_l('l_view_more')} ${!mailNextPageToken ? `(${schedule_messages.length - (messagesPage * 10)})` : ''}`}</span>
                                                <CaretDoubleDownIcon
                                                    className="HW14 ms-2"
                                                />
                                                <span role="status" aria-hidden="true" className="ms-2 opacity-0 spinner-border spinner-border-sm"></span>
                                            </div>
                                        </div>
                                    </a> 
                                : <></>}
                                </Accordion.Body>
                            </Accordion.Item> : <></>
                        }
                    </Accordion>
                    { showDocumentCanvas ?
                     <CommanOffCanvas 
                     show={showDocumentCanvas} 
                     handleClose={() => {setShowDocumentCanvas(false)}} 
                     data={mailToView} 
                     setSelectedId={setSelectedMailId} 
                     docType = {mailType} 
                     handleParentComponentAction={handleComponentAction}
                     pageName={"MyEmail"}
                      /> : <></>}
                   
                    {bulkEmailSendShow ? 
                        <AddEmailTemplatesModalNew
                            show={bulkEmailSendShow}
                            handleClose={() => { 
                                setBulkEmailSendShow(false); 
                                setschedualEmailData({});
                            }}
                            type = {"isFromEmailFooter"}
                            from = {"isFromSchedualEmail"}
                            data = {schedualEmailData}
                        />
                        : <></>}
                </div>
                
                </div>
                </div>
            </div>
        </>
    );
};
export default MyEmailList;