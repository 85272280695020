import React, {  } from "react";
import MapPageCard from "../components/MapPage/MapPageCard";

const MapPage = () => {

  return (
    <>
      <MapPageCard />
    </>
  );
};

export default MapPage;
