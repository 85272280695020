import React from 'react';
 

function AtIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M10.5 7.99957C10.5 9.38028 9.38071 10.4996 8 10.4996C6.61929 10.4996 5.5 9.38028 5.5 7.99957C5.5 6.61886 6.61929 5.49957 8 5.49957C9.38071 5.49957 10.5 6.61886 10.5 7.99957ZM10.5 7.99957C10.5 9.38082 11 10.4996 12.25 10.4996C13.5 10.4996 14 9.38082 14 7.99957C13.9997 6.66277 13.5529 5.36435 12.7307 4.31036C11.9084 3.25636 10.7577 2.50715 9.46113 2.18161C8.16457 1.85607 6.79643 1.97284 5.57379 2.5134C4.35116 3.05396 3.34406 3.98735 2.7123 5.16545C2.08054 6.34355 1.86031 7.6989 2.08655 9.01642C2.31279 10.3339 2.97254 11.5382 3.96112 12.438C4.9497 13.3379 6.21048 13.8819 7.54341 13.9836C8.87634 14.0853 10.2051 13.739 11.3188 12.9996M10.5 7.99957V5.49957" />
</svg>



		</React.Fragment>
	)
}

export default AtIcon