import React from 'react'
const KanbanTaskListSkeleton = () => {
    return (
        <div className='bg-white-03-solid border-bottom list-view taskcardliteversion SkeletonMain ps-10px pe-10px pt-15px pb-10px'>
            <div className='d-flex justify-content-between align-items-center mb-10px'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <div className='h20w20 me-10px skeleton-animation radius_3 me-10px mb-10'></div>
                    <div className='h32w32 rounded-5 skeleton-animation'></div>
                    <div className='ms-10px flex-grow-1'>
                        <div className='mb-1 skeleton-animation radius_3' style={{ width: '40%', height: '15px' }}></div>
                        <div className='skeleton-animation radius_3' style={{ width: '25%', height: '10px' }}></div>
                    </div>
                </div>
                <div className='d-flex align-items-cente gap10'>
                    <div className='h32w32 skeleton-animation radius_3'></div>
                </div>
            </div>
            <div className='d-flex align-items-cente gap10'>
                <div className='h32w32 skeleton-animation radius_3'></div>
                <div className='h32wauto radius_3 rounded-5 skeleton-animation' style={{ width: '80px', }}></div>
                <div className='h32w32 rounded-5 skeleton-animation'></div>
                <div className='h32w32 skeleton-animation radius_3'></div>
                <div className='h32w32 skeleton-animation radius_3'></div>
            </div>
        </div>
    )
}
export default KanbanTaskListSkeleton