import React, { useEffect, useState } from 'react'
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import LocationCustomIcon from '../../assets/icons/LocationCustomIcon';
import { _l } from '../../hooks/utilities';
import DropdownWithAccordion from '../Dropdowns/DropdownWithAccordion';
import customerServices from '../../services/customer-services';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import AddTaskIcon from '../../assets/icons/AddTaskIcon';
import RequestTaskIcon from '../../assets/icons/RequestTaskIcon';
import jquery from "jquery";
import BuildingsIcon from '../../assets/icons/BuildingsIcon';
import ChatIcon from '../../assets/icons/ChatIcon';
import FileTextIcon from '../../assets/icons/FileTextIcon';
import UserCircleGearIcon from '../../assets/icons/UserCircleGearIcon';
import UsersThreeIcon from '../../assets/icons/UsersThreeIcon';
import WrenchIcon from '../../assets/icons/WrenchIcon';

const CustomMarker = ({ data, setActiveMarkerSpaceId, activeMarkerSpaceId, locationPinScale, }) => {
  const [users, setUsers] = useState([]);
  const [mapPinScale, setmapPinScale] = useState('scale05')
  const [spaceData, setspaceData] = useState([])
  const [selectedOffice, setSelectedOffice] = useState(
    localStorage.getItem("selectedOffice")
      ? localStorage.getItem("selectedOffice")
      : 0
  );

  const noActiveSpacePageNames = ["invoice", "estimate", "subscription", "staff", "benchmark", "schedule", "CompanyProfile", "Proposals", "MyProfile", "client", "fullcalendar", "AdminTaskPage", "credit_notes", "_equipments", "PaymentPage","dashboardnew"];
  const pageName = null

  jquery(document).mouseup(function (e) {
  var container = jquery('.location-custom-style');
  if (!container.is(e.target) && container.has(e.target).length === 0) {
    handleShowData(0)
  }
})
  
  useEffect(() => {
    let peopleArr = []
    data.customers.forEach((customer) => {
      peopleArr.push({ ...customer, type: "Customer" });
    });
    data.providers.forEach((provider) => {
      peopleArr.push({ ...provider, type: "Provider" });
    });
    setUsers(peopleArr.slice());
  }, [data])

  const handleShowData = (id) =>{
    setActiveMarkerSpaceId(id !==  activeMarkerSpaceId ? id : 0)
  }
  useEffect(()=>{
    setmapPinScales(locationPinScale)
  },[locationPinScale])

  const setmapPinScales = (locationPinScale) => {
    let locationPinScales = '';
    switch (true) {
      case locationPinScale <= 3:
        locationPinScales = 'scale05';
        break;
      case locationPinScale == 4:
        locationPinScales = 'scale06';
        break;  
      case locationPinScale == 5:
        locationPinScales = 'scale07';
        break;
      case locationPinScale == 6:
        locationPinScales = 'scale08';
        break;
      case locationPinScale == 7:
      locationPinScales = 'scale09';
      break;
      case locationPinScale >= 8:
        locationPinScales = 'scale1';
        break;      
      default:
        break;
    }
    setmapPinScale(locationPinScales)
  }

  const getspacedata = (e ,project_id)=>{
    customerServices.getSpaceDataOhover(project_id).then((res)=>
    {
      if(res.status)
      {
        setspaceData(res.data)
      }
    })
  }
  const activeClass =(selectedOffice > 0 && selectedOffice == data.project_id && !noActiveSpacePageNames.includes(pageName)) && !localStorage.getItem('selectedVirtualSpace') && localStorage.getItem("isLiteVersion") != "true" ? "active" : "";
  const badgeClass = data.notification_count > 0 ? "with-badge" : "";
  const projectStatus = data.project_status && data.project_status == 2
                          ? "inprogress half"
                          : data.project_status == 3
                            ? "inprogress half"
                            : data.project_status == 5
                              ? "done half"
                              : data.project_status == 6
                                ? "done"
                                : data.project_status == 1
                                ? "onboarding"
                                : "";
  return (
    <div className='location-custom-style'>
      { activeMarkerSpaceId == data.id? <>
      <span class="badge p-1 rounded-pill color-white mb-2">{_l("l_tenants")}</span>

      <div className="multi_inner_wrapper with-min-width d-flex with_minus justify-content-start mb-2">
        {
          users && users.map((user, key) => {
            if (key < 2) {
              return <a href="#/" className="comman-image-box  h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding after-none blue-border-w-2">
                <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${user.image})` }}></div>
              </a>
            }
          })
        }
        {
          users.length > 2 ?
            <a href="#/" className="comman-image-box  h35w35 rounded-circle one assignee-list-wrapper card-view-less-padding after-none">
              <UncontrolledDropdown className="with_overlay d-flex rounded-circl">
                <DropdownToggle tag="a" className="comman-image-box h35w35 with_overlay d-flex rounded-circle dropdown team-list-dropdown z-index-2 after-none blue-border-w-2" >
                  <div className="unread_count m-auto"
                    type="button"
                    id="GeneralListing"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false">
                    +{users.length - 2}
                  </div>
                    <DropdownWithAccordion
                      list={users}
                      handler={() => { }}
                      unControlList={false}
                    />
                </DropdownToggle>
              </UncontrolledDropdown>
            </a>
            : <></>}
      </div>
      </> : <></>}
      { data.id === activeMarkerSpaceId  || activeMarkerSpaceId== 0 ? 
          <>       
            <div>
            {["auto"].map((placement) => (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                delay={{ show: 1000 }}
                key={placement}
                placement={placement}
                onEnter={(e)=>getspacedata(e,data.project_id)}
                overlay={
                  <Popover
                    id={`popover-positioned-${placement}`}
                    className=""
                  >
                    {spaceData.length > 0 ?  <Popover.Body className="p-0">
                      <div className="p-0 popover-detail-wrapper">
                        <li className={`office-box  p-15 hr_1 pb-2 ${(pageName && ['PremiumSubscription', 'leadPage', 'dashboard','MyEmail'].includes(pageName)) || window.location.pathname == '/dashboardfordaterange'
                        ? ""
                        : activeClass
                        } ${badgeClass}  with-circle ${projectStatus}`}>
                          <a href="#/" className="w-100 text-truncate">
                            <div className="d-flex align-items-center">
                              <div className="c-list-icon">
                                <div className="h40w40 with-bg d-flex align-items-center bg-white-05 radius_5 rounded-circle comman-round-box">
                                  <div
                                    className="comman-bg-img h-100 w-100 bg-style-cover radius_5 rounded-circle d-none"
                                    style={{ backgroundImage: `url('${data.logo}')` }}
                                  ></div>
                                  <span class="m-auto c-font f-10 p-1 office-subtitle text-truncate hover-span">{ spaceData[0].office_title }</span>
                                </div>
                              </div>
                              <div className="ps-2 w100minus40 title-fonts">
                                <div className="c-list-detail c-font f-14 text-truncate color-white c-font f-12">
                                  { spaceData[0].office_title }
                                </div>
                                <div className="c-font f-10 title-fonts color-white-60 text-truncate">
                                  { spaceData[0].postal_address }
                                </div>
                              </div>
                            </div>
                            <div className="w-100 d-flex align-items-center pt-3">
                              <div className="d-flex align-items-center progress-slider flex-grow-1">
                                <div className="progress">
                                  <div className="progress-bar" style={{ width: `${spaceData[0].workspace_score}%` }}></div>
                                </div>
                                <div class="progress-text c-font f-10 color-white-60 title-fonts">{spaceData[0].workspace_score}%</div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li className="hr_1">
                          <div className="p-15 py-2">
                            <div className="color-white-60 text-truncate c-font f-12">
                              <span>{_l("l_date")}: </span>
                              <span className="with_separator_10">{spaceData[0].date_added}</span><span className="ps-2">{_l("l_project_id")}: </span><span>{spaceData[0].project_id}</span></div>
                          </div>
                        </li>
                        <li className="hr_1">
                          <div className="p-10">
                            <div className="c-font f-12 color-white-60 text-truncate">{_l("l_kpi")}</div>
                            <div className="p-15 pb-0 px-0 row ">
                              <div className="col-xl-6">
                                <div className="d-flex pb-2">
                                  <div className="comman_action_icon me-2">
                                    <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                      <BuildingsIcon className="HW16" />
                                    </div>
                                  </div>
                                  <div className="card-list-heading text-truncate ps-1">
                                    <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].superficies}</div>
                                    <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                      <span className="hover-span">{_l("l_sq_meter")}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="d-flex pb-2">
                                  <div className="comman_action_icon me-2">
                                    <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                      <UserCircleGearIcon className="HW16" />
                                    </div>
                                  </div>
                                  <div className="card-list-heading text-truncate ps-1">
                                    <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].employees_count}</div>
                                    <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                      <span className="hover-span">{_l("l_tetants")}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="d-flex pb-2">
                                  <div className="comman_action_icon me-2">
                                    <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                      <UsersThreeIcon className="HW16" />
                                    </div>
                                  </div>
                                  <div className="card-list-heading text-truncate ps-1">
                                    <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].operator_count}</div>
                                    <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                      <span className="hover-span">{_l("l_operators")}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="d-flex pb-2">
                                  <div className="comman_action_icon me-2">
                                    <div className="d-flex c-list-icon action_icon on-hover-active-toggle-img light-theme-img">
                                      <WrenchIcon className="HW16" />
                                    </div>
                                  </div>
                                  <div className="card-list-heading text-truncate ps-1">
                                    <div className="title-fonts c-font f-16 line-height-1 text-truncate fw-semibold">{spaceData[0].equipments_count}</div>
                                    <div className="color-white-60 c-font f-12 text-truncate pt-1">
                                      <span className="hover-span">{_l("l_equipments")}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <div>
                          <div className="">
                            <div className="c-font f-12 color-white-60 p-10 pb-0">{_l("l_notification")}</div>
                            <ul className="notification-listing">
                              <li className="hr_sepretr dropdown-item">
                                <div className="d-flex align-items-center text-truncate  ">                              
                                    <div className="c-list-icon ">
                                      <AddTaskIcon className="HW18" />
                                    </div>
                                    <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                      <div className="c-list-detail c-font f-14 text-truncate">{_l("l_tasks")}</div>
                                      {
                                        data.notification_count == 0 ? (<></>) : (
                                          <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex">{spaceData[0].notification_count}</div>
                                      )}
                                    </div>
                                </div>
                              </li>
                              <li className="hr_sepretr dropdown-item">
                                <div className="d-flex align-items-center text-truncate  ">
                                  <div className="c-list-icon ">
                                    <RequestTaskIcon className="HW18 fill-transparent" />
                                  </div>
                                  <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                    <div className="c-list-detail c-font f-14 text-truncate">{_l("l_request")}</div>
                                    <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex d-none">0</div>
                                  </div>
                                </div>
                              </li>
                              <li className="hr_sepretr dropdown-item">
                                <div className="d-flex align-items-center text-truncate  ">                              
                                    <div className="c-list-icon ">
                                    <ChatIcon className={`HW16`} />
                                    </div>
                                    <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                      <div className="c-list-detail c-font f-14 text-truncate">{_l("l_chat_modal")}</div>
                                      {
                                        spaceData[0].notification_count && data.notification_count == 0 ? (<></>) : (
                                      <div className={`unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex ${spaceData[0].chat_notification_count > 0 ? "" : "d-none"}`}>{spaceData[0].chat_notification_count}</div>
                                      )}  </div>
                                </div>
                              </li>
                              <li className="hr_sepretr dropdown-item">
                                <div className="d-flex align-items-center text-truncate  ">                              
                                    <div className="c-list-icon ">
                                    <FileTextIcon className="HW16" />
                                    </div>
                                    <div className="ps-3 w100minus20 text_wrap d-flex align-items-center  justify-content-between">
                                      <div className="c-list-detail c-font f-14 text-truncate">{_l("l_documents")}</div>
                                      <div className="unread-count rounded-pill badge-custom h18w18 border-0 with-position-relative with-ms-5px with-d-flex d-none">0</div>
                                    </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Popover.Body> : <></>}
                  </Popover>
                }
              >
              <div className={` cursor-pointer  d-flex h50w50 justify-content-center position-relative mb-3 ${mapPinScale} `} onClick={()=>handleShowData(data.id)}>
                  <LocationCustomIcon className="" />
                  <div className='with-circle not-started position-absolute start-50 translate-middle location-inner-div'>
                    <div className="comman-round-box d-flex h35w35 rounded-circle title-fonts">
                      <span className="m-auto c-font f-9 office-subtitle text-truncate p-1">{data.office_title}</span>
                    </div>
                  </div>
              </div>
              </OverlayTrigger>
            ))}
          </div>
      </> : <></>}
      { activeMarkerSpaceId == data.id? <>
      <span class="badge badge-in-progress rounded-pill text-uppercase py-2 mb-2"><span className=''>{_l("l_satisfaction")}</span></span>
      <div className="dropdown progress-wrapper-minus d-flex">
        <a href="#/" className="progress-wrapper-link">
          <div className="progress-slider with_separator_10 before-dnone card-view-less-padding">
            <div className="d-flex align-items-center">
              <div className="progress w_100">
                <div className="progress-bar progress-bar-color-blue" style={{ width: `${data.satisfaction}%` }}></div>
              </div>
              <div className="progress-text c-font f-12 title-fonts">{data.satisfaction}%</div>
            </div>
          </div>
        </a>
      </div>
      </>:<></>}
    </div>
  );
};

export default CustomMarker;