import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, loginAsUser } from "../actions/auth";
import { useNavigate, useParams } from "react-router-dom";
import EyeIcon from "../assets/icons/EyeIcon";
import LogoGreenDark from "../assets/icons/LogoGreenDark";
import LogoGreenLight from "../assets/icons/LogoGreenLight";
import EyeSlashIcon from "../assets/icons/EyeSlashIcon";
import LogoGreenBlack from "../assets/icons/LogoGreenBlack";
import { _l, SetLightAndDarkTheme } from "../hooks/utilities";
import { getCustomerSpaces, getUserDetail, isPremuimEstimateDisable, projectTaskCounts, setSelectedGloablView } from "../actions/customer";
import { showMessage } from "../actions/messages";
import authServices from "../services/auth-services";
import { adminLoginAsStaff } from "../actions/admin-actions";
import Spinner from "react-bootstrap/esm/Spinner";
import jquery from "jquery";
const SetPassword = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loder, setLoder] = useState(false);
  const { getUserInfo } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type, id } = useParams();
  const user_id = localStorage.getItem("contact_id") != "undefined" ? localStorage.getItem("contact_id") : localStorage.getItem("staff_id");
  const ProjectId = localStorage.getItem("user_project_id");
  const ClientId = localStorage.getItem("user_client_id");
  const loginAsuser = localStorage.getItem("from_login_as_user");
  let login_asuser = (loginAsuser == 'true');
  const {themeColor} = useSelector((state) => state.customer)
  const [LightTheme , setLightTheme] = useState(localStorage.getItem('selectedTheme') == "light" ? true : false);

  useEffect(() => {
    if (localStorage.getItem("accessToken") && !login_asuser) {
      if (localStorage.getItem("membership_package_id") == 1) {
   
        navigate("/myprofile");
        dispatch(isPremuimEstimateDisable());
      } else {
        dispatch(setSelectedGloablView("liteVersionView"));
      }
    }
    dispatch(getUserDetail(type, id, setShowLoader));
  }, []);
  useEffect(() => {
    if (getUserInfo) {
      setEmail(getUserInfo.email)
      setUserId(getUserInfo.id) 
      setUserType(getUserInfo.type)
      if (getUserInfo.registration_confirmed == 1) {
        navigate("/login");
      } else {
        localStorage.setItem("is_registration_confirmed", getUserInfo.registration_confirmed);
        localStorage.setItem("user_type", getUserInfo.type);
        localStorage.setItem("userId", getUserInfo.id);
        if (login_asuser) {
          dispatch(
            loginAsUser(getUserInfo.email, ClientId, ProjectId)
          ).then(() => {

            if (!localStorage.getItem("accessToken")) {
              dispatch(
                showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
              );
            } else {
              localStorage.setItem("password", "12345");
              if (localStorage.getItem("membership_package_id") == 1) {
              
                navigate("/myprofile");
              } else {
                localStorage.setItem("selectedOffice",ProjectId);
                dispatch(getCustomerSpaces(ClientId, userId));
                dispatch(adminLoginAsStaff(true));
                dispatch(projectTaskCounts(ProjectId));
                navigate("/redirect", {
                  state: {
                    url: "/",
                  
                  },
                });
              }
            }
            localStorage.removeItem("from_login_as_user");
          });
        } else {
          if (localStorage.getItem("redirectfrom") == "loginpage") {
            setShowLoader(false);
                
          } else{
          dispatch(
            login(getUserInfo.email, "12345")
          ).then((res) => {
            setShowLoader(false);
            if (!localStorage.getItem("accessToken") || (res && res.status && res.status != 1)) {
              dispatch(
                showMessage("unsucess", _l("l_error"), res.message)
              );
            } else {
              if (localStorage.getItem("membership_package_id") == 1) {
            
                navigate("/myprofile");
              } else {
                navigate("/dashboard");
              }
            }
          });
        }}
      }
   
      
    }
  }, [getUserInfo])

  useEffect(() => {
    SetLightAndDarkTheme();
    if (themeColor == "light") {
      setLightTheme(true);
    }else{
      setLightTheme(false);
    }

    
  }, [themeColor])

  const handleSetPassword = () => {
    setLoder(true)
    if (password == "") {
      setLoder(false);
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_password')))
    } else if (confirmPassword == "") {
      setLoder(false);
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_confirm_password')))
    } else if (confirmPassword != password) {
      setLoder(false);
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_password_and_confirm_password_doesnot_match")));
    } else {
      authServices.setUserPassword(userId, userType, password).then(
        (response) => {
          setLoder(false);
          if (response.status == 1) {
            localStorage.removeItem('redirectfrom');
            if (login_asuser) {
              dispatch(
                loginAsUser(email, ClientId, ProjectId)
              ).then(() => {
          
                if (!localStorage.getItem("accessToken")) {
                  dispatch(
                    showMessage("unsucess", _l("l_error"), _l("l_invalid_credentials"))
                  );
                } else {
                  localStorage.setItem("password", "12345");
          
                  if (localStorage.getItem("membership_package_id") == 1) {
                  
                    navigate("/myprofile");
                    
                  } else {
                    localStorage.setItem("selectedOffice",ProjectId);
                    dispatch(getCustomerSpaces(ClientId, userId));
                    dispatch(adminLoginAsStaff(true));
                    dispatch(projectTaskCounts(ProjectId));
                    navigate("/redirect", {
                      state: {
                        url: "/",
                      },
                    });
                  }
                }
                localStorage.removeItem("from_login_as_user");
              });   
            } else {
            dispatch(
              login(email, password)
            ).then((res) => {
              setShowLoader(false);
              if (!localStorage.getItem("accessToken") || (res && res.status && res.status != 1)) {
                dispatch(
                  showMessage("unsucess", _l("l_error"), res.message)
                );
              } else {
                localStorage.setItem("password", password);

                if (localStorage.getItem("membership_package_id") == 1) {
                 
                  navigate("/myprofile")
                } else {
                  dispatch(setSelectedGloablView("liteVersionView"));
                }
              }
            });
          }
          } else {
            dispatch(
              showMessage("unsucess", _l("l_error"), response.message)
            );
            setLoder(false);
          }
        }
      );
    }
  }
  const handleReset = () => {
    confirmPassword = "";
    password = "";
  };


  return (
    
      <div>
        <div className="login_page">
          <div className="login_box">
            <div className="login-logo">
              <LogoGreenBlack className="header-logo-img logo-light" />
              {LightTheme ? < LogoGreenLight className="header-logo-img "/> :  <LogoGreenDark  className="header-logo-img"/>}
            </div>
            <div className="comman_title_white">{_l("l_set_password")}</div>
            <div className="custom_input_box_main">
              <div className="custom_input_box with_before">
                <label className="input_box_label d-flex align-items-center justify-content-between">
                  <div className="title_text color-white-60">
                    {_l("l_password")}
                    <span className="star_img">*</span>
                  </div>
                </label>
                <div
                  className={` ${passwordShown ? "active" : ""} `}
                  id="password_class"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="input_box  autofill_off"
                      placeholder="*****"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <a href="#/" className="">
                      <div
                        className={`img-width-18 ${passwordShown ? "" : "d-none"}`}
                        onClick={() => {
                          setPasswordShown((pre) => !passwordShown);
                        }}
                      >
                        <EyeSlashIcon className="HW18" />
                      </div>
                      <div
                        className={`img-width-18 ${passwordShown ? "d-none" : ""}`}
                        onClick={() => {
                          setPasswordShown((pre) => !passwordShown);
                        }}
                      >
                        <EyeIcon className="HW18" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="custom_input_box with_before">
                <label className="input_box_label d-flex align-items-center justify-content-between">
                  <div className="title_text color-white-60">
                    {_l("l_confirm_password")}
                    <span className="star_img">*</span>
                  </div>
                </label>
                <div
                  className={` ${confirmPasswordShown ? "active" : ""} `}
                  id="password_class"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      type={confirmPasswordShown ? "text" : "password"}
                      className="input_box  autofill_off"
                      placeholder="*****"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSetPassword();
                        }
                      }}
                    />
                    <a href="#/" className="">
                      <div
                        className={`img-width-18 ${confirmPasswordShown ? "" : "d-none"}`}
                        onClick={() => {
                          setConfirmPasswordShown((pre) => !confirmPasswordShown);
                        }}
                      >
                        <EyeSlashIcon className="HW18" />
                      </div>
                      <div
                        className={`img-width-18 ${confirmPasswordShown ? "d-none" : ""}`}
                        onClick={() => {
                          setConfirmPasswordShown((pre) => !confirmPasswordShown);
                        }}
                      >
                        <EyeIcon className="HW18" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="mtop20"></div>
              <div className="login_btn d-flex align-items-center justify-content-start">
              <button className={`btn btn-primary me-4 ${loder ? "for-disabled" : "" }`} 
               onClick={handleSetPassword}>
                  {loder
                    ? _l('l_please_wait')
                    : _l("l_set_password")}
                    {
                    loder ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 `}
                        aria-hidden="true"
                      />
                    ) :
                      <></>
                  }
                </button>
                <button className="btn btn-secondary" onClick={handleReset}>
                  {_l("l_reset")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SetPassword;
