import React from "react";
import { _l } from "../../../hooks/utilities";
import CreditNotesList from "../../../components/CreditNotesList";
const ClientCreditnoteTab = ({data}) => {

    return (
        <div>
            <CreditNotesList pageName="customer" clientid={data.id}/>
        </div>
    )
}
export default ClientCreditnoteTab;