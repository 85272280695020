import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaskModalClose, _l, showLeftPanelNew } from "../../hooks/utilities";
import {
    muteCall,
    unMuteCall,
    callAction,
} from "../../actions/chat";
import {
    setCallDisconnect,
    setCallFullscreen,
    setCallRecording,
    setCallRecordingAction,
} from "../../actions/customer";
import { endVapiCall } from "../../actions/vapi";
import MicrophoneIcon from "../../assets/icons/MicrophoneIcon";
import MicrophoneSlashIcon from "../../assets/icons/MicrophoneSlashIcon";
import VideoCameraSlashIcon from "../../assets/icons/VideoCameraSlashIcon";
import VideoCameraIcon from "../../assets/icons/VideoCameraIcon";
import RecordIcon from "../../assets/icons/RecordIcon";
import PhoneSlashIcon from "../../assets/icons/PhoneSlashIcon";
const FeedchatInTaskModalButton = ({ isMute, setIsMute, isCameraOff, setIsCameraOff, isCameraFound, isScreenShareOn, setIsScreenShareOn }) => {
    const dispatch = useDispatch();
    const { callStatus, myStream } = useSelector((state) => state.chat);
    const { callRecordingActive } = useSelector((state) => state.customer);
    const [isDisabled, setIsDisabled] = useState(false)
    const muteTheCall = async () => {
        myStream.getAudioTracks()[0].enabled = false;
        setIsMute(true);
        dispatch(
            muteCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole,
            })
        );
    };
    const unMuteTheCall = () => {
        myStream.getAudioTracks()[0].enabled = true;
        setIsMute(false);
        dispatch(
            unMuteCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole,
            })
        );
    };
    const turnCamera = async (val = "") => {
        if(val == "off"){
            setIsCameraOff(true);
            doCallAction("turnCamera","off");
            myStream.getVideoTracks()[0].enabled = false;
        }else{
            setIsCameraOff(false);
            doCallAction("turnCamera","on");
            myStream.getVideoTracks()[0].enabled = true;
        }
    };
    const disconnectCall = () => {
        dispatch(setCallDisconnect(true));
        dispatch(setCallRecording(false));
        TaskModalClose();
        dispatch(setCallFullscreen(false));
        showLeftPanelNew();
    };
    const doCallAction = (actionKey, actionVal) => {
        dispatch(callAction({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            actionKey: actionKey,
            actionVal: actionVal
        }));
    };
    useEffect(() => {
        if (callStatus && callStatus.role && callStatus.role == 4 ) {
            setIsDisabled(true);
            setIsCameraOff(true);
        } else {
            setIsDisabled(false);
        }
    }, [callStatus])
    return (
        <div className="position-sticky bottom-0 start-0 file-parent-box">
            <div className="border-top"></div>
            <div className="p-10">
                <div className="d-flex align-items-center justify-content-center">
                    {isMute ? 
                    <a className={`btn btn-white-05 w_100px rounded-5 ${isDisabled ? "for-disabled" : ""}`} onClick={() => { if(!isDisabled){unMuteTheCall()} }}>
                        <MicrophoneSlashIcon className="HW32" />
                    </a>
                    : <a className={`btn btn-white-05 w_100px rounded-5 ${isDisabled ? "for-disabled" : ""}`} onClick={() => { if(!isDisabled){ muteTheCall()} }}>
                        <MicrophoneIcon className="HW32" />
                    </a>
                    }
                    <div className="p-15 py-0"></div>
                    {isCameraOff ? <a className={`btn ${isCameraFound ? "btn-white-05" : "btn-warning for-disabled"} w_100px rounded-5`} onClick={() => {if(isCameraFound){ turnCamera(); } }}>
                        <VideoCameraSlashIcon size={32} weight="light" className={`HW32 ${isCameraFound ? "" : "black-l-white"}`} />
                    </a>
                    : <a className={`btn btn-white-05 w_100px rounded-5`} onClick={() => { turnCamera("off") }} >
                        <VideoCameraIcon size={32} weight="light" className="HW32 " />
                    </a>
                    }
                    <div className="p-15 py-0"></div>
                    <a className={`btn btn-white-05 w_100px rounded-5 ${isDisabled ? "for-disabled" : ""}`} onClick={() => { dispatch(setCallRecordingAction(callRecordingActive ? "stop" : "start")) }}>
                        <RecordIcon className={`HW32 fill-white ${ callRecordingActive ? "color-green" : ""}`}/>
                    </a>
                    <div className="p-15 py-0"></div>
                    <a className="btn btn-warning w_100px rounded-5" onClick={() => { 
                        if (isDisabled) {
                            endVapiCall()
                        } else {
                            disconnectCall()
                        }
                         }}>
                        <PhoneSlashIcon className="HW32 black-l-white" />
                    </a>
                </div>
            </div>
        </div>
    );
};
export default FeedchatInTaskModalButton;
