import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { _l } from "../../hooks/utilities";
import { showMessage } from "../../actions/messages";
import jquery from "jquery";

import {
    endCall,
    acceptCall,
    callNotRespond,
} from "../../actions/chat";

import {
    ATTACH_BASE_URL,
    USR_IMG_PLACEHOLDER,
} from "../../actions/chat-action-type";
import { endVapiCall } from "../../actions/vapi";
import BuildingsIcon from "../../assets/icons/BuildingsIcon";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import ChatCenteredDotsIcon from "../../assets/icons/ChatCenteredDotsIcon";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import PhoneSlashIcon from "../../assets/icons/PhoneSlashIcon";
import UsersThreeIcon from "../../assets/icons/UsersThreeIcon";

const FeedChatCallModal = ({ show, handleClose, callStatus}) => {
    const dispatch = useDispatch();
    const [callImage, setCallImage] = useState(USR_IMG_PLACEHOLDER);
    const [callName, setCallName] = useState("");
    const [myCallRole, setMyCallRole] = useState("");
    const [myCallStatus, setMyCallStatus] = useState("");

    const acceptTheCall = () => {
        dispatch(acceptCall({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            offer: callStatus.offer
        }));
    };

    const disconnectCall = () => {
        if(callStatus.status == "initCall" && callStatus.callRole == "caller"){
            autoEndTheCall(false);
        }else{
            dispatch(endCall({
                userId: callStatus.callerInfo._id,
                receiverId: callStatus.receiverInfo._id,
                callRole: callStatus.callRole
            }));
        }
    };

    const autoEndTheCall = (showMsg = true) => {
        if(showMsg){
            dispatch(showMessage("unsucess", _l("l_call_status"),  _l("l_not_responding")));
        }
        dispatch(callNotRespond({
            userId: callStatus.callerInfo._id,
            receiverId: callStatus.receiverInfo._id,
            callRole: callStatus.callRole,
            projectId: callStatus.extraData.projectId,
            groupId: callStatus.extraData.groupId
        }));
    };

    useEffect(() => {
        if(!jquery.isEmptyObject(callStatus)){
            setMyCallStatus(callStatus.status);
            setMyCallRole(callStatus.callRole);
            if(callStatus.callRole == "caller"){
                if(callStatus.receiverInfo){
                    if(callStatus.receiverInfo.displayPicture != ""){
                        if (callStatus.receiverInfo.displayPicture && ((callStatus.receiverInfo.displayPicture).includes('https') || (callStatus.receiverInfo.displayPicture).includes('myr.ai'))) {
                            setCallImage(encodeURI(callStatus.receiverInfo.displayPicture));
                        }else{
                            setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.receiverInfo.displayPicture));
                        }
                    }else{
                        setCallImage(USR_IMG_PLACEHOLDER);
                    }
                    setCallName(callStatus.receiverInfo.fname+" "+callStatus.receiverInfo.lname);
                }
            }else{
                if(callStatus.callerInfo){
                    if(callStatus.callerInfo && callStatus.callerInfo.displayPicture != ""){
                        setCallImage(encodeURI(ATTACH_BASE_URL+callStatus.callerInfo.displayPicture));
                    }else{
                        setCallImage(USR_IMG_PLACEHOLDER);
                    }
                    setCallName(callStatus.callerInfo.fname+" "+callStatus.callerInfo.lname);
                }
            }
        }
    }, [JSON.stringify(callStatus)]);
    return (
    <>
        <div className="">
            <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            className="custom-modal-style feedchatmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static" backdropClassName="Feedchat-backdrop"
            >
                <Modal.Body className="p-0">
                    <div className="Feedchatmain">
                        <div className="FeedchatHeader">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex align-items-center  with-circle inprogress half pe-15px">
                                    <a href="#/" class=" h-100 d-flex align-items-center justify-content-center ic-provider-list office-list-circle">
                                        <div class=" h42w42 comman-round-box radius_3 title-fonts d-flex">
                                            <BuildingsIcon className="HW24 "/>
                                        </div>
                                    </a>
                                    <div className="ps10px">
                                        <div className="c-font f-18 fw-semibold title-fonts">{callStatus && callStatus.extraData && callStatus.extraData.projectName}</div>
                                        <div className="c-font color-white-60 f-14 max-width-160px text-truncate">{callStatus && callStatus.extraData && callStatus.extraData.projectAddress}</div>
                                    </div>
                                </div>
                                <div className="p-20px"><CaretRightIcon className="HW18 "/></div>
                                <div className="ps15px comman_action_icon d-flex align-items-center">
                                    <div class=" h42w42 rounded-circle title-fonts d-flex action_icon with_bg">
                                        <UsersThreeIcon className="HW24 "/>
                                    </div>
                                    <div className="ps10px">
                                        <div className="c-font f-18 fw-semibold title-fonts">{callStatus && callStatus.extraData && callStatus.extraData.topicName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-15"></div>
                        <div className="border-bottom"></div>
                        <div className="p-15"></div>
                        <div className="p-15"></div>
                        <div className="FeedchatContain ">
                            <div className="w_260px d-flex flex-column justify-content-center align-items-center m-auto">
                                <div className="CallerImg position-relative">
                                    <div className="comman-image-box multi-wrapper-border h120w120 rounded-circle">
                                        <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle" style={{ backgroundImage: `url(${callImage})` }}></div>
                                    </div>
                                    <span className="Commanwave wave1"></span>
                                    <span className="Commanwave wave2"></span>
                                    <span className="Commanwave wave3"></span>
                                </div>
                                <div className="p-15"></div>
                                <div className="pt-15 text-center">
                                    <div className="c-font f-32 title-fonts fw-semibold text-truncate ">{callName}</div>
                                </div>
                                <div className="p-15"></div>
                                <div className="comman_action_icon">
                                    <a href="#/" className="action_icon with_bg h50w50 rounded-circle">
                                        <ChatCenteredDotsIcon className="HW32 "/>
                                    </a>
                                </div>
                                <div className="p-15"></div>
                                <div className="d-flex align-items-center justify-content-center">
                                    {myCallRole == "receiver" &&  myCallStatus == "initCall" ?
                                    <>
                                        <a className="btn btn-primary w_100px rounded-5" onClick={() => { acceptTheCall(); }}>
                                            <PhoneIcon className="HW32 black-l-white"/>
                                        </a>
                                        <div className="p-15 py-0"></div>
                                    </>
                                    : <></> }
                                    {myCallStatus == "initCall" ? 
                                    <a className="btn btn-warning w_100px rounded-5" onClick={() => { 
                                        if (callStatus && callStatus.role && callStatus.role == 4) {
                                            endVapiCall()
                                        } else {
                                            disconnectCall()
                                        }
                                     }}>
                                        <PhoneSlashIcon className="HW32 black-l-white"/>
                                    </a>
                                    : <></> }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    </>
  );
};

export default FeedChatCallModal;

