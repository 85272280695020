import React from 'react';


function PhoneDisconnectIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M230.19,91.55h0c-55.39-55.4-149-55.4-204.38,0C6.56,110.82,4.7,139.22,21.29,160.61A14,14,0,0,0,37.39,165l49-17.38.22-.09a13.93,13.93,0,0,0,8.53-10.25l5.9-29.52a1.93,1.93,0,0,1,1.21-1.47,78.53,78.53,0,0,1,51.18-.11,1.93,1.93,0,0,1,1.22,1.45l6.21,29.75a14,14,0,0,0,8.5,10.14l.23.08,49,17.4a14,14,0,0,0,16.1-4.42C251.3,139.22,249.44,110.82,230.19,91.55Zm-5,61.71a2,2,0,0,1-2.29.58l-.22-.09-49-17.38A2,2,0,0,1,172.6,135l-6.22-29.74a14,14,0,0,0-9.06-10.35,90.53,90.53,0,0,0-59,.13,14,14,0,0,0-9,10.45L83.37,135a2,2,0,0,1-1.15,1.44L33.28,153.75l-.22.09a2,2,0,0,1-2.29-.58c-13-16.74-11.56-38.12,3.53-53.22,25.39-25.4,59.55-38.1,93.7-38.1s68.31,12.7,93.7,38.1C236.79,115.14,238.21,136.52,225.23,153.26ZM222,200a6,6,0,0,1-6,6H40a6,6,0,0,1,0-12H216A6,6,0,0,1,222,200Z"></path></svg>
		</React.Fragment>
	)
}

export default PhoneDisconnectIcon