import React, { useEffect, useState } from "react";
import { _l } from "../../../hooks/utilities";
import OnboardingHeader from "../../SpaceOnBoarding/OnboardingHeader";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBenchmarkList } from "../../../actions/customer";
import { Col, ProgressBar, Row } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import BenchmarkServices from "../../../services/benchmark-services";
import CaretDoubleDownIcon from "../../../assets/icons/CaretDoubleDownIcon";
import CaretRightIcon from "../../../assets/icons/CaretRightIcon";
import ChatCircleIcons from "../../../assets/icons/ChatCircleIcons";
import HashIcon from "../../../assets/icons/HashIcon";
import MagnifyingGlassIcon from "../../../assets/icons/MagnifyingGlassIcon";

const BenchmarkListing = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { benchmarkList } = useSelector((state) => state.customer);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState({
    title: "l_benchmark",
    key: "benchmark",
  });
  const [list, setList] = useState(
    benchmarkList.filter((w) => w.is_schedule == 0)
  );
  const [providers, setProviders] = useState([]);

  const changeSection = (tab) => {
    switch (tab) {
      case "benchmark":
        setList(benchmarkList.filter((w) => w.is_schedule == 0));
        setCurrentPage(1);
        setSelectedTab({
          ...selectedTab,
          title: "l_benchmark",
          key: "benchmark",
        });
        break;
      case "schedule":
        setList(benchmarkList.filter((w) => w.is_schedule == 1));
        setCurrentPage(1);
        setSelectedTab({
          ...selectedTab,
          title: "l_schedule",
          key: "schedule",
        });
        break;
      case "invited_providers":
        break;
      default:
        break;
    }
  };
  const SelectDemoOption = [
    { value: "1", label: "role - 1" },
    { value: "2", label: "role - 2" },
    { value: "3", label: "role - 3" },
  ];

  useEffect(() => {
    
    const id = searchParams.get("space");

    const getInvitedProvides = async() => {
        try {
          const response = await BenchmarkServices.getOperatorsListByProject(id);
          if(response.status && response.data.length)
          {
            const rawData = response.data;
            let formattedData = [];
            rawData.forEach((operator) => {
              const existingEntry = formattedData.find((w) => w.full_name && operator.full_name && w.full_name.trim() === operator.full_name.trim() && w.email.trim() === operator.email.trim());
              if(existingEntry)
              {
                existingEntry.categories.push({categoryId: operator.provider_id, categoryName: operator.name});
              }
              else {
                formattedData.push({...operator, categories: [{categoryId: operator.provider_id, categoryName: operator.name}]});
              }
            });
          }
        }
        catch(e)
        {
          console.error(e);
        }
    };

    if (id) {
      dispatch(getBenchmarkList(id));
      getInvitedProvides();
    } 
  }, []);

  useEffect(() => {
    if (
      !list.length &&
      benchmarkList.length &&
      selectedTab.key === "benchmark"
    ) {
      setList(benchmarkList.filter((w) => w.is_schedule == 0));
    }
  }, [benchmarkList]);

  return (
    <div className={`onboarding-wrapper-main m-auto h-100 d-flex flex-column with-modified-width`}>
      <OnboardingHeader title={"l_providers"} />
      <div
        className={`onboarding-content-scroll-wrapper overflow-hidden d-flex flex-column `}
      >
        <div className="company-profile-page d-flex   h-100">
          <div className="left-content-part  w-280 p-0  h-100  d-flex flex-column comman-contnet-wrapper border-end-0">
            <ul className="h-100 comman-verticle-tab">
              <div className="comman-content-scroll-wrapper comman-contnet-wrapper">
                <div className="comman-content-scroll comman-verticle-tab">
                  <li
                    className="comman-list p-0 "
                    onClick={() => {
                      changeSection("benchmark");
                    }}
                  >
                    <a href="#/" className={`w-100 position-relative tab_link ${selectedTab.key === "benchmark" ? "active" : ""}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="comman-list-left w100minus30">
                          <div className="d-flex align-items-center">
                            <div className="c-list-icon h30w30 d-flex">
                              {" "}
                              <HashIcon
                                className="HW16"
                              />
                            </div>
                            <div className="d-flex align-items-center p-1 fw-semibold text-truncate w100minus30">
                              {_l("l_benchmarks")}
                            </div>
                          </div>
                        </div>
                        <div className="comman-list-right">
                          <CaretRightIcon
                            className="HW14"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    className="comman-list p-0 "
                    onClick={() => {
                      changeSection("schedule");
                    }}
                  >
                    <a href="#/" className={`w-100 position-relative tab_link ${selectedTab.key === "schedule" ? "active" : ""}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="comman-list-left w100minus30">
                          <div className="d-flex align-items-center">
                            <div className="c-list-icon h30w30 d-flex">
                              {" "}
                              <HashIcon
                                className="HW16"
                              />
                            </div>
                            <div className="d-flex align-items-center p-1 fw-semibold text-truncate w100minus30">
                              {_l("l_schedules")}
                            </div>
                          </div>
                        </div>
                        <div className="comman-list-right">
                          <CaretRightIcon
                            className="HW14"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                  <li
                    className="comman-list p-0 "
                    onClick={() => {
                      changeSection("invited_providers");
                    }}
                  >
                    <a href="#/" className={`w-100 position-relative tab_link ${selectedTab.key === "providers" ? "active" : ""}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="comman-list-left w100minus30">
                          <div className="d-flex align-items-center">
                            <div className="c-list-icon h30w30 d-flex">
                              {" "}
                              <HashIcon
                                className="HW16"
                              />
                            </div>
                            <div className="d-flex align-items-center p-1 fw-semibold text-truncate w100minus30">
                              {_l("l_invited_providers")}
                            </div>
                          </div>
                        </div>
                        <div className="comman-list-right">
                          <CaretRightIcon
                            className="HW14"
                          />
                        </div>
                      </div>
                    </a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
          <div className="right-content-part width-calc-300 h-100 overflow-hiiden-auto d-flex flex-column border">
            <div className="tab-contain-wrapper comman_vertical_btn_h ">
              <div className="comman_top_header  p-0">
                <div className="comman-tab-wrapper pb-0 w-100 d-flex align-items-center justify-content-between">
                  <div className="tab-wrapper d-inline-flex align-items-center">
                    <div className="tab-name title-fonts fw-semibold m-0 color-white-80 me-1">
                      {_l(selectedTab.title)}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                  <Select
                      className="custom-select-menu  with-mr-10 max-h95 max-w-250 multi-select-with-scroll min-h-33"
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      isMulti
                      isSearchable={true}
                      options={SelectDemoOption}
                    />
                    <div className="comment-input-box mx-2">
                      <input
                        type="text"
                        className="form-control border-0 h-35"
                        placeholder={`${_l('l_search')}`}
                        data-bs-toggle="tooltip"
                        onChange={(e) => {
                        }}
                      />
                      <a href="#/" className="send-arrow-btn ">
                        <div className=" m-auto">
                          <MagnifyingGlassIcon className="HW14" />
                        </div>
                      </a>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="comman-content-scroll-wrapper p-15">
                {selectedTab.key === "providers" ? (
                  <div >
                    {
                      providers.map((w, index) => {
                        return(
                          <a href="#/"
                            key={index}
                            className={`d-flex align-items-center comman-list with-after-50 py-2 m-0 dealing-list-item radius_3 tab-active`}
                          >
                            <div
                              className={`d-flex align-items-center align-items-center flex-grow-1 w100minus100per`}
                            >
                              <div className="d-flex align-items-center">
                                <div className="h25w25 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                  <div
                                    className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                    style={{ backgroundImage: `url('${w.profile_image}'}")` }}
                                  ></div>
                                </div>
                              </div>
                              <div className="c-font color-white-60 f-14 ps-2 text-truncate title-fonts">
                                {w.company}
                              </div>
                            </div>
                            <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per">
                              kjsad@gai.cimn
                            </div>
                            <div className="c-font f-14 text-truncate flex-grow-1 w100minus100per">
                              <span className="pe-2 color-white-60">
                                {_l("l_total_task")}
                              </span>
                              :<span className="ps-2">{w.total_tasks}</span>
                            </div>
                            <div className="c-font color-white-60 f-14 text-truncate flex-grow-1 w100minus100per progress-slider">
                              <div className="d-flex align-items-center">
                                <ProgressBar now={w.work_score} className="flex-grow-1 me-3" />
                                {w.work_score}%
                              </div>
                            </div>
                          </a>
                        )
                      })
                    }
                  </div>
                ) : (
                  <Row className="w-100 gx-0 gy-3">
                    {list && list.length ? (
                      list
                        .slice(
                          0,
                          currentPage > 1
                            ? (currentPage - 1) * 10 + 3
                            : currentPage * 3
                        )
                        .map((benchmark, key) => {
                          return (
                            <Col
                              xl="12"
                              key={key}
                              className="select-input-cox with-input-style p-10 px-3 w-100 align-items-center d-flex"
                            >
                              <div class="w100minus100per text-truncate ps-3">
                                <div class="c-font f-16 fw-semibold text-truncate title-fonts">{`#${benchmark.id} - ${benchmark.category}`}</div>
                                <div class="c-font color-white-60 f-12 pt-1 text-truncate">
                                  {moment(benchmark.startdate).isValid()
                                    ? moment(benchmark.startdate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : ""}
                                </div>
                              </div>
                              <div className="align-items-center d-flex justify-content-between">
                                <div className="with_separator_10 text-center flex-grow-1 px-3">
                                  <div className="c-font f-18 fw-semibold title-fonts">
                                    {benchmark.max_budget}
                                  </div>
                                  <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                    {_l("l_budget")}
                                  </div>
                                </div>
                                <div className="with_separator_10 text-center flex-grow-1 px-3">
                                  <div className="c-font f-18 fw-semibold title-fonts">
                                    {benchmark.item_count}
                                  </div>
                                  <div className="c-font color-white-60 f-12 pt-1 text-wrap">
                                    {_l("l_total_item")}
                                  </div>
                                </div>
                                {
                                  selectedTab.key === "benchmark"
                                  ?
                                  <div className="with_separator_10 text-center flex-grow-1 px-3">
                                    <div className="c-font f-18 fw-semibold title-fonts">
                                      {benchmark.proposal_count}
                                    </div>
                                    <div className="c-font color-white-60 f-12 pt-1">
                                      {_l("l_proposals")}
                                    </div>
                                  </div>
                                  :
                                  <div className="with_separator_10 text-center flex-grow-1 px-3">
                                    <div className="c-font f-18 fw-semibold title-fonts">
                                      {benchmark.total_task}
                                    </div>
                                    <div className="c-font color-white-60 f-12 pt-1">
                                      {_l("l_total_task")}
                                    </div>
                                  </div>
                                }
                                <div className="with_separator_10 text-center flex-grow-1 px-3 before-dnone comman_action_icon">
                                  <div className="action_icon with_bg h-auto w-auto p-1">
                                    <ChatCircleIcons className="HW24" />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {list.length >= (currentPage - 1) * 10 + 3 &&
                    list.length != (currentPage - 1) * 10 + 3 ? (
                      <Col xl="12">
                        <a href="#/"
                          className="btn btn-white-03 w-100 view-more-btn"
                          onClick={() => {
                            setCurrentPage(currentPage + 1);
                          }}
                        >
                          <div className="d-flex align-item-center justify-content-center">
                            <span className=" title-fonts c-font f-12 ms-2">
                              {_l("l_view_more_btn")} (
                              {currentPage > 1
                                ? list.length - ((currentPage - 1) * 10 + 3)
                                : list.length - 3}
                              )
                            </span>
                            <CaretDoubleDownIcon
                              className="HW12 mx-2"
                            />
                          </div>
                        </a>
                      </Col>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </Row>
                )}
              </div>
              <div className="comman-content-bottom-btn d-flex justify-content-end align-items-center d-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkListing;
