import {
  ADD_LOADER_ITEM,
  REMOVE_LOADER_ITEM,
  SET_RESTRICTION_MESSAGE,
  TOGGLE_FEATURE_RESTRICTION_POP_UP,
} from "../actions/general-actions/general-actions-types";

const initialState = {
  addCount: 0,
  featureRestrictionMessage: "",
  loaderArray: [],
  removeCount: 0,
  showFeatureRestrictonModal: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_LOADER_ITEM:
      return { ...state, loaderArray: state.loaderArray.concat([payload.url]) };

    case REMOVE_LOADER_ITEM:
      return {
        ...state,
        loaderArray: state.loaderArray.filter(x => x.id != payload.url.id),
      };

    case SET_RESTRICTION_MESSAGE:
      return {
        ...state,
        featureRestrictionMessage: payload.message
      };
    
    case TOGGLE_FEATURE_RESTRICTION_POP_UP:
      return {
        ...state,
        showFeatureRestrictonModal: payload.flag
      }

    default:
      return state;
  }
}
