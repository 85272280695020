import React, { useEffect, useState } from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { dayGridDay, dayGridMonth, dayGridWeek, getDefaultTopics, leftPanelFilters, setDaterangeFilter, setDaterangeFilterApplied, setLeftPanelTab, setProject, setSelectedDateView, setSelectedGloablView } from "../actions/customer";
import Tasks from "../components/Tasks";
import { useNavigate } from "react-router-dom";
import { formateDate, showLeftPanelNew } from "../hooks/utilities";
import Customcalendar from "../components/Customcalendar";
const DashboardNew = () => {
  const {
    dashBoradView,
    taskFilters,
  } = useSelector((state) => state.customer);
  const [projectId] = useState(0);
  const [isStatusCleared, setIsStatusCleared] = useState(false);
  const [taskModalClose] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let selectedOffice = localStorage.getItem("selectedOffice")
  useEffect(()=>{
    localStorage.setItem("selectedDate", moment(new Date()).format("YYYY-MM-DD"));
    dispatch(setSelectedDateView("monthView"))
    dispatch(setSelectedGloablView("listView"));
    dispatch(setDaterangeFilterApplied(true));
    dispatch(setDaterangeFilter("CustomRange"));
    dispatch(setProject(0));
    const isInitSpace = localStorage.getItem("space_setup_pending");
    let spaces = localStorage.getItem("spaces");
    spaces = spaces && spaces.length ? JSON.parse(spaces) : [];
    if(isInitSpace > 0 && spaces.length <= 1)
    {
      navigate("/");
      dispatch(setSelectedGloablView("AichatView"));;
    }
    dispatch(setLeftPanelTab("dashboard"));
  },[])
  useEffect(() => {
    if( taskFilters.length == 0){
      dispatch(leftPanelFilters({
        filter_key : "date_range_filter",
        image : "", 
        main_key : "date_range_filter",
        main_sub_key : "", 
        sub_category : [] ,
        title : formateDate(moment(new Date()).startOf('month')) + " - "+ formateDate(moment(new Date()).endOf('month'))
      },"add"))
      localStorage.setItem("fromDate",formateDate(moment(new Date()).startOf('month')));
      localStorage.setItem("toDate", formateDate(moment(new Date()).endOf('month')));
    }
    dispatch(dayGridMonth(true));
    dispatch(dayGridWeek(false));
    dispatch(dayGridDay(false));
  }, [])
  useEffect(() => {
    dispatch(getDefaultTopics(0, "", "", "dashboardnew",taskFilters));
    showLeftPanelNew()
  }, [])
  useEffect(() => {
    if(selectedOffice){
      localStorage.removeItem("selectedOffice")
    }
  }, [selectedOffice])
  return (
    <>
      {
        dashBoradView == "taskListView" 
        ? 
          <Tasks
          projectId={0}
          isStatusCleared={isStatusCleared} 
          taskModalClose={taskModalClose} 
          pageName="dashboardnew" 
          kanbanView={false}
          />
        : dashBoradView == "calendarView" ?
          <Customcalendar
          pageName="calendar"
          setselectedEventDate={()=>{}}
          selectStartDate={localStorage.getItem("fromDate")}
          setDashboardStartDate={()=>{}}
          setDashboardEndDate={()=>{}}
        />
        :  <Dashboard projectId={projectId} pageName="dashboardnew"/>
        }
    </>
  );
};
export default DashboardNew;
