import React from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
const CustomPhoneInput = ({value, onChange}) => {
  return (
    <div>
      <div className="col-lg-2"></div>
      <div className="col-lg-7 m-auto">
        <Form.Group className="c-input-box position-relative text-center ">
          <div className="inputWrapper d-flex justify-content-center">
            <div className="p-15 pb-0 pe-0 position-relative pt-0">
              <PhoneInput
                localization={""}
                country="fr"
                disableCountryCode
                preferredCountries={["fr", "de"]}
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        </Form.Group>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};
export default CustomPhoneInput;
