import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  _l,
  getClientIdByProject,
  getFileType,
  showError,
} from "../../../../hooks/utilities";
import CommentMinified from "../../../Comments/CommentMinified";
import DocumentsServices from "../../../../services/documents-services";
import { useDispatch, useSelector } from "react-redux";
import {
  removeDocumentComment,
  setComments,
} from "../../../../actions/documents-actions/documents-actions";
import {
  editBenchmarkDetail,
  getBenchmarkDetail,
  setCategoryNotificationCounts,
  setSpaceNotificationCount,
} from "../../../../actions/customer";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../../actions/messages";
import BenchmarkServices from "../../../../services/benchmark-services";
import SignatureConfirmationIdentity from "../../../Modals/SignatureConfirmationIdentity";
import EstimatereviewModal from "../../../Modals/EstimatereviewModal";
 
import { useSearchParams } from "react-router-dom";
import DocumentModal from "../../../Modals/DocumentModal";
import StaffIbanSetup from "../../../Modals/StaffIbanSetup";
import AlertModal from "../../../Modals/AlertModal";
import CustomerServices from "../../../../services/customer-services";
import DocumentPreview from "../../../Documents/DocumentPreview";
import AddWalletProposalModal from "../../../Modals/AddWalletProposalModal";
import ChatCircleIcons from "../../../../assets/icons/ChatCircleIcons";
import CheckIcon from "../../../../assets/icons/CheckIcon";
import ClockIcon from "../../../../assets/icons/ClockIcon";
import CloudArrowDownIcon from "../../../../assets/icons/CloudArrowDownIcon";
import FileTextIcon from "../../../../assets/icons/FileTextIcon";
import DownloadSimpleIcon from "../../../../assets/icons/DownloadSimpleIcon";
import ShieldCheckIcon from "../../../../assets/icons/ShieldCheckIcon";
import XIcon from "../../../../assets/icons/XIcon";
import ProposalCustomeFiledModal from "../../../Modals/ProposalCustomeFiledModal";
import InfoIcon from "../../../../assets/icons/InfoIcon";

let showComments = false;

const ProposalCard = ({
  proposal,
  benchmark,
  increaseCount,
  setIncreaseCount,
  setStep,
  getComments,
}) => {
  const [unreadComments, setUnreadComments] = useState(
    proposal.unread_comment_count
  );
  const [totalComments, setTotalComments] = useState(proposal.total_comments);
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [mentionList, setMentionList] = useState([]);
  const [showSignaturePad, setShowSignaturePad] = useState({
    show: false,
    proposal: {},
  });
  const [showSignaturePadWithIban, setShowSignaturePadWithIban] = useState({
    show: false,
    proposal: {},
  });
  const [showEstimatereviewModal, setShowEstimatereviewModal] = useState(false);
  const [documentData, setDocumentData] = useState({
    id: 0,
    hash: "",
    type: "",
  });
  //Array containing the ID of proposals, if one proposal has been accepted, all the proposals will be pushed to the array in order to prevent the action from other proposal. For rejecting the proposal, idof that proposal has been pushed to the array so that no further action can be done on that proposal
  const [hideProposalActions, setHideProposalActions] = useState([]);
  const [showIBANField, setShowIBANField] = useState(false);
  const [showIBANSetupForm, setShowIBANSetupForm] = useState(false);
  const [externalUserBlockMessage, setExternalUserBlockMessage] = useState("");
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxData, setlightBoxData] = useState([]);
  const [estimateDownloadLink, setEstimateDownloadLink] = useState("")
  const dispatch = useDispatch();
  const { benchmarkDetail, IBANAccounts } = useSelector((state) => state.customer);
  const [searchParams] = useSearchParams();
  const [customFieldsProposal, setCustomFieldsProposal] = useState({modal : false, data : []});

  const selectedOffice = localStorage.getItem("selectedOffice");
  let rel_id = proposal.proposal_id;
  let rel_type = "proposal";
  const type = searchParams.get("provider-type");
  const contact_id = localStorage.getItem("contact_id") || 0;
  const staff_id = localStorage.getItem("staff_id") || 0;
  const contact_image = localStorage.getItem("contact_image");
  const full_name = localStorage.getItem("full_name");
  const userType = localStorage.getItem("user_type");

  const statusClassArr = {
    1: "badge-warning",
    2: "badge-red",
    3: "badge-done",
    4: "badge-in-progress",
    2: "badge-red",
  };

  const fetchProposalComments = async () => {
    showComments = true;
    setLoadingComments(true);
    try {
      const response = await DocumentsServices.getComments(
        rel_id,
        rel_type,
        0,
        "",
        1,
        benchmark.id
      );
      if (response.status) {
        const mentionedUsers = await DocumentsServices.getMentionlist(
          rel_id,
          rel_type
        );
        if (mentionedUsers.data) {
          setMentionList(mentionedUsers.data);
        }
        let sortedComments = response.data.slice();
        sortedComments.sort((a, b) => Number(a.id) - Number(b.id));
        dispatch(setComments(sortedComments, rel_id, rel_type));
        setCommentList(
          sortedComments.map((comment) => {
            return {
              ...comment,
              profile_url: comment.addedfrom_details.profile_url,
              full_name: comment.addedfrom_details.full_name,
            };
          })
        );
        if (unreadComments > 0) {
          setUnreadComments(0);
          dispatch(
            setSpaceNotificationCount(
              selectedOffice,
              Number(unreadComments),
              -1
            )
          );
          dispatch(
            setCategoryNotificationCounts(
              "providers",
              benchmark.provider_id,
              "benchmark_count",
              Number(unreadComments),
              -1
            )
          );
        }
      }
    } catch (e) {
      showError("l_something_went_wrong");
      console.error(e);
    } finally {
      setLoadingComments(false);
    }
  };

  const addComment = async (comment, files, id = 0) => {
    try {
      if ((comment && comment.trim().length) || files) {
        const response = await DocumentsServices.addDocumentComment(
          rel_type,
          rel_id,
          comment,
          undefined,
          id,
          files,
          benchmark.id
        );
        if (id > 0) {
          const commentIndex = commentList.findIndex((w) => w.id == id);
          setCommentList(
            Object.values({
              ...commentList,
              [commentIndex]: {
                ...commentList[commentIndex],
                content: comment,
              },
            })
          );
        } else {
          if (response.data.comment_id) {
            let attachments = [];

            if (files) {
              Array.from(files).map((file) => {
                attachments.push({
                  file_url: URL.createObjectURL(file),
                  file_name: file.name,
                });
              });
            }
            setTotalComments(totalComments + 1);
            setCommentList(
              commentList.concat([
                {
                  content: comment,
                  full_name,
                  contact_id,
                  staffid: staff_id,
                  profile_url: contact_image,
                  id: response.data.comment_id,
                  attachments,
                },
              ])
            );
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteComment = (comment) => {
    dispatch(
      removeDocumentComment(rel_id, rel_type, comment.id, 0, benchmark.id)
    );
    setCommentList(commentList.filter((x) => x.id == comment.id));
    setTotalComments(totalComments - 1);
  };

  const handleAcceptProposal = async (proposal) => {
    if (userType === "staff") {
      if (benchmarkDetail.is_myr_iban_set != 1) {
        setShowIBANSetupForm(true);
      } else if (
        benchmarkDetail.is_myr_iban_set == 1 &&
        benchmarkDetail.is_added_by_myr
      ) {
        setShowSignaturePad({
          show: true,
          proposal: {
            proposal_id: proposal.proposal_id,
            id: benchmark.id,
            is_iban_set: proposal.is_iban_set,
            company_name: benchmarkDetail.company_name,
            address: benchmarkDetail.address,
            estimate_id: proposal.estimate_id,
          },
        });
      }
    } else {
      try {
        const proposalTotalAmount =
          await BenchmarkServices.getEstimateUsingProposal(
            proposal.proposal_id
          );
          if (IBANAccounts.length == 0 && proposal.estimate_id) {
            setShowSignaturePadWithIban({
              show: true,
              proposal: {
                proposal_id: proposal.proposal_id,
                id: benchmark.id,
                is_iban_set: proposal.is_iban_set,
                company_name: benchmarkDetail.company_name,
                address: benchmarkDetail.address,
                proposal_total: proposalTotalAmount.data.total,
                is_custom_invoice:
                  proposal.proposal_custom_invoice.length > 0,
                estimate_id: proposal.estimate_id,
                proposal_id : proposal.proposal_id
              },
            });
            
          } else {
          setShowSignaturePad({
          show: true,
          proposal: {
            proposal_id: proposal.proposal_id,
            id: benchmark.id,
            is_iban_set: proposal.is_iban_set,
            company_name: benchmarkDetail.company_name,
            address: benchmarkDetail.address,
            proposal_total: proposalTotalAmount.data.total,
            is_custom_invoice:
              proposal.proposal_custom_invoice.length > 0,
            estimate_id: proposal.estimate_id,
          },
        });
      }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleRejectProposal = (proposal) => {
    dispatch(
      showConfirmation(
        _l("l_confirm"),
        _l("l_are_you_want_to_reject"),
        _l("l_yes"),
        _l("no"),
        () => {
          dispatch(toastCloseAction());
        },
        () => {
          BenchmarkServices.rejectProposal(
            proposal.proposal_id,
            benchmark.id,
            benchmarkDetail.is_added_by_myr
          ).then((res) => {
            dispatch(toastCloseAction());
            if (res.status) {
              if (benchmarkDetail) {
                let proposalBenchmark = benchmarkDetail;
                for (
                  let i = 0;
                  i < proposalBenchmark.proposal_list.length;
                  i++
                ) {
                  if (
                    benchmark.date == proposalBenchmark.proposal_list[i].date
                  ) {
                    for (
                      let j = 0;
                      j < proposalBenchmark.proposal_list[i].proposals.length;
                      j++
                    ) {
                      if (
                        proposal.proposal_id ==
                        proposalBenchmark.proposal_list[i].proposals[j]
                          .proposal_id
                      ) {
                        proposalBenchmark.proposal_list[i].proposals[
                          j
                        ].status = 2;
                      }
                    }
                  }
                }
                dispatch(editBenchmarkDetail(proposalBenchmark));
              }
              setHideProposalActions(
                hideProposalActions.concat(proposal.proposal_id)
              );
              dispatch(
                showMessage(
                  "sucess",
                  _l("l_success"),
                  _l("l_proposal_rejected")
                )
              );
            } else {
              dispatch(
                showMessage(
                  "unsucess",
                  _l("l_error"),
                  _l("l_something_went_wrong")
                )
              );
            }
          });
        }
      )
    );
  };

  useEffect(() => {
    if (increaseCount == 1) {
      setTotalComments(totalComments + 1);
      setIncreaseCount(0);
    }
  }, [increaseCount]);

  useEffect(() => {
    if (
      benchmarkDetail &&
      benchmarkDetail.proposal_list &&
      benchmarkDetail.proposal_list.length &&
      benchmarkDetail.proposal_list[0]
    ) {
      if (type === "external-benchmark" && proposal && proposal.status == 3) {
        const isTaskGenerationCompleted = async (scheduleId) => {
          const clientId = getClientIdByProject();
          try {
            const response = await CustomerServices.getBenchmarkDetail(
              scheduleId,
              clientId
            );
            if (response.status && response.data.is_assigneed <= 0) {
              setExternalUserBlockMessage(
                "l_wating_for_operator_to_complete_assignment"
              );
            }
          } catch (e) {
            console.error(e);
          }
        };

        if (
          proposal.is_iban_set == 0 ||
          isNaN(benchmarkDetail.generated_schedule_id) ||
          Number(benchmarkDetail.generated_schedule_id) <= 0
        ) {
          setExternalUserBlockMessage(
            "l_wating_for_operator_to_complete_assignment"
          );
        } else if (Number(benchmarkDetail.generated_schedule_id) > 0) {
          isTaskGenerationCompleted(benchmarkDetail.generated_schedule_id);
        }
      }
    }
    let spaces = localStorage.getItem("spaces");
    if (spaces) {
      spaces = JSON.parse(spaces) || [];
      let targetSpace = spaces.find((w) => w.project_id == selectedOffice);
      if (
        (targetSpace && targetSpace.is_iban_set) ||
        type === "external-benchmark"
      ) {
        setShowIBANField(true);
      }
    }
    BenchmarkServices.getEstimateUsingProposal(
      proposal.proposal_id
    ).then((res)=>{
      if (res && res.status) {
        setEstimateDownloadLink(res.data.pdf_url)
      } else {
        
      }
    })
  }, []);

  const isIBANFieldVisibleInSignatureModal = () => {
    let flag = true;
    if(proposal.proposal_custom_invoice.length)
    {
      flag = false;
    }
    else if(userType === "staff" && benchmarkDetail.is_added_by_myr == 1 && benchmarkDetail.is_myr_iban_set == 1)
    {
      flag = false;
    }
    return flag;
  };
  
  return (
    <React.Fragment>
      <Col xl={12}>
        <Row className="m-0 g-0 mb-1">
          <Col className="pe-0" xl={8}>
            <div className="task-list-card list-view p-10 radius_3 d-flex align-items-center justify-content-between no-hover bgspacelisting border-0">
              <div className="d-flex align-items-center w100minus100per">
                <div className="task-creator-img me-3">
                  <div className="comman-image-box h25w25 rounded-circle">
                    <div
                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                      style={{
                        backgroundImage: `url("${proposal.user_image}")`,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="fw-semibold title-fonts text-truncate">
                  {proposal.company_name || proposal.email}
                </div>
                {
                  proposal.is_verified > 0
                  ?
                  <a href="#/" className="h25w25">
                    <ShieldCheckIcon
                      className="HW18 mx-2 color-green"
                    />
                  </a>
                  :
                  <React.Fragment></React.Fragment>
                }
              </div>
              <div className="d-flex align-items-center ">
                <div className="badge badge-done rounded-pill h32wauto c-font f-12 d-flex align-items-cente">
                  <span className="my-auto">{proposal.compli_tasks}</span>
                </div>
                <div className=" with_separator_10">
                  <div className={`badge ${statusClassArr[proposal.status]} rounded-pill h32wauto c-font f-12 d-flex align-items-cente`}>
                    <span className={`my-auto `}>
                      {proposal.status == 2
                        ? _l("l_rejected")
                        : proposal.status == 3
                        ? _l("l_accepted")
                        : _l("l_pending")}
                    </span>
                  </div>
                </div>
                <div className="with_separator_10 before-dnone width150">
                  <div className="c-font f-13 d-flex align-items-center py-1">
                    <div className="progress-slider w-100 py-2">
                      <div className="d-flex align-items-center">
                        <div className="progress lightthemebgwhite">
                          <div
                            className="progress-bar"
                            style={{ width: `${proposal.compli_task_per}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between ms-3 title-fonts">
                      <div>{proposal.compli_task_per}%</div>
                    </div>
                  </div>
                </div>
                <div
                  className={`dropdown-center dropdown d-flex align-items-center card-view-less-padding close-toast`}
                >
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle
                      data-toggle="dropdown"
                      tag="span"
                      className="comman_action_icon w-100 d-flex close-toast border-0"
                    >
                      <a>
                        <div className="d-flex align-items-center w-100">
                          <div
                            className="action_icon with_bg h32w32 position-relative on-hover-active-toggle-img light-theme-img"
                            onClick={fetchProposalComments}
                          >
                            <ChatCircleIcons
                              className="HW18"
                            />
                            {unreadComments > 0 ? (
                              <span
                                className={`rounded-pill badge-custom p-0 `}
                              >
                                {unreadComments}
                              </span>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                          <span className="c-font color-white-60 d-flex f-12">
                            {totalComments}
                          </span>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      <CommentMinified
                        task={{
                          ...proposal,
                          docType: "proposal",
                          project_id: benchmark.project_id,
                          id: proposal.proposal_id,
                        }}
                        comments={commentList}
                        deleteComment={deleteComment}
                        disabled={proposal.status == 2}
                        handleAddComment={addComment}
                        id={proposal.id}
                        loading={loadingComments}
                        mentionList={mentionList.map((name) => {
                          let id =
                            name.is_team && name.is_team == 1
                              ? "-" + name.id
                              : name.id;
                          return {
                            display: name.name,
                            id: id,
                            image: name.profile_image || "",
                            isteam: name.is_team ? 1 : 0,
                          };
                        })}
                        unContolList={true}
                        showComments={showComments}
                      />
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
          </Col>
          <Col className=" ps-1" xl={4}>
            <div className="d-flex align-items-center justify-content-center h-100 task-list-card list-view p-10 mb-0 radius_3 no-hover comman_action_icon border-0 bgspacelisting light-theme-border-1 lightthemebg">
              <div className="c-font f-15 fw-semibold title-fonts with_separator_10">
                €{proposal.amount}
              </div>
              {proposal.status == 4 &&
              proposal.is_updated == "1" ? (
                <React.Fragment>
                  <div className="h32wauto action_icon with_bg ps-10px pe-10px d-flex align-items-center w100minus100per text-truncate px-1 ms-10px"
                  
                   >
                  <div className="text-truncate"><span className="c-font color-white-60 f-15 my-2 text-truncate title-fonts">{_l("l_waiting_for_new_proposal")}</span></div>
                  </div>
                   
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {proposal.proposal_custom_invoice.length ? (
                <a href="#/"
                  className="comman_action_icon with_separator_10"
                  
                   
                   
                   
                  onClick={() => {
                    if (proposal.proposal_custom_invoice.length) {
                      setOpenLightBox(true);
                      setlightBoxData([{src: proposal.preview_proposal_custom_invoice, type: getFileType(proposal.proposal_custom_invoice), download_url: proposal.proposal_custom_invoice}]);
                    }
                  }}
                >
                  <div className="action_icon on-hover-active-toggle-img with_bg">
                    {proposal.proposal_custom_invoice.length ? (
                      <CloudArrowDownIcon
                        className="HW18"
                      />
                    ) : (
                      <FileTextIcon className="HW18" />
                    )}
                  </div>
                </a>
              ) : (
                <React.Fragment>
                  {proposal.status == 4 &&
                  Number(proposal.amount) > 0 &&
                  proposal.is_updated == "0" ? (
                    <div>
                      <a href="#/"
                        className="comman_action_icon with_separator_10"
                      
                        
                        onClick={() => {
                          if (proposal.proposal_custom_invoice.length) {
                            setOpenLightBox(true);
                            setlightBoxData([{src: proposal.proposal_custom_invoice, type: getFileType(proposal.proposal_custom_invoice)}]);
                          } else {
                            setShowEstimatereviewModal(true);
                          }
                        }}
                      >
                        <div className="action_icon on-hover-active-toggle-img with_bg">
                          {proposal.proposal_custom_invoice.length ? (
                            <CloudArrowDownIcon
                              className="HW18"
                            />
                          ) : (
                            <FileTextIcon
                              className="HW18"
                            />
                          )}
                        </div>
                      </a>
                      <a href="#/"
                      className="comman_action_icon with_separator_10"
                     
                       
                      onClick={() => {
                          setCustomFieldsProposal({modal : true, data : proposal});
                      }}
                    >
                      <div className="action_icon on-hover-active-toggle-img with_bg">
                          <InfoIcon
                            className="HW18"
                          />
                      </div>
                    </a>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </React.Fragment>
              )}
              {proposal.status == 3 &&
              (proposal.is_iban_set > 0 || benchmarkDetail.is_external == 0) ? (
                <React.Fragment>
                  {benchmarkDetail.subscription_id > 0 ? (
                    <>
                    <Button
                      size="sm"
                      onClick={() =>
                        setDocumentData({
                          id: benchmarkDetail.subscription_id,
                          hash: benchmarkDetail.subscription_hash,
                          type: "subscription",
                        })
                      }
                    >
                      <span>{_l("l_view_subscription")}</span>
                    </Button>
                    </>
                  ) : benchmarkDetail.invoice_id > 0 ? (
                    <Button
                      size="sm"
                      onClick={() =>
                        setDocumentData({
                          id: benchmarkDetail.invoice_id,
                          hash: benchmarkDetail.invoice_hash,
                          type: "invoice",
                          is_app_invoice: 1,
                        })
                      }
                    >
                      <span>{_l("l_invoice")}</span>
                    </Button>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {proposal.status == 4 &&
              proposal.is_updated == "0" &&
              proposal.amount > 0 ? (
                <>
                    <a href="#/"
                      className="comman_action_icon with_separator_10"
                      onClick={() => {
                        window.open(estimateDownloadLink, "_blank")
                      }}>
                      <div className="action_icon on-hover-active-toggle-img with_bg">

                        <DownloadSimpleIcon
                          className="HW18"
                        />
                      </div>
                    </a>
                <div className="comman_action_icon with_separator_10 d-flex before-dnone">
                  <Button
                    className="me-2"
                    variant="outline-danger"
                    size="sm"
                    onClick={() => {
                      handleRejectProposal(proposal);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <XIcon className="HW18 pe-1 stroke-red" />
                      {_l("l_reject")}
                    </div>
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      handleAcceptProposal(proposal);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <CheckIcon
                        className="HW18 black pe-1"
                      />
                      {_l("l_accept")}
                    </div>
                  </Button>
                </div>
                </>
              ) : (
                <React.Fragment></React.Fragment>
              )}
               
            </div>
          </Col>
        </Row>
      </Col>
      {showSignaturePad && showSignaturePad.show ? (
        <SignatureConfirmationIdentity
          show={showSignaturePad.show}
          showIBANField={false}
          projectId={selectedOffice}
          estimate={showSignaturePad && showSignaturePad.proposal}
          handleClose={() => {
            setShowSignaturePad({ show: false, proposal: {} });
          }}
          callBack={() => {
            if (type === "external-benchmark") {
              setStep("chat-screen");
              getComments();
            }
          }}
          type="Proposal"
          benchmark={benchmark}
          setReadOnlyMode={() => {}}
          isExternalClient={showIBANField}
          showIbanModal={true}
          showIbanSelection={true}
          ibanID={IBANAccounts && IBANAccounts.length > 0 ? IBANAccounts[0].id : 0 }
          estimateId={proposal.estimate_id}
        />
      ) : (
        <></>
      )}
      {showSignaturePadWithIban && showSignaturePadWithIban.show ? (
        <AddWalletProposalModal
          show={showSignaturePadWithIban.show}
          showIBANField={isIBANFieldVisibleInSignatureModal()}
          projectId={selectedOffice}
          estimate={showSignaturePadWithIban && showSignaturePadWithIban.proposal}
          handleClose={() => {
            setShowSignaturePadWithIban({ show: false, proposal: {} });
          }}
          callBack={() => {
            if (type === "external-benchmark") {
              setStep("chat-screen");
              getComments();
            }
          }}
          type="Proposal"
          benchmark={benchmark}
          setReadOnlyMode={() => {}}
          isExternalClient={showIBANField}
          showIbanModal={true}
        />
      ) : (
        <></>
      )}
      {showEstimatereviewModal ? (
        <EstimatereviewModal
          show={showEstimatereviewModal}
          handleClose={() => {
            setShowEstimatereviewModal(false);
          }}
          proposalId={proposal.proposal_id}
        />
      ) : (
        <></>
      )}
      {documentData.id > 0 ? (
        <DocumentModal
          show={documentData.id > 0}
          handleClose={() => {
            setDocumentData({ id: 0, hash: "", type: "" });
          }}
          documentData={documentData}
          type={documentData.type}
          isExternalWithNoLeftPanel={
            type === "external-benchmark" ? true : false
          }
        />
      ) : (
        <></>
      )}
      {showIBANSetupForm ? (
        <StaffIbanSetup
          handleClose={(flag) => {
            if (flag) {
              dispatch(getBenchmarkDetail(benchmarkDetail.id));
            } else {
              setShowSignaturePad({
                show: false,
                proposal: {},
              });
            }
            setShowIBANSetupForm(false);
          }}
          show={showIBANSetupForm}
        />
      ) : (
        <></>
      )}
      {externalUserBlockMessage.length ? (
        <AlertModal
          handleClose={() => {}}
          message={externalUserBlockMessage}
          buttonText={""}
          icon={{ name: ClockIcon }}
          secondaryButtonText={""}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {openLightBox && lightBoxData ? (
        <DocumentPreview
          open={openLightBox}
          setOpen={setOpenLightBox}
          slides={lightBoxData}
        />
      ) : (
        <></>
      )}
      {customFieldsProposal.modal ? (
        <ProposalCustomeFiledModal
          show={customFieldsProposal.modal}
          handleClose={() => {
            setCustomFieldsProposal({modal: false});
          }}
          data={customFieldsProposal.data}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default ProposalCard;
