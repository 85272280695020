import React from "react";
import { _l } from "../../hooks/utilities";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import HashIcon from "../../assets/icons/HashIcon";
const GeneralTwoLevelDropdown = ({ menuOptions, onSelectOption }) => {
  return (
    <ul class="dropdown-menu w-200">
      {menuOptions.length > 0 &&
        menuOptions.map((item, outerIndex) => {
          return (
            <li key={outerIndex} className="hr_sepretr dropend " onClick={item.subMenuOptions.length ? () => {} : () => onSelectOption({outerData: item, innerData: {}})}>
              <a href="#/"
                className={`dropdown-item ${
                  item.is_selected ? "active with-tick" : ""
                }`}
                type="button"
                id="GeneralListing"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <div className="d-flex align-items-center text-truncate">
                  <div className="c-list-icon">
                    <div className="h25w25 rounded-circle">
                      <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle d-flex">
                        <HashIcon className="HW16" />
                      </div>
                    </div>
                  </div>
                  <div className="ps-2 w100minus30 text-truncate">
                    <div className="c-list-detail c-font f-14 text-truncate">
                      {_l(item.label)}
                    </div>
                  </div>
                  {item.numberOfSelectedSubMenuItems > 0 || item.subMenuOptions.filter((d) => d.is_selected == true).length > 0 ? (
                    <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                      <span className="m-auto p-0">
                        {item.numberOfSelectedSubMenuItems || item.subMenuOptions.filter((d) => d.is_selected == true).length}
                      </span>
                    </span>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {
                    item.subMenuOptions.length > 0 
                    ?
                    <span className="c-list-icon">
                        <CaretRightIcon className="HW14" />
                    </span>
                    :
                    <React.Fragment></React.Fragment>
                  }
                </div>
              </a>
              {item.subMenuOptions.length > 0 ? 
              <ul class="dropdown-menu w-200 overflowscroll">
                {item.subMenuOptions.length > 0 &&
                  item.subMenuOptions.map((subMenuOption, innerIndex) => {
                    return (
                      <li key={innerIndex} className="hr_sepretr dropstart" onClick={() => onSelectOption({outerData: item, innerData: subMenuOption})}>
                        <a href="#/"
                          class={`dropdown-item ${
                            subMenuOption.is_selected ? "active with-tick" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center text-truncate">
                            <div className="c-list-icon">
                              {subMenuOption.showHashIcon ? (
                                 <div className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle">
                                  <HashIcon className="HW16" />
                                </div>
                              ) :  (
                                <div className="comman-image-box h25w25 rounded-circle">
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                  style={{
                                    backgroundImage: `url('${subMenuOption.profile_image}')`,
                                  }}
                                ></div>
                              </div>
                              )}
                            </div>
                            <div className="ps-2 w100minus40 text-truncate">
                              <div className="c-list-detail c-font f-14 text-truncate">
                                {_l(subMenuOption.label)}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
              :
              <></>}
            </li>
          );
        })}
    </ul>
  );
};

export default GeneralTwoLevelDropdown;
