import React, { useEffect, useState } from "react";
import { _l, showError, showSuccess } from "../../hooks/utilities";
import { Button, Spinner, Form } from "react-bootstrap";
import vapiService from "../../services/vapi-service";

const AssistantPrompt = () => {
  const [loader, setLoader] = useState(false);
  const [englishPrompt, setEnglishPrompt] = useState("")
  const [frenchPrompt, setFrenchPrompt] = useState("")

  const handleSave = async() => {
    try {
        setLoader(true);
        let res = await vapiService.setAssistantPrompt(englishPrompt, frenchPrompt)
        if (res && res.status) {
            showSuccess("l_data_added")
        }else{
            showError("l_something_went_wrong")
        }
        setLoader(false);
        
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    vapiService.getAssistantPrompt().then((res)=>{
        if (res && res.status) {
            let eng = res.data.filter(l=> l.language_name == "english")
            let fr = res.data.filter(l=> l.language_name == "french")
            setEnglishPrompt(eng && eng[0] && eng[0].prompt ? eng[0].prompt : "" )
            setFrenchPrompt(fr && fr[0] && fr[0].prompt ? fr[0].prompt : "" )
        }
    })
  }, [])
  

  return (
    <>
      <div className="p-20 px-0 ">
        <div className="d-flex justify-content-between pe10per">
          <div class="c-font fw-semibold title-fonts f-14 pb-15px">
            {_l("l_ai_prompt")}
          </div>
          <div class="c-font fw-semibold title-fonts f-14 pb-15px">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                if (!loader) {
                    handleSave()
                }
              }}
              disabled={loader}
            >
              {loader ? _l("l_please_wait") : _l("l_save")}
              {loader ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </div>
        </div>
        <div className="pe10per h-100 pb-20pximp">
          <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_general_context_en")}
              </Form.Label>
            </div>
            <Form.Control
            className="bg-white-03-solid"
              as="textarea"
              rows={15}
              placeholder={`${_l("l_general_context_placeholder")}`}
              type="text"
              name="general_context"
              value={englishPrompt}
              onChange={(e) => {
                setEnglishPrompt(e.target.value)
              }}
              onBlur={() => {}}
            ></Form.Control>
          </Form.Group>

          <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <Form.Label className="input-label no-asterisk ">
                {_l("l_general_context_fr")}
              </Form.Label>
            </div>
            <Form.Control
            className="bg-white-03-solid"
              as="textarea"
              rows={15}
              placeholder={`${_l("l_general_context_placeholder")}`}
              type="text"
              name="general_context"
              value={frenchPrompt}
              onChange={(e) => {
                setFrenchPrompt(e.target.value);
              }}
              onBlur={() => {}}
            ></Form.Control>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default AssistantPrompt;
