import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Autocomplete from "../Dropdowns/Autocomplete";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { _l, showError } from "../../hooks/utilities";
import CheckIcon from "../../assets/icons/CheckIcon";
import HashIcon from "../../assets/icons/HashIcon";
import MagnifyingGlassIcon from "../../assets/icons/MagnifyingGlassIcon";
import PlusIcon from "../../assets/icons/PlusIcon";

const ListingWithCheckbox = ({
  list,
  setList,
  readOnly,
  handleAddNewCategory,
  allowdToAdd = true,
  selectOnlyOne = false,  
  selectedCategory = [],
  setSelectedCategory = () => {}
}) => {
  const [inputValue, setInputValue] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const addNewCategory = () => {
    if (
      !list.find(
        (w) =>
          inputValue.trim().length &&
          w.label.trim().toLowerCase() === inputValue.trim().toLowerCase()
      )
    ) {
      handleAddNewCategory(inputValue.trim());
      setInputValue("");
    } else {
      showError("l_already_exists");
    }
  };

  const CategoryListing = list.map((item, index) => {
    if (
      searchInput &&
      searchInput.trim().length &&
      item.label &&
      !item.label
        .trim()
        .toLowerCase()
        .includes(searchInput.trim().toLowerCase())
    ) {
      return <React.Fragment></React.Fragment>;
    }  

    return (
      <Col xs={4} key={index}>
        <div className="select-input-cox p-20 w-100">
          <input
            className="bottom-0 end-0 form-check-input h-100 position-absolute start-0 top-0 w-100 bg-transparent"
            name="provider-categories"
            type={"checkbox"}
            checked={selectOnlyOne && selectedCategory.length > 0 ? item.value == selectedCategory[0].value : item.checked}
            disabled={readOnly}
            onChange={(e) => {
              if (selectOnlyOne == true) {
                setSelectedCategory([{...item, checked: e.target.checked}])
                return
              } else {                
                setList(
                  Object.values({
                    ...list,
                    [index]: { ...list[index], checked: e.target.checked },
                  })
                );
              }
            }}
          />
          <a href="#/" className="check-image">
            <div className="check-image-box">
              <CheckIcon className="HW14 color-white-03-solid" />
            </div>
          </a>
          <div className="align-items-center d-flex">
            <div className="h25w25">
              <HashIcon className="HW22" />
            </div>
            <div className="c-font f-14 text-truncate  ps-2">{item.label}</div>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <React.Fragment>
      <div className="row my-0 g-4">
        {readOnly ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className="bgOfficelisting d-flex justify-content-end lightthemebgwhite m-0 position-sticky top-0 z-index-2">
            <div className="d-flex align-items-center justify-content-between w-100 ">
              <div class="row align-items-center justify-content-between flex-grow-1">
                <div className="col-lg-8" key={-1}>
                  <div className="align-items-center comment-input-box d-flex form-control">
                    <div className="align-items-center d-flex flex-grow-1 position-relative">
                      <div className="pe-2">
                        <MagnifyingGlassIcon
                          className="HW18"
                        />
                      </div>
                      <input
                        type="text"
                        placeholder={_l("l_type_to_search")}
                        value={searchInput}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                        className=" p-0 bg-transparent border-0   pe-5 shadow-none "
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  {readOnly ? (
                    <React.Fragment></React.Fragment>
                  ) : (
                    <div key={-1}>
                      <div
                        className={`select-input-cox p-20 w-100 h53px ${
                          allowdToAdd ? "" : "for-disabled"
                        }`}
                      >
                        <div className="align-items-center d-flex">
                          <Autocomplete
                            placeholder={_l("l_add_category")}
                            inputClass="form-control bottom-0 end-0 h-100 position-absolute start-0 top-0 w-100 c-font f-14 text-truncate pe-5 bg-transparent"
                            dropdownClass="max-height-20vh start-0 mt-3"
                            value={inputValue}
                            onChange={(e) => {
                              setInputValue(e.target.value);
                            }}
                            onSelect={(value) => {
                              setInputValue(value.label);
                            }}
                            options={[]}
                          />
                          <div className="comman_action_icon AddButtonInput">
                            <a
                              href="#/"
                              className={`action_icon with_bg h32w32 `}
                              onClick={() => {
                                if(inputValue.length)
                                {
                                  addNewCategory()
                                }
                              }}
                            >
                              <PlusIcon
                                className="HW22"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <React.Fragment>
          {list.length ? (
            CategoryListing
          ) : (
            <CommanPlaceholder
              imgType="no-benchmark"
              mainSpacing="h-100 mt-auto"
              placeholderText={`${_l("l_no")} ${_l("l_category")}`}
            />
          )}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default ListingWithCheckbox;
