import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l , getDatePickerLocale } from "../../../../hooks/utilities";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  addEditLeadNote,
  deleteLeadNote,
} from "../../../../actions/leads-actions/leads-actions";
import { showConfirmation, showMessage } from "../../../../actions/messages";
import moment from "moment";
 
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
import CalendarBlankIcon from "../../../../assets/icons/CalendarBlankIcon";
import PencilSimpleLineIcon from "../../../../assets/icons/PencilSimpleLineIcon";
import TrashIcon from "../../../../assets/icons/TrashIcon";
const LeadNotesAndReminders = ({ lead_id }) => {
  const noteFormat = {
    description: "",
    date_contacted: null,
    contact: null,
    note_id: 0,
    lead_id,
    date_reminder: null,
    lead_contact: { value: 0, label: "" },
    get_in_touch: "",
  };

  const [leadContactList, setLeadContactList] = useState([
    { label: _l("l_company_profile"), value: -1 },
  ]);
  const [note, setNote] = useState(noteFormat);
  const [noteList, setNoteList] = useState([]);
  const [editNote, setEditNote] = useState({ note_id: "", description: "" });

  const dispatch = useDispatch();
  const { leadDetails } = useSelector((state) => state.leadsReducer);

  useEffect(() => {
    if (leadDetails.lead_contact && leadDetails.lead_contact.length) {
      const defaultContact = [{ label: _l("l_company_profile"), value: -1 }];
      setLeadContactList(
        defaultContact.concat(
          leadDetails.lead_contact.map((contact) => {
            return {
              label: contact.full_name,
              value: contact.id,
            };
          })
        )
      );
    }
    if (leadDetails.lead_notes) {
      setNoteList(leadDetails.lead_notes);
    }
  }, [JSON.stringify(leadDetails)]);

  const addLeadNote = () => {
    let validation = true;
    Object.keys(note)
      .reverse()
      .forEach((key) => {
        if (!["note_id"].includes(key) && (!note[key] || note[key] == 0)) {
          validation = false;
          dispatch(
            showMessage("unsucess", _l("l_error"), _l(`l_invalid_${key}`))
          );
        }
      });
    if (validation) {
      dispatch(addEditLeadNote(note));
      setNote(noteFormat);
      dispatch(showMessage("success", _l("l_success"), _l("l_note_added")));
    }
  };

  const editLeadNote = () => {
    if (editNote.description && editNote.description.trim().length) {
      dispatch(addEditLeadNote(editNote));
      dispatch(showMessage("success", _l("l_success"), _l("l_note_edited")));
      setEditNote({ note_id: 0, description: "" });
    } else {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_add_note_description")
        )
      );
    }
  };

  const deleteNote = (note_id) => {
    dispatch(deleteLeadNote(lead_id, note_id));
    dispatch(showMessage("success", _l("l_success"), _l("l_note_deleted")));
  };

  return (
    <>
      <div className="comman-content-scroll-wrapper p-10 py-0">
        <div className="comman-content-scroll">
          <div className="form-wrapper-main pt-0">
            <div className="row ">
              <Form.Group className="col-12 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_description")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={`${_l("l_enter_details_placeholder")}`}
                  type="text"
                  onChange={(e) =>
                    setNote({ ...note, description: e.target.value })
                  }
                  value={note.description}
                />
              </Form.Group>
              <Form.Group className="col-4 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                  {_l("l_lead_contact_list")}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                  <Select
                    placeholder={_l("l_select_lead_contact")}
                    className="custom-select-menu w-100"
                    classNamePrefix="react-select"
                    options={leadContactList.sort((a, b) => {
                      return a.value - b.value;
                    })}
                    value={note.contact}
                    onChange={(value) =>
                      setNote({
                        ...note,
                        contact: value,
                        lead_contact: value.value,
                      })
                    }
                    closeMenuOnSelect={true}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_date_contacted")}
                </Form.Label>
                <div
                  className="custom-datepicker w-330px"
                  data-bs-toggle="tooltip"
                  title={new Date(note.date_contacted)}
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                  <CalendarBlankIcon className="HW18 mx-0" />
                    <DatePicker
                      selected={
                        note.date_contacted ? new Date(note.date_contacted) : ""
                      }
                      onChange={(date) =>
                        setNote({ ...note, date_contacted: date })
                      }
                      disabledKeyboardNavigation
                      placeholderText={_l("l_select_start_date")}
                      locale={getDatePickerLocale()}
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      selectsStart
                      showTimeSelect
                      defaultMenuIsOpen
                      calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 c-input-box pb-3 position-relative">
                <Form.Label className="input-label no-asterisk">
                  {_l("l_date_reminder ")}
                </Form.Label>
                <div
                  className="custom-datepicker"
                  data-bs-toggle="tooltip"
                  title={new Date(note.date_contacted)}
                >
                  <div className="d-flex align-items-center form-control  color-white-60 dropdown">
                  <CalendarBlankIcon className="HW18 mx-0" />
                    <DatePicker
                      selected={
                        note.date_reminder ? new Date(note.date_reminder) : ""
                      }
                      onChange={(date) =>
                        setNote({ ...note, date_reminder: date })
                      }
                      disabledKeyboardNavigation
                      placeholderText={_l("l_select_reminder_date")}
                      locale={getDatePickerLocale()}
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      selectsStart
                      defaultMenuIsOpen
                      calendarStartDay={1}
                    ><div className="datepicker-label">{_l("l_select_date_and_time")}</div></DatePicker>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-xl-4 form-check pb-3 ps-2 ">
                <Form.Label className="form-check-label mb-0  form-label">
                  <input
                    className="form-check-input ms-0"
                    type="radio"
                    name="lead_get_in_touch"
                    value={"1"}
                    onChange={() => {
                      setNote({ ...note, get_in_touch: "1" });
                    }}
                    checked={note.get_in_touch == "1"}
                  />
                  <span className="ps-2 ">
                    {_l("l_i_get_in_touch_with_this_lead")}
                  </span>
                </Form.Label>
              </Form.Group>
              <Form.Group className="col-xl-5 form-check pb-3 ps-2 ">
                <Form.Label className="form-check-label mb-0  form-label">
                  <input
                    className="form-check-input ms-0"
                    type="radio"
                    name="lead_get_in_touch"
                    value={"-1"}
                    onChange={() => {
                      setNote({ ...note, get_in_touch: "-1" });
                    }}
                    checked={note.get_in_touch == "-1"}
                  />
                  <span className="ps-2">
                    {_l("l_i_have_not_contacted_this_lead")}
                  </span>
                </Form.Label>
              </Form.Group>
              <div className="col-xl-3 text-end">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => addLeadNote()}
                >
                  {_l("l_add_note")}{" "}
                </Button>
              </div>
              <ul className="note-comment-wrapper border-top mt-2 pt-2">
                {noteList && noteList.length ? (
                  noteList.map((noteItem) => {
                    return (
                      <li
                        key={noteItem.id}
                        className="notification-box w-100 p-15 ps-0 with-border"
                      >
                        <div className="d-flex justify-content-between">
                          <div className="left-notification">
                            <div className="d-flex">
                              <div className="user-imge">
                                <div
                                  data-bs-toggle="tooltip"
                                  className="comman-image-box h35w35 rounded-circle"
                                >
                                  <div
                                    className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                    style={{
                                      backgroundImage: `url('${noteItem.profile_url}')`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="notification-detail w100minus40">
                                <div className="c-font f-12 color-white-60 ">
                                  <span>
                                    Note added:{" "}
                                    {moment(noteItem.dateadded).format(
                                      "DD/MM/YY hh:mm a"
                                    )}{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    Date Contacted:{" "}
                                    {moment(noteItem.date_contacted).format(
                                      "DD/MM/YY hh:mm a"
                                    )}{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    Date Reminder :{" "}
                                    {moment(noteItem.date_reminder).format(
                                      "DD/MM/YY hh:mm a"
                                    )}
                                  </span>
                                </div>
                                <div className="text-truncate title-fonts">
                                  {noteItem.full_name}
                                </div>
                                <span className="c-font f-12 color-white-60 d-block text-truncate pt-1">
                                  {noteItem.description}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="comman_action_icon me-2"
                               
                               
                               
                               
                              onClick={() => {
                                setEditNote({
                                  ...editNote,
                                  note_id: noteItem.id,
                                  description: noteItem.description,
                                });
                              }}
                            >
                              <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img d-flex">
                              <PencilSimpleLineIcon className="HW14" />
                              </div>
                            </a>
                            <a href="#/"
                              className="comman_action_icon"
                               
                               
                               
                               
                              onClick={() => {
                                dispatch(
                                  showConfirmation(
                                    _l("l_are_you_sure"),
                                    _l("l_confirm_delete_note"),
                                    _l("l_delete"),
                                    _l("l_cancel"),
                                    () => {},
                                    () => {
                                      deleteNote(noteItem.id);
                                    }
                                  )
                                );
                              }}
                            >
                              <div className="action_icon with_bg on-hover-active-toggle-img light-theme-img d-flex">
                              <TrashIcon className="HW14" />
                              </div>
                            </a>
                          </div>
                           
                        </div>
                        <div className="note-edit-wrapper">
                          {editNote.note_id == noteItem.id ? (
                            <React.Fragment>
                              <Form.Group className="col-12 c-input-box py-2 position-relative">
                                <Form.Control
                                  as="textarea"
                                  placeholder={`${_l(
                                    "l_enter_details_placeholder"
                                  )}`}
                                  type="text"
                                  value={editNote.description}
                                  onChange={(e) => {
                                    setEditNote({
                                      ...editNote,
                                      description: e.target.value,
                                    });
                                  }}
                                  name=""
                                />
                              </Form.Group>
                              <div className="d-flex align-items-center justify-content-end">
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => {
                                    setEditNote({
                                      ...editNote,
                                      note_id: "",
                                      description: "",
                                    });
                                  }}
                                >
                                  {_l("l_close")}
                                </Button>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className=""
                                  onClick={editLeadNote}
                                >
                                  {_l("l_save")}
                                </Button>
                              </div>
                            </React.Fragment>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <React.Fragment>
                  <CommanPlaceholder imgType="no-notes" placeholderText = {_l("l_no_notes")} />
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default LeadNotesAndReminders;
