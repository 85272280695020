import React, { useState } from 'react'
import EmployeeListing from "../../TaskModalComponents/EmployeeListing";
import { _l } from '../../../hooks/utilities';
import InfoIcon from '../../../assets/icons/InfoIcon';
import EqualsIcon from '../../../assets/icons/EqualsIcon';

const EmployeeDayTable = () => {
  const [EmpList, setEmpList] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"]);
  const employeestatusdata = () => {
    return (
      <div className="table-text">
        <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span>
        <a href="#/" className="" type="button" id="CategoryFilterDropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
          <InfoIcon color="#fca21f" className="HW16" />
        </a>
        {/* <EmployeeListing list={[1, 2, 3, 4].map((index) => {
          return {
            name: "person name",
            image: User_9,
            id: index,
          };
        })} /> */}
        <div className="d-flex pt-1">
        <div className="with_separator_10 ps-0">
            <span className="c-dots  c-7 bg-warning rounded-circle me-2 "></span><span className="color-white-80">2</span>
          </div>
          <div className="with_separator_10">
            <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
          </div>
          <div className="with_separator_10">
            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
          </div>
          <div className="with_separator_10 pe-0 ">
            <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="overflow-auto comman_vertical_btn_h">
        <table>
          <tr className="timesheet-head">
            <td className="time-sheet-box name-box">{_l('l_name_and_progress')}</td>
            <td className="time-sheet-box hour-box">{_l('l_total_hours')}</td>
            {EmpList.length > 0 && EmpList.map((item) => {
              return (
                <>
                  <td className="time-sheet-box">{item} Mon</td>
                </>)
            })
            }
          </tr>
          {EmpList.length > 0 && EmpList.map((item) => {
            return (
              <>
                <tr className="">
                  <td className="time-sheet-box name-box">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className=" h-100 d-flex align-items-center justify-content-center office-list-icon">
                        <a href="#/" className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                          <div
                            className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                            style={{ backgroundImage: `url('')` }}
                          ></div>
                        </a>
                      </div>
                      <div className="comman-list-wrapper-main ps-2 w100minus70 left-toggle-none flex-grow-1">
                        <div className="list-left-part">
                          <div className="d-flex flex-column">
                            <a href="#/" className="title-fonts text-truncate">
                              Devon Lane
                            </a>
                            <div className="progress-slider">
                              <div className="d-flex align-items-center">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `60%`,
                                    }}
                                  ></div>
                                </div>
                                <div className="progress-text c-font f-12 title-fonts">
                                  60%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="time-sheet-box hour-box">15 : 00 Hrs</td>
                  {EmpList.length > 0 && EmpList.map((item) => {
                    return (
                      <>
                        <td className="time-sheet-box">
                          {employeestatusdata()}
                        </td>
                      </>)
                  })
                  }
                </tr>
              </>)
          })
          }
          <tr className="timesheet-foot">
            <td className="time-sheet-box name-box">{_l('l_total_count')}</td>
            <td className="time-sheet-box hour-box">60 :00 Hrs</td>
            {EmpList.length > 0 && EmpList.map((item) => {
              return (
                <>
                  <td className="time-sheet-box"> {employeestatusdata()}</td>
                </>)
            })
            }
          </tr>
        </table>
      </div>
      <div className="overflow-auto comman_vertical_btn_h d-none">
        <div className="timesheet-head d-flex">
          <div className="timesheet-head-sticky">
            <div className="d-flex">
              <div className="time-sheet-box name-box ">{_l('l_name_and_progress')}</div>
              <div className="time-sheet-box">{_l('l_total_time')}</div>
            </div>
          </div>
          {EmpList.length > 0 && EmpList.map((item) => {
            return (
              <>
                <div className="time-sheet-box">01 Mon</div>
              </>)
          })
          }
        </div>
        <div className="timesheet-list">
          {EmpList.length > 0 && EmpList.map((item) => {
            return (
              <>
                <div className="d-flex">
                  <div className=" timesheet-left-sticky">
                    <div className="d-flex">
                      <div className="time-sheet-box name-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className=" h-100 d-flex align-items-center justify-content-center office-list-icon">
                            <a href="#/" className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{ backgroundImage: `url('')` }}
                              ></div>
                            </a>
                          </div>
                          <div className="comman-list-wrapper-main ps-2 w100minus70 left-toggle-none flex-grow-1">
                            <div className="list-left-part">
                              <div className="d-flex flex-column">
                                <a href="#/" className="title-fonts text-truncate">
                                  Devon Lane
                                </a>
                                <div className="progress-slider">
                                  <div className="d-flex align-items-center">
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width: `60%`,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="progress-text c-font f-12 title-fonts">
                                      60%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="time-sheet-box">{_l('l_total_time')}</div>
                    </div>
                  </div>
                  {EmpList.length > 0 && EmpList.map((item) => {
                    return (
                      <>
                        <div className="time-sheet-box">01 Mon</div>
                      </>)
                  })
                  }
                </div>
              </>)
          })
          }
        </div>
        <div className="timesheet-foot d-flex">
          <div className="timesheet-bottom-sticky">
            <div className="d-flex">
              <div className="time-sheet-box name-box">{_l('l_total_count')}</div>
              <div className="time-sheet-box">{_l('l_total_time')}</div>
            </div>
          </div>
          {EmpList.length > 0 && EmpList.map((item) => {
            return (
              <>
                <div className="time-sheet-box">01 Mon</div>
              </>)
          })
          }
        </div>
        <div className="d-none">
          <div className="left-sticky-bar">
            <div className="timesheet-bottom-sticky">
              <div className="d-flex">

              </div>
            </div>
            <div className="employee-list-main">
              {EmpList.length > 0 && EmpList.map((item) => {
                return (
                  <>
                    <div className='d-flex'>
                      <div className="time-sheet-box name-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className=" h-100 d-flex align-items-center justify-content-center office-list-icon">
                            <a href="#/" className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{ backgroundImage: `url('')` }}
                              ></div>
                            </a>
                          </div>
                          <div className="comman-list-wrapper-main ps-2 w100minus70 left-toggle-none flex-grow-1">
                            <div className="list-left-part">
                              <div className="d-flex flex-column">
                                <a href="#/" className="title-fonts text-truncate">
                                  Devon Lane
                                </a>
                                <div className="progress-slider">
                                  <div className="d-flex align-items-center">
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width: `60%`,
                                        }}
                                      ></div>
                                    </div>
                                    <div className="progress-text c-font f-12 title-fonts">
                                      60%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="time-sheet-box">15 : 00 Hrs</div>
                    </div>
                  </>)
              })
              }
            </div>
            <div className="employee-foot-main d-flex">
              <div className="left-sticky-bar d-flex">
                <div className="time-sheet-box name-box">{_l('l_total_count')}</div>
                <div className="time-sheet-box">60 :00 Hrs</div>
              </div>
              <div className="right-scroll-bar d-flex">
                {EmpList.length > 0 && EmpList.map((item) => {
                  return (
                    <>
                      <div className="time-sheet-box">01 Mon</div>
                    </>)
                })
                }
              </div>
            </div>
          </div>
          <div className="right-scroll-bar">

            <div className="employee-list-main">
              {EmpList.length > 0 && EmpList.map((item) => {
                return (
                  <>
                    <div className="d-flex">
                      {EmpList.length > 0 && EmpList.map((item) => {
                        return (
                          <>
                            <div className="time-sheet-box">01 Mon</div>
                          </>)
                      })
                      }
                    </div>
                  </>)
              })
              }
            </div>
            <div className="employee-foot-main d-flex">
              <div className="left-sticky-bar d-flex">
                <div className="time-sheet-box name-box">{_l('l_total_count')}</div>
                <div className="time-sheet-box">60 :00 Hrs</div>
              </div>
              <div className="right-scroll-bar d-flex">
                {EmpList.length > 0 && EmpList.map((item) => {
                  return (
                    <>
                      <div className="time-sheet-box">01 Mon</div>
                    </>)
                })
                }
              </div>
            </div>
          </div>
        </div>



      </div>
      <div className="comman_vertical_btn_h d-flex flex-column d-none">
        <div className="comman-content-scroll-wrapper">
          <div className="comman-content-scroll">
            <div className="comman-data-table overflow-auto">
              <table className="dataTable">
                <thead className="">
                  <tr>
                    <th style={{ width: "30%" }} className="sticky-head">Names & Progress</th>
                    <th style={{ width: "15%" }} className="text-center sticky-head">Total Time</th>
                    <th style={{ width: "15%" }} className=""><span>01 Mon</span></th>
                    <th style={{ width: "15%" }} className=""><span>01 Mon</span></th>
                    <th style={{ width: "15%" }} className=""><span>01 Mon</span></th>
                    <th style={{ width: "15%" }} className=""><span>01 Mon</span></th>
                  </tr>
                </thead>
                <tbody>
                  {EmpList.length > 0 && EmpList.map((item) => {
                    return (
                      <tr>
                        <td className="sticky-col">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className=" h-100 d-flex align-items-center justify-content-center office-list-icon">
                              <a href="#/" className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-5px">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{ backgroundImage: `url('')` }}
                                ></div>
                              </a>
                            </div>
                            <div className="comman-list-wrapper-main ps-2 w100minus70 left-toggle-none flex-grow-1">
                              <div className="list-left-part">
                                <div className="d-flex flex-column">
                                  <a href="#/" className="title-fonts text-truncate">
                                    Devon Lane
                                  </a>
                                  <div className="progress-slider">
                                    <div className="d-flex align-items-center">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          style={{
                                            width: `60%`,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="progress-text c-font f-12 title-fonts">
                                        60%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="max-width-100 text-truncate color-white-80 c-font f-15 text-center sticky-col">15 : 00 Hrs</td>
                        <td>
                          <div className=""
                            type="button"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                             
                             
                             
                          >
                            <div className="table-text">
                              <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a><InfoIcon  color="#fca21f" weight="light" className="HW16" /></a>
                              <div className="d-flex pt-1">
                                <div className="with_separator_10 ps-0">
                                  <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                                </div>
                                <div className="with_separator_10">
                                  <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                                </div>
                                <div className="with_separator_10">
                                  <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <EmployeeListing list={[1, 2, 3, 4].map((index) => {
                            return {
                              name: "person name",
                              image: User_9,
                              id: index,
                            };
                          })} /> */}
                        </td>
                        <td>
                          <div className="table-text">
                            <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className="d-none"><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                          </div>
                          <div className="d-flex pt-1">
                            <div className="with_separator_10 ps-0">
                              <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                            </div>
                            <div className="with_separator_10">
                              <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                            </div>
                            <div className="with_separator_10">
                              <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className="d-none"><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                            <div className="d-flex pt-1">
                              <div className="with_separator_10 ps-0">
                                <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                              </div>
                              <div className="with_separator_10">
                                <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                              </div>
                              <div className="with_separator_10">
                                <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="table-text">
                            <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className=""><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                            <div className="d-flex pt-1">
                              <div className="with_separator_10 ps-0">
                                <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                              </div>
                              <div className="with_separator_10">
                                <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                              </div>
                              <div className="with_separator_10">
                                <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
                <tfoot>
                  <tr className="">
                    <td className="tfoot-rounded" style={{ width: "30%" }}>
                      <div className="tfoot-rounded me-3">
                        <div className="d-flex align-items-center">
                          <a href="#/" className="me-2">
                          <EqualsIcon className="HW20" weight="light" />
                          </a>
                          <span className="title-fonts c-font f-14">Total Count</span>
                        </div>
                      </div>
                    </td>
                    <td className="max-width-100 text-truncate color-white-80 c-font f-15 text-center">
                      15 : 00 Hrs
                    </td>
                    <td>
                      <div className="table-text">
                        <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className=""><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                        <div className="d-flex ">
                          <div className="with_separator_10 ps-0">
                            <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="table-text">
                        <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className=""><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                        <div className="d-flex ">
                          <div className="with_separator_10 ps-0">
                            <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="table-text">
                        <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className=""><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                        <div className="d-flex ">
                          <div className="with_separator_10 ps-0">
                            <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="table-text">
                        <span className="color-white-80 c-font f-14">45</span><span className="color-white-60 c-font f-12 px-1">[15 : 00 Hrs]</span><a href="#/" className=""><InfoIcon color="#fca21f" weight="light" className="HW16" /></a>
                        <div className="d-flex ">
                          <div className="with_separator_10 ps-0">
                            <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span><span className="color-white-80 c-font f-12 title-fonts">5</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span><span className="color-white-80">10</span>
                          </div>
                          <div className="with_separator_10">
                            <span className="c-dots  c-7 done rounded-circle me-2"></span><span className="color-white-80">2</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>

              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeDayTable