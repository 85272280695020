import React from 'react';
 

function UserSwitchIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8 9.99971C9.38071 9.99971 10.5 8.88043 10.5 7.49971C10.5 6.119 9.38071 4.99971 8 4.99971C6.61929 4.99971 5.5 6.119 5.5 7.49971C5.5 8.88043 6.61929 9.99971 8 9.99971ZM8 9.99971C7.16899 9.99971 6.3542 10.2294 5.64596 10.6642C4.93772 11.0989 4.36369 11.7212 3.9875 12.4622M8 9.99971C8.83101 9.99971 9.6458 10.2294 10.354 10.6642C11.0623 11.0989 11.6363 11.7212 12.0125 12.4622M12.5 7.99971L14 9.49971M14 9.49971L15.5 7.99971M14 9.49971V7.99972C14.0014 6.70338 13.5829 5.44146 12.8072 4.40283C12.0315 3.3642 10.9403 2.60473 9.69688 2.23811C8.45347 1.87149 7.12476 1.91744 5.90965 2.36907C4.69453 2.82071 3.65837 3.65373 2.95625 4.74346M0.5 7.99971L2 6.49971M2 6.49971L3.5 7.99971M2 6.49971L2 7.99971C1.99858 9.29605 2.41705 10.558 3.19276 11.5966C3.96848 12.6352 5.05971 13.3947 6.30312 13.7613C7.54653 14.1279 8.87524 14.082 10.0904 13.6304C11.3055 13.1787 12.3416 12.3457 13.0437 11.256" />
</svg>



		</React.Fragment>
	)
}

export default UserSwitchIcon