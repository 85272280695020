import React from 'react';
 

function CircleFillInprogressIcons(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`} width="12" height="12" fill="#11abf1" viewBox="0 0 256 256"><path d="M232,128A104,104,0,1,1,128,24,104.13,104.13,0,0,1,232,128Z"></path></svg>
		</React.Fragment>
	)
}

export default CircleFillInprogressIcons