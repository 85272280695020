import React from 'react';
 

function MultiStarIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M11.3906 1V4M12.8906 2.5H9.89062M14.3906 4.5V6.5M15.3906 5.5H13.3906M9.05937 10.9688L7.85937 14.225C7.82386 14.3207 7.7599 14.4032 7.67611 14.4615C7.59231 14.5198 7.49269 14.551 7.39062 14.551C7.28855 14.551 7.18893 14.5198 7.10514 14.4615C7.02134 14.4032 6.95739 14.3207 6.92187 14.225L5.72187 10.9688C5.69655 10.9 5.65661 10.8376 5.60482 10.7858C5.55302 10.734 5.4906 10.6941 5.42187 10.6688L2.16562 9.46875C2.06993 9.43323 1.98741 9.36928 1.92913 9.28549C1.87085 9.20169 1.83961 9.10207 1.83961 9C1.83961 8.89793 1.87085 8.79831 1.92913 8.71452C1.98741 8.63072 2.06993 8.56677 2.16562 8.53125L5.42187 7.33125C5.4906 7.30593 5.55302 7.26599 5.60482 7.21419C5.65661 7.1624 5.69655 7.09998 5.72187 7.03125L6.92187 3.775C6.95739 3.67931 7.02134 3.59679 7.10514 3.53851C7.18893 3.48023 7.28855 3.44899 7.39062 3.44899C7.49269 3.44899 7.59231 3.48023 7.67611 3.53851C7.7599 3.59679 7.82386 3.67931 7.85937 3.775L9.05937 7.03125C9.0847 7.09998 9.12464 7.1624 9.17643 7.21419C9.22823 7.26599 9.29064 7.30593 9.35937 7.33125L12.6156 8.53125C12.7113 8.56677 12.7938 8.63072 12.8521 8.71452C12.9104 8.79831 12.9416 8.89793 12.9416 9C12.9416 9.10207 12.9104 9.20169 12.8521 9.28549C12.7938 9.36928 12.7113 9.43323 12.6156 9.46875L9.35937 10.6688C9.29064 10.6941 9.22823 10.734 9.17643 10.7858C9.12464 10.8376 9.0847 10.9 9.05937 10.9688Z"/>
</svg>

		</React.Fragment>
	)
}

export default MultiStarIcon