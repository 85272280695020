import { FETCH_AUTH_TOKEN } from "../actions/chat-action-type";

const CryptoJS = require("crypto-js");

export default function (state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_AUTH_TOKEN:
            if (!payload.data.success) {
                return state;
            }

            const chatUserId = payload.data.result._id;
            const chatAccessToken = payload.data.result.token;
            const enc_token = CryptoJS.AES.encrypt(
                CryptoJS.enc.Utf8.parse(chatAccessToken),
                process.env.REACT_APP_JWT_ENC_KEY
            );
            
            localStorage.setItem("chatAccessToken", enc_token);
            localStorage.setItem("chatUserId", chatUserId);
            localStorage.setItem("orgChatUserId", chatUserId);

            return state;
        default:
            return state;
    }
}
