import React, { useState, useEffect, useRef } from "react";
import {
  _l,
  showError,
  showSuccess,
} from "../hooks/utilities";
import HighPriorityIcon from "../assets/icons/HighPriorityIcon";
import HighPriorityActiveIcon from "../assets/icons/HighPriorityActiveIcon";
import moment from "moment";
import Toasts from "./Toasts/Toasts";
import Form from "react-bootstrap/Form";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import jquery from "jquery";
import GeneralListing from "./TaskModalComponents/GeneralListing";
import * as bootstrap from "bootstrap";
import CommentMinified from "./Comments/CommentMinified";
import ImageViewer from "./Offcanvas/ImageViewer";
import { CUSTOM_ASSIGNEE_CHANGE } from "../constants/constants";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { fetchMails, setMailReply } from "../actions/customer";
import documentsServices from "../services/documents-services";
import customerServices from "../services/customer-services";
import UnControlMainFiltersDropDown from "./TaskModalComponents/LeadsMainFiltersDropDown";
import { showConfirmation } from "../actions/messages";
import PushPinFillIcon from "../assets/icons/PushPinFillIcon";
import PushPinIcon from "../assets/icons/PushPinIcon";
import ImageIcon from "../assets/icons/ImageIcon";
import ChatCircleIcons from "../assets/icons/ChatCircleIcons";
import EyeIcon from "../assets/icons/EyeIcon";
import DownloadSimpleIcon from "../assets/icons/DownloadSimpleIcon";
import DotsNineIcon from "../assets/icons/DotsNineIcon";
import HashIcon from "../assets/icons/HashIcon";
import ProhibitIcon from "../assets/icons/ProhibitIcon";
import EnvelopeSimpleIcon from "../assets/icons/EnvelopeSimpleIcon";
import ArrowBendUpLeftIcon from "../assets/icons/ArrowBendUpLeftIcon";
window.bootstrap = bootstrap;
let showComments = true;
const EmailCard = ({
  task = {},
  active,
  handler = () =>{},
  pageName = "",
  taskCheckUncheck = ()=>{},
  checkedTask = [],
  setshowOptions =() =>{},
  setSelectedTaskIdforIconToggle =()=>{},
  selectedTaskIdforIconToggle = 0,
  showDefaultIcons = [],
  handleToggleIcons = ()=>{},
  email= {},
  showMailDetailsMessage= ()=>{ },
  inbox,
  UpdateMessage = () => { },
  markImportantEmail = ()=>{},
  is_important = false,
  handleAddTag = ()=>{},
  index
}) => {
  const { commanoffcanvasIsOpen, taskFilters,globalView } = useSelector((state) => state.customer);
  const isVirtualSpaces = localStorage.getItem("selectedVirtualSpace");
  const [openAssigneeDropdown, setOpenAssigneeDropdown] = useState(false);
  const [taskPriority, setTaskPriority] = useState(
  );
  const [taskHide, setTaskHide] = useState(false);
  const [updatedCommentCount, setUpdatedCommentCount] = useState(Number(task.comments_count));
  const [isTaskPinned, setIsTaskPinned] = useState(
    task.pinned && task.pinned == 1 ? true : false
  );
  const [showTaskImage, setShowTaskImage] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  const [replyCommentData, setReplyCommentData] = useState({});
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [isLiteVersion, setisLiteVersion] = useState(false);
  const [tagDropDownToggle, setTagDropDownToggle] = useState(-1)
let is_requested = false
  const [taskAssignee, setTaskAssignee] = useState([]);
  const [taskAllAssignee, setTaskAllAssignee] = useState([]);
  const [showTaskAssignee, setShowTaskAssignee] = useState(0);
  const handleTaskAssigneeData = () => {
    setShowTaskAssignee(showTaskAssignee == 0 ? 1 : 0);
  };
  const dispatch = useDispatch();
  const project_id = useSelector((state) => state.customer.selectedProject);
  const defaultTopics = useSelector((state) => state.customer.defaultTopics);
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const userType = localStorage.getItem("user_type");
  const contact_role = localStorage.getItem("contact_role");
  const contact_id = localStorage.getItem("contact_id");
  const [toastHeader, setToastHeader] = useState("");
  const [confirmButtonText, setConfirmButtonText] = useState("");
  const [cancelButtonText, setCancelButtonText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastClass, setToastClass] = useState("unsucess");
  const [toastMessage, setToastMessage] = useState("");
  const [requestTaskId, setRequestTaskId] = useState(0);
  const [taskAssigneePills, setTaskAssigneePills] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [attachments, setAttachments] = useState({
    file_array: [],
    date: "",
    currentIndex: 0
  })
  const [commentsLoading, setCommentsLoading] = useState(false);
  const pathname = window.location.pathname;
  const toastData = useSelector((state) => state.customer.toastClose);
  const unreadCommentCount = useRef(task.unread_comment_count);
  const [taskCardIconToggle,setTaskCardIconToggle] = useState(true);
  const [EmailAssignee, setEmailAssignee] = useState([]);
// Assignee new flow end-----------------------------------------------
  useEffect(() => {
      let task_assignee = [];
      let task_assignee_team = [];
      let task_assignee_ids = [];
      let task_assignee_pills = [];
        if (task.assignee_list && task.assignee_list.length && task.assignee_list.constructor === Array) {
          task.assignee_list &&
            task.assignee_list.length &&
            task.assignee_list.map((ta) => {
              ta.categories && ta.categories.map((taskAssign) => {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key ==  ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          if (ta.main_key == "equipments" || (task.is_requested == 1 && topic.main_key == "provider_task" && userType == "contact" && !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) {
                            task_assignee_ids.push({
                              name: ta.main_key,
                            });
                          }else{
                            task_assignee_ids.push({
                              id: taskAssign.filter_key,
                              name: ta.main_key,
                            });
                          }
                        }
                      })
                    }})
              if (
                ta.main_key == "myr_staff_task" ||
                ta.main_key == "my_people" ||
                ta.main_key == "task_topic" 
              ) {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key ==  ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          task_assignee.push({
                            name: provider.title,
                            profile_image: provider.image,
                            id: provider.filter_key,
                            email: provider.email,
                            user_type: provider.user_type,
                          });
                          if (provider.filter_key < 0 && !isVirtualSpaces) {
                            task_assignee_team.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              email: provider.email,
                              user_type: provider.user_type,
                            })
                          }
                          if (provider.filter_key > 0 && isVirtualSpaces) {
                            task_assignee_team.push({
                              name: provider.title,
                              profile_image: provider.image,
                              id: provider.filter_key,
                              email: provider.email,
                              user_type: provider.user_type,
                            })
                          }
                        }
                      });
                    }
                  });
              } else {
                defaultTopics &&
                  defaultTopics.forEach((topic) => {
                    if (topic.main_key == ta.main_key) {
                      topic.categories.forEach((provider) => {
                        if (taskAssign.filter_key == provider.filter_key) {
                          let subCategory = []
                          if (task.is_requested == 1 && topic.main_key == "provider_task") {
                            taskAssign.sub_category  && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                                subCategory.push({...dd,id:dd.staffid});
                            })
                          }else{
                            provider.sub_category && provider.sub_category.forEach((sc) => {
                              taskAssign.sub_category && taskAssign.sub_category.length > 0 && taskAssign.sub_category.forEach((dd) => {
                                if (sc.id == dd) {
                                  subCategory.push(dd);
                                  task_assignee_pills.push({
                                    name: sc.name,
                                    id: sc.id,
                                    main_key:topic.main_key
                                  });
                                }
                              })
                            });
                        }
                        task_assignee_ids.push({
                          id: provider.filter_key,
                          name: provider.title,
                          sub_category: subCategory
                        })
                          if (topic.main_key != "equipments" || !(task.is_requested == 1 && topic.main_key == "provider_task" && userType == "contact" && !CUSTOM_ASSIGNEE_CHANGE.includes(contact_role))) { 
                            task_assignee_pills.push({
                              name: provider.title,
                              id: provider.filter_key,
                              main_key:topic.main_key
                            });
                          }
                        }
                      });
                    }
                  });
                if ((ta.main_key == "provider_task" || ta.main_key == "proposals") && userType == "staff") {
                  ta.categories.map((data) => {
                    task_assignee_pills.push({
                      name: "Myr.ai Support",
                      id: data.filter_key,
                      main_key: data.filter_key
                    });
                  })
                }
              }
            });
          })
      }
  }, [
    openAssigneeDropdown,
    defaultTopics,
    JSON.stringify(task)
  ]);
  useEffect(() => {
    jquery(document).mouseup(function (e) {
     var container = jquery('.chat-page');
     if (!container.is(e.target) && container.has(e.target).length === 0) {
       setshowOptions(0)
     }
   });
  })
  useEffect(() => {
    let assigneeList = []
    defaultTopics && defaultTopics.map((topic) => {
      email['thread']['email_assignee'] && email['thread']['email_assignee'].map((e) => {
        if (topic.main_key == e.assignee_type) {
          topic.categories.map((category) => {
            if (category.main_sub_key == e.assignee_sub_type && category.filter_key == e.assignee_id) {
              assigneeList.push(category);
            }
          })
        }
      })
    })
    setEmailAssignee(assigneeList);
  }, [JSON.stringify(email)])
  const changeTaskPriority = (e,id) => {
    try {
   customerServices.setPriorityOfMail(id).then((res) => {
    if (res && res.status) {
      dispatch(fetchMails(taskFilters))
    }
   })
    } catch (error) {
      console.log(error)
    }
  };
  const deleteTask = (request_task_id = 0) => {
  };
  const getTask = () => {
  };
  const handleImagePreview = (comment) => {
    setShowImagePreview(true);
    setAttachments({...attachments, file_array: comment.attachments, currentIndex: 0, date: comment.dateadded});
  };
  const closePreview = () => {
    setAttachments({...attachments, file_array: [], currentIndex: 0});
    setShowImagePreview(false);
  };
  const changeAttachment = (direction) => {
    if(direction === "prev" && attachments.currentIndex > 0)
    {
      setAttachments({...attachments, currentIndex: attachments.currentIndex--});
    }
    else if(direction === "next" && attachments.currentIndex <= attachments.current.file_array.length)
    {
      setAttachments({...attachments, currentIndex: attachments.currentIndex++});
    }
  };
  const handleAddEditComment = async(content, files, id) => {
    // console.log(content, files, id);
    setFileUploadLoader(true);
    try {
      const response = await documentsServices.addDocumentComment('gmail', email["thread"]["id"], content, 0, id, files, 0, 0, replyCommentData.id );
      if(id > 0)
        {
          const commentIndex = commentsList.findIndex(w => w.id == id);
          setCommentsList(Object.values({...commentsList, [commentIndex]: {...commentsList[commentIndex], content}}));
        }
        else {
          const staffid = localStorage.getItem("staff_id");
          const profile_url = localStorage.getItem("contact_image");
          const full_name = localStorage.getItem("full_name");
          let attachments = [];
          if(files)
          {
            Array.from(files).map((file) => {
              attachments.push({
                file_url: URL.createObjectURL(file),
                file_name: file.name
              })
            });
          }
          var addComments = {
            id: response.data.comment_id,
            content: content,
            contact_id: contact_id,
            staffid: staffid,
            full_name: full_name,
            profile_url: profile_url,
            is_allowed_to_edit:"1",
            attachments: attachments,
            date: response.data.time,
            dateadded:new Date(),
          }
          if (replyCommentData && replyCommentData.content != '' && replyCommentData.id != '') {
            addComments.parent_comment_details = {id : replyCommentData.id, content : replyCommentData.content , file_id : "0"}
            addComments.parent_comment_id = replyCommentData.id
          }else if(replyCommentData.attachments && replyCommentData.attachments[0].file_url != ''){
            let replyCommentDatas = replyCommentData.attachments[0]
            addComments.parent_comment_details = {content : "[task_attachment]", file_id : replyCommentDatas.file_id, file_name : replyCommentDatas.file_name, file_url: replyCommentDatas.file_url}
            addComments.parent_comment_id = replyCommentData.id
          }
          setCommentsList(commentsList.concat([addComments]));
          setUpdatedCommentCount(Number(commentsList.length + 1));
          setFileUploadLoader(false);
        }
    } catch (error) {
    } finally {
    setFileUploadLoader(false);
    }
  };
  const handleDeleteComment = (comment) => {
  }
  const htmlDecode = (content) => {
    let e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}
  const convertDateintoTimezone = (date) => {
    var utc = new Date();
    var offset = utc.getTimezoneOffset();
    var MS_PER_MINUTE = 60000;
    var myStartDate = new Date(new Date(date).getTime()  - offset * MS_PER_MINUTE);
    return myStartDate;
  } 
  const getEmailComments = async () =>{
    setCommentsLoading(true);
    try {
      const comments = await documentsServices.getComments(email["thread"]["id"], 'gmail');
      if (comments && comments.data) {
        setCommentsList(comments.data.map((comment) => {
          return {
            id: comment.id,
            content: comment.content,
            contact_id: comment.contact_id,
            staffid: comment.staffid,
            full_name: comment.contact_full_name || comment.staff_full_name ,
            profile_url: comment.addedfrom_details.profile_url,
            attachments: comment.attachments,
            date: comment.time,
            dateadded: comment.dateadded,
            is_allowed_to_edit:comment.is_allowed_to_edit,
            parent_comment_details : comment.parent_comment_details ? comment.parent_comment_details : '',
            parent_comment_id : comment.parent_comment_id? comment.parent_comment_id : 0
          }
        }));
      } else {
      }
    } catch (error) {
    } finally {
    setCommentsLoading(false);
    }
  }
  const addToSpam = (id) =>{
    const functionWrapper = () => {
      try {
        customerServices.addToSpam(id).then((res) =>{
          if (res && res.status) {
            showSuccess(res.message);
            dispatch(fetchMails(taskFilters))
          }else{
            showError("l_something_went_wrong")
          }
        })      
        } catch (error) {
          console.log(error)
        } finally {
          setSelectedTaskIdforIconToggle(0);
        }
    }
    dispatch(
      showConfirmation(
        _l("l_are_you_sure"),
        _l("l_do_you_want_to_mark_this_email_as_spam"),
        _l("l_yes"),
        _l("l_no"),
        undefined,
        () => {
          functionWrapper()
        }
      )
    );
  }
  return (
    <React.Fragment>
      {showToast && toastData ? (
        <Toasts
          delay={2000}
          handler={() => {
            setShowToast(false);
          }}
          header={toastHeader}
          message={toastMessage}
          toastClass={toastClass}
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={() => {
            setShowToast(false);
          }}
          confirmButtonText={confirmButtonText}
          confirmButtonHandler={() => {
            deleteTask();
            if (requestTaskId && requestTaskId != 0) {
              const previousId = requestTaskId;
              setRequestTaskId(0);
              deleteTask(previousId);
              handler();
            }
            setShowToast(false);
          }}
        />
      ) : (
        <></>
      )}
      {!taskHide && pageName != "ChatAi"? (
        <div
          className={`task-list-card list-view ${selectedTaskIdforIconToggle == email['conversation']['id'] ? "active" : ""} ${active == email['conversation']['id'] ? "active" : ""} ${isLiteVersion ? "taskcardliteversion overflow-visible" : ""}`}
          id={`task_${
            task &&
            (typeof task.id == "string"
              ? task.id.replace(new RegExp("=", "g"), "")
              : task.id)
          }`}
        >
          {isLiteVersion ? (
          <div className={`text-center c-font color-white-80 f-10 p-2 liteversion-date-box comman-white-box base-body-bg min-width-60 fw-semibold d-none ${commanoffcanvasIsOpen ? "d-none" : ""}`}>
            {taskFilters.length > 0 ? 
              task.startdate ? 
                <>
                  <div>{moment(convertDateintoTimezone(task.startdate)).format("DD/MM/yy hh:mm a")}</div>
                  <div>{moment(convertDateintoTimezone(task.startdate)).format("  hh:mm a")}</div> 
                </>
                :
                <>
                  <div>{moment(convertDateintoTimezone(task.dateadded)).format("DD/MM/yy")}</div>
                  <div>{moment(convertDateintoTimezone(task.dateadded)).format("hh:mm a")}</div>
                </>
            : task.startdate  
              ? moment(convertDateintoTimezone(task.startdate)).format('hh:mm a')  
              : moment(convertDateintoTimezone(task.dateadded)).format('hh:mm a') }
          </div>) : (<></>)}
          {isTaskPinned ? (
            <div className="task-pin-status rounded-circle h25w25 bg-white-05 d-none">
              <div className="d-flex w-100 h-100">
                <ImageIcon className="HW16" />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="task-list-wrapper">
            <div className="d-flex w-100 align-items-start">
              <div className="d-flex task-list-detail">
                <div className={`task-left flex-grow-1 width100minus160 ${globalView == "kanbanView" ? "w-100" : ""}`}>
                  <div className="d-flex align-items-center w-100 task-left-part-toggle text-truncate">
                    {pathname == "/documents" &&
                    task &&
                    task.provider == "google_drive" || task &&
                    task.provider == "one_drive"  ? (
                      <></>
                    ) : (
                      <div className="task-creator-img">
                        <div className={`h20w20 d-flex align-items-center me-10px ${globalView == "kanbanView" ? "mb-10" : ""}`}>
                          <Form.Check
                            type="checkbox"
                            className="m-auto"
                            checked={
                              checkedTask && checkedTask.length
                                ? checkedTask.filter((id) => task.id == id)
                                    .length
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(e) => {
                              taskCheckUncheck(task.id);
                            }}
                            disabled={task.is_allowed_to_edit != 1}
                          />
                        </div>
                      </div>
                      )}
                                      <a href="#/"
                                          className={`task-top-part text-truncate flex-grow-1 d-flex ${task.request_task_id == 0
                                                  ? "main-calc-width-task"
                                                  : "main-calc-width"
                                              }`}
                                              onClick={()=>{showMailDetailsMessage(true, email,inbox) }}
                                      >
                                          <div className={`${isLiteVersion ? "flex-wrap" : ""} d-flex  w-100 align-items-center comman_action_icon`}>
                                              <div className="task-creator-img h32w32 task-creator-img m-auto rounded-5">
                                                  <div
                                                      id="deLeteDropdown"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                      data-bs-auto-close="outside"
                                                       className={`comman-image-box h-100 w-100 rounded-circle m-auto `}
                                                  >
                                                      <div
                                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                                          style={{
                                                              backgroundImage: `url('${email['thread']['profile']}')`,
                                                          }}
                                                      ></div>
                                                  </div>
                                              </div>
                                              <div className="d-flex flex-column w100minus100per task-list-name text-truncate ">
                                                 <div className="text-truncate w-100 mb-1 d-flex align-items-center">
                                                    <div className={`fw-semibold me-2`}>{htmlDecode((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'From'))[0]['value'])} :</div>
                                                    <div className={`fw-semibold text-truncate me-2`}>{(email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Subject'))[0]['value']}</div>
                                                 </div>
                                                  {/* show space address */}
                                                  <div className="d-flex align-items-center">
                                                      <div className="d-flex c-font color-white-60 f-10 lh-1 location me-2 fw-normal text-truncate">
                                                        <div className="align-items-center before-block before-dblock d-flex with_separator_10 with_seperaotr_10 ps-0">
                                                          <span className="p-0">{_l("l_date")} :</span> 
                                                          <div className="">{moment((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).format("DD/MM/YYYY hh:mm a")}</div>
                                                        </div>
                                                        <span className="ps-10px">{email['conversation']['messages'][email['conversation']['messages'].length - 1]['snippet']}</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/* show space address */}
                                          </div>
                                      </a>
                  </div>
                </div>
                <div className={`d-flex align-items-center task-bottom-part-full `}>
                    <div className="task-right-static-options">
                      <div className="d-flex align-items-center">
                        <div
                          className={`d-flex align-items-center multi_inner_wrapper justify-content-end w-120 w-auto `}
                        >
                        </div>
                        {/* Common date picker for start_date and due_date */}
                      <div className={`align-items-center d-flex  ${globalView == "kanbanView" ? "flex-wrap kanbanbottomspacing" : ""} ${commanoffcanvasIsOpen ? "with-abs-position-bg-taskcard" : ""}`}>
                        {showDefaultIcons.includes("eye") ? 
                        <div className="comman_action_icon card-view-less-padding p-0 me-2">
                        </div> : <></>}
                        {pathname == "/documents" &&
                          task &&
                          task.provider == "google_drive" || task && task.provider == 'one_drive' ? (
                          <>
                            <div className="comman_action_icon with_separator_10 card-view-less-padding">
                              <div className="c-font f-12 fw-semibold text-nowrap ">
                                {task.size
                                  ? Math.floor(parseInt(task.size) / 1024) +
                                  " KB"
                                  : "No Size"}
                              </div>
                            </div>
                            <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                              <div className="comman_action_icon w-100 d-flex">
                                <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                  <a>
                                    <div
                                      className="action_icon with_bg on-hover-active-toggle-img position-relative"
                                      onClick={() => {
                                        window.open(task.webViewLink, "_blank");
                                      }}
                                    >
                                      <EyeIcon
                                        className="HW18"
                                      />
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-center dropdown with_separator_10 d-flex align-items-center card-view-less-padding before-block min-width-auto before-dnone">
                              <a
                                className="comman_action_icon w-100 d-flex"
                                href={task.downloadUrl} 
                              >
                                {task.fileExtension !== "form" && (
                                  <div className="action_icon with_bg h32w32 on-hover-active-toggle-img position-relative">
                                    <DownloadSimpleIcon
                                      className="HW18"
                                    />
                                  </div>
                                )}
                              </a>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {/* disabled start */}
                         {(selectedTaskIdforIconToggle == email['conversation']['id'] && showDefaultIcons.includes('disabled') )? <div className="before-dnone comman_action_icon with_separator_10 me-10px px-0">
                          <a href="#/"
                            className="action_icon with_bg h32w32 light-theme-img close-toast"
                          onClick={() => {
                              addToSpam(email["thread"]["id"]) 
                            }}
                          >
                              <div className="m-auto ">
                                <ProhibitIcon className="HW18" />
                              </div>
                          </a>
                        </div> : <></>}
                        {/* disabled over */}
                        {/* Hash start */}
                          {(selectedTaskIdforIconToggle == email['conversation']['id'] && showDefaultIcons.includes('hash') ) ?
                          <>
                            {email['thread'] && email['thread'].custom_topic && email['thread'].custom_topic.length >= 1 ? 
                              <div
                                className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex with_separator_10 with_minus`}
                              >
                                {email['thread'] && email['thread'].custom_topic &&
                                email['thread'].custom_topic.length >= 1 ? (
                                  email['thread'].custom_topic.map((tag, index) => {
                                    if (index < 1)
                                      return (
                                        <div
                                          className="badge rounded-pill text-nowrap list-toggle-view-none c-font f-12 d-flex align-items-center  max-width75"
                                          key={index}
                                        >
                                          <span className={` p-0 text-truncate c-font f-12`}>
                                            {tag.main_key == "my_task"
                                              ? "#"
                                              : ""}
                                            {_l(tag.name)}
                                          </span>
                                        </div>
                                      );
                                  })
                                ) : (
                                  <></>
                                )}
                                {email['thread'].custom_topic && email['thread'].custom_topic && email['thread'].custom_topic.length > 1 ? (
                                  <a
                                    href="#/"
                                    className="badge rounded-pill text-nowrap list-toggle-view-none ms-10px c-font f-12 d-flex align-items-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <div className="unread_count m-auto c-font f-12
                                    ">
                                      +{email['thread'].custom_topic.length - 1}
                                    </div>
                                  </a>
                                ) : (
                                  <></>
                                )}
                                {email['thread'].custom_topic && email['thread'].custom_topic.length ? (
                                  <GeneralListing
                                    handler={() => {}}
                                    list={
                                      email['thread'].custom_topic &&
                                      email['thread'].custom_topic.length &&
                                      email['thread'].custom_topic.map((item, index) => {
                                        return {
                                          name: _l(item.name),
                                          id: index,
                                        };
                                      })
                                    }
                                    isHash={false}
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                            : <></>}
                            <UncontrolledDropdown className={`p-10  res py-0 pe-0 comman_action_icon  `}>
                              <div className="d-flex align-items-center m-auto light-theme-img shadow-none comman_action_icon justify-content-center">
                                <DropdownToggle tag={'div'} className="d-flex dropdown dropdown-center team-list-dropdown ">
                                  <a href="#/"
                                    className={`d-flex on-hover-active-toggle-img h32w32 with_bg action_icon me-10px 'activeicon'`}
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false" 
                                    onClick={() => setTagDropDownToggle(index)}
                                    >
                                    <div className="m-auto ">
                                    <HashIcon className="HW18 fill-white" />
                                  </div>
                                  </a>
                                </DropdownToggle>
                                <DropdownItem toggle={false} className="p-0"> 
                                <div toggle={false} className="px-0"> 
                                {tagDropDownToggle === index ? 
                                  <UnControlMainFiltersDropDown 
                                    type={"isEamilForMeTag"}
                                    data= {email}
                                    addEuipmentchecklists = {
                                      handleAddTag
                                    }
                                    />
                                   :<></>}
                                </div>
                                </DropdownItem>
                              </div>
                            </UncontrolledDropdown> 
                            </>
                          : <></>}
                        {/* Hash over */}
                        {/* HighPriorityIcon start */}
                        {taskPriority == 1 || (selectedTaskIdforIconToggle == email['conversation']['id'] && showDefaultIcons.includes("priority")) || email["thread"].is_priority ? <div className="comman_action_icon card-view-less-padding p-0">
                          <div
                            className={`priority-checkbox span-13 white-border-box action_icon with_bg h32w32 close-toast`}
                          >
                            <input
                              type="checkbox"
                              id="priority"
                              className="absolute-input"
                              checked={email["thread"].is_priority ? true : false}
                              onChange={(e) => {
                                changeTaskPriority(e, email["thread"]["id"]);
                              }}
                              disabled={
                                task && parseInt(task.request_task_id) > 0
                              }
                            />
                            <div className="check_box">
                              <HighPriorityActiveIcon className="HW18 check_span_img active" />
                              <HighPriorityIcon className="HW18 check_span_img inactive opacity-50" />
                            </div>
                          </div>
                        </div>
                         : <></>}
                        {/* HighPriorityIcon end */}
                        {/* email Assignee start */}
                        <div
                          className={`dropdown-center multi_inner_wrapper with-min-width d-flex with_minus before-dnone ps-10px ${
                            EmailAssignee && EmailAssignee.length >= 1
                              ? ""
                              : "d-none"
                          }`}
                        >
                          {EmailAssignee && EmailAssignee.length >= 1 ? (
                            EmailAssignee.map((assignee, index) => {
                              if (index < 1)
                                return (
                                  <a
                                    href="#/"
                                    data-bs-toggle="tooltip"
                                    className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                    key={index}
                                  >
                                    <div
                                      className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                      style={{
                                        backgroundImage: `url('${
                                          assignee.user_image ||
                                          'https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg'
                                        }')`,
                                      }}
                                    ></div>
                                  </a>
                                );
                            })
                          ) : (
                            <></>
                          )}
                          <UncontrolledDropdown>
                            <DropdownToggle tag="div">
                              {EmailAssignee && EmailAssignee.length > 1 ? (
                                <a
                                  href="#/"
                                  className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <div className="unread_count m-auto c-font f-12">
                                    +{EmailAssignee.length - 1}
                                  </div>
                                </a>
                              ) : (
                                <></>
                              )}
                              {EmailAssignee && EmailAssignee.length ? (
                                <GeneralListing
                                  unControlList={true}
                                  handler={() => {}}
                                  list={
                                    EmailAssignee &&
                                    EmailAssignee.length &&
                                    EmailAssignee
                                      .sort((a, b) => {
                                        if (a.title < b.title) return -1;
                                        if (a.title > b.title) return 1;
                                      })
                                      .map((item, index) => {
                                        return {
                                          name: item.full_name || item.title,
                                          image:
                                            item.user_image || 'https://myrpp.myr.ai/orb/assets/images/default-image-placeholder.svg',
                                          id: item.filter_key,
                                        };
                                      })
                                  }
                                  customSort={true}
                                />
                              ) : (
                                <></>
                              )}
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </div>
                        {/* email Assignee end */}
                        {/* Mail start */}
                       {((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length) || (selectedTaskIdforIconToggle == email['conversation']['id'] && showDefaultIcons.includes('mail')) ? 
                       <div className={`${isLiteVersion ? " " : ""} dropdown-center dropdown with_separator_10 before-dnone pe-0 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                          <UncontrolledDropdown className="w-100">
                            <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                              <a href="#/"
                                onClick={(e) => {
                                }}
                                className="on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                              >
                                <div className="d-flex align-items-center w-100">
                                  <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                    <EnvelopeSimpleIcon
                                      className="HW18"
                                    />
                                    {((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length) ? <span className=" rounded-pill badge-custom d-flex p-0 bg-warning">{((email['conversation']['messages'].filter((reply) => reply['labelIds'].includes('UNREAD')) || []).length)}</span> : <></>}
                                  </div>
                                  <span
                                    className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                    data-cmt-cnt-id={task.id}
                                  >0
                                  </span>
                                  {/* )} */}
                                </div>
                              </a>
                            </DropdownToggle>
                          </UncontrolledDropdown>
                        </div> : <></>}
                        {/* Mail over */}
                        {/* comment icon start */}
                       {Number(updatedCommentCount) > 0 || (selectedTaskIdforIconToggle == email['conversation']['id'] && showDefaultIcons.includes('comment')) ? 
                       <div className={`${isLiteVersion ? " " : ""} dropdown-center dropdown ps-2 text-on-hover-active-green on-hover-active-toggle-img d-flex align-items-center card-view-less-padding close-toast`}>
                          <UncontrolledDropdown className="w-100">
                            <DropdownToggle data-toggle="dropdown" tag="span" className="comman_action_icon w-100 d-flex close-toast border-0">
                              <a href="#/"
                                onClick={(e) => {
                                  getEmailComments();
                                }}
                                 className="on-hover-active-toggle-img text-on-hover-active-green hovertextgreen"
                              >
                                <div className="d-flex align-items-center w-100">
                                  <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                    <ChatCircleIcons
                                      className="HW18"
                                    />
                                    <span
                                      className={`rounded-pill badge-custom p-0 ${ Number(unreadCommentCount) > 0
                                        ? ""
                                        : "d-none"
                                        }`}
                                      data-unread-cmt-id={task.id}
                                    >
                                      {unreadCommentCount.current}
                                    </span>
                                  </div>
                                  <span
                                    className="c-font f-12 d-flex justify-content-center flex-grow-1 number-counter-toggle"
                                    data-cmt-cnt-id={task.id}
                                  >
                                    {Number(updatedCommentCount) > 0 ? Number(updatedCommentCount) : 0}
                                  </span>
                                  {/* )} */}
                                </div>
                              </a>
                            </DropdownToggle>
                            <DropdownItem toggle={false} className="p-0">
                              {
                                <CommentMinified
                                  comments={commentsList}
                                  disabled={
                                    task &&
                                      parseInt(task.request_task_id) > 0 &&
                                      task.is_requested == 1
                                      ? true
                                      : false}
                                  handleAddComment={handleAddEditComment}
                                  deleteComment={handleDeleteComment}
                                  id={email["thread"]["id"]}
                                  task={email}
                                  loading={commentsLoading}
                                  mentionList={mentionsList.map((name) => {
                                    let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                    return {
                                      display: name.name,
                                      id: id,
                                      image: name.profile_image || "",
                                      isteam: name.is_team ? 1 : 0,
                                    };
                                  })}
                                  handleImagePreview={handleImagePreview}
                                  unContolList={true}
                                  totalUnreadCommentCount={task.unread_comment_count}
                                  showComments={showComments}
                                  setReplyCommentData={setReplyCommentData}
                                  fileLoader={fileUploadLoader}
                                  type={"email"}
                                />
                              }
                            </DropdownItem>
                          </UncontrolledDropdown>
                        </div> : <></>}
                        {/* comment icon end */}
                        {/* task assignees start */}
                          <div className={`position-relative d-flex assignee-list-wrapper card-view-less-padding pe-2 ps-2`}>
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width ps-0 d-flex  with_minus `}
                            >
                                <div className="pill-min-width pill-bottom-show  text-end">
                                    <div className="d-flex align-items-center">
                                        <a href="#/" className="badge  badge-white rounded-pill fw-light">
                                            <span className="color-white p-0">{email['conversation']['messages'][0]['labelIds'][(email['conversation']['messages'][0]['labelIds'] || []).length - 1]}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                              className={`dropdown-center multi_inner_wrapper with-min-width d-flex ps-0 pe-10px with_minus before-dnone ${globalView == "kanbanView" ? "mb-10" : ""} ${taskAllAssignee && taskAllAssignee.length >= 1
                                ? ""
                                : "d-none"
                                }`}
                            >
                              {taskAllAssignee && taskAllAssignee.length >= 1 ? (
                                taskAllAssignee.map((assignee, index) => {
                                  if (index < 1)
                                    return (
                                      <a href="#/"
                                        data-bs-toggle="tooltip"
                                        className="comman-image-box multi-wrapper-border h32w32 rounded-circle one assignee-list-wrapper card-view-less-padding"
                                        key={index}
                                      >
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 rounded-circle"
                                          style={{
                                            backgroundImage: `url('${assignee.user_image ||
                                              assignee.profile_image
                                              }')`,
                                          }}
                                        ></div>
                                      </a>
                                    );
                                })
                              ) : (
                                <></>
                              )}
                              <UncontrolledDropdown>
                              <DropdownToggle tag="div">
                              {taskAllAssignee && taskAllAssignee.length > 1 ? (
                                <a href="#/"
                                  className="comman-image-box h32w32 with_overlay d-flex rounded-circle dropdown-center"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={handleTaskAssigneeData}
                                >
                                  <div className="unread_count m-auto c-font f-12">
                                    +{taskAllAssignee.length - 1}
                                  </div>
                                </a>
                              ) : (
                                <></>
                              )}
                              {taskAllAssignee && taskAllAssignee.length ? (
                                <GeneralListing
                                unControlList={true}
                                  handler={() => { }}
                                  list={
                                    taskAllAssignee &&
                                    taskAllAssignee.length &&
                                    taskAllAssignee.sort((a, b) => {
                                      if (a.name < b.name) return -1;
                                      if (a.name > b.name) return 1;
                                    }).map((item, index) => {
                                      return {
                                        name: item.full_name || item.name,
                                        image:
                                          item.user_image ||
                                          item.profile_image,
                                        id: item.id,
                                      };
                                    })
                                  }
                                  customSort={true}
                                />
                              ) : (
                                <></>
                              )}
                              </DropdownToggle>
                              </UncontrolledDropdown>
                            </div>
                          </div> 
                        {/* task assignees end */}
                        {/* push pin icon start  */}
                        {(showDefaultIcons.includes('pin') && selectedTaskIdforIconToggle == email['conversation']['id'] )||  is_important ? 
                        <React.Fragment>
                        <div className="before-dnone comman_action_icon with_separator_10 px-0 me-2"> 
                          <a href="#/"
                            className="action_icon with_bg h32w32 light-theme-img close-toast"
                          >
                            {is_important ? (
                              <div
                                className="m-auto "
                                onClick={() => {
                                  UpdateMessage(email['conversation']['id'], (email['thread']['is_closed'] == 0 ? 1 : 0), 'un_important')
                                }}
                              >
                                <PushPinFillIcon className="stroke-transparent Push-Pin-fill HW18" />
                              </div>
                            ) : (
                              <div
                                className="m-auto on-hover-active-toggle-img"
                                onClick={() => {
                                  markImportantEmail(email['conversation']['id'], new Date((email['conversation']['messages'][0]['payload']['headers'].filter((header) => header['name'] == 'Date'))[0]['value']).toLocaleString())
                                }}
                              >
                                <PushPinIcon
                                  className="HW18"
                                />
                              </div>
                            )}
                          </a>
                        </div>
                        </React.Fragment> 
                        : <React.Fragment></React.Fragment>}
                        {/* push pin icon end  */}
                        {/* separator Start */}
                        <div className={`px-0 with_separator2_10 me-10px`}></div>
                        {/* separator end */}
                        {/* reply Btn start */}
                        <a className="comman_action_icon card-view-none me-10px"
                              onClick={()=>{
                                showMailDetailsMessage(true, email,inbox);
                                dispatch(setMailReply(true));
                              }}
                              >
                          <div className="d-flex align-items-center">
                            <div className="action_icon with_bg on-hover-active-toggle-img text-on-hover-active-green light-theme-img h32wauto  color-white-80 calendarpadding d-flex align-items-center text-truncate">
                            <ArrowBendUpLeftIcon className="HW18 fill-white" />
                                <span className="ms-1">{_l("l_replay")}</span>
                            </div>
                          </div>
                        </a>
                        {/* reply Btn end */}
                        <div className={`comman_action_icon  ${globalView == "kanbanView" ? "absoluteDotsTask" : ""}`}>
                          <a href="#/" className={`action_icon h32w32 with_bg with-border-active on-hover-active-toggle-img`} onClick={()=>{
                            handleToggleIcons(isTaskPinned,taskAssignee,taskAssigneePills,taskPriority,task.comments_count,task.rating,setTaskCardIconToggle,taskCardIconToggle)
                            if (selectedTaskIdforIconToggle == email['conversation']['id']) {
                              setSelectedTaskIdforIconToggle(0)
                            }else{
                              setSelectedTaskIdforIconToggle(email['conversation']['id'])
                            }
                            }}>
                            <DotsNineIcon className="HW18" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className={`task-assign-section `}>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="pill-min-width  pill-bottom-top text-end d-none">
                      {task.is_requested == "1" ? (
                        <>
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="badge  badge-white rounded-pill me-2"
                              onClick={() => {
                                if (
                                  task &&
                                  parseInt(task.request_task_id) > 0
                                ) {
                                  handler(
                                    task.id,
                                    true,
                                    is_requested,
                                    task.request_task_id
                                  );
                                  getTask(task.id, is_requested, task.provider);
                                  jquery("body").removeClass("body-toggle");
                                  jquery(".provider-list").removeClass("expanded");
                                  jquery(".office-list").removeClass("expanded");
                                }
                              }}
                            >
                              <span className="text-warning p-0">
                                {_l("l_request")}
                              </span>
                            </a>
                            <a href="#/"
                              className={`badge  badge-white rounded-pill me-2 ${
                                task && parseInt(task.request_task_id) > 0 ? "" : "d-none"}`}
                              onClick={() => {
                                if ( task && parseInt(task.request_task_id) > 0) {
                                  handler(
                                    task.request_task_id,
                                    true,
                                    null,
                                    task.id
                                  );
                                  getTask(
                                    task.request_task_id,
                                    null,
                                    task.provider
                                  );
                                  jquery("body").removeClass("body-toggle");
                                  jquery(".provider-list").removeClass(
                                    "expanded"
                                  );
                                  jquery(".office-list").removeClass(
                                    "expanded"
                                  );
                                }
                              }}
                            >
                              <span className="color-white p-0">
                                {task.provider
                                  ? task.is_synced
                                    ? _l("l_document_task")
                                    : _l("l_document")
                                  : _l("l_task")}
                              </span>
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <a href="#/"
                              className="badge  badge-white rounded-pill me-2"
                              onClick={() => {
                                if (
                                  task &&
                                  parseInt(task.request_task_id) > 0
                                ) {
                                  handler(
                                    task.id,
                                    true,
                                    null,
                                    task.request_task_id
                                  );
                                  getTask(task.id, null, task.provider);
                                  jquery("body").removeClass("body-toggle");
                                  jquery(".provider-list").removeClass(
                                    "expanded"
                                  );
                                  jquery(".office-list").removeClass(
                                    "expanded"
                                  );
                                }
                              }}
                            >
                              <span className="color-white p-0">
                                {task.provider
                                  ? task.is_synced
                                    ? _l("l_document_task")
                                    : _l("l_document")
                                  : _l("l_task")}
                              </span>
                            </a>
                            <a href="#/"
                              className={`badge  badge-white rounded-pill me-2 fw-light ${
                                task && parseInt(task.request_task_id) > 0
                                  ? ""
                                  : "d-none"
                              }`}
                              onClick={() => {
                                if (
                                  task &&
                                  parseInt(task.request_task_id) > 0
                                ) {
                                  handler(
                                    task.request_task_id,
                                    true,
                                    1,
                                    task.id
                                  );
                                  getTask(
                                    task.request_task_id,
                                    1,
                                    task.provider
                                  );
                                  jquery("body").removeClass("body-toggle");
                                  jquery(".provider-list").removeClass(
                                    "expanded"
                                  );
                                  jquery(".office-list").removeClass(
                                    "expanded"
                                  );
                                }
                              }}
                            >
                              <span className="text-warning p-0">
                                {_l("l_request")}
                              </span>
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <>
          </>
      )}
      {showImagePreview ? 
        <ImageViewer
          CloseCommentModal={closePreview}
          attachmentDate={attachments.date}
          attachmentURL={attachments.file_array[attachments.currentIndex].file_url}
          changeAttach={changeAttachment}
          handleAction={() => {}}
          isChat={false}
          showNextBtn={attachments.file_array.length > 0}
          showPrevBtn={attachments.file_array.length > 0}
        />
        :
        <React.Fragment></React.Fragment>
      }
     {showTaskImage && Object.keys(attachmentDetails).length > 0 ? 
        <CommanOffCanvas
          show={showTaskImage}
          handleClose={() => {
            setShowTaskImage(false);
          }}
          data={{
            id: attachmentDetails.id,
            taskId: task.id,
            projectId: project_id,
            attachmentDetails: attachmentDetails,
            taskDetails: task,
            allAttachments: task.attachments
          }}
          docType={"imageview"}
          selectedTaskId={task.id}
          pageName={pageName}
          handleParentComponentAction={() => {
          }}
          isCommentsection={false}
          setSelectedId={setSelectedAttachmentId}
        /> : <></>
      }
    </React.Fragment>
  );
};
export default EmailCard