import React, { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { _l, getPreviewFromURL, showError } from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import Spinner from "react-bootstrap/Spinner";
import { validateEmail } from "../../hooks/utilities";
import CustomerServices from "../../services/customer-services";
import TagInput from "../Editor/TagInput";
import CopyIcon from "../../assets/icons/CopyIcon";
import GearSixIcon from "../../assets/icons/GearSixIcon";
import BellIcon from "../../assets/icons/BellIcon";
import GlobeIcon from "../../assets/icons/GlobeIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import GoogleLogoIcon from "../../assets/icons/GoogleLogoIcon";
import LinkSimpleIcon from "../../assets/icons/LinkSimpleIcon";
import CloudArrowUpIcon from "../../assets/icons/CloudArrowUpIcon";
import XIcon from "../../assets/icons/XIcon";

const TaskShareLinkModal = ({
  show,
  handleClose,
  title,
  link,
  action = "",
  data = {},
  docType,
  mailForComment = {},
}) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [loder, setLoder] = useState(false);
  const [email, setEmail] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [mailContent, setMailContent] = useState("");
  const [selectedEmailArr, setSelectedEmailArr] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isInternalShared, setIsInternalShared] = useState(true)
  const [attachmentData, setAttachmentData] = useState({
    attachmentsPreviews: [],
  });
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    if (mailForComment && mailForComment.content) {
      setMailContent(mailForComment.content.replace(/<[^>]*>/g, ""));
    }
  }, [mailForComment]);

  const handleAction = () => {
    if (docType !== "documents" && email == "" && (selectedEmailArr.length == 0 && emails.length == 0)) {
      dispatch(
        showMessage(
          "unsucess",
          _l("l_error"),
          _l("l_please_enter_email_or_select_contact")
        )
      );
    } else if (docType === "documents" && tags.length === 0) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_email"))
      );
    } else if (email != "" && !validateEmail(email)) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l("l_please_enter_valid_email"))
      );
    } else {
      var allEmails = [];
      if (selectedEmailArr.length > 0) {
        allEmails = selectedEmailArr.map((item) => {
          return item.value;
        });
      }
      if (email != "") {
        allEmails = [...allEmails, email];
      }
      if ((mailForComment.id > 0 || mailForComment.comment_id) > 0) {
        const id = mailForComment.id || mailForComment.comment_id;
        const content = mailContent || mailForComment.content;

        if (!mailSubject.length) {
          showError("l_subject_is_required");
        } else if (!content.length) {
          showError("l_content_required");
        } else {
          setLoder(true);

          CustomerServices.sendCommentMailNotification(
            allEmails.concat(tags),
            data.rel_id,
            data.id,
            id,
            content,
            mailSubject,
            docType,
            attachmentData.attachments
          )
            .then((res) => {
              setLoder(false);
              if (res.status) {
                setEmail("");
                setSelectedEmailArr([]);
                setMailContent("");
                setMailSubject("");
                dispatch(showMessage("sucess", _l("l_success"), res.message));
              } else {
                dispatch(showMessage("unsucess", _l("l_error"), res.message));
              }
            })
            .finally(() => {
              handleClose();
            });
        }
      } else {
        setLoder(true);
        CustomerServices.sendEmailMessage( 
          data.id,
          data.is_app_invoice == 1 ? `app_${docType}`  : data.is_app_credit_note == 1 ? "app_creditnote"  : docType,
          link,
          docType === "documents" ? tags : allEmails.concat(emails),
           isInternalShared ? 0 : 1 ,          
        ).then((res) => {
          setLoder(false);
          if (res.status) {
            setEmail("");
            setSelectedEmailArr([]);
            dispatch(showMessage("sucess", _l("l_success"), res.message));
            handleClose();
          } else {
            dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
        });
      }
    }
  };

  const projectlist = [
    {
      value: "1",
      label: "examplemail@gmail.com",
    },
    {
      value: "2",
      label: "examplemail@gmail.com",
    },
    {
      value: "3",
      label: "examplemail@gmail.com",
    },
    {
      value: "4",
      label: "examplemail@gmail.com",
    },
    {
      value: "5",
      label: "examplemail@gmail.com",
    },
    {
      value: "6",
      label: "examplemail@gmail.com",
    },
    {
      value: "7",
      label: "examplemail@gmail.com",
    },
    {
      value: "8",
      label: "examplemail@gmail.com",
    },
    {
      value: "9",
      label: "examplemail@gmail.com",
    },
    {
      value: "10",
      label: "examplemail@gmail.com",
    },
    {
      value: "11",
      label: "examplemail@gmail.com",
    },
    {
      value: "12",
      label: "examplemail@gmail.com",
    },
    {
      value: "13",
      label: "examplemail@gmail.com",
    },
    {
      value: "14",
      label: "examplemail@gmail.com",
    },
    {
      value: "15",
      label: "examplemail@gmail.com",
    },
    {
      value: "16",
      label: "examplemail@gmail.com",
    },
  ];

  const assignroleoption = [
    { value: "1", label: "role - 1" },
    { value: "2", label: "role - 2" },
    { value: "3", label: "role - 3" },
  ];

  const preparePreview = (files, remove = false) => {
    let previewArray = [];
    Array.from(files).map((img) => {
      previewArray.push({ name: img.name, url: URL.createObjectURL(img) });
    });

    if (remove) {
      setAttachmentData({
        ...attachmentData,
        attachmentsPreviews: previewArray,
      });
    } else {
      {
        setAttachmentData({
          ...attachmentData,
          attachmentsPreviews:
            attachmentData.attachmentsPreviews.concat(previewArray),
          attachments: attachmentData.attachments
            ? Array.from(attachmentData.attachments).concat(Array.from(files))
            : files,
        });
      }
    }
  };

  const removeAttachment = (attachment) => {
    setAttachmentData({
      ...attachmentData,
      attachments: Array.from(attachmentData.attachments).filter(
        (w) => w.name != attachment.name
      ),
      attachmentsPreviews: attachmentData.attachmentsPreviews.filter(
        (w) => w.name != attachment.name
      ),
    });
  };

  const selectFiles = (event, flag = "") => {
    const extensions = [
      "png",
      "jpg",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "txt",
      "jpeg",
    ];
    if (event.target.files) {
      for (let file = 0; file < event.target.files.length; file++) {
        let fileExtension = event.target.files[file].name.split(".").pop();
        let fileSize = event.target.files[file].size;

        if (extensions.indexOf(fileExtension) == -1) {
          showError("l_file_not_supported");
          return false;
        }

        if (fileSize > 10485760) {
          showError("l_please_upload_maximum_10mb");
          return false;
        }
      }
    }
    preparePreview(event.target.files, false, flag);
  };

  const dispatch = useDispatch();

  const handleAddEmail = () => {
    if (email.trim() !== '' && emailRegex.test(email.trim())) {
      // Add the current email to the emails array
      setEmails([...emails, email.trim()]);
      setEmail(''); // Clear the email input field after adding
    }else{
      setEmail(''); // Clear the email input field after adding
    }
  };

  const parseEmailsFromText = (text) => {
    const extractedEmails = [];
    const emailList = text.split(/[,\s]+/); // Split text by commas or spaces

    emailList.forEach((email) => {
      const trimmedEmail = email.trim();
      if (trimmedEmail !== '' && emailRegex.test(trimmedEmail)) {
        extractedEmails.push(trimmedEmail);
      }
    });

    return extractedEmails;
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text/plain');
    setEmail(pastedText)
    const parsedEmails = parseEmailsFromText(pastedText);

    if (parsedEmails.length > 0) {
      setEmails([...emails, ...parsedEmails]);
      setEmail("")
    }
  };

  const removeMailID = (mail) => {
    setEmails(emails.filter((w)=> w !== mail))
  }
  
  return (
    <>
      <div className="custom-modal-style add-topic-search">
        <Modal
          show={show}
          size="lg"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {title} {_l("l_share_link_modal")}
            </Modal.Title>
          </Modal.Header>
          <div className="d-none">
            <Modal.Body className="py-0 comman-content-scroll-wrapper comman_vertical_btn_h">
              <div className="form-wrapper-main h-100">
                <div className="col-xl-12 h-100 custom-accordian-main accordion-body-mt0 d-none">
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{_l("l_get_link")}</Accordion.Header>
                      <Accordion.Body>
                        <div className=" p-0 bg-transparent mb-3 comman-content-scroll-wrapper">
                          <div className="w-100 comman-contnet-wrapper p-15 border-top-0 comman-content-scroll">
                            <div className="row">
                              <div className="col-xl-9 selection">
                                <Select
                                  className="custom-select-menu select-modal"
                                  classNamePrefix="react-select"
                                  options={projectlist}
                                  isMulti
                                  closeMenuOnSelect={false}
                                />
                              </div>
                              <div className="col-xl-3">
                                <Select
                                  className="custom-select-menu"
                                  classNamePrefix="react-select"
                                  options={assignroleoption}
                                />
                              </div>
                            </div>
                            <div className=" title-fonts p-15 fw-semibold border-bottom">
                              {_l("l_follower")}
                            </div>
                            <div className="row p-15">
                              <div className="col-xl-6">
                                <div className="d-flex align-items-center">
                                  <div className="c-list-icon">
                                    <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('https://source.unsplash.com/random')`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                                    Natasha MYR
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 ">
                                <div className="d-flex image-border-none border w-100 radius_3">
                                  <span className="form-control w-auto border-0 pe-0">
                                    <GearSixIcon
                                      className="HW18"
                                    />
                                  </span>
                                  <Select
                                    className="custom-select-menu border-0 flex-grow-1"
                                    classNamePrefix="react-select"
                                    options={assignroleoption}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-3">
                                <div className="d-flex image-border-none border w-100 radius_3">
                                  <span className="form-control w-auto border-0 pe-0">
                                    <BellIcon
                                      className="HW18"
                                    />
                                  </span>
                                  <Select
                                    className="custom-select-menu border-0 flex-grow-1"
                                    classNamePrefix="react-select"
                                    options={assignroleoption}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="title-fonts fw-semibold border-bottom p-15 pt-0">
                              {_l("l_assignees")}
                            </div>
                            <div className="row p-15">
                              <div className="col-xl-6">
                                <div className="d-flex align-items-center">
                                  <div className="c-list-icon">
                                    <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                                      <div
                                        className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                        style={{
                                          backgroundImage: `url('https://source.unsplash.com/random')`,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                                    Kelly Floch
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3 ">
                                <div className="d-flex image-border-none border w-100 radius_3">
                                  <span className="form-control w-auto border-0 pe-0">
                                    <GearSixIcon
                                      className="HW18"
                                    />
                                  </span>
                                  <Select
                                    className="custom-select-menu border-0 flex-grow-1"
                                    classNamePrefix="react-select"
                                    options={assignroleoption}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-3">
                                <div className="d-flex image-border-none border w-100 radius_3">
                                  <span className="form-control w-auto border-0 pe-0">
                                    <BellIcon
                                      className="HW18"
                                    />
                                  </span>
                                  <Select
                                    className="custom-select-menu border-0 flex-grow-1"
                                    classNamePrefix="react-select"
                                    options={assignroleoption}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="c-input-box position-relative">
                              <textarea
                                className="form-control"
                                placeholder="Enter description"
                                rows="4"
                                type="number"
                                name=""
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {" "}
                        {_l("l_add_people")}{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="p-0 bg-transparent mb-0  p-15">
                          <div className="w-100 comman-contnet-wrapper p-15 border-top-0 comman-content-scroll">
                            <div className="row border-bottom m-0 pb-3">
                              <div className="col-11 p-0">
                                <span className="form-control border-0">
                                  http://192.168.1.221/myrai-web/orb/shared_task/get/243a6fe502eb80b1fb9f311d7d0693b6
                                </span>
                              </div>
                              <div className="col-xl-1 pe-0">
                                <a href="#/" className="d-flex align-items-center justify-content-center form-control">
                                  <CopyIcon
                                    className="HW18"
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="d-flex justify-content-between align-items-center border-bottom p-15">
                                <div className="d-flex align-items-center">
                                  <div className="c-list-icon">
                                    <div className="h35w35 comman-round-box with-bg d-flex align-items-center ">
                                      <a href="#/" className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle justify-content-center d-flex align-items-center">
                                        <LinkSimpleIcon
                                          className="HW24"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                                    Natasha MYR
                                  </div>
                                </div>
                                <div className="input-check">
                                  <div className="form-check form-switch d-flex justify-content-end align-items-center">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row p-15 pb-0">
                              <div className="col-xl-9 d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="c-list-icon">
                                    <div className="h30w30 comman-round-box with-bg d-flex align-items-center">
                                      <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex align-items-center justify-content-center">
                                        <GlobeIcon
                                        className="HW24"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="c-list-detail c-font f-14 color-white-60 w100minus20 text-truncate ps-2">
                                    {_l(
                                      "l_anyone_on_the_internet_with_this_link_can"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-3">
                                <Select
                                  className="custom-select-menu"
                                  classNamePrefix="react-select"
                                  options={assignroleoption}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="row d-none">
                  <div className="col-xl-12 h-100">
                    <div className="row pb-3">
                      <div className="col-xl-10">
                        <div className="d-flex form-control bg-transparent p-0">
                          <input
                            type="text"
                            placeholder="add user and groups"
                            className="form-control border-0 rounded-0 flex-grow-1"
                          />
                          <div className="selection border-0 w-25">
                            <Select
                              className="custom-select-menu select-modal border-0"
                              classNamePrefix="react-select"
                              options={projectlist}
                              isMulti
                              closeMenuOnSelect={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <Button
                          className="bg-white-05 w-100 h-100 color-white"
                          variant=""
                        >
                          {_l("l_send_invite")}{" "}
                        </Button>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-xl-10 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center">
                              <div className="comman-bg-img h-100 w-100 bg-style-cover d-flex align-items-center justify-content-center">
                                <GlobeIcon
                                className="HW24"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="c-list-detail c-font f-14 color-white-60 w100minus20 text-truncate ps-2">
                            {_l("l_anyone_on_the_internet_with_this_link_can")}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <Select
                          className="custom-select-menu with-filter-select min-w-auto me-0"
                          classNamePrefix="react-select"
                          options={assignroleoption}
                        />
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-xl-10">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('https://source.unsplash.com/random')`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                            Natasha MYR
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 d-flex justify-content-end align-items-center">
                        <span className="title-fonts">{_l("l_owner")} </span>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-xl-10">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('https://source.unsplash.com/random')`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                            Natasha MYR
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <Select
                          className="custom-select-menu with-filter-select min-w-auto me-0"
                          classNamePrefix="react-select"
                          options={assignroleoption}
                        />
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-xl-10">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('https://source.unsplash.com/random')`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                            Natasha MYR
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <Select
                          className="custom-select-menu with-filter-select min-w-auto me-0"
                          classNamePrefix="react-select"
                          options={assignroleoption}
                        />
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-xl-10">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('https://source.unsplash.com/random')`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                            Natasha MYR
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <Select
                          className="custom-select-menu with-filter-select min-w-auto me-0"
                          classNamePrefix="react-select"
                          options={assignroleoption}
                        />
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-xl-10">
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h30w30 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 p-1">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{
                                  backgroundImage: `url('https://source.unsplash.com/random')`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                            Natasha MYR
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2">
                        <Select
                          className="custom-select-menu with-filter-select min-w-auto me-0"
                          classNamePrefix="react-select"
                          options={assignroleoption}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-end d-none">
              <Button variant="secondary" onClick={handleClose}></Button>
              <Button variant="primary">{_l("l_save")} </Button>
            </Modal.Footer>
            <Modal.Footer className="justify-content-end border-top d-none">
              <div className="row w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="c-list-icon">
                      <div className="h35w35 comman-round-box with-bg d-flex align-items-center ">
                        <a href="#/" className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle justify-content-center d-flex align-items-center">
                          <LinkSimpleIcon
                            className="HW24"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                      {_l("l_copy_link")}
                    </div>
                  </div>
                  <div className="input-check">
                    <a href="#/" className="pe-2">
                      <CalendarIcon className="HW26" />
                    </a>
                    <a>
                      <GoogleLogoIcon className="HW26" />
                    </a>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </div>
          <Modal.Body>
            <div className="row m-0">
              <div className="col-xl-12">
                <Form.Group className="col-12 c-input-box pb-4 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_select_employee")}
                  </Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    className="custom-select-menu max-h95 multi-select-with-scroll"
                    classNamePrefix="react-select"
                    options={
                      data.contacts &&
                      data.contacts.map((item) => {
                        return { value: item.email, label: item.full_name };
                      })
                    }
                    placeholder={`${_l("l_select_employee")}`}
                    isMulti
                    onChange={(value) => {
                      setSelectedEmailArr(value);
                    }}
                  />
                </Form.Group>
                {(mailForComment.id > 0 || mailForComment.comment_id) > 0 ? (
                  <React.Fragment>
                    <Form.Group className="col-12 c-input-box pb-4 position-relative mt-2">
                      <Form.Label className="input-label no-asterisk ">
                        {_l("l_recipients")}
                      </Form.Label>
                      <TagInput 
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setTags={setTags}
                        tags={tags}
                      />
                    </Form.Group>
                    <Form.Group className="col-12 c-input-box pb-4 position-relative mt-2">
                      <Form.Label className="input-label no-asterisk ">
                        {_l("l_subject")}
                      </Form.Label>
                      <Form.Control
                        placeholder={`${_l("l_subject")}`}
                        type="text"
                        value={mailSubject}
                        onChange={(e) => {
                          setMailSubject(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col-12 c-input-box pb-4 position-relative mt-2">
                      <Form.Label className="input-label no-asterisk ">
                        {_l("l_content")}
                      </Form.Label>
                      <Form.Control
                        placeholder={`${_l("l_content")}`}
                        as="textarea"
                        rows={4}
                        value={mailContent}
                        onChange={(e) => {
                          setMailContent(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="col-xl-12 flex-grow-1 c-input-box pb-4 position-relative ">
                      <Form.Label className="input-label no-asterisk">
                        {_l("l_attachments")}
                      </Form.Label>
                      <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between p-15">
                          <input
                            className="z-index-3 hidden-input"
                            type="file"
                            onInput={(e) => selectFiles(e)}
                          />
                          <div className="m-auto">
                            <CloudArrowUpIcon
                              className="HW18"
                            />
                            <span className="color-white-60 ps-2">
                              {_l("l_click_to_upload")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="upload-image-preview pt-3 d-flex flex-wrap">
                        <div className="d-flex w-100">
                          {attachmentData.attachmentsPreviews.map(
                            (x, index) => {
                              let preview = {};
                              preview.url = getPreviewFromURL(x.name);

                              return (
                                <div className="h60w60 me-2" key={index}>
                                  <div className="comman-image-box h40w40 radius_3 upload-image with-margin">
                                    {typeof preview.url === "string" ? (
                                      <div
                                        className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                        style={{
                                          backgroundImage: `url(${x.url})`,
                                        }}
                                        onClick={() =>
                                          window.open(x.url, "_blank")
                                        }
                                         
                                         
                                         
                                      ></div>
                                    ) : (
                                      <div
                                        className="h-100 w-100 d-flex cursor-pointer"
                                        onClick={() =>
                                          window.open(x.url, "_blank")
                                        }
                                        style={{
                                          alignContent: "center",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <preview.url
                                          size={72}
                                          weight="light"
                                          className="c-icons"
                                        />
                                      </div>
                                    )}
                                    <a href="#/"
                                      className="upload-close"
                                      onClick={() => removeAttachment(x)}
                                    >
                                      <XIcon
                                        className="HW10"
                                      />
                                    </a>
                                  </div>
                                  <div className="c-font color-white-60 f-12 text-truncate">
                                    {x.name}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Form.Group>
                  </React.Fragment>
                ) : docType !== "documents" ? (
                  <>
                  <Form.Group className="col-12 c-input-box  position-relative mt-2">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_email_address_label")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_email_address_label")}`}
                      type="text" 
                      value={email}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleAddEmail();
                        }
                      }}
                      onChange={(e) => {
                          setEmail(e.target.value);                        
                      }}
                      onPaste={handlePaste}
                      onBlur={(e)=>{handleAddEmail()}}
                    />
                  </Form.Group>
                  <div className="pb-4">
                  {emails && emails.length > 0 && 
                  emails.map((mail,index)=>{
                    return(<>
                    <div
                      key={index}
                      className='badge c-font f-12 p-2 rounded-pill me-1 mt-2'
                    >
                      {mail}
                      <a href="#/" className='ps-1' onClick={() => removeMailID(mail)}>
                        <XIcon className="HW10" />
                      </a>
                    </div>
                    </>)
                  })}
                  </div>
                  </>
                ) : <></>
                }
                {
                  docType === "documents"
                  ? <Form.Group className="col-12 c-input-box pb-4 position-relative mt-2">
                      <Form.Label className="input-label no-asterisk ">
                        {_l("l_recipients")}
                      </Form.Label>
                      <TagInput
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setTags={setTags}
                        tags={tags}
                        conditionType={"email"}
                      />
                    </Form.Group>
                  : <></> 
                }

          {
            data.shared_emails && !data.shared_emails.length > 0 ?
              <>
                <div className="chat-u-name text-truncate mb-10">{_l("l_emails_with_access")}</div>
                <Form.Group className="col-12 overflowscroll d-flex flex-column gap10">
                  {
                    data.shared_emails.map((email) => {
                      return (
                        <div className="d-flex align-items-center">
                          <div className="c-list-icon">
                            <div className="h40w40 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05 comman-image-box">
                              <div
                                className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                style={{ backgroundImage: `url(${email.profile_image_url})` }}
                              ></div>
                            </div>
                          </div>
                          <div className="ps-2 w100minus40 title-fonts d-flex  flex-column">
                            <div className="c-list-detail c-font f-14  fw-semibold text-truncate color-white">
                             {email.full_name}
                            </div>
                            <div className="c-font f-10 color-white-60 text-truncate">
                              {email.email}
                            </div>
                          
                          </div>
                        </div>
                      )
                    })
                  }
                </Form.Group>
              </>
            : <></> 
          }

            <Form.Group className="col-5 c-input-box pb-4 pt-4 position-relative">
              <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="chat-u-name text-truncate">{_l("l_anyone_can_access_with_link")}</div>
                  <div className="d-flex align-items-cente">
                    <div className="topic-switch d-flex align-items-center">
                      <div className="form-check form-check-inline m-0 ps-2">
                        <Form.Check
                          className="with-no-label-space"
                          type="switch"
                          role="switch"
                          checked={isInternalShared}
                          onChange={(e) => {
                            setIsInternalShared(!isInternalShared)
                          }}
                        />
                        <label
                          className="form-check-label p-0"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form.Group>

            <div className="p-0 bg-transparent mb-0  p-15">
              {link ? (
                <div className="row border-bottom m-0 pb-3">
                  <div className="col-11 p-0">
                    <Form.Control
                      placeholder={_l("l_link")}
                      aria-label={_l("l_link")}
                      aria-describedby="basic-addon1"
                      className="flex-grow-1"
                      value={link}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-xl-1 pe-0">
                    <a href="#/"
                      className="d-flex align-items-center justify-content-center form-control"
                      onClick={() => {
                        navigator.clipboard.writeText(link);
                        dispatch(
                          showMessage(
                            "sucess",
                            _l("l_success"),
                            _l("l_link_copied")
                          )
                        );
                        handleClose();
                      }}
                    >
                      <CopyIcon className="HW18" />
                    </a>
                  </div>
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <div className="row m-0 d-none">
                <div className="d-flex justify-content-between align-items-center  p-15">
                  <div className="d-flex align-items-center">
                    <div className="c-list-icon">
                      <div className="h35w35 comman-round-box with-bg d-flex align-items-center ">
                        <a href="#/" className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle justify-content-center d-flex align-items-center">
                          <LinkSimpleIcon
                            className="HW24"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="c-list-detail c-font f-14 w100minus20 text-truncate ps-2">
                      Natasha MYR
                    </div>
                  </div>
                  <div className="input-check">
                    <div className="form-check form-switch d-flex justify-content-end align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button
              variant="primary"
              disabled={loder}
              size="sm"
              onClick={() => {
                handleAction();
              }}
            >
              {loder ? _l("l_please_wait") : _l("l_send_email")}
              {loder && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2`}
                  aria-hidden="true"
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default TaskShareLinkModal;
