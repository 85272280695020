import React, { useEffect, useState } from "react";
import 'intro.js/introjs.css';
import "react-datepicker/dist/react-datepicker.css";
import { _l, } from "../../hooks/utilities";
import CustomGoogleCalendarIcon from '../../assets/icons/CustomGoogleCalendarIcon';
import CustomGoogleIcon from '../../assets/icons/CustomGoogleIcon';
import CustomOutlookCalendarIcon from '../../assets/icons/CustomOutlookCalendarIcon';
import CustomOutlookIcon from '../../assets/icons/CustomOutlookIcon';
import Form from "react-bootstrap/Form";
import CustomerServices from "../../services/customer-services";
import { leftPanelFilters, projectTaskCounts } from "../../actions/customer";
import { useSelector, useDispatch } from "react-redux";
import Email from "../../assets/images/Email.svg";
import Calendar from "../../assets/images/Calendar.svg";
import Document from "../../assets/images/Document.svg";
import outlookCalendar from "../../services/outlook-calendar";
import ProgressBar from 'react-bootstrap/ProgressBar';
import EmailSyncIcon from "../../assets/icons/EmailSyncIcon";
import CalendarSyncIcon from "../../assets/icons/CalendarSyncIcon";
import DocumentSyncIcon from "../../assets/icons/DocumentSyncIcon";
import EmailRevokeIcon from "../../assets/icons/EmailRevokeIcon";
import CalendarRevokeIcon from "../../assets/icons/CalendarRevokeIcon";
import FileRevokeIcon from "../../assets/icons/FileRevokeIcon";
import Button from 'react-bootstrap/Button';
import MailSetting from "../Modals/MailSetting";
import CalendarsModal from "../Modals/CalendarsModal";
import DrivesFilesSetting from "../Modals/DrivesFilesSetting";
import XIcon from "../../assets/icons/XIcon";
import EnvelopeSimpleIcon from "../../assets/icons/EnvelopeSimpleIcon";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import FileTextIcon from "../../assets/icons/FileTextIcon";

const MapTaskRequestList = ({ dashBoardDetails }) => {
    const [calendarModal, setCalendarModal] = useState(false);
    const [DocumentModal, setDocumentModal] = useState(false);
    const dispatch = useDispatch();
    const { taskFilters } = useSelector((state) => state.customer);
    function openPopUpdiscord(w, h) {
        let url = "https://discord.com/api/oauth2/authorize?client_id=1065554211674280027&permissions=8&redirect_uri=" + encodeURIComponent(window.origin) + "&response_type=code&scope=identify%20guilds%20email%20connections%20messages.read%20bot%20applications.commands";
        var left = (window.screen.width - w) / 2;
        var top = (window.screen.height - h) / 4;
        window.open(
            url,
            "center window",
            "resizable = yes, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            top +
            ", left=" +
            left
        );
    }
    const [leftPanelActiveFilters, setLeftActivePanelFilters] = useState([]);
    const meetingProviderToolRevoke = (key) => {
        if (key == "ms_meet") {

            outlookCalendar.oauthUrl('ms_meeting', "", localStorage.getItem('selecteOffice'), 0).then((res) => {
                if (res.message == "oauth_url") {
                    var left = (window.screen.width - 600) / 2;
                    var top = (window.screen.height - 600) / 4;
                    window.open(res.data, "center window", 'resizable = yes, width=600, height=600, top=' + top + ', left=' + left);
                }
            })

        } else if (key == "zoom_meet") {

            outlookCalendar.oauthUrlZoom().then((res) => {
                if (res.message != "access_token_exists") {
                    var left = (window.screen.width - 600) / 2;
                    var top = (window.screen.height - 600) / 4;
                    window.open(res.data, "center window", 'resizable = yes, width=600, height=600, top=' + top + ', left=' + left);
                }
            })

        } else if (key == "google_meet") {

            outlookCalendar.oauthUrlGoogleMeet("", localStorage.getItem('selecteOffice')).then((res) => {
                if (res.message != "access_token_exists") {
                    var left = (window.screen.width - 600) / 2;
                    var top = (window.screen.height - 600) / 4;
                    window.open(res.data, "center window", 'resizable = yes, width=600, height=600, top=' + top + ', left=' + left);
                }
            })

        }
    }
    const gmailOpen = () => {
        CustomerServices.getMailSettings().then((res) => {

            var left = (window.screen.width - 600) / 2;
            var top = (window.screen.height - 600) / 4;
            window.open(res.data, "center window", 'resizable = yes, width=600, height=600, top=' + top + ', left=' + left);

        });
    }
    const [emailSyncBtn, setEmailSyncBtn] = useState (false);
    const [calendarSyncBtn, setCalendarSyncBtn] = useState (false);
    const [documentsSyncBtn, setDocumentsSyncBtn] = useState (false);
    useEffect(() => {
        if (taskFilters) {
            setLeftActivePanelFilters(taskFilters)
        }
    }, [taskFilters])

  const [isEmailSynce, setIsEmailSynce] = useState();
  const [showSyncMailModal, setSyncShowMailModal] = useState(false);
  const [synchronizedMail, setSynchronizedMail] = useState(false);

  

    useEffect(() => {
        CustomerServices.getMailSettings().then((res) => {
          setIsEmailSynce(res && res.status)
          if (res && res.status) {
            res.data.map((item) => {
              if (item["provider"] == "gmail") {
                setSynchronizedMail("gmail");
              } else if (item["provider"] == "outlook_mail") {
                setSynchronizedMail("outlook_mail");
              }
            });
          }
        });
      }, []);
      const mailModalHandleClose = () => setSyncShowMailModal(false);
  const mailModalHandleShow = () => setSyncShowMailModal(true);

    return (
        <>
            <div className="bg-white-03 radius_5 map-task-request-list p-20 h-55">
                <div className="d-flex align-items-center justify-content-between pb-3">
                <div className="title-fonts c-font f-16 fw-semibold text-truncate">{_l("l_Task_request")}</div>
                {leftPanelActiveFilters.length ? (
                    leftPanelActiveFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                            let displayFilterKey = value.title;
                            return (
                                <div
                                    key={index}
                                    className="badge  c-font f-12 w-auto color-white-80 bg-white-05  radius_3 p-1 comman-round-box active"
                                >
                                    <div className="d-flex align-items-center">
                                        <span className="color-white">
                                            {displayFilterKey}
                                        </span>
                                        <a href="#/"
                                            className="ps-1"
                                            onClick={() => {
                                                dispatch(
                                                    leftPanelFilters({
                                                        main_key: value.main_key,
                                                        filter_key: value.filter_key,
                                                        title: value.title,
                                                        main_sub_key: value.main_sub_key,
                                                    })
                                                );
                                                dispatch(
                                                    projectTaskCounts(localStorage.getItem('selectedOffice'), taskFilters, {
                                                        main_key: value.main_key,
                                                        filter_key: value.filter_key,
                                                        title: value.title,
                                                    }, 0, 'dashboard')
                                                );
                                            }}
                                        >
                                            <XIcon className="HW14" />
                                        </a>
                                    </div>
                                </div>
                            );
                        }
                    })
                ) : (
                    <></>
                )}
                </div>
                <div className="bg-white-03 task-request-Progress radius_3 mt-3">
                <span className="bg-warning" style={{  width: dashBoardDetails.request_percentage ? dashBoardDetails.request_percentage + "%" : 0 + "%" }}></span>
                    <span className="bg-todo-calendar" style={{  width: dashBoardDetails.todo_percentage ? dashBoardDetails.todo_percentage + "%" : 0 + "%" }}></span>
                    <span className="bg-blue" style={{ width: dashBoardDetails.inprogress_percentage ? dashBoardDetails.inprogress_percentage + "%" : 0 + "%" }}></span>
                    <span className="bg-gradient-green" style={{  width: dashBoardDetails.done_percentage ? dashBoardDetails.done_percentage + "%" : 0 + "%" }}></span>
                </div>
                <div className="d-flex align-items-center pb-3 pt-2">
                    <div className="title-fonts c-font f-12 color-white-80 text-truncate ">{_l("l_overall_progress")} & {_l("l_workspace_score")}</div>
                    <div className="progress-slider flex-grow-1 ps-2">
                        <div className="d-flex align-items-center">
                            <ProgressBar now={dashBoardDetails && dashBoardDetails.workspace_score ? dashBoardDetails.workspace_score : 0} />
                            <span className="title-fonts color-green ps-2">{dashBoardDetails && dashBoardDetails.workspace_score ? dashBoardDetails.workspace_score : 0}%</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap w-100 comman_vertical_btn_h vertical_scroll135">
                    <div className="d-flex map-task-box ps-0 align-items-center border-right border-bottom wih-before-box">
                        <div className="comman_action_icon me-3 d-flex">
                            <div className="color-white-60 action_icon with_bg d-flex h30w30 m-auto">
                                <div className="d-flex align-items-center m-auto">
                                    <span className="c-dots c-10 warning rounded-circle"></span>
                                </div>
                            </div>
                        </div>
                        <div className="card-list-heading text-truncate">
                            <div className="title-fonts c-font f-20 line-height-1 text-truncate text-warning fw-semibold"> {dashBoardDetails.requested_task ? dashBoardDetails.requested_task : 0}</div>
                            <div className="c-font title-fonts color-white-80 f-14 text-truncate pt-1">
                                <span className="hover-span">{_l("l_request")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex map-task-box ps-0 align-items-center border-bottom">
                        <div className="comman_action_icon me-3 d-flex">
                            <div className="color-white-60 action_icon with_bg d-flex h30w30 m-auto">
                                <div className="d-flex align-items-center m-auto">
                                    <span className="c-dots c-10 in-progress-white rounded-circle"></span>
                                </div>
                            </div>
                        </div>
                        <div className="card-list-heading text-truncate">
                            <div className="title-fonts c-font f-20 line-height-1 text-truncate color-white-80 fw-semibold">{dashBoardDetails.not_started_task ? dashBoardDetails.not_started_task : 0}</div>
                            <div className="c-font title-fonts color-white-80 f-14 text-truncate pt-1">
                                <span className="hover-span">{_l("l_not_started")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex map-task-box ps-0 align-items-center border-right ">
                        <div className="comman_action_icon me-3 d-flex">
                            <div className="color-white-60 action_icon with_bg d-flex h30w30 m-auto">
                                <div className="d-flex align-items-center m-auto">
                                    <span className="c-dots c-10 i in-progress-blue rounded-circle"></span>
                                </div>
                            </div>
                        </div>
                        <div className="card-list-heading text-truncate">
                            <div className="title-fonts c-font f-20 line-height-1 text-truncate color-blue fw-semibold">{dashBoardDetails.on_going_task ? dashBoardDetails.on_going_task : 0}</div>
                            <div className="c-font title-fonts color-white-80 f-14 text-truncate pt-1">
                                <span className="hover-span">{_l("l_inprogress")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex map-task-box ps-0 align-items-center">
                        <div className="comman_action_icon me-3 d-flex">
                            <div className="color-white-60 action_icon with_bg d-flex h30w30 m-auto">
                                <div className="d-flex align-items-center m-auto">
                                    <span className="c-dots c-10 done rounded-circle"></span>
                                </div>
                            </div>
                        </div>
                        <div className="card-list-heading text-truncate">
                            <div className="title-fonts c-font f-20 line-height-1 text-truncate color-green fw-semibold">{dashBoardDetails.completed_task ? dashBoardDetails.completed_task : 0}</div>
                            <div className="c-font title-fonts color-white-80 f-14 text-truncate pt-1">
                                <span className="hover-span">{_l("l_completed")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap mt-4 d-none">

                    <div className="map-task-box pe-2">
                        <div className="bg-white-03 radius_5 p-10">
                            <div className="title-fonts c-font f-16 fw-semibold text-truncate">{_l("l_email")}</div>
                        </div>
                    </div>
                    <div className="map-task-box">
                        <div className="bg-white-03 radius_5 p-10">
                            <div className="title-fonts c-font f-16 fw-semibold text-truncate">{_l("l_meeting_room")}</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap mt-2 d-none">
                    <div className="map-task-box pb-2 pe-2">
                        <div className="bg-white-03 p-10 radius_3 ">
                            <div className="d-flex align-items-center">

                                <div className="comman_action_icon me-3">
                                    <div className="h30w30 align-items-center d-flex" onClick={() => {
                                        gmailOpen()
                                    }}> <CustomGoogleIcon className="m-auto" /> </div>
                                </div>
                                <div className='w100minus100per flex-grow-1 text-truncate title-fonts c-font f-14' onClick={() => {
                                    gmailOpen()
                                }}>{_l("gmail")}</div>

                                <Form.Check
                                    type="switch"
                                    id=""
                                    label=""
                                    className="with-no-label-space"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="map-task-box pb-2">
                        <div className="bg-white-03 p-10 radius_3">
                            <div className="d-flex align-items-center">
                                <div className="comman_action_icon me-3" onClick={() => { meetingProviderToolRevoke('google_meet') }}>
                                    <div className="h30w30 align-items-center d-flex">
                                        <CustomGoogleCalendarIcon className="m-auto" />
                                    </div>
                                </div>
                                <div className='w100minus100per flex-grow-1 text-truncate title-fonts c-font f-14' onClick={() => { meetingProviderToolRevoke('google_meet') }}>{_l("l_google_calendar")}</div>
                                <Form.Check
                                    type="switch"
                                    id=""
                                    label=""
                                    className="with-no-label-space"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="map-task-box pb-2 pe-2">
                        <div className="bg-white-03 p-10 radius_3">
                            <div className="d-flex align-items-center">
                                <div className="comman_action_icon me-3" onClick={() => {
                                    meetingProviderToolRevoke('zoom_meet')
                                }}>
                                    <div className="h30w30 align-items-center d-flex"  ><CustomOutlookIcon className="m-auto" /> </div>
                                </div>
                                <div className='w100minus100per flex-grow-1 text-truncate title-fonts c-font f-14' onClick={() => {
                                    meetingProviderToolRevoke('zoom_meet')
                                }}>{_l("l_outlook")}</div>

                                <Form.Check
                                    type="switch"
                                    id=""
                                    label=""
                                    className="with-no-label-space"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="map-task-box pb-2">
                        <div className="bg-white-03 p-10 radius_3">
                            <div className="d-flex align-items-center">
                                <div className="comman_action_icon me-3" onClick={() => {
                                    openPopUpdiscord(600, 600);

                                }}>
                                    <div className="h30w30 align-items-center d-flex">
                                        <CustomOutlookCalendarIcon className="m-auto" />
                                    </div>
                                </div>
                                <div className='w100minus100per flex-grow-1 text-truncate title-fonts c-font f-14' onClick={() => {
                                    openPopUpdiscord(600, 600);

                                }}>{_l("l_outlook_calendar")}</div>
                                <Form.Check
                                    type="switch"
                                    id=""
                                    label=""
                                    className="with-no-label-space"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="p-10 d-flex align-items-center h-45 px-0 pb-0 ">
                <div className="main-card-list w-100 h-100 d-flex">
                    <a href="#/" className="bg-white-03 radius_5 card-list p-20 with-width-calc d-flex flex-column flex-grow-1 h-100"  >
                        <div className="d-flex justify-content-between card-list-top ">
                            <div className="d-flex ">
                                <div className="comman_action_icon me-3">
                                    <div className="h40w40  with-bg d-flex bg-base-header-color p-5px box-shadow-2 radius_3">
                                    <EnvelopeSimpleIcon className="HW24" /> 
                                    </div>
                                </div>
                                <div class="card-list-heading text-truncate">
                                    <div class="title-fonts c-font f-16  text-truncate color-white-80">
                                        <span class="hover-span">
                                       {_l("l_email")}
                                        
                                        </span></div>
                                    <div class= "c-font color-white-60 f-12 text-truncate">
                                        <span class="hover-span">
                                            {_l("l_please_connect_email")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="comman_action_icon  text-end d-flex">
                                <div className={`h35w35  with-bg d-flex bg-base-header-color p-5px box-shadow-2 radius_3 ms-auto z-index-2 ${emailSyncBtn ? "" : "d-none" }`}
                                onClick={()=>{
                                      
                                        setEmailSyncBtn(false);
                                }}>
                                   <EmailRevokeIcon className="HW20" />
                                    </div>
                            </div>
                        </div>
                        <div className=" mt-auto z-index-2 positon-relative">
                            <div class={`d-flex justify-content-between card-list-bottom  ${emailSyncBtn ? "" : "d-none" }`}>
                                <div class="mt-auto card-list-bottom-left w100minus100per flex-grow-1">
                                    <div class="left-team-image-wrapper">
                                        <div class="title-fonts c-font f-12  text-truncate pb-2">
                                        {_l("l_inbox")}
                                        </div>
                                        <div class="multi_image_wrapper">
                                            <div class="multi_inner_wrapper d-flex align-items-center">
                                            16
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ms-auto text-end card-list-bottom-right">
                                    <div class="title-fonts c-font f-30 fw-semibold text-truncate">
                                    26
                                    </div>
                                    <div class="c-font color-white-60 f-14 text-truncate">
                                        <span class="hover-span">
                                            {_l("l_total_email")}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            {isEmailSynce === 0 ?
                            <div className={`comman_action_icon  text-end d-flex ${emailSyncBtn ? "d-none" : "" }`}>
                                <Button variant="secondary" size="sm" className="w-100" onClick={()=>{
                                        setSyncShowMailModal(true);
                                }}>
                                   <EmailSyncIcon className="HW20" />
                                    <span class="hover-span">
                                        {_l("l_email_Sync")}
                                    </span>
                                </Button >
                            </div>
                            :
                            <></>}                            
                            {showSyncMailModal ? (
                                <MailSetting
                                   show={showSyncMailModal}
                                   handleClose={mailModalHandleClose}
                                   handleShow={mailModalHandleShow}
                                   setSynchronizedMail={setSynchronizedMail}
                                 />
                               ) : (
                                   <></>
                             )}
                        </div>
                        <div className="bg-img-bottom-right" style={{
                            backgroundImage: `url('${Email}')`
                        }}></div>
                    </a>
                    <a href="#/" className="bg-white-03 radius_5 card-list p-20 with-width-calc d-flex flex-column flex-grow-1 h-100"  >
                        <div className="d-flex justify-content-between card-list-top ">
                            <div className="d-flex ">
                                <div className="comman_action_icon me-3">
                                    <div className="h40w40  with-bg d-flex bg-base-header-color p-5px box-shadow-2 radius_3">
                                    <CalendarBlankIcon className="HW24" /> 
                                    </div>
                                </div>
                                <div class="card-list-heading text-truncate">
                                    <div class="title-fonts c-font f-16  text-truncate color-white-80">
                                        <span class="hover-span">
                                       {_l("l_calendar")}
                                        
                                        </span></div>
                                    <div class="c-font color-white-60 f-12 text-truncate">
                                        <span class="hover-span">
                                            {_l("l_please_connect_calendar")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="comman_action_icon  text-end d-flex">
                                <div className={`h35w35  with-bg d-flex bg-base-header-color p-5px box-shadow-2 radius_3 ms-auto z-index-2 ${documentsSyncBtn ? "" : "d-none" }`}
                                onClick={()=>{
                                        setDocumentsSyncBtn(false);
                                }}>
                                   <CalendarRevokeIcon className="HW20" />
                                </div>
                            </div>
                        </div>
                        <div className=" mt-auto z-index-2 positon-relative">
                            <div class={`d-flex justify-content-between card-list-bottom  ${documentsSyncBtn ? "" : "d-none" }`}>
                                <div class="mt-auto card-list-bottom-left w100minus100per flex-grow-1">
                                    <div class="left-team-image-wrapper">
                                        <div class="title-fonts c-font f-12  text-truncate pb-2">
                                        {_l("l_today_event")}
                                        </div>
                                        <div class="multi_image_wrapper">
                                            <div class="multi_inner_wrapper d-flex align-items-center">
                                            10
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ms-auto text-end card-list-bottom-right">
                                    <div class="title-fonts c-font f-30 fw-semibold text-truncate">
                                    35
                                    </div>
                                    <div class="c-font color-white-60 f-14 text-truncate">
                                        <span class="hover-span">
                                            {_l("l_total_events")}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div className={`comman_action_icon  text-end d-flex ${documentsSyncBtn ? "d-none" : "" }`}>
                                <Button variant="secondary" size="sm" className="w-100" 
                                onClick={() => {
                                    setCalendarModal(true);
                                  }}
                                >
                                   <CalendarSyncIcon className="comman-icons HW20" />
                                    <span class="hover-span">
                                        {_l("l_calendar_Sync")}
                                    </span>
                                </Button >
                            </div>
                        </div>
                        <div className="bg-img-bottom-right" style={{
                            backgroundImage: `url('${Calendar}')` }}></div>
                    </a>
                    <a href="#/" className="bg-white-03 radius_5 card-list p-20 with-width-calc d-flex flex-column flex-grow-1 h-100"  >
                        <div className="d-flex justify-content-between card-list-top ">
                            <div className="d-flex ">
                                <div className="comman_action_icon me-3">
                                    <div className="h40w40  with-bg d-flex bg-base-header-color p-5px box-shadow-2 radius_3">
                                    <FileTextIcon className="HW24" />
                                    </div>
                                </div>
                                <div class="card-list-heading text-truncate">
                                    <div class="title-fonts c-font f-16  text-truncate color-white-80">
                                        <span class="hover-span">
                                       {_l("l_documents")}
                                        
                                        </span></div>
                                    <div class="c-font color-white-60 f-12 text-truncate">
                                        <span class="hover-span">
                                            {_l("l_please_connect_documents")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="comman_action_icon  text-end d-flex">
                                <div className={`h35w35  with-bg d-flex bg-base-header-color p-5px box-shadow-2 radius_3 ms-auto z-index-2 ${calendarSyncBtn ? "" : "d-none" }`}
                                onClick={()=>{
                                        setCalendarSyncBtn(false);
                                }}>
                                   <FileRevokeIcon className="HW20" />
                                </div>
                            </div>
                        </div>
                        <div className=" mt-auto z-index-2 positon-relative">
                            <div class={`d-flex justify-content-between card-list-bottom  ${calendarSyncBtn ? "" : "d-none" }`}>
                                <div class="mt-auto card-list-bottom-left w100minus100per flex-grow-1">
                                    <div class="left-team-image-wrapper">
                                        <div class="title-fonts c-font f-12  text-truncate pb-2">
                                        {_l("l_new_document")}
                                        </div>
                                        <div class="multi_image_wrapper">
                                            <div class="multi_inner_wrapper d-flex align-items-center">
                                            12
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ms-auto text-end card-list-bottom-right">
                                    <div class="title-fonts c-font f-30 fw-semibold text-truncate">
                                    20
                                    </div>
                                    <div class="c-font color-white-60 f-14 text-truncate">
                                        <span class="hover-span">
                                            {_l("l_total_documents")}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div className={`comman_action_icon  text-end d-flex ${calendarSyncBtn ? "d-none" : "" }`}>
                                <Button variant="secondary" size="sm" className="w-100" 
                                onClick={() => {
                                    setDocumentModal(true);
                                  }}
                                >
                                   <DocumentSyncIcon className="comman-icons HW20" />
                                    <span class="hover-span">
                                        {_l("l_documents_Sync")}
                                    </span>
                                </Button >
                            </div>
                        </div>
                        <div className="bg-img-bottom-right" style={{
                            backgroundImage: `url('${Document}')` }}></div>
                    </a>
                </div>
            </div>
            {
                calendarModal ? (
                <CalendarsModal
                show={calendarModal}
                handleClose={() => setCalendarModal(false)}
                handleShow={setCalendarModal}
              />
                ) : (<></>) 
            }
            {
                DocumentModal ? (
                <DrivesFilesSetting
                    show={DocumentModal}
                    handleClose={() => setDocumentModal(false)}
                    handleShow={setDocumentModal}
                />
                ) : (<></>) 
            }
        </>
    );
};
export default MapTaskRequestList;