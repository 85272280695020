import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { _l, insertProviderPageDataToIDB } from "../../hooks/utilities";
import customerServices from "../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import { getProviderListTableData } from "../../actions/customer";

const AddEditContactProvider = ({ show, handleClose, data = {}, isEdit=false, setTabCounts}) => {
    const dispatch = useDispatch();
    const { providerlistTableData } = useSelector((state) => state.customer);

    const initialInput = {
        first_name: "",
        last_name: "",
        phone_number: "",
        country_code: "+33",
        email: "",
        contact_role : 1
    }

    const [numberFormate, setNumberFormate] = useState('');
    const [numberlength, setNumberLength]= useState({max : 14, min : 9});
    const [contactInput, setContactInput] = useState(initialInput);
    const [loader, setLoader] = useState(false)

    let providerID = localStorage.getItem("currentCompanyID")
    let client_id = localStorage.getItem("client_id") 

    useEffect(() => {
        if (numberFormate.dialCode) {
            setContactInput({...contactInput, country_code : `+${numberFormate.dialCode}`});
            const dialcodeL = Number(numberFormate.dialCode.length);
            let digitlength = numberFormate.format.match(/\./g).slice(numberFormate.dialCode.length);
            if (["32","352", "49", "44"].includes(numberFormate.dialCode)) {
                if (["49", "44"].includes(numberFormate.dialCode)) {
                    setNumberLength({max : 11 + dialcodeL, min : 10 + dialcodeL})
                }else{
                    setNumberLength({max : 10 + dialcodeL, min : 9 + dialcodeL})
                }
            }else{
                setNumberLength({max : digitlength.length + dialcodeL, min : digitlength.length + dialcodeL})
            }
        }
    }, [numberFormate]);
    
    const fieldValidations = () => {
        const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        let flag = true;
        if (!contactInput.first_name || !contactInput.first_name.length) {
            flag = false;
            dispatch(showMessage("unsucess",_l("l_error"),_l("l_please_enter_first_name")));
        } else if (!contactInput.last_name || !contactInput.last_name.length) {
            flag = false;
            dispatch(showMessage("unsucess",_l("l_error"),_l("l_please_enter_last_name")));
        } else if (contactInput.email.trim() == "") {
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_email")));
            flag = false;
        } else if (!pattern.test(contactInput.email.trim())) {
            dispatch(showMessage("unsucess",_l("l_error"),_l("l_please_enter_valid_email")));
            flag = false;
        }
        //  else if (
        //   !contactInput.phone_number ||
        //   !contactInput.phone_number.length ||
        //   (numberlength.max != contactInput.phone_number.length &&
        //     numberlength.min != contactInput.phone_number.length)
        // ) {
        //   dispatch(
        //     showMessage(
        //       "unsucess",
        //       _l("l_error"),
        //       _l("l_please_enter_valid_mobile_number")
        //     )
        //   );
        //   flag = false;
        // }
        return flag;
    };

    const addEmployeeLease = () => {
        if (!fieldValidations()) {
            return false
        }
        try {
            setLoader(true);
            let contact_mobile = `${contactInput.country_code} ${contactInput.phone_number.slice(contactInput.country_code.length - 1)}`
            customerServices.addEditProfileCntact(localStorage.getItem('currentCompanyID'), contactInput.first_name, contactInput.last_name, contact_mobile, contactInput.email, isEdit ? contactInput.contact_role : 1, isEdit, isEdit ? data.staffid : '').then((res) => {
                if (res && res.status) {
                    if (isEdit) {
                        const updateContactData = providerlistTableData.contact.map((item) => {
                            if (item.staffid == data.staffid) {
                                item.email = contactInput.email
                                item.firstname = contactInput.first_name
                                item.lastname = contactInput.last_name
                                item.phone_number = contact_mobile
                                item.fullname = `${contactInput.first_name} ${contactInput.last_name}`
                            }
                            return item;
                        })
                        dispatch(getProviderListTableData({ ...providerlistTableData, "contact": updateContactData }));
                        insertProviderPageDataToIDB("compannyDeatails",{ ...providerlistTableData, "contact": updateContactData } , providerID, client_id )
                    }else{
                        if (res.data) {
                            const newContactData = [{
                                staffid: res.data.staff_id,
                                email: res.data.email,  
                                firstname: res.data.firstname,
                                lastname: res.data.lastname,
                                phone_number: res.data.phone_number,
                                profile_image: res.data.profile_image,
                                fullname: res.data.fullname,
                                role: res.data.role,
                                role_detail: res.data.role_detail,
                                task_score : 0,
                                total_task : 0,
                                userid : providerID
                            }]
                            dispatch(getProviderListTableData({...providerlistTableData, "contact": newContactData.concat(providerlistTableData.contact) }));
                            insertProviderPageDataToIDB("compannyDeatails",{...providerlistTableData, "contact": newContactData.concat(providerlistTableData.contact) } , providerID, client_id );
                            setTabCounts((prevState) => ({ ...prevState, Contact: parseInt(prevState.Contact) + 1}));   // Increase count
                        }
                    }
                    setLoader(false);
                    handleClose();
                    dispatch(showMessage("sucess",_l("l_sucess"),_l(res.message)));
                }else{
                    setLoader(false);
                    dispatch(showMessage("unsucess",_l("l_error"),_l(res.message)));
                }
            })
        } catch (error) {
            console.log("error", error);
        }
    }
      
    useEffect(() => {
        if (isEdit) {
            setContactInput({
                first_name: data.firstname ? data.firstname: '',
                last_name: data.lastname ? data.lastname: '',
                country_code: data.phone_number ? data.phone_number.split(" ")[0] : '',
                phone_number: data.phone_number ? data.phone_number : '',
                email: data.email ? data.email :"",
                contact_role : data.role_detail && data.role_detail.role_id ? data.role_detail.role_id : 1
            })
        }
    }, [JSON.stringify(data)])
     
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="custom-modal-style"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>{(isEdit ? _l("l_edit") : _l("l_add")) + ' ' + _l("l_contact")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-0">
                    <div className="form-wrapper-main">
                        <div className="row m-0 border-bottom">
                            <Form.Group className="col-xl-6 c-input-box position-relative  mb-3">
                                <Form.Label className="input-label no-asterisk ">{_l("l_first_name")}</Form.Label>
                                <Form.Control
                                    placeholder={_l("l_first_name")}
                                    aria-label="contactName"
                                    aria-describedby="basic-addon1"
                                    value={contactInput.first_name}
                                    onChange={(e) => {
                                        setContactInput({ ...contactInput, first_name: e.target.value });
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="col-xl-6 c-input-box position-relative mb-3">
                                <Form.Label className="input-label no-asterisk ">{_l("l_last_name")}</Form.Label>
                                <Form.Control
                                placeholder={_l("l_last_name")}
                                aria-label="contactName"
                                aria-describedby="basic-addon1"
                                value={contactInput.last_name}
                                onChange={(e) => {
                                    setContactInput({ ...contactInput, last_name: e.target.value });
                                }}
                                />
                            </Form.Group>
                            <Form.Group className="col-xl-12 c-input-box position-relative mb-3 select-half-separator">
                                <Form.Label className="input-label no-asterisk ">{_l("l_phone")}</Form.Label>
                                <div className="align-items-center d-flex m-0 react-tel-input-small row select-half-separator-main">
                                    <PhoneInput
                                        inputClass="title-fonts f-12"
                                        autoFormat={true}
                                        containerClass="f-32"
                                        disableCountryGuess={true}
                                        countryCodeEditable={false}
                                        disableSearchIcon={true}
                                        enableSearch={true}
                                        enableAreaCodes={true}
                                        enableLongNumbers={true}
                                        localization={""}
                                        country="fr"
                                        preferredCountries={[
                                            "fr",
                                            "de",
                                            "be",
                                            "es",
                                            "ch",
                                            "gb",
                                            "pl",
                                            "lu",
                                        ]}
                                        value={contactInput.phone_number}
                                        placeholder="780 1250 456"
                                        isValid={(value, country)=>{
                                            if (value.length > numberlength.max) {
                                                setContactInput({ ...contactInput, phone_number: value.slice(0,numberlength.max) });
                                            } else {
                                                return true;
                                            }
                                        }}
                                        onChange={(phone, formattedValue) => {
                                            setContactInput({ ...contactInput, phone_number: phone });
                                            setNumberFormate(formattedValue);
                                        }}
                                        inputProps={{
                                            autoFocus: true
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="col-xl-12 c-input-box position-relative mb-3">
                                <Form.Label className="input-label no-asterisk ">{_l("l_email_address_label")}</Form.Label>
                                <Form.Control
                                    placeholder={_l("l_email_address_label")}
                                    aria-label="email"
                                    aria-describedby="basic-addon1"
                                    type="email"
                                    value={contactInput.email}
                                    onChange={(e) => {
                                        setContactInput({ ...contactInput, email: e.target.value });
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-end">
                    <Button variant="secondary" size="sm" onClick={handleClose}>{_l("l_cancel")}</Button>
                    <Button
                        variant="primary"
                        size="sm"
                        disabled={loader}
                        onClick={() => {
                            addEmployeeLease();
                        }}
                    >
                        {loader ? 
                        <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span className="ms-2">{_l("l_please_wait")}</span>
                        </> 
                        : _l("l_submit")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default AddEditContactProvider;
