import React from 'react';
 

function FilePptIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

			<svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`} >
			<path d="M3 12.5H4C4.26522 12.5 4.51957 12.3946 4.70711 12.2071C4.89464 12.0196 5 11.7652 5 11.5C5 11.2348 4.89464 10.9804 4.70711 10.7929C4.51957 10.6054 4.26522 10.5 4 10.5H3V13.5M7 12.5H8C8.26522 12.5 8.51957 12.3946 8.70711 12.2071C8.89464 12.0196 9 11.7652 9 11.5C9 11.2348 8.89464 10.9804 8.70711 10.7929C8.51957 10.6054 8.26522 10.5 8 10.5H7V13.5M13 10.5H10.75M11.875 13.5V10.5M3 8V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5M9.5 2L13 5.5M9.5 2V5.5H13M13 5.5V8" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>

		</React.Fragment>
	)
}

export default FilePptIcon