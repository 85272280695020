import React, { useEffect, useState } from "react";
import UserCirclePlusIcon from "../../assets/icons/UserCirclePlusIcon";
import RequestTaskIcon from "../../assets/icons/RequestTaskIcon";
import EstimateIcon from "../../assets/icons/EstimateIcon";
import Schedule3DScan from "../Modals/Schedule3DScan";
import { _l } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import customerServices from "../../services/customer-services";
import { showMessage } from "../../actions/messages";
import GeneralListing from "../TaskModalComponents/GeneralListing";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import $ from "jquery";
import { getPOPIsList, closeOutsideClick, ModelViewerPoint, getEquimenntsList, editedEquipmentsList, filterFor3Dmodel } from "../../actions/customer";
import ActionDropdown from "../Dropdowns/ActionDropdown";
import { Button } from "react-bootstrap";
import { getFloorDetails } from "../../actions/benchmark-actions/benchmark-actions";
import CommanOffCanvas from "../Offcanvas/CommanOffCanvas";
import CreateTaskRequestOffcanvas from "../Offcanvas/CreateTaskRequestOffcanvas";

const MyspaceTaskRequestWithData = ({setSetUpRoomShow, setOpenFrom, openCreatePOI, setOpenCreatePOI, setSelectedPOI, isDummyModal, dummyPOIs, setDummyPOIs}) => {
  const [showSchedule3DScan, setShowSchedule3DScan] = useState(false);
  const [floorId, setFloorId] = useState('');
  const [POIsList, setPOIsList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [typeOfSpaces, setTypeOfSpaces] = useState([]);
  const [show, setshow] = useState(false);
  const [showEquipment, setshowEquipment] = useState(false);
  const [data ,setData] = useState({
    addedfrom: "",
    assignee_list: {},
    assignee_list_DEV: [],
    assignee_type: "",
    assignees: [],
    assignees_type: {},
    attachment_count: 0,
    atttachments: [],
    checklist: [],
    checklist_progress: "0",
    comments: [],
    custom_recurring: "0",
    dateadded: "",
    description: null,
    duedate: null,
    hash: "",
    id: 0,
    is_added_from_contact: "1",
    is_allowed_to_edit: "1",
    is_pinned: 0,
    is_requested: "1",
    name: "",
    people_id: "0",
    priority: "2",
    provider_id: "0",
    recurring: "0",
    recurring_type: null,
    rel_id: project_id,
    rel_type: "project",
    repeat_every: null,
    request_task_id: "0",
    schedule_assignees: [],
    schedule_id: "0",
    startdate: "",
    status: "1",
    task_creator_image: localStorage.getItem("contact_image"),
    task_creator_name: localStorage.getItem("full_name"),
    unread_comment_count: "0",
    visible_to_client: "1",
    visible_to_provider: "1",
    });
  const [oustSideClickToggle, setOustSideClickToggle] = useState(false);
  const project_id = localStorage.getItem("selectedOffice");
  const [x, setX] = useState('');
  const [y, setY] = useState('');
  const [z, setZ] = useState('');
  const dispatch = useDispatch();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const spaceFloorPlan = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );
  const { setPOILatLng, getSelectedFloorId, getPOIList, outSideClick , get_equipments_list, filterfor3dPlan} = useSelector((state) => state.customer);
  useEffect(() => {
    if (setPOILatLng) {
      setX(setPOILatLng.x);
      setY(setPOILatLng.y);
      setZ(setPOILatLng.z);
    }
    if (getSelectedFloorId > 0) {
      setFloorId(getSelectedFloorId);
      dispatch(getPOPIsList(getSelectedFloorId));
      dispatch(getEquimenntsList(project_id,getSelectedFloorId,1));
    }

  }, [setPOILatLng, getSelectedFloorId])
  useEffect(() => {
    dispatch(getFloorDetails(project_id, 1));
  },[])

  useEffect(() => {
    if (spaceFloorPlan && spaceFloorPlan.type_of_space && spaceFloorPlan.type_of_space.length > 0) {
      setTypeOfSpaces(spaceFloorPlan.type_of_space)
    }
  }, [spaceFloorPlan])
  useEffect(() => {
    if (!isDummyModal && get_equipments_list && get_equipments_list.length > 0) {
      setEquipmentList(get_equipments_list);
    }
  }, [get_equipments_list])
  useEffect(() => {
    if (!isDummyModal) {
      if (getPOIList && getPOIList.length > 0) {
        setPOIsList(getPOIList)
      }else{
        setPOIsList([])
      }
    }
  }, [getPOIList])

  useEffect(() => {
    if (isDummyModal) {
      const dummyEquipmentList = [{
        "id": "5622",
        "equipment_name": "Equipment List",
        "quantity": "0",
        "contact_assignee": "0",
        "provider_assignee": "0",
        "task_priority": "0",
        "quantity_details": [
            {
                "quantity_id": "11781",
                "quantity_name": "Equipment 3",
                "device_id": "",
                "type_of_space_item_id": "0",
                "lifetime": "2024-04-15",
                "floor_id": "1000",
                "x": "0.000000000000",
                "y": "0.000000000000",
                "z": "0.000000000000",
                "poi": "0",
                "lat": "0.000000",
                "long": "0.000000",
                "alt": "0.000000",
                "web_x": "0.000000000000",
                "web_y": "0.000000000000",
                "web_z": "0.000000000000",
                "local_anchors_id": "554",
                "attachments": [
                    {
                        "file_id": 0,
                        "file_name": "",
                        "image_url": "https://myrpp.myr.ai/orb/assets/images/equipment-default-image-placeholder.png"
                    }
                ]
            },
            {
                "quantity_id": "11782",
                "quantity_name": "Equipment 4",
                "device_id": "",
                "type_of_space_item_id": "0",
                "lifetime": "2024-04-15",
                "floor_id": "1047",
                "x": "0.000000000000",
                "y": "0.000000000000",
                "z": "0.000000000000",
                "poi": "0",
                "lat": "0.000000",
                "long": "0.000000",
                "alt": "0.000000",
                "web_x": "0.000000000000",
                "web_y": "0.000000000000",
                "web_z": "0.000000000000",
                "local_anchors_id": "555",
                "attachments": [
                    {
                        "file_id": 0,
                        "file_name": "",
                        "image_url": "https://myrpp.myr.ai/orb/assets/images/equipment-default-image-placeholder.png"
                    }
                ]
            },
            {
                "quantity_id": "11783",
                "quantity_name": "Equipment 5",
                "device_id": "",
                "type_of_space_item_id": "0",
                "lifetime": "2024-04-15",
                "floor_id": "1047",
                "x": "0.000000000000",
                "y": "0.000000000000",
                "z": "0.000000000000",
                "poi": "0",
                "lat": "0.000000",
                "long": "0.000000",
                "alt": "0.000000",
                "web_x": "0.000000000000",
                "web_y": "0.000000000000",
                "web_z": "0.000000000000",
                "local_anchors_id": "556",
                "attachments": [
                    {
                        "file_id": 0,
                        "file_name": "",
                        "image_url": "https://myrpp.myr.ai/orb/assets/images/equipment-default-image-placeholder.png"
                    }
                ]
            }
        ],
        "comments_count": 0,
        "attachment_count": 0
      }]
      setEquipmentList(dummyEquipmentList)

      const dummyPOIs = [{
        "id": "1200",
        "client_id": "2215",
        "project_id": "700",
        "floor_id": "4321",
        "room_id": "0",
        "title": "POI 3",
        "web_x": "0.000000000000",
        "web_y": "0.000000000000",
        "web_z": "0.000000000000",
        "web_side": "O",
        "images": [
            "https://myrai-upload-storage.s3.fr-par.scw.cloud/ar_space_attachments/IMG_98.png"
        ]
      },
      {
        "id": "1201",
        "client_id": "2215",
        "project_id": "700",
        "floor_id": "4321",
        "room_id": "0",
        "title": "POI 4",
        "web_x": "0.000000000000",
        "web_y": "0.000000000000",
        "web_z": "0.000000000000",
        "web_side": "O",
        "images": [
            "https://myrai-upload-storage.s3.fr-par.scw.cloud/ar_space_attachments/IMG_98.png"
        ]
      }
    ]
      setPOIsList(dummyPOIs)
    }
  }, [isDummyModal])

  useEffect(() => {
    if (outSideClick || oustSideClickToggle) {
      $(document).on('click', function (e) {
        if (!$(e.target).closest('#client_3d_optionfirst').length) {
          $("#client_3d_optionfirst").css({ 'display': 'none' });
          if (show == false) { 
            if($('.blank_anchor').length){
              $('.blank_anchor').remove();
            }
          }
          dispatch(closeOutsideClick(false));
        }
      });
    }else{
      $(document).unbind();
    }
  }, [outSideClick,show,oustSideClickToggle,showEquipment,openCreatePOI])


  const addAiPOIs = (POI) => {
    const poiId = POI.id
    customerServices.setArPOIs(poiId, floorId, x, y, z).then((res) => {
      if (res.status == 1) {
        dispatch(ModelViewerPoint(true));
        $("client_3d_optionfirst").css({ 'display': 'none' });
        dispatch(getPOPIsList(getSelectedFloorId, 0));
        dispatch(showMessage('sucess', _l('l_success'), _l(res.message)));
      } else {
        dispatch(showMessage('unsucess', _l('l_error'), _l(res.message)));
      }
    })
  }


  
  const setEquipmentFordocument = (equipment) => {
    customerServices.setArLocalAnchors(getSelectedFloorId, 0, 0, x, y, z, equipment.equipment_id, equipment.id).then((res) => {
          if (res.status == 1) { 
            setOustSideClickToggle(true);
            let localArray = get_equipments_list
            let index = localArray.findIndex((d) => d.id == equipment.equipment_id)
            if (index > -1) {
              let key = localArray[index].quantity_details.findIndex((eq) => eq.id == equipment.id)
              if (key > -1) { 
                localArray[index].quantity_details[key].local_anchors_id = res.data.local_anchor_id ? res.data.local_anchor_id : 0
                localArray[index].quantity_details[key].web_x = x
                localArray[index].quantity_details[key].web_y = y
                localArray[index].quantity_details[key].web_z = z
              }
            }
            dispatch(editedEquipmentsList(localArray));
            dispatch(filterFor3Dmodel([...filterfor3dPlan, (equipment.equipment_id).toString()]));
          }
        })
  }

  const addDummyPOIs = (item) => {
   const dummyPOIWrapper = {
      name: item.quantity_name ? item.quantity_name : item.name ,
      x: x,
      y: y,
      z: z,
      id: item.id,
      status: "",
      is_requested: 0,
      task_id: 0,
      image: item.id > 0 ? "https://myrpp.myr.ai/orb/assets/images/equipment-default-image-placeholder.png" : "",
      quantityId: item.quantity_id,
      equipmentName: item.quantity_name,
      equipmentId: item.equipment_id
    }
    setDummyPOIs([...dummyPOIs, dummyPOIWrapper])
    if (item.quantity_id && item.quantity_id > 0) {
      const equipmentListWrapper = {...equipmentList[0], quantity_details: equipmentList[0].quantity_details.filter((eq) => eq.quantity_id != item.quantity_id)}
      setEquipmentList([equipmentListWrapper])
    } else {
      setPOIsList(POIsList.filter((poi) => poi.id != item.id))
    }
  }

  return (
    <>
      <div
        className="dropdown-menu radius_5 w-350 show new-close-new"
        id="client_3d_optionfirst"
        style={{ display: 'none', }}
      >
        <div className="shortcut-wrapper with-arrow">
          <div className="d-flex flex-wrap">
            <a href="#/"
              className={`shortcut-box-main d-flex flex-column w-33 d-none ${isDummyModal ? "pe-none for-disabled" : ""}`}
              id="addTask"
            >
              <div className="shortcut-top w-100">
                <div className="d-flex align-item-center justify-content-between">
                  <div className="left-img">
                    <UserCirclePlusIcon
                      className="HW20 m-auto opacity-80"
                    />
                  </div>
                </div>
              </div>
              <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                {_l('l_add') + " " + _l("l_task")}
              </div>
            </a>
            <a href="#/"
              className={`shortcut-box-main d-flex flex-column ${getPOIList && getPOIList.length == 0 ? "w-100" : "w-33"} ${isDummyModal ? "pe-none for-disabled" : ""}`}
              id="client_3d_addRequest"
              onClick={() => {
                setShowTaskModal(true);                
              }}
            >
              <div className="shortcut-top w-100">
                <div className="d-flex align-item-center justify-content-between">
                  <div className="left-img">
                    <RequestTaskIcon
                      className="HW20 m-auto opacity-80"
                    />
                  </div>
                </div>
              </div>
              <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                {_l('l_add') + " " + _l("l_request")}
              </div>
            </a>
            <div className={"dropdown shortcut-box-main d-flex flex-column w-33 "}>
              <a href="#/" type="button" className=""
                id=""
                data-bs-toggle="dropdown"
                aria-expanded="false"
                 
                data-bs-auto-close="false"
                 
                 
                 >
                <div className="shortcut-top">
                  <div className="d-flex align-item-center justify-content-between">
                    <div className="left-img">
                      <EstimateIcon
                        className="HW20 m-auto opacity-80"
                      />
                    </div>
                  </div>
                </div>
                <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                  {_l('l_set_equipments')}
                </div>
              </a>
              { isDummyModal
                ?
                 <></>
                : 
                  <Button variant="primary" size="sm" className="position-absolute end-0 bottom-0 m-2" onClick={() => {
                    setshowEquipment(true);
                  }}>{_l('l_add')}</Button> 
              }
              <ActionDropdown
              actions={ 
                equipmentList.filter((dd) => dd.quantity_details.filter((eq) => (eq.web_x == undefined || eq.web_x == 0) && (eq.web_y == undefined || eq.web_y == 0) && (eq.web_z == undefined || eq.web_z == 0)).length > 0).map((item) => {
                return {
                  actionName: item.equipment_name,
                  id: item.id,
                  expandChildMenu:(data) => {
                    item.childStatus = !item.childStatus 
                  },
                  childStatus:false,
                  ActionType:"equipments",
                  list:item.quantity_details.filter((eq) => (eq.web_x == undefined || eq.web_x == 0) && (eq.web_y == undefined || eq.web_y == 0) && (eq.web_z == undefined || eq.web_z == 0)),
                  actionIcon: "HashIcon",
              }
              })}
              type="equipments"
              handler={isDummyModal ? addDummyPOIs : setEquipmentFordocument}
              />
            </div>
            
            
            <div
              
              className={`shortcut-box-main d-flex flex-column`}
              id=""
            >
              <UncontrolledDropdown className="align-items-center d-flex dropdown">
                <DropdownToggle data-toggle="dropdown" tag="div" className="w-100">
                  <div className="shortcut-top w-100">
                    <div className="d-flex align-item-center justify-content-between">
                      <div className="left-img">
                        <EstimateIcon
                          className="HW20 m-auto opacity-80"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                    {_l("l_set_POI")}
                  </div>
                  {isDummyModal
                    ?
                      <></>
                    :
                      <Button variant="primary" size="sm" className="position-absolute end-0 bottom-0" onClick={() => {
                        setOpenCreatePOI(true)
                        setSelectedPOI("")
                      }}>{_l('l_create')}</Button>
                  }
                  <GeneralListing
                    unControlList={true}
                    handler={isDummyModal ? addDummyPOIs : addAiPOIs}
                    list={POIsList.length > 0 ?
                      POIsList.filter((po) => {
                        return po.id != 0 
                      }).slice(0,1).filter((poi) => {
                        return (parseFloat(poi.web_x) == 0 && parseFloat(poi.web_y) == 0 && parseFloat(poi.web_z) == 0)
                      })
                      .map((item, index) => {
                      return {
                        name: item.title,
                        id: item.id,
                        floor_id: item.floor_id || '',
                        project_id: item.project_id || ''
                      };
                    }) : []}
                    placeHolderName={_l("l_POI_placeholder")}
                  />
                </DropdownToggle>
              </UncontrolledDropdown>
            </div>
            <a href="#/"
              className="shortcut-box-main d-flex flex-column w-33 d-none"
              id=""
              onClick={() => {
                setshowEquipment(true);
              }}
            >
              <div className="shortcut-top w-100">
                <div className="d-flex align-item-center justify-content-between">
                  <div className="left-img">
                    <UserCirclePlusIcon
                      className="HW20 m-auto opacity-80"
                    />
                  </div>
                </div>
              </div>
              <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                {_l('l_add') + " " + _l("l_equipments")}
              </div>
            </a>
            <a href="#/"
              className={`shortcut-box-main d-flex flex-column d-none ${getPOIList && getPOIList.length == 0 ? "w-100" : "w-33"}`}
              id=""
              onClick={() => setOpenCreatePOI(true)}
            >
              <div className="align-items-center d-flex dropdown">
                <div data-bs-toggle="dropdown" type="button"
                            aria-expanded="false"  data-bs-auto-close="outside" className="w-100 p-2">
                  <div className="shortcut-top w-100">
                    <div className="d-flex align-item-center justify-content-between">
                      <div className="left-img">
                        <EstimateIcon
                          className="HW20 m-auto opacity-80"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                    {_l("l_create_poi")}
                  </div>
                </div>
              </div>
            </a>
            <div className={"dropdown shortcut-box-main d-flex flex-column w-33 "}>
              <a href="#/" type="button" className={`${isDummyModal ? "pe-none for-disabled" : ""}`}
                id=""
                data-bs-toggle="dropdown"
                aria-expanded="false"
                 
                data-bs-auto-close="false"
                 
                 
                 >
                <div className="shortcut-top">
                  <div className="d-flex align-item-center justify-content-between">
                    <div className="left-img">
                      <EstimateIcon
                        className="HW20 m-auto opacity-80"
                      />
                    </div>
                  </div>
                </div>
                <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                  {_l('l_setup_room')}
                </div>
              </a>
              { isDummyModal
                ? 
                  <></> 
                : 
                  <Button variant="primary" size="sm" className="position-absolute end-0 bottom-0 m-2" onClick={() => {
                    setSetUpRoomShow(true);
                    setOpenFrom("create");
                  }}>{_l('l_create')}</Button> 
              }
              <ActionDropdown
              actions={ 
                typeOfSpaces.filter((dd) => dd.details.filter((df) => [getSelectedFloorId].includes(df.floor_id)).length > 0).map((item) => {
                return {
                  actionName: item.title,
                  id: item.id,
                  expandChildMenu:(data) => {
                    item.childStatus = !item.childStatus 
                  },
                  childStatus:false,
                  ActionType:"roomSetup",
                  list:item.details,
                actionIcon: "HashIcon",
              }
              })}
              type="roomSetup"
                handler={setEquipmentFordocument}
              />
            </div>
            <div
              className={`shortcut-box-main d-flex flex-column d-none`}
              id=""
            
            >
              <div className="align-items-center d-flex dropdown">
                <div data-bs-toggle="dropdown" type="button"
                            aria-expanded="false"  data-bs-auto-close="outside" className="w-100 p-2">
                  <div className="shortcut-top w-100">
                    <div className="d-flex align-item-center justify-content-between">
                      <div className="left-img">
                        <EstimateIcon
                          className="HW20 m-auto opacity-80"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="shortcut-bottom mt-auto color-white-80 c-font f-12 pt-2">
                    {_l("l_create_room")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showSchedule3DScan ?
          <Schedule3DScan
            show={showSchedule3DScan}
            handleClose={() => {
              setShowSchedule3DScan(false)
            }}

          />
          : <></>
      }
      {
        show ? (
          <CommanOffCanvas
            show={show}
            handleClose={() => {
              setshow(false)
              if($('.blank_anchor').length){
                $('.blank_anchor').remove();
              }
            }}
            pinHandler={() => {}}
            data={data}
            pageName={"task"}
            docType={"request-task"}
            setSelectedId={() => {}}
            selectedTaskId={0}
            IsKanbanView={false}
            meetingUrl={''}
            canvasfullHeightwithHeaderFooter={true}
            handleParentComponentAction={(type, taskData) => {
            }}
            floorId={getSelectedFloorId}
            setRequestData={setData}
          />
        ) : (
          <></>
        )
      }
      
      {
            showEquipment ?
              <CommanOffCanvas
                show={showEquipment}
                handleClose={() => {
                  if ($('.blank_anchor').length) {
                    $('.blank_anchor').remove();
                  }
                  setshowEquipment(false);
                }}
                docType={'equipment_task'}
                setSelectedId={() => { }}
                floorId={getSelectedFloorId}
              />
              : <></>
          }
       {
        showTaskModal ? (
          <CreateTaskRequestOffcanvas
            show={showTaskModal}
            handleClose={(taskId) => {
              setShowTaskModal(false);
            }}
            isRequest={true}
            from={"3dPlan"}
          />
        ) : (
          <></>
        )
      }
    </>
  );
};

export default MyspaceTaskRequestWithData;