import React from "react";
import { _l } from "../../../hooks/utilities";
import EquipmentListing from "../../../components/EquipmentListing";
const ClientSpaceTab = ({data}) => {

    return (
        <div>
            <EquipmentListing pageName="customer" clientid={data.id} is_space={'space'}/>
        </div>
    )
}
export default ClientSpaceTab;