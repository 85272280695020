import React from 'react';
 

function ListBulletsIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M5.5 4H13.5"/>
<path d="M5.5 8H13.5"/>
<path d="M5.5 12H13.5"/>
<path d="M2.525 4.175C2.74591 4.175 2.925 3.99591 2.925 3.775C2.925 3.55409 2.74591 3.375 2.525 3.375C2.30409 3.375 2.125 3.55409 2.125 3.775C2.125 3.99591 2.30409 4.175 2.525 4.175Z" />
<path d="M2.525 12.175C2.74591 12.175 2.925 11.9959 2.925 11.775C2.925 11.5541 2.74591 11.375 2.525 11.375C2.30409 11.375 2.125 11.5541 2.125 11.775C2.125 11.9959 2.30409 12.175 2.525 12.175Z" />
<path d="M2.525 8.175C2.74591 8.175 2.925 7.99591 2.925 7.775C2.925 7.55409 2.74591 7.375 2.525 7.375C2.30409 7.375 2.125 7.55409 2.125 7.775C2.125 7.99591 2.30409 8.175 2.525 8.175Z" />
</svg>



		</React.Fragment>
	)
}

export default ListBulletsIcon