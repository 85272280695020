import React from 'react';
 

function NoBenchmark(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="76" height="84" viewBox="0 0 76 84" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons h-100 w-100 ${CommanHW}`}>
				<path d="M1.2076 41.8539C1.2076 31.9776 1.2076 22.1012 1.2076 12.2249C1.2076 5.15405 5.31626 1.03403 12.405 1.01401C29.6027 0.995329 46.8004 0.995329 63.9981 1.01401C71.0909 1.01401 75.1835 5.13804 75.1875 12.2249C75.1875 32.1057 75.1875 51.9917 75.1875 71.8831C75.1875 78.8259 71.0588 82.97 64.0905 82.982C46.8285 83.006 29.5585 83.006 12.2805 82.982C5.31624 82.982 1.19553 78.8179 1.1875 71.8752C1.19018 61.8707 1.19689 51.8636 1.2076 41.8539Z"  strokeMiterlimit="10" />
				<path d="M11.8945 14.1826H64.5078"  strokeMiterlimit="10" strokeLinecap="round" />
				<path d="M11.8945 27.3955H47.037"  strokeMiterlimit="10" strokeLinecap="round" />
				<path d="M11.8945 40.6084H32.1768"  strokeMiterlimit="10" strokeLinecap="round" />
			</svg>

		</React.Fragment>
	)
}

export default NoBenchmark