import React, { useEffect, useState } from 'react'
import { _l } from '../../hooks/utilities'
import customerServices from '../../services/customer-services';
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CommentMinified from '../Comments/CommentMinified';
import documentsServices from '../../services/documents-services';
import { getMentionList } from '../../actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import Form from "react-bootstrap/Form";
import Select from "react-select";
import ChatCircleIcons from '../../assets/icons/ChatCircleIcons';
let showComments = false;
const industryType = [
  {id : "industry_type_1", label : _l("l_office")},
  {id : "industry_type_2", label :  _l("l_coworking_and_flex_office")},
  {id : "industry_type_3", label : _l("l_building")},
  {id : "industry_type_4", label : _l("l_multi_properties")},
  {id : "industry_type_5", label : _l("l_hotel_and_retail")},
  {id : "industry_type_6", label : _l("l_property_manager_and_facility_manager")},
]
const ProviderProfileTable = ({
  data,
  editCompanyProfile = false,
  setEditCompanyProfileData = () => {},
  editCompanyProfileData
}) => {
  const mentionsList = useSelector((state) => state.customer.mentionList);
  const { providerlistTableData } = useSelector((state) => state.customer);
  const [regionvalue, setRegionValue] = useState([]);
  const [categoryListing, setCategoriesList] = useState([]);
  const [selectedKeys, setselectedKeys] = useState('');
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [replyCommentData, setReplyCommentData] = useState({});
  const [localCompanyProfile, setlocalCompanyProfile] = useState(data)
  const contact_id = localStorage.getItem("contact_id");
  const userType = localStorage.getItem("user_type");
  let providerID = localStorage.getItem("currentCompanyID");
  const dispatch = useDispatch();
  const getTaskComments = async (type_key) => {
    showComments = true;
    setCommentsLoading(true);
    try {
      const comments = await documentsServices.getComments(providerID, type_key, undefined, undefined, 0);
      if (comments.data) {
        setCommentsList(comments.data.map((comment) => {
          return {
            id: comment.id,
            content: comment.content,
            contact_id: comment.contact_id,
            staffid: comment.staffid,
            full_name: comment.contact_full_name || comment.staff_full_name,
            profile_url: comment.addedfrom_details.profile_url,
            attachments: comment.attachments,
            date: comment.time,
            dateadded: comment.dateadded,
            is_allowed_to_edit: comment.is_allowed_to_edit,
            parent_comment_details: comment.parent_comment_details ? comment.parent_comment_details : '',
            parent_comment_id: comment.parent_comment_id ? comment.parent_comment_id : 0
          }
        }));
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setCommentsLoading(false);
    }
  }
  const getMentionlist = (type_key) => {
    dispatch(getMentionList(providerID, type_key));
  };
  const handleAddEditComment = async (content, files, id) => {
    setFileUploadLoader(true)
    try {
      const response = await documentsServices.addDocumentComment(selectedKeys, providerID, content, 0, 0, files, 0, 0, 0);
      if (id > 0) {
        const commentIndex = commentsList.findIndex(w => w.id == id);
        setCommentsList(Object.values({ ...commentsList, [commentIndex]: { ...commentsList[commentIndex], content } }));
      }
      else {
        const staffid = localStorage.getItem("staff_id");
        const profile_url = localStorage.getItem("contact_image");
        const full_name = localStorage.getItem("full_name");
        let attachments = [];
        if (files) {
          Array.from(files).map((file) => {
            attachments.push({
              file_url: URL.createObjectURL(file),
              file_name: file.name
            })
          });
        }
        var addComments = {
          id: response.data.comment_id,
          content: content,
          contact_id: contact_id,
          staffid: staffid,
          full_name: full_name,
          profile_url: profile_url,
          is_allowed_to_edit: "1",
          attachments: attachments,
          date: response.data.time,
          dateadded: new Date(),
        }
        if (replyCommentData && replyCommentData.content != '' && replyCommentData.id != '') {
          addComments.parent_comment_details = { id: replyCommentData.id, content: replyCommentData.content, file_id: "0" }
          addComments.parent_comment_id = replyCommentData.id
        } else if (replyCommentData.attachments && replyCommentData.attachments[0].file_url != '') {
          let replyCommentDatas = replyCommentData.attachments[0]
          addComments.parent_comment_details = { content: "[task_attachment]", file_id: replyCommentDatas.file_id, file_name: replyCommentDatas.file_name, file_url: replyCommentDatas.file_url }
          addComments.parent_comment_id = replyCommentData.id
        }
        setCommentsList(commentsList.concat([addComments]));
        setFileUploadLoader(false);
      }
    }
    catch (e) {
      setFileUploadLoader(false);
      console.error(e);
    }
  };
  const handleDeleteComment = (comment) => {
    try {
      setCommentsList(commentsList.filter(x => x.id != comment.id));
    }
    catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    let regions = [];
    let categoryLists = [];
    customerServices.getRegionList().then((response) => {
      if (response.status) {
        regions = response.data.map((region) => {
          return {
            label: region.name,
            value: region.id,
          };
        })
        setRegionValue(regions);
      }
    })
    customerServices.getProviderServices().then((response) => {
      if (response.status) {
        categoryLists = response.data.map((w) => {
          return {
            label: w.name,
            value: w.id,
          };
        })
        setCategoriesList(categoryLists);
      }
    })
  }, []);
  useEffect(() => {
    setEditCompanyProfileData({
      ...editCompanyProfileData,
      company: localCompanyProfile.company ? localCompanyProfile.company : '',
      industry_type: localCompanyProfile.industry_type  
                        ? industryType.filter((w) => localCompanyProfile.industry_type && localCompanyProfile.industry_type.includes(w.id))
                        : [],
      company_id_number: localCompanyProfile.company_id_number ? localCompanyProfile.company_id_number : '',
      v_t_number: localCompanyProfile.v_t_number ? localCompanyProfile.v_t_number : '',
      regions: localCompanyProfile.regions 
                  ? regionvalue.filter((w) => localCompanyProfile.regions && localCompanyProfile.regions.includes(w.value))
                  : [],
      categories: localCompanyProfile.categories
                  ? categoryListing.filter((w) => localCompanyProfile.categories && localCompanyProfile.categories.includes(w.value))
                  : [],
      address: localCompanyProfile.address ? localCompanyProfile.address : '',
      additional_data: localCompanyProfile.additional_data ? localCompanyProfile.additional_data : '',
    });
  }, [categoryListing, regionvalue, JSON.stringify(localCompanyProfile)]);
  useEffect(() => {
    setlocalCompanyProfile(data)
  }, [JSON.stringify(data)])
  useEffect(() => {
    if (providerlistTableData) {
      setlocalCompanyProfile(providerlistTableData.company_profile)
    }
   }, [JSON.stringify(providerlistTableData.company_profile)])
  return (
    <div className="p-10">
      <div className="row">
        <div className="col-xl-12 ">
          <div className='comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1'>
            <table className='dataTable text-center tablewithborder'>
              <thead className='comman-white-box-shadow'>
                <tr>
                  <th className="text-start" style={{ width: "15%" }} >{_l('l_company_info')}</th>
                  <th className="text-start" style={{ width: "80%" }} >{_l('l_company_info')}</th>
                  <th className="text-center" style={{ width: "3%" }} >{_l('l_action')}</th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: "middle" }}>
                <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_company_name')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    <input 
                      className={`form-control c-font f-13  color-white-80 text-truncate p-10
                        ${editCompanyProfile ? "bg-transparent" : "border"}
                        `}
                      value={editCompanyProfileData.company ? editCompanyProfileData.company : ""}
                      disabled={editCompanyProfile}
                      onChange={(e) => {
                        setEditCompanyProfileData({
                          ...editCompanyProfileData,
                          company: e.target.value,
                        })
                      }}
                    />
                  </td>
                  <td>
                    {/* comment icon start */}
                    {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img  h32w32`}>
                      <UncontrolledDropdown className="w-100">
                        <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                          <a href="#/"
                            onClick={(e) => {
                              getMentionlist("profile_page");
                              getTaskComments("provider_company");
                              setselectedKeys("provider_company");
                            }}
                            className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                          >
                            <div className="d-flex align-items-center w-100">
                              <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                                <ChatCircleIcons
                                  className="HW18"
                                />
                              </div>
                            </div>
                          </a>
                        </DropdownToggle>
                        <DropdownItem toggle={false} className="p-0">
                          {
                            <CommentMinified
                              comments={commentsList}
                              handleAddComment={handleAddEditComment}
                              deleteComment={handleDeleteComment}
                              id={providerID}
                              loading={commentsLoading}
                              mentionList={mentionsList.map((name) => {
                                let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                                return {
                                  display: name.name,
                                  id: id,
                                  image: name.profile_image || "",
                                  isteam: name.is_team ? 1 : 0,
                                };
                              })}
                              unContolList={true}
                              showComments={showComments}
                              setReplyCommentData={setReplyCommentData}
                              fileLoader={fileUploadLoader}
                            />
                          }
                        </DropdownItem>
                      </UncontrolledDropdown>
                    </div>}
                    {/* comment icon end */}
                  </td>
                </tr>
                <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_company_type')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                  {
                    editCompanyProfile ? 
                    <span className={`${editCompanyProfile ? "bg-transparent" : "border"}`}>
                      {editCompanyProfileData.industry_type && editCompanyProfileData.industry_type[0] ? editCompanyProfileData.industry_type[0].label : ''}
                    </span>
                      : <Form.Group className={`col-xl-12 c-input-box position-relative ${editCompanyProfile ? "bg-transparent" : "border"}`}>  
                          <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                            <Select
                              className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                              classNamePrefix="react-select"
                              placeholder={_l("l_company_type")}
                              options={industryType}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                              value={ editCompanyProfileData.industry_type && editCompanyProfileData.industry_type.length > 0
                                  ? industryType.filter(
                                      (w) => w.id == editCompanyProfileData.industry_type[0].id
                                    )
                                  : []
                              }
                              onChange={(e) => {
                                setEditCompanyProfileData({
                                  ...editCompanyProfileData,
                                  industry_type: [e],
                                })
                              }}
                            />
                          </div>
                        </Form.Group>
                  }    
                  </td>
                  <td>
                       {/* comment icon start */}
                {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img  h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_industry_type");
                          setselectedKeys("provider_industry_type");
                        }}
                        className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                              <ChatCircleIcons
                                className="HW18"
                              />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                          comments={commentsList}
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={providerID}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          unContolList={true}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>}
                {/* comment icon end */}
                  </td>
                </tr>
                <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_company_id_number')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    <input 
                        className={`form-control c-font f-13  color-white-80 text-truncate p-10
                          ${editCompanyProfile ? "bg-transparent" : "border"}
                          `}
                        value={editCompanyProfileData.company_id_number ? editCompanyProfileData.company_id_number : ""}
                        disabled={editCompanyProfile}
                        onChange={(e) => {
                          setEditCompanyProfileData({
                            ...editCompanyProfileData,
                            company_id_number: e.target.value,
                          })
                        }}
                      />
                  </td>
                  <td>
                   {/* comment icon start */}
                {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green  h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_company_id_number");
                          setselectedKeys("provider_company_id_number");
                        }}
                        className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircleIcons
                              className="HW18"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                          comments={commentsList}
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={providerID}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          unContolList={true}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>}
                {/* comment icon end */}
                  </td>
                </tr>
                <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_tax_id')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    <input 
                        className={`form-control c-font f-13  color-white-80 text-truncate p-10
                          ${editCompanyProfile ? "bg-transparent" : "border"}
                          `}
                        value={editCompanyProfileData.v_t_number ? editCompanyProfileData.v_t_number : ""}
                        disabled={editCompanyProfile}
                        onChange={(e) => {
                          setEditCompanyProfileData({
                            ...editCompanyProfileData,
                            v_t_number: e.target.value
                          })
                        }}
                      />
                  </td>
                  <td>
                        {/* comment icon start */}
                  {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img  h32w32`}>
                    <UncontrolledDropdown className="w-100">
                      <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                        <a href="#/"
                          onClick={(e) => {
                            getMentionlist("profile_page");
                            getTaskComments("provider_v_t_number");
                            setselectedKeys("provider_v_t_number");
                          }}
                          className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                              <ChatCircleIcons
                                className="HW18"
                              />
                            </div>
                          </div>
                        </a>
                      </DropdownToggle>
                      <DropdownItem toggle={false} className="p-0">
                        {
                          <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                          />
                        }
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </div>}
                  {/* comment icon end */}
                  </td>
                </tr>
                <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_region_label')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    {editCompanyProfile ? 
                      <span className={`me-2 ${editCompanyProfile ? "bg-transparent" : "border"}`}>{editCompanyProfileData.regions && editCompanyProfileData.regions[0] ? editCompanyProfileData.regions[0].label : ''}</span>
                      :
                      <Form.Group className={`col-xl-12 c-input-box position-relative ${editCompanyProfile ? "bg-transparent" : "border"}`}>  
                        <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                          <Select
                            className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                            classNamePrefix="react-select"
                            placeholder={_l("l_region_label")}
                            options={regionvalue}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            value={ editCompanyProfileData.regions && editCompanyProfileData.regions.length > 0 
                                ? regionvalue.filter(
                                  (w) => w.value == editCompanyProfileData.regions[0].value
                                )
                                : []
                            }
                            onChange={(e) => {
                              setEditCompanyProfileData({
                                ...editCompanyProfileData,
                                regions: [e]
                              })
                            }}
                          />
                        </div>
                      </Form.Group>
                      }
                  </td>
                  <td>
                       {/* comment icon start */}
                {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img  h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_regions");
                          setselectedKeys("provider_regions");
                        }}
                        className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircleIcons
                              className="HW18"
                            />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                          comments={commentsList}
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={providerID}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          unContolList={true}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>}
                {/* comment icon end */}
                  </td>
                  </tr>
                  <tr>
                    <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_category')} </td>
                    <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    {editCompanyProfile ? 
                      <span className={`me-2 ${editCompanyProfile ? "bg-transparent" : "border"}`}>{editCompanyProfileData.categories && editCompanyProfileData.categories[0] ? editCompanyProfileData.categories[0].label : ''}</span>
                      :
                      <Form.Group className={`col-xl-12 c-input-box position-relative ${editCompanyProfile ? "bg-transparent" : "border"}`}>  
                        <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative">
                          <Select
                            className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
                            classNamePrefix="react-select"
                            placeholder={_l("l_region_label")}
                            options={categoryListing}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            value={ editCompanyProfileData.categories
                                ? categoryListing.filter(
                                  (w) => w.value == editCompanyProfileData.categories[0].value
                                )
                                : []
                            }
                            onChange={(e) => {
                              setEditCompanyProfileData({
                                ...editCompanyProfileData,
                                categories: [e]
                              })
                            }}
                          />
                        </div>
                      </Form.Group>
                      }
                    </td>
                    <td>
                        {/* comment icon start */}
                  {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img text-on-hover-active-green  h32w32`}>
                    <UncontrolledDropdown className="w-100">
                      <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                        <a href="#/"
                          onClick={(e) => {
                            getMentionlist("profile_page");
                            getTaskComments("provider_categories");
                            setselectedKeys("provider_categories");
                          }}
                          className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                        >
                          <div className="d-flex align-items-center w-100">
                            <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                              <ChatCircleIcons
                                className="HW18"
                              />
                            </div>
                          </div>
                        </a>
                      </DropdownToggle>
                      <DropdownItem toggle={false} className="p-0">
                        {
                          <CommentMinified
                            comments={commentsList}
                            handleAddComment={handleAddEditComment}
                            deleteComment={handleDeleteComment}
                            id={providerID}
                            loading={commentsLoading}
                            mentionList={mentionsList.map((name) => {
                              let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                              return {
                                display: name.name,
                                id: id,
                                image: name.profile_image || "",
                                isteam: name.is_team ? 1 : 0,
                              };
                            })}
                            unContolList={true}
                            showComments={showComments}
                            setReplyCommentData={setReplyCommentData}
                            fileLoader={fileUploadLoader}
                          />
                        }
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </div>}
                  {/* comment icon end */}
                    </td>
                  </tr>
                  <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start">{_l('l_company_address')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    <input 
                      className={`form-control c-font f-13  color-white-80 text-truncate p-10
                        ${editCompanyProfile ? "bg-transparent" : "border"}
                        `}
                      value={editCompanyProfileData.address ? editCompanyProfileData.address : ""}
                      disabled={editCompanyProfile}
                      onChange={(e) => {
                        setEditCompanyProfileData({
                          ...editCompanyProfileData,
                          address: e.target.value
                        })
                      }}
                    />
                    </td>
                  <td>
                             {/* comment icon start */}
                {userType == "contact" && <div className={`align-items-center  card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img  h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_address");
                          setselectedKeys("provider_address");
                        }}
                        className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircleIcons
                                className="HW18"
                              />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                          comments={commentsList}
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={providerID}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          unContolList={true}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>}
                  </td>
                  </tr>
                  <tr>
                  <td className="c-font color-white-60 f-13  fw-semibold text-start"> {_l('l_additional_data')} </td>
                  <td className='text-start c-font f-13  color-white text-truncate p-10 '>
                    <input 
                      className={`form-control c-font f-13  color-white-80 text-truncate p-10
                        ${editCompanyProfile ? "bg-transparent" : "border"}
                        `}
                      value={editCompanyProfileData.additional_data ? editCompanyProfileData.additional_data : ""}
                      disabled={editCompanyProfile}
                      onChange={(e) => {
                        setEditCompanyProfileData({
                          ...editCompanyProfileData,
                          additional_data: e.target.value,
                        })
                      }}
                    />
                    </td>
                  <td>
                        {/* comment icon start */}
                {userType == "contact" && <div className={`align-items-center card-view-less-padding close-toast  d-flex dropdown dropdown-center on-hover-active-toggle-img  h32w32`}>
                  <UncontrolledDropdown className="w-100">
                    <DropdownToggle data-toggle="dropdown" tag="span" className="close-toast comman_action_icon d-flex justify-content-end">
                      <a href="#/"
                        onClick={(e) => {
                          getMentionlist("profile_page");
                          getTaskComments("provider_additional_data");
                          setselectedKeys("provider_additional_data");
                        }}
                        className='on-hover-active-toggle-img text-on-hover-active-green hovertextgreen'
                      >
                        <div className="d-flex align-items-center w-100">
                          <div className="action_icon with_bg h32w32 position-relative light-theme-img">
                            <ChatCircleIcons
                                className="HW18"
                              />
                          </div>
                        </div>
                      </a>
                    </DropdownToggle>
                    <DropdownItem toggle={false} className="p-0">
                      {
                        <CommentMinified
                          comments={commentsList}
                          handleAddComment={handleAddEditComment}
                          deleteComment={handleDeleteComment}
                          id={providerID}
                          loading={commentsLoading}
                          mentionList={mentionsList.map((name) => {
                            let id = name.is_team && name.is_team == 1 ? "-" + name.id : name.id;
                            return {
                              display: name.name,
                              id: id,
                              image: name.profile_image || "",
                              isteam: name.is_team ? 1 : 0,
                            };
                          })}
                          unContolList={true}
                          showComments={showComments}
                          setReplyCommentData={setReplyCommentData}
                          fileLoader={fileUploadLoader}
                        />
                      }
                    </DropdownItem>
                  </UncontrolledDropdown>
                </div>}
                {/* comment icon end */}
                  </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProviderProfileTable