import React, {  } from "react";
import { _l } from "../../hooks/utilities";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { setCreateTaskModalIsopen } from "../../actions/customer";
import { DropdownItem, DropdownMenu } from "reactstrap";


const CreateTaskViewDropdown = ({
  checklistMode, 
  setShowFloorList = () =>{ }, 
  setChecklistMode = () =>{ }, 
  switchToClassicView= () =>{ },
  taskPinned,
  setTaskPinned = () =>{ },
  selectedSpace,
  project_id,
  setShowCheckListArea = ()=>{}
 }) => {
  const dispatch = useDispatch()
  return (
    <>
    <DropdownMenu  className="dropdown-menu w-100" aria-labelledby="CreateTaskViewDropdown">
      <DropdownItem className="comman-list with-after-40 "
        onClick={(e) => {
          switchToClassicView(e);
          setShowCheckListArea(true);
          dispatch(setCreateTaskModalIsopen(true));
        }} 
      >
        <Form.Check
          type="radio"
          label={_l("l_checklist_classic")}
          id="classic"
          className="me-3 cursor-pointer"
          name="classicview"
          value={"classic"}
          checked={checklistMode === "classic"}
        />
      </DropdownItem>
     <DropdownItem className={`comman-list with-after-40 ${window.location.pathname == "/dashboard" ? "for-Disbabled" : ""}`}
        onClick={(e) => {
          if(window.location.pathname != "/dashboard"){
          setChecklistMode("floor"); 
          setShowFloorList(true);
          setShowCheckListArea(true);
          dispatch(setCreateTaskModalIsopen(true));
          }
        }} 
     >
        <Form.Check
          type="radio"
          label={_l("l_checklist_floorwise")}
          id="floor"
          className="me-3 cursor-pointer"
          name="floorview"
          value={"floor"}
          checked={checklistMode === "floor"}
          disabled={project_id == 0 || project_id == null || project_id == undefined && !Object.keys(selectedSpace).length > 0}
        />
      </DropdownItem>
     <DropdownItem className="comman-list with-after-40 for-disabled">
        <Form.Check
          id="demo3"
          type="radio"
          label={_l("l_intro_footer_my_space")}
          name="createviewradio"
          disabled
        />
      </DropdownItem>     
    </DropdownMenu>
    </>
  );
};

export default CreateTaskViewDropdown;