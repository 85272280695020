import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { validateEmail, _l } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import CustomerServices from "../../services/customer-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import AddSpaceLead from "./AddSpaceLead";
import LeadContactProviderForm from "./LeadContactProviderForm";

const ConvertLeadToCustomer = ({ show, handleClose, handleSave, lead, isModal = 0, type = '', loder = false }) => {
  const leadName = !isModal ? lead.name.split(" ") : '';
  const customerInitialData = {
    firstName: !isModal ? leadName[0] : '',
    lastName: !isModal ? leadName.length > 1 ? lead.name.replace(leadName[0], "") : "" : '',
    position: "",
    email: lead.email,
    company: lead.company,
    phone: lead.phonenumber,
    website: "",
    leadAddress: "",
    city: "",
    state: "",
    leadCountry: "",
    leadZipCode: "",
    leadPassword: '',
    setSendPasswordEmail: false,
    spaceTitle: "",
    spaceAddress: "",
    spaceType: "",
    spaceRegion: "",
    superficy: 0,
    numberOfDesk: 0,
    region: '',
    typeJob: '',
    phone_code: { value: '+33', label: '+33' }
  };
  const space_details = {
    title: '',
    address: '',
    space_type: '',
    superfice: 0,
    region_id: '',
    number_of_desk: 0,
    startDate: '',
    endDate: '',
    typeOfSpace: [],
    country_id: '',
    space_type: ''
  }

  const [customerData, setCustomerData] = useState(customerInitialData);
  const [spaceDetails, setSpacDetails] = useState(space_details);

  const [countryList, setCountryList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const dispatch = useDispatch();
  const [categoryListing, setCategoryListing] = useState([]);
  const [selectedTab, setSelectedTab] = useState('step-1');




  const validationSuccessForm = () => {
    let success = true;
    let requiredFields = ['firstName', 'lastName', 'email', 'company', 'leadPassword'];
    if (type == 'operator') {
      requiredFields.push('region', 'typeJob')
    }
    Object.keys(customerInitialData)
      .reverse()
      .forEach((key, value) => {
        if (
          requiredFields.includes(key) &&
          (!customerData[key] ||
            customerData[key] == "" ||
            customerData[key] == 0)
        ) {
          success = false;
          dispatch(
            showMessage("unsucess", _l("l_error"), _l(`l_${key}_required`))
          );
        }
      });
    if (!validateEmail(customerData.email)) {
      success = false;
      dispatch(showMessage("unsucess", _l("l_error"), _l(`l_invalid_email`)));
    }

    return success;
  };
  const validationSuccessSpaceForm = () => {
    let success = true;
    if (spaceDetails.title == '') {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_add_space_title`))
      );
      success = false
    } else if (spaceDetails.address == "" || spaceDetails.country_id == "") {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_add_space_address`))
      );
      success = false
    } else if (spaceDetails.region_id == '' || spaceDetails.region_id < 1) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_add_space_region`))
      );
      success = false
    } else if (spaceDetails.superfice == '' || spaceDetails.superfice < 1) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_enter_superficy`))
      );
      success = false
    } else if (spaceDetails.number_of_desk == '' || spaceDetails.number_of_desk < 1) {
      dispatch(
        showMessage("unsucess", _l("l_error"), _l(`l_please_enter_number_of_desk`))
      );
      success = false
    }
    return success;
  };
  const validateAndSave = () => {
    if (validationSuccessForm() && validationSuccessSpaceForm()) {
      const formattedData = {
        first_name: customerData.firstName,
        last_name: customerData.lastName,
        position: customerData.position,
        email: customerData.email,
        company_name: customerData.company,
        phone_number: customerData.phone != '' ? customerData.phone_code.value + ' ' + customerData.phone : '',
        website: customerData.website,
        address: customerData.leadAddress,
        city: customerData.city,
        state: customerData.state,
        country_id: customerData.leadCountry.value,
        zip_code: customerData.leadZipCode,
        lead_id: lead.id,
        password: customerData.leadPassword,
        send_set_password_email: customerData.setSendPasswordEmail ? 1 : 0,
        region_id: customerData.region != '' ? customerData.region.value ? customerData.region.value : '' : '',
        type_of_job: customerData.typeJob != '' ? customerData.typeJob.value ? customerData.typeJob.value : '' : '',
      };
      handleSave(formattedData, spaceDetails, type == 'contact' ? 0 : 1);
    }
  };

  useEffect(() => {
    CustomerServices.getCountryCodes(true).then((res) => {
      if (res.status == "1") {
        setCountryList(
          res.data.map((code) => {
            return {
              value: code.country_id,
              label: code.short_name,
              short_code: code.iso2,
            };
          })
        );
      }
    });
    CustomerServices.getRegionList().then((res) => {
      if (res.status) {
        setRegionsList(
          res.data.map((x) => {
            return {
              label: x.name,
              value: parseFloat(x.id),
            };
          })
        );
      }
    });
    CustomerServices.getTypeOfJobs().then((res) => {
      if (res.status) {
        const formattedCategoryListing = res.data.map((item1) => {
          return {
            value: parseInt(item1.id),
            label: item1.name,
          };
        })
        setCategoryListing(
          formattedCategoryListing
        );

      }
    });
  }, []);
  useEffect(() => {
    if (isModal) {
      setCustomerData({
        ...customerInitialData,
        firstName: lead.name,
        position: lead.position ? lead.position : '',
        email: lead.email ? lead.email : '',
        company: lead.company ? lead.company : '',
        phone: lead.phonenumber ? lead.phonenumber : '',
        website: "",
        leadAddress: lead.address ? lead.address : '',
        city: lead.city ? lead.city : '',
        state: lead.state ? lead.state : '',
        leadCountry: "",
        leadZipCode: "",
        leadPassword: "",
        setSendPasswordEmail: false,
        spaceTitle: "",
        spaceAddress: "",
        spaceType: "",
        spaceRegion: "",
        superficy: 0,
        numberOfDesk: 0,
      })
    }
  }, [lead])

  return (
    <>
      <div className="">
        <Modal
          show={show}
          size="xl"
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{type == 'contact' ? _l("l_convert_to_customer") : 'Convert to provider'}</Modal.Title>

          </Modal.Header>

          <Modal.Body className=" py-0">
            <div className="comman-tab-wrapper">
              <div className="d-flex align-items-center border-bottom">

                <div

                  className={`tab-wrapper ${selectedTab == 'step-1' ? "active"
                    : ""
                    } d-flex align-items-center `}
                  onClick={() => {
                    setSelectedTab('step-1')
                  }}
                >
                  <a href="#/" className="tab-name">Convert Lead as {type == 'contact' ? 'Client' : 'Provider'} (Primary  {type == 'contact' ? 'Contact' : 'Operator'} Setup) </a>
                </div>
                <div

                  className={`tab-wrapper ${selectedTab == 'step-2' ? "active"
                    : ""
                    } d-flex align-items-center `}
                  onClick={() => {
                    if (validationSuccessForm()) {
                      setSelectedTab('step-2')
                    }
                  }}
                >
                  <a href="#/" className="tab-name">Convert Lead as {type == 'contact' ? 'Client' : 'Provider'} (First Space Setup) </a>
                </div>
              </div>
            </div>
            {
              selectedTab == 'step-1' ?
                <LeadContactProviderForm setCustomerData={setCustomerData} customerData={customerData} countryList={countryList} regionsList={regionsList} type={type} categoryListing={categoryListing} />
                : <AddSpaceLead spaceDetails={spaceDetails} setSpaceDetails={setSpacDetails} regionsList={regionsList} />
            }

          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-between">
            <Button variant="secondary d-none" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="secondary" className={`${selectedTab == 'step-2' ? '' : 'd-none'}`} onClick={() => {
              if (selectedTab == 'step-2') {
                setSelectedTab('step-1')
              }
            }}>
              {_l('l_previous')}
            </Button>
            <Button variant="primary ms-auto" onClick={() => {
              if (selectedTab == 'step-1') {
                if (validationSuccessForm()) {
                  setSelectedTab('step-2')
                }
              } else if (selectedTab == 'step-2') {
                if (validationSuccessSpaceForm()) {
                  validateAndSave()
                }
              }

            }}>
              {loder ? _l("l_please_wait") : selectedTab == 'step-1' ? _l("l_next") : _l("l_save")}
              {loder ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ConvertLeadToCustomer;
