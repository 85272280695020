import React from 'react';
 

function ShoppingCartIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`comman-icons ${CommanHW}`}>
<path d="M11.8906 11.5H4.75313L3.00938 1.9125C2.98897 1.79768 2.92908 1.69361 2.84006 1.61828C2.75104 1.54296 2.63848 1.50112 2.52187 1.5H1.39062M11.8906 11.5C11.2003 11.5 10.6406 12.0596 10.6406 12.75C10.6406 13.4404 11.2003 14 11.8906 14C12.581 14 13.1406 13.4404 13.1406 12.75C13.1406 12.0596 12.581 11.5 11.8906 11.5ZM4.29688 9H12.1469C12.3806 9.00071 12.6071 8.91902 12.7866 8.76928C12.966 8.61953 13.087 8.41133 13.1281 8.18125L13.8906 4H3.39062M6.64062 12.75C6.64062 13.4404 6.08098 14 5.39062 14C4.70027 14 4.14062 13.4404 4.14062 12.75C4.14062 12.0596 4.70027 11.5 5.39062 11.5C6.08098 11.5 6.64062 12.0596 6.64062 12.75Z" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


		</React.Fragment>
	)
}

export default ShoppingCartIcon