import React, { useEffect, useRef, useState, forwardRef } from "react";
import { getClientIdByProject, _l, generateButtonText } from "../../../hooks/utilities";
import Button from 'react-bootstrap/Button';
import CustomerServices from "../../../services/customer-services";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../store";
import ScheduleFooter from "./ScheduleFooter";
import { showMessage } from "../../../actions/messages";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import { removeDuplicateObjects } from "../../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { getBenchmarkList } from "../../../actions/customer";
import PlusIcon from "../../../assets/icons/PlusIcon";
import MinusIcon from "../../../assets/icons/MinusIcon";

const AccessoriesSchedule = forwardRef(({ handleClose, handleStep, isCreateBenchmark, isEdit = false, readOnlyMode }, ref) => {

    const dispatch = useDispatch();
    const [accessoriesList, setAccessoriesList] = useState(readOnlyMode ? [] : [{ id: 0, description: '', quantity: 0, checked: false }]);
    const [searchString, setSearchString] = useState("");
    const { scheduleDetail, createScheduleDetail } = useSelector((state) => state.benchmarkReducer);
    const [providerCategory, setProviderCategory] = useState(0);
    const [description, setDescription] = useState("");
    const [showLoader, setShowLoader] = useState(0);

    const accessoriesDivRef = useRef(null);

    const project_id = store.getState().customer.selectedProject;
    useEffect(() => {
        if (createScheduleDetail && Object.keys(createScheduleDetail).length) {
            setProviderCategory(createScheduleDetail.providerCategory)
            if (createScheduleDetail.accessories && isEdit) {
                let defaultList = accessoriesList.slice();
                if(defaultList.findIndex((x) => x.id == 0) === -1)
                {
                    defaultList.push({ id: 0, description: '', quantity: 0, checked: false });
                }
                let existingAccessiresList = createScheduleDetail.accessories;
                existingAccessiresList.forEach((x) => {
                    x.checked = true;
                });
                defaultList = defaultList.filter((w) => existingAccessiresList.findIndex((x) => x.acc_id == w.id) === -1);
                setAccessoriesList(removeDuplicateObjects(existingAccessiresList.concat(defaultList)));
            }

        }
    }, [JSON.stringify(createScheduleDetail)]);

    useEffect(() => {
        // if (!isEdit) {
        CustomerServices.getDefaultAccessories().then((res) => {
            if (res.status && res.data && res.data.length) {
                let defaultAccessories = res.data.map((item) => {
                    return {
                        id: item.id,
                        description: item.description,
                        quantity: 0,
                        checked: false
                    }
                });
                setAccessoriesList(defaultAccessories.concat(accessoriesList));
            }
        });
        // }

    }, []);

    // For Scroll
    useEffect(() => {
      if(accessoriesList.length)
      {
        try {
            accessoriesDivRef.current.scrollIntoView();
        }
        catch(e)
        {
            console.error(e);
        }
      }
    }, [accessoriesList])
    

    const handleQuantity = (item, index, count) => {
        if(Number(item.quantity) == 0 && count < 0)
        {
            return false;
        }
        setAccessoriesList(Object.values({ ...accessoriesList, [index]: { ...item, ['quantity']: Number(item.quantity) + count, ['checked']: true } }));
    }

    const addAccessories = (save_and_send) => {
        const startDate = scheduleDetail.start_date || createScheduleDetail.startDate;
        const scheduleId = scheduleDetail.id || createScheduleDetail.benchmarkId;
        const providerId = scheduleDetail.provider_id || createScheduleDetail.providerCategory
        const isUnknownStartDate = startDate === "" || startDate === undefined;
        const validAccessories = accessoriesList.filter(x => x.checked && x.quantity > 0 && x.description);

        let allValidTitles = true;
        let allValidQuantities = true;

        accessoriesList.forEach((x) => {
            if (x.checked) {
                if (!x.description || x.description == "") {
                    allValidTitles = false;
                }
                else if (x.quantity == 0) {
                    allValidQuantities = false;
                }
            }
        })

        if (!allValidTitles) {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_description')));
        }
        else if (!allValidQuantities) {
            dispatch(showMessage('unsucess', _l('l_error'), _l('l_please_enter_quantity')));
        }
        else {
            setShowLoader(save_and_send + 1);
            CustomerServices.addEditBenchmarkAccessories(project_id, scheduleId, validAccessories, startDate, isUnknownStartDate, providerId, getClientIdByProject(), save_and_send, 0, isEdit ? 1 : 0).then((res) => {
                if (res && res.status) {
                    handleClose(save_and_send);
                    dispatch(
                        getBenchmarkList(project_id, providerId)
                      );
                }
            }).finally(() => {
                setShowLoader(0);
            });
        }
    }

    const handleSave = (tab) => {
        if (tab == 'prev') {
            handleStep('create-schedule')
            return
        }
    };

    const handleDescription = (item, index, value) => {
        setDescription(value);
        setAccessoriesList(Object.values({ ...accessoriesList, [index]: { ...item, ['description']: value } }));
    }

    const isDuplicateAccessory = () => {
        const count = accessoriesList.reduce((acc, current) => {
            if(current.description.trim().toLowerCase() == description.trim().toLowerCase())
            {
                return acc + 1;
            }
            return acc;
        }, 0);
        return count != 1;
    }

    const addAccessory = () => {
        if(description && description.trim().length)
        {
            if(isDuplicateAccessory())
            {
                dispatch(showMessage('unsucess', _l('l_error'), _l('l_accessory_already_exists')));
                setDescription("");
            }
            else {
                CustomerServices.createAccessories(description).then((res) => {
                    if (res.status && res.data) {
                        setAccessoriesList([...accessoriesList.filter(a => a.id != 0), { id: res.data.item_id, description: description, quantity: 0, checked: true }].concat([{ id: 0, description: '', quantity: 0, checked: false }]));
                    } else {
                        dispatch(showMessage("unsucess", _l("l_error"), res.message));
                    }
                });
            }
        }
    };


    return (

        <div className={`d-flex flex-column h-100 ${readOnlyMode ? 'for-disabled' : ''}`}>
            {
                readOnlyMode && accessoriesList.findIndex((x) => x.checked && x.description && x.quantity > 0) == -1
                    ?
                    <CommanPlaceholder imgType="todo-task" placeholderText={`${_l('l_no')} ${_l('l_accessories')}`} />
                    :
                    accessoriesList.length ?
                        <React.Fragment>
                            {accessoriesList.map((accessory, index) => {
                                if (searchString && accessory.description && !accessory.description.toLowerCase().trim().includes(searchString.toLowerCase())) {
                                    return <React.Fragment></React.Fragment>
                                }
                                else if(readOnlyMode && (!accessory.description || !accessory.description.length))
                                {
                                    return <React.Fragment></React.Fragment>
                                }
                                return (
                                    <div key={index} className="row m-0 w-100">
                                        <div className="col-xl-10 p-0">
                                            <div className="align-items-center border-0 d-flex p-0 task-checklist">
                                                <div className="checklist-list ps-0 mt-2">
                                                    <div className="form-check">
                                                        <div className="d-flex align-items-center">
                                                            <input type="checkbox" className="form-check-input" checked={accessory.checked}
                                                                onChange={(e) => {
                                                                    setAccessoriesList(Object.values({ ...accessoriesList, [index]: { ...accessory, ['checked']: e.target.checked } }));
                                                                }} />
                                                            {accessory.id != 0 ?
                                                                <div className="comment-input-box ps-2">
                                                                    <input type="text" disabled placeholder={`${_l('l_item_name')}`} value={accessory.description} onChange={(e) => handleDescription(accessory, index, e.target.value)} className="form-control bg-transparent with-base-bg border-0 py-1 px-2" />
                                                                </div>
                                                                :
                                                                <input className="w-100 bg-transparent border-0 checklist_textarea  ps-3 pe-0" placeholder={`${_l('l_enter_accesories_name')}`} onChange={(e) => handleDescription(accessory, index, e.target.value)} onBlur={() => addAccessory()} />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-2  c-input-box pb-2 pe-0 d-flex align-items-center">
                                            <div className="d-flex align-items-center justify-content-between form-control p-2">
                                                <a href="#/" className={`c-font f-22 line-height-0 ${readOnlyMode ? 'd-none' : ''} ${accessory.quantity > 0 ? "" : "for-disabled"}`} onClick={() => handleQuantity(accessory, index, -1)}>
                                                    <MinusIcon className="HW18" />
                                                </a>
                                                <input
                                                    type="number"
                                                    className="bg-transparent border-0 text-center line-height-1"
                                                    placeholder={`${_l('l_enter_quantity')}`}
                                                    value={accessory.quantity}
                                                    onChange={(e) => {
                                                        setAccessoriesList(Object.values({ ...accessoriesList, [index]: { ...accessory, ['quantity']: e.target.value, ['checked']: true } }));
                                                    }}
                                                />
                                                <a href="#/" className={`c-font f-22 line-height-0 ${readOnlyMode ? 'd-none' : ''}`} onClick={() => handleQuantity(accessory, index, 1)}>
                                                    <PlusIcon className="HW18" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {searchString && accessoriesList.findIndex(x => x.description && x.description.toLowerCase().trim().includes(searchString.toLowerCase().trim())) === -1 ?

                                <CommanPlaceholder imgType="todo-task" placeholderText={`${_l('l_no')} ${_l('l_matched_accessories')}`} />
                                : <React.Fragment></React.Fragment>}
                                <div ref={accessoriesDivRef}></div>
                        </React.Fragment>
                        :
                        <CommanPlaceholder imgType="todo-task" placeholderText={`${_l('l_no')} ${_l('l_matched_accessories')}`} />
            }
            <div className={`d-flex justify-content-between mt-auto w-100 pt-3 position-sticky-bottom bg-base-header-color ${readOnlyMode ? 'd-none' : ''}`}>
                <div className="flex-grow-1">
                    <ScheduleFooter selectedTab={`accessories`} handleStep={handleSave} currentStep={4} isCreateBenchmark={isCreateBenchmark} providerCategory={providerCategory} />
                </div>
                <div className="p-3  pe-0 border-top">
                    <Button variant="secondary" size="sm" className={`${(createScheduleDetail && (createScheduleDetail.statusText == "draft" || !createScheduleDetail.statusText)) ? "" : "d-none"} me-3`} disabled={showLoader == 1} onClick={() => { addAccessories(0) }}>{_l('l_save_as_draft')} {showLoader == 1 ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 `}
                        aria-hidden="true"
                    /> : ""} </Button>
                    <Button variant="primary" disabled={showLoader == 2} size="sm" onClick={() => { addAccessories(1) }}>{generateButtonText(createScheduleDetail ? createScheduleDetail.scheduleStatus : "")} {showLoader == 2 ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className={`ms-2 `}
                        aria-hidden="true"
                    /> : ""}</Button>
                </div>
            </div>
        </div>
    );
});

export default AccessoriesSchedule;