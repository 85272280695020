import React from 'react';
 

function CreateBenchmarkIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8.51098 13.9997C7.12413 14.0005 5.73685 13.9997 4.34915 13.9974C3.50214 13.9974 3.00098 13.388 3 12.372C3.00033 10.9079 3.00114 9.44345 3.00244 7.97861V3.64268C3.00244 2.60791 3.50215 2.00498 4.36429 2.00205C6.45591 1.99932 8.54752 1.99932 10.6391 2.00205C11.5018 2.00205 11.9995 2.60557 12 3.64268V8.71951M4.98098 4.5H9.93343M4.98098 6.5H8.94294M4.98098 8.5H7.4572M12.0366 11V14.1008M13.5713 12.5499H10.5" strokeMiterlimit="10" strokeLinecap="round"/>
</svg>

		</React.Fragment>
	)
}

export default CreateBenchmarkIcon