import React from 'react';
 

function SubscriptionplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="88" height="79" viewBox="0 0 88 79" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M77.541 23.5059L60.562 40.4849C59.6622 41.3847 59.1087 41.3847 58.2224 40.5164C55.961 38.2519 53.6981 35.9874 51.4336 33.723"  strokeLinecap="round" strokeLinejoin="round" />
				<path d="M38.6736 45.2719C38.6912 42.7395 37.996 40.2531 36.6674 38.0971C35.3388 35.9411 33.4304 34.2024 31.1604 33.0798C26.0406 30.6188 21.0918 31.0957 16.5703 34.5239C13.0612 37.1828 11.3785 40.8224 11.248 45.2719C11.248 45.2719 23.4627 54.9986 38.6736 45.2719Z"  strokeMiterlimit="10" />
				<path d="M33.2262 23.3798C33.2351 21.7484 32.7595 20.151 31.8599 18.79C30.9603 17.429 29.677 16.3656 28.1726 15.7345C26.6681 15.1033 25.0102 14.9329 23.4088 15.2447C21.8074 15.5566 20.3347 16.3366 19.177 17.4862C18.0193 18.6357 17.2288 20.1029 16.9056 21.702C16.5823 23.3011 16.741 24.9602 17.3614 26.4691C17.9818 27.978 19.036 29.2688 20.3906 30.1781C21.7452 31.0874 23.3391 31.5742 24.9706 31.5769C27.1496 31.5758 29.24 30.7137 30.7863 29.1783C32.3326 27.643 33.2096 25.5588 33.2262 23.3798Z"  strokeMiterlimit="10" />
				<path d="M87.3932 17.2839C87.3662 15.2594 87.3932 13.2349 87.3932 11.2103C87.3482 5.06928 83.3846 1.02025 77.2886 1.01575C55.2528 0.994751 33.2155 0.994751 11.1766 1.01575C5.05808 1.01575 1.02699 5.06478 1.018 11.1564C0.994001 25.9999 0.994001 40.8464 1.018 55.6959C1.018 61.792 5.067 65.7825 11.2036 65.814C16.7507 65.841 22.3024 65.7285 27.8496 65.886C29.2189 65.9704 30.5227 66.5014 31.5613 67.3976C34.5531 70.097 37.32 73.0798 40.1948 75.9456C42.9482 78.672 45.4451 78.6855 48.1804 75.9816C51.0598 73.1383 53.8266 70.1735 56.8184 67.4336C57.8454 66.5248 59.1439 65.9807 60.512 65.886C66.0547 65.7195 71.6064 65.832 77.1581 65.8095C83.4116 65.7825 87.3887 61.7964 87.4067 55.5249C87.4292 48.1017 87.4067 40.6784 87.4067 33.2597L87.3932 17.2839Z"  strokeMiterlimit="10" />
			</svg>

		</React.Fragment>
	)
}

export default SubscriptionplaceholderIcon