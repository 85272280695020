import { ADD_LOADER_ITEM, REMOVE_LOADER_ITEM, SET_RESTRICTION_MESSAGE, TOGGLE_FEATURE_RESTRICTION_POP_UP } from "./general-actions-types";

export const addLoaderItem = (url) => (dispatch) => {
  dispatch({
    type: ADD_LOADER_ITEM,
    payload: { url },
  });
};

export const removeLoaderItem = (url) => (dispatch) => {
  dispatch({
    type: REMOVE_LOADER_ITEM,
    payload: { url },
  });
};

export const toggleFeatureRestrictionModal = (flag) => (dispatch) => {
  dispatch({
    type: TOGGLE_FEATURE_RESTRICTION_POP_UP,
    payload: { flag },
  });
};

export const setRestricitonMessage = (message) => (dispatch) => {
  dispatch({
    type: SET_RESTRICTION_MESSAGE,
    payload: { message },
  });
};