import React from 'react';
 

function UserGearCircleIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>



<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M8 10C9.38071 10 10.5 8.88071 10.5 7.5C10.5 6.11929 9.38071 5 8 5C6.61929 5 5.5 6.11929 5.5 7.5C5.5 8.88071 6.61929 10 8 10ZM8 10C7.16899 10 6.35421 10.2297 5.64597 10.6644C4.93773 11.0992 4.3637 11.7215 3.9875 12.4625M8 10C8.83102 10 9.6458 10.2297 10.354 10.6644C11.0623 11.0992 11.6363 11.7215 12.0125 12.4625M12.5 4.5C13.0523 4.5 13.5 4.05228 13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5M12.5 4.5C11.9477 4.5 11.5 4.05228 11.5 3.5C11.5 2.94772 11.9477 2.5 12.5 2.5M12.5 4.5V5.25M12.5 2.5V1.75M11.6313 3L10.9875 2.625M11.6313 4L10.9875 4.375M13.3688 4L14.0125 4.375M13.3688 3L14.0125 2.625M13.9563 7.28125C13.9866 7.51962 14.0012 7.75972 14 8C14 9.18669 13.6481 10.3467 12.9888 11.3334C12.3295 12.3201 11.3925 13.0892 10.2961 13.5433C9.19975 13.9974 7.99335 14.1162 6.82946 13.8847C5.66558 13.6532 4.59648 13.0818 3.75736 12.2426C2.91825 11.4035 2.3468 10.3344 2.11529 9.17054C1.88378 8.00666 2.0026 6.80026 2.45673 5.7039C2.91085 4.60754 3.67989 3.67047 4.66658 3.01118C5.65328 2.35189 6.81331 2 8 2C8.18572 1.99883 8.37138 2.00717 8.55625 2.025" />
</svg>




	


		</React.Fragment>
	)
}

export default UserGearCircleIcon