import React from 'react';
 

function RectangleCheckIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M35 0H0L40 40V5C40 2.23858 37.7614 0 35 0Z" fill="url(#paint0_linear_13_6)" />
				<g clip-path="url(#clip0_13_6)">
					<path d="M23.875 11.75L26.5 14.375L32.5 8.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<linearGradient id="paint0_linear_13_6" x1="0" y1="0" x2="40" y2="41.0127" gradientUnits="userSpaceOnUse">
						<stop stop-color="#00FF6A" />
						<stop offset="1" stop-color="#00FDD5" />
					</linearGradient>
					<clipPath id="clip0_13_6">
						<rect width="12" height="12" fill="white" transform="translate(22 5)" />
					</clipPath>
				</defs>
			</svg>



		</React.Fragment>
	)
}

export default RectangleCheckIcon