import React from 'react';
import { _l } from '../../../hooks/utilities';
import BenchmarkSend from '../../../assets/icons/BenchmarkSend';

const MessagePlaceHolderScreen = ({title, description, image, className=""}) => {
  return (
    <div className={`comman-content-scroll-wrapper ${className !="" ? className :""}`}>
    <div className="comman-content-scroll h-100 overflow-hiiden-auto res-overflow-visible">
      <div className="row justify-content-center h-100">
        <div className="col-xl-12 h-100">
          <div className="d-flex flex-column justify-content-center align-items-center h-100 c-font f-12">
            <div className="d-flex align-items-center p-1">
              <BenchmarkSend
                size={16}
                weight="light"
                className="c-icons h70w70 opacity-50"
              />
            </div>
            <div className="title-fonts fw-semibold mb-2  c-font f-16 pt-20px">
              {_l(description)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MessagePlaceHolderScreen