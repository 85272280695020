import React from 'react';
 

function LaptopIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<g clipPath="url(#clip0_15203_255280)">
<g clipPath="url(#clip1_15203_255280)">
<path d="M2.5 11V4.5C2.5 4.23478 2.60536 3.98043 2.79289 3.79289C2.98043 3.60536 3.23478 3.5 3.5 3.5H12.5C12.7652 3.5 13.0196 3.60536 13.2071 3.79289C13.3946 3.98043 13.5 4.23478 13.5 4.5V11"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.5 11H14.5V12C14.5 12.2652 14.3946 12.5196 14.2071 12.7071C14.0196 12.8946 13.7652 13 13.5 13H2.5C2.23478 13 1.98043 12.8946 1.79289 12.7071C1.60536 12.5196 1.5 12.2652 1.5 12V11Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 5.5H7"  strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_15203_255280">
<rect width="16" height="16"/>
</clipPath>
<clipPath id="clip1_15203_255280">
<rect width="16" height="16"/>
</clipPath>
</defs>
</svg>



		</React.Fragment>
	)
}

export default LaptopIcon