import React, { useState, useEffect } from "react";
import CommanLoader from "../../Loader/CommanLoader";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomerServices from "../../../services/customer-services";
import EmployeeListing from "../../TaskModalComponents/EmployeeListing";
import { _l } from "../../../hooks/utilities";
import OperatorCard from "./OperatorCard";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import EqualsIcon from "../../../assets/icons/EqualsIcon";
import InfoIcon from "../../../assets/icons/InfoIcon";
const OperatorStatusTable = ({
  projectId,
  getProvider,
  taskTotalCount,
  showLoader,
  selectedProviderId = []
}) => {
  const { isAppliedUnreadComment, taskFilters } = useSelector((state) => state.customer);
  const [getTaskList, setTaskList] = useState([])
  const [totalTime, setTotalTime] = useState("");
  const [totalToDoTime, setTotalToDoTime] = useState("00:00");
  const [totalInProgressTime, setTotalInProgressTime] = useState("00:00");
  const [totalDoneTime, setTotalDoneTime] = useState("00:00");
  const [showListLoader, setShowListLoader] = useState(false);
  const [newProviderList, setNewProviderList] = useState([])
  const { globalSearchString  } = useSelector((state) => state.customer);
  const [operatorSearchString, setOperatorSearchString] = useState("")
  const timeConvert = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + ":" + rminutes;
  };
  useEffect(() => {
    if (globalSearchString !== "" || globalSearchString !== undefined) {
      setOperatorSearchString(globalSearchString);
    }
}, [globalSearchString])
  useEffect(() => {
    if (selectedProviderId.length == 0) {
      setNewProviderList(getProvider)
    }
    let todoCount = 0;
    let inProgressCount = 0;
    let doneCount = 0;
    let tempTotalTime = "00:00";
    let inProgresTime = "00:00";
    let doneTime = "00:00";
    let inprogress_minute = 0;
    let inprogress_hour = 0;
    let completed_minute = 0;
    let completed_hour = 0;
    let todo_minute = 0;
    let todo_hour = 0;
    let total_time_minute = 0;
    let total_time_hour = 0;
    if (getProvider.length) {
      for (let i = 0; i < getProvider.length; i++) {
        inprogress_hour += parseInt(
          getProvider[i].ongoing_task_total_time.split(":")[0]
        )
          ? parseInt(getProvider[i].ongoing_task_total_time.split(":")[0]) * 60
          : 0;
        inprogress_minute += parseInt(
          getProvider[i].ongoing_task_total_time.split(":")[1]
        )
          ? parseInt(getProvider[i].ongoing_task_total_time.split(":")[1])
          : 0;
        todo_hour += parseInt(
          getProvider[i].to_do_task_total_time.split(":")[0]
        )
          ? parseInt(getProvider[i].to_do_task_total_time.split(":")[0]) * 60
          : 0;
        todo_minute += parseInt(
          getProvider[i].to_do_task_total_time.split(":")[1]
        )
          ? parseInt(getProvider[i].to_do_task_total_time.split(":")[1])
          : 0;
        completed_hour += parseInt(
          getProvider[i].completed_task_total_time.split(":")[0]
        )
          ? parseInt(getProvider[i].completed_task_total_time.split(":")[0]) *
          60
          : 0;
        completed_minute += parseInt(
          getProvider[i].completed_task_total_time.split(":")[1]
        )
          ? parseInt(getProvider[i].completed_task_total_time.split(":")[1])
          : 0;
        total_time_hour += parseInt(getProvider[i].total_time.split(":")[0])
          ? parseInt(getProvider[i].total_time.split(":")[0]) * 60
          : 0;
        total_time_minute += parseInt(getProvider[i].total_time.split(":")[1])
          ? parseInt(getProvider[i].total_time.split(":")[1])
          : 0;
      }
      getProvider.map((Employee) => {
        todoCount += parseInt(Employee.todo_count);
        inProgressCount += parseInt(Employee.ongoing_count);
        doneCount += parseInt(Employee.completed_count);
        totalTimeCount(tempTotalTime, Employee.to_do_task_total_time);
        totalTimeCount(inProgresTime, Employee.ongoing_task_total_time);
        totalTimeCount(doneTime, Employee.completed_task_total_time);
      });
    }
    setTotalDoneTime(timeConvert(completed_hour + completed_minute));
    setTotalToDoTime(timeConvert(todo_hour + todo_minute));
    setTotalInProgressTime(timeConvert(inprogress_hour + inprogress_minute));
    setTotalTime(timeConvert(total_time_hour + total_time_minute));
  }, [getProvider]);
  const totalTimeCount = (time1, time2) => {
    var hour = 0;
    var minute = 0;
    var second = 0;
    var splitTime1 = time1.split(":");
    var splitTime2 = time2.split(":");
    hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0]);
    minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1]);
    hour = hour + minute / 60;
    minute = minute % 60;
    second = parseInt(splitTime1[2]) + parseInt(splitTime2[2]);
    minute = minute + second / 60;
    second = second % 60;
    return hour + ":" + minute;
  };
  const taskList = (status, staffid) => {
    setShowListLoader(true)
    let unReadFilter = isAppliedUnreadComment ? isAppliedUnreadComment : false
    let fromDate = localStorage.getItem('selectedDate') ? moment(localStorage.getItem('selectedDate')).format("YYYY-MM-DD") : new Date()
    let toDate = ''
    let dateRangeFilters = taskFilters && taskFilters.length ? taskFilters.filter((dateRangeFilter) => dateRangeFilter.main_key == "date_range_filter") : []
    if (dateRangeFilters && dateRangeFilters.length) {
      fromDate = localStorage.getItem('fromDate')
      toDate = localStorage.getItem('toDate')
    }
    CustomerServices.getEmployeeTask(status, staffid, unReadFilter, fromDate != '' ? fromDate : '', toDate).then((res) => {
      setShowListLoader(false)
      if (res.status) {
        setTaskList(res.data);
      }
    });
  }
  const updatedCommentCount = (id) => {
    let taskTemp = getTaskList.map((task) => {
      if (task.id == id) {
        task['comments_count'] = parseInt(task['comments_count']) + 1
        return task
      }
      return task;
    })
    setTaskList(taskTemp)
  }
  useEffect(() => {
    if (selectedProviderId.length > 0) {
      let tempProvider = getProvider.filter((employee) => selectedProviderId.includes(employee.id))
      setNewProviderList(tempProvider)
    } else {
      setNewProviderList(getProvider)
    }
  }, [JSON.stringify(taskFilters), selectedProviderId, JSON.stringify(getProvider)])
  return (
    <>
      <div className="comman_vertical_btn_h  comman-white-box-shadow light-theme-white-bg">
          <div className="comman-data-table employee-table small-data-table overflow-auto comman-content-scroll-wrapper flex-grow-1">
            <table className="dataTable text-center bgleftpanelnew lightthemebgwhite">
              <thead className="comman-white-box-shadow">
                <tr>
                  <th style={{ width: "20%" }} className="text-start">
                  {_l('l_name_and_progress')}
                  </th>
                  <th style={{ width: "20%" }}>{_l('l_totla_time_HH:MM')}</th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots  c-7 bg-warning rounded-circle me-2"></span>
                    <span>{_l('l_request')}</span>
                  </th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots  c-7 in-progress-white rounded-circle me-2"></span>
                    <span>{_l('l_todo_kpi_name')}</span>
                  </th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots c-7 rounded-circle in-progress-blue me-2"></span>
                    <span>{_l('l_ongoing_kpi')}</span>
                  </th>
                  <th style={{ width: "15%" }}>
                    <span className="c-dots  c-7 done rounded-circle me-2"></span>
                    <span>{_l('l_done')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {showLoader ? (
                  <>
                    <CommanLoader className="comman-main-loader-wrapper" />
                  </>
                ) : newProviderList && newProviderList.length ? (
                  newProviderList.filter((employee) =>employee.name &&employee.name.toLowerCase().includes(operatorSearchString.toLowerCase())).map((Employee) => {
                    return (
                      <OperatorCard Employee={Employee} projectId={projectId} taskList={taskList} updatedCommentCount={updatedCommentCount} setTaskList={setTaskList} />
                    );
                  })
                ) : (
                  <tr className="">
                    <td className="border-0" colSpan={6}>
                      <CommanPlaceholder imgType="no-user" placeholderText = {_l('l_no_employee_found')} />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot className="position-sticky-bottom bottom_51px">
                <tr
                  className={` c-font f-15 color-white-80 ${getProvider && getProvider.length ? "" : ""
                    }`}
                >
                  <td className="tfoot-rounded text-start" style={{ width: "20%" }}>
                    <div className="tfoot-rounded me-3">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="me-2">
                          <EqualsIcon className="HW20" />
                        </a>
                        <span className="">
                          {_l('l_total_count')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="tfoot-rounded-start" style={{ width: "20%" }}>
                    <div className="border-right ">
                      {totalTime != "0:0" ? totalTime : "00:00"}
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "15%" }}>
                    <div className="border-right ">
                      {Object.keys(taskTotalCount).length
                        ? taskTotalCount.requested_task_count
                        : 0}
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalToDoTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalToDoTime != "0:0" ? totalToDoTime : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "15%" }}>
                    <div className="border-right ">
                      {Object.keys(taskTotalCount).length
                        ? taskTotalCount.to_do_task_count
                        : 0}
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalToDoTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalToDoTime != "0:0" ? totalToDoTime : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0" style={{ width: "15%" }}>
                    <div className="border-right -d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.on_going_task_count
                          ? taskTotalCount.on_going_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60 ps-2 ${totalInProgressTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalInProgressTime != "0:0"
                          ? totalInProgressTime
                          : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="tfoot-rounded-end" style={{ width: "15%" }}>
                    <div className="-d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.completed_task_count
                          ? taskTotalCount.completed_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalDoneTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalDoneTime != "0:0" ? totalDoneTime : "00:00"}
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
              <tfoot className="position-sticky-bottom d-none">
                <tr
                  className={` ${getProvider && getProvider.length ? "" : " d-none"
                    }`}
                >
                  <td className="tfoot-rounded text-start">
                    <div className="tfoot-rounded me-3">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="me-2">
                          <EqualsIcon className="HW20" />
                        </a>
                        <span className="">
                        {_l('l_total_count')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="tfoot-rounded-start">
                    <div className="border-right ">
                      {totalTime != "0:0" ? totalTime : "00:00"}
                    </div>
                  </td>
                  <td className="rounded-0">
                    <div className="border-right ">
                      {Object.keys(taskTotalCount).length
                        ? taskTotalCount.to_do_task_count
                        : 0}
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalToDoTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalToDoTime != "0:0" ? totalToDoTime : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="rounded-0">
                    <div className="border-right -d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.on_going_task_count
                          ? taskTotalCount.on_going_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60 ps-2 ${totalInProgressTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalInProgressTime != "0:0"
                          ? totalInProgressTime
                          : "00:00"}
                      </span>
                    </div>
                  </td>
                  <td className="tfoot-rounded-end">
                    <div className="-d-flex ">
                      <span className="">
                        {Object.keys(taskTotalCount).length && taskTotalCount.completed_task_count
                          ? taskTotalCount.completed_task_count
                          : 0}
                      </span>
                      <span
                        className={` c-font f-15 color-white-60  ps-2 ${totalDoneTime == "0:0" ? "d-none" : ""
                          }`}
                      >
                        {totalDoneTime != "0:0" ? totalDoneTime : "00:00"}
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            <table className="dataTable text-center mt-auto position-sticky-bottom comman-white-box-shadow">
            </table>
          </div>
        <div className="comman-content-bottom-btn d-none">
          <div className="comman-data-table">
            <table className="dataTable title-fonts small-padding">
              <tfoot>
                <tr className={``}>
                  <td className="me-3" style={{ width: "30%" }}>
                    <div className="name-with-progress max-w400">
                      <div className="d-flex align-items-center">
                        <a href="#/" className="me-2">
                        <InfoIcon className="HW20" />
                        </a>
                        <span className="">
                          {_l('l_total_count')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                  <td className="" style={{ width: "15%" }}>
                    Shipping cost
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <EmployeeListing list={getTaskList.map((task) => {
        return {
          task_name: task.task_name ? task.task_name : '',
          name: task.creator_details && task.creator_details.full_name ? task.creator_details.full_name : '',
          image: task.creator_details && task.creator_details.profile_image_url ? task.creator_details.profile_image_url : '',
          id: task.id ? task.id : '0',
          comments_count: task.comments_count ? task.comments_count : '0',
          unread_comment_count: task.unread_comment_count ? task.unread_comment_count : '0'
        };
      })} handler={() => { }} showLoader={showListLoader} getTaskList={getTaskList} setTaskList={setTaskList} />
    </>
  );
};
export default OperatorStatusTable;
