import moment from "moment";
import apiService from "./api-service/api-service";
import { newFilterFormate } from "../hooks/utilities";

const API_URL = process.env.REACT_APP_API_URL;

const getVirtualSpaceList = () => {
  const endPoint = API_URL + "mod=space&act=get_virtual_space_listing";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const setFavouriteVirtualSpace = (project_id, is_selected) => {
  const endPoint = API_URL + "mod=space&act=add_space_to_favourite";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("is_selected", is_selected);
  return apiService(endPoint, "POST", true, form);
};

const getMyRStaffList = (project_id) => {
  const endPoint = API_URL + "mod=space&act=get_myr_staff_list";
  let form = new FormData();
  if (project_id) { 
    form.append("project_id", project_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const createVirtualSpace = (members, title, project_id = 0) => {
  const endPoint = API_URL + "mod=space&act=add_edit_virtual_space";
  let form = new FormData();
  form.append("name", title);
  if (project_id > 0) {
    form.append("project_id", project_id);
  }
  members.forEach((member, index) => {
    form.append(`assignees[${index}]`, member);
  });
  return apiService(endPoint, "POST", true, form);
};

const getLeads = (page, status = [], filters = [], leadsFilters, globalSearchString) => {
  const endPoint = API_URL + "mod=leads&act=get_leads_list";
  let form = new FormData();
  form.append("page", page);
  if (globalSearchString !== '') {
    form.append(`search`, globalSearchString);
  }
  if (status && status.length) {
    status.forEach((item, index) => {
        form.append(`status[${index}]`, item);
    });
  }
    form.append(
      "filters[from_date]",
      localStorage.getItem("selectedDate")
        ? moment(localStorage.getItem("selectedDate")).format("YYYY-MM-DD")
        : ""
    );
    
  Object.keys(leadsFilters).forEach((key) => {
    const values = leadsFilters[key];
    if (values && values.length > 0) {
        form.append(`filters[${key}]`, leadsFilters[key]);
    }
  })
  // if (filters.length) {
  //   let formattedList = [];
  //   filters.forEach((filter) => {
  //     if (formattedList[filter.main_key]) {
  //       formattedList[filter.main_key].push(filter.filter_key);
  //     } else {
  //       formattedList[filter.main_key] = [filter.filter_key];
  //     }
  //     // form.append(`filters[${filter.main_key}][${index}]`, filter.filter_key);
  //   });
  //   Object.keys(formattedList).forEach((category) => {
  //     formattedList[category].forEach((value, index) => {
  //       if (category == "date_range_filter") {
  //         if (value != "all") {
  //           form.append(`filters[from_date]`, localStorage.getItem("fromDate"));
  //           form.append(`filters[to_date]`, localStorage.getItem("toDate"));
  //         }
  //         form.delete("startdate");
  //       } else {
  //         form.append(`filters[${category}][${index}]`, value);
  //       }
  //     });
  //   });
  // }
  if (filters && filters.length) {
    let formattedList = [];
    filters.forEach((filter) => {
      if (formattedList[filter.main_key] && formattedList[filter.main_key] != undefined) {
        formattedList[filter.main_key].push(filter.filter_key);
      } else {
        formattedList[filter.main_key] = [filter.filter_key];
      }
    });
    Object.keys(formattedList).forEach((category) => {
      formattedList[category].forEach((value) => {
        if (category == "date_range_filter") {
          if (value != "all") {
            form.delete("filters[from_date]");
            if(moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD") == moment(localStorage.getItem("toDate")).format("YYYY-MM-DD")){
              form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
            }else{
              form.append(`filters[from_date]`, moment(localStorage.getItem("fromDate")).format("YYYY-MM-DD"));
              form.append(`filters[to_date]`, moment(localStorage.getItem("toDate")).format("YYYY-MM-DD"));
            }
          }else if (value == "all") {
            form.delete("filters[from_date]");
          }
          
        }
      });
    });
    newFilterFormate(filters).forEach((fil) => {
      if(localStorage.getItem("contact_role") == 3){
        if (fil.main_key == "date_range_filter") {
        form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
        }
      }else{
        form.append(`filters[${fil.main_key}]`, JSON.stringify([fil]));
      }
    })
    if (filters.length > 0 && filters.some((filter)=> filter.main_key == "unread_comments")) {
      form.delete("filters[from_date]");
      form.delete("filters[to_date]");
      
    }
  }
  return apiService(endPoint, "POST", true, form);
};

const getLeadDetail = (lead_id, slug) => {
  const endPoint = API_URL + "mod=leads&act=get_lead_details";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("slug", slug);
  return apiService(endPoint, "POST", true, form);
};

const changeLeadStatus = (lead_id, status) => {
  const endPoint = API_URL + "mod=leads&act=change_lead_status";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("status", status);
  return apiService(endPoint, "POST", true, form);
};

const setUnsetHotLead = (lead_id) => {
  const endPoint = API_URL + "mod=leads&act=set_hot_lead";
  let form = new FormData();
  form.append("lead_id", lead_id);
  return apiService(endPoint, "POST", true, form);
};

const uploadLeadAttachment = (lead_id, files) => {
  const endPoint = API_URL + "mod=leads&act=add_lead_attachments";
  let form = new FormData();
  form.append("lead_id", lead_id);
  Array.from(files).forEach((file, index) => {
    form.append(`file[${index}]`, file);
  });
  return apiService(endPoint, "POST", true, form);
};

const removeLeadAttachment = (lead_id, file_id) => {
  const endPoint = API_URL + "mod=leads&act=delete_lead_attachment";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("file_id", file_id);
  return apiService(endPoint, "POST", true, form);
};

const addEditReminder = (
  reminderId,
  lead_id,
  selected_staff_id,
  description,
  date,
  notifyByEmail,
) => {
  const endPoint = API_URL + "mod=leads&act=add_reminder";
  let form = new FormData();
  form.append("lead_id", lead_id);
  if (reminderId) {
    form.append("reminder_id", reminderId);
  }
  form.append("selected_staff_id", selected_staff_id);
  form.append("notify_by_email", notifyByEmail);
  form.append("description", description);
  form.append("date", date);
  return apiService(endPoint, "POST", true, form);
};

const deleteReminder = (reminderId) => {
  const endPoint = API_URL + "mod=leads&act=delete_reminder";
  let form = new FormData();
  form.append("reminder_id", reminderId);
  return apiService(endPoint, "POST", true, form);
};

const getLeadActivities = (lead_id) => {
  const endPoint = API_URL + "mod=leads&act=get_lead_activity_list";
  let form = new FormData();
  form.append("lead_id", lead_id);
  return apiService(endPoint, "POST", true, form);
};

const addLeadComment = (lead_id, comment, activity_id = 0) => {
  const endPoint = API_URL + "mod=leads&act=add_lead_activity";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("description", comment);
  if (activity_id > 0) {
    form.append("activity_id", activity_id);
  }
  return apiService(endPoint, "POST", true, form);
};

const getLeadNoteList = (lead_id) => {
  const endPoint = API_URL + "mod=leads&act=get_lead_note_list";
  let form = new FormData();
  form.append("lead_id", lead_id);
  return apiService(endPoint, "POST", true, form);
};

const addEditLeadNote = (note) => {
  const endPoint = API_URL + "mod=leads&act=add_lead_note";
  let form = new FormData();
  Object.keys(note).forEach((key) => {
    if (["date_contacted", "date_reminder"].includes(key)) {
      form.append(key, moment(note[key]).format("YYYY-MM-DD hh:mm:ss"));
    } else if (key != "note_id" || note["note_id"] != 0) {
      form.append(key, note[key]);
    }
  });
  return apiService(endPoint, "POST", true, form);
};

const deleteNote = (lead_id, note_id) => {
  const endPoint = API_URL + "mod=leads&act=delete_lead_note";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("note_id", note_id);
  return apiService(endPoint, "POST", true, form);
};

const getLeadSources = () => {
  const endPoint = API_URL + "mod=leads&act=filters_list";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
};

const leadBulkActions = (data) => {
  const endPoint = API_URL + "mod=leads&act=lead_bulk_action";
  let form = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] !== null) {
        form.append(key, data[key]);
    }
  })
  return apiService(endPoint, "POST", true, form);
};

const createNewLead = (data, customFieldInput) => {
  const endPoint = API_URL + "mod=leads&act=add_new_lead";
  let form = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] !== null) {
      form.append(key, data[key]);
    }
  })
  Object.keys(customFieldInput).forEach((key) => {
    // if (data[key] != null) {
    if (!!customFieldInput[key] && customFieldInput[key].constructor === Object) {
      form.append(key, customFieldInput[key]['value']);
    } else {
      form.append(key, customFieldInput[key] instanceof Date && !isNaN(customFieldInput[key]) ? moment(customFieldInput[key]).format("YYYY-MM-DD HH:MM:SS") : customFieldInput[key]);
    }
  })
  return apiService(endPoint, "POST", true, form);
};

const convertLead = (data, customFieldInput, spaceDetails, is_add_operator) => {
  const endPoint = API_URL + "mod=leads&act=lead_convert_to_customer";
  let form = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] !== null) {
      form.append(key, data[key]);
    }
  })
  Object.keys(spaceDetails).forEach((key) => {
    if (spaceDetails[key] !== null) {
      if (key == 'typeOfSpace') {
        if (spaceDetails[key] && spaceDetails[key].length) {
          for (let i = 0; i < spaceDetails[key].length; i++) {
            form.append(`type_of_space[${i}][title]`, spaceDetails[key][i].title);
            form.append(`type_of_space[${i}][quantity]`, spaceDetails[key][i].quantity);
          }
        }
      } else if (key == 'space_type') {
        form.append("space_type", "industry_type_" + spaceDetails[key].value);
      } else if (key == 'region_id' || key == 'space_type') {
        if (spaceDetails[key] != '') {
          form.append(key, spaceDetails[key].value);
        }
      } else {
        form.append(key, spaceDetails[key]);
      }
    }
  })
  form.append('is_add_operator', is_add_operator);
  return apiService(endPoint, "POST", true, form);
}

const deleteLeadActivity = (lead_id, activity_id) => {
  const endPoint = API_URL + "mod=leads&act=delete_lead_activity";
  let form = new FormData();
  form.append("lead_id", lead_id);
  form.append("activity_id", activity_id);
  return apiService(endPoint, "POST", true, form);
}

const getAllProjectMembers = () => {
  const endPoint = API_URL + "mod=general&act=get_project_members";
  let form = new FormData();
  return apiService(endPoint, "POST", true, form);
}

const getLeadContactComments = (lead_id, rel_type ) => {
  const endPoint = API_URL + "mod=comment&act=get_comments";
  let form = new FormData();
  form.delete("rel_id")
  form.append("rel_id", lead_id);
  form.append("rel_type", rel_type);
  return apiService(endPoint, "POST", true, form);
};
export default {
  getVirtualSpaceList,
  getMyRStaffList,
  getLeadSources,
  createVirtualSpace,
  getLeads,
  getLeadDetail,
  changeLeadStatus,
  setUnsetHotLead,
  uploadLeadAttachment,
  removeLeadAttachment,
  getLeadActivities,
  addLeadComment,
  getLeadNoteList,
  addEditLeadNote,
  deleteNote,
  addEditReminder,
  deleteReminder,
  leadBulkActions,
  createNewLead,
  convertLead,
  setFavouriteVirtualSpace,
  deleteLeadActivity,
  getAllProjectMembers,
  getLeadContactComments
};
