import React, { useEffect, useState } from "react";
import { Row, Spinner, Accordion } from "react-bootstrap";
import { Col } from "reactstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import RectangleCheckIcon from "../../../assets/icons/RectangleCheckIcon";
import { _l, getPreviewFromURL, showError } from "../../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FloorPlanSummary } from "../../SpaceOnBoarding/SpaceDetails/FloorPlanSummary";
import CustomerServices from "../../../services/customer-services";
import {
  showConfirmation,
  showMessage,
  toastCloseAction,
} from "../../../actions/messages";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentSetupModal from "../../Modals/PaymentSetupModal";
import ChecklistComponent from "../../TaskModalComponents/ChecklistComponent";
import DocumentsServices from "../../../services/documents-services";
import {
  getBenchmarkDetail,
  getCustomerSpaces,
} from "../../../actions/customer";
import {
  setRestricitonMessage,
  toggleFeatureRestrictionModal,
} from "../../../actions/general-actions/general-actions";
import SignatureConfirmationIdentity from "../../Modals/SignatureConfirmationIdentity";
import DocumentModal from "../../Modals/DocumentModal";
import Select from "react-select";
import BuildingsIcon from "../../../assets/icons/BuildingsIcon";
import CloudArrowUpIcon from "../../../assets/icons/CloudArrowUpIcon";
import PencilSimpleLineIcon from "../../../assets/icons/PencilSimpleLineIcon";
import CalendarBlankIcon from "../../../assets/icons/CalendarBlankIcon";
import DatePicker from "react-datepicker";
 

const AddProposalAmount = ({ onFloorSelect }) => {
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");

  const { benchmarkDetail } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const [amountByAccessory, setAmountByAccessory] = useState(
    benchmarkDetail.accessories || []
  );
  const [showPaymentSetupSteps, setShowPaymentSetupSteps] = useState(false);
  const [amount, setAmount] = useState(
    benchmarkDetail.operator_total == ""
      ? ""
      : Number(benchmarkDetail.operator_total) -
          benchmarkDetail.accessories.reduce(
            (total, obj) => total + Number(obj.amount),
            0
          )
  );
  const [customInvoice, setCustomInvoice] = useState();
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [invoiceAttachment, setInvoiceAttachment] = useState();
  const [submittingProposal, setSubmittingProposal] = useState(false);
  const [invoicePreview, setInvoicePreview] = useState({
    name: "",
    url: "",
    icon: "",
  });
  const [autoSubScribe, setAutoSubScribe] = useState(false);
  const [proposal, setProposal] = useState({});
  const [itemCode, setItemCode] = useState("");
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [documentModalData, setDocumentModalData] = useState({});
  const [providerWalletList, setProviderWalletList] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState();
  const [unitTasks, setUnitTasks] = useState([]);
  const [customFieldValues, setCustomFieldValues] = useState([]);
  const [benchmarkCustomField, setBenchmarkCustomField] = useState([]);
  const navigate = useNavigate();
  const viewPage = window.location.pathname;
  const [searchParams] = useSearchParams();

  const dashboardProposalId = !isNaN(Number(searchParams.get("dpgf-proposal-id"))) && Number(searchParams.get("dpgf-proposal-id")) ? Number(searchParams.get("dpgf-proposal-id")) : 0;

  const validAmounts = () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      showError("l_please_enter_amount_for_schedule");
      return false;
    }
    let allValidAmounts = true;
    amountByAccessory.forEach((accessory) => {
      if (
        !accessory.amount ||
        isNaN(accessory.amount) ||
        Number(accessory.amount) <= 0
      ) {
        allValidAmounts = false;
      }
    });
    if (providerWalletList.length && !selectedWallet) {
      showError("l_please_select_wallet");
      allValidAmounts = false;
      return;
    }
    if (!allValidAmounts) {
      showError("l_please_enter_amount_for_accessories");
    }
    return allValidAmounts;
  };

  const isAllowedToBidProposal = () => {
    if (benchmarkDetail.permission_denied == 1) {
      let message = `${_l("l_bid_used")}: ${
        benchmarkDetail.package_bid_limit
      } \n ${_l("l_upgrade_price")}: ${
        benchmarkDetail.package_bid_amount
      } \n ${_l("l_tax")}: ${benchmarkDetail.package_bid_amount_tax}`;
      dispatch(toggleFeatureRestrictionModal(true));
      dispatch(setRestricitonMessage(message));
      return false;
    } else {
      return true;
    }
  };

  const validCustomeField = () => {
    let allVaild = true;
    customFieldValues.forEach((customField) => {
      if (customField.is_required == 1 && (!customField.value || customField.value.trim() == "")) {
        showError(customField.title);
        allVaild = false;
      }
    });
    return allVaild;
  };

  const submitProposal = () => {
    if (!termsAndConditions) {
      showError("l_please_accept_terms_and_conditions");
    } else if (validAmounts() && isAllowedToBidProposal() && validCustomeField()) {
      setSubmittingProposal(true);
      const finalCustomeFields = customFieldValues.map(cu => {
        return ({
          field_id: cu.id,
          value: cu.value
        })
      })
      CustomerServices.addEditProposalAmount(
        benchmarkDetail.proposal_id,
        amount,
        "",
        [],
        amountByAccessory,
        customInvoice ? 1 : 0,
        invoiceAttachment,
        benchmarkDetail.is_external,
        benchmarkDetail.proposal_type,
        benchmarkDetail.is_added_by_myr,
        itemCode,
        selectedWallet && selectedWallet.value > 0 ? selectedWallet.value : 0,
        unitTasks,
        finalCustomeFields
      )
        .then((response) => {
          if (response.status == 1) {
            let spaces = localStorage.getItem("spaces");
            spaces = spaces ? JSON.parse(spaces) : [];

            if (!providerWalletList.length) {
              dispatch(
                showConfirmation(
                  _l("l_confirm"),
                  _l("l_activate_wallet_confirmation"),
                  _l("l_activate_now"),
                  _l("l_activate_later"),
                  () => {
                    if(dashboardProposalId > 0)
                    {
                      navigate(`/view-dpgf-proposal?dpgf-proposal-id=${dashboardProposalId}`);
                    }
                    else {
                      navigate("/proposals", { state: { from: viewPage } });
                    }
                    dispatch(
                      showMessage(
                        "success",
                        _l("l_success"),
                        _l(response.message)
                      )
                    );
                  },
                  () => {
                    dispatch(getBenchmarkDetail(benchmarkDetail.id));
                    setShowPaymentSetupSteps(true);
                    sessionStorage.setItem("isWalletSetupOngoing", 1);
                    dispatch(toastCloseAction());
                  }
                )
              );
            } else {
              if(dashboardProposalId > 0)
              {
                navigate(`/view-dpgf-proposal?dpgf-proposal-id=${dashboardProposalId}`);
              }
              else {
                navigate("/proposals", { state: { from: viewPage } });
              }

              dispatch(
                showMessage("success", _l("l_success"), _l(response.message))
              );
            }
          }
          else {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l(response.message))
            );
          }
        })
        .catch((e) => {
          console.error(e);
          showError("l_something_went_wrong");
        })
        .finally(() => {
          setSubmittingProposal(false);
        });
    }
  };

  const uploadCustomInvoice = (e) => {
    const file = e.target.files[0];
    setInvoiceAttachment(file);
    setInvoicePreview({
      ...invoicePreview,
      name: file.name,
      url: URL.createObjectURL(file),
      icon: getPreviewFromURL(file.name),
    });
  };


  const getSubscripitonDetails = async (id) => {
    let details = {};
    try {
      const response = await DocumentsServices.getSubscriptionDetail(
        benchmarkDetail.project_id,
        id,
        "",
        1
      );
      if (response.status) {
        details = response.data;
      } else {
        showError("l_something_went_wrong");
      }
    } catch (e) {
      console.error(e);
    } finally {
      return details;
    }
  };



  const subscribe = async (id, subscribeForProvider = true) => {
    let subscriptionDetails = await getSubscripitonDetails(id);
    if (Object.keys(subscriptionDetails).length) {
      let primaryContact = subscriptionDetails.contacts.find(
        (w) => w.is_primary == 1
      );

      if (!primaryContact) {
        showError("l_no_primary_contact_found_for_subscription");
        return false;
      }

      try {
        const response = await DocumentsServices.subscribe(
          subscriptionDetails.hash,
          benchmarkDetail.startdate,
          primaryContact.email,
          0,
          benchmarkDetail.project_id,
          1,
          0,
          benchmarkDetail.client_id,
          undefined,
          subscriptionDetails.payment_mode
        );
        dispatch(getBenchmarkDetail(benchmarkDetail.id));
        dispatch(getCustomerSpaces(client_id, contact_id));
        if (response.status == 1 && subscribeForProvider) {
          if (response.extra.client_id) {
            let operatorData = response.extra;
            subscribeForProvider(operatorData);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const closePaymentSetupModal = (openSubscribeModal) => {
    setShowPaymentSetupSteps(false);
    sessionStorage.removeItem("isWalletSetupOngoing");
    if (openSubscribeModal) {
      dispatch(getBenchmarkDetail(benchmarkDetail.id, client_id));
      setProposal({ ...proposal, is_iban_set: 1 });
      setAutoSubScribe(false);
      subscribe(benchmarkDetail.subscription_id, false);
    }
  };

  const validatePaymentStatus = () => {
    if (benchmarkDetail.proposal_status == 3) {
      if (proposal.is_iban_set == 0) {
        setShowPaymentSetupSteps(true);
      }
    }
  };

  const operatorUpgradePlan = () => {
    if (benchmarkDetail.subscription_id && benchmarkDetail.subscription_hash) {
      setDocumentModalData({
        ...documentModalData,
        id: benchmarkDetail.subscription_id,
        hash: benchmarkDetail.subscription_hash,
      });
    } else {
      setShowSignatureModal(true);
    }
  };

  const getOperatorWalletList = async () => {
    try {
      const response = await CustomerServices.getProviderWallets(
        benchmarkDetail.project_id,
        benchmarkDetail.proposal_id
      );
      if (response.status) {
        const walletOptions = response.data.map((wallet) => {
          return {
            value: wallet.id,
            label: (
              <div className="d-flex align-items-center">
                <div
                  className="ps-2 w100minus20"
                   
                   
                   
                >
                  <div className="c-font f-14 color-white-80 text-truncate">
                    {wallet.company_name} - {wallet.company_address}
                  </div>
                </div>
                 
              </div>
            ),
            is_selected: wallet.is_selected,
          };
        });
        setProviderWalletList(walletOptions);
        if (walletOptions.length === 1) {
          setSelectedWallet(walletOptions[0]);
        } else {
          setSelectedWallet(walletOptions.find((w) => w.is_selected > 0));
        }
      } else {
        showError(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  };
  
  useEffect(() => {
    if (
      benchmarkDetail &&
      benchmarkDetail.proposal_list &&
      benchmarkDetail.proposal_list.length &&
      benchmarkDetail.proposal_list[0]
    ) {
      let currentProposal = benchmarkDetail.proposal_list[0].proposals.find(
        (w) => w.proposal_id == benchmarkDetail.proposal_id
      );
      if (currentProposal) {
        const isWalletSetupOngoing = sessionStorage.getItem(
          "isWalletSetupOngoing"
        );
        const flag =
          currentProposal.status == 3 && currentProposal.is_iban_set == 0;
        setAutoSubScribe(flag);
        setInvoicePreview({
          name: currentProposal.proposal_custom_invoice
            ? currentProposal.proposal_custom_invoice.split(".").pop()
            : "",
          url: currentProposal.proposal_custom_invoice || "",
          icon: currentProposal.proposal_custom_invoice
            ? getPreviewFromURL(currentProposal.proposal_custom_invoice)
            : "",
        });
        setCustomInvoice(currentProposal.proposal_custom_invoice.length > 0);
        setShowPaymentSetupSteps(isWalletSetupOngoing > 0 || flag);
        setProposal(currentProposal);
        setItemCode(currentProposal.proposal_item_code);
        getOperatorWalletList();
      }
    }
  }, []);

  useEffect(() => {
    if (benchmarkDetail.unit_task && benchmarkDetail.unit_task.length) {
      setUnitTasks(benchmarkDetail.unit_task);
    }
  }, [JSON.stringify(benchmarkDetail)]);
  
  useEffect(() => {
    if (benchmarkDetail && benchmarkDetail.custom_fields && benchmarkDetail.custom_fields.length > 0) {
      setBenchmarkCustomField(benchmarkDetail.custom_fields);
      if (benchmarkDetail && benchmarkDetail.proposal_list && benchmarkDetail.proposal_list[0] && benchmarkDetail.proposal_list[0] ) {
        
        let currentProposal = benchmarkDetail.proposal_list[0].proposals.find(
          (w) => w.proposal_id == benchmarkDetail.proposal_id
        );
        
        if (currentProposal && currentProposal.custom_fields) {
          
          const currentProposalFields = currentProposal.custom_fields

          const isID = currentProposalFields.map(item => item.id);
          let newFields = []
          if (currentProposalFields.length > 0) {
            benchmarkDetail.custom_fields.map((bcf) => {
              if (!isID.includes(bcf.id)) {
                newFields.push({...bcf, value : ''});
              }
            })
            setCustomFieldValues(currentProposalFields.concat(newFields));   
          }else {
            setCustomFieldValues(
              benchmarkDetail.custom_fields.map((item) => {
                return {
                  ...item,
                  value:'',
                };
              })
            );
          }
        }
      }
      
    }
  }, [benchmarkDetail])
  

  return (
    <React.Fragment>
     
        <React.Fragment>
          <Row className="h-100">
            <Col xs={8} className="h-100 d-flex flex-column">
              <div className="bg-white-03-solid top-0 z-index-5 mb-15px">
                <div className="title-fonts fw-semibold">
                  {_l("l_basic_details")}
                </div>
                <div className="select-input-cox p-10-15 w-100 align-items-center d-flex mt-10 border">
                  <div className="check-image top-0 end-0 position-absolute h-auto w-auto bg-transparent">
                    <RectangleCheckIcon
                      size={20}
                      weight="fill"
                      className="HW30 color-green"
                    />
                  </div>
                  <div class="h30w30 d-flex main-image">
                    <BuildingsIcon
                      className=" HW30 color-green"
                    />
                  </div>
                  <div class="w100minus100per text-truncate ps-3">
                    <div class="c-font fw-semibold text-truncate title-fonts">
                      {benchmarkDetail.company_name}
                    </div>
                    <div class="c-font color-white-60 f-12 text-truncate">
                      {benchmarkDetail.address}
                    </div>
                  </div>
                  <div className="align-items-center d-flex justify-content-between">
                    <div className="with_separator_10 text-center flex-grow-1 px-3 before-dnone">
                      <div className="c-font f-12 fw-semibold title-fonts">
                        {_l("l_start_date")}
                      </div>
                      <div className=" c-font color-white-60 f-12 mt-1">
                        {moment(new Date(benchmarkDetail.startdate)).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    </div>
                    <div className="with_separator_10 text-center flex-grow-1 px-3 before-dnone">
                      <div className="c-font f-12 fw-semibold title-fonts">
                        {_l("l_budget")}
                      </div>
                      <div className="c-font color-white-60 f-12 mt-1">
                        €{benchmarkDetail.max_budget}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-grow-1 overflow-auto">
              <div className="custom-accordian-main with-left-header-arrow flex-grow-1 d-flex flex-column  p-15 py-0 px-0">
                            <Accordion
                              className="flex-grow-1"
                            >
                              <Accordion.Item
                                eventKey="0"
                                className={`mainTopicTaskFilter for-accessible`}
                              >
                                <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts w-100 px-0 bgspacelisting radius_3 light-theme-grey-light-bg lightthemebgwhite position-sticky top-0 z-index-5">
                                <Accordion.Header className="text-truncate  bg-transparent with_separator_10 ps-0">
                                  <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                      <div className="title-fonts fw-semibold ps-4 d-flex align-items-center">
                                        <div className="me-2">
                                          {" "}
                                          {`${benchmarkDetail.provider_name} ${
                                            benchmarkDetail.is_schedule == 0
                                              ? _l("l_benchmark")
                                              : _l("l_schedule")
                                          }
                                    `}
                                        </div>
                                        <div className={`badge ${
                                              benchmarkDetail.recursive == 1
                                                ? "badge-warning"
                                                : "badge-done"
                                            }  rounded-pill fw-light `}>
                                          <span
                                            className={`p-0`}
                                          >
                                            {benchmarkDetail.recursive === "1"
                                              ? _l("l_recurrent")
                                              : _l("l_onetime")}
                                          </span>
                                        </div>
                                      </div>
                                  </div>
                                </Accordion.Header>
                                </div>
                                
                                <Accordion.Body className="mt-1 childTopicTaskFilter bgOfficelisting">
                                <div className="row">
                                {!Number(benchmarkDetail.recursive) ? (
                                    <ChecklistComponent
                                      fullHeight={false}
                                      isSystemTask={true}
                                      task_id={benchmarkDetail.request_id}
                                      projectId={benchmarkDetail.project_id}
                                      isExternal={false}
                                      setChecklistFilter={()=>{}}
                                      step={"request-checklists"}
                                    />
                                  ) : (
                                    <FloorPlanSummary
                                      onFloorSelect={(floor) => onFloorSelect(floor)}
                                    />
                                  )}
                                 </div> 
                                </Accordion.Body>
                                
                              </Accordion.Item>
                            </Accordion>
                          </div>
                {
                  <React.Fragment>
                    {!Number(benchmarkDetail.recursive) ? (
                      <React.Fragment></React.Fragment>
                    ) : (
                      <React.Fragment>
                        {amountByAccessory.length ? (
                          <React.Fragment>
                             <div className="custom-accordian-main with-left-header-arrow flex-grow-1 d-flex flex-column  p-15 py-0 px-0">
                            <Accordion
                              className="flex-grow-1"
                            >
                              <Accordion.Item
                                eventKey="1"
                                className={`mainTopicTaskFilter for-accessible`}
                              >
                                <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts w-100 px-0 bgspacelisting radius_3 light-theme-grey-light-bg lightthemebgwhite position-sticky top-0 z-index-5">
                                <Accordion.Header className="text-truncate  bg-transparent with_separator_10 ps-0">
                                  <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                      <div className="title-fonts fw-semibold ps-4">
                                        {_l("l_accesories")}
                                      </div>
                                  </div>
                                </Accordion.Header>
                                </div>
                                <Accordion.Body className="mt-1 childTopicTaskFilter bgOfficelisting">
                                <div className="row">
                                <div className="comman-data-table small-data-table">
                                  <table
                                    className="dataTable  title-fonts text-center"
                                    width="100%"
                                  >
                                    <thead className="" style={{ top: "54px" }}>
                                      <tr className="">
                                        <th
                                          style={{ width: "40px", padding: "5px" }}
                                        >
                                          {_l("l_id")}
                                        </th>
                                        <th className="text-start">{`${_l(
                                          "l_accesories"
                                        )} ${_l("l_name")}`}</th>
                                        <th style={{ width: "70px" }}>
                                          {_l("l_quantity")}
                                        </th>
                                        <th style={{ width: "70px" }}>
                                          {_l("l_recurring")}
                                        </th>
                                        <th style={{ width: "70px" }}>
                                          {_l("l_proposal_amount")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {amountByAccessory.map((accessory, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-start">
                                              {accessory.description}
                                            </td>
                                            <td className="fw-semibold">
                                              {accessory.quantity}
                                            </td>
                                            <td>
                                              <Form.Check
                                                type="switch"
                                                defaultChecked={true}
                                                disabled
                                              />
                                            </td>
                                            <td>
                                              <Form.Control
                                                className="p-1 title-fonts border"
                                                type="number"
                                                placeholder={_l("l_add_price")}
                                                value={accessory.amount}
                                                disabled={
                                                  benchmarkDetail.proposal_status !=
                                                  4
                                                }
                                                onChange={(e) => {
                                                  setAmountByAccessory(
                                                    Object.values({
                                                      ...amountByAccessory,
                                                      [index]: {
                                                        ...amountByAccessory[index],
                                                        amount: e.target.value
                                                          ? Math.abs(e.target.value)
                                                          : "",
                                                      },
                                                    })
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                                 </div> 
                                </Accordion.Body>
                              
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        {unitTasks && unitTasks.length ? (
                          <React.Fragment>
                            <div className="d-flex align-items-center justify-content-between pt-30px pb-10px bg-white-03-solid position-sticky top-0 z-index-5 light-theme-grey-light-bg">
                              <div className="title-fonts fw-semibold">
                                {_l("l_bpu_title")}
                              </div>
                            </div>
                            <div className="comman-data-table small-data-table">
                              <table
                                className="dataTable  title-fonts text-center"
                                width="100%"
                              >
                                <thead className="" style={{ top: "54px" }}>
                                  <tr className="">
                                    <th
                                      style={{ width: "40px", padding: "5px" }}
                                    >
                                      {_l("l_id")}
                                    </th>
                                    <th className="text-start">{`${_l(
                                      "l_bpu_title"
                                    )} ${_l("l_name")}`}</th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_monthly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_quarterly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_half_yearly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_yearly")}
                                    </th>
                                    <th style={{ width: "70px" }}>
                                      {_l("l_proposal_amount")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {unitTasks.map((unit, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="text-start">
                                          {unit.name}
                                        </td>
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.monthly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.quarterly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.half_yearly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td className="text-center ">
                                          <label for="" className="">
                                            <input
                                              className="form-check-input "
                                              type="checkbox"
                                              checked={unit.yearly}
                                              disabled={true}
                                            />
                                          </label>
                                        </td>{" "}
                                        <td>
                                          <Form.Control
                                            className="p-1 title-fonts border"
                                            type="number"
                                            placeholder={_l("l_add_price")}
                                            value={unit.amount}
                                            disabled={
                                              benchmarkDetail.proposal_status !=
                                              4
                                            }
                                            onChange={(e) => {
                                              setUnitTasks(
                                                unitTasks.map((w, i) => {
                                                  if (i === index) {
                                                    w.amount = e.target.value;
                                                  }
                                                  return w;
                                                })
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                          {customFieldValues && customFieldValues.length > 0 && benchmarkCustomField && benchmarkCustomField &&
                            <div className="custom-accordian-main with-left-header-arrow flex-grow-1 d-flex flex-column  p-15 py-0 px-0">
                            <Accordion
                              className="flex-grow-1"
                            >
                              <Accordion.Item
                                eventKey="2"
                                className={`mainTopicTaskFilter for-accessible`}
                              >
                                <div className="accordian-header-box d-flex align-items-center accordian-main-header title-fonts w-100 px-0 bgspacelisting radius_3 light-theme-grey-light-bg lightthemebgwhite position-sticky top-0 z-index-5">
                                <Accordion.Header className="text-truncate  bg-transparent with_separator_10 ps-0">
                                  <div className="d-flex align-items-center justify-content-between w-100 line-height-1">
                                      <div className="title-fonts fw-semibold ps-4">
                                        {_l("l_custom_fields")}
                                      </div>
                                  </div>
                                </Accordion.Header>
                                </div>
                                
                                <Accordion.Body className="mt-1 childTopicTaskFilter bgOfficelisting">
                                <div className="row">
            
                                  {benchmarkCustomField && benchmarkCustomField.map((item, i) => {
                                    switch (item.field_type) {
                                      case "url":
                                        return (
                                          <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                                            <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                              {item.title}
                                            </Form.Label>
                                            <div className="align-items-center d-flex comman_action_icon">
                                            <Form.Control
                                              placeholder={item.title}
                                              type="text"
                                              onChange={(e) => {
                                                setBenchmarkCustomField(
                                                  benchmarkCustomField.map((bc) => {
                                                    if (bc.id == item.id) {
                                                      bc.value = e.target.value;
                                                    }
                                                    return bc;
                                                  })
                                                );
                                                setCustomFieldValues(
                                                  customFieldValues.map((field) => {
                                                    if (field.id == item.id) {
                                                      field.value = e.target.value;
                                                    }
                                                    return field;
                                                  })
                                                );
                                              }}
                                              value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                            />
                                            </div>
                                          </Form.Group>
                                        );
                                      case "checkbox":
                                        const checkOptions = item.default_value.split(",");
                                        const  isCheck = customFieldValues &&  customFieldValues.find((field) => field.id == item.id) 
                                                            ? customFieldValues.find((field) => field.id == item.id).value.split(",").filter(bl => bl != '')
                                                            : [];
                                        return (
                                          <div className="d-flex">
                                            <Form.Group className={`c-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                              <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                                {item.title}
                                              </Form.Label>
                                              <div className="align-items-center d-flex">
                                                <div className="d-flex form-control with-bg position-relative flex-grow-1" >
                                                  {checkOptions && checkOptions.length > 0 &&
                                                    checkOptions.map((che) => {
                                                      return(
                                                        <div className="form-check with-span me-5 py-0">
                                                          <div className="d-flex align-items-center">
                                                            <Form.Label className="form-check-label mt-2 mb-0 w-100">
                                                              <input
                                                                className="form-check-input h20w20"
                                                                type="checkbox"
                                                                checked={isCheck.includes(che)}
                                                                onChange={(e) =>{
                                                                  setBenchmarkCustomField(
                                                                    benchmarkCustomField.map((bc) => {
                                                                      if (bc.id == item.id) {
                                                                        const setCheck = isCheck.includes(che) ? isCheck.filter(ch => ch != che) : [`${che}`].concat(isCheck);
                                                                        bc.value = setCheck.join(',');
                                                                      }
                                                                      return bc;
                                                                    })
                                                                  )
                                                                  setCustomFieldValues(
                                                                    customFieldValues.map((field) => {
                                                                      if (field.id == item.id) {
                                                                        const setCheck = isCheck.includes(che) ? isCheck.filter(ch => ch != che) : [`${che}`].concat(isCheck);
                                                                        field.value = setCheck.join(',');
                                                                      }
                                                                      return field;
                                                                    })
                                                                  )
                                                                }}
                                                              />
                                                              <span className="c-font f-12 ps-1">
                                                                {che}
                                                              </span>
                                                            </Form.Label>
                                                          </div>
                                                        </div>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              </div>
                                            </Form.Group>
                                          </div>
                                        );
                                      case "file":
                                      return (
                                        <div className="d-flex">
                                          <Form.Group className={`c-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                            <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                              {item.title}
                                            </Form.Label>
                                            <div className="align-items-center d-flex">
                                              <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between p-15">
                                                  <input
                                                    className="z-index-3 hidden-input"
                                                    type="file"
                                                  />
                                                  <div className="m-auto">
                                                    <CloudArrowUpIcon
                                                      className="HW18"
                                                    />
                                                    <span className="color-white-60 ps-2">
                                                      {_l("l_click_to_upload")}
                                                    </span>
                                                  </div>
                                                </div>
                                            </div>
                                            </div>
                                          </Form.Group>
                                        </div>
                                      );
                                      case "text":
                                        return (
                                          <div className="d-flex">
                                            <Form.Group className={`c-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                              <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                                {item.title}
                                              </Form.Label>
                                              <div className="align-items-center d-flex">
                                              <Form.Control
                                                as="input"
                                                placeholder={item.title}
                                                type="text"
                                                name=""
                                                onChange={(e) => {
                                                  setCustomFieldValues(
                                                    customFieldValues.map((field) => {
                                                      if (field.id == item.id) {
                                                        field.value = e.target.value;
                                                      }
                                                      return field;
                                                    })
                                                  );
                                                  setBenchmarkCustomField(
                                                    benchmarkCustomField.map((bc) => {
                                                      if (bc.id == item.id) {
                                                        bc.value = e.target.value;
                                                      }
                                                      return bc;
                                                    })
                                                  );
                                                }}
                                                value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                              ></Form.Control>
                                              </div>
                                            </Form.Group>
                                          </div>
                                        );
                                      case "number":
                                        return (
                                          <Form.Group className={`c-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                            <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                              {item.title}
                                            </Form.Label>
                                            <div className="align-items-center d-flex">
                                            <Form.Control
                                              as="input"
                                              placeholder={item.title}
                                              type="number"
                                              name=""
                                              onChange={(e) => {
                                                setCustomFieldValues(
                                                  customFieldValues.map((field) => {
                                                    if (field.id == item.id) {
                                                      field.value = e.target.value;
                                                    }
                                                    return field;
                                                  })
                                                );
                                                setBenchmarkCustomField(
                                                  benchmarkCustomField.map((bc) => {
                                                    if (bc.id == item.id) {
                                                      bc.value = e.target.value;
                                                    }
                                                    return bc;
                                                  })
                                                );
                                              }}
                                              value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                            ></Form.Control>
                                            </div>
                                          </Form.Group>
                                        );
                                      case "textarea":
                                        return (
                                          <Form.Group className={`c-input-box pb-4 position-relative col-xl-12 comman_action_icon`}>
                                            <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                              {item.title}
                                            </Form.Label>
                                            <div className="align-items-center d-flex">
                                            <Form.Control
                                              as="textarea"
                                              rows={5}
                                              placeholder={item.title}
                                              type="text"
                                              name=""
                                              onChange={(e) => {
                                                setCustomFieldValues(
                                                  customFieldValues.map((field) => {
                                                    if (field.id == item.id) {
                                                      field.value = e.target.value;
                                                    }
                                                    return field;
                                                  })
                                                );
                                                setBenchmarkCustomField(
                                                  benchmarkCustomField.map((bc) => {
                                                    if (bc.id == item.id) {
                                                      bc.value = e.target.value;
                                                    }
                                                    return bc;
                                                  })
                                                );
                                              }}
                                              value={customFieldValues && customFieldValues.find((field) => field.id == item.id) ? customFieldValues.find((field) => field.id == item.id).value : ''}
                                            ></Form.Control>
                                            </div>
                                          </Form.Group>
                                        );
                                      case "select":
                                        const singleSelectMenuOptions = item.default_value
                                        .split(",")
                                        .map((option) => {
                                          return { label: option, value: option };
                                        });

                                        const singleSelectcustomFieldValues = customFieldValues && customFieldValues.find((field) => field.id == item.id) 
                                                                    ? [customFieldValues.find((field) => field.id == item.id).value]
                                                                    : [{value : ''}]

                                        const singleSelectValue = singleSelectMenuOptions.filter(
                                          (w) =>
                                          singleSelectcustomFieldValues.find(
                                              (z) => z == w.value
                                            )
                                        );
                                        return (
                                          <Form.Group className="col-xl-12 c-input-box position-relative pb-3  comman_action_icon">
                                            <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>{item.title}</Form.Label>
                                            <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative gap-2 align-items-center d-flex">
                                              <Select
                                                className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh flex-grow-1 css-b62m3t-container"
                                                classNamePrefix="react-select"
                                                placeholder={_l("l_select")}
                                                options={singleSelectMenuOptions}
                                                value={singleSelectValue}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 4 }) }}
                                                menuPortalTarget={document.body} 
                                                onChange={(e) => {
                                                  setCustomFieldValues(
                                                    customFieldValues.map((field) => {
                                                      if (field.id == item.id) {
                                                        field.value = e.value;
                                                      }
                                                      return field;
                                                    })
                                                  );
                                                  setBenchmarkCustomField(
                                                    benchmarkCustomField.map((bca) => {
                                                      if (bca.id == item.id) {
                                                        bca.value = e.value;
                                                      }
                                                      return bca;
                                                    })
                                                  );
                                                }}
                                              />
                                            </div>
                                          </Form.Group>
                                        );
                                      case "multi_select":
                                        const multipleSelectMenuOptions = item.default_value
                                          .split(",")
                                          .map((option) => {
                                            return { label: option, value: option };
                                          });

                                          let formattedMultiSelectValue = customFieldValues.find((field) => field.id == item.id).value 
                                                                            ? customFieldValues.find((field) => field.id == item.id).value.split(",") 
                                                                            : [];

                                          const multiSelectValue = multipleSelectMenuOptions.filter(
                                            (w) =>
                                              formattedMultiSelectValue.find(
                                                (z) => z == w.value
                                              )
                                          );
                                        
                                        return (
                                          <Form.Group className="col-xl-12 c-input-box position-relative pb-3  comman_action_icon">
                                            <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>{item.title}</Form.Label>
                                            <div className="c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative gap-2 align-items-center d-flex">
                                              <Select
                                                className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh flex-grow-1"
                                                classNamePrefix="react-select"
                                                placeholder={_l("l_select")}
                                                isMulti
                                                value={multiSelectValue}
                                                options={multipleSelectMenuOptions}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 4 }) }}
                                                menuPortalTarget={document.body}
                                                onChange={(e) => {
                                                  setCustomFieldValues(
                                                    customFieldValues.map((field) => {
                                                      if (field.id == item.id) {
                                                        field.value = e.map((w) => w.value).join(",");
                                                      }
                                                      return field;
                                                    })
                                                  );
                                                  setBenchmarkCustomField(
                                                    benchmarkCustomField.map((bce) => {
                                                      if (bce.id == item.id) {
                                                        bce.value = e.map((w) => w.value).join(",");
                                                      }
                                                      return bce;
                                                    })
                                                  );
                                                }}
                                              />
                                            </div>
                                          </Form.Group>
                                        );
                                      case "date":
                                        return (
                                          <>
                                            {" "}
                                            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
                                              <Form.Label className={`input-label ${item.is_required == 1 ? '' : 'no-asterisk'}`}>
                                                {item.title}
                                              </Form.Label>
                                              <div className="align-items-center custom-datepicker d-flex">
                                                <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px gap-2 justify-content-between">
                                                  <div className="action_icon h32w32 with_bg">
                                                    <CalendarBlankIcon
                                                      className="HW18 opacity-60"
                                                    />
                                                  </div>
                                                  <DatePicker
                                                    className="ps-2 w-100"
                                                    disabledKeyboardNavigation
                                                    dateFormat="dd/MM/yyyy"
                                                    selectsStart
                                                    defaultMenuIsOpen
                                                    placeholderText={_l("l_please_select_date")}
                                                    calendarStartDay={1}
                                                    portalId="custom_datepicker"
                                                    calendarClassName="custom-datepicker"
                                                    onChange={(date) => {
                                                      setCustomFieldValues(
                                                        customFieldValues.map((field) => {
                                                          if (field.id == item.id) {
                                                            field.value =
                                                              moment(date).format("YYYY-MM-DD");
                                                          }
                                                          return field;
                                                        })
                                                      );
                                                      setBenchmarkCustomField(
                                                        benchmarkCustomField.map((bc) => {
                                                          if (bc.id == item.id) {
                                                            bc.value =
                                                              moment(date).format("YYYY-MM-DD");
                                                          }
                                                          return bc;
                                                        })
                                                      );
                                                    }}
                                                    selected={
                                                      customFieldValues && customFieldValues.find((field) => field.id == item.id) && customFieldValues.find((field) => field.id == item.id).value 
                                                        ? new Date(customFieldValues.find((field) => field.id == item.id).value)
                                                        : ""
                                                    }
                                                  >
                                                    <div className="datepicker-label">{_l("l_select_date_and_time")}</div>
                                                  </DatePicker>
                                                </div>
                                              </div>
                                            </Form.Group>{" "}
                                          </>
                                        );
                                      default:
                                        return <div></div>;
                                    }
                                    })}
                                 </div> 
                                </Accordion.Body>
                               
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          }
                      </React.Fragment>
                    )}
                  </React.Fragment>
                }
              </div>
            </Col>
            <Col xs={4} className="h-100 d-flex flex-column">
              <div className="title-fonts fw-semibold">{_l("l_proposal")}</div>
              <div className="bg-white-03 p-15 mt-2 flex-grow-1 d-flex flex-column overflow-auto lightthemebg">
                <div className="overflow-auto">
                  <div className="schedule-box border-bottom-dashed">
                    <div className="d-flex flex-wrap align-items-center pb-3  mb-15px border-bottom">
                      <div className="title-fonts fw-semibold flex-grow-1">
                        {_l("l_enter_your_price_here")}
                      </div>
                      <Form.Control
                        className="p-2 title-fonts w_100px text-end pe-2 with-success"
                        type="number"
                        disabled={benchmarkDetail.proposal_status != 4}
                        placeholder={_l("l_add_price")}
                        value={amount}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value) {
                            setAmount(Math.abs(e.target.value));
                          } else {
                            setAmount("");
                          }
                        }}
                      />
                      <Form.Control
                        className="title-fonts w-100 mt-2"
                        type="text"
                        disabled={benchmarkDetail.proposal_status != 4}
                        placeholder={_l("l_add_detail")}
                        value={itemCode}
                        onChange={(e) => {
                          setItemCode(e.target.value);
                        }}
                      />
                      {providerWalletList && providerWalletList.length ? (
                        <div className="my-3 mx-0 flex-grow-1">
                          <Select
                            className="custom-select-menu me-0"
                            classNamePrefix="react-select"
                            options={providerWalletList}
                            value={selectedWallet}
                            onChange={(value) => {
                              setSelectedWallet(value);
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            isDisabled={benchmarkDetail.proposal_status != 4}
                          />
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                    <div className="d-flex align-items-center  mb-15px">
                      <div className="title-fonts fw-semibold w100minus100">{`${
                        benchmarkDetail.provider_name
                      } ${
                        benchmarkDetail.is_schedule == 0
                          ? _l("l_benchmark")
                          : _l("l_schedule")
                      }`}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-2 color-white-60">
                      <div className="c-font f-12">
                        {benchmarkDetail.recursive === "1"
                          ? _l("l_recurrent")
                          : _l("l_onetime")}
                      </div>
                      <div className="">€ {amount ? amount.toFixed(2) : 0}</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between margin25px_0 fw-semibold c-font f-12">
                      <div className="">{_l("l_total")}</div>
                      <div className="">€ {amount ? amount.toFixed(2) : 0}</div>
                    </div>
                  </div>
                  {amountByAccessory.length ? (
                    <div
                      className={`schedule-box margin25px_0 ${
                        benchmarkDetail.customer_estimate_amount > 0
                          ? "border-bottom-dashed"
                          : ""
                      }`}
                    >
                      <div className="d-flex align-items-center mb-15px">
                        <div className="title-fonts fw-semibold w100minus100">
                          {_l("l_accessories")}
                        </div>
                        <Form.Control
                          className="border-0 p-1 title-fonts w_100px text-end pe-2 d-none"
                          type="number"
                          disabled={benchmarkDetail.proposal_status != 4}
                          placeholder={_l("l_add_price")}
                          value={amount}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value) {
                              setAmount(Math.abs(e.target.value));
                            } else {
                              setAmount("");
                            }
                          }}
                        />
                      </div>
                      {amountByAccessory.map((accessory, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center justify-content-between mb-2 color-white-60 c-font f-12"
                          >
                            <div className="">{accessory.description}</div>
                            <div className="">
                              € {Number(accessory.amount).toFixed(2)}
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex align-items-center justify-content-between mb-2 fw-semibold mt-4 c-font f-12">
                        <div className="">{_l("l_total")}</div>
                        <div className="">
                          €{" "}
                          {amountByAccessory
                            .reduce(
                              (total, obj) => total + Number(obj.amount),
                              0
                            )
                            .toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {benchmarkDetail.customer_estimate_amount > 0 ? (
                    <div className="schedule-box border-bottom-dashed margin25px_0">
                      <div className="d-flex align-items-center justify-content-between  mb-15px">
                        <div className="title-fonts fw-semibold flex-grow-1">{`Myr.AI ${_l(
                          "l_platform_cost"
                        )} `}</div>
                        {autoSubScribe ? (
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                              setShowPaymentSetupSteps(true);
                            }}
                          >
                            {_l("l_setup_payment_method")}
                          </Button>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                        {proposal.is_iban_set == 1 &&
                        benchmarkDetail.operator_hash &&
                        benchmarkDetail.proposal_status == 3 ? (
                          <Button
                            variant="primary"
                            size="sm"
                          >
                            {_l("l_pay_subscription")}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2 color-white-60">
                        <div className="c-font f-12">
                          {_l("l_platform_cost")}
                        </div>
                        <div className="">
                          €{" "}
                          {benchmarkDetail.customer_estimate_amount > 0
                            ? Number(
                                benchmarkDetail.customer_estimate_amount
                              ).toFixed(2)
                            : 0}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between margin25px_0 fw-semibold c-font f-12">
                        <div className="">{_l("l_total")}</div>
                        <div className="">
                          €{" "}
                          {benchmarkDetail.customer_estimate_amount > 0
                            ? Number(
                                benchmarkDetail.customer_estimate_amount
                              ).toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
                <div className="mt-auto position-sticky-bottom z-index-0imp">
                  <div className="border-bottom marginTop25px mb-15px"></div>
                  <div className="d-flex align-items-center justify-content-between mb-2 fw-semibold title-fonts c-font f-16 title">
                    <div className="">{_l("l_total")}</div>
                    <div className="color-green">
                      €{" "}
                      {Number(
                        amountByAccessory.reduce(
                          (total, obj) => total + Number(obj.amount),
                          0
                        ) +
                          Number(amount) +
                          Number(benchmarkDetail.customer_estimate_amount)
                      ).toFixed(2)}
                    </div>
                  </div>
                  {customInvoice ? (
                    <Form.Group className="align-items-center c-input-box d-flex pb-1 position-relative">
                      <div className="form-control file-upload with-bg position-relative p-0 flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between p-2">
                          <input
                            className="z-index-3 hidden-input"
                            type="file"
                            accept=".png,.jpg,.pdf,.jpeg"
                            onInput={uploadCustomInvoice}
                          />
                          <div className="m-auto">
                            <CloudArrowUpIcon
                              className="HW18"
                            />
                            <span className="color-white-60 ps-2">
                              {_l("l_upload_file")}
                            </span>
                          </div>
                        </div>
                      </div>
                      {invoiceAttachment || invoicePreview ? (
                        <div className="ms-3 upload-image-preview">
                          <div className="d-flex">
                            <div className="h40w40 d-flex align-items-center">
                              {typeof invoicePreview.icon === "string" ? (
                                <div
                                  className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 cursor-pointer"
                                  style={{
                                    backgroundImage: `url(${invoicePreview.url})`,
                                  }}
                                  onClick={() =>
                                    window.open(invoicePreview.url, "_blank")
                                  }
                                   
                                   
                                   
                                ></div>
                              ) : (
                                <>
                                  <div
                                    className="h-100 w-100 d-flex"
                                    style={{
                                      alignContent: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <invoicePreview.icon
                                      size={72}
                                      weight="light"
                                      className="c-icons"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </Form.Group>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  {benchmarkDetail.proposal_status == 4 ? (
                    <React.Fragment>
                      <Form.Group className="mt-10 mb-10">
                        <Form.Check
                          label={_l("l_custom_estimate_description")}
                          checked={customInvoice}
                          onChange={(e) => setCustomInvoice(e.target.checked)}
                        />
                      </Form.Group>
                      <Form.Group className="my-1  d-flex flex-wrap form-check">
                        <Form.Check
                          className=""
                          type={"checkbox"}
                          checked={termsAndConditions}
                          onChange={(e) =>
                            setTermsAndConditions(e.target.checked)
                          }
                        />
                        <div className="form-check-label">
                          {_l("l_i_accept_terms_and_conditions")}
                        </div>
                        <a
                          className="color-green ms-1"
                          href={"https://myr.ai/cgsc.html"}
                          target="_blank"
                        >
                          CGSC
                        </a>
                        <a
                          className="color-green ms-1"
                          href={"https://myr.ai/cgsp.html"}
                          target="_blank"
                        >
                          CGSP
                        </a>
                        <span className="mx-1">&</span>
                        <a
                          className="color-green ms-1"
                          href={"https://myr.ai/privacy-policy.html"}
                          target="_blank"
                        >
                          RGPD
                        </a>
                      </Form.Group>
                      <Button
                        variant="primary"
                        // size="sm"
                        className="w-100 mt-2"
                        onClick={submitProposal}
                        disabled={submittingProposal}
                      >
                        {submittingProposal ? (
                          <React.Fragment>
                            {_l("l_please_wait")}
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className={`ms-2`}
                              aria-hidden="true"
                            />
                          </React.Fragment>
                        ) : (
                          `${
                            benchmarkDetail.operator_total > 0
                              ? _l("l_update_my_proposal")
                              : _l("l_send_my_proposal")
                          }`
                        )}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {showPaymentSetupSteps ? (
            <PaymentSetupModal
              handleClose={closePaymentSetupModal}
              show={showPaymentSetupSteps}
              autoSubScribe={autoSubScribe}
              titleSuffix={benchmarkDetail ? benchmarkDetail.region_name : ""}
              regionId={benchmarkDetail ? benchmarkDetail.region_id : ""}
              categoryId={benchmarkDetail ? benchmarkDetail.categoryId : 0}
            />
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {showSignatureModal ? (
            <SignatureConfirmationIdentity
              show={showSignatureModal}
              projectId={benchmarkDetail.project_id}
              estimate={{ package_id: benchmarkDetail.membership_package_id }}
              hash={{}}
              handleClose={() => {
                setShowSignatureModal(false);
              }}
              type="operator-estimate"
              callBack={(data) => {
                setDocumentModalData({
                  ...data,
                  type: "subscription",
                  id: data.subscription_id,
                  hash: data.subscription_hash,
                });
                setShowSignatureModal(false);
              }}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      
      {documentModalData.id > 0 ? (
        <DocumentModal
          show={
            documentModalData.id > 0 || documentModalData.subscription_id > 0
          }
          handleClose={() => {
            setDocumentModalData({});
            dispatch(getBenchmarkDetail(benchmarkDetail.id));
          }}
          type={"subscription"}
          documentData={{
            ...documentModalData,
            id: documentModalData.id || documentModalData.subscription_id,
            hash: documentModalData.hash,
          }}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <div
        className="d-none"
        id="open-subscribe-now-modal"
        onClick={validatePaymentStatus}
      ></div>
      <div
        className="d-none"
        id="operator-upgrade-plan"
        onClick={operatorUpgradePlan}
      ></div>
    </React.Fragment>
  );
};

export default AddProposalAmount;
