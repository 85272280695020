import React, { useEffect, useState } from "react";

import RooflessFile from "../assets/images/rooflessfile.glb";
import MyspaceTaskModalAdd from "../components/Myspace/MyspaceTaskModalAdd";
import MapPinLineIcon from "../assets/icons/MapPinLineIcon";
import ArVrIcon from "../assets/icons/ArVrIcon";
import MapTrifoldIcon from "../assets/icons/MapTrifoldIcon"
import jquery from "jquery";
import $ from "jquery";

import { hideRightPanelNew, removeModifiedClass, showLeftPanel, showLeftPanelNew } from "../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import Lightbox from "yet-another-react-lightbox";
import FloorDocument from "../components/Modals/FloorDocument";
import { closeOutsideClick, getArLocalAnchors, getFloorDocumentByFloorId, getPOIsListUpdate, getPOPIsList, getSelectedTask, getSpaceFloorList, getTaskList, selectedFloorId, selectedTypeOfSapceId, setEquipmentQuantity, setEquipmentQuantityList, setPOILatLong, updateLocalAnchor } from "../actions/customer";
import { _l } from "../hooks/utilities";
import Button from 'react-bootstrap/Button';
import BookThreedPlan from "../components/Modals/BookThreedPlan";
import CreateTaskRequestOffcanvas from "../components/Offcanvas/CreateTaskRequestOffcanvas";
import CalendarDropdown from "../components/Dropdowns/CalendarDropdown";
import CommanOffCanvas from "../components/Offcanvas/CommanOffCanvas";
import customerServices from "../services/customer-services";
import { showConfirmation, showMessage } from "../actions/messages";
import Select from "react-select";
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import { mapStyle } from "../assets/map/mapStyle";
import moment from "moment";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { DEFAULT_LAT, DEFAULT_LNG } from "../constants/constants";
import BuildingsIcon from "../assets/icons/BuildingsIcon";
import CaretRightIcon from "../assets/icons/CaretRightIcon";
let cordinates = []
let cordinatesData = []
let cordinatesList = []
let poiList = []

const fakePOIsData = [
  {
      "name": "north",
      "x": 8.129181866497,
      "y": 1.296492669361,
      "z": -0.273692101961,
      "id": "0",
      "images": []
  },
  {
      "name": "Request Task",
      "x": "-0.843505688348",
      "y": "-0.508229017258",
      "z": "-0.256907853987",
      "id": "566",
      "status": "1",
      "is_requested": 1,
      "task_id": "334215",
      "quantityId": 0,
      "equipmentName": "",
      "equipmentId": 0
  },
  {
      "name": "To Do Task ",
      "x": "7.459298492122",
      "y": "-0.456867624593",
      "z": "2.255183941866",
      "id": "5672",
      "status": "1",
      "is_requested": 0,
      "task_id": "2342162",
      "quantityId": 0,
      "equipmentName": "",
      "equipmentId": 0
  },
  {
      "name": "Done Task",
      "x": "5.402319277125",
      "y": "-0.438153199068",
      "z": "5.817428774166",
      "id": "5692",
      "status": "5",
      "is_requested": 0,
      "task_id": "3342181",
      "quantityId": 0,
      "equipmentName": "",
      "equipmentId": 0
  },
  {
      "name": "Ongoing Task ",
      "x": "-3.371500015259",
      "y": "0.842641685235",
      "z": "4.139418950300",
      "id": "5701",
      "status": "4",
      "is_requested": 0,
      "task_id": "4334219",
      "quantityId": 0,
      "equipmentName": "",
      "equipmentId": 0
  },
  {
      "name": "Equipment 1",
      "x": "3.0719042829771066",
      "y": "-0.3204989983920753",
      "z": "1.3787159984930788",
      "id": "5614",
      "status": "",
      "is_requested": 0,
      "task_id": 0,
      "image": "https://myrpp.myr.ai/orb/assets/images/equipment-default-image-placeholder.png",
      "quantityId": "11732",
      "equipmentName": "Test Equipment ",
      "equipmentId": "5666"
  },
  {
    "name": "Equipment 2",
    "x": "-0.7369667272515628",
    "y": "-0.3609602650351616",
    "z": "6.450805294791936",
    "id": "5615",
    "status": "",
    "is_requested": 0,
    "task_id": 0,
    "image": "https://myrpp.myr.ai/orb/assets/images/equipment-default-image-placeholder.png",
    "quantityId": "11733",
    "equipmentName": "Test Water dispenser ",
    "equipmentId": "59667"
  },
  {
      "name": "POI 1",
      "x": "1.538740583998699",
      "y": "-0.24514126739515074",
      "z": "-6.151105365658004",
      "id": "239",
      "images": []
  },
  {
    "name": "POI 2",
    "x": "7.857247287285606",
    "y": "-0.25060681016991115",
    "z": "-3.22548982190064",
    "id": "240",
    "images": []
}
]

const MySpaceNew = () => {
  const dispatch = useDispatch()
  const [showToolsModal, setShowToolsModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Map-View");
  const [openGallery, setOpenGallery] = useState(false);
  const [documentList, setDocumentList] = useState({});
  const [extention, setExtention] = useState("default");
  const [documentDetail, setDocumentDetail] = useState({});
  const [showBook3dPlanModal, setShowBook3dPlanModal] = useState(false)
  const { getSapceFloorList, 
    getActivePOIList,
    get_Ar_Local_Anchors,
    getSelectedFloorId, 
    selectedTask, 
    getPOIList,
    modelViewerPoint, 
    activeTaskStatusFilters, 
    taskFilters,
    globalView,
    get_equipments_list,
    filterfor3dPlan,
    getSelectedTypeOfSpaceId,
    commanoffcanvasIsOpen, } = useSelector((state) => state.customer);
    const spaceFloorPlan = useSelector(
      (state) => state.benchmarkReducer.floorDetails
    );
  const quantityList = useSelector((state) => state.customer.set_equipments_quantity_list);
  const set_equipments_quantity = useSelector((state) => state.customer.set_equipments_quantity);
  const [documentAttachments, setDocumentAttachments] = useState([]);
  const [POIsList, setPOIsList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [show, setShow] = useState(false);
  const [openCreatePOI, setOpenCreatePOI] = useState(false);
  const [selectedPOI, setSelectedPOI] = useState("");
  const [isDragging,setIsDragging] = useState(false);

  const [taskId, setTaskId] = useState(0);
  const [showTaskInfo, setShowTaskInfo] = useState(false);
  const [clientX, setClientX] = useState(false);
  const [clientY, setClientY] = useState(false);
  const [events, setEvents] = useState();
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [commonModalData, setCommonModalData] = useState();
  const [task_type, setTask_type] = useState('');
  const [isSetPOI, setIsSetPOI] = useState('');
  const [setUpRoomShow, setSetUpRoomShow] = useState(false);
  const [arLocalAnchors, setArLocalAnchors] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [quantityLists, setQuantityLists] = useState([]);
  const [onLoad, setOnLoad] = useState(false);
  const [openFrom, setOpenFrom] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState("");
  const [showEquipment, setshowEquipment] = useState(false);
  const client_id = localStorage.getItem("client_id");
  const contact_id = localStorage.getItem("contact_id");
  const assignroleoption = [
    { value: "1", label: "role - 1" },
    { value: "2", label: "role - 2" },
    { value: "3", label: "role - 3" },
  ];
  const [coOrdinates, setCoOrdinates] = useState({
    lat: 48.83888,
    lng: 2.38062,
  });
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_SECRET
  });
  
  const [showDirectionPoint, setShowDirectionPoint] = useState(false);
  const [roomSetupCordinates, setRoomSetupCordinates] = useState([]);
  const [roomCordinatesData, setRoomCordinatesData] = useState([]);
  const [roomCordinatesList, setRoomCordinatesList] = useState([]);
  const [roomCenterPoints, setRoomCenterPoints] = useState([]);
  
  const [locationLetAndLng, setLocationLetAndLng] = useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG
  });
  const [mapShow, setMapShow] = useState(true);
  const [dummyPOIs, setDummyPOIs] = useState(fakePOIsData)
  const [isDummyModal, setIsDummyModal] = useState(false)


  useEffect(() => {
    if (quantityList) {
      setQuantityLists(quantityList.slice());
    }
  },[JSON.stringify(quantityList)])
  useEffect(() => {
    showLeftPanel(true, true);
    getAddressForMapLocation(projectId)
  }, []);
  useEffect(() => {
    if (spaceFloorPlan && spaceFloorPlan.type_of_space && spaceFloorPlan.type_of_space.length && getSelectedTypeOfSpaceId == undefined) {
      let tempArr = spaceFloorPlan.type_of_space
      let arr = [];
      let arrList = [];
      let centerPointsArr = [];
      setRoomCenterPoints([]);
      tempArr.forEach((tos) => {
        if(tos.details && tos.details.length > 0){
          tos.details.forEach((dd) => {
            if (dd.floor_id == getSelectedFloorId && dd.poi_set && dd.poi_set.length) {
              arrList.push({...dd,roomId : dd.id,
                roomTitle : dd.title})
              setRoomCordinatesList(arrList.slice());
              const centerPoint = getCenterCoordinate(dd.poi_set);
              centerPointsArr.push({...centerPoint, roomId : dd.id, roomTitle : dd.title,roomName:tos.title})
              setRoomCenterPoints(centerPointsArr.slice());
              dd.poi_set && dd.poi_set.forEach((poi) => { 
                arr.push({...poi,
                  roomId : dd.id,
                  roomTitle : dd.title});
                })
                }
          })
        }
      })
      setRoomCordinatesData(arr.slice());
    }
  }, [JSON.stringify(spaceFloorPlan.type_of_space),getSelectedFloorId, getSelectedTypeOfSpaceId])

  const getCenterCoordinate = (coordinates) => {
    if (coordinates.length === 0) {
      return null; // Return null or handle this case as needed
    }
  
    // Initialize sum of latitudes and longitudes
    let sumX = 0;
    let sumZ = 0;
    let sumY = 0;
  
    // Calculate the sum of latitudes and longitudes
    coordinates.forEach(coord => {
      sumX += coord.web_x;
      sumZ += coord.web_z;
      sumY = coord.web_y;
    });
  
    // Calculate the average latitude and longitude
    const centerLat = sumX / coordinates.length;
    const centerLng = sumZ / coordinates.length;
  
    return {
      web_x: centerLat,
      web_z: centerLng,
      web_y: sumY,

    };
  }
  
  // Example usage
  const coordinates = [
    { lat: 40.7128, lng: -74.0060 }, // New York City
    { lat: 34.0522, lng: -118.2437 }, // Los Angeles
    { lat: 51.5074, lng: -0.1278 } // London
  ];
  
  useEffect(() => {
    if (extention == "glb") {
      const modelViewer1 = document.getElementById('client_3d');
      setIsDummyModal(false)
      if (modelViewer1 != null) {
        setOnLoad(false);
        modelViewer1.addEventListener('load', () => {
          $("#client_3d").append(`
        <button class="poi_anchor colorPois d-none" slot="hotspot-0" data-position="0.000000000000000 0.000000000000000 0.000000000000000" data-visibility-attribute="visible"><div class="view-button">Set North</div><div class="call-ring-circle"></div></button>`
          );
          setOnLoad(true);
        })
      }
    }
  }, [extention, getActivePOIList, showDirectionPoint,getSelectedFloorId])
  useEffect(() => {
    setExtention('default');
    if (getSelectedFloorId > 0) {
      dispatch(getPOPIsList(getSelectedFloorId, 0));
      dispatch(getArLocalAnchors(getSelectedFloorId));
    }
    setTaskId(0);
    setPOIsList([]);
    setShowTaskInfo(false);
  }, [getSelectedFloorId])

  useEffect(() => {
    if (getSelectedFloorId > 0 && getActivePOIList && getActivePOIList.length > 0 && getActivePOIList.filter((PO) => PO.web_x != 0 || PO.web_y != 0 || PO.web_z != 0).length > 0) {
      dispatch(getArLocalAnchors(getSelectedFloorId));
    }
  }, [getSelectedFloorId, JSON.stringify(getActivePOIList)])

  useEffect(() => {
    const savedDate = localStorage.getItem("selectedDate");
    if (taskFilters && taskFilters.length && getSelectedFloorId > 0) {
      customerServices.getTaskList(
        client_id,
          contact_id,
          projectId,
          activeTaskStatusFilters.length
            ? ""
            : moment(savedDate).format("YYYY-MM-DD"),
          activeTaskStatusFilters.length ? 2 : 1,
          activeTaskStatusFilters,
          taskFilters,
          "",
          0,
          1,
          "",
          "",
          0,
          globalView,
          getSelectedFloorId,
      ).then((res) => {
        if (res.status == 1) {
          setTaskList(res.data)    
        }
      })
    } else if ( taskFilters && !taskFilters.length && getSelectedFloorId > 0) {
      customerServices.getTaskList(
          client_id,
          contact_id,
          projectId,
          activeTaskStatusFilters.length
            ? ""
            : moment(savedDate).format("YYYY-MM-DD"),
          1,
          activeTaskStatusFilters,
          taskFilters,
          "",
          0,
          1,
          "",
          "",
          globalView,
          getSelectedFloorId,
        ).then((res) => {
          if (res.status == 1) {
            setTaskList(res.data)    
          }
        })
    }
    setMapShow(false)
  }, [getSelectedFloorId]);

  useEffect(() => {
    setArLocalAnchors([]);
   
    let localAnchor = []
// set task and request in to local anchors state
    if(get_Ar_Local_Anchors){
      get_Ar_Local_Anchors.map((t) => {
        if (t.web_x && t.web_x != 0 && t.web_y && t.web_y != 0 && t.web_z && t.web_z != 0 && t.task_id > 0 && t.contact_id > 0) {  
        return localAnchor.push({
          task_id:t.task_id,
          is_requested: t.is_requested == "1" ? 1 : 0,
          id:t.id,
          title:t.title,
          status:t.task_status_id,
          web_x:t.web_x,
          web_y:t.web_y,
          web_z:t.web_z,
          checklist_id:0
        })
      }
      })
    }
    if (get_equipments_list && get_equipments_list.length > 0 && filterfor3dPlan.length > 0) {
      get_equipments_list.map((t) => {
        if (filterfor3dPlan.includes(t.id)) {
         t.quantity_details.length > 0 && t.quantity_details.map((d) =>{
          if (d.web_x && d.web_x != 0 && d.web_y && d.web_y != 0 && d.web_z && d.web_z != 0) { 
            return localAnchor.push({
              quantityId:d.id,
              task_id:0,
              is_requested:0,
              id:d.local_anchors_id,
              title:d.quantity_name,
              equipmentName:t.equipment_name,
              equipmentId:t.id,
              web_x:d.web_x,
              web_y:d.web_y,
              web_z:d.web_z,
              checklist_id:0,
              images: d.attachments
            })
          }
        })
      }
      })
    }
// set new room cordinates in to local anchors state
    if (roomSetupCordinates && roomSetupCordinates.length > 0) {
      roomSetupCordinates.map((d) =>{
        if (d.web_x && d.web_x != 0 && d.web_y && d.web_y != 0 && d.web_z && d.web_z != 0) { 
            setPOIsList([])
            return localAnchor.push({
              quantityId:0,
              task_id:0,
              is_requested:0,
              id:"roomId",
              from:"create",
              title:"",
              web_x:d.web_x,
              web_y:d.web_y,
              web_z:d.web_z,
              checklist_id:0
            })
          }
      })
    }
// set room list cordinates in to local anchors state
    if (roomCordinatesData && roomCordinatesData.length > 0) {
      roomCordinatesData.map((d) =>{
        if (d.web_x && d.web_x != 0 && d.web_y && d.web_y != 0 && d.web_z && d.web_z != 0) { 
            setPOIsList([])
            return localAnchor.push({
              quantityId:0,
              task_id:0,
              is_requested:0,
              id:"roomId",
              roomId:d.roomId,
              title:d.roomTitle,
              web_x:d.web_x,
              web_y:d.web_y,
              web_z:d.web_z,
              checklist_id:0
            })
          }
      })
    }
// set room  cordinates in to local anchors state
    if (roomCenterPoints && roomCenterPoints.length > 0) {
      roomCenterPoints.map((d) =>{
        if (d.web_x && d.web_x != 0 && d.web_y && d.web_y != 0 && d.web_z && d.web_z != 0) { 
            setPOIsList([])
            return localAnchor.push({
              quantityId:0,
              task_id:0,
              is_requested:0,
              id:"centerPoint",
              roomId:d.roomId,
              title:d.roomTitle,
              name:d.roomName,
              web_x:d.web_x,
              web_y:d.web_y,
              web_z:d.web_z,
              checklist_id:0
            })
          }
      })
    }

    setArLocalAnchors(localAnchor.slice())
    
  },[JSON.stringify(get_Ar_Local_Anchors) ,JSON.stringify(taskList), getSelectedFloorId,JSON.stringify(get_equipments_list), JSON.stringify(quantityLists),roomSetupCordinates,filterfor3dPlan,JSON.stringify(roomCordinatesData),JSON.stringify(roomCenterPoints),onLoad])

  useEffect(() => {
    let newArr = []
    let checklistArr = []
    setPOIsList([])
// set POisList state into all local anchors 
    if (getActivePOIList && getActivePOIList.length > 0) {
      getActivePOIList.filter((p) => p.web_x != 0 || p.web_y != 0 || p.web_z != 0 ).forEach((PO, index) => {
        if (index == 0 && modelViewerPoint) { 
          if ((PO.web_x && PO.web_x != 0) || (PO.web_y && PO.web_y != 0) || (PO.web_z && PO.web_z != 0)) {
            newArr.push({
              name: PO.title,
              x: PO.id == 0 ? Number(PO.web_x) : PO.web_x,
              y: PO.id == 0 ? Number(PO.web_y) : PO.web_y,
              z: PO.id == 0 ? Number(PO.web_z) : PO.web_z,
              id: PO.id,
              images: PO.images
            })
           
          }
        } 
        if(!modelViewerPoint) {
          if ((PO.web_x && PO.web_x != 0) || (PO.web_y && PO.web_y != 0) || (PO.web_z && PO.web_z != 0)) {
            newArr.push({
              name: PO.title,
              x: PO.id == 0 ? Number(PO.web_x) : PO.web_x,
              y: PO.id == 0 ? Number(PO.web_y) : PO.web_y,
              z: PO.id == 0 ? Number(PO.web_z) : PO.web_z,
              id: PO.id,
              images: PO.images
            })
           
          }
        }
      });
    }
    if (arLocalAnchors && arLocalAnchors.length > 0 && newArr.length > 0) {
      arLocalAnchors.forEach(PO => {
        if ((PO.web_x != 0 || PO.web_y != 0 || PO.web_z != 0) && PO.checklist_id == 0 && (PO.task_id > 0 || PO.quantityId > 0)) {
          newArr.push({
            name: PO.title,
            x: PO.web_x,
            y: PO.web_y,
            z: PO.web_z,
            id: PO.id,
            status:PO.status ? PO.status : "",
            is_requested:PO.is_requested,
            task_id: PO.task_id ? PO.task_id : 0,
            image: PO.images && PO.images.length > 0 && PO.images[0].image_url,
            quantityId:PO.quantityId ? PO.quantityId : 0,
            equipmentName:PO.equipmentName || "",
            equipmentId:PO.equipmentId || 0,
          })
        }
        if (PO.id == "roomId"|| PO.id == "centerPoint") {
          newArr.push({
            name: "",
            title:PO.title,
            roomId:PO.roomId|| 0,
            roomName:PO.name || "",
            from:PO.from || "",
            x: PO.web_x,
            y: PO.web_y,
            z: PO.web_z,
            id: PO.id,
            is_requested:0
          })
        }
        if (PO.checklist_id > 0 && PO.task_id > 0 && PO.task_id == taskId) {
          checklistArr.push({
            name: PO.checklist_title,
            x: PO.web_x,
            y: PO.web_y,
            z: PO.web_z,
            id: PO.id,
            task_id: PO.task_id,
            checklist_id: PO.checklist_id
          })
        }
      });
    }
    if (getActivePOIList && getActivePOIList.length > 0 && modelViewerPoint) {
      getActivePOIList.filter((p) => (p.web_x != 0 || p.web_y != 0 || p.web_z != 0)).forEach((PO,index) => {
        if (index > 0) {
          if ((PO.web_x && PO.web_x != 0) || (PO.web_y && PO.web_y != 0) || (PO.web_z && PO.web_z != 0)) {
            newArr.push({
              name: PO.title,
              x: PO.id == 0 ? Number(PO.web_x) : PO.web_x,
              y: PO.id == 0 ? Number(PO.web_y) : PO.web_y,
              z: PO.id == 0 ? Number(PO.web_z) : PO.web_z,
              id: PO.id,
              images: PO.images
            })
            
          }
        }
      });
    }
// saprate all hotspots points
    setCheckList(checklistArr.slice());
    let directionPOIs = []
    let roomPOIs = []
    let createRoomPOIs = []
    let roomCenterPOIs = []
    let otherPOIs = []
    newArr.forEach((data) => {
      if (data.id == 0) {
        directionPOIs.push(data);
      } else if (/^[a-zA-Z]/.test(data.id)) {
        if (data.from == "create") {
          createRoomPOIs.push(data)
        }else{
          if (data.id == "centerPoint") {
            roomCenterPOIs.push(data);
          }else{
            roomPOIs.push(data);
          }
        }
      } else if (data.id > 0) {
        otherPOIs.push(data);
      }
    })
  
    setPOIsList([...directionPOIs, ...roomPOIs,...createRoomPOIs,...roomCenterPOIs, ...otherPOIs].slice());
    poiList = [...directionPOIs, ...roomPOIs, ...createRoomPOIs,...roomCenterPOIs, ...otherPOIs].slice();

    if(getActivePOIList && (getActivePOIList.length == 0 || (getActivePOIList.length > 0 && getActivePOIList.filter((PO) => PO.web_x != 0 || PO.web_y != 0 || PO.web_z != 0).length == 0))){
      setIsSetPOI(true);
    }else{
      setIsSetPOI(false);
    }
}, [JSON.stringify(getActivePOIList), JSON.stringify(arLocalAnchors), taskId,onLoad ])
  
  useEffect(() => {
    if (roomSetupCordinates.length == 0) {
      cordinates = []
    }else{
      cordinates = roomSetupCordinates
    }
    if (roomCordinatesData.length > 0) {
      cordinatesData = roomCordinatesData
    }
    if (roomCordinatesList.length > 0) {
      cordinatesList = roomCordinatesList
    }
  }, [JSON.stringify(roomSetupCordinates),JSON.stringify(roomCordinatesData),JSON.stringify(roomCordinatesList)])

  const setDirection = (event) => {
    const modelViewer = document.querySelector("#client_3d");
    var material1 = modelViewer.positionAndNormalFromPoint(event.clientX, event.clientY);
    dispatch(
      showConfirmation(
        _l(""),
        _l("l_are_you_you_want_set_this_direction_as_north"),
        _l("l_yes"),
        _l("l_no"),
        () => {},
        () => {
          if (getSelectedFloorId > 0 && material1 != null && material1 != undefined) {
            customerServices.setDirectionNew(getSelectedFloorId, material1.position.x, material1.position.y, material1.position.z,documentDetail.id).then((res) => {
              if (res.status == 1) {
                let newArr = []
                newArr.push({
                  id: "0",
                  images: [],
                  title: "north",
                  web_x: material1.position.x,
                  web_y: material1.position.y,
                  web_z: material1.position.z,
                })
                dispatch(getPOIsListUpdate(newArr,1));
                setShowDirectionPoint(false)
                dispatch(showMessage("sucess", _l("l_success"), _l(res.message)));
                $(".colorDirection").remove()
              } else {
                dispatch(showMessage("unsucess", _l("l_error"),_l(res.message)));
              }
            })
          }
        }
      )
    )
  }
  const addPoints = (event) => {
    const modelViewer1 = document.querySelector("#client_3d");
    if (set_equipments_quantity) {
      let quantityData = quantityList
      var material1 = modelViewer1.positionAndNormalFromPoint(event.clientX, event.clientY);
      if (material1 != null) {
        let key = quantityData.findIndex((ss) => ss.id == set_equipments_quantity.id)
        if (key > -1) { 
          quantityData[key].web_x = material1.position.x;
          quantityData[key].web_y = material1.position.y; 
          quantityData[key].web_z = material1.position.z; 
        }
      
      }
      dispatch(setEquipmentQuantityList(quantityData));
      dispatch(setEquipmentQuantity(undefined));
    }else if (extention == "glb") {
      var x = poiList ? poiList.length : 0;
      let client_3d = '#client_3d';
      const addTask = document.querySelector(client_3d + "_addTask");
      
      const addEquipment = document.querySelector(client_3d + "_addEquipment");
      const addPois = document.querySelector(client_3d + "_addPois");
      const createPois = document.querySelector(client_3d + "_createPois");

      const modelViewer1 = document.querySelector("#client_3d");
      var material = (event.clientX, event.clientY);
      if (material != null) {
        x++;
        $("#client_3d_optionfirst").css({ 'left': event.clientX, 'top': event.clientY, 'display': 'block' });
        dispatch(closeOutsideClick(true));
        var material1 = modelViewer1.positionAndNormalFromPoint(event.clientX, event.clientY);
        if (material1 != null) { 
          
          const data = {
            x: material1.position.x,
            y: material1.position.y,
            z: material1.position.z,
          }
          dispatch(setPOILatLong(data));
          if (material1.position != null) {
            $("#client_3d").append('<button class="blank_anchor hotspot colorMask" slot="hotspot-' + x + '" data-position="' + material1.position.x + 'm ' + material1.position.y + 'm ' + material1.position.z + 'm"  data-visibility-attribute="visible"><div class="view-button"></div></button>');
          }
        }
      }
      var commoncolor = '';

      $(addTask).on('click', function () {
        $('[slot="hotspot-' + x + '"]').removeClass('blank_anchor');
        $('[slot="hotspot-' + x + '"]').addClass('colorTask');
        $('[slot="hotspot-' + x + '"] .view-button').text('Task');
        $(client_3d + "_optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
   
      $(addEquipment).on('click', function () {
        $('[slot="hotspot-' + x + '"]').removeClass('blank_anchor');
        $('[slot="hotspot-' + x + '"]').addClass('colorEquipment');
        $('[slot="hotspot-' + x + '"] .view-button').text('Equipment');
        $(client_3d + "_optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
      $(addPois).on('click', function () {
        $('[slot="hotspot-' + x + '"]').removeClass('blank_anchor');
        $('[slot="hotspot-' + x + '"]').addClass('poi_anchor');
        $('[slot="hotspot-' + x + '"]').addClass('colorRequest');
        $('[slot="hotspot-' + x + '"] .view-button').text('POIs');
        $(client_3d + "_optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
      $(createPois).on('click', function () {
        $('[slot="hotspot-' + x + '"]').removeClass('blank_anchor');
        $('[slot="hotspot-' + x + '"]').addClass('poi_anchor');
        $('[slot="hotspot-' + x + '"]').addClass('colorRequest');
        $('[slot="hotspot-' + x + '"] .view-button').text('POIs');
        $(client_3d + "_optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
    }
  }
  const modelViewer = document.querySelector('#client_3d');
  
// render local anchors rooms line dynamically
  useEffect(() => {
      if (modelViewer != null && onLoad == true) {
        setTimeout(() => {
          renderSVG();
          modelViewer.addEventListener('camera-change', renderSVG);
        }, 500);
      }
  })

  useEffect(() => {
    if (modelViewer != null && onLoad == true) {
      renderSVG();
      modelViewer.addEventListener('camera-change', renderSVG);
    }
  },[JSON.stringify(roomSetupCordinates)])

// drow line of room function call
  const renderSVG = () => {
    if (modelViewer != null && onLoad == true) {
     if (cordinates && cordinates.length > 0) {
        cordinates.forEach((line,index) => {
          if ((index+1) == cordinates.length) {  
              }else{
            drawLine(modelViewer.queryHotspot(`hotspot-${index+2 + cordinatesData.length}-0`), modelViewer.queryHotspot(`hotspot-${index+3 +cordinatesData.length}`),index+1 +cordinatesData.length,0);
          }
        })
      }
      if (cordinatesData && cordinatesData.length > 0) {
        let room_start_index = 0
        cordinatesData.forEach((line,room_index) => {
          if ((cordinatesData[room_index+1] && cordinatesData[room_index+1].roomId != line.roomId) || (room_index +1) == cordinatesData.length) { 
            drawLine(modelViewer.queryHotspot(`hotspot-${room_index+2}${line.roomId > 0 ? "-"+line.roomId :""}`), modelViewer.queryHotspot(`hotspot-${room_start_index+2}${line.roomId > 0 ? "-"+line.roomId :""}`),room_index+1,line.roomId);
            room_start_index = room_index + 1
            }else{
              drawLine(modelViewer.queryHotspot(`hotspot-${room_index+2}${line.roomId > 0 ? "-"+line.roomId :""}`), modelViewer.queryHotspot(`hotspot-${room_index+3}${line.roomId > 0 ? "-"+line.roomId :""}`),room_index+1,line.roomId);
              
            }
        })
      }
    }

   
  }

  
  // drow line of room function call
  const drawLine = (dotHotspot1, dotHotspot2,index = 0,roomId) => {
    if (dotHotspot1 && dotHotspot2 && poiList.length > 0 && index > 0) {
      $(`.dimensionLines-${index}${roomId ? "-"+roomId :""}`).attr('x1', dotHotspot1.canvasPosition.x);
      $(`.dimensionLines-${index}${roomId ? "-"+roomId :""}`).attr('y1', dotHotspot1.canvasPosition.y);
      $(`.dimensionLines-${index}${roomId ? "-"+roomId :""}`).attr('z1', dotHotspot1.canvasPosition.z);
      $(`.dimensionLines-${index}${roomId ? "-"+roomId :""}`).attr('x2', dotHotspot2.canvasPosition.x);
      $(`.dimensionLines-${index}${roomId ? "-"+roomId :""}`).attr('y2', dotHotspot2.canvasPosition.y);
      $(`.dimensionLines-${index}${roomId ? "-"+roomId :""}`).attr('z2', dotHotspot2.canvasPosition.z);
      }
    if (dotHotspot1 && dotHotspot2 && poiList.length > 0 && index == 0) {
      $(`.dimensionLines-0`).attr('x1', dotHotspot1.canvasPosition.x);
      $(`.dimensionLines-0`).attr('y1', dotHotspot1.canvasPosition.y);
      $(`.dimensionLines-0`).attr('z1', dotHotspot1.canvasPosition.z);
      $(`.dimensionLines-0`).attr('x2', dotHotspot2.canvasPosition.x);
      $(`.dimensionLines-0`).attr('y2', dotHotspot2.canvasPosition.y);
      $(`.dimensionLines-0`).attr('z2', dotHotspot2.canvasPosition.z);
    
    }
    setOnLoad(false);
  }
  useEffect(() => {
    if (getSelectedTypeOfSpaceId && Object.keys(getSelectedTypeOfSpaceId).length > 0) {
      if (getSelectedTypeOfSpaceId.poi_set) { 
        setRoomSetupCordinates(getSelectedTypeOfSpaceId.poi_set);
        cordinates = getSelectedTypeOfSpaceId.poi_set;
      }
      setSetUpRoomShow(true);
    } else {
      setRoomSetupCordinates([]);
      cordinates = [];
      if (openFrom != "create") { 
        setSetUpRoomShow(false);
      }
    }
  }, [JSON.stringify(getSelectedTypeOfSpaceId)])
  useEffect(() => {
    if (extention == "default") {
      var x = 5;
      const modelViewer1 = document.querySelector("#placeholder_demo");
      var commoncolor = '';
      $(document).on('dblclick', '#placeholder_demo_1', function (event) {
        var material = modelViewer1.materialFromPoint(event.clientX, event.clientY);
        if (material != null) {
          x++;
          $("#optionfirst").css({ 'left': event.offsetX, 'top': event.offsetY, 'display': 'block' });
          var material = modelViewer1.materialFromPoint(event.clientX, event.clientY);
          if (material != null) {
            var material1 = modelViewer1.positionAndNormalFromPoint(event.clientX, event.clientY);
            if (material1.position != null) {
              $('#placeholder_demo').append('<button class="placeholder_hotspot" slot="hotspot-' + x + '" data-position="' + material1.position.x + 'm ' + material1.position.y + 'm ' + material1.position.z + 'm"  data-visibility-attribute="visible"><div class="view-button"></div></button>');
            }

          } else {
          }

        }
      });
      $(document).on('click', '.placeholder_hotspot', function (hotspot) {
        setShow(true);
      });
      $('#setPOIs').on('click', function () {
        $('[slot="hotspot-' + x + '"]').addClass('colorRequest');
        $('[slot="hotspot-' + x + '"] .view-button').text('POIs');
        $("#optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
      $('#addTask').on('click', function () {
        $('[slot="hotspot-' + x + '"]').addClass('colorTask');
        $('[slot="hotspot-' + x + '"] .view-button').text('Task');
        $("#optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
      $('#addRequest').on('click', function () {
        $('[slot="hotspot-' + x + '"]').addClass('colorRequest');
        $('[slot="hotspot-' + x + '"] .view-button').text('Request');
        $("#optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
      $('#addEquipment').on('click', function () {
        $('[slot="hotspot-' + x + '"]').addClass('colorEquipment');
        $('[slot="hotspot-' + x + '"] .view-button').text('Equipment');
        $("#optionfirst").css({ 'display': 'none' });
        commoncolor = '';
      });
      
      return () => {
        jquery("body").removeClass("pb-0");
        removeModifiedClass();
      };
    }
  }, [extention]);
  const modelViewer1 = document.getElementById("roofless");

  useEffect(() => {
    dispatch(getSpaceFloorList());
    return () => {
      
    };
  }, []);
  useEffect(() => {
    if (getSapceFloorList && getSapceFloorList.length > 0) {
      dispatch(selectedFloorId(getSapceFloorList[0].id));
    }
  }, [getSapceFloorList])
  useEffect(() => {
    if (getSelectedFloorId) {
      dispatch(getFloorDocumentByFloorId(getSelectedFloorId));
    } else {
      setDocumentList({});
    }
  }, [getSelectedFloorId])

  
  const spaces = JSON.parse(localStorage.getItem("spaces")) ? JSON.parse(localStorage.getItem("spaces")) : [];

  const [projectId, setProjectId] = useState(localStorage.getItem('selectedOffice'));
  const getFloorDocument = useSelector(
    (state) => state.customer.getFloorDocument
  );

  useEffect(() => {
    setDocumentList(getFloorDocument);
    if (getFloorDocument && getFloorDocument.attachments && getFloorDocument.attachments.length > 0) {
      setDocumentDetail(getFloorDocument.attachments[0]);
    } else {
      setDocumentDetail({});
    }
    if (getFloorDocument && getFloorDocument.entry != null && getFloorDocument.entry != "") {
      setShowDirectionPoint(false)
    } else {
      setShowDirectionPoint(true)
    }
  }, [getFloorDocument])

  useEffect(() => {
    if (documentList && documentList.attachments) {
      const arr = documentList.attachments.filter((df) => df.file_name.split(".").slice(-1)[0] == "jpg" || df.file_name.split(".").slice(-1)[0] == "png" || df.file_name.split(".").slice(-1)[0] == "jpeg")
      setDocumentAttachments(arr);
    }
  }, [documentList])
  useEffect(() => {
    if (documentDetail && documentDetail.file_url) {
      const name = documentDetail.file_url.split("/").slice(-1)[0]
      
      setExtention(name.split(".").slice(-1)[0]);
    }
    if (documentDetail && documentDetail.length == 0) {
      setExtention("default");
    }
  }, [documentDetail])

  const previewDocument = (document) => {
    setDocumentDetail(document);
  }

  const setAttchmentOnTop = (id) => {
    const targetAttchement = documentAttachments.filter(
      (attachment) => attachment.id === id
    );
    const otherAttachments = documentAttachments.filter(
      (attachment) => attachment.id != id
    );
    setDocumentAttachments(targetAttchement.concat(otherAttachments));
  };

  const handleSelectedTask = (
    taskId = 0,
    show = "",
    isRequestedTask = null,
    convertRequestTaskId = 0
  ) => {
    setShowTaskModal(false);
    setTaskId(taskId);
    localStorage.setItem("SelectedTask", taskId);
    localStorage.setItem(
      "TaskType",
      isRequestedTask && isRequestedTask != null ? isRequestedTask : 0
    );
    if (isRequestedTask || taskId) {
      setShowTaskModal(show);
    }
  };
  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "static",
  };
  const threedAlternatMapcontainerStyle = {
    width: "100%",
    height: "100%",
    position: "static",
  }
  useEffect(() => {
    setTask_type(selectedTask && selectedTask.is_requested && selectedTask.is_requested == 1 ? 'request-task' : 'task');
    setCommonModalData(selectedTask);
  }, [JSON.stringify(selectedTask)])
  const clearChecklist = () => {
    setCheckList([]);
    setTaskId(0);
  };

    function mouseDown (c, key, fromDummy) {
      setIsDragging(false)
      let localIsDragging = false; 
      const equipmentBtn = document.getElementById(`hotspot-${key + 1}`);
      // ondragstart to false cause broweser has defualt support for images and it conflict with us as we have img for equipment
       equipmentBtn.ondragstart = function() {
        return false;
      };

      const modelViewer = document.querySelector('#client_3d');

       function moveAt(pageX, pageY) {
        var cordinates = modelViewer.positionAndNormalFromPoint(pageX, pageY);
          if(cordinates){
            modelViewer.updateHotspot({
            name: `hotspot-${key + 1}`,
            position: `${cordinates.position.x} ${cordinates.position.y} ${cordinates.position.z}`
            });
          }
          else{
            document.removeEventListener('mousemove', onMouseMove);
            equipmentBtn.onmouseup = null;
            modelViewer.updateHotspot({
              name: `hotspot-${key + 1}`,
              position: `${c.x} ${c.y} ${c.z}`
            });
            dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_place_equipment_item_within_area_of_3D_space")));
          }
        }

        function onMouseMove(event) {
          moveAt(event.pageX, event.pageY);
          setIsDragging(true)
          return localIsDragging = true;  
        }

        document.addEventListener('mousemove', onMouseMove);

        equipmentBtn.onmouseup = function(event) {
          document.removeEventListener('mousemove', onMouseMove);
          equipmentBtn.onmouseup = null;
          if (localIsDragging && !fromDummy) {
            var cordinates = modelViewer.positionAndNormalFromPoint(event.clientX, event.clientY);
            if (c.quantityId > 0) {
              customerServices.setArLocalAnchors(getSelectedFloorId, 0, 0, cordinates.position.x, cordinates.position.y, cordinates.position.z, parseInt(c.equipmentId), c.quantityId)
                .then((res) => {
                  if (res.status == 1) { 
                    let localArray = get_equipments_list
                    let index = localArray.findIndex((d) => d.id == c.equipmentId)
                    if (index > -1) {
                      let key = localArray[index].quantity_details.findIndex((eq) => eq.id == c.quantityId)
                      if (key > -1) { 
                        localArray[index].quantity_details[key].web_x = cordinates.position.x;
                        localArray[index].quantity_details[key].web_y = cordinates.position.y;
                        localArray[index].quantity_details[key].web_z = cordinates.position.z;
                      }
                    }
                  }
                })
            }
            if (c.task_id > 0) {
              const {x, y, z} = cordinates.position
              customerServices.updateLocalAnchorsDirection(x.toString(), y.toString(), z.toString(), c.id)
              .then((res) => {
                if (res.status == 1) { 
                  let localArray = get_Ar_Local_Anchors
                  localArray = localArray.map(anchor => {
                    if (anchor.id === c.id) {
                      return { ...anchor,  web_x : x.toString(), web_y: y.toString(), web_z: z.toString()}; 
                    }
                    return anchor; 
                  });
                  dispatch(updateLocalAnchor(localArray));
                }
              })
            } 
          }
        }
    }

    const getAddressForMapLocation = (projectId) => {
      const activeSpace =  spaces.filter((space) => space.id == projectId)
      if (activeSpace && activeSpace.length > 0 && activeSpace[0].latitude && activeSpace[0].longitude) {
        setLocationLetAndLng({
          lat:  Number(activeSpace[0].latitude),
          lng: Number(activeSpace[0].longitude)
        });
      }
    };

    const handleOnLoad = (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      get_Ar_Local_Anchors.forEach((position) => bounds.extend({
        lat: Number(position.lat),
        lng: Number(position.long)  
      }));
      map.fitBounds(bounds);
    };

    const addDummyPOIs = (event) => {
      const modelViewer1 = document.querySelector("#client_3d");
      var material1 = modelViewer1.positionAndNormalFromPoint(event.clientX, event.clientY);
      var x = dummyPOIs ? dummyPOIs.length : 0;
      var material = (event.clientX, event.clientY);
      if (material != null) {
        $("#client_3d_optionfirst").css({ 'left': event.clientX, 'top': event.clientY, 'display': 'block' });
        const data = {
          x: material1.position.x,
          y: material1.position.y,
          z: material1.position.z,
        }
        dispatch(closeOutsideClick(true));
        dispatch(setPOILatLong(data));
        if (material1 != null && material1.position != null) {
          $("#client_3d").append('<button class="blank_anchor hotspot colorMask" slot="hotspot-' + x + 1 + '" data-position="' + material1.position.x + 'm ' + material1.position.y + 'm ' + material1.position.z + 'm"  data-visibility-attribute="visible"><div class="view-button"></div></button>');
        }
      }
    }

    useEffect(() => {
      if (modelViewer) {
        checkList.length == 0 && POIsList && POIsList.map((c, key) => {
          modelViewer.updateHotspot({
            name: `hotspot-${key + 1}`,
            position: `${c.x} ${c.y} ${c.z}`
          })
        })
      }
    }, [POIsList])

    useEffect(() => {
      if (modelViewer) {
        dummyPOIs && dummyPOIs.map((c, key) => {
          modelViewer.updateHotspot({
            name: `hotspot-${key + 1}`,
            position: `${c.x} ${c.y} ${c.z}`
          })
        })
      }
    }, [dummyPOIs])

  return (
    <>
       
      <div className="d-flex flex-column h-100 plan-main-div">
      
        <FloorDocument
          show={showToolsModal}
          handleClose={() => {
            setShowToolsModal(false);
          }}
          data={documentList}
        />
        <div className="comman-contnet-wrapper res-border-0 h-100 pt-2 border-0 space-document-main position-relative">
          <div className="d-flex flex-wrap  h-100">
            {
              documentList && documentList.attachments && documentList.attachments.length > 0 ?
                <div className="left-content-part w-280 p-0 res-width-100 h-100  d-flex flex-column comman-contnet-wrapper border-end-0 d-none">
                  <div className="comman-content-scroll-wrapper overflow-hiiden-web">
                    <div className="d-flex align-items-center p-2 border-bottom justify-content-between">
                      <div className="title-fonts fw-semibold">{getSapceFloorList && getSapceFloorList.length && getSapceFloorList.filter(fd => fd.id == getSelectedFloorId)[0] && getSapceFloorList.filter(fd => fd.id == getSelectedFloorId)[0].name}</div>
                  
                    </div>
                    <div className="comman-content-scroll comman-verticle-tab">
                      {

                        documentList.attachments.map((thumb, index) => {
                          return <li className="comman-list p-0 w-100">
                            <a href="#/" key={index} className={`w-100 position-relative tab_link ${documentDetail.id == thumb.id ? "active" : ""}`}
                              onClick={() => previewDocument(thumb)}>
                              <div className="d-flex align-items-center text-truncate">
                                <div className="comman-list-left text-truncate flex-grow-1">
                                  <div className="upload-image-preview">
                                    <div className="d-flex align-items-center justify-content-start">
                                      <div className="comman-image-box h40w40 radius_3 upload-image">
                                        <div
                                          className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3 "
                                          style={{
                                            backgroundImage: `url('${thumb.file_url}')`,
                                          }}
                                        ></div>
                                      </div>
                                      <div class="image-text w100minus30 ps-3 text-truncate">
                                        <div class="text-truncate c-font f-13"
                                           
                                           
                                           
                                           >{thumb.file_name}</div>
                                        <div class="text-truncate c-font f-11 color-white-60 "></div>
                                         
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="comman-list-right">
                                  <CaretRightIcon className="HW16" />
                                </div>
                              </div>
                            </a>
                          </li>
                        })
                      }
                    </div>
                  </div>
                </div>
                : <></>
            }
            <div className={`right-content-part width-calc-280 h-100 comman-contnet-wrapper ${(showDirectionPoint || isSetPOI)  ? "": ""}`}>{/*z-index-21 border-0*/}
              { projectId == null || projectId == 0 || projectId == undefined ? 
                <>
                {isLoaded &&
                <GoogleMap
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                      options={{
                        streetViewControl: false,
                        scrollwheel: false,
                        styles: mapStyle,
                      }}
                      mapContainerStyle={containerStyle}
                      center={coOrdinates}
                      zoom={12}
                    >
                      <Marker position={coOrdinates} />
                </GoogleMap>
                }
                </> 
              : <div className="comman-main-box mt-0 h-100 p-2">
                <div className="myspace-fixed-box d-none">
                  <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "Map-View" ? "active" : ""}`} onClick={() => { setSelectedTab("Map-View"); }}>

                    <MapPinLineIcon className="HW20 path-fill-transparent" />
                  </a>
                  <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "AR-View" ? "active" : ""}`} onClick={() => {
                    setSelectedTab("AR-View");
                  }}>

                    <ArVrIcon className="HW20 path-fill-transparent" />
                  </a>
                  <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "ThreeDScan-View" ? "active" : ""}`} onClick={() => { setSelectedTab("ThreeDScan-View"); }}>

                    <MapTrifoldIcon className="HW20" />
                  </a>
                </div>

                {documentList && documentList.attachments && documentList.attachments.length ?
                  extention == "glb" ?
                    (
                      <>
                      { getSelectedTypeOfSpaceId && Object.keys(getSelectedTypeOfSpaceId).length > 0 ? 
                      <div className={`space-placeholder-box text-center`}>
                      <div className="title-fonts fw-semibold  mb-2">
                        {_l("l_please_setup_room_to_click_on_3dmodel")}
                      </div>
                    </div>
                      :set_equipments_quantity ? 
                        <div className={`space-placeholder-box text-center`}>
                        <div className="title-fonts fw-semibold  mb-2">
                          {_l("l_set_equipment_quantity_to_double_click_on_3dmodel")}
                        </div>
                      </div> 
                      : showDirectionPoint ? 
                        <div className={`space-placeholder-box text-center`}>
                        <div className="title-fonts fw-semibold  mb-2">
                          {_l("l_please_set_direction_description_using_dobule_click_in_3d_modal")}
                        </div>
                      </div>
                        : getPOIList && getPOIList.length == 0 && getActivePOIList && getActivePOIList.length == 0 ? 
                        <div className={`space-placeholder-box text-center`}>
                        <div className="title-fonts fw-semibold  mb-2">
                          {_l("l_please_set_pios_using_dobule_click_in_3d_modal")}
                        </div>
                      </div>
                        :
                        <></>
                      }

                    <span className="card-view-less-padding comman_action_icon me-10px p-0 position-absolute width-50px z-1" style={{ right: "0", top: "20px" }}>
                      <div className={`action_icon close-toast comman_action_icon h40w40 priority-checkbox with_bg bg-white-03-solid-imp `}>
                        <input
                          type="checkbox"
                          id="priority"
                          className="absolute-input"
                          onChange={()  => setMapShow(!mapShow)}
                        />
                        <div className="check_box">
                          <BuildingsIcon className="HW24 check_span_img active h30w30" />
                          <MapTrifoldIcon className="HW24 check_span_img inactive h30w30" />
                        </div>
                      </div>
                       
                    </span>
                  { mapShow 
                    ? <Form.Group className="map-box-parent overflow-hidden h-100">
                        {Object.keys(locationLetAndLng).length && isLoaded ? (
                          <GoogleMap
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                            id="map"
                            onLoad={handleOnLoad}
                            options={{
                              streetViewControl: false,
                              scrollwheel: false,
                              styles: mapStyle,
                              gestureHandling : "none"
                            }}
                            mapContainerStyle={threedAlternatMapcontainerStyle}
                            center={locationLetAndLng}
                            zoom={18}
                          >
                            <Marker
                              position={locationLetAndLng}
                            />
                              { get_Ar_Local_Anchors &&
                                get_Ar_Local_Anchors.map((position) => (
                                  <OverlayView
                                    position={{
                                      lat: Number(position.lat),
                                      lng: Number(position.long)  
                                    }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                  >
                                    <Marker position={{
                                      lat: Number(position.lat),
                                      lng: Number(position.long)  
                                    }}/>
                                  </OverlayView>
                                ))
                              }
                          </GoogleMap>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                    : <model-viewer
                      id="client_3d"
                      alt="Space"
                      style={{position:"relative"}}
                      src={encodeURI(documentDetail.file_url)}
                    
                      onClick={(event) => {
                        const modelViewer = document.querySelector("#client_3d");
                        var material1 = modelViewer.positionAndNormalFromPoint(event.clientX, event.clientY);
                        if (getSelectedTypeOfSpaceId && Object.keys(getSelectedTypeOfSpaceId).length > 0 && material1 != null) {
                          let arr = roomSetupCordinates
                          if (material1 != null) {
                            arr.push(
                              {
                                web_x: material1.position.x,
                                web_y: material1.position.y,
                                web_z: material1.position.z
                              })
                        }
                          cordinates = arr.slice()
                          setRoomSetupCordinates(arr.slice());
                        }
                      }}
                      onDoubleClick={(event) => {
                        const modelViewer1 = document.querySelector("#client_3d");
                        var material = modelViewer1.positionAndNormalFromPoint(event.clientX, event.clientY);
                        if(material != null){
                          console.log("coordinate", ' x:', material.position.x, ' y:', material.position.y, ' z:',material.position.z);
                        }
                        if (showDirectionPoint && material != null && (getSelectedTypeOfSpaceId == undefined || Object.keys(getSelectedTypeOfSpaceId).length == 0)) {
                           setDirection(event);
                       
                        } else {
                          if (material != null && (getSelectedTypeOfSpaceId == undefined || Object.keys(getSelectedTypeOfSpaceId).length == 0)) { 
                            addPoints(event)
                          }
                       
                        }
                      }}
                      camera-controls
                      shadow-intensity="1"
                      disable-tap
                      ar
                      environment-image=""
                      crossorigin="anonymous"
                      touch-action="none"
                    >
                      {checkList.length == 0 && POIsList && POIsList.map((c, key) => {
                        if (c.id == 0) {
                          return (
                          <div className="colorDirection fw-bolder poi_anchor"
                          data-position={`${c.x}m ${c.y}m ${c.z}m`}
                          slot={`hotspot-${key + 1}`}
                          >N
                            </div>
                        )}
                        else{
                        return (
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        delay={{ show: 2000 }}
                        key={"bottom"}
                        placement={"bottom"}
                        onEnter={(e)=> {
                          if ((getSelectedTypeOfSpaceId == undefined || Object.keys(getSelectedTypeOfSpaceId).length == 0) && c.task_id > 0) {
                            setTaskId(c.task_id);
                            setClientY(e.clientY);
                            setClientX(e.clientX);
                            setShowTaskInfo(true)
                            dispatch(getSelectedTask(projectId, c.task_id, c.is_requested == "1" ? true : false ));
                          } 
                        }}
                        overlay={
                          c.task_id > 0 ? (
                            <Popover
                              id={`popover-positioned-${"bottom"}`}
                              className=""
                            >
                            <CalendarDropdown
                              projectId={projectId}
                              taskId={taskId}
                              providers={[]}
                              dayClick={''}
                              meetingUrl={''}
                              docType={"task"}
                              pageName={''}
                              data={{ contacts: '' }}
                              isOutlookCalActive={false}
                              handler={handleSelectedTask}
                              selectCalIds={[]}
                              setShowTaskInfo={setShowTaskInfo}
                              taskNameOrStatusUpdate={(
                                task_id,
                                task_name_status,
                                flag = ""
                              ) => {
                                let eventUpdate = events && events.map((event) => {
                                  if (task_id == event.id) {
                                    if (flag != "") {
                                      if (task_name_status == 1) {
                                        event["color"] = "#b3b3b3";
                                      } else if (task_name_status == 4) {
                                        event["color"] = "#03A9F4";
                                      } else if (task_name_status == 5) {
                                        event["color"] = "#00ff6a";
                                      }
                                    } else {
                                      event["title"] = task_name_status;
                                    }
                                    return event;
                                  }
                                  return event;
                                });
                                setEvents(eventUpdate);
                              }}
                              setCheckListClear={clearChecklist}
                              style = {{left : clientX,top : clientY}}
                              setTaskId3dspase = {setTaskId}
                            />
                            </Popover>
                          ) : (
                            <></>
                          )
                          }
                          >
                        <button
                          class={`${
                            c.task_id > 0 && c.status == 5 && c.is_requested == 0
                             ? "hotspot colorDone"
                              : c.task_id > 0 && c.status == 1 && c.is_requested == 0
                                ? "hotspot zeroTask"
                                 : c.task_id > 0 && c.is_requested == 1 && c.status == "1"
                                  ? "hotspot colorRequest"
                                   : c.status == 5 ? "hotspot colorDone"
                                    : c.status == 4 ? "hotspot colorInprogress"
                                     : c.status == 1 ? "hotspot zeroTask"
                                   : c.quantityId > 0
                                  ? "hotspot colorEquipment" : c.id == 0
                                   ? "poi_anchor colorDirection"
                                    : c.id == "roomId" && c.from != "create" 
                                      ? "poi_anchor" : c.from == "create"
                                       ? "poi_anchor colorPois"
                                        : c.id == "centerPoint"
                                         ? "colorPois bg-transparent"
                                         : "poi_anchor colorPois"}
                                         `}
                          id={`hotspot-${key + 1}`}
                          slot={`hotspot-${key + 1}${c.id == "roomId"? "-"+c.roomId :""}`}
                          data-position={`${c.x}m ${c.y}m ${c.z}m`}
                          
                          data-visibility-attribute="visible"
                          onMouseDown={c.quantityId > 0 || c.task_id >  0 ? event => {
                              mouseDown(c, key, 0)
                          } : ""}
                          onClick={!isDragging ? (e) => { 
                            e.stopPropagation();
                            if (c.task_id > 0) {
                              handleSelectedTask(c.task_id,true,c.is_requested == "1" ? true : false)
                              setTaskId(0);
                              setShowTaskInfo(false)
                              setTaskId(c.task_id);
                              setshowEquipment(false);
                              setOpenCreatePOI(false);
                              dispatch(getSelectedTask(projectId, c.task_id, c.is_requested == "1" ? true : false ));
                            }else if(c.id == "centerPoint"){
                              setSelectedRoom(c);
                              setSetUpRoomShow(true);
                              setOpenFrom("create")
                            }else if(c.quantityId > 0){
                              setSelectedEquipment(c);
                              setshowEquipment(true);
                              setOpenCreatePOI(false);
                              setCommonModalData(false)
                              dispatch(setEquipmentQuantity(c))
                            } else {
                              if (c.id != "roomId" && c.id != "centerPoint" && c.id != 0 && (c.quantityId == undefined || c.quantityId == 0) && (getSelectedTypeOfSpaceId == undefined || Object.keys(getSelectedTypeOfSpaceId).length == 0)) {
                                setSelectedPOI(c)
                                setOpenCreatePOI(true);
                                setshowEquipment(false);
                                setCommonModalData()
                              }
                            }
                          } : "" }
                        >
                          {
                            commanoffcanvasIsOpen && c.quantityId > 0
                              ? <img className={`${showEquipment && c.quantityId == selectedEquipment.quantityId ? "call-ring-circle" : ""} hotspot equipmentBg opacity-100 position-absolute top-0 start-0`} src={c.image && c.image != "" && !c.image.includes("placeholder") ? c.image : "https://source.unsplash.com/random"} border="0" alt="" />
                              : (!showTaskInfo && commonModalData && c.task_id && c.task_id == commonModalData.id) || 
                                (openCreatePOI && selectedPOI && c.id == selectedPOI.id)
                                ? <img className={`call-ring-circle hotspot opacity-100 position-absolute top-0 start-0`} border="0" alt="" />
                                : <></>
                          }
                          <div class="view-button">{c.id == "roomId" ? "" : c.id == "centerPoint"? c.title : c.name}</div>
                        </button>
                      </OverlayTrigger>
                      )}
                      })
                      }

                      {checkList && checkList.length > 0 && checkList.map((c, key) => {
                        return <button
                          class={`${c.checklist_id > 0 ? "hotspot colorEquipment" : ""}`}
                          slot={`hotspot-${key + 1}`}
                          data-position={`${c.x}m ${c.y}m ${c.z}m`}
                          data-normal="-0.11739374762335689m 0.9083539582811813m 0.4013873372365575m"
                          data-visibility-attribute="visible"
                        >
                          <div class="view-button">{c.name}</div>
                        </button>
                      })
                      }
                      <svg id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
                      <line className="dimensionLine dimensionLines-0"></line>
                      {roomSetupCordinates && roomSetupCordinates.map((dd,index) => {
                        return <line className={`dimensionLine dimensionLines-${index + 1 + roomCordinatesData.length}`} style={{stroke: "#16a5e6" , strokeWidth : "3px" , strokeDasharray: "0"}}></line>
                      })}
                      {
                        roomCordinatesData && roomCordinatesData.map((ff, index) => {
                            return (<line className={`dimensionLine dimensionLines-${index+1}${ff.roomId > 0 ? "-"+ff.roomId :""}`} style={{stroke: `${selectedRoom.roomId == ff.roomId? "#25fd76" : "#16a5e6"}` , strokeWidth : "3px" , strokeDasharray: "0"}}></line>)
                        })
                      }
                      </svg> 
                    </model-viewer>
                  }                    
                     </>)
                    : extention == "pdf" ?
                      <iframe src={documentDetail.file_url} className="h-100 w-100" />
                      : extention == "jpg" || extention == "png" || extention == "jpeg" ?
                        <div className="h-100 w-100 bg-style-contain"
                          onClick={() => {
                            setOpenGallery(true);
                            setAttchmentOnTop(documentDetail.id)
                          }}
                          type="button"
                          style={{
                            backgroundImage: `url('${documentDetail.file_url}')`,
                          }}
                        >
                        </div>
                        :
                        <></>
                  :
                  <>
                  <span className="card-view-less-padding comman_action_icon me-10px p-0 position-absolute width-50px z-1" style={{ right: "0", top: "20px" }}>
                    <div className={`action_icon close-toast comman_action_icon h40w40 priority-checkbox with_bg bg-white-03-solid-imp `}>
                      <input
                        type="checkbox"
                        id="priority"
                        className="absolute-input"
                        onChange={()  => setMapShow(!mapShow)}
                      />
                      <div className="check_box">
                        <MapTrifoldIcon className="HW24 check_span_img active h30w30" />
                        <BuildingsIcon className="HW24 check_span_img h30w30" />
                      </div>
                    </div>
                     
                  </span>
                  { !mapShow
                    ? <Form.Group className="map-box-parent overflow-hidden h100minus50px">
                      {Object.keys(locationLetAndLng).length && isLoaded ? (
                       <GoogleMap
                          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_SECRET}
                          
                          options={{
                            streetViewControl: false,
                            scrollwheel: false,
                            styles: mapStyle,
                            gestureHandling : "none",
                          }}
                          mapContainerStyle={threedAlternatMapcontainerStyle}
                          center={locationLetAndLng}
                          zoom={18}
                        >
                          <Marker
                            position={locationLetAndLng}
                          />
                        </GoogleMap>
                      ) : (
                        <></>
                      )}
                      </Form.Group>
                    : 
                  <>
                    <div className="space-placeholder-box text-center">
                      <div className="title-fonts fw-semibold  mb-2">
                        {_l("l_space_placeholder_title")}
                      </div>
                      <div className="title-fonts c-font f-13 opacity-60">
                        {_l("l_space_placeholder_description")}
                      </div>
                    </div>
                    <model-viewer
                      id="client_3d"
                      class="h-100"
                      alt="Space"
                      src={RooflessFile}
                      camera-controls
                      shadow-intensity="1"
                      ar
                      disable-tap
                      environment-image=""
                      poster=""
                      touch-action="none"
                      min-camera-orbit='auto auto 100%'
                      max-camera-orbit='auto auto 100%'
                      onDoubleClick={(event) => {
                        const modelViewer1 = document.querySelector("#client_3d");
                        var material = modelViewer1.positionAndNormalFromPoint(event.clientX, event.clientY);
                        if(material != null){
                            addDummyPOIs(event)
                            setIsDummyModal(true)
                        }
                      }}
                    >
                      { dummyPOIs && dummyPOIs.map((c, key) => {
                        if (c.id == 0) {
                          return (
                            <div className="colorDirection fw-bolder poi_anchor" data-position={`${c.x}m ${c.y}m ${c.z}m`} slot={`hotspot-${key + 1}`}>N</div>
                          )
                        }
                        else {
                          return (
                            <button
                              class={`${
                                  c.task_id > 0 && c.status == 5 && c.is_requested == 0 ? "hotspot colorDone"
                                    : c.task_id > 0 && c.status == 1 && c.is_requested == 0 ? "hotspot zeroTask"
                                      : c.task_id > 0 && c.status == 4 && c.is_requested == 0 ? "hotspot colorInprogress"
                                          : c.task_id > 0 && c.status == 1 && c.is_requested == 1 ? "hotspot colorRequest"
                                              : c.quantityId > 0 ? "hotspot colorEquipment"
                                                  : c.id == 0 ? "poi_anchor colorDirection"
                                                      : c.id == "roomId" && c.from != "create" ? "poi_anchor"
                                                        : c.from == "create" ? "poi_anchor colorPois" 
                                                          : c.id == "centerPoint" ? "colorPois bg-transparent"
                                                            : "poi_anchor colorPois"
                              }`}
                              id={`hotspot-${key + 1}`}
                              slot={`hotspot-${key + 1}${c.id == "roomId"? "-"+ c.roomId :""}`}
                              data-position={`${c.x}m ${c.y}m ${c.z}m`}
                              data-visibility-attribute="visible"
                              onMouseDown={c.quantityId > 0 || c.task_id >  0 ? event => mouseDown(c, key, 1) : ""}
                            >
                              {
                                commanoffcanvasIsOpen && c.quantityId > 0
                                  ? <img className={`${showEquipment && c.quantityId == selectedEquipment.quantityId ? "call-ring-circle" : ""} hotspot equipmentBg opacity-100 position-absolute top-0 start-0`} src={c.image && c.image != "" && !c.image.includes("placeholder") ? c.image : "https://source.unsplash.com/random"} border="0" alt="" />
                                  : (!showTaskInfo && commonModalData && c.task_id && c.task_id == commonModalData.id) || 
                                    (openCreatePOI && selectedPOI && c.id == selectedPOI.id)
                                    ? <img className={`call-ring-circle hotspot opacity-100 position-absolute top-0 start-0`} border="0" alt="" />
                                    : <></>
                              }
                              <div class="view-button">{c.id == "roomId" ? "" : c.id == "centerPoint"? c.title : c.name}</div>
                            </button>
                          )
                        }
                      })}
                    </model-viewer>
                    <div className="space-document-add-button">
                      
                      <Button variant="primary" className=""
                        onClick={() => { setShowBook3dPlanModal(true) }}
                      >
                        {_l("l_book_3d_plan")}
                      </Button>
                    </div>
                  </>
                  }
                  </>
                }
              </div>}
            </div>

          </div></div>
        {
          documentDetail && (extention == "jpg" || extention == "png" || extention == "jpeg") > 0 ?

            <Lightbox
              open={openGallery}
              close={() => setOpenGallery(false)}
              slides={documentAttachments.map((src) => { return { src: src.file_url }; })}
            />
            : <></>
        }

        <div className="plan-iframe d-none">
          <div className="myspace-fixed-box">
            <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "Map-View" ? "active" : ""}`} onClick={() => { setSelectedTab("Map-View"); }}>

              <MapPinLineIcon className="HW20 path-fill-transparent" />
            </a>
            <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "AR-View" ? "active" : ""}`} onClick={() => {
              setSelectedTab("AR-View");
            }}>

              <ArVrIcon className="HW20 path-fill-transparent" />
            </a>
            <a href="#/" className={`dropdown-item p-2 on-hover-active-toggle-img ${selectedTab === "ThreeDScan-View" ? "active" : ""}`} onClick={() => { setSelectedTab("ThreeDScan-View"); }}>

              <MapTrifoldIcon className="HW20" />
            </a>
          </div>
        </div>

        <MyspaceTaskModalAdd setSetUpRoomShow ={setSetUpRoomShow} setOpenFrom={setOpenFrom} openCreatePOI={openCreatePOI} setOpenCreatePOI={setOpenCreatePOI} setSelectedPOI={setSelectedPOI} isDummyModal={isDummyModal} dummyPOIs={dummyPOIs} setDummyPOIs={setDummyPOIs}/> 
        
       
        { 
        setUpRoomShow ?
          <CommanOffCanvas
          show={setUpRoomShow}
          handleClose={() => {
            dispatch(selectedTypeOfSapceId(undefined));
            setOpenFrom("");
            setSetUpRoomShow(false);
            setSelectedRoom("");
            if ($('.blank_anchor').length) {
              $('.blank_anchor').remove();
            }
            $("body").removeClass("task-modal-offcanvas-open");
            hideRightPanelNew();
            showLeftPanelNew()
          }}
       
        handleChangeCordinate={setRoomSetupCordinates}
        setPOIsList={() => setPOIsList([])}
        cordinatesData = {roomSetupCordinates}
        typeOfSpaceData = {getSelectedTypeOfSpaceId}
        floorId={getSelectedFloorId}
        from={openFrom}
        selectedRoom={selectedRoom}
        setSelectedRoom={setSelectedRoom}
          setData={() => {}}
          docType={'type_of_space'}
          setSelectedId={() => {}}
        />
        :<></>
        }

          {
            showEquipment ?
              <CommanOffCanvas
                show={showEquipment}
                handleClose={() => {
                  if ($('.blank_anchor').length) {
                    $('.blank_anchor').remove();
                  }
                  setshowEquipment(false);
                  $("body").removeClass("task-modal-offcanvas-open");
                  hideRightPanelNew();
                  showLeftPanelNew();
                }}
                selectedEquipment={selectedEquipment}
                setData={() => { }}
                docType={'equipment_task'}
                floorId={getSelectedFloorId}
                setSelectedId={() => { }}
              />
              : <></>
          }

        {
          show ? (
            <CreateTaskRequestOffcanvas
              show={show}
              handleClose={() => {
                setShow(false)
              }}
            />
          ) : (
            <></>
          )
        }
         
          {
        openCreatePOI ?
          <CommanOffCanvas
            show={openCreatePOI}
            handleClose={() => {
              setOpenCreatePOI(false)
              setSelectedPOI({})
              if ($('.blank_anchor').length) {
                $('.blank_anchor').remove();
              }
            }}
            docType={'poi'}
            selectedPoi={selectedPOI}
            setPOIsList={setPOIsList}
            setSelectedId={() => { }}
            floorId={getSelectedFloorId}
          />
          : <></>
      }
        {
          showTaskModal ? <CommanOffCanvas
            show={showTaskModal}
            handleClose={() => {
              setShowTaskModal(false);
              setCommonModalData();
              setTaskId(0);
              setTask_type('')
            }}
            pinHandler={(id, type, flag) => {
            }}
            data={commonModalData}
            pageName={"task"}
            docType={task_type}
            setSelectedId={setTaskId}
            selectedTaskId={taskId}
            IsKanbanView={false}
            meetingUrl={''}
            canvasfullHeightwithHeaderFooter={true}
            handleParentComponentAction={(type, taskData) => {
              if (task_type == 'task') {
                if (type == 'attachmentCountUpdate') {
                  
                  return;
                } else if (type == 'handleSelectedTask') {
                  handleSelectedTask(taskData.task_id, taskData.flag)
                  return;
                } else if (type == 'convertedTaskAction') {
                  handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                  return;
                }
              } else if (task_type == 'request-task') {

                if (type == 'convertedTaskAction') {
                  handleSelectedTask(taskData.convertTaskId, taskData.isShow, taskData.isRequestTask, taskData.task_id)
                  return;
                }
              }

            }}
          /> : <></>
        }
        {showBook3dPlanModal ?
          <BookThreedPlan
            projectId={projectId}
            show={showBook3dPlanModal}
            handleClose={() => {
              setShowBook3dPlanModal(false);
            }}
          /> : <></>}
      </div> 
       {/* tutorial 3d map */}
       {
       (showDirectionPoint || isSetPOI) && !(getPOIList && getPOIList.length == 0 && getActivePOIList && getActivePOIList.length == 0) ? 
       
      <div className={`tutorial-3d-map bottom-0 end-0 h-100 position-absolute start-0 top-0 w-100 d-none`}>
        <div className="position-relative h-100 w-100">
          <div className="tutorial-3d-direction-dropdown z-index-2 d-none" style={{ position: "absolute", left: "1%", top: "30%", transform: "translate(15px, 2px)"}}>
          <Select className="custom-select-menu w-220 bg-white-hex-03 radius_3 operator_user" classNamePrefix="react-select" options={assignroleoption} />
          </div>
          <div className="tutorial-3d-direction-dropdown" style={{ position: "absolute", left: "15%", top: "30%" }}>
          </div>
          
        </div>
      </div> : (<></>)
      }
    </>
  );
};

export default MySpaceNew;
