import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { UncontrolledDropdown, DropdownToggle, DropdownItem } from "reactstrap";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import ProviderListActionModal from "./ProviderListActionModal";
import CaretDoubleDownIcon from "../../assets/icons/CaretDoubleDownIcon";
import DotsNineIcon from "../../assets/icons/DotsNineIcon";
const ContactTable = ({data, existingdTab=''}) => {
  const {  providerlistTableData } = useSelector((state) => state.customer);
  const [contacts, setContacts] = useState(
    data.data && data.data.length > 0 ? data.data : []
  );
  const [contactPage, setContactPage] = useState(1);
  const [loder, setLoder] = useState({
    contactLoader: false,
  });
  const scroll = (status) => {
    let page;
    let taskStaus = status;
    if (status == 1) {
      page = contactPage + 1;
      setContactPage(page);
    }
    taskStaus = [status];
    if (status == 1) {
      setLoder({ ...loder, ["contactLoader"]: false });
    }
  };
  useEffect(() => {
    setContacts(providerlistTableData.contact)
  }, [JSON.stringify(providerlistTableData)])
  return (
    <table className="dataTable text-center tablewithborder">
      <thead className="comman-white-box-shadow">
        <tr>
          <th style={{ width: "20%" }} className="text-start">
            {_l("l_contact_name")}
          </th>
          <th style={{ width: "2%" }} className="text-start">
            {_l("l_role")}
          </th>
          <th style={{ width: "5%" }} className="text-start">
            {_l("l_position")}
          </th>
          <th style={{ width: "2%" }} className="text-center">
            {_l("l_task_score")}
          </th>
          <th style={{ width: "2%" }} className="text-center">
            {_l("l_task_count")}
          </th>
          <th style={{ width: "3%" }} className="text-center">
            {_l("l_action")}
          </th>
        </tr>
      </thead>
      <tbody style={{ verticalAlign: "middle" }}>
        {contacts && contacts.length > 0 ? (
          <>
            {contacts.slice(
                  0,
                  contactPage > 1 ? (contactPage - 1) * 10 + 10 : contactPage * 10
                ).map((contact) => {
              return (
                <>
                  <tr>
                    <td>
                      <div className="name-with-progress max-w300">
                        <div className="d-flex align-items-center">
                          <a
                            href="#/"
                            className="h35w35 comman-round-box with-bg rounded-circle bg-white-05"
                          >
                            <div
                              className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                              style={{
                                backgroundImage: `url('${contact.profile_image}')`,
                              }}
                            ></div>
                          </a>
                          <div className="ps-2 w100minus70 text-start flex-grow-1">
                            <div className="d-flex flex-column">
                              <a
                                href="#/"
                                className="title-fonts text-truncate text-start max-width-200 fw-semibold"
                              >
                                {contact.fullname}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="title-fonts text-truncate text-start">
                      {_l(contact.role)}
                    </td>
                    <td className="title-fonts text-start text-truncate max-width75">
                      {contact.role_detail.role_name}
                    </td>
                    <td className="title-fonts text-center">
                      {contact.task_score}
                    </td>
                    <td className="title-fonts text-center">
                      {contact.total_task || 0}
                    </td>
                    <td> 
                      <div className="align-items-center comman_action_icon d-flex justify-content-center">
                      <UncontrolledDropdown setActiveFromChild={true}>
                           <DropdownToggle className="border-0 p-0">
                             <a
                               href="#/"
                               className="d-flex action_icon with_bg h32w32  position-relative on-hover-active-toggle-img "
                             >
                               <DotsNineIcon className="HW18" />
                             </a>
                           </DropdownToggle>
                           <DropdownItem toggle={false} className="p-0">
                              <ProviderListActionModal actionList={existingdTab == "Invited" ?["editContact"] : ''} data={contact}/>
                           </DropdownItem>
                         </UncontrolledDropdown>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
             <tr>
                {(contacts.length >= (contactPage - 1) * 10 + 10 &&
                  contacts.length != (contactPage - 1) * 10 + 10) ||
                loder.contactLoader ? (
                  <>
                    <td className="border-0" colSpan={12}>
                      <a
                        href="#/"
                        className="btn btn-white-03 w-100 view-more-btn"
                        onClick={() => {
                          setLoder({ ...loder, ["contactLoader"]: true });
                          scroll(1);
                        }}
                      >
                        <div className="d-flex align-item-center justify-content-center">
                          <span className=" title-fonts c-font f-12 ms-2">
                            {_l("l_view_more_btn")} (
                            {contactPage > 1
                              ? contacts.length - ((contactPage - 1) * 10 + 10)
                              : contacts.length - 10}
                            )
                          </span>
                          <CaretDoubleDownIcon
                            className="HW12 mx-2"
                          />
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            className={`ms-2 ${
                              loder.contactLoader ? "opacity-1" : "opacity-0"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                      </a>
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
          </>
        ) : (
          <>
            {" "}
            <tr className="">
              <td className="border-0" colSpan={7}>
                <div className="comman-placeholder-wrapper mt-4 w-100 justify-content-center">
                  <div className="d-flex flex-column align-items-center">
                    <CommanPlaceholder
                      imgType="request"
                      placeholderText={_l("l_no_data")}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};
export default ContactTable;
