import React from "react";
import { _l} from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import FileUploadComponent from "../TaskModalComponents/FileUploadComponent";

const SpacePois = ({ isExternal,data,type,handleAction}) => {
    const dispatch = useDispatch();

    const project_id = localStorage.getItem("selectedOffice");
   
    return (
        <>
            <div className="overflow-auto p-15 mt-auto px-0">
                <FileUploadComponent
                    projectId={project_id}
                    task_id={0}
                    attachmentCountUpdate={(task_id, flag) => {
                    }}
                    type={type}
                    data={data}
                    handleAction={handleAction}
                    toastClose={() => { }}
                    closeAllTost={false}
                    searchAttchment={false}
                    isExternal={isExternal}
                    readOnly={false}
                    taskDetails={""}
                />
            </div>
        </>
    )
}

export default SpacePois;