import React from "react";
import Modal from "react-bootstrap/Modal"
import { _l } from "../../hooks/utilities";

const PayoutsDetailsModal = ({ showSmallModal, handleClose, data, cardOnClickHandler }) => {
    
    return (
        <>
            <Modal
                show={showSmallModal}
                onHide={handleClose}
                className="custom-modal-style w1300px payout-modal"
                aria-labelledby="contained-modal-title-vcenter"
                size="md"
                centered
                backdrop={false}
                scrollable
            >
                <Modal.Header closeButton className="">
                    <Modal.Title>{`${_l('l_payout')} ${_l('l_details')}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className=" d-flex flex-column gap10">
                    {
                        data && data.payout && data.payout.source_payments && data.payout.source_payments.length && data.payout.source_payments.map((items, index) => (
                            <a href="#/" className="bg-white-03 p-10 radius_3 w-100" key={index} onClick={() => {cardOnClickHandler(items.id)}} >
                                <div className="fw-semibold mb-1">{items.client}</div>
                                <div className="d-flex justify-content-between align-items-cente">
                                    <div className="c-font color-white-60 f-10 lh-1 location fw-normal text-truncate w100minus100per">{items.invoice_details.title}</div>
                                    <div className="c-font f-10 lh-1 location fw-normal color-green">€{items.db_amount}</div>
                                </div>
                            </a>
                        ))
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PayoutsDetailsModal;
