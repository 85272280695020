import React from 'react'
import { _l } from '../../hooks/utilities'
const UnControlSubFiltersDropdown = ({ data, filterName, handleFilterLeads = () => {}, selectedleadfilers}) => {
    return (
            <ul className={`dropdown-menu w-140 overflowscroll ${filterName == "spaceListData" ? '' : 'mL-158'}`} aria-labelledby="TaskListStatusDropdown">
                {filterName != "additional_filters" ? (
                    <>
                        {filterName == "spaceListData" && data && data.length > 0 &&
                        <>
                        <li className="">
                            <a href="#/"
                                className={`custom-assignees custom-class-active dropdown-item with-tick ${selectedleadfilers.length == data.length ? 'active' : ''}`}
                            >
                                <div className="d-flex align-items-center text-truncate"
                                onClick={ () => {
                                    if (filterName == "spaceListData") {
                                        handleFilterLeads(data.map(id => id.project_id), "allSpacesAdd");
                                    }
                                }}
                                >
                                    <div className="d-flex align-items-center w100minus10 text_wrap">
                                        <div className="ps-2 text_wrap">
                                            <div className="c-list-detail c-font f-14 text-truncate ">
                                                {_l("l_select")+ ' '+ _l("l_space")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a> 
                        </li>
                        <li className='dropdown-divider my-0'></li>   
                        </>
                        }
                    {data && data.map((filter, i) => {
                        return (
                            <>
                            <li className="" key={i}>
                                <a href="#/"
                                    className={`custom-assignees custom-class-active dropdown-item with-tick 
                                        ${filterName == "spaceListData" 
                                            ? selectedleadfilers.includes(filter.project_id) && selectedleadfilers.length !== data.length ? 'active' : '' 
                                            : selectedleadfilers.includes(filter.id || filter.staffid) ? 'active' : '' 
                                        }`}
                                >
                                    <div
                                        className="d-flex align-items-center text-truncate"
                                    onClick={ () => {
                                        if (filterName == "spaceListData") {
                                            handleFilterLeads(filter.project_id);
                                        }else{
                                            handleFilterLeads(filter.id, filterName);
                                        }
                                    }}
                                    >
                                        <div className="d-flex align-items-center w100minus10 text_wrap">
                                            <div className="ps-2 text_wrap">
                                                <div className="c-list-detail c-font f-14 text-truncate ">
                                                    {filter.name || filter.fullname || filter.office_title}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>    
                            </li>
                            <li className='dropdown-divider my-0'></li>
                            </>
                        )
                    })}
                    </>
                )
                : data && Object.keys(data).length ? (
                    Object.keys(data).map((filter, i) => { 
                        return(
                        <>   
                        <li className="" key={i}>
                            <a href="#/"
                                className={`custom-assignees custom-class-active dropdown-item with-tick ${selectedleadfilers.includes(filter) ? 'active' : ''}`}
                            >
                                <div
                                    className="d-flex align-items-center text-truncate"
                                    onClick={ () => {
                                        handleFilterLeads(filter, filterName);
                                    }}
                                >
                                    <div className="d-flex align-items-center w100minus10 text_wrap">
                                        <div className="ps-2 text_wrap">
                                            <div className="c-list-detail c-font f-14 text-truncate ">
                                            { _l(data[filter])}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li className='dropdown-divider my-0'></li>
                        </>
                        )
                    })) 
                    : <></>}
            </ul>
    )
}
export default UnControlSubFiltersDropdown