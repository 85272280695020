import React, { useEffect, useState} from "react";
import Tasks from "../components/Tasks";
import { useNavigate } from "react-router-dom";
import jquery from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {   CardListScroll,  setPageMetaDataDescription, CardListFullHeight, DefaultLeftPanel, showLeftPanelNew, hideLeftPanelNew, _l, showContactDirectory } from "../hooks/utilities";
import { DEFAULT_METADATA_DESCRIPTION } from "../constants/constants";
import Customcalendar from "../components/Customcalendar";
import MySpaceNew from "./MySpaceNew";
import { setSelectedGloablView } from "../actions/customer";
import moment from "moment";
import AiChat from "./AiChat";
import ProviderTable from "./ProviderTable";
import NewThemeDashboard from "./NewThemeDashboard";
import AddTenants from "../components/ExploreFeatures/Tenants/AddTenants";
import SetupAccountAI from "./SetupAccountAI";
const Dashboard = () => {
  const navigate = useNavigate();
  const {globalView, commanoffcanvasIsOpen, isContactDirChat, leftPanelTab, isTenantsDataVaild, setupAccTab} = useSelector((state) => state.customer);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isStatusCleared] = useState(false);
  const [taskModalClose] = useState(false);
  const dispatch = useDispatch();
  const [IsKanbanView, setIsKanbanView] = useState(localStorage.getItem("fav_dashboard") == "kanban_view" ? true : false);
  const [selectedEventDate, setselectedEventDate] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const selectStartDate  = localStorage.getItem("fromDate");
  const contact_role = localStorage.getItem("contact_role");
  useEffect(()=>{
    localStorage.setItem("selectedDate", moment(new Date()).format("YYYY-MM-DD"));
  },[])
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      setIsLoggedin(true);
    }
    setPageMetaDataDescription(DEFAULT_METADATA_DESCRIPTION);
  }, []);
  useEffect(() => {
    if(globalView == " listView"){
      setIsKanbanView(false)
    }
    if(globalView === " spaceView"){
      CardListScroll();
    }
    if (globalView == "chat") {
      dispatch(setSelectedGloablView("liteVersionView"))
    }
    if(globalView ==="listView" || globalView ==="kanabanView"){
    }else if(localStorage.getItem("is_registered") == 1 && isContactDirChat){
      showLeftPanelNew();
    }else{
      if (localStorage.getItem("is_agent") != 1 && leftPanelTab != "my-tenants") {
      DefaultLeftPanel(true);
      }
    }
    CardListFullHeight();
    if(globalView == "liteVersionView"){
      localStorage.setItem("tasklistView", "liteVersionView")
    }
    return () => {
    }
  }, [globalView]);
  const savedProject = localStorage.getItem("selectedOffice");
  const [projectId] = useState(savedProject || "noData");
  useEffect(() => {
    if( window.location.pathname == "/"){
    if (leftPanelTab == "homepage" && !setupAccTab && !commanoffcanvasIsOpen) {
      showContactDirectory();
    }else if(leftPanelTab == "homepage" && setupAccTab){
      hideLeftPanelNew();
    }else if((projectId == 0 || projectId == null || projectId == undefined || projectId == "null") ) {
      jquery(document).mouseup(function (e) {
      var container = jquery(".fixed-left-panel");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        hideLeftPanelNew();
      }
    });
    } else if(commanoffcanvasIsOpen){
      hideLeftPanelNew();
    }else if(localStorage.getItem("is_registered") == 1 && isContactDirChat){
      showLeftPanelNew();
    }else if((commanoffcanvasIsOpen == false && (projectId == 0 || projectId == null || projectId == undefined)) || contact_role == 3){
      if (localStorage.getItem("is_agent") != 1) {
        hideLeftPanelNew();
      }
    }else{
      showLeftPanelNew();
    }}
  })
  return (
    <>
      {isLoggedin ? (
        <div className={`d-flex flex-column h-100 ${globalView == "AichatView" ? "flex-grow-1 overflow-auto ai-chat-header position-relative" :""}`}>
          <div className=""></div>
         {
          leftPanelTab == "homepage" && !setupAccTab ? 
          <React.Fragment>
            <NewThemeDashboard />
          </React.Fragment>
          :
          leftPanelTab == "homepage" && setupAccTab ?
          <SetupAccountAI />
          :
          leftPanelTab == "my-clients" ? 
          <>
          <ProviderTable />
          </>
          :
          isTenantsDataVaild && leftPanelTab === "my-tenants" ? 
          <>
          <AddTenants />
          </>
          :
            globalView == "calendarView" || leftPanelTab == "my-calendar" ?
              <Customcalendar
                pageName="calendar"
                setselectedEventDate={setselectedEventDate}
                selectStartDate={selectStartDate}
                setDashboardStartDate={setstartDate}
                setDashboardEndDate={setEndDate}
              />
            : 
            globalView == "listView" || globalView == "liteVersionView" || globalView == "kanbanView"
            ? 
              <Tasks
                projectId={projectId}
                isStatusCleared={isStatusCleared}
                taskModalClose={taskModalClose}
                pageName="Dashboard"
                kanbanView={IsKanbanView} 
              />
            : globalView == "spaceView" ?
              <MySpaceNew />
            : globalView == "AichatView"?
              <AiChat />
            :
              <></>
            }
        </div>
      ) : (
        <></>
      )}
    </>
  );
}; 
export default Dashboard;
