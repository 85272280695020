import React, { useEffect, useState } from "react";
import { validateEmail, _l } from "../../../hooks/utilities";
import Select from "react-select";
import {Button, Form, Spinner} from 'react-bootstrap';
import ScheduleFooter from "./ScheduleFooter";
import { useDispatch, useSelector } from "react-redux";
import { createScheduleDetails } from "../../../actions/benchmark-actions/benchmark-actions";
import { showMessage } from "../../../actions/messages";
import CustomerServices from "../../../services/customer-services";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";

const AddNewProvider = ({ handleStep, isCreateBenchmark, selectedTab, setSelectedTab, isEdit, inviteCustomerOnly, projectId }) => {

  const benchmarkReducer = useSelector((state) => state.benchmarkReducer);
  const { selectedProject } = useSelector((state) => state.customer);
  const userType = localStorage.getItem("user_type");
  const [providerCategory, setProviderCategory] = useState(0);
  const [formShow, setFormShow] = useState(inviteCustomerOnly);
  const [addInviteProvider, setAddInviteProvider] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [providerName, setName] = useState("");
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [showInviteProviderLoader, setShowInviteProviderLoader] = useState(false);

  const countryCodes = [
    {
      value: 1,
      label: "+32",
    },
    {
      value: 2,
      label: "+33",
    },
    {
      value: 3,
      label: "+49",
    },
    {
      value: 4,
      label: "+325",
    },
    {
      value: 5,
      label: "+34",
    },
    {
      value: 6,
      label: "+41",
    },
    {
      value: 7,
      label: "+44",
    },
  ];
  const [loder, setLoder] = useState(false);
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState("");
  const [alreadyExistOpeator, setAlreadyExistOpeator] = useState(false);
  const [countryCode, setCountryCode] = useState({
    value: "+33",
    label: "+33",
  });
  const [selectedBenchmarkOperator, setSelectedBenchmarkOperator] = useState([]);

  useEffect(() => {
    if (benchmarkReducer.createScheduleDetail && Object.keys(benchmarkReducer.createScheduleDetail).length) {
      setProviderCategory(benchmarkReducer.createScheduleDetail.providerCategory ? benchmarkReducer.createScheduleDetail.providerCategory : 0);
      let tempFormShow = benchmarkReducer.createScheduleDetail.step1_selectedTab == "new" && benchmarkReducer.createScheduleDetail.providerCategory > 0
      setFormShow(inviteCustomerOnly || tempFormShow)
      setSelectedTab(benchmarkReducer.createScheduleDetail.step1_selectedTab)
      let tempOperatorShow = benchmarkReducer.createScheduleDetail.step1_selectedTab == "existing" && benchmarkReducer.createScheduleDetail.providerCategory > 0
      if (isCreateBenchmark) {
        setSelectedBenchmarkOperator(benchmarkReducer.createScheduleDetail.selectedBenchmarkOperator ? benchmarkReducer.createScheduleDetail.selectedBenchmarkOperator : [])
      }

    }
  }, [JSON.stringify(benchmarkReducer.createScheduleDetail)])
  useEffect(() => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (addInviteProvider && pattern.test(addInviteProvider)) {
      emailCheckProvider();
    } else {
      setEmailAlreadyExist(false);
      setName("");
      setMobileNumber("");
      setCountryCode({ value: "+33", label: "+33" });
      setCompanyName("");
      setAlreadyExistOpeator(false);
    }
  }, [addInviteProvider]);

  const handleSave = (selectedTab = '') => {
    if (AddProvider()) {
      handleStep(selectedTab == 'next' ? 'provider-listing' : 'SelectCategory')
    }
    setLoder(false)
  }


  const customerDataValidation = () => {
    if(providerName == "" && mobileNumber == "" && addInviteProvider == "")
    {
      handleStep();
    }
    else if(!validateEmail(addInviteProvider))
    {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter_valid') +  _l('l_email')
        )
      );
      return false;
    }
    else if (
      providerName == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage("unsucess", _l('l_error'), _l('l_please_enter') +  _l('l_name'))
      );
      return false;
    } 
    else if (
      countryCode == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage("unsucess", _l('l_error'), _l('l_please_select_the_country_code'))
      );
      return false;
    } 
    else if (
      mobileNumber == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter') + _l('l_mobile_number')
        )
      );
      return false;
    }
    return true;
  }

  const addClient = async() => {
    try {
      setShowInviteProviderLoader(true);
      const response = await CustomerServices.addClientToSpace(companyName, providerName, addInviteProvider, mobileNumber, projectId);
      if(response.status)
      {
        if(inviteCustomerOnly)
        {
          return {clientId: response.data.client_id, contactId: response.data.contact_id};
        }
        else {
          let spaces = JSON.parse(localStorage.getItem('spaces'));
          let currentSpaceIndex = spaces.findIndex(w => w.project_id == selectedProject);
          if(currentSpaceIndex > -1)
          {
            spaces[currentSpaceIndex].client_id = response.data.client_id;
            spaces[currentSpaceIndex].contact_id = response.data.contact_id;
            localStorage.setItem('spaces', JSON.stringify(spaces));
          }        
          handleStep();
        }
      }
    }
    catch (e) {
      console.error(e);
    }
    finally {
      setShowInviteProviderLoader(false);
    }
  }

  const AddProvider = () => {
    const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    let User = userType == "contact" ? "provider" : "client";
    if(User == "client" && customerDataValidation())
    {
      addClient();
      return;
    }
    if (!pattern.test(addInviteProvider)) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter_valid') + ' ' + User + ' ' + _l('l_email')
        )
      );
      return false;
    } else if (
      providerName == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage("unsucess", _l('l_error'), _l('l_please_enter') + ' ' + User + ' ' + _l('l_name'))
      );
      return false;
    } else if (
      countryCode == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage("unsucess", _l('l_error'), _l('l_please_select_the_country_code'))
      );
      return false;
    } else if (
      companyName == "" &&
      emailAlreadyExist &&
      userType == "operator" &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter_the') + ' ' + User + ' ' + _l('l_company_name')
        )
      );
      return false;
    } else if (
      mobileNumber == "" &&
      emailAlreadyExist &&
      alreadyExistOpeator == false
    ) {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_please_enter') + ' ' + User + ' ' + _l('l_mobile_number')
        )
      );
      return false;
    }
    else if(benchmarkReducer.createScheduleDetail.operators && benchmarkReducer.createScheduleDetail.operators.find((x) => x.email && x.email.trim().toLowerCase() == addInviteProvider.trim().toLowerCase()))
    {
      dispatch(
        showMessage(
          "unsucess",
          _l('l_error'),
          _l('l_provider_already_added')
        )
      );
      return false;
    }
     else {
      if (emailAlreadyExist) {
        addProviderAPI();
        return true;
      }
    }

  };
  const addProviderAPI = () => {
    let mobileTemp = countryCode.value + " " + mobileNumber.trim();
    setShowInviteProviderLoader(true);
    CustomerServices.addProviderToCategory(
      addInviteProvider,
      providerCategory,
      selectedProject,
      providerName,
      mobileTemp
    ).then((res) => {

      if (res.status == "1" && res.data) {

        if (isCreateBenchmark) {
          let tempOperator = benchmarkReducer.createScheduleDetail.operators ? [...benchmarkReducer.createScheduleDetail.operators, {
            cp_id: res.data.provider_id,
            email: addInviteProvider,
            full_name: providerName,
            alreadyExist: res.data.length == 0 ? false : true,
            profile_url: localStorage.getItem("contact_image"),
            mobile: mobileTemp,
            staffid: res.data.provider_id,
          }] : [{
            cp_id: res.data.provider_id,
            email: addInviteProvider,
            full_name: providerName,
            alreadyExist: res.data.length == 0 ? false : true,
            profile_url: localStorage.getItem("contact_image"),
            mobile: mobileTemp,
            staffid: res.data.provider_id,
          }]

          let tempSelectedOperator = benchmarkReducer.createScheduleDetail && benchmarkReducer.createScheduleDetail.selectedBenchmarkOperator ? benchmarkReducer.createScheduleDetail.selectedBenchmarkOperator : []
          if (userType == 'contact') {
            tempSelectedOperator = [...tempSelectedOperator, addInviteProvider]

            setSelectedBenchmarkOperator([...selectedBenchmarkOperator, addInviteProvider])
          }

          dispatch(createScheduleDetails({ ...benchmarkReducer.createScheduleDetail, [`operators`]: tempOperator, ['step1_selectedTab']: 'existing', ['selectedBenchmarkOperator']: tempSelectedOperator, ['step1_selectedTab_disabled']: false }))
        } else {
          let tempSelectedSchedule = {
            cp_id: res.data.provider_id,
            email: addInviteProvider,
            profile_url: localStorage.getItem("contact_image"),
            mobile: mobileNumber,
          }

          dispatch(createScheduleDetails({
            ...benchmarkReducer.createScheduleDetail, [`operators`]: tempSelectedSchedule, ['selectedSchedule']: tempSelectedSchedule
          }))
        }
      } else {
        dispatch(
          showMessage(
            "unsucess",
            _l('l_error'),
            res.message ? res.message : _l('l_something_went_wrong')
          )
        );
      }
    }).finally(() => {
      setShowInviteProviderLoader(false);
    });
  };

  const inviteCustomer = async() => {
    const data = await addClient();
    handleStep(data);
  };

  const emailCheckProvider = () => {
    CustomerServices.emailCheckInvitees(
      addInviteProvider,
      providerCategory,
      selectedProject,
      inviteCustomerOnly
    ).then((res) => {
      if (res.status == "1") {
        if (!!res.data && res.data.constructor === Object) {
          setName(res.data.fullname ? res.data.fullname : "");
          if (res.data.phonenumber) {
            let extractedCountryCode = countryCodes.filter(
              (x) => res.data.phonenumber.split(" ")[0] == x.value
            );

            if (extractedCountryCode.length) {
              setCountryCode(extractedCountryCode[0]);
            } else {
              setCountryCode({ value: "+33", label: "+33" });
            }
            const phoneNumber = res.data.phonenumber.split(" ")[1]
              ? res.data.phonenumber.split(" ")[1]
              : res.data.phonenumber;
            setMobileNumber(phoneNumber.trim());
          }
          if(res.data.company)
          {
            setCompanyName(res.data.company);
          }
          setAlreadyExistOpeator(true);
        }
        setEmailAlreadyExist(true);
      } else {
        if (alreadyExistOpeator) {
          setName("");
          setCountryCode({ value: "+33", label: "+33" });
          setMobileNumber("");
          setCompanyName("");
        }
        setAlreadyExistOpeator(false);
        setEmailAlreadyExist(true);
      }
    });
  };
  return (
    <>
      <div className="row ">
        <div className="px-2 mb-3">
          <div className="border-top"></div>
        </div>

        {
          formShow ? <>
            <Form.Group className="col-12 c-input-box pb-4 position-relative ">
              <Form.Label className="input-label no-asterisk">{userType == 'contact' ? _l('l_provider_email') : _l('l_customer_email')} </Form.Label>
              <div className="checklist-inputbox p-0">
                <div className="comment-input-box">
                  <Form.Control
                    placeholder={`${_l('l_email_placeholder')}`}
                    type="text"
                    value={addInviteProvider}
                    onChange={(e) => {
                      setAddInviteProvider(e.target.value);
                    }}
                  />
                  <a href="#/" className={`d-flex send-arrow-btn ${userType == "contact" && isCreateBenchmark ? "" : "d-none"
                    }`}
                    onClick={() => {
                      if (isCreateBenchmark && !showInviteProviderLoader) {
                        AddProvider();
                      }
                    }}
                  >
                    {
                      showInviteProviderLoader
                      ?
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          className={`m-auto`}
                          aria-hidden="true"
                        />
                      :
                        <ArrowRightIcon className="HW18 m-auto" /> 
                    }
                  </a>
                </div>
              </div>
            </Form.Group>
            {
              userType == 'contact'
              ?
              <React.Fragment></React.Fragment>
              :
              <Form.Group className="col-12 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label no-asterisk">{_l('l_company_name')} </Form.Label>
                <div className="checklist-inputbox p-0">
                  <div className="comment-input-box">
                    <Form.Control
                      placeholder={`${_l('l_company_name_placeholder')}`}
                      type="text"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                    />
                  </div>
                </div>
            </Form.Group>
            }
            <Form.Group className="col-xl-6 c-input-box pb-3 position-relative ">
              <Form.Label className="input-label no-asterisk">{userType == 'contact' ? _l('l_provdier_name') : _l('l_customer_name')}</Form.Label>
              <Form.Control
                placeholder={`${userType == 'contact' ? _l('l_provdier_name') : _l('l_customer_name')}`}
                type="text"
                value={providerName}
                onChange={(e) => {
                  const reg = new RegExp("^[A-Za-z0-9 ]+$");
                  const inputValue = e.target.value;
                  if (reg.test(inputValue) || inputValue == "")
                    setName(e.target.value);
                }}
                disabled={alreadyExistOpeator}
              />

            </Form.Group>

            <Form.Group className="col-xl-6 c-input-box position-relative">
              <Form.Label className="input-label no-asterisk no-asterisk">{_l('l_phone_number')}</Form.Label>
              <div className="row">
                <div className="c_input_border drop_image col-4  position-relative">
                  <Select
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    placeholder={`${_l('l_country_code_placeholder')}`}
                    options={countryCodes}
                    closeMenuOnSelect={true}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    value={countryCode}
                    onChange={(e) => {
                      setCountryCode({ value: e.value, label: e.label });
                    }}
                    isDisabled={alreadyExistOpeator}
                  />
                </div>
                <div className="col-8  ps-md-0">
                  <Form.Control
                    placeholder={`${_l('l_phone_number_placeholder')}`}
                    type="Number"
                    name="phonenumber"
                    value={mobileNumber}
                    onChange={(e) => {
                      const reg = new RegExp("^[0-9+ ]+$");
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/[ \-.]/g, "").substring(0, 10);
                      if (reg.test(inputValue) || inputValue == "")
                        setMobileNumber(inputValue);
                    }}
                    disabled={alreadyExistOpeator}
                  />
                </div>
              </div>
            </Form.Group>
          </> : <></>
        }


      </div>
      {
        !inviteCustomerOnly
        ?
        <div className={`d-flex mt-auto w-100 bg-base-header-color ${selectedTab == `SelectCategory` ? "position-sticky-bottom" : ""}`}>
          <ScheduleFooter selectedTab={`SelectCategory`} handleStep={handleSave} currentStep={1} isCreateBenchmark={isCreateBenchmark} providerCategory={providerCategory} loder={loder} setLoder={setLoder} isEdit={isEdit} />
        </div>
        :
        <div className="d-flex align-items-center justify-content-end mt-2">
          <Button className="me-2" size="sm" variant="primary" onClick={inviteCustomer}>{_l("l_invite_customer")}</Button>
          <Button size="sm" variant="secondary" onClick={handleStep}>{_l("l_cancel")}</Button>
        </div>
      }
    </>
  );
};

export default AddNewProvider;