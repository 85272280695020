import io from "socket.io-client";
import chatAuthHeader from "../services/chat-auth-header";
import peer from "./peer";
import { showMessage } from "./messages";
import { TaskModalClose, _l, getChatMessagesFromIDB, showLeftPanelNew, updateMessagesInIDB, isMicFound, isCamFound, isMicAllowed, isCamAllowed } from "../hooks/utilities";
import jquery from "jquery";

import {
  FETCH_GROUPS,
  FETCH_GROUPS_EXTERNAL,
  FETCH_MESSAGES,
  FETCH_UNREAD_MSG_COUNT,
  NEW_MESSAGE,
  UPLOAD_CHAT_MEDIA,
  UPDATE_UPLOADED_FILENAME,
  USER_ONLINE,
  USER_OFFLINE,
  UPDATE_USER_ONLINE,
  UPDATE_USER_OFFLINE,
  UPDATE_NEW_MESSAGE,
  UPDATE_UNREAD_MSG_COUNT,
  USER_READ_MSG,
  UPDATE_USER_READ_MSG,
  FETCH_VIEW_GROUPS,
  NEW_TASK_NOTI,
  ALL_PROJ_UNREAD_MSG_CNT,
  NEW_MSG_FOR_AM,
  UPDATE_NEW_MSG_FOR_AM,
  NEW_GROUP,
  EDIT_GROUP,
  EDIT_MESSAGE,
  DELETE_MESSAGE,
  UPDATE_GENERAL_MSG_COUNT,
  OPEN_GENERAL_CHAT_GROUP,
  SET_CALL_STATUS,
  MUTE_CALL,
  UNMUTE_CALL,
  ACCEPT_CALL,
  CALL_NOT_RESPOND,
  SET_MY_STREAM,
  SET_REMOTE_STREAM,
  SET_UNREAD_MSG_COUNT,
  GET_GROUP_DETAILS,
  CALL_ACTION,
  CLEAR_MESSAGE_LIST,
  CALL_TASK_LIST,
  STOP_CHAT_SCROLL,
  SET_TYPING_MEMBERS,
  BURGER_MENU_TOPIC_TYPING,
  DISABLE_INPUT_CHAT,
  BURGER_MENU_TOPIC_ANALYSIS,
  CALL_IS_RECIVE,
  CONTACT_DIR_COUNT,
  CALL_TIMER,
  RECORDING_CALL_TIMER,
  IS_CHAT_SOCKET
} from "./chat-action-type";
import ChatServices from "../services/chat-services";
import { CALL_MESSAGE_PAGINATIONS, FIRST_MESSAGE_DATE, FIRST_MESSAGE_ID, MISSED_CALL, UPDATE_IDB_ACTION } from "./action-type";
import moment from "moment";
import { commanoffcanvasisOpen, createTaskForAudioCall, setCallFullscreen, setCallRecordingAction } from "./customer";

var chatURL = process.env.REACT_APP_CHAT_BASE_URL;

export const fetchGroups = (userId, projectId, noData, isTopic) => (dispatch) => {
  if(noData == 1){
    dispatch({
      type: FETCH_GROUPS,
      payload: { data: [] },
    });
  }else{
    return ChatServices.fetchGroups(userId, projectId, isTopic).then((data) => {
      dispatch({
        type: FETCH_GROUPS,
        payload: { data },
      });
    });
  }
};


export const fetchGroupsExternal = (userId, projectId, isTopic) => (dispatch) => {
    return ChatServices.fetchGroups(userId, projectId, isTopic).then((data) => {
      dispatch({
        type: FETCH_GROUPS_EXTERNAL,
        payload: { data },
      });
      dispatch({
        type: CONTACT_DIR_COUNT,
        payload: "",
      })
    });
};

export const fetchMessages = (userId, groupId, otherUserId,setLoader, firstMsgDate,id,startDate,endDate, unread_count) => (dispatch) => {
  setLoader(true);

  const dispatchEvents = (data) => {
    setLoader(false);
    dispatch({
      type: FETCH_MESSAGES,
      payload: { data },
    });
    dispatch({
      type: FIRST_MESSAGE_DATE,
      payload:
        data &&
        data.extra &&
        moment(data.extra.firstMsgDate).format("YYYY-MM-DD"),
    });
    dispatch({
      type: CALL_MESSAGE_PAGINATIONS,
      payload: data && data.result && data.result.length >= 20 ? true : false,
    });
  };
  if(!id)
  {
    getChatMessagesFromIDB(groupId, startDate, endDate, id, unread_count).then((data) => {
      if(data.result && data.result.length)
      {
        dispatchEvents(data);
      }
    });
  }
  return ChatServices.fetchMessages(userId, groupId, otherUserId, firstMsgDate,id, startDate,endDate, unread_count).then(
    (data) => {
      updateMessagesInIDB(groupId, startDate, endDate, id, unread_count, data.result);
      dispatchEvents(data);
    }
  );
};

var socket = "";
export const connectToSocket = () => (dispatch) => {
  var options = {
    rememberUpgrade: true,
    transports: ["websocket"],
    secure: true,
    rejectUnauthorized: false,
    query: {
      token: chatAuthHeader(),
    },
  };

  socket = io.connect(chatURL, options);

  socket.on("connect", function () {
    dispatch({
      type: IS_CHAT_SOCKET, 
      payload: socket,
    });
    var chatUserId = localStorage.getItem("chatUserId");
    // Set user online
    var online_data = {
      userId: chatUserId,
    };
    socket.emit("userOnline", online_data, function (res) {
    });
  });

  socket.on('disconnect', (reason) => {
    dispatch({
      type: IS_CHAT_SOCKET, 
      payload: {},
    });
  });

  socket.on("newMessage", function (data) {
    dispatch({
      type: STOP_CHAT_SCROLL,
      payload: true,
    });
    dispatch({
      type: NEW_MESSAGE,
      payload: { data },
    });
  });

  socket.on("userOnline", function (data) {
    dispatch({
      type: USER_ONLINE,
      payload: { data },
    });
  });

  socket.on("userOffline", function (data) {
    dispatch({
      type: USER_OFFLINE,
      payload: { data },
    });
  });

  socket.on("readAllGrpMsgBy", function (data) {
    dispatch({
      type: USER_READ_MSG,
      payload: { data },
    });
  });

  socket.on("newTaskNoti", function (data) {
    dispatch({
      type: NEW_TASK_NOTI,
      payload: data,
    });
  });

  socket.on("newMsgForAM", function (data) {
    dispatch({
      type: NEW_MSG_FOR_AM,
      payload: { data },
    });
  });

  socket.on("newGroup", function (data) {
    dispatch({
      type: NEW_GROUP,
      payload: { data },
    });
  });

  socket.on("editGroup", function (data) {
    dispatch({
      type: EDIT_GROUP,
      payload: { data },
    });
  });

  socket.on("editMessage", function (data) {
    dispatch({
      type: EDIT_MESSAGE,
      payload: { data },
    });
  });

  socket.on("deleteMessage", function (data) {
    dispatch({
      type: DELETE_MESSAGE,
      payload: { data },
    });
  });

  socket.on("initCall", function (data) {
    peer.openConn();
    dispatch(playCallAudio());
    data.status = "initCall";
    data.callRole = "receiver";
    dispatch({
      type: SET_CALL_STATUS,
      payload: data,
    });
  });

  socket.on("endCall", function (data) {
    peer.closeConn();
    dispatch(stopCapturingMedia());
    dispatch(setMyStream([]));
    dispatch(setRemoteStream([]));
    dispatch(playCallAudio("stop"));
    dispatch(playCallEndAudio());
    TaskModalClose();
    showLeftPanelNew();
    dispatch(setCallFullscreen(false));
    dispatch(setCallisRecived(false))
    jquery("body").removeClass("task-modal-offcanvas-open with-right-penel")
    dispatch(commanoffcanvasisOpen(false));
    dispatch(setCallRecordingAction(""));
    dispatch({
      type: SET_CALL_STATUS,
      payload: {},
    });
    dispatch(showMessage("unsucess", _l("l_call_status"),  _l("l_call_ended")));
  });

  socket.on("muteCall", function (data) {
    dispatch({
      type: MUTE_CALL,
      payload: data,
    });
  });

  socket.on("unMuteCall", function (data) {
    dispatch({
      type: UNMUTE_CALL,
      payload: data,
    });
  });

  socket.on("acceptCall", async function (data) {
    dispatch(setCallFullscreen(true));
    dispatch(playCallAudio("stop"));
    dispatch(playCallAcceptAudio());
    let callerinfo = JSON.parse(localStorage.getItem("callerInfo"))? JSON.parse(localStorage.getItem("callerInfo")) : {}
    let selectedTopicForCall = JSON.parse(localStorage.getItem("selectedTopicForCall")) ? JSON.parse(localStorage.getItem("selectedTopicForCall")) : JSON.parse(localStorage.getItem("selectedTopic"))
    dispatch(createTaskForAudioCall(
      localStorage.getItem("selectedOffice"),
      0,
      1,
      [],
      "",
      `Call between ${localStorage.getItem("full_name")} and ${callerinfo.title}`,
      0,
      0,
      "",
      2,
      false,
      [],
      [],
      [],
      [],
      moment(new Date).format("YYYY-MM-DD HH:mm:ss"),
      selectedTopicForCall && selectedTopicForCall.main_key == "providers" || selectedTopicForCall.main_key =="equipments"  ?
      [{
        main_key : localStorage.getItem("user_type") == "contact" ? "my_contact" :  localStorage.getItem("user_type") == "staff" ? "my_staff" : "my_operator",
        categories : [{
          filter_key : localStorage.getItem("staff_id"),
          main_sub_key : "people",
          sub_Category : []
        }]
      },{
        main_key:selectedTopicForCall.main_key,
        categories:[
          {
            filter_key:selectedTopicForCall.filter_key,
            main_sub_key:callerinfo.main_sub_key,
            sub_category:[callerinfo.filter_key]
        }]
      }
      ]:
      [{
        main_key : localStorage.getItem("user_type") == "contact" ? "my_contact" :  localStorage.getItem("user_type") == "staff" ? "my_staff" : "my_operator",
        categories : [{
          filter_key : localStorage.getItem("staff_id"),
          main_sub_key : "people",
          sub_Category : []
        }]
      },
      {
        main_key : callerinfo.main_key ,
        categories : [{
          filter_key : callerinfo.filter_key,
          main_sub_key : callerinfo.main_sub_key,
          sub_Category : []
        }]
      }
    ],
      0,
      0,
      0,
      []
    ))
    dispatch({
      type: ACCEPT_CALL,
      payload: data,
    });
    dispatch(setCallisRecived(true))
    await peer.setLocalDescription(data.ans);
  });

  socket.on("negoNeeded", function (data) {
    if(data && data.callRole != "receiver"){
      dispatch(negoDone(data));
    }
  });

  socket.on("negoDone", async function (data) {
    await peer.setLocalDescription(data.ans);
    dispatch(negoNeeded2(data));
  });

  socket.on("negoNeeded2", function (data) {
    dispatch(negoDone2(data));
  });

  socket.on("negoDone2", async function (data) {
    await peer.setLocalDescription(data.ans);
  });

  socket.on("sendCandidates", async function (data) {
    if(data && data.candidates && data.candidates.length > 0){
      data.candidates.map((item) => {
        const candidateObj = new RTCIceCandidate(item);
        peer.addIceCandidate(candidateObj).catch({
          /* handle the error thrown by addIceCandidate() */
        });
      })
    }
  });

  socket.on("callAction", function (data) {
    dispatch({
      type: CALL_ACTION,
      payload: data,
    });
    if(data.actionKey == "record" && data.actionVal == "start"){
      dispatch(playCallRecordingAudio());
    }
  });

  socket.on("missedCall", async function (data) {
    dispatch({
      type: MISSED_CALL,
      payload: data,
    });
  });

  socket.on("newTask", async function (data) {
    dispatch({
      type : CALL_TASK_LIST,
      payload : data
    })
  });

  socket.on("disableInput", async function (data) {
    if (data.groupId == localStorage.getItem("activeGroupId")) {
      dispatch({
        type : DISABLE_INPUT_CHAT,
        payload : data.disableInput
      })
    }
    dispatch({
      type : BURGER_MENU_TOPIC_ANALYSIS,
      payload : data
    })
  });

  socket.on("startTyping", async function (data) {
    if(data && data.groupId == localStorage.getItem("activeGroupId") && data.userId != localStorage.getItem("chatUserId")){
      data.action = "add";
      dispatch({
        type : SET_TYPING_MEMBERS,
        payload : data
      })
      let typingTimeout;
      typingTimeout = setTimeout(() => {
        data.action = "remove";
        dispatch({
          type : SET_TYPING_MEMBERS,
          payload : data
        })
      }, 3000);
    }
    if(data && data.userId != localStorage.getItem("chatUserId")){
      data.action = "add";
      dispatch({
        type : BURGER_MENU_TOPIC_TYPING,
        payload : data
      })
      let setMessagetypingTimeout;
      setMessagetypingTimeout = setTimeout(() => {
        data.action = "remove";
        dispatch({
          type : BURGER_MENU_TOPIC_TYPING,
          payload : data
        })
      }, 3000);
    }
  });
  
  socket.on("updateIDB", async function (data) {
    console.log("Scocket Recived........data in updateIDB", data)
    dispatch({
      type : UPDATE_IDB_ACTION,
      payload : data
    })
  });


};

export const sendMessage = (data) => (dispatch) => {
  socket.emit("sendMessage", data, function (res) {
  });
};

export const uploadChatMedia = (file) => (dispatch) => {
  return ChatServices.uploadChatMedia(file).then((data) => {
    dispatch({
      type: UPLOAD_CHAT_MEDIA,
      payload: { data: data },
    });
  });
};

export const updateUploadedFileName = () => (dispatch) => {
  dispatch({
    type: UPDATE_UPLOADED_FILENAME,
    payload: {},
  });
};

export const updateUserOnline = () => (dispatch) => {
  dispatch({
    type: UPDATE_USER_ONLINE,
    payload: {},
  });
};

export const updateUserOffline = () => (dispatch) => {
  dispatch({
    type: UPDATE_USER_OFFLINE,
    payload: {},
  });
};

export const groupOut = (data) => (dispatch) => {
  if(socket){
    socket.emit("groupOut", data, function (res) {
    });
  }
};

export const groupIn = (data) => (dispatch) => {
  if(socket){
    socket.emit("groupIn", data, function (res) {
    });
  }
};

export const updateNewMessage = () => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_MESSAGE,
    payload: {},
  });
};

export const fetchUnreadMsgCount = (userId, projectId) => (dispatch) => {
  return ChatServices.fetchUnreadMsgCount(userId, projectId).then((data) => {
    dispatch({
      type: FETCH_UNREAD_MSG_COUNT,
      payload: { data },
    });
  });
};

export const setUnreadMsgCount = (data) => (dispatch) => {
  return dispatch({
    type: SET_UNREAD_MSG_COUNT,
    payload: data,
  });
};

export const updateUnreadMsgCount = (count) => (dispatch) => {
  dispatch({
    type: UPDATE_UNREAD_MSG_COUNT,
    payload: count,
  });
};

export const updateReadAllGrpMsgBy = () => (dispatch) => {
  dispatch({
    type: UPDATE_USER_READ_MSG,
    payload: {},
  });
};

export const fetchViewGroups = (userId, projectId) => (dispatch) => {
  return ChatServices.fetchViewGroups(userId, projectId).then((data) => {
    dispatch({
      type: FETCH_VIEW_GROUPS,
      payload: { data },
    });
  });
};

export const userOffline = (data) => (dispatch) => {
  try {
    socket.emit("userOffline", data, function (res) {
    });
    socket.disconnect();
  } catch (error) {
   console.log(error); 
  }
};

export const updateNewTaskNoti = () => (dispatch) => {
  dispatch({
    type: NEW_TASK_NOTI,
    payload: {},
  });
};

export const fetchAllProjUnreadMsgCnt = () => (dispatch) => {
  var userId = localStorage.getItem('chatUserId');
  if(userId && userId !="" && userId != null){
    return ChatServices.fetchAllProjUnreadMsgCnt(userId).then((data) => {
      dispatch({
        type: ALL_PROJ_UNREAD_MSG_CNT,
        payload: { data },
      });
    });
  }
};

export const fetchAllProjAMUnreadMsgCnt = () => (dispatch) => {
  var userId = localStorage.getItem('chatUserId');
  if(userId && userId !="" && userId != null){
    return ChatServices.fetchAllProjAMUnreadMsgCnt(userId).then((data) => {
      if (data.success) { 
        dispatch({
          type: ALL_PROJ_UNREAD_MSG_CNT,
          payload: { data },
        });
      }
    });
  }
};

export const updateNewMsgForAM = () => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_MSG_FOR_AM,
    payload: {},
  });
};

export const playMsgAudio = () => (dispatch) => {
  var x = document.getElementById("my-msg-audio");
  if (x) {
    x.play();
    x.volume = 1.0;
  }
};

export const playCallAudio = (action = "play") => (dispatch) => {
  var x = document.getElementById("my-call-audio");
  if (x && x != null) {
    if(action == "play"){
      x.play();
      x.volume = 1.0;
    } else {
      x.pause();
      x.currentTime = 0;
    }
  }
 
};

export const playCallEndAudio = () => (dispatch) => {
  var x = document.getElementById("my-call-end-audio");
  if (x) {
    x.play();
    x.volume = 1.0;
  }
};

export const playCallAcceptAudio = () => (dispatch) => {
  var x = document.getElementById("my-call-accept-audio");
  x.play();
  x.volume = 1.0;
};

export const updateGeneralMsgCount = (count) => (dispatch) => {
  dispatch({
    type: UPDATE_GENERAL_MSG_COUNT,
    payload: count,
  });
};

export const setOpenGeneralChatGroup = (isOpen) => (dispatch) => {
  dispatch({
    type: OPEN_GENERAL_CHAT_GROUP,
    payload: isOpen,
  });
};

export const initCall = (data) => async (dispatch) => {
  if(socket){
    const micFound = await isMicFound();
    const camFound = await isCamFound();
    if(!micFound){
      dispatch(showMessage("unsucess", _l("l_error"), "Can not found mic in your system"));
    }else{
      var myStream;
      peer.openConn();
      if(!camFound){
        myStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
      }else{
        myStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        }).catch(function(err) {
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            dispatch(showMessage("unsucess", _l("l_error"), "Permission to access microphone and camera was denied by the user"));
          } else {
            dispatch(showMessage("unsucess", _l("l_error"), "Error accessing microphone and camera"));
          }
        });
      }
      const micAllowed = await isMicAllowed();
      const camAllowed = await isCamAllowed();
      if(!micAllowed){
        dispatch(showMessage("unsucess", _l("l_error"), "Please allow mic permission"));
      }else if(camFound && !camAllowed){
        dispatch(showMessage("unsucess", _l("l_error"), "Please allow camera permission"));
      }else{
        window.localStream=myStream;
        dispatch(setMyStream(myStream));
        const offer = await peer.getOffer();
        data.offer = offer;
        dispatch(playCallAudio());
        var projectName = "";
        var projectAddress = "";
        var isVirtualSpace = 0;
        var spaces = JSON.parse(localStorage.getItem("spaces"));
        var selectedVirtualSpace = JSON.parse(localStorage.getItem("selectedVirtualSpace"));
        var selectedTopic = JSON.parse(localStorage.getItem("selectedTopic"));
        var projectId = localStorage.getItem("isLiteVersion") == "true" || data.projectId == 0 ? 0 : localStorage.getItem("selectedOffice");
        if (selectedVirtualSpace && Object.keys(selectedVirtualSpace).length > 0) {
          var isVirtualSpace = 1;
          projectName = selectedVirtualSpace.name;
        }else if(spaces && spaces.length > 0){
          var selectedSpace = spaces.filter((item) => item.project_id == projectId);
          if(selectedSpace.length > 0){
            projectName = selectedSpace[0].office_title;
            projectAddress = selectedSpace[0].postal_address;
          }
        }
        data.extraData.projectId = projectId;
        data.extraData.projectName = projectName;
        data.extraData.projectAddress = projectAddress;
        data.extraData.isVirtualSpace = isVirtualSpace;
        data.extraData.topicName = selectedTopic ? selectedTopic.title : "";
        data.extraData.isCallerCamFound = camFound;
        socket.emit("initCall", data, function (res) {
          if(res.success){
            var randomCallId = (Math.random() + 1).toString(36).substring(8);
            res.result.status = "initCall";
            res.result.callRole = "caller";
            res.result.randomCallId = randomCallId;
            res.result.offer = offer;
            dispatch({
              type: SET_CALL_STATUS,
              payload: res.result,
            });
            setTimeout(() => {
              dispatch({
                type: CALL_NOT_RESPOND,
                payload: {randomCallId: randomCallId},
              });
            }, 28000);
          }else{
            dispatch(endCall(data));
            dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
        });
      }
    }
  }else{
    dispatch(showMessage("unsucess", _l("l_error"), _l("l_socket_not_defined")));
  }
};

export const endCall = (data) => (dispatch) => {
  peer.closeConn();
  dispatch(stopCapturingMedia());
  dispatch(setMyStream([]));
  dispatch(setRemoteStream([]));
  dispatch(playCallAudio("stop"));
  dispatch(playCallEndAudio());
  TaskModalClose();
  showLeftPanelNew();
  dispatch(setCallFullscreen(false));
  dispatch(setCallisRecived(false))
  jquery("body").removeClass("task-modal-offcanvas-open with-right-penel");
  dispatch(commanoffcanvasisOpen(false));
  dispatch(setCallRecordingAction(""));
  if(socket){
    socket.emit("endCall", data, function (res) {
      dispatch({
        type: SET_CALL_STATUS,
        payload: {},
      });
    });
  }
};

export const muteCall = (data) => (dispatch) => {
  if(socket){
    socket.emit("muteCall", data, function (res) {
      dispatch({
        type: MUTE_CALL,
        payload: res.result,
      });
    });
  }
};

export const unMuteCall = (data) => (dispatch) => {
  if(socket){
    socket.emit("unMuteCall", data, function (res) {
      dispatch({
        type: UNMUTE_CALL,
        payload: res.result,
      });
    });
  }
};

export const acceptCall = (data) => async (dispatch) => {
  const micFound = await isMicFound();
  const camFound = await isCamFound();
  if(!micFound){
    dispatch(showMessage("unsucess", _l("l_error"), "Can not found mic in your system"));
  }else{
    var myStreams;
    if(!camFound){
      myStreams = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
    }else{
      myStreams = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      }).catch(function(err) {
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          dispatch(showMessage("unsucess", _l("l_error"), "Permission to access microphone and camera was denied by the user"));
        } else {
          dispatch(showMessage("unsucess", _l("l_error"), "Error accessing microphone and camera"));
        }
      });
    }
    const micAllowed = await isMicAllowed();
    const camAllowed = await isCamAllowed();
    if(!micAllowed){
      dispatch(showMessage("unsucess", _l("l_error"), "Please allow mic permission"));
    }else if(camFound && !camAllowed){
      dispatch(showMessage("unsucess", _l("l_error"), "Please allow camera permission"));
    }else{
      window.localStream=myStreams;
      dispatch(setMyStream(myStreams));
      const ans = await peer.getAnswer(data.offer);
      dispatch(setCallFullscreen(true));
      dispatch(playCallAudio("stop"));
      dispatch(playCallAcceptAudio());
      data.ans = ans;
      data.isReceiverCamFound = camFound;
      if(socket){
        socket.emit("acceptCall", data, function (res) {
        dispatch(setCallisRecived(true))
          dispatch({
            type: ACCEPT_CALL,
            payload: {userId: data.userId, ans: ans},
          });
        });
      }
    }
  }
};

export const callNotRespond = (data) => (dispatch) => {
  peer.closeConn();
  dispatch(stopCapturingMedia());
  dispatch(setMyStream([]));
  dispatch(setRemoteStream([]));
  dispatch(playCallAudio("stop"));
  dispatch(playCallEndAudio());
  if(socket){
    socket.emit("autoEndCall", data, function (res) {
      dispatch({
        type: SET_CALL_STATUS,
        payload: {},
      });
    });
  }
};

export const setMyStream = (myStream) => (dispatch) => {
  dispatch({
    type: SET_MY_STREAM,
    payload: myStream,
  });
};

export const setRemoteStream = (remoteStream) => (dispatch) => {
  dispatch({
    type: SET_REMOTE_STREAM,
    payload: remoteStream,
  });
};

export const negoNeeded = (data) => async (dispatch) => {
  if(socket){
    const offer = await peer.getOffer();
    data.offer = offer;
    socket.emit("negoNeeded", data, function (res) {
      
    });
  }
};

export const negoDone = (data) => async (dispatch) => {
  if(socket){
    const ans = await peer.getAnswer(data.offer);
    data.ans = ans;
    socket.emit("negoDone", data, function (res) {
      
    });
  }
};

export const negoNeeded2 = (data) => async (dispatch) => {
  if(socket){
    const offer = await peer.getOffer();
    data.offer = offer;
    socket.emit("negoNeeded2", data, function (res) {
      
    });
  }
};

export const negoDone2 = (data) => async (dispatch) => {
  if(socket){
    const ans = await peer.getAnswer(data.offer);
    data.ans = ans;
    socket.emit("negoDone2", data, function (res) {
      
    });
  }
};

export const getGroupDetails =( details) =>(dispatch)=>{
  dispatch({
    type: GET_GROUP_DETAILS,
    payload: details,
  });
  dispatch({
    type : DISABLE_INPUT_CHAT,
    payload : details.disableInput
  })
}
export const callAction = (data) => (dispatch) => {
  if(socket){
    socket.emit("callAction", data, function (res) {
      if(!res.success){
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      }
    });
  }
};

export const playCallRecordingAudio = () => (dispatch) => {
  var x = document.getElementById("my-call-recording-audio");
  x.play();
  x.volume = 1.0;
};

export const setClearmessage = () => (dispatch) =>{
  dispatch({
    type : CLEAR_MESSAGE_LIST,
    payload : []
  })
}

export const sendCandidates = (data) => (dispatch) => {
  if(socket){
    socket.emit("sendCandidates", data, function (res) {
      if(!res.success){
        dispatch(showMessage("unsucess", _l("l_error"), _l(res.message)));
      }
    });
  }
};

export const callTaskListForRealtime = (data) => (dispatch) => {
  dispatch({
    type : CALL_TASK_LIST,
    payload : data
  })
  
}

export const handleChatScrolltoStop = (data) => (dispatch) =>{
  dispatch({
    type :STOP_CHAT_SCROLL,
    payload: data
  })
}

export const setTypingMembers = (data) => (dispatch) =>{
  dispatch({
    type: SET_TYPING_MEMBERS,
    payload: data
  })
}

export const sendTyping = (data) => (dispatch) => {
  if(socket){
    socket.emit("startTyping", data, function (res) {
      
    });
  }
};

export const setIDofFirstMessage = (id) => (dispatch) => {
  dispatch({
    type: FIRST_MESSAGE_ID,
    payload: id,
  });
};

export const disableChatInput = (data) => (dispatch) => {
  dispatch({
    type: DISABLE_INPUT_CHAT,
    payload: data,
  });
};
export const setCallisRecived = (data) => (dispatch) => {
  dispatch({
    type: CALL_IS_RECIVE,
    payload: data,
  });
};
export const stopCapturingMedia = () => (dispatch) => {
  // Get the local media stream (localStream) which includes both audio and video tracks
  const localStream = window.localStream;
  if(localStream){
    // Loop through each track in the localStream and stop it
    localStream.getTracks().forEach(track => {
        track.stop(); // Stop the track
    });

    // After stopping all tracks, you might want to clear the reference to the local stream
    window.localStream = null;
  }
};

export const setCallRunnigTimer = (time) => (dispatch) => {
  dispatch({
    type: CALL_TIMER,
    payload: time
  })
}

export const setCallRecordingRunnigTimer = (time) => (dispatch) => {
  dispatch({
    type: RECORDING_CALL_TIMER,
    payload: time
  })
}