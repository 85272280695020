import React from 'react';
 

function CelebrationIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>
			<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg" className='Push-Pin-fill'>
				<g clip-path="url(#clip0_10986_425884)">
					<path d="M123.223 75.2549C110.128 68.2961 94.5335 70.7696 84.2244 80.2929M62.8825 21.7951C68.8501 33.0253 66.9832 46.331 59.1979 55.4501M36.5574 60.9424L50.4603 74.8453M32.5422 72.4008L67.6504 107.509M24.9125 92.902L47.151 115.141M20.3889 103.853L36.2003 119.664M81.0887 26.3823C81.0887 23.159 83.7016 20.5461 86.9249 20.5461C90.1482 20.5461 92.7611 17.9331 92.7611 14.7098M113.858 36.0476C110.487 36.0476 107.755 38.7801 107.755 42.1509C107.755 45.5218 105.022 48.2546 101.651 48.2546M116.605 95.3742L114.42 93.1897M125.342 104.113L127.526 106.297M125.342 95.3742L127.526 93.1897M116.605 104.113L114.42 106.297M125.342 14.7114L127.526 12.5269M116.605 23.4482L114.42 25.6327M125.342 23.4482L127.526 25.6327M116.605 14.7114L114.42 12.5269M65.2096 89.5927L79.1124 103.496M77.2182 73.4667L84.9706 65.7142M93.2779 57.4032L91.5981 59.0831M95.0667 98.0858L18.0634 126.313C15.3695 127.3 12.7529 124.684 13.7404 121.99L41.9671 44.9863C42.8143 42.6755 45.7727 42.0233 47.513 43.7636L96.2894 92.5402C98.0298 94.2805 97.3775 97.2387 95.0667 98.0858ZM127.528 55.0394C127.528 57.8547 125.246 60.137 122.431 60.137C119.616 60.137 117.333 57.8547 117.333 55.0394C117.333 52.2242 119.616 49.9419 122.431 49.9419C125.246 49.9419 127.528 52.2242 127.528 55.0394ZM89.3207 42.1495C89.3207 44.9647 87.0384 47.247 84.2231 47.247C81.4079 47.247 79.1256 44.9647 79.1256 42.1495C79.1256 39.3343 81.4079 37.052 84.2231 37.052C87.0384 37.052 89.3207 39.3343 89.3207 42.1495ZM45.9658 17.6239C45.9658 20.4392 43.6835 22.7215 40.8683 22.7215C38.053 22.7215 35.7707 20.4392 35.7707 17.6239C35.7707 14.8087 38.053 12.5264 40.8683 12.5264C43.6835 12.5264 45.9658 14.8087 45.9658 17.6239Z" stroke="url(#paint0_linear_10986_425884)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
				</g>
				<defs>
					<linearGradient id="paint0_linear_10986_425884" x1="13.5273" y1="12.5264" x2="127.527" y2="129.414" gradientUnits="userSpaceOnUse">
						<stop stop-color="currentColor" />
						<stop offset="1" stop-color="currentColor" />
					</linearGradient>
					<clipPath id="clip0_10986_425884">
						<rect width="140" height="140" fill="white" transform="translate(0.5)" />
					</clipPath>
				</defs>
			</svg>


		</React.Fragment>
	)
}

export default CelebrationIcon