import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../actions/messages";
import Spinner from "react-bootstrap/Spinner";
import CustomerServices from "../../services/customer-services";
import { editBenchmarkDetail } from "../../actions/customer";

const AddEditProposalAmount = ({ show, handleClose, data }) => {
  const { benchmarkDetail } = useSelector((state) => state.customer);
  const [loder, setLoder] = useState(false);
  const [amount, setAmount] = useState("");
  const [proposalId, setProposalId] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      setAmount(data.amount);
      setDescription(data.description);
      setProposalId(data.proposal_id);
    }
  }, [JSON.stringify(data)])
  const handleAction = () => {
    if (amount == "" || amount == 0 || amount < 0) {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_amount')));
    } else if (description.trim() == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_please_enter_description")));
    } else if (proposalId == undefined || proposalId == 0) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_proposal_id_required")));
    } else {
      setLoder(true)
      CustomerServices.addEditProposalAmount(proposalId, amount, description, []).then(
        (res) => {
          setLoder(false);
          if (res.status == 1) {
            dispatch(showMessage("sucess",_l("l_success"), res.message));
            handleClose();
            clearFormData();
            if (benchmarkDetail) {
              let peoposalAmountBenchmark = benchmarkDetail
              peoposalAmountBenchmark.operator_total = amount;
              peoposalAmountBenchmark.proposal_description = description;
              dispatch(editBenchmarkDetail(peoposalAmountBenchmark));
            }
          } else {
            dispatch(showMessage("unsucess", _l("l_error"), res.message));
          }
        }
      );
    }
  }
  const clearFormData = () => {
    setAmount("");
    setDescription('');
    setProposalId("");
  }
  return (
    <>
      <div className="custom-modal-style add-topic-search">
        <Modal
          show={show}
          size="md"
          onHide={() => {
            handleClose();
            clearFormData();
          }}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
             {data && data.amount > 0 ? _l("l_edit") :  _l("l_add")} {_l("l_proposal_amount")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-0 bg-transparent mb-0  p-15">
              <div className="row m-0 pb-3">
                <div className="col-xl-12 p-0">
                  <Form.Group className="col-12 c-input-box pb-4 position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_amount")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l('l_amount')}`}
                      type="text"
                      name="amount"
                      value={amount}
                      onChange={(e) => {
                        var validNumber = new RegExp(/^\d*\.?\d*$/);
                        const inputValue = e.target.value;
                        if (validNumber.test(inputValue)) {
                          setAmount(inputValue);
                        }
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-12 p-0">
                  <Form.Group className="col-12 c-input-box position-relative">
                    <Form.Label className="input-label no-asterisk ">
                      {_l("l_description")}
                    </Form.Label>
                    <Form.Control
                      placeholder={`${_l("l_description")}`}
                      as="textarea"
                      rows={3}
                      type="text"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={() => {
            handleClose();
            clearFormData();
          }}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" disabled={loder} size="sm" onClick={() => {handleAction()}}>
              {loder ? _l('l_please_wait') : _l('l_save')}
              {loder && <Spinner as="span" animation="border" size="sm" role="status" className={`ms-2`} aria-hidden="true" />}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddEditProposalAmount;
