import React, { useEffect, useState } from "react";
import { _l } from "../../hooks/utilities";
import MapMainCardList from "./MapMainCardList";
import MapTaskRequestList from "./MapTaskRequestList";
import CustomerServices from "../../services/customer-services";
import { useSelector } from "react-redux";
const MapPageCard = ({ }) => {
    const {
        calendarData,
        taskFilters,
        activeTaskStatusFilters,
    } = useSelector((state) => state.customer);
    const [dashboardDetails, setDashboardDetails] = useState({});

    useEffect(() => {
        getDashboardDetails(activeTaskStatusFilters ? activeTaskStatusFilters : [], taskFilters)
    }, [localStorage.getItem("selectedOffice"), JSON.stringify(taskFilters), JSON.stringify(activeTaskStatusFilters), calendarData])
    const getDashboardDetails = (status, taskFilters) => {
        CustomerServices.getDashboardDetails(status, taskFilters).then((res) => {
            if (res.status) {
                if (res.data) {
                    setDashboardDetails({
                        step_2: {
                            request_percentage: res.data.request_percentage ? res.data.request_percentage : 0,
                            todo_percentage: res.data.todo_percentage ? res.data.todo_percentage : 0,
                            inprogress_percentage: res.data.inprogress_percentage ? res.data.inprogress_percentage : 0,
                            done_percentage: res.data.done_percentage ? res.data.done_percentage : 0,
                            completed_task: res.data.completed_task ? res.data.completed_task : 0,
                            on_going_task: res.data.on_going_task ? res.data.on_going_task : 0,
                            not_started_task: res.data.not_started_task ? res.data.not_started_task : 0,
                            requested_task: res.data.requested_task ? res.data.requested_task : 0,
                            workspace_score: res.data.workspace_score ? res.data.workspace_score : 0,
                        },
                        step_3: {
                            total_area: res.data.total_area ? res.data.total_area : 0,
                            area: res.data.area ? res.data.area : 0,
                            equipments_count: res.data.equipments_count ? res.data.equipments_count : 0,
                            equipments: res.data.equipments ? res.data.equipments : 0,
                            operator_count: res.data.operator_count ? res.data.operator_count : 0,
                            operators: res.data.operators ? res.data.operators : 0,
                            employee_count: res.data.employee_count ? res.data.employee_count : 0,
                            employees: res.data.employees ? res.data.employees : 0,
                            property_count: res.data.property_count ? res.data.property_count : 0,
                        }
                    })
                    localStorage.setItem('overallWorkSpaceScore', res.data.workspace_score ? res.data.workspace_score : 0)
                }

            }
        });
    }


    return (
        <>
            <div className="d-flex flex-column h-100">
                <div className="map-main w-100">
                    <div className="map-right w-100">
                        <div className="w-100 map-right-main d-flex h-100">
                            <div className="map-right-task-request py-0 ps-0 p-10  flex-grow-1">
                                <MapTaskRequestList dashBoardDetails={dashboardDetails.step_2 ? dashboardDetails.step_2 : {}} />
                            </div>
                            <div className="main-card-list h-100 d-flex flex-column">
                                <MapMainCardList dashBoardDetails={dashboardDetails.step_3 ? dashboardDetails.step_3 : {}} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default MapPageCard;