import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;

const exchangeGoogleCode = (code, provider = "google_drive") => {
    const endPoint = API_URL + "mod=google_drive_integration&act=exachangecode";
    let form = new FormData();
    form.append("code", code);
    form.append("provider", provider);
    form.append("project_id", localStorage.getItem("selectedOffice"));

    return apiService(endPoint, "POST", true, form);
};

const getAcitivityList = (document_id, project_id) => {
    const endPoint = API_URL + "mod=google_drive_integration&act=get_activities";
    let form = new FormData();
    form.append("max_result", 5);
    form.append("project_id", project_id);
    form.append("document_id", document_id);
    return apiService(endPoint, "POST", true, form);
};

const is_connected = async (project_id) => {
    const endPoint = API_URL + "mod=google_drive_integration&act=is_connected";
    let form = new FormData();
    form.append("project_id", project_id);

    return apiService(endPoint, "POST", true, form);
};

const revoke = async (project_id) => {
    const endPoint = API_URL + "mod=google_drive_integration&act=revoke";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("provider", "google_drive");
    return apiService(endPoint, "POST", true, form);
};


const updateDocumentStatus = (document_id, project_id, status) => {
    const endPoint = API_URL + "mod=google_drive_integration&act=update_file_status";
    let form = new FormData();
    form.append("document_id", document_id);
    form.append("project_id", project_id);
    form.append("status", status);
    return apiService(endPoint, "POST", true, form);
};

const pinDocument = (document_id, project_id) => {
    const endPoint = API_URL + "mod=google_drive_integration&act=pin_document";
    let form = new FormData();
    form.append("document_id", document_id);
    form.append("project_id", project_id);
    return apiService(endPoint, "POST", true, form);
};

const getDocumentList = (
    project_id,
    startDate = "",
    page = false,
    status = [],
    filters = [],
    searchString = "",
    viewAll = 0,
    isDateActive = 1
) => {
    let endPoint = API_URL + "mod=google_drive_integration&act=get_document_list";
    let filter_key = "";
    let form = new FormData();
    form.append("project_id", project_id);

    if (status && status.length) {
        status.forEach((item, index) => {
            form.append(`status[${index}]`, item);
        });
        form.append("page", page);
        // form.append("view_all", 1);
    }
    // if (viewAll == 1) {
    //   form.append("view_all", 1);
    // }
    if (isDateActive == 1) {
        form.append("startdate", startDate);
    }
    if (filters.length) {
        let formattedList = [];
        filters.forEach((filter) => {
            filter_key =    filter.main_key;
            if (formattedList[filter.main_key]) {
                formattedList[filter.main_key].push(filter.filter_key);
            } else {
                formattedList[filter.main_key] = [filter.filter_key];
            }
            // form.append(`filters[${filter.main_key}][${index}]`, filter.filter_key);
        });
        Object.keys(formattedList).forEach((category) => {
            formattedList[category].forEach((value, index) => {
                if (category == "date_range_filter") {
                    if (value != "all") {
                        form.append(`filters[from_date]`, localStorage.getItem("fromDate"));
                        form.append(`filters[to_date]`, localStorage.getItem("toDate"));
                    }
                    form.delete("startdate");
                } else {
                    form.append(`filters[${category}][${index}]`, value);
                }
            });
        });
    }
    if (searchString) {
        form.append(`filters[search]`, searchString);
    }
    if(filter_key === 'onedrive_documents')
    {
        endPoint = API_URL + "mod=onedrive_integration&act=get_folder_data";
    }
    return apiService(endPoint, "POST", true, form);
};

const changePriority = (document_id, project_id) => {
    const endPoint = API_URL + "mod=google_drive_integration&act=set_priority";
    let form = new FormData();
    form.append("document_id", document_id);
    form.append("project_id", project_id);
    return apiService(endPoint, "POST", true, form);
};

const change_topic_status = async (project_id, filter_key, active_or_inactive) => {

    const endPoint = API_URL + "mod=google_drive_integration&act=change_topic_status";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("filter_key", filter_key);
    form.append("active", active_or_inactive);

    return apiService(endPoint, "POST", true, form);
};

export default {
    exchangeGoogleCode,
    getAcitivityList,
    is_connected,
    revoke,
    updateDocumentStatus,
    pinDocument,
    getDocumentList,
    changePriority,
    change_topic_status
};