import React, { useEffect } from "react";
import { _l } from "../../../hooks/utilities";
import CheckCircleIcon from "../../../assets/icons/CheckCircleIcon";

const AcknowledgeScreen = ({steps, delay, afterDismissAction, title, description}) => {

  useEffect(() => {
    setTimeout(() => {
      afterDismissAction();
    }, delay);
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex h-100 w-100">
        <div className="m-auto p-20 rounded-3">
          <div className="d-flex flex-column light-theme-white-bg p-20">
            <div className="c-font f-18 fw-semibold text-center title-fonts py-2">
              {_l(title)}
            </div>
            <div className="c-font color-white-80 text-center f-13">
            {_l(description)}
            </div>
            <ul className="comman-list mt-2 mx-auto position-relative">
              {steps.titles.map((step, key) => {
                return (
                  <li key={key} className="steps-green-line">
                    <a href="#/" className="check-image absolute-content">
                      {key === steps.titles.length - 1 ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="ms-1 spinner-border spinner-border-sm"
                        ></span>
                      ) : (
                        <CheckCircleIcon
                          className="HW22 color-green"
                        />
                      )}
                    </a>
                    <div className="c-font f-14 fw-semibold title-fonts">
                      {_l(step)}
                    </div>
                    <div className="c-font color-white-60 f-13 title-fonts">
                      {_l(steps.descriptions[key])}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AcknowledgeScreen;
