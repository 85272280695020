import React from 'react';
 

function GearSixIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M8 11.0004C9.65686 11.0004 11 9.65724 11 8.00039C11 6.34354 9.65686 5.00039 8 5.00039C6.34315 5.00039 5 6.34354 5 8.00039C5 9.65724 6.34315 11.0004 8 11.0004Z" />
				<path d="M12.3375 5.04414C12.488 5.25956 12.6197 5.48748 12.7313 5.72539L14.35 6.62539C14.5521 7.53069 14.5543 8.46919 14.3563 9.37539L12.7313 10.2754C12.6197 10.5133 12.488 10.7412 12.3375 10.9566L12.3688 12.8129C11.6827 13.438 10.8708 13.909 9.9875 14.1941L8.39375 13.2379C8.13159 13.2566 7.86842 13.2566 7.60625 13.2379L6.01875 14.1879C5.13269 13.908 4.31806 13.4388 3.63125 12.8129L3.6625 10.9629C3.5133 10.7445 3.38163 10.5146 3.26875 10.2754L1.65 9.37539C1.44788 8.4701 1.44574 7.5316 1.64375 6.62539L3.26875 5.72539C3.3803 5.48748 3.51204 5.25956 3.6625 5.04414L3.63125 3.18789C4.31728 2.56274 5.12924 2.09177 6.0125 1.80664L7.60625 2.76289C7.86842 2.74414 8.13159 2.74414 8.39375 2.76289L9.98125 1.81289C10.8673 2.09283 11.6819 2.56199 12.3688 3.18789L12.3375 5.04414Z" />
				</svg>


		</React.Fragment>
	)
}

export default GearSixIcon