import React from "react";
import { Outlet } from "react-router-dom";
import ExternalPageConnector from "../views/ExternalPageConnector";
const AuthenticatedRoutes = () => {
  const authToken = localStorage.getItem("accessToken");
  return (
    <React.Fragment>
      {authToken ? (
        <Outlet />
      ) : (
          <ExternalPageConnector />
        // <Navigate to="/login" state={{ from: location }} replace />
      )}
    </React.Fragment>
  );
};
export default AuthenticatedRoutes;
