import React from "react";
import { _l } from "../../hooks/utilities";


const ProgressBarLoader = ({}) => {
  return (
    <>
      <div className="main-loader-wrapper full-screen">
          <div className="w-100 h-100">
            <div className="d-flex w-50 flex-column align-items-center justify-content-center m-auto h-100">
            <div className="c-font f-20 fw-semibold title-fonts pb-3">{_l('l_loading_calculation')}</div>
              <div className="w-100 p-20px px-0">
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: `50%`,}}></div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default ProgressBarLoader;
