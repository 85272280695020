import React, { useEffect, useRef } from 'react';
import 'emoji-picker-element';

const EmojiPicker = ({ onEmojiSelect }) => {
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    const emojiPicker = emojiPickerRef.current;

    // Listen for emoji selection events
    const handleEmojiSelect = (event) => {
      if (onEmojiSelect) {
        onEmojiSelect(event.detail.unicode);
      }
    };

    emojiPicker.addEventListener('emoji-click', handleEmojiSelect);

    // Cleanup the event listener when component unmounts
    return () => {
      emojiPicker.removeEventListener('emoji-click', handleEmojiSelect);
    };
  }, [onEmojiSelect]);

  return (
    <div className="emoji-picker-wrapper">
      {/* The Web Component */}
      <emoji-picker skin-tone-emoji="😀" ref={emojiPickerRef}></emoji-picker>
    </div>
  );
};

export default EmojiPicker;
