import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l, showError,} from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import TrashIcon from "../../assets/icons/TrashIcon";
import CalendarBlankIcon from "../../assets/icons/CalendarBlankIcon";
import DatePicker from "react-datepicker";
import { showMessage } from "../../actions/messages";

const CustomFieldsAdd = ({
  customField,
  addSelectItem = []
}) => {
  switch (customField.type) {
    case "textarea":
      return (
        <Form.Group className={`c-input-box pb-4 position-relative col-xl-12}`}>
          <Form.Label className="input-label ">
            {customField.name}
          </Form.Label>
          <Form.Control
            as="textarea"
            className={`${addSelectItem.some(key => key.title == customField.name) ? 'active_border50' :''}`}
            rows={5}
            placeholder={customField.name}
            type="text"
            name=""
            disabled
          ></Form.Control>
        </Form.Group>
      );
    case "select":
      const singleSelectMenuOptions = customField.default_value
        .split(",")
        .map((option) => {
          return { label: option, value: option };
        });

      return (
        <Form.Group className="col-xl-12 c-input-box position-relative pb-3 z-index-12">
          <Form.Label className="input-label">{customField.name}</Form.Label>
          <div className={`c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative border-transparent radius_3 ${addSelectItem.some(key => key.title == customField.name) ? 'active_border50' :''}`}
          onClick={(e) => e.stopPropagation()}
          >
            <Select
              className="custom-select-menu bg-white-03-solid onboarding-map-input scroll-height-30vh"
              classNamePrefix="react-select"
              placeholder={_l("l_select")}
              options={singleSelectMenuOptions}
              disabled
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              isOptionDisabled={() => true}
            />
          </div>
        </Form.Group>
      );
    case "multi_select":
      const multipleSelectMenuOptions = customField.default_value
        .split(",")
        .map((option) => {
          return { label: option, value: option };
        });
      return (
        <Form.Group className="col-xl-12 c-input-box position-relative pb-3 z-index-12">
          <Form.Label className="input-label">{customField.name}</Form.Label>
          <div className={`c_input_border drop_image col-12 col-md-12  col-xl-12 position-relative border-transparent radius_3 ${addSelectItem.some(key => key.title == customField.name) ? 'active_border50' :''}`}
          onClick={(e) => e.stopPropagation()}
          >
            <Select
              className="custom-select-menu bg-white-03 onboarding-map-input scroll-height-30vh"
              classNamePrefix="react-select"
              placeholder={_l("l_select")}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              options={multipleSelectMenuOptions}
              isMulti
              disabled
              isOptionDisabled={() => true}
            />
          </div>
        </Form.Group>
      );
    case "url":
      return (
        <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
          <Form.Label className="input-label ">
            {customField.name}
          </Form.Label>
          <Form.Control
            className={`${addSelectItem.some(key => key.title == customField.name) ? 'active_border50' :''}`}
            placeholder={customField.name}
            type="text"
            name={customField.name}
            disabled
          />
        </Form.Group>
      );
    case "date":
      return (
        <>
          {" "}
          <Form.Group className="col-xl-12 c-input-box pb-4 position-relative">
            <Form.Label className="input-label ">
              {customField.name}
            </Form.Label>
            <div className="custom-datepicker">
              <div className={`d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px ${addSelectItem.some(key => key.title == customField.name) ? 'active_border50' :''}`}>
                <div className="action_icon h32w32 with_bg">
                  <CalendarBlankIcon
                    className="HW18 opacity-60"
                  />
                </div>
              </div>
            </div>
          </Form.Group>{" "}
        </>
      );
    default:
      return <div>CustomFields</div>;
  }
};


const AddFieldItem = ({ show, handleClose = () => {}, docType, data }) => {

  const dispatch = useDispatch();

  const { benchmarkDetail } = useSelector((state) => state.customer);
  
  const [type, setType] = useState();
  const [fieldName, setFieldName] = useState('');
  const [optionField, setOptionField] = useState('')
  const [addOption, setAddOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addSelectItem, setAddSelectItem] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const typeOption = [
    { value: "text", label: "Input" },
    { value: "textarea", label: "Textarea" },
    { value: "number", label: "Number" },
    { value: "date", label: "Date Picker" },
    { value: "select", label: "Select" },
    { value: "multi_select", label: "Multi select" },
    { value: "url", label: "URL" },
    { value: "checkbox", label: "Checkbox" },
    // { value: "file", label: "File" },
  ];

  const addSelectOption = [
    {
      "name": "Date de la visite préalable à la réalisation de ce document :",
      "type": "date",
      "default_value": "",
      "is_required": "1"
    },
    {
      "name": "Joindre/ importer un plan du site",
      "type": "url",
      "default_value": "",
      "is_required": "1"
    },
    {
      "name": "Locaux de l’Entreprise Utilisatrice mis à disposition",
      "type": "multi_select",
      "default_value": "sanitaires, vestiaires, locaux de restauration, autre",
      "is_required": "1"
    },
    {
      "name": "Risques présents dans l’entreprise utilisatrice",
      "type": "multi_select",
      "is_required": "1",
      "default_value": "Chute de hauteur, Circulation d’engins, Incendie / Explosion, Chute de plain-pied, Déplacement de piétons, Effondrement, Chute d’objet, Risque biologique, Risque électrique, Bruit, Risque chimique, Machine en mouvement, Manque d’éclairage, Projections, Travail isolé"
    },
    {
      "name": "Établissement d’un permis de feu ?",
      "type": "select",
      "default_value": "Oui, Non",
      "is_required": "1"
    },
    {
      "name": "Établissement d’un DICT ? (Déclaration d’Intervention de Commencement de Travaux (proche canalisation))",
      "type": "select",
      "default_value": "Oui, Non",
      "is_required": "1"
    },
    {
      "name": "Travail appartenant à la liste des travaux dangereux ? (Arrêté du 10 mai 1994)",
      "type": "select",
      "default_value": "Oui, Non",
      "is_required": "1"
    },
    {
      "name": "Existe-t-il du personnel de l’entreprise extérieure qui relève d’une surveillance médicale spéciale ?  (Article R4624-19 du code du travail) (bruit, p",
      "type": "select",
      "default_value": "Oui, Non",
      "is_required": "1"
    },
    {
      "name": "EN CAS D’URGENCE :  Présence d’un sauveteur secouriste du travail :",
      "type": "select",
      "default_value": "Oui, Non",
      "is_required": "1"
    },
    {
      "name": "Conduite à tenir en cas d’urgence (numéro à contacter)",
      "type": "textarea",
      "default_value": "",
      "is_required": "1"
    },
    {
      "name": "Liste des tâches réalisées par l’Entreprise Extérieure",
      "type": "textarea",
      "default_value": "",
      "is_required": "1"
    },
    {
      "name": "Instructions particulières aux personnes intervenantes :",
      "type": "textarea",
      "default_value": "",
      "is_required": "1"
    },
    {
      "name": "Matériel mis à la disposition de l’entreprise extérieure (machines, produits …) :",
      "type": "textarea",
      "default_value": "",
      "is_required": "1"
    },
  ]

  const addOptionHandler = async() => {
    setAddOption([...addOption, optionField]);
    setOptionField('');
  };

  const removeOption = (index) => {
    setAddOption(addOption.filter((w, i)=> i !== index))
  }

  const addSelecteHandler = (item) => {
    const addIndex = addSelectItem.findIndex(w => w.title == item.name);
    if(addIndex == -1){
      setAddSelectItem([...addSelectItem, {title : item.name, default_value : item.default_value, type : item.type, is_required : item.is_required}]);
    }else{
      setAddSelectItem(addSelectItem.filter((w, i)=> w.title !== item.name))
    }
  }

  const fieldValidations = () => {
    let flag = true;
    if (docType === "addTemp") {
      if (!addSelectItem || !addSelectItem.length) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_please_enter_the") + _l("l_select_field"))
        );
        flag = false;
      }
    }else{
      if (!fieldName || fieldName == '') {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_please_enter_field_name"))
        );
        flag = false;
      } else if (!type || !type.value || !type.value.length) {
        dispatch(
          showMessage("unsucess", _l("l_error"), _l("l_please_select_type"))
        );
        flag = false;
      } else if (type && ["select", "multi_select", "checkbox"].includes(type.value) && addOption && addOption.length == 0) {
        dispatch(
          showMessage(
            "unsucess",
            _l("l_error"),
            _l("l_please_enter_select_option_value")
          )
        );
        flag = false;
      } 
    }
    return flag;
  };
  
  useEffect(() => {
    let element = document.body;
    if (show) {
      if (element) {
        element.classList.remove("modal-open");
      }
    }
    return () => {
      if (element) {
        element.classList.remove("modal-open");
      }
    };
  }, [show]);

  useEffect(() => {
    if (docType == "edit") {
        setFieldName(data.title);
        setType(typeOption.find((ty) => ty.value == data.type));
        setIsRequired(data.is_required);
        if (["select", "multi_select", "checkbox"].includes(typeOption.find((ty) => ty.value == data.type).value)) {
          let str = data.default_value;
          
          // Step 1: Remove the parentheses
          str = str.replace(/[()]/g, '');
          
          // Step 2: Split the string by commas and trim spaces
          let arr = str.split(',').map(item => item.trim().replace(/"/g, ''));
          setAddOption(arr)
        }
    }
  }, [docType])
  
 
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={"static"}
      keyboard={false}
      size={docType === "addTemp" ? "xl" : ''}
      centered
      className="custom-modal-style solarcommanmodal "
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
    >
      <Modal.Header className="p-3" closeButton>
        <Modal.Title>{docType === "addTemp" ? _l("l_select_field") : _l("l_add")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {docType === "addTemp" ? 
        <div className="mt-1 childTopicTaskFilter">
        <div className="row">
          {addSelectOption.map((field, index) => {
            return (
              <div className="col-lg-6"
                onClick={() => {
                  addSelecteHandler(field);
                }}
              >
                <CustomFieldsAdd
                  key={index}
                  customField={field}
                  addSelectItem={addSelectItem}
                />
              </div>
            );
          })}
        </div>
      </div>
        : <div className="form-wrapper-main p-0">
            <div className="col-12">
                <Form.Group className="c-input-box  position-relative mb-3">
                <Form.Label className="input-label ">
                    {`${_l("l_add")} ${_l("Fields")}`}
                </Form.Label>
                <input
                    className="form-control"
                    placeholder="name"
                    type="text"
                    name="budget"
                    value={fieldName}
                    onChange={(e) => {
                        setFieldName(e.target.value);
                    }}
                />
                </Form.Group>
                <Form.Group className="c-input-box  position-relative mb-3">
                    <Form.Label className="input-label ">
                    {_l("l_select_type")}
                    </Form.Label>
                    
                    <Select
                    placeholder={`${_l("l_select_type")}`}
                    className="custom-select-menu w-100"
                    classNamePrefix="react-select"
                    isDisabled= {docType == "edit_doc_template"}
                    closeMenuOnSelect={true}
                    value={type}
                    isClearable={false}
                    options={typeOption}
                    onChange={(value) => { setType(value)}}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    />
                </Form.Group>
                {type && ["select", "multi_select", "checkbox"].includes(type.value) ?<>
                <Form.Group className="c-input-box  position-relative">
                <Form.Label className="input-label ">
                    {`${_l("l_options")}`}
                </Form.Label>
                <input
                    className="form-control"    
                    placeholder="name"
                    type="text"
                    name="budget"
                    value={optionField}
                    onChange={(e) => {
                        setOptionField(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            addOptionHandler();
                        }
                    }}
                />
                </Form.Group>
                <div className="pb-4">
                    {addOption && addOption.length > 0 && 
                    addOption.map((mail,index)=>{
                    return(<>
                    <div
                        key={index}
                        className='badge c-font f-12 p-2 rounded-pill me-1 mt-2'
                    >
                        {mail}
                        <a href="#/" className='ps-1' onClick={() => removeOption(index)}>
                        <TrashIcon size={10}  weight="light" className="c-icons" />
                        </a>
                    </div>
                    </>)
                    })}
                </div>
                </> :<></>}
                <div className="form-check with-span py-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label mt-2 mb-0 w-100">
                      <input
                        className="form-check-input h20w20"
                        type="checkbox"
                        checked={isRequired}
                        onChange={(e) => setIsRequired(e.target.checked)}
                      />
                      <span className="c-font f-12 ps-1">
                        {_l("l_is_required")}
                      </span>
                    </Form.Label>
                  </div>
                </div>
            </div>
        </div>}
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-end mt-5">
        <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l("l_close")}
        </Button>
        {docType === "addTemp" ?
         <Button
            variant="primary"
            size="sm"
            onClick={() => {
              if (!fieldValidations()) {
                return false
              }
              handleClose(addSelectItem);
            }}
          >{ _l("l_save")}</Button>
          :
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              if (!fieldValidations()) {
                return false
              }
              handleClose(type.value, fieldName, addOption.join(), isRequired);
            }}
          >
            {loader ? _l("l_please_wait") : _l("l_save")}
            {loader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                className={`ms-2 `}
                aria-hidden="true"
              />
            ) : (
              <></>
            )}
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default AddFieldItem;