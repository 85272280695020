import React, { useEffect } from "react";
import StaffList from "../components/clientList";
import {
  CardListFullHeight,
  DefaultListView,
  leftPanelCategoryListToggle,
  showLeftPanel,
} from "../hooks/utilities";
const clientmain = () => {
  const pageName = window.location.pathname == '/clients' ? 'client' : "";  
  useEffect(() => {
    showLeftPanel(true, true);
    CardListFullHeight();
    DefaultListView();
    document.addEventListener('mouseup', leftPanelCategoryListToggle);
    return () => {
      document.removeEventListener('mouseup', leftPanelCategoryListToggle);
    }
  }, []);
  return (
    <>
      <StaffList pageName={pageName} />
    </>
  );
};
export default clientmain;
