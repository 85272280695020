import React from 'react';
 

function NoViewsTrackedplaceholderIcon(props) {
	const height = (props.height) ? props.height : "13";
	const width = (props.width) ? props.width : "16";
	const viewBox = (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW = (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return (
		<React.Fragment>

			<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
				<path d="M18.75 15.625L81.25 84.375" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M60.5061 61.5625C57.6387 64.1893 53.8871 65.6398 49.9983 65.625C46.8447 65.6247 43.7649 64.67 41.164 62.8866C38.563 61.1032 36.5626 58.5745 35.4257 55.6329C34.2888 52.6913 34.0686 49.4745 34.7941 46.4054C35.5196 43.3364 37.1568 40.5587 39.4905 38.4375" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M28.9062 26.7969C12.9688 34.8438 6.25 50 6.25 50C6.25 50 18.75 78.125 50 78.125C57.3226 78.1847 64.5538 76.4974 71.0938 73.2031" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M81.4824 66.0548C89.998 58.4376 93.748 50.0001 93.748 50.0001C93.748 50.0001 81.248 21.8751 49.998 21.8751C47.2889 21.8697 44.5841 22.0919 41.9121 22.5392" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M52.9297 34.6479C56.2525 35.2775 59.2804 36.9709 61.5564 39.4723C63.8324 41.9738 65.233 45.1478 65.5469 48.5151" strokeLinecap="round" strokeLinejoin="round" />
			</svg>



		</React.Fragment>
	)
}

export default NoViewsTrackedplaceholderIcon