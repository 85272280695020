import React from "react";
import useScheduleSteps from "../../../hooks/useScheduleSteps";
import {
  countCheckedValues,
} from "../../../hooks/utilities";
import ListChecksIcon from "../../../assets/icons/ListChecksIcon";
import XIcon from "../../../assets/icons/XIcon";

export default function ScheduleAssignmentOperatorSlot({
  handleSelectOperatorDaySlot,
  detail,
  openScheduleActiondetailModal,
  removeSlot,
  index,
}) {
  const { operatorAvailability, assignedChecklistItems, calculationsCounts } =
    useScheduleSteps();

  const handleSelectedSlot = (day, checked) => {
    let days = { ...detail.days };
    days[day] = checked;
    if (calculationsCounts.headerCounts[day] > 0 || !days[day]) {
      handleSelectOperatorDaySlot(days);
    }
  };

  return (
    <tr>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input"
            type="checkbox"
            checked={detail.days && detail.days.mon ? detail.days.mon : false}
            onChange={(e) => {
              handleSelectedSlot("mon", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={detail.days && detail.days.tue ? detail.days.tue : false}
            onChange={(e) => {
              handleSelectedSlot("tue", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={detail.days && detail.days.wed ? detail.days.wed : false}
            onChange={(e) => {
              handleSelectedSlot("wed", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={detail.days && detail.days.thu ? detail.days.thu : false}
            onChange={(e) => {
              handleSelectedSlot("thu", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={detail.days && detail.days.fri ? detail.days.fri : false}
            onChange={(e) => {
              handleSelectedSlot("fri", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={detail.days && detail.days.sat ? detail.days.sat : false}
            onChange={(e) => {
              handleSelectedSlot("sat", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={detail.days && detail.days.sun ? detail.days.sun : false}
            onChange={(e) => {
              handleSelectedSlot("sun", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0"></td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={
              detail.days && detail.days.monthly ? detail.days.monthly : false
            }
            onChange={(e) => {
              handleSelectedSlot("monthly", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={
              detail.days && detail.days.quarterly
                ? detail.days.quarterly
                : false
            }
            onChange={(e) => {
              handleSelectedSlot("quarterly", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input "
            type="checkbox"
            checked={
              detail.days && detail.days.half_yearly
                ? detail.days.half_yearly
                : false
            }
            onChange={(e) => {
              handleSelectedSlot("half_yearly", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <label for="" className="benchmark_div_height">
          <input
            className="form-check-input"
            type="checkbox"
            checked={
              detail.days && detail.days.yearly ? detail.days.yearly : false
            }
            onChange={(e) => {
              handleSelectedSlot("yearly", e.target.checked);
            }}
          />
        </label>
      </td>
      <td className="text-center p-0">
        <a href="#/"
          className="btn btn-primary btn-sm min-w-75px h-100"
          onClick={() => {
            openScheduleActiondetailModal();
          }}
        >
          <ListChecksIcon
            className="HW16"
          />
          <span className="px-1">
            [{operatorAvailability[index].action_count}]
          </span>
        </a>
      </td>
      <td className="text-center p-0">
        {countCheckedValues(assignedChecklistItems, index, operatorAvailability[index].days)}
      </td>
      <td className="res-white-space p-0 text-center">
        {operatorAvailability.length > 1 ? (
          <a href="#/" className="bg-white-05" onClick={removeSlot}>
            <XIcon className="HW14 m-2" />
          </a>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}
