import React from 'react';
 

function LeadIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={`comman-icons ${CommanHW}`}>
<path d="M23 7C24.1037 7 25 6.10365 25 5C25 3.89635 24.1037 3 23 3C21.8963 3 21 3.89635 21 5C21 6.10365 21.8963 7 23 7Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 10C17.1037 10 18 9.10365 18 8C18 6.89635 17.1037 6 16 6C14.8963 6 14 6.89635 14 8C14 9.10365 14.8963 10 16 10Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.99998 8C10.1037 8 11 7.10365 11 6C11 4.89635 10.1037 4 8.99998 4C7.89632 4 7 4.89635 7 6C7 7.10365 7.89632 8 8.99998 8Z"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.68138 12H23.3186C23.4508 12 23.5802 12.0409 23.6909 12.1175C23.8016 12.1942 23.8889 12.3033 23.9422 12.4317C23.9955 12.5601 24.0124 12.7021 23.991 12.8405C23.9695 12.9789 23.9105 13.1077 23.8213 13.2112L18.2237 19.7461C18.1072 19.8784 18.0431 20.0531 18.0448 20.2341V25.35C18.0458 25.4701 18.0183 25.5887 17.9646 25.6945C17.911 25.8004 17.8331 25.8902 17.7381 25.9556L15.0117 27.8808C14.9094 27.9522 14.7909 27.9932 14.6686 27.9992C14.5462 28.0053 14.4246 27.9763 14.3165 27.9152C14.2084 27.8542 14.1178 27.7634 14.0542 27.6523C13.9907 27.5413 13.9565 27.4141 13.9552 27.2842V20.2341C13.9569 20.0531 13.8928 19.8784 13.7763 19.7461L8.1787 13.2112C8.08946 13.1077 8.03052 12.9789 8.00905 12.8405C7.98758 12.7021 8.00451 12.5601 8.05779 12.4317C8.11107 12.3033 8.19838 12.1942 8.3091 12.1175C8.41982 12.0409 8.54917 12 8.68138 12Z"  strokeLinecap="round" strokeLinejoin="round"/>
</svg>
		</React.Fragment>
	)
}

export default LeadIcon