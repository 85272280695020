import React from "react";
import { Button, Modal } from "react-bootstrap";
import { _l } from "../../hooks/utilities";

const AlertModal = ({
  handleClose,
  message,
  icon,
  buttonText,
  secondaryButtonText,
  secondaryButtonHandler,
}) => {
  return (
    <Modal
      className="custom-modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
    >
      <Modal.Body className=" pt-0">
        <div className="form-wrapper-main d-flex">
          <div className="text-center w-100">
            <icon.name className="fill-transparent HW22 pe-auto c-icons mx-auto" />
            <div className="c-font f-18 fw-semibold title-fonts mt-2">
              {_l(message)}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center">
        {
          buttonText ? 
            <Button variant="primary" onClick={handleClose}>
              {_l(buttonText)}{" "}
            </Button>
          :
            <React.Fragment></React.Fragment>
        }
        {secondaryButtonText ? (
          <Button variant="secondary" onClick={secondaryButtonHandler}>
            {_l(secondaryButtonText)}{" "}
          </Button>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
