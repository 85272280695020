import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;

const oneDrive_authentication = (code, state, project_id, provider = "one_drive") => {
  const endPoint = API_URL + "mod=onedrive_integration&act=user_authentication";
  let form = new FormData();
  form.append("code", code);
  form.append("provider", provider);
  form.append("state", state);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};
const change_topic_status = (key, status, project_id) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=change_topic_status";
  let form = new FormData();
  form.append("key", key);
  form.append("status", status);
  form.append("project_id", project_id);
  return apiService(endPoint, "POST", true, form);
};
const revoke = async (rel_id, rel_type) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=revoke";
  let form = new FormData();
  form.append("rel_id", rel_id);
  form.append("rel_type", rel_type);
  return apiService(endPoint, "POST", true, form);
};
const oauthUrl = async (rel_id, rel_type) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=oauth_url";
  let form = new FormData();
  form.append("rel_id", rel_id);
  form.append("rel_type", rel_type);
  return apiService(endPoint, "POST", true, form);
};
const getOnedriveFiles = async (rel_id, rel_type) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=get_files";
  let form = new FormData();
  form.append("rel_id", rel_id);
  form.append("rel_type", rel_type);
  return apiService(endPoint, "POST", true, form);
};

const is_connected = async (project_id) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=is_onedrive_connected";
  let form = new FormData();
  form.append("project_id", project_id);

  return apiService(endPoint, "POST", true, form);
};
const folder_data = async (project_id) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=get_folder_data";
  let form = new FormData();
  form.append("project_id", project_id);

  return apiService(endPoint, "POST", true, form);
};
const getOneDriveAssignee = async (file_id ,project_id) => {
  const endPoint = API_URL + "mod=onedrive_integration&act=get_contacts";
  let form = new FormData();
  form.append("project_id", project_id);
  form.append("file_id", file_id);

  return apiService(endPoint, "POST", true, form);
};
export default {
  oneDrive_authentication,
  revoke,
  getOnedriveFiles,
  is_connected,
  change_topic_status,
  folder_data,
  oauthUrl,
  getOneDriveAssignee,
};
