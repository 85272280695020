import React, { useEffect, useState } from "react";
import jquery from "jquery";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../actions/messages";
import { CardView } from "./Documents/CardView";
import { _l } from "../hooks/utilities";
import { getSubscriptionDetail } from "../actions/documents-actions/documents-actions";
import DocumentsServices from "../services/documents-services";
import CommanOffCanvas from "./Offcanvas/CommanOffCanvas";
import { ESTIMATE_TOPICS, SUBSCRIPTION_COUNTER } from "../actions/action-type";
import CommanPlaceholder from "./Placeholder/CommanPlaceholder";
import { clientTabData, leftPanelFilters } from "../actions/customer";
import XIcon from "../assets/icons/XIcon";
import MagnifyingGlassIcon from "../assets/icons/MagnifyingGlassIcon";
import CaretDoubleDownIcon from "../assets/icons/CaretDoubleDownIcon";
const SubscriptionList = ({pageName, clientid}) => {
    const [activeSubscription, setActiveSubscription] = useState([]);
    const [activeSubscriptionPage, setActiveSubscriptionPage] = useState(1);
    const [pastSubscription, setPastSubscription] = useState([]);
    const [pastSubscriptionPage, setPastSubscriptionPage] = useState(1);
    const [futureSubscription, setFutureSubscription] = useState([]);
    const [futureSubscriptionPage, setFutureSubscriptionPage] = useState(1);
    const [unpaidSubscription, setUnpaidSubscription] = useState([]);
    const [unpaidSubscriptionPage, setUnpaidSubscriptionPage] = useState(1);
    const [expiredEstimateListPage, setExpiredEstimateListPage] = useState(1);
  const [docType, setDocType] = useState("");
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showReqestedTaskModal, setShowReqestedTaskModal] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [showDocumentCanvas, setShowDocumentCanvas] = useState(localStorage.getItem('clientTab') == 1 ? true : false);
  const [selectDocument, setSelectDocument] = useState("");
  const [documentCardDetails, setDocumentCardDetails] = useState();
  const [documentDetail, setDocumentDetail] = useState();
  const [subscriptionListPage, setSubscriptionListPage] = useState(1);
  const [selectedId, setSelectedId] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [searchString, setSearchString] = useState("");
  const savedDate = localStorage.getItem("selectedDate");
  const { selectedProject: projectId } = useSelector((state) => state.customer);
  const {
    taskFilters,
    globalSearchString
  } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const spaceClient = useSelector((state) => state.customer.spaceClient);
   const subscription_details = useSelector((state) => state.documentsReducer.subscription_details); 
   const pathname = window.location.pathname;
  useEffect(()=>{
    if(spaceClient && spaceClient.id && pathname == "/subscription")
    {
      subscriptionCardHandler(spaceClient);
      let data = [];
      dispatch(clientTabData(data));
    }
    if(subscription_details)
    {
      setDocumentDetail(subscription_details);
    }
  }, [spaceClient, subscription_details, pathname])
  setTimeout(() => {
    localStorage.setItem('clientTab', 0);
  }, 10000)
  useEffect(() => {
    if(pageName == 'estimate')
    {
      DocumentsServices.getEstimateTopics(pageName, 0).then((res) => {
        if(res.status == 1)
        {
          let data = res.data;
          dispatch({
            type: ESTIMATE_TOPICS,
            payload: {data}
          })
        }
      })
    }
  }, [pageName])
  useEffect(() => {
    if (globalSearchString == undefined || globalSearchString == "") {
      setActiveSubscription(
        subscription && subscription.filter(
          (inv) => (inv.status != null && inv.status === "active" ? inv.status : "")
          .toLowerCase()
        )
      );
      setPastSubscription(
        subscription && subscription.filter(
          (inv) => (inv.status != null && inv.status === "not_subscribed" ? inv.status : "")
          .toLowerCase()
        )
      );
      setFutureSubscription(
        subscription && subscription.filter(
          (inv) => (inv.status != null && inv.status === "future" ? inv.status : "")
          .toLowerCase()
        )
      );
      setUnpaidSubscription(
        subscription && subscription.filter(
          (inv) => (inv.status != null && inv.status === "canceled" ? inv.status : "")
          .toLowerCase()
        )
      );
    } else {
        setActiveSubscription(
            subscription &&
              subscription.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "active" ? est.status : "")
                    .toLowerCase()
              )
          );
          setPastSubscription(
            subscription &&
              subscription.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "not_subscribed" ? est.status : "")
                    .toLowerCase()
              )
          );
          setFutureSubscription(
            subscription &&
              subscription.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "future" ? est.status : "")
                    .toLowerCase()
              )
          );
          setUnpaidSubscription(
            subscription &&
              subscription.filter(
                (est) =>
                  (est.name.toLowerCase().includes(globalSearchString.toLowerCase()) ||
                  (est.date != null ? est.date : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase()) ||
                  (est.id != null ? est.id : "")
                    .toLowerCase()
                    .includes(globalSearchString.toLowerCase())) &&
                  (est.status != null && est.status == "canceled" ? est.status : "")
                    .toLowerCase()
              )
          );
    }
  }, [subscription, globalSearchString]);
  useEffect(() => {
        getSubscriptionList(clientid);
  }, [projectId, taskFilters, savedDate]);
  const getSubscriptionList = (clientid) => {
    DocumentsServices.getDocumentSubscriptions(projectId, taskFilters, clientid).then(
      (res) => {
        if (res.status == 1) {
          if(pageName == 'customer')
          {
            setSubscriptionList(res.data)
          } else {
            setSubscription(res.data);
          }
          let data = res.extra;
          dispatch({
            type: SUBSCRIPTION_COUNTER,
            payload: { data }
          })
        }
      }
    );
  };  
    const handleViewMore = (section) => {
        switch (section) {
          case "active":
            setActiveSubscriptionPage(activeSubscriptionPage + 1);
            break;
            case "not_subscribed":
              setPastSubscriptionPage(pastSubscriptionPage + 1);
              break;
              case "future":
                setFutureSubscriptionPage(futureSubscriptionPage + 1);
                break;
                case "canceled":
                  setUnpaidSubscriptionPage(unpaidSubscriptionPage + 1);
                  break;
                  case "expired":
                    setExpiredEstimateListPage(expiredEstimateListPage + 1);
                    break;
                    case "client_subscription":
                    setSubscriptionListPage(subscriptionListPage + 1);
                    break;
          default:
            break;
        }
      };
      const details = useSelector((state) => {
        switch (selectDocument) {
          case "invoice":
            return state.documentsReducer.invoice_details;
          case "subscription":
            return state.documentsReducer.subscription_details;
          case "estimate":
            return state.documentsReducer.estimate_details;
          case "credit_note":
            return state.documentsReducer.credit_note_details;
          case "document":
            return state.documentsReducer.document_details;
          case "spaceDocument":
            return state.customer.getFloorDocument;
          default:
            break;
        }
      });
      useEffect(() => {
        if (details) {
          setDocumentDetail(details);
        }
      }, [documentDetail, details]);
    const handlePinUnPin = (id, listName, flag = 1) => {
        let itemPosition = -1;
        let updateInvoice = [];
        switch (listName) {
          case "active":
            itemPosition = activeSubscription.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...activeSubscription,
              [itemPosition]: {
                ...activeSubscription[itemPosition],
                pinned: activeSubscription[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setActiveSubscription(updateInvoice);
            subscriptionCardHandler(updateInvoice[itemPosition], flag);
            break;
          case "not_subscribed":
            itemPosition = pastSubscription.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...pastSubscription,
              [itemPosition]: {
                ...pastSubscription[itemPosition],
                pinned: pastSubscription[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setPastSubscription(updateInvoice);
            subscriptionCardHandler(updateInvoice[itemPosition], flag);
            break;
          case "future":
            itemPosition = futureSubscription.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...futureSubscription,
              [itemPosition]: {
                ...futureSubscription[itemPosition],
                pinned: futureSubscription[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setFutureSubscription(updateInvoice);
            subscriptionCardHandler(updateInvoice[itemPosition], flag);
            break;
          case "canceled":
            itemPosition = unpaidSubscription.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...unpaidSubscription,
              [itemPosition]: {
                ...unpaidSubscription[itemPosition],
                pinned: unpaidSubscription[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setUnpaidSubscription(updateInvoice);
            subscriptionCardHandler(updateInvoice[itemPosition], flag);
            break;
          case "client_subscription":
            itemPosition = subscriptionList.findIndex((x) => x.id == id);
              updateInvoice = Object.values({
              ...subscriptionList,
              [itemPosition]: {
                ...subscriptionList[itemPosition],
                pinned: subscriptionList[itemPosition].pinned == 1 ? 0 : 1,
              },
            });
            setSubscriptionList(updateInvoice);
            subscriptionCardHandler(updateInvoice[itemPosition], flag);
            break;
        }
        if (itemPosition > -1) {
          let tempDocumentDetail = documentDetail;
          if (tempDocumentDetail && Object.keys(tempDocumentDetail).length) {
            tempDocumentDetail["pinned"] =
              tempDocumentDetail["pinned"] == 1 ? 0 : 1;
            setDocumentDetail(tempDocumentDetail);
          }
        }
      };
      const subscriptionCardHandler = async (subscription, flag = 1) => {
        setShowTaskModal(false);
        if (
          documentCardDetails &&
          documentCardDetails.type === "subscription" &&
          documentCardDetails.id == subscription.id
        ) {
          if (flag) {
            setShowDocumentCanvas(false);
            setDocumentCardDetails();
          }
        } else {
          try {
            dispatch(getSubscriptionDetail(projectId, subscription.id));
            setDocumentCardDetails({ ...subscription, type: "subscription" });
            setShowDocumentCanvas(true);
          } catch (e) {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l("l_something_went_wrong"))
            );
            console.error(e);
          }
        }
      };
    return (
        <>
            <>
              <div
                id="list_view"
                className={`comman-main-box custom-accordian-main res-padding-right0 h-100 task-list-accordian ${
                  showTaskModal || showReqestedTaskModal || showDocumentCanvas
                    ? "list-view-toggle"
                    : ""
                }`}
              >
                <Accordion
                  defaultActiveKey={["01","02","03","04", "05"]}
                  alwaysOpen
                  className="task-list-accordian-main"
                >
                  <div className="pill-wrapper">
                    <div id="" className="">
                      <div className="d-flex align-items-center filter-lists-menu min-width-190 justify-content-end">
                        <div className="badge  c-font f-12 color-white-80 bg-white-05  radius_3 me-2 d-none">
                          <div className="d-flex align-items-center">
                            <span className="color-white"></span>
                            <a href="#/" className="ps-1">
                              <XIcon className="HW14" />
                            </a>
                          </div>
                        </div>
                        {taskFilters.length ? (
                      taskFilters.map((value, index) => {
                        if (value.main_key == "date_range_filter") {
                          jquery("body #active_week_date").removeClass("c-gradinet-active-border");
                          let displayFilterKey = value.title;
                          return (
                            <div
                              key={index}
                              className="badge c-font f-12 w-auto color-white-80 bg-white-05  radius_3 ms-2 comman-round-box active py-1"
                            >
                              <div className="d-flex align-items-center">
                                <span className="color-white">
                                  {displayFilterKey}
                                </span>
                                <a href="#/"
                                  className="ps-1"
                                  onClick={() => {
                                    dispatch(
                                      leftPanelFilters({
                                        main_key: value.main_key,
                                        filter_key: value.filter_key,
                                        title: value.title,
                                      })
                                    );
                                  }}
                                >
                                  <XIcon className="HW14" />
                                </a>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                        <div className={`d-flex radius_3 justify-content-between commn-search ${pageName == 'customer' ? 'd-none' : ""}`}>
                          <div className="comment-input-box">
                            <input
                              type="text"
                              className="form-control bg-transparent with-base-bg border-0 py-1 px-2 f-11 c-font"
                              placeholder={`${_l("l_search_documents")}`}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              value={searchString}
                            />
                          </div>
                          <a href="#/" className="comman_action_icon">
                            <div className="d-flex align-items-center ">
                              <div className="action_icon h25w25 d-none">
                                <XIcon
                                  className="HW14 m-auto filter-img"
                                />
                              </div>
                              <div className="action_icon h25w25">
                                <MagnifyingGlassIcon
                                  className="HW16"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {pageName == 'customer' ? 
                  <Accordion.Item eventKey="01" className="with-pb-15 ">
                  <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                  <p className="mb-0"><br></br></p>
                  </div>
                  <Accordion.Body className="taskCountToggle">
                  {subscriptionList && subscriptionList.length ? (
                      subscriptionList
                      .slice(
                          0,
                          subscriptionListPage > 1
                          ? (subscriptionListPage - 1) * 10 + 3
                          : subscriptionListPage * 3
                      )
                      .map((subscription, index) => {
                          return (
                          <CardView
                              hideShow={() => {
                              subscriptionCardHandler(subscription);
                              setSelectedId(subscription.id);
                              setSelectDocument("subscription");
                              }}
                              data={{
                              ...subscription,
                              downloadUrl: subscription.invoie_url,
                              }}
                              docType={"subscription"}
                              setDocType={setDocType}
                              key={index}
                              selectedId={selectedId}
                              setSelectedId={setSelectedId}
                              pinHandler={(id) => {
                              handlePinUnPin(id, "client_subscription");
                              }}
                          />
                          );
                      })
                  ) : (
                      <></>
                  )}
                  {subscriptionList.length >=
                      (subscriptionListPage - 1) * 10 + 3 &&
                  subscriptionList.length !=
                      (subscriptionListPage - 1) * 10 + 3 ? (
                      <div className="d-flex justify-content-center">
                      <a href="#/"
                          className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                          onClick={() => {
                          handleViewMore("client_subscription");
                          }}
                      >
                          <span className="title-fonts d-flex justify-content-center align-items-center">
                          <div className="d-flex align-item-center justify-content-center">
                              {_l("l_view_more_btn")}(
                              {subscriptionListPage > 1
                              ? subscriptionList.length -
                                  ((subscriptionListPage - 1) * 10 + 3)
                              : subscriptionList.length - 3}
                              )
                              <CaretDoubleDownIcon
                              className="HW12 mx-2"
                              />
                              <span
                              role="status"
                              aria-hidden="true"
                              className="ms-2 opacity-0 spinner-border spinner-border-sm"
                              ></span>
                          </div>
                          </span>
                      </a>
                      </div>
                  ) : (
                      <></>
                  )}
                  {subscriptionList.length == 0 ? (
                      <CommanPlaceholder imgType="no-subscriptions" isAbsolute={true} placeholderText = {_l("l_no_subscriptions")} />
                  ) : (
                      <></>
                  )}
                  </Accordion.Body>
                </Accordion.Item>
                :
                <>
                <Accordion.Item eventKey="01" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active done accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 done rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_active")}
                            </span>
                            <span className="">
                            [{activeSubscription ? activeSubscription.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {activeSubscription && activeSubscription.length ? (
                        activeSubscription
                        .slice(
                            0,
                            activeSubscriptionPage > 1
                            ? (activeSubscriptionPage - 1) * 10 + 3
                            : activeSubscriptionPage * 3
                        )
                        .map((subscription, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                subscriptionCardHandler(subscription);
                                setSelectedId(subscription.id);
                                setSelectDocument("subscription");
                                }}
                                data={{
                                ...subscription,
                                downloadUrl: subscription.invoie_url,
                                }}
                                docType={"subscription"}
                                setDocType={setDocType}
                                key={index}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "active");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {activeSubscription.length >=
                        (activeSubscriptionPage - 1) * 10 + 3 &&
                    activeSubscription.length !=
                        (activeSubscriptionPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("active");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {activeSubscriptionPage > 1
                                ? activeSubscription.length -
                                    ((activeSubscriptionPage - 1) * 10 + 3)
                                : activeSubscription.length - 3}
                                )
                                <CaretDoubleDownIcon
                                className="HW12 mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {activeSubscription.length == 0 ? (
                        <CommanPlaceholder imgType="no-subscriptions" placeholderText = {_l("l_no_subscriptions")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="02" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active to-do accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 in-progress-white rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_not_subscribed")}
                            </span>
                            <span className="">
                            [{pastSubscription ? pastSubscription.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {pastSubscription && pastSubscription.length ? (
                        pastSubscription
                        .slice(
                            0,
                            pastSubscriptionPage > 1
                            ? (pastSubscriptionPage - 1) * 10 + 3
                            : pastSubscriptionPage * 3
                        )
                        .map((subscription, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                subscriptionCardHandler(subscription);
                                setSelectedId(subscription.id);
                                setSelectDocument("subscription");
                                }}
                                data={{
                                ...subscription,
                                downloadUrl: subscription.invoie_url,
                                }}
                                docType={"subscription"}
                                setDocType={setDocType}
                                key={index}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "not_subscribed");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {pastSubscription.length >=
                        (pastSubscriptionPage - 1) * 10 + 3 &&
                    pastSubscription.length !=
                        (pastSubscriptionPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("not_subscribed");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {pastSubscriptionPage > 1
                                ? pastSubscription.length -
                                    ((pastSubscriptionPage - 1) * 10 + 3)
                                : pastSubscription.length - 3}
                                )
                                <CaretDoubleDownIcon
                                className="HW12 mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {pastSubscription.length == 0 ? (
                        <CommanPlaceholder imgType="no-subscriptions" placeholderText = {_l("l_no_subscriptions")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="03" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active in-progress accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 in-progress-blue rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_future")}
                            </span>
                            <span className="">
                            [{futureSubscription ? futureSubscription.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {futureSubscription && futureSubscription.length ? (
                        futureSubscription
                        .slice(
                            0,
                            futureSubscriptionPage > 1
                            ? (futureSubscriptionPage - 1) * 10 + 3
                            : futureSubscriptionPage * 3
                        )
                        .map((subscription, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                subscriptionCardHandler(subscription);
                                setSelectedId(subscription.id);
                                setSelectDocument("subscription");
                                }}
                                data={{
                                ...subscription,
                                downloadUrl: subscription.invoie_url,
                                }}
                                docType={"subscription"}
                                setDocType={setDocType}
                                key={index}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "future");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {futureSubscription.length >=
                        (futureSubscriptionPage - 1) * 10 + 3 &&
                    futureSubscription.length !=
                        (futureSubscriptionPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("future");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {futureSubscriptionPage > 1
                                ? futureSubscription.length -
                                    ((futureSubscriptionPage - 1) * 10 + 3)
                                : futureSubscription.length - 3}
                                )
                                <CaretDoubleDownIcon
                                className="HW12 mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {futureSubscription.length == 0 ? (
                        <CommanPlaceholder imgType="no-subscriptions" placeholderText = {_l("l_no_subscriptions")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="04" className="with-pb-15 ">
                    <div className="accordian-header-box d-flex align-items-center justify-content-between border-bottom-color-border">
                    <Accordion.Header className="active red accordion-header">
                        <div className="left-task-header">
                        <div className="d-flex align-items-center fw-semibold title-fonts c-font f-13">
                            <span className="c-dots c-7 bg-danger rounded-circle me-2"></span>
                            <span className="task-title me-2">
                            {_l("l_canceled")}
                            </span>
                            <span className="">
                            [{unpaidSubscription ? unpaidSubscription.length : 0}]
                            </span>
                        </div>
                        </div>
                    </Accordion.Header>
                    </div>
                    <Accordion.Body className="taskCountToggle">
                    {unpaidSubscription && unpaidSubscription.length ? (
                        unpaidSubscription
                        .slice(
                            0,
                            unpaidSubscriptionPage > 1
                            ? (unpaidSubscriptionPage - 1) * 10 + 3
                            : unpaidSubscriptionPage * 3
                        )
                        .map((subscription, index) => {
                            return (
                            <CardView
                                hideShow={() => {
                                subscriptionCardHandler(subscription);
                                setSelectedId(subscription.id);
                                setSelectDocument("subscription");
                                }}
                                data={{
                                ...subscription,
                                downloadUrl: subscription.invoie_url,
                                }}
                                docType={"subscription"}
                                setDocType={setDocType}
                                key={index}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                pinHandler={(id) => {
                                handlePinUnPin(id, "canceled");
                                }}
                            />
                            );
                        })
                    ) : (
                        <></>
                    )}
                    {unpaidSubscription.length >=
                        (unpaidSubscriptionPage - 1) * 10 + 3 &&
                    unpaidSubscription.length !=
                        (unpaidSubscriptionPage - 1) * 10 + 3 ? (
                        <div className="d-flex justify-content-center">
                        <a href="#/"
                            className="btn c-font f-12 py-2  btn-white-03 w-100 mt-0 justify-content-center view-more-btn"
                            onClick={() => {
                            handleViewMore("canceled");
                            }}
                        >
                            <span className="title-fonts d-flex justify-content-center align-items-center">
                            <div className="d-flex align-item-center justify-content-center">
                                {_l("l_view_more_btn")}(
                                {unpaidSubscriptionPage > 1
                                ? unpaidSubscription.length -
                                    ((unpaidSubscriptionPage - 1) * 10 + 3)
                                : unpaidSubscription.length - 3}
                                )
                                <CaretDoubleDownIcon
                                className="HW12 mx-2"
                                />
                                <span
                                role="status"
                                aria-hidden="true"
                                className="ms-2 opacity-0 spinner-border spinner-border-sm"
                                ></span>
                            </div>
                            </span>
                        </a>
                        </div>
                    ) : (
                        <></>
                    )}
                    {unpaidSubscription.length == 0 ? (
                        <CommanPlaceholder imgType="no-subscriptions" placeholderText = {_l("l_no_subscriptions")} />
                    ) : (
                        <></>
                    )}
                    </Accordion.Body>
                  </Accordion.Item>
                </>}
                </Accordion>
                {showDocumentCanvas ? (
            <CommanOffCanvas
              show={showDocumentCanvas}
              handleClose={() => {
                setShowDocumentCanvas(false);
                setDocumentCardDetails();
                setDocumentDetail();
                setSelectedId(0);
              }}
              pinHandler={(id, type, flag) => {
                handlePinUnPin(id, type, flag);
              }}
              data={documentDetail}
              setData={setDocumentDetail}
              docType={'subscription'}
              setSelectedId={setSelectedId}
            />
          ) : (
            <></>
          )}
              </div>
          </>
          </>
    )
}
export default SubscriptionList;