import {
  SHOW_MESSAGE,
  SHOW_CONFIRMATION,
  DISMISS_MESSAGE,
  SHOW_FULL_SCREEN_LOADER,
  HIDE_FULL_SCREEN_LOADER,
  SHOW_IDB_DATA_FETCHING_LOADER,
} from "./../actions/action-type";

const initialState = {
  showToast: false,
  className: "",
  header: "",
  message: "",
  cancelButtonText: "",
  confirmButtonText: "",
  toastCloseHandler: () => {},
  cancelButtonHandler: () => {},
  confirmButtonHandler: () => {},
  idbDataLoader: false,
  fullScreenLoader: false,
  closeOnlyToast: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_MESSAGE:
      return { ...state, showToast: true, ...payload };
      case SHOW_CONFIRMATION:
      return { ...state, showToast: true, ...payload };
    case DISMISS_MESSAGE:
      return { ...initialState };
    case SHOW_FULL_SCREEN_LOADER:
      return { ...state, fullScreenLoader: true };
    case HIDE_FULL_SCREEN_LOADER:
      return { ...state, fullScreenLoader: false };
    case SHOW_IDB_DATA_FETCHING_LOADER:
      return { ...state, idbDataLoader: payload };
    default:
      return state;
  }
}
