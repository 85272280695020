import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { _l } from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import { showConfirmation, showMessage, toastCloseAction } from "../../actions/messages";
import leaseServices from "../../services/lease-services";
import LeaseCard from "../Documents/tabs/LeaseCard";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import LeaseModal from "./LeaseModal";

const AddSubContractModal = ({ show, handleClose, spaceDetail, setSpaceDetail}) => {
    const [subContractData, setsubContractData] = useState([]);
    const [showLeaseDetailModal, setShowLeaseDetailModal] = useState(false);
    const [leaseDetails, setleaseDetails] = useState({});
    const [documents, setDocuments] = useState([]);
    const [subLeaseId, setSubLeaseId] = useState(0);
    const [selectedFloorIds, setSelectedFloorIds] = useState([]);


    const dispatch = useDispatch();
    
    const cancelLeaseContracts = (lease) => {
        dispatch(showConfirmation(_l("l_are_you_sure"), _l("l_cancel_lease_contract"), _l("l_yes"), _l("l_no"), () => {}, async() => {
          try {
            dispatch(toastCloseAction());
            const response = await leaseServices.cancelLeaseContract(spaceDetail.project_id, lease.lease_id, lease.id);
            if(response.status) {
                setsubContractData(
                    subContractData.map((w) => {
                        if (w.id == lease.id) {
                        w.status = "0";
                        }
                        return w;
                    })
                );
                dispatch(showMessage("sucess", _l("l_success"), _l("l_subscription_cancelled")))
            }else{
              showMessage(response.message);
            }
          }
          catch(e)
          {
            console.error(e);
          }
        }))
      }

    useEffect(() => {
        try {
            if (spaceDetail) {
                leaseServices.getProjectSubLease(spaceDetail.project_id, spaceDetail.lease_id).then((res) => {
                    if (res && res.status) {
                        res.data && res.data.map((sub) => {
                            let floorData = [];
                            sub.lease_area.map((element, i) => {
                                floorData.push({floor : `Floor ${i + 1}`})
                            });
                            sub.floorName = floorData
                        })
                        setsubContractData(res.data)
                    }
                })
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }, [JSON.stringify(spaceDetail)]);
    
    const getLeaseDeatils = (item) => {
      let floorids = item.lease_area.map((floor) => {
        return floor.floor_id
      })
        setDocuments(item.documents && item.documents.length > 0 ? item.documents : []);
        setleaseDetails(item);
        setSubLeaseId(item.id)
        setSelectedFloorIds(floorids)
        setShowLeaseDetailModal(true)
    }
    
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_sub_contract")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="form-wrapper-main">
            <div className="row m-0 ">
              {subContractData && subContractData.length > 0  ?
                subContractData.map((item, index) => {
                    let spaceLease = {office_title : `# ${item.lease_id} - ${item.id} (${item.floorName.map((item) => item.floor)})`};
                    return(
                        <LeaseCard
                          key={index}
                          leaseItem = {item}
                          space = {spaceLease}
                          openDetailLease = {() => {
                            getLeaseDeatils(item)
                          }}
                          actionItems={
                            item.status == 0 
                              ? [] 
                              : [
                                  {
                                    actionName:
                                      _l("l_delete"),
                                    actionHandler:
                                      () => {
                                        cancelLeaseContracts(item);
                                      },
                                    actionIcon:
                                      "TrashIcon",
                                  },
                                ]
                          }
                         />
                         )
                }) : 
                <CommanPlaceholder
                    mainSpacing="bgOfficelisting p-0"
                    imgWidth="big-image h95 d-none"
                    isImageOpacity="opacity-50"
                    placeholderTextClass="c-font f-16 pt-0 opacity30"
                    imgType="no-benchmark"
                    placeholderText={_l(
                    `l_no_leasedocument`
                )}
              />            
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          
        </Modal.Footer>
      </Modal>
      {
      showLeaseDetailModal ?
        <LeaseModal
        show={showLeaseDetailModal}
        handleClose={()=>{
            setShowLeaseDetailModal(false);
        }}
        spaceDetail={spaceDetail}
        isFrom={"floor-list"}
        leaseDetails={leaseDetails}
        detailsdocuments={documents}
        lease_sub_id={subLeaseId}
        selectedFloorIds={selectedFloorIds}
        setSelectedFloorIds={setSelectedFloorIds}
        setSpaceDetail={setSpaceDetail}
        /> : <></>
    }
    </>
  );
};
export default AddSubContractModal;
