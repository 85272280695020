import React, { useEffect } from "react";
import { leftPanelMouseUpEvent } from "../hooks/utilities";
import ConnectProvider from "../components/ExploreFeatures/ConnectProvider/ConnectProvider";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import { useDispatch } from "react-redux";
import { ScheduleProvider } from "../context/ScheduleContext";
import { setListOfIBANAccounts } from "../actions/customer";
import userpaymnetServices from "../services/userpaymnet-services";
const ConnectProviderView = () => {
  let spaces = localStorage.getItem("spaces");
  spaces = spaces ? JSON.parse(localStorage.getItem("spaces")) : [];
  const dispatch = useDispatch();
  useEffect(() => {
    document.addEventListener("mouseup", leftPanelMouseUpEvent);
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );
    userpaymnetServices.getIBANlisting().then((response) => {
      if (response && response.status) {
        dispatch(setListOfIBANAccounts(response.data));
      }
    })
  }, []);
  return (
    <React.Fragment>
      <ScheduleProvider >
        <ConnectProvider />
      </ScheduleProvider>
    </React.Fragment>
  );
};
export default ConnectProviderView;
