import React from 'react'
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import Select from "react-select";
function AddOperatorStepOne({ firstName, setFirstName, lastName, employeeRoleListing, setEmployeeRole, employeeRole, setLastName, email, setEmail, password, setPassword, countryCodes, countryCode, setCountryCode, mobileNumber, setMobileNumber, allregionData, region, setRegion, typeJob, setTypeJob, categoryListing, confirmPassword, setConfirmPassword,employeePosition, setEmployeePosition }) {
    return (
        <>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label no-asterisk">
                    {_l("l_first_name")}
                </Form.Label>
                <Form.Control
                    placeholder={`${_l("l_first_name_placeholder")}`}
                    type="text"
                    name=""
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                />

            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative ">
                <Form.Label className="input-label no-asterisk">
                    {_l("l_last_name")}
                </Form.Label>
                <Form.Control
                    placeholder={`${_l("l_last_name_placeholder")}`}
                    type="text"
                    name=""
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                />

            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                    {_l("l_email")}
                </Form.Label>
                <Form.Control
                    placeholder={`${_l("l_email_placeholder")}`}
                    value={email}
                    type="email"
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                    {_l('l_password')}
                </Form.Label>
                <Form.Control
                    placeholder={_l('l_password_placeholder')}
                    type="password"
                    autocomplete="new-password"
                    name=""
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />

            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk">
                    {_l('l_confirm_password')}
                </Form.Label>
                <Form.Control
                    placeholder={_l('l_password_placeholder')}
                    type="password"
                    autocomplete="new-password"
                    name=""
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                    }}
                />

            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l('l_mobile_number')}
                </Form.Label>
                <div className="row">
                    <div
                        className="c_input_border drop_image col-3 col-md-3  col-xl-3 position-relative"
                    >
                        <Select
                            className="custom-select-menu"
                            classNamePrefix="react-select"
                            placeholder={`${_l('l_country_code_placeholder')}`}
                            options={countryCodes}
                            closeMenuOnSelect={true}
                            value={Object.keys(countryCode).length > 0 ? countryCode : setCountryCode({
                                value: '+33', label: '+33'
                            })}
                            onChange={(value) => { setCountryCode(value) }}
                        />
                    </div>
                    <div className="col-9 col-md-9 col-xl-9 ps-md-0">
                        <Form.Control
                            placeholder={_l('l_mobile_number_placeholder')}
                            type="Number"
                            name="phonenumber"
                            value={mobileNumber}
                            onChange={(e) => {
                                setMobileNumber(e.target.value);
                            }}
                        />

                    </div>
                </div>
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                {_l('l_position')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                <Form.Control
                    placeholder={_l('l_emoployee_position')}
                    type="text"
                    autoComplete="new-password"
                    name=""
                    value={employeePosition}
                    onChange={(e) => {
                    setEmployeePosition(e.target.value);
                    }}
                />
                </div>
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative">
                <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l('l_assign_role')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                    <Select
                        className="custom-select-menu w-100"
                        classNamePrefix="react-select"
                        placeholder={_l('l_select_role')}
                        options={employeeRoleListing}
                        closeMenuOnSelect={true}
                        value={employeeRole}
                        onChange={(value) => {
                            setEmployeeRole(value);
                        }}
                    />
                </div>
            </Form.Group>
            <Form.Group className="col-xl-6 c-input-box pb-4 position-relative d-none">
                <Form.Label className="input-label no-asterisk no-asterisk">
                    {_l('l_region_label')}
                </Form.Label>
                <div className="d-flex flex-grow-1 align-items-center">
                    <Select
                        placeholder={_l('l_select_region')}
                        className="custom-select-menu w-100"
                        classNamePrefix="react-select"
                        options={allregionData}
                        closeMenuOnSelect={true}
                        value={region}
                        isMulti
                    />
                </div>
            </Form.Group>
            <Form.Group className="col-xl-12 c-input-box pb-4 position-relative d-none">
                <Form.Label className="input-label no-asterisk">
                    {_l('l_type_of_job')}
                </Form.Label>
                <Select
                    className="custom-select-menu"
                    classNamePrefix="react-select"
                    placeholder={_l('l_type_of_job_placeholder')}
                    options={categoryListing}
                    closeMenuOnSelect={true}
                />
            </Form.Group>
        </>
    )
}

export default AddOperatorStepOne