
import apiService from "./api-service/api-service";

const API_URL = process.env.REACT_APP_API_URL;

const user_auth = async(code, provider,project_id) => {
    
    const endPoint = API_URL + "mod=slack_api&act=tokens";
    let form = new FormData();
    form.append("code", code);
    form.append("provider", provider);
    form.append("project_id", project_id);
    
    return apiService(endPoint, "POST", true, form);
  };


const revoke = async(project_id) => {
    
    const endPoint = API_URL + "mod=slack_api&act=revoke";
    let form = new FormData();
    form.append("project_id", project_id);
    
    return apiService(endPoint, "POST", true, form);
  }; 

  const slack_tasks = async( channel_id, project_id) => {
    
    const endPoint = API_URL + "mod=slack_api&act=get_channel_tasks";
    let form = new FormData();
    form.append("channel_id", channel_id);
    form.append("project_id", project_id);
    return apiService(endPoint, "POST", true, form);
  };
  
  const post_message_to_slack = async( task_id, status, project_id ) => {
    
    const endPoint = API_URL + "mod=slack_modal&act=post_notification_to_slack";
    let form = new FormData();
    form.append("task_id", task_id);
    form.append("status", status);
    form.append("project_id", project_id);
    return apiService(endPoint, "POST", true, form);
  };

  const is_slack_connected = async(project_id) => {
    
    const endPoint = API_URL + "mod=slack_api&act=is_slack_connected";
    let form = new FormData();
    form.append("project_id", project_id);
    
    return apiService(endPoint, "POST", true, form);
  };

  const change_topic_status_slack = async(project_id, filter_key, active_or_inactive) => {
    
    const endPoint = API_URL + "mod=slack_api&act=change_topic_status_slack";
    let form = new FormData();
    form.append("project_id", project_id);
    form.append("filter_key", filter_key);
    form.append("active", active_or_inactive);
    
    return apiService(endPoint, "POST", true, form);
  };
// const get_channels = async(rel_id, rel_type, provider,project_id) => {
    
//     const endPoint = API_URL + "mod=slack_api&act=getchannels";
    
//     let form = new FormData();
//     form.append("rel_id", rel_id);
//     form.append("rel_type", rel_type);
//     form.append("provider", 'slack');
//     form.append("project_id", project_id);
    
//     return apiService(endPoint, "POST", true, form);
//   }; 

  export default {user_auth, revoke, slack_tasks,post_message_to_slack, is_slack_connected, change_topic_status_slack};