import React, { useState } from "react";
import CreditNoteBasicdetails from "../../tabs/CreditNoteBasicdetails";
import Paytm from "../../tabs/Paytm";
import Reminders from "../../tabs/Reminders";
import { _l } from "../../../../hooks/utilities";

const CreditNote = ({ data }) => {
    const [selectedTab, setSelectedTab] = useState("basicdetails");
    
    
    return (
       <>
            <div className="tab-wrapper_main pb-3 res-overflow-auto">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                    <div className="comman-tab-wrapper pb-0">
                        <div className="d-flex align-items-center">
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center for-accessible ${selectedTab === "basicdetails" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("basicdetails");
                                    
                                }}
                                >
                                {_l("l_basic_details")}
                                </a>
                            </div>
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center for-accessible ${selectedTab === "items" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("items");
                                    
                                }}
                                >
                                {_l("l_invoices_credited")}
                                </a>
                            </div>
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center for-accessible ${selectedTab === "refunds" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("refunds");
                                    
                                }}
                                >
                                {_l("l_refunds")}
                                </a>
                            </div>
                            <div className={`tab-wrapper task-detail-tab d-flex align-items-center for-accessible ${selectedTab === "reminders" ? "active" : ""
                                }`}>
                                <a href="#/" className="tab-name"
                                onClick={() => {
                                    setSelectedTab("reminders");
                                    
                                }}
                                >
                                {_l("l_reminders")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className="task-header-right comman-tab-wrapper p-0"
                    >
                        <div className={`d-flex align-items-center tab-wrapper justify-content-end ${selectedTab === "paytm" ? "active" : ""
                            }`}>
                            <a href="#/" className="tab-name py-2"
                                onClick={() => {
                                    setSelectedTab("paytm");

                                }}>
                                <button type="button" className="btn btn-primary btn-sm">{_l('l_pay_now')}</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hiiden-auto comman_vertical_btn_h px-2">
            {selectedTab === "basicdetails" ? (
                    <CreditNoteBasicdetails data={data} />
                    ) : selectedTab === "items" ? (
                    <></>
                    ) : selectedTab === "refunds" ? (
                    <></>
                    ) : selectedTab === "reminders" ? (
                    <Reminders />
                    ) : (
                    <Paytm />
                )}
            </div>
        </>
    );
};
export default CreditNote;