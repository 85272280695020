import React from 'react';
 

function ShareScreen(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill={''} viewBox="0 0 256 256" className={`comman-icons ${CommanHW}`}><path d="M208,40H48A24,24,0,0,0,24,64V176a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V64A24,24,0,0,0,208,40Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Zm-48,48a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224ZM157.66,106.34a8,8,0,0,1-11.32,11.32L136,107.31V152a8,8,0,0,1-16,0V107.31l-10.34,10.35a8,8,0,0,1-11.32-11.32l24-24a8,8,0,0,1,11.32,0Z"></path></svg>
		</React.Fragment>
	)
}


export default ShareScreen