
import discordServices from "../services/discord-services";
import { CHECK_DISCORD_CONNECTIVITY, DISCORD_TOPICS } from "./action-type";
export const is_discord_connected = (project_id) => (dispatch) => {
    discordServices
        .is_discord_connected(project_id)
        .then((data) => {
            dispatch({
                type: CHECK_DISCORD_CONNECTIVITY,
                payload: data
            });
        });
}

export const discord_topics = (data) => (dispatch) => {
    dispatch({
        type: DISCORD_TOPICS,
        payload: data
    });
}


