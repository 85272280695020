import React, { useEffect, useState } from "react";
import { _l } from "../../../../hooks/utilities";
import LeadCommanCard from "./LeadCommanCard";
import CommanPlaceholder from "../../../Placeholder/CommanPlaceholder";
import customerServices from "../../../../services/customer-services";
import { useDispatch } from "react-redux";
import { setLeadsPrimaryKey } from "../../../../actions/leads-actions/leads-actions";
const Leadcontact = ({ data }) => {
  const [leadContactData, setLeadContactData] = useState(data);
  const [openAccordion, setOpenAccordion] = useState('0');
  
  const dispatch = useDispatch();

  const setPrimaryContact = (id) => {
    customerServices.setPrimaryLeadContact(id).then((res) => {
      if (res.status) {
        dispatch(setLeadsPrimaryKey(id, 'lead_contact'))
      }
    })
  }
  useEffect(() => {
    setLeadContactData(data);
  }, [JSON.stringify(data)]);
  const handleAccordionToggle = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <div>
      <div className="p-10 py-0">
        <div className=" d-flex flex-column">
          <div className="comman-content-scroll-wrapper">
            <div className="comman-content-scroll">
              <div className="comman-data-table small-data-table with-horizontal-scroll">
                <div class="custom-accordian-main d-flex flex-column flex-grow-1">
                  
                    {leadContactData && leadContactData.length > 0 ?
                    <>
                    {
                      leadContactData.map((contact, index)=>{
                        return(<>
                         <LeadCommanCard  data={contact} type={'contactLeads'} handleAccordionToggle={handleAccordionToggle} index={index} openAccordion={openAccordion} setPrimaryContact={setPrimaryContact}/>
                        </>)
                      })

                    }
                    </> 
                    :<>
                    <CommanPlaceholder imgType="appointme" mainWidth="m-auto" placeholderText = {_l("l_no_contact")} />
                    </>}
            
            
              </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
       
    </div>
  );
};
export default Leadcontact;
