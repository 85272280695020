import {
  GET_BENCHMARK_SLOTS,
  ASSIGN_SLOT_TO_OPERATOR,
  GET_CHECKLIST_TEMPLATES,
  GET_CREATE_SCHEDULE_DETAILS,
  GET_SPACE_ROOMS,
  ADD_SPACE_ROOM,
  UPDATE_KPI_COUNTS,
  CREATE_SCHEDULE,
  GET_SPACE_ROOMS_ITEM,
  REMOVE_SPACE_ROOM,
  GET_PROPOSAL_COMMENT,
  UPDATE_TYPE_OF_SPACE,
  GET_FLOOR_DETAILS,
  UPDATE_TYPE_OF_SPACE_LIST,
  SET_FLOOR_FILTER_ARRAY,
  SELECT_FLOOR_FOR_SCHEDULE,
  SET_FLOOR_PLANNING,
  UPDATE_FLOOR_SETTING,
  ADD_CHECKLIST_ITEM_TO_COPY,
  REMOVE_CHECKLIST_ITEM_FROM_ARRAY,
  CLEAR_CHECKLIST_CLIPBOARD,
  GET_GLOBAL_DOC_TYPE,
  UPDATE_SPACE_COEFFICIENT,
  SET_SELECTED_SUB_FLOOR_IDS,
  SET_SELECTED_FLOOR_IDS
} from "../../actions/benchmark-actions/benchmark-action-type";

const initialState = {
  benchmarks: [],
  checklistTemplates: [],
  templateProviderId: 0,
  spaceRooms: [],
  scheduleDetail: {},
  spaceRoomsItem: [],
  floorDetails: {type_of_space: [], floor: [], all_type_of_space: [], type_of_space_items: []},
  filteredFloorIds: [],
  selectedFloorIds: [],
  checklistClipboard: [],
  floorIds : [],
  subFloorIds : [],

};

export default function (state = initialState, action) {
  const { type, payload } = action;
  let currentTypeOfSpaceList = state.floorDetails.type_of_space.slice();
  let currentFloorList = state.floorDetails.floor.slice();

  switch (type) {
    case GET_BENCHMARK_SLOTS:
      return { ...state, benchmarks: payload.data };
    case ASSIGN_SLOT_TO_OPERATOR:
      const { schedule_id, templates, staff } = payload;
      const assignee = {
        staffid: staff.filter_key,
        email: "",
        mobile: "",
        profile_url: staff.image,
        full_name: staff.title,
      };
      let benchmarks = state.benchmarks;
      benchmarks.forEach((benchmark) => {
        if (benchmark.benchmark_id == schedule_id) {
          templates.forEach((template) => {
            const slotIndex = benchmark.slots.findIndex(
              (x) => x.slot_id == template
            );
            if (benchmark.slots[slotIndex].assignees) {
              const assigneeIndex = benchmark.slots[
                slotIndex
              ].assignees.findIndex((w) => w.staffid == staff.filter_key);
              if (assigneeIndex === -1) {
                benchmark.slots[slotIndex].assignees.push(assignee);
              } else {
                benchmark.slots[slotIndex].assignees.splice(assigneeIndex, 1);
              }
            } else {
              benchmark.slots[slotIndex]["assignees"] = [assignee];
            }
          });
        }
      });
      return { ...state, benchmarks };
    case GET_CHECKLIST_TEMPLATES:
      let benchmarkTemplates = state.checklistTemplates ? state.checklistTemplates : [];
      if (payload.updateChecklist.length > 0) {
        benchmarkTemplates = benchmarkTemplates.concat(payload.updateChecklist)
      }
      return {
        ...state,
        checklistTemplates: payload.updateChecklist.length ? benchmarkTemplates : payload.data,
        templateProviderId: payload.providerId,
      };
    case GET_CREATE_SCHEDULE_DETAILS:
      let scheduleDetail = state.createScheduleDetail ? state.createScheduleDetail : {};

      if (payload.changeDatatype != '') {
        scheduleDetail[`${payload.changeDatatype}`] = payload.data
      } else if (payload.isEdit == 1 && payload.key != '') {
        scheduleDetail[`${payload.key}`] = payload.data
      } else {
        scheduleDetail = payload.data
      } return {
        ...state,
        createScheduleDetail: scheduleDetail,
      };
    case GET_SPACE_ROOMS:
      return { ...state, spaceRooms: payload.data };
    case ADD_SPACE_ROOM:
      let currentTypeOfSpaces = payload.data;
      let currentSchedule = payload.schedule_id;
      currentTypeOfSpaces.forEach((w) => {
        const pos = currentTypeOfSpaces.findIndex(x => x.item_id == w.id && x.rel_type == "schedule" && x.rel_id == currentSchedule);
        w['checked'] = pos != -1;
      });
      return { ...state, spaceRooms: currentTypeOfSpaces };

    case UPDATE_TYPE_OF_SPACE:
      let typeOfSpaces = state.spaceRooms;
      const {title, quantity, id, item_id} = payload;
      if(typeOfSpaces.length)
      {
        typeOfSpaces.forEach((space) => {
          if(space.id == id && space.item_id == item_id)
          {
            space.title = title;
            space.quantity = quantity;
          }
        })
      }
        return { ...state, spaceRooms: typeOfSpaces };

    case UPDATE_KPI_COUNTS:
      const counts = payload.data;
      let allTypeOfSpace = state.floorDetails.type_of_space;
      Object.keys(counts).forEach((e) => {
        const pos = allTypeOfSpace.findIndex(x => x.id == e);
        if (allTypeOfSpace[pos] && !isNaN(counts[e])) {
          allTypeOfSpace[pos].count = counts[e];
        }

      });
      return { ...state, floorDetails: {...state.floorDetails, typeOfSpaces: allTypeOfSpace} }

    case CREATE_SCHEDULE:
      return { ...state, scheduleDetail: payload.data }
    case GET_SPACE_ROOMS_ITEM:
      return { ...state, spaceRoomsItem: payload.data }
    case REMOVE_SPACE_ROOM:
      const room = payload.data;
      let newRoomList = state.spaceRooms.filter((x) => {
        if (room.id != 0 && x.id != room.id) {
          return x;
        }
        else if (x.index != room.index) {
          return x;
        }
      })
      return { ...state, spaceRooms: newRoomList }

    case GET_PROPOSAL_COMMENT:
      return { ...state, proposalComments: payload.data }

    case GET_FLOOR_DETAILS:
      return { ...state, floorDetails: {type_of_space: payload.data.type_of_space, floor: payload.data.floor, all_type_of_space: payload.data.all_type_of_space, type_of_space_items: payload.data.type_of_space.flatMap((w) => w.details)} }

    case UPDATE_TYPE_OF_SPACE_LIST:
      const {spaceIds, checked} = payload;

      currentTypeOfSpaceList.forEach((space) => {
        if(spaceIds.includes(space.id))
        {
          space.checked = checked;
        }
      });
      return { ...state, floorDetails: {...state.floorDetails, type_of_space: currentTypeOfSpaceList, type_of_space_items: currentTypeOfSpaceList.flatMap((w) => w.details)} }

    case SET_FLOOR_FILTER_ARRAY:
      let filterIndices = payload.keys
      currentTypeOfSpaceList.forEach((space) => {
        if(space.is_default == 0)
        {
          let matchedCounts = 0;
          if(filterIndices.length)
          {
            if(space.checked)
            {
              let floorsOfSpaceItems = [];
              space.details.forEach((item) => {
                if(!floorsOfSpaceItems.includes(item.floor_id))
                {
                  floorsOfSpaceItems.push(item.floor_id);
                }
              });
              floorsOfSpaceItems.forEach((id) => {
                if(filterIndices.includes(id))
                {
                  matchedCounts++;
                }
              })
            }
            if(matchedCounts === 0)
            {
              space.filtered = true;
            }
            else {
              space.filtered = false;
            }
          }
          else {
            space.filtered = false;
          }
        }
      });
      return { ...state, filteredFloorIds: filterIndices}

    case SELECT_FLOOR_FOR_SCHEDULE:
      let selectedIndices = payload.keys;
      currentTypeOfSpaceList.forEach((space) => {
        if(space.is_default == 0)
        {
          let matchedCounts = 0;
          if(selectedIndices.length)
          {
            if(space.checked)
            {
              let floorsOfSpaceItems = [];
              space.details.forEach((item) => {
                if(!floorsOfSpaceItems.includes(item.floor_id))
                {
                  floorsOfSpaceItems.push(item.floor_id);
                }
              });
              floorsOfSpaceItems.forEach((id) => {
                if(selectedIndices.includes(id))
                {
                  matchedCounts++;
                }
              })
            }
            if(matchedCounts === 0)
            {
              space.checked = false;
            }
            else {
              space.checked = true;
            }
          }
          else {
            space.checked = true;
          }
        }
      });
      return { ...state, selectedFloorIds: selectedIndices}

    case SET_FLOOR_PLANNING:
      return {...state, floorDetails: {...state.floorDetails, type_of_space: payload.type_of_spaces, type_of_space_items: payload.type_of_spaces.flatMap((w) => w.details),  floor: payload.floors}}

    case UPDATE_FLOOR_SETTING:
      const {floor, type_of_spaces} = payload;
      let targetFloorIndex = currentFloorList.findIndex((x) => x.id == floor.id);
      currentFloorList[targetFloorIndex] = {...currentFloorList[targetFloorIndex], ...floor};
      return {...state, floorDetails: {...state.floorDetails, type_of_space: type_of_spaces, type_of_space_items: type_of_spaces.flatMap((w) => w.details), floor: currentFloorList}}

    case ADD_CHECKLIST_ITEM_TO_COPY:
      let {checklistItem} = payload;
      if(state.checklistClipboard.findIndex((w) => w.description && checklistItem.description && w.description.trim() == checklistItem.description.trim() && w.floor_id == checklistItem.floor_id && w.type_of_space_item_id == checklistItem.type_of_space_item_id && w.room_id == checklistItem.room_id) === -1)
      {
        return {...state, checklistClipboard: state.checklistClipboard.concat([checklistItem])};
      }
      return state;
    
    case REMOVE_CHECKLIST_ITEM_FROM_ARRAY:
      return {...state, checklistClipboard: state.checklistClipboard.filter((w) => w.description && payload.checklistItem.description && w.description.trim() !== payload.checklistItem.description.trim() && w.floor_id !== payload.checklistItem.floor_id && w.type_of_space_item_id == payload.checklistItem.type_of_space_item_id && w.room_id == payload.checklistItem.room_id)}

    case CLEAR_CHECKLIST_CLIPBOARD:
      return {...state, checklistClipboard: []}

    case GET_GLOBAL_DOC_TYPE:
      return {...state, global_doc_type :payload}

    case UPDATE_SPACE_COEFFICIENT:
      return {...state, floor : {...state.floorDetails, type_of_spaces: state.floorDetails.type_of_space.map((space) => {
        if(space.id === payload.spaceId)
        {
          space.coeffcient_value = payload.coEfficient;
        }
        return space;
      })}}
      
      case SET_SELECTED_FLOOR_IDS:
    return{
      ...state,
      floorIds : payload
    }
    case SET_SELECTED_SUB_FLOOR_IDS:
    return{
      ...state,
      subFloorIds : payload
    }
    default:
      return state;
  }
};
