import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import EyeSlashIcon from "../../assets/icons/EyeSlashIcon";
import EyeIcon from "../../assets/icons/EyeIcon";
import authServices from "../../services/auth-services";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { _l } from "../../hooks/utilities";
import Spinner from "react-bootstrap/Spinner";

const SetUserPassword = ({ handleClose}) => {
  const dispatch = useDispatch();
  const [loder, setLoder] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const userType = localStorage.getItem("user_type");
  const userId = localStorage.getItem("userId");

  const handleSetPassword = () => {
    if (password == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_password')))
    } else if (confirmPassword == "") {
      dispatch(showMessage("unsucess", _l("l_error"), _l('l_please_enter_confirm_password')))
    } else if (confirmPassword != password) {
      dispatch(showMessage("unsucess", _l("l_error"), _l("l_password_and_confirm_password_doesnot_match")));
    } else {
      setLoder(true);
      authServices.setUserPassword(userId, userType, password).then(
        (response) => {
          setLoder(false);
          if (response.status == 1) {
            dispatch(
              showMessage("sucess", _l("l_success"), _l(response.message))
            );
            localStorage.removeItem('is_registration_confirmed');
            handleClose();
          } else {
            dispatch(
              showMessage("unsucess", _l("l_error"), _l(response.message))
            );
          }
        }
      );
    }
  }
  return (
    <>
      <div className="">
        <Modal
          show={true}
          onHide={handleClose}
          className="custom-modal-style"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{_l("l_set_password")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className=" pt-0">
            <div className="form-wrapper-main">
              <div className="row">
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_password")}
                    <span className="ms-1 star_img">*</span>
                  </Form.Label>
                  <div
                    className={` ${passwordShown ? "active" : ""} `}
                    id="password_class"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        className="input_box"
                        placeholder="*****"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <a href="#/" className="">
                        <div
                          className={`img-width-18 ${passwordShown ? "" : "d-none"}`}
                          onClick={() => {
                            setPasswordShown((pre) => !passwordShown);
                          }}
                        >
                          <EyeSlashIcon className="HW18" />
                        </div>
                        <div
                          className={`img-width-18 ${passwordShown ? "d-none" : ""}`}
                          onClick={() => {
                            setPasswordShown((pre) => !passwordShown);
                          }}
                        >
                          <EyeIcon className="HW18" />
                        </div>
                      </a>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="col-xl-12 c-input-box pb-3 position-relative">
                  <Form.Label className="input-label no-asterisk ">
                    {_l("l_confirm_password")}
                    <span className="ms-1 star_img">*</span>
                  </Form.Label>
                  <div
                    className={` ${confirmPasswordShown ? "active" : ""} `}
                    id="password_class"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Control
                        type={confirmPasswordShown ? "text" : "password"}
                        placeholder="*****"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSetPassword();
                          }
                        }}
                      />
                      <a href="#/" className="">
                        <div
                          className={`img-width-18 ${confirmPasswordShown ? "" : "d-none"}`}
                          onClick={() => {
                            setConfirmPasswordShown((pre) => !confirmPasswordShown);
                          }}
                        >
                          <EyeSlashIcon className="HW18" />
                        </div>
                        <div
                          className={`img-width-18 ${confirmPasswordShown ? "d-none" : ""}`}
                          onClick={() => {
                            setConfirmPasswordShown((pre) => !confirmPasswordShown);
                          }}
                        >
                          <EyeIcon className="HW18" />
                        </div>
                      </a>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <Button variant="secondary" size="sm" onClick={handleClose}>
              {_l("l_close")}
            </Button>
            <Button variant="primary" className={`${loder ? 'for-disabled' : ""}`} size="sm"
              onClick={() => {
                handleSetPassword()
              }}
            >
              {loder ? _l('l_please_wait') : _l('l_set_password')}
              {loder ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  className={`ms-2 `}
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SetUserPassword;
