import React, { useEffect } from "react";
import { leftPanelMouseUpEvent } from "../hooks/utilities";
import { setOnboardingStepData } from "../actions/onboarding-actions/onboarding-actions";
import { useDispatch } from "react-redux";
import EquipmentMainScreen from "../components/ExploreFeatures/Equipments/EquipmentMainScreen";

const ConnectProviderView = () => {


  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("mouseup", leftPanelMouseUpEvent);
    dispatch(
      setOnboardingStepData({
        step: "space-onboarding",
      })
    );

    return () => {
      document.removeEventListener("mouseup", leftPanelMouseUpEvent);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex flex-column h-100">
        <EquipmentMainScreen/>
      </div>
    </React.Fragment>
  );
};

export default ConnectProviderView;
