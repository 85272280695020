import React from "react";
import { _l } from "../../hooks/utilities";
import TopicSettingModal from "./TopicSettingModal";

const PaymentSetupModal = ({
  show,
  handleClose,
  autoSubScribe,
  titleSuffix,
  categoryId,
  regionId,
  walletId,
  data
}) => {
  const title = `${_l("l_payment")} ${_l("l_setup")} ${
    titleSuffix ? " - " + titleSuffix : ""
  }`;

  return (
    <TopicSettingModal
      data={{ main_key: "operator_wallet_setup", id: 1, autoSubScribe, categoryId, regionId, walletId, paymentSetupModalData: data}}
      handleClose={handleClose}
      show={show}
      title={title}
      hideFilterArea={true}
      hideSearchArea={true}
    />
  );
};

export default PaymentSetupModal;
