import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { leadStatuses, _l } from "../../hooks/utilities";
import { useDispatch } from "react-redux";
import { showMessage } from "../../actions/messages";
import { leadBulkAction } from "../../actions/leads-actions/leads-actions";
const BulkActionsModal = ({
  show,
  handleClose,
  leads, 
  staffList,
  leadSources,
  tagesLeads
}) => {

  const formInitialInputs = {
    status: null,
    source_id: null,
    assignee_id: null,
    is_public: null,
    tags : null
  };

  const dispatch = useDispatch();

  const [actionInput, setActionInput] = useState(formInitialInputs);
  const [slugLeads, setSlugLeads] = useState([]);

  const setInputValue = (actioSlug, inputValue) => {
    switch(actioSlug) {
      case 'lead_mass_delete':
        setActionInput({...actionInput, slug: actioSlug});
        break;
      case 'lead_mass_mark_as_lost':
        setActionInput({...actionInput, slug: actioSlug});
        break;
      case 'lead_mass_assignee':
        setActionInput({...actionInput, assignee_id: inputValue});
        slugLeads.push(actioSlug);
        break;
      case 'lead_mass_is_public':
        setActionInput({...actionInput, is_public: inputValue});
        slugLeads.push(actioSlug);
        break;
      case 'lead_mass_change_status':
        setActionInput({...actionInput, status: inputValue });
        slugLeads.push(actioSlug);
        break;
      case 'lead_mass_change_source':
        setActionInput({...actionInput, source_id: inputValue });
        slugLeads.push(actioSlug);
        break;
      case 'lead_mass_tag_update':
        setActionInput({...actionInput, tags: inputValue }); 
        slugLeads.push(actioSlug);
        break;
     }
  }
  const performBulkAction = () => {
    if(!leads || !leads.length)
    {
      dispatch(showMessage('unsucess', _l('l_error'), _l('l_no_leads_selected')));
    }
    else {
      console.log("actionInput", actionInput);
      let data = actionInput;
      leads.forEach((key, index) => {
          data[`lead_ids[${index}]`] = key.id;
      });
      slugLeads.forEach((slug, i) => {
        data[`slug[${i}]`] = slug
      })
      Object.keys(data).forEach((key) => {
        if(['status', 'source_id', 'assignee_id', 'tag_id'].includes(key) && data[key])
        {
          data[key] = data[key].value;
        }
      });
      dispatch(leadBulkAction(data));
      handleClose();
    }
  }

  

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l('l_bulk_action')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row m-0">
              <Form.Group className="c-input-box position-relative mb-3 border-bottom p-0 pb-3">
                <div className="form-check with-span">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label text_wrap mt-2 mx-3 ms-0 mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={actionInput.slug === 'lead_mass_delete'}
                        onChange={(e) => {
                          if(e.target.checked) {
                            console.log('e.target.checked', e.target.checked);
                          setInputValue('lead_mass_delete', e);
                          slugLeads.push('lead_mass_delete')
                          let slug = slugLeads.filter((item) => item != 'lead_mass_mark_as_lost')
                          setSlugLeads(slug);
                          }
                        }}
                      />
                      <span>{_l('l_mass_delete')}</span>
                    </Form.Label>
                    <Form.Label className="form-check-label text_wrap mt-2 mb-0 mx-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={actionInput.slug === 'lead_mass_mark_as_lost'}
                        onChange={(e) => {
                          if(e.target.checked) {
                            setInputValue('lead_mass_mark_as_lost', e);
                            slugLeads.push('lead_mass_mark_as_lost')
                            let slug = slugLeads.filter((item) => item != 'lead_mass_delete')
                            setSlugLeads(slug);
                          }
                        }}
                      />
                      <span>{_l('l_mark_as_lost')}</span>
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="c-input-box position-relative mb-3 px-0 z-index-4">
                <Form.Label className="input-label no-asterisk ">
                {_l('l_change_status')}
                </Form.Label>
                <Select className="custom-select-menu"  classNamePrefix="react-select" options={leadStatuses} value={actionInput.status} onChange={(e) => {setInputValue('lead_mass_change_status', e);}} />
              </Form.Group>
              <Form.Group className="c-input-box position-relative mb-3 px-0 z-index-3">
                <Form.Label className="input-label no-asterisk ">
                {_l('l_lead_source')}
                </Form.Label>
                <Select className="custom-select-menu" options={leadSources} value={actionInput.source_id} classNamePrefix="react-select" onChange={(e) => {setInputValue('lead_mass_change_source', e);}}/>
              </Form.Group>
              <Form.Group className="c-input-box position-relative mb-3 px-0 z-index-3">
                <Form.Label className="input-label no-asterisk ">
                {_l('l_tags')}
                </Form.Label>
                <Select className="custom-select-menu" options={tagesLeads} value={actionInput.tag_id} classNamePrefix="react-select" onChange={(e) => {setInputValue('lead_mass_tag_update', e);}}/>
              </Form.Group>
              <Form.Group className="c-input-box position-relative mb-3 px-0">
                <Form.Label className="input-label no-asterisk ">
                {_l('l_assigned')}
                </Form.Label>
                <Select
                  className="custom-select-menu"
                  classNamePrefix="react-select"
                  options={staffList}
                  value={actionInput.assignee_id}
                  onChange={(e) => {setInputValue('lead_mass_assignee', e);}}
                />
              </Form.Group>
              <Form.Group className="c-input-box position-relative border-top border-bottom p-15 px-0">
                <div className="form-check p-0">
                  <div className="d-flex align-items-center">
                    <Form.Label className="form-check-label text_wrap  mb-0 ps-0">
                    <Form.Check
                      inline
                      name="1"
                      label={_l('l_public')}
                      checked={actionInput.is_public === 1}
                      onChange={() => {setInputValue('lead_mass_is_public', 1)}}
                      type="radio"
                    />
                    </Form.Label>
                    <Form.Label className="form-check-label text_wrap mb-0">
                    <Form.Check
                      inline
                      name="1"
                      label={_l('l_private')}
                      checked={actionInput.is_public === 0}
                      onChange={() => {setInputValue('lead_mass_is_public', 0)}}
                      type="radio"
                    />
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
          {_l('l_cancel')}
          </Button>
          <Button variant="primary" size="sm" onClick={() => {performBulkAction()}}>{_l('l_confirm')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkActionsModal;
