import React, { useEffect, useState } from "react";
import { DropdownMenu } from "reactstrap";
import UnControlSubFiltersDropdown from "./LeadsSubFiltersDropdown";
import { _l } from "../../hooks/utilities";
import { useDispatch, useSelector } from "react-redux";
import { leadFiltersApplied } from "../../actions/leads-actions/leads-actions";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { showMessage } from "../../actions/messages";
import CommanPlaceholder from "../Placeholder/CommanPlaceholder";
import { useNavigate } from "react-router-dom";
import customerServices from "../../services/customer-services";
import { getProviderListTableData, updateProviderList } from "../../actions/customer";
import CaretRightIcon from "../../assets/icons/CaretRightIcon";
import MinusIcon from "../../assets/icons/MinusIcon";
import NotePencilIcon from "../../assets/icons/NotePencilIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
const UnControlMainFiltersDropDown = ({
  myrLeadSources,
  selectedleadfilers,
  setselectedleadfilers,
  data,
  type = "",
  addEuipmentchecklists,
  is_from_schedule = false,
}) => {
  let idslength = {
    channels: [],
    additional_filters: [],
    sources: [],
    tags: [],
  };
  const { defaultTopics, isCreatesTaskDefaultTopics, providerlistTableData, providerList, companyEmployeeData } = useSelector(
    (state) => state.customer
  );
  const [selectedleadLength, setselectedleadLength] = useState(idslength);
  const [isEuipmentData, setisEuipmentData] = useState([]);
  const [topicsIds, setTopicsIds] = useState([]);
  const [providerListAssignee, setProviderListAssignee] = useState([])
  const [selectedProviderId, setselectedProviderId] = useState(type === "provider_list" ? selectedleadfilers ? selectedleadfilers : [] : [])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedOffice = localStorage.getItem("selectedOffice");
  const CompanyID = localStorage.getItem("currentCompanyID")
  const handleFilterLeads = (filter, filterName) => {
    const filterIndex = selectedleadfilers.findIndex((fil) => fil == filter);
    if (filterIndex === -1) {
      let finalarr = selectedleadfilers.concat(filter);
      setselectedleadfilers(finalarr);
      switch (filterName) {
        case "channels":
          setselectedleadLength({
            ...selectedleadLength,
            channels: selectedleadLength.channels.concat(filter),
          });
          break;
        case "additional_filters":
          setselectedleadLength({
            ...selectedleadLength,
            additional_filters:
              selectedleadLength.additional_filters.concat(filter),
          });
          break;
        case "sources":
          setselectedleadLength({
            ...selectedleadLength,
            sources: selectedleadLength.sources.concat(filter),
          });
          break;
        case "tags":
          setselectedleadLength({
            ...selectedleadLength,
            tags: selectedleadLength.tags.concat(filter),
          });
          break;
        default:
          break;
      }
    } else {
      let finalarr = selectedleadfilers.filter((fil) => fil != filter);
      setselectedleadfilers(finalarr);
      switch (filterName) {
        case "channels":
          setselectedleadLength({
            ...selectedleadLength,
            channels: selectedleadLength.channels.filter(
              (fil) => fil != filter
            ),
          });
          break;
        case "additional_filters":
          setselectedleadLength({
            ...selectedleadLength,
            additional_filters: selectedleadLength.additional_filters.filter(
              (fil) => fil != filter
            ),
          });
          break;
        case "sources":
          setselectedleadLength({
            ...selectedleadLength,
            sources: selectedleadLength.sources.filter((fil) => fil != filter),
          });
          break;
        case "tags":
          setselectedleadLength({
            ...selectedleadLength,
            tags: selectedleadLength.tags.filter((fil) => fil != filter),
          });
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    dispatch(leadFiltersApplied(selectedleadLength));
  }, [selectedleadLength]);
  useEffect(() => {
    let datas = [];
    let equipments = [];
    if (type === "isEamilForMeTag") {
      equipments =
        defaultTopics &&
        defaultTopics.filter((item) => item.main_key === "my_emails");
      equipments =
        equipments &&
        equipments[0] && equipments[0].categories.filter(
          (category) => Number(category.filter_key) > 0
        );
      datas = data && data.thread && data.thread.custom_topic
        let ids = []
        datas && datas.map((custom) => {
            ids.push(custom.tag_id)
        })
        setTopicsIds(ids);
      setisEuipmentData(equipments);
    } else {
      if (type === "isckecklistEuipment") {
        equipments =
          defaultTopics &&
          defaultTopics.filter((item) => item.main_key === "equipments");
        datas =
          data &&
          data.consumable_equipments_assignee &&
          data.consumable_equipments_assignee;
      } else if (type === "isCreateTaskCkecklist" && !is_from_schedule) {
        equipments =
          isCreatesTaskDefaultTopics &&
          isCreatesTaskDefaultTopics.filter(
            (item) => item.main_key === "equipments"
          );
        datas = data && data.equipment_quantity;
      } else if (is_from_schedule) {
        equipments =
          isCreatesTaskDefaultTopics &&
          isCreatesTaskDefaultTopics.filter(
            (item) => item.main_key === "equipments"
          );
        datas = data;
      }
      let equip = [];
      equipments && equipments[0] &&
        equipments[0].categories.forEach((category) => {
          if (category.is_consumable == "1") {
            const initialEuipment = is_from_schedule
              ? datas &&
                datas.equipment_detail &&
                datas.equipment_detail.filter(
                  (w) => w.equipment_id == category.filter_key
                )[0]
                ? datas.equipment_detail.filter(
                    (w) => w.equipment_id == category.filter_key
                  )[0].equipment_quantity
                : 0
              : datas &&
                datas.filter((item) => item.equipment_id == category.filter_key)
                  .length;
            const remainingEuipment = category.sub_category.filter(
              (item) => item.task_checklist_id == "0" && item.is_used == "0"
            ).length;
            const totalEuipment = category.sub_category.filter(
              (item) => item.is_used == "0"
            ).length;
            category.sub_category.forEach((sub) => {
              const subeuipment = is_from_schedule
                ? 0
                : datas &&
                  datas.filter((item) => item.equipment_quantity_id == sub.id);
              if (subeuipment && subeuipment.length > 0) {
                sub.is_euipment = 1;
              } else {
                sub.is_euipment = 0;
              }
            });
            equip.push({
              ...category,
              count: initialEuipment || 0,
              initialEuipment: initialEuipment || 0,
              remainingEuipment,
              totalEuipment,
            });
          }
        });
      setisEuipmentData(equip);
    }
  }, [
    JSON.stringify(defaultTopics),
    JSON.stringify(data),
    JSON.stringify(isCreatesTaskDefaultTopics),
  ]);
  const assigneProviderCompany = (id) => {
    let checkId = selectedProviderId.filter((item) => item == id)
    let selectedsID = selectedProviderId
    let provider_assigneeTable = providerlistTableData && providerlistTableData.provider_assignee
    let company_id = type === "provider_list" && data && data.userid ? data.userid : CompanyID
    let providerMain = providerList ? providerList : []
    if (checkId.length > 0) {
      selectedsID = selectedProviderId.filter((item) => item != id)
      provider_assigneeTable = provider_assigneeTable && provider_assigneeTable.filter((item) => item.assignee_id != id)
      providerMain = providerList.map((pro) => {
        if (pro.userid == company_id) {
          return {
           ...pro,
           provider_assignee: pro.provider_assignee.filter((item) => id != item.assignee_id),
          };
        } else {
          return pro;
        }
      })
    } else {
      selectedsID = [id].concat(selectedsID)
      provider_assigneeTable = [{assignee_id: id}].concat(provider_assigneeTable)
      providerMain = providerList.map((pro) => {
        if (pro.userid == company_id) {
          return {
           ...pro,
           provider_assignee: [{assignee_id: id}].concat(pro.provider_assignee),
          };
        } else {
          return pro;
        }
      })
    }
    setselectedProviderId(selectedsID);
    customerServices.assigneProviderCompany(company_id, selectedsID).then((res) => {
      if (res.status) {
        let documentUpdates = {
          ...providerlistTableData,
          ["provider_assignee"]: provider_assigneeTable
        };
        if (type === "provider_page") {
          dispatch(getProviderListTableData(documentUpdates))
        }else if (type === "provider_list") {
          dispatch(updateProviderList(providerMain));
        }
        dispatch(
          showMessage( "sucess",_l("l_success") ,_l(res.message))
        );
      }else{
        dispatch(
          showMessage("unsucess",_l("l_error"),_l(res.message))
        );
      }
    })
  }
  useEffect(() => {
    if (["provider_page", "provider_list"].includes(type)) {
      let  setProviders = companyEmployeeData && companyEmployeeData.length > 0 ? companyEmployeeData : []
      setProviderListAssignee(setProviders.slice());
      if (type === "provider_page") {
        let proviserList = providerlistTableData && providerlistTableData.provider_assignee && providerlistTableData.provider_assignee.map((item) => item.assignee_id);
        setselectedProviderId(proviserList.slice());
      }
    }
  }, [JSON.stringify(companyEmployeeData), JSON.stringify(providerlistTableData)])
  const addeuipmentQuantity = (id, subid) => {
    let euipment = isEuipmentData;
    euipment.forEach((category) => {
      if (category.filter_key == id) {
        if (subid) {
          category.count = Number(category.count) + 1;
        } else {
          category.count = Number(category.count) - 1;
        }
      }
    });
    if (type == "isCreateTaskCkecklist" || is_from_schedule) {
      addEuipmentchecklists(data, euipment.slice());
    }
    setisEuipmentData(euipment.slice());
  };
  return (
    <>
      <DropdownMenu
        className={`dropdown-menu ${
          ["isckecklistEuipment", "isCreateTaskCkecklist"].includes(type)
            ? ""
            : "w-140"
        } ${["provider_page", "provider_list"].includes(type) ? "h-200px overflow-auto" : ''}`}
        container="body"
        direction="down"
      >
        <ul>
          {type == "leadAppointment" ? (
            data &&
            data.map((filter, i) => {
              var IconComponent = require("../../assets/icons/" +
                filter.actionIcon).default;
              return (
                <>
                  <li className="" key={i}>
                    <a
                      href="#/"
                      className={`custom-assignees custom-class-active dropdown-item with-tick`}
                    >
                      <div
                        className="d-flex align-items-center text-truncate"
                        onClick={() => {
                          filter.actionHandler();
                        }}
                      >
                        <div className="d-flex align-items-center w100minus10 text_wrap">
                          <div className="c-list-icon comman_action_icon">
                            <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                              <IconComponent />
                            </div>
                          </div>
                          <div className="ps-2 text_wrap">
                            <div className="c-list-detail c-font f-14 text-truncate ">
                              {filter.actionName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="dropdown-divider my-0"></li>
                </>
              );
            })
          ) : [
              "isckecklistEuipment",
              "isCreateTaskCkecklist",
              "isEamilForMeTag",
            ].includes(type) ? (
            <>
              {is_from_schedule && (
                <Button className="position-sticky top-0 z-index-2 p-2 pb-0 dropdown-item btn-secondary text-wrap lh-base fw-semibold">
                  {_l("l_choose_equipment")}
                </Button>
              )}
              <hr className="mb-0 mt-1 border-white" />
              {isEuipmentData && isEuipmentData.length > 0 ? (
                isEuipmentData.map((item, i) => {
                  return (
                    <>
                      <li className="" key={i}>
                        <a
                          href="#/"
                          className={`custom-assignees custom-class-active dropdown-item with-tick ${(topicsIds).includes(item.filter_key) ?  'active' : ''}`}
                        >
                          <div className="d-flex align-items-center text-truncate"
                            onClick={() => {
                                if (type === "isEamilForMeTag") {
                                    addEuipmentchecklists(data, item);
                                }
                            }}
                          >
                            <div className="d-flex align-items-center w100minus100per text_wrap">
                              {type === "isEamilForMeTag" ? (
                                <></>
                              ) : (
                                <span className="rounded-circle badge with-bg d-flex h20w20 p-0">
                                  <span className="m-auto p-0">
                                    {item.totalEuipment}
                                  </span>
                                </span>
                              )}
                              <div className="ps-2 w100minus10 text_wrap">
                                <div className="c-list-detail c-font f-14 text-truncate ">
                                  {_l(`${item.title}`)}
                                </div>
                              </div>
                            </div>
                            {type === "isEamilForMeTag" ? (
                              <></>
                            ) : (
                              <Form.Group className={` c-input-box`}>
                                <div className="d-flex align-items-center justify-content-between comman_action_icon">
                                  <a
                                    href="#/"
                                    className="action_icon with_bg d-flex justify-content-end me-10px"
                                    onClick={() =>
                                      navigate(
                                        `/equipments?space=${selectedOffice}&equipment=${item.filter_key}`
                                      )
                                    }
                                  >
                                    <NotePencilIcon
                                      className="HW14"
                                    />
                                  </a>
                                  <a
                                    href="#/"
                                    className={`line-height-0`}
                                    onClick={() => {
                                      if (item.count > 0) {
                                        addeuipmentQuantity(
                                          item.filter_key,
                                          false
                                        );
                                      }
                                    }}
                                  >
                                    <div className="img-width-18">
                                      <MinusIcon
                                        className="HW16"
                                      />
                                    </div>
                                  </a>
                                  <div
                                    className={`c-font f-13 fw-semibold title-fonts mx-2 ${
                                      item.count > 0 ? "color-green" : ""
                                    } `}
                                  >{`${item.count || 0}`}</div>
                                  <a
                                    href="#/"
                                    className="line-height-0"
                                    onClick={() => {
                                      if (
                                        item.count <
                                        item.remainingEuipment +
                                          item.initialEuipment
                                      ) {
                                        addeuipmentQuantity(
                                          item.filter_key,
                                          true
                                        );
                                      } else {
                                        dispatch(
                                          showMessage(
                                            "unsucess",
                                            _l("l_error"),
                                            _l(
                                              "l_all_equipment_quantities_are_assignee"
                                            )
                                          )
                                        );
                                      }
                                    }}
                                  >
                                    <div className="img-width-18">
                                      <PlusIcon
                                        className="HW16"
                                      />
                                    </div>
                                  </a>
                                </div>
                              </Form.Group>
                            )}
                          </div>
                        </a>
                      </li>
                      <li className="dropdown-divider my-0"></li>
                    </>
                  );
                })
              ) : (
                <CommanPlaceholder
                  imgWidth="h60w60"
                  mainSpacing="py-3"
                  placeholderText={_l("l_no_equipments")}
                />
              )}
              {(isEuipmentData && isEuipmentData.length == 0) ||
              ["isCreateTaskCkecklist", "isEamilForMeTag"].includes(type) ||
              is_from_schedule ? (
                <></>
              ) : (
                <li
                  className={`p-2 ${
                    (isEuipmentData && isEuipmentData.length == 0) ||
                    ["isCreateTaskCkecklist"].includes(type)
                      ? "d-none"
                      : ""
                  }`}
                >
                  <Button
                    variant="secondary"
                    className="border w-100 p-1 text-center"
                    size="sm"
                    onClick={() => {
                      addEuipmentchecklists(data, isEuipmentData);
                    }}
                  >
                    {_l("l_save")}
                  </Button>
                </li>
              )}
            </>
            ) : 
            ["provider_page", "provider_list"].includes(type) ? 
            <>
            {providerListAssignee && providerListAssignee.length > 0 ? (
                providerListAssignee.sort((a) => {
                  if (selectedProviderId && selectedProviderId.includes(a.staff_id)) {
                    return -1;
                  }else{
                    return 0
                  }
                }).map((item, i) => {
                  return (
                    <>
                      <li className={` dropdown-item p-0 with-tick ${selectedProviderId && selectedProviderId.includes(item.staff_id) ? "active" : ''}`} key={i}>
                        <a
                          href="#/"
                          className="dropdown-item d-flex"
                          type="button"
                          id="Loginuserdropdown"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        > 
                          <div className="c-list-icon">
                            <div className={`h25w25 comman-round-box with-bg d-flex align-items-center bg-white-05 rounded-circle`}>
                              <div
                                className={`comman-bg-img h-100 w-100 bg-style-cover rounded-circle`}
                                style={{
                                  backgroundImage: `url(${item.profile_image})`,
                                }}
                              >
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center text-truncate" 
                            onClick={() => {
                              assigneProviderCompany(item.staff_id)
                            }}>
                            <div className="d-flex align-items-center text_wrap">
                              <div className="ps-2 text_wrap">
                                <div className="c-list-detail c-font f-14 text-truncate ">
                                  {_l(`${item.full_name}`)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li className="dropdown-divider my-0"></li>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </> :
          (
            <>
              {myrLeadSources && Object.keys(myrLeadSources).length ? (
                Object.keys(myrLeadSources).map((item, i) => {
                  return (
                    <>
                      <li className="" key={i}>
                        <a
                          href="#/"
                          className="dropdown-item"
                          type="button"
                          id="Loginuserdropdown"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center text-truncate">
                            <div className="d-flex align-items-center w100minus20 text_wrap">
                              <div className="ps-2 text_wrap">
                                <div className="c-list-detail c-font f-14 text-truncate ">
                                  {_l(`l_${item}`)}
                                </div>
                              </div>
                            </div>
                            {selectedleadLength[item].length > 0 ? (
                              <span className="rounded-circle green-text badge with-bg d-flex h20w20 p-0">
                                <span className="m-auto p-0">
                                  {selectedleadLength[item].length}
                                </span>
                              </span>
                            ) : (
                              <></>
                            )}
                            <div className="c-list-icon">
                              <CaretRightIcon
                                className="HW14"
                              />
                            </div>
                          </div>
                        </a>
                        <UnControlSubFiltersDropdown
                          data={myrLeadSources[item]}
                          filterName={item}
                          handleFilterLeads={handleFilterLeads}
                          selectedleadfilers={selectedleadfilers}
                        />
                      </li>
                      <li className="dropdown-divider my-0"></li>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </>
          )}
        </ul>
      </DropdownMenu>
    </>
  );
};
export default UnControlMainFiltersDropDown;
