import React from "react";
import { _l } from "../../../hooks/utilities";
import InviteProviderIcon from "../../../assets/icons/InviteProviderIcon";
import ChooseFromPastIcon from "../../../assets/icons/ChooseFromPastIcon";
import NetworkIcon from "../../../assets/icons/NetworkIcon";
import CheckIcon from "../../../assets/icons/CheckIcon";


let PROVIDER_SOURCES = [
  { key: "l_invite_provider", description: "l_invite_user_description",icon:InviteProviderIcon },
  { key: "l_choose_from_past", description: "l_past_providers_description",icon:ChooseFromPastIcon },
  { key: "l_myr_network", description: "l_myr_network_description",icon:NetworkIcon },
];

const ProviderSources = ({providerData, setProviderData}) => {

  if(providerData.isCustomCategory)
  {
    PROVIDER_SOURCES = [
      { key: "l_invite_provider", description: "l_invite_user_description",icon:InviteProviderIcon },
    ];
  }

  return (
    <>
      {PROVIDER_SOURCES.map((provider, key) => {
        if(providerData.type === "schedule" && provider.key === "l_myr_network")
        {
          return <React.Fragment></React.Fragment>
        }
        return (
          <div key={key} className="space-select-box select-input-cox mx-3 bg-transparent">
            <input
              className="bottom-0 end-0 form-check-input h_230px w-100 position-absolute start-0 top-0 bg-transparent"
              name="provider-type"
              type="checkbox"
              checked={providerData.sources.includes(provider.key.replace("l_", ""))}
              value={provider.key.replace("l_", "")}
              onClick={(e) => {
                if(e.target.checked)
                {
                    setProviderData({
                        ...providerData,
                        sources: providerData.sources.concat(provider.key.replace("l_", "")),
                        invite_all_myr_providers: provider.key === "l_myr_network" ? e.target.checked : providerData.invite_all_myr_providers,
                    });
                }
                else {
                    setProviderData({
                        ...providerData,
                        sources: providerData.sources.filter(x => x!= provider.key.replace("l_", "")),
                        invite_all_myr_providers: e.target.checked,
                        invite_all_myr_providers: provider.key === "l_myr_network" ? e.target.checked : providerData.invite_all_myr_providers,
                    });
                }
              }}
            />
            <a href="#/" className="check-image">
              <div className="check-image-box">
                <CheckIcon className="HW14 color-white-03-solid" />
              </div>
            </a>
            <div className="">
              <div className="d-flex bg-white-03 m-auto h_230px radius_3">
                <provider.icon className="h60w60 stroke-width-06px" />
              </div>
              <div className="text-center p-10">
                <div className="text-center c-font f-16 p-10 px-0 fw-semibold">
                  {_l(provider.key)}
                </div>
                <div className="text-center c-font f-14 color-white-60 fw-light">
                  {_l(provider.description)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProviderSources;
