import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { _l } from "../../hooks/utilities";
import PaperclipIcon from "../../assets/icons/PaperclipIcon";

const ImportProviders = ({
  show,
  handleClose,
  setImportFile,
  handleImport,
  importFile,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal-style"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_l("l_bulk_upload")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0">
          <div className="form-wrapper-main">
            <div className="row pb-3">
              <ol start={1} className="ps-4">
                <li className="with-listing-count pb-1">
                  {_l("l_you_csv_data_string")}
                  <b>UTF-8</b> {_l("l_to_avoid_unnecessary")}{" "}
                  <b>{_l("l_encoding_problems")}</b>
                </li>
              </ol>
              <div className="comman-data-table">
                <table className="dataTable GeneralItemTable">
                  <thead className="WithHeaderbg ">
                    <tr>
                      <th className="c-font f-13 text-nowrap">
                        {_l("l_email")}
                      </th>
                      <th className="c-font f-13 text-nowrap">
                        {_l("l_name")}
                      </th>
                      <th className="c-font f-13 text-nowrap">
                        {_l("l_position")}
                      </th>
                      <th className="c-font f-13 text-center text-nowrap">
                        {_l("l_mobile_number")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_assignee_email_placeholder")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_full_name_placeholder")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_manager")}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">
                          {_l("l_mobile_number_placeholder")}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_assignee_email_placeholder")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_full_name_placeholder")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_manager")}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">
                          {_l("l_mobile_number_placeholder")}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_assignee_email_placeholder")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_full_name_placeholder")}
                        </div>
                      </td>
                      <td className="max-width-100 text-truncate border-bottom">
                        <div className="c-font text-truncate f-12">
                          {_l("l_manager")}
                        </div>
                      </td>
                      <td className="max-width-100 border-bottom text-center text-nowrap">
                        <div className="c-font f-12">
                          {_l("l_mobile_number_placeholder")}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row align-items-end pb-3">
              <Form.Group className="col-6  c-input-box  position-relative">
                <Form.Label className="input-label no-asterisk ">
                  {_l("l_choose_csv_file")}
                </Form.Label>

                <div className="comment-input-box w-100">
                  <div
                    className="input_box_main with_file_upload"
                     
                     
                     
                     
                  >
                    <label className="upload_inputbox w-100 upload_relative form-control height-45  p-0 d-flex align-items-center ">
                      <Form.Control
                        className="  hidden-input"
                        type="file"
                        onInput={setImportFile}
                        accept=".csv"
                      />
                      <div className="custom_file">
                        <PaperclipIcon
                          className="upload_icon HW18"
                        />
                        <div className="btn btn-sm btn-white-10 border-0">
                          <span className="c-font f-10 color-white-60">
                            {" "}
                            {_l("l_upload_file")}
                          </span>
                        </div>
                        <span className="px-1 c-font f-13 color-white-60">
                          {importFile && importFile[0]
                            ? importFile[0].name
                            : ""}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </Form.Group>
            </div>
            <Form.Group className="col-12 pb-3 c-input-box  position-relative d-none">
              <Button
                variant="secondary me-2 mt-2"
                size="sm"
                className="position-relative"
                onClick={handleImport}
              >
                {_l("l_import_items")}
              </Button>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-end">
          <Button variant="secondary" size="sm" onClick={handleClose}>
            {_l("l_close")}
          </Button>
          <Button variant="primary" size="sm" onClick={handleImport}>
            {_l("l_import")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportProviders;
