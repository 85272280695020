import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row } from "react-bootstrap";
import { _l, getFileType, getImagePreviewIcon, getPreviewFromURL, showError } from "../../../hooks/utilities";
import DatePicker from "react-datepicker";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import Select from "react-select";
import CommanPlaceholder from "../../Placeholder/CommanPlaceholder";
import TopicSettingModal from "../../Modals/TopicSettingModal";
import leaseServices from "../../../services/lease-services";
import Popover from 'react-bootstrap/Popover';
import { Chart } from "react-google-charts";
import jquery from "jquery";
import Button from "react-bootstrap/Button";
import LeaseEnterprisCompanyModal from "../../Modals/LeaseEnterprisCompanyModal";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "../../../actions/messages";
import AddLeaseEmployeeModal from "../../Modals/AddLeaseEmployeeModal";
import DocumentPreview from "../../Documents/DocumentPreview";
import BuildingsIcon from "../../../assets/icons/BuildingsIcon";
import CalendarBlankIcon from "../../../assets/icons/CalendarBlankIcon";
import ChartLineIcon from "../../../assets/icons/ChartLineIcon";
import CloudArrowUpIcon from "../../../assets/icons/CloudArrowUpIcon";
import FileTextIcon from "../../../assets/icons/FileTextIcon";
import GearSixIcon from "../../../assets/icons/GearSixIcon";
import MagnifyingGlassIcon from "../../../assets/icons/MagnifyingGlassIcon";
import PencilSimpleLineIcon from "../../../assets/icons/PencilSimpleLineIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import WrenchIcon from "../../../assets/icons/WrenchIcon";
import XIcon from "../../../assets/icons/XIcon";
const LeaseContractSetup = ({
  leaseData = {},
  setLeaseData = () => {},
  leaseDocuments,
  setLeaseDocuments = () => {},
  otherDocuments,
  setOtherDocuments = () => {},
  spaceDetail,
  topics,
  chartData = {},
  documents,
  setDocuments = () => {},
  spaceBasicDetails,
  isFrom= "",
  selectedFloorId= [],
  leaseJson = [],
  setSelectedFloorIds = () => {},
  setLeaseJson = () => {},
  currentFloorId,
  lease_sub_id = 0,
  setSpaceDetail = () =>{},
  setTopics = () =>{},
  isCreate = false,
}) => {
  const { floorDetails } = useSelector((state) => state.benchmarkReducer);
  const spaceFloorPlan = useSelector(
    (state) => state.benchmarkReducer.floorDetails
  );
  const dispatch = useDispatch();
  const [openLeaseEnterprise, setOpenLeaseEnterprise] = useState(false)
  var options = {
    'title': '',
    legend: {textStyle: {color: '#FCA120'}, position: 'top', alignment: 'start'},
    curveType: 'function',
    backgroundColor: {
      fill: 'transparent',
      stroke: '#a4aeb5',
      strokeSize: 2
    },
    hAxis: {
      title: '',
      textStyle: {
        color: '#a4aeb5',
        fontSize: 12,
        fontName: 'Montserrat',
      },
      titleTextStyle: {
        color: '#a4aeb5',
        fontSize: 12,
        fontName: 'Montserrat',
      }
    },
    vAxis: {
      title: '',
      textStyle: {
        color: '#a4aeb5',
        fontSize: 12,
      },
      gridlines: {
        color: '#3A4F5E',
      },
      titleTextStyle: {
        color: '#a4aeb5',
        fontSize: 0,
      }
    },
    colors: ['#FCA120']
  };
  const [showSearch, setShowSearch] = useState({
    servicesSearch: false,
    equipmentsSearch: false,
    residentsSearch: false,
  });
  const [leaseDocumentPreview, setLeaseDocumentPreview] = useState([]);
  const [otherDocumentPreview, setOtherDocumentPreview] = useState([]);
  const [serviceSearchString, setServiceSearchString] = useState("");
  const [equipmentsSearchString, setEquipmentsSearchString] = useState("");
  const [residentsSearchString, setResidentsSearchString] = useState("");
  const [servicesTopics, setServicesTopics] = useState([]);
  const [equipmentsTopics, setequipmentsTopics] = useState([]);
  const [residentTopics, setResidentTopics] = useState([]);
  const [providerListingCategory, setProviderListingCategory] = useState({id: 0, title: "", main_key: "", is_active: "", main_key_title: "", main_sub_key: ""});
  const [openCalendar, setOpenCalendar] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [chartDataForLease, setchartDataForLease] = useState([]);
  const [allAttachments, setAllAttachments] = useState([]);
  const [showExternalImage, setshowExternalImage] = useState(false);
  const [floorOptions, setFloorOptions] = useState([]);
  const [subFloorOptions, setsubFloorOptions] = useState([])
  const [selectedTypeOfSpaceID, setselectedTypeOfSpaceID] = useState([]);
  const [addLeaseEmployeeModalOpen, setAddLeaseEmployeeModalOpen] = useState(false);
  const preparePreview = (files, type) => {
    let previrewArray = [];
    Array.from(files).map((img) => {
      let preview_url = getImagePreviewIcon(img);
      previrewArray.push({ name: img.name, url: preview_url, image_url : URL.createObjectURL(img) , id : Math.floor(Math.random() * 90000) + 10000 });
    });
    if (type == "leaseDocument") {
      setLeaseDocumentPreview(previrewArray);
    } else if (type == "otherDocuments") {
      setOtherDocumentPreview(previrewArray);
    }
  };
  const removeImg = (name, type, file) =>{
    if (type == "leaseDocument") {
      let doc = leaseDocumentPreview
      doc.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail.url);
      });
      const updatedFileList = Array.from(doc).filter(
        (img) => img.name != name
      );
      setLeaseDocumentPreview(updatedFileList);
    } else if (type == "otherDocuments") {
      let doc = otherDocumentPreview
      doc.forEach((thumbnail) => {
        URL.revokeObjectURL(thumbnail.url);
      });
      const updatedFileList = Array.from(doc).filter(
        (img) => img.name != name
      );
      setOtherDocumentPreview(updatedFileList);
    }
  }
  const removeImageSelection = (name, type, file) => {
    if (file.is_contract_file == 0 || file.is_contract_file == 1) {
      leaseServices.deleteLeaseDocument(spaceDetail.project_id, spaceDetail.lease_id ,file.id).then((res)=>{
        if(res && res.status){
          const updatedFileList = Array.from(documents).filter(
            (img) => img.id != file.id 
          );
          setDocuments(updatedFileList);
        }
      })
    } else{
      removeImg(name, type, file);
    }
  };
  const hadleNoticeDates = (date) => {
    let tempDate = leaseData.noticeDates;
    tempDate.forEach((notice) => {
      if (notice.notice_date == "") {
        notice.notice_date = date;
      }
    });
    setLeaseData({ ...leaseData, noticeDates: tempDate });
  };
  const setBlankNoticeDate = () => {
    let tempDate = leaseData.noticeDates;
    setLeaseData({
      ...leaseData,
      noticeDates: tempDate.concat([{ notice_date: "" }]),
    });
  };
  const handleTopicSelection = (topicId , topicType) => {
    switch (topicType) {
      case "services":
        let selectedServicesIds = leaseData.selectedServices ;
        let servicesIndex = selectedServicesIds.findIndex((id) => id == topicId);
        if (servicesIndex > -1) {
          selectedServicesIds = selectedServicesIds.filter((id) => id != topicId )
        } else {
          selectedServicesIds = selectedServicesIds.concat([topicId])
        }
        setLeaseData({...leaseData , selectedServices : selectedServicesIds});
        break;
      case "equipments":
        let selectedequipmentsIds = leaseData.selectedEquipments ;
        let equipmentIndex = selectedequipmentsIds.findIndex((id) => id == topicId);
        if (equipmentIndex > -1) {
          selectedequipmentsIds = selectedequipmentsIds.filter((id) => id != topicId )
        } else {
          selectedequipmentsIds = selectedequipmentsIds.concat([topicId])
        }
        setLeaseData({...leaseData , selectedEquipments : selectedequipmentsIds});
        break;
        case "residents":
          let selectedresidentsIds = leaseData.selectedResidents ;  
          let residentIndex = selectedresidentsIds.findIndex((id) => id == topicId);
          if (residentIndex > -1 ) {
            selectedresidentsIds = selectedresidentsIds.filter((id) => id != topicId )
          } else {
            selectedresidentsIds = selectedresidentsIds.concat([topicId])
          }
        setLeaseData({...leaseData , selectedResidents : selectedresidentsIds});
        break;
      default:
        break;
    }
  }
  const handleUpdateNoticeDates = (date , index) =>{
    let noticeDate = leaseData.noticeDates
    let tempDate = leaseData.noticeDates[index]
    if (tempDate) {
      tempDate.notice_date = date
    }
    setLeaseData({
      ...leaseData,
      noticeDates : noticeDate
    });
    setTimeout(() => {
      setOpenCalendar(false);
    }, 500);
  }
  const deleteNoticeDates = (index) =>{
    let noticeDate = leaseData.noticeDates
    setLeaseData({
      ...leaseData, noticeDates : noticeDate.filter((date , i) =>  i != index)
    })
  }
  const activeTopics = (topic, main_key) => {
    let topicData =
    [{"main_key":main_key,
    "filter_key": topic.filter_key,
    "main_sub_key":topic.main_sub_key,
    "status":1
  }]
  const activeTopicsSub = (lease_id) =>{
    let index = -1
      leaseServices.updateLeaseTopic(spaceDetail.project_id, topicData, spaceDetail.lease_id > 0 ? spaceDetail.lease_id : lease_id, lease_sub_id).then((res)=>{
        if (res && res.status) {
          switch (main_key) {
            case "providers":
              index = servicesTopics.findIndex((service) => service.filter_key == topic.filter_key)
              if (index > -1) {
                let updatedServiceTopics = servicesTopics[index];
              updatedServiceTopics.is_active = 1;
              setServicesTopics(
                Object.values({ ...servicesTopics, [index]: updatedServiceTopics })
              );
            }
              break;
            case "equipments":
              index = equipmentsTopics.findIndex((service) => service.filter_key == topic.filter_key)
              if (index > -1) {
                let updatedEquipmentList = equipmentsTopics[index];
              updatedEquipmentList.is_active = 1;
              setequipmentsTopics(
                Object.values({ ...equipmentsTopics, [index]: updatedEquipmentList })
              );
            }
              break;
            case "my_contact" :
            case "my_operator" :
            case "resident" :
              index = residentTopics.findIndex((service) => service.filter_key == topic.filter_key)
              if (index > -1) {
                let updatedEquipmentList = residentTopics[index];
              updatedEquipmentList.is_active = 1;
              setResidentTopics(
                Object.values({ ...residentTopics, [index]: updatedEquipmentList })
              );
            }
              break;
            default:
              break;
          }
        }
      })
  }
  if (spaceDetail.lease_id > 0) {
    activeTopicsSub()
  } else {
    if(!validateStep())
      {
        return false;
      }
      leaseServices.addeditProjectLease(
        spaceDetail.project_id > 0 ? spaceDetail.project_id : 0,
        leaseData,
        leaseDocuments,
        otherDocuments,
        spaceDetail.lease_id > 0 ? spaceDetail.lease_id : 0
      ).then((res)=>{
        if (res && res.status) {
          setSpaceDetail({
            ...spaceDetail,
            lease_id : res.data.lease_id
          })
          setLeaseDocumentPreview([]);
          setOtherDocumentPreview([]);
          activeTopicsSub(res.data.lease_id)
        }else{
          showError(res.message)
        }
      })
  }
  }
  const inactiveTopics = (topic, main_key) => {
    let topicData =
    [{"main_key":main_key,
    "filter_key": topic.filter_key,
    "main_sub_key":topic.main_sub_key,
    "status": 0
  }]
  const inactiveTopicsSub = (lease_id) => {
    let index = -1
    leaseServices.updateLeaseTopic(spaceDetail.project_id, topicData, spaceDetail.lease_id > 0 ? spaceDetail.lease_id : lease_id , lease_sub_id).then((res)=>{
      if (res && res.status) {
        switch (main_key) {
          case "providers":
            index = servicesTopics.findIndex((service) => service.filter_key == topic.filter_key)
            if (index > -1) {
              let updatedServiceTopics = servicesTopics[index];
            updatedServiceTopics.is_active = 0;
            setServicesTopics(
              Object.values({ ...servicesTopics, [index]: updatedServiceTopics })
            );
          }
            break;
          case "equipments":
            index = equipmentsTopics.findIndex((service) => service.filter_key == topic.filter_key)
            if (index > -1) {
              let updatedEquipmentList = equipmentsTopics[index];
            updatedEquipmentList.is_active = 0;
            setequipmentsTopics(
              Object.values({ ...equipmentsTopics, [index]: updatedEquipmentList })
            );
          }
            break;
          case "my_contact" :
          case "my_operator" :
          case "resident" :
            index = residentTopics.findIndex((service) => service.filter_key == topic.filter_key)
            if (index > -1) {
              let updatedEquipmentList = residentTopics[index];
            updatedEquipmentList.is_active = 0;
            setResidentTopics(
              Object.values({ ...residentTopics, [index]: updatedEquipmentList })
            );
          }
            break;
          default:
            break;
        }
      }
    })
  }
  if (spaceDetail.lease_id > 0) {
    inactiveTopicsSub()
  } else {
    if(!validateStep())
      {
        return false;
      }
      leaseServices.addeditProjectLease(
        spaceDetail.project_id > 0 ? spaceDetail.project_id : 0,
        leaseData,
        leaseDocuments,
        otherDocuments,
        spaceDetail.lease_id > 0 ? spaceDetail.lease_id : 0
      ).then((res)=>{
        if (res && res.status) {
          setSpaceDetail({
            ...spaceDetail,
            lease_id : res.data.lease_id
          })
          inactiveTopicsSub(res.data.lease_id);
          setLeaseDocumentPreview([]);
          setOtherDocumentPreview([]);
        }
      })
  }
}
const setAttchmentOnTop = (id , type) => {
  let leaseDocuments = documents.filter((file) => file.is_contract_file == 1).length > 0 ? documents.filter((file) => file.is_contract_file == 1) : []
  let otherAttachment = documents.filter((file) => file.is_contract_file == 0).length > 0 ? documents.filter((file) => file.is_contract_file == 0) : []
  let allAttachments = type == "leaseDocument" ? leaseDocuments.concat(leaseDocumentPreview) : otherAttachment.concat(otherDocumentPreview);
  const targetAttchement = allAttachments.filter(
    (attachment) => attachment.id === id
  );
  const otherAttachments = allAttachments.filter(
    (attachment) => attachment.id != id
  );
  setAllAttachments(targetAttchement.concat(otherAttachments));
};
const sortDataByDate = (data) => {
  const keys = Object.keys(data);
  const parseDate = (dateStr) => {
      const [month, year] = dateStr.split('-');
      const monthMap = {
          "Jan": 0,
          "Feb": 1,
          "Mar": 2,
          "Apr": 3,
          "May": 4,
          "Jun": 5,
          "Jul": 6,
          "Aug": 7,
          "Sep": 8,
          "Oct": 9,
          "Nov": 10,
          "Dec": 11
      };
      return new Date(year, monthMap[month]);
  };
  keys.sort((a, b) => parseDate(a) - parseDate(b));
  const sortedData = {};
  keys.forEach(key => sortedData[key] = data[key]);
  return sortedData;
};
const hadleTypeofspaceJson = (e) =>{
  let tempTos = []
  e.map((x)=>{
    let index = -1
    index = spaceFloorPlan.type_of_space_items.findIndex((space)=> space.id  == x.value)
    if (index > -1) {
      tempTos.push(spaceFloorPlan.type_of_space_items[index])
    }
  })
  setLeaseJson(tempTos.map((space)=>{
    return{
      "floor_id" : space.floor_id,
        "type_of_space_id" : space.room_id,
        "type_of_space_item_id" : space.id
    }}))
}
const validateStep = () => {
  if (leaseData.leaseStartDate == '') {
    dispatch(
      showMessage(
        "unsucess",
        _l("l_error"),
        _l("l_please_select_the_lease_start_date")
      )
    );
    return false;
  } else if (leaseData.leaseEndDate == '') {
    dispatch(
      showMessage(
        "unsucess",
        _l("l_error"),
        _l("l_please_select_the_lease_end_date")
      )
    );
    return false;
  } else if (leaseData.noticeDates && leaseData.noticeDates.filter((date) => date.notice_date != '').length == 0) {
    dispatch(
      showMessage(
        "unsucess",
        _l("l_error"),
        _l("l_please_select_the_notice_lease_end_date")
      )
    );
    return false;
  } else if (leaseData.amount == '') {
    dispatch(
      showMessage(
        "unsucess",
        _l("l_error"),
        _l("l_please_enter_amount")
      )
    );
    return false;
  } else if (leaseDocuments && leaseDocuments.length == 0) {
    dispatch(
      showMessage(
        "unsucess",
        _l("l_error"),
        _l("l_please_upload_lease_document")
      )
    );
    return false;
  }else{
    return true;
  }
}
  useEffect(() => {
  if (isFrom == "floor-setup") {
    let ids = []
    leaseJson && leaseJson.map((floor)=>{
      if (ids.includes(floor.type_of_space_item_id)) {
      }else{
        ids.push(floor.type_of_space_item_id)
      }
    })
    setselectedTypeOfSpaceID(ids)
  }  
  }, [JSON.stringify(leaseJson)])
useEffect(() => {
  if (topics && topics.length > 0) {
    setServicesTopics(topics.filter((topic)=> topic.main_key == "providers")[0].categories);
    setequipmentsTopics(topics.filter((topic)=> topic.main_key == "equipments")[0].categories);
    setResidentTopics(topics.filter((topic)=> topic.main_key == "resident")[0].categories);
    if (isFrom == "lease-setup") {    
      setLeaseData({
        ...leaseData ,
        selectedServices :topics.filter((topic)=> topic.main_key == "providers")[0].categories.filter((cat)=> cat.is_active > 0).map((cat)=>{return cat.filter_key}),
        selectedEquipments :topics.filter((topic)=> topic.main_key == "equipments")[0].categories.filter((cat)=> cat.is_active > 0).map((cat)=>{return cat.filter_key}),
        selectedResidents :topics.filter((topic)=> topic.main_key == "resident")[0].categories.filter((cat)=> cat.is_active > 0).map((cat)=>{return cat.filter_key}),
      })
    }
  }
}, [JSON.stringify(topics)])
useEffect(() => {
  if (topics && topics.length) {    
    if (serviceSearchString != "") {
      setServicesTopics(servicesTopics.filter((cat)=> cat.title.toLocaleLowerCase().includes(serviceSearchString.toLocaleLowerCase())));
    } else {
      setServicesTopics(topics.filter((topic)=> topic.main_key == "providers")[0].categories);
    }
  }
}, [serviceSearchString])
useEffect(() => {
  if (topics && topics.length) {    
    if (equipmentsSearchString != "") {
      setequipmentsTopics(equipmentsTopics.filter((cat)=> cat.title.toLocaleLowerCase().includes(equipmentsSearchString.toLocaleLowerCase())));
    } else {
      setequipmentsTopics(topics.filter((topic)=> topic.main_key == "equipments")[0].categories);
    }
  }
}, [equipmentsSearchString])
useEffect(() => {
  if (topics && topics.length) {  
    if (residentsSearchString != "") {
      setResidentTopics(residentTopics.filter((cat)=> (cat.user_details.firstname || cat.user_detailslastname).toLocaleLowerCase().includes(residentsSearchString.toLocaleLowerCase())));
    } else {
      setResidentTopics(topics.filter((topic)=> topic.main_key == "resident")[0].categories);
    }
  }
}, [residentsSearchString])
useEffect(() => {
  jquery(document).mouseup(function (e) {
    var container = jquery('.dropdown-menu');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jquery(document).mouseup(function (e) {
        var container = jquery('.custom-datepicker');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          // custom-datepicker
          setOpenCalendar(false);
        }
      })
    }
  })
})
useEffect(() => {
  jquery(document).mouseup(function (e) {
    var container = jquery('.onboarding-wrapper-main');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      setShowChart(false);
    }
  })
  jquery(document).mouseup(function (e) {
    var container = jquery('#reactgooglegraph-3');
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      setShowChart(false);
    }
  })
})
useEffect(() => {
  if (chartData && Object.keys(chartData).length > 0) {    
    let data = sortDataByDate(chartData)
    let datas = [['month', `price`]];
    Object.keys(data).map((item)=>{
      datas.push([item, Number(data[item])]);
    })
    setchartDataForLease(datas)
  }
}, [chartData])
useEffect(() => {
  if (floorDetails && floorDetails.floor && floorDetails.floor.length > 0) {
    setFloorOptions( floorDetails.floor.map((floor) => {
      {
        return {
          label: floor.title,
          value: floor.id,
        };
      }
    }))
  }
}, [floorDetails])
useEffect(() => {
  if (spaceFloorPlan && spaceFloorPlan.type_of_space_items && spaceFloorPlan.type_of_space_items.length > 0) {
    setsubFloorOptions( spaceFloorPlan.type_of_space_items.filter((x) => x.is_default != 1 && x.floor_id == currentFloorId).map((floor) => {
      {
        return {
          label: floor.title,
          value: floor.id,
        };
      }
    }))
  }
}, [spaceFloorPlan])
  return (
    <React.Fragment>
      {showExternalImage ? (
            <>
              <DocumentPreview
                open={showExternalImage}
                setOpen={setshowExternalImage}
                slides={allAttachments.map((img) => {
                  if (typeof img.url === "string" ) {
                    return { src: (img.image_url ? img.image_url : img.url) };
                  } else {
                    return {
                      src: img.image_url ? img.image_url : img.url,
                      type:
                      img.type ||
                        getFileType(img.name ? img.name :img.image_url ? img.image_url : img.url) ||
                        getFileType(img.name),
                    };
                  }
                })}
              />
            </>
          ) : (
            <></>
          )}
    <Row className="justify-content-center">
      <Col xl={12}>
        <Row className="g-3">
          {isFrom == "floor-list" ?
          <Form.Group className="col-lg-12  c-input-box  position-relative">
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_floors")}`}
            </Form.Label>
                <Select
                  className="custom-select-menu bgOfficelisting"
                  classNamePrefix="react-select"
                  placeholder={`${_l("l_floors")}`}
                  options={floorOptions}
                  closeMenuOnSelect={true}
                  isMulti
                  value={floorDetails
                    ? floorOptions.filter(
                        (x) => selectedFloorId.includes(x.value
                        )
                      )
                    : ""}
                  onChange={(e)=>{
                    setSelectedFloorIds(e.map((x)=> {return x.value}))
                  }}
                />
          </Form.Group> :
          isFrom == "floor-setup" ?
          <>
         <Form.Group className="col-lg-12  c-input-box  position-relative">
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_type_of_space_items")}`}
            </Form.Label>
                <Select
                  className="custom-select-menu bgOfficelisting"
                  classNamePrefix="react-select"
                  placeholder={`${_l("l_type_of_space_items")}`}
                  options={subFloorOptions}
                  closeMenuOnSelect={true}
                  isMulti
                  value={subFloorOptions
                    ? subFloorOptions.filter(
                        (x) => selectedTypeOfSpaceID.includes(x.value
                        )
                      )
                    : ""}
                  onChange={(e)=>{
                    hadleTypeofspaceJson(e)
                  }}
                />
          </Form.Group>
          </>
          : <></>}
          {isFrom == "lease-setup" ? <Form.Group className={`col-lg-12 c-input-box  position-relative`}>
            <Form.Label className="input-label no-asterisk">
              {_l("l_renting")} / {_l("l_owning")}
            </Form.Label>
            <div className="form-control gap-4 d-flex align-items-center">
              <Form.Group className="form-check">
                <Form.Label className="form-check-label mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="ranting"
                    value="renting"
                    checked={leaseData.isRentingorOwning == "renting"}
                    onChange={() => {
                      setLeaseData({
                        ...leaseData,
                        isRentingorOwning: "renting",
                      });
                    }}
                  />
                  <span className="ps-2 c-font f-12">{`${_l(
                    "l_location"
                  )}`}</span>
                </Form.Label>
              </Form.Group>
              <Form.Group className="form-check opacity-80">
                <Form.Label className="form-check-label mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="owning"
                    value="owning"
                    disabled
                    checked={leaseData.isRentingorOwning == "owning"}
                    onChange={() => {
                      setLeaseData({
                        ...leaseData,
                        isRentingorOwning: "owning",
                      });
                    }}
                  />
                  <span className="ps-2 c-font f-12">{`${_l(
                    "l_owning"
                  )}`}</span>
                </Form.Label>
              </Form.Group>
            </div>
          </Form.Group> : <></>}
          <Form.Group className={` col-lg-6  c-input-box  position-relative`}>
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_lease_start_date")}`}
            </Form.Label>
            <div className="custom-datepicker w-100">
              <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px" onClick={()=>{setOpenCalendar(false)}}>
                <div className="action_icon h32w32 with_bg">
                  <CalendarBlankIcon className="HW18" />
                </div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText={`${_l("l_select_date_placeholder")}`}
                  className="ps-2 w-100"
                  calendarStartDay={1}
                  selected={leaseData.leaseStartDate}
                  onChange={(date) => {
                    setLeaseData({ ...leaseData, leaseStartDate: date });
                  }}
                >
                  <div className="datepicker-label">
                    {_l("l_select_date_and_time")}
                  </div>
                </DatePicker>
              </div>
            </div>
          </Form.Group>
          <Form.Group className={` col-lg-6  c-input-box  position-relative`}>
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_lease_end_date")}`}
            </Form.Label>
            <div className="custom-datepicker w-100">
              <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px" 
              onClick={()=>{
                setOpenCalendar(false);
              }}>
                <div className="action_icon h32w32 with_bg">
                  <CalendarBlankIcon className="HW18" />
                </div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText={`${_l("l_select_date_placeholder")}`}
                  className="ps-2 w-100"
                  calendarStartDay={1}
                  selected={leaseData.leaseEndDate}
                  onChange={(date) => {
                    setLeaseData({ ...leaseData, leaseEndDate: date });
                  }}
                  minDate={leaseData.leaseStartDate}
                >
                  <div className="datepicker-label">
                    {_l("l_select_date_and_time")}
                  </div>
                </DatePicker>
              </div>
            </div>
          </Form.Group>
          <Form.Group className={` col-lg-6  c-input-box  position-relative`}>
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_notice_lease_end_date")}`}
            </Form.Label>
            <div className="d-flex justify-content-between">
              <div className="w88per">
                <div className="custom-datepicker w-100">
                  <div className="d-flex align-items-center form-control color-white-60 dropdown-center dropdown w-100 react-datepicker-wrapper-w-100 comman_action_icon h_53px"
                  onClick={()=>{
                  setOpenCalendar(false)
                  }}>
                    <div className="action_icon h32w32 with_bg">
                      <CalendarBlankIcon
                        className="HW18"
                      />
                    </div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText={`${_l("l_select_date_placeholder")}`}
                      className="ps-2 w-100"
                      calendarStartDay={1}
                      onChange={(date) => {
                        hadleNoticeDates(date);
                      }}
                      selected={
                        leaseData && leaseData.noticeDates && leaseData.noticeDates.length > 0 ? leaseData.noticeDates[leaseData.noticeDates.length - 1]
                        .notice_date !== "" ? new Date(leaseData.noticeDates[leaseData.noticeDates.length - 1].notice_date) : ""
                     : "" }
                    >
                      <div className="datepicker-label">
                        {_l("l_select_date_and_time")}
                      </div>
                    </DatePicker>
                  </div>
                </div>
              </div>
              <div className="w12per comman_action_icon">
                <UncontrolledDropdown className="w-100  position-relative light-theme-blue-bg radius_5" isOpen={openCalendar}>
                  <DropdownToggle
                    data-toggle="dropdown"
                    tag="a"
                    className=" action_icon with_bg h53w53 d-flex align-items-center justify-content-center w-100"
                    onClick={() => {
                      setOpenCalendar(!openCalendar);
                      if (
                        leaseData.noticeDates.filter(
                          (date) => date.notice_date == ""
                        ).length == 0
                      ) {
                        setBlankNoticeDate();
                      }
                    }}
                  >
                    <PlusIcon className="HW16 mx-0" />
                    {leaseData.noticeDates.length > 1 ? (
                      <span className="c-font f-14 title_text">
                        {leaseData.noticeDates.length - 1}
                      </span>
                    ) : (
                      <></>
                    )}
                  </DropdownToggle>
                  <React.Fragment>
                    <DropdownMenu
                      container="body"
                      direction="down"
                      className={`dropdown-menu `}
                    >
                      {leaseData.noticeDates &&
                        leaseData.noticeDates.length > 1 &&
                        leaseData.noticeDates
                          .filter((notice) => notice.notice_date !== "")
                          .map((notice, index) => {
                            return (
                              <React.Fragment>
                                <li className="hr_sepretr l_50px">
                                  <DropdownItem
                                    toggle={false}
                                    className="text-truncate"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="c-list-icon comman_action_icon">
                                        <div className="h32w32 d-flex align-items-center action_icon with_bg rounded-5">
                                          <CalendarBlankIcon
                                            className="HW18"
                                          />
                                        </div>
                                      </div>
                                      <div className="ps-2 w100minus20 text_wrap d-flex align-items-center justify-content-between">
                                        <div className="c-list-detail c-font f-14 text-truncate color-white">
                                          {moment(notice.notice_date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </div>
                                        <div className="d-flex align-items-center comman_action_icon gap10">
                                          <div
                                            className=" custom-datepicker w-330px withinputabsolute"
                                            data-bs-toggle="tooltip"
                                          >
                                            <div
                                              className={`action_icon with_bg h32w32 on-hover-active-toggle-img light-theme-img position-relative `}
                                            >
                                              <PencilSimpleLineIcon
                                                className="HW18"
                                              />
                                              <DatePicker
                                                portalId="custom_datepicker"
                                                calendarClassName="custom-datepicker"
                                                calendarStartDay={1}
                                                dateFormat="dd/MM/yyyy"
                                                selected={new Date(notice.notice_date)}
                                                onChange={(date) => {
                                                  handleUpdateNoticeDates(date ,index)
                                                }}
                                              >
                                                <div className="datepicker-label">
                                                  {_l("l_select_date_and_time")}
                                                </div>
                                              </DatePicker>
                                            </div>
                                          </div>
                                          <a href="#/" className="action_icon h32w32 with_bg"
                                          onClick={()=>{
                                            deleteNoticeDates(index)
                                          }}>
                                            <TrashIcon
                                              className="HW18"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </DropdownItem>
                                </li>
                              </React.Fragment>
                            );
                          })}
                    </DropdownMenu>
                  </React.Fragment>
                </UncontrolledDropdown>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-lg-6  c-input-box  position-relative">
            <Form.Label className="input-label no-asterisk no-asterisk d-flex align-items-center justify-content-between">
              <div>{`${_l("l_amount")}`}</div>
              <div className="d-flex align-items-center gap10 comman_action_icon">
              {["auto"].map((placement) =>(
                <OverlayTrigger
                delay={{ show: 100 }}
                key={placement}
                placement={placement}
                show={showChart}
                overlay={
                  <Popover
                    id={`popover-positioned-${placement}`}
                    className={` ${chartDataForLease.length < 9 ? "" : "chart-overflow"} w-500`}
                  >
                    <Popover.Body
                      className={`p-0 position-relative calednarwithborder`}
                    >
                       {showChart &&
                                            chartDataForLease.length >= 1 ? (
                                            <div className="position-relative">
                                              <div className="h-100">
                                                <Chart
                                                  chartType="LineChart"
                                                  width={chartDataForLease.length < 9 ? "530px"  : "1350PX" }
                                                  height="100%"
                                                  data={chartDataForLease}
                                                  options={options}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="position-relative">
                                              <div className="h-100">
                                                <CommanPlaceholder imgType="no-documents" placeholderText={_l("l_no_data")}/>
                                              </div>
                                            </div>
                                          )}
                    </Popover.Body>
                  </Popover>
                }
              >
                 <a href="#/" className="action_icon h20w20" onClick={()=>{
                  setShowChart(true);
                 }}>
                  <ChartLineIcon className="HW18" />
                </a>
              </OverlayTrigger>
              ))}
                <div className="with_separator_10 px-0"></div>
                <a
                  href="#/"
                  className="action_icon h20w20 with_margin_minus_10px"
                  onClick={()=>{
                    window.open("https://www.insee.fr/fr/statistiques/serie/001617112#Tableau" , "_blank")
                  }}
                >
                  <FileTextIcon className="HW18" />
                </a>
              </div>
            </Form.Label>
            <div className="position-relative inputabsright">
              <input
                className="form-control"
                placeholder="Є100"
                type="number"
                name="budget"
                value={leaseData.amount}
                onChange={(e) => {
                  setLeaseData({ ...leaseData, amount: e.target.value });
                }}
              />
              <div className="absoptions d-flex align-items-center position-absolute gap-3">
                <Form.Group className="form-check">
                  <Form.Label className="form-check-label mb-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="monthly"
                      value="monthly"
                      checked={leaseData.isMonthlyOrAnnualli == "monthly"}
                      onChange={() => {
                        setLeaseData({
                          ...leaseData,
                          isMonthlyOrAnnualli: "monthly",
                        });
                      }}
                    />
                    <span className="ps-2 c-font f-12">{`${_l(
                      "l_monthly"
                    )}`}</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group className="form-check">
                  <Form.Label className="form-check-label mb-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="annually"
                      value="annually"
                      checked={leaseData.isMonthlyOrAnnualli == "annually"}
                      onChange={() => {
                        setLeaseData({
                          ...leaseData,
                          isMonthlyOrAnnualli: "annually",
                        });
                      }}
                    />
                    <span className="ps-2 c-font f-12">{`${_l(
                      "l_annually"
                    )}`}</span>
                  </Form.Label>
                </Form.Group>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-lg-6  c-input-box position-relative">
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_lease_contracts")}`}
            </Form.Label>
            <div className="d-flex flex-column">
              <div className="form-control  file-upload with-bg position-relative p-0 h-100 text-center">
                <div className="d-flex align-items-center justify-content-center p-15 flex-column">
                  <input
                    className="z-index-3 hidden-input m-auto"
                    type="file"
                    multiple
                    accept="image/*,video/*,.pdf"
                    onChange={(e) => {
                      setLeaseDocuments(e.target.files ,"leaseDocument")
                      preparePreview(e.target.files, "leaseDocument");
                    }}
                  />
                  <div className="d-flex gap10 align-items-center">
                    <CloudArrowUpIcon
                      className="HW30 opacity-25 "
                    />
                    <div className="color-white-60 mt-10">
                      {_l("l_click_to_upload")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex g-3 row flex-wrap pt-20px">
                {documents &&
                  documents.length > 0 &&
                  documents.filter((file) => file.is_contract_file == 1).map((img, index) => {
                    return (
                      <React.Fragment>
                        <div
                          className="upload-image-preview cursor-pointer col-auto"
                          key={index}
                        >
                          <div
                            className="comman-image-box h50w50 radius_3 upload-image with-border active"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                              style={{ backgroundImage: `url(${img.image_url || img.url})` }}
                              onClick={()=>{
                                setshowExternalImage(true);
                                setAttchmentOnTop(img.id , "leaseDocument" )
                              }}
                            ></div>
                            <a
                              href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(img.name || img.file_name, "leaseDocument",img);
                              }}
                            >
                              <XIcon className="HW10" />
                            </a>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                {leaseDocumentPreview &&
                  leaseDocumentPreview.length > 0 &&
                  leaseDocumentPreview.map((img, index) => {
                    let preview = {};
                      preview.url = getPreviewFromURL(img.name);
                    return (
                      <React.Fragment>
                        <div
                          className="upload-image-preview cursor-pointer col-auto"
                          key={index}
                        >
                          <div
                            className="comman-image-box h50w50 radius_3 upload-image with-border active"
                          >
                            {typeof img.url === "string" ? (
                                <div
                                className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                                style={{ backgroundImage: `url(${img.url})` }}
                                onClick={()=>{
                                setshowExternalImage(true);
                                  setAttchmentOnTop(img.id , "leaseDocument" )
                                }}
                              ></div>
                          ) : (
                            <div
                              className="h-100 w-100 d-flex cursor-pointer"
                              onClick={()=>{
                                setshowExternalImage(true);
                                  setAttchmentOnTop(img.id , "leaseDocument" )
                                }}
                              style={{
                                alignContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <preview.url
                                size={72}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                            <a
                              href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(img.name || img.file_name, "leaseDocument",img);
                              }}
                            >
                              <XIcon className="HW10" />
                            </a>
                          </div>
                          <div className="image-text d-flex flex-column justify-content-center d-none">
                            <div className="text-truncate c-font f-13">
                              image name
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-lg-6  c-input-box position-relative">
            <Form.Label className="input-label no-asterisk">
              {`${_l("l_other_documents")}`}
            </Form.Label>
            <div className="d-flex flex-column">
              <div className="form-control  file-upload with-bg position-relative p-0 h-100 text-center">
                <div className="d-flex align-items-center justify-content-center p-15 flex-column">
                  <input
                    className="z-index-3 hidden-input m-auto"
                    type="file"
                    multiple
                    accept="image/*,video/*,.pdf"
                    onChange={(e) => {
                      setOtherDocuments(e.target.files, "otherDocuments");
                      preparePreview(e.target.files, "otherDocuments");
                    }}
                  />
                  <div className="d-flex gap10 align-items-center">
                    <CloudArrowUpIcon
                      className="HW30 opacity-25 "
                    />
                    <div className="color-white-60 mt-10">
                      {_l("l_click_to_upload")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex g-3 row flex-wrap pt-20px">
                {documents &&
                  documents.length > 0 &&
                  documents.filter((file) => file.is_contract_file == 0).map((img, index) => {
                    return (
                      <React.Fragment>
                        <div
                          className="upload-image-preview cursor-pointer col-auto"
                          key={index}
                        >
                          <div
                            className="comman-image-box h50w50 radius_3 upload-image with-border active"
                          >
                            <div
                              className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                              style={{ backgroundImage: `url(${img.image_url || img.url})` }}
                              onClick={()=>{
                                setshowExternalImage(true);
                                setAttchmentOnTop(img.id , "otherDocuments" )
                              }}
                            ></div>
                            <a
                              href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(
                                  img.name  || img.file_name,
                                  "otherDocuments",
                                  img
                                );
                              }}
                            >
                              <XIcon className="HW10" />
                            </a>
                          </div>
                          <div className="image-text d-flex flex-column justify-content-center d-none">
                            <div className="text-truncate c-font f-13">
                              image name
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                {otherDocumentPreview &&
                  otherDocumentPreview.length > 0 &&
                  otherDocumentPreview.map((img, index) => {
                    let preview = {};
                    preview.url = getPreviewFromURL(img.name);
                    return (
                      <React.Fragment>
                        <div
                          className="upload-image-preview cursor-pointer col-auto"
                          key={index}
                        >
                          <div
                            className="comman-image-box h50w50 radius_3 upload-image with-border active"
                          >
                             {typeof img.url === "string" ? (
                               <div
                               className="comman-image-box-bg bg-style-cover h-100 w-100 radius_3"
                               style={{ backgroundImage: `url(${img.url})` }}
                               onClick={()=>{
                                setshowExternalImage(true);
                                setAttchmentOnTop(img.id , "otherDocuments" )
                               }}
                             ></div>
                          ) : (
                            <div
                              className="h-100 w-100 d-flex cursor-pointer"
                              onClick={()=>{
                                setshowExternalImage(true);
                                setAttchmentOnTop(img.id , "otherDocuments" )
                                }}
                              style={{
                                alignContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <preview.url
                                size={72}
                                weight="light"
                                className="c-icons"
                              />
                            </div>
                          )}
                            <a
                              href="#/"
                              className="upload-close"
                              onClick={() => {
                                removeImageSelection(
                                  img.name || img.file_name,
                                  "otherDocuments",
                                  img
                                );
                              }}
                            >
                              <XIcon className="HW10" />
                            </a>
                          </div>
                          <div className="image-text d-flex flex-column justify-content-center d-none">
                            <div className="text-truncate c-font f-13">
                              image name
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-12 c-input-box position-relative ">
            <Form.Label className="input-label no-asterisk title-fonts color-white-60">
              {_l("l_termination_conditions")}
            </Form.Label>
            <textarea
            rows={2}
              placeholder={_l("l_details")}
              className="form-control"
              value={leaseData.termandCondition}
              onChange={(e) => {
                setLeaseData({
                  ...leaseData,
                  termandCondition: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="col-lg-4  c-input-box  position-relative">
            <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
              <div>{`${_l("l_space_services")}`}</div>
              <div className="d-flex align-items-center gap10 comman_action_icon">
                {/* search input */}
                {showSearch.servicesSearch ? (
                  <div class="searchToggle position-relative">
                    <div class="comment-input-box togglesearchfield">
                      <input
                        type="text"
                        class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                        placeholder="Search"
                        value={serviceSearchString}
                        onChange={(e)=>{
                            setServiceSearchString(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* search icon start */}
               {showSearch.servicesSearch ? 
               <a
               href="#/"
               className="action_icon with_bg h32w32"
               onClick={() => {
                 setShowSearch({
                   ...showSearch,
                   servicesSearch: false,
                 });
                 setServiceSearchString("")
               }}
             >
               <XIcon
                 className="HW16"
               />
             </a>
               : <a
                  href="#/"
                  className="action_icon with_bg h32w32"
                  onClick={() => {
                    setShowSearch({
                      ...showSearch,
                      servicesSearch: true,
                    });
                  }}
                >
                  <MagnifyingGlassIcon
                    className="HW16"
                  />
                </a>}
                {/* search icon over */}
              </div>
            </Form.Label>
            <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                {
                    servicesTopics && servicesTopics.length > 0 && servicesTopics.map((category)=>{
                      let preview = {};
                       preview.url = category.image_url.image_url
                        return(<>
                         <li className="comman-list  with-after-50 p-0">
                <a
                  href="#/"
                  className="w-100 position-relative tab_link  left-chat "
                >
                  <div className="d-flex align-items-center">
                    <div className="c-list-icon">
                      <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                        {category.image_url.icon_type == "icon" ? <>
                        {
                            <WrenchIcon className="HW16" />
                        }
                        </> : <>
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${category.image_url.image_url}')`,
                          }}
                        ></div>
                        </>}
                      </div>
                    </div>
                    <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                      <div className="top-part pb-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="chat-u-name text-truncate">
                            {category.title}
                          </div>
                          <div className="d-flex align-items-cente">
                            <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                             {category.is_active > 0 ?
                              <a href="#/" className="action_icon with_bg h32w32"
                              onClick={()=>{
                                setProviderListingCategory(
                                  {
                                    id: category.filter_key, 
                                    title: category.title, 
                                    main_key: "providers", 
                                    is_active: category.is_active, 
                                    main_key_title: "l_services",
                                    main_sub_key: category.main_sub_key
                                  });
                              }}>
                                <GearSixIcon
                                  className="HW16"
                                />
                              </a> : <></>}
                              <div className="form-check form-check-inline m-0 ps-2">
                                <Form.Check
                                  className="with-no-label-space"
                                  type="switch"
                                  role="switch"
                                  value={category.filter_key}
                                  checked={category.is_active > 0}
                                  onChange={()=>{
                                    if (category.is_active > 0) {
                                      inactiveTopics(category,"providers" )
                                    } else {
                                      activeTopics(category,"providers")
                                    }
                                  }}
                                />
                                <label className="form-check-label p-0"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
                        </>)
                    }) 
                }
                {
                  servicesTopics && servicesTopics.length == 0 ? 
                  <CommanPlaceholder placeholderText={_l("l_no_data")} />
                : <React.Fragment></React.Fragment>
                }
            </ul>
          </Form.Group>
          <Form.Group className="col-lg-4  c-input-box  position-relative">
            <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
              <div>{`${_l("l_space_equipments")}`}</div>
              <div className="d-flex align-items-center gap10 comman_action_icon">
                {/* search input */}
                {showSearch.equipmentsSearch ? (
                  <div class="searchToggle position-relative">
                    <div class="comment-input-box togglesearchfield">
                      <input
                        type="text"
                        class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                        placeholder="Search"
                        value={equipmentsSearchString}
                        onChange={(e)=>{
                            setEquipmentsSearchString(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* search icon start */}
                {showSearch.equipmentsSearch ? 
                <a
                href="#/"
                className="action_icon with_bg h32w32"
                onClick={() => {
                  setShowSearch({
                    ...showSearch,
                    equipmentsSearch: false,
                  });
                  setEquipmentsSearchString("")
                }}
              >
                <XIcon
                  className="HW16"
                />
              </a>
                :
                <a
                  href="#/"
                  className="action_icon with_bg h32w32"
                  onClick={() => {
                    setShowSearch({
                      ...showSearch,
                      equipmentsSearch: true,
                    });
                  }}
                >
                  <MagnifyingGlassIcon
                    className="HW16"
                  />
                </a>}
                {/* search icon over */}
                <Button variant="secondary btn-sm"
                className={`h32w32 d-flex p-0`}
                onClick={()=>{
                  window.open(
                    `/equipments?space=${spaceDetail.project_id}&action=equipment-list`,
                    "_blank"
                  );
                }}
              >
                <PlusIcon
                    className="HW16"
                  />
              </Button>
              </div>
            </Form.Label>
            <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
                {
                    equipmentsTopics && equipmentsTopics.length > 0 && equipmentsTopics.map((category)=>{
                        return(<>
                         <li className="comman-list  with-after-50 p-0">
                <a
                  href="#/"
                  className="w-100 position-relative tab_link  left-chat "
                >
                  <div className="d-flex align-items-center">
                    <div className="c-list-icon">
                      <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                        <div
                          className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                          style={{
                            backgroundImage: `url('${category.image_url.image_url}')`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                      <div className="top-part pb-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="chat-u-name text-truncate">
                            {category.title}
                          </div>
                          <div className="d-flex align-items-cente">
                            <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                              { category.is_active ?
                               <a href="#/" className="action_icon with_bg h32w32"
                               onClick={()=>{
                                setProviderListingCategory(
                                  {
                                    id: category.filter_key, 
                                    title: category.title, 
                                    main_key: "equipments", 
                                    is_active: category.is_active, 
                                    main_key_title: "l_equipments",
                                    main_sub_key: category.main_sub_key
                                  });
                               }}>
                                <GearSixIcon
                                  className="HW16"
                                />
                              </a> : <></>}
                              <div className="form-check form-check-inline m-0 ps-2">
                                <Form.Check
                                  className="with-no-label-space"
                                  type="switch"
                                  role="switch"
                                  value={category.filter_key}
                                  checked={category.is_active > 0}
                                  onChange={()=>{
                                    if (category.is_active > 0) {
                                      inactiveTopics(category,"equipments" );
                                    } else {
                                      activeTopics(category,"equipments");
                                    }
                                  }}
                                />
                                <label className="form-check-label p-0"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
                        </>)
                    }) 
                }
                   {
                  equipmentsTopics && equipmentsTopics.length == 0 ? 
                  <CommanPlaceholder placeholderText={_l("l_no_data")} />
                : <React.Fragment></React.Fragment>
                }
            </ul>
          </Form.Group>
          <Form.Group className="col-lg-4  c-input-box  position-relative">
            <Form.Label className="align-items-center border-bottom d-flex form-label input-label justify-content-between no-asterisk pb-10px pe-0">
              <div>{`${_l("l_space_residents")}`}</div>
              <div className="d-flex align-items-center gap10 comman_action_icon">
                {/* search input */}
                {showSearch.residentsSearch ? (
                  <div class="searchToggle position-relative">
                    <div class="comment-input-box togglesearchfield">
                      <input
                        type="text"
                        class="c-font f-11 form-control h-100 pe-0 ps-10px py-0 shadow-none with-base-bg"
                        placeholder="Search"
                        value={residentsSearchString}
                        onChange={(e)=>{
                            setResidentsSearchString(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* search icon start */}
                {showSearch.residentsSearch ?
                 <a
                 href="#/"
                 className="action_icon with_bg h32w32"
                 onClick={() => {
                   setShowSearch({
                     ...showSearch,
                     residentsSearch: false,
                   });
                   setResidentsSearchString("")
                 }}
               >
                 <XIcon
                   className="HW16"
                 />
               </a>
                :
                <a
                  href="#/"
                  className="action_icon with_bg h32w32"
                  onClick={() => {
                    setShowSearch({
                      ...showSearch,
                      residentsSearch: true,
                    });
                  }}
                >
                  <MagnifyingGlassIcon
                    className="HW16"
                  />
                </a>}
                {/* search icon over */}
                <Button 
                variant="secondary btn-sm"
                className={`h32w32 d-flex p-0`}
                onClick={()=>{
                  setAddLeaseEmployeeModalOpen(true);
                }}
              >
                <PlusIcon
                    className="HW16"
                  />
              </Button>
              <Button 
                variant="secondary btn-sm"
                className={`h32w32 d-flex p-0`}
                onClick={()=>{
                  setOpenLeaseEnterprise(true);
                }}
              >
                <BuildingsIcon
                    className="HW16"
                  />
              </Button>
              </div>
            </Form.Label>
            <ul className="comman-verticle-tab m-0  overflow-hiiden-auto row">
              {
               isFrom == "lease-setup" &&  residentTopics && residentTopics.length > 0 && residentTopics.map((category, i) =>{
                  return(
                    <React.Fragment>
                      <li className="comman-list  with-after-50 p-0">
                        <a
                          href="#/"
                          className="w-100 position-relative tab_link  left-chat "
                        >
                          <div className="d-flex align-items-center">
                            <div className="c-list-icon">
                              <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url('${category.image || (category.user_details && category.user_details.image)}')`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                              <div className="top-part pb-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="chat-u-name text-truncate">
                                    {category.user_details.firstname + ' ' + category.user_details.lastname}
                                  </div>
                                  <div className="d-flex align-items-cente">
                                    <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                      { category.is_active > 0 ?
                                      <a href="#/" className="action_icon with_bg h32w32"
                                      onClick={()=>{
                                        setProviderListingCategory(
                                          {
                                            id: category.filter_key, 
                                            title: category.title, 
                                            main_key:category.user_type == "contact"  ? "my_contact" : "my_operator", 
                                            is_active: category.is_active, 
                                            main_key_title: category.user_type == "contact"  ? "l_my_people" : "l_operator",
                                            main_sub_key: category.main_sub_key
                                          });
                                      }}
                                      >
                                        <GearSixIcon
                                          className="HW16"
                                        />
                                      </a> : <></>}
                                      <div className="form-check form-check-inline m-0 ps-2">
                                        <Form.Check
                                          className="with-no-label-space"
                                          type="switch"
                                          role="switch"
                                          checked={category.is_active > 0}
                                          onChange={()=>{
                                            handleTopicSelection(category.filter_key , "residents");
                                            if (category.is_active > 0) {
                                              inactiveTopics(category,category.main_sub_key == "resident" ? "resident" : category.user_type == "contact"  ? "my_contact" : "my_operator" );
                                            } else {
                                               activeTopics(category,category.main_sub_key == "resident" ? "resident" : category.user_type == "contact"  ? "my_contact" : "my_operator");
                                            }
                                          }}
                                        />
                                        <label className="form-check-label p-0"></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    </React.Fragment>
                  )
                })
              }
                  {
                  isFrom == "lease-setup" && residentTopics && residentTopics.length == 0 ? 
                  <CommanPlaceholder placeholderText={_l("l_no_data")} />
                : <React.Fragment></React.Fragment>
                }
                {/* --------------------------------------------- modal residents start --------------------------------------------- */}
              {
                isFrom !== "lease-setup" && residentTopics && residentTopics.length > 0 && residentTopics.map((category, i) =>{
                  return(
                    <React.Fragment>
                      <li className="comman-list  with-after-50 p-0">
                        <a
                          href="#/"
                          className="w-100 position-relative tab_link  left-chat "
                        >
                          <div className="d-flex align-items-center">
                            <div className="c-list-icon">
                              <div className="h35w35 comman-round-box with-bg d-flex align-items-center rounded-circle bg-white-05">
                                <div
                                  className="comman-bg-img h-100 w-100 bg-style-cover rounded-circle"
                                  style={{
                                    backgroundImage: `url('${category.image || (category.user_details && category.user_details.image)}')`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="c-list-detail c-font f-14 ps-2 w100minus35">
                              <div className="top-part pb-1">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="chat-u-name text-truncate">
                                    {category.user_details.firstname + ' ' + category.user_details.lastname}
                                  </div>
                                  <div className="d-flex align-items-cente">
                                    <div className="topic-switch d-flex align-items-center gap10 comman_action_icon">
                                      { leaseData.selectedResidents.includes(category.filter_key) ?
                                      <a href="#/" className="action_icon with_bg h32w32"
                                      onClick={()=>{
                                        setProviderListingCategory(
                                          {
                                            id: category.filter_key, 
                                            title: category.title, 
                                            main_key:category.user_type == "contact"  ? "my_contact" : "my_operator", 
                                            is_active: category.is_active, 
                                            main_key_title: category.user_type == "contact"  ? "l_my_people" : "l_operator",
                                            main_sub_key: category.main_sub_key
                                          });
                                      }}
                                      >
                                        <GearSixIcon
                                          className="HW16"
                                        />
                                      </a> : <></>}
                                      <div className="form-check form-check-inline m-0 ps-2">
                                        <Form.Check
                                          className="with-no-label-space"
                                          type="switch"
                                          role="switch"
                                          checked={leaseData.selectedResidents.includes(category.filter_key)}
                                          onChange={()=>{
                                            if (leaseData.selectedResidents.includes(category.filter_key)) {
                                              setLeaseData({...leaseData ,selectedResidents : [] });
                                              inactiveTopics(category,category.main_sub_key == "resident" ? "resident" : category.user_type == "contact"  ? "my_contact" : "my_operator");
                                            } else {
                                              setLeaseData({...leaseData ,selectedResidents : [category.filter_key]});
                                               activeTopics(category,category.main_sub_key == "resident" ? "resident" : category.user_type == "contact"  ? "my_contact" : "my_operator");
                                            }
                                          }}
                                        />
                                        <label className="form-check-label p-0"></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    </React.Fragment>
                  )
                })
              }
                  {isFrom !== "lease-setup" &&
                  residentTopics && residentTopics.length == 0 ? 
                  <CommanPlaceholder placeholderText={_l("l_no_data")} />
                : <React.Fragment></React.Fragment>
                }
                {/* --------------------------------------------- modal residents end --------------------------------------------- */}
            </ul>
          </Form.Group>
        </Row>
      </Col>
    </Row>
    {
        Math.abs(providerListingCategory.id) > 0
        ?
        <TopicSettingModal 
          data={providerListingCategory} 
          show={Math.abs(providerListingCategory.id) > 0} 
          handleClose={() => {setProviderListingCategory({id: 0, title: "", main_key: "", is_active: "", main_key_title: "", main_sub_key: ""})}} 
          title={providerListingCategory.main_key_title} titlePrefix={"l_settings"} 
          hideFilterArea={providerListingCategory.hideFilterArea} 
          pageName = {"leaseContract"}
          project_id={spaceDetail.project_id > 0 ? spaceDetail.project_id : 0}
          />
        :
        <React.Fragment></React.Fragment>
      }
      {openLeaseEnterprise ?
       <LeaseEnterprisCompanyModal 
          show={openLeaseEnterprise}
          handleClose={() => {
            setOpenLeaseEnterprise(false);
            leaseServices.getLeaseTopics(spaceDetail.project_id, spaceDetail.lease_id).then((res)=>{
              if (res && res.status) {
                setTopics(res.data.topics)
              }
            })
          }}
          spaceBasicDetails = {spaceBasicDetails}
          setResidentTopics = {setResidentTopics}
          residentTopics = {residentTopics}
       />
       :
       <React.Fragment></React.Fragment>
      }
      {
        addLeaseEmployeeModalOpen ? 
          <AddLeaseEmployeeModal
            show={addLeaseEmployeeModalOpen}
            handleClose={() => {
              setAddLeaseEmployeeModalOpen(false);
              leaseServices.getLeaseTopics(spaceDetail.project_id, spaceDetail.lease_id).then((res)=>{
                if (res && res.status) {
                  setTopics(res.data.topics)
                }
              })
            }}
            spaceBasicDetails = {spaceBasicDetails}
            setResidentTopics = {setResidentTopics}
            residentTopics = {residentTopics}
          />
        : <React.Fragment></React.Fragment>
      }
    </React.Fragment>
  );
};
export default LeaseContractSetup;
