import React from 'react';
 

function PencilIcon(props){
	const height 	= (props.height) ? props.height : "13";
	const width 	= (props.width) ? props.width : "16";
	const viewBox 	= (props.viewBox) ? props.viewBox : "0 0 16 13";
	const CommanHW 	= (props.className) ? props.className : "";
	// const fill 		= (props.fill) ? props.fill : "#ffffff";

	return(		
		<React.Fragment>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"   className={`comman-icons ${CommanHW}`}>
<path d="M8.89062 3.99995L12.3906 7.49995M10.6406 5.74995L4.64062 11.7499M6.35937 13.4687L2.92187 10.0312M6.18437 13.4999H3.39062C3.25802 13.4999 3.13084 13.4473 3.03707 13.3535C2.9433 13.2597 2.89062 13.1326 2.89062 12.9999V10.2062C2.8904 10.1413 2.90298 10.0769 2.92764 10.0169C2.95231 9.95682 2.98858 9.90222 3.03437 9.8562L10.5344 2.3562C10.5809 2.30895 10.6364 2.27144 10.6975 2.24583C10.7587 2.22022 10.8243 2.20703 10.8906 2.20703C10.9569 2.20703 11.0226 2.22022 11.0837 2.24583C11.1449 2.27144 11.2004 2.30895 11.2469 2.3562L14.0344 5.1437C14.0816 5.19022 14.1191 5.24568 14.1447 5.30684C14.1704 5.368 14.1835 5.43364 14.1835 5.49995C14.1835 5.56625 14.1704 5.63189 14.1447 5.69305C14.1191 5.75421 14.0816 5.80967 14.0344 5.8562L6.53437 13.3562C6.48835 13.402 6.43375 13.4383 6.37369 13.4629C6.31363 13.4876 6.2493 13.5002 6.18437 13.4999Z" stroke="#DEDEDE" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



		</React.Fragment>
	)
}

export default PencilIcon