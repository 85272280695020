import React, { useState } from "react";
import EmailDetailViewAccordians from "./EmailDetailViewAccordians";
import { _l } from "../../../hooks/utilities";

const EmailDetailView = ({ mailToView,data,setShowLoader,id }) => {
  const [selectedTab, setSelectedTab] = useState(data && data.status == 'active' ? "" : "emailbody");


  return (
    <>
      <div className="tab-wrapper_main  res-overflow-auto">
        <div className="d-flex align-items-center justify-content-between border-bottom">
        </div>
      </div>
      <div className="comman_vertical_btn_h d-flex flex-column pt-2 h54px">
        {selectedTab === "emailbody" ? (
          <EmailDetailViewAccordians mailToView={mailToView} id={id} setShowLoader={setShowLoader} />
        ) : selectedTab === "task" ? (
          <></>
        ) : (
          <></>
        )}
      </div>
      </>
  );
};
export default EmailDetailView;
