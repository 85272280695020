import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopicSettingModal from "../components/Modals/TopicSettingModal";
import { _l } from "../hooks/utilities";

const PremiumEstimate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [settingModalData, setSettingModalData] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    } else {
      setSettingModalData({
        main_key: "premium_estimate",
        id: -1,
        main_key_title: _l("l_my_plan"),
        title: "l_all",
      });
    }
  }, []);

  useEffect(() => {
    if(!settingModalData)
    {
      navigate(-1);
    }
  }, [settingModalData])
  

  return (
    <div>
      {settingModalData && Math.abs(settingModalData.id) > 0 ? (
        <TopicSettingModal
          data={{
            main_key: settingModalData.main_key,
            id: settingModalData.id,
            title: settingModalData.title,
            main_key_title: settingModalData.main_key_title,
          }}
          handleClose={() => {
            navigate(location.state && location.state.from ? location.state.from : "/");
          }}
          hideFilterArea={true}
          show={Math.abs(settingModalData.id) > 0}
          title={settingModalData.main_key_title}
          titlePrefix={"l_settings"}
          project_id={0}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

export default PremiumEstimate;
